import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ReduxState } from 'types/redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import prolongRequest from 'utils/prolongRequest';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { updateApplicationVariables } from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { getCardsByTabId } from 'LoanOriginationSystemApplicationDataTabCards/Selectors';
import { getApplicationDataTabCards } from 'LoanOriginationSystemApplicationDataTabCards/ActionCreator';
import { getVisualDataType } from 'Variables/utils';
import { VisualDataType } from 'Variables/VariablesTypes';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import DataTabRow from 'components/LoanOriginationSystem/ApplicationTabs/DataTab/DataTabRow';
import ButtonWithImage from 'components/ButtonWithImage';
import Button from 'components/Button';
import SkeletonDataTab from './SkeletonDataTab';
import styles from './DataTab.module.scss';

export interface DataTabProps {
  tab: ApplicationTab;
  application: Application;
}

const ON_CHANGE_UPDATE_FIELDS: VisualDataType[] = ['List', 'Boolean', 'Date'];
const UPDATE_APPLICATION_VARIABLES_MIN_REQUEST_TIME = 1000;

const DataTab = ({ tab, application }: DataTabProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState({ ...application.variables });
  const [loaderStateById, setLoaderStateById] = useState<Record<string, LoaderState | null | undefined>>({});
  const focusedFieldRef = useRef<HTMLInputElement | null>(null);
  const cards = useSelector((state: ReduxState) => getCardsByTabId(state, tab.id));

  const prolongedUpdateApplicationVariables = prolongRequest(
    ({ variable }: VariableConfiguration, value: VariableValue) => {
      return dispatchRoutine(updateApplicationVariables(application.id, { [variable.systemName]: value }));
    },
    UPDATE_APPLICATION_VARIABLES_MIN_REQUEST_TIME,
  );

  useEffect(() => {
    dispatch(getApplicationDataTabCards(tab.id));

    setIsEditMode(false);
  }, [tab]);

  useEffect(() => {
    setData({ ...application.variables });
  }, [application.variables]);

  const handleApplicationDataUpdate = async (variableConfiguration: VariableConfiguration, value: VariableValue) => {
    const { id } = variableConfiguration;

    try {
      setLoaderStateById({ [id]: LoaderState.Updating });

      await prolongedUpdateApplicationVariables(variableConfiguration, value);

      setLoaderStateById({ [id]: LoaderState.Success });
    } catch {
      setLoaderStateById({ [id]: LoaderState.Failure });
    }
  };

  const handleFieldChange = async (variableConfiguration: VariableConfiguration, value: VariableValue) => {
    const { variable } = variableConfiguration;

    const visualDataType = getVisualDataType(variable);

    setData({ ...data, [variable.systemName]: value });

    if (!ON_CHANGE_UPDATE_FIELDS.includes(visualDataType)) {
      return;
    }

    if (visualDataType === 'Date' && focusedFieldRef.current) {
      return;
    }

    if (application.variables[variable.systemName] === value) {
      return;
    }

    await handleApplicationDataUpdate(variableConfiguration, value);
  };

  const handleFieldFocus = (variableConfiguration: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => {
    focusedFieldRef.current = event.target;
  };

  const handleFieldBlur = async (variableConfiguration: VariableConfiguration) => {
    const { variable } = variableConfiguration;
    const visualDataType = getVisualDataType(variable);

    if (ON_CHANGE_UPDATE_FIELDS.includes(visualDataType) && visualDataType !== 'Date') {
      return;
    }

    if (application.variables[variable.systemName] === data[variable.systemName]) {
      return;
    }

    await handleApplicationDataUpdate(variableConfiguration, data[variable.systemName]);
  };

  const handleLoaderStateReset = ({ id }: VariableConfiguration) => setLoaderStateById({ [id]: null });

  const cardsLayout = useFormLayout(cards);

  const renderHeaderButton = () => {
    if (isEditMode) {
      return (
        <Button className={styles.closeEditorButton} kind="secondary" onClick={() => setIsEditMode(false)}>
          Close Editor
        </Button>
      );
    }

    return (
      <ButtonWithImage
        className={styles.editDataButton}
        disabled={!cardsLayout}
        title="Edit Data"
        kind="edit"
        onClick={() => setIsEditMode(true)}
      />
    );
  };

  const renderTabContent = () => {
    if (!cardsLayout) {
      return <SkeletonDataTab />;
    }

    return (
      <>
        {cardsLayout.map((cardsInRow, index) => (
          <DataTabRow
            onLoaderStateReset={handleLoaderStateReset}
            loaderStateById={loaderStateById}
            key={index}
            cards={cardsInRow}
            data={data}
            onFieldChange={handleFieldChange}
            onFieldFocus={handleFieldFocus}
            onFieldBlur={handleFieldBlur}
            isEditMode={isEditMode}
          />
        ))}
      </>
    );
  };

  return (
    <div>
      <div className={clsx(styles.tabHeader, isEditMode && styles.tabHeaderEdit)}>
        <h4>{tab.name}</h4>
        {renderHeaderButton()}
      </div>
      {renderTabContent()}
    </div>
  );
};

export default DataTab;
