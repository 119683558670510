import { AnyAction } from 'redux';
import mountChildReducers from 'utils/childReducers';
import { IndividualProcessingState, IndividualCaseState } from './Types';
import {
  GET_STRATEGIES_LIST_SUCCESS,
  GET_STRATEGY_INPUTS_SUCCESS,
  RESET_INDIVIDUAL_STRATEGY_INPUTS,
  GET_INDIVIDUAL_CASE_SUCCESS,
  GET_STRATEGY_INPUTS,
  RUN_INDIVIDUAL_STRATEGY,
  RUN_INDIVIDUAL_STRATEGY_SUCCESS,
  GET_STRATEGY_INPUTS_FAILURE,
  GET_INDIVIDUAL_CASE,
  RUN_INDIVIDUAL_STRATEGY_FAILURE,
} from './ActionTypes';
import individualProcessingHistoryReducer, {
  initialState as individualProcessingHistoryInitialState,
} from './IndividualProcessingHistoryReducer';

const individualCaseDataInitialState: IndividualCaseState = {
  application: null,
  compiledOrder: [],
  displayTitle: '',
  inputs: {},
  moduleOrder: [],
  outputs: {},
  passed: false,
  strategyDisplayName: '',
  user: {
    creator: '',
    updater: '',
  },
  declineReasons: [],
  error: [],
  createdAt: '',
  strategyId: '',
  variablesMap: [],
  isLoading: false,
};

const initialState: IndividualProcessingState = {
  strategies: [],
  inputs: null,
  isInputsLoading: false,
  individualCaseData: individualCaseDataInitialState,
  isLoading: false,
  history: individualProcessingHistoryInitialState,
};

const IndividualProcessingReducer = (state = initialState, action: AnyAction): IndividualProcessingState => {
  switch (action.type) {
    case GET_STRATEGIES_LIST_SUCCESS: {
      return { ...state, strategies: action.payload };
    }
    case GET_STRATEGY_INPUTS: {
      return { ...state, inputs: null, isInputsLoading: true };
    }
    case GET_STRATEGY_INPUTS_SUCCESS: {
      return { ...state, inputs: action.payload, isInputsLoading: false };
    }
    case GET_STRATEGY_INPUTS_FAILURE: {
      return { ...state, isInputsLoading: false };
    }
    case RESET_INDIVIDUAL_STRATEGY_INPUTS: {
      return { ...state, inputs: null };
    }
    case GET_INDIVIDUAL_CASE: {
      return {
        ...state,
        individualCaseData: {
          ...state.individualCaseData,
          isLoading: true,
        },
      };
    }
    case GET_INDIVIDUAL_CASE_SUCCESS: {
      return { ...state, individualCaseData: { ...action.payload, isLoading: false } };
    }
    case RUN_INDIVIDUAL_STRATEGY: {
      return { ...state, individualCaseData: individualCaseDataInitialState, isLoading: true };
    }
    case RUN_INDIVIDUAL_STRATEGY_SUCCESS:
    case RUN_INDIVIDUAL_STRATEGY_FAILURE: {
      return { ...state, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

const childReducers = {
  history: individualProcessingHistoryReducer,
};

export default mountChildReducers(IndividualProcessingReducer, childReducers);
