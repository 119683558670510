export const OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP = 'OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP';
export const CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP = 'CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP';

export const OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP = 'OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP';
export const CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP = 'CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP';

export const ASSIGN_OUTPUT_VARIABLE = 'ASSIGN_OUTPUT_VARIABLE';
export const ASSIGN_OUTPUT_VARIABLE_SUCCESS = 'ASSIGN_OUTPUT_VARIABLE_SUCCESS';
export const ASSIGN_OUTPUT_VARIABLE_FAILURE = 'ASSIGN_OUTPUT_VARIABLE_FAILURE';

export const ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES = 'ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES';
export const ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS =
  'ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS';
export const ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE =
  'ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE';
