import { LoanOriginationSystemProductsFiltersActionTypes } from './ActionTypes';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';

export interface SetSearchInputValueAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue;
  payload: string;
}

export interface SetFilterStatusesAction {
  type: LoanOriginationSystemProductsFiltersActionTypes.SetFilterStatuses;
  payload: {
    statuses: ProductStatus[];
  };
}

export const setSearchInputValue = (search: string): SetSearchInputValueAction => ({
  type: LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue,
  payload: search,
});

export const setFilterStatuses = (statuses: ProductStatus[]): SetFilterStatusesAction => ({
  type: LoanOriginationSystemProductsFiltersActionTypes.SetFilterStatuses,
  payload: {
    statuses,
  },
});
