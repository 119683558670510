import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { SelectBorrowerViewTabAction } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { SelectIntermediaryViewTabAction } from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';
import styles from './ItemHasApplicationsErrorNotification.module.scss';

export interface ItemHasApplicationsNotificationProps {
  message: string;
  url: string;
  selectApplicationsTabActionCreator: () => SelectBorrowerViewTabAction | SelectIntermediaryViewTabAction;
}

const ItemHasApplicationsErrorNotification: FC<ItemHasApplicationsNotificationProps> = ({
  message,
  url,
  selectApplicationsTabActionCreator,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onViewApplicationsClick = () => {
    history.push(url);
    dispatch(selectApplicationsTabActionCreator());
  };

  return (
    <div>
      {`${message} `}
      <span className={styles.link} onClick={onViewApplicationsClick}>
        View Applications
      </span>
    </div>
  );
};

export default ItemHasApplicationsErrorNotification;
