import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';

const createSelectorWithDispatch = <State, TSelected>(
  getAction: () => AnyAction,
  selector: (state: State) => TSelected,
) => {
  return () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getAction());
    }, []);

    return useSelector(selector);
  };
};

export default createSelectorWithDispatch;
