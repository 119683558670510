import React, { FC, useState } from 'react';
import styles from './AddSimpleOutputs.module.scss';
import Button from 'components/Button';
import ComparisonOperand from 'components/ComparisonOperand';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import { Option } from 'components/SelectInput/SelectInput';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { RuleOutput, SimpleOutputRequestData } from 'RuleCreate/Types';
import { RuleBuilderState } from 'components/RuleBuilder/Types';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import getDataTypeAttributes from 'utils/ruleBuilder/getDataTypeAttributes';
import getShowingDataType from 'utils/ruleBuilder/getShowingDataType';
import { isEqual } from 'lodash';
import { getIsCurrentOperandFilled } from 'components/RuleBuilder/utils';
import TransitionComponent from 'components/HeightTransitionComponent';

interface AddSimpleOutputsProps {
  saveSimpleOutput: (simpleOutputData: SimpleOutputRequestData) => void;
  serializedRules?: RuleBuilderState[];
  serializedOutputs?: RuleOutput[];
  openVariableCreation: () => void;
  isSaving?: boolean;
}

const AddSimpleOutputs: FC<AddSimpleOutputsProps> = ({
  serializedRules = [],
  openVariableCreation,
  saveSimpleOutput,
  isSaving,
}) => {
  const derivedSourceVariableId = serializedRules[0]?.sourceVariableId;
  const derivedSimpleOutputValue = serializedRules[0]?.firstOperandValue;
  const derivedSimpleOutputValueId = serializedRules[0]?.firstOperandVariableId;
  const derivedSimpleOutputType = serializedRules[0]?.firstOperandType;

  const [sourceVariableId, setSourceVariableId] = useState(derivedSourceVariableId || undefined);
  const [simpleOutputValue, setSimpleOutputValue] = useState(derivedSimpleOutputValue || '');
  const [simpleOutputValueId, setSimpleOutputValueId] = useState(derivedSimpleOutputValueId || undefined);
  const [simpleOutputType, setSimpleOutputType] = useState(derivedSimpleOutputType || ComparisonOperandType.VALUE);

  const onChangeSourceVariable = ({ value }: Option) => {
    setSourceVariableId(value);
    setSimpleOutputValue('');
    setSimpleOutputValueId('');
  };

  const sourceVariable = useVariableAttributes(sourceVariableId!);

  const dataTypeWithAttributes = getDataTypeAttributes(sourceVariable);

  const onSaveSimpleOutput = () => {
    saveSimpleOutput({
      sourceVariableId,
      simpleOutputValue,
      simpleOutputValueId,
      simpleOutputType,
    } as SimpleOutputRequestData);
  };

  const isSimpleOutputFilled = getIsCurrentOperandFilled(simpleOutputType, simpleOutputValueId, simpleOutputValue);

  const areSimpleOutputChange = [
    [sourceVariableId, derivedSourceVariableId],
    [simpleOutputValue, derivedSimpleOutputValue],
    [simpleOutputValueId, derivedSimpleOutputValueId],
  ].some(([a, b]) => !isEqual(a, b));

  const isButtonEnabled = sourceVariableId && isSimpleOutputFilled && areSimpleOutputChange;

  return (
    <div className={styles.content}>
      <InputWithDataTypeLabel
        labelTitle="Output Variable"
        value={sourceVariableId}
        onChange={onChangeSourceVariable}
        dataType={getShowingDataType(dataTypeWithAttributes)}
        link={<CreateNewVariableLink onClick={openVariableCreation} />}
      />
      <TransitionComponent>
        {sourceVariableId && (
          <ComparisonOperand
            value={simpleOutputValue}
            operandType={simpleOutputType}
            variableId={simpleOutputValueId}
            onVariableIdChange={setSimpleOutputValueId}
            onValueChange={setSimpleOutputValue}
            onTypeChange={setSimpleOutputType}
            labelTitle="Value"
            {...(dataTypeWithAttributes as VisualDataTypeWithAttributes)}
          />
        )}
      </TransitionComponent>
      <Button
        disabled={!isButtonEnabled}
        kind="primary"
        size="form"
        className={styles.continueButton}
        onClick={onSaveSimpleOutput}
        isLoading={isSaving}
      >
        Save
      </Button>
    </div>
  );
};

export default AddSimpleOutputs;
