import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import pagination from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';

export default (api: LoanOriginationSystemEmailsApi) => {
  return pagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getEmails(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        createdAtDateRange: params.createdAtDateRange,
        borrower: params.selectedBorrower,
      },
      params.sortingType,
    );
    return items;
  });
};
