enum ProductSectionAccessPermissionsActionTypes {
  GetProductSectionAccessPermissionsRequest = 'productSectionAccessPermissions/GetProductSectionAccessPermissionsRequest',
  GetProductSectionAccessPermissionsSuccess = 'productSectionAccessPermissions/GetProductSectionAccessPermissionsSuccess',
  GetProductSectionAccessPermissionsFailure = 'productSectionAccessPermissions/GetProductSectionAccessPermissionsFailure',
  UpdateProductSectionAccessPermissionRequest = 'productSectionAccessPermissions/UpdateProductSectionAccessPermissionRequest',
  UpdateProductSectionAccessPermissionSuccess = 'productSectionAccessPermissions/UpdateProductSectionAccessPermissionSuccess',
  UpdateProductSectionAccessPermissionFailure = 'productSectionAccessPermissions/UpdateProductSectionAccessPermissionFailure',
  ResetProductSectionAccessPermissionUpdatingState = 'productSectionAccessPermissions/ResetProductSectionAccessPermissionUpdatingState',
  ResetPermissionsRequest = 'productSectionAccessPermissions/ResetPermissionsRequest',
  ResetPermissionsSuccess = 'productSectionAccessPermissions/ResetPermissionsSuccess',
  ResetPermissionsFailure = 'productSectionAccessPermissions/ResetPermissionsFailure',
  OpenResetToDefaultsPopUp = 'productSectionAccessPermissions/OpenResetToDefaultsPopUp',
  CloseResetToDefaultsPopUp = 'productSectionAccessPermissions/CloseResetToDefaultsPopUp',
}

export default ProductSectionAccessPermissionsActionTypes;
