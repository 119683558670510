import Fetcher from 'middlewares/Fetcher';
import { Middleware } from 'redux';
import { UserOrganizationsActionTypes } from 'UserOrganizations/UserOrganizationsActionTypes';
import { getOrganizationsFailed, getOrganizationsSuccess } from 'UserOrganizations/UserOrganizationsActionCreators';
import { OrganizationMembershipApi } from 'api/Core/OrganizationMembershipApi';
import { OrganizationInfo } from 'api/Core/OrganizationApi';

const GetOrganizationsMiddleware: (api: OrganizationMembershipApi) => Middleware = (api) =>
  Fetcher<OrganizationInfo[]>(
    UserOrganizationsActionTypes.GetOrganizations,
    getOrganizationsSuccess,
    getOrganizationsFailed,
    () => api.getUserOrganizations(),
  );

export default GetOrganizationsMiddleware;
