import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { IndividualProcessingHistoryCase } from 'api/Types';
import { DecisionRunsSortingFieldType } from 'ApplicationDecisionRuns/types';

export const HISTORY_DECISION_RUN_PER_PAGE_DEFAULT = 20;

const applicationDecisionRunsPagination = Pagination<
  ReduxState,
  IndividualProcessingHistoryCase,
  { applicationId: string; sortingType: DecisionRunsSortingFieldType }
>('applicationCases', HISTORY_DECISION_RUN_PER_PAGE_DEFAULT);

export default applicationDecisionRunsPagination;
