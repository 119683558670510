import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  ApplicationTab,
  ApplicationTabType,
  UpdateApplicationTabParams,
} from 'api/LoanOriginationSystem/ApplicationTabsApi';
import getPosition from 'utils/getPosition';
import {
  selectTab,
  setTabToDelete,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationSetup/ActionCreator';
import {
  createApplicationTab,
  ApplicationTabsActionOrigin,
  getApplicationTabs,
  updateApplicationTab,
} from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import { getTabsByProductId } from 'LoanOriginationSystemApplicationTabs/Selectors';
import useStandardVariables from 'hooks/useStandardVariables';
import ButtonWithImage from 'components/ButtonWithImage';
import HeightTransitionComponent from 'components/HeightTransitionComponent';
import ProductConfigurationSubHeader from 'components/LoanOriginationSystem/ProductConfiguration/ProductConfigurationSubHeader';
import ApplicationHeaderPreview, { SkeletonApplicationHeaderPreview } from './ApplicationHeaderPreview';
import ApplicationSetupBody from './ApplicationSetupBody';
import styles from './ApplicationSetup.module.scss';

const ApplicationSetup = () => {
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration.product,
  );
  const tabs = useSelector((state: ReduxState) => getTabsByProductId(state, selectedProduct?.id));
  const { selectedTab, isBlockingUpdateInProgress } = useSelector((state: ReduxState) => ({
    selectedTab: state.loanOriginationSystemProducts.productConfiguration.applicationSetup.selectedTab || tabs?.[0],
    isBlockingUpdateInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationSetup.isBlockingUpdateInProgress,
  }));
  const [displayApplicationHeaderPreview, setDisplayApplicationHeaderPreview] = useState(true);
  const standardVariables = useStandardVariables();

  useEffect(() => {
    if (!tabs && selectedProduct) {
      dispatch(setActionOrigin(getApplicationTabs(selectedProduct.id), ApplicationTabsActionOrigin.ApplicationSetup));
    }
  }, [selectedProduct]);

  const handleTabSelect = (tab: ApplicationTab) => dispatch(selectTab(tab));
  const handleTabDelete = (tab: ApplicationTab) => dispatch(setTabToDelete(tab));

  const handleTabAdd = (type: ApplicationTabType) => {
    if (!selectedProduct || !tabs) {
      return;
    }

    const position = getPosition(tabs, tabs.length);

    dispatch(
      createApplicationTab({
        productId: selectedProduct.id,
        position,
        type,
      }),
    );
  };

  const handleTabUpdate = (id: string, params: UpdateApplicationTabParams) => {
    dispatch(updateApplicationTab(id, params));
  };

  const renderApplicationHeaderPreview = () => {
    if (!selectedProduct || !standardVariables) {
      return <SkeletonApplicationHeaderPreview />;
    }

    return (
      <ApplicationHeaderPreview
        productName={selectedProduct.name}
        standardVariables={standardVariables}
        borrowerType={selectedProduct.borrowerType}
      />
    );
  };

  const renderQuestionTooltip = () => (
    <>
      <p>The information displayed when your team views an</p>
      <p>application. Every tab includes the standard header.</p>
    </>
  );

  const renderSubHeaderActions = () => (
    <ButtonWithImage
      className={clsx(
        styles.displayHeaderPreviewButton,
        displayApplicationHeaderPreview && styles.activatedHeaderPreviewButton,
      )}
      title={displayApplicationHeaderPreview ? 'Hide Header Preview' : 'Show Header Preview'}
      kind="topArrow"
      onClick={() => setDisplayApplicationHeaderPreview(!displayApplicationHeaderPreview)}
    />
  );

  return (
    <div className={styles.container}>
      <ProductConfigurationSubHeader
        className={styles.subheader}
        renderQuestionTooltip={renderQuestionTooltip}
        renderActions={renderSubHeaderActions}
        title="Application Views"
      />
      <HeightTransitionComponent useHeightRecalculation animateOnMount={false}>
        {displayApplicationHeaderPreview && renderApplicationHeaderPreview()}
      </HeightTransitionComponent>
      <ApplicationSetupBody
        selectedProduct={selectedProduct}
        applicationTabs={tabs}
        onTabAdd={handleTabAdd}
        onTabDelete={handleTabDelete}
        onTabSelect={handleTabSelect}
        onTabUpdate={handleTabUpdate}
        selectedTab={selectedTab || null}
        showLoadingMask={isBlockingUpdateInProgress}
      />
    </div>
  );
};

export default ApplicationSetup;
