import { AnyAction } from 'redux';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { Variable } from 'Variables/VariablesTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  ApplicationVariableConfiguration,
  UpdateApplicationVariableConfigurationParams,
} from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import { BulkUpdateVariableConfigurationParams } from 'api/LoanOriginationSystem/Types';
import { BulkUpdateVariableConfigurationsActionOrigin } from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

export interface GetApplicationVariableConfigurationsAction extends AnyAction {
  type: VariablesConfigurationActionType.GetApplicationVariableConfigurations;
  payload: {
    productId: string;
  };
}

export interface GetApplicationVariableConfigurationsSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.GetApplicationVariableConfigurationsSuccess;
  payload: {
    configurations: ApplicationVariableConfiguration[];
  };
}

export interface CreateApplicationVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateApplicationVariableConfiguration;
  payload: {
    column: number;
    position: number;
    variable: Variable;
    productId: string;
  };
}

export interface CreateApplicationVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateApplicationVariableConfigurationSuccess;
  payload: {
    configuration: ApplicationVariableConfiguration;
  };
}

export interface UpdateApplicationVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.UpdateApplicationVariableConfiguration;
  payload: {
    params: UpdateApplicationVariableConfigurationParams;
    id: string;
  };
}

export interface UpdateApplicationVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.UpdateApplicationVariableConfigurationSuccess;
  payload: {
    configuration: ApplicationVariableConfiguration;
  };
}

export interface DeleteApplicationVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteApplicationVariableConfiguration;
  payload: {
    id: string;
  };
}

export interface DeleteApplicationVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteApplicationVariableConfigurationSuccess;
}

export interface BulkUpdateApplicationVariableConfigurationsAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurations;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export interface BulkUpdateApplicationVariableConfigurationsSuccessAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsSuccess;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export const getApplicationVariableConfigurations = (
  productId: string,
): GetApplicationVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.GetApplicationVariableConfigurations,
  payload: {
    productId,
  },
});

export const getApplicationVariableConfigurationsSuccess = (
  configurations: ApplicationVariableConfiguration[],
): GetApplicationVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.GetApplicationVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const createApplicationVariableConfiguration = (
  variable: Variable,
  productId: string,
  position: number,
  column: number,
): CreateApplicationVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.CreateApplicationVariableConfiguration,
  payload: {
    variable,
    productId,
    position,
    column,
  },
});

export const createApplicationVariableConfigurationSuccess = (
  configuration: ApplicationVariableConfiguration,
): CreateApplicationVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.CreateApplicationVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const updateApplicationVariableConfiguration = (
  id: string,
  params: UpdateApplicationVariableConfigurationParams,
): UpdateApplicationVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.UpdateApplicationVariableConfiguration,
  payload: {
    id,
    params,
  },
});

export const updateApplicationVariableConfigurationSuccess = (
  configuration: ApplicationVariableConfiguration,
): UpdateApplicationVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.UpdateApplicationVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const deleteApplicationVariableConfiguration = (id: string): DeleteApplicationVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.DeleteApplicationVariableConfiguration,
  payload: {
    id,
  },
});

export const deleteApplicationVariableConfigurationSuccess = (): DeleteApplicationVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.DeleteApplicationVariableConfigurationSuccess,
});

export const bulkUpdateApplicationVariableConfigurations = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateApplicationVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurations,
  payload: {
    configurations,
  },
});

export const bulkUpdateApplicationVariableConfigurationsSuccess = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateApplicationVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const getApplicationVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.GetApplicationVariableConfigurationsFailure,
);
export const createApplicationVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.CreateApplicationVariableConfigurationFailure,
);
export const updateApplicationVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.UpdateApplicationVariableConfigurationFailure,
);
export const deleteApplicationVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.DeleteApplicationVariableConfigurationFailure,
);
export const bulkUpdateApplicationVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsFailure,
);
