import React, { FC } from 'react';
import Button from 'components/Button';
import { DigifiProduct } from 'components/RouteWithPermissions/Types';
import styles from './RestrictOrganizationPermissions.module.scss';
import { useHistory } from 'react-router-dom';
import LoanOriginationSystemImage from 'static/images/loan-origination-system.png';
import DecisionEngineImage from 'static/images/automated-decision-engine.png';
import { AppRoutes } from 'routes/RouteService';

export interface RestrictOrganizationPermissionsProps {
  product: DigifiProduct;
}

const ProductTitle: Record<DigifiProduct, string> = {
  [DigifiProduct.DecisionEngine]: 'Decision Engine',
  [DigifiProduct.LoanOriginationSystem]: 'Loan Origination System',
};

const ProductImage: Record<DigifiProduct, string> = {
  [DigifiProduct.DecisionEngine]: DecisionEngineImage,
  [DigifiProduct.LoanOriginationSystem]: LoanOriginationSystemImage,
};

const RestrictOrganizationPermissions: FC<RestrictOrganizationPermissionsProps> = ({ product }) => {
  const history = useHistory();

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h2 className={styles.title}>{ProductTitle[product]}</h2>
        <p className={styles.info}>
          This product is not active for your company. Please contact your account administrator to request access.
        </p>
        <Button kind="primary" size="form" onClick={() => history.push(AppRoutes.Home)}>
          Go to Dashboard
        </Button>
      </div>
      <div className={styles.image}>
        <img src={ProductImage[product]} alt={ProductTitle[product]} />
      </div>
    </div>
  );
};

export default RestrictOrganizationPermissions;
