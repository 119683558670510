import React, { ReactElement } from 'react';

interface TableContentBodyProps<RowElement> {
  renderSkeletonTableRow: (index: number) => ReactElement;
  renderTableRow: (element: RowElement, index: number) => ReactElement;
  rows: Array<RowElement | null>; // null means data is loading
}

const TableBodyContent = <RowElement extends object>({
  renderSkeletonTableRow,
  renderTableRow,
  rows,
}: TableContentBodyProps<RowElement>) => {
  return (
    <>
      {rows.map((rowElement, index) => {
        if (rowElement === null) {
          return renderSkeletonTableRow(index);
        }
        return renderTableRow(rowElement, index);
      })}
    </>
  );
};

export default TableBodyContent;
