import React, { useEffect, useState } from 'react';
import { useAuthProvider } from 'providers/AuthProvider';
import AuthLayout from 'components/Auth/AuthLayout';
import CreateAccountInviteForm from 'components/Auth/CreateAccountInviteForm';
import { useParams } from 'react-router';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'routes/RouteService';
import InvitationLinkExpired from 'pages/InvitationLinkExpired';

interface CreateAccountInviteParams {
  token: string;
}

const CreateAccountInvite = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const { push } = useHistory();
  const params = useParams<CreateAccountInviteParams>();
  const [resetPasswordToken, setResetPasswordToken] = useState<string | null>(null);
  const [hasLinkExpired, setHasLinkExpired] = useState(false);

  const onSuccess = async (notificationText: string) => {
    notification.createNotification(notificationText, 'success', dispatch);
    await authProvider.logout();
    push(AppRoutes.SignIn);
  };

  const handleSubmit = async (password: string) => {
    // The button is only drawn if reset password token has been requested
    await authProvider.resetPassword(password, resetPasswordToken!);
    await onSuccess('Your account has been created. Please, sign in.')
  };

  const acceptInvite = async () => {
    try {
      const token = await authProvider.acceptInvite(params.token);

      // If no token has been received, it means a user account already exists for this email.
      // In this case we don't prompt for the password, but rather redirect to Sign In right away.
      if (!token) {
        await onSuccess('Invite accepted. Please, sign in.');
      } else {
        setResetPasswordToken(token);
      }
    } catch (error) {
      setHasLinkExpired(true);
    }
  };

  useEffect(() => {
    acceptInvite();
  }, []);

  const handleSignInClick = () => {
    push(AppRoutes.SignIn);
  };

  if (hasLinkExpired) {
    return <InvitationLinkExpired onSignInClick={handleSignInClick} />;
  }

  return (
    <AuthLayout
      title="Create Your Account"
      subTitle={resetPasswordToken ? 'Set your password to accept your invitation' : ''}
    >
      {resetPasswordToken && <CreateAccountInviteForm onSubmit={handleSubmit} />}
    </AuthLayout>
  );
};

export default CreateAccountInvite;
