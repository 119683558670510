import React from 'react';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SelectVariablesTableSizes from 'components/LoanOriginationSystem/EditApplication/DeleteConfirmationPopup/SelectVariablesTable/SelectVariablesTableSizes';

const SelectVariablesTableRowSkeleton = () => (
  <TableRow disableHover>
    <TableBodyCell width={SelectVariablesTableSizes.CHECKBOX}>
      <SkeletonRectangle color="primary6" width="50%" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={SelectVariablesTableSizes.NAME}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={SelectVariablesTableSizes.VALUE}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SelectVariablesTableRowSkeleton;
