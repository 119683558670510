import searchApplicationsMiddleware from 'LoanOriginationSystemApplications/SearchApplicationsMiddleware';
import searchIntermediariesMiddleware from 'LoanOriginationSystemIntermediariesPage/SearchIntermediariesMiddleware';
import filterApplicationsByMembersMiddleware from 'LoanOriginationSystemApplications/FilterApplicationsByMembersMiddleware';
import GetFilterLabelsMiddleware from 'LoanOriginationSystemApplications/Filters/GetFilterLabelsMiddleware';
import GetFiltersIntermediariesMiddleware from 'LoanOriginationSystemApplications/Filters/GetFiltersIntermediariesMiddleware';
import onFiltersChangeMiddleware from 'LoanOriginationSystemApplications/Filters/OnFiltersChangeMiddleware';
import searchBorrowersMiddleware from 'LoanOriginationSystemBorrowers/SearchBorrowersMiddleware';
import searchLabelsMiddleware from 'LoanOriginationSystemLabelsPage/SearchLabelsMiddleware';
import searchTasksMiddleware from 'LoanOriginationSystemTasks/SearchTasksMiddleware';
import searchEmailsMiddleware from 'LoanOriginationSystemEmails/SearchEmailsMiddleware';
import onTasksFiltersChangeMiddleware from 'LoanOriginationSystemTasks/Filters/OnFiltersChangeMiddleware';
import onIntermediariesFiltersChangeMiddleware from 'LoanOriginationSystemIntermediariesPage/Filters/OnFiltersChangeMiddleware';
import onBorrowersFiltersChangeMiddleware from 'LoanOriginationSystemBorrowers/Filters/OnFiltersChangeMiddleware';
import onTemplatesFiltersChangeMiddleware from 'SendGrid/Filters/OnFiltersChangeMiddleware';
import onEmailsFiltersChangeMiddleware from 'LoanOriginationSystemEmails/Filters/OnFiltersChangeMiddleware';
import filterTasksByMembersMiddleware from 'LoanOriginationSystemTasks/Filters/FilterTasksByMembersMiddleware';
import filterIntermediariesByMembersMiddleware from 'LoanOriginationSystemIntermediariesPage/Filters/FilterIntermediariesByMembersMiddleware';
import filterBorrowersByMembersMiddleware from 'LoanOriginationSystemBorrowers/Filters/FilterBorrowersByMembersMiddleware';
import filterTemplatesByMembersMiddleware from 'SendGrid/Filters/FilterTemplatesByMembersMiddleware';
import LoanOriginationSystemLabelsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import LoanOriginationSystemIntermediariesRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import clearAllFiltersMiddleware from 'LoanOriginationSystemApplications/Filters/ClearAllFiltersMiddleware';
import { TemplatesSearchMiddleware } from 'SendGrid/Middlewares';
import responseHandlers from 'api/ResponseHandlers';

const loanOriginationSystemLabelsApi = new LoanOriginationSystemLabelsRestApi(responseHandlers);
const loanOriginationSystemIntermediariesRestApi = new LoanOriginationSystemIntermediariesRestApi(
  responseHandlers,
);

const getProductLabelsMiddleware = GetFilterLabelsMiddleware(loanOriginationSystemLabelsApi);
const getProductIntermediariesMiddleware = GetFiltersIntermediariesMiddleware(
  loanOriginationSystemIntermediariesRestApi,
);

export default [
  searchApplicationsMiddleware,
  searchIntermediariesMiddleware,
  filterApplicationsByMembersMiddleware,
  getProductLabelsMiddleware,
  getProductIntermediariesMiddleware,
  onFiltersChangeMiddleware,
  searchBorrowersMiddleware,
  searchLabelsMiddleware,
  searchTasksMiddleware,
  TemplatesSearchMiddleware,
  onTasksFiltersChangeMiddleware,
  onIntermediariesFiltersChangeMiddleware,
  onBorrowersFiltersChangeMiddleware,
  onTemplatesFiltersChangeMiddleware,
  onEmailsFiltersChangeMiddleware,
  filterTasksByMembersMiddleware,
  filterIntermediariesByMembersMiddleware,
  filterBorrowersByMembersMiddleware,
  filterTemplatesByMembersMiddleware,
  searchEmailsMiddleware,
  clearAllFiltersMiddleware,
];
