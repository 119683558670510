import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { ErrorNotificationIcon, SuccessNotificationIcon } from 'static/images';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { VariableAdvancedDataTypeAttributes } from 'Variables/VariablesTypes';
import { MultipleRuleValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { ApplicationStatusMultipleRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { DerivedComparisonsTypes } from 'components/RuleRow/Types';
import formatValueByDataType from 'utils/formatValueByDataType';
import styles from './ApplicationStatusRuleValidationResult.module.scss';

export interface ApplicationStatusRuleValidationResult {
  rule: ApplicationStatusMultipleRule;
  ruleValidationResult: MultipleRuleValidationResult;
}

const formatStatusRuleValue = (
  variableValue: VariableValue | null | undefined,
  sourceVariableData: VariableAdvancedDataTypeAttributes,
) => {
  const formattedValue = formatValueByDataType(variableValue, sourceVariableData);

  if (formattedValue === null) {
    return 'Null';
  }

  if (formattedValue === undefined) {
    return 'Not Defined';
  }

  return formattedValue;
};

const ApplicationStatusRuleValidationResult = ({
  rule,
  ruleValidationResult,
}: ApplicationStatusRuleValidationResult) => {
  const statusRuleValidationResultStyle = clsx(
    styles.validationResult,
    ruleValidationResult.valid ? styles.success : styles.failed,
  );

  const renderIcon = () => {
    if (ruleValidationResult.valid) {
      return <SuccessNotificationIcon />;
    }

    return <ErrorNotificationIcon />;
  };

  const getRuleValue = () => {
    const formattedFirstOperandValue = formatStatusRuleValue(
      ruleValidationResult.firstOperandValue,
      rule.firstOperandVariable || rule.sourceVariable,
    );
    const formattedSecondOperandValue = formatStatusRuleValue(
      ruleValidationResult.secondOperandValue,
      rule.secondOperandVariable || rule.sourceVariable,
    );

    if (rule.comparisonOperator === 'RANGE') {
      return `${formattedFirstOperandValue} to ${formattedSecondOperandValue}`;
    }

    return formattedFirstOperandValue;
  };

  const renderRuleValueVariables = () => {
    if (rule.firstOperandType !== ComparisonOperandType.VARIABLE) {
      return null;
    }

    if (rule.comparisonOperator === 'RANGE') {
      return (
        <>
          {rule.firstOperandVariable?.displayName} to {rule.secondOperandVariable?.displayName}
        </>
      );
    }

    return <>{rule.firstOperandVariable?.displayName}</>;
  };

  const renderLine = (header: ReactNode, content: ReactNode) => (
    <div className={styles.line}>
      <p>{header}</p>
      <p>{content}</p>
    </div>
  );

  const renderRuleValueLine = () => {
    if (rule.comparisonOperator === 'IS NULL' || rule.comparisonOperator === 'IS NOT NULL') {
      return null;
    }

    return renderLine(
      'Rule Value',
      <>
        {getRuleValue()}
        <br />
        {renderRuleValueVariables()}
      </>,
    );
  };

  return (
    <div className={statusRuleValidationResultStyle}>
      <h6 className={styles.sourceVariableName}>{rule.sourceVariable.displayName}</h6>
      <div>
        {renderLine(
          'Value',
          <b className={styles.bold}>{formatStatusRuleValue(ruleValidationResult.sourceValue, rule.sourceVariable)}</b>,
        )}
        {renderLine('Comparison', DerivedComparisonsTypes[rule.comparisonOperator])}
        {renderRuleValueLine()}
      </div>
      <div className={styles.resultIcon}>{renderIcon()}</div>
    </div>
  );
};

export default ApplicationStatusRuleValidationResult;
