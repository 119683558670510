import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';

import { ACTION_TYPE_CREATE_VARIABLE_SUCCESS, ACTION_TYPE_EDIT_VARIABLE_SUCCESS } from './ActionTypes';

import notification from 'handlers/notification/notificationActionCreator';
import { CreateVariableActionOrigin, getVariablesRequest } from './VariablesActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { RoutineHandler } from 'middlewares/Fetcher';

export const isCreateVariableSuccessAction = (action: AnyAction) => action.type === ACTION_TYPE_CREATE_VARIABLE_SUCCESS;
export const isEditVariableSuccessAction = (action: AnyAction) => action.type === ACTION_TYPE_EDIT_VARIABLE_SUCCESS;
const isDefaultCreateVariableSuccessAction = (action: ActionWithOrigin<CreateVariableActionOrigin>) =>
  isCreateVariableSuccessAction(action) && action.meta?.actionOrigin === CreateVariableActionOrigin.Default;

export const notifyOnSuccessSaveVariableMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isEditVariableSuccessAction(action) || isCreateVariableSuccessAction(action)) {
    const messageType =
      action.type === ACTION_TYPE_CREATE_VARIABLE_SUCCESS ? MessageType.VariableCreated : MessageType.VariableUpdated;

    notification.createNotification(
      getMessage(messageType, {
        variableName: action.payload.variable?.display_title || action.payload.variableName,
      }),
      'success',
      dispatch,
    );
  }

  return result;
};

export const onDefaultSaveVariableSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (isEditVariableSuccessAction(action) || isDefaultCreateVariableSuccessAction(action)) {
      dispatch(getVariablesRequest());
    }
  },
);
