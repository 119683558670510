import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { History } from 'history';
import notification from 'handlers/notification/notificationActionCreator';
import { LoanOriginationSystemCreateIntermediaryTypes } from './ActionTypes';
import { getIntermediaries } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import { CreateIntermediarySuccessAction } from './ActionCreator';

const CREATE_INTERMEDIARY_SUCCESS_MESSAGE = 'New intermediary has been created.';

const isCreateIntermediarySuccessAction = (action: AnyAction): action is CreateIntermediarySuccessAction =>
  action.type === LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediarySuccess;

const CreateIntermediarySuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateIntermediarySuccessAction(action)) {
    replace(`/los/intermediaries`);

    const { loanOriginationSystemIntermediariesState } = getState();

    const { filters, sortingType } = loanOriginationSystemIntermediariesState;

    notification.createNotification(CREATE_INTERMEDIARY_SUCCESS_MESSAGE, 'success', dispatch);

    dispatch(getIntermediaries(filters, sortingType));
  }

  return result;
};

export default CreateIntermediarySuccessMiddleware;
