import { ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  AutoLoanRefinancingProductTypeImage,
  CreditCardProductTypeImage,
  HomeEquityLoanProductTypeImage,
  HomeImprovementLoanProductTypeImage,
  MortgageProductTypeImage,
  PersonalLoanProductTypeImage,
  PointOfSaleFinancingProductTypeImage,
  CustomProductTypeImage,
  BusinessLoanProductTypeImage,
  CommercialLoanProductTypeImage,
} from 'static/images';

export const baseProductTypeOptions = {
  [ProductType.Custom]: { name: 'Custom', image: CustomProductTypeImage },
};

export const personProductTypeOptions = {
  ...baseProductTypeOptions,
  [ProductType.CreditCard]: { name: 'Credit Card', image: CreditCardProductTypeImage },
  [ProductType.PersonalLoan]: { name: 'Personal Loan', image: PersonalLoanProductTypeImage },
  [ProductType.AutoLoanRefinancing]: { name: 'Auto Loan Refinancing', image: AutoLoanRefinancingProductTypeImage },
  [ProductType.Mortgage]: { name: 'Mortgage', image: MortgageProductTypeImage },
  [ProductType.HomeEquityLoan]: { name: 'Home Equity Loan', image: HomeEquityLoanProductTypeImage },
  [ProductType.HomeImprovementLoan]: { name: 'Home Improvement Loan', image: HomeImprovementLoanProductTypeImage },
  [ProductType.PointOfSaleFinancing]: { name: 'Point-Of-Sale Financing', image: PointOfSaleFinancingProductTypeImage },
};

export const companyProductTypeOptions = {
  ...baseProductTypeOptions,
  [ProductType.SmallBusinessLoan]: { name: 'Small Business Loan', image: BusinessLoanProductTypeImage },
  [ProductType.CommercialLoan]: {
    name: 'Commercial Loan',
    type: ProductType.CommercialLoan,
    image: CommercialLoanProductTypeImage,
  },
};

export const productTypeOptions = {
  ...personProductTypeOptions,
  ...companyProductTypeOptions,
};
