import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import React from 'react';
import { VERSIONS_TABLE_COLUMNS_SIZES } from 'components/VersionsView/VersionsTableView/TableColumnSizes';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import styles from './SkeletonVersionTableRow.module.scss';

const SkeletonVersionTableRow = () => (
  <TableRow disableHover className={styles.row}>
    <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.VERSION}>
      <SkeletonRectangle width="37px" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.STATUS}>
      <SkeletonRectangle width="63px" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.UPDATED}>
      <SkeletonCircle width="28px" color="primary6" height="28px" />
      <SkeletonRectangle width="142px" color="primary6" height="16px" marginLeft="6px" />
    </TableBodyCell>
    <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.DESCRIPTION}>
      <SkeletonRectangle width="290px" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonVersionTableRow;
