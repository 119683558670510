import { VariablesFiltersActionTypes } from './ActionTypes';
import { AnyAction } from 'redux';
import { VisualDataType } from 'Variables/VariablesTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface FiltersResult {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  onlyStandard: boolean;
  onlyCustom: boolean;
  dataType: VisualDataType | '';
}

export interface SetSearchInputValueAction extends AnyAction {
  type: VariablesFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchValue: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: VariablesFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: VariablesFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface ResetAllVariablesFilters extends AnyAction {
  type: VariablesFiltersActionTypes.ResetAllFilters;
}

export interface FilterVariablesByMembersAction extends AnyAction {
  type: VariablesFiltersActionTypes.FilterVariablesByMembers;
  payload: {
    members: UserInfo[];
  };
}

export const setSearchInputValue = (searchValue: string): SetSearchInputValueAction => {
  return {
    type: VariablesFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchValue,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: VariablesFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: VariablesFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: VariablesFiltersActionTypes.ChangePopupFilters,
    payload: {
      dueCreatedDateRange: {
        from: null,
        to: null,
      },
      dueUpdatedDateRange: {
        from: null,
        to: null,
      },
      onlyStandard: false,
      onlyCustom: false,
      dataType: '',
    },
  };
};

export const resetAllVariablesFilters = (): ResetAllVariablesFilters => {
  return {
    type: VariablesFiltersActionTypes.ResetAllFilters,
  };
};

export const filterVariablesByMembers = (members: UserInfo[]): FilterVariablesByMembersAction => ({
  type: VariablesFiltersActionTypes.FilterVariablesByMembers,
  payload: {
    members,
  },
});
