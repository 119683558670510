import React, { useMemo } from 'react';
import ApiRequestTemplatePopup from 'components/LoanOriginationSystem/ApiRequestTemplatePopup';
import { IntermediaryApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

export interface IntermediaryApiRequestTemplatePopupProps {
  onDownload: (requestType: IntermediaryApiRequestTemplateType) => void;
  onClose: () => void;
  isDownloadInProgress?: boolean;
}

enum IntermediaryApiRequestInputType {
  RequestType = 'requestType',
}

const IntermediaryApiRequestsTemplatePopup = ({
  onClose,
  onDownload,
  isDownloadInProgress,
}: IntermediaryApiRequestTemplatePopupProps) => {
  const apiRequestInputs = useMemo(
    () => [
      {
        label: 'API Request Type',
        type: IntermediaryApiRequestInputType.RequestType,
        options: [
          {
            name: 'Create Intermediary',
            value: IntermediaryApiRequestTemplateType.CreateIntermediary,
          },
          {
            name: 'Update Intermediary',
            value: IntermediaryApiRequestTemplateType.UpdateIntermediary,
          },
        ],
      },
    ],
    [],
  );

  const handleDownload = (values: Record<string, string>) => {
    onDownload(values[IntermediaryApiRequestInputType.RequestType] as IntermediaryApiRequestTemplateType);
  };

  return (
    <ApiRequestTemplatePopup
      inputs={apiRequestInputs}
      title="Download API Request Template"
      onClose={onClose}
      onDownload={handleDownload}
      isDownloadInProgress={isDownloadInProgress}
    />
  );
};

export default IntermediaryApiRequestsTemplatePopup;
