import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  GetIntermediaryToDeleteApplicationsSuccessData,
  GetIntermediaryToDeleteApplicationsAction,
  getIntermediaryToDeleteApplicationsSuccess,
  getIntermediaryToDeleteApplicationsFailed,
} from './ActionCreator';
import { INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/IntermediaryDetails/pagination';
import { GET_INTERMEDIARY_TO_DELETE_APPLICATIONS } from './ActionTypes';
import { LoanOriginationSystemIntermediariesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

const GetIntermediaryToDeleteApplicationsMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<GetIntermediaryToDeleteApplicationsSuccessData, GetIntermediaryToDeleteApplicationsAction, ReduxState>(
    GET_INTERMEDIARY_TO_DELETE_APPLICATIONS,
    getIntermediaryToDeleteApplicationsSuccess,
    getIntermediaryToDeleteApplicationsFailed,
    async ({ payload: { id } }, state) => {
      const { applicationsSortingType } = state.loanOriginationSystemIntermediaryDetails;
      const applications = await api.getIntermediaryApplications(
        id,
        {
          offset: 0,
          count: INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT,
        },
        applicationsSortingType,
      );

      return applications;
    },
  );

export default GetIntermediaryToDeleteApplicationsMiddleware;
