import { MinifiedPermissions, prettifySystemSectionAccessPermissions } from 'SessionInfo/Utils';
import { ProductSectionAccessPermissionsMap } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import base64ToObject from 'utils/base64ToObject';

const PERMISSIONS_HEADER = 'x-digifi-permissions';

const getPermissionsInfo = (response: Response): ProductSectionAccessPermissionsMap | null => {
  const permissionsHeaderValue = response.headers.get(PERMISSIONS_HEADER);

  if (permissionsHeaderValue) {
    const parsedValue = base64ToObject<MinifiedPermissions>(permissionsHeaderValue);
    const permissions = prettifySystemSectionAccessPermissions(parsedValue);
    return permissions;
  }

  return null;
};

export default getPermissionsInfo;
