import { FILTER_APPLICATIONS_BY_MEMBERS } from './Filters/ActionTypes';
import Debouncer from 'middlewares/Debouncer';
import { getProductDataByViewType } from 'LoanOriginationSystemApplications/ActionCreator';
import { FilterApplicationsByMembersActionPayload } from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { ViewType } from 'LoanOriginationSystemApplications/Types';

export default Debouncer<FilterApplicationsByMembersActionPayload>(
  (payload, getState) => {
    const { loanOriginationSystemApplications } = getState();
    const { filters, tableViewSortingType, columnViewSortingType } = loanOriginationSystemApplications;

    return getProductDataByViewType(
      payload.productId,
      payload.viewType,
      filters,
      payload.viewType === ViewType.Column ? columnViewSortingType : tableViewSortingType,
    );
  },
  [FILTER_APPLICATIONS_BY_MEMBERS],
);
