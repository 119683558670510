import { getProductDataByViewType } from 'LoanOriginationSystemApplications/ActionCreator';
import { ClearAllFiltersAction } from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { ApplicationsActionType } from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';

const isClearAllFiltersAction = (action: AnyAction): action is ClearAllFiltersAction =>
  action.type === ApplicationsActionType.ClearAllFilters;

const clearAllFiltersMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isClearAllFiltersAction(action)) {
    const { loanOriginationSystemApplications } = getState();

    const { filters } = loanOriginationSystemApplications;

    dispatch(
      getProductDataByViewType(
        loanOriginationSystemApplications.selectedProduct!.id,
        action.payload.viewType,
        {
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
          selectedIntermediaries: filters.selectedIntermediaries,
          selectedStatusesIds: filters.selectedStatusesIds,
          selectedLabels: filters.selectedLabels,
          selectedMembers: filters.selectedMembers,
          searchInputValue: filters.searchInputValue,
        },
        action.payload.viewType === ViewType.Column
          ? loanOriginationSystemApplications.columnViewSortingType
          : loanOriginationSystemApplications.tableViewSortingType,
      ),
    );
  }

  return result;
}) as Middleware;

export default clearAllFiltersMiddleware;
