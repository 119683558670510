import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/Types';
import React, { useMemo, useState, FocusEvent } from 'react';
import { PopperProps } from '@material-ui/core';
import ConfigurableForm from 'components/ConfigurableForm';
import { removeWhiteSpace } from 'utils/validation/validation';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import shouldShowSuggestions from './shouldShowSuggestions';
import SuggestionsPopper, {
  SuggestionItem,
} from 'components/LoanOriginationSystem/CreateApplication/BorrowerSuggestionsPopper';
import { getFormattedIntermediaryPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import { IntermediarySuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import styles from './NewIntermediaryForm.module.scss';
import { StandardVariables } from 'Variables/VariablesTypes';

interface NewIntermediaryFormProps {
  intermediaryData: FormLayoutData;
  configurations: VariableConfiguration[];
  standardVariables: StandardVariables;
  suggestions: SuggestionItem[];
  onChange: (formData: FormLayoutData) => void;
  onLoadSuggestions?: (filter: IntermediarySuggestionFilter) => void;
  onSuggestionSelect: (suggestionItem: SuggestionItem) => void;
}

const VARIABLE_NAMES_WITH_SUGGESTIONS = [
  IntermediaryDefaultVariable.Name,
  IntermediaryDefaultVariable.Email,
  IntermediaryDefaultVariable.PhoneNumber,
];

const NewIntermediaryForm = ({
  configurations,
  intermediaryData,
  suggestions,
  standardVariables,
  onChange,
  onLoadSuggestions,
  onSuggestionSelect,
}: NewIntermediaryFormProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionAnchorElement, setSuggestionAnchorElement] = useState<PopperProps['anchorEl']>(null);

  const formattedSuggestions = useMemo(() => {
    return suggestions.map((suggestion) => {
      return {
        ...suggestion,
        phone: getFormattedIntermediaryPhoneNumber(suggestion.phone, standardVariables),
      };
    });
  }, [suggestions]);

  const emitOnLoadSuggestion = (filter: IntermediarySuggestionFilter) => {
    if (shouldShowSuggestions(filter) && suggestionAnchorElement) {
      onLoadSuggestions?.(filter);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const getSuggestionFilter = (data: FormLayoutData) =>
    ({
      email: data[IntermediaryDefaultVariable.Email],
      phoneNumber: data[IntermediaryDefaultVariable.PhoneNumber],
      name: data[IntermediaryDefaultVariable.Name],
    } as IntermediarySuggestionFilter);

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    const newFormData = {
      ...intermediaryData,
      [variable.systemName]: removeWhiteSpace(value) as string,
    };

    onChange(newFormData);

    if (VARIABLE_NAMES_WITH_SUGGESTIONS.includes(variable.systemName)) {
      emitOnLoadSuggestion(getSuggestionFilter(newFormData));
    }
  };

  const handleFieldFocus = ({ variable }: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => {
    if (VARIABLE_NAMES_WITH_SUGGESTIONS.includes(variable.systemName)) {
      setSuggestionAnchorElement(event.target);
    }
  };

  const onClosePopper = () => {
    setShowSuggestions(false);
  };

  return (
    <div className={styles.container}>
      <ConfigurableForm
        configurations={configurations}
        data={intermediaryData}
        onFieldChange={handleFieldChange}
        onFieldFocus={handleFieldFocus}
        hideCustomFields
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
      />
      <SuggestionsPopper
        placement="bottom-start"
        className={styles.popper}
        suggestions={formattedSuggestions}
        anchorEl={suggestionAnchorElement}
        open={suggestions && showSuggestions}
        onClose={onClosePopper}
        onSelectSuggestion={onSuggestionSelect}
      />
    </div>
  );
};

export default NewIntermediaryForm;
