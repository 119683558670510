import Fetcher from 'middlewares/Fetcher';
import { AutomatedProcessesApi, AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import { GetAutomatedProcessesAction, getAutomatedProcessesFailure, getAutomatedProcessesSuccess } from './ActionCreator';
import { ProcessAutomationActionType } from './ActionTypes';
import { TableViewData } from 'api/Types';

const GetAutomatedProcessesMiddleware = (api: AutomatedProcessesApi) =>
  Fetcher<TableViewData<AutomatedProcess>, GetAutomatedProcessesAction>(
    ProcessAutomationActionType.GetAutomatedProcesses,
    getAutomatedProcessesSuccess,
    getAutomatedProcessesFailure,
    (action) => api.getAutomatedProcesses({
      offset: action.payload.itemsPerPage * (action.payload.page - 1),
      count: action.payload.itemsPerPage,
      group: action.payload.group,
      category: action.payload.category,
    }),
  );

export default GetAutomatedProcessesMiddleware;
