import Fetcher from 'middlewares/Fetcher';
import { ApplicationTabsApi } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';
import { deleteApplicationTabSuccess, deleteApplicationTabFailure, DeleteApplicationTabAction } from './ActionCreator';

const DeleteApplicationTabMiddleware = (api: ApplicationTabsApi) =>
  Fetcher<string, DeleteApplicationTabAction>(
    LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTab,
    deleteApplicationTabSuccess,
    deleteApplicationTabFailure,
    async (action) => {
      await api.delete(action.payload.id);

      return action.payload.id;
    },
  );

export default DeleteApplicationTabMiddleware;
