import React, { useMemo } from 'react';
import {
  ApplicationStatus,
  DefaultApplicationStatusName,
  PermissionsField,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import ApplicationStatusesList, {
  SkeletonApplicationStatusesList,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationWorkflowSetup/ApplicationStatusesList';
import ApplicationStatusCard, {
  SkeletonApplicationStatusCard,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationWorkflowSetup/ApplicationStatusCard';
import LoadingMask from 'components/LoadingMask';
import ButtonWithImage from 'components/ButtonWithImage';
import ApplicationWorkflowSetupTab from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationWorkflowSetup/ApplicationWorkflowSetupTab';
import styles from './Body.module.scss';

export interface BodyProps {
  selectedTab: ApplicationWorkflowSetupTab;
  statuses: ApplicationStatus[] | null;
  statusUpdateIds: string[];
  onEditDeclineReasons: () => void;
  onApplicationStatusCreate: () => void;
  onApplicationStatusReorder: (index: number, sourceIndex: number) => void;
  onApplicationStatusDelete: (status: ApplicationStatus) => void;
  onApplicationStatusNameUpdate: (statusId: string, name: string) => Promise<void>;
  onApplicationStatusPermissionsChange: (
    statusId: string,
    permissionsField: PermissionsField,
    permissions: UserRoleName[],
  ) => void;
  onApplicationStatusRuleAdd: (status: ApplicationStatus) => void;
  onApplicationStatusRuleEdit: (statusRule: ApplicationStatusRule) => void;
  onApplicationStatusRuleDelete: (status: ApplicationStatus, rule: ApplicationStatusRule) => void;
  isLoading: boolean;
}

const Body = ({
  selectedTab,
  isLoading,
  statusUpdateIds,
  statuses,
  onEditDeclineReasons,
  onApplicationStatusPermissionsChange,
  onApplicationStatusNameUpdate,
  onApplicationStatusReorder,
  onApplicationStatusDelete,
  onApplicationStatusCreate,
  onApplicationStatusRuleAdd,
  onApplicationStatusRuleEdit,
  onApplicationStatusRuleDelete,
}: BodyProps) => {
  const { approvedStatus, rejectedStatus, customStatuses } = useMemo(() => {
    if (!statuses) {
      return {};
    }

    return {
      approvedStatus: statuses.find((status) => status.name === DefaultApplicationStatusName.Approved),
      rejectedStatus: statuses.find((status) => status.name === DefaultApplicationStatusName.Rejected),
      customStatuses: statuses.filter(
        (status) => !Object.values(DefaultApplicationStatusName).includes(status.name as DefaultApplicationStatusName),
      ),
    };
  }, [statuses]);

  const getTitle = () => {
    switch (selectedTab) {
      case ApplicationWorkflowSetupTab.ProcessingStatuses: {
        return 'Processing Statuses';
      }
      case ApplicationWorkflowSetupTab.FinalStatuses: {
        return 'Final Statuses';
      }
      default: {
        return '';
      }
    }
  };

  const renderStatuses = () => {
    if (selectedTab === ApplicationWorkflowSetupTab.ProcessingStatuses) {
      return (
        <>
          {customStatuses ? (
            <ApplicationStatusesList
              statusUpdateIds={statusUpdateIds}
              statuses={customStatuses || null}
              onApplicationStatusPermissionsChange={onApplicationStatusPermissionsChange}
              onApplicationStatusNameUpdate={onApplicationStatusNameUpdate}
              onApplicationStatusCreate={onApplicationStatusCreate}
              onApplicationStatusReorder={onApplicationStatusReorder}
              onApplicationStatusDelete={onApplicationStatusDelete}
              onApplicationStatusRuleAdd={onApplicationStatusRuleAdd}
              onApplicationStatusRuleEdit={onApplicationStatusRuleEdit}
              onApplicationStatusRuleDelete={onApplicationStatusRuleDelete}
            />
          ) : (
            <SkeletonApplicationStatusesList />
          )}
        </>
      );
    }

    return (
      <div className={styles.defaultStatuses}>
        {approvedStatus ? (
          <ApplicationStatusCard
            className={styles.defaultStatusCard}
            isUpdating={statusUpdateIds.includes(approvedStatus.id)}
            status={approvedStatus!}
            onPermissionsChange={onApplicationStatusPermissionsChange}
            onStatusRuleAdd={onApplicationStatusRuleAdd}
            onStatusRuleEdit={onApplicationStatusRuleEdit}
            onStatusRuleDelete={onApplicationStatusDelete}
          />
        ) : (
          <SkeletonApplicationStatusCard className={styles.defaultStatusCard} statusOptionsCount={4} />
        )}
        {rejectedStatus ? (
          <ApplicationStatusCard
            className={styles.defaultStatusCard}
            isUpdating={statusUpdateIds.includes(rejectedStatus.id)}
            actions={renderDeclineReasonsButton()}
            status={rejectedStatus!}
            onPermissionsChange={onApplicationStatusPermissionsChange}
            onStatusRuleAdd={onApplicationStatusRuleAdd}
            onStatusRuleEdit={onApplicationStatusRuleEdit}
            onStatusRuleDelete={onApplicationStatusDelete}
          />
        ) : (
          <SkeletonApplicationStatusCard className={styles.defaultStatusCard} />
        )}
      </div>
    );
  };

  const renderDeclineReasonsButton = () => (
    <ButtonWithImage
      className={styles.editDeclineReasonsButton}
      title="Edit Decline Reasons"
      onClick={onEditDeclineReasons}
      kind="edit"
    />
  );

  return (
    <div className={styles.container}>
      <h5 className={styles.statusesHeader}>{getTitle()}</h5>
      {renderStatuses()}
      {isLoading && <LoadingMask background="light" />}
    </div>
  );
};

export default Body;
