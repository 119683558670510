const SIZES = {
  FIRST_NAME: 13,
  LAST_NAME: 12,
  EMAIL: 19,
  PERMISSIONS_TYPE: 12,
  PHONE_AUTHENTICATION: 16,
  EMAIL_VERIFIED: 11,
  ACCOUNT_STATUS: 10,
};

export default SIZES;
