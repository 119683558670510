import React from 'react';
import { SkeletonText } from 'components/Skeleton';
import styles from './DocuSignCredentialsSkeleton.module.scss';

const PRIVATE_KEY_SKELETON_LINES = 6;
const DEFAULT_LINES_COUNT = 1;

const DocuSignCredentialsSkeleton = () => {
  const renderSkeletonCredentialLine = (linesCount = DEFAULT_LINES_COUNT) => {
    return (
      <div className={styles.skeletonCredentialLine}>
        <div>
          <SkeletonText lineHeight="44px" width="160px" height="16px" color="primary6" />
        </div>
        <div>
          {new Array(linesCount).fill(null).map((value, index) => (
            <SkeletonText
              key={index}
              lineHeight="28px"
              width={linesCount === DEFAULT_LINES_COUNT ? '300px' : '100%'}
              height="16px"
              color="primary6"
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderSkeletonCredentialLine()}
      {renderSkeletonCredentialLine()}
      {renderSkeletonCredentialLine()}
      {renderSkeletonCredentialLine(PRIVATE_KEY_SKELETON_LINES)}
    </div>
  );
};

export default DocuSignCredentialsSkeleton;
