import { nanoid } from 'nanoid';
import { VariableItem } from 'api/VariablesType';
import { BulkUpdateVariableConfigurationParams, VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import { EditVariableSuccessAction } from 'Variables/VariablesActionCreator';
import { ACTION_TYPE_EDIT_VARIABLE_SUCCESS } from 'Variables/ActionTypes';
import { VariablesConfigurationState } from './Types';
import { VariablesConfigurationActionType } from './ActionTypes';
import {
  BulkUpdateBorrowerVariableConfigurationsSuccessAction,
  BulkUpdateIntermediaryVariableConfigurationsSuccessAction,
  CreateBorrowerVariableConfigurationAction,
  CreateBorrowerVariableConfigurationSuccessAction,
  CreateIntermediaryVariableConfigurationAction,
  CreateIntermediaryVariableConfigurationSuccessAction,
  DeleteBorrowerVariableConfigurationAction,
  DeleteIntermediaryVariableConfigurationAction,
  GetBorrowerVariableConfigurationsSuccessAction,
  GetIntermediaryVariableConfigurationsSuccessAction,
  UpdateBorrowerVariableConfigurationAction,
  UpdateBorrowerVariableConfigurationSuccessAction,
  UpdateIntermediaryVariableConfigurationAction,
  UpdateIntermediaryVariableConfigurationSuccessAction,
} from './ActionCreator';
import getVariableAttributes from 'utils/getVariableAttributes';
import normalizeEntityArray from 'utils/normalizeEntityArray';
import {
  BulkUpdateApplicationVariableConfigurationsSuccessAction,
  CreateApplicationVariableConfigurationAction,
  CreateApplicationVariableConfigurationSuccessAction,
  DeleteApplicationVariableConfigurationAction,
  DeleteApplicationVariableConfigurationSuccessAction,
  GetApplicationVariableConfigurationsSuccessAction,
  UpdateApplicationVariableConfigurationAction,
  UpdateApplicationVariableConfigurationSuccessAction,
} from './ApplicationVariableConfigurationsActionCreator';

export type VariablesConfigurationActions =
  | EditVariableSuccessAction
  | GetBorrowerVariableConfigurationsSuccessAction
  | GetIntermediaryVariableConfigurationsSuccessAction
  | GetApplicationVariableConfigurationsSuccessAction
  | CreateIntermediaryVariableConfigurationAction
  | CreateIntermediaryVariableConfigurationSuccessAction
  | CreateApplicationVariableConfigurationAction
  | CreateApplicationVariableConfigurationSuccessAction
  | UpdateIntermediaryVariableConfigurationAction
  | UpdateIntermediaryVariableConfigurationSuccessAction
  | UpdateApplicationVariableConfigurationAction
  | UpdateApplicationVariableConfigurationSuccessAction
  | DeleteApplicationVariableConfigurationAction
  | DeleteApplicationVariableConfigurationSuccessAction
  | BulkUpdateApplicationVariableConfigurationsSuccessAction
  | DeleteIntermediaryVariableConfigurationAction
  | CreateBorrowerVariableConfigurationAction
  | CreateBorrowerVariableConfigurationSuccessAction
  | UpdateBorrowerVariableConfigurationAction
  | UpdateBorrowerVariableConfigurationSuccessAction
  | DeleteBorrowerVariableConfigurationAction
  | BulkUpdateBorrowerVariableConfigurationsSuccessAction
  | BulkUpdateIntermediaryVariableConfigurationsSuccessAction;

const getNewVariableConfiguration = <Configuration extends VariableConfiguration>(
  variableConfiguration: Configuration,
  variable: VariableItem,
) => {
  if (variableConfiguration.variable.id === variable.id) {
    return {
      ...variableConfiguration,
      variable: {
        id: variable.id,
        displayName: variable.displayName,
        name: variable.name,
        systemName: variable.systemName,
        updateDate: variable.updatedat,
        createDate: variable.createdat,
        createdBy: variable.createdBy,
        updatedBy: variable.updatedBy,
        description: variable.description,
        dependsOn: variable.dependsOn,
        ...getVariableAttributes(variable),
      },
    };
  }

  if (variableConfiguration.variable.dependsOn?.id === variable.id) {
    return {
      ...variableConfiguration,
      variable: {
        ...variableConfiguration.variable,
        ...getVariableAttributes(variable),
        dependsOn: {
          id: variable.id,
          systemName: variable.systemName,
          displayName: variable.displayName,
        },
      },
    };
  }

  return variableConfiguration;
};

const filterConfigurationEntities = <Configuration extends VariableConfiguration>(
  configurationsById: Record<string, Configuration>,
  filter: (value: VariableConfiguration) => boolean,
) => {
  return normalizeEntityArray(Object.values(configurationsById).filter(filter));
};

const updateVariableForVariableConfigurationsById = <Configuration extends VariableConfiguration>(
  variableConfigurationsById: Record<string, Configuration>,
  variable: VariableItem,
) => {
  return Object.values(variableConfigurationsById).reduce((newVariableConfigurationsById, variableConfiguration) => {
    return {
      ...newVariableConfigurationsById,
      [variableConfiguration.id]: getNewVariableConfiguration(variableConfiguration, variable),
    };
  }, {} as Record<string, Configuration>);
};

const bulkUpdateVariableConfigurations = <Configuration extends VariableConfiguration>(
  configurations: BulkUpdateVariableConfigurationParams[],
  variableConfigurationsById: Record<string, Configuration>,
) => {
  return configurations.reduce((configurationsById, configuration) => {
    const existingConfiguration = variableConfigurationsById[configuration.id];

    if (!existingConfiguration) {
      return configurationsById;
    }

    return {
      ...configurationsById,
      [configuration.id]: {
        ...existingConfiguration,
        ...configuration,
      },
    };
  }, variableConfigurationsById);
};

export const initialState: VariablesConfigurationState = {
  borrowerVariableConfigurationsById: {},
  intermediaryVariableConfigurationsById: {},
  applicationVariableConfigurationsById: {},
};

const variablesConfigurationReducer = (
  state: VariablesConfigurationState = initialState,
  action: VariablesConfigurationActions,
): VariablesConfigurationState => {
  switch (action.type) {
    case VariablesConfigurationActionType.GetBorrowerVariableConfigurationsSuccess: {
      return {
        ...state,
        borrowerVariableConfigurationsById: {
          ...state.borrowerVariableConfigurationsById,
          ...normalizeEntityArray(action.payload.configurations),
        },
      };
    }
    case VariablesConfigurationActionType.GetIntermediaryVariableConfigurationsSuccess: {
      return {
        ...state,
        intermediaryVariableConfigurationsById: {
          ...state.intermediaryVariableConfigurationsById,
          ...normalizeEntityArray(action.payload.configurations),
        },
      };
    }
    case VariablesConfigurationActionType.GetApplicationVariableConfigurationsSuccess: {
      return {
        ...state,
        applicationVariableConfigurationsById: {
          ...state.applicationVariableConfigurationsById,
          ...normalizeEntityArray(action.payload.configurations),
        },
      };
    }
    case VariablesConfigurationActionType.CreateIntermediaryVariableConfiguration: {
      const tempId = nanoid();

      return {
        ...state,
        intermediaryVariableConfigurationsById: {
          ...state.intermediaryVariableConfigurationsById,
          [tempId]: {
            id: nanoid(),
            column: action.payload.column,
            position: action.payload.position,
            variable: action.payload.variable,
          },
        },
      };
    }
    case VariablesConfigurationActionType.CreateIntermediaryVariableConfigurationSuccess: {
      const { configuration } = action.payload;

      return {
        ...state,
        intermediaryVariableConfigurationsById: {
          ...filterConfigurationEntities(
            state.intermediaryVariableConfigurationsById,
            ({ position, column }) => !(position === configuration.position && column === configuration.column),
          ),
          [configuration.id]: configuration,
        },
      };
    }
    case VariablesConfigurationActionType.CreateApplicationVariableConfiguration: {
      const tempId = nanoid();

      return {
        ...state,
        applicationVariableConfigurationsById: {
          ...state.applicationVariableConfigurationsById,
          [tempId]: {
            id: tempId,
            column: action.payload.column,
            position: action.payload.position,
            variable: action.payload.variable,
            productId: action.payload.productId,
          },
        },
      };
    }
    case VariablesConfigurationActionType.CreateApplicationVariableConfigurationSuccess: {
      const { configuration } = action.payload;

      return {
        ...state,
        applicationVariableConfigurationsById: {
          ...filterConfigurationEntities(
            state.applicationVariableConfigurationsById,
            ({ position, column, productId }) => {
              return !(
                position === configuration.position &&
                column === configuration.column &&
                productId === configuration.productId
              );
            },
          ),
          [configuration.id]: configuration,
        },
      };
    }
    case VariablesConfigurationActionType.UpdateApplicationVariableConfiguration: {
      return {
        ...state,
        applicationVariableConfigurationsById: {
          ...state.applicationVariableConfigurationsById,
          [action.payload.id]: {
            ...state.applicationVariableConfigurationsById[action.payload.id],
            ...action.payload.params,
          },
        },
      };
    }
    case VariablesConfigurationActionType.UpdateApplicationVariableConfigurationSuccess: {
      return {
        ...state,
        applicationVariableConfigurationsById: {
          ...state.applicationVariableConfigurationsById,
          [action.payload.configuration.id]: action.payload.configuration,
        },
      };
    }
    case VariablesConfigurationActionType.DeleteApplicationVariableConfiguration: {
      return {
        ...state,
        applicationVariableConfigurationsById: filterConfigurationEntities(
          state.applicationVariableConfigurationsById,
          ({ id }) => id !== action.payload.id,
        ),
      };
    }
    case VariablesConfigurationActionType.UpdateIntermediaryVariableConfiguration: {
      return {
        ...state,
        intermediaryVariableConfigurationsById: {
          ...state.intermediaryVariableConfigurationsById,
          [action.payload.id]: {
            ...state.intermediaryVariableConfigurationsById[action.payload.id],
            ...action.payload.params,
          },
        },
      };
    }
    case VariablesConfigurationActionType.UpdateIntermediaryVariableConfigurationSuccess: {
      return {
        ...state,
        intermediaryVariableConfigurationsById: {
          ...state.intermediaryVariableConfigurationsById,
          [action.payload.configuration.id]: action.payload.configuration,
        },
      };
    }
    case VariablesConfigurationActionType.DeleteBorrowerVariableConfiguration: {
      return {
        ...state,
        borrowerVariableConfigurationsById: filterConfigurationEntities(
          state.borrowerVariableConfigurationsById,
          ({ id }) => id !== action.payload.id,
        ),
      };
    }
    case VariablesConfigurationActionType.DeleteIntermediaryVariableConfiguration: {
      return {
        ...state,
        intermediaryVariableConfigurationsById: filterConfigurationEntities(
          state.intermediaryVariableConfigurationsById,
          ({ id }) => id !== action.payload.id,
        ),
      };
    }
    case VariablesConfigurationActionType.CreateBorrowerVariableConfiguration: {
      const tempId = nanoid();

      return {
        ...state,
        borrowerVariableConfigurationsById: {
          ...state.borrowerVariableConfigurationsById,
          [tempId]: {
            id: tempId,
            column: action.payload.column,
            position: action.payload.position,
            variable: action.payload.variable,
            borrowerType: action.payload.borrowerType,
          },
        },
      };
    }
    case VariablesConfigurationActionType.CreateBorrowerVariableConfigurationSuccess: {
      const { configuration } = action.payload;

      return {
        ...state,
        borrowerVariableConfigurationsById: {
          ...filterConfigurationEntities(
            state.borrowerVariableConfigurationsById,
            ({ position, column }) => !(position === configuration.position && column === configuration.column),
          ),
          [configuration.id]: configuration,
        },
      };
    }
    case VariablesConfigurationActionType.UpdateBorrowerVariableConfiguration: {
      return {
        ...state,
        borrowerVariableConfigurationsById: {
          ...state.borrowerVariableConfigurationsById,
          [action.payload.id]: {
            ...state.borrowerVariableConfigurationsById[action.payload.id],
            ...action.payload.params,
          },
        },
      };
    }
    case VariablesConfigurationActionType.UpdateBorrowerVariableConfigurationSuccess: {
      return {
        ...state,
        borrowerVariableConfigurationsById: {
          ...state.borrowerVariableConfigurationsById,
          [action.payload.configuration.id]: action.payload.configuration,
        },
      };
    }
    case VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsSuccess: {
      return {
        ...state,
        borrowerVariableConfigurationsById: bulkUpdateVariableConfigurations(
          action.payload.configurations,
          state.borrowerVariableConfigurationsById,
        ),
      };
    }
    case VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsSuccess: {
      return {
        ...state,
        intermediaryVariableConfigurationsById: bulkUpdateVariableConfigurations(
          action.payload.configurations,
          state.intermediaryVariableConfigurationsById,
        ),
      };
    }
    case VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsSuccess: {
      return {
        ...state,
        applicationVariableConfigurationsById: bulkUpdateVariableConfigurations(
          action.payload.configurations,
          state.applicationVariableConfigurationsById,
        ),
      };
    }
    case ACTION_TYPE_EDIT_VARIABLE_SUCCESS: {
      const { variable, meta } = action.payload;

      if (!meta) {
        return state;
      }

      return {
        ...state,
        intermediaryVariableConfigurationsById: updateVariableForVariableConfigurationsById(
          state.intermediaryVariableConfigurationsById,
          variable,
        ),
        borrowerVariableConfigurationsById: updateVariableForVariableConfigurationsById(
          state.borrowerVariableConfigurationsById,
          variable,
        ),
        applicationVariableConfigurationsById: updateVariableForVariableConfigurationsById(
          state.applicationVariableConfigurationsById,
          variable,
        ),
      };
    }
    default: {
      return state;
    }
  }
};

export default variablesConfigurationReducer;
