import React, { FC } from 'react';
import styles from './RulesListTableFooter.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';

interface TableFooter {
  addButtonTitle: string;
  addRule: () => void;
}

const TableFooter: FC<TableFooter> = ({ addButtonTitle, addRule }) => (
  <div className={styles.footer}>
    <ButtonWithImage title={addButtonTitle} kind="add" onClick={addRule} className={styles.addButton} />
  </div>
);

export default TableFooter;
