export const CREATE_NEW_MODULE_REQUEST = 'CREATE_NEW_MODULE_REQUEST';
export const CREATE_NEW_MODULE_REQUEST_FAILURE = 'CREATE_NEW_MODULE_REQUEST_FAILURE';

export const MODULE_ADDED_SUCCESS = 'MODULE_ADDED_SUCCESS';

export const UPDATE_MODULE_INFO_REQUEST = 'UPDATE_MODULE_INFO_REQUEST';
export const UPDATE_MODULE_INFO_REQUEST_SUCCESS = 'UPDATE_MODULE_INFO_REQUEST_SUCCESS';
export const UPDATE_MODULE_INFO_REQUEST_FAILURE = 'UPDATE_MODULE_INFO_REQUEST_FAILURE';

export const UPDATE_MODULE_NAME = 'UPDATE_MODULE_NAME';
export const UPDATE_MODULE_NAME_SUCCESS = 'UPDATE_MODULE_NAME_SUCCESS';
export const UPDATE_MODULE_NAME_FAILURE = 'UPDATE_MODULE_NAME_FAILURE';
