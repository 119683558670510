import { AnyAction } from 'redux';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';
import { ApplicationDecisionRunsDataState, DecisionRunsSortingField } from 'ApplicationDecisionRuns/types';
import applicationDecisionRunsPagination from 'components/LoanOriginationSystem/ApplicationTabs/DecisionEngineTab/Pagination';
import withStateReset from 'utils/reducers/withStateReset';

const ITEMS_PER_PAGE_DEFAULT = 20;

const initialState: ApplicationDecisionRunsDataState = {
  itemsTotal: undefined,
  items: [],
  page: 1,
  loaded: false,
  itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
  sortingType: { field: DecisionRunsSortingField.Date, ascending: false },
};

const applicationDecisionRunsReducer = (state = initialState, action: AnyAction): ApplicationDecisionRunsDataState => {
  switch (action.type) {
    case ApplicationDecisionRunActionType.GetApplicationDecisionRunsSuccess: {
      return {
        ...state,
        ...action.payload,
        loaded: true,
      };
    }
    case ApplicationDecisionRunActionType.Sort: {
      return {
        ...state,
        sortingType: {
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationDecisionRunsPagination.wrapReducer<ApplicationDecisionRunsDataState>(
  withStateReset(applicationDecisionRunsReducer, ApplicationDecisionRunActionType.ResetState, () => initialState),
);
