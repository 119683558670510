import React, { FC } from 'react';
import styles from 'components/RuleRow/RuleRow.module.scss';
import { ConditionOutput, MultipleRule, RuleData } from 'BranchInfo/Types';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import clsx from 'clsx';
import { DragImage } from 'static/images';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { getConditionOutputValue, getVariableDisplayValue } from './utils';
import { DuplicateRuleRequestData } from 'RuleCreate/Types';
import { useQueryParams } from 'hooks/useQueryParam';
import { DerivedComparisonsTypes } from './Types';
import { useVariables } from 'hooks/useVariableDataType';
import SkeletonRuleLine from 'components/RulesTable/SkeletonRuleLine';

interface TableRowProps {
  rule: RuleData;
  strategyId: string;
  hideComparison?: boolean;
  showConditionOutput?: boolean;
  rowClassName?: string;
  variableCellClass?: string;
  valueCellClass?: string;
  comparisonCellClass?: string;
  outputCellClass?: string;
  isDragging?: boolean;
  moduleType?: ModuleType;
  type: 'ruleset' | 'conditions';
  openDeleteRulePopUp: () => void;
  openEditRulePopUp: () => void;
  duplicateRule: (params: DuplicateRuleRequestData) => void;
}

const RuleRow: FC<TableRowProps> = ({
  rule,
  rowClassName,
  variableCellClass,
  valueCellClass,
  comparisonCellClass,
  outputCellClass,
  hideComparison,
  showConditionOutput,
  isDragging,
  moduleType,
  openDeleteRulePopUp,
  openEditRulePopUp,
  type,
  duplicateRule,
  strategyId,
}) => {
  const { multipleRules, conditionOutput, type: ruleModuleType, ruleType, id } = rule;
  const params = useQueryParams();

  const tableRowClasses = clsx({
    [styles.ruleRow]: !rowClassName,
    ...(rowClassName ? { [rowClassName]: !!rowClassName } : {}),
    [styles.dragging]: isDragging,
  });
  const moduleKey = params.get('module-branch-key')!;
  const branchIndex = parseInt(params.get('branchIndex')!, 10);

  const handleDuplicateRule = () => {
    duplicateRule({
      strategyId,
      ruleId: id,
      moduleKey,
      branchIndex,
      ruleType: type,
    });
  };

  const variableIds: (string | undefined)[] = [];

  for (const { sourceVariableId, operandVariableId } of conditionOutput) {
    variableIds.push(sourceVariableId, operandVariableId);
  }

  for (const { sourceVariableId, firstOperandVariableId, secondOperandVariableId } of multipleRules) {
    variableIds.push(sourceVariableId, firstOperandVariableId, secondOperandVariableId);
  }

  const { isLoading, getVariable } = useVariables(...variableIds);

  if (isLoading) {
    return <SkeletonRuleLine moduleType={moduleType!} className={styles.skeletonContainer} />;
  }

  const renderRule = (simpleRule: MultipleRule, index: number, rules: MultipleRule[]) => {
    const { selectedComparison, sourceVariableId, firstOperandVariableId, secondOperandVariableId } = simpleRule;
    const sourceVariableData = getVariable(sourceVariableId);
    const firstOperandVariableData = getVariable(firstOperandVariableId!);
    const secondOperandVariableData = getVariable(secondOperandVariableId!);
    const variableDisplayValue =
      ruleModuleType === ModuleType.CalculationScripts
        ? simpleRule.firstOperandScript
        : getVariableDisplayValue(
            simpleRule,
            sourceVariableData!,
            firstOperandVariableData!,
            secondOperandVariableData!,
          );

    return (
      <div key={`${id}_${index}`}>
        <div className={styles.ruleContainer}>
          <div className={variableCellClass || styles.variable}>{sourceVariableData?.displayName}</div>
          {selectedComparison && !hideComparison && (
            <div className={comparisonCellClass || styles.comparison}>
              {DerivedComparisonsTypes[selectedComparison]}
            </div>
          )}
          <div className={valueCellClass || styles.value}>{variableDisplayValue}</div>
        </div>
        {index !== rules.length - 1 && <span className={styles.rulesSeparator}>{ruleType}</span>}
      </div>
    );
  };

  return (
    <RowActionsContainer>
      <div className={tableRowClasses} onClick={openEditRulePopUp}>
        <div className={styles.dragImage}>
          <DragImage />
        </div>
        <div className={styles.conditionContainer}>{multipleRules.map(renderRule)}</div>
        {showConditionOutput && (
          <div className={styles.outputsContainer}>
            {conditionOutput.map((output: ConditionOutput, index: number) => {
              const { sourceVariableId, operandVariableId } = output;
              // showConditionOutput is true only for scorecard/output/requirements modules
              // only output module should have sourceVariableId and may have operandVariableId
              const sourceVariableData = sourceVariableId ? getVariable(sourceVariableId) : undefined;
              const operandVariableData = operandVariableId ? getVariable(operandVariableId) : undefined;

              const value = getConditionOutputValue(output, ruleModuleType, sourceVariableData, operandVariableData);

              return (
                <p key={index} className={outputCellClass || styles.output}>
                  {value}
                </p>
              );
            })}
          </div>
        )}
        <div className={styles.actionsButton}>
          <RowActions
            actions={[
              {
                title: 'Duplicate Rule',
                handler: () => handleDuplicateRule(),
                danger: false,
                separatorRequired: true,
              },
              {
                title: 'Delete Rule',
                handler: openDeleteRulePopUp,
                danger: true,
              },
            ]}
          />
        </div>
        {!isDragging && (
          <p className={styles.rowsSeparator}>{moduleType === ModuleType.ScoringModel ? 'plus' : 'and'}</p>
        )}
      </div>
    </RowActionsContainer>
  );
};

export default RuleRow;
