export enum ApplicationDecisionRunActionType {
  GetApplicationDecisionRuns = 'applicationDecisionRuns/getApplicationDecisionRuns',
  GetApplicationDecisionRunsSuccess = 'applicationDecisionRuns/getApplicationDecisionRunsSuccess',
  GetApplicationDecisionRunsFailure = 'applicationDecisionRuns/getApplicationDecisionRunsFailure',
  Sort = 'applicationDecisionRuns/sort',
  DeleteApplicationDecisionRun = 'applicationDecisionRuns/deleteApplicationDecisionRun',
  DeleteApplicationDecisionRunSuccess = 'applicationDecisionRuns/deleteApplicationDecisionRunSuccess',
  DeleteApplicationDecisionRunFailure = 'applicationDecisionRuns/deleteApplicationDecisionRunFailure',
  ResetState = 'applicationDecisionRuns/resetState',
}
