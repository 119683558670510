import React from 'react';
import styles from './TasksForm.module.scss';
import {
  TaskApplicationInfo,
  TasksFilterOptions,
  TaskStatus,
} from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { Option } from 'components/SelectInput/SelectInput';
import ButtonWithImage from 'components/ButtonWithImage';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import AutoCompletionMultiselect, { AutoCompletionProps } from 'components/AutoCompletionMultiselect';
import DatePicker from 'components/DatePicker/DatePicker';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import formatDisplayId from 'utils/userIdFormat';

const DESCRIPTION_MAX_LENGTH = 200;

const statusOptions = [
  { name: TasksFilterOptions.NotDone, value: TaskStatus.NotDone },
  { name: TasksFilterOptions.Done, value: TaskStatus.Done },
];

interface TasksFormProps {
  setDescription: (value: string) => void;
  selectedStatus: TaskStatus;
  setSelectedStatus: (done: TaskStatus) => void;
  members: UserInfo[];
  applications: TaskApplicationInfo[];
  selectedApplication: TaskApplicationInfo | null;
  setSelectedApplication: (application: TaskApplicationInfo | null) => void;
  dueDate: string;
  description: string;
  setDueDate: (dueDate: string) => void;
  membersProps: Omit<AutoCompletionProps, 'labelTitle' | 'id'>;
  lockApplication: boolean;
}

const ONLY_SPACES_REGEX = /^\s+$/;

const TasksForm = ({
  setDescription,
  selectedStatus,
  applications,
  setSelectedApplication,
  description,
  selectedApplication,
  dueDate,
  setDueDate,
  setSelectedStatus,
  membersProps,
  lockApplication,
}: TasksFormProps) => {
  const onApplicationChange = ({ value }: Option) => {
    const newApplication = value ? applications.find((application) => application.id === value) : null;

    setSelectedApplication(newApplication || null);
  };

  const onGoToApplicationClick = () => {
    window.open(`${window.location.origin}/los/applications/${selectedApplication!.displayId}`, '_blank');
  };

  const onStatusChange = (option: Option) => {
    setSelectedStatus(option.value as TaskStatus);
  };

  const getApplicationTitle = (application: TaskApplicationInfo) => {
    return `${getBorrowerFullName(application.borrowerType, application.variables)}`;
  };

  const applicationOptions = applications.map((application) => {
    return {
      name: `${getApplicationTitle(application)} (${formatDisplayId(application.displayId)})`,
      value: application.id,
    };
  });

  const getSelectedOption = (application: TaskApplicationInfo) => {
    return {
      name: `${getApplicationTitle(application)} (${formatDisplayId(application.displayId)})`,
      value: application.id,
    };
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (ONLY_SPACES_REGEX.test(event.target.value)) {
      setDescription('');
    } else {
      setDescription(event.target.value);
    }
  };

  return (
    <div>
      <TextInput
        value={description}
        labelTitle="Description"
        onChange={handleDescriptionChange}
        maxLength={DESCRIPTION_MAX_LENGTH}
      />
      <AutoCompletion
        labelTitle="Status"
        options={statusOptions}
        value={selectedStatus}
        onChange={onStatusChange}
        hideClearIcon
      />
      <AutoCompletionMultiselect id="teamMembers" labelTitle="Team Members" {...membersProps} />
      <div className={styles.applicationSelectInner}>
        <ButtonWithImage
          disabled={!selectedApplication}
          kind="link"
          title="Go To Application"
          className={styles.goToApplicationButton}
          onClick={onGoToApplicationClick}
        />
        <AutoCompletion
          labelTitle="Application"
          options={applicationOptions}
          selectedOption={selectedApplication ? getSelectedOption(selectedApplication) : undefined}
          onChange={onApplicationChange}
          disabled={lockApplication}
        />
        <DatePicker value={dueDate!} onChange={setDueDate} labelTitle="Due Date" />
      </div>
    </div>
  );
};

export default TasksForm;
