import { DocuSignFiltersState } from './Types';
import {
  ChangeFiltersAction,
  FilterTemplatesByMembersAction,
  ResetAllFiltersAction,
  SetSearchInputValueAction,
  ToggleFiltersPopupAction,
} from './ActionCreator';
import { DocuSignFiltersActionType } from './ActionTypes';

export const initialState: DocuSignFiltersState = {
  selectedMembers: [],
  selectedStatus: null,
  isFiltersPopupOpen: false,
  searchInputValue: '',
  createdDateRange: {
    from: null,
    to: null,
  },
  updatedDateRange: {
    from: null,
    to: null,
  },
};

export type DocuSignFiltersActions =
  | SetSearchInputValueAction
  | ResetAllFiltersAction
  | ChangeFiltersAction
  | FilterTemplatesByMembersAction
  | ToggleFiltersPopupAction;

const docuSignFiltersReducer = (
  state: DocuSignFiltersState = initialState,
  action: DocuSignFiltersActions,
): DocuSignFiltersState => {
  switch (action.type) {
    case DocuSignFiltersActionType.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.search,
      };
    }
    case DocuSignFiltersActionType.ResetAllFilters: {
      return {
        ...state,
        selectedMembers: [],
        selectedStatus: null,
        createdDateRange: {
          from: null,
          to: null,
        },
        updatedDateRange: {
          from: null,
          to: null,
        },
        isFiltersPopupOpen: false,
      };
    }
    case DocuSignFiltersActionType.ChangeFilters: {
      const { status, createdDateRange, updatedDateRange } = action.payload.params;

      return {
        ...state,
        selectedStatus: status,
        createdDateRange,
        updatedDateRange,
        isFiltersPopupOpen: false,
      };
    }
    case DocuSignFiltersActionType.FilterTemplatesByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }
    case DocuSignFiltersActionType.ToggleFiltersPopup: {
      return {
        ...state,
        isFiltersPopupOpen: !state.isFiltersPopupOpen,
      };
    }
    default: {
      return state;
    }
  }
};

export default docuSignFiltersReducer;
