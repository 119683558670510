import React, { FC } from 'react';
import styles from 'components/BranchConditions/BranchConditions.module.scss';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableActionCell from 'components/Table/TableActionCell';

const SkeletonConditionLine: FC = () => {
  return (
    <TableRow disableHover className={styles.skeletonRow}>
      <TableBodyCell className={styles.variable} noPadding>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell className={styles.comparison}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell className={styles.value}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonConditionLine;
