import React, { FC } from 'react';
import styles from './TableRow.module.scss';
import { ExternalIntegrationInputsList, ExternalIntegrationVariableType } from 'api/DecisionEngineStrategiesType';
import { Option } from 'components/SelectInput/SelectInput';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';

interface TableRowProps {
  variable: ExternalIntegrationInputsList;
  numberVariables: Option[];
  stringVariables: Option[];
  booleanVariables: Option[];
  dateVariables: Option[];
}

const TableRow: FC<TableRowProps> = ({
  variable,
  numberVariables,
  stringVariables,
  booleanVariables,
  dateVariables,
}) => {
  const { name, variable: variableValue, inputType, type } = variable;

  const getVariableName = () => {
    if (variableValue) {
      // eslint-disable-next-line default-case
      switch (type) {
        case ExternalIntegrationVariableType.String:
          return stringVariables.find((stringVariable) => stringVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Number:
          return numberVariables.find((numberVariable) => numberVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Boolean:
          return booleanVariables.find((booleanVariable) => booleanVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Date:
          return dateVariables.find((dateVariable) => dateVariable.value === variableValue)!.name;
      }
    }
    return '';
  };

  return (
    <div className={styles.ruleRow}>
      <div className={styles.conditionContainer}>
        <div className={styles.singleRuleContainer}>
          <div className={styles.ruleContainer}>
            <div className={styles.variable}>{name}</div>
            <div className={styles.value}>
              {inputType === ComparisonOperandType.VARIABLE ? getVariableName() : variableValue}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableRow;
