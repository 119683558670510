import React, { FC } from 'react';
import styles from 'components/Variables/Variables.module.scss';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import { SIZES } from 'components/Variables/TableColumnSizes';
import { VariablesSortingField, VariablesSortingType } from 'api/Core/VariablesApi';
import TableActionCell from 'components/Table/TableActionCell';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonVariableLine from 'components/Variables/SkeletonVariableLine';
import Pagination from 'components/Pagination';
import { Variable } from 'Variables/VariablesTypes';
import VariableLine from 'components/Variables/VariableLine';
import useSorting from 'hooks/useSorting';
import { NullableItems, PaginationProps } from 'pagination';

interface VariablesTableProps {
  variables: NullableItems<Variable>;
  onEditVariable: (variableId: string) => void;
  searchInputValue: string;
  openVariableStatusChangePopup: (id: string) => void;
  paginationProps: PaginationProps;
  currentVariableId: string | null;
  sortingType: VariablesSortingType;
  onSort: (field: VariablesSortingField, ascending: boolean) => void;
}

const VariablesTable: FC<VariablesTableProps> = ({
  variables,
  onEditVariable,
  openVariableStatusChangePopup,
  paginationProps,
  currentVariableId,
  sortingType,
  onSort,
  searchInputValue,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderRow = (variable: Variable) => (
    <VariableLine
      variable={variable}
      key={variable.id}
      onEdit={onEditVariable}
      openVariableStatusChangePopup={openVariableStatusChangePopup}
      searchInputValue={searchInputValue}
      isActive={currentVariableId === variable.id}
    />
  );

  return (
    <div>
      <div className={styles.variablesContent}>
        <div className={styles.variablesContent__table}>
          <TableHead>
            <TableHeadCell
              width={SIZES.DISPLAY_NAME}
              ascending={getSortingType(VariablesSortingField.DisplayName)}
              onClick={() => changeSorting(VariablesSortingField.DisplayName)}
            >
              Display Name
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.SYSTEM_NAME}
              ascending={getSortingType(VariablesSortingField.SystemName)}
              onClick={() => changeSorting(VariablesSortingField.SystemName)}
            >
              System Name
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.DATA_TYPE}
              ascending={getSortingType(VariablesSortingField.DataType)}
              onClick={() => changeSorting(VariablesSortingField.DataType)}
            >
              Data Type
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.VARIABLE_TYPE}
              ascending={getSortingType(VariablesSortingField.VariableType)}
              onClick={() => changeSorting(VariablesSortingField.VariableType)}
            >
              Variable Type
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.UPDATED}
              ascending={getSortingType(VariablesSortingField.UpdatedAt)}
              onClick={() => changeSorting(VariablesSortingField.UpdatedAt)}
            >
              Updated
            </TableHeadCell>
            <TableHeadCell width={SIZES.DESCRIPTION}>Description</TableHeadCell>
            <TableActionCell />
          </TableHead>
          <div className={styles.variablesContent__tableBody}>
            <TableBodyContent
              rows={variables}
              renderSkeletonTableRow={(index) => <SkeletonVariableLine key={index} />}
              renderTableRow={renderRow}
            />
          </div>
        </div>
      </div>
      <Pagination {...paginationProps} />
    </div>
  );
};

export default VariablesTable;
