import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { VariableConfiguration } from 'api/LoanOriginationSystem/Types';

interface BaseGetVariableConfigurationsParams {
  productId?: string;
}

interface GetBorrowerVariableConfigurationsSelectorParams extends BaseGetVariableConfigurationsParams {
  borrowerType?: BorrowerType;
}

const getBorrowerVariableConfigurationsById = (state: ReduxState) =>
  state.variablesConfiguration.borrowerVariableConfigurationsById;
const getBorrowerType = (_: ReduxState, params: GetBorrowerVariableConfigurationsSelectorParams) => params.borrowerType;
const getProductId = (_: ReduxState, params: BaseGetVariableConfigurationsParams) => params?.productId;

const getIntermediaryVariableConfigurationsById = (state: ReduxState) =>
  state.variablesConfiguration.intermediaryVariableConfigurationsById;

const getApplicationVariableConfigurationsById = (state: ReduxState) =>
  state.variablesConfiguration.applicationVariableConfigurationsById;

const filterByProductId = (variableConfiguration: VariableConfiguration, productId?: string) => {
  return productId ? variableConfiguration.productId === productId : !variableConfiguration.productId;
};

export const getBorrowerVariableConfigurationsSelector = createSelector(
  [getBorrowerVariableConfigurationsById, getBorrowerType, getProductId],
  (variableConfigurationsById, borrowerType, productId) => {
    if (!borrowerType) {
      return null;
    }

    const variableConfigurations = Object.values(variableConfigurationsById).filter((variableConfiguration) => {
      return variableConfiguration.borrowerType === borrowerType && filterByProductId(variableConfiguration, productId);
    });

    if (!variableConfigurations.length) {
      return null;
    }

    return variableConfigurations;
  },
);

export const getIntermediaryVariableConfigurationsSelector = createSelector(
  [getIntermediaryVariableConfigurationsById, getProductId],
  (variableConfigurationsById, productId) => {
    const variableConfigurations = Object.values(variableConfigurationsById).filter((variableConfiguration) => {
      return filterByProductId(variableConfiguration, productId);
    });

    if (!variableConfigurations.length) {
      return null;
    }

    return variableConfigurations;
  },
);

export const getApplicationVariableConfigurationsSelector = createSelector(
  [getApplicationVariableConfigurationsById, getProductId],
  (variableConfigurationsById, productId) => {
    if (!productId) {
      return null;
    }

    const variableConfigurations = Object.values(variableConfigurationsById).filter((variableConfiguration) => {
      return variableConfiguration.productId === productId;
    });

    if (!variableConfigurations.length) {
      return null;
    }

    return variableConfigurations;
  },
);
