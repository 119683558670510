import Fetcher from 'middlewares/Fetcher';
import { ApplicationStatusRule, ApplicationStatusRuleApi } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  updateApplicationStatusRuleSuccess,
  updateApplicationStatusRuleFailure,
  UpdateApplicationStatusRuleAction,
} from './ActionCreator';

const UpdateApplicationStatusRuleMiddleware = (api: ApplicationStatusRuleApi) =>
  Fetcher<ApplicationStatusRule, UpdateApplicationStatusRuleAction>(
    ApplicationStatusesActionType.UpdateApplicationStatusRule,
    updateApplicationStatusRuleSuccess,
    updateApplicationStatusRuleFailure,
    async (action) => {
      return api.update(action.payload.ruleId, action.payload.multipleRules);
    },
  );

export default UpdateApplicationStatusRuleMiddleware;
