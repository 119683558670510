import React from 'react';
import { RelatedApplicationsTableColumnSizes } from 'components/LoanOriginationSystem/RelatedApplicationsTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import styles from './SkeletonRelatedApplicationTableRow.module.scss';

const SkeletonRelatedApplicationTableRow = () => (
  <TableRow disableHover>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.BorrowerFullName}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.Product}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.Id}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.ApplicationDate}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.Status}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={RelatedApplicationsTableColumnSizes.LoanAmount} className={styles.flexEndJustify}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonRelatedApplicationTableRow;
