import React, { forwardRef } from 'react';
import clsx from 'clsx';
import Avatar, { TextSize } from 'components/Avatar';
import styles from './RestUsersAvatar.module.scss';

interface RestUsersAvatarProps {
  size?: TextSize;
  hasItemsSelected: boolean;
  usersCount: number;
  onClick?: () => void;
  className?: string;
  isActive?: boolean;
}

const RestUsersAvatar = forwardRef<HTMLDivElement, RestUsersAvatarProps>(
  ({ size, hasItemsSelected, usersCount, className, onClick, isActive }, ref) => {
    return (
      <Avatar
        size={size}
        className={clsx(
          styles.restUsersAvatar,
          hasItemsSelected && styles.hasItemsSelected,
          className,
          isActive && styles.activeAvatar,
        )}
        ref={ref}
        onClick={onClick}
      >
        <p>+{usersCount}</p>
      </Avatar>
    );
  },
);

export default RestUsersAvatar;
