import React from 'react';
import clsx from 'clsx';
import CustomCheckbox from 'components/CustomCheckbox';
import styles from './CheckboxMultiSelectList.module.scss';

export interface CheckboxMultiSelectListItem {
  id: string;
  label: React.ReactNode;
}

interface CheckboxMultiSelectListProps {
  items: CheckboxMultiSelectListItem[];
  selectedItemsIds: string[];
  disabledIds?: string[];
  onChange: (id: string) => void;
  className?: string;
  isDisabled?: (itemId: string) => boolean;
  listItemCheckboxClassName?: string;
  listItemLabelClassName?: string;
}

const CheckboxMultiSelectList = ({
  items,
  selectedItemsIds,
  isDisabled,
  onChange,
  className,
  listItemCheckboxClassName,
  listItemLabelClassName,
}: CheckboxMultiSelectListProps) => {
  const onCheck = (item: CheckboxMultiSelectListItem) => {
    onChange(item.id);
  };

  return (
    <div className={clsx(styles.list, className)}>
      {items.map((item) => (
        <CustomCheckbox
          className={listItemCheckboxClassName}
          labelClassName={listItemLabelClassName}
          key={item.id}
          disabled={isDisabled?.(item.id)}
          checked={selectedItemsIds.includes(item.id)}
          onChange={() => onCheck(item)}
          label={item.label}
        />
      ))}
    </div>
  );
};

export default CheckboxMultiSelectList;
