/* eslint @typescript-eslint/no-unused-vars: 0 */

import { ApplicationsSortingType, FormLayoutData, TableViewData, VariableValue } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerType, Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BaseLoanOriginationSystemApplicationsFilters } from 'LoanOriginationSystemApplications/Filters/Types';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import {
  convertToCoBorrowerVariable
} from 'enums/ApplicationDefaultVariable';
import { ApplicationStatus } from './LoanOriginationSystemApplicationStatusesApi';

export interface UpdateApplicationModel {
  id: string;
  borrower?: FormLayoutData;
  coborrower?: FormLayoutData;
  applicationDetails?: FormLayoutData;
  intermediary?: FormLayoutData;
}

export interface UserInfo {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  // TODO figure out when it's avatarUrl and when imageId
  avatarUrl?: string;
  active?: boolean;
  imageId?: string;
}

export interface Application {
  id: string;
  displayId: string;
  variables: Record<string, VariableValue>;
  status: ApplicationStatus;
  borrowerId: string;
  borrowerType: BorrowerType;
  coborrowerId?: string;
  coborrowerType?: BorrowerType;
  intermediaryId?: string;
  declineReasons?: string[];
  teamMembers: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl?: string;
  }[];
  labels: BaseLabelInfo[];
  createdAt: Date;
  updatedAt: Date;
  product: {
    id: string;
    name: string;
  };
  updatedBy?: UserInfo | null;
  createdBy?: UserInfo | null;
  rejectedAt?: Date;
  approvedAt?: Date;
  originalApplicationId?: string;
}

interface CountFilter {
  createdDateRange: BaseLoanOriginationSystemApplicationsFilters['createdDateRange'];
}

interface EditLabelsParams {
  labelsToAdd?: string[];
  labelsToDelete?: string[];
}

export interface LoanOriginationSystemApplicationsApi {
  getAll(
    productId?: string,
    filters?: BaseLoanOriginationSystemApplicationsFilters,
    sortingType?: ApplicationsSortingType,
  ): Promise<TableViewData<Application>>;
  getApplicationByDisplayId(displayId: string): Promise<Application>;
  changeApplicationStatus(id: string, statusId: string, declineReasons?: string[]): Promise<Application>;
  createApplication(
    productId: string,
    borrower: FormLayoutData | Borrower,
    coBorrower: FormLayoutData | Borrower | undefined,
    intermediaryData: FormLayoutData | undefined,
    applicationDetails: FormLayoutData,
  ): Promise<Application>;
  updateApplication(data: UpdateApplicationModel): Promise<Application>;
  updateApplicationVariables(applicationId: string, variables: FormLayoutData): Promise<Application>;
  updateApplicationTeamMembers(applicationId: string, teamMemberIds: string[]): Promise<Application>;
  deleteApplication(applicationId: string): Promise<void>;
  duplicateApplication(applicationId: string): Promise<Application>;
  deleteCoborrower(applicationId: string, variablesToDelete: string[]): Promise<Application>;
  deleteIntermediary(applicationId: string, variablesToDelete: string[]): Promise<Application>;
  addCoborrower(applicationId: string, coborrowerId?: string, coborrowerData?: FormLayoutData): Promise<Application>;
  addIntermediary(
    applicationId: string,
    intermediaryId?: string,
    intermediaryData?: FormLayoutData,
  ): Promise<Application>;
  editLabels(applicationId: string, params: EditLabelsParams): Promise<Application>;
  getCount(filters: CountFilter): Promise<number>;
}

export default class LoanOriginationSystemApplicationsApiRest extends LoanOriginationSystemApi<Application>
  implements LoanOriginationSystemApplicationsApi {
  protected resourceName = 'applications';

  public async getCount(filters: CountFilter) {
    const params = this.getPaginationUrlSearchParams({ count: 1, offset: 0 });

    if (filters?.createdDateRange.from) {
      params.append('createdAtFrom', filters.createdDateRange.from.toISOString());
    }

    if (filters?.createdDateRange.to) {
      params.append('createdAtTo', filters.createdDateRange.to.toISOString());
    }

    const { total } = await this.getResources<TableViewData<Application>>(params);

    return total;
  }

  public async getAll(
    productId?: string,
    filters?: BaseLoanOriginationSystemApplicationsFilters,
    sortingType?: ApplicationsSortingType,
  ) {
    const params = this.getPaginationUrlSearchParams(filters?.pagination, sortingType);

    if (productId) {
      params.set('productId', productId);
    }

    if (filters) {
      params.set('search', filters.searchInputValue);
      filters.selectedStatusesIds.forEach((statusId) => params.append('statusIds', statusId));
      filters.selectedLabels.forEach((label) => params.append('labelIds', label.id));
      filters.selectedIntermediaries.forEach((intermediary) => params.append('intermediaryIds', intermediary.id));
      filters.selectedMembers.forEach((member) => params.append('teamMemberIds', member.id));
    }

    if (filters?.createdDateRange.from) {
      params.append('createdAtFrom', filters.createdDateRange.from.toISOString());
    }

    if (filters?.createdDateRange.to) {
      params.append('createdAtTo', filters.createdDateRange.to.toISOString());
    }

    if (filters?.updatedDateRange.from) {
      params.append('updatedAtFrom', filters.updatedDateRange.from.toISOString());
    }

    if (filters?.updatedDateRange.to) {
      params.append('updatedAtTo', filters.updatedDateRange.to.toISOString());
    }

    if (filters?.variablesToInclude) {
      Object.values(filters.variablesToInclude).forEach((variable) => {
        params.append('variablesToInclude', variable);
      });
    }

    const applicationsTableData = await this.getResources<TableViewData<Application>>(params);

    return applicationsTableData;
  }

  public getApplicationByDisplayId(displayId: string): Promise<Application> {
    return this.getResourceById(displayId);
  }

  public changeApplicationStatus(id: string, statusId: string, declineReasons?: string[]): Promise<Application> {
    return this.updateResource(id, { application: { statusId, declineReasons } });
  }

  public createApplication(
    productId: string,
    borrowerData: FormLayoutData | Borrower,
    coborrowerData: FormLayoutData | Borrower | undefined,
    intermediaryData: FormLayoutData | undefined,
    applicationDetails: FormLayoutData,
  ) {
    const borrower = borrowerData.id ? null : { variables: borrowerData };
    const coborrower = !coborrowerData || coborrowerData.id ? null : { variables: coborrowerData };
    const intermediary = !intermediaryData || intermediaryData.id ? null : { variables: intermediaryData };

    const application = {
      productId,
      borrowerId: borrowerData.id,
      borrower,
      coborrowerId: coborrowerData?.id,
      coborrower,
      intermediary,
      intermediaryId: intermediaryData?.id,
      variables: applicationDetails,
    };

    return this.createResource<Application>({ application });
  }

  public updateApplication(data: UpdateApplicationModel): Promise<Application> {
    const application = {
      variables: {
        ...(data.borrower || {}),
        ...(data.intermediary || {}),
        ...Object.keys(data.coborrower || {}).reduce(
          (result, key) => ({
            ...result,
            [convertToCoBorrowerVariable(key)]: data.coborrower![key],
          }),
          {},
        ),
        ...(data.applicationDetails || {}),
      },
    };

    return this.updateResource(data.id, { application });
  }

  public updateApplicationVariables(applicationId: string, variables: FormLayoutData) {
    return this.updateResource(applicationId, {
      application: {
        variables,
      },
    });
  }

  public updateApplicationTeamMembers(applicationId: string, teamMemberIds: string[]) {
    return this.updateResource(applicationId, {
      application: {
        teamMemberIds,
      },
    });
  }

  public deleteApplication(applicationId: string) {
    return this.deleteResource(applicationId);
  }

  public duplicateApplication(applicationId: string) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/duplicate`, 'POST');
  }

  public deleteCoborrower(applicationId: string, variablesToDelete: string[]) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/coborrower`, 'DELETE', {
      variablesToDelete,
    });
  }

  public deleteIntermediary(applicationId: string, variablesToDelete: string[]) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/intermediary`, 'DELETE', {
      variablesToDelete,
    });
  }

  public addCoborrower(applicationId: string, coborrowerId?: string, coborrowerData?: FormLayoutData) {
    const coborrower = !coborrowerData || coborrowerData.id ? undefined : { variables: coborrowerData };

    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/coborrower`, 'PUT', {
      coborrowerId,
      coborrower,
    });
  }

  public addIntermediary(applicationId: string, intermediaryId?: string, intermediaryData?: FormLayoutData) {
    const intermediary = !intermediaryData || intermediaryData.id ? undefined : { variables: intermediaryData };

    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/intermediary`, 'PUT', {
      intermediaryId,
      intermediary,
    });
  }

  public editLabels(applicationId: string, params: EditLabelsParams) {
    return this.fetch<Application>(`/${this.resourceName}/${applicationId}/labels`, 'PUT', params);
  }
}
