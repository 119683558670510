import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import { closeDeleteEmailPopup, getEmails } from './ActionCreator';

const DeleteBorrowerSuccessMiddleware = (): Middleware => ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (action.type === LoanOriginationSystemEmailsActionTypes.DeleteEmailSuccess) {
    const { loanOriginationSystemEmails } = getState();
    const { filters, sortingType } = loanOriginationSystemEmails;

    notification.createNotification(
      getMessage(MessageType.EmailDeleted),
      'success',
      dispatch,
    );

    dispatch(closeDeleteEmailPopup());
    dispatch(getEmails(filters, sortingType));
  }

  return result;
};

export default DeleteBorrowerSuccessMiddleware;
