import {
  SetSearchInputValueAction,
  ChangePopupFiltersAction,
  ResetAllVariablesFilters,
  ToggleFiltersPopupAction,
  FilterVariablesByMembersAction,
} from './ActionCreator';
import { VariablesFiltersActionTypes } from './ActionTypes';
import { VariablesFiltersState } from './Types';

export const initialState: VariablesFiltersState = {
  searchInputValue: '',
  isPopupVisible: false,
  dueUpdatedDateRange: {
    from: null,
    to: null,
  },
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
  onlyCustom: false,
  onlyStandard: false,
  dataType: '',
  selectedMembers: [],
};

type VariablesFiltersActions =
  | SetSearchInputValueAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | ResetAllVariablesFilters
  | FilterVariablesByMembersAction;

export const variablesFiltersReducer = (
  state: VariablesFiltersState = initialState,
  action: VariablesFiltersActions,
): VariablesFiltersState => {
  switch (action.type) {
    case VariablesFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchValue,
      };
    }

    case VariablesFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }

    case VariablesFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        isPopupVisible: false,
        dueCreatedDateRange: action.payload.dueCreatedDateRange,
        dueUpdatedDateRange: action.payload.dueUpdatedDateRange,
        onlyCustom: action.payload.onlyCustom,
        onlyStandard: action.payload.onlyStandard,
        dataType: action.payload.dataType,
      };
    }

    case VariablesFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }

    case VariablesFiltersActionTypes.FilterVariablesByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }

    default: {
      return state;
    }
  }
};
