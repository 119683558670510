import Fetcher from 'middlewares/Fetcher';
import { ApplicationStatusRule, ApplicationStatusRuleApi } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  CreateApplicationStatusRuleAction,
  createApplicationStatusRuleSuccess,
  createApplicationStatusRuleFailure,
} from './ActionCreator';

const CreateApplicationStatusRuleMiddleware = (api: ApplicationStatusRuleApi) =>
  Fetcher<ApplicationStatusRule, CreateApplicationStatusRuleAction>(
    ApplicationStatusesActionType.CreateApplicationStatusRule,
    createApplicationStatusRuleSuccess,
    createApplicationStatusRuleFailure,
    async (action) => {
      return api.create(action.payload.statusId, action.payload.multipleRules);
    },
  );

export default CreateApplicationStatusRuleMiddleware;
