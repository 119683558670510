import { AnyAction } from 'redux';

import {
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_REQUEST,
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_SUCCESS,
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_FAILURE,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_FAILURE,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface VerifyPasswordRequestAction extends AnyAction {
  payload: string;
}

export const VerifyPasswordSuccess = () => {
  return {
    type: ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_SUCCESS,
  };
};

export const VerifyPasswordError = FailedActionCreator(ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_FAILURE);

export const VerifyPasswordRequest = (password: string): VerifyPasswordRequestAction => {
  return {
    type: ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_REQUEST,
    payload: password,
  };
};

export const GenerateSecondSecretRequest = () => {
  return {
    type: ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST,
  };
};

export const GenerateSecondSecretError = FailedActionCreator(ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_FAILURE);

export const GenerateSecondSecretSuccess = () => {
  return {
    type: ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS,
  };
};
