import React, { useState } from 'react';
import clsx from 'clsx';
import { ChevronIcon } from 'static/images';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { RuleValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import ApplicationStatusRuleValidationResult from 'components/ApplicationStatusRuleValidationResult';
import TransitionComponent from 'components/HeightTransitionComponent';
import { Tag } from 'components/Tag';
import styles from './StatusRulesValidationResult.module.scss';

export interface StatusRulesValidationProps {
  status: ApplicationStatus;
  statusIndex: number;
  ruleValidationResults: RuleValidationResult[];
  isCurrentStatus?: boolean;
  initialExpanded?: boolean;
}

const StatusRulesValidationResult = ({
  status,
  ruleValidationResults,
  statusIndex,
  isCurrentStatus,
  initialExpanded = false,
}: StatusRulesValidationProps) => {
  const noStatusRules = ruleValidationResults.length === 0;
  const [isExpanded, setIsExpanded] = useState(initialExpanded && !noStatusRules);

  const handleExpandIconClick = () => {
    if (noStatusRules) {
      return;
    }

    setIsExpanded(!isExpanded);
  };

  const renderRuleValidationResult = (validationResult: RuleValidationResult, index: number) => {
    const [multipleRule] = status.rules[index].multipleRules;
    const [multipleRuleValidationResult] = validationResult.multipleRuleValidationResults;

    if (!multipleRule || !multipleRuleValidationResult) {
      return null;
    }

    return (
      <ApplicationStatusRuleValidationResult
        key={multipleRule.id}
        rule={multipleRule}
        ruleValidationResult={multipleRuleValidationResult}
      />
    );
  };

  const renderRulesPassedInfoContent = () => {
    if (noStatusRules) {
      return 'No Status Rules';
    }

    const passedStatusRulesCount = ruleValidationResults.reduce((count, { valid }) => (valid ? count + 1 : count), 0);

    return `${passedStatusRulesCount} of ${ruleValidationResults.length} Rules Passed`;
  };

  const renderExpandIcon = () => (
    <div
      onClick={handleExpandIconClick}
      className={clsx(
        styles.chevronIcon,
        noStatusRules && styles.chevronIconDisabled,
        isExpanded && styles.chevronIconActive,
      )}
    >
      <ChevronIcon />
    </div>
  );

  return (
    <div className={clsx(styles.statusRulesValidationResult, !isExpanded && styles.collapsed)}>
      <div className={styles.statusRulesValidationResultHeader}>
        <div className={styles.subHeader}>
          <h5 className={styles.statusName}>{status.name}</h5>
          {isCurrentStatus && (
            <Tag className={styles.tag} color="green">
              Current Status
            </Tag>
          )}
        </div>
        <p className={styles.rulesPassedInfo}>{renderRulesPassedInfoContent()}</p>
        <p className={styles.statusIndex}>{statusIndex}.</p>
        {renderExpandIcon()}
      </div>
      <TransitionComponent>
        {isExpanded && <div>{ruleValidationResults.map(renderRuleValidationResult)}</div>}
      </TransitionComponent>
    </div>
  );
};

export default StatusRulesValidationResult;
