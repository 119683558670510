import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocuSign from 'components/DocuSign';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { ESignTemplateRecipientsMapping } from 'api/Core/ESignTemplatesApi';
import {
  ImportableDocuSignTemplate,
  ImportableDocuSignTemplateRecipientType,
  ImportableDocuSignTemplateStatus,
} from 'api/Core/DocuSignImportApi';
import {
  getTemplatesToImport,
  toggleImportTemplatesPopupActive,
  toggleVariablesMappingPopupActive,
  toggleRecipientsMappingPopupActive,
  setDeleteTemplateId,
  importDocuSignTemplates,
  toggleCreateVariablePopupActive,
} from 'DocuSign/ActionCreator';
import { getESignTemplate as getDocuSignTemplateSelector } from 'ESignTemplates/Selectors';
import {
  updateESignTemplate,
  getESignTemplate,
  deleteESignTemplate,
  ESignTemplatesActionOrigin,
} from 'ESignTemplates/ActionCreator';
import { VariableClientType } from 'Variables/VariablesTypes';
import { getVariableType } from 'Variables/utils';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import DeleteTemplatePopUp from 'components/DocuSign/DeleteTemplatePopUp';
import ImportTemplatePopUp from 'components/ImportTemplatesPopup';
import { useQueryParams } from 'hooks/useQueryParam';
import EditTemplate from 'components/DocuSign/EditTemplate';
import MappingVariablePopUp from 'components/DocuSign/MappingVariablePopUp';
import { DocuSignTab } from 'components/DocuSign/DocuSignTab';
import TemplatesFilter from 'components/DocuSign/TemplatesFilter';
import RecipientsMappingPopup from 'components/DocuSign/RecipientsMappingPopup';
import CreateVariablePopup from 'components/CreateVariablePopup';

const DOCU_SIGN_LINK = 'https://www.docusign.com/';
const WARNING_TEXT_IMPORT_TEMPLATE =
  'Importing a template will automatically overwrite any existing template with the same name, including your variable mapping. Only import templates that you would like to add or replace.';

const DocuSignPage = () => {
  const leftNav = makeLeftNavigation(NavigationLinkId.DocuSign, ApplicationSectionName.CompanySettings);
  const dispatch = useDispatch();
  const history = useHistory();
  const { tab } = useParams<{ tab: DocuSignTab }>();
  const params = useQueryParams();
  const templateId = params.get('edit');

  const {
    templateIdToDelete,
    isImportTemplatesPopUpOpen,
    isVariableMappingPopUpOpen,
    isRecipientsMappingPopupOpen,
    isCreateVariablePopupOpen,
    templatesToImport,
    filters,
    isTemplatesImportInProgress,
    isVariablesMappingSavingInProgress,
    isRecipientsMappingSavingInProgress,
    isTemplateDeleteInProgress,
    isVariableCreationInProgress,
  } = useSelector((state: ReduxState) => state.docuSign);
  const templateToEdit = useSelector((state: ReduxState) => getDocuSignTemplateSelector(state, templateId));

  const handleCloseContextualView = useCloseContextualView();

  useEffect(() => {
    if (isImportTemplatesPopUpOpen) {
      dispatch(getTemplatesToImport());
    }
  }, [isImportTemplatesPopUpOpen]);

  const handleTabChange = (newTab: DocuSignTab): void => history.push(newTab);

  const handleDeleteTemplatePopupClose = () => dispatch(setDeleteTemplateId(null));

  const handleTemplateDelete = () => {
    if (!templateIdToDelete) {
      return;
    }

    dispatch(
      setActionOrigin(deleteESignTemplate(templateIdToDelete), ESignTemplatesActionOrigin.DocuSignConfiguration),
    );
  };

  const handleTemplatesImport = (templates: ImportableDocuSignTemplate[]) => {
    dispatch(importDocuSignTemplates(templates));
  };

  const handleImportTemplatesPopupClose = () => dispatch(toggleImportTemplatesPopupActive());

  const handleVariablesMappingPopupClose = () => dispatch(toggleVariablesMappingPopupActive());

  const handleRecipientsMappingPopupClose = () => dispatch(toggleRecipientsMappingPopupActive());

  const handleTemplateActiveChange = (active: boolean) => {
    if (!templateToEdit) {
      return;
    }

    dispatch(
      setActionOrigin(
        updateESignTemplate(templateToEdit.id, { active }),
        ESignTemplatesActionOrigin.DocuSignConfiguration,
      ),
    );
  };

  const handleVariablesMappingSave = (variablesMapping: Record<string, string>) => {
    if (!templateToEdit) {
      return;
    }

    dispatch(
      setActionOrigin(
        updateESignTemplate(templateToEdit.id, { variablesMapping }),
        ESignTemplatesActionOrigin.DocuSignConfiguration,
      ),
    );
  };

  const handleRecipientsMappingSave = (recipientsMapping: ESignTemplateRecipientsMapping) => {
    if (!templateToEdit) {
      return;
    }

    dispatch(
      setActionOrigin(
        updateESignTemplate(templateToEdit.id, { recipientsMapping }),
        ESignTemplatesActionOrigin.DocuSignConfiguration,
      ),
    );
  };

  const handleOpenTemplatesImportPopup = () => {
    dispatch(toggleImportTemplatesPopupActive());
  };

  const handleDocuSignTemplateLoad = () => {
    if (templateId) {
      dispatch(getESignTemplate(templateId));
    }
  };

  const handleVariableMappingPopupOpen = () => {
    dispatch(toggleVariablesMappingPopupActive());
  };

  const handleRecipientsMappingPopupOpen = () => {
    dispatch(toggleRecipientsMappingPopupActive());
  };

  const handleToggleCreateVariablePopupActive = () => {
    dispatch(toggleCreateVariablePopupActive());
  };

  const handleCreateVariable = (variable: VariableClientType) => {
    const action = setActionOrigin(
      createVariableRequest({ ...variable, ...getVariableType(variable) }),
      CreateVariableActionOrigin.DocuSignVariablesMapping,
    );

    dispatch(action);
  };

  const getOverlay = (): ReactElement | null => {
    if (isCreateVariablePopupOpen) {
      return (
        <CreateVariablePopup
          onClose={handleToggleCreateVariablePopupActive}
          onSave={handleCreateVariable}
          isSavingInProgress={isVariableCreationInProgress}
        />
      );
    }

    if (templateIdToDelete) {
      return (
        <DeleteTemplatePopUp
          isDeleteInProgress={isTemplateDeleteInProgress}
          onDeleteTemplate={handleTemplateDelete}
          onPopupClose={handleDeleteTemplatePopupClose}
        />
      );
    }

    if (isImportTemplatesPopUpOpen) {
      const getCheckboxErrorByTemplate = (templateToImport: ImportableDocuSignTemplate) => {
        if (templateToImport.recipientTypes.includes(ImportableDocuSignTemplateRecipientType.InPersonSigners)) {
          return 'This template has an in-person signer and cannot be imported.';
        }

        return templateToImport.status === ImportableDocuSignTemplateStatus.Active
          ? 'This template must be deactivated before it can be re-imported.'
          : '';
      };

      return (
        <ImportTemplatePopUp
          templatesToImport={templatesToImport}
          isImportInProgress={isTemplatesImportInProgress}
          onImportTemplates={handleTemplatesImport}
          onPopupClose={handleImportTemplatesPopupClose}
          integrationTitle="DocuSign"
          integrationLink={DOCU_SIGN_LINK}
          warningTextMessage={WARNING_TEXT_IMPORT_TEMPLATE}
          getCheckboxErrorByTemplate={getCheckboxErrorByTemplate}
          isTemplateInitiallySelected={(template) =>
            !template.recipientTypes.includes(ImportableDocuSignTemplateRecipientType.InPersonSigners)
          }
        />
      );
    }

    if (isVariableMappingPopUpOpen && templateToEdit) {
      return (
        <MappingVariablePopUp
          isSaveInProgress={isVariablesMappingSavingInProgress}
          fields={templateToEdit.fields}
          templateVariablesMapping={templateToEdit.variablesMapping}
          onClose={handleVariablesMappingPopupClose}
          onOpenCreateVariablePopup={handleToggleCreateVariablePopupActive}
          onSave={handleVariablesMappingSave}
        />
      );
    }

    if (isRecipientsMappingPopupOpen && templateToEdit) {
      return (
        <RecipientsMappingPopup
          isSaveInProgress={isRecipientsMappingSavingInProgress}
          template={templateToEdit}
          onClose={handleRecipientsMappingPopupClose}
          onSave={handleRecipientsMappingSave}
        />
      );
    }

    return null;
  };

  const getContextualViewPage = () => {
    if (templateId) {
      return (
        <EditTemplate
          template={templateToEdit}
          loadTemplate={handleDocuSignTemplateLoad}
          onClose={handleCloseContextualView}
          onVariablesMappingPopUpOpen={handleVariableMappingPopupOpen}
          onRecipientsMappingPopupOpen={handleRecipientsMappingPopupOpen}
          onChangeTemplateActive={handleTemplateActiveChange}
        />
      );
    }

    return null;
  };

  const renderRightSidePopupView = () => {
    if (filters.isFiltersPopupOpen) {
      return (
        <TemplatesFilter
          createdDateRange={filters.createdDateRange}
          updateDateRange={filters.updatedDateRange}
          status={filters.selectedStatus}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={getOverlay()}
      contextualView={getContextualViewPage()}
      rightSidePopupView={renderRightSidePopupView()}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <DocuSign onTemplatesImport={handleOpenTemplatesImportPopup} onTabChange={handleTabChange} tab={tab} />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default DocuSignPage;
