import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { UpdateESignTemplateSuccessAction } from './ActionCreator';
import { ESignTemplatesActionType } from './ActionTypes';

const isUpdateESignTemplateSuccessAction = (action: AnyAction): action is UpdateESignTemplateSuccessAction =>
  action.type === ESignTemplatesActionType.UpdateESignTemplateSuccess;

const notifyOnESignTemplateStatusChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  if (!isUpdateESignTemplateSuccessAction(action)) {
    return next(action);
  }

  const { eSignTemplates } = getState();

  const previousESignTemplate = eSignTemplates.templatesById[action.payload.template.id];

  if (!previousESignTemplate || previousESignTemplate.active === action.payload.template.active) {
    return next(action);
  }

  const data = {
    templateName: action.payload.template.name,
    statusMessage: action.payload.template.active ? 'activated' : 'deactivated',
  };

  notification.createNotification(getMessage(MessageType.UpdateTemplateStatusSuccess, data), 'success', dispatch);

  return next(action);
}) as Middleware;

export default notifyOnESignTemplateStatusChangeMiddleware;
