import React, { FC, ReactElement } from 'react';
import { DigifiProduct, ProductAccess } from './Types';
import RestrictOrganizationPermissionsPage from 'pages/RestrictOrganizationPermissions';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

interface ProductAccessCheckProps {
  product: DigifiProduct;
  children: ReactElement;
}

const ProductAccessCheck: FC<ProductAccessCheckProps> = ({ product, children }) => {
  const productAccess = useSelector<ReduxState, ProductAccess | null>((state) => state.sessionInfo.subscription);

  if (!productAccess) {
    return null;
  }

  if (product && !productAccess[product]) {
    return <RestrictOrganizationPermissionsPage product={product} />;
  }

  return children;
};

export default ProductAccessCheck;
