import React from 'react';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import { Option } from 'components/SelectInput/SelectInput';
import OptionsInput from 'components/OptionsInput';
import { FirstOperandProps } from 'components/ComparisonOperatorPicker/Types';
import { ListWithAttributes, VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import { FORBIDDEN_OPTION_SYMBOLS_REGEXP } from 'components/VariableForm';

export const OPTION_SEPARATOR = ',';

const noop = () => { };

interface ComparisonOperandInProps extends Pick<FirstOperandProps, 'firstOperandValue' | 'onFirstOperandValueChange'> { }

const ComparisonOperandIn = (props: ComparisonOperandInProps & VisualDataTypeWithAttributes) => {
  const { firstOperandValue, onFirstOperandValueChange, ...dataTypeAttributes } = props;
  const valueOptions = variableOptionsListToSelectInputOptions((dataTypeAttributes as ListWithAttributes).optionsList)
  const getOptionsList = () => {
    if (!firstOperandValue) {
      return [];
    }
    return firstOperandValue.split(OPTION_SEPARATOR);
  };

  const onDeleteOption = (currentValue: string) => {
    const finalValue = firstOperandValue
      .split(OPTION_SEPARATOR)
      .filter((value) => value !== currentValue)
      .join(OPTION_SEPARATOR);
    onFirstOperandValueChange(finalValue);
  };

  const onAddOption = (currentValue: string) => {

    const firstOperandValuesList = firstOperandValue.split(',');
    if (firstOperandValuesList.includes(currentValue)) {
      return;
    };

    if (!firstOperandValue) {
      onFirstOperandValueChange(currentValue);
      return;
    }

    const finalValue = `${firstOperandValue}${OPTION_SEPARATOR}${currentValue}`;
    onFirstOperandValueChange(finalValue);
  };

  return dataTypeAttributes.dataType === 'List' ? (
    <AutoCompletionMultiselect
      id="listInOrNotIn"
      labelTitle="Value"
      options={valueOptions as Option[]}
      selectedOptions={getOptionsList()}
      onInputChange={noop}
      onDeleteOption={onDeleteOption}
      onSelect={onAddOption}
    />
  ) : (
      <OptionsInput
        id="InOrNotIn"
        selectedOptions={getOptionsList()}
        labelTitle="Value"
        onDeleteOption={onDeleteOption}
        onAddOption={onAddOption}
        onFormatValue={(value) => value.replace(FORBIDDEN_OPTION_SYMBOLS_REGEXP, '')}
      />
    );
};

export default ComparisonOperandIn;
