import { BillingInfo, Invoice, InvoicesSortingField } from 'api/BillingApi';
import { DataViewSortingType } from 'api/Types';
import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import BillingPageActionType from './ActionTypes';

export interface GetBillingInfoAction extends AnyAction {
  type: BillingPageActionType.GetBillingInfo;
}

export interface GetBillingInfoSuccessAction extends AnyAction {
  type: BillingPageActionType.GetBillingInfoSuccess;
  payload: {
    infoData: BillingInfo;
  };
}

export interface GetBillingInfoFailedAction extends AnyAction {
  type: BillingPageActionType.GetBillingInfoFailed;
}

export interface GetInvoicesAction extends AnyAction {
  type: BillingPageActionType.GetInvoices;
  payload: {
    sortingType: DataViewSortingType<InvoicesSortingField>;
  };
}

export interface GetInvoicesSuccessAction extends AnyAction {
  type: BillingPageActionType.GetInvoicesSuccess;
  payload: {
    invoices: Invoice[];
  };
}

export interface GetInvoicesFailedAction extends AnyAction {
  type: BillingPageActionType.GetInvoicesFailed;
}

export interface GetApplicationsCountAction extends AnyAction {
  type: BillingPageActionType.GetApplicationsCount;
  payload: {
    createdDate: {
      from: Date;
      to: Date;
    };
  };
}

export interface GetApplicationsCountSuccessAction extends AnyAction {
  type: BillingPageActionType.GetApplicationsCountSuccess;
  payload: {
    applicationsCount: number;
  };
}

export interface GetApplicationsCountFailedAction extends AnyAction {
  type: BillingPageActionType.GetApplicationsCountFailed;
}

export interface GetDecisionsCountAction extends AnyAction {
  type: BillingPageActionType.GetDecisionsCount;
  payload: {
    createdDate: {
      from: Date;
      to: Date;
    };
  };
}

export interface GetDecisionsCountSuccessAction extends AnyAction {
  type: BillingPageActionType.GetDecisionsCountSuccess;
  payload: {
    decisionsCount: number;
  };
}

export interface GetDecisionsCountFailedAction extends AnyAction {
  type: BillingPageActionType.GetDecisionsCountFailed;
}

export const getBillingInfo = (): GetBillingInfoAction => {
  return {
    type: BillingPageActionType.GetBillingInfo,
  };
};

export const getBillingInfoSuccess = (infoData: BillingInfo): GetBillingInfoSuccessAction => {
  return {
    type: BillingPageActionType.GetBillingInfoSuccess,
    payload: {
      infoData,
    },
  };
};

export const gitBillingInfoFailed = FailedActionCreator(BillingPageActionType.GetBillingInfoFailed);

export const getInvoices = (sortingType: DataViewSortingType<InvoicesSortingField>): GetInvoicesAction => {
  return {
    type: BillingPageActionType.GetInvoices,
    payload: {
      sortingType,
    },
  };
};

export const getInvoicesSuccess = (invoices: Invoice[]): GetInvoicesSuccessAction => {
  return {
    type: BillingPageActionType.GetInvoicesSuccess,
    payload: {
      invoices,
    },
  };
};

export const getInvoicesFailed = FailedActionCreator(BillingPageActionType.GetInvoicesFailed);

export const getApplicationsCount = (from: Date, to: Date): GetApplicationsCountAction => {
  return {
    type: BillingPageActionType.GetApplicationsCount,
    payload: {
      createdDate: {
        from,
        to,
      },
    },
  };
};

export const getApplicationsCountSuccess = (applicationsCount: number): GetApplicationsCountSuccessAction => {
  return {
    type: BillingPageActionType.GetApplicationsCountSuccess,
    payload: {
      applicationsCount,
    },
  };
};

export const getApplicationsCountFailed = FailedActionCreator(BillingPageActionType.GetApplicationsCountFailed);

export const getDecisionsCount = (from: Date, to: Date): GetDecisionsCountAction => {
  return {
    type: BillingPageActionType.GetDecisionsCount,
    payload: {
      createdDate: {
        from,
        to,
      },
    },
  };
};

export const getDecisionsCountSuccess = (decisionsCount: number): GetDecisionsCountSuccessAction => {
  return {
    type: BillingPageActionType.GetDecisionsCountSuccess,
    payload: {
      decisionsCount,
    },
  };
};

export const getDecisionsCountFailed = FailedActionCreator(BillingPageActionType.GetDecisionsCountFailed);
