import React, { FC, useState, useEffect } from 'react';
import { has } from 'lodash';
import AutoCompletion from 'components/AutoCompletion';
import { StrategiesListItem, IndividualRunInputs } from 'api/Types';
import InputWithDataType from 'components/InputWithDataType';
import styles from 'components/RunDecisionForm/RunDecisionForm.module.scss';
import Button from 'components/Button';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { Option } from 'components/SelectInput/SelectInput';
import { ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';
import { Tag } from 'components/Tag';
import { statusColors } from 'pages/IndividualProcessing/IndividualProcessing';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';

interface RunDecisionFormProps {
  strategies: StrategiesListItem[];
  strategyInputs: ProcessingStrategyInput[] | null;
  onRunDecision: (inputs: IndividualRunInputs) => void;
  onStrategyChange: (strategyId: string) => void;
  currentStrategy?: string;
  initialInputValues?: IndividualRunInputs;
  inputContainerClassName?: string;
  inputsLoading?: boolean;
}

export interface InputValues {
  [inputName: string]: string;
}

const RunDecisionForm: FC<RunDecisionFormProps> = ({
  currentStrategy = '',
  strategies,
  strategyInputs,
  initialInputValues,
  onRunDecision,
  onStrategyChange,
  inputContainerClassName,
  inputsLoading,
}) => {
  const [inputs, setInputs] = useState<InputValues | null>(null);
  const [isRunDecisionInLoading, setIsRunDecisionInLoading] = useState(false);

  useEffect(() => {
    if (currentStrategy && strategyInputs) {
      const inputValues: InputValues = strategyInputs.reduce(
        (acc, { systemName }) => {
          const value = has(initialInputValues, systemName) ? initialInputValues![systemName] : '';
          acc[systemName] = value && value.toString();
          return acc;
        },
        { case_name: '' },
      );
      setInputs(inputValues);
    }
  }, [strategyInputs, currentStrategy]);

  const handleStrategyChange = ({ value }: Option) => onStrategyChange(value || '');
  const inputChangeHandler = (attr: string) => (value: string) => {
    setInputs({ ...inputs, [attr]: value });
  };

  const handleClick = async () => {
    if (!inputs) {
      return;
    }

    setIsRunDecisionInLoading(true);
    try {
      await onRunDecision(inputs);
      setIsRunDecisionInLoading(false);
    } catch (error) {
      setIsRunDecisionInLoading(false);
    }
  };

  const renderInputs = () => {
    if (!inputs || !strategyInputs || !strategyInputs.length) {
      return null;
    }

    return (
      <>
        {strategyInputs.map((input) => {
          const inputValue = inputs[input.systemName];
          const inputDisplayValue =
            typeof inputValue !== 'undefined' && inputValue !== null ? inputValue.toString() : '';

          return (
            <div className={inputContainerClassName || styles.input} key={input.systemName}>
              <InputWithDataType
                value={inputDisplayValue}
                options={input.optionsList ? variableOptionsListToSelectInputOptions(input.optionsList) : null}
                labelTitle={input.displayName}
                onChange={inputChangeHandler(input.systemName)}
                disabledValidation
                {...getVisualDataTypeWithAttributes(input)}
              />
            </div>
          );
        })}
        <div className={inputContainerClassName || styles.input}>
          <InputWithDataType
            dataType="Text"
            labelTitle="Decision Name"
            value={inputs.case_name || ''}
            options={null}
            onChange={inputChangeHandler('case_name')}
          />
        </div>
      </>
    );
  };

  const getStrategyOptions = (): Option[] => {
    return strategies.map(({ id, name, status }: StrategiesListItem) => {
      const normalizedStatus = getNormalizedStatus(status);
      return {
        name,
        value: id,
        iconAfter: <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
      };
    });
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={inputContainerClassName || styles.input}>
          <AutoCompletion
            labelTitle="Strategy Name"
            onChange={handleStrategyChange}
            value={currentStrategy}
            options={getStrategyOptions()}
            loading={inputsLoading}
            showLoader
          />
        </div>
        {!inputsLoading && renderInputs()}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          size="form"
          kind="primary"
          onClick={handleClick}
          disabled={!currentStrategy || !strategyInputs}
          isLoading={isRunDecisionInLoading}
        >
          Run Decision
        </Button>
      </div>
    </>
  );
};

export default RunDecisionForm;
