import { AnyAction } from 'redux';
import {
  ApplicationPageActionType,
  CLOSE_CHANGE_APPLICATION_STATUS_POPUP,
  GET_APPLICATION_DATA,
  GET_APPLICATION_DATA_FAILED,
  GET_APPLICATION_DATA_SUCCESS,
  OPEN_CHANGE_APPLICATION_STATUS_POPUP,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_FAILED,
  UPDATE_APPLICATION_SUCCESS,
} from './ActionTypes';
import { Application, UpdateApplicationModel } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import ApplicationVariableSortingField from './ApplicationVariableSortingField';
import { Variable } from 'Variables/VariablesTypes';

export interface GetApplicationDataAction extends AnyAction {
  type: typeof GET_APPLICATION_DATA;
  payload: {
    applicationDisplayId: string;
  };
}

export interface GetApplicationDataSuccessAction extends AnyAction {
  type: typeof GET_APPLICATION_DATA_SUCCESS;
  payload: {
    applicationData: Application;
  };
}
export interface OpenChangeApplicationStatusPopupAction extends AnyAction {
  type: typeof OPEN_CHANGE_APPLICATION_STATUS_POPUP;
  payload: {
    statusToChange: ApplicationStatus;
  };
}
export interface CloseChangeApplicationStatusPopupAction extends AnyAction {
  type: typeof CLOSE_CHANGE_APPLICATION_STATUS_POPUP;
}

export interface UpdateApplicationAction extends AnyAction {
  type: typeof UPDATE_APPLICATION;
  payload: {
    data: UpdateApplicationModel;
  };
}

export interface UpdateApplicationSuccessAction extends AnyAction {
  type: typeof UPDATE_APPLICATION_SUCCESS;
  payload: {
    application: Application;
  };
}

export interface UpdateApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationVariables;
  payload: {
    applicationId: string;
    variables: FormLayoutData;
  };
}

export interface UpdateApplicationVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationVariablesSuccess;
  payload: {
    application: Application;
  };
}

export interface GetApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.GetApplicationVariables;
  payload: {
    application: Application;
  };
}

export interface GetApplicationVariablesSuccessAction extends AnyAction {
  type: ApplicationPageActionType.GetApplicationVariablesSuccess;
  payload: {
    variables: Variable[];
  };
}

export interface DeleteApplicationCoborrowerAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationCoborrower;
  payload: {
    applicationId: string;
    variablesToDelete: string[];
  };
}

export interface DeleteApplicationCoborrowerSuccessAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationCoborrowerSuccess;
  payload: {
    application: Application;
  };
}

export interface DeleteApplicationIntermediaryAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationIntermediary;
  payload: {
    applicationId: string;
    variablesToDelete: string[];
  };
}

export interface DeleteApplicationIntermediarySuccessAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationIntermediarySuccess;
  payload: {
    application: Application;
  };
}

export interface AddApplicationCoborrowerAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationCoborrower;
  payload: {
    applicationId: string;
    coborrowerId?: string;
    coborrower?: FormLayoutData;
  };
}

export interface AddApplicationCoborrowerSuccessAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationCoborrowerSuccess;
  payload: {
    application: Application;
  };
}

export interface AddApplicationIntermediaryAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationIntermediary;
  payload: {
    applicationId: string;
    intermediaryId?: string;
    intermediary?: FormLayoutData;
  };
}

export interface AddApplicationIntermediarySuccessAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationIntermediarySuccess;
  payload: {
    application: Application;
  };
}

export interface SortApplicationVariablesAction extends AnyAction {
  type: ApplicationPageActionType.SortApplicationVariables;
  payload: {
    field: ApplicationVariableSortingField;
    ascending: boolean;
  };
}

export interface UpdateApplicationTeamMembersAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationTeamMembers;
  payload: {
    applicationId: string;
    teamMembers: string[];
  };
}

export interface UpdateApplicationTeamMembersSuccessAction extends AnyAction {
  type: ApplicationPageActionType.UpdateApplicationTeamMembersSuccess;
  payload: {
    application: Application;
  };
}

export interface AddApplicationLabelAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationLabel;
  payload: {
    applicationId: string;
    labelId: string;
  };
}

export interface AddApplicationLabelSuccessAction extends AnyAction {
  type: ApplicationPageActionType.AddApplicationLabelSuccess;
  payload: {
    application: Application;
  };
}

export interface DeleteApplicationLabelAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationLabel;
  payload: {
    applicationId: string;
    labelId: string;
  };
}

export interface DeleteApplicationLabelSuccessAction extends AnyAction {
  type: ApplicationPageActionType.DeleteApplicationLabelSuccess;
  payload: {
    application: Application;
  };
}

export const getApplicationData = (applicationDisplayId: string): GetApplicationDataAction => {
  return {
    type: GET_APPLICATION_DATA,
    payload: {
      applicationDisplayId,
    },
  };
};

export const getApplicationDataSuccess = (applicationData: Application): GetApplicationDataSuccessAction => {
  return {
    type: GET_APPLICATION_DATA_SUCCESS,
    payload: {
      applicationData,
    },
  };
};

export const getApplicationDataFailed = FailedActionCreator(GET_APPLICATION_DATA_FAILED);

export const openChangeApplicationStatusPopup = (
  statusToChange: ApplicationStatus,
): OpenChangeApplicationStatusPopupAction => {
  return {
    type: OPEN_CHANGE_APPLICATION_STATUS_POPUP,
    payload: {
      statusToChange,
    },
  };
};

export const closeChangeApplicationStatusPopup = (): CloseChangeApplicationStatusPopupAction => {
  return {
    type: CLOSE_CHANGE_APPLICATION_STATUS_POPUP,
  };
};

export const updateApplication = (data: UpdateApplicationModel): UpdateApplicationAction => {
  return {
    type: UPDATE_APPLICATION,
    payload: {
      data,
    },
  };
};

export const updateApplicationSuccess = (application: Application): UpdateApplicationSuccessAction => {
  return {
    type: UPDATE_APPLICATION_SUCCESS,
    payload: {
      application,
    },
  };
};

export const updateApplicationVariables = (
  applicationId: string,
  variables: FormLayoutData,
): UpdateApplicationVariablesAction => ({
  type: ApplicationPageActionType.UpdateApplicationVariables,
  payload: {
    applicationId,
    variables,
  },
});

export const updateApplicationTeamMembers = (
  applicationId: string,
  teamMembers: string[],
): UpdateApplicationTeamMembersAction => ({
  type: ApplicationPageActionType.UpdateApplicationTeamMembers,
  payload: {
    applicationId,
    teamMembers,
  },
});

export const updateApplicationTeamMembersSuccess = (
  application: Application,
): UpdateApplicationTeamMembersSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationTeamMembersSuccess,
  payload: {
    application,
  },
});

export const updateApplicationVariablesSuccess = (
  application: Application,
): UpdateApplicationVariablesSuccessAction => ({
  type: ApplicationPageActionType.UpdateApplicationVariablesSuccess,
  payload: {
    application,
  },
});

export const updateApplicationFailed = FailedActionCreator(UPDATE_APPLICATION_FAILED);
export const updateApplicationVariablesFailure = FailedActionCreator(
  ApplicationPageActionType.UpdateApplicationVariablesFailure,
);

export const getApplicationVariables = (application: Application): GetApplicationVariablesAction => {
  return {
    type: ApplicationPageActionType.GetApplicationVariables,
    payload: {
      application,
    },
  };
};

export const getApplicationVariablesSuccess = (variables: Variable[]): GetApplicationVariablesSuccessAction => {
  return {
    type: ApplicationPageActionType.GetApplicationVariablesSuccess,
    payload: {
      variables,
    },
  };
};

export const getApplicationVariablesFailed = FailedActionCreator(
  ApplicationPageActionType.GetApplicationVariablesFailed,
);

export const deleteApplicationCoborrower = (
  applicationId: string,
  variablesToDelete: string[],
): DeleteApplicationCoborrowerAction => {
  return {
    type: ApplicationPageActionType.DeleteApplicationCoborrower,
    payload: {
      applicationId,
      variablesToDelete,
    },
  };
};

export const deleteApplicationCoborrowerSuccess = (
  application: Application,
): DeleteApplicationCoborrowerSuccessAction => {
  return {
    type: ApplicationPageActionType.DeleteApplicationCoborrowerSuccess,
    payload: {
      application,
    },
  };
};

export const deleteApplicationCoborrowerFailed = FailedActionCreator(
  ApplicationPageActionType.DeleteApplicationCoborrowerFailed,
);

export const deleteApplicationIntermediary = (
  applicationId: string,
  variablesToDelete: string[],
): DeleteApplicationIntermediaryAction => {
  return {
    type: ApplicationPageActionType.DeleteApplicationIntermediary,
    payload: {
      applicationId,
      variablesToDelete,
    },
  };
};

export const deleteApplicationIntermediarySuccess = (
  application: Application,
): DeleteApplicationIntermediarySuccessAction => {
  return {
    type: ApplicationPageActionType.DeleteApplicationIntermediarySuccess,
    payload: {
      application,
    },
  };
};

export const deleteApplicationIntermediaryFailed = FailedActionCreator(
  ApplicationPageActionType.DeleteApplicationIntermediaryFailed,
);

export const addApplicationCoborrower = (
  applicationId: string,
  coborrowerId?: string,
  coborrower?: FormLayoutData,
): AddApplicationCoborrowerAction => {
  return {
    type: ApplicationPageActionType.AddApplicationCoborrower,
    payload: {
      applicationId,
      coborrowerId,
      coborrower,
    },
  };
};

export const addApplicationCoborrowerSuccess = (application: Application): AddApplicationCoborrowerSuccessAction => {
  return {
    type: ApplicationPageActionType.AddApplicationCoborrowerSuccess,
    payload: {
      application,
    },
  };
};

export const addApplicationCoborrowerFailed = FailedActionCreator(
  ApplicationPageActionType.AddApplicationCoborrowerFailed,
);

export const addApplicationIntermediary = (
  applicationId: string,
  intermediaryId?: string,
  intermediary?: FormLayoutData,
): AddApplicationIntermediaryAction => {
  return {
    type: ApplicationPageActionType.AddApplicationIntermediary,
    payload: {
      applicationId,
      intermediaryId,
      intermediary,
    },
  };
};

export const addApplicationIntermediarySuccess = (
  application: Application,
): AddApplicationIntermediarySuccessAction => {
  return {
    type: ApplicationPageActionType.AddApplicationIntermediarySuccess,
    payload: {
      application,
    },
  };
};

export const addApplicationLabel = (applicationId: string, labelId: string): AddApplicationLabelAction => ({
  type: ApplicationPageActionType.AddApplicationLabel,
  payload: {
    applicationId,
    labelId,
  },
});

export const addApplicationLabelSuccess = (application: Application): AddApplicationLabelSuccessAction => ({
  type: ApplicationPageActionType.AddApplicationLabelSuccess,
  payload: {
    application,
  },
});

export const deleteApplicationLabel = (applicationId: string, labelId: string): DeleteApplicationLabelAction => ({
  type: ApplicationPageActionType.DeleteApplicationLabel,
  payload: {
    applicationId,
    labelId,
  },
});

export const deleteApplicationLabelSuccess = (application: Application): DeleteApplicationLabelSuccessAction => ({
  type: ApplicationPageActionType.DeleteApplicationLabelSuccess,
  payload: {
    application,
  },
});

export const addApplicationLabelFailure = FailedActionCreator(ApplicationPageActionType.AddApplicationLabelFailure);

export const deleteApplicationLabelFailure = FailedActionCreator(
  ApplicationPageActionType.DeleteApplicationLabelFailure,
);

export const addApplicationIntermediaryFailed = FailedActionCreator(
  ApplicationPageActionType.AddApplicationIntermediaryFailed,
);

export const sortApplicationVariables = (
  field: ApplicationVariableSortingField,
  ascending: boolean,
): SortApplicationVariablesAction => {
  return {
    type: ApplicationPageActionType.SortApplicationVariables,
    payload: {
      field,
      ascending,
    },
  };
};

export const updateApplicationTeamMembersFailure = FailedActionCreator(
  ApplicationPageActionType.UpdateApplicationTeamMembersFailure,
);

export interface ClearApplicationDataAction {
  type: ApplicationPageActionType.ClearApplicationData;
}

export const clearApplicationData = (): ClearApplicationDataAction => ({
  type: ApplicationPageActionType.ClearApplicationData,
});
