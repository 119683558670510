import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

interface TransitionEventHandler {
  (event: React.TransitionEvent<HTMLElement>): void;
}

type ProvidedValues<Children> = [Children[], TransitionEventHandler, number | undefined];

const useCachedList = <Children = ReactNode>(
  children: (ReactElement | null)[],
  currentElementIndex?: number,
): ProvidedValues<ReactElement | null> => {
  const [childNodes, setChildNodes] = useState<(ReactElement | null)[]>(children);
  const [cachedCurrentElementIndex, setCachedCurrentElementIndex] = useState<number | undefined>(currentElementIndex);

  const onTransitionEnd = ({ target, currentTarget }: React.TransitionEvent<HTMLElement>) => {
    if (target !== currentTarget) {
      return;
    }
    setChildNodes(children);
  };

  useEffect(() => {
    const cachedChildrenList: (ReactElement | null)[] = [];

    for (const [index, child] of children.entries()) {
      cachedChildrenList.push(child || childNodes[index]);
    }

    setChildNodes(cachedChildrenList);
    setCachedCurrentElementIndex(currentElementIndex);
  }, [...children, currentElementIndex]);

  return [childNodes, onTransitionEnd, cachedCurrentElementIndex];
};

export default useCachedList;
