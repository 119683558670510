import { ImportableSendGridTemplate } from 'SendGrid/Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';

class SendGridImportApi extends LoanOriginationSystemApi<ImportableSendGridTemplate> {
  getSendGridTemplatesToImport(): Promise<ImportableSendGridTemplate[]> {
    return this.fetch<ImportableSendGridTemplate[]>(
      '/import-email-templates',
      'GET',
    );
  }

  getSendGridSenderEmails(): Promise<string[]> {
    return this.fetch<string[]>(
      '/import-sender-emails',
      'GET',
    );
  }
}

export default SendGridImportApi;
