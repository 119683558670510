import { AnyAction } from 'redux';
import { IndividualProcessingHistoryState } from './Types';
import {
  GET_INDIVIDUAL_HISTORY_SUCCESS,
  SET_INDIVIDUAL_HISTORY_SEARCH_INPUT,
  IndividualProcessingHistoryActionType,
} from './ActionTypes';
import { GetIndividualHistorySuccessAction, IndividualHistorySetSearchInput } from './ActionCreator';
import individualHistoryPagination, {
  HISTORY_CASES_PER_PAGE_DEFAULT,
} from 'components/IndividualProcessing/History/Pagination';
import withStateReset from 'utils/reducers/withStateReset';

export const initialState: IndividualProcessingHistoryState = {
  searchInputValue: '',
  error: null,
  items: [],
  searched: {},
  page: 1,
  itemsPerPage: HISTORY_CASES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
};

const individualProcessingHistoryReducer = (
  state = initialState,
  action: AnyAction,
): IndividualProcessingHistoryState => {
  switch (action.type) {
    case GET_INDIVIDUAL_HISTORY_SUCCESS: {
      const { items, itemsTotal, searchInputValue } = (action as GetIndividualHistorySuccessAction).payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal,
              page: 1,
              itemsPerPage: HISTORY_CASES_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        items,
        itemsTotal,
        page: 1,
        itemsPerPage: state?.itemsPerPage || HISTORY_CASES_PER_PAGE_DEFAULT,
        error: '',
      };
    }
    case SET_INDIVIDUAL_HISTORY_SEARCH_INPUT: {
      const searchInputValue = (action as IndividualHistorySetSearchInput).payload;
      return {
        ...state,
        searchInputValue,
      };
    }
    default: {
      return state;
    }
  }
};

export default individualHistoryPagination.wrapReducer<IndividualProcessingHistoryState>(
  withStateReset(
    individualProcessingHistoryReducer,
    IndividualProcessingHistoryActionType.ResetState,
    () => initialState,
  ),
);
