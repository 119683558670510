import React, { ReactNode, FC } from 'react';
import PopUpCore from './PopUpCore';
import PopUpHeaderWithClose from './PopUpHeaderWithClose';

import styles from './_LegacyPopUp.module.scss';

interface LegacyPopUpProps {
  children: ReactNode;
  title: string;
  closePopUp?: () => void;
  closable?: boolean;
}

// TODO get rid of usages and remove this component
// overlays should be moved from Main to appropriate page components

const LegacyPopUp: FC<LegacyPopUpProps> = ({ children, closePopUp, title, closable }) => {
  return (
    <div className={styles.popup}>
      <PopUpCore>
        <PopUpHeaderWithClose title={title} onClose={closePopUp} closable={closable} />
        {children}
      </PopUpCore>
    </div>
  );
};

export default LegacyPopUp;
