import Button from 'components/Button';
import React from 'react';
import styles from './LandingView.module.scss';
import { LoanOriginationSystem } from 'static/images';

interface LandingViewProps {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
}

const LandingView = ({ buttonText, description, onButtonClick, title }: LandingViewProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.info}>{description}</p>
        <Button kind="primary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
      <div className={styles.image}>
        <img src={LoanOriginationSystem} alt="" />
      </div>
    </div>
  );
};

export default LandingView;
