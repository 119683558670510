import React, { FC, useState } from 'react';
import styles from './Variable.module.scss';
import { CloseButtonImage } from 'static/images';
import { Variable, VariableClientType } from 'Variables/VariablesTypes';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import VariableForm from 'components/VariableForm';
import VariableFormButtons from 'components/Variable/VariableFormButtons';
import VariableTypeTag from 'components/VariableTypeTag/VariableTypeTag';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';

interface EditVariableTypes {
  onClose?: () => void;
  onSaveVariable: (variableData: VariableClientType) => Promise<void>;
  regionSettings: RegionSettings;
  variable: Variable;
}

const createValidFormData = (formData: VariableClientType) => {
  const {
    description,
    dataType,
    optionsList,
    userAccessPermissions,
    phoneNumberFormat,
    currency,
    identificationNumberType,
    identificationNumberDescription,
    dateFormat,
  } = formData;
  const commonVariableData = {
    dataType,
    userAccessPermissions,
    description,
  };

  if (dataType === 'List') {
    return {
      ...commonVariableData,
      optionsList,
    };
  }
  if (dataType === 'Text') {
    return {
      ...commonVariableData,
      optionsList: null,
    };
  }
  if (dataType === 'Number') {
    return {
      ...commonVariableData,
      numberFormat: null,
      currency: null,
    };
  }
  if (dataType === 'Monetary') {
    return {
      ...commonVariableData,
      currency,
    };
  }

  if (dataType === 'PhoneNumber') {
    return {
      ...commonVariableData,
      phoneNumberFormat,
    };
  }

  if (dataType === 'IdentificationNumber') {
    return {
      ...commonVariableData,
      identificationNumberType,
      identificationNumberDescription,
    };
  }
  if (dataType === 'Date') {
    return {
      ...commonVariableData,
      dateFormat,
    };
  }
  return commonVariableData;
};

const EditVariable: FC<EditVariableTypes> = ({ onClose, onSaveVariable, variable, regionSettings }) => {
  const { displayName: name, updatedBy, updateDate, id } = variable;
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);

  const onVariableSave = async (formData: VariableClientType) => {
    const variableData = {
      ...createValidFormData(formData),
      ...formData,
      id,
    };

    try {
      setIsSavingInProgress(true);
      await onSaveVariable(variableData as VariableClientType);
    } finally {
      setIsSavingInProgress(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{name}</h1>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <div className={styles.updaterInfoContainer}>
        <VariableTypeTag variable={variable} medium className={styles.variableTypeTag} />
        <WithSystemApiUserAvatar user={updatedBy} size="tiny" />
        <p className={styles.updateDateContainer}>
          Updated <DateTime time={updateDate} format={DateTimeFormat.Long} />
        </p>
      </div>

      <VariableForm onSave={onVariableSave} variable={variable} regionSettings={regionSettings}>
        <VariableFormButtons update isSavingInProgress={isSavingInProgress} />
      </VariableForm>
    </div>
  );
};

export default EditVariable;
