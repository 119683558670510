import React from 'react';
import { SIZES } from 'components/Variables/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import TableActionCell from 'components/Table/TableActionCell';
import styles from 'components/Variables/VariableLine/VariableLine.module.scss';

const SkeletonVariableLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.DISPLAY_NAME}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.SYSTEM_NAME}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.DATA_TYPE}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.VARIABLE_TYPE}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.UPDATED} noPadding>
        <SkeletonCircle className={styles.creatorIcon} width="28px" height="28px" color="primary6" />
        <SkeletonRectangle width="65%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.DESCRIPTION}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonVariableLine;
