export enum VariablesConfigurationActionType {
  GetBorrowerVariableConfigurations = 'variablesConfiguration/getBorrowerVariableConfigurations',
  GetBorrowerVariableConfigurationsSuccess = 'variablesConfiguration/getBorrowerVariableConfigurationsSuccess',
  GetBorrowerVariableConfigurationsFailure = 'variablesConfiguration/getBorrowerVariableConfigurationsFailure',
  CreateBorrowerVariableConfiguration = 'variablesConfiguration/createBorrowerVariableConfiguration',
  CreateBorrowerVariableConfigurationSuccess = 'variablesConfiguration/createBorrowerVariableConfigurationSuccess',
  CreateBorrowerVariableConfigurationFailure = 'variablesConfiguration/createBorrowerVariableConfigurationFailure',
  UpdateBorrowerVariableConfiguration = 'variablesConfiguration/updateBorrowerVariableConfiguration',
  UpdateBorrowerVariableConfigurationSuccess = 'variablesConfiguration/updateBorrowerVariableConfigurationSuccess',
  UpdateBorrowerVariableConfigurationFailure = 'variablesConfiguration/updateBorrowerVariableConfigurationFailure',
  DeleteBorrowerVariableConfiguration = 'variablesConfiguration/deleteBorrowerVariableConfiguration',
  DeleteBorrowerVariableConfigurationSuccess = 'variablesConfiguration/deleteBorrowerVariableConfigurationSuccess',
  DeleteBorrowerVariableConfigurationFailure = 'variablesConfiguration/deleteBorrowerVariableConfigurationFailure',
  BulkUpdateBorrowerVariableConfigurations = 'variablesConfiguration/bulkUpdateBorrowerVariableConfigurations',
  BulkUpdateBorrowerVariableConfigurationsSuccess = 'variablesConfiguration/bulkUpdateBorrowerVariableConfigurationsSuccess',
  BulkUpdateBorrowerVariableConfigurationsFailure = 'variablesConfiguration/bulkUpdateBorrowerVariableConfigurationsFailure',
  GetIntermediaryVariableConfigurations = 'variablesConfiguration/getIntermediaryVariableConfigurations',
  GetIntermediaryVariableConfigurationsSuccess = 'variablesConfiguration/getIntermediaryVariableConfigurationsSuccess',
  GetIntermediaryVariableConfigurationsFailure = 'variablesConfiguration/getIntermediaryVariableConfigurationsFailure',
  CreateIntermediaryVariableConfiguration = 'variablesConfiguration/createIntermediaryVariableConfiguration',
  CreateIntermediaryVariableConfigurationSuccess = 'variablesConfiguration/createIntermediaryVariableConfigurationSuccess',
  CreateIntermediaryVariableConfigurationFailure = 'variablesConfiguration/createIntermediaryVariableConfigurationFailure',
  UpdateIntermediaryVariableConfiguration = 'variablesConfiguration/updateIntermediaryVariableConfiguration',
  UpdateIntermediaryVariableConfigurationSuccess = 'variablesConfiguration/updateIntermediaryVariableConfigurationSuccess',
  UpdateIntermediaryVariableConfigurationFailure = 'variablesConfiguration/updateIntermediaryVariableConfigurationFailure',
  DeleteIntermediaryVariableConfiguration = 'variablesConfiguration/deleteIntermediaryVariableConfiguration',
  DeleteIntermediaryVariableConfigurationSuccess = 'variablesConfiguration/deleteIntermediaryVariableConfigurationSuccess',
  DeleteIntermediaryVariableConfigurationFailure = 'variablesConfiguration/deleteIntermediaryVariableConfigurationFailure',
  BulkUpdateIntermediaryVariableConfigurations = 'variablesConfiguration/bulkUpdateIntermediaryVariableConfigurations',
  BulkUpdateIntermediaryVariableConfigurationsSuccess = 'variablesConfiguration/bulkUpdateIntermediaryVariableConfigurationsSuccess',
  BulkUpdateIntermediaryVariableConfigurationsFailure = 'variablesConfiguration/bulkUpdateIntermediaryVariableConfigurationsFailure',
  GetApplicationVariableConfigurations = 'variablesConfiguration/getApplicationVariableConfigurations',
  GetApplicationVariableConfigurationsSuccess = 'variablesConfiguration/getApplicationVariableConfigurationsSuccess',
  GetApplicationVariableConfigurationsFailure = 'variablesConfiguration/getApplicationVariableConfigurationsFailure',
  CreateApplicationVariableConfiguration = 'variablesConfiguration/createApplicationVariableConfiguration',
  CreateApplicationVariableConfigurationSuccess = 'variablesConfiguration/createApplicationVariableConfigurationSuccess',
  CreateApplicationVariableConfigurationFailure = 'variablesConfiguration/createApplicationVariableConfigurationFailure',
  UpdateApplicationVariableConfiguration = 'variablesConfiguration/updateApplicationVariableConfiguration',
  UpdateApplicationVariableConfigurationSuccess = 'variablesConfiguration/updateApplicationVariableConfigurationSuccess',
  UpdateApplicationVariableConfigurationFailure = 'variablesConfiguration/updateApplicationVariableConfigurationFailure',
  DeleteApplicationVariableConfiguration = 'variablesConfiguration/deleteApplicationVariableConfiguration',
  DeleteApplicationVariableConfigurationSuccess = 'variablesConfiguration/deleteApplicationVariableConfigurationSuccess',
  DeleteApplicationVariableConfigurationFailure = 'variablesConfiguration/deleteApplicationVariableConfigurationFailure',
  BulkUpdateApplicationVariableConfigurations = 'variablesConfiguration/bulkUpdateApplicationVariableConfigurations',
  BulkUpdateApplicationVariableConfigurationsSuccess = 'variablesConfiguration/bulkUpdateApplicationVariableConfigurationsSuccess',
  BulkUpdateApplicationVariableConfigurationsFailure = 'variablesConfiguration/bulkUpdateApplicationVariableConfigurationsFailure',
}
