import Fetcher from 'middlewares/Fetcher';
import { ApplicationStatusRuleApi } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  DeleteApplicationStatusRuleAction,
  deleteApplicationStatusRuleSuccess,
  deleteApplicationStatusRuleFailure,
  DeleteApplicationStatusRuleParams,
} from './ActionCreator';

const DeleteApplicationStatusRuleMiddleware = (api: ApplicationStatusRuleApi) =>
  Fetcher<DeleteApplicationStatusRuleParams, DeleteApplicationStatusRuleAction>(
    ApplicationStatusesActionType.DeleteApplicationStatusRule,
    deleteApplicationStatusRuleSuccess,
    deleteApplicationStatusRuleFailure,
    async (action) => {
      await api.delete(action.payload.ruleId);

      return {
        statusId: action.payload.statusId,
        ruleId: action.payload.ruleId,
      };
    },
  );

export default DeleteApplicationStatusRuleMiddleware;
