import { AnyAction } from 'redux';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { DownloadResponse } from 'api/APIRest';
import {
  Product,
  ProductApiRequestTemplateType,
  ProductsDataFilter,
  ProductStatus,
  ProductType,
  UpdateProductParams,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { StatusChangeData } from './Types';

export enum GetProductActionOrigin {
  ProductConfiguration = 'ProductConfiguration',
  ApplicationTabs = 'ApplicationTabs',
}

export enum UpdateProductActionOrigin {
  ProductConfigurationName = 'ProductConfigurationName',
  ConfigurationDocumentsTab = 'ConfigurationDocumentsTab',
  AddConfigurableApplicationFormPage = 'AddConfigurableApplicationFormPage',
  DeleteConfigurableApplicationFormPage = 'DeleteConfigurableApplicationFormPage',
  FormSettings = 'FormSettings',
}

export interface GetProductsAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.GetProducts;
  payload: ProductsDataFilter;
}

export interface GetProductsSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.GetProductsSuccess;
  payload: Product[];
}

export interface SetProductToDuplicateAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.SetProductToDuplicate;
  payload: Product | null;
}

export interface SetStatusChangeDataAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.SetStatusChangeData;
  payload: StatusChangeData | null;
}

export interface ChangeProductStatusAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.ChangeProductStatus;
  payload: {
    productId: string;
    status: ProductStatus;
  };
}

export interface ChangeProductStatusSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess;
  payload: Product;
}

export interface DuplicateProductAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.DuplicateProduct;
  payload: string;
}

export interface DuplicateProductSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.DuplicateProductSuccess;
  payload: Product;
}

export interface CreateProductAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.CreateProduct;
  payload: {
    name: string;
    borrowerType: BorrowerType;
    type: ProductType;
    status: ProductStatus;
  };
}

export interface CreateProductSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.CreateProductSuccess;
  payload: Product;
}

export interface UpdateProductAction extends ActionWithOrigin<UpdateProductActionOrigin> {
  type: LoanOriginationSystemProductsActionType.UpdateProduct;
  payload: {
    productId: string;
    product: UpdateProductParams;
  };
}

export interface UpdateProductSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.UpdateProductSuccess;
  payload: {
    product: Product;
  };
}

export interface GetProductAction extends ActionWithOrigin<GetProductActionOrigin> {
  type: LoanOriginationSystemProductsActionType.GetProduct;
  payload: {
    productId: string;
  };
}

export interface GetProductSuccessAction extends ActionWithOrigin<GetProductActionOrigin> {
  type: LoanOriginationSystemProductsActionType.GetProductSuccess;
  payload: {
    product: Product;
  };
}

export interface DownloadApiRequestTemplateAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.DownloadApiRequestTemplate;
  payload: {
    productId: string;
    requestType: ProductApiRequestTemplateType;
  };
}

export interface DownloadApiRequestTemplateSuccessAction extends AnyAction {
  type: LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateSuccess;
  payload: {
    response: DownloadResponse;
  };
}

export const getProducts = (filters: ProductsDataFilter): GetProductsAction => ({
  type: LoanOriginationSystemProductsActionType.GetProducts,
  payload: filters,
});

export const getProductsSuccess = (items: Product[]): GetProductsSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.GetProductsSuccess,
  payload: items,
});

export const setProductToDuplicate = (product: Product | null): SetProductToDuplicateAction => ({
  type: LoanOriginationSystemProductsActionType.SetProductToDuplicate,
  payload: product,
});

export const setStatusChangeData = (data: StatusChangeData | null): SetStatusChangeDataAction => ({
  type: LoanOriginationSystemProductsActionType.SetStatusChangeData,
  payload: data,
});

export const changeProductStatus = (productId: string, status: ProductStatus): ChangeProductStatusAction => ({
  type: LoanOriginationSystemProductsActionType.ChangeProductStatus,
  payload: {
    productId,
    status,
  },
});

export const changeProductStatusSuccess = (newProduct: Product): ChangeProductStatusSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess,
  payload: newProduct,
});

export const duplicateProduct = (productId: string): DuplicateProductAction => ({
  type: LoanOriginationSystemProductsActionType.DuplicateProduct,
  payload: productId,
});

export const duplicateProductSuccess = (newProduct: Product): DuplicateProductSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.DuplicateProductSuccess,
  payload: newProduct,
});

export const createProduct = (
  name: string,
  borrowerType: BorrowerType,
  type: ProductType,
  status: ProductStatus,
): CreateProductAction => ({
  type: LoanOriginationSystemProductsActionType.CreateProduct,
  payload: {
    name,
    borrowerType,
    type,
    status,
  },
});

export const createProductSuccess = (product: Product): CreateProductSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.CreateProductSuccess,
  payload: product,
});

export const updateProduct = (productId: string, product: UpdateProductParams): UpdateProductAction => ({
  type: LoanOriginationSystemProductsActionType.UpdateProduct,
  payload: {
    productId,
    product,
  },
});

export const updateProductSuccess = (product: Product): UpdateProductSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.UpdateProductSuccess,
  payload: { product },
});

export const getProduct = (productId: string): GetProductAction => ({
  type: LoanOriginationSystemProductsActionType.GetProduct,
  payload: {
    productId,
  },
});

export const getProductSuccess = (product: Product): GetProductSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.GetProductSuccess,
  payload: {
    product,
  },
});

export const downloadApiRequestTemplate = (
  productId: string,
  requestType: ProductApiRequestTemplateType,
): DownloadApiRequestTemplateAction => ({
  type: LoanOriginationSystemProductsActionType.DownloadApiRequestTemplate,
  payload: {
    productId,
    requestType,
  },
});

export const downloadApiRequestTemplateSuccess = (
  response: DownloadResponse,
): DownloadApiRequestTemplateSuccessAction => ({
  type: LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateSuccess,
  payload: {
    response,
  },
});

export const getProductsFailure = FailedActionCreator(LoanOriginationSystemProductsActionType.GetProductsFailure);
export const changeProductStatusFailure = FailedActionCreator(
  LoanOriginationSystemProductsActionType.ChangeProductStatusFailure,
);
export const duplicateProductFailure = FailedActionCreator(
  LoanOriginationSystemProductsActionType.DuplicateProductFailure,
);
export const createProductFailure = FailedActionCreator(LoanOriginationSystemProductsActionType.CreateProductFailure);
export const updateProductFailure = FailedActionCreator(LoanOriginationSystemProductsActionType.UpdateProductFailure);
export const getProductFailure = FailedActionCreator(LoanOriginationSystemProductsActionType.GetProductFailure);
export const downloadApiRequestTemplateFailure = FailedActionCreator(
  LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateFailure,
);
