import { AnyAction, Middleware } from 'redux';

import { ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS } from './ActionTypes';

import notification from 'handlers/notification/notificationActionCreator';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { RoutineHandler } from 'middlewares/Fetcher';

export const onEditCompanyUserSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS) {
      dispatch(getOrganizationInfo());
      notification.createNotification(
        getMessage(MessageType.UserInformationUpdated, {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
        }),
        'success',
        dispatch,
      );
    }
  },
);

export default onEditCompanyUserSuccessMiddleware;
