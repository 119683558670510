import { ChangePopupFiltersAction, ResetAllVariablesFilters } from 'Variables/Filters/ActionCreator';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { VariablesFiltersActionTypes } from './ActionTypes';
import { getVariablesRequest } from 'Variables/VariablesActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === VariablesFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllVariablesFilters =>
  action.type === VariablesFiltersActionTypes.ResetAllFilters;

const onVariablesFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetAllFiltersAction(action)) {
    const { variables } = getState();
    const { filters, sortingType } = variables;

    dispatch(getVariablesRequest(filters, sortingType));
  }

  return result;
}) as Middleware;

export default onVariablesFiltersChangeMiddleware;
