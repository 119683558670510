import React, { FC } from 'react';
import styles from './ConnectEmailsPopup.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';

interface ConnectEmailsPopupProps {
  emailAddress: string;
  onPopupClose: () => void;
}

const POPUP_TITLE = 'Connect Emails to DigiFi';

const ConnectEmailsPopup: FC<ConnectEmailsPopupProps> = ({ emailAddress, onPopupClose }) => {
  return (
    <PopUp title={POPUP_TITLE} closePopUp={onPopupClose}>
      <PopUpContent hasTopMargin>
        <p className={styles.description}>
          To connect emails to the DigiFi Loan Origination System, please CC or BCC the following email address on your
          outbound emails:
        </p>
        <p className={styles.emailAddress}>{emailAddress}</p>
        <Button kind="secondary" size="form" onClick={onPopupClose}>
          Close Window
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default ConnectEmailsPopup;
