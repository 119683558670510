import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { DecisionEngineApi } from 'api/Types';
import { getDecisionStrategyInfoRequest } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import {
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  DUPLICATE_BRANCH_REQUEST,
  DUPLICATE_BRANCH_SUCCESS,
  GET_BRANCH_INFO_REQUEST,
  REORDER_BRANCH_RULES,
  REORDER_BRANCH_RULES_SUCCESS,
  UPDATE_BRANCH_NAME_REQUEST,
  UPDATE_BRANCH_NAME_REQUEST_SUCCESS,
} from './ActionTypes';
import { batch } from 'react-redux';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/DecisionStrategyUpdatesActionCreator';
import {
  getBranchInfoRequestSuccess,
  getBranchInfoRequestFailure,
  GetBranchInfoRequestAction,
  UpdateBranchNameRequestAction,
  updateBranchNameRequestSuccess,
  updateBranchNameRequestError,
  getBranchInfoRequest,
  reorderBranchRulesRequestSuccess,
  reorderBranchRulesRequestError,
  ReorderBranchRulesRequestAction,
  deleteBranchRequestSuccess,
  deleteBranchRequestError,
  DeleteBranchRequestAction,
  DuplicateBranchRequestAction,
  duplicateBranchSuccess,
  duplicateBranchError,
  DuplicateBranchSuccessAction,
} from './ActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { BranchInfo, BranchParams, DuplicateBranchSuccessData, ReorderBranchRuleParams } from './Types';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { getBranchKey } from './utils/getBranchKey';

export const GetBranchInfoMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BranchInfo, GetBranchInfoRequestAction>(
    GET_BRANCH_INFO_REQUEST,
    getBranchInfoRequestSuccess,
    getBranchInfoRequestFailure,
    ({ payload }) => api.getBranchInfo(payload),
  );

export const UpdateBranchNameMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BranchParams, UpdateBranchNameRequestAction>(
    UPDATE_BRANCH_NAME_REQUEST,
    updateBranchNameRequestSuccess,
    updateBranchNameRequestError,
    ({ payload }) => api.updateBranchInfo(payload),
  );

export const DeleteBranchMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BranchParams, DeleteBranchRequestAction>(
    DELETE_BRANCH_REQUEST,
    deleteBranchRequestSuccess,
    deleteBranchRequestError,
    async ({ payload }) => {
      await api.deleteBranch(payload);
      return payload;
    },
  );

const isDeleteBranchSuccessAction = (action: AnyAction): action is DeleteBranchRequestAction =>
  action.type === DELETE_BRANCH_SUCCESS;

export const onDeleteBranchSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isDeleteBranchSuccessAction(action)) {
    batch(() => {
      const { branchName, strategyId } = action.payload;
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(
        getMessage(MessageType.BranchDeleted, {
          branchName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};

const isUpdateBranchNameSuccessAction = (action: AnyAction): action is UpdateBranchNameRequestAction =>
  action.type === UPDATE_BRANCH_NAME_REQUEST_SUCCESS;

export const onUpdateBranchNameSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isUpdateBranchNameSuccessAction(action)) {
    batch(() => {
      const { strategyId, moduleKey, branchIndex, branchName } = action.payload;
      dispatch(
        getBranchInfoRequest({
          strategyId,
          moduleKey,
          branchIndex,
        }),
      );
      dispatch(getDecisionStrategyInfoRequest(strategyId));
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));

      notification.createNotification(
        getMessage(MessageType.BranchNameUpdated, {
          branchName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};

export const ReorderBranchRulesMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ReorderBranchRuleParams, ReorderBranchRulesRequestAction>(
    REORDER_BRANCH_RULES,
    reorderBranchRulesRequestSuccess,
    reorderBranchRulesRequestError,
    async ({ payload }) => {
      try {
        await api.updateRulesOrder(payload);
        return payload;
      } catch (error) {
        const { moduleKey, branchIndex, ruleType } = payload;

        error.branchKey = getBranchKey(moduleKey, branchIndex);
        error.ruleType = ruleType;
        throw error;
      }
    },
  );

export const onUpdateRuleOrderSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === REORDER_BRANCH_RULES_SUCCESS) {
    const { strategyId } = action.payload;
    batch(() => {
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(getMessage(MessageType.RuleOrderUpdated), 'success', dispatch);
    });
  }
  return result;
};

export const DuplicateBranchMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<DuplicateBranchSuccessData, DuplicateBranchRequestAction>(
    DUPLICATE_BRANCH_REQUEST,
    duplicateBranchSuccess,
    duplicateBranchError,
    async ({ payload }) => {
      const branchData = await api.duplicateBranch(payload);
      return {
        ...branchData,
        originalBranchName: payload.branchName,
        strategyId: payload.strategyId,
      };
    },
  );

const isDuplicateBranchSuccessAction = (action: AnyAction): action is DuplicateBranchSuccessAction =>
  action.type === DUPLICATE_BRANCH_SUCCESS;

export const onDuplicateBranchSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isDuplicateBranchSuccessAction(action)) {
    const { strategyId, display_name: copiedBranchName, originalBranchName } = action.payload;
    batch(() => {
      dispatch(getDecisionStrategyInfoRequest(strategyId));
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(
        getMessage(MessageType.BranchDuplicated, {
          copiedBranchName,
          originalBranchName,
        }),
        'success',
        dispatch,
      );
    });
  }
  return result;
};
