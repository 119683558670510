import React from 'react';
import AsideLink from 'components/AsideLink/AsideLink';

interface CreateNewVariableLinkProps {
  onClick?: () => void;
}

const CreateNewVariableLink = ({ onClick }: CreateNewVariableLinkProps) => {
  return (
    <AsideLink href="/decision/variables/all?new" onClick={onClick}>
      Create New Variable
    </AsideLink>
  );
};

export default CreateNewVariableLink;
