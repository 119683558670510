import React, { ComponentType, PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import styles from 'components/SelectableItem/SelectableItem.module.scss';

export type PositionType = 'first' | 'last' | undefined;

export interface SelectableItemProps<Type> {
  title: string;
  description?: string;
  selected: boolean;
  type: Type;
  Image: ComponentType<{ className?: string }>;
  onClick: (type: Type) => void;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const getModuleIndex = (index: number, arrayLength: number): PositionType => {
  if (index === 0) {
    return 'first';
  }
  if (index === arrayLength - 1) {
    return 'last';
  }
  return undefined;
};

const SelectableItem = <Type,>({
  Image,
  title,
  description,
  onClick,
  selected,
  type,
  children,
  disabled,
  className,
}: PropsWithChildren<SelectableItemProps<Type>>) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(type);
    }
  };

  return (
    <div
      className={clsx(selected ? styles.activeContainer : styles.container, { [styles.disabled]: disabled }, className)}
      onClick={handleClick}
    >
      <div className={styles.imageContainer}>
        <Image className={selected ? styles.activeImage : styles.image} />
      </div>
      <div className={styles.infoContainer}>
        <div className={selected ? styles.activeTitle : styles.title}>{title}</div>
        <div className={selected ? styles.activeDescription : styles.description}>{description}</div>
        {children}
      </div>
    </div>
  );
};

export default SelectableItem;
