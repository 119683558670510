import { AnyAction } from 'redux';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { TemplatesFilterOptions } from 'api/LoanOriginationSystem/SendGridTemplateApi';

export interface FiltersResult {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  active: TemplatesFilterOptions | null;
}

export interface TemplatesSearchInputValueSetAction {
  type: LoanOriginationSystemTemplatesFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchInputValue: string;
  };
}

export interface FilterTemplatesByMembersAction extends AnyAction {
  type: LoanOriginationSystemTemplatesFiltersActionTypes.FilterTemplatesByMembers;
  payload: {
    members: UserInfo[];
  };
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemTemplatesFiltersActionTypes.ToggleFiltersPopup;
}

export interface ResetAllTemplatesFilters extends AnyAction {
  type: LoanOriginationSystemTemplatesFiltersActionTypes.ResetAllFilters;
}

export const setTemplatesSearchInputValue = (searchInputValue: string): TemplatesSearchInputValueSetAction => ({
  type: LoanOriginationSystemTemplatesFiltersActionTypes.SetSearchInputValue,
  payload: {
    searchInputValue,
  },
});

export const filterTemplatesByMembers = (members: UserInfo[]): FilterTemplatesByMembersAction => {
  return {
    type: LoanOriginationSystemTemplatesFiltersActionTypes.FilterTemplatesByMembers,
    payload: {
      members,
    },
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters,
    payload: {
      dueCreatedDateRange: {
        from: null,
        to: null,
      },
      dueUpdatedDateRange: {
        from: null,
        to: null,
      },
      active: null,
    },
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemTemplatesFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const resetAllTemplatesFilters = (): ResetAllTemplatesFilters => {
  return {
    type: LoanOriginationSystemTemplatesFiltersActionTypes.ResetAllFilters,
  };
};
