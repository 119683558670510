import React, { FC, useEffect, useState, Fragment, useMemo } from 'react';
import {
  ESignTemplate,
  ESignTemplateRecipient,
  ESignTemplateRecipientEntityType,
  ESignTemplateRecipientsMapping,
} from 'api/Core/ESignTemplatesApi';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import TransitionComponent from 'components/HeightTransitionComponent';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import TextInput from 'components/TextInput';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './RecipientsMappingPopup.module.scss';

export interface RecipientsMappingPopupProps {
  template: ESignTemplate;
  onClose: () => void;
  isSaveInProgress?: boolean;
  onSave: (recipientsMapping: ESignTemplateRecipientsMapping) => void;
}

const RECIPIENT_OPTIONS = [
  {
    value: ESignTemplateRecipientEntityType.Borrower,
    name: 'Borrower',
  },
  {
    value: ESignTemplateRecipientEntityType.CoBorrower,
    name: 'Co-Borrower',
  },
  {
    value: ESignTemplateRecipientEntityType.Intermediary,
    name: 'Intermediary',
  },
  {
    value: ESignTemplateRecipientEntityType.Other,
    name: 'Other',
  },
];

const RecipientsMappingPopup: FC<RecipientsMappingPopupProps> = ({ onClose, template, isSaveInProgress, onSave }) => {
  const [recipientsMapping, setRecipientsMapping] = useState(template.recipientsMapping);

  useEffect(() => {
    setRecipientsMapping(template.recipientsMapping);
  }, [template.recipientsMapping]);

  const handleRecipientOptionChange = (recipient: ESignTemplateRecipient, option: Option) => {
    if (!option.value) {
      const newRecipientsMapping = { ...recipientsMapping };

      delete newRecipientsMapping[recipient.recipientId];

      setRecipientsMapping(newRecipientsMapping);

      return;
    }

    setRecipientsMapping({
      ...recipientsMapping,
      [recipient.recipientId]: {
        type: option.value as ESignTemplateRecipientEntityType,
      },
    });
  };

  const handleRecipientAttributeChange = (
    recipient: ESignTemplateRecipient,
    attribute: 'email' | 'name',
    value: string,
  ) => {
    if (!value) {
      const newMappingEntry = { ...recipientsMapping[recipient.recipientId] };

      delete newMappingEntry[attribute];

      setRecipientsMapping({ ...recipientsMapping, [recipient.recipientId]: newMappingEntry });

      return;
    }

    setRecipientsMapping({
      ...recipientsMapping,
      [recipient.recipientId]: {
        ...recipientsMapping[recipient.recipientId],
        [attribute]: value,
      },
    });
  };

  const renderDisabledInput = (labelTitle: string, value?: string) => (
    <TextInput labelTitle={labelTitle} disabled value={value} />
  );

  const renderRecipientAdditionalInputs = (recipient: ESignTemplateRecipient) => {
    const emailLabel = `${recipient.name} Email`;
    const nameLabel = `${recipient.name} Name`;
    const emailValue = recipient.recipientEmail ?? (recipientsMapping[recipient.recipientId].email || '');
    const nameValue = recipient.recipientName ?? (recipientsMapping[recipient.recipientId].name || '');

    const renderEmailRecipientVariableSelector = () => (
      <VariableSelector
        labelTitle={emailLabel}
        value={emailValue}
        onChange={({ value }) => handleRecipientAttributeChange(recipient, 'email', value)}
        dataType="String"
      />
    );

    const renderNameRecipientVariableSelector = () => (
      <VariableSelector
        labelTitle={nameLabel}
        value={nameValue}
        onChange={({ value }) => handleRecipientAttributeChange(recipient, 'name', value)}
        dataType="String"
      />
    );

    return (
      <div className={styles.additionalInputsSection}>
        {recipient.recipientEmail
          ? renderDisabledInput(emailLabel, emailValue)
          : renderEmailRecipientVariableSelector()}
        {recipient.recipientName ? renderDisabledInput(nameLabel, nameValue) : renderNameRecipientVariableSelector()}
      </div>
    );
  };

  const renderInputs = () => {
    return template.recipients.map((recipient) => {
      const type = recipientsMapping[recipient.recipientId]?.type;

      return (
        <Fragment key={recipient.recipientId}>
          <AutoCompletion
            value={type || ''}
            labelTitle={`Recipient: ${recipient.name}`}
            onChange={(option) => handleRecipientOptionChange(recipient, option)}
            options={RECIPIENT_OPTIONS}
            disabled={!!recipient.recipientName || !!recipient.recipientEmail}
          />
          <TransitionComponent>
            {type === ESignTemplateRecipientEntityType.Other && renderRecipientAdditionalInputs(recipient)}
          </TransitionComponent>
        </Fragment>
      );
    });
  };

  const handleSave = () => {
    onSave(recipientsMapping);
  };

  const isSaveEnabled = useMemo(() => {
    const recipientsMappingKeys = Object.keys(recipientsMapping);

    if (recipientsMappingKeys.length !== Object.keys(template.recipientsMapping).length) {
      return true;
    }

    return recipientsMappingKeys.some((recipientId) => {
      const mappingEntity = recipientsMapping[recipientId];
      const templateMappingEntity = template.recipientsMapping[recipientId];

      if (mappingEntity?.type !== templateMappingEntity?.type) {
        return true;
      }

      if (mappingEntity?.type !== ESignTemplateRecipientEntityType.Other) {
        return false;
      }

      return mappingEntity.name !== templateMappingEntity.name || mappingEntity.email !== templateMappingEntity.email;
    });
  }, [recipientsMapping, template.recipientsMapping]);

  return (
    <PopUp title="Recipient Mapping" closePopUp={onClose}>
      <PopUpContent hasTopMargin>
        {renderInputs()}
        <Button
          kind="primary"
          size="form"
          disabled={!isSaveEnabled}
          onClick={handleSave}
          isLoading={isSaveInProgress}
          className={styles.saveButton}
        >
          Save
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default RecipientsMappingPopup;
