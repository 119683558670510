import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import useStateReset from 'hooks/useStateReset';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';
import { getEmails, openDeleteEmailPopup, sortEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import { resetAllEmailsFilters } from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import { getOrganizationEmail } from 'utils/emailGetters';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import EmailsTable from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable';
import { EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { useHistory } from 'react-router-dom';
import styles from './BorrowerEmails.module.scss';
import NoEmailsConnected from 'components/LoanOriginationSystem/NoEmailsConnected';
import { changeContextualViewStep } from 'LoanOriginationSystemBorrowers/ActionCreator';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';

interface BorrowerEmailsProps {
  borrowerId: string;
  borrowerEmail: string;
}

export const EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER = 1;

const BorrowerEmails: FC<BorrowerEmailsProps> = ({ borrowerId, borrowerEmail }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { filters, sortingType, isLoading } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  const organizationInfo = useOrganizationDetailedInfo();

  const organizationEmail = getOrganizationEmail(organizationInfo.id);

  useStateReset(LoanOriginationSystemEmailsActionTypes.ResetState);

  const filtersWithBorrowerId = {
    ...filters,
    selectedBorrower: borrowerId,
  };

  const params: EmailsPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    createdAtDateRange: filters.createdAtRange,
    selectedBorrower: borrowerId,
  };

  const emails = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  useEffect(() => {
    dispatch(getEmails(filtersWithBorrowerId, sortingType));

    return () => {
      dispatch(resetAllEmailsFilters());
    };
  }, []);

  const isNoEmails = !emails.length && !isLoading;

  const handleSort = (field: EmailsSortingField, ascending: boolean) => {
    dispatch(sortEmails(field, ascending, borrowerId));
  };

  const handleOpenEmail = (id: string) => {
    history.replace(`?edit=${borrowerId}&borrowerEmailId=${id}`);
    dispatch(changeContextualViewStep(EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER));
  };
  const handleDeleteEmail = (id: string) => dispatch(openDeleteEmailPopup(id));

  return (
    <div className={styles.emailsContainer}>
      {isNoEmails ? (
        <NoEmailsConnected
          headerText=""
          email={organizationEmail}
          borrowerEmail={borrowerEmail}
          hideHeader
          descriptionClassName={styles.noEmailsDescriptionContainer}
        />
      ) : (
        <EmailsTable
          paginationProps={paginationProps}
          emails={emails}
          onSort={handleSort}
          sortingType={sortingType}
          onOpenEmail={handleOpenEmail}
          onDeleteEmail={handleDeleteEmail}
          organizationEmail={getOrganizationEmail(organizationInfo.id)}
          searchValue={filters.searchInputValue}
          stickyMarginTop={TABLE_HEADER_OFFSET}
        />
      )}
    </div>
  );
};

export default BorrowerEmails;
