import RestrictPermissionsPage from 'pages/RestrictPermissions';
import React, { FC, ReactElement } from 'react';
import { ApplicationSectionName, ProductSection } from './Types';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ProductSectionAccessPermissionsMap } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';

interface RouteWithPermissionsProps {
  productSection: ProductSection;
  pageName: string;
  children: ReactElement;
}

const productSectionMenuEntries: Record<ProductSection, ApplicationSectionName> = {
  // LOS
  [ProductSection.LoanOriginationSystemApplications]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemBorrowers]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemIntermediaries]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemTasks]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemEmails]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemReporting]: ApplicationSectionName.LoanOriginationSystem,
  [ProductSection.LoanOriginationSystemConfiguration]: ApplicationSectionName.LoanOriginationSystem,
  // DE
  [ProductSection.DecisionEngineStrategies]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineProcessing]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineApiActivation]: ApplicationSectionName.DecisionEngine,
  [ProductSection.DecisionEngineReporting]: ApplicationSectionName.DecisionEngine,
  // Company Settings
  [ProductSection.CompanySettingsGeneral]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsPlanAndBilling]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsTeam]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsVariables]: ApplicationSectionName.CompanySettings,
  [ProductSection.CompanySettingsIntegrations]: ApplicationSectionName.CompanySettings,
};

const ProductSectionAccessCheck: FC<RouteWithPermissionsProps> = ({ productSection, pageName, children }) => {
  const productSectionAccessPermissions = useSelector<ReduxState, ProductSectionAccessPermissionsMap | null>(
    (state) => state.sessionInfo.permissions,
  );

  if (!productSectionAccessPermissions) {
    return null;
  }

  if (!productSectionAccessPermissions[productSection]) {
    const menuEntry = productSectionMenuEntries[productSection];

    return <RestrictPermissionsPage pageName={pageName} menuEntry={menuEntry} />;
  }

  return children;
};

export default ProductSectionAccessCheck;
