import React, { forwardRef, useState } from 'react';
import { findSelectedOption } from './utils';
import SelectInput, { SelectInputProps, Option } from 'components/SelectInput/SelectInput';

export type SearchableOptionAttribute = Extract<keyof Option, 'name' | 'description' | 'value'>;

export interface AutoCompletionSyncProps extends Omit<SelectInputProps, 'open' | 'userInput' | 'onInputChange'> {
  searchedOptionAttrs?: SearchableOptionAttribute[];
}

const AutoCompletionSync = forwardRef<HTMLInputElement, AutoCompletionSyncProps>(
  ({ value, options, onChange, searchedOptionAttrs = ['name'], ...selectInputProps }, ref) => {
    const selectedOption = findSelectedOption(options, value);
    const [userInput, setUserInput] = useState<string | null>(null);
    const matchingOptions = !userInput
      ? options
      : options.filter((option) => {
          const searchString = userInput.trim().toLowerCase();
          return searchedOptionAttrs.some((attr) => {
            const matchedValue = option[attr];
            return matchedValue && matchedValue.toLowerCase().includes(searchString);
          });
        });

    const handleInputChange = (inputValue: string) => setUserInput(inputValue);
    const handleChange = (option: Option) => {
      setUserInput(null);
      onChange(option);
    };

    return (
      <SelectInput
        selectedOption={selectedOption}
        userInput={userInput}
        options={matchingOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        {...selectInputProps}
        ref={ref}
      />
    );
  },
);

export default AutoCompletionSync;
