import React, { ChangeEvent, FC, useEffect } from 'react';
import TabNoItems from 'components/LoanOriginationSystem/ApplicationTabs/TabNoItems';
import TabHeader from 'components/LoanOriginationSystem/ApplicationTabs/TabHeader';
import { useDispatch, useSelector, batch } from 'react-redux';
import pagination from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/pagination';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableActionCell from 'components/Table/TableActionCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import Pagination from 'components/Pagination';
import { ApplicationNote } from 'api/Types';
import SIZES from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/TableColumnSizes';
import NoteRow from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/NoteRow';
import SearchInput from 'components/SearchInput';
import styles from './NotesTab.module.scss';
import SkeletonNoteLine from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/SceletonRow';
import Button from 'components/Button';
import { ReduxState } from 'types/redux';
import {
  getApplicationNotes,
  openApplicationNotePopup,
  setCurrentCurrentApplicationNote,
  sortApplicationNotes,
  setSearchValue,
} from 'LoanOriginationSystemApplicationPage/Notes/ActionCreator';
import { ApplicationNotePopUpType } from 'LoanOriginationSystemApplicationPage/Notes/Types';
import { ApplicationNotesActionType } from 'LoanOriginationSystemApplicationPage/Notes/ActionTypes';
import useSorting from 'hooks/useSorting';
import useStateReset from 'hooks/useStateReset';
import { ApplicationNotesSortingField } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';
import SearchNotFound from 'components/SearchNotFound';

interface NotesTabProps {
  applicationId: string;
}

const NotesTab: FC<NotesTabProps> = ({ applicationId }) => {
  const dispatch = useDispatch();
  const { search, sortingType, isLoading } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.notes,
  );

  const handleSort = (field: ApplicationNotesSortingField, ascending: boolean) => {
    dispatch(sortApplicationNotes(applicationId, { field, ascending }));
  };

  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, handleSort);

  const handleAddNote = () => dispatch(openApplicationNotePopup(ApplicationNotePopUpType.Add));

  const applicationNotes = pagination.usePaginatedItems({ applicationId, search, sortingType });
  const paginationProps = pagination.usePagination({ applicationId, search, sortingType });

  useStateReset(ApplicationNotesActionType.ResetState);

  useEffect(() => {
    dispatch(getApplicationNotes(applicationId, search, sortingType));
  }, []);

  const renderRow = (note: ApplicationNote) => (
    <NoteRow
      onClick={() => {
        batch(() => {
          dispatch(openApplicationNotePopup(ApplicationNotePopUpType.Update));
          dispatch(setCurrentCurrentApplicationNote(note.id));
        });
      }}
      searchInputValue={search}
      key={note.id}
      note={note}
      rowActions={[
        {
          title: 'Edit Note',
          handler: () => {
            batch(() => {
              dispatch(openApplicationNotePopup(ApplicationNotePopUpType.Update));
              dispatch(setCurrentCurrentApplicationNote(note.id));
            });
          },
          separatorRequired: true,
        },
        {
          title: 'Delete Note',
          handler: () => {
            batch(() => {
              dispatch(openApplicationNotePopup(ApplicationNotePopUpType.Delete));
              dispatch(setCurrentCurrentApplicationNote(note.id));
            });
          },
          danger: true,
        },
      ]}
    />
  );

  const handleSearchInputChange = (value: string) => {
    dispatch(setSearchValue(applicationId, value));
  };

  const handleSearchClear = () => dispatch(setSearchValue(applicationId, ''));

  const renderTable = () => {
    return (
      <>
        <Table>
          <TableHead useActions stickyMarginTop={TABLE_HEADER_OFFSET} sticky>
            <TableHeadCell
              width={SIZES.DATE}
              ascending={getSortingType(ApplicationNotesSortingField.UpdatedAt)}
              onClick={() => changeSorting(ApplicationNotesSortingField.UpdatedAt)}
            >
              Date
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.CONTENT}
              ascending={getSortingType(ApplicationNotesSortingField.Note)}
              onClick={() => changeSorting(ApplicationNotesSortingField.Note)}
            >
              Content
            </TableHeadCell>
            <TableHeadCell
              width={SIZES.AUTHOR}
              ascending={getSortingType(ApplicationNotesSortingField.CreatedBy)}
              onClick={() => changeSorting(ApplicationNotesSortingField.CreatedBy)}
            >
              Author
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent
              rows={applicationNotes}
              renderTableRow={renderRow}
              renderSkeletonTableRow={(index: number) => <SkeletonNoteLine key={index} />}
            />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </>
    );
  };

  const renderBody = () => {
    if (search && !paginationProps.itemsTotal && !isLoading) {
      return <SearchNotFound searchValue={search} />;
    }

    return renderTable();
  };

  const renderNoItems = () => (
    <TabNoItems title="No notes have been added yet!" buttonMessage="Add Note" onButtonClick={handleAddNote} />
  );

  const renderActions = () => (
    <Button kind="primary" size="default" onClick={handleAddNote} className={styles.addNoteButton}>
      Add Note
    </Button>
  );

  return (
    <>
      <TabHeader title="Notes" actions={renderActions()} />
      {(paginationProps.itemsTotal !== 0 || search) && (
        <SearchInput
          placeholder="Search"
          containerClassName={styles.searchInput}
          value={search}
          onChange={(event: ChangeEvent<HTMLInputElement>) => handleSearchInputChange(event.target.value)}
          onClear={handleSearchClear}
        />
      )}
      {paginationProps.itemsTotal === 0 && !isLoading && !search ? renderNoItems() : renderBody()}
    </>
  );
};

export default NotesTab;
