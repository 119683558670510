import Fetcher from 'middlewares/Fetcher';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { APPLICATION_NOTES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/pagination';
import { getApplicationNotesSuccess, getApplicationNotesFailure, GetApplicationNotesAction } from './ActionCreator';
import { ApplicationNotesActionType } from './ActionTypes';
import { ApplicationNotesApi } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import { ApplicationNote } from 'api/Types';

const GetApplicationNotesMiddleware = (api: ApplicationNotesApi) =>
  Fetcher<TableViewData<ApplicationNote> & { search: string }, GetApplicationNotesAction>(
    ApplicationNotesActionType.GetApplicationNotes,
    getApplicationNotesSuccess,
    getApplicationNotesFailure,
    async (action) => {
      const data = await api.getAll(
        action.payload.applicationId,
        {
          search: action.payload.search,
          count: APPLICATION_NOTES_PER_PAGE_DEFAULT,
          offset: 0,
        },
        action.payload.sortingType,
      );

      return {
        ...data,
        search: action.payload.search,
      };
    },
  );

export default GetApplicationNotesMiddleware;
