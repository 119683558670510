import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import React from 'react';
import { STRATEGY_GROUPS_TABLE_COLUMNS_SIZES } from 'components/StrategyGroups/StrategiesTableView/TableColumnSizes';

const SkeletonStrategyTableRow = () =>
  <TableRow disableHover>
    <TableBodyCell width={STRATEGY_GROUPS_TABLE_COLUMNS_SIZES.DISPLAY_NAME}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={STRATEGY_GROUPS_TABLE_COLUMNS_SIZES.VERSIONS}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>

export default SkeletonStrategyTableRow
