import Fetcher from 'middlewares/Fetcher';
import { UnlockBorrowerAction, unlockBorrowerFailure, unlockBorrowerSuccess } from './ActionCreator';
import { LoanOriginationSystemUnlockBorrowerType } from './ActionTypes';
import LoanOriginationSystemBorrowersRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const UnlockBorrowerMiddleware = (api: LoanOriginationSystemBorrowersRestApi) =>
  Fetcher<string, UnlockBorrowerAction>(
    LoanOriginationSystemUnlockBorrowerType.UnlockBorrower,
    unlockBorrowerSuccess,
    unlockBorrowerFailure,
    async (action) => {
      await api.unlockBorrower(action.payload);

      return action.payload;
    },
  );

export default UnlockBorrowerMiddleware;
