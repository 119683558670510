import React, { FC, ReactNode } from 'react';

import HomeApplicationCard from 'components/HomeApplicationCard';
import ListOfApplications from 'components/ListOfApplications';

import styles from './HomeDashboard.module.scss';
import { DigifiProduct, ProductAccess } from 'components/RouteWithPermissions/Types';
import WrapperWithTooltip from 'components/Tooltip';

export const CompanySettings = 'CompanySettings';

const DISABLED_CARD_TOOLTIP_TEXT = (
  <p>
    This product is not active for your company. <br />
    Please contact your account administrator to request access.
  </p>
);

export interface HomeDashboardCard {
  product: DigifiProduct | typeof CompanySettings;
  icon: ReactNode;
  title: string;
  description: string;
  redirectURL: string;
  basicProduct?: boolean;
}

interface HomeDashboardProps {
  cards: HomeDashboardCard[];
  userFirstName: string;
  productAccess: ProductAccess;
}

const availableApplications = [
  {
    title: 'Getting Started',
    applicationLink: 'https://docs.digifi.io/docs',
    listOfSubLinks: [
      { title: 'Manage Team Members', link: ' https://docs.digifi.io/docs/user-management' },
      { title: 'Set Up Data Structure', link: 'https://docs.digifi.io/docs/data-structure' },
      { title: 'Personalize Your Account', link: 'https://docs.digifi.io/docs/overview-of-my-account' },
      { title: 'Basic API Setup', link: 'https://docs.digifi.io/docs/api-setup' },
    ],
  },
  {
    title: 'Loan Origination System',
    applicationLink: 'https://docs.digifi.io/docs/overview',
    listOfSubLinks: [
      { title: 'Set Up The LOS', link: 'https://docs.digifi.io/docs/setting-up-the-los' },
      { title: 'Applications', link: 'https://docs.digifi.io/docs/applications' },
      { title: 'Borrowers', link: 'https://docs.digifi.io/docs/borrowers' },
      { title: 'Intermediaries', link: 'https://docs.digifi.io/docs/intermediaries' },
    ],
  },
  {
    title: 'Decision Engine',
    applicationLink: 'https://docs.digifi.io/docs/decision-automation-strategies',
    listOfSubLinks: [
      { title: 'Strategy Building', link: 'https://docs.digifi.io/docs/strategy-building' },
      { title: 'Versions & Locking', link: 'https://docs.digifi.io/docs/versions-locking' },
      { title: 'Decision Processing', link: 'https://docs.digifi.io/docs/processing-rules-engine' },
      { title: 'External Integrations', link: 'https://docs.digifi.io/docs/integrations' },
    ],
  },
];

const isApplicationCardEnabled = (product: DigifiProduct | typeof CompanySettings, productAccess: ProductAccess) => {
  return product === CompanySettings || productAccess[product];
};

const HomeDashboard: FC<HomeDashboardProps> = ({ cards, userFirstName, productAccess }) => {
  return (
    <div className={styles.dashboard}>
      <h1>Dashboard</h1>
      <h3>
        <span>{userFirstName}</span>, welcome to the DigiFi Platform!
      </h3>
      <div className={styles.dashboard__cardContainer}>
        {cards.map((item) => (
          <WrapperWithTooltip
            key={item.product}
            tooltip={!isApplicationCardEnabled(item.product, productAccess) && DISABLED_CARD_TOOLTIP_TEXT}
            position="top"
          >
            <div className={styles.card}>
              <HomeApplicationCard
                name={item.title}
                description={item.description}
                link={item.redirectURL}
                icon={item.icon}
                disabled={!isApplicationCardEnabled(item.product, productAccess)}
              />
            </div>
          </WrapperWithTooltip>
        ))}
      </div>
      <h2>Helpful Resources</h2>
      <div className={styles.dashboardLinks}>
        {availableApplications.map((application) => (
          <ListOfApplications
            key={application.title}
            title={application.title}
            mainLink={application.applicationLink}
            listOfSubLinks={application.listOfSubLinks}
          />
        ))}
      </div>
    </div>
  );
};

export default HomeDashboard;
