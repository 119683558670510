import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';
import { LoanOriginationSystemApplicationTabsState } from './Types';
import {
  CreateApplicationTabSuccessAction,
  DeleteApplicationTabSuccessAction,
  GetApplicationTabsActionSuccess,
  UpdateApplicationTabSuccessAction,
} from './ActionCreator';

export type LoanOriginationSystemApplicationTabsActions =
  | GetApplicationTabsActionSuccess
  | UpdateApplicationTabSuccessAction
  | CreateApplicationTabSuccessAction
  | DeleteApplicationTabSuccessAction;

const initialState: LoanOriginationSystemApplicationTabsState = {
  tabsById: {},
  tabsIdsByProductId: {},
};

const getTabsById = (tabs: ApplicationTab[]) => {
  return tabs.reduce(
    (tabsById, tab) => ({
      ...tabsById,
      [tab.id]: tab,
    }),
    {},
  );
};

const loanOriginationSystemApplicationTabsReducer = (
  state = initialState,
  action: LoanOriginationSystemApplicationTabsActions,
): LoanOriginationSystemApplicationTabsState => {
  switch (action.type) {
    case LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabsSuccess: {
      return {
        ...state,
        tabsById: {
          ...state.tabsById,
          ...getTabsById(action.payload.tabs),
        },
        tabsIdsByProductId: {
          ...state.tabsIdsByProductId,
          [action.payload.productId]: action.payload.tabs.map((tab) => tab.id),
        },
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess: {
      return {
        ...state,
        tabsById: {
          ...state.tabsById,
          [action.payload.tab.id]: action.payload.tab,
        },
        tabsIdsByProductId: {
          ...state.tabsIdsByProductId,
          [action.payload.tab.productId]: [
            ...(state.tabsIdsByProductId[action.payload.tab.productId] || []),
            action.payload.tab.id,
          ],
        },
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabSuccess: {
      return {
        ...state,
        tabsById: {
          ...state.tabsById,
          [action.payload.tab.id]: action.payload.tab,
        },
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabSuccess: {
      const tab = state.tabsById[action.payload.deletedId];

      return {
        ...state,
        tabsIdsByProductId: {
          [tab.productId]: (state.tabsIdsByProductId[tab.productId] || []).filter(
            (tabId) => tabId !== action.payload.deletedId,
          ),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemApplicationTabsReducer;
