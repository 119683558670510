import React from 'react';
import SearchInput from 'components/SearchInput';
import styles from './Header.module.scss';
import Button from 'components/Button';
import { openConnectEmailsPopup } from 'LoanOriginationSystemEmails/ActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getOrganizationEmail } from 'utils/emailGetters';
import { getMailtoURI } from 'Email/utils';

interface HeaderProps {
  searchInputValue: string;
  subTitleHidden: boolean;
  onSearch: (search: string) => void;
  borrowerEmail: string;
  coBorrowerEmail: string;
}

const Header = ({ searchInputValue, subTitleHidden, onSearch, borrowerEmail, coBorrowerEmail }: HeaderProps) => {
  const dispatch = useDispatch();

  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);

  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const onSearchClear = () => {
    onSearch('');
  };

  const handleConnectEmails = () => {
    dispatch(openConnectEmailsPopup());
  };

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: [borrowerEmail, coBorrowerEmail],
      cc: getOrganizationEmail(organizationInfo.id),
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Emails</div>
        <div className={styles.buttonContainer}>
          <Button kind="secondary" className={styles.connectEmailsButton} onClick={handleConnectEmails}>
            Connect Emails
          </Button>
          <Button kind="primary" className={styles.sendEmailButton} onClick={handleSendEmail}>
            Send Email
          </Button>
        </div>
      </div>
      {!subTitleHidden && (
        <div className={styles.subTitle}>
          <SearchInput
            placeholder="Search"
            containerClassName={styles.subTitle__search}
            value={searchInputValue}
            onChange={onSearchInputChange}
            onClear={onSearchClear}
          />
        </div>
      )}
    </div>
  );
};

export default Header;
