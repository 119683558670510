import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import styles from './InvitationLinkExpired.module.scss';
import { ErrorInSystemImage } from 'static/images';
import Button from 'components/Button';

interface InvitationLinkExpiredProps {
  onSignInClick: () => void;
}

const InvitationLinkExpired: FC<InvitationLinkExpiredProps> = ({ onSignInClick }) => {
  const subTitle = (
    <>
      <div className={styles.subTitleRow}>
        Your verification link has expired. Please contact your account administrator.
      </div>
    </>
  );

  return (
    <AuthLayout title="Invitation Link Expired" subTitle={subTitle} titleImage={<ErrorInSystemImage />}>
      <Button size="form" fullWidth onClick={onSignInClick}>
        Go To Sign In
      </Button>
    </AuthLayout>
  );
};

export default InvitationLinkExpired;
