import React, { useEffect } from 'react';
import MainLayout, { PageWrapper, PageContent, PageFooter } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import Footer from 'components/Footer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { clearStrategyGroupsData, getStrategyGroupsData } from 'StrategyGroupsData/Actions';
import { StrategyGroupsState } from 'StrategyGroupsData/Reducer';
import StrategyGroupsComponent from 'components/StrategyGroups';
import pagination from './Pagination';
import VersionsView from 'components/VersionsView/VersionsViewConnector';
import { useQueryParams } from 'hooks/useQueryParam';
import NoStrategiesAvailable from 'components/NoStrategiesAvailable';
import styles from './StrategyGroups.module.scss';
import ArchiveStrategyWizard from 'components/ArchiveStrategyWizard/ArchiveStrategyWizardConnector';
import DeleteStrategyWizard from 'DeleteStrategyWizard/DeleteStrategyWizardConnector';
import NavigationLinkId from 'enums/NavigationLinkId';
import DuplicateStrategyWizard from 'DuplicateStrategyWizard';

const leftNav = makeLeftNavigation(NavigationLinkId.Strategies, ApplicationSectionName.DecisionEngine);

const StrategyGroups = () => {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const closeContextualView = useCloseContextualView();
  const { items, searchInputValue, showArchived, selectedUpdaters, versionsStatuses, page, loading } = useSelector<
    ReduxState,
    StrategyGroupsState
  >(({ strategyGroups }) => strategyGroups);
  const paginationProps = pagination.usePagination({
    searchInputValue,
    showArchived,
    selectedUpdaters,
    versionsStatuses,
  });
  const groups = pagination.usePaginatedItems({ searchInputValue, showArchived, selectedUpdaters, versionsStatuses });

  useEffect(() => {
    if (!items.length) {
      dispatch(getStrategyGroupsData());
    }

    return () => {
      dispatch(clearStrategyGroupsData());
    };
  }, []);

  const getContextualView = () => {
    if (!params.has('strategy-name')) {
      return null;
    }

    return (
      <VersionsView
        onClose={closeContextualView}
        strategyName={params.get('strategy-name')!}
        initialShowArchived={showArchived}
      />
    );
  };

  const isNoStrategiesAvailable =
    !items.length && !searchInputValue && !selectedUpdaters.length && page === 1 && !loading;

  return (
    <>
      <MainLayout leftNav={leftNav} contextualView={getContextualView()} closeContextualView={closeContextualView}>
        <PageWrapper>
          <PageContent className={isNoStrategiesAvailable ? styles.noGroupsAvailableView : undefined}>
            {isNoStrategiesAvailable ? (
              <NoStrategiesAvailable />
            ) : (
              <StrategyGroupsComponent
                groups={groups}
                paginationProps={paginationProps}
                searchInputValue={searchInputValue}
              />
            )}
          </PageContent>
          <PageFooter>
            <Footer separator />
          </PageFooter>
        </PageWrapper>
      </MainLayout>
      <DeleteStrategyWizard />
      <ArchiveStrategyWizard />
      <DuplicateStrategyWizard />
    </>
  );
};

export default StrategyGroups;
