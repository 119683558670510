import { AnyAction } from 'redux';
import { LoanOriginationSystemCreateIntermediaryTypes } from './ActionTypes';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface CreateIntermediaryAction extends AnyAction {
  type: LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediary;
  payload: {
    data: FormLayoutData;
  };
}

export interface CreateIntermediarySuccessAction extends AnyAction {
  type: LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediarySuccess;
}

export const createIntermediary = (data: FormLayoutData): CreateIntermediaryAction => ({
  type: LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediary,
  payload: {
    data,
  },
});

export const createIntermediarySuccess = (): CreateIntermediarySuccessAction => ({
  type: LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediarySuccess,
});

export const createIntermediaryFailure = FailedActionCreator(
  LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediaryFailure,
);
