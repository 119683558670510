import { LoanOriginationSystemTasksState } from './Types';
import {
  CloseDeleteTaskPopupAction,
  DeleteTaskAction,
  GetTasksSuccessAction,
  OpenDeleteTaskPopupAction,
  SortTasksAction,
  GetTasksAction,
} from './ActionCreator';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import tasksPagination, { TASKS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/TasksDashboard/Pagination';
import { AnyAction } from 'redux';
import { initialState as filtersInitialState, loanOriginationSystemTasksFilterReducer } from './Filters/Reducer';
import { initialState as createTaskInitialState, loanOriginationSystemCreateTaskReducer } from './CreateTask/Reducer';
import { initialState as editTaskInitialState, loanOriginationSystemEditTaskReducer } from './EditTask/Reducer';
import { ChangePopupFiltersAction, FilterTasksByMembersAction, ResetAllTasksFilters } from './Filters/ActionCreator';
import { TasksSortingFields } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { LoanOriginationSystemTasksFiltersActionTypes } from './Filters/ActionTypes';
import withStateReset from 'utils/reducers/withStateReset';

type LoanOriginationSystemTasksActions =
  | GetTasksAction
  | GetTasksSuccessAction
  | SortTasksAction
  | OpenDeleteTaskPopupAction
  | CloseDeleteTaskPopupAction
  | DeleteTaskAction
  | ResetAllTasksFilters
  | FilterTasksByMembersAction
  | ChangePopupFiltersAction;

type LoanOriginationSystemTasksType = (
  state: LoanOriginationSystemTasksState,
  action: AnyAction,
) => LoanOriginationSystemTasksState;

const initialState: LoanOriginationSystemTasksState = {
  items: [],
  page: 1,
  itemsPerPage: TASKS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  searched: {},
  filters: filtersInitialState,
  sortingType: {
    field: TasksSortingFields.DueDate,
    ascending: true,
  },
  taskIdToDelete: null,
  isDeleteInProgress: false,
  createTask: createTaskInitialState,
  editTask: editTaskInitialState,
};

const loanOriginationSystemTasksReducer = (
  state = initialState,
  action: LoanOriginationSystemTasksActions,
): LoanOriginationSystemTasksState => {
  switch (action.type) {
    case LoanOriginationSystemTasksActionTypes.GetTasks: {
      const { notReload } = action.payload;

      if (notReload) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        itemsTotal: undefined,
      };
    }
    case LoanOriginationSystemTasksActionTypes.GetTasksSuccess: {
      if (action.payload.searchInputValue) {
        return {
          ...state,
          selectedApplicationId: action.payload.applicationId,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: TASKS_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        selectedApplicationId: action.payload.applicationId,
        page: 1,
        itemsPerPage: state?.itemsPerPage || TASKS_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
        isDeleteInProgress: false,
        taskIdToDelete: null,
      };
    }

    case LoanOriginationSystemTasksActionTypes.SortTasks: {
      return {
        ...state,
        sortingType: action.payload,
      };
    }
    case LoanOriginationSystemTasksActionTypes.OpenDeleteTaskPopup: {
      return {
        ...state,
        taskIdToDelete: action.payload.taskIdToDelete,
      };
    }
    case LoanOriginationSystemTasksActionTypes.CloseDeleteTaskPopup: {
      return {
        ...state,
        taskIdToDelete: null,
      };
    }
    case LoanOriginationSystemTasksActionTypes.DeleteTask: {
      return {
        ...state,
        isDeleteInProgress: true,
      };
    }

    case LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers:
    case LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        itemsTotal: undefined,
        filters: loanOriginationSystemTasksFilterReducer(state.filters, action),
      };
    }

    default:
      return {
        ...state,
        filters: loanOriginationSystemTasksFilterReducer(state.filters, action),
        createTask: loanOriginationSystemCreateTaskReducer(state.createTask, action),
        editTask: loanOriginationSystemEditTaskReducer(state.editTask, action),
      };
  }
};

export default tasksPagination.wrapReducer<LoanOriginationSystemTasksState>(
  withStateReset(
    loanOriginationSystemTasksReducer,
    LoanOriginationSystemTasksActionTypes.ResetState,
    () => initialState,
  ) as LoanOriginationSystemTasksType,
);
