import React, { FC } from 'react';
import styles from './RestrictonOrganizationPermissions.module.scss';
import Footer from 'components/Footer';
import MainLayout from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageFooter from 'MainLayout/PageFooter';
import PageContent from 'MainLayout/PageContent';
import PageWrapper from 'MainLayout/PageWrapper';
import { DigifiProduct } from 'components/RouteWithPermissions/Types';
import RestrictOrganizationPermissions from 'components/RestrictOrganizationPermissions';
import productsList from 'components/LeftNav/productsList';

interface RestrictOrganizationPermissionsPageProps {
  product: DigifiProduct;
}

const RestrictOrganizationPermissionsPage: FC<RestrictOrganizationPermissionsPageProps> = ({ product }) => {
  // For each product that we check access to, the corresponding menu entry has `product` property set.
  const menuEntry = productsList.find((entry) => entry.product === product)!;

  const leftNav = makeLeftNavigation('', menuEntry.title, true);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent className={styles.restrictPermissionsContainer}>
          <RestrictOrganizationPermissions product={product} />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default RestrictOrganizationPermissionsPage;
