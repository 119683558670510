import { AnyAction } from 'redux';
import NotificationActionType from './notificationActionType';

import { NotificationItem } from './notificationTypes';

const initialState: Array<NotificationItem> = [];

const notificationReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case NotificationActionType.ShowNotification:
      return [...state, action.payload];
    case NotificationActionType.HideNotification:
      return state.filter((notification) => notification.id !== action.payload.id);
    default:
      return state;
  }
};

export default notificationReducer;
