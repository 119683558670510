import { DataFilter, DataViewSortingType, DateRange, ESignIntegrationType, TableViewData } from 'api/Types';
import CoreSystemApi from 'api/Core/CoreSystemApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface ESignTemplatesDataFilter extends DataFilter {
  status?: ESignTemplateFilterStatus | null;
  createdDateRange: DateRange;
  updatedDateRange: DateRange;
  integrationId?: string;
  members?: UserInfo[];
}

export enum ESignTemplatesSortingField {
  Name = 'name',
  UpdatedAt = 'updatedAt',
}

export enum ESignTemplateFilterStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum ESignTemplateFieldType {
  Text = 'text',
  Checkbox = 'checkbox',
  Radio = 'radiogroup',
}

export interface ESignTemplateField {
  name: string;
  fieldId: string;
  type: ESignTemplateFieldType;
  recipientId?: string;
  required?: boolean;
}

export interface ESignTemplateRecipient {
  recipientId: string;
  name: string;
  recipientName?: string;
  recipientEmail?: string;
}

export enum ESignTemplateRecipientEntityType {
  Borrower = 'Borrower',
  CoBorrower = 'Co-Borrower',
  Intermediary = 'Intermediary',
  Other = 'Other',
}

export interface ESignTemplateRecipientMappingEntity {
  type: ESignTemplateRecipientEntityType;
  name?: string;
  email?: string;
}

export type ESignTemplateRecipientsMapping = Record<string, ESignTemplateRecipientMappingEntity>;

export interface ESignTemplate {
  id: string;
  name: string;
  templateId: string;
  active: boolean;
  fields: ESignTemplateField[];
  recipients: ESignTemplateRecipient[];
  variablesMapping: Record<string, string>;
  recipientsMapping: ESignTemplateRecipientsMapping;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
  createdAt: Date;
  updatedAt: Date;
}

export type ESignTemplatesSortingType = DataViewSortingType<ESignTemplatesSortingField>;

export interface ESignTemplatesApi {
  getAll(
    filters: ESignTemplatesDataFilter,
    sortingType: ESignTemplatesSortingType,
  ): Promise<TableViewData<ESignTemplate>>;
  getDropdownOptions(
    search: string,
    integrationType: ESignIntegrationType,
    abortSignal: AbortSignal,
  ): Promise<ESignTemplate[]>;
  findById(id: string): Promise<ESignTemplate>;
  importBatch(templates: Array<{ name: string; templateId: string }>): Promise<ESignTemplate[]>;
  update(id: string, template: Partial<ESignTemplate>): Promise<ESignTemplate>;
  delete(id: string): Promise<void>;
}

export default class ESignTemplatesRestApi extends CoreSystemApi<ESignTemplate> implements ESignTemplatesApi {
  protected resourceName = 'e-sign-templates';

  public getAll(filters: ESignTemplatesDataFilter, sortingType: ESignTemplatesSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters.status) {
      params.append('active', filters.status === ESignTemplateFilterStatus.Active ? 'true' : 'false');
    }

    if (filters.integrationId) {
      params.append('integrationId', filters.integrationId);
    }

    if (filters.members) {
      filters.members.forEach((member) => params.append('teamMemberIds', member.id));
    }

    if (filters?.createdDateRange.from) {
      params.append('createdAtFrom', filters.createdDateRange.from.toISOString());
    }

    if (filters?.createdDateRange.to) {
      params.append('createdAtTo', filters.createdDateRange.to.toISOString());
    }

    if (filters?.updatedDateRange.from) {
      params.append('updatedAtFrom', filters.updatedDateRange.from.toISOString());
    }

    if (filters?.updatedDateRange.to) {
      params.append('updatedAtTo', filters.updatedDateRange.to.toISOString());
    }

    return this.getResources<TableViewData<ESignTemplate>>(params);
  }

  public async getDropdownOptions(
    search: string,
    integrationType: ESignIntegrationType,
    abortSignal: AbortSignal,
  ): Promise<ESignTemplate[]> {
    const params = new URLSearchParams();

    params.append('integrationType', integrationType);
    params.append('search', search);

    const { items } = await this.getResources<TableViewData<ESignTemplate>>(params, abortSignal);

    return items;
  }

  public findById(id: string) {
    return this.getResourceById(id);
  }

  public importBatch(templates: Array<{ name: string; templateId: string }>) {
    return this.fetch<ESignTemplate[]>(`/${this.resourceName}/import-batch`, 'POST', { templates });
  }

  public update(id: string, template: Partial<ESignTemplate>) {
    return this.updateResource(id, { template });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
