import React from 'react';
import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import monetaryInputStyles from './MonetaryInput.module.scss';
import monetaryInputStubStyles from './MonetaryInputStub.module.scss';

export interface MonetaryInputStubProps extends TextInputStubProps {
  currencySymbol: string;
}

const MonetaryInputStub = ({ currencySymbol, placeholder, ...restProps }: MonetaryInputStubProps) => {
  const renderPlaceholder = () => (
    <>
      <div className={monetaryInputStubStyles.currencySymbolContainer}>
        <span className={monetaryInputStyles.symbol}>{currencySymbol}</span>
      </div>
      {placeholder}
    </>
  );

  return <TextInputStub {...restProps} placeholder={renderPlaceholder()} />;
};

export default MonetaryInputStub;
