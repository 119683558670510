import { normalize } from 'normalizr';
import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationDataTabCardsApi,
  NormalizedApplicationDataTabCard,
} from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabCardsActionType } from './ActionTypes';
import {
  getApplicationDataTabCardsSuccess,
  getApplicationDataTabCardsFailure,
  GetApplicationDataTabCardsAction,
  GetApplicationDataTabCardsResult,
} from './ActionCreator';
import ApplicationDataTabCardSchema from 'schemas/ApplicationDataTabCardSchema';

const GetApplicationDataTabCardsMiddleware = (api: ApplicationDataTabCardsApi) =>
  Fetcher<GetApplicationDataTabCardsResult, GetApplicationDataTabCardsAction>(
    ApplicationDataTabCardsActionType.GetApplicationDataTabCards,
    getApplicationDataTabCardsSuccess,
    getApplicationDataTabCardsFailure,
    async (action) => {
      const cards = await api.find(action.payload.tabId);

      const normalizedData = normalize(cards, [ApplicationDataTabCardSchema]);

      return {
        tabId: action.payload.tabId,
        cards: normalizedData.entities.cards || ({} as Record<string, NormalizedApplicationDataTabCard>),
        applicationVariableConfigurations: normalizedData.entities.applicationVariableConfigurations as Record<
          string,
          ApplicationDataTabVariableConfiguration
        >,
        cardIds: normalizedData.result as string[],
      };
    },
  );

export default GetApplicationDataTabCardsMiddleware;
