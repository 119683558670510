import APIRest from 'api/APIRest';

interface GetDecisionRunsCountFilters {
  createdAtFrom?: Date;
  createdAtTo?: Date;
}

export interface DecisionRunApi {
  getDecisionRunsCount(filters: GetDecisionRunsCountFilters): Promise<number>;
}

export default class DecisionRunApiRest extends APIRest implements DecisionRunApi {
  protected origin = process.env.REACT_APP_DE_API_URL;

  public async getDecisionRunsCount(filters: GetDecisionRunsCountFilters) {
    const params = new URLSearchParams();

    if (filters.createdAtFrom) {
      params.append('createdAtFrom', filters.createdAtFrom.toISOString());
    }

    if (filters.createdAtTo) {
      params.append('createdAtTo', filters.createdAtTo.toISOString());
    }

    const { total } = await this.fetch<{ total: number }>(`/decision-runs/total-count?${params}`);

    return total;
  }
}
