import React from 'react';
import { DataViewSortingType, VariableValue } from 'api/Types';
import CustomCheckbox from 'components/CustomCheckbox';
import Table, { TableBody, TableBodyCell, TableHead, TableHeadCell, TableRow } from 'components/Table';
import styles from './SelectVariablesTable.module.scss';
import formatValueByDataType from 'utils/formatValueByDataType';
import SelectVariablesTableSizes from './SelectVariablesTableSizes';
import SelectVariablesTableRowSkeleton from './SelectVariablesTableRowSkeleton/SelectVariablesTableRowSkeleton';
import useSorting from 'hooks/useSorting';
import ApplicationVariableSortingField from 'LoanOriginationSystemApplicationPage/ApplicationVariableSortingField';
import clsx from 'clsx';
import { Variable } from 'Variables/VariablesTypes';

interface SelectVariablesTableProps {
  variables: Variable[] | null;
  values: Record<string, VariableValue>;
  className: string;
  selectedVariables: string[];
  isVariableDisabled: (variable: Variable) => boolean;
  onSelect: (variable: Variable, selected: boolean) => void;
  onSort: (field: ApplicationVariableSortingField, ascending: boolean) => void;
  variableSortingType: DataViewSortingType<ApplicationVariableSortingField>;
  hiddenVariables?: string[];
}

const SKELETON_ROWS_COUNT = 10;

const SelectVariablesTable = ({
  variables,
  values,
  className,
  isVariableDisabled,
  selectedVariables,
  onSelect,
  onSort,
  variableSortingType,
  hiddenVariables,
}: SelectVariablesTableProps) => {
  const renderRowSkeletons = () => {
    return Array.from({ length: SKELETON_ROWS_COUNT }).map((element, index) => (
      <SelectVariablesTableRowSkeleton key={index} />
    ));
  };

  const [changeSorting, getSortingType] = useSorting<ApplicationVariableSortingField>(
    variableSortingType.field,
    variableSortingType.ascending,
    onSort,
  );

  const variablesState = variables?.reduce(
    (result, variable) => ({
      ...result,
      [variable.id]: isVariableDisabled(variable),
    }),
    {},
  ) as Record<string, boolean>;

  return (
    <Table className={className}>
      <TableHead>
        <TableHeadCell width={SelectVariablesTableSizes.CHECKBOX} />
        <TableHeadCell
          width={SelectVariablesTableSizes.NAME}
          ascending={getSortingType(ApplicationVariableSortingField.DisplayName)}
          onClick={() => changeSorting(ApplicationVariableSortingField.DisplayName)}
        >
          Variable
        </TableHeadCell>
        <TableHeadCell
          width={SelectVariablesTableSizes.VALUE}
          ascending={getSortingType(ApplicationVariableSortingField.Value)}
          onClick={() => changeSorting(ApplicationVariableSortingField.Value)}
        >
          Value
        </TableHeadCell>
      </TableHead>
      <TableBody className={styles.body}>
        {!variables && renderRowSkeletons()}
        {variables?.map((variable) => {
          if (hiddenVariables?.includes(variable.systemName)) {
            return null;
          }

          return (
            <TableRow
              key={variable.systemName}
              className={clsx(variablesState[variable.id] && styles.disabledRow)}
              disableHover={variablesState[variable.id]}
              onClick={() =>
                !variablesState[variable.id] && onSelect(variable, !selectedVariables.includes(variable.systemName))
              }
            >
              <TableBodyCell width={SelectVariablesTableSizes.CHECKBOX}>
                <CustomCheckbox
                  checked={selectedVariables.includes(variable.systemName)}
                  disabled={variablesState[variable.id]}
                  onChange={(event, checked) => onSelect(variable, checked)}
                />
              </TableBodyCell>
              <TableBodyCell width={SelectVariablesTableSizes.NAME}>{variable.displayName}</TableBodyCell>
              <TableBodyCell width={SelectVariablesTableSizes.VALUE}>
                {formatValueByDataType(values[variable.systemName], variable)}
              </TableBodyCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default SelectVariablesTable;
