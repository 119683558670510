import React, { useState } from 'react';
import clsx from 'clsx';
import { ApplicationFormPage, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import CustomCheckbox from 'components/CustomCheckbox';
import { productTypeOptions } from 'components/LoanOriginationSystem/ProductBasicInfoForm/ProductTypeOptions';
import styles from './CreateNewApplicationPopup.module.scss';

export interface CreateNewApplicationPopupProps {
  products: Product[];
  initialProduct?: Product | null;
  onSubmit: (product: Product, additionalFormPages: ApplicationFormPage[]) => void;
  onClose: () => void;
}

const CreateNewApplicationPopup = ({ products, initialProduct, onClose, onSubmit }: CreateNewApplicationPopupProps) => {
  const [selectedProductId, setSelectedProductId] = useState(initialProduct?.id);
  const [additionalApplicationFormPages, setAdditionalApplicationFormPages] = useState<ApplicationFormPage[]>([]);
  const selectedProduct = products.find((product) => product.id === selectedProductId);

  const handleProductChange = (product: Product) => {
    setSelectedProductId(product.id);
    setAdditionalApplicationFormPages([]);
  };

  const renderProduct = (product: Product) => {
    const productTypeOption = productTypeOptions[product.type];
    const Image = productTypeOption.image;

    const isSelected = selectedProductId === product.id;

    return (
      <div
        key={product.id}
        onClick={() => handleProductChange(product)}
        className={clsx(styles.product, isSelected && styles.selectedProduct)}
      >
        <div className={styles.productContent}>
          <div className={styles.productType}>{productTypeOption.name}</div>
          <h5 className={styles.productName}>{product.name}</h5>
        </div>
        <div>
          <Image className={styles.productTypeImage} />
        </div>
      </div>
    );
  };

  const handleAdditionalApplicationFormPagesChange = (formPageToHandle: ApplicationFormPage) => {
    return (_event: React.ChangeEvent, checked: boolean) => {
      setAdditionalApplicationFormPages(
        checked
          ? [...additionalApplicationFormPages, formPageToHandle]
          : additionalApplicationFormPages.filter((formPage) => formPageToHandle !== formPage),
      );
    };
  };

  const renderCheckbox = (label: string, formPage: ApplicationFormPage) => (
    <div className={styles.checkboxContainer}>
      <CustomCheckbox
        className={styles.checkbox}
        label={label}
        checked={additionalApplicationFormPages.includes(formPage)}
        onChange={handleAdditionalApplicationFormPagesChange(formPage)}
      />
    </div>
  );

  const renderAdditionalProductSettingsSection = () => {
    if (!selectedProduct) {
      return null;
    }

    const isCoBorrowerPageActive = selectedProduct.settings.applicationFormPages.includes(
      ApplicationFormPage.CoBorrower,
    );
    const isIntermediaryPageActive = selectedProduct.settings.applicationFormPages.includes(
      ApplicationFormPage.Intermediary,
    );

    if (!isCoBorrowerPageActive && !isIntermediaryPageActive) {
      return null;
    }

    return (
      <div className={styles.additionalSettingsContainer}>
        <h6 className={styles.sectionHeader}>Application Form Options</h6>
        {isCoBorrowerPageActive && renderCheckbox('Include Co-Borrower Page', ApplicationFormPage.CoBorrower)}
        {isIntermediaryPageActive && renderCheckbox('Include Intermediary Page', ApplicationFormPage.Intermediary)}
      </div>
    );
  };

  const handleSubmit = () => {
    if (!selectedProduct) {
      return;
    }

    onSubmit(selectedProduct, additionalApplicationFormPages);
  };

  return (
    <PopUp closePopUp={onClose} title="New Application">
      <PopUpContent hasTopMargin>
        <h6 className={styles.sectionHeader}>Select Product</h6>
        <div className={styles.products}>{products.map(renderProduct)}</div>
        {renderAdditionalProductSettingsSection()}
        <Button
          onClick={handleSubmit}
          kind="secondary"
          size="form"
          className={styles.continueButton}
          disabled={!selectedProduct}
        >
          Continue
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateNewApplicationPopup;
