import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import {
  getApplicationDataSuccess,
  getApplicationDataFailed,
  GetApplicationDataAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { GET_APPLICATION_DATA } from 'LoanOriginationSystemApplicationPage/ActionTypes';

const GetApplicationDataMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, GetApplicationDataAction>(
    GET_APPLICATION_DATA,
    getApplicationDataSuccess,
    getApplicationDataFailed,
    (action) => {
      return api.getApplicationByDisplayId(action.payload.applicationDisplayId);
    },
  );

export default GetApplicationDataMiddleware;
