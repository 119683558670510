import { Middleware } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import { RunDecisionWizardActionType } from 'RunDecisionWizard/actionTypes';
import {
  GetApplicationProductVariables,
  getApplicationProductVariablesFailure,
  getApplicationProductVariablesSuccess,
} from 'RunDecisionWizard/actions';
import { ApplicationDataTabVariableConfigurationsApi } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';

const GetApplicationProductVariablesMiddleware: (api: ApplicationDataTabVariableConfigurationsApi) => Middleware = (
  api,
) =>
  Fetcher<string[], GetApplicationProductVariables>(
    RunDecisionWizardActionType.GetApplicationProductVariables,
    getApplicationProductVariablesSuccess,
    getApplicationProductVariablesFailure,
    async ({ payload }) => {
      const configurations = await api.find({ productId: payload.productId });
      return configurations.map(({ variable }) => variable.systemName);
    },
  );

export default GetApplicationProductVariablesMiddleware;
