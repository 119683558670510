import React, { FC } from 'react';
import styles from './ButtonLoader.module.scss';
import { ButtonType } from 'components/Button/Button';
import clsx from 'clsx';

interface ButtonLoaderProps {
  kind: ButtonType;
  className?: string;
}

const getLoaderTypeClassName = (type: ButtonType): string | null => {
  switch (type) {
    case 'primary':
      return styles.primary;
    case 'secondary':
      return styles.secondary;
    case 'warning':
      return styles.warning;
    default:
      return null;
  }
};

const ButtonLoader: FC<ButtonLoaderProps> = ({ kind, className }) => {
  return (
    <div className={clsx(styles.container, getLoaderTypeClassName(kind), className)}>
      <div className={clsx(styles.dot, styles.left)} />
      <div className={clsx(styles.dot, styles.middle)} />
      <div className={clsx(styles.dot, styles.right)} />
    </div>
  );
};

export default ButtonLoader;
