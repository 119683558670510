import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getLabels, SortLabelsAction } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';

const isSortLabelsAction = (action: AnyAction): action is SortLabelsAction =>
  action.type === LoanOriginationSystemLabelsActionTypes.SortLabels;

const sortLabelsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortLabelsAction(action)) {
    const { loanOriginationSystemLabelsState } = getState();

    dispatch(getLabels({ filters: loanOriginationSystemLabelsState.filters, sortingType: action.payload }));
  }

  return result;
}) as Middleware;

export default sortLabelsMiddleware;
