import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ApplicationDocumentsActionType } from './ActionTypes';
import {
  DeleteApplicationDocumentSuccessAction,
  getApplicationDocuments,
  RenameApplicationDocumentSuccessAction,
  UploadApplicationDocumentSuccessAction,
} from './ActionCreator';
import { RoutineHandler } from 'middlewares/Fetcher';

const isDeleteApplicationDocumentSuccessAction = (
  action: AnyAction,
): action is DeleteApplicationDocumentSuccessAction =>
  action.type === ApplicationDocumentsActionType.DeleteApplicationDocumentSuccess;

const isUploadApplicationDocumentSuccessAction = (
  action: AnyAction,
): action is UploadApplicationDocumentSuccessAction =>
  action.type === ApplicationDocumentsActionType.UploadApplicationDocumentSuccess;

const isRenameApplicationDocumentSuccessAction = (
  action: AnyAction,
): action is RenameApplicationDocumentSuccessAction =>
  action.type === ApplicationDocumentsActionType.RenameApplicationDocumentSuccess;

export const notifyOnSuccessUpdateApplicationDocumentsMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteApplicationDocumentSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentDeleted), 'success', dispatch);
  }

  if (isUploadApplicationDocumentSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentUploaded), 'success', dispatch);
  }

  if (isRenameApplicationDocumentSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationDocumentRenamed), 'success', dispatch);
  }

  return result;
}) as Middleware;

export const reloadOnSuccessUpdateApplicationDocumentsMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch, getState }) => {
    if (
      isDeleteApplicationDocumentSuccessAction(action) ||
      isUploadApplicationDocumentSuccessAction(action) ||
      isRenameApplicationDocumentSuccessAction(action)
    ) {
      const { loanOriginationSystemApplicationPage, loanOriginationSystemDocuments } = getState();
      const { applicationData } = loanOriginationSystemApplicationPage;

      if (!applicationData) {
        return;
      }

      dispatch(
        getApplicationDocuments(
          applicationData.id,
          loanOriginationSystemDocuments.search,
          loanOriginationSystemDocuments.sortingType,
        ),
      );
    }
  },
);
