import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import { SkeletonInfoPanel } from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel';
import { SkeletonSubtitle } from 'components/LoanOriginationSystem/ApplicationDetails/Subtitle';
import styles from './SkeletonApplicationHeaderPreview.module.scss';

const SkeletonApplicationHeaderPreview = () => (
  <div className={styles.skeletonHeader}>
    <div className={styles.skeletonTitle}>
      <div>
        <SkeletonRectangle width="233px" height="36px" color="primary6" marginRight="12px" />
        <SkeletonRectangle width="124px" height="36px" color="primary6" />
      </div>
      <SkeletonSubtitle />
    </div>
    <SkeletonInfoPanel />
  </div>
);

export default SkeletonApplicationHeaderPreview;
