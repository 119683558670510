import React, { FC, useEffect, useState } from 'react';
import styles from './RulesModule.module.scss';
import { RuleData, RuleType } from 'BranchInfo/Types';
import RulesListWrapper from 'components/RulesListWrapper';
import RulesListTableHeader from 'components/RulesListTableHeader';
import RulesListTableFooter from 'components/RulesListTableFooter';
import { RulesModuleCreateButtons, RulesModuleDescriptions, ModuleTypeName } from './Types';
import RulesTable from 'components/RulesTable';
import { ScoringModelRequestData } from 'ScoringModel/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import NumberInput from 'components/NumberInput';
import { Option } from 'components/SelectInput/SelectInput';
import { EntityType } from 'RuleCreate/Types';
import LoadingMask from 'components/LoadingMask';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';

interface RulesModuleTypes {
  onClose: () => void;
  strategyId: string;
  isStrategyLocked: boolean;
  moduleId: string;
  branchIndex: number;
  addBranchCondition: () => void;
  addRule: () => void;
  rulesList: RuleData[];
  conditionsList: RuleData[];
  name: string;
  multipleBranches: boolean;
  getBranchRules: () => void;
  handleOpenDownloadPage: (population?: string) => void;
  initialScore: string;
  outputVariable: string;
  updateSegment: (data: ScoringModelRequestData) => void;
  moduleType: ModuleType;
  openDeleteRulePopUp: (index: number, entityType: EntityType) => void;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  onOpenEditRulePopUp: (data: RuleEditPopUpData) => void;
  isLoading: boolean;
  rulesBlockingActionInProgress: boolean;
  conditionsBlockingActionInProgress: boolean;
}

const RulesModule: FC<RulesModuleTypes> = ({
  onClose,
  addBranchCondition,
  name,
  conditionsList,
  multipleBranches,
  getBranchRules,
  addRule,
  rulesList,
  handleOpenDownloadPage,
  strategyId,
  isStrategyLocked,
  moduleId,
  branchIndex,
  initialScore,
  outputVariable,
  updateSegment,
  moduleType,
  openDeleteRulePopUp,
  handleRuleReorder,
  onOpenEditRulePopUp,
  isLoading,
  rulesBlockingActionInProgress,
  conditionsBlockingActionInProgress,
}) => {
  const [scoreValue, setScoreValue] = useState(initialScore);
  const [loadingStates, setLoadingStates] = useState<Record<string, LoaderState | null>>({});

  useEffect(() => {
    getBranchRules();
  }, []);

  useEffect(() => {
    if (moduleType === 'scorecard') {
      setScoreValue(initialScore);
    }
  }, [initialScore]);

  const onScoreUpdate = async () => {
    try {
      setLoadingStates({ initialScore: LoaderState.Updating });
      await updateSegment({ initialScore: scoreValue, outputVariable });
      setLoadingStates({ initialScore: LoaderState.Success });
    } catch {
      setLoadingStates({ initialScore: LoaderState.Failure });
    }
  };

  const onOutputVariableUpdate = async ({ value }: Option) => {
    try {
      setLoadingStates({ outputVariable: LoaderState.Updating });
      await updateSegment({ outputVariable: value, initialScore: scoreValue });
      setLoadingStates({ outputVariable: LoaderState.Success });
    } catch {
      setLoadingStates({ outputVariable: LoaderState.Failure });
    }
  };

  return (
    <RulesListWrapper
      onClose={onClose}
      addBranchCondition={addBranchCondition}
      name={name}
      conditionsList={conditionsList}
      multipleBranches={multipleBranches}
      downloadBranchConditionsCSV={handleOpenDownloadPage}
      strategyId={strategyId}
      isStrategyLocked={isStrategyLocked}
      moduleId={moduleId}
      branchIndex={branchIndex}
      handleRuleReorder={handleRuleReorder}
      openDeleteBranchPopUp={openDeleteRulePopUp}
      onOpenEditBranchPopUp={onOpenEditRulePopUp}
      conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
      isLoading={isLoading}
    >
      <div className={styles.container}>
        <RulesListTableHeader
          title={ModuleTypeName[moduleType]}
          description={RulesModuleDescriptions[moduleType]}
          handleDownloadCSV={handleOpenDownloadPage}
        />
        {moduleType === 'scorecard' && (
          <div className={styles.inputsContainer}>
            <div className={styles.firstInputContainer}>
              <NumberInput
                labelTitle="Initial Score (Y-Intercept)"
                value={scoreValue}
                onBlur={onScoreUpdate}
                onChange={(value: string) => setScoreValue(value)}
                onLoaderStateReset={() => {
                  setLoadingStates({ initialScore: null });
                }}
                loaderState={loadingStates.initialScore}
                showLoader
              />
            </div>
            <div className={styles.secondInputContainer}>
              <VariableSelector
                value={outputVariable}
                onChange={onOutputVariableUpdate}
                labelTitle="Output Variable"
                onLoaderStateReset={() => {
                  setLoadingStates({ outputVariable: null });
                }}
                loaderState={loadingStates.outputVariable}
                showLoader
              />
            </div>
          </div>
        )}
        <div className={styles.ruleTable}>
          {rulesBlockingActionInProgress && <LoadingMask background="light" />}
          <RulesTable
            strategyId={strategyId}
            rulesList={rulesList}
            moduleType={moduleType}
            openDeleteRulePopUp={openDeleteRulePopUp}
            handleRuleReorder={handleRuleReorder}
            onOpenEditRulePopUp={onOpenEditRulePopUp}
            isLoading={isLoading}
          />
          <RulesListTableFooter addButtonTitle={RulesModuleCreateButtons[moduleType]} addRule={addRule} />
        </div>
      </div>
    </RulesListWrapper>
  );
};

export default RulesModule;
