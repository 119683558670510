import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import notification from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import getOriginalDisplayIdFromDuplicated from 'utils/getOriginalDisplayIdFromDuplicated';
import { openDuplicateApplicationPopup } from './ActionCreator';

const openDuplicateApplicationPopupOrShowErrorMessage = (application: Application, dispatch: Dispatch<AnyAction>) => {
  if (application.originalApplicationId) {
    notification.createNotification(
      {
        componentType: NotificationComponentTypes.DuplicateApplicationError,
        props: {
          originalApplicationDisplayId: getOriginalDisplayIdFromDuplicated(application.displayId),
        },
      },
      'error',
      dispatch,
    );

    return;
  }

  dispatch(openDuplicateApplicationPopup(application));
};

export default openDuplicateApplicationPopupOrShowErrorMessage;
