import React, { CSSProperties } from 'react';
import { ApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import DataTabCard from './DataTabCard';
import AddCardButton from './AddCardButton';
import styles from './DataTab.module.scss';

export interface DataTabRowProps {
  cards: ApplicationDataTabCard[];
  sourceDroppableId: string | null;
  draggingId: string | null;
  placeholderStyles: CSSProperties | null;
  droppableType: string;
  onCardAdd: (row: number, column: number) => void;
  onCardDelete: (cardId: string) => void;
  onCardNameUpdate: (cardId: string, name: string) => void;
  onVariableDelete: (cardId: string, variableConfiguration: string) => void;
  rowIndex: number;
}

const MAX_CARDS_COUNT = 4;
const MAX_ROWS_COUNT = 10;

const DataTabRow = ({
  cards,
  draggingId,
  sourceDroppableId,
  placeholderStyles,
  droppableType,
  onCardAdd,
  onCardDelete,
  onCardNameUpdate,
  onVariableDelete,
  rowIndex,
}: DataTabRowProps) => {
  const handleAddCardInSameRow = () => {
    onCardAdd(rowIndex, cards.length);
  };

  const handleAddCardInNewRow = () => {
    onCardAdd(rowIndex + 1, 0);
  };

  const renderCard = (card: ApplicationDataTabCard) => (
    <DataTabCard
      key={card.id}
      draggingId={draggingId}
      sourceDroppableId={sourceDroppableId}
      placeholderStyles={placeholderStyles}
      droppableType={droppableType}
      onCardDelete={onCardDelete}
      onCardNameUpdate={onCardNameUpdate}
      onVariableDelete={onVariableDelete}
      card={card}
    />
  );

  const renderAddCardInSameRowImage = () => {
    if (cards.length === MAX_CARDS_COUNT) {
      return null;
    }

    return <AddCardButton onClick={handleAddCardInSameRow} className={styles.addCardInSameRowButton} />;
  };

  const renderAddCardInNewRowImage = () => {
    if (rowIndex + 1 === MAX_ROWS_COUNT) {
      return null;
    }

    return <AddCardButton onClick={handleAddCardInNewRow} className={styles.addCardInNewRowButton} />;
  };

  return (
    <div className={styles.row}>
      {renderAddCardInSameRowImage()}
      {renderAddCardInNewRowImage()}
      {cards.map(renderCard)}
    </div>
  );
};

export default DataTabRow;
