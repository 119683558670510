import React, { FC, useState } from 'react';
import styles from './StrategyCreateNewVersionPopUp.module.scss';
import Button from 'components/Button';

interface CreateNewStrategyVersionPopUpProps {
  onCreateNewStrategyVersionRequest: () => Promise<void>;
  onClose: () => void;
}

interface ConfirmationContainerProps {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const confirmationContainerNotification = `Please confirm that you would like to create a new version.`;

const ButtonContainerContainer: FC<ConfirmationContainerProps> = ({ onConfirm, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCreateNewVersion = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
  };

  return (
    <div className={styles.buttonsContainer}>
      <div className={styles.confirmButtonContainer}>
        <Button kind="primary" size="form" onClick={onCreateNewVersion} isLoading={isLoading}>
          Confirm
        </Button>
      </div>
      <div className={styles.cancelButtonContainer}>
        <Button size="form" onClick={onCancel} disabled={isLoading}>
          No, Go Back
        </Button>
      </div>
    </div>
  );
};

const CreateNewStrategyVersionPopUp: FC<CreateNewStrategyVersionPopUpProps> = ({
  onCreateNewStrategyVersionRequest,
  onClose,
}) => {
  return (
    <>
      <div className={styles.notificationContainer}>{confirmationContainerNotification}</div>
      <ButtonContainerContainer onConfirm={onCreateNewStrategyVersionRequest} onCancel={onClose} />
    </>
  );
};

export default CreateNewStrategyVersionPopUp;
