import { Middleware } from 'redux';

import OrganizationActionType from './ActionTypes';
import {
  updateOrganizationInfoError,
  updateOrganizationInfoSuccess,
  ChangeOrganizationInfoRequestAction,
} from './CompanyInformationActionCreator';

import OrganizationAPIRest from 'api/Organization';
import Fetcher from 'middlewares/Fetcher';
import { OrganizationInfoType } from './CompanyInformationTypes';

const ChangeCompanyMiddleware: (api: OrganizationAPIRest) => Middleware = (api) =>
  Fetcher<OrganizationInfoType, ChangeOrganizationInfoRequestAction>(
    OrganizationActionType.ChangeOrganizationInfoRequest,
    updateOrganizationInfoSuccess,
    updateOrganizationInfoError,
    ({ payload }) => api.updateOrganizationInfo(payload),
  );

export default ChangeCompanyMiddleware;
