import { ItemsStateWithPagination } from 'pagination';
import { DataViewSortingType } from 'api/LoanOriginationSystem/Types';

export enum NormalizedStatus {
  Active = 'Active',
  Archive = 'Archive',
  Inactive = 'Inactive',
  Testing = 'Testing',
}

export type SystemStatus = 'active' | 'testing' | null | 'archive';

export interface StrategiesList {
  [id: string]: Strategy;
}

export interface DecisionStrategiesState extends ItemsStateWithPagination<Strategy> {
  searchInputValue: string;
  strategiesDetailedAttributes: StrategiesList;
  strategiesRecoveryCopy?: StrategiesList;
  isLoading: boolean;
  blockingActionInProgress: boolean;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<Strategy>;
  };
  collapsedModules: { [strategyId: string]: boolean[] };
}

export interface Strategy {
  id: string;
  name: string;
  version: number;
  status: NormalizedStatus;
  isLocked: boolean;
  editorFirstName: string;
  editorLastName: string;
  editorImageId?: string;
  updated: Date;
  description: string;
  latestVersion: boolean;
  moduleList: StrategyModule[];
}

export enum ModuleType {
  RequirementsRules = 'requirements',
  CalculationScripts = 'calculations',
  RuleBasedOutputs = 'output',
  SimpleOutputs = 'assignments',
  ScoringModel = 'scorecard',
  ExternalIntegration = 'dataintegration',
  ImportExistingModule = 'ImportExistingModule',
}

export interface StrategyModule {
  id: string;
  active: boolean;
  description: string;
  name: string;
  lookupName: string;
  branches: ModuleBranch[];
  type: ModuleType;
  moduleId: string;
}

export interface ModuleBranch {
  id: string;
  name: string;
  description: string;
}

export enum ApiActivationSortingFields {
  StrategyName = 'strategyName',
}

export type ApiActivationSortingType = DataViewSortingType<ApiActivationSortingFields>;
