import SendGridTemplateApi from 'api/LoanOriginationSystem/SendGridTemplateApi';
import Fetcher from 'middlewares/Fetcher';
import { SendGridTemplate } from 'SendGrid/Types';
import { GetEmailTemplatesAction, getEmailTemplatesFailure, getEmailTemplatesSuccess } from './ActionCreator';
import { ProcessAutomationActionType } from './ActionTypes';

const GetEmailTemplateMiddleware = (api: SendGridTemplateApi) =>
  Fetcher<SendGridTemplate[], GetEmailTemplatesAction>(
    ProcessAutomationActionType.GetEmailTemplates,
    getEmailTemplatesSuccess,
    getEmailTemplatesFailure,
    async () => {
      const { items } = await api.getActiveSendGridTemplates();

      return items;
    }
  );

export default GetEmailTemplateMiddleware;
