import { RefObject, useLayoutEffect, useState } from 'react';

const useOffset = (ref: RefObject<HTMLElement>) => {
  const [offset, setOffset] = useState(0);
  useLayoutEffect(() => {
    const refC = ref.current;
    if (refC) {
      setOffset(refC.offsetTop);
    }
  });
  return offset;
};

export default useOffset;
