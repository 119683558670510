import { useEffect } from 'react';
import { useStopwatch } from 'react-timer-hook';

const DEFAULT_RESEND_CODE_TIMEOUT = 60;

interface TimerOptions {
  autoStart?: boolean;
}

const useTimer = (timeout = DEFAULT_RESEND_CODE_TIMEOUT, options: TimerOptions = {}) => {
  const {
    autoStart = true,
  } = options;

  const stopwatch = useStopwatch({
    autoStart,
  });

  const calculateTotalSeconds = () => {
    const secondsInMinute = 60;

    return stopwatch.minutes * secondsInMinute + stopwatch.seconds;
  };

  useEffect(() => {
    if (stopwatch.isRunning && calculateTotalSeconds() >= timeout) {
      stopwatch.pause();
    }
  }, [stopwatch.seconds]);

  return {
    isRunning: stopwatch.isRunning,
    seconds: timeout - stopwatch.seconds,
    restart: () => {
      stopwatch.reset();
      stopwatch.start();
    },
  };
};

export default useTimer;
