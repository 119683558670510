import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  ApplicationDataTabVariableConfiguration,
  UpdateApplicationDataTabVariableConfigurationParams,
} from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabVariableConfigurationsActionType } from './ActionTypes';
import { Variable } from 'Variables/VariablesTypes';

export interface SuccessUpdateApplicationDataTabVariableConfigurationResult {
  previousCardId?: string;
  variableConfiguration: ApplicationDataTabVariableConfiguration;
}

export interface CreateApplicationDataTabVariableConfigurationAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfiguration;
  payload: {
    productId: string;
    cardId: string;
    column: number;
    position: number;
    variable: Variable;
  };
}

export interface CreateApplicationDataTabVariableConfigurationSuccessAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationSuccess;
  payload: {
    variableConfiguration: ApplicationDataTabVariableConfiguration;
  };
}

export interface UpdateApplicationDataTabVariableConfigurationAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfiguration;
  payload: {
    id: string;
    params: UpdateApplicationDataTabVariableConfigurationParams;
  };
}

export interface UpdateApplicationDataTabVariableConfigurationSuccessAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationSuccess;
  payload: SuccessUpdateApplicationDataTabVariableConfigurationResult;
}

export interface DeleteApplicationDataTabVariableConfigurationAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfiguration;
  payload: {
    id: string;
  };
}

export interface DeleteApplicationDataTabVariableConfigurationSuccessAction extends AnyAction {
  type: ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationSuccess;
  payload: {
    deletedId: string;
  };
}

export const createApplicationDataTabVariableConfiguration = (
  cardId: string,
  productId: string,
  column: number,
  position: number,
  variable: Variable,
): CreateApplicationDataTabVariableConfigurationAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfiguration,
  payload: {
    cardId,
    position,
    productId,
    column,
    variable,
  },
});

export const createApplicationDataTabVariableConfigurationSuccess = (
  variableConfiguration: ApplicationDataTabVariableConfiguration,
): CreateApplicationDataTabVariableConfigurationSuccessAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationSuccess,
  payload: {
    variableConfiguration,
  },
});

export const updateApplicationDataTabVariableConfiguration = (
  id: string,
  params: UpdateApplicationDataTabVariableConfigurationParams,
): UpdateApplicationDataTabVariableConfigurationAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfiguration,
  payload: {
    id,
    params,
  },
});

export const updateApplicationDataTabVariableConfigurationSuccess = (
  result: SuccessUpdateApplicationDataTabVariableConfigurationResult,
): UpdateApplicationDataTabVariableConfigurationSuccessAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationSuccess,
  payload: result,
});

export const deleteApplicationDataTabVariableConfiguration = (
  id: string,
): DeleteApplicationDataTabVariableConfigurationAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfiguration,
  payload: {
    id,
  },
});

export const deleteApplicationDataTabVariableConfigurationSuccess = (
  id: string,
): DeleteApplicationDataTabVariableConfigurationSuccessAction => ({
  type: ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationSuccess,
  payload: {
    deletedId: id,
  },
});

export const createApplicationDataTabVariableConfigurationFailure = FailedActionCreator(
  ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationFailure,
);
export const updateApplicationDataTabVariableConfigurationFailure = FailedActionCreator(
  ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationFailure,
);
export const deleteApplicationDataTabVariableConfigurationFailure = FailedActionCreator(
  ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationFailure,
);
