import PopUp from 'components/PopUps/PopUp';
import React, { useEffect, useState } from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import styles from './ChangeStatusConfirmationPopup.module.scss';
import Button from 'components/Button';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import ChangeStatusConfirmationPopupContent from './ChangeStatusConfirmationPopupContent';

interface ChangeStatusConfirmationPopupProps {
  statusToChange: string;
  onPopupClose: () => void;
  declineReasons: string[];
  onChangeStatusClick: (declineReasons: string[]) => void;
  isLoading?: boolean;
}

const popupButtonText = (statusToChange: string) => {
  switch (statusToChange) {
    case DefaultApplicationStatus.Approved:
      return 'Approve Application';
    case DefaultApplicationStatus.Rejected:
      return 'Reject Application';
    default:
      return 'Yes, Change Status';
  }
};

const popupTitleText = (statusToChange: string) => {
  switch (statusToChange) {
    case DefaultApplicationStatus.Approved:
      return 'Approve Loan Application';
    case DefaultApplicationStatus.Rejected:
      return 'Reject Loan Application';
    default:
      return `Move to ${statusToChange}`;
  }
};

const ChangeStatusConfirmationPopup = ({
  statusToChange,
  onPopupClose,
  declineReasons,
  onChangeStatusClick,
  isLoading,
}: ChangeStatusConfirmationPopupProps) => {
  const [selectedDeclineReasons, setSelectedDeclineReasons] = useState(declineReasons ? [declineReasons[0]] : []);

  useEffect(() => {
    if (!selectedDeclineReasons && declineReasons) {
      setSelectedDeclineReasons([declineReasons[0]]);
    }
  }, [declineReasons]);

  return (
    <PopUp closePopUp={onPopupClose} title={popupTitleText(statusToChange)} closable={!isLoading}>
      <PopUpContent hasTopMargin>
        <ChangeStatusConfirmationPopupContent
          statusToChange={statusToChange}
          declineReasons={declineReasons}
          selectedDeclineReasons={selectedDeclineReasons}
          onDeclineReasonsChange={setSelectedDeclineReasons}
        />
        <Button
          kind="primary"
          size="form"
          className={styles.changeStatusButton}
          onClick={() => onChangeStatusClick(selectedDeclineReasons)}
          isLoading={isLoading}
          disabled={selectedDeclineReasons.length === 0}
        >
          {popupButtonText(statusToChange)}
        </Button>
        <Button
          kind="secondary"
          size="form"
          className={styles.goBackButton}
          onClick={onPopupClose}
          disabled={isLoading}
        >
          No, Go Back
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default ChangeStatusConfirmationPopup;
