import { AnyAction } from 'redux';

export enum GlobalStoreActions {
  LogOut = 'globalStoreActions/logOut',
  SwitchOrganization = 'globalStoreActions/switchOrganization',
}

export const logOut = () => ({
  type: GlobalStoreActions.LogOut,
});

export const switchOrganization = () => ({
  type: GlobalStoreActions.SwitchOrganization,
});

export const doesActionRequireStateReset = (
  action: AnyAction,
): action is ReturnType<typeof logOut> | ReturnType<typeof switchOrganization> =>
  action.type === GlobalStoreActions.LogOut || action.type === GlobalStoreActions.SwitchOrganization;
