import { ApplicationDataTabCardsActionType } from './ActionTypes';
import { ApplicationDataTabCardsState } from './Types';
import {
  CreateApplicationDataTabCardSuccessAction,
  DeleteApplicationDataTabCardSuccessAction,
  GetApplicationDataTabCardsSuccessAction,
  UpdateApplicationDataTabCardSuccessAction,
} from './ActionCreator';
import {
  CreateApplicationDataTabVariableConfigurationSuccessAction,
  DeleteApplicationDataTabVariableConfigurationSuccessAction,
  UpdateApplicationDataTabVariableConfigurationSuccessAction,
} from 'ApplicationDataTabVariableConfigurations/ActionCreator';
import { ApplicationDataTabVariableConfigurationsActionType } from 'ApplicationDataTabVariableConfigurations/ActionTypes';

export type ApplicationDataTabCardsActions =
  | GetApplicationDataTabCardsSuccessAction
  | UpdateApplicationDataTabCardSuccessAction
  | CreateApplicationDataTabCardSuccessAction
  | DeleteApplicationDataTabCardSuccessAction
  | CreateApplicationDataTabVariableConfigurationSuccessAction
  | UpdateApplicationDataTabVariableConfigurationSuccessAction
  | DeleteApplicationDataTabVariableConfigurationSuccessAction;

const initialState: ApplicationDataTabCardsState = {
  cardsById: {},
  cardsByTabId: {},
};

const applicationDataTabCardsReducer = (
  state = initialState,
  action: ApplicationDataTabCardsActions,
): ApplicationDataTabCardsState => {
  switch (action.type) {
    case ApplicationDataTabCardsActionType.GetApplicationDataTabCardsSuccess: {
      return {
        ...state,
        cardsById: {
          ...state.cardsById,
          ...action.payload.cards,
        },
        cardsByTabId: {
          ...state.cardsByTabId,
          [action.payload.tabId]: action.payload.cardIds,
        },
      };
    }
    case ApplicationDataTabCardsActionType.CreateApplicationDataTabCardSuccess: {
      return {
        ...state,
        cardsById: {
          ...state.cardsById,
          ...action.payload.cards,
        },
        cardsByTabId: {
          ...state.cardsByTabId,
          [action.payload.tabId]: [...(state.cardsByTabId[action.payload.tabId] || []), ...action.payload.cardIds],
        },
      };
    }
    case ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardSuccess: {
      return {
        ...state,
        cardsById: {
          ...state.cardsById,
          ...action.payload.cards,
        },
      };
    }
    case ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardSuccess: {
      const card = state.cardsById[action.payload.deletedCardId];

      if (!card) {
        return state;
      }

      const cardIds = state.cardsByTabId[card.tabId] || [];
      const newCardIds = cardIds.filter((cardId) => cardId !== action.payload.deletedCardId);

      return {
        ...state,
        cardsByTabId: {
          ...state.cardsByTabId,
          [card.tabId]: newCardIds,
        },
      };
    }
    case ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationSuccess: {
      const card = state.cardsById[action.payload.variableConfiguration.cardId];

      if (!card) {
        return state;
      }

      return {
        ...state,
        cardsById: {
          ...state.cardsById,
          [card.id]: {
            ...card,
            fields: [...card.fields, action.payload.variableConfiguration.id],
          },
        },
      };
    }
    case ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationSuccess: {
      if (!action.payload.previousCardId) {
        return state;
      }

      const previousCard = state.cardsById[action.payload.previousCardId];
      const newCard = state.cardsById[action.payload.variableConfiguration.cardId];

      if (!previousCard || !newCard || previousCard.id === newCard.id) {
        return state;
      }

      return {
        ...state,
        cardsById: {
          ...state.cardsById,
          [newCard.id]: {
            ...newCard,
            fields: [...newCard.fields, action.payload.variableConfiguration.id],
          },
          [previousCard.id]: {
            ...previousCard,
            fields: previousCard.fields.filter((fieldId) => fieldId !== action.payload.variableConfiguration.id),
          },
        },
      };
    }
    case ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationSuccess: {
      const allCardIds = Object.keys(state.cardsById);

      const cardsById = allCardIds.reduce((newCardsById, cardId) => {
        const card = state.cardsById[cardId];
        const newCard = card.fields.includes(action.payload.deletedId)
          ? { ...card, fields: card.fields.filter((fieldId) => fieldId !== action.payload.deletedId) }
          : card;

        return {
          ...newCardsById,
          [cardId]: newCard,
        };
      }, {});

      return {
        ...state,
        cardsById: {
          ...cardsById,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationDataTabCardsReducer;
