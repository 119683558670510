import React, { FC, useState } from 'react';
import Button from 'components/Button';
import ButtonsFormRow from 'components/ButtonsFormRow/ButtonsFormRow';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import { StrategiesListItem } from 'api/Types';
import styles from './PopUp.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import { Tag } from 'components/Tag';
import { statusColors } from 'pages/IndividualProcessing/IndividualProcessing';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import getNormalizedStatus from 'utils/getNormalizedStatus';

interface RequestTemplateProps {
  decisionStrategies: StrategiesListItem[];
  downloadTemplate: (strategyId: string, type: string) => void;
}

interface TemplateType {
  name: string;
  value: 'individual' | 'batch';
}

const API_REQUEST_TEMPLATE_TYPES: TemplateType[] = [
  {
    name: 'Individual Decision Request',
    value: 'individual',
  },
  {
    name: 'Batch Decision Request',
    value: 'batch',
  },
];

const RequestTemplate: FC<RequestTemplateProps> = ({ decisionStrategies, downloadTemplate }) => {
  const [strategyId, setStrategyId] = useState('');
  const [type, setType] = useState('');

  const isSubmitButtonDisabled = !(strategyId && type);

  const filteredStrategiesList = decisionStrategies.filter((strategy) =>
    getNormalizedStatus(strategy.status) !== NormalizedStatus.Inactive);

  const visibleStrategiesList: Option[] = filteredStrategiesList.map((strategy) => {
    const normalizedStatus = getNormalizedStatus(strategy.status);
    return {
      name: strategy.name,
      value: strategy.id,
      iconAfter: strategy.id !== null && <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
    };
  });

  return (
    <>
      <TextInput labelTitle="Format" disabled value="JSON" />
      <AutoCompletion
        labelTitle="Strategy"
        options={visibleStrategiesList}
        onChange={({ value }) => setStrategyId(value)}
        value={strategyId}
      />
      <AutoCompletion
        labelTitle="Type"
        options={API_REQUEST_TEMPLATE_TYPES}
        onChange={({ value }) => setType(value)}
        value={type}
      />
      <ButtonsFormRow>
        <Button
          kind="primary"
          size="form"
          onClick={() => downloadTemplate(strategyId, type)}
          disabled={isSubmitButtonDisabled}
          className={styles.downloadButton}
        >
          Download Template
        </Button>
      </ButtonsFormRow>
    </>
  );
};

export default RequestTemplate;
