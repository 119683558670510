import React from 'react';
import styles from './DeleteTemplatePopUp.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';

interface DeleteTemplatePopUpProps {
  onPopupClose: () => void;
  onDeleteLabel: () => void;
  isDeleteInProgress: boolean;
}

const DeleteTemplatePopUp = ({ onPopupClose, onDeleteLabel, isDeleteInProgress }: DeleteTemplatePopUpProps) => {
  return (
    <PopUp title="Delete Template" closePopUp={onPopupClose} closable={!isDeleteInProgress}>
      <PopUpContent className={styles.popupContent} hasTopMargin>
        <p className={styles.deleteTemplateQuestion}>Please confirm that you would like to delete template.</p>
        <Button
          kind="primary"
          size="form"
          className={styles.deleteTemplateButton}
          onClick={onDeleteLabel}
          isLoading={isDeleteInProgress}
        >
          Yes, Delete Template
        </Button>
        <Button
          kind="secondary"
          size="form"
          className={styles.goBackButton}
          onClick={onPopupClose}
          disabled={isDeleteInProgress}
        >
          No, Go Back
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default DeleteTemplatePopUp;
