import React, { ChangeEvent, useState } from 'react';
import { AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import styles from './DeclineReason.module.scss';
import TextInput from 'components/TextInput';
import { LoaderState } from 'components/LoaderWithState';

interface DeclineReasonProps {
  automatedProcess: AutomatedProcess;
  onDeclineReasonUpdate: (updatedDeclineReason?: string) => Promise<void>;
  disabled?: boolean;
}

const DeclineReason = ({ automatedProcess, onDeclineReasonUpdate, disabled }: DeclineReasonProps) => {
  const [declineReason, setDeclineReason] = useState(automatedProcess.runtimeOptions.declineReasonToSetName);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState<LoaderState | null>(null);

  const handleUpdateDeclineReason = async () => {
    if (automatedProcess.runtimeOptions.declineReasonToSetName === declineReason) {
      return;
    }

    setIsUpdateInProgress(LoaderState.Updating);
    try {
      await onDeclineReasonUpdate(declineReason);
      setIsUpdateInProgress(LoaderState.Success);
    } catch (error) {
      setIsUpdateInProgress(LoaderState.Failure);
    }
  };

  const handleLoaderStateReset = () => {
    setIsUpdateInProgress(null);
  };

  return (
    <div className={styles.inner}>
      <TextInput
        value={declineReason}
        placeholder="Decline Reason"
        containerClassName={styles.inputContainer}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setDeclineReason(event.target.value)}
        onBlur={handleUpdateDeclineReason}
        disabled={disabled}
        showLoader
        loaderState={isUpdateInProgress}
        onLoaderStateReset={handleLoaderStateReset}
        loaderClassName={styles.inputLoader}
      />
    </div>
  );
};

export default DeclineReason;
