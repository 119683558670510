import LoanOriginationSystemApplicationsApiRest from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import VariablesRestApi from 'api/Core/VariablesApi';
import LoanOriginationSystemProductsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import LoanOriginationSystemIntermediariesRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import LoanOriginationSystemBorrowersRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import ApplicationTabsRestApi from 'api/LoanOriginationSystem/ApplicationTabsApi';
import ApplicationStatusRuleRestApi from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import ApplicationStatusesRulesValidationRestApi from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import ApplicationHistoryRestApi from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import IntermediaryVariableConfigurationsRestApi from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import BorrowerVariableConfigurationsRestApi from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import ApplicationDataTabCardsRestApi from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import ApplicationDataTabVariableConfigurationsRestApi from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import ApplicationVariableConfigurationsRestApi from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import DocuSignIntegrationRestApi from 'api/Core/DocuSignIntegrationApi';
import DocuSignImportRestApi from 'api/Core/DocuSignImportApi';
import ESignTemplatesRestApi from 'api/Core/ESignTemplatesApi';
import DocuSignEnvelopeRestApi from 'api/LoanOriginationSystem/DocuSignEnvelopeApi';
import GetColumnViewProductDataMiddleware from 'LoanOriginationSystemApplications/GetColumnViewProductDataMiddleware';
import GetProductsListMiddleware from 'LoanOriginationSystemApplications/GetProductsListMiddleware';
import GetTableViewProductDataMiddleware from 'LoanOriginationSystemApplications/GetTableViewProductDataMiddleware';
import ApplicationsPaginationMiddleware from 'LoanOriginationSystemApplications/ApplicationsPaginationMiddleware';
import selectProductMiddleware from 'LoanOriginationSystemApplications/SelectProductMiddleware';
import sortProductDataMiddleware from 'LoanOriginationSystemApplications/SortProductDataMiddleware';
import filtersMiddlewares from './filters';
import GetApplicationDataMiddleware from 'LoanOriginationSystemApplicationPage/GetApplicationDataMiddleware';
import LoadBorrowersSuggestionsMiddleware from 'LoanOriginationSystemApplications/CreateApplication/LoadBorrowesSuggestionsMiddleware';
import UnlockBorrowerMiddleware from 'LoanOriginationSystemBorrowers/UnlockBorrower/UnlockBorrowerMiddleware';
import CreateApplicationMiddleware from 'LoanOriginationSystemApplications/CreateApplication/CreateApplicationMiddleware';
import UpdateApplicationMiddleware from 'LoanOriginationSystemApplicationPage/UpdateApplicationMiddleware';
import history from 'stores/history';
import UpdateApplicationSuccessMiddleware from 'LoanOriginationSystemApplicationPage/UpdateApplicationSuccessMiddleware';
import GetIntermediariesMiddleware from 'LoanOriginationSystemIntermediariesPage/GetIntermediariesMiddleware';
import IntermediariesPaginationMiddleware from 'LoanOriginationSystemIntermediariesPage/IntermediariesPaginationMiddleware';
import sortIntermediariesDataMiddleware from 'LoanOriginationSystemIntermediariesPage/SortIntermediariesMiddleware';
import DeleteIntermediaryMiddleware from 'LoanOriginationSystemIntermediariesPage/DeleteIntermediaryMiddleware';
import { DeleteIntermediarySuccessMiddleware } from 'LoanOriginationSystemIntermediariesPage/DeleteIntermediarySuccessMiddleware';
import GetIntermediaryToDeleteApplicationsMiddleware from 'LoanOriginationSystemIntermediariesPage/GetIntermediaryToDeleteApplicationsMiddleware';
import { notifyOnIntermediaryToDeleteHasApplications } from 'LoanOriginationSystemIntermediariesPage/GetIntermediaryToDeleteApplicationsSuccessMiddleware';
import GetBorrowersMiddleware from 'LoanOriginationSystemBorrowers/GetBorrowersMiddleware';
import BorrowersPaginationMiddleware from 'LoanOriginationSystemBorrowers/BorrowersPaginationMiddleware';
import sortBorrowersMiddleware from 'LoanOriginationSystemBorrowers/SortBorrowersMiddleware';
import GetBorrowerVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/GetBorrowerVariableConfigurationsMiddleware';
import CreateBorrowerMiddleware from 'LoanOriginationSystemBorrowers/CreateBorrower/CreateBorrowerMiddleware';
import CreateBorrowerSuccessMiddleware from 'LoanOriginationSystemBorrowers/CreateBorrower/CreateBorrowerSuccessMiddleware';
import DeleteBorrowerMiddleware from 'LoanOriginationSystemBorrowers/DeleteBorrowerMiddleware';
import DeleteBorrowerSuccessMiddleware from 'LoanOriginationSystemBorrowers/DeleteBorrowerSuccessMiddleware';
import GetBorrowerToDeleteApplicationsMiddleware from 'LoanOriginationSystemBorrowers/GetBorrowerToDeleteApplicationsMiddleware';
import { notifyOnBorrowerToDeleteHasApplications } from 'LoanOriginationSystemBorrowers/GetBorrowerToDeleteApplicationsSuccessMiddleware';
import GetBorrowerMiddleware from 'LoanOriginationSystemBorrowerDetails/GetBorrowerMiddleware';
import UpdateBorrowerMiddleware from 'LoanOriginationSystemBorrowers/EditBorrower/UpdateBorrowerMiddleware';
import {
  updateBorrowerSuccessMiddleware,
  UpdateBorrowerDetailsSuccessMiddleware,
} from 'LoanOriginationSystemBorrowers/EditBorrower/UpdateBorrowerSuccessMiddleware';
import GetBorrowerApplicationsMiddleware from 'LoanOriginationSystemBorrowerDetails/GetBorrowerApplicationsMiddleware';
import sortBorrowerApplicationsMiddleware from 'LoanOriginationSystemBorrowerDetails/SortBorrowerApplicationsMiddleware';
import BorrowerApplicationsPaginationMiddleware from 'LoanOriginationSystemBorrowerDetails/BorrowerApplicationsPaginationMiddleware';
import unlockBorrowerSuccessMiddleware from 'LoanOriginationSystemBorrowers/UnlockBorrower/UnlockBorroweSuccessMiddleware';
import GetIntermediaryVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/GetIntermediaryVariableConfigurationsMiddleware';
import LoanOriginationSystemLabelsApiRest from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import GetLabelsMiddleware from 'LoanOriginationSystemLabelsPage/GetLabelsMiddleware';
import GetLabelInfoMiddleware from 'LoanOriginationSystemLabelsPage/GetLabelInfoMiddleware';
import sortLabelsMiddleware from 'LoanOriginationSystemLabelsPage/SortLabelsMiddleware';
import LabelsPaginationMiddleware from 'LoanOriginationSystemLabelsPage/LabelsPaginationMiddleware';
import DeleteLabelMiddleware from 'LoanOriginationSystemLabelsPage/DeleteLabelMiddleware';
import DeleteLabelSuccessMiddleware from 'LoanOriginationSystemLabelsPage/DeleteLabelSuccessMiddleware';
import CreateIntermediaryMiddleware from 'LoanOriginationSystemIntermediariesPage/CreateIntermediary/CreateIntermediaryMiddleware';
import CreateIntermediarySuccessMiddleware from 'LoanOriginationSystemIntermediariesPage/CreateIntermediary/CreateIntermediarySuccessMiddleware';
import UpdateBorrowerVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/UpdateBorrowerVariableConfigurationMiddleware';
import notifyOnVariableConfigurationsChangeMiddleware from 'LoanOriginationSystemVariablesConfiguration/NotifyOnVariableConfigurationsChangeMiddleware';
import GetListVariablesMiddleware from 'LoanOriginationSystemVariablesList/GetListVariablesMiddleware';
import VariablesConfigurationPaginationMiddleware from 'LoanOriginationSystemVariablesList/ListVariablesPaginationMiddleware';
import searchConfigurationVariablesMiddleware from 'LoanOriginationSystemVariablesList/SearchListVariableMiddleware';
import createListVariableSuccessMiddleware from 'LoanOriginationSystemVariablesList/CreateListVariableSuccessMiddleware';
import CreateLabelMiddleware from 'LoanOriginationSystemLabelsPage/CreateLabelMiddleware';
import CreateLabelSuccessMiddleware from 'LoanOriginationSystemLabelsPage/CreateLabelSuccessMiddleware';
import UpdateLabelMiddleware from 'LoanOriginationSystemLabelsPage/UpdateLabelMiddleware';
import UpdateLabelSuccessMiddleware from 'LoanOriginationSystemLabelsPage/UpdateLabelSuccessMiddleware';
import GetTasksMiddleware from 'LoanOriginationSystemTasks/GetTasksMiddleware';
import LoanOriginationSystemTasksRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import LoanOriginationSystemDocumentsRestApi from 'api/LoanOriginationSystem/DocumentsApi';
import TasksPaginationMiddleware from 'LoanOriginationSystemTasks/TasksPaginationMiddleware';
import EmailsPaginationMiddleware from 'LoanOriginationSystemEmails/EmailsPaginationMiddleware';
import DeleteEmailMiddleware from 'LoanOriginationSystemEmails/DeleteEmailMiddleware';
import DeleteEmailSuccessMiddleware from 'LoanOriginationSystemEmails/DeleteEmailSuccessMiddleware';
import sortTasksMiddleware from 'LoanOriginationSystemTasks/SortTasksMiddleware';
import sortEmailsMiddleware from 'LoanOriginationSystemEmails/SortEmailsMiddleware';
import DeleteTaskMiddleware from 'LoanOriginationSystemTasks/DeleteTaskMiddleware';
import DeleteTaskSuccessMiddleware from 'LoanOriginationSystemTasks/DeleteTaskSuccessMiddleware';
import UserRestApi from 'api/Core/UserApi';
import GetOrganizationUsersMiddleware from 'LoanOriginationSystemOrganization/GetOrganizationUsersMiddleware';
import UpdateIntermediaryVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/UpdateIntermediaryVariableConfigurationMiddleware';
import GetIntermediaryDataMiddleware from 'LoanOriginationSystemIntermediaryDetails/GetIntermediaryDataMiddleware';
import UpdateIntermediaryMiddleware from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/UpdateIntermediaryMiddleware';
import UpdateIntermediarySuccessMiddleware from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/UpdateIntermediarySuccessMiddleware';
import GetIntermediaryApplicationsMiddleware from 'LoanOriginationSystemIntermediaryDetails/GetIntermediaryApplicationsMiddleware';
import IntermediaryApplicationsPaginationMiddleware from 'LoanOriginationSystemIntermediaryDetails/IntermediaryApplicationsPaginationMiddleware';
import sortIntermediaryApplicationsMiddleware from 'LoanOriginationSystemIntermediaryDetails/SortIntermediaryApplicationsMiddleware';
import GetApplicationStatusesMiddleware from 'LoanOriginationSystemApplicationStatuses/GetApplicationStatusesMiddleware';
import LoanOriginationSystemApplicationStatusesRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import ChangeApplicationStatusMiddleware from 'LoanOriginationSystemApplications/ChangeApplicationStatusMiddleware';
import GetDeclineReasonsMiddleware from 'LoanOriginationSystemDeclineReasons/GetDeclineReasonsMiddleware';
import LoanOriginationSystemDeclineReasonsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemDeclineReasonsApi';
import GetApplicationDecisionRunsMiddleware from 'ApplicationDecisionRuns/GetApplicationDecisionRunsMiddleware';
import DeleteApplicationDecisionRunMiddleware, {
  onDeleteApplicationDecisionRunSuccessMiddleware,
} from 'DeleteDecisionWizard/DeleteApplicationDecisionRunMiddleware';
import GetProductsMiddleware from 'LoanOriginationSystemProducts/GetProductsMiddleware';
import ChangeProductStatusMiddleware from 'LoanOriginationSystemProducts/ChangeProductStatusMiddleware';
import UpdateProductMiddleware from 'LoanOriginationSystemProducts/UpdateProductMiddleware';
import DuplicateProductMiddleware from 'LoanOriginationSystemProducts/DuplicateProductMiddleware';
import onProductsFiltersChangeMiddleware from 'LoanOriginationSystemProducts/Filters/OnProductsFiltersChangeMiddleware';
import notifyOnProductStatusChangeSuccessMiddleware from 'LoanOriginationSystemProducts/NotifyOnStatusChangeSuccessMiddleware';
import {
  notifyOnDuplicateProductSuccessMiddleware,
  reloadProductsOnDuplicateProductSuccessMiddleware,
} from 'LoanOriginationSystemProducts/DuplicateProductSuccessMiddleware';
import CreateProductMiddleware from 'LoanOriginationSystemProducts/CreateProductMiddleware';
import {
  notifyOnCreateProductSuccessMiddleware,
  reloadProductsOnCreateProductSuccessMiddleware,
  NavigateToProductOnCreateProductSuccessMiddleware,
} from 'LoanOriginationSystemProducts/CreateProductSuccessMiddeware';
import ApplicationDecisionRunsApiRest from 'api/LoanOriginationSystem/ApplicationDecisionRunsApi';
import applicationDecisionRunsPagination from 'components/LoanOriginationSystem/ApplicationTabs/DecisionEngineTab/Pagination';
import ApplicationNotesApiRest from 'api/LoanOriginationSystem/ApplicationNotesApi';
import GetOrganizationApplicationsMiddleware from 'LoanOriginationSystemOrganization/GetOrganizationApplicationsMiddleware';
import CreateTaskMiddleware from 'LoanOriginationSystemTasks/CreateTask/CreateTaskMiddleware';
import CreateTaskSuccessMiddleware from 'LoanOriginationSystemTasks/CreateTask/CreateTaskSuccessMiddleware';
import GetTaskDataMiddleware from 'LoanOriginationSystemTasks/EditTask/GetTaskDataMiddleware';
import EditTaskMiddleware from 'LoanOriginationSystemTasks/EditTask/EditTaskMiddleware';
import editTaskSuccessMiddleware from 'LoanOriginationSystemTasks/EditTask/EditTaskSuccessMiddleware';
import GetApplicationTabsMiddleware from 'LoanOriginationSystemApplicationTabs/GetApplicationTabsMiddleware';
import CreateApplicationTabMiddleware from 'LoanOriginationSystemApplicationTabs/CreateApplicationTabMiddleware';
import DeleteApplicationTabMiddleware from 'LoanOriginationSystemApplicationTabs/DeleteApplicationTabMiddleware';
import AutomatedProcessesRestApi from 'api/LoanOriginationSystem/ProcessAutomationApi';
import GetAutomatedProcessesMiddleware from 'ProcessAutomation/GetAutomatedProcessesMiddleware';
import ChangeActivationStatusMiddleware from 'ProcessAutomation/ChangeActivationStatusMiddleware';
import ChangeActivationStatusSuccessMiddleware from 'ProcessAutomation/ChangeActivationStatusSuccessMiddleware';
import ProcessAutomationPaginationMiddleware from 'ProcessAutomation/PaginationMiddleware';
import ProcessAutomationSelectCategoryMiddleware from 'ProcessAutomation/SelectCategoryMiddleware';
import ProcessAutomationSelectGroupMiddleware from 'ProcessAutomation/SelectGroupMiddleware';
import UpdateRuntimeOptionsMiddleware from 'ProcessAutomation/UpdateRuntimeOptionsMiddleware';
import UpdateRuntimeOptionsSuccessMiddleware from 'ProcessAutomation/UpdateRuntimeOptionsSuccessMiddleware';
import DeleteApplicationStatusMiddleware from 'LoanOriginationSystemApplicationStatuses/DeleteApplicationStatusMiddleware';
import CreateApplicationStatusMiddleware from 'LoanOriginationSystemApplicationStatuses/CreateApplicationStatusMiddleware';
import UpdateApplicationStatusMiddleware from 'LoanOriginationSystemApplicationStatuses/UpdateApplicationStatusMiddleware';
import UpdateDeclineReasonsMiddleware from 'LoanOriginationSystemDeclineReasons/UpdateDeclineReasonsMiddleware';
import UpdateApplicationVariablesMiddleware from 'LoanOriginationSystemApplicationPage/UpdateApplicationVariablesMiddleware';
import GetApplicationDocumentsMiddleware from 'LoanOriginationSystemApplicationPage/Documents/GetApplicationDocumentsMiddleware';
import UploadApplicationDocumentMiddleware from 'LoanOriginationSystemApplicationPage/Documents/UploadApplicationDocumentMiddleware';
import searchApplicationDocumentsMiddleware from 'LoanOriginationSystemApplicationPage/Documents/SearchApplicationDocumentsMiddleware';
import searchApplicationNotesMiddleware from 'LoanOriginationSystemApplicationPage/Notes/SearchApplicationNotesMiddleware';
import sortApplicationDocumentsMiddleware from 'LoanOriginationSystemApplicationPage/Documents/SortApplicationDocumentsMiddleware';
import DeleteAppicationDocumentMiddleware from 'LoanOriginationSystemApplicationPage/Documents/DeleteAppicationDocumentMiddleware';
import {
  notifyOnSuccessUpdateApplicationDocumentsMiddleware,
  reloadOnSuccessUpdateApplicationDocumentsMiddleware,
} from 'LoanOriginationSystemApplicationPage/Documents/UpdateApplicationDocumentsSuccessMiddlewares';
import GetApplicationStrategyListMiddleware from 'RunDecisionWizard/GetApplicationStrategyListMiddleware';
import GetApplicationStrategyInputsMiddleware from 'RunDecisionWizard/GetApplicationStrategyInputsMiddleware';
import GetApplicationProductVariablesMiddleware from 'RunDecisionWizard/GetApplicationProductVariablesMiddleware';
import ApplicationDecisionRunInputsLegacyApi from 'api/LoanOriginationSystem/ApplicationDecisionRunInputsLegacyApi';
import ChangeVariableStatusMiddleware from 'Variables/ChangeVariableStatusMiddleware';
import GetProductMiddleware from 'LoanOriginationSystemProducts/GetProductMiddleware';
import GetStandardVariablesMiddleware from 'LoanOriginationSystemOrganization/GetStandardVariablesMiddleware';
import RenameApplicationDocumentMiddleware from 'LoanOriginationSystemApplicationPage/Documents/RenameApplicationDocumentMiddleware';
import GetEmailTemplateMiddleware from 'ProcessAutomation/GetEmailTemplatesMiddleware';
import RequestCustomerUploadMiddleware from 'LoanOriginationSystemApplicationPage/Documents/RequestCustomerUploadMiddleware';
import requestCustomerUploadSuccessMiddlewares from 'LoanOriginationSystemApplicationPage/Documents/RequestCustomerUploadSuccessMiddlewares';
import GetOrganizationInfoMiddleware from 'UploadSecureDocument/GetOrganizationInfoMiddleware';
import UploadSecretDocumentMiddleware from 'UploadSecureDocument/UploadSecretDocumentMiddleware';
import uploadSecretDocumentSuccessMiddlewares from 'UploadSecureDocument/UploadSecretDocumentSuccessMiddlewares';
import CustomerDocumentUploadApi from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';
import DeleteApplicationMiddleware from 'LoanOriginationSystemApplications/DeleteApplicationMiddleware';
import LoanOriginationSystemEmailsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import GetEmailsMiddleware from 'LoanOriginationSystemEmails/GetEmailsMiddleware';
import GetBorrowersForEmailFilterMiddleware from 'LoanOriginationSystemEmails/GetBorrowersMiddleware';
import GetApplicationNotesMiddleware from 'LoanOriginationSystemApplicationPage/Notes/GetApplicationNotesMiddleware';
import AddApplicationNoteMiddleware from 'LoanOriginationSystemApplicationPage/Notes/AddApplicationNoteMiddleware';
import UpdateApplicationNoteMiddleware from 'LoanOriginationSystemApplicationPage/Notes/UpdateApplicationNoteMiddleware';
import DeleteApplicationNoteMiddleware from 'LoanOriginationSystemApplicationPage/Notes/DeleteAppicationNoteMiddleware';
import { AddApplicationNotesSuccessMiddlewares } from 'LoanOriginationSystemApplicationPage/Notes/AddApplicationNoteSuccessMiddlewares';
import { DeleteApplicationNotesSuccessMiddlewares } from 'LoanOriginationSystemApplicationPage/Notes/DeleteApplicationNoteSuccessMiddlewares';
import { UpdateApplicationNotesSuccessMiddlewares } from 'LoanOriginationSystemApplicationPage/Notes/UpdateApplicationNotesSuccessMiddlewares';
import sortApplicationNotesMiddleware from 'LoanOriginationSystemApplicationPage/Notes/SortApplicationNotesMiddleware';
import CreateApplicationStatusRuleMiddleware from 'LoanOriginationSystemApplicationStatuses/CreateApplicationStatusRuleMiddleware';
import DeleteApplicationStatusRuleMiddleware from 'LoanOriginationSystemApplicationStatuses/DeleteApplicationStatusRuleMiddleware';
import UpdateApplicationStatusRuleMiddleware from 'LoanOriginationSystemApplicationStatuses/UpdateApplicationStatusRuleMiddleware';
import {
  notifyOnCreateApplicationStatusRuleMiddleware,
  notifyOnDeleteApplicationStatusRuleMiddleware,
  notifyOnUpdateApplicationStatusRuleMiddleware,
} from 'LoanOriginationSystemApplicationStatuses/NotifyMiddlewares';
import { notifyOnApplicationStatusRulesFail } from 'LoanOriginationSystemApplications/ChangeApplicationStatusFailureMiddlewares';
import LoadIntermediarySuggestionsMiddleware from 'LoanOriginationSystemApplications/CreateApplication/LoadIntermediarySuggestionsMiddleware';
import GetStatusesRulesValidationResultsMiddleware from 'LoanOriginationSystemApplicationPage/StatusesRules/GetStatusesRulesValidationResultsMiddleware';
import DocumentsPaginationMiddleware from 'LoanOriginationSystemApplicationPage/Documents/DocumentsPaginationMiddleware';
import NotesPaginationMiddleware from 'LoanOriginationSystemApplicationPage/Notes/NotesPaginationMiddleware';
import GetApplicationHistoryMiddleware from 'LoanOriginationSystemApplicationPage/History/GetApplicationHistoryMiddleware';
import reloadHistoryRecordsMiddleware from 'LoanOriginationSystemApplicationPage/History/ReloadHistoryRecordsMiddleware';
import HistoryRecordsPaginationMiddleware from 'LoanOriginationSystemApplicationPage/History/HistoryRecordsPaginationMiddleware';
import DownloadProductApiRequestTemplateMiddleware from 'LoanOriginationSystemProducts/DownloadApiRequestTemplateMiddleware';
import DownloadBorrowerApiRequestTemplateMiddleware from 'LoanOriginationSystemBorrowerConfiguration/DownloadApiRequestTemplateMiddleware';
import DownloadIntermediaryApiRequestTemplateMiddleware from 'LoanOriginationSystemIntermediaryConfiguration/DownloadApiRequestTemplateMiddleware';
import CreateIntermediaryVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/CreateIntermediaryVariableConfigurationMiddleware';
import DeleteIntermediaryVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/DeleteIntermediaryVariableConfigurationMiddleware';
import CreateBorrowerVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/CreateBorrowerVariableConfigurationMiddleware';
import DeleteBorrowerVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/DeleteBorrowerVariableConfigurationMiddleware';
import GetApplicationDataTabCardsMiddleware from 'LoanOriginationSystemApplicationDataTabCards/GetApplicationDataTabCardsMiddleware';
import CreateApplicationDataTabCardMiddleware from 'LoanOriginationSystemApplicationDataTabCards/CreateApplicationDataTabCardMiddleware';
import DeleteApplicationDataTabCardMiddleware from 'LoanOriginationSystemApplicationDataTabCards/DeleteApplicationDataTabCardMiddleware';
import UpdateApplicationDataTabCardMiddleware from 'LoanOriginationSystemApplicationDataTabCards/UpdateApplicationDataTabCardMiddleware';
import CreateApplicationDataTabVariableConfigurationMiddleware from 'ApplicationDataTabVariableConfigurations/CreateApplicationDataTabVariableConfigurationMiddleware';
import DeleteApplicationDataTabVariableConfigurationMiddleware from 'ApplicationDataTabVariableConfigurations/DeleteApplicationDataTabVariableConfigurationMiddleware';
import UpdateApplicationDataTabVariableConfigurationMiddleware from 'ApplicationDataTabVariableConfigurations/UpdateApplicationDataTabVariableConfigurationMiddleware';
import UpdateApplicationTabMiddleware from 'LoanOriginationSystemApplicationTabs/UpdateApplicationTabMiddleware';
import SendGridTemplateApi from 'api/LoanOriginationSystem/SendGridTemplateApi';
import GetApplicationVariablesMiddleware from 'LoanOriginationSystemApplicationPage/GetApplicationVariablesMiddleware';
import DeleteApplicationCoborrowerMiddleware from 'LoanOriginationSystemApplicationPage/DeleteApplicationCoborrowerMiddleware';
import DeleteApplicationIntermediaryMiddleware from 'LoanOriginationSystemApplicationPage/DeleteApplicationIntermediaryMiddleware';
import AddApplicationCoborrowerMiddleware from 'LoanOriginationSystemApplicationPage/AddApplicationCoborrowerMiddleware';
import AddApplicationIntermediaryMiddleware from 'LoanOriginationSystemApplicationPage/AddApplicationIntermediaryMiddleware';
import GetDocuSignIntegrationMiddleware from 'ESignIntegrations/GetDocuSignIntegrationMiddleware';
import ApiRequestTemplatesRestApi from 'api/LoanOriginationSystem/ApiRequestTemplatesApi';
import GetReportingFilterInitialDataMiddleware from 'LoanOriginationSystemReporting/Filters/Middlewares';
import LoanOriginationSystemApplicationReportingRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemReportingApi';
import GetReportingDataMiddleware from 'LoanOriginationSystemReporting/Applications/Middlewares';
import CreateDocuSignIntegrationMiddleware from 'ESignIntegrations/CreateDocuSignIntegrationMiddleware';
import UpdateDocuSignIntegrationMiddleware from 'ESignIntegrations/UpdateDocuSignIntegrationMiddleware';
import GetTemplatesToImportMiddleware from 'DocuSign/GetTemplatesToImportMiddleware';
import GetESignTemplatesMiddleware from 'ESignTemplates/GetESignTemplatesMiddleware';
import ImportDocuSignTemplatesMiddleware from 'DocuSign/ImportDocuSignTemplatesMiddleware';
import UpdateESignTemplateMiddleware from 'ESignTemplates/UpdateESignTemplateMiddleware';
import DeleteESignTemplateMiddleware from 'ESignTemplates/DeleteESignTemplateMiddleware';
import GetESignTemplateMiddleware from 'ESignTemplates/GetESignTemplateMiddleware';
import onDocuSignTemplatesFilterChangeMiddleware from 'DocuSign/Filters/onDocuSignTemplatesFilterChangeMiddleware';
import filterTemplatesByMembersMiddleware from 'DocuSign/Filters/FilterTemplatesByMembersMiddleware';
import searchDocuSignTemplatesMiddleware from 'DocuSign/Filters/SearchDocuSignTemplatesMiddleware';
import sortDocuSignTemplatesMiddleware from 'DocuSign/SortDocuSignTemplatesMiddleware';
import reloadTemplatesOnSuccessUpdateMiddleware from 'DocuSign/ReloadTemplatesOnSuccessUpdateMiddleware';
import DocuSignTemplatesPaginationMiddleware from 'DocuSign/DocuSignTemplatesPaginationMiddleware';
import CreateDocuSignEnvelopeMiddleware from 'LoanOriginationSystemApplicationPage/Documents/CreateDocuSignEnvelopeMiddleware';
import notifyOnCredentialsUpdateMiddleware from 'DocuSign/NotifyOnCredentialsUpdateMiddleware';
import notifyOnSuccessTemplatesImportMiddleware from 'DocuSign/NotifyOnSuccessTemplatesImportMiddleware';
import notifyOnTemplateDeleteMiddleware from 'ESignTemplates/NotifyOnTemplateDeleteMiddleware';
import notifyOnESignTemplateStatusChangeMiddleware from 'ESignTemplates/NotifyOnTemplateStatusChangeMiddleware';
import notifyOnSuccessDocuSignEnvelopeMiddleware from 'LoanOriginationSystemApplicationPage/Documents/NotifyOnSuccessDocuSignEnvelopeMiddleware';
import { GetEmailTemplateMiddleware as GetSendGridEmailTemplateMiddleware } from 'SendGrid/Middlewares';
import BulkUpdateBorrowerVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/BulkUpdateBorrowerVariableConfigurationsMiddleware';
import BulkUpdateIntermediaryVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/BulkUpdateIntermediaryVariableConfigurationsMiddleware';
import GetApplicationVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/GetApplicationVariableConfigurationsMiddleware';
import CreateApplicationVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/CreateApplicationVariableConfigurationMiddleware';
import UpdateApplicationVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/UpdateApplicationVariableConfigurationMiddleware';
import DeleteApplicationVariableConfigurationMiddleware from 'LoanOriginationSystemVariablesConfiguration/DeleteApplicationVariableConfigurationMiddleware';
import BulkUpdateApplicationVariableConfigurationsMiddleware from 'LoanOriginationSystemVariablesConfiguration/BulkUpdateApplicationVariableConfigurationsMiddleware';
import UpdateApplicationTeamMembersMiddleware from 'LoanOriginationSystemApplicationPage/UpdateApplicationTeamMembersMiddleware';
import updateApplicationTeamMembersSuccessMiddleware from 'LoanOriginationSystemApplicationPage/UpdateApplicationTeamMembersSuccessMiddleware';
import AddApplicationLabelMiddleware from 'LoanOriginationSystemApplicationPage/AddApplicationLabelMiddleware';
import DeleteApplicationLabelMiddleware from 'LoanOriginationSystemApplicationPage/DeleteApplicationLabelMiddleware';
import DuplicateApplicationMiddleware from 'LoanOriginationSystemApplications/DuplicateApplicationMiddleware';
import responseHandlers from 'api/ResponseHandlers';
import notifyOnUpdateRequiredFieldsMiddleware from 'LoanOriginationSystemProducts/UpdateRequiredFieldsSuccessMiddleware';
import notifyOnApplicationFormSettingsUpdateMiddleware from 'LoanOriginationSystemProducts/UpdateProductSuccessMiddleware';

const loanOriginationSystemApplicationsApi = new LoanOriginationSystemApplicationsApiRest(responseHandlers);
const loanOriginationSystemIntermediariesApi = new LoanOriginationSystemIntermediariesRestApi(responseHandlers);
const loanOriginationSystemBorrowersApi = new LoanOriginationSystemBorrowersRestApi(responseHandlers);
const loanOriginationSystemLabelsApi = new LoanOriginationSystemLabelsApiRest(responseHandlers);
const loanOriginationSystemApplicationStatusesRestApi = new LoanOriginationSystemApplicationStatusesRestApi(
  responseHandlers,
);
const loanOriginationSystemDeclineReasonsRestApi = new LoanOriginationSystemDeclineReasonsRestApi(responseHandlers);
export const loanOriginationSystemEmailsApi = new LoanOriginationSystemEmailsRestApi(responseHandlers);
const automatedProcessesApi = new AutomatedProcessesRestApi(responseHandlers);
const sendGridTemplateApi = new SendGridTemplateApi(responseHandlers);

export const loanOriginationSystemProductsApi = new LoanOriginationSystemProductsRestApi(responseHandlers);
const variablesApi = new VariablesRestApi(responseHandlers);
const loanOriginationSystemTasksApi = new LoanOriginationSystemTasksRestApi(responseHandlers);
const applicationDecisionRunsApi = new ApplicationDecisionRunsApiRest(responseHandlers);
const applicationDecisionRunInputsLegacyApi = new ApplicationDecisionRunInputsLegacyApi(responseHandlers);
const applicationNotesApi = new ApplicationNotesApiRest(responseHandlers);
const applicationTabsApi = new ApplicationTabsRestApi(responseHandlers);
const customerDocumentUploadApi = new CustomerDocumentUploadApi();
const userApi = new UserRestApi(responseHandlers);
export const applicationDocumentsApi = new LoanOriginationSystemDocumentsRestApi(responseHandlers);
const applicationStatusRuleApi = new ApplicationStatusRuleRestApi(responseHandlers);
const applicationStatusesRulesValidationApi = new ApplicationStatusesRulesValidationRestApi(responseHandlers);
const applicationHistoryRestApi = new ApplicationHistoryRestApi(responseHandlers);
const intermediaryVariableConfigurationsApi = new IntermediaryVariableConfigurationsRestApi(responseHandlers);
const borrowerVariableConfigurationsApi = new BorrowerVariableConfigurationsRestApi(responseHandlers);
const applicationDataTabCardsApi = new ApplicationDataTabCardsRestApi(responseHandlers);
const applicationDataTabVariableConfigurationsApi = new ApplicationDataTabVariableConfigurationsRestApi(
  responseHandlers,
);
const applicationVariableConfigurationsApi = new ApplicationVariableConfigurationsRestApi(responseHandlers);
const docuSignIntegrationApi = new DocuSignIntegrationRestApi(responseHandlers);
export const apiRequestTemplatesApi = new ApiRequestTemplatesRestApi(responseHandlers);
const docuSignImportApi = new DocuSignImportRestApi(responseHandlers);
export const eSignTemplatesApi = new ESignTemplatesRestApi(responseHandlers);
const docuSignEnvelopeApi = new DocuSignEnvelopeRestApi(responseHandlers);

const reportingApi = new LoanOriginationSystemApplicationReportingRestApi(
  responseHandlers,
  loanOriginationSystemLabelsApi,
  loanOriginationSystemIntermediariesApi,
  loanOriginationSystemApplicationStatusesRestApi,
  loanOriginationSystemProductsApi,
  userApi,
);

const getColumnViewProductDataMiddleware = GetColumnViewProductDataMiddleware(loanOriginationSystemApplicationsApi);
const getProductsListMiddleware = GetProductsListMiddleware(loanOriginationSystemProductsApi);
const getTableViewProductDataMiddleware = GetTableViewProductDataMiddleware(loanOriginationSystemApplicationsApi);
const applicationsPaginationMiddleware = ApplicationsPaginationMiddleware(loanOriginationSystemApplicationsApi);
const getApplicationDataMiddleware = GetApplicationDataMiddleware(loanOriginationSystemApplicationsApi);
const loadBorrowersSuggestionsMiddleware = LoadBorrowersSuggestionsMiddleware(loanOriginationSystemBorrowersApi);
const unlockBorrowerMiddleware = UnlockBorrowerMiddleware(loanOriginationSystemBorrowersApi);
const createApplicationMiddleware = CreateApplicationMiddleware(loanOriginationSystemApplicationsApi);
const updateApplicationMiddleware = UpdateApplicationMiddleware(loanOriginationSystemApplicationsApi);
const updateApplicationSuccessMiddleware = UpdateApplicationSuccessMiddleware();
const getIntermediariesDataMiddleware = GetIntermediariesMiddleware(loanOriginationSystemIntermediariesApi);
const intermediariesPaginationMiddleware = IntermediariesPaginationMiddleware(loanOriginationSystemIntermediariesApi);
const deleteIntermediariesMiddleware = DeleteIntermediaryMiddleware(loanOriginationSystemIntermediariesApi);
const getIntermediaryToDeleteApplicationsMiddleware = GetIntermediaryToDeleteApplicationsMiddleware(
  loanOriginationSystemIntermediariesApi,
);
const deleteIntermediariesSuccessMiddleware = DeleteIntermediarySuccessMiddleware();
const getBorrowersMiddleware = GetBorrowersMiddleware(loanOriginationSystemBorrowersApi);
const borrowersPaginationMiddleware = BorrowersPaginationMiddleware(loanOriginationSystemBorrowersApi);
const getBorrowerVariableConfigurationsMiddleware = GetBorrowerVariableConfigurationsMiddleware(
  borrowerVariableConfigurationsApi,
);
const createBorrowerMiddleware = CreateBorrowerMiddleware(loanOriginationSystemBorrowersApi);
const createBorrowerSuccessMiddleware = CreateBorrowerSuccessMiddleware(history);
const createIntermediaryMiddleware = CreateIntermediaryMiddleware(loanOriginationSystemIntermediariesApi);
const createIntermediarySuccessMiddleware = CreateIntermediarySuccessMiddleware(history);
const deleteBorrowerMiddleware = DeleteBorrowerMiddleware(loanOriginationSystemBorrowersApi);
const deleteBorrowerSuccessMiddleware = DeleteBorrowerSuccessMiddleware(history);
const getBorrowerToDeleteApplicationsMiddleware = GetBorrowerToDeleteApplicationsMiddleware(
  loanOriginationSystemBorrowersApi,
);
const getBorrowerMiddleware = GetBorrowerMiddleware(loanOriginationSystemBorrowersApi);
const updateBorrowerMiddleware = UpdateBorrowerMiddleware(loanOriginationSystemBorrowersApi);
const updateBorrowerDetailsSuccessMiddleware = UpdateBorrowerDetailsSuccessMiddleware(history);
const getBorrowerApplicationsMiddleware = GetBorrowerApplicationsMiddleware(loanOriginationSystemBorrowersApi);
const borrowerApplicationsPaginationMiddleware = BorrowerApplicationsPaginationMiddleware(
  loanOriginationSystemBorrowersApi,
);
const getTasksMiddleware = GetTasksMiddleware(loanOriginationSystemTasksApi);
const tasksPaginationMiddleware = TasksPaginationMiddleware(loanOriginationSystemTasksApi);
const documentsPaginationMiddleware = DocumentsPaginationMiddleware(applicationDocumentsApi);
const notesPaginationMiddleware = NotesPaginationMiddleware(applicationNotesApi);
const emailsPaginationMiddleware = EmailsPaginationMiddleware(loanOriginationSystemEmailsApi);
const deleteEmailMiddleware = DeleteEmailMiddleware(loanOriginationSystemEmailsApi);
const deleteEmailSuccessMiddleware = DeleteEmailSuccessMiddleware();
const updateBorrowerVariableConfigurationMiddleware = UpdateBorrowerVariableConfigurationMiddleware(
  borrowerVariableConfigurationsApi,
);
const createBorrowerVariableConfigurationMiddleware = CreateBorrowerVariableConfigurationMiddleware(
  borrowerVariableConfigurationsApi,
);
const deleteBorrowerVariableConfigurationMiddleware = DeleteBorrowerVariableConfigurationMiddleware(
  borrowerVariableConfigurationsApi,
);

const getConfigurationVariablesMiddleware = GetListVariablesMiddleware(variablesApi);
const variablesConfigurationPaginationMiddleware = VariablesConfigurationPaginationMiddleware(variablesApi);
const getLabelsMiddleware = GetLabelsMiddleware(loanOriginationSystemLabelsApi);
const getLabelInfoMiddleware = GetLabelInfoMiddleware(loanOriginationSystemLabelsApi);
const labelsPaginationMiddleware = LabelsPaginationMiddleware(loanOriginationSystemLabelsApi);
const deleteLabelMiddleware = DeleteLabelMiddleware(loanOriginationSystemLabelsApi);
const deleteLabelSuccessMiddleware = DeleteLabelSuccessMiddleware(history);
const createLabelMiddleware = CreateLabelMiddleware(loanOriginationSystemLabelsApi);
const createLabelSuccessMiddleware = CreateLabelSuccessMiddleware();
const deleteTaskMiddleware = DeleteTaskMiddleware(loanOriginationSystemTasksApi);
const deleteTaskSuccessMiddleware = DeleteTaskSuccessMiddleware();
const applicationDecisionRunsMiddleware = GetApplicationDecisionRunsMiddleware(applicationDecisionRunsApi);
const deleteApplicationDecisionRunMiddleware = DeleteApplicationDecisionRunMiddleware(applicationDecisionRunsApi);
const getApplicationStrategyListMiddleware = GetApplicationStrategyListMiddleware(applicationDecisionRunsApi);
const getApplicationStrategyInputsMiddleware = GetApplicationStrategyInputsMiddleware(
  applicationDecisionRunInputsLegacyApi,
);
const getApplicationDataTabVariableConfigurationsMiddleware = GetApplicationProductVariablesMiddleware(
  applicationDataTabVariableConfigurationsApi,
);
const getApplicationNotesMiddleware = GetApplicationNotesMiddleware(applicationNotesApi);
const addApplicationNoteMiddleware = AddApplicationNoteMiddleware(applicationNotesApi);
const updateApplicationNoteMiddleware = UpdateApplicationNoteMiddleware(applicationNotesApi);
const deleteApplicationNoteRequestMiddleware = DeleteApplicationNoteMiddleware(applicationNotesApi);
const getOrganizationMembersMiddleware = GetOrganizationUsersMiddleware(userApi);
const getIntermediaryVariableConfigurationsMiddleware = GetIntermediaryVariableConfigurationsMiddleware(
  intermediaryVariableConfigurationsApi,
);
const updateIntermediaryVariableConfigurationMiddleware = UpdateIntermediaryVariableConfigurationMiddleware(
  intermediaryVariableConfigurationsApi,
);
const createIntermediaryVariableConfigurationMiddleware = CreateIntermediaryVariableConfigurationMiddleware(
  intermediaryVariableConfigurationsApi,
);
const deleteIntermediaryVariableConfigurationMiddleware = DeleteIntermediaryVariableConfigurationMiddleware(
  intermediaryVariableConfigurationsApi,
);
const getIntermediaryData = GetIntermediaryDataMiddleware(loanOriginationSystemIntermediariesApi);
const updateIntermediaryMiddleware = UpdateIntermediaryMiddleware(loanOriginationSystemIntermediariesApi);
const updateIntermediarySuccessMiddleware = UpdateIntermediarySuccessMiddleware();
const getIntermediaryApplicationsMiddleware = GetIntermediaryApplicationsMiddleware(
  loanOriginationSystemIntermediariesApi,
);
const intermediaryApplicationsPaginationMiddleware = IntermediaryApplicationsPaginationMiddleware(
  loanOriginationSystemIntermediariesApi,
);
const getOrganizationApplicationsMiddleware = GetOrganizationApplicationsMiddleware(
  loanOriginationSystemApplicationsApi,
);
const createTaskMiddleware = CreateTaskMiddleware(loanOriginationSystemTasksApi);
const createTaskSuccessMiddleware = CreateTaskSuccessMiddleware();
const getTaskDataMiddleware = GetTaskDataMiddleware(loanOriginationSystemTasksApi);
const editTaskMiddleware = EditTaskMiddleware(loanOriginationSystemTasksApi);
const updateLabelMiddleware = UpdateLabelMiddleware(loanOriginationSystemLabelsApi);
const updateLabelSuccessMiddleware = UpdateLabelSuccessMiddleware();
const getApplicationStatusesMiddleware = GetApplicationStatusesMiddleware(
  loanOriginationSystemApplicationStatusesRestApi,
);
const applicationsChangeApplicationStatusMiddleware = ChangeApplicationStatusMiddleware(
  loanOriginationSystemApplicationsApi,
);
const getDeclineReasonsMiddleware = GetDeclineReasonsMiddleware(loanOriginationSystemDeclineReasonsRestApi);
const changeProductStatusMiddleware = ChangeProductStatusMiddleware(loanOriginationSystemProductsApi);
const updateProductMiddleware = UpdateProductMiddleware(loanOriginationSystemProductsApi);
const duplicateProductMiddleware = DuplicateProductMiddleware(loanOriginationSystemProductsApi);
const getProductsMiddleware = GetProductsMiddleware(loanOriginationSystemProductsApi);
const createProductMiddleware = CreateProductMiddleware(loanOriginationSystemProductsApi);
const getApplicationTabsMiddleware = GetApplicationTabsMiddleware(applicationTabsApi);
const createApplicationTabMiddleware = CreateApplicationTabMiddleware(applicationTabsApi);
const updateApplicationTabMiddleware = UpdateApplicationTabMiddleware(applicationTabsApi);
const deleteApplicationTabMiddleware = DeleteApplicationTabMiddleware(applicationTabsApi);
const deleteApplicationStatusMiddleware = DeleteApplicationStatusMiddleware(
  loanOriginationSystemApplicationStatusesRestApi,
);
const createApplicationStatusMiddleware = CreateApplicationStatusMiddleware(
  loanOriginationSystemApplicationStatusesRestApi,
);
const updateApplicationStatusMiddleware = UpdateApplicationStatusMiddleware(
  loanOriginationSystemApplicationStatusesRestApi,
);
const updateDeclineReasonsMiddleware = UpdateDeclineReasonsMiddleware(loanOriginationSystemDeclineReasonsRestApi);
const updateApplicationVariablesMiddleware = UpdateApplicationVariablesMiddleware(loanOriginationSystemApplicationsApi);
const getEmailsMiddleware = GetEmailsMiddleware(loanOriginationSystemEmailsApi);
const getBorrowersForEmailFilterMiddleware = GetBorrowersForEmailFilterMiddleware(loanOriginationSystemEmailsApi);

const applicationDecisionRunsPaginationMiddleware = applicationDecisionRunsPagination.getItemsFetcherMiddleware(
  async ({ applicationId, sortingType }) => {
    const { items } = await applicationDecisionRunsApi.getAll(applicationId, sortingType);
    return items;
  },
);
const getAutomatedProcessesMiddleware = GetAutomatedProcessesMiddleware(automatedProcessesApi);
const changeActivationStatusMiddleware = ChangeActivationStatusMiddleware(automatedProcessesApi);
const updateRuntimeOptionsMiddleware = UpdateRuntimeOptionsMiddleware(automatedProcessesApi);
const processAutomationPaginationMiddleware = ProcessAutomationPaginationMiddleware(automatedProcessesApi);
const processAutomationSelectCategoryMiddleware = ProcessAutomationSelectCategoryMiddleware();
const processAutomationSelectGroupMiddleware = ProcessAutomationSelectGroupMiddleware();
const changeActivationStatusSuccessMiddleware = ChangeActivationStatusSuccessMiddleware();
const updateRuntimeOptionsSuccessMiddleware = UpdateRuntimeOptionsSuccessMiddleware();
const getApplicationDocumentsMiddleware = GetApplicationDocumentsMiddleware(applicationDocumentsApi);
const uploadApplicationDocumentMiddleware = UploadApplicationDocumentMiddleware(applicationDocumentsApi);
const renameApplicationDocumentMiddleware = RenameApplicationDocumentMiddleware(applicationDocumentsApi);
const deleteApplicationDocumentMiddleware = DeleteAppicationDocumentMiddleware(applicationDocumentsApi);
const requestCustomerUploadMiddleware = RequestCustomerUploadMiddleware(applicationDocumentsApi);
const getOrganizationInfoByTokenMiddleware = GetOrganizationInfoMiddleware(customerDocumentUploadApi);
const uploadSecretDocumentMiddleware = UploadSecretDocumentMiddleware(customerDocumentUploadApi);
const navigateToProductOnCreateProductSuccessMiddleware = NavigateToProductOnCreateProductSuccessMiddleware(history);
const getProductMiddleware = GetProductMiddleware(loanOriginationSystemProductsApi);
const getStandardVariablesMiddleware = GetStandardVariablesMiddleware(variablesApi);

const changeVariableStatusMiddleware = ChangeVariableStatusMiddleware(variablesApi);
const getEmailTemplatesMiddleware = GetEmailTemplateMiddleware(sendGridTemplateApi);

const deleteApplicationMiddleware = DeleteApplicationMiddleware(loanOriginationSystemApplicationsApi);
const duplicateApplicationMiddleware = DuplicateApplicationMiddleware(loanOriginationSystemApplicationsApi);
const createApplicationStatusRuleMiddleware = CreateApplicationStatusRuleMiddleware(applicationStatusRuleApi);
const deleteApplicationStatusRuleMiddleware = DeleteApplicationStatusRuleMiddleware(applicationStatusRuleApi);
const updateApplicationStatusRuleMiddleware = UpdateApplicationStatusRuleMiddleware(applicationStatusRuleApi);
const getStatusesRulesValidationResultsMiddleware = GetStatusesRulesValidationResultsMiddleware(
  applicationStatusesRulesValidationApi,
);
const downloadProductApiRequestTemplateMiddleware = DownloadProductApiRequestTemplateMiddleware(apiRequestTemplatesApi);
const downloadBorrowerApiRequestTemplateMiddleware = DownloadBorrowerApiRequestTemplateMiddleware(
  apiRequestTemplatesApi,
);
const downloadIntermediaryApiRequestTemplateMiddleware = DownloadIntermediaryApiRequestTemplateMiddleware(
  apiRequestTemplatesApi,
);

const loadIntermediarySuggestions = LoadIntermediarySuggestionsMiddleware(loanOriginationSystemIntermediariesApi);
const getApplicationHistoryMiddleware = GetApplicationHistoryMiddleware(applicationHistoryRestApi);
const historyRecordsPaginationMiddleware = HistoryRecordsPaginationMiddleware(applicationHistoryRestApi);
const getApplicationDataTabCardsMiddleware = GetApplicationDataTabCardsMiddleware(applicationDataTabCardsApi);
const createApplicationDataTabCardMiddleware = CreateApplicationDataTabCardMiddleware(applicationDataTabCardsApi);
const deleteApplicationDataTabCardMiddleware = DeleteApplicationDataTabCardMiddleware(applicationDataTabCardsApi);
const updateApplicationDataTabCardMiddleware = UpdateApplicationDataTabCardMiddleware(applicationDataTabCardsApi);
const createApplicationDataTabVariableConfiguration = CreateApplicationDataTabVariableConfigurationMiddleware(
  applicationDataTabVariableConfigurationsApi,
);
const updateApplicationDataTabVariableConfiguration = UpdateApplicationDataTabVariableConfigurationMiddleware(
  applicationDataTabVariableConfigurationsApi,
);
const deleteApplicationDataTabVariableConfiguration = DeleteApplicationDataTabVariableConfigurationMiddleware(
  applicationDataTabVariableConfigurationsApi,
);

const getApplicationVariablesMiddleware = GetApplicationVariablesMiddleware(variablesApi);

const deleteApplicationCoborrowerMiddleware = DeleteApplicationCoborrowerMiddleware(
  loanOriginationSystemApplicationsApi,
);
const deleteApplicationIntermediaryMiddleware = DeleteApplicationIntermediaryMiddleware(
  loanOriginationSystemApplicationsApi,
);

const addApplicationCoborrowerMiddleware = AddApplicationCoborrowerMiddleware(loanOriginationSystemApplicationsApi);
const addApplicationIntermediaryMiddleware = AddApplicationIntermediaryMiddleware(loanOriginationSystemApplicationsApi);
const getDocuSignIntegrationMiddleware = GetDocuSignIntegrationMiddleware(docuSignIntegrationApi);

const getReportingFilterInitialDataMiddleware = GetReportingFilterInitialDataMiddleware(reportingApi);
const getReportingDataMiddleware = GetReportingDataMiddleware(reportingApi);
const createDocuSignIntegrationMiddleware = CreateDocuSignIntegrationMiddleware(docuSignIntegrationApi);
const updateDocuSignIntegrationMiddleware = UpdateDocuSignIntegrationMiddleware(docuSignIntegrationApi);
const getTemplatesToImportMiddleware = GetTemplatesToImportMiddleware(docuSignImportApi);
const getDocuSignTemplatesMiddleware = GetESignTemplatesMiddleware(eSignTemplatesApi);
const importDocuSignTemplatesMiddleware = ImportDocuSignTemplatesMiddleware(docuSignImportApi);
const updateDocuSignTemplateMiddleware = UpdateESignTemplateMiddleware(eSignTemplatesApi);
const deleteDocuSignTemplateMiddleware = DeleteESignTemplateMiddleware(eSignTemplatesApi);
const getDocuSignTemplateMiddleware = GetESignTemplateMiddleware(eSignTemplatesApi);
const docuSignTemplatesPaginationMiddleware = DocuSignTemplatesPaginationMiddleware(eSignTemplatesApi);
const createDocuSignEnvelopeMiddleware = CreateDocuSignEnvelopeMiddleware(docuSignEnvelopeApi);
const getSendGridEmailTemplateMiddleware = GetSendGridEmailTemplateMiddleware(sendGridTemplateApi);
const bulkUpdateBorrowerVariableConfigurationsMiddleware = BulkUpdateBorrowerVariableConfigurationsMiddleware(
  borrowerVariableConfigurationsApi,
);
const bulkUpdateIntermediaryVariableConfigurationsMiddleware = BulkUpdateIntermediaryVariableConfigurationsMiddleware(
  intermediaryVariableConfigurationsApi,
);
const getApplicationVariableConfigurationsMiddleware = GetApplicationVariableConfigurationsMiddleware(
  applicationVariableConfigurationsApi,
);
const updateApplicationVariableConfigurationsMiddleware = UpdateApplicationVariableConfigurationMiddleware(
  applicationVariableConfigurationsApi,
);
const createApplicationVariableConfigurationsMiddleware = CreateApplicationVariableConfigurationMiddleware(
  applicationVariableConfigurationsApi,
);
const deleteApplicationVariableConfigurationsMiddleware = DeleteApplicationVariableConfigurationMiddleware(
  applicationVariableConfigurationsApi,
);
const bulkUpdateVariableConfigurationsMiddleware = BulkUpdateApplicationVariableConfigurationsMiddleware(
  applicationVariableConfigurationsApi,
);
const updateApplicationTeamMembersMiddleware = UpdateApplicationTeamMembersMiddleware(
  loanOriginationSystemApplicationsApi,
);
const addApplicationLabelMiddleware = AddApplicationLabelMiddleware(loanOriginationSystemApplicationsApi);
const deleteApplicationLabelMiddleware = DeleteApplicationLabelMiddleware(loanOriginationSystemApplicationsApi);

export default [
  getColumnViewProductDataMiddleware,
  getProductsListMiddleware,
  getTableViewProductDataMiddleware,
  applicationsPaginationMiddleware,
  selectProductMiddleware,
  sortProductDataMiddleware,
  loadBorrowersSuggestionsMiddleware,
  unlockBorrowerMiddleware,
  createApplicationMiddleware,
  ...filtersMiddlewares,
  getApplicationDataMiddleware,
  updateApplicationMiddleware,
  updateApplicationSuccessMiddleware,
  getIntermediariesDataMiddleware,
  intermediariesPaginationMiddleware,
  sortIntermediariesDataMiddleware,
  deleteIntermediariesMiddleware,
  deleteIntermediariesSuccessMiddleware,
  getIntermediaryToDeleteApplicationsMiddleware,
  notifyOnIntermediaryToDeleteHasApplications,
  getBorrowersMiddleware,
  sortBorrowersMiddleware,
  borrowersPaginationMiddleware,
  getBorrowerVariableConfigurationsMiddleware,
  createBorrowerMiddleware,
  createBorrowerSuccessMiddleware,
  createIntermediaryMiddleware,
  createIntermediarySuccessMiddleware,
  deleteBorrowerMiddleware,
  deleteBorrowerSuccessMiddleware,
  getBorrowerToDeleteApplicationsMiddleware,
  notifyOnBorrowerToDeleteHasApplications,
  getBorrowerMiddleware,
  updateBorrowerMiddleware,
  updateBorrowerSuccessMiddleware,
  updateBorrowerDetailsSuccessMiddleware,
  getBorrowerApplicationsMiddleware,
  sortBorrowerApplicationsMiddleware,
  borrowerApplicationsPaginationMiddleware,
  unlockBorrowerSuccessMiddleware,
  getTasksMiddleware,
  tasksPaginationMiddleware,
  documentsPaginationMiddleware,
  notesPaginationMiddleware,
  sortTasksMiddleware,
  updateBorrowerVariableConfigurationMiddleware,
  createBorrowerVariableConfigurationMiddleware,
  deleteBorrowerVariableConfigurationMiddleware,
  notifyOnVariableConfigurationsChangeMiddleware,
  getConfigurationVariablesMiddleware,
  variablesConfigurationPaginationMiddleware,
  searchConfigurationVariablesMiddleware,
  createListVariableSuccessMiddleware,
  getLabelsMiddleware,
  getLabelInfoMiddleware,
  sortLabelsMiddleware,
  labelsPaginationMiddleware,
  deleteLabelMiddleware,
  deleteLabelSuccessMiddleware,
  createLabelMiddleware,
  createLabelSuccessMiddleware,
  deleteTaskMiddleware,
  deleteTaskSuccessMiddleware,
  applicationDecisionRunsMiddleware,
  deleteApplicationDecisionRunMiddleware,
  getApplicationStrategyListMiddleware,
  getApplicationStrategyInputsMiddleware,
  getApplicationDataTabVariableConfigurationsMiddleware,
  getApplicationNotesMiddleware,
  addApplicationNoteMiddleware,
  updateApplicationNoteMiddleware,
  deleteApplicationNoteRequestMiddleware,
  getOrganizationMembersMiddleware,
  getIntermediaryVariableConfigurationsMiddleware,
  createIntermediaryVariableConfigurationMiddleware,
  updateIntermediaryVariableConfigurationMiddleware,
  deleteIntermediaryVariableConfigurationMiddleware,
  getIntermediaryData,
  updateIntermediaryMiddleware,
  updateIntermediarySuccessMiddleware,
  getIntermediaryApplicationsMiddleware,
  intermediaryApplicationsPaginationMiddleware,
  sortIntermediaryApplicationsMiddleware,
  updateLabelMiddleware,
  updateLabelSuccessMiddleware,
  getApplicationStatusesMiddleware,
  applicationsChangeApplicationStatusMiddleware,
  getDeclineReasonsMiddleware,
  getProductsMiddleware,
  changeProductStatusMiddleware,
  updateProductMiddleware,
  duplicateProductMiddleware,
  onProductsFiltersChangeMiddleware,
  notifyOnProductStatusChangeSuccessMiddleware,
  notifyOnDuplicateProductSuccessMiddleware,
  reloadProductsOnDuplicateProductSuccessMiddleware,
  createProductMiddleware,
  notifyOnCreateProductSuccessMiddleware,
  reloadProductsOnCreateProductSuccessMiddleware,
  applicationDecisionRunsPaginationMiddleware,
  getOrganizationApplicationsMiddleware,
  createTaskMiddleware,
  createTaskSuccessMiddleware,
  getTaskDataMiddleware,
  editTaskMiddleware,
  editTaskSuccessMiddleware,
  getApplicationTabsMiddleware,
  createApplicationTabMiddleware,
  deleteApplicationTabMiddleware,
  updateApplicationTabMiddleware,
  getAutomatedProcessesMiddleware,
  changeActivationStatusMiddleware,
  changeActivationStatusSuccessMiddleware,
  updateRuntimeOptionsMiddleware,
  updateRuntimeOptionsSuccessMiddleware,
  processAutomationPaginationMiddleware,
  processAutomationSelectCategoryMiddleware,
  processAutomationSelectGroupMiddleware,
  deleteApplicationStatusMiddleware,
  createApplicationStatusMiddleware,
  updateApplicationStatusMiddleware,
  updateDeclineReasonsMiddleware,
  updateApplicationVariablesMiddleware,
  getApplicationDocumentsMiddleware,
  uploadApplicationDocumentMiddleware,
  renameApplicationDocumentMiddleware,
  searchApplicationDocumentsMiddleware,
  searchApplicationNotesMiddleware,
  sortApplicationDocumentsMiddleware,
  deleteApplicationDocumentMiddleware,
  notifyOnSuccessUpdateApplicationDocumentsMiddleware,
  reloadOnSuccessUpdateApplicationDocumentsMiddleware,
  changeVariableStatusMiddleware,
  onDeleteApplicationDecisionRunSuccessMiddleware,
  navigateToProductOnCreateProductSuccessMiddleware,
  getProductMiddleware,
  getStandardVariablesMiddleware,
  getEmailTemplatesMiddleware,
  requestCustomerUploadMiddleware,
  requestCustomerUploadSuccessMiddlewares,
  getOrganizationInfoByTokenMiddleware,
  uploadSecretDocumentMiddleware,
  uploadSecretDocumentSuccessMiddlewares,
  deleteApplicationMiddleware,
  duplicateApplicationMiddleware,
  getEmailsMiddleware,
  getBorrowersForEmailFilterMiddleware,
  sortEmailsMiddleware,
  emailsPaginationMiddleware,
  deleteEmailMiddleware,
  deleteEmailSuccessMiddleware,
  AddApplicationNotesSuccessMiddlewares,
  DeleteApplicationNotesSuccessMiddlewares,
  UpdateApplicationNotesSuccessMiddlewares,
  sortApplicationNotesMiddleware,
  createApplicationStatusRuleMiddleware,
  deleteApplicationStatusRuleMiddleware,
  updateApplicationStatusRuleMiddleware,
  notifyOnCreateApplicationStatusRuleMiddleware,
  notifyOnDeleteApplicationStatusRuleMiddleware,
  notifyOnUpdateApplicationStatusRuleMiddleware,
  notifyOnApplicationStatusRulesFail,
  loadIntermediarySuggestions,
  getStatusesRulesValidationResultsMiddleware,
  getApplicationHistoryMiddleware,
  reloadHistoryRecordsMiddleware,
  historyRecordsPaginationMiddleware,
  downloadProductApiRequestTemplateMiddleware,
  downloadBorrowerApiRequestTemplateMiddleware,
  downloadIntermediaryApiRequestTemplateMiddleware,
  getApplicationDataTabCardsMiddleware,
  createApplicationDataTabCardMiddleware,
  deleteApplicationDataTabCardMiddleware,
  updateApplicationDataTabCardMiddleware,
  createApplicationDataTabVariableConfiguration,
  updateApplicationDataTabVariableConfiguration,
  deleteApplicationDataTabVariableConfiguration,
  updateApplicationTabMiddleware,
  getApplicationVariablesMiddleware,
  deleteApplicationCoborrowerMiddleware,
  deleteApplicationIntermediaryMiddleware,
  addApplicationCoborrowerMiddleware,
  addApplicationIntermediaryMiddleware,
  getDocuSignIntegrationMiddleware,
  getReportingFilterInitialDataMiddleware,
  getReportingDataMiddleware,
  createDocuSignIntegrationMiddleware,
  updateDocuSignIntegrationMiddleware,
  getTemplatesToImportMiddleware,
  getDocuSignTemplatesMiddleware,
  importDocuSignTemplatesMiddleware,
  updateDocuSignTemplateMiddleware,
  deleteDocuSignTemplateMiddleware,
  getDocuSignTemplateMiddleware,
  onDocuSignTemplatesFilterChangeMiddleware,
  filterTemplatesByMembersMiddleware,
  searchDocuSignTemplatesMiddleware,
  sortDocuSignTemplatesMiddleware,
  reloadTemplatesOnSuccessUpdateMiddleware,
  docuSignTemplatesPaginationMiddleware,
  createDocuSignEnvelopeMiddleware,
  notifyOnCredentialsUpdateMiddleware,
  notifyOnSuccessTemplatesImportMiddleware,
  notifyOnTemplateDeleteMiddleware,
  notifyOnESignTemplateStatusChangeMiddleware,
  notifyOnSuccessDocuSignEnvelopeMiddleware,
  getSendGridEmailTemplateMiddleware,
  bulkUpdateBorrowerVariableConfigurationsMiddleware,
  bulkUpdateIntermediaryVariableConfigurationsMiddleware,
  getApplicationVariableConfigurationsMiddleware,
  createApplicationVariableConfigurationsMiddleware,
  updateApplicationVariableConfigurationsMiddleware,
  deleteApplicationVariableConfigurationsMiddleware,
  bulkUpdateVariableConfigurationsMiddleware,
  updateApplicationTeamMembersMiddleware,
  updateApplicationTeamMembersSuccessMiddleware,
  addApplicationLabelMiddleware,
  deleteApplicationLabelMiddleware,
  notifyOnApplicationFormSettingsUpdateMiddleware,
  notifyOnUpdateRequiredFieldsMiddleware,
];
