import LoanOriginationSystemApi from './LoanOriginationSystemApi';

export interface LoanOriginationSystemDeclineReasonsApi {
  getAll(productId: string): Promise<string[]>;
  update(productId: string, declineReasons: string[]): Promise<string[]>;
}

export default class LoanOriginationSystemDeclineReasonsRestApi extends LoanOriginationSystemApi
  implements LoanOriginationSystemDeclineReasonsApi {
  public async getAll(productId: string) {
    return this.fetch<string[]>(`/products/${productId}/decline-reasons`);
  }

  public update(productId: string, declineReasons: string[]) {
    return this.fetch<string[]>(`/products/${productId}/decline-reasons`, 'PUT', { declineReasons });
  }
}
