export enum ApplicationStatusesActionType {
  GetApplicationStatuses = 'applicationStatuses/getApplicationStatuses',
  GetApplicationStatusesSuccess = 'applicationStatuses/getApplicationStatusesSuccess',
  GetApplicationStatusesFailed = 'applicationStatuses/getApplicationStatusesFailed',
  DeleteApplicationStatus = 'applicationStatuses/deleteApplicationStatus',
  DeleteApplicationStatusSuccess = 'applicationStatuses/deleteApplicationStatusSuccess',
  DeleteApplicationStatusFailure = 'applicationStatuses/deleteApplicationStatusFailure',
  CreateApplicationStatus = 'applicationStatuses/createApplicationStatus',
  CreateApplicationStatusSuccess = 'applicationStatuses/createApplicationStatusSuccess',
  CreateApplicationStatusFailure = 'applicationStatuses/createApplicationStatusFailure',
  UpdateApplicationStatus = 'applicationStatuses/updateApplicationStatus',
  UpdateApplicationStatusSuccess = 'applicationStatuses/updateApplicationStatusSuccess',
  UpdateApplicationStatusFailure = 'applicationStatuses/updateApplicationStatusFailure',
  CreateApplicationStatusRule = 'applicationStatuses/createApplicationStatusRule',
  CreateApplicationStatusRuleSuccess = 'applicationStatuses/createApplicationStatusRuleSuccess',
  CreateApplicationStatusRuleFailure = 'applicationStatuses/createApplicationStatusRuleFailure',
  UpdateApplicationStatusRule = 'applicationStatuses/updateApplicationStatusRule',
  UpdateApplicationStatusRuleSuccess = 'applicationStatuses/updateApplicationStatusRuleSuccess',
  UpdateApplicationStatusRuleFailure = 'applicationStatuses/updateApplicationStatusRuleFailure',
  DeleteApplicationStatusRule = 'applicationStatuses/deleteApplicationStatusRule',
  DeleteApplicationStatusRuleSuccess = 'applicationStatuses/deleteApplicationStatusRuleSuccess',
  DeleteApplicationStatusRuleFailure = 'applicationStatuses/deleteApplicationStatusRuleFailure',
}
