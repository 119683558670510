import React, { FC, ReactNode } from 'react';
import styles from './PaymentRestrictionMessage.module.scss';
import ShareButton from 'components/BillingDashboard/ShareButton';
import clsx from 'clsx';

interface PaymentRestrictionMessageProps {
  title: string;
  description: ReactNode;
  textButton: string;
  onButtonClick: () => void;
  className?: string;
  innerContainerClassName?: string;
}

const PaymentRestrictionMessage: FC<PaymentRestrictionMessageProps> = ({
  title,
  description,
  textButton,
  onButtonClick,
  className,
  innerContainerClassName,
}) => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={clsx(styles.innerContainer, innerContainerClassName)}>
        <div className={styles.messageContent}>
          <h4 className={styles.title}>{title}</h4>
          {description}
        </div>
        <ShareButton size="form" kind="primary" onClick={onButtonClick} className={styles.shareButton}>
          {textButton}
        </ShareButton>
      </div>
    </div>
  );
};

export default PaymentRestrictionMessage;
