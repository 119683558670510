import { AnyAction } from 'redux';
import { FormLayoutData } from 'api/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemCreateBorrowerTypes } from './ActionTypes';

export interface CreateBorrowerAction extends AnyAction {
  type: LoanOriginationSystemCreateBorrowerTypes.CreateBorrower;
  payload: {
    data: FormLayoutData;
    borrowerType: BorrowerType;
  };
}

export interface CreateBorrowerSuccessAction {
  type: LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerSuccess;
}

export const createBorrower = (borrowerType: BorrowerType, data: FormLayoutData) => ({
  type: LoanOriginationSystemCreateBorrowerTypes.CreateBorrower,
  payload: {
    borrowerType,
    data,
  },
});

export const createBorrowerSuccess = (): CreateBorrowerSuccessAction => ({
  type: LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerSuccess,
});

export const createBorrowerFailure = FailedActionCreator(
  LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerFailure,
);
