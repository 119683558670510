import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { StrategyGroupVersion } from 'api/Types';
import { VersionsTableViewSortingType } from 'VersionsViewData/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const PER_PAGE_DEFAULT = 10;

const versionsPagination = Pagination<
  ReduxState,
  StrategyGroupVersion,
  {
    searchInputValue: string;
    selectedUpdaters: UserInfo[];
    showArchived: boolean;
    strategyName: string;
    sortingType: VersionsTableViewSortingType;
  }
>('versionsListData', PER_PAGE_DEFAULT, ({ searchInputValue }) => {
  return searchInputValue ? ['searched', searchInputValue] : [];
});

export default versionsPagination;
