import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import Tag from 'components/Tag/Tag';
import CustomCheckbox from 'components/CustomCheckbox';
import { SkeletonRectangle } from 'components/Skeleton';
import WrapperWithTooltip from 'components/Tooltip';
import ShareButton from 'components/BillingDashboard/ShareButton';
import styles from './ImportTemplatesPopup.module.scss';

export type TemplateToImportStatus = 'new' | 'active' | 'inactive';

interface DefaultTemplateToImport {
  templateId: string;
  name: string;
  status: TemplateToImportStatus;
}

interface ImportTemplatesPopUpProps<TemplateToImport> {
  onPopupClose: () => void;
  onImportTemplates: (templates: TemplateToImport[]) => void;
  isImportInProgress: boolean;
  templatesToImport: TemplateToImport[] | null;
  getCheckboxErrorByTemplate: (templateToImport: TemplateToImport) => string;
  warningTextMessage: string;
  integrationLink: string;
  integrationTitle: string;
  isTemplateInitiallySelected?: (templateToImport: TemplateToImport) => boolean;
}

const DEFAULT_SKELETON_ITEMS_LENGTH = 5;

const getTagColorByTemplateStatus = (status: TemplateToImportStatus) => {
  switch (status) {
    case 'new': {
      return 'blue';
    }
    case 'active': {
      return 'green';
    }
    case 'inactive': {
      return 'gray';
    }
    default: {
      return 'gray';
    }
  }
};

const ImportTemplatesPopup = <TemplateToImport extends DefaultTemplateToImport>({
  onPopupClose,
  onImportTemplates,
  templatesToImport,
  isImportInProgress,
  warningTextMessage,
  integrationTitle,
  integrationLink,
  getCheckboxErrorByTemplate,
  isTemplateInitiallySelected,
}: ImportTemplatesPopUpProps<TemplateToImport>) => {
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<string[]>([]);

  useEffect(() => {
    if (!templatesToImport) {
      return;
    }

    const templates = templatesToImport.filter((template) => {
      return template.status === 'new' && (!isTemplateInitiallySelected || isTemplateInitiallySelected(template));
    });

    setSelectedTemplateIds(templates.map((template) => template.templateId));
  }, [templatesToImport]);

  const handleImportTemplates = () => {
    if (!templatesToImport) {
      return;
    }

    const selectedTemplates = selectedTemplateIds.map((templateId) => {
      return templatesToImport.find((template) => template.templateId === templateId)!;
    });

    onImportTemplates(selectedTemplates);
  };

  const handleTemplateToImportCheck = (templateToImport: TemplateToImport, checked: boolean) => {
    const newSelectedTemplateIds = checked
      ? [...selectedTemplateIds, templateToImport.templateId]
      : selectedTemplateIds.filter((templateId) => templateId !== templateToImport.templateId);

    setSelectedTemplateIds(newSelectedTemplateIds);
  };

  const handleGoToIntegrationClick = () => {
    window.open(integrationLink, '_blank');
  };

  const renderTemplateToImport = (templateToImport: TemplateToImport) => (
    <div className={styles.templateToImport} key={templateToImport.templateId}>
      <WrapperWithTooltip tooltip={getCheckboxErrorByTemplate(templateToImport)}>
        <span>
          <CustomCheckbox
            disabled={!!getCheckboxErrorByTemplate(templateToImport)}
            onChange={(event, checked) => handleTemplateToImportCheck(templateToImport, checked)}
            checked={selectedTemplateIds.includes(templateToImport.templateId)}
            style={{ padding: 0 }}
          />
        </span>
      </WrapperWithTooltip>
      <p>{templateToImport.name}</p>
      <Tag color={getTagColorByTemplateStatus(templateToImport.status)}>{templateToImport.status}</Tag>
    </div>
  );

  const renderTemplatesToImport = () => {
    if (!templatesToImport) {
      return new Array(DEFAULT_SKELETON_ITEMS_LENGTH)
        .fill(null)
        .map((item, index) => (
          <SkeletonRectangle key={index} className={styles.skeletonItem} width="480px" height="20px" color="primary6" />
        ));
    }

    if (!templatesToImport.length) {
      return null;
    }

    return templatesToImport.map(renderTemplateToImport);
  };

  const renderDescription = () => {
    if (!templatesToImport) {
      return null;
    }

    if (!templatesToImport.length) {
      return `There are no templates to import. Please set up a template in ${integrationTitle}.`;
    }

    return 'The following templates are available to be imported.';
  };

  const getWarningMessage = () => {
    if (!templatesToImport) {
      return '';
    }

    const allTemplatesAreNew = templatesToImport.every((template) => template.status === 'new');

    return allTemplatesAreNew ? '' : warningTextMessage;
  };

  const renderButtons = () => {
    if (!templatesToImport) {
      return null;
    }

    if (!templatesToImport.length) {
      return (
        <>
          <ShareButton className={styles.shareButton} kind="primary" size="form" onClick={handleGoToIntegrationClick}>
            Go To {integrationTitle}
          </ShareButton>
          <Button kind="secondary" size="form" onClick={onPopupClose}>
            Close Window
          </Button>
        </>
      );
    }

    return (
      <Button
        kind="primary"
        size="form"
        disabled={!selectedTemplateIds.length}
        className={styles.importTemplatesButton}
        onClick={handleImportTemplates}
        isLoading={isImportInProgress}
      >
        Import Templates
      </Button>
    );
  };

  return (
    <PopUp title="Import Templates" closePopUp={onPopupClose} warningText={getWarningMessage()}>
      <PopUpContent hasTopMargin className={styles.popupContent}>
        <p className={styles.description}>{renderDescription()}</p>
        <div
          className={clsx(
            styles.templatesToImportContainer,
            templatesToImport && !templatesToImport.length && styles.noItemsTemplatesToImportContainer,
          )}
        >
          {renderTemplatesToImport()}
        </div>
        <div className={styles.buttonsContainer}>{renderButtons()}</div>
      </PopUpContent>
    </PopUp>
  );
};

export default ImportTemplatesPopup;
