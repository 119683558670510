import React, { FC, useEffect, useState } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import { IndividualRunInputs, StrategiesListItem } from 'api/Types';
import { ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';
import ApplicationDecisionRunForm from 'components/ApplicationDecisionRunForm/ApplicationDecisionRunForm';

interface RunDecisionWizardProps {
  isLoading: boolean;
  closePopUp: () => void;
  strategies: StrategiesListItem[];
  strategyInputs: ProcessingStrategyInput[] | null;
  inputValues: IndividualRunInputs;
  applicationProductInputs: string[];
  onRunDecision: (strategyId: string, inputs: IndividualRunInputs) => void;
  getStrategiesList: () => void;
  resetStrategyInputs: () => void;
  getStrategyInputs: (strategyId: string) => void;
  getApplicationProductConfigurations: () => void;
  dataLoading: boolean;
}

export const RunDecisionWizard: FC<RunDecisionWizardProps> = ({
  closePopUp,
  isLoading,
  strategies,
  strategyInputs,
  applicationProductInputs,
  onRunDecision,
  getStrategiesList,
  getApplicationProductConfigurations,
  resetStrategyInputs,
  getStrategyInputs,
  dataLoading,
  inputValues,
}) => {
  const [strategyId, setStrategyId] = useState('');
  useEffect(() => {
    getStrategiesList();
    return () => {
      resetStrategyInputs();
    };
  }, []);

  return (
    <PopUpWizard noPadding step={0} closePopUp={closePopUp} title="Run Decision Engine" closable={!isLoading}>
      <ApplicationDecisionRunForm
        isLoading={isLoading}
        strategies={strategies}
        currentStrategy={strategyId}
        strategyInputs={strategyInputs}
        inputValues={inputValues}
        applicationProductInputs={applicationProductInputs}
        dataLoading={dataLoading}
        onRunDecision={(inputs: IndividualRunInputs) => onRunDecision(strategyId, inputs)}
        onStrategyChange={async (id: string) => {
          setStrategyId(id);
          if (id) {
            getStrategyInputs(id);
            getApplicationProductConfigurations();
          } else {
            resetStrategyInputs();
          }
        }}
      />
    </PopUpWizard>
  );
};
