import { RuleOutput } from 'RuleCreate/Types';

const getFormattedOutputs = (outputsList: RuleOutput[]) =>
  outputsList.reduce((accumulator, output, index) => {
    const { sourceVariableId, operandValue, operandVariableId, operandType } = output;
    return {
      ...accumulator,
      [`condition_output*${index}*value`]: operandType === 'variable' ? operandVariableId : operandValue,
      [`condition_output*${index}*value_type`]: operandType,
      [`condition_output*${index}*variable`]: sourceVariableId,
    };
  }, {});

export default getFormattedOutputs;
