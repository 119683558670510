import React from 'react';
import { TableRow, TableActionCell, TableBodyCell } from 'components/Table';
import { SkeletonRectangle } from 'components/Skeleton';
import { BorrowersColumnSizes } from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/TableColumnSizes';

const SkeletonBorrowerItemRow = () => {
  return (
    <TableRow disableHover useActions>
      <TableBodyCell width={BorrowersColumnSizes.Name}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.IdNumber}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.Phone}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.Email}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.UpdatedAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonBorrowerItemRow;
