import clsx from 'clsx';
import Portal from 'components/Portal';
import { noop } from 'lodash';
import React, { FC, RefObject, useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import cumulativeElementOffset from 'utils/cumulativeElementOffset';
import animationData from './animationData.json';
import styles from './ConfettiAnimation.module.scss';

interface Position {
  top: number;
  left: number;
}

interface ConfettiAnimationProps {
  targetElementRef: RefObject<HTMLElement>;
  className?: string;
  offset?: Position;
  onAnimationComplete?: () => void;
  size?: number;
}

const DEFAULT_OFFSET: Position = { top: 0, left: 0 };

const DEFAULT_SIZE = 200;

const ConfettiAnimation: FC<ConfettiAnimationProps> = ({
  targetElementRef,
  className,
  offset = DEFAULT_OFFSET,
  size = DEFAULT_SIZE,
  onAnimationComplete,
}) => {
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });

  const handleReposition = () => {
    if (targetElementRef.current) {
      const newPosition = cumulativeElementOffset(targetElementRef.current);

      const newTop = newPosition.top - size / 2 + targetElementRef.current.clientHeight / 2;
      const newLeft = newPosition.left - size / 2 + targetElementRef.current.clientWidth / 2;

      setPosition({
        top: newTop + offset.top,
        left: newLeft + offset.left,
      });
    }
  };

  useEffect(() => {
    handleReposition();
  }, [targetElementRef.current]);

  return (
    <Portal>
      <div className={clsx(styles.container, className)}>
        <Lottie
          options={{ animationData, autoplay: true, loop: false }}
          width={size}
          height={size}
          style={{
            position: 'fixed',
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          eventListeners={[{ eventName: 'complete', callback: onAnimationComplete || noop }]}
        />
      </div>
    </Portal>
  );
};

export default ConfettiAnimation;
