import React, { ReactNode, FC, forwardRef } from 'react';
import clsx from 'clsx';
import PopUpCore from './PopUpCore';
import PopUpHeaderWithClose from './PopUpHeaderWithClose';
import Spinner from 'components/Spinner';
import Portal from 'components/Portal';
import styles from './PopUpContent.module.scss';

interface PopUpProps {
  children: ReactNode;
  closePopUp: () => void;
  title: string | React.ReactNode;
  classNames?: Partial<{
    popup: string;
    popupCore: string;
  }>;
  isLoading?: boolean;
  warningText?: string;
  titleTooltip?: string;
  closable?: boolean;
  usePortal?: boolean;
}

const PopUp: FC<PopUpProps> = (
  { children, closePopUp, title, classNames = {}, isLoading, warningText, titleTooltip, closable, usePortal },
  ref,
) => {
  const renderPopup = () => (
    <div className={clsx(styles.popup, classNames.popup)}>
      <PopUpCore className={classNames.popupCore} ref={ref} warningText={warningText}>
        <PopUpHeaderWithClose titleTooltip={titleTooltip} title={title} onClose={closePopUp} closable={closable} />
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Spinner size={24} background="light" />
          </div>
        ) : (
          children
        )}
      </PopUpCore>
    </div>
  );

  if (usePortal) {
    return <Portal tagName="div">{renderPopup()}</Portal>;
  }

  return renderPopup();
};

export default forwardRef(PopUp);
