import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BulkUpdateVariableConfigurationParams, VariableConfiguration } from './Types';

export interface ApplicationVariableConfiguration extends VariableConfiguration {
  productId: string;
}

export interface CreateApplicationVariableConfigurationParams {
  productId: string;
  column: number;
  position: number;
  variableId: string;
}

export type UpdateApplicationVariableConfigurationParams = Partial<
  Omit<CreateApplicationVariableConfigurationParams, 'productId' | 'variableId'>
>;

export interface ApplicationVariableConfigurationsApi {
  find(productId: string): Promise<ApplicationVariableConfiguration[]>;
  create(params: CreateApplicationVariableConfigurationParams): Promise<ApplicationVariableConfiguration>;
  bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]): Promise<void>;
  update(id: string, params: UpdateApplicationVariableConfigurationParams): Promise<ApplicationVariableConfiguration>;
  delete(id: string): Promise<void>;
}

export default class ApplicationVariableConfigurationsRestApi
  extends LoanOriginationSystemApi<ApplicationVariableConfiguration>
  implements ApplicationVariableConfigurationsApi {
  protected resourceName = 'application-variable-configurations';

  public find(productId: string) {
    const params = new URLSearchParams();

    params.set('productId', productId);

    return this.getResources(params);
  }

  public create(params: CreateApplicationVariableConfigurationParams) {
    return this.createResource({
      variableConfiguration: {
        ...params,
      },
    });
  }

  public bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]): Promise<void> {
    return this.fetch<void>(`/${this.resourceName}`, 'PUT', {
      configurations,
    });
  }

  public update(id: string, params: UpdateApplicationVariableConfigurationParams) {
    return this.updateResource(id, {
      variableConfiguration: { ...params },
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
