import LoanOriginationSystemApi from './LoanOriginationSystemApi';

export enum ApplicationTabType {
  DataTab = 'data',
  Emails = 'emails',
  Documents = 'documents',
  Tasks = 'tasks',
  Notes = 'notes',
  DecisionEngine = 'decisionEngine',
  StatusRules = 'statusRules',
  History = 'history',
}

export interface ApplicationTab {
  id: string;
  type: ApplicationTabType;
  name: string;
  position: number;
  productId: string;
}

export interface CreateApplicationTabParams {
  productId: string;
  name?: string;
  type: ApplicationTabType;
  position: number;
}

export type UpdateApplicationTabParams = Partial<Omit<CreateApplicationTabParams, 'productId' | 'type'>>;

export interface ApplicationTabsApi {
  find(productId: string): Promise<ApplicationTab[]>;
  create(params: CreateApplicationTabParams): Promise<ApplicationTab>;
  update(id: string, tab: Partial<ApplicationTab>): Promise<ApplicationTab>;
  delete(id: string): Promise<void>;
}

export default class ApplicationTabsRestApi extends LoanOriginationSystemApi<ApplicationTab>
  implements ApplicationTabsApi {
  protected resourceName = 'application-tabs';

  public find(productId: string) {
    const params = new URLSearchParams();

    params.set('productId', productId);

    return this.getResources(params);
  }

  public create(params: CreateApplicationTabParams) {
    return this.createResource({
      tab: params,
    });
  }

  public update(id: string, params: UpdateApplicationTabParams) {
    return this.updateResource(id, { tab: params });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
