import { AnyAction } from 'redux';

import FailedActionCreator from 'utils/actions/FailedActionCreator';

import {
  ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST,
  ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS,
  ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_FAILURE,
} from './ActionTypes';
import { AddNewUserParams, NewUserType } from './AddNewCompanyUserTypes';

export interface AddNewCompanyUserRequestAction extends AnyAction {
  payload: AddNewUserParams;
}

export interface AddNewCompanyUserSuccessAction extends AnyAction {
  payload: NewUserType;
}

export const AddNewCompanyUserSuccess = (userInfo: NewUserType): AddNewCompanyUserSuccessAction => {
  return {
    type: ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_SUCCESS,
    payload: userInfo,
  };
};

export const AddNewCompanyUserError = FailedActionCreator(ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST_FAILURE);

export const AddNewCompanyUserRequest = (userParams: AddNewUserParams): AddNewCompanyUserRequestAction => {
  return {
    type: ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST,
    payload: userParams,
  };
};
