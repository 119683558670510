import { LoanOriginationSystemIntermediariesFiltersState } from './Types';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './ActionTypes';
import {
  SetSearchInputValueAction,
  ToggleFiltersPopupAction,
  ChangePopupFiltersAction,
  FilterIntermediariesByMembersAction,
  ResetAllIntermediariesFilters,
} from './ActionCreator';

export const MIN_COMMISSION_VALUE = 0;
export const MAX_COMMISSION_VALUE = 100;

export const initialState: LoanOriginationSystemIntermediariesFiltersState = {
  searchInputValue: '',
  isPopupVisible: false,
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
  dueUpdatedDateRange: {
    from: null,
    to: null,
  },
  dueCommissionRange: {
    from: MIN_COMMISSION_VALUE,
    to: MAX_COMMISSION_VALUE,
  },
  borrowerTypes: [],
  selectedMembers: [],
};

type LoanOriginationSystemIntermediariesFilterAction =
  | SetSearchInputValueAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | FilterIntermediariesByMembersAction
  | ResetAllIntermediariesFilters;

export const loanOriginationSystemIntermediariesFilterReducer = (
  state: LoanOriginationSystemIntermediariesFiltersState,
  action: LoanOriginationSystemIntermediariesFilterAction,
): LoanOriginationSystemIntermediariesFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemIntermediariesFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchInputValue,
      };
    }

    case LoanOriginationSystemIntermediariesFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }

    case LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters: {
      const { dueCreatedDateRange, dueUpdatedDateRange, dueCommissionRange, borrowerTypes } = action.payload;
      return {
        ...state,
        isPopupVisible: false,
        dueCreatedDateRange,
        dueUpdatedDateRange,
        dueCommissionRange,
        borrowerTypes,
      };
    }

    case LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }

    case LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
