import Fetcher from 'middlewares/Fetcher';
import { BorrowerVariableConfigurationsApi } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import {
  DeleteBorrowerVariableConfigurationAction,
  deleteBorrowerVariableConfigurationSuccess,
  deleteBorrowerVariableConfigurationFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const DeleteBorrowerVariableConfigurationMiddleware = (api: BorrowerVariableConfigurationsApi) =>
  Fetcher<void, DeleteBorrowerVariableConfigurationAction>(
    VariablesConfigurationActionType.DeleteBorrowerVariableConfiguration,
    deleteBorrowerVariableConfigurationSuccess,
    deleteBorrowerVariableConfigurationFailure,
    (action) => {
      return api.delete(action.payload.id);
    },
  );

export default DeleteBorrowerVariableConfigurationMiddleware;
