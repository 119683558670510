import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import styles from './PastDueSubscription.module.scss';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { useAuthProvider } from 'providers/AuthProvider';
import UserCard from 'components/UserCard';
import ShareButton from 'components/BillingDashboard/ShareButton';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import { ExternalRoutes } from 'routes/RouteService';

const PastDueSubscription: FC = () => {
  const authProvider = useAuthProvider();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const { user } = useSelector((state: ReduxState) => state.sessionInfo);

  const subTitle = (
    <>
      Please contact the administrator of your organization to make a payment. Please note that API access will also be
      unavailable until this payment is made.
    </>
  );

  const subTitleForOwner = (
    <>
      Please make a payment to re-activate your account. Please note that API access will also be unavailable until this
      payment is made.
    </>
  );

  const isOwner = user!.role === UserRoleName.Owner;

  return (
    <AuthLayout title="Your Subscription is Past Due" subTitle={isOwner ? subTitleForOwner : subTitle}>
      {isOwner && (
        <ShareButton
          className={styles.goToPortalButton}
          fullWidth
          size="form"
          onClick={() => window.open(ExternalRoutes.PaymentPortal)}
        >
          Go to Payment Portal
        </ShareButton>
      )}
      <UserCard
        firstName={accountDetails!.firstName}
        lastName={accountDetails!.lastName}
        imageId={accountDetails!.imageId}
        onLogOutClick={() => authProvider.logout()}
        noMargin
        className={styles.userCard}
      />
    </AuthLayout>
  );
};

export default PastDueSubscription;
