import AuthEventEmitter, { AuthEventMessage } from 'eventHandlers/AuthEventEmitter';
import HttpStatusCodes from 'http-status-codes';

const UnauthorizedMiddleware = (response: Response): void => {
  if (response.status === HttpStatusCodes.UNAUTHORIZED) {
    AuthEventEmitter.emit(AuthEventMessage.UnauthorizedRequest);
  }
};

export default UnauthorizedMiddleware;
