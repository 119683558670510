import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './EditLabelPopUp.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import BasicColors from 'components/LoanOriginationSystem/LabelsDetails/BasicColors';
import ColorPickerInput from 'components/ColorPickerInput';
import {
  validateLabelColor,
  validateLabelName,
} from 'components/LoanOriginationSystem/LabelsDetails/CreateLabelPopUp/utils/validation/validation';
import { SkeletonRectangle } from 'components/Skeleton';

interface UpdateLabelPopUpProps {
  onPopupClose: () => void;
  blockingActionInProgress: boolean;
  initialLabelName: string;
  initialLabelColor: string;
  onUpdateLabel: (name: string, color: string) => void;
  isLoading: boolean;
}

const DEFAULT_SKELETON_COLORS_LENGTH = 9;

const UpdateLabelPopUp = ({
  onPopupClose,
  blockingActionInProgress,
  initialLabelName,
  initialLabelColor,
  onUpdateLabel,
  isLoading,
}: UpdateLabelPopUpProps) => {
  const [labelName, setLabelName] = useState(initialLabelName);
  const [labelColor, setLabelColor] = useState(initialLabelColor);
  const [lastCorrectHEXValue, setLastCorrectHEXValue] = useState('');

  useEffect(() => {
    if (labelColor.length === 6 || labelColor.length === 3) {
      setLastCorrectHEXValue(labelColor);
    }
  }, [labelColor]);

  useEffect(() => {
    setLabelName(initialLabelName);
    setLabelColor(initialLabelColor);
  }, [initialLabelName, initialLabelColor]);

  const setCorrectHEXValue = () => {
    if (labelColor.length === 6 || labelColor.length === 3) {
      return;
    }
    setLabelColor(lastCorrectHEXValue);
  };

  const onLabelNameChange = (value: string) => {
    if (validateLabelName(value)) {
      setLabelName(value);
    }
  };

  const onLabelColorChange = (value: string) => {
    if (validateLabelColor(value)) {
      setLabelColor(value);
    }
    if (value[0] === '#') {
      setLabelColor(value.substring(1));
    }
  };

  const isButtonDisabled = () =>
    (labelColor.trim() === initialLabelColor && labelName.trim() === initialLabelName) ||
    !labelName.trim() ||
    !labelColor.trim();

  const renderBody = () => (
    <>
      <TextInput
        labelTitle="Label Name"
        value={labelName}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => onLabelNameChange(target.value)}
        maxLength={50}
      />
      <BasicColors checkedColor={labelColor} onBasicColorChange={onLabelColorChange} />
      <ColorPickerInput
        value={labelColor}
        color={lastCorrectHEXValue}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => onLabelColorChange(target.value)}
        onBlur={setCorrectHEXValue}
        setColor={onLabelColorChange}
      />
      <Button
        kind="primary"
        size="form"
        className={styles.saveButton}
        onClick={() => onUpdateLabel(labelName.trim(), labelColor)}
        isLoading={blockingActionInProgress}
        disabled={isButtonDisabled()}
      >
        Save Changes
      </Button>
    </>
  );

  const renderSkeleton = () => (
    <>
      <SkeletonRectangle width="100px" height="16px" color="primary6" marginTop="24px" />
      <SkeletonRectangle width="600px" height="52px" color="primary6" marginTop="8px" />
      <SkeletonRectangle width="100px" height="16px" color="primary6" marginTop="24px" marginBottom="8px" />
      <div className={styles.colorsContainer}>
        {new Array(DEFAULT_SKELETON_COLORS_LENGTH).fill(null).map((_, index) => (
          <SkeletonRectangle
            width="52px"
            height="52px"
            color="primary6"
            marginRight={index !== DEFAULT_SKELETON_COLORS_LENGTH - 1 ? '16px' : ''}
          />
        ))}
      </div>
      <SkeletonRectangle width="100px" height="16px" color="primary6" marginTop="24px" />
      <SkeletonRectangle width="188px" height="52px" color="primary6" marginTop="8px" />
      <SkeletonRectangle width="180px" height="52px" color="primary6" marginTop="36px" />
    </>
  );

  return (
    <PopUp title="Edit Label" closePopUp={onPopupClose} closable={!blockingActionInProgress}>
      <PopUpContent>{isLoading ? renderSkeleton() : renderBody()}</PopUpContent>
    </PopUp>
  );
};

export default UpdateLabelPopUp;
