import BillingRestApi from 'api/BillingApi';
import DecisionRunApiRest from 'api/DecisionEngine/DecisionRunService';
import LoanOriginationSystemApplicationsApiRest from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import responseHandlers from 'api/ResponseHandlers';
import GetApplicationsCountMiddleware from 'BillingPage/GetApplicationsCountMiddleware';
import GetBillingInfoMiddleware from 'BillingPage/GetBillingInfoMiddleware';
import GetDecisionsCountMiddleware from 'BillingPage/GetDecisionsCountMiddleware';
import GetInvoicesMiddleware from 'BillingPage/GetInvoicesMiddleware';
import sortVariablesMiddleware from 'Variables/SortVariablesMiddleware';
import onVariablesFiltersChangeMiddleware from 'Variables/Filters/OnFiltersChangeMiddleware';
import UsersPaginationMiddleware from 'OrganizationUsers/UsersPaginationMiddleware';
import UserRestApi from 'api/Core/UserApi';
import onToggleShowArchivedMiddleware from 'Variables/OnToggleShowArchivedMiddleware';
import filterVariablesByMembersMiddleware from 'Variables/Filters/FilterVariablesByMembersMiddleware';

const billingApi = new BillingRestApi(responseHandlers);
const loanOriginationSystemApplicationsApiRest = new LoanOriginationSystemApplicationsApiRest(responseHandlers);
const decisionRunApiRest = new DecisionRunApiRest(responseHandlers);
const userApi = new UserRestApi(responseHandlers);

const getBillingInfoMiddleware = GetBillingInfoMiddleware(billingApi);
const getInvoicesMiddleware = GetInvoicesMiddleware(billingApi);
const getApplicationsCountMiddleware = GetApplicationsCountMiddleware(loanOriginationSystemApplicationsApiRest);
const getDecisionsCountMiddleware = GetDecisionsCountMiddleware(decisionRunApiRest);
const usersPaginationMiddleware = UsersPaginationMiddleware(userApi);

const companySettingsMiddlewares = [
  getBillingInfoMiddleware,
  getInvoicesMiddleware,
  getApplicationsCountMiddleware,
  getDecisionsCountMiddleware,
  sortVariablesMiddleware,
  onToggleShowArchivedMiddleware,
  onVariablesFiltersChangeMiddleware,
  usersPaginationMiddleware,
  filterVariablesByMembersMiddleware,
];

export default companySettingsMiddlewares;
