import { Middleware } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';
import AccountDetailsActionType from 'AccountDetails/ActionTypes';
import {
  disablePhoneAuthenticationFailure,
  disablePhoneAuthenticationSuccess,
} from 'AccountDetails/AccountDetailsActionCreator';
import { User } from 'AccountDetails/AccountDetailsTypes';

const DisablePhoneAuthenticationMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<User>(
    AccountDetailsActionType.DisablePhoneAuthentication,
    disablePhoneAuthenticationSuccess,
    disablePhoneAuthenticationFailure,
    ({ payload }) => {
      return api.disablePhoneAuthentication(payload.code);
    },
  );

export default DisablePhoneAuthenticationMiddleware;
