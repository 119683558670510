import { AnyAction } from 'redux';
import { DownloadResponse } from 'api/APIRest';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { IntermediaryApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { IntermediaryConfigurationActionType } from './ActionTypes';

export interface DownloadApiRequestTemplateAction extends AnyAction {
  type: IntermediaryConfigurationActionType.DownloadApiRequestTemplate;
  payload: {
    requestType: IntermediaryApiRequestTemplateType;
  };
}

export interface DownloadApiRequestTemplateSuccessAction extends AnyAction {
  type: IntermediaryConfigurationActionType.DownloadApiRequestTemplateSuccess;
  payload: {
    response: DownloadResponse;
  };
}

export const downloadApiRequestTemplate = (
  requestType: IntermediaryApiRequestTemplateType,
): DownloadApiRequestTemplateAction => ({
  type: IntermediaryConfigurationActionType.DownloadApiRequestTemplate,
  payload: {
    requestType,
  },
});

export const downloadApiRequestTemplateSuccess = (
  response: DownloadResponse,
): DownloadApiRequestTemplateSuccessAction => ({
  type: IntermediaryConfigurationActionType.DownloadApiRequestTemplateSuccess,
  payload: {
    response,
  },
});

export const downloadApiRequestTemplateFailure = FailedActionCreator(
  IntermediaryConfigurationActionType.DownloadApiRequestTemplateFailure,
);
