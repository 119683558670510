import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  AddApplicationIntermediaryAction,
  addApplicationIntermediaryFailed,
  addApplicationIntermediarySuccess,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';

const AddApplicationIntermediary = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, AddApplicationIntermediaryAction, ReduxState>(
    ApplicationPageActionType.AddApplicationIntermediary,
    addApplicationIntermediarySuccess,
    addApplicationIntermediaryFailed,
    (action) => {
      return api.addIntermediary(
        action.payload.applicationId,
        action.payload.intermediaryId,
        action.payload.intermediary,
      );
    },
  );

export default AddApplicationIntermediary;
