import { Middleware } from 'redux';

import { ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST } from './ActionTypes';
import {
  AddNewCompanyUserError,
  AddNewCompanyUserSuccess,
  AddNewCompanyUserRequestAction,
} from './AddNewCompanyUserActionCreator';

import OrganizationAPIRest from 'api/Organization';
import Fetcher from 'middlewares/Fetcher';
import { NewUserType } from './AddNewCompanyUserTypes';

const AddNewCompanyUserMiddleware: (api: OrganizationAPIRest) => Middleware = (api) =>
  Fetcher<NewUserType, AddNewCompanyUserRequestAction>(
    ACTION_TYPE_ADD_NEW_COMPANY_USER_REQUEST,
    AddNewCompanyUserSuccess,
    AddNewCompanyUserError,
    ({ payload }) => api.addNewUser(payload),
  );

export default AddNewCompanyUserMiddleware;
