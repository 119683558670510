import AutoCompletion from 'components/AutoCompletion';
import { SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import Button from 'components/Button/Button';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import { formattedCurrencies } from 'components/CurrencySelect/currencies';
import { DATE_FORMATS, IDENTIFICATION_NUMBER_TYPES } from 'components/VariableForm/DataTypes';
import React, { FC } from 'react';
import styles from './RegionSettings.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import PageName from 'constants/PageName';

interface RegionSettingsProps {
  currencyFormat: string | null;
  phoneNumberFormat: string | null;
  dateFormat: string | null;
  idNumberType: string | null;
  saveButtonDisabled: boolean;
  onCurrencyFormatChange: (option: Option) => void;
  onPhoneNumberFormatChange: (option: Option) => void;
  onDateFormatChange: (option: Option) => void;
  onIdNumberTypeChange: (option: Option) => void;
  onOpenConfirmDialog: () => void;
}

const CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description', 'value'];

const RegionSettings: FC<RegionSettingsProps> = ({
  currencyFormat,
  phoneNumberFormat,
  dateFormat,
  idNumberType,
  saveButtonDisabled,
  onCurrencyFormatChange,
  onPhoneNumberFormatChange,
  onDateFormatChange,
  onIdNumberTypeChange,
  onOpenConfirmDialog,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{PageName.RegionSettings}</h2>
      </div>
      <div className={styles.body}>
        <div className={styles.itemContainer}>
          <CountryCodeSelect
            labelTitle="Phone Format"
            onChange={onPhoneNumberFormatChange}
            value={phoneNumberFormat || undefined}
          />
        </div>
        <div className={styles.itemContainer}>
          <AutoCompletion
            labelTitle="Currency Format"
            onChange={onCurrencyFormatChange}
            value={currencyFormat || undefined}
            options={formattedCurrencies}
            searchedOptionAttrs={CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
          />
        </div>
        <div className={styles.itemContainer}>
          <AutoCompletion
            labelTitle="Date Format"
            onChange={onDateFormatChange}
            value={dateFormat || undefined}
            options={DATE_FORMATS}
          />
        </div>
        <div className={styles.itemContainer}>
          <AutoCompletion
            labelTitle="Identification Number Type"
            onChange={onIdNumberTypeChange}
            value={idNumberType || undefined}
            options={IDENTIFICATION_NUMBER_TYPES}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button kind="primary" size="form" onClick={onOpenConfirmDialog} disabled={saveButtonDisabled}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default RegionSettings;
