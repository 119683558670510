import React, { ChangeEvent, useState, useEffect } from 'react';
import styles from './CreateLabelPopUp.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import BasicColors from 'components/LoanOriginationSystem/LabelsDetails/BasicColors';
import ColorPickerInput from 'components/ColorPickerInput';
import { generateRandomColor } from './utils/generateRandomColor';
import { validateLabelColor, validateLabelName } from './utils/validation/validation';

interface EditLabelPopUpProps {
  onPopupClose: () => void;
  onCreateLabel: (name: string, color: string) => void;
  blockingActionInProgress: boolean;
}

const EditLabelPopUp = ({ onPopupClose, onCreateLabel, blockingActionInProgress }: EditLabelPopUpProps) => {
  const [labelName, setLabelName] = useState('');
  const [labelColor, setLabelColor] = useState(generateRandomColor());
  const [lastCorrectHEXValue, setLastCorrectHEXValue] = useState('');

  useEffect(() => {
    if (labelColor.length === 6 || labelColor.length === 3) {
      setLastCorrectHEXValue(labelColor);
    }
  }, [labelColor]);

  const setCorrectHEXValue = () => {
    if (labelColor.length === 6 || labelColor.length === 3) {
      return;
    }
    setLabelColor(lastCorrectHEXValue);
  };

  const onLabelNameChange = (value: string) => {
    if (validateLabelName(value)) {
      setLabelName(value);
    }
  };

  const onLabelColorChange = (value: string) => {
    if (validateLabelColor(value)) {
      setLabelColor(value);
    }
    if (value[0] === '#') {
      setLabelColor(value.substring(1));
    }
  };

  return (
    <PopUp title="Add Label" closePopUp={onPopupClose} closable={!blockingActionInProgress}>
      <PopUpContent>
        <TextInput
          labelTitle="Label Name"
          value={labelName}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => onLabelNameChange(target.value)}
          maxLength={50}
        />
        <BasicColors checkedColor={labelColor} onBasicColorChange={onLabelColorChange} />
        <ColorPickerInput
          value={labelColor}
          color={lastCorrectHEXValue}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => onLabelColorChange(target.value)}
          onBlur={setCorrectHEXValue}
          setColor={onLabelColorChange}
        />
        <Button
          kind="primary"
          size="form"
          className={styles.saveButton}
          onClick={() => onCreateLabel(labelName.trim(), labelColor)}
          isLoading={blockingActionInProgress}
          disabled={!labelName.trim()}
        >
          Save Changes
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditLabelPopUp;
