import React, { ReactNode } from 'react';
import PopUpHeader from './PopUpHeader';
import { CloseImage } from 'static/images';

import styles from './PopUpHeaderWithClose.module.scss';
import clsx from 'clsx';

interface PopUpHeaderWithCloseProps {
  title: ReactNode;
  titleTooltip?: string;
  onClose?: () => void;
  closable?: boolean;
}

const PopUpHeaderWithClose = ({ title, onClose, closable = true, titleTooltip }: PopUpHeaderWithCloseProps) => {
  return (
    <PopUpHeader title={title} titleTooltip={titleTooltip}>
      <button
        type="button"
        className={clsx(styles.closeBtn, !closable && styles.closeBtn__disabled)}
        onClick={onClose}
        disabled={!closable}
      >
        <CloseImage />
      </button>
    </PopUpHeader>
  );
};

export default PopUpHeaderWithClose;
