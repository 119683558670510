import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { ApplicationDocument, ApplicationDocumentsSortingType } from 'api/LoanOriginationSystem/DocumentsApi';

export const APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT = 20;

export interface ApplicationDocumentsPaginationParams {
  applicationId: string;
  search: string;
  sortingType: ApplicationDocumentsSortingType;
}

const applicationDocumentsPagination = Pagination<
  ReduxState,
  ApplicationDocument,
  ApplicationDocumentsPaginationParams
>('loanOriginationSystemDocuments', APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT, ({ search }) =>
  search ? ['searched', search] : [],
);

export default applicationDocumentsPagination;
