import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { VariableValue } from 'api/Types';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';

const DEFAULT_SYSTEM_NAMES = Object.values(BorrowerDefaultVariable);
const DEFAULT_BORROWER_PREFIX = 'Borrower ';
const DEFAULT_BORROWER_COMPANY_ID_PREFIX = `Borrower Company `;
const DEFAULT_BORROWER_PERSON_ID_PREFIX = `Borrower Personal `;

export const getBorrowerFullName = (
  borrowerType: BorrowerType,
  variables: Record<string, VariableValue>,
  borrowerDefaultVariables = BorrowerDefaultVariable,
) => {
  return borrowerType === BorrowerType.Person
    ? `${variables[borrowerDefaultVariables.FirstName]} ${variables[borrowerDefaultVariables.LastName]}`
    : (variables[borrowerDefaultVariables.CompanyName] as string);
};

export const getBorrowerIdNumber = (
  borrowerType: BorrowerType,
  variables: Record<string, VariableValue>,
  borrowerDefaultVariables = BorrowerDefaultVariable,
) => {
  return borrowerType === BorrowerType.Person
    ? (variables[borrowerDefaultVariables.PersonalIdNumber] as string)
    : (variables[borrowerDefaultVariables.CompanyIdNumber] as string);
};

export const formatDefaultFieldsDisplayTitle = (systemName: string, title: string) => {
  if (!DEFAULT_SYSTEM_NAMES.includes(systemName)) {
    return title;
  }

  if (systemName === BorrowerDefaultVariable.CompanyIdNumber) {
    return title.replace(DEFAULT_BORROWER_COMPANY_ID_PREFIX, '');
  }

  if (systemName === BorrowerDefaultVariable.PersonalIdNumber) {
    return title.replace(DEFAULT_BORROWER_PERSON_ID_PREFIX, '');
  }

  return title.replace(DEFAULT_BORROWER_PREFIX, '');
};
