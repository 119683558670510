import Fetcher from 'middlewares/Fetcher';
import { ApplicationVariableConfigurationsApi } from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import {
  DeleteApplicationVariableConfigurationAction,
  deleteApplicationVariableConfigurationFailure,
  deleteApplicationVariableConfigurationSuccess,
} from './ApplicationVariableConfigurationsActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const DeleteApplicationVariableConfigurationMiddleware = (api: ApplicationVariableConfigurationsApi) =>
  Fetcher<void, DeleteApplicationVariableConfigurationAction>(
    VariablesConfigurationActionType.DeleteApplicationVariableConfiguration,
    deleteApplicationVariableConfigurationSuccess,
    deleteApplicationVariableConfigurationFailure,
    (action) => {
      return api.delete(action.payload.id);
    },
  );

export default DeleteApplicationVariableConfigurationMiddleware;
