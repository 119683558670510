import Debouncer from 'middlewares/Debouncer';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getVariablesRequest } from 'Variables/VariablesActionCreator';
import { VariablesFiltersActionTypes } from './ActionTypes';

export default Debouncer<{ members: UserInfo[] }>(
  (payload, getState) => {
    const { variables } = getState();
    const { sortingType, filters, showArchived } = variables;

    return getVariablesRequest({ ...filters, selectedMembers: payload.members }, sortingType, showArchived);
  },
  [VariablesFiltersActionTypes.FilterVariablesByMembers],
);
