import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import { GetUserInfoRequest } from 'AccountDetails/AccountDetailsActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';

const onChangeUserSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === AccountDetailsActionType.UpdateAccountDetailsSuccess) {
    notification.createNotification(getMessage(MessageType.AccountInfoUpdated), 'success', dispatch);
    dispatch(GetUserInfoRequest());
  }
  return result;
};

export default onChangeUserSuccessMiddleware;
