import React, { createContext, ReactNode, useCallback, useContext, useLayoutEffect, useRef } from 'react';
import { once } from 'lodash';
import { EventEmitter } from 'events';

const DynamicSizeContext = createContext<EventEmitter | null>(null);

const EVENT_RESIZE = 'resize';

interface ContextProviderProps {
  children: ReactNode;
}

export const useDynamicSize = () => {
  const eventEmitterRef = useRef(once(() => new EventEmitter()));
  const eventEmitter = useContext(DynamicSizeContext) || eventEmitterRef.current();

  const contextProviderRef = useRef(
    once(() => ({ children }: ContextProviderProps) => {
      return <DynamicSizeContext.Provider value={eventEmitter}>{children}</DynamicSizeContext.Provider>;
    }),
  );

  const trigger = () => {
    eventEmitter.emit(EVENT_RESIZE);
  };

  return {
    Provider: contextProviderRef.current(),
    trigger,
  };
};

export const useDynamicResize = (onResize: () => void) => {
  const eventEmitter = useContext(DynamicSizeContext);

  const handleResize = useCallback(() => {
    onResize();
  }, []);

  useLayoutEffect(() => {
    eventEmitter?.addListener(EVENT_RESIZE, handleResize);

    return () => {
      eventEmitter?.removeListener(EVENT_RESIZE, onResize);
    };
  }, [eventEmitter]);
};
