import React, { useEffect } from 'react';
import ProcessingIndividualCaseComponent from 'components/ProcessingIndividualCase';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualCase } from 'StrategyProcessing/ActionCreator';
import { useHistory, useParams } from 'react-router';
import { ReduxState } from 'types/redux';
import MainLayout, { PageContent, PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ModuleStatus, VariablesType } from 'components/ProcessingIndividualCase/ProcessingIndividualCase';
import Footer from 'components/Footer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import NavigationLinkId from 'enums/NavigationLinkId';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import formatValueByDataType from 'utils/formatValueByDataType';
import { IndividualCaseState } from 'StrategyProcessing/Types';

const leftNav = makeLeftNavigation(
  NavigationLinkId.IndividualStrategyProcessing,
  ApplicationSectionName.DecisionEngine,
);
const SYSTEM_VARIABLE_NAMES = ['selected_strategy', 'case_name', 'datasources', 'strategy_status', 'requirements'];

export interface ModuleNameStatusMap {
  name: string;
  status: ModuleStatus;
}

const ProcessingIndividualCase = () => {
  const { case: caseId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const downloadApi = useDownloadApi();

  const individualCase = useSelector(
    (state: ReduxState) => state.individualProcessing.individualCaseData,
  ) as IndividualCaseState;

  useEffect(() => {
    dispatch(getIndividualCase(caseId));
  }, []);

  const overallResultType = (): ModuleStatus => {
    let resultType = ModuleStatus.Passed;
    if (!individualCase.passed) {
      resultType = individualCase.error.length ? ModuleStatus.Error : ModuleStatus.Failed;
    }
    return resultType;
  };

  const getModuleStatus = (moduleName: string) => {
    const individualModule = individualCase.compiledOrder.find((module) => module.display_name === moduleName);
    const individualModuleIndex = individualCase.compiledOrder
      .map((module) => {
        return module.display_name;
      })
      .indexOf(moduleName);
    if (individualCase.passed) {
      return individualModule!.type === ModuleType.RequirementsRules ? ModuleStatus.Passed : ModuleStatus.Completed;
    }
    if (individualModuleIndex === individualCase.moduleOrder.length - 1) {
      return individualCase.error.length ? ModuleStatus.Error : ModuleStatus.Failed;
    }
    if (individualModuleIndex < individualCase.moduleOrder.length - 1) {
      return individualModule!.type === ModuleType.RequirementsRules ? ModuleStatus.Passed : ModuleStatus.Completed;
    }
    return ModuleStatus.NotRun;
  };

  const getModuleListWithStatus = () => {
    const moduleNames = individualCase.compiledOrder.map((module) => module.display_name);
    return moduleNames.reduce((acc, moduleName) => {
      acc.push({
        name: moduleName,
        status: getModuleStatus(moduleName),
      });
      return acc;
    }, [] as ModuleNameStatusMap[]);
  };

  const getVariableDataBySystemName = (systemName: string) => {
    return individualCase.variablesMap.find((variable) => {
      return variable.systemName === systemName;
    });
  };

  const getVariables = (variablesType: VariablesType) => {
    const variablesArr = variablesType === 'inputs' ? individualCase.inputs : individualCase.outputs;
    if (!variablesArr) return {};
    const variableNames = Object.keys(variablesArr).filter((name) => !SYSTEM_VARIABLE_NAMES.includes(name));
    return Object.assign(
      {},
      ...variableNames.map((key) => {
        const variableConfig = getVariableDataBySystemName(key);

        const value = variablesArr[key];

        return {
          [variableConfig?.displayName || key]: variableConfig ? formatValueByDataType(value, variableConfig) : value,
        };
      }),
    );
  };

  const reprocessDecision = () => {
    const { strategyId, inputs } = individualCase;
    history.push(`/decision/processing/individual/run/${strategyId}`, inputs);
  };

  return (
    <div>
      <MainLayout leftNav={leftNav}>
        <PageWrapper>
          <PageContent>
            <ProcessingIndividualCaseComponent
              individualCase={individualCase}
              handleDownloadResults={() => downloadApi.downloadSimulation(caseId)}
              resultType={overallResultType()}
              moduleNameStatusMap={getModuleListWithStatus()}
              inputs={getVariables('inputs')}
              outputs={getVariables('outputs')}
              reprocessDecision={reprocessDecision}
            />
          </PageContent>
          <PageFooter>
            <Footer separator />
          </PageFooter>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default ProcessingIndividualCase;
