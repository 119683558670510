import React, { FC, useMemo } from 'react';
import SIZES from 'components/Permissions/TableColumnSizes';
import styles from './Permissions.module.scss';
import Button from 'components/Button';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import SkeletonProductRow from './SkeletonProductRow';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import Table from 'components/Table';
import ProductRow, { ProductRowProps } from './ProductRow';
import {
  PermissionsUpdatingState,
  Product,
  ProductPermissions,
  ProductSectionAccessPermissions,
  SectionPermissions,
  ProductSection,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import QuestionIcon from 'components/QuestionIcon';
import { ReloadImage } from 'static/images';
import PageName from 'constants/PageName';

export interface PermissionsProps {
  permissions: ProductSectionAccessPermissions[] | null;
  permissionsUpdatingState: PermissionsUpdatingState;
  updatePermissions: ProductRowProps['updatePermissions'];
  resetPermissionsUpdatingState: ProductRowProps['resetPermissionsUpdatingState'];
  openPopUp: () => void;
}

const ProductSectionAccessPermissionsStructure: Record<Product, ProductSection[]> = {
  [Product.LoanOriginationSystem]: [
    ProductSection.LoanOriginationSystemApplications,
    ProductSection.LoanOriginationSystemBorrowers,
    ProductSection.LoanOriginationSystemIntermediaries,
    ProductSection.LoanOriginationSystemTasks,
    ProductSection.LoanOriginationSystemEmails,
    ProductSection.LoanOriginationSystemReporting,
    ProductSection.LoanOriginationSystemConfiguration,
  ],
  [Product.DecisionEngine]: [
    ProductSection.DecisionEngineStrategies,
    ProductSection.DecisionEngineProcessing,
    ProductSection.DecisionEngineApiActivation,
    ProductSection.DecisionEngineReporting,
  ],
  [Product.CompanySettings]: [
    ProductSection.CompanySettingsGeneral,
    ProductSection.CompanySettingsPlanAndBilling,
    ProductSection.CompanySettingsTeam,
    ProductSection.CompanySettingsVariables,
    ProductSection.CompanySettingsIntegrations,
  ],
};

const DEFAULT_ROWS_AMOUNT = Object.values(ProductSection).length;

const Permissions: FC<PermissionsProps> = (props) => {
  const { permissions, permissionsUpdatingState, openPopUp, ...rowProps } = props;

  const renderRow = (productPermissions: ProductPermissions) => (
    <ProductRow
      key={productPermissions.product}
      productPermissions={productPermissions}
      permissionsUpdatingState={permissionsUpdatingState}
      {...rowProps}
    />
  );

  const tableRows = useMemo(() => {
    if (!permissions) {
      return new Array(DEFAULT_ROWS_AMOUNT).fill(null);
    }
    const sortedPermissions: ProductPermissions[] = [];
    for (const product of [Product.LoanOriginationSystem, Product.DecisionEngine, Product.CompanySettings]) {
      const sections = ProductSectionAccessPermissionsStructure[product].map((productSection) => {
        const section = { productSection };
        for (const userRole of Object.values(UserRoleName)) {
          // We can safely assume that permissions for each role exist cause they are pre-seeded and never deleted.
          const userRolePermissions = permissions.find((permission) => permission.userRole === userRole)!;
          section[userRole] = userRolePermissions.permissions[productSection];
        }
        return section as SectionPermissions;
      });
      sortedPermissions.push({
        product,
        sections,
      });
    }
    return sortedPermissions;
  }, [permissions]);

  return (
    <div className={styles.permissionsPage}>
      <div className={styles.permissionsHeader}>
        <div className={styles.permissionsHeader__title}>
          <h2>{PageName.AccessPermissions}</h2>
          <QuestionIcon
            className={styles.tooltip}
            size={24}
            tooltip="Sets which user roles can access each section of the platform"
            tooltipPosition="top"
          />
        </div>
        <div className={styles.permissionsHeader__button}>
          <Button kind="secondary" size="default" onClick={openPopUp} className={styles.resetToDefaultsButton}>
            <ReloadImage />
            Reset To Defaults
          </Button>
        </div>
      </div>
      <div className={styles.permissionsContent}>
        <Table>
          <TableHead useActions sticky>
            <TableHeadCell width={SIZES.PRODUCT}>Product</TableHeadCell>
            <TableHeadCell width={SIZES.SECTION}>Section</TableHeadCell>
            <TableHeadCell width={3 * SIZES.PERMISSION}>Permissions</TableHeadCell>
          </TableHead>
          <TableBody>
            <TableBodyContent
              rows={tableRows}
              renderSkeletonTableRow={(index) => <SkeletonProductRow key={index} />}
              renderTableRow={renderRow}
            />
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Permissions;
