import React, { ReactElement, useState } from 'react';
import styles from './AutomatedProcessCard.module.scss';
import linkButtonStyles from 'components/LinkButton/LinkButton.module.scss';
import {
  AutomatedProcessCategory,
  AutomatedProcess,
  TaskType,
  AutomatedProcessGroup,
} from 'api/LoanOriginationSystem/ProcessAutomationApi';
import clsx from 'clsx';
import NumberOfDays from './NumberOfDays';
import StyledSwitch from 'components/StyledSwitch';
import {
  ApproveEnvelopeIcon,
  ColorListIcon,
  CustomAutomatedProcessIcon,
  FolderIcon,
  ListIcon,
  NotificationEnvelope,
  ReceiveEnvelopeIcon,
  RejectEnvelope,
  RejectFolderIcon,
  ShareImage,
} from 'static/images';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import WrapperWithTooltip from 'components/Tooltip';
import LabelsDropdown from './LabelsDropdown';
import EmailsDropdown from './EmailsDropdown';
import { SendGridTemplate } from 'SendGrid/Types';
import DeclineReason from './DeclineReason';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';

const automatedProcessImages = new Map<TaskType, ReactElement>([
  [TaskType.RejectOldApplicationTask, <RejectFolderIcon />],
  [TaskType.EmailOnApplicationReceivedTask, <ReceiveEnvelopeIcon />],
  [TaskType.EmailOnApplicationApprovedTask, <ApproveEnvelopeIcon />],
  [TaskType.AssignLabelToNotUpdatingApplicationTask, <ListIcon />],
  [TaskType.AssignLabelToOldApplicationTask, <ColorListIcon />],
  [TaskType.ReopenBorrowerToNewApplicationsTask, <FolderIcon />],
  [TaskType.EmailCustomerWithActiveApplicationDailyTask, <NotificationEnvelope />],
  [TaskType.EmailOnApplicationRejectedTask, <RejectEnvelope />],
]);

interface AutomatedProcessCardProps {
  automatedProcess: AutomatedProcess;
  onActivationStatusChange: (id: string, status: boolean) => void;
  onRuntimeOptionsChange: (id: string, runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>) => void;
  labels?: Label[];
  emailTemplates?: SendGridTemplate[];
}

const AutomatedProcessCard = ({
  automatedProcess,
  onActivationStatusChange,
  onRuntimeOptionsChange,
  labels,
  emailTemplates,
}: AutomatedProcessCardProps) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, LoaderState | null>>({});

  const disableSwitcher =
    (automatedProcess.categories.includes(AutomatedProcessCategory.Emails) &&
      !automatedProcess.runtimeOptions?.template) ||
    (automatedProcess.categories.includes(AutomatedProcessCategory.Labels) && !automatedProcess.runtimeOptions?.label);

  const renderAutomatedProcessIcon = () => {
    if (automatedProcess.group === AutomatedProcessGroup.Standard) {
      return automatedProcessImages.get(automatedProcess.type);
    }

    return <CustomAutomatedProcessIcon />;
  };

  const onLabelSelect = async (labelId: string) => {
    try {
      setLoadingStates({ label: LoaderState.Updating });
      await onRuntimeOptionsChange(automatedProcess.id, { label: labelId });
      setLoadingStates({ label: LoaderState.Success });
    } catch {
      setLoadingStates({ label: LoaderState.Failure });
    }
  };

  const onNumberOfDaysUpdate = async (updatedNumberOfDays: number) => {
    try {
      setLoadingStates({ numberOfDays: LoaderState.Updating });
      await onRuntimeOptionsChange(automatedProcess.id, { numberOfDays: updatedNumberOfDays });
      setLoadingStates({ numberOfDays: LoaderState.Success });
    } catch {
      setLoadingStates({ numberOfDays: LoaderState.Failure });
    }
  };

  const onDeclineReasonUpdate = async (declineReasonToSetName?: string) => {
    await onRuntimeOptionsChange(automatedProcess.id, { declineReasonToSetName });
  };

  const onEmailTemplateSelect = async (templateId: string) => {
    try {
      setLoadingStates({ email: LoaderState.Updating });
      await onRuntimeOptionsChange(automatedProcess.id, { template: templateId });
      setLoadingStates({ email: LoaderState.Success });
    } catch {
      setLoadingStates({ email: LoaderState.Failure });
    }
  };

  const showAddSendGridCredentialsNotification =
    automatedProcess.categories.includes(AutomatedProcessCategory.Emails) && !emailTemplates!.length;

  const handleActivationStatusChange = () => {
    if (!disableSwitcher) {
      onActivationStatusChange(automatedProcess.id, !automatedProcess.active);
    }
  };

  return (
    <div
      className={clsx(styles.automatedProcessCardInner, automatedProcess.active && styles.automatedProcessActivated)}
    >
      <div className={styles.automatedProcessCardContent}>
        <div className={styles.automatedProcessIcon}>{renderAutomatedProcessIcon()}</div>
        <p
          className={clsx(
            styles.automatedProcessCardTitle,
            showAddSendGridCredentialsNotification && styles.cardTitleWithNotification,
          )}
        >
          {automatedProcess.description}
        </p>
        {automatedProcess.categories.includes(AutomatedProcessCategory.Labels) && labels && (
          <LabelsDropdown
            labels={labels}
            isAutomatedProcessActive={automatedProcess.active}
            selectedLabelId={automatedProcess.runtimeOptions?.label}
            onLabelSelect={onLabelSelect}
            disabled={automatedProcess.active}
            loaderState={loadingStates.label}
            onLoaderStateReset={() => {
              setLoadingStates({ label: null });
            }}
          />
        )}
        {automatedProcess.categories.includes(AutomatedProcessCategory.Activities) && (
          <div className={styles.numberOfDays}>
            <NumberOfDays
              automatedProcess={automatedProcess}
              onNumberOfDaysUpdate={onNumberOfDaysUpdate}
              disabled={automatedProcess.active}
              loaderState={loadingStates.numberOfDays}
              onLoaderStateReset={() => {
                setLoadingStates({ numberOfDays: null });
              }}
            />
          </div>
        )}
        {automatedProcess.type === TaskType.RejectOldApplicationTask && (
          <div className={styles.declineReason}>
            <DeclineReason
              automatedProcess={automatedProcess}
              onDeclineReasonUpdate={onDeclineReasonUpdate}
              disabled={automatedProcess.active}
            />
          </div>
        )}
        {automatedProcess.categories.includes(AutomatedProcessCategory.Emails) && emailTemplates && (
          <EmailsDropdown
            selectedEmailTemplateId={automatedProcess.runtimeOptions?.template}
            onEmailTemplateSelect={onEmailTemplateSelect}
            isAutomatedProcessActive={automatedProcess.active}
            emailTemplates={emailTemplates}
            disabled={!emailTemplates.length || automatedProcess.active}
            loaderState={loadingStates.email}
            onLoaderStateReset={() => {
              setLoadingStates({ email: null });
            }}
          />
        )}
        {showAddSendGridCredentialsNotification && (
          <div className={styles.sendGridNotification}>
            <p className={styles.sendGridNotificationText}>Please add your SendGrid credentials to activate.</p>
            <a
              href="/company-settings/send-grid/credentials"
              className={clsx(linkButtonStyles.linkButton, styles.companySettingsLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className={styles.companySettingsLinkTitle}>Company Settings</p>
              <ShareImage />
            </a>
          </div>
        )}
      </div>
      <div
        className={clsx(
          styles.activationInner,
          automatedProcess.active && styles.activationInnerActive,
          !disableSwitcher && styles.activationInnerClickable,
        )}
        onClick={handleActivationStatusChange}
      >
        <span
          className={clsx(
            styles.activationTitle,
            automatedProcess.active && styles.activationTitleActive,
            disableSwitcher && styles.disableSwitcher,
          )}
        >
          {automatedProcess.active ? 'Activated' : 'Not Activated'}
        </span>
        <WrapperWithTooltip tooltip={disableSwitcher ? 'Complete the required fields before activating' : ''}>
          <div>
            <StyledSwitch checked={automatedProcess.active} disabled={disableSwitcher} />
          </div>
        </WrapperWithTooltip>
      </div>
    </div>
  );
};

export default AutomatedProcessCard;
