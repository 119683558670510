import { IntermediaryVariableConfigurationsApi } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import Fetcher from 'middlewares/Fetcher';
import {
  GetIntermediaryVariableConfigurationsAction,
  getIntermediaryVariableConfigurationsSuccess,
  getIntermediaryVariableConfigurationsFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';
import { VariableConfiguration } from 'api/LoanOriginationSystem/Types';

const GetIntermediaryVariableConfigurationsMiddleware = (api: IntermediaryVariableConfigurationsApi) =>
  Fetcher<VariableConfiguration[], GetIntermediaryVariableConfigurationsAction>(
    VariablesConfigurationActionType.GetIntermediaryVariableConfigurations,
    getIntermediaryVariableConfigurationsSuccess,
    getIntermediaryVariableConfigurationsFailure,
    async (action) => {
      return api.getAll(action.payload.productId);
    },
  );

export default GetIntermediaryVariableConfigurationsMiddleware;
