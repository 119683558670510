import { AnyAction } from 'redux';
import replaceAt from 'utils/replaceAt';
import removeAt from 'utils/removeAt';
import withStateReset from 'utils/reducers/withStateReset';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import {
  ChangeProductStatusAction,
  ChangeProductStatusSuccessAction,
  CreateProductAction,
  CreateProductSuccessAction,
  DownloadApiRequestTemplateAction,
  DownloadApiRequestTemplateSuccessAction,
  DuplicateProductAction,
  DuplicateProductSuccessAction,
  GetProductsAction,
  GetProductsSuccessAction,
  SetProductToDuplicateAction,
  SetStatusChangeDataAction,
  UpdateProductSuccessAction,
} from './ActionCreator';
import loanOriginationSystemProductsFiltersReducer, {
  initialState as productsFiltersInitialState,
} from './Filters/Reducer';
import loanOriginationSystemProductConfigurationReducer, {
  initialState as productConfigurationInitialState,
} from './ProductConfiguration/Reducer';
import { LoanOriginationSystemProductsState } from './Types';
import { SetSearchInputValueAction } from './Filters/ActionCreator';
import { LoanOriginationSystemProductsFiltersActionTypes } from './Filters/ActionTypes';

const initialState: LoanOriginationSystemProductsState = {
  items: null,
  filters: productsFiltersInitialState,
  productConfiguration: productConfigurationInitialState,
  statusChangeData: null,
  productToDuplicate: null,
  isStatusUpdatingInProgress: false,
  isDuplicatingInProgress: false,
  isProductCreatingInProgress: false,
  isDownloadApiRequestTemplateInProgress: false,
};

type LoanOriginationSystemProductsReducer = (
  state: LoanOriginationSystemProductsState,
  action: AnyAction,
) => LoanOriginationSystemProductsState;

type LoanOriginationSystemProductsActions =
  | GetProductsAction
  | GetProductsSuccessAction
  | SetStatusChangeDataAction
  | SetProductToDuplicateAction
  | ChangeProductStatusAction
  | ChangeProductStatusSuccessAction
  | DuplicateProductAction
  | DuplicateProductSuccessAction
  | SetSearchInputValueAction
  | UpdateProductSuccessAction
  | CreateProductAction
  | CreateProductSuccessAction
  | DownloadApiRequestTemplateAction
  | DownloadApiRequestTemplateSuccessAction
  | FailedAction<LoanOriginationSystemProductsActionType.DuplicateProductFailure>
  | FailedAction<LoanOriginationSystemProductsActionType.ChangeProductStatusFailure>
  | FailedAction<LoanOriginationSystemProductsActionType.CreateProductFailure>
  | FailedAction<LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateFailure>;

const loanOriginationSystemProductsReducer = (
  state = initialState,
  action: LoanOriginationSystemProductsActions,
): LoanOriginationSystemProductsState => {
  switch (action.type) {
    case LoanOriginationSystemProductsActionType.GetProducts: {
      return {
        ...state,
        items: null,
      };
    }
    case LoanOriginationSystemProductsActionType.GetProductsSuccess: {
      return {
        ...state,
        items: action.payload,
      };
    }
    case LoanOriginationSystemProductsActionType.SetProductToDuplicate: {
      return {
        ...state,
        productToDuplicate: action.payload,
      };
    }
    case LoanOriginationSystemProductsActionType.SetStatusChangeData: {
      return {
        ...state,
        statusChangeData: action.payload,
      };
    }
    case LoanOriginationSystemProductsActionType.ChangeProductStatus: {
      return {
        ...state,
        isStatusUpdatingInProgress: true,
      };
    }
    case LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess: {
      const productConfiguration = loanOriginationSystemProductConfigurationReducer(state.productConfiguration, action);
      const index = state.items ? state.items.findIndex((product) => product.id === action.payload.id) : -1;

      if (
        action.payload.status === ProductStatus.Archived &&
        !state.filters.statuses.includes(ProductStatus.Archived)
      ) {
        return {
          ...state,
          productConfiguration,
          items: index !== -1 ? removeAt(state.items!, index) : state.items,
          statusChangeData: null,
          isStatusUpdatingInProgress: false,
        };
      }

      return {
        ...state,
        productConfiguration,
        items: index !== -1 ? replaceAt(state.items!, index, action.payload) : state.items,
        statusChangeData: null,
        isStatusUpdatingInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.ChangeProductStatusFailure: {
      return {
        ...state,
        isStatusUpdatingInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.DuplicateProduct: {
      return {
        ...state,
        isDuplicatingInProgress: true,
      };
    }
    case LoanOriginationSystemProductsActionType.DuplicateProductSuccess: {
      return {
        ...state,
        productToDuplicate: null,
        isDuplicatingInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.DuplicateProductFailure: {
      return {
        ...state,
        isDuplicatingInProgress: false,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        items: null,
        filters: loanOriginationSystemProductsFiltersReducer(state.filters, action),
      };
    }
    case LoanOriginationSystemProductsActionType.UpdateProductSuccess: {
      if (!state.items) {
        return {
          ...state,
          productConfiguration: loanOriginationSystemProductConfigurationReducer(state.productConfiguration, action),
        };
      }

      const productIndex = state.items.findIndex((product) => product.id === action.payload.product.id);

      if (productIndex === -1) {
        return {
          ...state,
          productConfiguration: loanOriginationSystemProductConfigurationReducer(state.productConfiguration, action),
        };
      }

      return {
        ...state,
        items: replaceAt(state.items, productIndex, action.payload.product),
        productConfiguration: loanOriginationSystemProductConfigurationReducer(state.productConfiguration, action),
      };
    }
    case LoanOriginationSystemProductsActionType.CreateProduct: {
      return {
        ...state,
        isProductCreatingInProgress: true,
      };
    }
    case LoanOriginationSystemProductsActionType.CreateProductSuccess:
    case LoanOriginationSystemProductsActionType.CreateProductFailure: {
      return {
        ...state,
        isProductCreatingInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.DownloadApiRequestTemplate: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: true,
      };
    }
    case LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateSuccess:
    case LoanOriginationSystemProductsActionType.DownloadApiRequestTemplateFailure: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: false,
      };
    }
    default: {
      return {
        ...state,
        productConfiguration: loanOriginationSystemProductConfigurationReducer(state.productConfiguration, action),
        filters: loanOriginationSystemProductsFiltersReducer(state.filters, action),
      };
    }
  }
};

export default withStateReset(
  loanOriginationSystemProductsReducer,
  LoanOriginationSystemProductsActionType.ResetState,
  (state) => ({ ...initialState, productConfiguration: state.productConfiguration }),
) as LoanOriginationSystemProductsReducer;
