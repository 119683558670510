import CoreSystemApi from 'api/Core/CoreSystemApi';
import { OrganizationInfo } from 'api/Core/OrganizationApi';

export interface OrganizationMembershipApi {
  getUserOrganizations: () => Promise<OrganizationInfo[]>;
}

export default class OrganizationMembershipApiRest extends CoreSystemApi<OrganizationInfo>
  implements OrganizationMembershipApi {
  protected origin = process.env.REACT_APP_CORE_API_URL;

  protected resourceName = 'organization-memberships';

  public getUserOrganizations() {
    return this.getResources();
  }
}
