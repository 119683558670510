import React from 'react';
import { PercentageImage } from 'static/images';
import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import styles from './PercentageInputStub.module.scss';

const PercentageInputStub = ({ placeholder, ...restProps }: TextInputStubProps) => {
  const renderPlaceholder = () => (
    <>
      <div className={styles.percentageImageContainer}>
        <PercentageImage />
      </div>
      {placeholder}
    </>
  );

  return <TextInputStub {...restProps} placeholder={renderPlaceholder()} />;
};

export default PercentageInputStub;
