import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { ApplicationStatus } from './LoanOriginationSystemApplicationStatusesApi';
import { VariableValue } from './Types';

export interface MultipleRuleValidationResult {
  valid: boolean;
  sourceValue: VariableValue | undefined | null;
  firstOperandValue: VariableValue | undefined | null;
  secondOperandValue: VariableValue | undefined | null;
}

export interface RuleValidationResult {
  valid: boolean;
  multipleRuleValidationResults: MultipleRuleValidationResult[];
}

export interface ApplicationStatusRulesValidationResult {
  valid: boolean;
  status: ApplicationStatus;
  ruleValidationResults: RuleValidationResult[];
}

export interface ApplicationStatusesRulesValidationApi {
  getValidationResults(applicationId: string): Promise<ApplicationStatusRulesValidationResult[]>;
}

export default class ApplicationStatusesRulesValidationRestApi
  extends LoanOriginationSystemApi<ApplicationStatusRulesValidationResult>
  implements ApplicationStatusesRulesValidationApi {
  protected resourceName = 'application-statuses-rules-validation';

  public async getValidationResults(applicationId: string) {
    return this.fetch<ApplicationStatusRulesValidationResult[]>(`/${this.resourceName}/${applicationId}`);
  }
}
