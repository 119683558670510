import React, { useRef } from 'react';
import ViewSwitcher from 'components/LoanOriginationSystem/ApplicationsDashboard/ViewSwitcher';
import SearchInput from 'components/SearchInput';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import styles from 'components/ListHeader/ListHeader.module.scss';
import SliderButton from 'components/StrategyGroups/SliderButton';
import debounce from 'lodash/debounce';
import { StrategyListParams } from 'api/DecisionEngineStrategiesType';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';

interface HeaderProps {
  showArchived: boolean;
  updaters: UserInfo[];
  selectedUpdaters: UserInfo[];
  setUpdaters: (users: UserInfo[]) => void;
  viewType?: ViewType;
  onViewTypeChange?: (viewType: ViewType) => void;
  searchInputValue: string;
  setSearchInputValue: (value: string) => void;
  setShowArchived: (showArchived: boolean) => void;
  fetchListData: (params: StrategyListParams) => void;
  placeholderText: string;
}

const DEBOUNCE_TIME = 300;

const ListHeader = ({
  searchInputValue,
  setSearchInputValue,
  updaters,
  selectedUpdaters,
  setUpdaters,
  setShowArchived,
  viewType,
  onViewTypeChange,
  showArchived,
  fetchListData,
  placeholderText,
}: HeaderProps) => {
  const debouncedFetchListData = useRef(debounce(fetchListData, DEBOUNCE_TIME)).current;

  const handleSetShowArchived = (enabled: boolean) => {
    setShowArchived(enabled);
    fetchListData({ showArchived: enabled });
  };

  const handleSearchValueChange = (searchValue: string) => {
    setSearchInputValue(searchValue);
    debouncedFetchListData({ searchInputValue: searchValue });
  };

  const onSelectedUpdatersChange = (selectedUpdatersList: UserInfo[]) => {
    setUpdaters(selectedUpdatersList);
    debouncedFetchListData({ selectedUpdaters: selectedUpdatersList });
  };

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <SearchInput
          placeholder={placeholderText}
          containerClassName={styles.subTitle__search}
          value={searchInputValue}
          onChange={({ target }) => handleSearchValueChange(target.value)}
          onClear={() => handleSearchValueChange('')}
        />
        <div className={styles.memberFilterContainer}>
          <UserFilter users={updaters} selectedUsers={selectedUpdaters} onChange={onSelectedUpdatersChange} />
        </div>
        <SliderButton active={showArchived} onChange={handleSetShowArchived} labelText="Show Archived" />
        {onViewTypeChange && <ViewSwitcher viewType={viewType!} onChange={onViewTypeChange} isTileView />}
      </div>
    </div>
  );
};

export default ListHeader;
