import moment from 'moment';
import { ReportingFilterStateChangeMethods, ReportingTimeDeltas } from './Types';

export const getUpdatedValue = (prevState: string[], method: ReportingFilterStateChangeMethods, value: string) => {
  if (method === ReportingFilterStateChangeMethods.Add) {
    return [...prevState, value];
  }
  if (method === ReportingFilterStateChangeMethods.Delete) {
    return prevState.filter((entity) => entity !== value);
  }
  return prevState;
};

export const getNewSelectedStatuses = (id: string, statuses: string[]) => {
  if (statuses.includes(id)) {
    return statuses.filter((status: string) => status !== id);
  }

  return [...statuses, id];
};

export const formatDate = (date: string) => moment(date).format('MMM YYYY');

export const dateDeltaFormat = (currentTimeDelta: ReportingTimeDeltas): { from: Date | null; to: Date | null } => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  switch (currentTimeDelta) {
    case ReportingTimeDeltas.ThisYear:
      return {
        from: new Date(`01/01/${currentYear}`),
        to: currentDate,
      };
    case ReportingTimeDeltas.Last12Months: {
      const yearAgo = new Date(
        moment(currentDate)
          .subtract(11, 'month')
          .toDate(),
      );
      return {
        from: yearAgo,
        to: currentDate,
      };
    }
    case ReportingTimeDeltas.Last6Months: {
      const sixMonthAgo = new Date(
        moment(currentDate)
          .subtract(5, 'month')
          .toDate(),
      );
      return {
        from: sixMonthAgo,
        to: currentDate,
      };
    }
    case ReportingTimeDeltas.AllTime:
    default: {
      return {
        from: null,
        to: null,
      };
    }
  }
};
