import { AnyAction } from 'redux';
import mountChildReducers from 'utils/childReducers';
import { BatchProcessingState } from './Types';
import { GET_STRATEGIES_LIST_SUCCESS } from './ActionTypes';
import batchProcessingHistoryReducer, {
  initialState as batchProcessingHistoryInitialState,
} from './BatchProcessingHistoryReducer';

const initialState: BatchProcessingState = {
  strategies: [],
  history: batchProcessingHistoryInitialState,
};

const BatchProcessingReducer = (state = initialState, action: AnyAction): BatchProcessingState => {
  switch (action.type) {
    case GET_STRATEGIES_LIST_SUCCESS: {
      return { ...state, strategies: action.payload };
    }
    default: {
      return state;
    }
  }
};

const childReducers = {
  history: batchProcessingHistoryReducer,
};

export default mountChildReducers(BatchProcessingReducer, childReducers);
