import { SendGridTemplatesSortingType } from 'api/LoanOriginationSystem/SendGridTemplateApi';
import { TableViewData } from 'api/Types';
import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { SendGridActionType } from './ActionTypes';
import {
  ImportableSendGridTemplate,
  SendGridIntegration,
  SendGridTemplate,
  SendGridTemplateRequestParams,
} from './Types';
import { LoanOriginationSystemTemplatesFiltersState } from './Filters/Types';
import { Variable } from 'Variables/VariablesTypes';

export interface SaveSendGridIntegrationRequestAction extends AnyAction {
  payload: SendGridIntegration;
}

export interface SaveSendGridIntegrationSuccessAction extends AnyAction {
  payload: SendGridIntegration;
}

export interface GetSendGridIntegrationSuccessAction extends AnyAction {
  payload: SendGridIntegration;
}

export interface GetApplicationVariablesRequestSuccessAction extends AnyAction {
  payload: { applicationVariables: Variable[] };
}

export interface GetTemplatesRequestAction extends AnyAction {
  payload: {
    filters: LoanOriginationSystemTemplatesFiltersState;
    sortingType: SendGridTemplatesSortingType;
  };
}

export interface GetTemplatesRequestSuccessAction extends AnyAction {
  payload: { templatesPaginationResult: TableViewData<SendGridTemplate> };
}

export interface SortApplicationDocumentsAction extends AnyAction {
  type: SendGridActionType.SortTemplates;
  payload: {
    sortingType: SendGridTemplatesSortingType;
  };
}

export interface GetTemplatesToImportRequestSuccessAction extends AnyAction {
  payload: { importableTemplates: ImportableSendGridTemplate[] };
}

export interface GetSenderEmailsRequestSuccessAction extends AnyAction {
  payload: { senderEmails: string[] };
}

export interface DeleteTemplateRequestSuccessAction extends AnyAction {
  payload: SendGridTemplate;
}

export interface OpenCreateVariablePopupAction {
  type: SendGridActionType.OpenCreateVariablePopup;
}

export interface CloseCreateVariablePopupAction {
  type: SendGridActionType.CloseCreateVariablePopup;
}

export interface UpdateTemplateStatusSuccessAction {
  type: SendGridActionType.UpdateTemplateStatusRequestSuccess;
  payload: {
    templateId: string;
    templateName: string;
    active: boolean;
  };
}

export interface UpdateTemplateSenderEmailRequestSuccessAction {
  type: SendGridActionType.UpdateTemplateSenderEmailRequestSuccess;
  payload: {
    templateId: string;
    templateName: string;
    senderEmail?: string;
  };
}

export interface GetTemplateAction extends AnyAction {
  type: SendGridActionType.GetTemplate;
  payload: {
    id: string;
  };
}

export interface GetTemplateSuccessAction extends AnyAction {
  type: SendGridActionType.GetTemplateSuccess;
  payload: {
    template: SendGridTemplate;
  };
}

export const SaveSendGridIntegrationRequest = (
  integration: SendGridIntegration,
): SaveSendGridIntegrationRequestAction => {
  return {
    type: SendGridActionType.SaveSendGridIntegrationRequest,
    payload: integration,
  };
};

export const SaveSendGridIntegrationSuccess = (
  fetchedResponse: SendGridIntegration,
): SaveSendGridIntegrationSuccessAction => {
  return {
    type: SendGridActionType.SaveSendGridIntegrationRequestSuccess,
    payload: fetchedResponse,
  };
};

export const SaveSendGridIntegrationError = FailedActionCreator(SendGridActionType.SaveSendGridIntegrationRequestError);

export const GetSendGridIntegrationRequest = () => {
  return {
    type: SendGridActionType.GetSendGridIntegrationRequest,
  };
};

export const GetSendGridIntegrationSuccess = (
  integration: SendGridIntegration,
): GetSendGridIntegrationSuccessAction => {
  return {
    type: SendGridActionType.GetSendGridIntegrationRequestSuccess,
    payload: integration,
  };
};

export const GetSendGridIntegrationFailure = (): AnyAction => {
  return {
    type: SendGridActionType.GetSendGridIntegrationRequestError,
  };
};

export const GetApplicationVariablesRequestSuccess = (
  applicationVariables: Variable[],
): GetApplicationVariablesRequestSuccessAction => {
  return {
    type: SendGridActionType.GetApplicationVariablesRequestSuccess,
    payload: { applicationVariables },
  };
};

export const GetApplicationVariablesRequestFailure = FailedActionCreator(
  SendGridActionType.GetApplicationVariablesRequestError,
);

export const GetTemplatesRequest = ({
  filters,
  sortingType,
}: SendGridTemplateRequestParams): GetTemplatesRequestAction => {
  return {
    type: SendGridActionType.GetTemplatesRequest,
    payload: {
      filters,
      sortingType,
    },
  };
};

export const GetTemplatesRequestSuccess = (
  templatesPaginationResult: TableViewData<SendGridTemplate>,
): GetTemplatesRequestSuccessAction => {
  return {
    type: SendGridActionType.GetTemplatesRequestSuccess,
    payload: { templatesPaginationResult },
  };
};

export const GetTemplatesRequestFailure = FailedActionCreator(SendGridActionType.GetTemplatesRequestError);

export const sortTemplates = (sortingType: SendGridTemplatesSortingType): SortApplicationDocumentsAction => ({
  type: SendGridActionType.SortTemplates,
  payload: {
    sortingType,
  },
});

export const GetTemplatesToImportRequest = () => {
  return {
    type: SendGridActionType.GetTemplatesToImportRequest,
  };
};

export const GetTemplatesToImportRequestSuccess = (
  importableTemplates: ImportableSendGridTemplate[],
): GetTemplatesToImportRequestSuccessAction => {
  return {
    type: SendGridActionType.GetTemplatesToImportRequestSuccess,
    payload: { importableTemplates },
  };
};

export const GetTemplatesToImportRequestFailure = FailedActionCreator(
  SendGridActionType.GetTemplatesToImportRequestError,
);

export const GetSenderEmailsRequest = () => {
  return {
    type: SendGridActionType.GetSenderEmailsRequest,
  };
};

export const GetSenderEmailsRequestSuccess = (senderEmails: string[]): GetSenderEmailsRequestSuccessAction => {
  return {
    type: SendGridActionType.GetSenderEmailsRequestSuccess,
    payload: { senderEmails },
  };
};

export const GetSenderEmailsRequestFailure = FailedActionCreator(SendGridActionType.GetSenderEmailsRequestError);

export interface OpenDeleteTemplatePopUpAction {
  type: SendGridActionType.OpenDeleteTemplatePopUp;
  payload: { templateId: string };
}

export const openDeleteTemplatePopUp = (templateId: string): OpenDeleteTemplatePopUpAction => ({
  type: SendGridActionType.OpenDeleteTemplatePopUp,
  payload: { templateId },
});

export interface CloseDeleteTemplatePopUpAction {
  type: SendGridActionType.CloseDeleteTemplatePopUp;
}

export const closeDeleteTemplatePopUp = (): CloseDeleteTemplatePopUpAction => ({
  type: SendGridActionType.CloseDeleteTemplatePopUp,
});

export interface DeleteTemplateRequestAction {
  type: SendGridActionType.DeleteTemplateRequest;
  payload: string;
}

export const DeleteTemplateRequest = (templateId: string): DeleteTemplateRequestAction => ({
  type: SendGridActionType.DeleteTemplateRequest,
  payload: templateId,
});

export const DeleteTemplateRequestSuccess = (fetchedResponse: SendGridTemplate): DeleteTemplateRequestSuccessAction => {
  return {
    type: SendGridActionType.DeleteTemplateRequestSuccess,
    payload: fetchedResponse,
  };
};

export const DeleteTemplateRequestError = FailedActionCreator(SendGridActionType.DeleteTemplateRequestError);

export interface ImportTemplatesRequestAction {
  type: SendGridActionType.ImportTemplatesRequest;
  payload: Array<{ name: string; templateId: string }>;
}

export interface ImportTemplatesRequestActionSucess {
  type: SendGridActionType.ImportTemplatesRequestSuccess;
}

export interface ImportTemplatesRequestActionError {
  type: SendGridActionType.ImportTemplatesRequestError;
}

export const importTemplatesRequest = (
  templatesToImport: Array<{ name: string; templateId: string }>,
): ImportTemplatesRequestAction => ({
  type: SendGridActionType.ImportTemplatesRequest,
  payload: templatesToImport,
});

export const importTemplatesRequestSuccess = (): ImportTemplatesRequestActionSucess => ({
  type: SendGridActionType.ImportTemplatesRequestSuccess,
});

export const importTemplatesRequestError = (): ImportTemplatesRequestActionError => ({
  type: SendGridActionType.ImportTemplatesRequestError,
});

export interface OpenImportTemplatePopUpAction {
  type: SendGridActionType.OpenImportTemplatePopUp;
}

export const openImportTemplatePopUp = (): OpenImportTemplatePopUpAction => ({
  type: SendGridActionType.OpenImportTemplatePopUp,
});

export interface CloseImportTemplatePopUpAction {
  type: SendGridActionType.CloseImportTemplatePopUp;
}

export const closeImportTemplatePopUp = (): CloseImportTemplatePopUpAction => ({
  type: SendGridActionType.CloseImportTemplatePopUp,
});

export interface OpenVariablesMappingPopUpAction {
  type: SendGridActionType.OpenVariablesMappingPopUp;
}

export const openVariablesMappingPopUp = (): OpenVariablesMappingPopUpAction => ({
  type: SendGridActionType.OpenVariablesMappingPopUp,
});

export interface CloseVariablesMappingPopUpAction {
  type: SendGridActionType.CloseVariablesMappingPopUp;
}

export const closeVariablesMappingPopUp = (): CloseVariablesMappingPopUpAction => ({
  type: SendGridActionType.CloseVariablesMappingPopUp,
});

export interface UpdateTemplateVariablesMappingRequestSuccessAction {
  type: SendGridActionType.UpdateTemplateVariablesMappingRequestSuccess;
  payload: {
    templateId: string;
    templateName: string;
    variables: Record<string, string>;
  };
}

export const openCreateVariablePopup = (): OpenCreateVariablePopupAction => ({
  type: SendGridActionType.OpenCreateVariablePopup,
});

export const closeCreateVariablePopup = (): CloseCreateVariablePopupAction => ({
  type: SendGridActionType.CloseCreateVariablePopup,
});

export const updateTemplateStatusRequest = (templateId: string, active: boolean) => ({
  type: SendGridActionType.UpdateTemplateStatusRequest,
  payload: { templateId, active },
});

export const updateTemplateStatusRequestSuccess = (template: SendGridTemplate): UpdateTemplateStatusSuccessAction => {
  return {
    type: SendGridActionType.UpdateTemplateStatusRequestSuccess,
    payload: {
      templateId: template.id,
      templateName: template.name,
      active: template.active,
    },
  };
};

export const updateTemplateStatusRequestFailure = FailedActionCreator(
  SendGridActionType.UpdateTemplateStatusRequestError,
);

export const updateTemplateSenderEmailRequest = (templateId: string, senderEmail: string) => ({
  type: SendGridActionType.UpdateTemplateSenderEmailRequest,
  payload: { templateId, senderEmail },
});

export const updateTemplateSenderEmailRequestSuccess = (
  template: SendGridTemplate,
): UpdateTemplateSenderEmailRequestSuccessAction => {
  return {
    type: SendGridActionType.UpdateTemplateSenderEmailRequestSuccess,
    payload: {
      templateId: template.id,
      templateName: template.name,
      senderEmail: template.senderEmail,
    },
  };
};

export const updateTemplateSenderEmailRequestFailure = FailedActionCreator(
  SendGridActionType.UpdateTemplateSenderEmailRequestError,
);

export const updateTemplateVariablesMappingRequest = (templateId: string, variables: Record<string, string>) => ({
  type: SendGridActionType.UpdateTemplateVariablesMappingRequest,
  payload: { templateId, variables },
});

export const updateTemplateVariablesMappingRequestSuccess = (
  template: SendGridTemplate,
): UpdateTemplateVariablesMappingRequestSuccessAction => {
  return {
    type: SendGridActionType.UpdateTemplateVariablesMappingRequestSuccess,
    payload: {
      templateId: template.id,
      templateName: template.name,
      variables: template.variables,
    },
  };
};

export const getTemplate = (id: string): GetTemplateAction => ({
  type: SendGridActionType.GetTemplate,
  payload: {
    id,
  },
});

export const getTemplateSuccess = (template: SendGridTemplate) => ({
  type: SendGridActionType.GetTemplateSuccess,
  payload: {
    template,
  },
});

export const updateTemplateVariablesMappingRequestFailure = FailedActionCreator(
  SendGridActionType.UpdateTemplateVariablesMappingRequestError,
);

export const getTemplateFailure = FailedActionCreator(SendGridActionType.GetTemplateFailure);
