import React, { useState } from 'react';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import {
  getFormattedBorrowerIdNumber,
  getFormattedBorrowerPhoneNumber,
  getIdNumberVariableNameByType,
} from 'LoanOriginationSystemOrganization/Utils';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import { BorrowersColumnSizes } from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersTable/TableColumnSizes';
import SearchHighlight from 'components/SearchHighlight';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import styles from './BorrowerItemRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import { StandardVariables } from 'Variables/VariablesTypes';

interface BorrowerItemRowProps {
  borrower: Borrower;
  onClick: (id: string) => void;
  searchInputValue: string;
  onBorrowerDelete: (id: string) => Promise<void>;
  onBorrowerEdit: (id: string) => void;
  standardVariables: StandardVariables;
}

const BorrowerItemRow = ({
  borrower,
  searchInputValue,
  standardVariables,
  onClick,
  onBorrowerDelete,
  onBorrowerEdit,
}: BorrowerItemRowProps) => {
  const renderCellContent = (content: string) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue}>{content || '-'}</SearchHighlight>
    </OverflowedText>
  );

  const idNumberVariableName = getIdNumberVariableNameByType(borrower.type, BorrowerDefaultVariable);
  const formattedBorrowerIdNumber = getFormattedBorrowerIdNumber(
    borrower.variables[idNumberVariableName],
    standardVariables[idNumberVariableName],
  );
  const formattedBorrowerPhone = getFormattedBorrowerPhoneNumber(
    borrower.variables[BorrowerDefaultVariable.PhoneNumber],
    standardVariables,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleBorrowerDeleteClick = async (borrowerId: string) => {
    setIsLoading(true);
    await onBorrowerDelete(borrowerId);
    setIsLoading(false);
  };

  return (
    <TableRow useActions key={borrower.id} onClick={() => onClick(borrower.id)} disabled={isLoading}>
      <TableBodyCell width={BorrowersColumnSizes.Name} className={styles.nameCell} noPadding>
        {renderCellContent(getBorrowerFullName(borrower.type, borrower.variables))}
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.IdNumber}>
        {renderCellContent(formattedBorrowerIdNumber)}
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.Phone}>{renderCellContent(formattedBorrowerPhone)}</TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.Email}>
        {renderCellContent(borrower.variables[BorrowerDefaultVariable.Email] as string)}
      </TableBodyCell>
      <TableBodyCell width={BorrowersColumnSizes.UpdatedAt} noPadding>
        <WithSystemApiUserAvatar user={borrower.updatedBy} className={styles.avatar} size="small" />
        <OverflowedText>{formatDate(borrower.updatedAt, DateTimeFormat.Short)}</OverflowedText>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit Borrower',
            handler: () => onBorrowerEdit(borrower.id),
            danger: false,
            separatorRequired: true,
          },
          {
            title: 'Delete Borrower',
            handler: () => handleBorrowerDeleteClick(borrower.id),
            danger: true,
            separatorRequired: false,
          },
        ]}
        isLoading={isLoading}
      />
    </TableRow>
  );
};

export default BorrowerItemRow;
