import { extractEmails } from 'utils/extractEmails';

export const parseEmailAddress = (address: string) => {
  const senderName = address.slice(0, address.lastIndexOf(' ')).replace(/['"]+/g, '');
  const senderEmail = extractEmails(address)[0];

  return {
    name: senderName,
    email: senderEmail,
  };
};
