import React from 'react';
import EditBorrowerForm, {
  EditBorrowerFormProps,
} from 'components/LoanOriginationSystem/EditBorrowerForm/EditBorrowerForm';
import UnlockBorrower from 'components/LoanOriginationSystem/UnlockBorrower';
import styles from './EditBorrower.module.scss';

export interface EditBorrowerProps extends EditBorrowerFormProps {
  onBorrowerUnlock: () => void;
}

const EditBorrower = ({ borrower, onBorrowerUnlock, ...restProps }: EditBorrowerProps) => (
  <>
    <EditBorrowerForm borrower={borrower} {...restProps} />
    {borrower?.locked && (
      <UnlockBorrower className={styles.unlockBorrowerSection} onUnlockBorrower={onBorrowerUnlock} />
    )}
  </>
);

export default EditBorrower;
