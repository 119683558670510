import { Email, EmailsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const EMAILS_PER_PAGE_DEFAULT = 20;

export interface EmailsPaginationParams {
  search: string;
  sortingType: EmailsSortingType;
  createdAtDateRange: {
    from: Date | null;
    to: Date | null;
  };
  selectedBorrower: string | null;
}

const emailsPagination = Pagination<ReduxState, Email, EmailsPaginationParams>(
  'loanOriginationSystemEmails',
  EMAILS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default emailsPagination;
