import React, { FC } from 'react';
import styles from 'components/MultipleOptionsSelect/Option/Option.module.scss';
import { CloseButtonImage } from 'static/images';
import clsx from 'clsx';
import ApplicationTag from 'components/Tag/ApplicationTag';

export interface OptionType {
  className?: string;
  value: string;
  onDeleteOption: () => void;
  disabled: boolean;
  color?: string;
  renderOptionPrefix?: () => React.ReactNode;
  isLabelTag?: boolean;
}

const ALPHA_TRANSPARENCY = 20;

const Option: FC<OptionType> = ({
  color,
  className,
  value,
  onDeleteOption,
  renderOptionPrefix,
  disabled,
  isLabelTag,
}) => {
  const handleDelete = () => {
    if (disabled) {
      return;
    }
    onDeleteOption();
  };

  const backgroundColor = color ? `${color}${ALPHA_TRANSPARENCY}` : '';
  const renderOption = () => (
    <div style={{ backgroundColor }} className={clsx(styles.optionContainer, disabled && styles.disabled, className)}>
      {renderOptionPrefix && <div className={styles.prefixElement}>{renderOptionPrefix()}</div>}
      <p style={{ color }} className={styles.optionTitle}>
        {value}
      </p>
      <CloseButtonImage className={styles.closeButton} onClick={handleDelete} />
    </div>
  );

  const renderLabelTag = () => (
    <ApplicationTag color={color} medium onClose={handleDelete} className={styles.labelContainer}>
      {value}
    </ApplicationTag>
  );

  return isLabelTag ? renderLabelTag() : renderOption();
};

export default Option;
