import React from 'react';
import styles from './MappingVariablePopUp.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import { openCreateVariablePopup } from 'SendGrid/ActionCreator';
import { useDispatch } from 'react-redux';
import MappingVariableAutoCompletion from './MappingVariableAutoCompletion';

interface MappingVariablePopUpProps {
  onPopupClose: () => void;
  onMappingSave: () => void;
  isSaveInProgress: boolean;
  variablesMapping: Record<string, string>;
  initialVariablesMapping: Record<string, string>;
  updateVariableEntry: (variableEntryId: string, variable: string) => void;
}

const MappingVariablePopUp = ({
  onPopupClose,
  onMappingSave,
  isSaveInProgress,
  variablesMapping,
  initialVariablesMapping,
  updateVariableEntry,
}: MappingVariablePopUpProps) => {
  const dispatch = useDispatch();

  const openVariableCreation = () => {
    dispatch(openCreateVariablePopup());
  };

  const renderInputs = () => {
    return Object.keys(variablesMapping).map((variableKey, index) => (
      <MappingVariableAutoCompletion
        key={variableKey}
        variableKey={variableKey}
        variableSystemName={variablesMapping[variableKey]}
        updateVariableEntry={updateVariableEntry}
        openVariableCreation={openVariableCreation}
        displayCreateVariableLink={index === 0}
      />
    ));
  };

  return (
    <PopUp title="Variable Mapping" closePopUp={onPopupClose}>
      <PopUpContent hasTopMargin>
        {renderInputs()}
        <Button
          kind="primary"
          size="form"
          onClick={onMappingSave}
          isLoading={isSaveInProgress}
          className={styles.saveButton}
          disabled={initialVariablesMapping === variablesMapping}
        >
          Save
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default MappingVariablePopUp;
