import { AnyAction } from 'redux';

import {
  GET_DECISION_STRATEGY_UPDATES_REQUEST,
  GET_DECISION_STRATEGY_UPDATES_REQUEST_SUCCESS,
  GET_DECISION_STRATEGY_UPDATES_REQUEST_FAILURE,
} from './ActionTypes';

import { StrategyUpdateType } from './DecisionStrategiesTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ItemsWithTotalCount } from 'api/Types';

export interface GetDecisionStrategyUpdatesAction extends AnyAction {
  payload: string;
}

export interface DecisionStrategyUpdates extends ItemsWithTotalCount<StrategyUpdateType> {
  strategyId: string;
}

export interface GetDecisionStrategyUpdatesSuccessAction extends AnyAction {
  payload: DecisionStrategyUpdates;
}

export const getDecisionStrategyUpdatesRequest = (strategyId: string): GetDecisionStrategyUpdatesAction => {
  return {
    type: GET_DECISION_STRATEGY_UPDATES_REQUEST,
    payload: strategyId,
  };
};

export const getDecisionStrategyUpdatesSuccess = (
  decisionStrategyUpdates: DecisionStrategyUpdates,
): GetDecisionStrategyUpdatesSuccessAction => {
  return {
    type: GET_DECISION_STRATEGY_UPDATES_REQUEST_SUCCESS,
    payload: decisionStrategyUpdates,
  };
};

export const getDecisionStrategyUpdatesError = FailedActionCreator(GET_DECISION_STRATEGY_UPDATES_REQUEST_FAILURE);
