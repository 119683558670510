export enum LoanOriginationSystemBorrowerDetailsActionType {
  GetBorrower = 'loanOriginationSystemBorrowerDetails/getBorrower',
  GetBorrowerSuccess = 'loanOriginationSystemBorrowerDetails/getBorrowerSuccess',
  GetBorrowerFailure = 'loanOriginationSystemBorrowerDetails/getBorrowerFailure',
  ResetBorrowersDetails = 'loanOriginationSystemBorrowerDetails/resetBorrowersDetails',
  GetBorrowerApplications = 'loanOriginationSystemBorrowerDetails/getBorrowerApplications',
  GetBorrowerApplicationsSuccess = 'loanOriginationSystemBorrowerDetails/getBorrowerApplicationsSuccess',
  GetBorrowerApplicationsFailure = 'loanOriginationSystemBorrowerDetails/getBorrowerApplicationsFailure',
  SortBorrowerApplications = 'loanOriginationSystemBorrowerDetails/sortBorrowerApplications',
}
