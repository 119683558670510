import Fetcher from 'middlewares/Fetcher';
import { DocuSignIntegration, DocuSignIntegrationApi } from 'api/Core/DocuSignIntegrationApi';
import {
  CreateDocuSignIntegrationAction,
  createDocuSignIntegrationSuccess,
  createDocuSignIntegrationFailure,
} from './ActionCreator';
import { ESignIntegrationsActionType } from './ActionTypes';

const CreateDocuSignIntegrationMiddleware = (api: DocuSignIntegrationApi) =>
  Fetcher<DocuSignIntegration, CreateDocuSignIntegrationAction>(
    ESignIntegrationsActionType.CreateDocuSignIntegration,
    createDocuSignIntegrationSuccess,
    createDocuSignIntegrationFailure,
    (action) => {
      return api.createIntegration(action.payload.settings);
    },
  );

export default CreateDocuSignIntegrationMiddleware;
