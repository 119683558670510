import {
  GET_STRATEGIES_LIST,
  GET_STRATEGY_INPUTS,
  GET_INDIVIDUAL_HISTORY,
  RUN_INDIVIDUAL_STRATEGY,
  RUN_INDIVIDUAL_STRATEGY_SUCCESS,
  GET_INDIVIDUAL_CASE,
  GET_BATCH_HISTORY,
  RUN_BATCH_STRATEGY,
  GET_BATCH_CASE,
  RUN_BATCH_STRATEGY_SUCCESS,
} from './ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import {
  getStrategiesListSuccess,
  getStrategiesListFailure,
  GetStrategiesListAction,
  getStrategyInputsSuccess,
  GetStrategyInputsAction,
  GetIndividualHistoryAction,
  getIndividualHistorySuccess,
  getIndividualHistoryFailure,
  IndividualHistoryWithParams,
  RunIndividualStrategyAction,
  runIndividualStrategySuccess,
  runIndividualStrategyFailure,
  RunIndividualStrategySuccessAction,
  getIndividualCaseSuccess,
  getIndividualCaseFailure,
  GetIndividualCaseAction,
  BatchHistoryWithParams,
  GetBatchHistoryAction,
  getBatchHistoryFailure,
  getBatchHistorySuccess,
  RunBatchStrategyAction,
  runBatchStrategySuccess,
  runBatchStrategyFailure,
  BatchCaseWithParams,
  GetBatchCaseAction,
  getBatchCaseSuccess,
  getBatchCaseFailure,
  getBatchHistory,
} from './ActionCreator';
import { History } from 'history';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { DecisionEngineApi, StrategiesListItem } from 'api/Types';
import { ReduxState } from 'types/redux';
import { HISTORY_CASES_PER_PAGE_DEFAULT } from 'components/IndividualProcessing/History/Pagination';
import { BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT } from 'pages/ProcessingBatchCase/Pagination';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { IndividualCaseData, ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';

const GetStrategiesListMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<StrategiesListItem[], GetStrategiesListAction>(
    GET_STRATEGIES_LIST,
    getStrategiesListSuccess,
    getStrategiesListFailure,
    () => api.getStrategiesList(),
  );

const GetStrategyInputsMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<ProcessingStrategyInput[], GetStrategyInputsAction>(
    GET_STRATEGY_INPUTS,
    getStrategyInputsSuccess,
    getStrategiesListFailure,
    ({ payload }) => api.getStrategyInputs(payload),
  );

const GetIndividualHistoryMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<IndividualHistoryWithParams, GetIndividualHistoryAction, ReduxState>(
    GET_INDIVIDUAL_HISTORY,
    getIndividualHistorySuccess,
    getIndividualHistoryFailure,
    async (action, { individualProcessing }) => {
      const { searchInputValue } = individualProcessing.history;
      const response = await api.getIndividualCaseList({
        page: 1,
        perPage: HISTORY_CASES_PER_PAGE_DEFAULT,
        search: searchInputValue,
      });
      return {
        ...response,
        searchInputValue,
      };
    },
  );

const RunIndividualStrategyMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<{ caseId: string }, RunIndividualStrategyAction>(
    RUN_INDIVIDUAL_STRATEGY,
    runIndividualStrategySuccess,
    runIndividualStrategyFailure,
    async ({ payload }) => {
      const caseId = await api.runIndividualStrategy(payload.strategyId, payload.inputs, payload.applicationId);
      return { caseId };
    },
  );

const RunIndividualStrategySuccessMiddleware = ({ push }: History): Middleware => ({
  dispatch,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === RUN_INDIVIDUAL_STRATEGY_SUCCESS) {
    const { caseId } = (action as RunIndividualStrategySuccessAction).payload;

    notification.createNotification(getMessage(MessageType.RanIndividualProcessing), 'success', dispatch);
    push(`/decision/processing/individual/results/${caseId}`);
  }
  return result;
};

const GetIndividualCaseMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<IndividualCaseData, GetIndividualCaseAction>(
    GET_INDIVIDUAL_CASE,
    getIndividualCaseSuccess,
    getIndividualCaseFailure,
    ({ payload }) => api.getIndividualCase(payload),
  );

const GetBatchHistoryMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BatchHistoryWithParams, GetBatchHistoryAction, ReduxState>(
    GET_BATCH_HISTORY,
    getBatchHistorySuccess,
    getBatchHistoryFailure,
    async (action, { batchProcessing }) => {
      const { searchInputValue } = batchProcessing.history;
      const response = await api.getBatchCaseList({
        page: 1,
        perPage: HISTORY_CASES_PER_PAGE_DEFAULT,
        search: searchInputValue,
      });
      return {
        ...response,
        searchInputValue,
      };
    },
  );

const RunBatchStrategyMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<void, RunBatchStrategyAction>(
    RUN_BATCH_STRATEGY,
    runBatchStrategySuccess,
    runBatchStrategyFailure,
    ({ payload }) => api.runBatchStrategy(payload.strategyId, payload.batchData, payload.processName),
  );

const RunBatchStrategySuccessMiddleware = ({ push }: History) => ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === RUN_BATCH_STRATEGY_SUCCESS) {
    notification.createNotification(getMessage(MessageType.RanBatchProcessing), 'success', dispatch);
    dispatch(getBatchHistory());
    push(`/decision/processing/batch/history`);
  }
  return result;
};

const GetBatchCaseMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<BatchCaseWithParams, GetBatchCaseAction, ReduxState>(
    GET_BATCH_CASE,
    getBatchCaseSuccess,
    getBatchCaseFailure,
    async ({ payload }, { batchProcessingCase }) => {
      const { searchInputValue } = batchProcessingCase;
      const response = await api.getBatchCase(payload, {
        page: 1,
        perPage: BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT,
        search: searchInputValue,
      });
      return {
        ...response,
        searchInputValue,
      };
    },
  );

export {
  GetStrategiesListMiddleware,
  GetStrategyInputsMiddleware,
  GetIndividualHistoryMiddleware,
  RunIndividualStrategyMiddleware,
  RunIndividualStrategySuccessMiddleware,
  GetIndividualCaseMiddleware,
  GetBatchHistoryMiddleware,
  RunBatchStrategyMiddleware,
  RunBatchStrategySuccessMiddleware,
  GetBatchCaseMiddleware,
};
