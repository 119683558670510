import { connect } from 'react-redux';
import DeleteStrategyWizard from './DeleteStrategyWizard';
import { DeleteStrategyWizardState } from 'DeleteStrategyWizard/deleteStrategyWizardTypes';
import { closeDeleteStrategyWizard } from 'DeleteStrategyWizard/Actions';
import { deleteDecisionStrategyRequest } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export type DeleteStrategyWizardConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ deleteStrategyWizard }: { deleteStrategyWizard: DeleteStrategyWizardState }) => {
  return {
    isOpen: deleteStrategyWizard.isOpen,
    isLoading: deleteStrategyWizard.isLoading,
    id: deleteStrategyWizard.id,
    name: deleteStrategyWizard.name,
    version: deleteStrategyWizard.version,
    isCurrent: deleteStrategyWizard.isCurrent,
    type: deleteStrategyWizard.type,
    actionOrigin: deleteStrategyWizard.actionOrigin,
  };
};

const mapDispatchToProps = {
  deleteStrategy: deleteDecisionStrategyRequest,
  closePopUp: closeDeleteStrategyWizard,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteStrategyWizard);
