import React, { FC } from 'react';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { SIZES } from 'components/LoanOriginationSystem/LabelsDetails/Table/TableColumnSizes';
import styles from './LabelsLine.module.scss';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import ApplicationTag from 'components/Tag/ApplicationTag';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import OverflowedText from 'components/OverflowedText/OverflowedText';

interface LabelLineProps {
  label: Label;
  onPopUpOpen: (id: string) => void;
  onUpdatePopupOpen: (id: string) => void;
}

const LabelLine: FC<LabelLineProps> = ({ label, onPopUpOpen, onUpdatePopupOpen }) => {
  const { id, name, color, createdAt, updatedAt, createdBy, updatedBy } = label;
  return (
    <TableRow useActions onClick={() => onUpdatePopupOpen(id)}>
      <TableBodyCell width={SIZES.NAME}>
        <ApplicationTag color={color}>{name}</ApplicationTag>
      </TableBodyCell>
      <TableBodyCell width={SIZES.CREATED} noPadding>
        <WithSystemApiUserAvatar user={createdBy} size="small" />
        <OverflowedText className={styles.editorRowText}>
          {formatDate(createdAt, DateTimeFormat.Short)}
        </OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={SIZES.UPDATED} noPadding>
        <WithSystemApiUserAvatar user={updatedBy} size="small" />
        <OverflowedText className={styles.editorRowText}>
          {formatDate(updatedAt, DateTimeFormat.Short)}
        </OverflowedText>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit Label',
            handler: () => onUpdatePopupOpen(id),
            danger: false,
            separatorRequired: true,
          },
          {
            title: 'Delete Label',
            handler: () => onPopUpOpen(id),
            danger: true,
          },
        ]}
      />
    </TableRow>
  );
};

export default LabelLine;
