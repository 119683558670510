import React, { FC } from 'react';
import styles from './DigifiApi.module.scss';
import Button from 'components/Button';
import { ApiCredentials } from 'CompanyInformation/CompanyInformationTypes';
import CredentialsTable from './Table/CredentialsTable';

interface DigifiApiProps {
  apiCredentials: ApiCredentials;
  openGenerateFirstSecret: () => void;
  openGenerateSecondSecret: () => void;
}

const DigifiApi: FC<DigifiApiProps> = ({ apiCredentials, openGenerateFirstSecret, openGenerateSecondSecret }) => {
  const { clientId, clientSecret1, clientSecret2, publicKey } = apiCredentials;
  return (
    <div className={styles.pageContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h2 className={styles.titleText}>DigiFi API</h2>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            kind="primary"
            size="default"
            onClick={openGenerateFirstSecret}
            className={styles.generateSecretButton}
          >
            Generate New Secret 1
          </Button>
          <Button
            kind="primary"
            size="default"
            onClick={openGenerateSecondSecret}
            className={styles.generateSecretButton}
          >
            Generate New Secret 2
          </Button>
        </div>
      </div>
      <div className={styles.content}>
        <h4 className={styles.tableTitle}>Credentials</h4>
        <CredentialsTable
          clientId={clientId}
          clientPublicKey={publicKey}
          clientSecret1={clientSecret1}
          clientSecret2={clientSecret2}
        />
      </div>
    </div>
  );
};

export default DigifiApi;
