import React, { FC } from 'react';
import clsx from 'clsx';
import ShareButton from 'components/BillingDashboard/ShareButton';
import styles from './MonthlyPrice.module.scss';
import maskNumberValue from 'utils/masks/maskNumberValue';
import SkeletonText from 'components/Skeleton/SkeletonText';
import QuestionIcon from 'components/QuestionIcon';
import { ProductsConfig } from 'api/BillingApi';
import { AppRoutes } from 'routes/RouteService';

interface MonthlyPriceProps {
  className?: string;
  data: {
    displayPrice: string;
    applicationsIncluded: number;
    decisionsIncluded: number;
    extraApplicationPrice: string;
    extraDecisionPrice: string;
    products: ProductsConfig;
  } | null;
}

const MonthlyPrice: FC<MonthlyPriceProps> = ({ className, data }) => {
  const getHintMessage = () => {
    if (!data) {
      return '';
    }

    const applicationMessage = `${maskNumberValue(data.applicationsIncluded)} Applications`;
    const decisionMessage = `${maskNumberValue(data.decisionsIncluded)} Decisions`;

    if (!data.products.decisionEngine || (data.applicationsIncluded && data.decisionsIncluded === 0)) {
      return applicationMessage;
    }

    if (!data.products.loadOriginationSystem || (data.applicationsIncluded === 0 && data.decisionsIncluded)) {
      return decisionMessage;
    }

    return `${applicationMessage} and ${decisionMessage}`;
  };

  const getHintTooltipMessage = () => {
    if (!data) {
      return '';
    }

    const applicationMessage = `${data.extraApplicationPrice}/Application`;
    const decisionMessage = `${data.extraDecisionPrice}/Decision`;

    if (!data.products.decisionEngine || (data.applicationsIncluded && data.decisionsIncluded === 0)) {
      return applicationMessage;
    }

    if (!data.products.loadOriginationSystem || (data.applicationsIncluded === 0 && data.decisionsIncluded)) {
      return decisionMessage;
    }

    return `${applicationMessage} and ${decisionMessage}`;
  };

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.leftContainer}>
        <p className={styles.label}>Monthly Price (US Dollars)</p>
        {data && <p className={styles.value}>{data.displayPrice}</p>}
        {!data && (
          <SkeletonText className={styles.value} color="primary20" width="300px" height="24px" lineHeight="36px" />
        )}
        {data && (
          <div className={styles.hint}>
            Includes {getHintMessage()}
            <QuestionIcon
              className={styles.questionIcon}
              tooltip={`Overages are charged at ${getHintTooltipMessage()}`}
            />
          </div>
        )}
        {!data && (
          <SkeletonText className={styles.hint} color="primary6" width="190px" height="16px" lineHeight="16px" />
        )}
      </div>
      <div className={styles.rightContainer}>
        <ShareButton onClick={() => window.open(AppRoutes.CustomerPortal)}>Change Plan or Add Seats</ShareButton>
      </div>
    </div>
  );
};

export default MonthlyPrice;
