import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';

/**
 * Let's not use built-in dependency injection from redux-thunk using .withExtraArgument(),
 * cause we can provide dependencies per-thunk using just high-order functions.
 */
type DigifiThunkAction = ThunkAction<any, ReduxState, undefined, any>;
export type DigifiThunkActionCreator<Action extends AnyAction = AnyAction> = (action: Action) => DigifiThunkAction;
export type ActionsToThunksMap = Record<string, DigifiThunkActionCreator<any>>;

const MapActionToThunkMiddleware = (actionsToThunksMap: ActionsToThunksMap) =>
  (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: ThunkDispatch<any, undefined, any>) => (action: AnyAction) => {
    const result = next(action);
    const createThunk = actionsToThunksMap[action.type];
    if (!createThunk) {
      return result;
    }
    return dispatch((createThunk(action) as unknown) as AnyAction);
  }) as Middleware;

export default MapActionToThunkMiddleware;
