import { VariablesApi } from 'api/Core/VariablesApi';
import Fetcher from 'middlewares/Fetcher';
import {
  GetApplicationVariablesAction,
  getApplicationVariablesFailed,
  getApplicationVariablesSuccess,
} from './ActionCreator';
import { ApplicationPageActionType } from './ActionTypes';
import { Variable } from 'Variables/VariablesTypes';

const GetApplicationVariablesMiddleware = (api: VariablesApi) =>
  Fetcher<Variable[], GetApplicationVariablesAction>(
    ApplicationPageActionType.GetApplicationVariables,
    getApplicationVariablesSuccess,
    getApplicationVariablesFailed,
    async (action) => {
      const systemNames = Object.keys(action.payload.application.variables);

      const { items } = await api.getVariables({ systemNames });

      return items;
    },
  );

export default GetApplicationVariablesMiddleware;
