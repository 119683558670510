import React, { FC, useState } from 'react';
import styles from './EditExternalIntegrationVariables.module.scss';
import Button from 'components/Button';
import PopUpContent from 'components/PopUps/PopUpContent';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import { Option } from 'components/SelectInput/SelectInput';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { ExternalIntegrationOutputsList } from 'api/DecisionEngineStrategiesType';

interface AssignOutputVariableProps {
  assignedOutput: ExternalIntegrationOutputsList;
  openVariableCreation: () => void;
  assignOutputVariable: (outputIndex: number, variableId: string) => void;
  assignedOutputIndex: number;
  isLoading: boolean;
}

const AssignOutputVariable: FC<AssignOutputVariableProps> = ({
  openVariableCreation,
  assignedOutput,
  assignOutputVariable,
  assignedOutputIndex,
  isLoading,
}) => {
  const [sourceVariableId, setSourceVariableId] = useState(assignedOutput.variable);

  const isButtonDisabled = sourceVariableId === assignedOutput.variable;

  return (
    <PopUpContent>
      <InputWithDataTypeLabel
        labelTitle={assignedOutput.name}
        value={sourceVariableId}
        onChange={({ value }: Option) => setSourceVariableId(value)}
        dataType={assignedOutput.type}
        link={<CreateNewVariableLink onClick={openVariableCreation} />}
        description={assignedOutput.description}
        placeholder={`e.g. ${assignedOutput.example}`}
        placeholderType={assignedOutput.type}
        needDataType
      />
      <Button
        kind="primary"
        size="form"
        className={styles.saveButton}
        isLoading={isLoading}
        onClick={() => assignOutputVariable(assignedOutputIndex, sourceVariableId)}
        disabled={isButtonDisabled}
      >
        Save
      </Button>
    </PopUpContent>
  );
};

export default AssignOutputVariable;
