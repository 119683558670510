import {
  ResetStatusesRulesValidationResultsAction,
  GetStatusesRulesValidationResultsSuccessAction,
} from './ActionCreator';
import { ApplicationStatusRulesValidationState } from './Types';
import { ApplicationStatusRulesValidationActionType } from './ActionTypes';

export type ApplicationStatusesRulesValidationResultsActions =
  | GetStatusesRulesValidationResultsSuccessAction
  | ResetStatusesRulesValidationResultsAction;

export const initialState: ApplicationStatusRulesValidationState = null;

const applicationStatusesRulesValidationResultsReducer = (
  state: ApplicationStatusRulesValidationState = initialState,
  action: ApplicationStatusesRulesValidationResultsActions,
) => {
  switch (action.type) {
    case ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResultsSuccess: {
      return action.payload.validationResults;
    }
    case ApplicationStatusRulesValidationActionType.ResetStatusesRulesValidationResults: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default applicationStatusesRulesValidationResultsReducer;
