enum InvoiceStatus {
  Deleted = 'deleted',
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

export default InvoiceStatus;
