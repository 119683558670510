import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  NewTask,
  Task,
  TasksSortingFields,
  TasksSortingType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import styles from './TasksTable.module.scss';
import Table, { TableBodyContent } from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableActionCell from 'components/Table/TableActionCell';
import TableBody from 'components/Table/TableBody';
import SkeletonTaskLine from './SkeletonTaskLine';
import TaskLine from './TaskLine';
import Pagination from 'components/Pagination';
import useSorting from 'hooks/useSorting';
import SkeletonTaskLineSmall from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/SkeletonTaskLineSmall';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { TasksColumnSizes } from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TableColumnSizes';
import { TasksColumnSizesSmall } from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TableColumnSizesSmall';

export interface TasksTableProps {
  tasks: NullableItems<Task>;
  paginationProps: PaginationProps;
  sortingType: TasksSortingType;
  onSort: (field: TasksSortingFields, ascending: boolean) => void;
  onTaskDeletePopupOpen: (taskIdToDelete: string) => void;
  onTaskEdit: (id: string) => void;
  onStatusChange: (id: string, editTaskData: Partial<NewTask>) => Promise<void>;
  size: 'normal' | 'small';
  stickyMarginTop?: number;
}

const TasksTable = ({
  tasks,
  paginationProps,
  sortingType,
  onSort,
  onTaskDeletePopupOpen,
  onTaskEdit,
  onStatusChange,
  size,
  stickyMarginTop,
}: TasksTableProps) => {
  const renderRow = (task: Task) => (
    <TaskLine
      onTaskDeletePopupOpen={onTaskDeletePopupOpen}
      task={task}
      key={task.id}
      onTaskEdit={onTaskEdit}
      onStatusChange={onStatusChange}
      size={size}
    />
  );

  const renderSkeletonRow = (index: number) => {
    if (size === 'normal') {
      return <SkeletonTaskLine key={index} />;
    }

    return <SkeletonTaskLineSmall key={index} />;
  };

  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);
  const commonColumnSizes = size === 'normal' ? TasksColumnSizes : TasksColumnSizesSmall;

  const renderTableContent = () => {
    return (
      <>
        <Table>
          <TableHead sticky useActions stickyMarginTop={stickyMarginTop} className={styles.tableRow}>
            <TableHeadCell width={commonColumnSizes.Checkbox} />
            <TableHeadCell
              width={commonColumnSizes.Description}
              ascending={getSortingType(TasksSortingFields.Description)}
              onClick={() => changeSorting(TasksSortingFields.Description)}
            >
              Description
            </TableHeadCell>
            {size === 'normal' && (
              <>
                <TableHeadCell
                  width={TasksColumnSizes.ApplicationId}
                  ascending={getSortingType(TasksSortingFields.ApplicationId)}
                  onClick={() => changeSorting(TasksSortingFields.ApplicationId)}
                >
                  Application ID
                </TableHeadCell>
                <TableHeadCell
                  width={TasksColumnSizes.BorrowerName}
                  ascending={getSortingType(TasksSortingFields.BorrowerName)}
                  onClick={() => changeSorting(TasksSortingFields.BorrowerName)}
                >
                  Borrower Name
                </TableHeadCell>
              </>
            )}
            <TableHeadCell width={commonColumnSizes.TeamMembers}>Team Members</TableHeadCell>
            <TableHeadCell
              width={commonColumnSizes.DueDate}
              ascending={getSortingType(TasksSortingFields.DueDate)}
              onClick={() => changeSorting(TasksSortingFields.DueDate)}
            >
              Due Date
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent rows={tasks} renderSkeletonTableRow={renderSkeletonRow} renderTableRow={renderRow} />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </>
    );
  };

  if (size === 'normal') {
    return (
      <TableWrapperWithFooter>
        <div className={styles.table}>{renderTableContent()}</div>
      </TableWrapperWithFooter>
    );
  }

  return renderTableContent();
};

export default TasksTable;
