import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { UploadSecureDocumentActionType } from './ActionTypes';
import { UploadSecureDocumentSuccessAction } from './ActionCreator';

const isUploadSecretDocumentSuccessAction = (action: AnyAction): action is UploadSecureDocumentSuccessAction =>
  action.type === UploadSecureDocumentActionType.UploadSecureDocumentSuccess;

const UploadSecretDocumentSuccessMiddlewares = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUploadSecretDocumentSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.UploadSecretDocument), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default UploadSecretDocumentSuccessMiddlewares;
