import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';

const DEFAULT_SYSTEM_NAMES = Object.values(IntermediaryDefaultVariable);
const DEFAULT_INTERMEDIARY_PREFIX = 'Intermediary ';

export const formatDefaultFieldsDisplayTitle = (systemName: string, title: string) => {
  if (!DEFAULT_SYSTEM_NAMES.includes(systemName)) {
    return title;
  }

  return title.replace(DEFAULT_INTERMEDIARY_PREFIX, '');
};
