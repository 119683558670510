import React, { FC } from 'react';
import areArraysEqual from 'utils/areArraysEqual';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import useAutoCompletionMultiSelectProps from 'hooks/useAutoCompletionMultiSelectProps';
import styles from './EditTeamMembersPopup.module.scss';

export interface EditTeamMembersPopupProps {
  onSaveChanges: (newTeamMemberIds: string[]) => void;
  initialSelectedTeamMemberIds: string[];
  usePortal?: boolean;
  isSavingInProgress?: boolean;
  onClose: () => void;
}

const EditTeamMembersPopup: FC<EditTeamMembersPopupProps> = ({
  onSaveChanges,
  initialSelectedTeamMemberIds,
  isSavingInProgress,
  onClose,
  usePortal,
}: EditTeamMembersPopupProps) => {
  const teamMembers = useOrganizationMembers();

  const renderMemberAvatar = (member: UserInfo) => (
    <ImageFetcher size="small" firstName={member.firstName} lastName={member.lastName} imageId={member.imageId} />
  );

  const [membersProps, selectedMembers] = useAutoCompletionMultiSelectProps(
    teamMembers,
    teamMembers.filter(({ id }) => initialSelectedTeamMemberIds.includes(id)),
    (member) => member.id,
    (member) => `${member.firstName} ${member.lastName}`,
    renderMemberAvatar,
    renderMemberAvatar,
  );

  const isButtonDisabled = areArraysEqual(
    selectedMembers.map(({ id }) => id),
    initialSelectedTeamMemberIds,
  );

  return (
    <PopUp usePortal={usePortal} closePopUp={onClose} title="Team Members">
      <PopUpContent>
        <AutoCompletionMultiselect id="teamMembers" labelTitle="Assigned Team Members" {...membersProps} />
        <Button
          onClick={() => onSaveChanges(selectedMembers.map(({ id }) => id))}
          kind="primary"
          size="form"
          className={styles.saveChangesButton}
          isLoading={isSavingInProgress}
          disabled={isButtonDisabled}
        >
          Save Changes
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditTeamMembersPopup;
