import { AnyAction } from 'redux';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { ApplicationDocument, ApplicationDocumentsSortingType } from 'api/LoanOriginationSystem/DocumentsApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

export interface GetApplicationDocumentsAction extends AnyAction {
  type: ApplicationDocumentsActionType.GetApplicationDocuments;
  payload: {
    applicationId: string;
    search: string;
    sortingType: ApplicationDocumentsSortingType;
  };
}

export interface GetApplicationDocumentsSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.GetApplicationDocumentsSuccess;
  payload: TableViewData<ApplicationDocument> & { search: string };
}

export interface UploadApplicationDocumentAction extends AnyAction {
  type: ApplicationDocumentsActionType.UploadApplicationDocument;
  payload: {
    applicationId: string;
    file: File;
  };
}

export interface UploadApplicationDocumentSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.UploadApplicationDocumentSuccess;
  payload: {
    document: ApplicationDocument;
  };
}

export interface RenameApplicationDocumentSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.RenameApplicationDocumentSuccess;
}

export interface DeleteApplicationDocumentAction extends AnyAction {
  type: ApplicationDocumentsActionType.DeleteApplicationDocument;
  payload: {
    applicationId: string;
    documentId: string;
  };
}

export interface DeleteApplicationDocumentSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.DeleteApplicationDocumentSuccess;
}

export interface OpenUploadApplicationDocumentPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.OpenApplicationDocumentUploadPopup;
}

export interface CloseUploadApplicationDocumentPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.CloseApplicationDocumentUploadPopup;
}

export interface OpenRenameApplicationDocumentPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.OpenRenameApplicationDocumentPopup;
}

export interface CloseRenameApplicationDocumentPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.CloseRenameApplicationDocumentPopup;
}

export interface RequestCustomerUpload extends AnyAction {
  type: ApplicationDocumentsActionType.RequestCustomerUpload;
  payload: {
    subject: string;
    description: string;
    applicationId: string;
  };
}

export interface RequestCustomerUploadSuccess extends AnyAction {
  type: ApplicationDocumentsActionType.RequestCustomerUploadSuccess;
}

export interface OpenRequestCustomerUploadPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.OpenRequestCustomerUploadPopup;
}

export interface CloseRequestCustomerUploadPopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.CloseRequestCustomerUploadPopup;
}

export interface SetSearchValueAction extends AnyAction {
  type: ApplicationDocumentsActionType.SetSearchValue;
  payload: {
    applicationId: string;
    search: string;
  };
}

export interface RenameApplicationDocumentAction extends AnyAction {
  type: ApplicationDocumentsActionType.RenameApplicationDocument;
  payload: {
    applicationId: string;
    documentId: string;
    documentName: string;
  };
}

export interface SortApplicationDocumentsAction extends AnyAction {
  type: ApplicationDocumentsActionType.SortApplicationDocuments;
  payload: {
    applicationId: string;
    sortingType: ApplicationDocumentsSortingType;
  };
}

export interface SetApplicationDocumentToDeleteAction extends AnyAction {
  type: ApplicationDocumentsActionType.SetApplicationDocumentToDelete;
  payload: {
    document: ApplicationDocument | null;
  };
}

export interface DownloadApplicationDocumentAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadDocument;
  payload: {
    documentId: string;
  };
}

export interface DownloadApplicationDocumentSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadDocumentSuccess;
  payload: {
    documentDownloadingId: string;
  };
}

export interface DownloadApplicationDocumentFailureAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadDocumentFailure;
  payload: {
    documentDownloadingId: string;
  };
}

export interface DownloadArchiveOfApplicationDocumentsAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments;
  payload: {
    applicationId: string;
  };
}

export interface DownloadArchiveOfApplicationDocumentsSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsSuccess;
  payload: {
    documentDownloadingId: string;
  };
}

export interface DownloadArchiveOfApplicationDocumentsFailureAction extends AnyAction {
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsFailure;
  payload: {
    documentDownloadingId: string;
  };
}

export interface RemoveDownloadedDocumentAction extends AnyAction {
  type: ApplicationDocumentsActionType.RemoveDownloadedDocument;
  payload: {
    documentDownloadingId: string;
  };
}

export interface CreateDocuSignEnvelopeAction extends AnyAction {
  type: ApplicationDocumentsActionType.CreateDocuSignEnvelope;
  payload: {
    applicationId: string;
    templateId: string;
  };
}

export interface CreateDocuSignEnvelopeSuccessAction extends AnyAction {
  type: ApplicationDocumentsActionType.CreateDocuSignEnvelopeSuccess;
}

export interface ToggleSendDocuSignSignaturePopupAction extends AnyAction {
  type: ApplicationDocumentsActionType.ToggleSendDocuSignSignaturePopup;
}

export const getApplicationDocuments = (
  applicationId: string,
  search: string,
  sortingType: ApplicationDocumentsSortingType,
): GetApplicationDocumentsAction => ({
  type: ApplicationDocumentsActionType.GetApplicationDocuments,
  payload: {
    applicationId,
    search,
    sortingType,
  },
});

export const getApplicationDocumentsSuccess = (
  result: TableViewData<ApplicationDocument> & { search: string },
): GetApplicationDocumentsSuccessAction => ({
  type: ApplicationDocumentsActionType.GetApplicationDocumentsSuccess,
  payload: result,
});

export const uploadApplicationDocument = (applicationId: string, file: File): UploadApplicationDocumentAction => ({
  type: ApplicationDocumentsActionType.UploadApplicationDocument,
  payload: {
    applicationId,
    file,
  },
});

export const uploadApplicationDocumentSuccess = (
  document: ApplicationDocument,
): UploadApplicationDocumentSuccessAction => ({
  type: ApplicationDocumentsActionType.UploadApplicationDocumentSuccess,
  payload: {
    document,
  },
});

export const renameApplicationDocumentSuccess = (): RenameApplicationDocumentSuccessAction => ({
  type: ApplicationDocumentsActionType.RenameApplicationDocumentSuccess,
});

export const deleteApplicationDocument = (
  applicationId: string,
  documentId: string,
): DeleteApplicationDocumentAction => ({
  type: ApplicationDocumentsActionType.DeleteApplicationDocument,
  payload: {
    documentId,
    applicationId,
  },
});

export const deleteApplicationDocumentSuccess = (): DeleteApplicationDocumentSuccessAction => ({
  type: ApplicationDocumentsActionType.DeleteApplicationDocumentSuccess,
});

export const openUploadApplicationDocumentPopup = (): OpenUploadApplicationDocumentPopupAction => ({
  type: ApplicationDocumentsActionType.OpenApplicationDocumentUploadPopup,
});

export const closeUploadApplicationDocumentPopup = (): CloseUploadApplicationDocumentPopupAction => ({
  type: ApplicationDocumentsActionType.CloseApplicationDocumentUploadPopup,
});

export const openRenameApplicationDocumentPopup = (
  document: ApplicationDocument,
): OpenRenameApplicationDocumentPopupAction => ({
  type: ApplicationDocumentsActionType.OpenRenameApplicationDocumentPopup,
  payload: document,
});

export const closeRenameApplicationDocumentPopup = (): CloseRenameApplicationDocumentPopupAction => ({
  type: ApplicationDocumentsActionType.CloseRenameApplicationDocumentPopup,
});

export const setSearchValue = (applicationId: string, search: string): SetSearchValueAction => ({
  type: ApplicationDocumentsActionType.SetSearchValue,
  payload: {
    applicationId,
    search,
  },
});

export const renameApplicationDocument = (
  applicationId: string,
  documentId: string,
  documentName: string,
): RenameApplicationDocumentAction => ({
  type: ApplicationDocumentsActionType.RenameApplicationDocument,
  payload: {
    applicationId,
    documentId,
    documentName,
  },
});

export const sortApplicationDocuments = (
  applicationId: string,
  sortingType: ApplicationDocumentsSortingType,
): SortApplicationDocumentsAction => ({
  type: ApplicationDocumentsActionType.SortApplicationDocuments,
  payload: {
    applicationId,
    sortingType,
  },
});

export const setApplicationDocumentToDelete = (
  document: ApplicationDocument | null,
): SetApplicationDocumentToDeleteAction => ({
  type: ApplicationDocumentsActionType.SetApplicationDocumentToDelete,
  payload: {
    document,
  },
});

export const requestCustomerUpload = (payload: RequestCustomerUpload['payload']): RequestCustomerUpload => ({
  type: ApplicationDocumentsActionType.RequestCustomerUpload,
  payload,
});

export const requestCustomerUploadSuccess = (): RequestCustomerUploadSuccess => ({
  type: ApplicationDocumentsActionType.RequestCustomerUploadSuccess,
});

export const openRequestCustomerUploadPopup = (): OpenRequestCustomerUploadPopupAction => ({
  type: ApplicationDocumentsActionType.OpenRequestCustomerUploadPopup,
});

export const closeRequestCustomerUploadPopup = (): CloseRequestCustomerUploadPopupAction => ({
  type: ApplicationDocumentsActionType.CloseRequestCustomerUploadPopup,
});

export const downloadApplicationDocument = (documentId: string): DownloadApplicationDocumentAction => ({
  type: ApplicationDocumentsActionType.DownloadDocument,
  payload: {
    documentId,
  },
});

export const downloadApplicationDocumentSuccess = (
  documentDownloadingId: string,
): DownloadApplicationDocumentSuccessAction => ({
  type: ApplicationDocumentsActionType.DownloadDocumentSuccess,
  payload: {
    documentDownloadingId,
  },
});

export const downloadApplicationDocumentFailure = (
  documentDownloadingId: string,
): DownloadApplicationDocumentFailureAction => ({
  type: ApplicationDocumentsActionType.DownloadDocumentFailure,
  payload: {
    documentDownloadingId,
  },
});

export const removeDownloadedDocument = (documentDownloadingId: string): RemoveDownloadedDocumentAction => ({
  type: ApplicationDocumentsActionType.RemoveDownloadedDocument,
  payload: {
    documentDownloadingId,
  },
});

export const downloadArchiveOfApplicationDocuments = (
  applicationId: string,
): DownloadArchiveOfApplicationDocumentsAction => ({
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments,
  payload: {
    applicationId,
  },
});

export const downloadArchiveOfApplicationDocumentsSuccess = (
  documentDownloadingId: string,
): DownloadArchiveOfApplicationDocumentsSuccessAction => ({
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsSuccess,
  payload: {
    documentDownloadingId,
  },
});

export const downloadArchiveOfApplicationDocumentsFailure = (
  documentDownloadingId: string,
): DownloadArchiveOfApplicationDocumentsFailureAction => ({
  type: ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsFailure,
  payload: {
    documentDownloadingId,
  },
});

export const createDocuSignEnvelope = (applicationId: string, templateId: string): CreateDocuSignEnvelopeAction => ({
  type: ApplicationDocumentsActionType.CreateDocuSignEnvelope,
  payload: {
    applicationId,
    templateId,
  },
});

export const createDocuSignEnvelopeSuccess = (): CreateDocuSignEnvelopeSuccessAction => ({
  type: ApplicationDocumentsActionType.CreateDocuSignEnvelopeSuccess,
});

export const toggleSendDocuSignSignaturePopup = (): ToggleSendDocuSignSignaturePopupAction => ({
  type: ApplicationDocumentsActionType.ToggleSendDocuSignSignaturePopup,
});

export const getApplicationDocumentsFailure = FailedActionCreator(
  ApplicationDocumentsActionType.GetApplicationDocumentsFailure,
);
export const uploadApplicationDocumentFailure = FailedActionCreator(
  ApplicationDocumentsActionType.UploadApplicationDocumentFailure,
);
export const deleteApplicationDocumentFailure = FailedActionCreator(
  ApplicationDocumentsActionType.DeleteApplicationDocumentFailure,
);
export const renameApplicationDocumentFailure = FailedActionCreator(
  ApplicationDocumentsActionType.RenameApplicationDocumentFailure,
);
export const requestCustomerUploadFailure = FailedActionCreator(
  ApplicationDocumentsActionType.RequestCustomerUploadFailure,
);
export const createDocuSignEnvelopeFailure = FailedActionCreator(
  ApplicationDocumentsActionType.CreateDocuSignEnvelopeFailure,
);
