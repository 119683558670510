import { FailedAction } from 'utils/actions/FailedActionCreator';
import { SetBorrowerToUnlockAction, UnlockBorrowerAction, UnlockBorrowerSuccessAction } from './ActionCreator';
import { LoanOriginationSystemUnlockBorrowerType } from './ActionTypes';
import { LoanOriginationSystemUnlockBorrowerState } from './Types';

export const initialState: LoanOriginationSystemUnlockBorrowerState = {
  isUnlockingInProgress: false,
  borrowerToUnlock: null,
};

type LoanOriginationSystemUnlockBorrowerActions =
  | UnlockBorrowerAction
  | UnlockBorrowerSuccessAction
  | FailedAction<LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerFailure>
  | SetBorrowerToUnlockAction;

export const loanOriginationSystemUnlockBorrowerReducer = (
  state: LoanOriginationSystemUnlockBorrowerState = initialState,
  action: LoanOriginationSystemUnlockBorrowerActions,
): LoanOriginationSystemUnlockBorrowerState => {
  switch (action.type) {
    case LoanOriginationSystemUnlockBorrowerType.UnlockBorrower: {
      return {
        ...state,
        isUnlockingInProgress: true,
      };
    }
    case LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess: {
      return {
        ...state,
        isUnlockingInProgress: false,
        borrowerToUnlock: null,
      };
    }
    case LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerFailure: {
      return {
        ...state,
        isUnlockingInProgress: false,
      };
    }
    case LoanOriginationSystemUnlockBorrowerType.SetBorrowerToUnlock: {
      return {
        ...state,
        borrowerToUnlock: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
