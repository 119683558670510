import React from 'react';
import ActionPopUpItem from 'components/ActionPopUpItem';
import styles from './SuggestionsPopper.module.scss';
import CustomPopper, { CustomPopperProps } from 'components/CustomPopper';
import OverlayScrollContainer from 'components/OverlayScrollContainer';

export interface SuggestionItem {
  id: string;
  name: string;
  email: string;
  phone: string;
}

interface SuggestionsPopperProps extends Omit<CustomPopperProps, 'children'> {
  suggestions: SuggestionItem[];
  onSelectSuggestion: (suggestionItem: SuggestionItem) => void;
}

const SuggestionsPopper = ({
  suggestions,
  onSelectSuggestion,
  anchorEl,
  className,
  ...popperProps
}: SuggestionsPopperProps) => {
  const width = anchorEl ? parseFloat(window.getComputedStyle(anchorEl as Element).width) : 0;

  return (
    <CustomPopper style={{ width }} className={className} anchorEl={anchorEl} {...popperProps}>
      <OverlayScrollContainer className={styles.overlayContainer}>
        {suggestions.map((suggestionItem) => (
          <ActionPopUpItem key={suggestionItem.id} onClick={() => onSelectSuggestion(suggestionItem)}>
            <div className={styles.name}>{suggestionItem.name}</div>
            <div className={styles.email}>{suggestionItem.email}</div>
            <div className={styles.phone}>{suggestionItem.phone}</div>
          </ActionPopUpItem>
        ))}
      </OverlayScrollContainer>
    </CustomPopper>
  );
};

export default SuggestionsPopper;
