import React, { FC } from 'react';
import {
  ESignTemplateRecipient,
  ESignTemplateRecipientEntityType,
  ESignTemplateRecipientsMapping,
} from 'api/Core/ESignTemplatesApi';
import TableColumnSizes from 'components/DocuSign/RecipientsTable/TableColumnSizes';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import styles from './RecipientTableRow.module.scss';

interface RecipientTableRowProps {
  recipient: ESignTemplateRecipient;
  recipientsMapping: ESignTemplateRecipientsMapping;
}

const RecipientTableRow: FC<RecipientTableRowProps> = ({ recipient, recipientsMapping }) => {
  const { name, recipientId } = recipient;

  const nameVariable = useVariableAttributes(recipientsMapping[recipientId]?.name);
  const emailVariable = useVariableAttributes(recipientsMapping[recipientId]?.email);

  const getEntryValue = () => {
    if (recipientsMapping[recipientId]?.type === ESignTemplateRecipientEntityType.Other) {
      const recipientName = recipient.recipientName ?? nameVariable?.displayName;
      const recipientEmail = recipient.recipientEmail ?? emailVariable?.displayName;

      return recipientName && recipientEmail ? `${recipientName} (${recipientEmail})` : '';
    }

    return recipientsMapping[recipientId]?.type;
  };

  return (
    <TableRow disableHover>
      <TableBodyCell className={styles.recipientName} width={TableColumnSizes.Recipient}>
        {name}
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Variable}>{getEntryValue()}</TableBodyCell>
    </TableRow>
  );
};

export default RecipientTableRow;
