import React, { FC, MouseEvent, useRef, useState } from 'react';
import styles from './ColorPickerInput.module.scss';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ColorPicker from 'components/ColorPicker/ColorPicker';
import clsx from 'clsx';

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface ColorPickerInputProps extends HTMLInputProps {
  setColor: (color: string) => void;
  color?: string;
}

const WHITE_COLOR_CODE = 'FFFFFF';
const SHORT_WHITE_COLOR_CODE = 'FFF';

const ColorPickerInput: FC<ColorPickerInputProps> = ({ value, setColor, color, ...props }) => {
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const handleOpenPopUp = (event: MouseEvent): void => {
    event.stopPropagation();
    setIsPopUpOpen(true);
  };
  const handleClosePopUp = (): void => {
    setIsPopUpOpen(false);
  };

  const handleChangeColor = (inputValue: string) => {
    setColor(inputValue.toUpperCase());
  };

  const isWhiteColor =
    color && (color.toUpperCase() === SHORT_WHITE_COLOR_CODE || color.toUpperCase() === WHITE_COLOR_CODE);

  const popupHandlerRef = useRef<HTMLDivElement>(null);

  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <p className={styles.inputTitle}>Selected Color</p>
      <div className={styles.colorInputContainer}>
        <div
          className={clsx(styles.colorBlock, isWhiteColor && styles.whiteColorBlock)}
          style={{ backgroundColor: `#${color || value}` }}
          onClick={handleOpenPopUp}
          ref={popupHandlerRef}
        />
        <p>#</p>
        <input type="text" className={styles.colorInput} value={value} {...props} maxLength={6} />
      </div>
      <ContextualPopUp
        open={isPopUpOpen}
        anchorEl={popupHandlerRef.current}
        onClose={() => handleClosePopUp()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <ColorPicker value={`${value}`} onChange={handleChangeColor} />
      </ContextualPopUp>
    </div>
  );
};

export default ColorPickerInput;
