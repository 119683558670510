import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  LoanOriginationSystemIntermediariesApi,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { LoanOriginationSystemIntermediaryDetailsActionTypes } from './ActionTypes';
import { GetIntermediaryData, getIntermediaryDataFailure, getIntermediaryDataSuccess } from './ActionCreator';

const GetIntermediaryDataMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary, GetIntermediaryData, ReduxState>(
    LoanOriginationSystemIntermediaryDetailsActionTypes.GetIntermediaryData,
    getIntermediaryDataSuccess,
    getIntermediaryDataFailure,
    async (action) => {
      return api.getIntermediary(action.payload.id);
    },
  );

export default GetIntermediaryDataMiddleware;
