import { ChangePopupFiltersAction, ResetAllTasksFilters } from 'LoanOriginationSystemTasks/Filters/ActionCreator';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './ActionTypes';
import { getEmails } from 'LoanOriginationSystemEmails/ActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllTasksFilters =>
  action.type === LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters;

const onEmailsFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetAllFiltersAction(action)) {
    const { loanOriginationSystemEmails } = getState();
    const { filters, sortingType } = loanOriginationSystemEmails;

    dispatch(getEmails(filters, sortingType));
  }

  return result;
}) as Middleware;

export default onEmailsFiltersChangeMiddleware;
