import Fetcher from 'middlewares/Fetcher';
import { DownloadResponse } from 'api/APIRest';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { BorrowerConfigurationActionType } from './ActionTypes';
import {
  DownloadApiRequestTemplateAction,
  downloadApiRequestTemplateFailure,
  downloadApiRequestTemplateSuccess,
} from './ActionCreator';
import { ApiRequestTemplatesApi } from 'api/LoanOriginationSystem/ApiRequestTemplatesApi';

const DownloadApiRequestTemplateMiddleware = (api: ApiRequestTemplatesApi) =>
  Fetcher<DownloadResponse, DownloadApiRequestTemplateAction>(
    BorrowerConfigurationActionType.DownloadApiRequestTemplate,
    downloadApiRequestTemplateSuccess,
    downloadApiRequestTemplateFailure,
    async (action) => {
      const response = await api.downloadBorrowerApiRequestTemplate(
        action.payload.borrowerType,
        action.payload.requestType,
      );

      downloadBlobFile(response);

      return response;
    },
  );

export default DownloadApiRequestTemplateMiddleware;
