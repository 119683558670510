import Fetcher from 'middlewares/Fetcher';
import { GetLabelInfoAction, getLabelInfoSuccess, getLabelInfoFailure } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { ReduxState } from 'types/redux';
import LoanOriginationSystemLabelsRestApi, { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

const GetLabelInfoMiddleware = (api: LoanOriginationSystemLabelsRestApi) =>
  Fetcher<Label, GetLabelInfoAction, ReduxState>(
    LoanOriginationSystemLabelsActionTypes.GetLabelInfo,
    getLabelInfoSuccess,
    getLabelInfoFailure,
    ({ payload }) => api.getLabelById(payload),
  );

export default GetLabelInfoMiddleware;
