import React, { useEffect, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import styles from './DeleteConfirmationPopup.module.scss';
import Button from 'components/Button';
import SelectVariablesTable from './SelectVariablesTable/SelectVariablesTable';
import { DataViewSortingType, VariableValue } from 'api/Types';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import useOnce from 'hooks/useOnce';
import ApplicationVariableSortingField from 'LoanOriginationSystemApplicationPage/ApplicationVariableSortingField';
import { Variable } from 'Variables/VariablesTypes';

interface DeleteConfirmationPopupProps {
  entityName: EditApplicationStep;
  variables: Variable[] | null;
  values: Record<string, VariableValue>;
  isLoading: boolean;
  onSubmit: (variableNames: string[]) => void;
  onClose: () => void;
  onVariablesSort: (field: ApplicationVariableSortingField, ascending: boolean) => void;
  variableSortingType: DataViewSortingType<ApplicationVariableSortingField>;
}

const COBORROWER_SPECIAL_WORDS = ['co-borrower', 'coborrower', 'joint', 'combined'];
const INTERMEDIARY_SPECIAL_WORDS = [
  'intermediary',
  'broker',
  'contractor',
  'affiliate',
  'correspondent',
  'dealer',
  'retailer',
];

const HIDDEN_VARIABLES = [
  ApplicationDefaultVariable.IncludesIntermediary,
  ApplicationDefaultVariable.IncludesCoBorrower,
];

const DeleteConfirmationPopup = ({
  entityName,
  variables,
  values,
  isLoading,
  onSubmit,
  onClose,
  onVariablesSort,
  variableSortingType,
}: DeleteConfirmationPopupProps) => {
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);

  const preselectVariables = useOnce((allVariables: Variable[]) => {
    const preselectedSystemNames =
      entityName === EditApplicationStep.CoBorrower
        ? Object.values(ApplicationDefaultVariable.Coborrower)
        : Object.values(ApplicationDefaultVariable.Intermediary);

    const specialWords =
      entityName === EditApplicationStep.CoBorrower ? COBORROWER_SPECIAL_WORDS : INTERMEDIARY_SPECIAL_WORDS;

    const variablesToSelect = allVariables.filter((variable) => {
      return (
        preselectedSystemNames.includes(variable.systemName) ||
        (!variable.isStandard &&
          specialWords.some((specialWord) => variable.displayName.toLocaleLowerCase().includes(specialWord)))
      );
    });

    setSelectedVariables(variablesToSelect.map((variable) => variable.systemName));
  });

  const isVariableDisabled = (variable: Variable) => {
    return [
      ApplicationDefaultVariable.LoanAmount,
      ApplicationDefaultVariable.IncludesCoBorrower,
      ApplicationDefaultVariable.IncludesIntermediary,
      ...Object.values(ApplicationDefaultVariable.Borrower),
      ...Object.values(ApplicationDefaultVariable.Coborrower),
      ...Object.values(ApplicationDefaultVariable.Intermediary),
    ].includes(variable.systemName);
  };

  const handleSelectVariable = (variable: Variable, selected: boolean) => {
    if (selected) {
      setSelectedVariables([...selectedVariables, variable.systemName]);

      return;
    }

    setSelectedVariables(selectedVariables.filter((systemName) => variable.systemName !== systemName));
  };

  useEffect(() => {
    if (variables && variables.length > 0) {
      preselectVariables(variables);
    }
  }, [variables]);

  return (
    <PopUp
      closePopUp={onClose}
      title={`Remove ${entityName}`}
      titleTooltip={`Another ${entityName.toLowerCase()} can be added to the application after this one is removed`}
      closable={!isLoading}
    >
      <PopUpContent hasTopMargin>
        <p className={styles.contentMessage}>
          Please confirm that you would like to remove the {entityName} from this application.
        </p>
        <p className={styles.contentSubMessage}>
          You may delete data related to this {entityName} from this application by clicking below. We have pre-selected
          certain fields that we believe should be removed.
        </p>
        <SelectVariablesTable
          variables={variables}
          values={values}
          className={styles.table}
          isVariableDisabled={isVariableDisabled}
          selectedVariables={selectedVariables}
          onSelect={handleSelectVariable}
          onSort={onVariablesSort}
          variableSortingType={variableSortingType}
          hiddenVariables={HIDDEN_VARIABLES}
        />
        <div className={styles.buttonsContainer}>
          <Button
            kind="primary"
            size="form"
            className={styles.submit}
            isLoading={isLoading}
            onClick={() => onSubmit(selectedVariables)}
          >
            Remove {entityName}
          </Button>
          <Button kind="secondary" size="form" disabled={isLoading} onClick={onClose}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default DeleteConfirmationPopup;
