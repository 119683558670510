import React from 'react';
import { ApplicationsTableSizes } from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable/TableColumnSizes';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import ApplicationLabels from 'components/LoanOriginationSystem/ApplicationLabels';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import TableRow from 'components/Table/TableRow';
import styles from './ApplicationTableBody.module.scss';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { getFormattedBorrowerPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import ApplicationRowActions from 'components/LoanOriginationSystem/ApplicationsDashboard/ApplicationRowActions/ApplicationRowActions';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { StandardVariables } from 'Variables/VariablesTypes';
import WrapperWithTooltip from 'components/Tooltip';
import { DuplicateImage } from 'static/images';
import CoBorrowerInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';

export interface ApplicationTableRowProps {
  application: Application;
  currencySymbol: string;
  standardVariables: StandardVariables | null;
  deleteDisabled: boolean;
  onClick: (application: Application) => void;
  onEdit?: (application: Application) => void;
  onDelete?: (application: Application) => void;
  onDuplicate?: (application: Application) => void;
}

const MAX_AVATARS_COUNT = 4;

const ApplicationTableRow = ({
  application,
  currencySymbol,
  standardVariables,
  deleteDisabled,
  onClick,
  onEdit,
  onDelete,
  onDuplicate,
}: ApplicationTableRowProps) => {
  const borrowerPhoneNumber = application.variables[ApplicationDefaultVariable.Borrower.PhoneNumber];
  const formattedBorrowerPhoneNumber = getFormattedBorrowerPhoneNumber(borrowerPhoneNumber, standardVariables);
  const loanAmountDisplayValue = formatMonetaryValue(
    application.variables[ApplicationDefaultVariable.LoanAmount] as number,
    currencySymbol,
  );

  return (
    <TableRow key={application.id} onClick={() => onClick(application)} useActions>
      <TableBodyCell width={ApplicationsTableSizes.BorrowerFullName} className={styles.nameCell} noPadding>
        <ApplicationLabels labels={application.labels} />
        <OverflowedText className={styles.nameLine}>
          <div className={styles.label}>{getBorrowerFullName(application.borrowerType, application.variables)}</div>
          {application.coborrowerType &&  <CoBorrowerInfo coborrowerType={application.coborrowerType} variables={application.variables} />}
        </OverflowedText>
      </TableBodyCell>
      <TableBodyCell overflowContainerClassName={styles.displayId} width={ApplicationsTableSizes.DisplayId} overflowed>
        {formatDisplayId(application.displayId)}
        {application.originalApplicationId && (
          <WrapperWithTooltip tooltip="Duplicated Application">
            <DuplicateImage className={styles.duplicateIcon} />
          </WrapperWithTooltip>
        )}
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableSizes.CreatedAt} overflowed>
        {formatDate(application.createdAt, DateTimeFormat.Short)}
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableSizes.Status} overflowed>
        {application.status.name}
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableSizes.BorrowerPhoneNumber} overflowed>
        {formattedBorrowerPhoneNumber || borrowerPhoneNumber}
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableSizes.TeamMembers} noPadding>
        {application.teamMembers.length > 0 && (
          <UserAvatarInlineList source={application.teamMembers} size="small" maxCount={MAX_AVATARS_COUNT} />
        )}
      </TableBodyCell>
      <TableBodyCell width={ApplicationsTableSizes.LoanAmount} className={styles.loanAmountCell} overflowed>
        {loanAmountDisplayValue}
      </TableBodyCell>
      <ApplicationRowActions
        onEdit={() => onEdit?.(application)}
        onDelete={() => onDelete?.(application)}
        onDuplicate={() => onDuplicate?.(application)}
        deleteDisabled={deleteDisabled}
      />
    </TableRow>
  );
};

export default ApplicationTableRow;
