import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { get, tap, times } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationParams } from 'api/Types';
import deepAssign from 'utils/deepAssign';
import { unhandledCase } from 'utils/unhandledCase';

const getRaw = (tree: Object, path: string[]) => path.reduce((val, key) => val[key], tree);

interface PaginationState {
  page: number;
  itemsPerPage: number;
  itemsTotal: number | undefined;
}

export interface ItemsStateWithPagination<Item> extends PaginationState {
  items: Item[];
  error: Error | string | null;
}

export type NullableItems<Item> = (Item | null)[];

export interface PaginationProps extends PaginationState {
  pagesCount: number | undefined;
  setPage: (page: number) => void;
  nextPage: () => void;
  prevPage: () => void;
  setItemsPerPage: (itemsPerPage: number) => void;
  setItemsTotal: (itemsTotal: number) => void;
}

interface PaginationActionMeta {
  collectionId: string | symbol;
}

interface PaginationAction {
  type: string;
  error?: boolean;
  meta: PaginationActionMeta;
}

const ACTION_TYPE_PAGINATION_SET_ITEMS_PER_PAGE = 'PAGINATION_SET_ITEMS_PER_PAGE';

interface SetItemsPerPageAction<Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_SET_ITEMS_PER_PAGE;
  payload: {
    itemsPerPage: number;
    params: Params;
  };
}

const ACTION_TYPE_PAGINATION_SET_ITEMS_TOTAL = 'PAGINATION_SET_ITEMS_TOTAL';

interface SetItemsTotalAction<Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_SET_ITEMS_TOTAL;
  payload: {
    itemsTotal: number;
    params: Params;
  };
}

const ACTION_TYPE_PAGINATION_SET_PAGE = 'PAGINATION_SET_PAGE';

interface SetPageAction<Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_SET_PAGE;
  payload: {
    page: number;
    params: Params;
  };
}

const isSetPageAction = <Params,>(action: AnyAction): action is SetPageAction<Params> =>
  action.type === ACTION_TYPE_PAGINATION_SET_PAGE;

const ACTION_TYPE_PAGINATION_FETCH_ITEMS_SUCCESS = 'PAGINATION_FETCH_ITEMS_SUCCESS';

interface FetchItemsSuccessAction<Item, Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_FETCH_ITEMS_SUCCESS;
  payload: {
    page: number;
    perPage: number;
    items: Item[];
    params: Params;
  };
}

const isFetchItemsSuccessAction = <Item, Params>(action: AnyAction): action is FetchItemsSuccessAction<Item, Params> =>
  action.type === ACTION_TYPE_PAGINATION_FETCH_ITEMS_SUCCESS;

const ACTION_TYPE_PAGINATION_FETCH_ITEMS_FAILURE = 'PAGINATION_FETCH_ITEMS_FAILURE';

interface FetchItemsFailureAction<Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_FETCH_ITEMS_FAILURE;
  payload: Error;
  error: true;
  meta: PaginationActionMeta & {
    params: Params;
  };
}

const ACTION_TYPE_PAGINATION_RELOAD_CURRENT_PAGE = 'PAGINATION_RELOAD_CURRENT_PAGE';

interface ReloadCurrentPageAction<Params> extends PaginationAction {
  type: typeof ACTION_TYPE_PAGINATION_RELOAD_CURRENT_PAGE;
  payload: {
    params: Params;
  };
}

const isReloadCurrentPageAction = <Params,>(action: AnyAction): action is ReloadCurrentPageAction<Params> =>
  action.type === ACTION_TYPE_PAGINATION_RELOAD_CURRENT_PAGE;

type HandledAction<Item, Params> =
  | SetItemsPerPageAction<Params>
  | FetchItemsSuccessAction<Item, Params>
  | FetchItemsFailureAction<Params>
  | SetPageAction<Params>
  | SetItemsTotalAction<Params>
  | ReloadCurrentPageAction<Params>;

type ItemsFetcher<Item, Params, State> = (
  params: PaginationParams & Params,
  dispatch: Dispatch<State>,
) => Promise<Item[]>;

type Path = string | string[];

interface StorePathGetter<Params> {
  (params: Params): Path;
}

interface Selector<State, Item, Params> {
  (state: State, params: Params): ItemsStateWithPagination<Item>;
}

interface PaginationOptions {
  collectionId?: string | symbol;
  prefillFirstSlice?: boolean;
  sliceItems?: boolean;
}

const storePathGetterDefault = () => [];

function Pagination<ReduxState extends {}, Item, Params = undefined>(
  nameSpace: Path,
  itemsPerPageDefault: number,
  paramsToPath: StorePathGetter<Params> = storePathGetterDefault,
  options: PaginationOptions = {},
) {
  const {
    collectionId = Symbol(typeof nameSpace === 'string' ? nameSpace : undefined),
    prefillFirstSlice = true,
    sliceItems = true,
  } = options;

  const initialState: ItemsStateWithPagination<Item> = {
    itemsTotal: undefined,
    page: 1,
    itemsPerPage: itemsPerPageDefault,
    items: [],
    error: null,
  };

  const getFullPath = (params: Params) => {
    const path = paramsToPath(params);
    return [nameSpace, path].flat();
  };

  const selector: Selector<ReduxState, Item, Params> = (
    state: ReduxState,
    params: Params,
  ): ItemsStateWithPagination<Item> => {
    return get(state, getFullPath(params));
  };

  const getItems = (state: ReduxState, params: Params): NullableItems<Item> => {
    const { page, itemsPerPage, items, itemsTotal }: ItemsStateWithPagination<Item> =
      selector(state, params) || initialState;

    const firstItem = (page - 1) * itemsPerPage;

    if (typeof itemsTotal === 'undefined') {
      return prefillFirstSlice ? new Array(itemsPerPageDefault).fill(null) : [];
    }

    if (!sliceItems) {
      return items;
    }

    const sliceEnd = Math.min(page * itemsPerPage, itemsTotal);
    const slice: NullableItems<Item> = items.slice(firstItem, sliceEnd);

    times(sliceEnd - firstItem, (i) => {
      if (!slice[i]) {
        slice[i] = null;
      }
    });

    return slice;
  };

  const setPage = (page: number, params: Params): SetPageAction<Params> => ({
    type: 'PAGINATION_SET_PAGE',
    payload: { page, params },
    meta: { collectionId },
  });

  const setItemsPerPage = (itemsPerPage: number, params: Params): SetItemsPerPageAction<Params> => ({
    type: 'PAGINATION_SET_ITEMS_PER_PAGE',
    payload: { itemsPerPage, params },
    meta: { collectionId },
  });

  const setItemsTotal = (itemsTotal: number, params: Params): SetItemsTotalAction<Params> => ({
    type: 'PAGINATION_SET_ITEMS_TOTAL',
    payload: { itemsTotal, params },
    meta: { collectionId },
  });

  const onFetchItemsSuccess = (
    items: Item[],
    page: number,
    perPage: number,
    params: Params,
  ): FetchItemsSuccessAction<Item, Params> => ({
    type: 'PAGINATION_FETCH_ITEMS_SUCCESS',
    payload: { items, page, perPage, params },
    meta: { collectionId },
  });

  const onFetchItemsFailure = (error: Error, params: Params): FetchItemsFailureAction<Params> => ({
    type: 'PAGINATION_FETCH_ITEMS_FAILURE',
    payload: error,
    error: true,
    meta: { collectionId, params },
  });

  const reloadCurrentPage = (params: Params): ReloadCurrentPageAction<Params> => ({
    type: ACTION_TYPE_PAGINATION_RELOAD_CURRENT_PAGE,
    payload: { params },
    meta: { collectionId },
  });

  const isRelevantAction = (action: AnyAction): action is HandledAction<Item, Params> =>
    action.meta?.collectionId === collectionId;

  const getItemsFetcherMiddleware = (fetchPage: ItemsFetcher<Item, Params, ReduxState>): Middleware =>
    (({ getState, dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<ReduxState>) => (action: AnyAction): any =>
      tap(next(action), async () => {
        if (
          (isSetPageAction<Params>(action) || isReloadCurrentPageAction<Params>(action)) &&
          isRelevantAction(action)
        ) {
          const { params } = action.payload;
          const { page, itemsPerPage }: ItemsStateWithPagination<Item> = {
            ...initialState,
            ...selector(getState(), params),
          };
          try {
            const itemsForPage = await fetchPage({ page, perPage: itemsPerPage, ...params }, dispatch);
            dispatch(onFetchItemsSuccess(itemsForPage, page, itemsPerPage, params));
          } catch (error) {
            dispatch(onFetchItemsFailure(error, params));
          }
        }
      })) as Middleware;

  const paginationReducer = (
    state: ItemsStateWithPagination<Item>,
    action: AnyAction,
  ): ItemsStateWithPagination<Item> => {
    const handedAction = action as HandledAction<Item, Params>;
    switch (handedAction.type) {
      case 'PAGINATION_FETCH_ITEMS_SUCCESS': {
        const { items, page, perPage } = handedAction.payload;
        if (perPage !== state.itemsPerPage) {
          // pagination params has changed
          return state;
        }
        const nextItems = [...state.items];
        items.forEach((item, index) => {
          // TODO handle holes in the items array
          nextItems[(page - 1) * perPage + index] = item;
        });
        return {
          ...state,
          items: nextItems,
          error: null,
        };
      }
      case 'PAGINATION_FETCH_ITEMS_FAILURE': {
        return {
          ...state,
          error: handedAction.payload,
        };
      }
      case 'PAGINATION_SET_PAGE': {
        const { page } = handedAction.payload;
        return {
          ...state,
          page,
        };
      }
      case 'PAGINATION_SET_ITEMS_TOTAL': {
        const { itemsTotal } = handedAction.payload;
        return {
          ...state,
          itemsTotal,
        };
      }
      default:
        return state;
    }
  };

  interface WrappedReducer<FullState> {
    (prevState: FullState, action: AnyAction): FullState;
  }

  const getPaginationState = <FullState,>(state: FullState, params: Params): ItemsStateWithPagination<Item> => {
    const path = paramsToPath(params);
    if (typeof path === 'string') {
      return state[path];
    }
    if (path.length === 0) {
      return (state as unknown) as ItemsStateWithPagination<Item>;
    }
    return getRaw(state, path) as ItemsStateWithPagination<Item>;
  };

  const setPaginationState = <FullState,>(
    state: FullState,
    params: Params,
    paginationState: ItemsStateWithPagination<Item>,
  ): FullState => {
    const path = paramsToPath(params);
    if (typeof path === 'string') {
      const subState = state[path];
      return {
        ...state,
        [path]: {
          ...subState,
          ...paginationState,
        },
      };
    }
    return deepAssign<FullState>(state, path, paginationState);
  };

  const getParamsFromAction = (action: HandledAction<Item, Params>): Params => {
    switch (action.type) {
      case 'PAGINATION_FETCH_ITEMS_FAILURE':
        return action.meta.params;
      case 'PAGINATION_SET_PAGE':
      case 'PAGINATION_FETCH_ITEMS_SUCCESS':
      case 'PAGINATION_SET_ITEMS_PER_PAGE':
      case 'PAGINATION_SET_ITEMS_TOTAL':
      case 'PAGINATION_RELOAD_CURRENT_PAGE':
        return action.payload.params;
      default:
        return unhandledCase(action);
    }
  };

  const wrapReducer = <FullState,>(wrappedReducer: WrappedReducer<FullState>) => (
    prevState: FullState,
    action: AnyAction,
  ): FullState => {
    const nextState = wrappedReducer(prevState, action);
    if (isRelevantAction(action)) {
      if (paramsToPath === storePathGetterDefault) {
        const paginationState =
          typeof prevState === 'undefined'
            ? {
                ...initialState,
                ...nextState,
              }
            : nextState;
        return (paginationReducer(
          (paginationState as unknown) as ItemsStateWithPagination<Item>,
          action,
        ) as unknown) as FullState;
        // eslint-disable-next-line no-else-return
      } else {
          const params = getParamsFromAction(action);
          const prevPaginationState: ItemsStateWithPagination<Item> = getPaginationState<FullState>(prevState, params);
          const nextPaginationState = paginationReducer(
            typeof prevPaginationState === 'undefined' ? initialState : prevPaginationState,
            action,
          );
          if (nextPaginationState === prevPaginationState) {
            return nextState;
          }
          return setPaginationState<FullState>(nextState, params!, nextPaginationState);
      }
    }
    return nextState;
  };

  const usePaginatedItems = (params?: Params) =>
    useSelector<ReduxState, NullableItems<Item>>((state) => getItems(state, params!));

  const usePagination = (params?: Params): PaginationProps => {
    const { page, itemsPerPage, itemsTotal } = useSelector<ReduxState, PaginationState>(
      (state) => selector(state, params!) || initialState,
    );

    const dispatch = useDispatch();

    const pagesCount = itemsTotal && Math.ceil(itemsTotal / itemsPerPage);

    const dispatchSetPage = (pageIndex: number) => dispatch(setPage(pageIndex, params!));

    const prevPage = () => {
      if (page > 1) {
        dispatchSetPage(page - 1);
      }
    };

    const nextPage = () => {
      if (typeof pagesCount === 'undefined') {
        return;
      }
      if (page < pagesCount) {
        dispatchSetPage(page + 1);
      }
    };

    return {
      page,
      itemsPerPage,
      itemsTotal,
      pagesCount,
      setPage: dispatchSetPage,
      prevPage,
      nextPage,
      setItemsPerPage: (perPage: number) => dispatch(setItemsPerPage(perPage, params!)),
      setItemsTotal: (total: number) => dispatch(setItemsTotal(total, params!)),
    };
  };

  return {
    usePaginatedItems,
    usePagination,
    wrapReducer,
    getItemsFetcherMiddleware,
    reloadCurrentPage,
    isFetchItemsSuccessAction: (action: AnyAction): action is FetchItemsSuccessAction<Item, Params> =>
      isFetchItemsSuccessAction<Item, Params>(action) && isRelevantAction(action),
  };
}

export default Pagination;
