import { AnyAction } from 'redux';

import {
  ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST,
  ACTION_TYPE_GET_INTEGRATIONS_LIST_SUCCESS,
  ACTION_TYPE_GET_INTEGRATIONS_LIST_FAILURE,
} from './ActionTypes';
import { getIntegrationsListSuccessAction } from './ActionCreator';
import { IntegrationsListState } from './Types';

const initialState: IntegrationsListState = {
  integrations: [],
  error: null,
  isLoading: false,
};

const externalIntegrationsReducer = (state = initialState, action: AnyAction): IntegrationsListState => {
  switch (action.type) {
    case ACTION_TYPE_GET_INTEGRATIONS_LIST_SUCCESS: {
      const integrations = (action as getIntegrationsListSuccessAction).payload;

      return {
        ...state,
        integrations,
        error: null,
        isLoading: false,
      };
    }
    case ACTION_TYPE_GET_INTEGRATIONS_LIST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default externalIntegrationsReducer;
