import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductApplicationSetupState } from './ApplicationSetup/Types';
import { ApplicationsWorkflowSetupState } from './ApplicationsWorkflowSetup/Types';
import { ApplicationFormConfigurationState } from './ApplicationFormConfiguration/Types';

export enum ProductConfigurationStep {
  ApplicationFormConfiguration = 'application-form-configuration',
  ApplicationSetup = 'application-setup',
  WorkflowSetup = 'workflow-setup',
}

export interface LoanOriginationSystemProductConfigurationState {
  applicationSetup: LoanOriginationSystemProductApplicationSetupState;
  applicationWorkflowSetup: ApplicationsWorkflowSetupState;
  applicationFormConfiguration: ApplicationFormConfigurationState;
  product: Product | null;
  isProductUpdateInProgress: boolean;
}
