import PopUp from 'components/PopUps/PopUp';
import UploadFile from 'components/UploadFile/UploadFile';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CheckedIcon } from 'static/images';
import { closeCompanyLogoUploadDialog, uploadCompanyLogo } from './Actions';
import styles from './CompanyLogoUploadDialog.module.scss';
import Button from 'components/Button';
import PopUpContent from 'components/PopUps/PopUpContent';

interface CompanyLogoUploadDialogProps {
  isLoading: boolean;
}

const LOGO_UPLOAD_REQUIREMENTS = [
  'The following file formats are accepted: .svg, .png, .jpg, .gif',
  'Recommended image size: 320px × 120px',
  'Maximum file size: 10MB',
];

const TITLE = 'Upload Logo';

const renderRequirementsComponent = () => {
  return (
    <div className={styles.requirementsContainer}>
      {LOGO_UPLOAD_REQUIREMENTS.map((requirement) => {
        return (
          <div key={requirement} className={styles.requirement}>
            <CheckedIcon />
            <div className={styles.requirementText}>{requirement}</div>
          </div>
        );
      })}
    </div>
  );
};

const ACCEPT_FORMATS = '.svg, .png, .jpg, .gif';
const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 Mb

const CompanyLogoUploadDialog: FC<CompanyLogoUploadDialogProps> = ({ isLoading }) => {
  const [file, setFile] = useState<File | null>(null);
  const dispatch = useDispatch();

  const onSaveLogo = () => {
    dispatch(uploadCompanyLogo(file!));
  };

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  const handleClose = () => {
    dispatch(closeCompanyLogoUploadDialog());
  };

  return (
    <PopUp title={TITLE} closePopUp={handleClose} closable={!isLoading}>
      <PopUpContent hasTopMargin>
        <UploadFile file={file} onFileChange={handleFileChange} accept={ACCEPT_FORMATS} maxFileSize={MAX_FILE_SIZE} />
        {renderRequirementsComponent()}
        <div className={styles.buttonsContainer}>
          <Button kind="primary" size="form" disabled={!file} onClick={onSaveLogo} isLoading={isLoading}>
            Upload File
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default CompanyLogoUploadDialog;
