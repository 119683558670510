import React, { ChangeEvent } from 'react';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import StyledSwitch from 'components/StyledSwitch';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';
import styles from './ProductsDashboardHeader.module.scss';

interface ProductsDashboardHeaderProps {
  search: string;
  showArchived: boolean;
  onSearch: (search: string) => void;
  onAddProduct: () => void;
  onDownloadApiRequestTemplate: () => void;
  onChangeShowArchived: () => void;
  displayFilters?: boolean;
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test,
);

const ProductsDashboardHeader = ({
  search,
  onSearch,
  onAddProduct,
  onDownloadApiRequestTemplate,
  onChangeShowArchived,
  showArchived,
  displayFilters,
}: ProductsDashboardHeaderProps) => {
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => onSearch(event.target.value);
  const handleSearchClear = () => onSearch('');

  const renderSubtitle = () => {
    if (!displayFilters) {
      return null;
    }

    return (
      <div className={styles.subTitle}>
        <SearchInput
          placeholder="Search Products"
          value={search}
          onChange={handleSearchInputChange}
          onClear={handleSearchClear}
        />
        <div className={styles.showArchivedSwitchContainer}>
          <StyledSwitch
            color="primary"
            className={styles.switchButton}
            checked={showArchived}
            onChange={onChangeShowArchived}
          />
          <p>Show Archived</p>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2 className={styles.headerTitle}>Products</h2>
        <div className={styles.buttonsContainer}>
          <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiRequestTemplate} />
          <Button className={styles.addProductButton} kind="primary" onClick={onAddProduct}>
            Add Product
          </Button>
        </div>
      </div>
      {renderSubtitle()}
    </div>
  );
};

export default ProductsDashboardHeader;
