import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getApiActivationsRequest, SortApiActivationAction } from './ActionCreator';
import { ApiActivationActionType } from './ActionTypes';

const isSortApiActivationAction = (action: AnyAction): action is SortApiActivationAction =>
  action.type === ApiActivationActionType.SortStrategies;

const sortApiActivationMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortApiActivationAction(action)) {
    const { apiActivation } = getState();
    const { showArchived, searchInputValue, selectedUpdaters, versionsStatuses } = apiActivation;

    dispatch(
      getApiActivationsRequest({
        showArchived,
        searchInputValue,
        selectedUpdaters,
        versionsStatuses,
        sortingType: action.payload,
      }),
    );
  }

  return result;
}) as Middleware;

export default sortApiActivationMiddleware;
