import React, { FC } from 'react';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import SkeletonFieldsTableItem from './SkeletonFieldsTableItem';
import FieldTableRow, { FieldTableItem } from './FieldTableRow/FieldTableRow';
import TableColumnSize from './TableColumnSize';
import styles from './FieldsTable.module.scss';

export interface VariablesTableProps {
  items: FieldTableItem[] | null;
}

const DEFAULT_SKELETON_ITEMS_LENGTH = 5;

const FieldsTable: FC<VariablesTableProps> = ({ items }) => {
  const renderFieldsTableBody = () => {
    if (!items) {
      return (
        <>
          {new Array(DEFAULT_SKELETON_ITEMS_LENGTH).fill(null).map((item, index) => (
            <SkeletonFieldsTableItem key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        {items.map((item) => (
          <FieldTableRow key={item.fieldId} item={item} />
        ))}
      </>
    );
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSize.FieldName}>
            Variable Name
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSize.FieldValue}>
            Value
          </TableHeadCell>
        </TableHead>
        <TableBody>{renderFieldsTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default FieldsTable;
