import { AnyAction } from 'redux';
import { RunDecisionWizardActionType } from 'RunDecisionWizard/actionTypes';
import { RunDecisionWizardState } from 'RunDecisionWizard/types';

const initialState: RunDecisionWizardState = {
  isOpen: false,
  strategyList: [],
  applicationId: '',
  applicationProductId: '',
  inputs: [],
  applicationValues: {},
  applicationProductInputs: [],
  inputsLoading: false,
  productInputsLoading: false,
};

const runDecisionWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RunDecisionWizardActionType.OpenRunDecisionWizard: {
      return { ...initialState, isOpen: true, ...action.payload };
    }
    case RunDecisionWizardActionType.CloseRunDecisionWizard: {
      return { ...initialState };
    }
    case RunDecisionWizardActionType.GetStrategiesSuccess: {
      return { ...state, strategyList: action.payload };
    }
    case RunDecisionWizardActionType.GetApplicationStrategiesInputs: {
      return { ...state, inputsLoading: true };
    }
    case RunDecisionWizardActionType.GetApplicationStrategiesInputsSuccess: {
      return { ...state, inputsLoading: false, ...action.payload };
    }
    case RunDecisionWizardActionType.ResetApplicationStrategiesInputs: {
      return { ...state, inputsLoading: false, inputs: [] };
    }
    case RunDecisionWizardActionType.GetApplicationProductVariables: {
      return { ...state, productInputsLoading: true, applicationProductInputs: [] };
    }
    case RunDecisionWizardActionType.GetApplicationProductVariablesSuccess: {
      return { ...state, productInputsLoading: false, applicationProductInputs: [...action.payload] };
    }
    case RunDecisionWizardActionType.GetApplicationProductVariablesFailure: {
      return { ...state, productInputsLoading: false, applicationProductInputs: [] };
    }
    default: {
      return state;
    }
  }
};

export default runDecisionWizardReducer;
