export enum ApplicationNotesActionType {
  GetApplicationNotes = 'applicationNotes/getApplicationNotes',
  GetApplicationNotesSuccess = 'applicationNotes/getApplicationNotesSuccess',
  GetApplicationNotesFailure = 'applicationNotes/getApplicationNotesFailure',
  OpenApplicationNotePopup = 'applicationNotes/openApplicationNotePopup',
  CloseApplicationNotePopup = 'applicationNotes/closeApplicationNotePopup',
  AddApplicationNote = 'applicationNotes/addApplicationNote',
  AddApplicationNoteSuccess = 'applicationNotes/addApplicationNoteSuccess',
  AddApplicationNoteFailure = 'applicationNotes/addApplicationNoteFailure',
  DeleteApplicationNote = 'applicationNotes/deleteApplicationNote',
  DeleteApplicationNoteSuccess = 'applicationNotes/deleteApplicationNoteSuccess',
  DeleteApplicationNoteFailure = 'applicationNotes/deleteApplicationNoteFailure',
  UpdateApplicationNote = 'applicationNotes/updateApplicationNote',
  UpdateApplicationNoteSuccess = 'applicationNotes/updateApplicationNoteSuccess',
  UpdateApplicationNoteFailure = 'applicationNotes/updateApplicationNoteFailure',
  SetSearchValue = 'applicationNotes/setSearchValue',
  SortApplicationNotes = 'applicationNotes/sortApplicationNotes',
  SetCurrentApplicationNote = 'applicationNotes/setCurrentApplicationNote',
  ResetState = 'applicationNotes/resetState',
}
