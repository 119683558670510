import React, { FC } from 'react';
import { openAddRulePopUp } from 'RuleCreate/ActionCreator';
import { getBranchInfoRequest } from 'BranchInfo/ActionCreator';
import { RuleData, RuleType, BranchInfo } from 'BranchInfo/Types';
import RulesModule from 'components/RulesModule';
import { getModuleType } from 'pages/Strategy/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateScoringRuleSegment } from 'ScoringModel/ActionCreator';
import { ScoringModelRequestData, ScoringModelState } from 'ScoringModel/Types';
import { ReduxState } from 'types/redux';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import { EntityType } from 'RuleCreate/Types';
import { useQueryParams } from 'hooks/useQueryParam';

interface RulesModuleContainerTypes {
  strategyId: string;
  isStrategyLocked: boolean;
  branchInfo: BranchInfo | null;
  name: string;
  onClose: () => void;
  multipleBranches: boolean;
  moduleKey: string;
  branchIndex: number;
  handleOpenDownloadPage: (population?: string) => void;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  onOpenDeleteRulePopUp: (ruleIndex: number, currentEntityType: EntityType) => void;
  onOpenEditRulePopUp: (data: RuleEditPopUpData) => void;
  isLoading: boolean;
  rulesBlockingActionInProgress: boolean;
  conditionsBlockingActionInProgress: boolean;
}

const RulesModuleContainer: FC<RulesModuleContainerTypes> = ({
  strategyId,
  isStrategyLocked,
  branchInfo,
  multipleBranches,
  onClose,
  moduleKey,
  handleOpenDownloadPage,
  handleRuleReorder,
  onOpenDeleteRulePopUp,
  onOpenEditRulePopUp,
  isLoading,
  rulesBlockingActionInProgress,
  conditionsBlockingActionInProgress,
}) => {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const branchIndex = parseInt(params.get('branchIndex')!, 10);
  const moduleType = getModuleType(moduleKey);

  const { rules, conditions, name } = branchInfo || {};

  const { entityType } = useSelector((state: ReduxState) => state.ruleCreate);

  const { initialScore, outputVariable } = useSelector<ReduxState, ScoringModelState>(
    ({ scoringModel }) => scoringModel,
  );

  const getBranchRules = () => {
    if (moduleKey) {
      dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex }));
    }
  };

  const onUpdateScoringRuleSegment = (data: ScoringModelRequestData) => {
    const requestData = { ...data, moduleKey, branchIndex, strategyId, entityType };
    dispatch(updateScoringRuleSegment(requestData));
  };

  const handleAddBranchCondition = () => {
    dispatch(
      openAddRulePopUp({
        entityType: 'branch',
        moduleType,
      }),
    );
  };

  const handleAddRule = () => {
    dispatch(openAddRulePopUp({ entityType: 'module', moduleType }));
  };

  return (
    <RulesModule
      rulesList={rules || []}
      conditionsList={conditions || []}
      name={name || ''}
      initialScore={initialScore || ''}
      outputVariable={outputVariable || ''}
      updateSegment={onUpdateScoringRuleSegment}
      addBranchCondition={handleAddBranchCondition}
      addRule={handleAddRule}
      branchIndex={branchIndex}
      moduleId={moduleKey}
      strategyId={strategyId}
      isStrategyLocked={isStrategyLocked}
      getBranchRules={getBranchRules}
      handleOpenDownloadPage={handleOpenDownloadPage}
      multipleBranches={multipleBranches}
      onClose={onClose}
      moduleType={moduleType}
      openDeleteRulePopUp={onOpenDeleteRulePopUp}
      handleRuleReorder={handleRuleReorder}
      onOpenEditRulePopUp={onOpenEditRulePopUp}
      isLoading={isLoading}
      rulesBlockingActionInProgress={rulesBlockingActionInProgress}
      conditionsBlockingActionInProgress={conditionsBlockingActionInProgress}
    />
  );
};

export default RulesModuleContainer;
