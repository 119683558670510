import { AnyAction } from 'redux';

import { GET_DECISION_STRATEGY_UPDATES_REQUEST_SUCCESS } from './ActionTypes';
import { DecisionStrategyUpdatesState } from './DecisionStrategiesTypes';
import { decisionStrategyUpdatesPagination, STRATEGY_UPDATES_PER_PAGE_DEFAULT } from 'pages/Strategy/Pagination';
import { GetDecisionStrategyUpdatesSuccessAction } from './DecisionStrategyUpdatesActionCreator';

const decisionStrategyUpdatesReducer = (
  state: DecisionStrategyUpdatesState = {},
  action: AnyAction,
): DecisionStrategyUpdatesState => {
  switch (action.type) {
    case GET_DECISION_STRATEGY_UPDATES_REQUEST_SUCCESS: {
      const { strategyId, ...strategyUpdates } = (action as GetDecisionStrategyUpdatesSuccessAction).payload;

      return {
        ...state,
        [strategyId]: {
          ...strategyUpdates,
          page: 1,
          itemsPerPage: STRATEGY_UPDATES_PER_PAGE_DEFAULT,
          error: '',
        },
      };
    }
    default:
      return state;
  }
};

export default decisionStrategyUpdatesPagination.wrapReducer<DecisionStrategyUpdatesState>(
  decisionStrategyUpdatesReducer,
);
