import React, { FC } from 'react';
import styles from './CoBorrowerInfoTooltipContent.module.scss';

interface CoBorrowerInfoTooltipContentProps {
  coborrowerName: string;
}

const CoBorrowerInfoTooltipContent: FC<CoBorrowerInfoTooltipContentProps> = ({ coborrowerName }) => {
  return (
    <div>
      <p className={styles.name}>{coborrowerName}</p>
      <p className={styles.description}>Co-Borrower</p>
    </div>
  );
};

export default CoBorrowerInfoTooltipContent;
