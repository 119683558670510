import React, { MouseEvent } from 'react';
import clsx from 'clsx';
import { RemoveTabImage, DragImage } from 'static/images';
import styles from './TabListItem.module.scss';

export interface TabListItemProps {
  id: string;
  name: string;
  onSelect: (id: string) => void;
  onDelete: (id: string) => void;
  selected: boolean;
  isDragging: boolean;
}

const TabListItem = ({ name, id, onDelete, onSelect, isDragging, selected }: TabListItemProps) => {
  const handleDelete = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onDelete(id);
  };

  const handleClick = () => {
    onSelect(id);
  };

  return (
    <div
      onClick={handleClick}
      className={clsx(styles.tab, { [styles.dragging]: isDragging, [styles.selected]: selected })}
    >
      <div className={styles.tabItemContent}>
        {name}
        <div className={styles.dragImage}>
          <DragImage />
        </div>
        <div className={styles.closeImage} onClick={handleDelete}>
          <RemoveTabImage />
        </div>
      </div>
    </div>
  );
};

export default TabListItem;
