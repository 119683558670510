import React, { FC, useState } from 'react';
import styles from './EditExternalIntegrationVariables.module.scss';
import { EditExternalIntegrationVariablesPopUpState } from 'EditExternalIntegrationVariablesPopUp/Types';
import InputWithVariables from './InputWithVariables';
import { ExternalIntegrationInputsList } from 'api/DecisionEngineStrategiesType';
import Button from 'components/Button';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import PopUpContent from 'components/PopUps/PopUpContent';
import { isEqual } from 'lodash';

interface EditExternalIntegrationVariablesProps {
  externalIntegrationInfo: EditExternalIntegrationVariablesPopUpState;
  editExternalIntegration: (inputList: ExternalIntegrationInputsList[]) => void;
  openVariableCreation: () => void;
  isLoading: boolean;
}

const EditExternalIntegrationVariables: FC<EditExternalIntegrationVariablesProps> = ({
  externalIntegrationInfo,
  editExternalIntegration,
  openVariableCreation,
  isLoading,
}) => {
  const [inputList, setInputList] = useState(externalIntegrationInfo.inputList);

  const updateInput = (number: number, inputType: ComparisonOperandType, value: string) => {
    const visibleInputList = inputList.map((input, index) => {
      if (number === index) {
        return {
          ...input,
          inputType,
          variable: value,
        };
      }
      return input;
    });

    setInputList(visibleInputList);
  };

  const isButtonDisabled = isEqual(externalIntegrationInfo.inputList, inputList);

  return (
    <PopUpContent>
      {inputList.map((input, index) => {
        const { name, example, variable, type, inputType, description } = input;
        return (
          <InputWithVariables
            name={name}
            example={example}
            variable={variable}
            initialVariable={externalIntegrationInfo.inputList[index].variable}
            type={type}
            inputType={inputType}
            initialInputType={externalIntegrationInfo.inputList[index].inputType}
            key={index}
            updateInput={updateInput}
            id={index}
            description={description}
            openVariableCreation={openVariableCreation}
          />
        );
      })}
      <Button
        kind="primary"
        size="form"
        className={styles.saveButton}
        onClick={() => editExternalIntegration(inputList)}
        disabled={isButtonDisabled}
        isLoading={isLoading}
      >
        Save
      </Button>
    </PopUpContent>
  );
};

export default EditExternalIntegrationVariables;
