import {
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES,
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE,
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS,
  ASSIGN_OUTPUT_VARIABLE,
  ASSIGN_OUTPUT_VARIABLE_FAILURE,
  ASSIGN_OUTPUT_VARIABLE_SUCCESS,
  CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP,
  CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
  OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP,
  OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  CompleteGetExternalIntegrationRequestData,
  CompleteGetExternalIntegrationResponseData,
  OutputData,
} from 'api/DecisionEngineStrategiesType';

export interface OpenEditExternalIntegrationVariablesPopUpAction {
  type: typeof OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP;
}

export interface CloseEditExternalIntegrationVariablesPopUpAction {
  type: typeof CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP;
}

export interface GetExternalIntegrationVariablesAction {
  type: typeof ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES;
  payload: CompleteGetExternalIntegrationRequestData;
}

export interface GetExternalIntegrationVariablesSuccessAction {
  type: typeof ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS;
  payload: CompleteGetExternalIntegrationResponseData;
}

export interface OpenAssignOutputVariablePopUpAction {
  type: typeof OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP;
  payload: string;
}

export interface CloseAssignOutputVariablePopUpAction {
  type: typeof CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP;
}

export interface AssignOutputVariableAction {
  type: typeof ASSIGN_OUTPUT_VARIABLE;
  payload: {
    strategyId: string;
    outputIndex: number;
    variableId: string;
    moduleName: string;
  };
}

export interface AssignOutputVariableSuccessAction {
  type: typeof ASSIGN_OUTPUT_VARIABLE_SUCCESS;
  payload: OutputData;
}

export const openEditExternalIntegrationVariablesPopUp = (): OpenEditExternalIntegrationVariablesPopUpAction => ({
  type: OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
});

export const closeEditExternalIntegrationVariablesPopUp = (): CloseEditExternalIntegrationVariablesPopUpAction => ({
  type: CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
});

export const openAssignOutputVariablePopUp = (assignedOutput: string): OpenAssignOutputVariablePopUpAction => ({
  type: OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP,
  payload: assignedOutput,
});

export const closeAssignOutputVariablePopUp = (): CloseAssignOutputVariablePopUpAction => ({
  type: CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP,
});

export const assignOutputVariable = (
  strategyId: string,
  outputIndex: number,
  variableId: string,
  moduleName: string,
): AssignOutputVariableAction => ({
  type: ASSIGN_OUTPUT_VARIABLE,
  payload: {
    strategyId,
    outputIndex,
    variableId,
    moduleName,
  },
});

export const assignOutputVariableSuccess = (outputData: OutputData): AssignOutputVariableSuccessAction => ({
  type: ASSIGN_OUTPUT_VARIABLE_SUCCESS,
  payload: outputData,
});

export const assignOutputVariableError = FailedActionCreator(ASSIGN_OUTPUT_VARIABLE_FAILURE);

export const getExternalIntegrationVariables = (
  payload: CompleteGetExternalIntegrationRequestData,
): GetExternalIntegrationVariablesAction => ({
  type: ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES,
  payload,
});

export const getExternalIntegrationVariablesSuccess = (
  payload: CompleteGetExternalIntegrationResponseData,
): GetExternalIntegrationVariablesSuccessAction => ({
  type: ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS,
  payload,
});

export const getExternalIntegrationVariablesError = FailedActionCreator(
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE,
);
