import { IndividualRunInputs } from 'api/Types';
import APIRest from 'api/APIRest';
import { ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';

export interface ApplicationDecisionRunInputsLegacyApiRest {
  getApplicationStrategyInputs(applicationId: string, strategyId: string): Promise<ApplicationStrategyInputs>;
}

export interface GetStrategyInputsResponseData {
  compiledStrategy: {
    input_variables: ProcessingStrategyInput[];
  };
  application: {
    variables: IndividualRunInputs;
    product: string;
  };
  product: {
    variables: string[];
  };
}

export interface ApplicationStrategyInputs {
  inputs: ProcessingStrategyInput[];
  applicationValues: IndividualRunInputs;
  applicationProductId: string;
}

export default class ApplicationDecisionRunInputsLegacyApi extends APIRest
  implements ApplicationDecisionRunInputsLegacyApiRest {
  protected resourceName = 'applications/decision-runs';

  protected origin = process.env.REACT_APP_DE_API_URL;

  async getApplicationStrategyInputs(applicationId: string, strategyId: string) {
    const { application, compiledStrategy } = await this.fetch<GetStrategyInputsResponseData>(
      `/los/api/applications/${applicationId}/run_automation/decision/${strategyId}`,
    );

    return {
      inputs: compiledStrategy.input_variables,
      applicationValues: application.variables,
      applicationProductId: application.product,
    };
  }
}
