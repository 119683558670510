import React, { FC, useRef, useState } from 'react';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import Button from 'components/Button';
import { CheckedImage } from 'static/images';
import styles from './DropdownButton.module.scss';
import { ButtonType } from 'components/Button/Button';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

export interface DropdownButtonOption {
  id: string;
  text: string;
}

interface DropdownButtonProps {
  options: DropdownButtonOption[];
  onSelect: (option: DropdownButtonOption) => void;
  selectedOption: DropdownButtonOption | null;
  className?: string;
  buttonClassName?: string;
  kind?: ButtonType;
  active?: boolean;
  popupClassName?: string;
  tooltip?: string;
}

const DropdownButton: FC<DropdownButtonProps> = ({
  selectedOption,
  children,
  options,
  className,
  buttonClassName,
  onSelect,
  kind = 'with-state',
  popupClassName,
  tooltip,
}) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onItemClick = (option: DropdownButtonOption) => {
    setOpen(false);
    onSelect(option);
  };

  return (
    <div className={className} ref={containerRef}>
      <WrapperWithTooltip tooltip={tooltip}>
        <div>
          <Button
            kind={kind}
            type="button"
            onClick={() => setOpen(true)}
            className={clsx(styles.button, buttonClassName)}
            active={open}
          >
            {children}
          </Button>
        </div>
      </WrapperWithTooltip>
      <ContextualPopUp
        anchorEl={containerRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        anchorPosition={{ top: 2, left: 0 }}
        classes={{ paper: styles.popupRoot }}
      >
        <ActionPopUp className={popupClassName} onClose={() => setOpen(false)} hideTitle>
          {options.map((option) => (
            <ActionPopUpItem className={styles.actionItem} key={option.id} onClick={() => onItemClick(option)}>
              {option.text}
              {option.id === selectedOption?.id && <CheckedImage className={styles.checkIcon} />}
            </ActionPopUpItem>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </div>
  );
};

export default DropdownButton;
