import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import styles from './FormFooterLink.module.scss';

interface FormFooterLinkProps {
  text: string;
  linkText: string;
  to: string;
  className?: string;
}

const FormFooterLink: FC<FormFooterLinkProps> = ({ text, linkText, to, className }) => {
  return (
    <div className={clsx(styles.linkContainer, className)}>
      <p className={styles.text}>{text}</p>
      <Link to={to} className={styles.link}>
        {linkText}
      </Link>
    </div>
  );
};

export default FormFooterLink;
