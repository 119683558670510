import Fetcher from 'middlewares/Fetcher';
import { ESignTemplatesApi, ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { ESignTemplatesActionType } from './ActionTypes';
import { GetESignTemplateAction, getESignTemplateFailure, getESignTemplateSuccess } from './ActionCreator';

const GetESignTemplateMiddleware = (api: ESignTemplatesApi) =>
  Fetcher<ESignTemplate, GetESignTemplateAction>(
    ESignTemplatesActionType.GetESignTemplate,
    getESignTemplateSuccess,
    getESignTemplateFailure,
    (action) => {
      return api.findById(action.payload.id);
    },
  );

export default GetESignTemplateMiddleware;
