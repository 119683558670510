export enum ModuleTypeName {
  requirements = 'Requirements Rules',
  assignments = 'Simple Outputs',
  calculations = 'Calculation Script',
  scorecard = 'Scoring Model',
  output = 'Rule-Based Outputs',
  dataintegration = 'External Integration',
}

export enum RulesModuleDescriptions {
  requirements = 'This module passes if all rules pass. If any rule fails, \n the corresponding Decline Reason will be recorded.',
  assignments = 'This module assigns values to the selected output variables.',
  calculations = 'This module runs calculation scripts in order and \n assigns results to the selected output variables.',
  scorecard = 'This module adds the Initial Score plus Weights \n and assigns this output to the selected variable.',
  output = 'This module runs rules in order and assigns outputs \n to variables when the corresponding rules pass.',
}

export enum RulesModuleCreateButtons {
  requirements = 'Add Requirements Rule',
  assignments = 'Add Output',
  calculations = 'Add Calculation Script',
  scorecard = 'Add Scoring Rule',
  output = 'Add Rule-Based Output',
}
