import React, { FC } from 'react';
import { ESignTemplate } from 'api/Core/ESignTemplatesApi';
import TableColumnSizes from 'components/DocuSign/RecipientsTable/TableColumnSizes';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import RecipientTableRow from './RecipientTableRow';
import SkeletonRecipientItem from './SkeletonRecipientItem';
import styles from './RecipientsTable.module.scss';

interface FieldsTableProps {
  template?: ESignTemplate | null;
}

const SKELETON_ITEMS_DEFAULT = 5;

const RecipientsTable: FC<FieldsTableProps> = ({ template }) => {
  const renderRecipientsTableBody = () => {
    if (!template) {
      return (
        <>
          {new Array(SKELETON_ITEMS_DEFAULT).fill(null).map((item, index) => (
            <SkeletonRecipientItem key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        {template.recipients.map((recipient) => (
          <RecipientTableRow
            recipient={recipient}
            key={recipient.recipientId}
            recipientsMapping={template.recipientsMapping}
          />
        ))}
      </>
    );
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Recipient}>
            Recipient
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Variable}>
            DigiFi entity
          </TableHeadCell>
        </TableHead>
        <TableBody>{renderRecipientsTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default RecipientsTable;
