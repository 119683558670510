import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemTasksApi, Task } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { CreateTaskAction, createTaskFailure, createTaskSuccess } from './ActionCreator';
import { LoanOriginationSystemCreateTaskActionTypes } from './ActionTypes';

const CreateTaskMiddleware = (api: LoanOriginationSystemTasksApi) =>
  Fetcher<Task, CreateTaskAction>(
    LoanOriginationSystemCreateTaskActionTypes.CreateTask,
    createTaskSuccess,
    createTaskFailure,
    (action) => {
      return api.createTask(action.payload.data);
    },
  );

export default CreateTaskMiddleware;
