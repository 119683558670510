import React, { FC } from 'react';
import styles from './DecisionProcess.module.scss';
import ModuleList from './ModuleList';
import LoadingMask from 'components/LoadingMask';
import { CheckedImage, StartProcessIcon } from 'static/images';
import AddModuleButton from './AddModuleButton';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'hooks/useQueryParam';
import { StrategyModule } from 'DecisionStrategy/DecisionStrategiesTypes';
import { ModuleListProps } from 'components/StrategyOverview/DecisionProcess/ModuleList/ModuleList';

export interface DecisionProcessProps {
  strategyId: string;
  isStrategyLocked: boolean;
  openDeleteModulePopUp: (moduleLookupName: string, moduleIndex: number) => void;
  currentModuleKey: string;
  currentBranchIndex: number;
  collapsedModuleList: boolean[];
  changeModuleCollapseState: (moduleIndex: number, isCollapsed: boolean) => void;
  isLoading: boolean;
  blockingActionInProgress: boolean;
  moduleList: StrategyModule[];
  onEditModuleBranch: ModuleListProps['onEditModuleBranch'];
}

const DecisionProcess: FC<DecisionProcessProps> = ({
  strategyId,
  isStrategyLocked,
  openDeleteModulePopUp,
  currentModuleKey,
  currentBranchIndex,
  collapsedModuleList,
  changeModuleCollapseState,
  isLoading,
  blockingActionInProgress,
  moduleList,
  onEditModuleBranch,
}) => {
  const history = useHistory();
  const params = useQueryParams();
  const addedModuleIndex = params.get('module-index')!;

  const onAddNewModule = (index: number) => history.replace(`overview?new-module&module-index=${index}`);

  return (
    <div className={styles.decisionProcess}>
      {blockingActionInProgress && <LoadingMask background="dark" transparent />}
      <div className={styles.startProcessContainer}>
        <div className={styles.startProcess}>
          <StartProcessIcon />
          Start Process
        </div>
        <AddModuleButton
          onClick={() => onAddNewModule(0)}
          blockingActionInProgress={blockingActionInProgress}
          isActive={addedModuleIndex === '0'}
          isLoading={isLoading && !moduleList.length}
        />
      </div>
      <div className={clsx(blockingActionInProgress && styles.moduleListContainer)}>
        {!isLoading || moduleList.length ? (
          <ModuleList
            strategyId={strategyId}
            isStrategyLocked={isStrategyLocked}
            openDeleteModulePopUp={openDeleteModulePopUp}
            currentModuleKey={currentModuleKey}
            currentBranchIndex={currentBranchIndex}
            collapsedModuleList={collapsedModuleList}
            changeModuleCollapseState={changeModuleCollapseState}
            onAddNewModule={onAddNewModule}
            addedModuleIndex={addedModuleIndex}
            onEditModuleBranch={onEditModuleBranch}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={styles.endProcess}>
        <CheckedImage />
        End Process
      </div>
    </div>
  );
};

export default DecisionProcess;
