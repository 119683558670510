import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  updateApplicationTeamMembersFailure,
  updateApplicationTeamMembersSuccess,
  UpdateApplicationTeamMembersAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationTeamMembersMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationTeamMembersAction>(
    ApplicationPageActionType.UpdateApplicationTeamMembers,
    updateApplicationTeamMembersSuccess,
    updateApplicationTeamMembersFailure,
    (action) => {
      return api.updateApplicationTeamMembers(action.payload.applicationId, action.payload.teamMembers);
    },
  );

export default UpdateApplicationTeamMembersMiddleware;
