import { BorrowerVariableConfiguration, BulkUpdateVariableConfigurationParams } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerType } from './LoanOriginationSystemBorrowersApi';

export interface CreateBorrowerVariableConfigurationParams {
  column: number;
  position: number;
  borrowerType: BorrowerType;
  variable: string;
}

export type UpdateBorrowerVariableConfigurationParams = Partial<
  Omit<CreateBorrowerVariableConfigurationParams, 'variable' | 'borrowerType'>
>;

export interface BorrowerVariableConfigurationsApi {
  getAll(borrowerType: BorrowerType, productId?: string): Promise<BorrowerVariableConfiguration[]>;
  create(params: CreateBorrowerVariableConfigurationParams): Promise<BorrowerVariableConfiguration>;
  bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]): Promise<void>;
  update(id: string, params: UpdateBorrowerVariableConfigurationParams): Promise<BorrowerVariableConfiguration>;
  delete(id: string): Promise<void>;
}

export default class BorrowerVariableConfigurationsRestApi
  extends LoanOriginationSystemApi<BorrowerVariableConfiguration>
  implements BorrowerVariableConfigurationsApi {
  protected resourceName = 'borrower-variable-configurations';

  public getAll(borrowerType: BorrowerType, productId?: string) {
    const params = new URLSearchParams();

    params.set('borrowerType', borrowerType);

    if (productId) {
      params.set('productId', productId);
    }

    return this.getResources<BorrowerVariableConfiguration[]>(params);
  }

  public create(params: CreateBorrowerVariableConfigurationParams) {
    return this.createResource({
      variableConfiguration: params,
    });
  }

  public update(id: string, params: UpdateBorrowerVariableConfigurationParams) {
    return this.updateResource(id, {
      variableConfiguration: params,
    });
  }

  public bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]): Promise<void> {
    return this.fetch(`/${this.resourceName}/`, 'PUT', {
      configurations,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
