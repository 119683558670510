import React, { FC } from 'react';
import styles from './ProductRow.module.scss';
import SIZES from 'components/Permissions/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import {
  PermissionsUpdatingState,
  Product,
  ProductPermissions,
  ProductSection,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import CustomCheckbox from 'components/CustomCheckbox';
import LoaderWithState from 'components/LoaderWithState';

const PRODUCT_TITLES = {
  [Product.LoanOriginationSystem]: 'Loan Origination System',
  [Product.DecisionEngine]: 'Decision Engine',
  [Product.CompanySettings]: 'Company Settings',
};

const canChangePermissions = (userRole: UserRoleName, productSection: ProductSection) => {
  if (userRole === UserRoleName.Owner) {
    return false;
  }
  switch (productSection) {
    case ProductSection.CompanySettingsTeam:
    case ProductSection.CompanySettingsPlanAndBilling:
      return false;
    default:
      return true;
  }
};

const SECTION_TITLE: Record<ProductSection, string> = {
  [ProductSection.LoanOriginationSystemApplications]: 'Applications',
  [ProductSection.LoanOriginationSystemBorrowers]: 'Borrowers',
  [ProductSection.LoanOriginationSystemIntermediaries]: 'Intermediaries',
  [ProductSection.LoanOriginationSystemTasks]: 'Tasks',
  [ProductSection.LoanOriginationSystemEmails]: 'Emails',
  [ProductSection.LoanOriginationSystemReporting]: 'Reporting',
  [ProductSection.LoanOriginationSystemConfiguration]: 'Configuration',
  [ProductSection.DecisionEngineStrategies]: 'Strategies',
  [ProductSection.DecisionEngineProcessing]: 'Processing',
  [ProductSection.DecisionEngineApiActivation]: 'API Activation',
  [ProductSection.DecisionEngineReporting]: 'Reporting',
  [ProductSection.CompanySettingsGeneral]: 'General',
  [ProductSection.CompanySettingsPlanAndBilling]: 'Plan & Billing',
  [ProductSection.CompanySettingsTeam]: 'Team',
  [ProductSection.CompanySettingsVariables]: 'Variables',
  [ProductSection.CompanySettingsIntegrations]: 'Integrations',
};

const USER_ROLE_LABELS = {
  [UserRoleName.User]: 'User',
  [UserRoleName.Admin]: 'Admin',
  [UserRoleName.Owner]: 'Owner',
};

export interface ProductRowProps {
  productPermissions: ProductPermissions;
  permissionsUpdatingState: PermissionsUpdatingState;
  updatePermissions: (userRole: UserRoleName, productSection: ProductSection, value: boolean) => void;
  resetPermissionsUpdatingState: (userRole: UserRoleName, productSection: ProductSection) => void;
}

const ProductRow: FC<ProductRowProps> = (props) => {
  const { productPermissions, permissionsUpdatingState, updatePermissions, resetPermissionsUpdatingState } = props;

  return (
    <div className={styles.productRow}>
      {productPermissions.sections.map(({ productSection, ...section }, index) => (
        <TableRow disableHover className={styles.anyRow} key={productSection}>
          <TableBodyCell width={SIZES.PRODUCT} className={styles.productTitle}>
            <p>{index === 0 ? PRODUCT_TITLES[productPermissions.product] : ''}</p>
          </TableBodyCell>
          <TableBodyCell className={styles.sectionCell} width={SIZES.SECTION}>
            {SECTION_TITLE[productSection]}
          </TableBodyCell>
          {[UserRoleName.User, UserRoleName.Admin, UserRoleName.Owner].map((userRole) => (
            <TableBodyCell className={styles.sectionCell} width={SIZES.PERMISSION} key={userRole} noPadding>
              {!permissionsUpdatingState[userRole][productSection] ? (
                <CustomCheckbox
                  checked={section[userRole]}
                  onClick={() => updatePermissions(userRole, productSection, !section[userRole])}
                  disabled={!canChangePermissions(userRole, productSection)}
                  className={styles.checkbox}
                />
              ) : (
                <LoaderWithState
                  state={permissionsUpdatingState[userRole][productSection]}
                  onStateReset={() => resetPermissionsUpdatingState(userRole, productSection)}
                  useOnlyUpdatingState
                />
              )}
              <p className={styles.userRole}>{USER_ROLE_LABELS[userRole]}</p>
            </TableBodyCell>
          ))}
        </TableRow>
      ))}
    </div>
  );
};

export default ProductRow;
