import Fetcher from 'middlewares/Fetcher';
import { DocuSignImportApi } from 'api/Core/DocuSignImportApi';
import { DocuSignActionType } from './ActionTypes';
import {
  ImportDocuSignTemplatesAction,
  importDocuSignTemplatesFailure,
  importDocuSignTemplatesSuccess,
} from './ActionCreator';

const ImportDocuSignTemplatesMiddleware = (api: DocuSignImportApi) =>
  Fetcher<void, ImportDocuSignTemplatesAction>(
    DocuSignActionType.ImportDocuSignTemplates,
    importDocuSignTemplatesSuccess,
    importDocuSignTemplatesFailure,
    (action) => {
      return api.importBatch(action.payload.templates);
    },
  );

export default ImportDocuSignTemplatesMiddleware;
