export const getAbbreviatedTextLine = (textLine: string, maxLength: number) => {
  const MAX_TEXT_LINE_LENGTH = maxLength;
  const TEXT_LINE_FIRST_PART_LENGTH = MAX_TEXT_LINE_LENGTH / 2;
  const TEXT_LINE_SECOND_PART_LENGTH = MAX_TEXT_LINE_LENGTH / 2 - 3;

  if (textLine.length < MAX_TEXT_LINE_LENGTH) {
    return textLine;
  }

  const textLineFirstPart = textLine.slice(0, TEXT_LINE_FIRST_PART_LENGTH);
  const textLineSecondPart = textLine.slice(textLine.length - TEXT_LINE_SECOND_PART_LENGTH);

  return `${textLineFirstPart}...${textLineSecondPart}`;
};
