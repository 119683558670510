import Fetcher from 'middlewares/Fetcher';
import { GetVariablesByIdAction, getVariablesByIdFailure, getVariablesByIdSuccess } from './Actions';
import { VariablesAttributesActionTypes } from './ActionTypes';
import { Variable } from 'Variables/VariablesTypes';
import { VariablesApi } from 'api/Core/VariablesApi';

const GetVariablesByIdMiddleware = (api: VariablesApi) =>
  Fetcher<Variable[], GetVariablesByIdAction>(
    VariablesAttributesActionTypes.GetVariables,
    getVariablesByIdSuccess,
    getVariablesByIdFailure,
    async ({ payload }) => {
      const { items } = await api.getVariables({ ids: payload, includeArchived: true });
      return items;
    },
  );

export default GetVariablesByIdMiddleware;
