import { AnyAction } from 'redux';
import { CLOSE_EDIT_RULE_POP_UP, OPEN_EDIT_RULE_POP_UP } from './ActionTypes';
import { RuleEditState } from './Types';

const initialState: RuleEditState = {
  ruleModuleType: null,
  ruleType: null,
  id: null,
  entityType: null,
};

const RuleEditReducer = (state = initialState, action: AnyAction): RuleEditState => {
  switch (action.type) {
    case OPEN_EDIT_RULE_POP_UP: {
      const { ruleModuleType, ruleType, id, entityType } = action.payload;
      return { ruleModuleType, ruleType, id, entityType };
    }
    case CLOSE_EDIT_RULE_POP_UP: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default RuleEditReducer;
