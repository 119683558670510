import { DUPLICATE_MODULE, DUPLICATE_MODULE_FAILURE } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ModuleAddedSuccessAction } from 'ModuleInfo/ModuleInfoActionCreator';
import { MODULE_ADDED_SUCCESS } from 'ModuleInfo/ActionTypes';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';

export interface DuplicateModuleAction {
  type: typeof DUPLICATE_MODULE;
  payload: {
    copyToStrategyId: string;
    copyFromStrategyId: string;
    moduleKey: string;
    moduleName: string;
    moduleType: ModuleType;
    moduleIndex: number;
  };
}

export const duplicateModule = (payload: DuplicateModuleAction['payload']): DuplicateModuleAction => ({
  type: DUPLICATE_MODULE,
  payload,
});

export const duplicateModuleSuccess = (payload: ModuleAddedSuccessAction['payload']): ModuleAddedSuccessAction => ({
  type: MODULE_ADDED_SUCCESS,
  payload,
});

export const duplicateModuleFailure = FailedActionCreator(DUPLICATE_MODULE_FAILURE);
