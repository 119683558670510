import Fetcher from 'middlewares/Fetcher';
import { ApplicationTabsApi } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';
import {
  GetApplicationTabsAction,
  getApplicationTabsFailure,
  GetApplicationTabsResult,
  getApplicationTabsSuccess,
} from './ActionCreator';

const GetApplicationTabsMiddleware = (api: ApplicationTabsApi) =>
  Fetcher<GetApplicationTabsResult, GetApplicationTabsAction>(
    LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabs,
    getApplicationTabsSuccess,
    getApplicationTabsFailure,
    async (action) => {
      const tabs = await api.find(action.payload.productId);

      return {
        tabs,
        productId: action.payload.productId,
      };
    },
  );

export default GetApplicationTabsMiddleware;
