import Fetcher from 'middlewares/Fetcher';
import { DocuSignIntegration, DocuSignIntegrationApi } from 'api/Core/DocuSignIntegrationApi';
import {
  updateDocuSignIntegrationSuccess,
  updateDocuSignIntegrationFailure,
  UpdateDocuSignIntegrationAction,
} from './ActionCreator';
import { ESignIntegrationsActionType } from './ActionTypes';

const UpdateDocuSignIntegrationMiddleware = (api: DocuSignIntegrationApi) =>
  Fetcher<DocuSignIntegration, UpdateDocuSignIntegrationAction>(
    ESignIntegrationsActionType.UpdateDocuSignIntegration,
    updateDocuSignIntegrationSuccess,
    updateDocuSignIntegrationFailure,
    (action) => {
      return api.updateIntegration(action.payload.id, action.payload.settings);
    },
  );

export default UpdateDocuSignIntegrationMiddleware;
