import ConfirmRegionSettingsDialog from 'components/ConfirmRegionSettingsDialog';
import PopUp from 'components/PopUps/PopUp';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { closesRegionSettingsConfirmDialog } from './Actions';

const TITLE = 'Confirm Change';

interface RegionSettingsChangesConfirmDialogProps {
  checkboxesState: { [key: string]: boolean };
  onSaveRegionSettings: () => Promise<unknown>;
  onChangeCheckboxesState: (name: string) => void;
  showedCheckboxes: string[];
  onResetCheckboxesState: () => void;
}

const RegionSettingsChangesConfirmDialog: FC<RegionSettingsChangesConfirmDialogProps> = ({
  checkboxesState,
  onSaveRegionSettings,
  onChangeCheckboxesState,
  showedCheckboxes,
  onResetCheckboxesState,
}) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closesRegionSettingsConfirmDialog());
    onResetCheckboxesState();
  };
  return (
    <PopUp title={TITLE} closePopUp={handleClose}>
      <ConfirmRegionSettingsDialog
        onSave={onSaveRegionSettings}
        checkboxesState={checkboxesState}
        onChangeCheckboxesState={onChangeCheckboxesState}
        onClose={handleClose}
        showedCheckboxes={showedCheckboxes}
        onResetCheckboxesState={onResetCheckboxesState}
      />
    </PopUp>
  );
};

export default RegionSettingsChangesConfirmDialog;
