import React, { FC } from 'react';
import styles from './Overview.module.scss';
import Statistics from './Statistics';
import Products from './Products';
import Features from './Features';
import MonthlyPrice from './MonthlyPrice';
import { BillingInfo } from 'api/BillingApi';
import SkeletonText from 'components/Skeleton/SkeletonText';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';

interface OverviewProps {
  infoData: BillingInfo | null;
  applicationsCount: number | null;
  decisionsCount: number | null;
}

const Overview: FC<OverviewProps> = ({ infoData, applicationsCount, decisionsCount }) => {
  const availableSeatsMessage = infoData?.seats ? `(${infoData.seats} ${infoData.seats === 1 ? 'Seat' : 'Seats'})` : '';

  return (
    <div className={styles.container}>
      <div className={styles.columns}>
        <div className={styles.leftSideContainer}>
          <div className={styles.licenseTierContainer}>
            {infoData && `License Tier: ${infoData.licenseTier} ${availableSeatsMessage}`}
            {!infoData && <SkeletonText color="primary20" width="320px" height="24px" lineHeight="28px" />}
          </div>
        </div>
      </div>
      <div className={styles.columns}>
        <div className={styles.leftSideContainer}>
          <MonthlyPrice
            className={styles.monthlyPriceContainer}
            data={
              infoData && {
                displayPrice: infoData.displayPrice,
                applicationsIncluded: infoData.applicationsIncluded,
                extraApplicationPrice: infoData.extraApplicationPrice,
                decisionsIncluded: infoData.decisionsIncluded,
                extraDecisionPrice: infoData.extraDecisionPrice,
                products: infoData.products,
              }
            }
          />
          <Features
            className={styles.featureContainer}
            features={infoData && infoData.features}
            professionalServicesRate={infoData?.professionalServicesRate || null}
          />
          <Products products={infoData && infoData.products} />
        </div>
        <div className={styles.rightSideContainer}>
          <Statistics
            amountOwed={infoData?.amountOwed || 0}
            applicationsCount={applicationsCount}
            decisionsCount={decisionsCount}
            date={infoData?.reportDate || null}
            currencySign={(infoData && infoData.currency && getCurrencySymbol(infoData.currency.toUpperCase())) || ''}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
