import { DigifiThunkActionCreator } from 'stores/MapActionToThunkMiddleware';
import PermissionsApi from 'api/Core/Permission';
import {
  UpdateOrganizationPermissionRequestAction,
  getOrganizationPermissionsSuccess,
  resetPermissionsSuccess,
  updateOrganizationPermissionSuccess,
  updateProductSectionAccessPermissionFailure,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsActions';
import { ProductSectionAccessPermissionsMap } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';

export const GetProductSectionAccessPermissions = (api: PermissionsApi): DigifiThunkActionCreator => () => async (
  dispatch,
) => {
  const permissions = await api.getAll();
  dispatch(getOrganizationPermissionsSuccess(permissions));
};

export const UpdateProductSectionAccessPermissions = (
  api: PermissionsApi,
): DigifiThunkActionCreator<UpdateOrganizationPermissionRequestAction> => (action) => async (dispatch) => {
  const { userRole, productSection, value } = action.payload;
  try {
    const permissions = await api.update(userRole, {
      [productSection]: value,
    } as ProductSectionAccessPermissionsMap);
    dispatch(updateOrganizationPermissionSuccess(permissions, productSection));
  } catch (error) {
    dispatch(updateProductSectionAccessPermissionFailure(userRole, productSection));
  }
};

export const ResetProductSectionAccessPermissions = (api: PermissionsApi): DigifiThunkActionCreator => () => async (
  dispatch,
) => {
  await api.resetToDefault();
  dispatch(resetPermissionsSuccess());
};
