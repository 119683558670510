import borrowerApplicationsPagination, {
  BORROWER_APPLICATIONS_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/BorrowerDetails/pagination';
import { ApplicationSortingField } from 'api/LoanOriginationSystem/Types';
import { UnlockBorrowerSuccessAction } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import { LoanOriginationSystemUnlockBorrowerType } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionTypes';
import { LoanOriginationSystemBorrowerDetailsState } from './Types';
import { LoanOriginationSystemBorrowerDetailsActionType } from './ActionTypes';
import {
  GetBorrowerApplicationsSuccessAction,
  GetBorrowerSuccessAction,
  ResetBorrowerDetailsAction,
  SortBorrowerApplicationsAction,
} from './ActionCreator';

export const initialState: LoanOriginationSystemBorrowerDetailsState = {
  borrower: null,
  applications: null,
  applicationsSortingType: {
    field: ApplicationSortingField.CreatedAt,
    ascending: true,
  },
};

type LoanOriginationSystemBorrowerDetailsActions =
  | GetBorrowerSuccessAction
  | ResetBorrowerDetailsAction
  | GetBorrowerApplicationsSuccessAction
  | SortBorrowerApplicationsAction
  | UnlockBorrowerSuccessAction;

const loanOriginationSystemBorrowerDetailsReducer = (
  state = initialState,
  action: LoanOriginationSystemBorrowerDetailsActions,
) => {
  switch (action.type) {
    case LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerSuccess: {
      return {
        ...state,
        borrower: action.payload,
      };
    }
    case LoanOriginationSystemBorrowerDetailsActionType.ResetBorrowersDetails: {
      return initialState;
    }
    case LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplicationsSuccess: {
      return {
        ...state,
        applications: {
          page: 1,
          itemsPerPage: state.applications?.itemsPerPage || BORROWER_APPLICATIONS_PER_PAGE_DEFAULT,
          error: '',
          items: action.payload.items,
          itemsTotal: action.payload.total,
        },
      };
    }
    case LoanOriginationSystemBorrowerDetailsActionType.SortBorrowerApplications: {
      return {
        ...state,
        applicationsSortingType: action.payload.sortingType,
      };
    }
    case LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess: {
      if (!state.borrower) {
        return state;
      }

      return {
        ...state,
        borrower: {
          ...state.borrower,
          locked: state.borrower.id === action.payload ? false : state.borrower.locked,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default borrowerApplicationsPagination.wrapReducer<LoanOriginationSystemBorrowerDetailsState>(
  loanOriginationSystemBorrowerDetailsReducer,
);
