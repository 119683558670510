import AutoCompleteWithError from 'components/AutoCompleteWithError';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { Option } from 'components/SelectInput/SelectInput';
import React, { FC, useState } from 'react';
import styles from './PlanAndProductInformationStep.module.scss';
import PRODUCT_TEMPLATES from './ProductTemplates';
import QuestionIcon from 'components/QuestionIcon';

interface PlanAndProductInformationStepValidationResult {
  template?: string;
}

interface PlanAndProductInformationStepProps {
  onCreateAccount: (template?: string) => Promise<void>;
  onChange: (template: string) => void;
  initialTemplate?: string;
}

const PlanAndProductInformationStep: FC<PlanAndProductInformationStepProps> = ({
  initialTemplate,
  onCreateAccount,
  onChange,
}) => {
  const [template, setTemplate] = useState(initialTemplate);
  const [errors, setErrors] = useState<PlanAndProductInformationStepValidationResult>({});

  const handleProductChange = ({ value }: Option) => {
    setTemplate(value);
    onChange(value);

    setErrors({ ...errors, template: '' });
  };

  return (
    <>
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          Free Trial Details
          <QuestionIcon
            tooltipPosition="bottom-start"
            className={styles.hintIcon}
            size={24}
            tooltip="You can make changes after creating your account."
          />
        </div>

        <div className={styles.line} />
        <div className={styles.infoLine}>
          <div className={styles.subTitle}>Tier</div>
          <div className={styles.subTitleText}>Basic (1 seat)</div>
        </div>

        <div className={styles.line} />
        <div className={styles.infoLine}>
          <div className={styles.subTitle}>Products</div>
          <div className={styles.subTitleText}>Loan Origination System Decision Engine</div>
        </div>

        <div className={styles.line} />
      </div>
      <div className={styles.block}>
        <div className={styles.blockTitle}>
          Quick Start Templates
          <QuestionIcon
            tooltipPosition="bottom"
            className={styles.hintIcon}
            size={24}
            tooltip={
              <p className={styles.hintText}>
                Selecting a template will help you get started
                <br />
                quickly. You can add, modify or create a custom
                <br />
                template later.
              </p>
            }
          />
        </div>
        <AutoCompleteWithError
          className={styles.selectContainer}
          options={PRODUCT_TEMPLATES}
          labelTitle="Loan Product Template"
          value={template}
          errorMessage={errors.template}
          onChange={handleProductChange}
        />
        <div className={styles.formFooter}>
          By clicking <div className={styles.bold}>Create Account</div> you agree to the{' '}
          <ExternalLink href="https://www.digifi.io/master-subscription-agreement">
            Master Subscription Agreement
          </ExternalLink>{' '}
          and <ExternalLink href="https://www.digifi.io/privacy-policy">Privacy Policy</ExternalLink>.
        </div>
        <ButtonWithLoadingState
          onClick={() => onCreateAccount(template)}
          kind="primary"
          size="form"
          className={styles.createAccount}
          alwaysClickable
        >
          Create Account
        </ButtonWithLoadingState>
      </div>
    </>
  );
};

export default PlanAndProductInformationStep;
