import Fetcher, { RoutineHandler } from 'middlewares/Fetcher';
import { IStrategiesApi } from 'api/DecisionEngine/StrategiesApi';
import { StrategyActionType } from 'Strategies/ActionTypes';
import {
  createStrategyFailure,
  createStrategySuccess,
  CreateStrategySuccessAction, DuplicateStrategyAction, duplicateStrategyFailure,
  duplicateStrategySuccess, DuplicateStrategySuccessAction
} from 'Strategies/ActionCreator';
import { AnyAction, Middleware } from 'redux';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { History } from 'history';
import { IStrategyGroupsApi } from 'api/DecisionEngine/StrategyGroupApi';

export const CreateStrategyMiddleware = (api: IStrategyGroupsApi) =>
  Fetcher<{ strategyId: string }>(
    StrategyActionType.CreateStrategy,
    createStrategySuccess,
    createStrategyFailure,
    async () => {
      const strategy = await api.createNew();
      return { strategyId: strategy.id };
    },
  );

export const CreateStrategySuccessMiddleware = ({ push }: History): Middleware => RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
  if (action.type === StrategyActionType.CreateStrategySuccess) {
    const { strategyId } = ((action as unknown) as CreateStrategySuccessAction).payload;
    push(`/decision/strategies/${strategyId}/overview`);
    notification.createNotification(getMessage(MessageType.StrategyCreated), 'success', dispatch);
  }
});

export const DuplicateStrategyMiddleware = (api: IStrategiesApi) =>
  Fetcher<string, DuplicateStrategyAction>(
    StrategyActionType.DuplicateStrategy,
    duplicateStrategySuccess,
    duplicateStrategyFailure,
    async ({ payload }) => {
      const strategy = await api.copy(payload.strategyId);
      return strategy.id;
    }
  );

export const DuplicateStrategySuccessMiddleware = ({ push }: History): Middleware => RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
  if (action.type === StrategyActionType.DuplicateStrategySuccess) {
    const { strategyId } = ((action as unknown) as DuplicateStrategySuccessAction).payload;
    push(`/decision/strategies/${strategyId}/overview`);
    notification.createNotification(getMessage(MessageType.StrategyDuplicated), 'success', dispatch);
  }
});
