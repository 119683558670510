import React from 'react';
import { EditImage } from 'static/images';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import {
  getFormattedBorrowerDateOfBirth,
  getFormattedBorrowerIdNumber,
  getFormattedBorrowerPhoneNumber,
  getIdNumberVariableNameByType,
} from 'LoanOriginationSystemOrganization/Utils';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import styles from './ExistingBorrowerForm.module.scss';
import { StandardVariables } from 'Variables/VariablesTypes';

interface ExistingBorrowerFormProps {
  borrowerType: BorrowerType;
  variables: FormLayoutData;
  onClose: () => void;
  onProfileEditClick?: () => void;
  standardVariables: StandardVariables;
  hideCloseButton?: boolean;
}

const ExistingBorrowerForm = ({
  variables,
  borrowerType,
  standardVariables,
  onProfileEditClick,
  onClose,
  hideCloseButton,
}: ExistingBorrowerFormProps) => {
  const isPerson = borrowerType === BorrowerType.Person;

  const getAddressInfo = () => {
    const addressFields = [
      variables[BorrowerDefaultVariable.Street],
      variables[BorrowerDefaultVariable.City],
      variables[BorrowerDefaultVariable.State],
      variables[BorrowerDefaultVariable.Zip],
      variables[BorrowerDefaultVariable.Country],
    ];

    return addressFields.filter((field) => !!field).join(', ');
  };

  const handleProfileEditClick = () => {
    if (!onProfileEditClick) {
      return;
    }

    onProfileEditClick();
  };

  const idNumberVariableName = getIdNumberVariableNameByType(borrowerType, BorrowerDefaultVariable);
  const formattedIdNumber = getFormattedBorrowerIdNumber(
    variables[idNumberVariableName],
    standardVariables[idNumberVariableName],
  );
  const formattedPhone = getFormattedBorrowerPhoneNumber(
    variables[BorrowerDefaultVariable.PhoneNumber],
    standardVariables,
  );
  const formattedDateOfBirth = getFormattedBorrowerDateOfBirth(
    variables[BorrowerDefaultVariable.DateOfBirth],
    standardVariables,
  );

  return (
    <InfoCard
      title={getBorrowerFullName(borrowerType, variables)}
      titleLink={
        <>
          <EditImage className={styles.editIcon} />
          Edit Profile
        </>
      }
      onTitleLinkClick={handleProfileEditClick}
      onClose={onClose}
      hideCloseButton={hideCloseButton}
    >
      <div className={styles.columnsContainer}>
        <InfoCardColumn
          itemClassName={styles.leftColumnItem}
          items={[
            {
              label: 'Email',
              content: variables[BorrowerDefaultVariable.Email] as string,
            },
            {
              label: 'Phone',
              content: formattedPhone,
            },
            ...(isPerson ? [{ label: 'Address', content: getAddressInfo() }] : []),
          ]}
        />
        <InfoCardColumn
          className={styles.rightColumn}
          itemClassName={styles.rightColumnItem}
          items={[
            {
              label: 'ID Number',
              content: formattedIdNumber,
            },
            {
              label: isPerson ? 'Date of Birth' : 'Address',
              content: isPerson ? formattedDateOfBirth : getAddressInfo(),
            },
          ]}
        />
      </div>
    </InfoCard>
  );
};

export default ExistingBorrowerForm;
