import React, { FC, ChangeEvent, useEffect } from 'react';
import styles from './History.module.scss';
import SearchNotFound from 'components/SearchNotFound';
import SearchInput from 'components/SearchInput';
import StrategyRunHistoryTable from 'components/StrategyRunHistoryTable';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import pagination from 'components/IndividualProcessing/History/Pagination';
import NoItems from 'components/NoItems';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import { useHistory } from 'react-router';
import { IndividualProcessingHistoryCase } from 'api/Types';
import useStateReset from 'hooks/useStateReset';
import { IndividualProcessingHistoryActionType } from 'StrategyProcessing/ActionTypes';
import { getIndividualHistory } from 'StrategyProcessing/ActionCreator';

interface HistoryProps {
  onSearchInputChange: (searchInputValue: string) => void;
}

const History: FC<HistoryProps> = ({ onSearchInputChange }) => {
  const dispatch = useDispatch();
  const downloadApi = useDownloadApi();
  const history = useHistory();

  useEffect(() => {
    dispatch(getIndividualHistory());
  }, []);

  const handleSearchInputChange =
    onSearchInputChange && (({ target }: ChangeEvent<HTMLInputElement>) => onSearchInputChange(target.value));
  const searchInputValue = useSelector<ReduxState, string>(
    ({ individualProcessing }) => individualProcessing.history.searchInputValue,
  );
  const individualCases = pagination.usePaginatedItems({ searchInputValue });
  const paginationProps = pagination.usePagination({ searchInputValue });
  const empty = paginationProps.itemsTotal === 0;

  useStateReset(IndividualProcessingHistoryActionType.ResetState);

  const renderSearchInput = () => {
    if (empty && !searchInputValue) {
      return null;
    }

    return (
      <SearchInput
        placeholder="Search"
        containerClassName={styles.historySearchContainer}
        value={searchInputValue}
        onChange={handleSearchInputChange}
        onClear={() => onSearchInputChange('')}
      />
    );
  };

  const handleRunReprocess = (caseId: string) => {
    const { strategyId, inputs } = (individualCases as IndividualProcessingHistoryCase[]).find(
      ({ id }: IndividualProcessingHistoryCase) => id === caseId,
    )!;
    history.push(`/decision/processing/individual/run/${strategyId}`, inputs);
  };

  const renderNoItems = () => (
    <NoItems title="No recorded activity yet!" className={styles.noData} titleClassName={styles.noDataTitle} />
  );

  const renderResultsTable = () => {
    if (empty && !searchInputValue) {
      return renderNoItems();
    }
    if (empty && searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }

    return (
      <div className={styles.table}>
        <StrategyRunHistoryTable
          individualCases={individualCases}
          paginationProps={paginationProps}
          searchInputValue={searchInputValue}
          getRowActions={() => [
            {
              title: 'Re-Process Decision',
              handler: (caseId?: string) => handleRunReprocess(caseId!),
            },
            {
              title: 'Download Results',
              handler: (caseId?: string) => downloadApi.downloadSimulation(caseId!),
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <h4 className={styles.historyHeader__title}>History</h4>
      {renderSearchInput()}
      {renderResultsTable()}
    </>
  );
};

export default History;
