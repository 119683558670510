import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { BorrowerVariableConfiguration } from 'api/Types';
import { BorrowerVariableConfigurationsApi } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import {
  UpdateBorrowerVariableConfigurationAction,
  updateBorrowerVariableConfigurationFailure,
  updateBorrowerVariableConfigurationSuccess,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const UpdateBorrowerVariableConfigurationMiddleware = (api: BorrowerVariableConfigurationsApi) =>
  Fetcher<BorrowerVariableConfiguration, UpdateBorrowerVariableConfigurationAction, ReduxState>(
    VariablesConfigurationActionType.UpdateBorrowerVariableConfiguration,
    updateBorrowerVariableConfigurationSuccess,
    updateBorrowerVariableConfigurationFailure,
    (action) => {
      return api.update(action.payload.id, action.payload.params);
    },
  );

export default UpdateBorrowerVariableConfigurationMiddleware;
