import { VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { DataType } from 'Variables/VariablesTypes';
import { unhandledCase } from 'utils/unhandledCase';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';

export const countDefaultVariables = (configuration: VariableConfiguration[]) => {
  return configuration.reduce((count, variableConfiguration) => {
    if (variableConfiguration.default) {
      return count + 1;
    }

    return count;
  }, 0);
};

export const convertVariableInput = (valueInput: string, dataType: DataType): VariableValue => {
  switch (dataType) {
    case 'Boolean': {
      return valueInput ? valueInput.toLowerCase().trim() === 'true' : null;
    }
    case 'Number': {
      return valueInput ? Number(valueInput) : null;
    }
    case 'Date': {
      return valueInput || null;
    }
    case 'String': {
      return valueInput || null;
    }
    default: {
      return unhandledCase(dataType);
    }
  }
};

export const convertVariableValueToString = (variableValue: VariableValue | undefined): string => {
  if (variableValue === null) {
    return '';
  }
  switch (typeof variableValue) {
    case 'boolean':
    case 'number': {
      return String(variableValue);
    }
    case 'undefined': {
      return '';
    }
    case 'string': {
      return variableValue;
    }
    default: {
      return unhandledCase(variableValue);
    }
  }
};

export const isConfigurableFormDataChanged = (
  initialData: Record<string, VariableValue>,
  newData: Record<string, VariableValue>,
) => {
  return Object.keys(newData).some((key) => {
    if (isEmptyVariableValue(newData[key]) && isEmptyVariableValue(initialData[key])) {
      return false;
    }

    return newData[key] !== initialData[key];
  });
};
