import { AnyAction } from 'redux';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { ApplicationNotesSortingType } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationNotesActionType } from './ActionTypes';
import { ApplicationNote } from 'api/Types';
import { ApplicationNotePopUpType } from './Types';

export interface GetApplicationNotesAction extends AnyAction {
  type: ApplicationNotesActionType.GetApplicationNotes;
  payload: {
    applicationId: string;
    search: string;
    sortingType: ApplicationNotesSortingType;
  };
}

export interface GetApplicationNotesSuccessAction extends AnyAction {
  type: ApplicationNotesActionType.GetApplicationNotesSuccess;
  payload: TableViewData<ApplicationNote> & { search: string };
}

export interface AddApplicationNoteAction extends AnyAction {
  type: ApplicationNotesActionType.AddApplicationNote;
  payload: {
    applicationId: string;
    note: string;
  };
}

export interface AddApplicationNoteSuccessAction extends AnyAction {
  type: ApplicationNotesActionType.AddApplicationNoteSuccess;
  payload: {
    note: ApplicationNote;
  };
}

export interface DeleteApplicationNoteAction extends AnyAction {
  type: ApplicationNotesActionType.DeleteApplicationNote;
  payload: {
    applicationId: string;
    noteId: string;
  };
}

export interface DeleteApplicationNoteSuccessAction extends AnyAction {
  type: ApplicationNotesActionType.DeleteApplicationNoteSuccess;
}

export interface UpdateApplicationNoteAction extends AnyAction {
  type: ApplicationNotesActionType.UpdateApplicationNote;
  payload: {
    applicationId: string;
    noteId: string;
    note: string;
  };
}

export interface UpdateApplicationNoteSuccessAction extends AnyAction {
  type: ApplicationNotesActionType.UpdateApplicationNoteSuccess;
}

export interface OpenApplicationNotePopupAction extends AnyAction {
  type: ApplicationNotesActionType.OpenApplicationNotePopup;
  payload: ApplicationNotePopUpType;
}

export interface CloseApplicationNotePopupAction extends AnyAction {
  type: ApplicationNotesActionType.CloseApplicationNotePopup;
}

export interface SetSearchValueAction extends AnyAction {
  type: ApplicationNotesActionType.SetSearchValue;
  payload: {
    applicationId: string;
    search: string;
  };
}

export interface SortApplicationNotesAction extends AnyAction {
  type: ApplicationNotesActionType.SortApplicationNotes;
  payload: {
    applicationId: string;
    sortingType: ApplicationNotesSortingType;
  };
}

export interface SetCurrentApplicationNoteAction extends AnyAction {
  type: ApplicationNotesActionType.SetCurrentApplicationNote;
  payload: {
    noteId: string | null;
  };
}

export const getApplicationNotes = (
  applicationId: string,
  search: string,
  sortingType: ApplicationNotesSortingType,
): GetApplicationNotesAction => ({
  type: ApplicationNotesActionType.GetApplicationNotes,
  payload: {
    applicationId,
    search,
    sortingType,
  },
});

export const getApplicationNotesSuccess = (
  result: TableViewData<ApplicationNote> & { search: string },
): GetApplicationNotesSuccessAction => ({
  type: ApplicationNotesActionType.GetApplicationNotesSuccess,
  payload: result,
});

export const addApplicationNote = (applicationId: string, note: string): AddApplicationNoteAction => ({
  type: ApplicationNotesActionType.AddApplicationNote,
  payload: {
    applicationId,
    note,
  },
});

export const addApplicationNoteSuccess = (note: ApplicationNote): AddApplicationNoteSuccessAction => ({
  type: ApplicationNotesActionType.AddApplicationNoteSuccess,
  payload: {
    note,
  },
});

export const deleteApplicationNote = (applicationId: string, noteId: string): DeleteApplicationNoteAction => ({
  type: ApplicationNotesActionType.DeleteApplicationNote,
  payload: {
    noteId,
    applicationId,
  },
});

export const deleteApplicationNoteSuccess = (): DeleteApplicationNoteSuccessAction => ({
  type: ApplicationNotesActionType.DeleteApplicationNoteSuccess,
});

export const updateApplicationNote = (
  applicationId: string,
  noteId: string,
  note: string,
): UpdateApplicationNoteAction => ({
  type: ApplicationNotesActionType.UpdateApplicationNote,
  payload: {
    noteId,
    applicationId,
    note,
  },
});

export const updateApplicationNoteSuccess = (): UpdateApplicationNoteSuccessAction => ({
  type: ApplicationNotesActionType.UpdateApplicationNoteSuccess,
});

export const openApplicationNotePopup = (popUpType: ApplicationNotePopUpType): OpenApplicationNotePopupAction => ({
  type: ApplicationNotesActionType.OpenApplicationNotePopup,
  payload: popUpType,
});

export const closeApplicationNotePopup = (): CloseApplicationNotePopupAction => ({
  type: ApplicationNotesActionType.CloseApplicationNotePopup,
});

export const setSearchValue = (applicationId: string, search: string): SetSearchValueAction => ({
  type: ApplicationNotesActionType.SetSearchValue,
  payload: {
    applicationId,
    search,
  },
});

export const sortApplicationNotes = (
  applicationId: string,
  sortingType: ApplicationNotesSortingType,
): SortApplicationNotesAction => ({
  type: ApplicationNotesActionType.SortApplicationNotes,
  payload: {
    applicationId,
    sortingType,
  },
});

export const setCurrentCurrentApplicationNote = (noteId: string | null): SetCurrentApplicationNoteAction => ({
  type: ApplicationNotesActionType.SetCurrentApplicationNote,
  payload: {
    noteId,
  },
});

export const getApplicationNotesFailure = FailedActionCreator(ApplicationNotesActionType.GetApplicationNotesFailure);
export const addApplicationNoteFailure = FailedActionCreator(ApplicationNotesActionType.AddApplicationNoteFailure);
export const deleteApplicationNoteFailure = FailedActionCreator(
  ApplicationNotesActionType.DeleteApplicationNoteFailure,
);
export const updateApplicationNoteFailure = FailedActionCreator(
  ApplicationNotesActionType.UpdateApplicationNoteFailure,
);
