import Fetcher from 'middlewares/Fetcher';
import { ApplicationVariableConfigurationsApi } from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import { BulkUpdateVariableConfigurationParams } from 'api/LoanOriginationSystem/Types';
import {
  BulkUpdateApplicationVariableConfigurationsAction,
  bulkUpdateApplicationVariableConfigurationsFailure,
  bulkUpdateApplicationVariableConfigurationsSuccess,
} from './ApplicationVariableConfigurationsActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const BulkUpdateApplicationVariableConfigurationsMiddleware = (api: ApplicationVariableConfigurationsApi) =>
  Fetcher<BulkUpdateVariableConfigurationParams[], BulkUpdateApplicationVariableConfigurationsAction>(
    VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurations,
    bulkUpdateApplicationVariableConfigurationsSuccess,
    bulkUpdateApplicationVariableConfigurationsFailure,
    async (action) => {
      await api.bulkUpdate(action.payload.configurations);

      return action.payload.configurations;
    },
  );

export default BulkUpdateApplicationVariableConfigurationsMiddleware;
