import React, { FC, FormEvent, useEffect, useState } from 'react';

import styles from './EditCompanyUser.module.scss';
import { CloseButtonImage } from 'static/images';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import AutoCompletion from 'components/AutoCompletion';
import ButtonLikeToggle from 'components/ButtonLikeToggle';
import { Option } from 'components/SelectInput/SelectInput';
import EditCompanyUserSkeleton from './EditCompanyUserSkeleton';
import { useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import { getOrganizationSeats } from 'CompanyInformation/CompanyInformationActionCreator';
import SeatsAttribute from 'CompanyInformation/SeatsAttribute';
import { SeatsData } from 'api/Core/OrganizationApi';
import { OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';

const USER_TYPE = { OWNER: 'owner', ADMIN: 'admin', USER: 'user' };

const typeList = [
  { value: USER_TYPE.OWNER, name: 'Owner' },
  { value: USER_TYPE.ADMIN, name: 'Admin' },
  { value: USER_TYPE.USER, name: 'User' },
];

interface EditCompanyUserProps {
  updateUser: (userInfo: EditUserParams) => Promise<unknown>;
  selectedUserInfo?: OrganizationUser;
  onClose: () => void;
  selfEdit: boolean;
  handleOpenSeatsAreFullPopUp: () => void;
}

const defaultUserInfo: OrganizationUser = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  isMfaEnabled: false,
  phone: '',
  isEmailVerified: false,
  isActive: false,
  role: UserRoleName.User,
};

const EditCompanyUser: FC<EditCompanyUserProps> = (props) => {
  const dispatchRoutine = useDispatchRoutineWithResult();
  const { updateUser, selectedUserInfo = defaultUserInfo, onClose, selfEdit, handleOpenSeatsAreFullPopUp } = props;

  const { id, firstName, lastName, email, role, isActive, phone } = selectedUserInfo;

  const [permissionType, setPermissionType] = useState(USER_TYPE.USER);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChangeStatusInLoading, setIsChangeStatusInLoading] = useState(false);

  useEffect(() => {
    setPermissionType(role.toLowerCase());
    setStatus(isActive);
  }, [role, isActive, phone]);

  const handleTypeChange = ({ value }: Option) => {
    setPermissionType(value);
  };

  const handleEditUser = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    await updateUser({
      userId: id,
      isActive: status,
      role: permissionType as UserRoleName,
    });
    setIsLoading(false);
    onClose();
  };

  const handleStatusChange = async (e: unknown, isChecked: boolean) => {
    if (!isActive && !status) {
      setIsChangeStatusInLoading(true);
      const seatsData = await dispatchRoutine(getOrganizationSeats());
      const { occupiedSeats, purchasedSeats } = seatsData[SeatsAttribute.Seats] as SeatsData;
      setIsChangeStatusInLoading(false);

      if (occupiedSeats >= purchasedSeats) {
        handleOpenSeatsAreFullPopUp();
        return;
      }
    }

    setStatus(isChecked);
  };

  const isUserInfoChanged = !(role.toLowerCase() === permissionType && isActive === status);

  const renderForm = () => {
    return (
      <>
        <div className={styles.editUser__inputs}>
          <TextInput type="text" labelTitle="First Name" value={firstName} disabled />
          <TextInput type="text" labelTitle="Last Name" value={lastName} disabled />
          <TextInput type="text" labelTitle="Email" value={email} disabled />
          <AutoCompletion
            labelTitle="Permissions Type"
            onChange={handleTypeChange}
            value={permissionType}
            options={typeList}
            disabled={selfEdit}
            hideClearIcon
          />
        </div>
        <div className={`${styles.editUser__sectionList}`}>
          <div className={`${styles.editUser__section}`}>
            <div className={styles.editUser__sectionTitle}>
              <p className={styles.label}>Account Status</p>
            </div>
            <div className={`${styles.editUser__sectionContent}`}>
              <h5>{status ? 'Active' : 'Inactive'}</h5>
              <ButtonLikeToggle
                checked={status}
                className={styles.editUser__activeButton}
                onChange={handleStatusChange}
                isLoading={isChangeStatusInLoading}
                disabled={selfEdit}
              >
                {status ? 'Deactivate Account' : 'Activate Account'}
              </ButtonLikeToggle>
            </div>
          </div>
        </div>
        <Button type="submit" kind="primary" size="form" disabled={!isUserInfoChanged} isLoading={isLoading}>
          Save Changes
        </Button>
      </>
    );
  };

  return (
    <form className={styles.editUser} onSubmit={handleEditUser}>
      <div className={styles.editUser__header}>
        <h2>Edit User</h2>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.editUser__content}>{id ? renderForm() : <EditCompanyUserSkeleton />}</div>
    </form>
  );
};

export default EditCompanyUser;
