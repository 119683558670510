import React from 'react';
import { IllustrationImage } from 'static/images';
import styles from 'components/LoanOriginationSystem/NoEmailsConnected/NoEmailsConnected.module.scss';
import Button from 'components/Button';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { openConnectEmailsPopup } from 'LoanOriginationSystemEmails/ActionCreator';
import WrapperWithTooltip from 'components/Tooltip';
import { getMailtoURI } from 'Email/utils';

interface NoEmailsConnectedProps {
  headerText: string;
  email: string;
  borrowerEmail: string;
  coBorrowerEmail?: string;
  small?: boolean;
  hideHeader?: boolean;
  descriptionClassName?: string;
}

const BUTTON_SEND_TOOLTIP_TEXT = 'This application does not have an email address';

const NoEmailsConnected = ({
  email,
  headerText,
  small,
  borrowerEmail,
  coBorrowerEmail,
  hideHeader,
  descriptionClassName,
}: NoEmailsConnectedProps) => {
  const dispatch = useDispatch();

  const handleConnectEmails = () => {
    dispatch(openConnectEmailsPopup());
  };

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: [borrowerEmail, coBorrowerEmail],
      cc: email,
    });
  };

  return (
    <div className={styles.container}>
      {!hideHeader && (
        <div className={styles.header}>
          {small ? <h4 className={styles.title}>{headerText}</h4> : <h2 className={styles.smallTitle}>{headerText}</h2>}
          <div className={styles.buttonContainer}>
            <Button kind="secondary" className={styles.connectEmailsButton} onClick={handleConnectEmails}>
              Connect Emails
            </Button>
            <WrapperWithTooltip tooltip={small && !borrowerEmail ? BUTTON_SEND_TOOLTIP_TEXT : ''}>
              <div>
                <Button
                  kind="primary"
                  className={styles.sendEmailButton}
                  disabled={small && !borrowerEmail}
                  onClick={handleSendEmail}
                >
                  Send Email
                </Button>
              </div>
            </WrapperWithTooltip>
          </div>
        </div>
      )}
      <div className={clsx(small ? styles.smallDescription : styles.description, descriptionClassName)}>
        <div className={styles.subtitleContainer}>
          {!small && <h4>Connect Emails to the DigiFi Platform</h4>}
          <p className={styles.subtitle}>
            To connect emails to the DigiFi Loan Origination System, please CC or BCC the following email address on
            your outbound emails:
          </p>
          <p>{email}</p>
        </div>
        <IllustrationImage />
      </div>
    </div>
  );
};

export default NoEmailsConnected;
