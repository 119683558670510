import { debounce } from 'lodash';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { VariablesFiltersActionTypes } from './Filters/ActionTypes';
import { getVariablesRequest } from './VariablesActionCreator';
import { SetSearchInputValueAction } from './Filters/ActionCreator';

const REQUEST_DELAY_MS = 250; // 1/4 of a second

const isVariablesSetSearchInputAction = (action: AnyAction): action is SetSearchInputValueAction =>
  action.type === VariablesFiltersActionTypes.SetSearchInputValue;

const variablesSearchMiddleware: Middleware = <S>({ dispatch }: MiddlewareAPI<S>) => (next: Dispatch<S>) => {
  const requestVariables = debounce(() => dispatch(getVariablesRequest()), REQUEST_DELAY_MS);

  return <A extends AnyAction>(action: A): A => {
    const result = next(action);
    if (isVariablesSetSearchInputAction(action)) {
      requestVariables();
    }
    return result;
  };
};

export default variablesSearchMiddleware;
