import React from 'react';
import clsx from 'clsx';
import { AddImage } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './DataTab.module.scss';

export interface AddCardButtonProps {
  onClick: () => void;
  className?: string;
}

const AddCardButton = ({ onClick, className }: AddCardButtonProps) => (
  <WrapperWithTooltip tooltip="Add Card">
    <div className={clsx(styles.addCardButton, className)} onClick={onClick}>
      <AddImage />
    </div>
  </WrapperWithTooltip>
);

export default AddCardButton;
