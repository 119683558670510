import { Middleware } from 'redux';

import { ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST } from './ActionTypes';
import { GenerateSecondSecretError, GenerateSecondSecretSuccess } from './GenerateSecondSecretActionCreator';

import AuthenticationAPIRest from 'api/Authentication';
import Fetcher from 'middlewares/Fetcher';

const GenerateSecondSecretMiddleware: (api: AuthenticationAPIRest) => Middleware = (api) =>
  Fetcher<void>(
    ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST,
    GenerateSecondSecretSuccess,
    GenerateSecondSecretError,
    () => api.generateSecondSecret(),
  );

export default GenerateSecondSecretMiddleware;
