import React, { ChangeEvent, FC } from 'react';
import styles from './ProcessingBatchCase.module.scss';
import { SearchImage } from 'static/images';
import UserAvatar from 'components/UserAvatar';
import { useHistory } from 'react-router-dom';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import ButtonWithImage from 'components/ButtonWithImage';
import { BatchIndividualCase, BatchProcessingCaseData } from 'api/Types';
import { NullableItems, PaginationProps } from 'pagination';
import SearchNotFound from 'components/SearchNotFound';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { BatchProcessingCaseResults } from 'api/DecisionEngineStrategiesType';
import RowActions from 'components/RowActions';
import { useDownloadApi } from 'providers/ApiServiceProvider';

export interface ProcessingBatchCaseProps {
  individualCases: NullableItems<BatchIndividualCase>;
  caseData: BatchProcessingCaseData;
  handleDownloadResults: () => void;
  searchInputValue: string;
  empty?: boolean;
  onSearchInputChange?: (searchInputValue: string) => void;
  paginationProps: PaginationProps;
  results: BatchProcessingCaseResults;
}

export enum CaseResult {
  Passed = 'Passed',
  Failed = 'Failed',
  Error = 'Error',
}

const statusStyles = new Map([
  [CaseResult.Passed, styles.statusPassed],
  [CaseResult.Failed, styles.statusFailed],
  [CaseResult.Error, styles.statusError],
]);

const getStateColor = (status: CaseResult) => {
  return statusStyles.get(status);
};

const SIZES = {
  NAME: 40,
  STRATEGY_NAME: 40,
  RESULT: 20,
};

const ProcessingBatchCase: FC<ProcessingBatchCaseProps> = ({
  individualCases,
  caseData,
  handleDownloadResults,
  onSearchInputChange,
  empty = false,
  searchInputValue,
  paginationProps,
  results,
}) => {
  const { user, displayTitle, date } = caseData;

  const history = useHistory();
  const downloadApi = useDownloadApi();

  const handleSearchInputChange =
    onSearchInputChange && (({ target }: ChangeEvent<HTMLInputElement>) => onSearchInputChange(target.value));

  const handleCaseClick = (caseId: string) => {
    history.push(`/decision/processing/individual/results/${caseId}`);
  };

  return (
    <div className={styles.page}>
      <div className={styles.caseHeader}>
        <div className={styles.caseHeader__title}>
          <h2>{displayTitle}</h2>
        </div>
      </div>
      <div className={styles.caseInfo}>
        <div className={styles.caseUpdateInfo}>
          <UserAvatar firstName={user.name.split(' ')[0]} lastName={user.name.split(' ')[1]} size="tiny" tooltip />
          <p>
            Created <DateTime time={date} format={DateTimeFormat.LongWithSeconds} />
          </p>
        </div>
        <div className={styles.caseProcessInfo}>
          {`${results.attempted} Attempted | ${results.passed} Passed | ${results.failed} Failed | ${results.error} Errors`}
        </div>
      </div>
      <div className={styles.caseContent}>
        <div className={styles.caseContent__header}>
          <h4 className={styles.title}>Individual Decisions</h4>
          <ButtonWithImage title="Download CSV" onClick={handleDownloadResults} />
        </div>
        <div className={styles.historySearch}>
          <SearchImage />
          <input value={searchInputValue} type="text" placeholder="Search" onChange={handleSearchInputChange} />
        </div>
        {empty ? (
          <SearchNotFound searchValue={searchInputValue} />
        ) : (
          <div className={styles.table}>
            <Table>
              <TableHead useActions>
                <TableHeadCell width={SIZES.NAME}>Case Name</TableHeadCell>
                <TableHeadCell width={SIZES.STRATEGY_NAME}>Strategy Name</TableHeadCell>
                <TableHeadCell width={SIZES.RESULT}>Overall Result</TableHeadCell>
                <div className={styles.ellipsis} />
              </TableHead>
              <TableBody>
                {individualCases.map((individualCase) => {
                  if (!individualCase) {
                    return null; // TODO skeleton
                  }

                  return (
                    <TableRow key={individualCase.id} useActions onClick={() => handleCaseClick(individualCase.id)}>
                      <TableBodyCell width={SIZES.NAME} className={styles.nameCell} noPadding>
                        <div>{individualCase.name}</div>
                      </TableBodyCell>
                      <TableBodyCell width={SIZES.STRATEGY_NAME}>{individualCase.strategyDisplayName}</TableBodyCell>
                      <TableBodyCell width={SIZES.RESULT}>
                        <div className={getStateColor(individualCase.status)}>{individualCase.status}</div>
                      </TableBodyCell>
                      <RowActions
                        actions={[
                          {
                            title: 'Download Results',
                            handler: () => {
                              downloadApi.downloadSimulation(individualCase.id);
                            },
                          },
                        ]}
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Pagination {...paginationProps} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProcessingBatchCase;
