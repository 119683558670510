import Footer from 'components/Footer';
import RegionSettings from 'components/RegionSettings';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { makeLeftNavigation } from 'MainLayout/utils';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RegionSettingsChangesConfirmDialog from 'RegionSettings/RegionSettingsChangesConfirmDialog';
import { ReduxState } from 'types/redux';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import { openRegionSettingsConfirmDialog, updateRegionSettings } from 'RegionSettings/Actions';
import MainLayout, { PageFooter } from 'MainLayout';
import PageWrapper from 'MainLayout/PageWrapper';
import PageContent from 'MainLayout/PageContent';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import PageName from 'constants/PageName';

const DEFAULT_CHECKBOXES_STATE = {
  phoneNumberFormat: false,
  currencyFormat: false,
  dateFormat: false,
};

const RegionSettingsPage = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { regionSettings } = useSelector((state: ReduxState) => state.organizationInformation);

  const {
    phoneNumberFormat: derivedPhoneNumberFormat,
    currencyFormat: derivedCurrencyFormat,
    dateFormat: derivedDateFormat,
    idNumberType: derivedIdNumberType,
  } = regionSettings || {};

  const regionSettingsState = useSelector((state: ReduxState) => state.regionSettings);

  const [phoneNumberFormat, setPhoneNumberFormat] = useState(derivedPhoneNumberFormat);
  const [currencyFormat, setCurrencyFormat] = useState(derivedCurrencyFormat);
  const [dateFormat, setDateFormat] = useState(derivedDateFormat);
  const [idNumberType, setIdNumberType] = useState(derivedIdNumberType);
  const [checkboxesState, setCheckboxesState] = useState<{ [key: string]: boolean }>(DEFAULT_CHECKBOXES_STATE);
  const [showedCheckboxes, setShowedCheckboxes] = useState<string[]>([]);

  const isPhoneNumberFormatChanged = derivedPhoneNumberFormat !== phoneNumberFormat;
  const isCurrencyFormatChanged = derivedCurrencyFormat !== currencyFormat;
  const isDateFormatChanged = derivedDateFormat !== dateFormat;
  const isIdNumberTypeChanged = derivedIdNumberType !== idNumberType;

  const fieldsChangesStatuses = {
    isPhoneNumberFormatChanged,
    isCurrencyFormatChanged,
    isDateFormatChanged,
    isIdNumberTypeChanged,
  };

  const isSaveButtonEnabled =
    phoneNumberFormat &&
    currencyFormat &&
    dateFormat &&
    idNumberType &&
    Object.values(fieldsChangesStatuses).includes(true);

  useEffect(() => {
    dispatch(getOrganizationInfo());
  }, []);

  useEffect(() => {
    setPhoneNumberFormat(derivedPhoneNumberFormat);
    setCurrencyFormat(derivedCurrencyFormat);
    setDateFormat(derivedDateFormat);
    setIdNumberType(derivedIdNumberType);
  }, [derivedPhoneNumberFormat, derivedCurrencyFormat, derivedDateFormat, derivedIdNumberType]);

  const handleSaveRegionSettings = () => {
    const regionSettingsData = {
      phoneNumberFormat,
      currencyFormat,
      dateFormat,
      idNumberType,
      checkboxesState,
    };
    return dispatchRoutine(updateRegionSettings(regionSettingsData));
  };

  const handleChangeCheckboxesState = (name: string) => {
    const oldFiledValue = checkboxesState![name];
    const newCheckboxesState = { ...checkboxesState, [name]: !oldFiledValue };
    setCheckboxesState(newCheckboxesState);
  };

  const handleResetCheckboxesState = () => {
    setCheckboxesState(DEFAULT_CHECKBOXES_STATE);
  };

  const getOverlay = () => {
    if (regionSettingsState.isOpen) {
      return (
        <RegionSettingsChangesConfirmDialog
          onSaveRegionSettings={handleSaveRegionSettings}
          checkboxesState={checkboxesState!}
          onChangeCheckboxesState={handleChangeCheckboxesState}
          showedCheckboxes={showedCheckboxes}
          onResetCheckboxesState={handleResetCheckboxesState}
        />
      );
    }
    return null;
  };

  const handleOpenConfirmDialog = () => {
    if (isPhoneNumberFormatChanged || isCurrencyFormatChanged || isDateFormatChanged || isIdNumberTypeChanged) {
      dispatch(openRegionSettingsConfirmDialog());
      const checkboxesChangeStatus = {
        phoneNumberFormat: isPhoneNumberFormatChanged,
        currencyFormat: isCurrencyFormatChanged,
        dateFormat: isDateFormatChanged,
        idNumberType: isIdNumberTypeChanged,
      };
      const checkboxesToShow = Object.keys(checkboxesChangeStatus).filter(
        (checkboxStatus) => checkboxesChangeStatus[checkboxStatus],
      );
      setShowedCheckboxes(checkboxesToShow);
      return;
    }
    handleSaveRegionSettings();
  };

  const leftNav = makeLeftNavigation(PageName.RegionSettings, ApplicationSectionName.CompanySettings);

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapper>
        <PageContent>
          <RegionSettings
            currencyFormat={currencyFormat}
            phoneNumberFormat={phoneNumberFormat}
            dateFormat={dateFormat}
            idNumberType={idNumberType}
            saveButtonDisabled={!isSaveButtonEnabled}
            onCurrencyFormatChange={({ value }) => setCurrencyFormat(value)}
            onPhoneNumberFormatChange={({ value }) => setPhoneNumberFormat(value)}
            onDateFormatChange={({ value }) => setDateFormat(value)}
            onIdNumberTypeChange={({ value }) => setIdNumberType(value)}
            onOpenConfirmDialog={handleOpenConfirmDialog}
          />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default RegionSettingsPage;
