import {
  LoanOriginationSystemApplicationsApi,
  Application,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  DeleteApplicationIntermediaryAction,
  deleteApplicationIntermediaryFailed,
  deleteApplicationIntermediarySuccess,
} from './ActionCreator';
import { ApplicationPageActionType } from './ActionTypes';

const DeleteApplicationIntermediaryMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, DeleteApplicationIntermediaryAction, ReduxState>(
    ApplicationPageActionType.DeleteApplicationIntermediary,
    deleteApplicationIntermediarySuccess,
    deleteApplicationIntermediaryFailed,
    (action) => {
      return api.deleteIntermediary(action.payload.applicationId, action.payload.variablesToDelete);
    },
  );

export default DeleteApplicationIntermediaryMiddleware;
