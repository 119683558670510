import React, { FC, useState } from 'react';
import styles from './NoteContentCell.module.scss';
import SearchHighlight from 'components/SearchHighlight';
import clsx from 'clsx';

interface NoteContentCellProps {
  note: string;
  searchInputValue: string;
}

const MAX_COLLAPSED_NOTE_LENGTH = 80;

const NoteContentCell: FC<NoteContentCellProps> = ({ note, searchInputValue }) => {
  const [isOpen, setIsOpen] = useState(false);

  const showFullNote = (e: any) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  const hideFullNote = (e: any) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const controlNoteButton = isOpen ? (
    <button type="button" onClick={hideFullNote} className={styles.controlButton}>
      Show Less
    </button>
  ) : (
    <button type="button" onClick={showFullNote} className={styles.controlButton}>
      Read More
    </button>
  );

  return (
    <div className={clsx(isOpen ? styles.noteTextContainerExpanded : styles.noteTextContainerCollapsed)}>
      <p className={isOpen ? styles.noteTextExpanded : styles.noteTextCollapsed}>
        <SearchHighlight search={searchInputValue}>{note}</SearchHighlight>
      </p>
      {note.length > MAX_COLLAPSED_NOTE_LENGTH && controlNoteButton}
    </div>
  );
};

export default NoteContentCell;
