import React from 'react';

import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import { CalendarImage } from 'static/images';

import { DEFAULT_DATE_FORMAT } from './DatePicker';

import styles from './DatePickerInputStub.module.scss';

export interface DatePickerInputStub extends Omit<TextInputStubProps, 'placeholder'> {
  dateFormat?: string;
}

const DatePickerInputStub = ({ dateFormat = DEFAULT_DATE_FORMAT, ...restProps }: DatePickerInputStub) => {
  const renderPlaceholder = () => (
    <>
      <div>{dateFormat}</div>
      <CalendarImage />
    </>
  );

  return <TextInputStub {...restProps} stubInputClassName={styles.stubInput} placeholder={renderPlaceholder()} />;
};

export default DatePickerInputStub;
