import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { hideSnack } from './ActionCreator';
import { ApplicationDocumentsActionType } from 'LoanOriginationSystemApplicationPage/Documents/ActionTypes';
import {
  DocumentDownloadStatusSnackContent,
  EmailAttachmentsDownloadStatusSnackContent,
  SingleEmailAttachmentDownloadStatusSnackContent,
  SnackbarComponentTypes,
  SnackType,
} from './Types';
import { ReduxState } from 'types/redux';
import {
  DownloadApplicationDocumentFailureAction,
  DownloadApplicationDocumentSuccessAction,
  DownloadArchiveOfApplicationDocumentsFailureAction,
  DownloadArchiveOfApplicationDocumentsSuccessAction,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';
import {
  DownloadEmailAttachmentsFailureAction,
  DownloadEmailAttachmentsSuccessAction,
} from 'LoanOriginationSystemEmails/ActionCreator';

export const DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION = 1500;

export const SetDownloadDocumentSnackLifeTimeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (
  action:
    | DownloadApplicationDocumentSuccessAction
    | DownloadApplicationDocumentFailureAction
    | DownloadEmailAttachmentsSuccessAction
    | DownloadEmailAttachmentsFailureAction
    | DownloadArchiveOfApplicationDocumentsSuccessAction
    | DownloadArchiveOfApplicationDocumentsFailureAction,
) => {
  const result = next(action);

  if (
    action.type === ApplicationDocumentsActionType.DownloadDocumentSuccess ||
    action.type === ApplicationDocumentsActionType.DownloadDocumentFailure ||
    action.type === ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsSuccess ||
    action.type === ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsFailure
  ) {
    const { documentDownloadingId } = action.payload;
    const { snackbar } = getState();

    const documentDownloadingStateSnacks = snackbar.filter(
      ({ content }) =>
        content.componentType === SnackbarComponentTypes.ApplicationDownloadDocumentComponent ||
        content.componentType === SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
    ) as SnackType<DocumentDownloadStatusSnackContent>[];

    const currentSnack = documentDownloadingStateSnacks.find(
      (snack) => snack.content.props.documentDownloadingId === documentDownloadingId,
    );

    if (currentSnack) {
      setTimeout(() => {
        dispatch(hideSnack(currentSnack.id));
      }, DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION);
    }
  }
  if (
    action.type === LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsSuccess ||
    action.type === LoanOriginationSystemEmailsActionTypes.DownloadEmailAttachmentsFailure
  ) {
    const { attachmentId } = action.payload;
    const { snackbar } = getState();

    const attachmentDownloadingStateSnacks = snackbar.filter(
      ({ content }) =>
        content.componentType === SnackbarComponentTypes.ApplicationEmailAttachmentsComponent ||
        content.componentType === SnackbarComponentTypes.ApplicationSingleEmailAttachmentComponent,
    ) as SnackType<EmailAttachmentsDownloadStatusSnackContent | SingleEmailAttachmentDownloadStatusSnackContent>[];

    const currentSnack = attachmentDownloadingStateSnacks.find(
      (snack) => snack.content.props.attachmentDownloadingId === attachmentId,
    );

    if (currentSnack) {
      setTimeout(() => {
        dispatch(hideSnack(currentSnack.id));
      }, DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION);
    }
  }
  return result;
}) as Middleware;

export default SetDownloadDocumentSnackLifeTimeMiddleware;
