import React, { FC, useState } from 'react';
import styles from './UsersFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { useDispatch, batch, useSelector } from 'react-redux';
import { OrganizationUsersState, toggleFiltersOrganizationUsersPopUp } from 'OrganizationUsers/OrganizationUserStore';
import AutoCompletion from 'components/AutoCompletion';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import { Option } from 'components/SelectInput/SelectInput';
import { ReduxState } from 'types/redux';
import { OrganizationUsersSortingType } from 'api/Core/UserApi';

const PERMISSIONS_TYPE = [
  {
    name: 'User',
    value: UserRoleName.User,
  },
  {
    name: 'Admin',
    value: UserRoleName.Admin,
  },
  {
    name: 'Owner',
    value: UserRoleName.Owner,
  },
];

const PHONE_AUTHENTICATION = [
  {
    name: 'Enabled',
    value: 'enabled',
  },
  {
    name: 'Disabled',
    value: 'disabled',
  },
];

const EMAIL_VERIFIED = [
  {
    name: 'Yes',
    value: 'yes',
  },
  {
    name: 'No',
    value: 'no',
  },
];

const INPUT_EMPTY_VALUE = '';

interface UsersFilterProps {
  getOrganizationUsers: (
    userOffset: number,
    userItemsPerPage: number,
    userSearch: string,
    userShowInactive: boolean,
    userPermissionType: UserRoleName | null,
    userPhoneAuthentication: boolean | null,
    userEmailVerified: boolean | null,
    userSortingType: OrganizationUsersSortingType,
  ) => void;
  resetAllFilters: () => void;
}

const UsersFilter: FC<UsersFilterProps> = ({ getOrganizationUsers, resetAllFilters }) => {
  const dispatch = useDispatch();

  const {
    page,
    itemsPerPage,
    searchInputValue,
    showInactive,
    permissionType,
    emailVerified,
    phoneAuthentication,
    sortingType,
  } = useSelector<ReduxState, OrganizationUsersState>((state) => state.organizationUsers);

  const isNotNull = (value: UserRoleName | boolean | null): boolean => value !== null;

  const getInitialPhoneAuthentication = () => {
    if (!isNotNull(phoneAuthentication)) {
      return INPUT_EMPTY_VALUE;
    }
    return phoneAuthentication ? 'enabled' : 'disabled';
  };

  const getInitialEmailVerified = () => {
    if (!isNotNull(emailVerified)) {
      return INPUT_EMPTY_VALUE;
    }
    return emailVerified ? 'yes' : 'no';
  };

  const [permissionTypeFilter, setPermissionTypeFilter] = useState<UserRoleName | string>(
    isNotNull(permissionType) ? (permissionType as UserRoleName) : INPUT_EMPTY_VALUE,
  );
  const [phoneAuthenticationFilter, setPhoneAuthenticationFilter] = useState<string>(getInitialPhoneAuthentication);
  const [emailVerifiedFilter, setEmailVerifiedFilter] = useState(getInitialEmailVerified);

  const handlePermissionTypeChange = (option: Option) => {
    setPermissionTypeFilter(option.value);
  };

  const handlePhoneAuthenticationChange = (option: Option) => {
    setPhoneAuthenticationFilter(option.value);
  };

  const handleEmailVerifiedChange = (option: Option) => {
    setEmailVerifiedFilter(option.value);
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersOrganizationUsersPopUp({ popUpOpeningStatus: false }));
  };

  const onClearFiltersClick = () => {
    batch(() => {
      resetAllFilters();
      onFiltersPopupClose();
    });
  };

  const onFiltersApply = () => {
    batch(() => {
      getOrganizationUsers(
        page,
        itemsPerPage,
        searchInputValue,
        showInactive,
        (permissionTypeFilter as UserRoleName) || null,
        phoneAuthenticationFilter === INPUT_EMPTY_VALUE ? null : phoneAuthenticationFilter === 'enabled',
        emailVerifiedFilter === INPUT_EMPTY_VALUE ? null : emailVerifiedFilter === 'yes',
        sortingType,
      );
      onFiltersPopupClose();
    });
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Permissions Type"
        onChange={handlePermissionTypeChange}
        value={permissionTypeFilter}
        options={PERMISSIONS_TYPE}
      />
      <AutoCompletion
        labelTitle="Phone Authentication"
        onChange={handlePhoneAuthenticationChange}
        value={phoneAuthenticationFilter}
        options={PHONE_AUTHENTICATION}
      />
      <AutoCompletion
        labelTitle="Email Verified"
        onChange={handleEmailVerifiedChange}
        value={emailVerifiedFilter}
        options={EMAIL_VERIFIED}
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default UsersFilter;
