import { AnyAction } from 'redux';
import { FormLayoutData } from 'api/Types';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  LOAD_BORROWERS_SUGGESTIONS,
  LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
  LOAD_BORROWERS_SUGGESTIONS_FAILED,
  SELECT_BORROWER,
  SELECT_BORROWER_TO_EDIT,
  SELECT_CO_BORROWER,
  CREATE_APPLICATION,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILED,
  LoanOriginationSystemCreateApplicationActionType,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetBorrowersSuggestionsActionPayload {
  filter: BorrowerSuggestionFilter;
}

export interface GetIntermediarySuggestionsActionPayload {
  filter: IntermediarySuggestionFilter;
}

export interface LoadBorrowersSuggestionsAction extends AnyAction {
  type: typeof LOAD_BORROWERS_SUGGESTIONS;
  payload: GetBorrowersSuggestionsActionPayload;
}

export interface LoadIntermediarySuggestionsAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestions;
  payload: GetIntermediarySuggestionsActionPayload;
}

export interface LoadBorrowersSuggestionsSuccessAction extends AnyAction {
  type: typeof LOAD_BORROWERS_SUGGESTIONS_SUCCESS;
  payload: {
    suggestions: Borrower[];
  };
}

export interface LoadIntermediarySuggestionsSuccessAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess;
  payload: {
    suggestions: Intermediary[];
  };
}

export interface SelectBorrowerAction extends AnyAction {
  type: typeof SELECT_BORROWER;
  payload: {
    borrower: Borrower | null;
  };
}

export interface SelectIntermediaryAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediary;
  payload: {
    intermediary: Intermediary | null;
  };
}

export interface SelectCoBorrowerAction extends AnyAction {
  type: typeof SELECT_CO_BORROWER;
  payload: {
    borrower: Borrower | null;
  };
}

export interface CreateApplicationAction extends AnyAction {
  type: typeof CREATE_APPLICATION;
  payload: {
    borrower: FormLayoutData | Borrower;
    coBorrower?: FormLayoutData | Borrower;
    intermediary?: FormLayoutData;
    applicationDetailsFormData: FormLayoutData;
    borrowerType: BorrowerType;
    productId: string;
  };
}

export interface CreateApplicationSuccessAction extends AnyAction {
  type: typeof CREATE_APPLICATION_SUCCESS;
  payload: {
    application: Application;
  };
}

export interface SelectBorrowerToEdit {
  type: typeof SELECT_BORROWER_TO_EDIT;
  payload: Borrower | null;
}

export interface SelectIntermediaryToEditAction extends AnyAction {
  type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediaryToEdit;
  payload: {
    intermediary: Intermediary | null;
  };
}

export const loadBorrowersSuggestions = (filter: BorrowerSuggestionFilter): LoadBorrowersSuggestionsAction => {
  return {
    type: LOAD_BORROWERS_SUGGESTIONS,
    payload: {
      filter,
    },
  };
};

export const loadBorrowersSuggestionsSuccess = (suggestions: Borrower[]): LoadBorrowersSuggestionsSuccessAction => {
  return {
    type: LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
    payload: {
      suggestions,
    },
  };
};

export const loadBorrowersSuggestionsFailed = FailedActionCreator(LOAD_BORROWERS_SUGGESTIONS_FAILED);

export const loadIntermediarySuggestions = (
  filter: IntermediarySuggestionFilter,
): LoadIntermediarySuggestionsAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestions,
    payload: {
      filter,
    },
  };
};

export const loadIntermediarySuggestionsSuccess = (
  suggestions: Intermediary[],
): LoadIntermediarySuggestionsSuccessAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess,
    payload: {
      suggestions,
    },
  };
};

export const loadIntermediarySuggestionsFailed = FailedActionCreator(
  LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsFailed,
);

export const selectBorrower = (borrower: Borrower | null): SelectBorrowerAction => {
  return {
    type: SELECT_BORROWER,
    payload: {
      borrower,
    },
  };
};

export const selectIntermediary = (intermediary: Intermediary | null): SelectIntermediaryAction => {
  return {
    type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediary,
    payload: {
      intermediary,
    },
  };
};

export const selectCoBorrower = (borrower: Borrower | null): SelectCoBorrowerAction => {
  return {
    type: SELECT_CO_BORROWER,
    payload: {
      borrower,
    },
  };
};

export const createApplication = (
  productId: string,
  borrowerType: BorrowerType,
  borrower: FormLayoutData | Borrower,
  coBorrower: FormLayoutData | Borrower | undefined,
  intermediary: FormLayoutData | undefined,
  applicationDetailsFormData: FormLayoutData,
): CreateApplicationAction => {
  return {
    type: CREATE_APPLICATION,
    payload: {
      productId,
      borrower,
      coBorrower,
      intermediary,
      applicationDetailsFormData,
      borrowerType,
    },
  };
};

export const createApplicationSuccess = (application: Application): CreateApplicationSuccessAction => {
  return {
    type: CREATE_APPLICATION_SUCCESS,
    payload: {
      application,
    },
  };
};

export const selectBorrowerToEdit = (borrower: Borrower | null): SelectBorrowerToEdit => ({
  type: SELECT_BORROWER_TO_EDIT,
  payload: borrower,
});

export const selectIntermediaryToEdit = (intermediary: Intermediary | null): SelectIntermediaryToEditAction => ({
  type: LoanOriginationSystemCreateApplicationActionType.SelectIntermediaryToEdit,
  payload: {
    intermediary,
  },
});

export const createApplicationFailed = FailedActionCreator(CREATE_APPLICATION_FAILED);
