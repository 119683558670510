import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { GetFilterDataAction, getFilterDataSuccess, getFilterDataFailure } from './ActionCreator';
import { FilterInitialData } from './Types';
import { LoanOriginationSystemApplicationReportingApi } from 'api/LoanOriginationSystem/LoanOriginationSystemReportingApi';

const GetReportingFilterInitialDataMiddleware = (api: LoanOriginationSystemApplicationReportingApi) =>
  Fetcher<FilterInitialData, GetFilterDataAction>(
    LoanOriginationSystemReportingActionType.GetFilterData,
    getFilterDataSuccess,
    getFilterDataFailure,
    async () => {
      return api.getFiltersInitialState();
    },
  );

export default GetReportingFilterInitialDataMiddleware;
