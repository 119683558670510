import React, { useEffect } from 'react';
import ReportingDashboard from 'components/LoanOriginationSystem/ReportingDashboard/ReportingDashboard';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import ReportingFilter from 'components/LoanOriginationSystem/ReportingFilter/ReportingFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeTimeDelta,
  closeReportingFiltersBar,
  getFilterData,
  openReportingFiltersBar,
  resetFilterState,
} from 'LoanOriginationSystemReporting/Filters/ActionCreator';
import { ReportingFilterBarState, ReportingTimeDeltas } from 'LoanOriginationSystemReporting/Filters/Types';
import { Option } from 'components/SelectInput/SelectInput';
import { getReportingData } from 'LoanOriginationSystemReporting/Applications/ActionsCreator';
import { ReportingState } from 'LoanOriginationSystemReporting/Applications/Types';
import { ReduxState } from 'types/redux';
import { dateDeltaFormat } from 'LoanOriginationSystemReporting/Filters/utils';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';

const Reporting = () => {
  const dispatch = useDispatch();

  const {
    open,
    statuses,
    selectedStatuses,
    selectedIntermediaries,
    selectedLabels,
    selectedProducts,
    selectedTeamMembers,
    timeDelta,
  } = useSelector<ReduxState, ReportingFilterBarState>((state) => state.reportingFilterBar);

  const isInitialFilterState =
    !selectedStatuses.length &&
    !selectedIntermediaries.length &&
    !selectedLabels.length &&
    !selectedProducts.length &&
    !selectedTeamMembers.length &&
    timeDelta === ReportingTimeDeltas.AllTime;

  const handleGetReportingData = (derivedTimeDelta?: ReportingTimeDeltas) => {
    dispatch(
      getReportingData({
        statusIds: selectedStatuses,
        intermediaryIds: selectedIntermediaries,
        labelIds: selectedLabels,
        productIds: selectedProducts,
        teamMemberIds: selectedTeamMembers,
        timeDelta: dateDeltaFormat(derivedTimeDelta || timeDelta),
      }),
    );
    dispatch(closeReportingFiltersBar());
  };

  const onClearFiltersClick = () => {
    dispatch(resetFilterState());
    dispatch(
      getReportingData({
        statusIds: [],
        intermediaryIds: [],
        labelIds: [],
        productIds: [],
        teamMemberIds: [],
        timeDelta: dateDeltaFormat(ReportingTimeDeltas.AllTime),
      }),
    );
    dispatch(closeReportingFiltersBar());
  };

  const { reportingEntitiesMap, loading, currencyType } = useSelector<ReduxState, ReportingState>(
    (state) => state.reporting,
  );

  const leftNav = makeLeftNavigation(NavigationLinkId.Reporting, ApplicationSectionName.LoanOriginationSystem);

  const rightSidePopupView = open && (
    <ReportingFilter onApplyClick={handleGetReportingData} onClearFiltersClick={onClearFiltersClick} />
  );

  const handleOpenFilterPopup = () => {
    dispatch(openReportingFiltersBar());
  };

  useEffect(() => {
    dispatch(getFilterData());
    handleGetReportingData();
  }, []);

  const handleTimeDeltaChange = (option: Option) => {
    dispatch(changeTimeDelta(option.value as ReportingTimeDeltas));
    handleGetReportingData(option.value as ReportingTimeDeltas);
  };

  return (
    <MainLayout leftNav={leftNav} rightSidePopupView={rightSidePopupView}>
      <PageWrapperWithFooter>
        <PageContent>
          <ReportingDashboard
            reportingEntitiesMap={reportingEntitiesMap}
            statuses={statuses}
            onOpenFilterPopup={handleOpenFilterPopup}
            timeDelta={timeDelta}
            onTimeDeltaChange={handleTimeDeltaChange}
            loading={loading}
            isInitialFilterState={isInitialFilterState}
            onClearFiltersClick={onClearFiltersClick}
            currency={getCurrencySymbol(currencyType)}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Reporting;
