import React, { FC, useEffect, useMemo } from 'react';
import { ESignTemplateField, ESignTemplateFieldType } from 'api/Core/ESignTemplatesApi';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './MappingVariablesPopUp.module.scss';

interface MappingVariablesPopUpProps {
  fields: ESignTemplateField[];
  templateVariablesMapping: Record<string, string>;
  onClose: () => void;
  isSaveInProgress?: boolean;
  onOpenCreateVariablePopup: () => void;
  onSave: (variablesMapping: Record<string, string>) => void;
}

const getDataTypeByESignTemplateFieldType = (fieldType: ESignTemplateFieldType) => {
  switch (fieldType) {
    case ESignTemplateFieldType.Text: {
      return undefined;
    }
    case ESignTemplateFieldType.Checkbox: {
      return 'Boolean';
    }
    case ESignTemplateFieldType.Radio: {
      return 'String';
    }
    default: {
      return null as never;
    }
  }
};

const MappingVariablesPopUp: FC<MappingVariablesPopUpProps> = ({
  onClose,
  fields,
  templateVariablesMapping,
  isSaveInProgress,
  onSave,
  onOpenCreateVariablePopup,
}) => {
  const [variablesMapping, setVariablesMapping] = React.useState(templateVariablesMapping);

  useEffect(() => {
    setVariablesMapping(templateVariablesMapping);
  }, [templateVariablesMapping]);

  const handleVariableSelect = (field: ESignTemplateField, { value }: Option) => {
    if (!value) {
      const newVariablesMapping = { ...variablesMapping };

      delete newVariablesMapping[field.fieldId];

      setVariablesMapping(newVariablesMapping);

      return;
    }

    setVariablesMapping({ ...variablesMapping, [field.fieldId]: value });
  };

  const renderInputs = () => {
    return fields.map((field, index) => (
      <VariableSelector
        labelTitle={field.name}
        value={variablesMapping[field.fieldId]}
        onChange={(option) => handleVariableSelect(field, option)}
        key={field.fieldId}
        dataType={getDataTypeByESignTemplateFieldType(field.type)}
        link={index === 0 && <CreateNewVariableLink onClick={onOpenCreateVariablePopup} />}
      />
    ));
  };

  const handleSave = () => {
    onSave(variablesMapping);
  };

  const isSaveEnabled = useMemo(() => {
    const variablesMappingKeys = Object.keys(variablesMapping);

    return (
      variablesMappingKeys.length !== Object.keys(templateVariablesMapping).length ||
      variablesMappingKeys.some((fieldId) => variablesMapping[fieldId] !== templateVariablesMapping[fieldId])
    );
  }, [variablesMapping, templateVariablesMapping]);

  return (
    <PopUp title="Variable Mapping" closePopUp={onClose}>
      <PopUpContent hasTopMargin>
        {renderInputs()}
        <Button
          kind="primary"
          size="form"
          disabled={!isSaveEnabled}
          onClick={handleSave}
          isLoading={isSaveInProgress}
          className={styles.saveButton}
        >
          Save
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default MappingVariablesPopUp;
