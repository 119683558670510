import Debouncer from 'middlewares/Debouncer';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getIntermediaries } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './ActionTypes';

export default Debouncer<UserInfo[]>(
  (payload, getState) => {
    const { loanOriginationSystemIntermediariesState } = getState();
    const { filters, sortingType } = loanOriginationSystemIntermediariesState;

    return getIntermediaries(filters, sortingType);
  },
  [LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers],
);
