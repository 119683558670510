import docuSignTemplatesPagination from 'components/DocuSign/pagination';
import { ESignTemplatesApi } from 'api/Core/ESignTemplatesApi';
import { saveESignTemplates } from 'ESignTemplates/ActionCreator';

export default (api: ESignTemplatesApi) => {
  return docuSignTemplatesPagination.getItemsFetcherMiddleware(async (params, dispatch) => {
    const { items } = await api.getAll(
      {
        members: params.selectedMembers,
        status: params.selectedStatus,
        integrationId: params.integrationId,
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
        createdDateRange: params.createdDateRange,
        updatedDateRange: params.updatedDateRange,
      },
      params.sortingType,
    );

    dispatch(saveESignTemplates(items));

    return items.map((item) => item.id);
  });
};
