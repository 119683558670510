import React from 'react';
import SIZES from 'components/SendGrid/TemplatesTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';

const SkeletonApplicationTableBody = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.NAME}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.UPDATED}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.STATUS}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonApplicationTableBody;
