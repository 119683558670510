import React, { useEffect, useState } from 'react';
import { VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import CustomCheckbox from 'components/CustomCheckbox';
import styles from './EditRequiredFieldsPopup.module.scss';

export interface EditRequiredFieldsPopupProps {
  configurations: VariableConfiguration[];
  description: string;
  onClose: () => void;
  onSave: (requiredFields: string[]) => void;
  isUpdatingInProgress?: boolean;
  alwaysDisabledFields?: string[];
  formatDisplayName?: (systemName: string, title: string) => string;
}

const getRequiredFields = (configurations: VariableConfiguration[]) => {
  return configurations.filter(({ required }) => required).map(({ variable }) => variable.systemName);
};

const EditRequiredFieldsPopup = ({
  configurations,
  description,
  onClose,
  onSave,
  isUpdatingInProgress,
  alwaysDisabledFields,
  formatDisplayName,
}: EditRequiredFieldsPopupProps) => {
  const [requiredFields, setRequiredFields] = useState<string[]>(getRequiredFields(configurations));
  const sortedConfigurations = configurations.sort((firstConfiguration, secondConfiguration) => {
    return (
      firstConfiguration.position - secondConfiguration.position ||
      firstConfiguration.column - secondConfiguration.column
    );
  });

  useEffect(() => {
    setRequiredFields(getRequiredFields(configurations));
  }, [configurations]);

  const handleCheckboxChange = (systemName: string, checked: boolean) => {
    setRequiredFields(
      checked ? [...requiredFields, systemName] : requiredFields.filter((field) => field !== systemName),
    );
  };

  const renderCheckbox = ({ id, variable }: VariableConfiguration) => {
    return (
      <div key={id} className={styles.checkboxContainer}>
        <CustomCheckbox
          className={styles.checkbox}
          label={
            formatDisplayName ? formatDisplayName(variable.systemName, variable.displayName) : variable.displayName
          }
          checked={requiredFields.includes(variable.systemName)}
          onChange={(_event, checked) => handleCheckboxChange(variable.systemName, checked)}
          disabled={alwaysDisabledFields?.includes(variable.systemName)}
        />
      </div>
    );
  };

  return (
    <PopUp closePopUp={onClose} title="Required Fields">
      <PopUpContent hasTopMargin>
        <div className={styles.description}>{description}</div>
        <div className={styles.checkboxesContainer}>{sortedConfigurations.map(renderCheckbox)}</div>
        <Button
          onClick={() => onSave(requiredFields)}
          kind="primary"
          size="form"
          isLoading={isUpdatingInProgress}
          className={styles.saveChangesButton}
        >
          Save Changes
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditRequiredFieldsPopup;
