import { round } from 'lodash';

const isEmptyNumericValue = (value: string) => value === '' || value === '0';

export const DecimalPartLimiter = (maxDecimalPartLength: number) => (value: string): string => {
  const firstDotPosition = value.indexOf('.');
  if (firstDotPosition === -1) {
    return value;
  }
  const decimalPartStartIndex = firstDotPosition + 1;
  return (
    value.slice(0, decimalPartStartIndex) +
    value.slice(decimalPartStartIndex, decimalPartStartIndex + maxDecimalPartLength)
  );
};

export const numberToPercentage = (value: string): string => {
  if (isEmptyNumericValue(value)) {
    return value;
  }
  const dotPosition = value.indexOf('.');
  const decimalPartLength = dotPosition === -1 ? 0 : value.length - dotPosition - 1;
  return `${round(Number(value) * 100, decimalPartLength - 2)}`;
};

export const percentageToNumber = (value: string): string => {
  if (isEmptyNumericValue(value) || value === '-') {
    return value;
  }
  const dotPosition = value.indexOf('.');
  const decimalPartLength = dotPosition === -1 ? 0 : value.length - dotPosition - 1;
  return `${round(Number(value) * 0.01, decimalPartLength + 2)}`;
};
