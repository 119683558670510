import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemTasksApi, Task } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { EditTaskAction, editTaskFailure, editTaskSuccess } from './ActionCreator';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';

const EditTaskMiddleware = (api: LoanOriginationSystemTasksApi) =>
  Fetcher<Task, EditTaskAction>(
    LoanOriginationSystemEditTaskActionTypes.EditTask,
    editTaskSuccess,
    editTaskFailure,
    async (action) => {
      return api.editTask(action.payload.id, action.payload.editTaskData);
    },
  );

export default EditTaskMiddleware;
