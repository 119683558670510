import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { RuleBuilderCoreData } from 'RuleCreate/Types';

const getFormattedRulesList = (rulesList: RuleBuilderCoreData[]) =>
  rulesList.reduce((accumulator, currentRule, index) => {
    const {
      firstOperandType,
      firstOperandValue,
      firstOperandVariableId,
      secondOperandType,
      secondOperandValue,
      secondOperandVariableId,
      selectedComparison,
      sourceVariableId,
      optionSeparator,
    } = currentRule;

    const operandsData = {};

    if (selectedComparison === 'RANGE') {
      const minimumValueComparison =
        firstOperandType === ComparisonOperandType.VALUE ? firstOperandValue : firstOperandVariableId;
      operandsData[`rule*${index}*state_property_attribute_value_minimum`] = minimumValueComparison;
      operandsData[`rule*${index}*state_property_attribute_value_minimum_type`] = firstOperandType.toLowerCase();

      const maximumValueComparison =
        secondOperandType === ComparisonOperandType.VALUE ? secondOperandValue : secondOperandVariableId;
      operandsData[`rule*${index}*state_property_attribute_value_maximum`] = maximumValueComparison;
      operandsData[`rule*${index}*state_property_attribute_value_maximum_type`] = secondOperandType.toLowerCase();
    }

    if (selectedComparison !== 'RANGE') {
      const valueComparison =
        firstOperandType === ComparisonOperandType.VALUE ? firstOperandValue : firstOperandVariableId;
      operandsData[`rule*${index}*state_property_attribute_value_comparison`] = valueComparison;
      operandsData[`rule*${index}*state_property_attribute_value_comparison_type`] = firstOperandType.toLowerCase();
    }

    return {
      ...accumulator,
      [`rule*${index}*condition_test`]: selectedComparison,
      [`rule*${index}*state_property_attribute`]: sourceVariableId,
      [`rule*${index}*optionSeparator`]: optionSeparator,
      ...operandsData,
    };
  }, {});

export default getFormattedRulesList;
