import React from 'react';
import Button from 'components/Button';
import SearchInput from 'components/SearchInput';
import styles from './BorrowersDashboardHeader.module.scss';
import FilterButton from 'components/FilterButton';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

interface BorrowersDashboardHeaderProps {
  searchInputValue: string;
  onSearch: (search: string) => void;
  onCreateNewBorrower: () => void;
  hideSubtitle?: boolean;
  onFilterButtonClick: () => void;
  filtersChanged: boolean;
  members: UserInfo[];
  selectedMembers: UserInfo[];
  onMemberFilterChange: (members: UserInfo[]) => void;
}

const BorrowersDashboardHeader = ({
  searchInputValue,
  onSearch,
  onCreateNewBorrower,
  hideSubtitle,
  onFilterButtonClick,
  filtersChanged,
  members,
  selectedMembers,
  onMemberFilterChange,
}: BorrowersDashboardHeaderProps) => {
  const onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => onSearch(event.target.value);
  const onSearchClear = () => onSearch('');

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2 className={styles.headerTitle}>Borrowers</h2>
        <Button kind="primary" size="default" className={styles.createBorrowerButton} onClick={onCreateNewBorrower}>
          Add Borrower
        </Button>
      </div>
      {!hideSubtitle && (
        <div className={styles.subTitle}>
          <SearchInput
            placeholder="Search"
            containerClassName={styles.searchInput}
            value={searchInputValue}
            onChange={onSearchInputChange}
            onClear={onSearchClear}
          />
          <UserFilter users={members} selectedUsers={selectedMembers} onChange={onMemberFilterChange} />
          <FilterButton
            onClick={onFilterButtonClick}
            active={filtersChanged}
            containerClassName={styles.filterButtonContainer}
          />
        </div>
      )}
    </div>
  );
};

export default BorrowersDashboardHeader;
