import { ItemsStateWithPagination } from 'pagination';
import { ApplicationNote } from 'api/Types';
import { ApplicationNotesSortingType } from 'api/LoanOriginationSystem/ApplicationNotesApi';

export enum ApplicationNotePopUpType {
  Add = 'add',
  Delete = 'delete',
  Update = 'update',
}

export interface ApplicationNotesState extends ItemsStateWithPagination<ApplicationNote> {
  search: string;
  sortingType: ApplicationNotesSortingType;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<ApplicationNote>;
  };
  currentNote: null | string;
  isPopupOpen: boolean;
  popUpType: ApplicationNotePopUpType;
  isLoading: boolean;
}
