import { ComponentTypeWithProps } from 'handlers/notification/notificationTypes';
import { ApplicationDocumentDownloadSnackbarProps } from 'components/ApplicationDocumentDownloadSnackbar';
import { ApplicationEmailAttachmentsDownloadSnackbarProps } from 'components/ApplicationEmailAttachmentsDownloadSnackbar';
import { ApplicationSingleEmailAttachmentDownloadSnackbarProps } from 'components/ApplicationSingleEmailAttachmentDownloadSnackbar';
import { ApplicationDocumentsDownloadSnackbarProps } from 'components/ApplicationDocumentsDownloadSnackbar';

export enum SnackbarComponentTypes {
  ApplicationDownloadDocumentComponent = 'ApplicationDownloadDocument',
  ApplicationDownloadDocumentsComponent = 'ApplicationDownloadDocuments',
  ApplicationEmailAttachmentsComponent = 'ApplicationEmailAttachments',
  ApplicationSingleEmailAttachmentComponent = 'ApplicationSingleEmailAttachment',
}

export type DocumentDownloadStatusSnackContent = ComponentTypeWithProps<
  SnackbarComponentTypes.ApplicationDownloadDocumentComponent,
  ApplicationDocumentDownloadSnackbarProps
>;

export type DocumentsDownloadStatusSnackContent = ComponentTypeWithProps<
  SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
  ApplicationDocumentsDownloadSnackbarProps
>;

export type EmailAttachmentsDownloadStatusSnackContent = ComponentTypeWithProps<
  SnackbarComponentTypes.ApplicationEmailAttachmentsComponent,
  ApplicationEmailAttachmentsDownloadSnackbarProps
>;

export type SingleEmailAttachmentDownloadStatusSnackContent = ComponentTypeWithProps<
  SnackbarComponentTypes.ApplicationSingleEmailAttachmentComponent,
  ApplicationSingleEmailAttachmentDownloadSnackbarProps
>;

export type SnackbarComponents =
  | DocumentDownloadStatusSnackContent
  | EmailAttachmentsDownloadStatusSnackContent
  | SingleEmailAttachmentDownloadStatusSnackContent
  | DocumentsDownloadStatusSnackContent;

export interface SnackType<ContentComponent> {
  id: string;
  title: string;
  content: ContentComponent;
}
