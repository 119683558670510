const INITIAL_POSITION = 65535;
const POSITION_INCREMENT = INITIAL_POSITION + 1;

const getPosition = <WithPosition extends { position: number }[]>(items: WithPosition, index: number) => {
  if (items.length === 0) {
    return INITIAL_POSITION;
  }

  if (index === 0) {
    return items[index].position / 2;
  }

  if (items.length === index) {
    return items[index - 1].position + POSITION_INCREMENT;
  }

  return (items[index].position + items[index - 1].position) / 2;
};

export default getPosition;
