import clsx from 'clsx';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableRow from 'components/Table/TableRow';
import times from 'lodash/times';
import React from 'react';
import styles from './ScrollTablePartSkeleton.module.scss';

const renderColumnPart = () => {

  const renderColumnDataSkeleton = () => {
    return (
      <TableRow disableHover className={styles.dashedBorderBottom}>
        <TableBodyCell width={74} pixels>
          <SkeletonRectangle width="70px" color="primary6" height="16px" />
        </TableBodyCell>
      </TableRow>
    )
  };

  return (
    <>
      {times(5, renderColumnDataSkeleton)}
      <TableRow disableHover >
        <TableBodyCell width={74} pixels>
          <SkeletonRectangle width="70px" color="primary20" height="16px" />
        </TableBodyCell>
      </TableRow>
    </>
  )
};

const renderColumn = (index: number) => {
  const lastElement = index
  return (
    <div className={clsx(styles.column, lastElement && styles.fullWidth)}>
      <TableHead >
        <TableHeadCell width={74} pixels>
          <SkeletonRectangle width="70px" color="primary6" height="16px" />
        </TableHeadCell>
      </TableHead>
      {times(2, renderColumnPart)}
    </div>
  )
};

const ScrollTablePartSkeleton = () => {
  return (
    <div className={styles.container}>
      {times(9, renderColumn)}
    </div>
  )
};

export default ScrollTablePartSkeleton;
