import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { DuplicateProductSuccessAction, getProducts } from './ActionCreator';

const isDuplicateProductSuccessAction = (action: AnyAction): action is DuplicateProductSuccessAction =>
  action.type === LoanOriginationSystemProductsActionType.DuplicateProductSuccess;

export const reloadProductsOnDuplicateProductSuccessMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDuplicateProductSuccessAction(action)) {
    const { loanOriginationSystemProducts } = getState();
    const { filters } = loanOriginationSystemProducts;

    dispatch(getProducts(filters));
  }

  return result;
};

export const notifyOnDuplicateProductSuccessMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isDuplicateProductSuccessAction(action)) {
    const { loanOriginationSystemProducts } = getState();
    const { productToDuplicate } = loanOriginationSystemProducts;

    notification.createNotification(
      getMessage(MessageType.ProductDuplicated, {
        productName: productToDuplicate.name,
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};
