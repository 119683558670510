import React, { CSSProperties, useEffect, useState } from 'react';
import arrayMove from 'utils/arrayMove';
import DndListContext from 'components/DndList/DndListContext';
import DroppableList, { DroppableListItemProps } from 'components/DndList/DroppableList';
import { BeforeCapture, DropResult } from 'react-beautiful-dnd';

export interface DndListProps<Item> extends DroppableListItemProps<Item> {
  items: Item[];
  handleReorder?: (items: Item[], dropResult: DropResult) => void;
  handleDragEnd?: (result: DropResult) => void;
  handleBeforeCapture?: (event: BeforeCapture) => void;
  direction?: 'vertical' | 'horizontal';
  handleChildListReorder?: (dropResult: DropResult) => void;
}

const DndList = <Item extends { id: string | number }>({
  droppableId,
  items,
  handleReorder,
  handleChildListReorder,
  handleBeforeCapture,
  handleDragEnd,
  direction = 'vertical',
  ...droppableProps
}: DndListProps<Item>) => {
  const [listItems, setListItems] = useState<Item[]>(items);

  useEffect(() => {
    setListItems(items);
  }, [items]);

  const onReorder = (dropResult: DropResult) => {
    if (!dropResult.destination || dropResult.source.index === dropResult.destination.index) {
      return;
    }

    if (dropResult.source.droppableId !== droppableId && handleChildListReorder) {
      handleChildListReorder(dropResult);
      return;
    }
    const updatedListItems = arrayMove(listItems, dropResult.source.index, dropResult.destination.index);
    setListItems(updatedListItems);
    handleReorder!(updatedListItems, dropResult);
  };

  const renderDroppablePart = (placeholderStyles?: CSSProperties | null) => {
    return (
      <DroppableList
        direction={direction}
        placeholderStyles={placeholderStyles}
        items={listItems}
        droppableId={droppableId}
        {...droppableProps}
      />
    );
  };

  return (
    <DndListContext
      onReorder={onReorder}
      onDragEnd={handleDragEnd}
      onBeforeCapture={handleBeforeCapture}
      direction={direction}
    >
      {(placeholderStyles) => renderDroppablePart(placeholderStyles)}
    </DndListContext>
  );
};

export default DndList;
