import React, { useEffect, useState } from 'react';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';

interface ChangeHandler {
  (value: string): void;
}

interface TemporaryValueInputProps {
  value: string;
  handleChange: ChangeHandler;
  placeholderText?: string;
  saveEmptyValue?: boolean;
  notEditable?: boolean;
  notEditableErrorNotificationText?: string;
  emptyValueErrorNotificationText?: string;
}

export interface HtmlInputrops {
  [key: string]: any;
  type?: string;
}

export interface InputProps extends HtmlInputrops {
  value: string;
  title: string;
  placeholder: string;
  onFocus: () => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (newValue: string) => void;
}

const DEFAULT_EMPTY_VALUE_ERROR_MESSAGE = 'Empty values disallowed.';

const useAsyncValueInputProps = ({
  value,
  handleChange,
  placeholderText,
  saveEmptyValue,
  notEditable,
  notEditableErrorNotificationText,
  emptyValueErrorNotificationText = DEFAULT_EMPTY_VALUE_ERROR_MESSAGE,
}: TemporaryValueInputProps): InputProps => {
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState('');

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  const handleValueUpdate = (valueToSet: string) => {
    const trimmedValue = valueToSet.trim();
    if ((trimmedValue || saveEmptyValue) && valueToSet !== value && !notEditable) {
      handleChange(valueToSet);
      return;
    }

    if (notEditable && notEditableErrorNotificationText) {
      notification.createNotification(notEditableErrorNotificationText, 'error', dispatch);
    }

    if (!trimmedValue && !saveEmptyValue) {
      notification.createNotification(emptyValueErrorNotificationText, 'error', dispatch);
    }

    setUserInput(value);
  };

  const onFocus = () => {
    const valueToSet = value === placeholderText ? '' : value;
    setUserInput(valueToSet);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleValueUpdate(event.currentTarget.value);
  };

  const onChange = (newValue: string) => {
    setUserInput(newValue);
  };

  return {
    value: userInput,
    title: userInput,
    placeholder: value && placeholderText ? placeholderText : '',
    onFocus,
    onBlur,
    onChange,
  };
};

export default useAsyncValueInputProps;
