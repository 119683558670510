import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { DeleteESignTemplateSuccessAction } from './ActionCreator';
import { ESignTemplatesActionType } from './ActionTypes';

const isDeleteESignTemplateSuccessAction = (action: AnyAction): action is DeleteESignTemplateSuccessAction =>
  action.type === ESignTemplatesActionType.DeleteESignTemplateSuccess;

const notifyOnTemplateDeleteMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteESignTemplateSuccessAction(action)) {
    const { eSignTemplates } = getState();

    notification.createNotification(
      getMessage(MessageType.ESignTemplateDeleted, {
        templateName: eSignTemplates.templatesById[action.payload.id].name,
      }),
      'success',
      dispatch,
    );
  }

  return result;
}) as Middleware;

export default notifyOnTemplateDeleteMiddleware;
