import {
  TableViewData,
  FormLayoutData,
  ApplicationsSortingType,
  DataFilter,
  VariableValue,
  DataViewSortingType,
  VariableConfiguration,
} from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Application, UserInfo } from './LoanOriginationSystemApplicationsApi';
import formatDate from 'utils/dateFormat';

export interface Borrower {
  id: string;
  variables: Record<string, VariableValue>;
  type: BorrowerType;
  locked: boolean;
  updatedAt: Date;
  createdAt: Date;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
}

export interface BorrowersDataFilter extends DataFilter {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  isCompany: boolean;
  isPerson: boolean;
  members: UserInfo[];
}

export interface BorrowerSuggestionFilter {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
}

export enum BorrowerType {
  Person = 'person',
  Company = 'company',
}

export enum BorrowersSortingField {
  BorrowerName = 'fullName',
  IdNumber = 'idNumber',
  Phone = 'phoneNumber',
  Email = 'email',
  UpdatedAt = 'updatedAt',
}

export enum BorrowerApiRequestTemplateType {
  CreateBorrower = 'createBorrower',
  UpdateBorrower = 'updateBorrower',
}

export type BorrowersSortingType = DataViewSortingType<BorrowersSortingField>;

export interface LoanOriginationSystemBorrowersApi {
  getBorrowers(filters: BorrowersDataFilter, sortingType: BorrowersSortingType): Promise<TableViewData<Borrower>>;
  getBorrowerVariablesConfiguration(type: BorrowerType): Promise<VariableConfiguration[]>;
  createBorrower(type: BorrowerType, data: FormLayoutData): Promise<Borrower>;
  deleteBorrower(id: string): void;
  getBorrowerById(id: string): Promise<Borrower>;
  updateBorrower(id: string, data: FormLayoutData): Promise<Borrower>;
  getBorrowerApplications(
    id: string,
    filters: DataFilter,
    sortingType: ApplicationsSortingType,
  ): Promise<TableViewData<Application>>;
  getBorrowersSuggestions(filter: BorrowerSuggestionFilter): Promise<Borrower[]>;
}

export default class LoanOriginationSystemBorrowersRestApi extends LoanOriginationSystemApi<Borrower>
  implements LoanOriginationSystemBorrowersApi {
  protected resourceName = 'borrowers';

  public async getBorrowers(filters: BorrowersDataFilter, sortingType: BorrowersSortingType) {
    const { isCompany, isPerson, dueCreatedDateRange, dueUpdatedDateRange, members } = filters;

    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    members.forEach((member) => params.append('teamMemberIds', member.id));

    if (isCompany && !isPerson) {
      params.append('companyType', 'true');
    }
    if (isPerson && !isCompany) {
      params.append('personType', 'true');
    }
    if (dueCreatedDateRange.from) {
      params.append('dueCreatedDateRangeFrom', formatDate(dueCreatedDateRange.from));
    }
    if (dueCreatedDateRange.to) {
      params.append('dueCreatedDateRangeTo', formatDate(dueCreatedDateRange.to));
    }
    if (dueUpdatedDateRange.from) {
      params.append('dueUpdatedDateRangeFrom', formatDate(dueUpdatedDateRange.from));
    }
    if (dueUpdatedDateRange.to) {
      params.append('dueUpdatedDateRangeTo', formatDate(dueUpdatedDateRange.to));
    }

    return this.getResources<TableViewData<Borrower>>(params);
  }

  public createBorrower(borrowerType: BorrowerType, data: FormLayoutData) {
    return this.createResource({
      borrower: {
        type: borrowerType,
        variables: data,
      },
    });
  }

  public deleteBorrower(id: string) {
    return this.deleteResource(id);
  }

  public getBorrowerById(id: string) {
    return this.getResourceById(id);
  }

  public updateBorrower(id: string, borrowerData: FormLayoutData) {
    return this.updateResource(id, { borrower: { variables: borrowerData } });
  }

  public getBorrowerVariablesConfiguration(borrowerType: BorrowerType) {
    return this.fetch<VariableConfiguration[]>(`/${this.resourceName}/variables-configuration/${borrowerType}`);
  }

  public getBorrowerApplications(id: string, filters: DataFilter, sortingType: ApplicationsSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    params.append('borrowerId', id);

    return this.fetch<TableViewData<Application>>(`/applications?${params}`);
  }

  public unlockBorrower(id: string) {
    return this.updateResource(id, { borrower: { locked: false } });
  }

  public getBorrowersSuggestions(filter: BorrowerSuggestionFilter) {
    const params = new URLSearchParams();

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        params.set(key, filter[key]);
      }
    });

    return this.fetch<Borrower[]>(`/${this.resourceName}/suggestions/?${params}`);
  }
}
