import React from 'react';
import styles from './SkeletonStatusRulesValidationContent.module.scss';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';

const SKELETON_RESULTS_COUNT = 2;

const SkeletonStatusRulesValidationContent = () => {
  const renderSkeletonValidationResult = () => (
    <div className={styles.skeletonValidationResult}>
      <SkeletonCircle className={styles.skeletonResultIcon} width="28px" height="28px" color="primary10" />
      <SkeletonRectangle width="240px" height="16px" color="primary20" marginBottom="13px" />
      <div className={styles.validationResultContent}>
        <div>
          <SkeletonRectangle width="34px" height="14px" color="primary10" marginRight="98px" />
          <SkeletonRectangle width="56px" height="14px" color="primary10" />
        </div>
        <div>
          <SkeletonRectangle width="74px" height="14px" color="primary10" marginRight="58px" />
          <SkeletonRectangle width="55px" height="14px" color="primary10" />
        </div>
        <div>
          <SkeletonRectangle width="66px" height="14px" color="primary10" marginRight="66px" />
          <SkeletonRectangle width="130px" height="14px" color="primary10" />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.skeletonContainer}>
      <div className={styles.skeletonHeader}>
        <SkeletonRectangle className={styles.skeletonStatusNumber} width="24px" height="24px" color="primary20" />
        <SkeletonRectangle width="200px" height="24px" color="primary20" marginBottom="7px" />
        <SkeletonRectangle width="140px" height="14px" color="primary10" />
      </div>
      <div className={styles.skeletonBody}>
        {new Array(SKELETON_RESULTS_COUNT).fill(null).map(renderSkeletonValidationResult)}
      </div>
      <SkeletonRectangle width="200px" height="24px" color="primary20" marginTop="36px" />
    </div>
  );
};

export default SkeletonStatusRulesValidationContent;
