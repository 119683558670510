import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';

export default class ChangeApplicationStatusError extends Error {
  constructor(
    message: string,
    readonly application: Application,
    readonly status: ApplicationStatus,
    readonly previousIndex: number | undefined,
  ) {
    super(message);
  }
}
