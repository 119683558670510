import Fetcher from 'middlewares/Fetcher';
import { IntermediaryVariableConfigurationsApi } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import {
  DeleteIntermediaryVariableConfigurationAction,
  deleteIntermediaryVariableConfigurationSuccess,
  deleteIntermediaryVariableConfigurationFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const DeleteIntermediaryVariableConfigurationMiddleware = (api: IntermediaryVariableConfigurationsApi) =>
  Fetcher<void, DeleteIntermediaryVariableConfigurationAction>(
    VariablesConfigurationActionType.DeleteIntermediaryVariableConfiguration,
    deleteIntermediaryVariableConfigurationSuccess,
    deleteIntermediaryVariableConfigurationFailure,
    (action) => {
      return api.delete(action.payload.id);
    },
  );

export default DeleteIntermediaryVariableConfigurationMiddleware;
