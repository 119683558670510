import React, { FC } from 'react';
import RunProcess from './RunProcess';
import History from './History';
import styles from './BatchProcessing.module.scss';
import { StrategiesListItem } from 'api/Types';
import { TabName } from 'components/IndividualProcessing/IndividualProcessing';
import PageName from 'constants/PageName';

export interface BatchProcessingProps {
  currentStrategy?: string;
  strategies: StrategiesListItem[];
  onTabChange: (tab: TabName) => void;
  switchBlock: TabName;
  onSearchInputChange: (searchInputValue: string) => void;
  onRunProcess: (file: File, processName?: string) => Promise<unknown>;
  downloadTemplate: () => void;
}

const BatchProcessing: FC<BatchProcessingProps> = ({
  currentStrategy,
  strategies,
  onTabChange,
  switchBlock,
  onSearchInputChange,
  onRunProcess,
  downloadTemplate,
}) => {
  const renderBlockContent = () => {
    if (switchBlock === 'run') {
      return (
        <RunProcess
          strategies={strategies}
          currentStrategy={currentStrategy}
          onRunProcess={onRunProcess}
          key={currentStrategy}
          downloadTemplate={downloadTemplate}
        />
      );
    }

    if (switchBlock === 'history') {
      return <History onSearchInputChange={onSearchInputChange} />;
    }

    return null;
  };

  return (
    <div className={styles.processingPage}>
      <div className={styles.processingHeader}>
        <div className={styles.processingHeader__title}>
          <h2>{PageName.BatchProcessing}</h2>
        </div>
      </div>
      <div className={styles.processingDescription}>
        <p>Upload multiple cases and generate results in a batch process</p>
      </div>
      <div className={styles.processingSwitch}>
        <input
          type="radio"
          id="run-process"
          name="switch"
          checked={switchBlock === TabName.RUN}
          onChange={() => onTabChange(TabName.RUN)}
        />
        <label htmlFor="run-process">Run Batch</label>
        <input
          type="radio"
          id="history"
          name="switch"
          checked={switchBlock === TabName.HISTORY}
          onChange={() => onTabChange(TabName.HISTORY)}
        />
        <label htmlFor="history">History</label>
      </div>
      <div className={styles.processingContent}>{renderBlockContent()}</div>
    </div>
  );
};

export default BatchProcessing;
