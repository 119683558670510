import variablesListPagination from 'components/LoanOriginationSystem/VariablesConfiguration/pagination';
import { VariablesApi } from 'api/Core/VariablesApi';

export default (api: VariablesApi) => {
  return variablesListPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getVariables({
      count: params.perPage,
      offset: (params.page - 1) * params.perPage,
      search: params.search,
      onlyCustom: true,
    });

    return items;
  });
};
