export const CLOSE_EDIT_RULE_POP_UP = 'CLOSE_EDIT_RULE_POP_UP';
export const OPEN_EDIT_RULE_POP_UP = 'OPEN_EDIT_RULE_POP_UP';
export const EDIT_RULE = 'EDIT_RULE';
export const EDIT_RULE_SUCCESS = 'EDIT_RULE_SUCCESS';
export const EDIT_RULE_FAILURE = 'EDIT_RULE_FAILURE';
export const EDIT_CALCULATION_SCRIPT = 'EDIT_CALCULATION_SCRIPT';
export const EDIT_CALCULATION_SCRIPT_SUCCESS = 'EDIT_CALCULATION_SCRIPT_SUCCESS';
export const EDIT_CALCULATION_SCRIPT_FAILURE = 'EDIT_CALCULATION_SCRIPT_FAILURE';
export const EDIT_SIMPLE_OUTPUT = 'EDIT_SIMPLE_OUTPUT';
export const EDIT_SIMPLE_OUTPUT_SUCCESS = 'EDIT_SIMPLE_OUTPUT_SUCCESS';
export const EDIT_SIMPLE_OUTPUT_FAILURE = 'EDIT_SIMPLE_OUTPUT_FAILURE';
