import { VariableItem } from 'api/VariablesType';

const getVariableAttributes = (variable: VariableItem) => {
  return {
    dataType: variable.dataType,
    numberFormat: variable.numberFormat,
    identificationNumberType: variable.identificationNumberType,
    identificationNumberDescription: variable.identificationNumberDescription,
    currency: variable.currency,
    stringFormat: variable.stringFormat,
    dateFormat: variable.dateFormat,
    phoneNumberFormat: variable.phoneNumberFormat,
    optionsList: variable.optionsList,
    userAccessPermissions: variable.userAccessPermissions,
  };
};

export default getVariableAttributes;
