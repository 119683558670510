import React from 'react';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SIZES from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/TableColumnSizes';

const SkeletonNoteLine = () => {
  return (
    <TableRow disableHover useActions>
      <TableBodyCell width={SIZES.DATE}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.CONTENT}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.AUTHOR}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonNoteLine;
