import React from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import ResetPasswordForm from 'components/Auth/ResetPasswordForm';
import { useAuthProvider } from 'providers/AuthProvider';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import notification from 'handlers/notification/notificationActionCreator';
import { AppRoutes } from 'routes/RouteService';
import useQueryParam from 'hooks/useQueryParam';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';

const ResetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const token = useQueryParam('token');

  if (!token) {
    return null;
  }

  const handleSubmit = async (password: string) => {
    try {
      await authProvider.resetPassword(password, token);
    } catch (requestError) {
      if (requestError.data?.expired) {
        notification.createNotification(
          {
            componentType: NotificationComponentTypes.ResetPasswordExpiredErrorNotificationComponent,
            props: undefined,
          },
          'error',
          dispatch,
        );
        return;
      }
    }

    notification.createNotification('Your password has been updated. Please sign in.', 'success', dispatch);

    history.push(AppRoutes.SignIn);
  };

  return (
    <AuthLayout title="Create New Password">
      <ResetPasswordForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

export default ResetPassword;
