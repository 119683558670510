export interface StringFragment {
  start: number;
  end: number;
  isMatched: boolean;
}

const stringMatches = (string: string, subString: string): StringFragment[] | null => {
  if (subString.length === 0) {
    return null;
  }
  const matches: StringFragment[] = [];
  let start = 0;
  for (let index = string.indexOf(subString, start); index !== -1; index = string.indexOf(subString, start)) {
    if (index !== start) {
      matches.push({
        start,
        end: index,
        isMatched: false,
      });
    }
    const end = index + subString.length;
    matches.push({
      start: index,
      end,
      isMatched: true,
    });
    start = end;
  }
  if (start !== string.length) {
    matches.push({
      start,
      end: string.length,
      isMatched: false,
    });
  }
  return matches;
};

export default stringMatches;
