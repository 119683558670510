import { AnyAction } from 'redux';
import { RegionSettings } from './ActionTypes';
import { RegionSettingsState } from './Types';

const initialState: RegionSettingsState = {
  isOpen: false,
};

const RegionSettingsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case RegionSettings.OpenRegionSettingsConfirmDialog: {
      return { isOpen: true };
    }
    case RegionSettings.CloseRegionSettingsConfirmDialog: {
      return { isOpen: false };
    }
    default: {
      return state;
    }
  }
};

export default RegionSettingsReducer;
