import React from 'react';
import { SIZES } from 'components/StrategyOverview/StrategyUpdates/TableColumnSizes';
import styles from 'components/StrategyOverview/StrategyUpdates/UpdateLine/UpdateLine.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';

const SkeletonUpdateLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.UPDATED}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.EDITOR} noPadding>
        <SkeletonCircle width="28px" height="28px" color="primary6" />
        <SkeletonRectangle width="80%" height="16px" color="primary6" className={styles.updateLineEditorText} />
      </TableBodyCell>
      <TableBodyCell width={SIZES.TYPE}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonUpdateLine;
