import Fetcher from 'middlewares/Fetcher';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { ApplicationHistoryApi, ApplicationHistoryRecord } from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { APPLICATION_HISTORY_RECORDS_PER_PAGE } from 'components/LoanOriginationSystem/ApplicationTabs/HistoryTab/pagination';
import {
  getApplicationHistorySuccess,
  getApplicationHistoryFailure,
  GetApplicationHistoryAction,
} from './ActionCreator';
import { ApplicationHistoryActionType } from './ActionTypes';

const GetApplicationHistoryMiddleware = (api: ApplicationHistoryApi) =>
  Fetcher<TableViewData<ApplicationHistoryRecord>, GetApplicationHistoryAction>(
    ApplicationHistoryActionType.GetApplicationHistory,
    getApplicationHistorySuccess,
    getApplicationHistoryFailure,
    (action) => {
      return api.getHistoryRecords(
        {
          ...action.payload.filters,
          offset: 0,
          count: APPLICATION_HISTORY_RECORDS_PER_PAGE,
        },
        action.payload.sortingType,
      );
    },
  );

export default GetApplicationHistoryMiddleware;
