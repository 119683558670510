import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { CloseButtonImage } from 'static/images';
import Button from 'components/Button';
import { SendGridTemplate } from 'SendGrid/Types';
import { Option } from 'components/SelectInput/SelectInput';
import AutoCompletion from 'components/AutoCompletion';
import EditTemplateVariablesTable from 'components/SendGrid/EditTemplateVariablesTable';
import StyledSwitch from 'components/StyledSwitch';
import WrapperWithTooltip from 'components/Tooltip';
import { SkeletonRectangle } from 'components/Skeleton';
import {
  GetSenderEmailsRequest,
  updateTemplateSenderEmailRequest,
  updateTemplateStatusRequest,
} from 'SendGrid/ActionCreator';
import styles from './EditTemplate.module.scss';

interface EditTemplateProps {
  senderEmails: string[] | null;
  selectedTemplateInfo?: SendGridTemplate | null;
  isUpdatingSenderEmail: boolean;
  onClose: () => void;
  onVariablesMappingPopUpOpen: () => void;
}

const areTemplateVariablesUnmapped = (template: SendGridTemplate) => {
  return Object.keys(template.variables).some((key) => template.variables[key] === null);
};

const EditTemplate: FC<EditTemplateProps> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetSenderEmailsRequest());
  }, []);

  const { senderEmails, selectedTemplateInfo, isUpdatingSenderEmail, onClose, onVariablesMappingPopUpOpen } = props;

  const activeSenderEmail = senderEmails?.find((email) => email === selectedTemplateInfo?.senderEmail);

  const senderEmailsOptions =
    senderEmails &&
    senderEmails.map((senderEmail) => ({
      name: senderEmail,
      value: senderEmail,
    }));

  const handleSenderEmailChange = (option: Option) => {
    if (!selectedTemplateInfo) {
      return;
    }

    dispatch(updateTemplateSenderEmailRequest(selectedTemplateInfo.id, option.value));
  };

  const updateTemplateStatus = (active: boolean) => {
    if (!selectedTemplateInfo) {
      return;
    }

    dispatch(updateTemplateStatusRequest(selectedTemplateInfo.id, active));
  };

  const shouldRenderVariablesMapping = Object.keys(selectedTemplateInfo?.variables || {}).length !== 0;

  const renderTitle = () => {
    if (!selectedTemplateInfo) {
      return <SkeletonRectangle width="347px" height="44px" color="primary6" />;
    }

    return <h2>{selectedTemplateInfo.name}</h2>;
  };

  const renderVariablesMappingSection = () => {
    if (selectedTemplateInfo && !shouldRenderVariablesMapping) {
      return null;
    }

    return (
      <div className={styles.variablesMappingSection}>
        <EditTemplateVariablesTable template={selectedTemplateInfo} />
        {selectedTemplateInfo && (
          <Button
            type="submit"
            kind="secondary"
            onClick={onVariablesMappingPopUpOpen}
            className={styles.editVariableMappingButton}
          >
            Edit Variable Mapping
          </Button>
        )}
      </div>
    );
  };

  const renderSwitchText = () => {
    if (!selectedTemplateInfo) {
      return <SkeletonRectangle width="68px" height="20px" color="primary6" />;
    }

    return (
      <p className={clsx(selectedTemplateInfo.active && styles.activeSwitchText)}>
        {selectedTemplateInfo.active ? 'Activated' : 'Not Activated'}
      </p>
    );
  };

  const getSwitchTooltip = () => {
    if (!selectedTemplateInfo) {
      return '';
    }

    if (!activeSenderEmail) {
      return 'You must set up Sender Email to activate a template';
    }

    return areTemplateVariablesUnmapped(selectedTemplateInfo) ? 'Please map all fields before activating.' : '';
  };

  return (
    <div className={styles.editTemplate}>
      <div className={styles.editTemplateHeader}>
        <div className={styles.headerInnerContainer}>
          {renderTitle()}
          <div className={styles.switchContainer}>
            <WrapperWithTooltip tooltip={getSwitchTooltip()}>
              <div>
                <StyledSwitch
                  checked={!!selectedTemplateInfo?.active}
                  disabled={
                    !selectedTemplateInfo || areTemplateVariablesUnmapped(selectedTemplateInfo) || !activeSenderEmail
                  }
                  onChange={(event, checked) => updateTemplateStatus(checked)}
                />
              </div>
            </WrapperWithTooltip>
            {renderSwitchText()}
          </div>
        </div>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.editTemplateContent}>
        <p className={styles.editTemplateDescription}>
          Set basic information and map template fields to system variables to populate dynamic data.
        </p>
        <AutoCompletion
          loading={isUpdatingSenderEmail}
          labelTitle="Sender Email"
          options={senderEmailsOptions || []}
          value={activeSenderEmail || ''}
          onChange={handleSenderEmailChange}
          disabled={!senderEmailsOptions?.length || !selectedTemplateInfo || selectedTemplateInfo.active}
        />
        {senderEmailsOptions && !senderEmailsOptions.length && (
          <p className={styles.helpMessage}>
            You must set up at least one Sender Email in SendGrid to activate a template.
          </p>
        )}
        {renderVariablesMappingSection()}
      </div>
    </div>
  );
};

export default EditTemplate;
