import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { UpdateApplicationSuccessAction } from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { UPDATE_APPLICATION_SUCCESS } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import { ReduxState } from 'types/redux';
import { Dispatch } from 'react';
import notification from 'handlers/notification/notificationActionCreator';

const CREATE_APPLICATION_SUCCESS_MESSAGE = 'The application has been updated.';

const isUpdateApplicationSuccessAction = (action: AnyAction): action is UpdateApplicationSuccessAction =>
  action.type === UPDATE_APPLICATION_SUCCESS;

const UpdateApplicationSuccessMiddleware = () =>
  (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
    const result = next(action);

    if (isUpdateApplicationSuccessAction(action)) {
      notification.createNotification(CREATE_APPLICATION_SUCCESS_MESSAGE, 'success', dispatch);
    }

    return result;
  }) as Middleware;

export default UpdateApplicationSuccessMiddleware;
