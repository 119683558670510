import { AnyAction } from 'redux';
import { StrategyArchiveStatusActionType } from 'DecisionStrategy/ActionTypes';
import { ChangeStrategyStatusWizardActions } from 'components/ArchiveStrategyWizard/constants';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export enum ArchiveEntityType {
  Strategy = 'Strategy',
  Version = 'Version',
}

export interface ChangeStrategyStatusWizardState {
  isOpen: boolean;
  strategyName: string;
  archived?: boolean;
  entityType: ArchiveEntityType | null;
  strategyId: string;
  loading: boolean;
  versionNumber: number | null;
  actionOrigin?: StrategyActionOrigin;
}

const initialState: ChangeStrategyStatusWizardState = {
  isOpen: false,
  strategyName: '',
  archived: undefined,
  strategyId: '',
  entityType: null,
  versionNumber: null,
  loading: false,
  actionOrigin: undefined,
};

const changeStrategyStatusWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ChangeStrategyStatusWizardActions.OpenArchiveStrategyWizard: {
      return { isOpen: true, ...action.payload };
    }
    case ChangeStrategyStatusWizardActions.CloseArchiveStrategyWizard: {
      return initialState;
    }
    case StrategyArchiveStatusActionType.ArchiveStrategy: {
      return { ...state, loading: true };
    }
    case StrategyArchiveStatusActionType.RestoreStrategy: {
      return { ...state, loading: true };
    }
    case StrategyArchiveStatusActionType.ArchiveStrategySuccess: {
      return initialState;
    }
    case StrategyArchiveStatusActionType.ArchiveStrategyFailure: {
      return { ...state, loading: false };
    }
    case StrategyArchiveStatusActionType.RestoreStrategySuccess: {
      return initialState;
    }
    case StrategyArchiveStatusActionType.RestoreStrategyFailure: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default changeStrategyStatusWizardReducer;
