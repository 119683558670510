import { createPortal } from 'react-dom';
import { ReactElement, ReactPortal, useEffect, useRef } from 'react';
import { DraggableProvided, DraggableStateSnapshot, DraggingStyle } from 'react-beautiful-dnd';

const useDraggableInPortal = () => {
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const divElement = document.createElement('div');
    divElement.style.position = 'absolute';
    divElement.style.pointerEvents = 'none';
    divElement.style.top = '0';
    divElement.style.width = '100%';
    divElement.style.height = '100%';
    elementRef.current = divElement;
    document.body.appendChild(divElement);
    return () => {
      document.body.removeChild(divElement);
    };
  }, []);

  return (render: (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => ReactElement | ReactPortal) => (
    provided: DraggableProvided,
    snapshot: DraggableStateSnapshot,
  ) => {
    const element = render(provided, snapshot);
    if ((provided.draggableProps.style as DraggingStyle).position === 'fixed') {
      return createPortal(element, elementRef.current!);
    }
    return element;
  };
};

export default useDraggableInPortal;
