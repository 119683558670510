export const GET_BRANCH_INFO_REQUEST = 'GET_BRANCH_INFO_REQUEST';
export const GET_BRANCH_INFO_REQUEST_SUCCESS = 'GET_BRANCH_INFO_REQUEST_SUCCESS';
export const GET_BRANCH_INFO_REQUEST_FAILURE = 'GET_BRANCH_INFO_REQUEST_FAILURE';

export const UPDATE_BRANCH_NAME_REQUEST = 'UPDATE_BRANCH_NAME_REQUEST';
export const UPDATE_BRANCH_NAME_REQUEST_SUCCESS = 'UPDATE_BRANCH_NAME_REQUEST_SUCCESS';
export const UPDATE_BRANCH_NAME_REQUEST_FAILURE = 'UPDATE_BRANCH_NAME_REQUEST_FAILURE';

export const REORDER_BRANCH_RULES = 'REORDER_BRANCH_RULES';
export const REORDER_BRANCH_RULES_SUCCESS = 'REORDER_BRANCH_RULES_SUCCESS';
export const REORDER_BRANCH_RULES_FAILURE = 'REORDER_BRANCH_RULES_FAILURE';

export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'DELETE_BRANCH_FAILURE';

export const DUPLICATE_BRANCH_REQUEST = 'DUPLICATE_BRANCH_REQUEST';
export const DUPLICATE_BRANCH_SUCCESS = 'DUPLICATE_BRANCH_SUCCESS';
export const DUPLICATE_BRANCH_FAILURE = 'DUPLICATE_BRANCH_FAILURE';
