import Fetcher from 'middlewares/Fetcher';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';
import {
  DeleteApplicationDecisionRunAction,
  deleteApplicationDecisionRunFailure,
  deleteApplicationDecisionRunSuccess,
} from 'ApplicationDecisionRuns/actions';
import ApplicationDecisionRunsApiRest from 'api/LoanOriginationSystem/ApplicationDecisionRunsApi';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { AnyAction, MiddlewareAPI, Middleware } from 'redux';

const DeleteApplicationDecisionRunMiddleware = (api: ApplicationDecisionRunsApiRest) =>
  Fetcher<void, DeleteApplicationDecisionRunAction>(
    ApplicationDecisionRunActionType.DeleteApplicationDecisionRun,
    deleteApplicationDecisionRunSuccess,
    deleteApplicationDecisionRunFailure,
    (action) => {
      return api.deleteDecisionRun(action.payload.decisionRunId);
    },
  );

export const onDeleteApplicationDecisionRunSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === ApplicationDecisionRunActionType.DeleteApplicationDecisionRunSuccess) {
    notification.createNotification(getMessage(MessageType.ResultRemoved), 'success', dispatch);
  }

  return result;
};

export default DeleteApplicationDecisionRunMiddleware;
