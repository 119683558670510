import ConfirmPopup from 'components/ConfirmPopup';
import React from 'react';
import { ConfirmPopupProps } from 'components/ConfirmPopup/ConfirmPopup';

type DeleteEmailConfirmationPopupProps = Omit<ConfirmPopupProps, 'message' | 'confirmText' | 'declineText' | 'title'>;

const DeleteEmailConfirmationPopup = (props: DeleteEmailConfirmationPopupProps) => {
  return (
    <ConfirmPopup
      {...props}
      message="Are you sure you want to delete this email?"
      confirmText="Delete Email"
      declineText="No, Go Back"
      title="Delete Email"
    />
  );
};

export default DeleteEmailConfirmationPopup;
