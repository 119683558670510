import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import React, { FC } from 'react';
import styles from './ImagePreview.module.scss';
import { MinusIcon, PlusIcon } from 'static/images';
import clsx from 'clsx';

interface ImagePreviewProps {
  previewLink: string;
  filename: string;
  onLoad: () => void;
  onError: () => void;
}

interface ITransformProps {
  zoomIn: () => void;
  zoomOut: () => void;
  resetTransform: () => void;
  scale: number;
}

const MIN_SCALE = 0.5;
const MAX_SCALE = 8;

const ImagePreview: FC<ImagePreviewProps> = ({ previewLink, filename, onLoad, onError }) => {
  return (
    <TransformWrapper
      options={{
        minScale: MIN_SCALE,
        maxScale: MAX_SCALE,
        wrapperClass: styles.wrapper,
        contentClass: styles.content,
      // any used because wrapperClass and contentClass doesn't exist in types.
      } as any}
    >
      {({ zoomIn, zoomOut, resetTransform, scale }: ITransformProps) => (
        <>
          <TransformComponent>
            <img
              src={previewLink}
              alt={filename}
              onLoad={onLoad}
              onError={onError}
              className={styles.image}
            />
          </TransformComponent>
          <div className={styles.tools}>
            <div
              onClick={zoomOut}
              className={clsx(styles.zoomIcon, scale === MIN_SCALE && styles.disabled)}
            >
              <MinusIcon/>
            </div>
            <div
              onClick={resetTransform}
              className={styles.resetButton}
            >
              {Math.round(scale * 100)}%
            </div>
            <div
              onClick={zoomIn}
              className={clsx(styles.zoomIcon, scale === MAX_SCALE && styles.disabled)}
            >
              <PlusIcon/>
            </div>
          </div>
        </>
      )}
    </TransformWrapper>
  );
};

export default ImagePreview;
