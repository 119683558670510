import { MessageType } from 'constants/messages';

export function validateEmail(email: string, required: boolean = true): string {
  if (!email && required) {
    return 'Email is required.';
  }

  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return 'Invalid email address.';
  }

  return '';
}

export function validatePasswordPresence(password: string): MessageType | null {
  if (!password) {
    return MessageType.PasswordRequired;
  }

  return null;
}

export function validateCode(code: string): string {
  if (!code) {
    return 'Code is required.';
  }

  if (code.length < 6) {
    return 'Code is short.';
  }

  return '';
}
