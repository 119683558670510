import React, { FC } from 'react';
import { CloseButtonImage } from 'static/images';
import styles from './InfoCard.module.scss';

interface InfoCardProps {
  title: string;
  titleLink: React.ReactElement | string;
  onTitleLinkClick?: () => void;
  onClose?: () => void;
  hideCloseButton?: boolean;
}

const InfoCard: FC<InfoCardProps> = ({ title, titleLink, onTitleLinkClick, onClose, children, hideCloseButton }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h3>{title}</h3>
        <div onClick={onTitleLinkClick} className={styles.titleLink}>
          {titleLink}
        </div>
        {!hideCloseButton && <CloseButtonImage className={styles.closeButton} onClick={onClose} />}
      </div>
      {children}
    </div>
  );
};

export default InfoCard;
