import { AnyAction, MiddlewareAPI } from 'redux';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { UnlockBorrowerSuccessAction } from './ActionCreator';
import { LoanOriginationSystemUnlockBorrowerType } from './ActionTypes';

const isUnlockBorrowerSuccessAction = (action: AnyAction): action is UnlockBorrowerSuccessAction =>
  action.type === LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess;

const unlockBorrowerSuccessMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const { loanOriginationSystemBorrowers } = getState();
  const { unlockBorrower } = loanOriginationSystemBorrowers;

  if (isUnlockBorrowerSuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.BorrowerUnlocked, {
        borrowerName: getBorrowerFullName(
          unlockBorrower.borrowerToUnlock.type,
          unlockBorrower.borrowerToUnlock.variables,
        ),
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};

export default unlockBorrowerSuccessMiddleware;
