import React from 'react';
import { useHistory } from 'react-router';

import LeftNav from 'components/LeftNav/LeftNavigation';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';

export type NOT_FOUND_PAGE_SECTION = '';

export const makeLeftNavigation = (
  activeLinkId: string,
  activeProductTitle?: ApplicationSectionName | NOT_FOUND_PAGE_SECTION,
  noLinks?: boolean,
) => <LeftNav activeLinkId={activeLinkId} activeProductTitle={activeProductTitle} noLinks={noLinks} />;

export const useCloseContextualView = () => {
  const history = useHistory();
  return () => history.replace(history.location.pathname);
};
