export enum ESignTemplatesActionType {
  GetESignTemplates = 'eSignTemplates/getESignTemplates',
  GetESignTemplatesSuccess = 'eSignTemplates/getESignTemplatesSuccess',
  GetESignTemplatesFailure = 'eSignTemplates/getESignTemplatesFailure',
  GetESignTemplate = 'eSignTemplates/getESignTemplate',
  GetESignTemplateSuccess = 'eSignTemplates/getESignTemplateSuccess',
  GetESignTemplateFailure = 'eSignTemplates/getESignTemplateFailure',
  UpdateESignTemplate = 'eSignTemplates/updateESignTemplate',
  UpdateESignTemplateSuccess = 'eSignTemplates/updateESignTemplateSuccess',
  UpdateESignTemplateFailure = 'eSignTemplates/updateESignTemplateFailure',
  DeleteESignTemplate = 'eSignTemplates/deleteESignTemplate',
  DeleteESignTemplateSuccess = 'eSignTemplates/deleteESignTemplateSuccess',
  DeleteESignTemplateFailure = 'eSignTemplates/deleteESignTemplateFailure',
  SaveESignTemplates = 'eSignTemplates/saveESignTemplates',
}
