import React, { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';
import useFocusLock from 'components/FocusLock';

import styles from 'MainLayout/MainLayout.module.scss';

interface ContextualViewWrapperProps {
  children: ReactNode;
}

const ContextualViewWrapper = forwardRef<HTMLDivElement | null, ContextualViewWrapperProps>(
  ({ children }: ContextualViewWrapperProps, ref) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useFocusLock(containerRef);

    useImperativeHandle(ref, () => containerRef.current);

    return (
      <div ref={containerRef} className={styles.contextualViewWrapper}>
        {children}
      </div>
    );
  },
);

export default ContextualViewWrapper;
