import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationStatus,
  LoanOriginationSystemApplicationStatusesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  createApplicationStatusSuccess,
  createApplicationStatusFailure,
  CreateApplicationStatusAction,
} from './ActionCreator';

const CreateApplicationStatusMiddleware = (api: LoanOriginationSystemApplicationStatusesApi) =>
  Fetcher<ApplicationStatus, CreateApplicationStatusAction>(
    ApplicationStatusesActionType.CreateApplicationStatus,
    createApplicationStatusSuccess,
    createApplicationStatusFailure,
    async (action) => {
      return api.create(action.payload.name, action.payload.position, action.payload.productId);
    },
  );

export default CreateApplicationStatusMiddleware;
