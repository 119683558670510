import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  ApplicationFormPage,
  AssigneeTeamMembersType,
  Product,
} from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import Stepper from 'components/Stepper';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import Button from 'components/Button';
import isBasicInfoValid from 'components/LoanOriginationSystem/CreateApplication/Forms/ApplicationDetailsForm/NewApplicationDetailsForm/validate';
import CreateApplicationForm from 'components/LoanOriginationSystem/CreateApplication/CreateApplicationForm';
import {
  createApplication,
  loadBorrowersSuggestions,
  loadIntermediarySuggestions,
  selectBorrower,
  selectBorrowerToEdit,
  selectCoBorrower,
  selectIntermediary,
  selectIntermediaryToEdit,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { ReduxState } from 'types/redux';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { Borrower, BorrowerSuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getApplicationVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';
import { REQUEST_DELAY_MS } from 'middlewares/Debouncer';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import {
  getBorrowerVariableConfigurations,
  getIntermediaryVariableConfigurations,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import {
  getApplicationVariableConfigurationsSelector,
  getBorrowerVariableConfigurationsSelector,
  getIntermediaryVariableConfigurationsSelector,
} from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import useStandardVariables from 'hooks/useStandardVariables';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import ApplicationAttribute from 'LoanOriginationSystemApplications/CreateApplication/ApplicationAttribute';
import ApplicationContextualViewLayout from './ApplicationContextualViewLayout';
import styles from './CreateApplication.module.scss';

const STEPS = [
  { type: EditApplicationStep.Borrower, label: EditApplicationStep.Borrower },
  { type: EditApplicationStep.CoBorrower, label: EditApplicationStep.CoBorrower },
  { type: EditApplicationStep.Intermediary, label: EditApplicationStep.Intermediary },
  { type: EditApplicationStep.ApplicationDetails, label: EditApplicationStep.ApplicationDetails },
];

interface CreateApplicationProps {
  onClose: () => void;
  product: Product;
  additionalFormPages: ApplicationFormPage[];
  onCreateSuccess: (createdApplicationDisplayId: string | undefined) => void;
  setDataWasChanged: (changed: boolean) => void;
}

const onLoadBorrowerSuggestions = debounce((filter: BorrowerSuggestionFilter, dispatch: Dispatch<ReduxState>) => {
  dispatch(loadBorrowersSuggestions(filter));
}, REQUEST_DELAY_MS);

const onLoadIntermediarySuggestions = debounce(
  (filter: IntermediarySuggestionFilter, dispatch: Dispatch<ReduxState>) => {
    dispatch(loadIntermediarySuggestions(filter));
  },
  REQUEST_DELAY_MS,
);

const getTeamMemberIdsByProduct = (product: Product, currentUserId?: string) => {
  if (!currentUserId) {
    return null;
  }

  if (product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.OnCreate) {
    return [currentUserId];
  }

  if (product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.SpecificTeamMembers) {
    return product.settings.teamMembersToAssign;
  }

  return [];
};

const CreateApplication = ({
  onClose,
  onCreateSuccess,
  setDataWasChanged,
  additionalFormPages,
  product,
}: CreateApplicationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutineWithResult();

  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const [currentStep, setCurrentStep] = useState<EditApplicationStep>(EditApplicationStep.Borrower);
  const [borrowerFormData, setBorrowerFormData] = useState({});
  const [coBorrowerFormData, setCoBorrowerFormData] = useState({});
  const [intermediaryFormData, setIntermediaryFormData] = useState({});
  const [applicationDetailsFormData, setApplicationDetailsFormData] = useState({});
  const {
    isCreating,
    borrowerSuggestions,
    intermediarySuggestions,
    selectedCoBorrower,
    selectedBorrower,
    selectedIntermediary,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications.createApplication);
  const standardVariables = useStandardVariables();
  const { borrowerType } = product;
  const addCoBorrower = additionalFormPages.includes(ApplicationFormPage.CoBorrower);
  const addIntermediary = additionalFormPages.includes(ApplicationFormPage.Intermediary);

  const borrowerVariableConfigurations = useSelector((state: ReduxState) =>
    getBorrowerVariableConfigurationsSelector(state, { borrowerType, productId: product.id }),
  );
  const intermediaryVariableConfigurations = useSelector((state: ReduxState) =>
    getIntermediaryVariableConfigurationsSelector(state, { productId: product.id }),
  );
  const applicationVariableConfigurations = useSelector((state: ReduxState) =>
    getApplicationVariableConfigurationsSelector(state, { productId: product.id }),
  );

  const steps = STEPS.filter(
    (step) =>
      step.type === EditApplicationStep.Borrower ||
      (step.type === EditApplicationStep.CoBorrower && addCoBorrower) ||
      (step.type === EditApplicationStep.Intermediary && addIntermediary) ||
      step.type === EditApplicationStep.ApplicationDetails,
  );

  useEffect(() => {
    if (borrowerType && !borrowerVariableConfigurations) {
      dispatch(getBorrowerVariableConfigurations(borrowerType, product.id));
    }
  }, [borrowerType]);

  useEffect(() => {
    if (currentStep === EditApplicationStep.Intermediary && !intermediaryVariableConfigurations) {
      dispatch(getIntermediaryVariableConfigurations(product.id));
    }
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === EditApplicationStep.ApplicationDetails && !applicationVariableConfigurations) {
      dispatch(getApplicationVariableConfigurations(product.id));
    }
  }, [currentStep]);

  const validateBorrowerData = useConfigurableFormValidation(borrowerVariableConfigurations);
  const validateIntermediaryData = useConfigurableFormValidation(intermediaryVariableConfigurations);
  const validateApplicationDetailsData = useConfigurableFormValidation(applicationVariableConfigurations);

  const onStepChange = (stepName: string) => {
    setCurrentStep(stepName as EditApplicationStep);
  };

  const onContinue = () => {
    const currentIndex = steps.findIndex(({ type }) => type === currentStep);

    setCurrentStep(steps[currentIndex + 1].type);
  };

  const onUnlockCustomerClick = (borrower: Borrower) => {
    dispatch(setBorrowerToUnlock(borrower));
  };

  const onBorrowerSelect = (borrower: Borrower | null) => {
    dispatch(selectBorrower(borrower));
  };

  const onIntermediarySelect = (intermediary: Intermediary | null) => {
    dispatch(selectIntermediary(intermediary));
  };

  const onCoBorrowerSelect = (borrower: Borrower | null) => {
    dispatch(selectCoBorrower(borrower));
  };

  const onCreateApplication = async () => {
    const result = await dispatchRoutine(
      createApplication(
        product.id,
        product.borrowerType,
        selectedBorrower || borrowerFormData,
        addCoBorrower ? selectedCoBorrower || coBorrowerFormData : undefined,
        addIntermediary ? selectedIntermediary || intermediaryFormData : undefined,
        applicationDetailsFormData,
      ),
    );

    const createdApplicationId = result[ApplicationAttribute.ApplicationId] as string | undefined;

    onCreateSuccess(createdApplicationId);
  };

  const handleProfileEditClick = () => {
    if (currentStep === EditApplicationStep.Borrower && selectedBorrower) {
      dispatch(selectBorrowerToEdit(selectedBorrower));

      return;
    }

    if (currentStep === EditApplicationStep.CoBorrower && selectedCoBorrower) {
      dispatch(selectBorrowerToEdit(selectedCoBorrower));

      return;
    }

    if (currentStep === EditApplicationStep.Intermediary && selectedIntermediary) {
      dispatch(selectIntermediaryToEdit(selectedIntermediary));
    }
  };

  const isBorrowerFormDataInvalid = selectedBorrower
    ? !validateBorrowerData(selectedBorrower.variables)
    : !validateBorrowerData(borrowerFormData);

  const isCoBorrowerFormDataInvalid = selectedCoBorrower
    ? !validateBorrowerData(selectedCoBorrower.variables)
    : !validateBorrowerData(coBorrowerFormData);

  const isIntermediaryFormDataInvalid = selectedIntermediary
    ? !validateIntermediaryData(selectedIntermediary.variables)
    : !validateIntermediaryData(intermediaryFormData);

  const isContinueDisabled =
    !borrowerType ||
    !borrowerVariableConfigurations ||
    (currentStep === EditApplicationStep.Borrower && isBorrowerFormDataInvalid) ||
    (currentStep === EditApplicationStep.CoBorrower && isCoBorrowerFormDataInvalid) ||
    (currentStep === EditApplicationStep.Intermediary && isIntermediaryFormDataInvalid);

  const isCreateApplicationDisabled = () => {
    if (currentStep !== EditApplicationStep.ApplicationDetails) {
      return isCreating || !applicationVariableConfigurations;
    }

    return (
      !validateApplicationDetailsData(applicationDetailsFormData) ||
      !isBasicInfoValid(applicationDetailsFormData) ||
      isCreating ||
      !applicationVariableConfigurations
    );
  };

  const isContinueButtonVisible = () => {
    return (
      (currentStep !== EditApplicationStep.Borrower || !selectedBorrower?.locked) &&
      (currentStep !== EditApplicationStep.CoBorrower || !selectedCoBorrower?.locked)
    );
  };

  useEffect(() => {
    setDataWasChanged(
      !!selectedBorrower ||
        Object.values(borrowerFormData).filter((value) => !isEmptyVariableValue(value as VariableValue)).length > 0,
    );
  }, [selectBorrower, borrowerFormData]);

  const renderAfterContent = () => (
    <Stepper steps={steps} currentStep={currentStep} className={styles.stepper} onStepChange={onStepChange} />
  );

  return (
    <ApplicationContextualViewLayout
      contentClassName={styles.content}
      onClose={onClose}
      teamMembersIds={getTeamMemberIdsByProduct(product, accountDetails?.id)}
      displayRoundRobinUser={product.settings.assigneeTeamMembersType === AssigneeTeamMembersType.RoundRobin}
      hideEditTeamMembersButton
      renderAfterContent={renderAfterContent}
    >
      <div className={styles.productName}>{product.name}</div>
      <div className={styles.formContainer}>
        <CreateApplicationForm
          currentStep={currentStep}
          borrowerFormData={borrowerFormData}
          coBorrowerFormData={coBorrowerFormData}
          intermediaryFormData={intermediaryFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          borrowerSuggestions={borrowerSuggestions}
          intermediarySuggestions={intermediarySuggestions}
          selectedBorrower={selectedBorrower}
          selectedCoBorrower={selectedCoBorrower}
          selectedIntermediary={selectedIntermediary}
          borrowerConfigurations={borrowerVariableConfigurations}
          intermediaryConfigurations={intermediaryVariableConfigurations}
          standardVariables={standardVariables}
          onBorrowerDataChange={(formData) => setBorrowerFormData(formData)}
          onCoBorrowerDataChange={(formData) => setCoBorrowerFormData(formData)}
          onIntermediaryDataChange={(formData) => setIntermediaryFormData(formData)}
          onApplicationDetailsDataChange={(formData) => setApplicationDetailsFormData(formData)}
          onBorrowerSelect={onBorrowerSelect}
          onIntermediarySelect={onIntermediarySelect}
          onCoBorrowerSelect={onCoBorrowerSelect}
          onLoadBorrowerSuggestions={(suggestionFilters) => onLoadBorrowerSuggestions(suggestionFilters, dispatch)}
          onLoadIntermediarySuggestions={(suggestionFilters) =>
            onLoadIntermediarySuggestions(suggestionFilters, dispatch)
          }
          onUnlockCustomerClick={onUnlockCustomerClick}
          onProfileEditClick={handleProfileEditClick}
          applicationDetailsConfigurations={applicationVariableConfigurations}
          borrowerType={product.borrowerType}
        />
        {currentStep !== EditApplicationStep.ApplicationDetails && isContinueButtonVisible() && (
          <Button
            size="form"
            kind="secondary"
            className={styles.continueButton}
            disabled={isContinueDisabled}
            onClick={onContinue}
          >
            Continue
          </Button>
        )}
        {currentStep === EditApplicationStep.ApplicationDetails && (
          <Button
            size="form"
            kind="primary"
            disabled={isCreateApplicationDisabled()}
            onClick={onCreateApplication}
            className={styles.createApplicationButton}
            isLoading={isCreating}
          >
            Create Application
          </Button>
        )}
      </div>
    </ApplicationContextualViewLayout>
  );
};

export default CreateApplication;
