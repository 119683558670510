import React from 'react';
import clsx from 'clsx';
import styles from './SkeletonCircle.module.scss';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

interface SkeletonCircleProps {
  width: string;
  height: string;
  color: SkeletonColorType;
  className?: string;
  stopAnimation?: boolean;
}

const SkeletonCircle = ({ width, height, color, className, stopAnimation }: SkeletonCircleProps) => {
  return (
    <SkeletonRectangle
      stopAnimation={stopAnimation}
      width={width}
      height={height}
      color={color}
      className={clsx(styles.skeletonCircle, className)}
    />
  );
};

export default SkeletonCircle;
