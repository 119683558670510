import FilterButton from 'components/FilterButton';
import { ReportingTimeDeltas } from 'LoanOriginationSystemReporting/Filters/Types';
import React from 'react';
import ActionsBar from 'components/LoanOriginationSystem/ReportingDashboard/ActionsBar/ActionsBar';
import styles from './ReportingDashboardHeader.module.scss';
import { Option } from 'components/SelectInput/SelectInput';

interface ReportingDashboardHeaderProps {
  timeDelta: ReportingTimeDeltas;
  onTimeDeltaChange: (value: Option) => void;
  onOpenFilterPopup: () => void;
  isInitialFilterState: boolean;
}

const ReportingDashboardHeader = ({
  timeDelta,
  onTimeDeltaChange,
  onOpenFilterPopup,
  isInitialFilterState,
}: ReportingDashboardHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.filtersRow}>
        <ActionsBar timeDelta={timeDelta} onTimeDeltaChange={onTimeDeltaChange} />
        <FilterButton onClick={onOpenFilterPopup} active={!isInitialFilterState} />
      </div>
    </div>
  );
};

export default ReportingDashboardHeader;
