export enum DocuSignActionType {
  ImportDocuSignTemplates = 'eSignTemplates/importDocuSignTemplates',
  ImportDocuSignTemplatesSuccess = 'eSignTemplates/importDocuSignTemplatesSuccess',
  ImportDocuSignTemplatesFailure = 'eSignTemplates/importDocuSignTemplatesFailure',
  GetTemplatesToImport = 'docuSign/getTemplatesToImport',
  GetTemplatesToImportSuccess = 'docuSign/getTemplatesToImportSuccess',
  GetTemplatesToImportFailure = 'docuSign/getTemplatesToImportFailure',
  ChangeSortingType = 'docuSign/changeSortingType',
  SetDeleteTemplateId = 'docuSign/setDeleteTemplateId',
  ToggleImportTemplatesPopupActive = 'docuSign/toggleImportTemplatesPopupActive',
  ToggleVariablesMappingPopupActive = 'docuSign/toggleVariablesMappingPopupActive',
  ToggleRecipientsMappingPopupActive = 'docuSign/toggleRecipientsMappingPopupActive',
  ToggleCreateVariablePopupActive = 'docuSign/toggleCreateVariablePopupActive',
  ResetTemplatesState = 'docuSign/resetTemplatesState',
}
