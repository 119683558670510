import { LoanOriginationSystemTasksFiltersState } from './Types';
import {
  ChangePopupFiltersAction,
  FilterTasksByMembersAction,
  ResetAllTasksFilters,
  SetSearchInputValueAction,
  ToggleFiltersPopupAction,
} from 'LoanOriginationSystemTasks/Filters/ActionCreator';
import { LoanOriginationSystemTasksFiltersActionTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemTasksFiltersState = {
  searchInputValue: '',
  isPopupVisible: false,
  dueDateRange: {
    from: null,
    to: null,
  },
  selectedStatus: null,
  selectedMembers: [],
};

type LoanOriginationSystemTasksFilterAction =
  | SetSearchInputValueAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | FilterTasksByMembersAction
  | ResetAllTasksFilters;

export const loanOriginationSystemTasksFilterReducer = (
  state: LoanOriginationSystemTasksFiltersState,
  action: LoanOriginationSystemTasksFilterAction,
): LoanOriginationSystemTasksFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemTasksFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchInputValue,
      };
    }

    case LoanOriginationSystemTasksFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }

    case LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        isPopupVisible: false,
        dueDateRange: action.payload.dueDateRange,
        selectedStatus: action.payload.status,
      };
    }

    case LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }

    case LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
