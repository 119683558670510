import React, { FC, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ReduxState } from 'types/redux';
import Header from 'components/LeftNav/LeftNavigationHeader';
import UserCard from 'components/LeftNav/UserCard';
import NavigationLinks from 'components/LeftNav/NavigationLinks';
import { toggleBurgerExpand, toggleLeftNavExpand } from 'MainLayout/leftNavReducer';
import { VectorRightImage } from 'static/images';
import Menu from 'components/LeftNav/Menu';
import productsList from './productsList';
import styles from './LeftNavigation.module.scss';
import TransitionExpand from 'components/TransitionExpand';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { ApplicationSectionName, DigifiProduct, ProductAccess } from 'components/RouteWithPermissions/Types';
import { NOT_FOUND_PAGE_SECTION } from 'MainLayout/utils';
import { useAuthProvider } from 'providers/AuthProvider';
import useUserRole from 'MyAccount/useUserRole';
import useOrganizationInfo from 'hooks/useOrganizationInfo';
import { getOrganizations } from 'UserOrganizations/UserOrganizationsActionCreators';
import { UserOrganizationsState } from 'UserOrganizations/UserOrganizationsReducer';

export type LeftNavigationBarType = {
  activeLinkId: string;
  activeProductTitle?: ApplicationSectionName | NOT_FOUND_PAGE_SECTION;
  noLinks?: boolean;
};

const LeftNavigationBar: FC<LeftNavigationBarType> = ({ activeLinkId, activeProductTitle, noLinks }) => {
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationInfo = useOrganizationInfo();
  const userRole = useUserRole();
  const [burgerMenuAnchor, setBurgerMenuAnchor] = useState<Element>();
  const userData = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);
  const userOrganizations = useSelector<ReduxState, UserOrganizationsState | null>((state) => state.userOrganizations);
  const isLeftNavOpen = useSelector((state: ReduxState) => state.leftNavigation.isLeftNavOpen);
  const isBurgerOpen = useSelector((state: ReduxState) => state.leftNavigation.isBurgerOpen);
  const productAccess = useSelector<ReduxState, ProductAccess>(
    (state) =>
      state.sessionInfo.subscription || {
        [DigifiProduct.DecisionEngine]: true,
        [DigifiProduct.LoanOriginationSystem]: true,
      },
  );

  useEffect(() => {
    if (!userOrganizations?.isLoading) {
      dispatch(getOrganizations());
    }
  }, []);

  if (!userData || !userRole || !organizationInfo) {
    return null;
  }

  const handleLogOut = () => {
    authProvider.logout();
  };

  const handleClickOnLogo = () => history.push('/home');

  const setActiveLinkTitle = (route: string) => history.push(route);

  const toggleExpandBurgerMenu = (payload: boolean) => {
    dispatch(toggleBurgerExpand(payload));
  };

  const toggleExpand = (value: boolean) => {
    dispatch(toggleLeftNavExpand(value));
  };

  const onOpen = () => toggleExpand(true);
  const onClose = () => toggleExpand(false);
  const onOpenBurgerMenu = (anchor: SVGSVGElement) => {
    batch(() => {
      setBurgerMenuAnchor(anchor);
      toggleExpandBurgerMenu(true);
    });
  };
  const onCloseBurgerMenu = () => toggleExpandBurgerMenu(false);

  const burgerMenu = isBurgerOpen ? (
    <Menu
      onClose={onCloseBurgerMenu}
      pagesList={productsList}
      activeProductTitle={activeProductTitle}
      onLogOut={handleLogOut}
      multipleOrganizations={userOrganizations ? userOrganizations.items.length > 1 : false}
      productAccess={productAccess}
    />
  ) : null;

  const { links } = productsList.find((product) => product.title === activeProductTitle) || {};

  return (
    <div className={isLeftNavOpen ? styles.navBarContainerExpanded : styles.navBarContainerCollapsed}>
      <Header
        isExpanded={isLeftNavOpen}
        toggleBurgerMenu={onOpenBurgerMenu}
        currentProductTitle={activeProductTitle}
        handleClickOnLogo={handleClickOnLogo}
      />
      <div className={styles.toggleButton} onClick={isLeftNavOpen ? onClose : onOpen}>
        <VectorRightImage className={isLeftNavOpen ? styles.toggleImgOpened : styles.toggleImg} />
        <div className={styles.imageTooltip}>
          <p className={styles.tooltipText}>{isLeftNavOpen ? 'Hide Menu' : 'Show Menu'}</p>
        </div>
      </div>
      <TransitionExpand left={-11} anchorElement={burgerMenuAnchor} className={styles.burgerMenuWrapper}>
        {burgerMenu}
      </TransitionExpand>
      <NavigationLinks
        isExpanded={isLeftNavOpen}
        links={noLinks ? [] : links}
        activeLinkId={activeLinkId}
        setActiveLinkTitle={setActiveLinkTitle}
      />
      <UserCard
        {...userData}
        companyName={organizationInfo.name}
        setActiveLinkTitle={setActiveLinkTitle}
        isActive={activeLinkId === 'UserPage'}
      />
    </div>
  );
};

export default LeftNavigationBar;
