import { connect } from 'react-redux';
import ArchiveStrategyWizard from 'components/ArchiveStrategyWizard/ArchiveStrategyWizard';
import { closeArchiveStrategyWizard } from 'components/ArchiveStrategyWizard/Actions';
import { archiveStrategies, restoreStrategies } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { ChangeStrategyStatusWizardState } from 'components/ArchiveStrategyWizard/changeStrategyStatusWizardReducer';

export type ChangeStrategyStatusWizardConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({
  changeStrategyStatusWizard,
}: {
  changeStrategyStatusWizard: ChangeStrategyStatusWizardState;
}) => {
  return {
    ...changeStrategyStatusWizard,
  };
};

const mapDispatchToProps = {
  restore: restoreStrategies,
  archive: archiveStrategies,
  closePopUp: closeArchiveStrategyWizard,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveStrategyWizard);
