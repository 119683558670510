import React, { FC } from 'react';
import styles from './OrganizationList.module.scss';
import { OrganizationInfo } from 'api/Core/OrganizationApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import clsx from 'clsx';
import { SkeletonCircle } from 'components/Skeleton';
import SkeletonText from 'components/Skeleton/SkeletonText';
import { HandleKeyDown } from 'utils/TriggerEventOnEnterKeyDown';
import KeyEventEnum from 'common/KeyEventEnum';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';
import { OrganizationDefaultIcon } from 'static/images';

interface OrganizationListProps {
  organizations: OrganizationInfo[] | null;
  onSelect: (organization: OrganizationInfo) => void;
}

const SKELETON_ORGANIZATIONS = Array.from({ length: 2 }, () => null);

const OrganizationList: FC<OrganizationListProps> = ({ organizations, onSelect }) => {
  const renderLine = (organization: OrganizationInfo | null, index: number) => {
    const handleClick = () => organization && onSelect(organization);

    return (
      <div
        className={clsx(styles.line, !organization && styles.disabled)}
        key={organization?.id || index}
        onClick={handleClick}
        onKeyDown={HandleKeyDown(KeyEventEnum.Enter, handleClick)}
        tabIndex={index + 1}
      >
        {organization && organization.imageId && (
          <ImageFetcher
            firstName=""
            lastName=""
            size="small"
            imageId={organization.imageId}
            className={clsx(styles.logo, styles.centred)}
          />
        )}
        {organization && !organization.imageId && (
          <div className={styles.organizationDefaultIcon}>
            <OrganizationDefaultIcon />
          </div>
        )}
        {!organization && <SkeletonCircle className={styles.logo} width="36px" height="36px" color="primary10" />}
        <div className={clsx(organization && !organization.lastActiveAt && styles.centred)}>
          {organization && <div className={styles.name}>{organization.name}</div>}
          {organization && organization.lastActiveAt && (
            <div className={styles.lastActiveDate}>
              Last Active {formatDate(new Date(organization.lastActiveAt), DateTimeFormat.Long)}
            </div>
          )}
          {!organization && <SkeletonText width="108px" height="16px" lineHeight="20px" color="primary20" />}
          {!organization && <SkeletonText width="220px" height="12px" lineHeight="16px" color="primary6" />}
        </div>
      </div>
    );
  };

  return (
    <div>
      {organizations?.map(renderLine)}
      {!organizations && SKELETON_ORGANIZATIONS.map(renderLine)}
    </div>
  );
};

export default OrganizationList;
