import React from 'react';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './TagLine.module.scss';

interface TagLineProps {
  minimized?: boolean;
  color: string;
  name: string;
  className?: string;
}

const TagLine = ({ color, name, minimized, className }: TagLineProps) => {
  return (
    <WrapperWithTooltip tooltip={name}>
      <div
        className={clsx(className, styles.tagLine, minimized && styles.minimized)}
        style={{ backgroundColor: color }}
      />
    </WrapperWithTooltip>
  );
};

export default TagLine;
