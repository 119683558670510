import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import LoanOriginationSystemLabelsRestApi, { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { CreateLabelAction, createLabelFailure, createLabelSuccess } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';

const CreateLabelMiddleware = (api: LoanOriginationSystemLabelsRestApi) =>
  Fetcher<Label, CreateLabelAction, ReduxState>(
    LoanOriginationSystemLabelsActionTypes.CreateLabel,
    createLabelSuccess,
    createLabelFailure,
    async ({ payload }) => {
      const newLabel = await api.createLabel(payload.name, payload.color);
      return newLabel;
    },
  );

export default CreateLabelMiddleware;
