import React, { FC } from 'react';
import clsx from 'clsx';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import OutputBuilderCore from 'components/RuleBuilderOutputCore';
import { RuleBuilderDivider, RuleBuilderDividerTextWrapper } from 'components/RuleBuilderDivider';
import styles from './RuleBuilderOutputCoreAdapter.module.scss';

interface RuleBuilderOutputCoreAdapterProps {
  onOutputChange: ({ outputId, key, value }: { outputId: number; key: string; value: string }) => void;
  outputId: number;
  onOutputDelete: (id: number) => void;
  sourceVariableId?: string;
  operandValue: string;
  operandVariableId?: string;
  operandType: ComparisonOperandType;
  order: number;
}

const RuleBuilderOutputCoreAdapter: FC<RuleBuilderOutputCoreAdapterProps> = ({
  outputId,
  onOutputChange,
  onOutputDelete,
  order,
  sourceVariableId,
  ...props
}) => {
  const setSourceVariableId = (value: string): void => {
    onOutputChange({ outputId, key: 'sourceVariableId', value });
  };

  const setOperandValue = (value: string): void => {
    onOutputChange({ outputId, key: 'operandValue', value });
  };

  const setOperandVariableId = (value: string): void => {
    onOutputChange({ outputId, key: 'operandVariableId', value });
  };

  const setOperandType = (value: string): void => {
    onOutputChange({ outputId, key: 'operandType', value });
  };

  const DeleteOutputButton = () => (
    <div className={styles.deleteOutputButton} onClick={() => onOutputDelete(outputId)}>
      Delete Condition
    </div>
  );

  const dividerContainerClasses = clsx({
    [styles.dividerContainerNotFirst]: outputId > 0,
  });

  return (
    <>
      <div className={dividerContainerClasses}>
        <RuleBuilderDivider>
          <RuleBuilderDividerTextWrapper>{`Result ${order} (Assigned if Rule passes)`}</RuleBuilderDividerTextWrapper>
        </RuleBuilderDivider>
      </div>
      <OutputBuilderCore
        {...props}
        setSourceVariableId={setSourceVariableId}
        setOperandValue={setOperandValue}
        setOperandVariableId={setOperandVariableId}
        setOperandType={setOperandType}
        link={outputId !== 0 ? <DeleteOutputButton /> : null}
        sourceVariableId={sourceVariableId}
      />
    </>
  );
};

export default RuleBuilderOutputCoreAdapter;
