import { UpdateUserInfoCredentials } from 'api/Types';
import { User } from 'AccountDetails/AccountDetailsTypes';
import CoreSystemApi from 'api/Core/CoreSystemApi';

export interface IAccountDetailsApi {
  updateAccountInfo(userInfo: UpdateUserInfoCredentials): Promise<User>;
  updateAccountPhoto({ photo }: { photo: File }): Promise<User>;
  updateEmail(email: string): Promise<UpdateUserInfoCredentials>;
  requestPhoneCode(phone: string): Promise<void>;
  enablePhoneAuthentication(phone: string, code: string): Promise<User>;
  disablePhoneAuthentication(code: string): Promise<User>;
}

export default class AccountDetailsRestApi extends CoreSystemApi<User> implements IAccountDetailsApi {
  protected resourceName = '/account-details';

  public async updateAccountInfo(userInfo: UpdateUserInfoCredentials): Promise<User> {
    return this.fetch<User>(`${this.resourceName}`, 'PUT', {
      first_name: userInfo.firstName,
      last_name: userInfo.lastName,
    });
  }

  public async updateAccountPhoto({ photo }: { photo: File }) {
    const formData = new FormData();
    formData.append('file', photo);

    return this.fetch<User>(`${this.resourceName}/photos`, 'PUT', formData, {
      contentType: null,
    });
  }

  public async requestPhoneCode(phone: string) {
    return this.fetch<void>(`${this.resourceName}/phone-authentication-requests`, 'POST', {
      phone,
    });
  }

  public async enablePhoneAuthentication(phone: string, code: string) {
    return this.fetch<User>(`${this.resourceName}/phone-authentications`, 'PUT', {
      phone,
      code,
    });
  }

  public async disablePhoneAuthentication(code: string) {
    return this.fetch<User>(`${this.resourceName}/phone-authentications`, 'DELETE', {
      code,
    });
  }

  public async updateEmail(email: string) {
    return this.fetch<UpdateUserInfoCredentials>(`${this.resourceName}/emails`, 'POST', {
      email,
    });
  }
}
