const IntermediaryDefaultVariable = {
  Name: 'intermediary_name',
  IdNumber: 'intermediary_id_number',
  PhoneNumber: 'intermediary_phone',
  Email: 'intermediary_email',
  Type: 'intermediary_type',
  CommissionRate: 'intermediary_commission_rate',
};

export type IntermediaryDefaultVariableType = typeof IntermediaryDefaultVariable;

export default IntermediaryDefaultVariable;
