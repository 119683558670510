import React, { FC } from 'react';
import TableColumnSizes from 'components/DocuSign/TemplatesTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';

const SkeletonTemplateItem: FC = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={TableColumnSizes.Name}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.UpdatedAt}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Status}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonTemplateItem;
