import { normalize } from 'normalizr';
import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationDataTabCardsApi,
  NormalizedApplicationDataTabCard,
} from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabCardsActionType } from './ActionTypes';
import {
  updateApplicationDataTabCardSuccess,
  updateApplicationDataTabCardFailure,
  UpdateApplicationDataTabCardResult,
  UpdateApplicationDataTabCardAction,
} from './ActionCreator';
import ApplicationDataTabCardSchema from 'schemas/ApplicationDataTabCardSchema';

const UpdateApplicationDataTabCardMiddleware = (api: ApplicationDataTabCardsApi) =>
  Fetcher<UpdateApplicationDataTabCardResult, UpdateApplicationDataTabCardAction>(
    ApplicationDataTabCardsActionType.UpdateApplicationDataTabCard,
    updateApplicationDataTabCardSuccess,
    updateApplicationDataTabCardFailure,
    async (action) => {
      const card = await api.update(action.payload.cardId, {
        name: action.payload.name,
      });

      const normalizedData = normalize(card, ApplicationDataTabCardSchema);

      return {
        cards: normalizedData.entities.cards || ({} as Record<string, NormalizedApplicationDataTabCard>),
        applicationVariableConfigurations:
          normalizedData.entities.applicationVariableConfigurations ||
          ({} as Record<string, ApplicationDataTabVariableConfiguration>),
      };
    },
  );

export default UpdateApplicationDataTabCardMiddleware;
