import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  ChangeApplicationStatusAction,
  ChangeApplicationStatusActionOrigin,
  ChangeApplicationStatusSuccessAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import { LoanOriginationSystemApplicationPageState } from './Types';
import {
  ApplicationPageActionType,
  CLOSE_CHANGE_APPLICATION_STATUS_POPUP,
  GET_APPLICATION_DATA_SUCCESS,
  OPEN_CHANGE_APPLICATION_STATUS_POPUP,
  UPDATE_APPLICATION,
  UPDATE_APPLICATION_FAILED,
  UPDATE_APPLICATION_SUCCESS,
} from './ActionTypes';
import {
  AddApplicationCoborrowerAction,
  AddApplicationCoborrowerSuccessAction,
  AddApplicationIntermediaryAction,
  AddApplicationIntermediarySuccessAction,
  AddApplicationLabelAction,
  AddApplicationLabelSuccessAction,
  CloseChangeApplicationStatusPopupAction,
  DeleteApplicationCoborrowerAction,
  DeleteApplicationCoborrowerSuccessAction,
  DeleteApplicationIntermediaryAction,
  DeleteApplicationIntermediarySuccessAction,
  DeleteApplicationLabelAction,
  DeleteApplicationLabelSuccessAction,
  GetApplicationDataSuccessAction,
  GetApplicationVariablesAction,
  GetApplicationVariablesSuccessAction,
  OpenChangeApplicationStatusPopupAction,
  SortApplicationVariablesAction,
  UpdateApplicationAction,
  UpdateApplicationSuccessAction,
  UpdateApplicationTeamMembersAction,
  UpdateApplicationTeamMembersSuccessAction,
  UpdateApplicationVariablesSuccessAction,
  ClearApplicationDataAction,
} from './ActionCreator';
import applicationNotesReducer, { initialState as applicationNotesInitialState } from './Notes/Reducer';
import applicationStatusesRulesValidationResultsReducer, {
  initialState as applicationStatusesRulesValidationResultsInitialState,
} from './StatusesRules/Reducer';
import applicationDocumentsReducer, { initialState as applicationDocumentsInitialState } from './Documents/Reducer';
import applicationHistoryReducer, { initialState as applicationHistoryInitialState } from './History/Reducer';
import mountChildReducers from 'utils/childReducers';
import { orderBy } from 'lodash';
import ApplicationVariableSortingField from './ApplicationVariableSortingField';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { GetProductActionOrigin, GetProductSuccessAction } from 'LoanOriginationSystemProducts/ActionCreator';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import { Variable } from 'Variables/VariablesTypes';

const initialState: LoanOriginationSystemApplicationPageState = {
  isEditing: false,
  applicationData: null,
  product: null,
  statusToChange: null,
  isStatusChangeInProgress: false,
  notes: applicationNotesInitialState,
  statusesRulesValidationResults: applicationStatusesRulesValidationResultsInitialState,
  documents: applicationDocumentsInitialState,
  history: applicationHistoryInitialState,
  variables: null,
  isDeletingApplicationCoborrower: false,
  isDeletingApplicationIntermediary: false,
  isTeamMembersUpdatingInProgress: false,
  variableSortingType: {
    ascending: true,
    field: ApplicationVariableSortingField.DisplayName,
  },
  isAddingLabelInProgress: false,
  isDeletingLabelInProgress: false,
};

type LoanOriginationSystemApplicationPageActions =
  | GetApplicationDataSuccessAction
  | OpenChangeApplicationStatusPopupAction
  | CloseChangeApplicationStatusPopupAction
  | ChangeApplicationStatusAction
  | ChangeApplicationStatusSuccessAction
  | UpdateApplicationSuccessAction
  | UpdateApplicationAction
  | UpdateApplicationVariablesSuccessAction
  | FailedAction<typeof UPDATE_APPLICATION_FAILED>
  | FailedAction<LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed>
  | FailedAction<ApplicationPageActionType.UpdateApplicationVariablesFailure>
  | GetApplicationVariablesAction
  | GetApplicationVariablesSuccessAction
  | DeleteApplicationCoborrowerAction
  | DeleteApplicationCoborrowerSuccessAction
  | DeleteApplicationIntermediaryAction
  | DeleteApplicationIntermediarySuccessAction
  | FailedAction<ApplicationPageActionType.DeleteApplicationCoborrowerFailed>
  | FailedAction<ApplicationPageActionType.DeleteApplicationIntermediaryFailed>
  | AddApplicationCoborrowerAction
  | AddApplicationCoborrowerSuccessAction
  | AddApplicationIntermediaryAction
  | AddApplicationIntermediarySuccessAction
  | GetProductSuccessAction
  | UpdateApplicationTeamMembersAction
  | UpdateApplicationTeamMembersSuccessAction
  | FailedAction<ApplicationPageActionType.UpdateApplicationTeamMembersFailure>
  | FailedAction<ApplicationPageActionType.AddApplicationCoborrowerFailed>
  | FailedAction<ApplicationPageActionType.AddApplicationIntermediaryFailed>
  | SortApplicationVariablesAction
  | AddApplicationLabelAction
  | AddApplicationLabelSuccessAction
  | FailedAction<ApplicationPageActionType.AddApplicationLabelFailure>
  | DeleteApplicationLabelAction
  | DeleteApplicationLabelSuccessAction
  | FailedAction<ApplicationPageActionType.DeleteApplicationLabelFailure>
  | ClearApplicationDataAction;

const sortApplicationVariables = (
  variables: Variable[],
  applicationData: Application,
  field: ApplicationVariableSortingField,
  ascending: boolean,
) => {
  const sortingType = ascending ? 'asc' : 'desc';

  return field === ApplicationVariableSortingField.DisplayName
    ? orderBy(variables, ['displayName'], [sortingType])
    : orderBy(variables, [({ systemName }) => applicationData.variables[systemName]], [sortingType]);
};

const loanOriginationSystemApplicationPageReducer = (
  state = initialState,
  action: LoanOriginationSystemApplicationPageActions,
) => {
  switch (action.type) {
    case GET_APPLICATION_DATA_SUCCESS:
      return {
        ...state,
        applicationData: action.payload.applicationData,
      };
    case OPEN_CHANGE_APPLICATION_STATUS_POPUP:
      return {
        ...state,
        statusToChange: action.payload.statusToChange,
      };
    case CLOSE_CHANGE_APPLICATION_STATUS_POPUP:
      return {
        ...state,
        statusToChange: null,
      };
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus: {
      if (action.meta?.actionOrigin !== ChangeApplicationStatusActionOrigin.ApplicationsDetails) {
        return state;
      }

      return {
        ...state,
        isStatusChangeInProgress: true,
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusSuccess: {
      if (action.meta?.actionOrigin !== ChangeApplicationStatusActionOrigin.ApplicationsDetails) {
        return state;
      }

      return {
        ...state,
        isStatusChangeInProgress: false,
        statusToChange: null,
        applicationData: action.payload.application,
      };
    }
    case LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed: {
      if (action.meta?.actionOrigin !== ChangeApplicationStatusActionOrigin.ApplicationsDetails) {
        return state;
      }

      return {
        ...state,
        isStatusChangeInProgress: false,
      };
    }
    case UPDATE_APPLICATION:
      return {
        ...state,
        isEditing: true,
      };
    case UPDATE_APPLICATION_SUCCESS:
      return {
        ...state,
        applicationData: action.payload.application,
        isEditing: false,
      };
    case UPDATE_APPLICATION_FAILED:
      return {
        ...state,
        isEditing: false,
      };
    case ApplicationPageActionType.UpdateApplicationVariablesSuccess: {
      return {
        ...state,
        isVariablesUpdateInProgress: false,
        applicationData: action.payload.application,
      };
    }
    case ApplicationPageActionType.UpdateApplicationVariablesFailure: {
      return {
        ...state,
        isVariablesUpdateInProgress: false,
      };
    }
    case ApplicationPageActionType.GetApplicationVariables: {
      return {
        ...state,
        variables: null,
        variableSortingType: {
          ascending: true,
          field: ApplicationVariableSortingField.DisplayName,
        },
      };
    }
    case ApplicationPageActionType.GetApplicationVariablesSuccess: {
      return {
        ...state,
        variables: sortApplicationVariables(
          action.payload.variables,
          state.applicationData!,
          state.variableSortingType.field,
          state.variableSortingType.ascending,
        ),
      };
    }
    case ApplicationPageActionType.DeleteApplicationCoborrower: {
      return {
        ...state,
        isDeletingApplicationCoborrower: true,
      };
    }
    case ApplicationPageActionType.DeleteApplicationCoborrowerSuccess: {
      return {
        ...state,
        isDeletingApplicationCoborrower: false,
        applicationData: action.payload.application,
      };
    }
    case ApplicationPageActionType.DeleteApplicationCoborrowerFailed: {
      return {
        ...state,
        isDeletingApplicationCoborrower: false,
      };
    }
    case ApplicationPageActionType.DeleteApplicationIntermediary: {
      return {
        ...state,
        isDeletingApplicationIntermediary: true,
      };
    }
    case ApplicationPageActionType.DeleteApplicationIntermediarySuccess: {
      return {
        ...state,
        isDeletingApplicationIntermediary: false,
        applicationData: action.payload.application,
      };
    }
    case ApplicationPageActionType.DeleteApplicationIntermediaryFailed: {
      return {
        ...state,
        isDeletingApplicationIntermediary: false,
      };
    }
    case ApplicationPageActionType.AddApplicationIntermediary:
    case ApplicationPageActionType.AddApplicationCoborrower: {
      return {
        ...state,
        isEditing: true,
      };
    }
    case ApplicationPageActionType.AddApplicationIntermediarySuccess:
    case ApplicationPageActionType.AddApplicationCoborrowerSuccess: {
      return {
        ...state,
        isEditing: false,
        applicationData: action.payload.application,
      };
    }
    case ApplicationPageActionType.AddApplicationIntermediaryFailed:
    case ApplicationPageActionType.AddApplicationCoborrowerFailed: {
      return {
        ...state,
        isEditing: false,
      };
    }
    case ApplicationPageActionType.SortApplicationVariables: {
      return {
        ...state,
        variableSortingType: {
          ascending: action.payload.ascending,
          field: action.payload.field,
        },
        variables: sortApplicationVariables(
          state.variables!,
          state.applicationData!,
          action.payload.field,
          action.payload.ascending,
        ),
      };
    }
    case LoanOriginationSystemProductsActionType.GetProductSuccess: {
      if (action.meta?.actionOrigin !== GetProductActionOrigin.ApplicationTabs) {
        return state;
      }

      return {
        ...state,
        product: action.payload.product,
      };
    }
    case ApplicationPageActionType.UpdateApplicationTeamMembers: {
      return {
        ...state,
        isTeamMembersUpdatingInProgress: true,
      };
    }
    case ApplicationPageActionType.UpdateApplicationTeamMembersSuccess: {
      return {
        ...state,
        applicationData: action.payload.application,
        isTeamMembersUpdatingInProgress: false,
      };
    }
    case ApplicationPageActionType.UpdateApplicationTeamMembersFailure: {
      return {
        ...state,
        isTeamMembersUpdatingInProgress: false,
      };
    }
    case ApplicationPageActionType.AddApplicationLabel: {
      return {
        ...state,
        isAddingLabelInProgress: true,
      };
    }
    case ApplicationPageActionType.AddApplicationLabelSuccess: {
      return {
        ...state,
        applicationData:
          state.applicationData?.id === action.payload.application.id
            ? action.payload.application
            : state.applicationData,
        isAddingLabelInProgress: false,
      };
    }
    case ApplicationPageActionType.AddApplicationLabelFailure: {
      return {
        ...state,
        isAddingLabelInProgress: false,
      };
    }
    case ApplicationPageActionType.DeleteApplicationLabel: {
      return {
        ...state,
        isDeletingLabelInProgress: true,
      };
    }
    case ApplicationPageActionType.DeleteApplicationLabelSuccess: {
      return {
        ...state,
        applicationData:
          state.applicationData?.id === action.payload.application.id
            ? action.payload.application
            : state.applicationData,
        isDeletingLabelInProgress: false,
      };
    }
    case ApplicationPageActionType.DeleteApplicationLabelFailure: {
      return {
        ...state,
        isDeletingLabelInProgress: false,
      };
    }
    case ApplicationPageActionType.ClearApplicationData: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const childReducers = {
  documents: applicationDocumentsReducer,
  notes: applicationNotesReducer,
  history: applicationHistoryReducer,
  statusesRulesValidationResults: applicationStatusesRulesValidationResultsReducer,
};

export default mountChildReducers(loanOriginationSystemApplicationPageReducer, childReducers);
