import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { DocuSignIntegration, DocuSignIntegrationSettings } from 'api/Core/DocuSignIntegrationApi';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import ButtonWithImage from 'components/ButtonWithImage';
import DocuSignCredentialsSkeleton from './DocuSignCredentialsSkeleton';
import styles from './DocuSignCredentials.module.scss';

interface DocuSignCredentialsProps {
  integration: DocuSignIntegration | null;
  isIntegrationLoaded: boolean;
  integrationSavingInProgress: boolean;
  onSaveIntegration: (integrationSettings: DocuSignIntegrationSettings) => void;
}

const DEFAULT_SETTINGS: DocuSignIntegrationSettings = {
  clientId: '',
  userId: '',
  privateKey: '',
  accountId: '',
};

const ONE_LINE_INPUT_MAX_LENGTH = 100;

const DocuSignCredentials: FC<DocuSignCredentialsProps> = ({
  integration,
  isIntegrationLoaded,
  onSaveIntegration,
  integrationSavingInProgress,
}) => {
  const [settings, setSettings] = useState(integration ? integration.settings : DEFAULT_SETTINGS);
  const [isEditMode, setIsEditMode] = useState(false);
  const [blurredFields, setBlurredFields] = useState<Array<keyof DocuSignIntegrationSettings>>([]);

  const handleChange = (key: keyof DocuSignIntegrationSettings) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      setSettings({
        ...settings,
        [key]: event.target.value,
      });
    };
  };

  const handleBlurredChange = (field: keyof DocuSignIntegrationSettings) => {
    setBlurredFields([...blurredFields, field]);
  };

  const handleSave = () => onSaveIntegration(settings);

  const isIntegrationConfigured = () =>
    !!(settings.privateKey && settings.accountId && settings.userId && settings.clientId);

  const isSubmitAvailable = () => {
    if (!integration) {
      return isIntegrationConfigured();
    }

    return (
      isIntegrationConfigured() &&
      (settings.privateKey !== integration.settings.privateKey ||
        settings.clientId !== integration.settings.clientId ||
        settings.accountId !== integration.settings.accountId ||
        settings.userId !== integration.settings.userId)
    );
  };

  useEffect(() => {
    if (integration) {
      setSettings(integration.settings);
      setIsEditMode(false);
    }
  }, [integration]);

  const getRequiredFieldError = (field: keyof DocuSignIntegrationSettings, fieldName: string) => {
    return blurredFields.includes(field) && !settings[field] ? `${fieldName} is required` : '';
  };

  const renderForm = () => (
    <>
      <div className={styles.inputsContainer}>
        <div className={styles.inputsRow}>
          <TextInput
            onBlur={() => handleBlurredChange('clientId')}
            labelTitle="Integration Key"
            onChange={handleChange('clientId')}
            value={settings.clientId}
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            errorMessage={getRequiredFieldError('clientId', 'Integration Key')}
          />
          <TextInput
            onBlur={() => handleBlurredChange('userId')}
            labelTitle="API Username (GUID)"
            onChange={handleChange('userId')}
            value={settings.userId}
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            errorMessage={getRequiredFieldError('userId', 'API Username (GUID)')}
          />
        </div>
        <div className={styles.inputsRow}>
          <TextInput
            containerClassName={styles.accountIdInput}
            labelTitle="Account ID"
            maxLength={ONE_LINE_INPUT_MAX_LENGTH}
            onChange={handleChange('accountId')}
            onBlur={() => handleBlurredChange('accountId')}
            errorMessage={getRequiredFieldError('accountId', 'Account ID')}
            value={settings.accountId}
          />
        </div>
        <div className={styles.inputsRow}>
          <TextInput
            containerClassName={styles.privateKeyInputContainer}
            labelTitle="RSA Private Key"
            onChange={handleChange('privateKey')}
            onBlur={() => handleBlurredChange('privateKey')}
            errorMessage={getRequiredFieldError('privateKey', 'RSA Private Key')}
            multiline
            value={settings.privateKey}
          />
        </div>
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          isLoading={integrationSavingInProgress}
          size="form"
          kind="primary"
          onClick={handleSave}
          disabled={!isSubmitAvailable()}
        >
          Save Changes
        </Button>
      </div>
    </>
  );

  const renderCredentialsRow = (title: string, value: string) => {
    return (
      <div className={styles.credentialsRow}>
        <p>{title}</p>
        <p>{value}</p>
      </div>
    );
  };

  const renderCredentials = () => (
    <div>
      {renderCredentialsRow('Integration Key', settings.clientId)}
      {renderCredentialsRow('API Username (GUID)', settings.userId)}
      {renderCredentialsRow('Account ID', settings.accountId)}
      {renderCredentialsRow('RSA Private Key', settings.privateKey)}
    </div>
  );

  const renderHeaderButton = () => {
    if (!isIntegrationLoaded) {
      return null;
    }

    if (isEditMode) {
      return (
        <Button className={styles.closeEditorButton} kind="secondary" onClick={() => setIsEditMode(false)}>
          Close Editor
        </Button>
      );
    }

    return (
      <ButtonWithImage
        className={styles.editButton}
        onClick={() => setIsEditMode(true)}
        title="Edit Credentials"
        kind="edit"
      />
    );
  };

  const renderContent = () => {
    if (!isIntegrationLoaded) {
      return <DocuSignCredentialsSkeleton />;
    }

    if (isEditMode) {
      return renderForm();
    }

    return renderCredentials();
  };

  return (
    <div>
      <div className={styles.header}>
        <h4>Credentials</h4>
        {renderHeaderButton()}
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};

export default DocuSignCredentials;
