import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DateRange } from 'api/LoanOriginationSystem/Types';
import { changeFilters, resetAllFilters, toggleFiltersPopup } from 'DocuSign/Filters/ActionCreator';
import { ESignTemplateFilterStatus } from 'api/Core/ESignTemplatesApi';
import { Option } from 'components/SelectInput/SelectInput';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import styles from './TemplatesFilter.module.scss';

interface TemplatesFilterProps {
  status: ESignTemplateFilterStatus | null;
  createdDateRange: DateRange;
  updateDateRange: DateRange;
}

const filterStatusOptions = [
  { name: 'All', value: '' },
  { name: 'Active', value: ESignTemplateFilterStatus.Active },
  { name: 'Inactive', value: ESignTemplateFilterStatus.Inactive },
];

const TemplatesFilter: FC<TemplatesFilterProps> = ({
  status,
  createdDateRange,
  updateDateRange,
}: TemplatesFilterProps) => {
  const dispatch = useDispatch();
  const integration = useSelector((state: ReduxState) => state.eSignIntegrations.docuSignIntegration);
  const [selectedFilterStatusOption, setSelectedFilterStatusOption] = useState(filterStatusOptions[0]);
  const [createdDateForm, setCreatedDateFrom] = useState(createdDateRange.from);
  const [createdDateTo, setCreatedDateTo] = useState(createdDateRange.to);
  const [updatedDateFrom, setUpdatedDateFrom] = useState(updateDateRange.from);
  const [updatedDateTo, setUpdatedDateTo] = useState(updateDateRange.to);

  const initialSelectedFilterStatusOption = filterStatusOptions.find((option) => (option.value || null) === status)!;

  useEffect(() => {
    setSelectedFilterStatusOption(initialSelectedFilterStatusOption);
  }, []);

  const filtersResult = {
    status: (selectedFilterStatusOption.value as ESignTemplateFilterStatus) || null,
    createdDateRange: {
      from: createdDateForm,
      to: createdDateTo,
    },
    updatedDateRange: {
      from: updatedDateFrom,
      to: updatedDateTo,
    },
  };

  const handleStatusChangeChange = (newOption: Option) => {
    setSelectedFilterStatusOption(filterStatusOptions.find(({ value }) => value === newOption.value)!);
  };

  const onClearFiltersClick = () => {
    dispatch(resetAllFilters(integration?.id));
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changeFilters(filtersResult, integration?.id));
  };

  const handleCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setCreatedDateFrom(from);
    setCreatedDateTo(to);
  };

  const handleUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setUpdatedDateFrom(from);
    setUpdatedDateTo(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Status"
        onChange={handleStatusChangeChange}
        options={filterStatusOptions}
        selectedOption={selectedFilterStatusOption}
        hideClearIcon
        className={styles.statusDropdownContainer}
      />
      <DateRangePicker
        from={createdDateForm}
        to={createdDateTo}
        onChange={handleCreatedDateRangeChange}
        label="Created"
      />
      <DateRangePicker
        from={updatedDateFrom}
        to={updatedDateTo}
        onChange={handleUpdatedDateRangeChange}
        label="Updated"
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default TemplatesFilter;
