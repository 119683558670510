import { AnyAction } from 'redux';
import { LoanOriginationSystemLabelsFiltersActionTypes } from './ActionTypes';

export interface SetSearchInputValueAction extends AnyAction {
  type: LoanOriginationSystemLabelsFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchInputValue: string;
  };
}

export const setSearchInputValue = (searchInputValue: string): SetSearchInputValueAction => {
  return {
    type: LoanOriginationSystemLabelsFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchInputValue,
    },
  };
};
