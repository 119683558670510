import AuthEventEmitter, { AuthEventMessage } from 'eventHandlers/AuthEventEmitter';
import { SessionInfo } from 'SessionInfo/Types';
import getUserInfo from './GetUserInfo';
import getOrganizationInfo from './GetOrganizationInfo';
import getPermissionsInfo from './GetPermissionsInfo';
import getSubscription from 'api/ResponseHandlers/GetSubscription';
import { pickBy } from 'lodash';

const emitSessionInfo = (response: Response): void => {
  const user = getUserInfo(response);
  const organization = getOrganizationInfo(response);
  const permissions = getPermissionsInfo(response);
  const subscription = getSubscription(response);

  const sessionInfo: Partial<SessionInfo> = pickBy({
    user,
    organization,
    permissions,
    subscription,
  });

  AuthEventEmitter.emit(AuthEventMessage.SessionInfoUpdate, sessionInfo);
};

export default emitSessionInfo;
