import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getApplicationTabs } from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import { getTabsByProductId } from 'LoanOriginationSystemApplicationTabs/Selectors';
import ApplicationTabs from './ApplicationTabs';
import { useHistory } from 'react-router-dom';
import { getOrganizationEmail } from 'utils/emailGetters';
import { getProduct, GetProductActionOrigin } from 'LoanOriginationSystemProducts/ActionCreator';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';

interface ApplicationTabsConnectorProps {
  onCreateTask: () => void;
  selectedTabId: string | null;
  onEditTask: (id: string) => void;
}

const ApplicationTabsConnector = ({ onCreateTask, onEditTask, selectedTabId }: ApplicationTabsConnectorProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const applicationData = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.applicationData,
  );
  const product = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.product);

  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);

  const tabs = useSelector((state: ReduxState) => getTabsByProductId(state, applicationData?.product?.id));

  const handleGetApplicationTabs = (productId: string) => {
    dispatch(getApplicationTabs(productId));
  };

  const handleGetProduct = (productId: string) => {
    dispatch(setActionOrigin(getProduct(productId), GetProductActionOrigin.ApplicationTabs));
  };

  const handleTabChange = (tabId: string) => {
    history.push(`?tab-id=${tabId}`);
  };

  return (
    <ApplicationTabs
      application={applicationData || null}
      productId={applicationData?.product?.id || null}
      product={product}
      tabs={tabs}
      onGetProduct={handleGetProduct}
      onGetApplicationTabs={handleGetApplicationTabs}
      onCreateTask={onCreateTask}
      onEditTask={onEditTask}
      onTabChange={handleTabChange}
      selectedTabId={selectedTabId}
      organizationEmail={getOrganizationEmail(organizationInfo.id)}
    />
  );
};

export default ApplicationTabsConnector;
