import React from 'react';
import { DocIcon, FileIcon, ImageIcon, JsonIcon, PdfIcon, PptIcon, XlsIcon } from 'static/images';
import { ApplicationDocument, DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import RowActions from 'components/RowActions';
import { ApplicationDocumentsTableSize } from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/ApplicationDocumentsTable/TableColumnSizes';
import SearchHighlight from 'components/SearchHighlight';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import styles from './ApplicationDocumentItemRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';

interface ApplicationDocumentItemRowProps {
  document: ApplicationDocument;
  onClick: (document: ApplicationDocument) => void;
  searchInputValue: string;
  onApplicationDocumentDelete: (document: ApplicationDocument) => void;
  onApplicationDocumentDownload: (document: ApplicationDocument) => void;
  onRenameApplicationDocument: (document: ApplicationDocument) => void;
}

export const iconsByExtension = new Map([
  [DocumentExtension.Xls, <XlsIcon />],
  [DocumentExtension.Xlsx, <XlsIcon />],
  [DocumentExtension.Csv, <XlsIcon />],
  [DocumentExtension.Doc, <DocIcon />],
  [DocumentExtension.Docx, <DocIcon />],
  [DocumentExtension.Xml, <DocIcon />],
  [DocumentExtension.Txt, <DocIcon />],
  [DocumentExtension.Ppt, <PptIcon />],
  [DocumentExtension.Pptx, <PptIcon />],
  [DocumentExtension.Pdf, <PdfIcon />],
  [DocumentExtension.Json, <JsonIcon />],
  [DocumentExtension.Gif, <ImageIcon />],
  [DocumentExtension.Png, <ImageIcon />],
  [DocumentExtension.Jpeg, <ImageIcon />],
  [DocumentExtension.Jpg, <ImageIcon />],
  [DocumentExtension.Svg, <ImageIcon />],
]);

export const getBytesInMb = (bytes: number) => {
  return bytes / (1024 * 1024);
};

export const SMALL_SIZE_DISPLAY_SIZE = 0.1;

const ApplicationDocumentItemRow = ({
  document,
  searchInputValue,
  onClick,
  onApplicationDocumentDelete,
  onApplicationDocumentDownload,
  onRenameApplicationDocument,
}: ApplicationDocumentItemRowProps) => {
  const renderCellContent = (content: string) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue}>{content}</SearchHighlight>
    </OverflowedText>
  );

  // if file less then 5kb, then .toFixed(1) will return "0.0" and we show it like <SMALL_SIZE_DISPLAY_SIZE>
  const fileSize = Number(getBytesInMb(document.size).toFixed(1)) || SMALL_SIZE_DISPLAY_SIZE;

  return (
    <TableRow useActions key={document.id} onClick={() => onClick(document)}>
      <TableBodyCell width={ApplicationDocumentsTableSize.Name} noPadding>
        <div className={styles.extensionIcon}>{iconsByExtension.get(document.extension) || <FileIcon />}</div>
        {renderCellContent(document.name)}
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.Type} overflowed>
        {document.extension}
      </TableBodyCell>
      <TableBodyCell className={styles.sizeCell} width={ApplicationDocumentsTableSize.Size} noPadding overflowed>
        {`${fileSize}Mb`}
      </TableBodyCell>
      <TableBodyCell width={ApplicationDocumentsTableSize.CreatedAt} noPadding>
        <WithSystemApiUserAvatar className={styles.avatar} user={document.createdBy} size="small" />
        <OverflowedText>{formatDate(document.createdAt, DateTimeFormat.Short)}</OverflowedText>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Download Document',
            handler: () => onApplicationDocumentDownload(document),
            danger: false,
          },
          {
            title: 'Rename Document',
            handler: () => onRenameApplicationDocument(document),
            separatorRequired: true,
          },
          {
            title: 'Delete Document',
            handler: () => onApplicationDocumentDelete(document),
            danger: true,
            separatorRequired: false,
          },
        ]}
      />
    </TableRow>
  );
};

export default ApplicationDocumentItemRow;
