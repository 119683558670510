export enum DerivedComparisonsTypes {
  'EQUAL' = '=',
  'NOT EQUAL' = '<>',
  'FLOOR' = '>=',
  'CAP' = '<=',
  'GT' = '>',
  'LT' = '<',
  'IN' = 'In',
  'NOT IN' = 'Not In',
  'RANGE' = 'Between',
  'IS NULL' = 'Is Null',
  'IS NOT NULL' = 'Is Not Null',
}
