export enum UserRoleName {
  Owner = 'owner',
  Admin = 'admin',
  User = 'user',
}

export interface UserState {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  isMfaEnabled: boolean;
  isEmailVerified: boolean;
  error: string;
  requestPhoneAuthenticationError: string;
  isLoading: boolean;
  isActive: boolean;
  imageId?: string;
}

export interface ChangeUserInfoParams {
  _id: string;
  firstName: string;
  lastName: string;
}

export interface ChangeUserPhotoParams {
  photo: File;
}

export interface User {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string | null;
  status: {
    active: boolean;
    mfa: boolean;
  };
  imageId?: string;
  association: {
    organization: {
      _id: string;
      name: string;
    };
  };
}
