enum Feature {
  CloudHosting = 'CloudHosting',
  OnlineAccess = 'OnlineAccess',
  OnlineDocumentation = 'OnlineDocumentation',
  StandardProcessAutomation = 'StandardProcessAutomation',
  StandardIntegrations = 'StandardIntegrations',
  CustomerSupport = 'CustomerSupport',
  ApiAccess = 'ApiAccess',
  DedicatedSupportLead = 'DedicatedSupportLead',
  DataExport = 'DataExport',
  CustomProcessAutomation = 'CustomProcessAutomation',
  CustomIntegrations = 'CustomIntegrations',
  UserTraining = 'UserTraining',
  AdditionalSandboxAccount = 'AdditionalSandboxAccount',
  UptimeGuaranteeSla = 'UptimeGuaranteeSla',
  ResponseTimeSla = 'ResponseTimeSla',
  SecurityAndComplianceAuditSupport = 'SecurityAndComplianceAuditSupport',
  CustomLegalAgreements = 'CustomLegalAgreements',
}

export default Feature;
