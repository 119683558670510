import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { DuplicateStrategyWizardActionType } from 'DuplicateStrategyWizard/ActionTypes';

export const openDuplicateStrategyWizard = (
  strategyId: string,
  strategyName: string,
  actionOrigin: StrategyActionOrigin,
) => {
  return {
    type: DuplicateStrategyWizardActionType.OpenWizard,
    payload: {
      strategyId,
      strategyName,
      actionOrigin,
    },
    meta: { actionOrigin },
  };
};

export const closeDuplicateStrategyWizard = () => {
  return {
    type: DuplicateStrategyWizardActionType.CloseWizard,
  };
};
