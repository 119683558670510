import React, { JSXElementConstructor } from 'react';

type EnvironmentValue = string | boolean | number | undefined;

const EnvironmentWrapper = <ComponentProps extends {}>(
  Component: JSXElementConstructor<ComponentProps>,
  environmentValue: EnvironmentValue,
  expectedValue: EnvironmentValue,
  ...additionalExpectedValues: EnvironmentValue[]
) => {
  return (props: ComponentProps) =>
    [expectedValue, ...additionalExpectedValues].includes(environmentValue) ? <Component {...props} /> : null;
};

export default EnvironmentWrapper;
