import { ActionsToThunksMap } from 'stores/MapActionToThunkMiddleware';
import responseHandlers from 'api/ResponseHandlers';
import ProductSectionAccessPermissionsActionTypes from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsActionTypes';
import {
  GetProductSectionAccessPermissions,
  ResetProductSectionAccessPermissions,
  UpdateProductSectionAccessPermissions,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsThunks';
import PermissionApi from 'api/Core/Permission';

const permissionsApi = new PermissionApi(responseHandlers);

const actionsToThunksMap: ActionsToThunksMap = {
  [ProductSectionAccessPermissionsActionTypes.GetProductSectionAccessPermissionsRequest]: GetProductSectionAccessPermissions(
    permissionsApi,
  ),
  [ProductSectionAccessPermissionsActionTypes.UpdateProductSectionAccessPermissionRequest]: UpdateProductSectionAccessPermissions(
    permissionsApi,
  ),
  [ProductSectionAccessPermissionsActionTypes.ResetPermissionsRequest]: ResetProductSectionAccessPermissions(
    permissionsApi,
  ),
};

export default actionsToThunksMap;
