import { stringify } from 'querystring';
import { compact, uniq, identity, pickBy } from 'lodash';

type OptionalEmail = string | undefined;
type Addressee = string | OptionalEmail[];

interface MailtoParams {
  to?: Addressee;
  cc?: Addressee;
  bcc?: Addressee;
  subject?: string;
  body?: string;
}

interface NormalizedMailtoParams {
  to?: string;
  cc?: string;
  bcc?: string;
  subject?: string;
  body?: string;
}

type AddressSeparator = ',' | ';';

const getAddresseeString = (addressee: Addressee, separator: AddressSeparator) =>
  typeof addressee === 'string' ? addressee : uniq(compact(addressee)).join(separator);

const normalizeMailtoParams = (params: MailtoParams): NormalizedMailtoParams => {
  const { to, cc, bcc, subject, body } = params;
  return {
    to: to && getAddresseeString(to, ';'),
    cc: cc && getAddresseeString(cc, ','),
    bcc: bcc && getAddresseeString(bcc, ','),
    subject,
    body,
  };
};

const getMailtoQueryString = (params: Omit<NormalizedMailtoParams, 'to'>) =>
  stringify(pickBy(params), undefined, undefined, {
    encodeURIComponent: identity,
  });

export const getMailtoURI = (params: MailtoParams) => {
  const { to, ...normalizedParams } = normalizeMailtoParams(params);
  const uriBase = `mailto:${to || ''}`;
  const queryString = getMailtoQueryString(normalizedParams);
  return queryString ? `${uriBase}?${queryString}` : uriBase;
};
