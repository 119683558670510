import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { StrategyActionType } from './ActionTypes';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export interface CreateStrategySuccessAction {
  type: StrategyActionType.CreateStrategySuccess;
  payload: {
    strategyId: string;
  };
}

export interface DuplicateStrategyAction {
  type: StrategyActionType.DuplicateStrategy;
  payload: {
    strategyId: string;
  };
  meta: { actionOrigin: StrategyActionOrigin };
}

export interface DuplicateStrategySuccessAction {
  type: StrategyActionType.DuplicateStrategySuccess;
  payload: {
    strategyId: string;
  };
}

export const createStrategy = () => ({
  type: StrategyActionType.CreateStrategy,
});

export const createStrategySuccess = ({
  strategyId,
}: CreateStrategySuccessAction['payload']): CreateStrategySuccessAction => {
  return {
    type: StrategyActionType.CreateStrategySuccess,
    payload: {
      strategyId,
    },
  };
};

export const createStrategyFailure = FailedActionCreator(StrategyActionType.CreateStrategyFailure);

export const duplicateStrategy = (strategyId: string, actionOrigin: StrategyActionOrigin): DuplicateStrategyAction => ({
  type: StrategyActionType.DuplicateStrategy,
  payload: { strategyId },
  meta: { actionOrigin },
});

export const duplicateStrategySuccess = (strategyId: string) => ({
  type: StrategyActionType.DuplicateStrategySuccess,
  payload: { strategyId },
});

export const duplicateStrategyFailure = FailedActionCreator(StrategyActionType.DuplicateStrategyFailure);
