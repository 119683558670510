import { RuleCreateReducerActionType, AddRuleState } from './Types';
import {
  OPEN_ADD_RULE_POP_UP,
  CLOSE_ADD_RULE_POP_UP,
  SET_CURRENT_RULE_TYPE,
  ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE,
} from './ActionType';

const initialState: AddRuleState = {
  isCreatingVariable: false,
  moduleType: null,
  rulePopUpType: null,
  entityType: null,
};

const RuleCreateReducer = (state = initialState, action: RuleCreateReducerActionType): AddRuleState => {
  switch (action.type) {
    case OPEN_ADD_RULE_POP_UP: {
      return { ...state, moduleType: action.payload.moduleType, entityType: action.payload.entityType };
    }
    case CLOSE_ADD_RULE_POP_UP: {
      return initialState;
    }
    case SET_CURRENT_RULE_TYPE: {
      return { ...state, rulePopUpType: action.payload };
    }
    case ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE: {
      return { ...state, isCreatingVariable: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default RuleCreateReducer;
