import { LoanOriginationSystemApplicationsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  DeleteApplicationAction,
  deleteApplicationFailed,
  deleteApplicationSuccess,
} from 'LoanOriginationSystemApplications/ActionCreator';

const DeleteApplicationMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<void, DeleteApplicationAction, ReduxState>(
    LoanOriginationSystemApplicationsActionType.DeleteApplication,
    deleteApplicationSuccess,
    deleteApplicationFailed,
    async (action) => {
      const { applicationId } = action.payload;

      await api.deleteApplication(applicationId);
    },
  );

export default DeleteApplicationMiddleware;
