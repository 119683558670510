import styles from './Chart.module.scss';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarGroup } from '@visx/shape';
import React from 'react';
import { MonthAmount } from './Chart';
import { localPoint } from '@visx/event';

interface BarsViewProps {
  dateScale: any;
  dataByMonth: MonthAmount[];
  chartKeys: string[];
  height: number;
  factor: number;
  hideTooltip: () => void;
  showTooltip: (args: any) => void;
  yAxisMax: number;
  colorsList: string[];
}

const CHART_HEADER_HEIGHT = 91;

const getDate = (d: any) => d.date;

let tooltipTimeout: number;

const BarsView = ({
  dateScale,
  dataByMonth,
  chartKeys,
  height,
  factor,
  hideTooltip,
  showTooltip,
  yAxisMax,
  colorsList,
}: BarsViewProps) => {
  const cityScale = scaleBand<string>({
    domain: chartKeys,
    padding: 0,
  });

  cityScale.rangeRound([0, dateScale.bandwidth()]);

  const colorScale = scaleOrdinal<string, string>({
    domain: chartKeys,
    range: colorsList,
  });

  const formattedData = dataByMonth.map((entity) => {
    return {
      ...entity,
      Number: entity.Number * factor,
    };
  });

  const yScale = scaleLinear<number>({
    domain: [0, yAxisMax],
  });

  yScale.range([height - CHART_HEADER_HEIGHT, 0]);

  return (
    <Group top={22}>
      <BarGroup
        data={formattedData}
        keys={chartKeys}
        height={height - CHART_HEADER_HEIGHT}
        x0={getDate}
        x0Scale={dateScale}
        x1Scale={cityScale}
        color={colorScale}
        yScale={yScale}
      >
        {(barGroups) => {
          return barGroups.map((barGroup) => (
            <Group
              key={`bar-group-${barGroup.index}-${barGroup.x0}`}
              left={barGroup.x0}
              className={styles.barGroup}
              onMouseLeave={() => {
                tooltipTimeout = window.setTimeout(() => {
                  hideTooltip();
                }, 300);
              }}
              onMouseMove={(event) => {
                if (tooltipTimeout) {
                  clearTimeout(tooltipTimeout);
                }
                const eventSvgCoords = localPoint(event)!;
                const left = barGroup.x0 + barGroup.bars[0].width;
                const tooltipData = {
                  dollarAmount: barGroup.bars[0].value,
                  numberAmount: barGroup.bars[1].value / factor,
                  date: dataByMonth[barGroup.index].date,
                };
                showTooltip({
                  tooltipData,
                  tooltipTop: eventSvgCoords.y,
                  tooltipLeft: left,
                });
              }}
            >
              {barGroup.bars.map((bar) => (
                <rect
                  key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                  x={bar.x}
                  y={bar.y}
                  width={bar.width}
                  height={bar.height}
                  fill={bar.color}
                />
              ))}
            </Group>
          ));
        }}
      </BarGroup>
    </Group>
  );
};

export default BarsView;
