import React, { useState } from 'react';
import { AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import styles from './NumberOfDays.module.scss';
import clsx from 'clsx';
import NumberInput from 'components/NumberInput';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';

const MAX_SYMBOLS_LENGTH = 3;

interface NumberOfDaysProps {
  automatedProcess: AutomatedProcess;
  onNumberOfDaysUpdate: (updatedNumberOfDays: number) => void;
  disabled?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
}

const NumberOfDays = ({
  automatedProcess,
  onNumberOfDaysUpdate,
  disabled,
  onLoaderStateReset,
  loaderState,
}: NumberOfDaysProps) => {
  const [numberOfDays, setNumberOfDays] = useState(`${automatedProcess.runtimeOptions.numberOfDays}`);

  const setNumberOfDaysWithLimit = (value: string) => {
    const properValue = () => {
      if (value === '0') {
        return value.replace(value, '');
      }

      return value
        .split('')
        .filter((symbol) => symbol !== '.' && symbol !== '-')
        .join('');
    };

    return setNumberOfDays(properValue());
  };

  const handleInputBlur = (value: string) => {
    setNumberOfDays(value);

    const updatedNumberOfDays = Number(value);

    if (!Number.isNaN(updatedNumberOfDays)) {
      onNumberOfDaysUpdate(updatedNumberOfDays);
    }
  };

  return (
    <div className={styles.inner}>
      <NumberInput
        value={`${numberOfDays}`}
        onChange={setNumberOfDaysWithLimit}
        containerClassName={styles.inputContainer}
        maxLength={MAX_SYMBOLS_LENGTH}
        onBlur={handleInputBlur}
        disabled={disabled}
        onLoaderStateReset={onLoaderStateReset}
        loaderState={loaderState}
        showLoader
        loaderClassName={styles.loader}
      />
      <span className={clsx(styles.description, styles.daysAmount)}>Days</span>
    </div>
  );
};

export default NumberOfDays;
