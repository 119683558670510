import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './InfoPanelContentItem.module.scss';

export interface InfoPanelContentItemProps {
  label: string;
  className?: string;
  itemClassName?: string;
  labelClassName?: string;
}

const InfoPanelContentItem = ({
  label,
  className,
  children,
  itemClassName,
  labelClassName,
}: PropsWithChildren<InfoPanelContentItemProps>) => {
  return (
    <div className={clsx(styles.infoPanelContentItemInner, className)}>
      <div className={clsx(styles.infoPanelLabel, labelClassName)}>{label}</div>
      <div className={clsx(styles.infoPanelContentItem, itemClassName)}>{children}</div>
    </div>
  );
};

export default InfoPanelContentItem;
