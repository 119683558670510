import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { ApplicationDataTabVariableConfiguration } from './ApplicationDataTabVariableConfigurationsApi';

interface BaseApplicationDataTabCard {
  id: string;
  tabId: string;
  name: string;
  row: number;
  position: number;
}

export interface ApplicationDataTabCard extends BaseApplicationDataTabCard {
  fields: ApplicationDataTabVariableConfiguration[];
}

export interface NormalizedApplicationDataTabCard extends BaseApplicationDataTabCard {
  fields: string[];
}

export interface CreateApplicationDataTabCardParams {
  tabId: string;
  row: number;
  position: number;
  name: string;
}

export type UpdateApplicationDataTabCardParams = Partial<Omit<CreateApplicationDataTabCardParams, 'tabId' | 'row'>>;

export interface ApplicationDataTabCardsApi {
  find(tabId: string): Promise<ApplicationDataTabCard[]>;
  create(params: CreateApplicationDataTabCardParams): Promise<ApplicationDataTabCard>;
  update(id: string, params: UpdateApplicationDataTabCardParams): Promise<ApplicationDataTabCard>;
  delete(id: string): Promise<void>;
}

export default class ApplicationDataTabCardsRestApi extends LoanOriginationSystemApi<ApplicationDataTabCard>
  implements ApplicationDataTabCardsApi {
  protected resourceName = 'application-cards';

  public find(tabId: string) {
    const params = new URLSearchParams();

    params.set('tabId', tabId);

    return this.getResources(params);
  }

  public create(params: CreateApplicationDataTabCardParams) {
    return this.createResource({
      card: params,
    });
  }

  public update(id: string, params: UpdateApplicationDataTabCardParams) {
    return this.updateResource(id, {
      card: params,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
