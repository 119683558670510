import { VariableValue } from 'api/LoanOriginationSystem/Types';

export const getTableDisplayValue = (value: VariableValue) => {
  if (value === null) {
    return 'Null';
  }

  if (value === undefined) {
    return 'Undefined';
  }

  return value.toString();
};
