import React, { useState } from 'react';
import styles from './OverviewOfScripts.module.scss';
import { VectorBottomImage } from 'static/images';
import finalScreen from 'static/images/popup-final-screen-calculation-script.png';

const OverviewOfScripts = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenOverview = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.overviewContainer}>
      <div className={styles.overviewHeader}>
        <div onClick={handleOpenOverview}>
          <button type="button" className={isOpen ? '' : styles.isOpen}>
            <VectorBottomImage />
          </button>
          <p>Overview of Calculation Scripts</p>
        </div>
      </div>
      <div className={`${styles.overviewContent} ${isOpen ? styles.overviewCollapsed : styles.overviewExpanded}`}>
        <h6>Calculation Scripts assign the results of calculations to Output Variables.</h6>
        <ul>
          <li>
            <p>
              The calculations must be written in JavaScript as a single line of code that calculates the Output
              Variable
            </p>
          </li>
          <li>
            <p>You should not include anything except the actual formula (e.g. do not include a “return” statement)</p>
          </li>
          <li>
            <p>
              When referencing other variables in calculations, you must refer to their Variable System Name. All
              variables used must be added to the Required Variables list.
            </p>
          </li>
        </ul>
        <p>
          Below please find an example Calculation Script. This will calculate the Total Price Of Dinner (an Output
          Variable) as Base Price + Taxes + $3.00.
        </p>
        <img src={finalScreen} alt="Final screen" />
      </div>
    </div>
  );
};

export default OverviewOfScripts;
