import React from 'react';
import { useSelector } from 'react-redux';

import { ApplicationsImage, CustomersImage, SettingsImage } from 'static/images';

import { ReduxState } from 'types/redux';
import Footer from 'components/Footer';
import NeedMoreHelp from 'components/NeedMoreHelp';

import MainLayout from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import HomeDashboard from 'components/HomeDashboard';
import { CompanySettings, HomeDashboardCard } from 'components/HomeDashboard/HomeDashboard';
import PageFooter from 'MainLayout/PageFooter';
import PageContent from 'MainLayout/PageContent';
import PageWrapper from 'MainLayout/PageWrapper';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { ApplicationSectionName, DigifiProduct, ProductAccess } from 'components/RouteWithPermissions/Types';

const cards: HomeDashboardCard[] = [
  {
    product: DigifiProduct.LoanOriginationSystem,
    icon: <ApplicationsImage />,
    title: 'Loan Origination System',
    description: 'Next-gen platform for managing the borrower acquisition process',
    redirectURL: '/los/applications',
  },
  {
    product: DigifiProduct.DecisionEngine,
    icon: <CustomersImage />,
    title: 'Automated Decision Engine',
    description: 'Automation of underwriting policies and data integrations',
    redirectURL: '/decision/strategies',
  },
  {
    product: CompanySettings,
    icon: <SettingsImage />,
    title: 'Company Settings',
    description: 'Manage company information, user access, and API credentials',
    redirectURL: '/company-settings/company_info',
    basicProduct: true,
  },
];

export default function Home() {
  const userData = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);

  const productAccess = useSelector<ReduxState, ProductAccess | null>((state) => state.sessionInfo.subscription);

  if (!userData || !productAccess) {
    return null;
  }

  const leftNav = makeLeftNavigation('Dashboard', ApplicationSectionName.Dashboard);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent>
          <HomeDashboard cards={cards} userFirstName={userData.firstName} productAccess={productAccess} />
        </PageContent>
        <PageFooter>
          <NeedMoreHelp link="https://www.digifi.io/contact-support" />
          <Footer />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
}
