import {
  GET_BRANCH_INFO_REQUEST,
  GET_BRANCH_INFO_REQUEST_SUCCESS,
  GET_BRANCH_INFO_REQUEST_FAILURE,
  UPDATE_BRANCH_NAME_REQUEST,
  UPDATE_BRANCH_NAME_REQUEST_SUCCESS,
  UPDATE_BRANCH_NAME_REQUEST_FAILURE,
  REORDER_BRANCH_RULES,
  REORDER_BRANCH_RULES_FAILURE,
  REORDER_BRANCH_RULES_SUCCESS,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_FAILURE,
  DELETE_BRANCH_SUCCESS,
  DUPLICATE_BRANCH_REQUEST,
  DUPLICATE_BRANCH_SUCCESS,
  DUPLICATE_BRANCH_FAILURE,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  BranchInfoRequestData,
  BranchInfo,
  ReorderBranchRuleParams,
  BranchParams,
  DuplicateBranchSuccessData,
} from './Types';
import { AnyAction } from 'redux';
import { DuplicateBranchData } from 'ModuleInfo/Types';

export interface GetBranchInfoRequestAction {
  type: typeof GET_BRANCH_INFO_REQUEST;
  payload: BranchInfoRequestData;
}

export interface GetBranchInfoSuccessAction {
  type: typeof GET_BRANCH_INFO_REQUEST_SUCCESS;
  payload: BranchInfo;
}

export interface ReorderBranchRulesRequestAction {
  type: typeof REORDER_BRANCH_RULES;
  payload: ReorderBranchRuleParams;
}

export interface DeleteBranchRequestAction {
  type: typeof DELETE_BRANCH_REQUEST;
  payload: BranchParams;
}

export interface DuplicateBranchRequestAction {
  type: typeof DUPLICATE_BRANCH_REQUEST;
  payload: DuplicateBranchData;
}

export interface DuplicateBranchSuccessAction {
  type: typeof DUPLICATE_BRANCH_SUCCESS;
  payload: DuplicateBranchSuccessData;
}

export const getBranchInfoRequest = (payload: BranchInfoRequestData): GetBranchInfoRequestAction => ({
  type: GET_BRANCH_INFO_REQUEST,
  payload,
});

export const getBranchInfoRequestSuccess = (payload: BranchInfo): GetBranchInfoSuccessAction => ({
  type: GET_BRANCH_INFO_REQUEST_SUCCESS,
  payload,
});

export interface UpdateBranchNameRequestAction extends AnyAction {
  type: typeof UPDATE_BRANCH_NAME_REQUEST;
  payload: BranchParams;
}

export const updateBranchNameRequest = (payload: BranchParams): UpdateBranchNameRequestAction => {
  return {
    type: UPDATE_BRANCH_NAME_REQUEST,
    payload,
  };
};

export const updateBranchNameRequestSuccess = (payload: BranchParams) => {
  return {
    type: UPDATE_BRANCH_NAME_REQUEST_SUCCESS,
    payload,
  };
};

export const updateBranchNameRequestError = FailedActionCreator(UPDATE_BRANCH_NAME_REQUEST_FAILURE);

export const getBranchInfoRequestFailure = FailedActionCreator(GET_BRANCH_INFO_REQUEST_FAILURE);

export const reorderBranchRulesRequest = (payload: ReorderBranchRuleParams): ReorderBranchRulesRequestAction => {
  return {
    type: REORDER_BRANCH_RULES,
    payload,
  };
};

export const reorderBranchRulesRequestSuccess = (payload: ReorderBranchRuleParams) => {
  return {
    type: REORDER_BRANCH_RULES_SUCCESS,
    payload,
  };
};

export const reorderBranchRulesRequestError = FailedActionCreator(REORDER_BRANCH_RULES_FAILURE);

export const deleteBranchRequest = (payload: BranchParams): DeleteBranchRequestAction => {
  return {
    type: DELETE_BRANCH_REQUEST,
    payload,
  };
};

export const deleteBranchRequestSuccess = (payload: BranchParams) => {
  return {
    type: DELETE_BRANCH_SUCCESS,
    payload,
  };
};

export const deleteBranchRequestError = FailedActionCreator(DELETE_BRANCH_FAILURE);

export const duplicateBranch = (payload: DuplicateBranchData): DuplicateBranchRequestAction => ({
  type: DUPLICATE_BRANCH_REQUEST,
  payload,
});

export const duplicateBranchSuccess = (payload: DuplicateBranchSuccessData) => ({
  type: DUPLICATE_BRANCH_SUCCESS,
  payload,
});

export const duplicateBranchError = FailedActionCreator(DUPLICATE_BRANCH_FAILURE);
