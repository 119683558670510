import { AnyAction } from 'redux';

export enum EmailVerificationLinkExpiredActionTypes {
  ResendEmailVerificationForExpiredToken = 'emailVerificationLinkExpired/resendEmailVerificationForExpiredToken',
  ResendEmailVerificationForExpiredTokenSuccess = 'emailVerificationLinkExpired/resendEmailVerificationForExpiredTokenSuccess',
  ResendEmailVerificationForExpiredTokenFailure = 'emailVerificationLinkExpired/resendEmailVerificationForExpiredTokenFailure',
}

export interface ResendEmailVerificationForExpiredTokenAction extends AnyAction {
  type: EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredToken,
  payload: {
    verificationToken: string;
  };
}

export const resendEmailVerificationForExpiredToken = (verificationToken: string): ResendEmailVerificationForExpiredTokenAction => ({
  type: EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredToken,
  payload: { verificationToken }
});

export const resendEmailVerificationForExpiredTokenSuccess = () => ({
  type: EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredTokenSuccess,
});

export const resendEmailVerificationForExpiredTokenFailure = () => ({
  type: EmailVerificationLinkExpiredActionTypes.ResendEmailVerificationForExpiredTokenFailure,
});
