import { AnyAction } from 'redux';
import { keyBy } from 'lodash';
import { Variable } from 'Variables/VariablesTypes';
import { GetVariablesByIdAction, GetVariablesByIdSuccessAction } from './Actions';
import { VariablesAttributesActionTypes } from './ActionTypes';

export type VariablesAttributesState = { [key: string]: Variable };

const initialState: VariablesAttributesState = {};

type HandledAction = GetVariablesByIdAction | GetVariablesByIdSuccessAction;

const VariablesSelectorReducer = (state = initialState, action: HandledAction): VariablesAttributesState => {
  switch (action.type) {
    case VariablesAttributesActionTypes.GetVariablesSuccess: {
      const variables = action.payload;
      const variablesById = keyBy(variables, 'id');
      return {
        ...state,
        ...variablesById,
      };
    }
    default: {
      return state;
    }
  }
};

export default VariablesSelectorReducer as (
  state: VariablesAttributesState,
  action: AnyAction,
) => VariablesAttributesState;
