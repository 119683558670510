import React, { FC } from 'react';
import NewPasswordForm from 'components/Auth/NewPasswordForm';

interface ResetPasswordFormProps {
  onSubmit: (password: string) => Promise<void>;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  return <NewPasswordForm buttonTitle="Save New Password" onSubmit={onSubmit} />;
};

export default ResetPasswordForm;
