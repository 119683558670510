import { memoize } from 'lodash';
import { readResponseAsBinaryStream } from 'utils/fetch';
import toBase64 from 'utils/toBase64';
import CoreSystemApi from 'api/Core/CoreSystemApi';

export interface AssetsApi {
  getUserImage(id: string): Promise<string | ArrayBuffer | null>;
}

export default class AssetsRestApi extends CoreSystemApi<string> implements AssetsApi {
  protected resourceName = '/assets/';

  public getUserImage = memoize(async (id: string) => {
    try {
      const userImageBlob = await this.fetch(`${this.resourceName}${id}`, 'GET', undefined, {
        processResponse: readResponseAsBinaryStream,
      });
      const base64String = await toBase64(userImageBlob as File);
      return base64String;
    } catch (error) {
      return null;
    }
  });
}
