import Button from 'components/Button';
import React, { FC, useState } from 'react';
import { CheckboxChecked, CheckboxUnchecked } from 'static/images';
import styles from './ConfirmRegionSettingsDialog.module.scss';

const DESCRIPTION_TEXT = `You are updating the system's region settings. Would you also like to automatically update all variables in the system?`;

const CHECKBOXES_DESCRIPTIONS = [
  {
    name: 'phoneNumberFormat',
    description: 'Update all variables with the Data Type of Phone Number with this phone format.',
  },
  {
    name: 'currencyFormat',
    description: 'Update all variables with the Data Type of Monetary with this currency format.',
  },
  {
    name: 'dateFormat',
    description: 'Update all variables with the Data Type of Date with this date format.',
  },
  {
    name: 'idNumberType',
    description:
      'Update all variables with the Data Type of Identification Number with this identification number type.',
  },
];

interface ConfirmRegionSettingsDialogProps {
  onClose: () => void;
  onSave: () => Promise<unknown>;
  checkboxesState: { [key: string]: boolean };
  onChangeCheckboxesState: (name: string) => void;
  showedCheckboxes: string[];
  onResetCheckboxesState: () => void;
}

const ConfirmRegionSettingsDialog: FC<ConfirmRegionSettingsDialogProps> = ({
  onClose,
  onSave,
  checkboxesState,
  onChangeCheckboxesState,
  showedCheckboxes,
  onResetCheckboxesState,
}) => {
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);
  const renderCheckBoxes = () => {
    return showedCheckboxes.map((value) => {
      const isChecked = checkboxesState[value];
      const handleUpdateCheckboxesState = () => {
        onChangeCheckboxesState(value);
      };
      const { description } = CHECKBOXES_DESCRIPTIONS.find(({ name }) => name === value)!;
      return (
        <div className={styles.checkboxLine} key={value}>
          {isChecked ? (
            <CheckboxChecked onClick={handleUpdateCheckboxesState} />
          ) : (
            <CheckboxUnchecked onClick={handleUpdateCheckboxesState} />
          )}
          <p className={styles.text}>{description}</p>
        </div>
      );
    });
  };

  const handleSave = async () => {
    setIsAddingInProgress(true);
    await onSave();
    setIsAddingInProgress(false);
    onResetCheckboxesState();
  };

  return (
    <div className={styles.container}>
      <div className={styles.description}>
        <p>{DESCRIPTION_TEXT}</p>
      </div>
      <div className={styles.checkboxes}>{renderCheckBoxes()}</div>
      <div className={styles.buttons}>
        <Button kind="primary" size="form" onClick={handleSave} isLoading={isAddingInProgress}>
          Confirm Update
        </Button>
        <Button kind="secondary" size="form" onClick={onClose} disabled={isAddingInProgress}>
          No, Go Back
        </Button>
      </div>
    </div>
  );
};

export default ConfirmRegionSettingsDialog;
