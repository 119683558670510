import React, { FC, useState } from 'react';
import styles from './DuplicateStrategyPopUp.module.scss';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';

interface DuplicateStrategyPopUpProps {
  onDuplicateStrategy: () => Promise<void>;
  onClose: () => void;
  name: string;
}

const DuplicateStrategyPopup: FC<DuplicateStrategyPopUpProps> = ({
  name,
  onDuplicateStrategy,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirmBtnClick = async () => {
    setIsLoading(true);
    await onDuplicateStrategy();
    setIsLoading(false);
  };

  return (
    <PopUp title="Create Duplicate" closePopUp={onClose}>
      <PopUpContent hasTopMargin>
        <div className={styles.notificationContainer}>This will copy {name} and create the first version of a new strategy.</div>
        <div className={styles.buttonsContainer}>
          <div className={styles.confirmButtonContainer}>
            <Button kind="primary" size="form" onClick={handleConfirmBtnClick} isLoading={isLoading}>
              Confirm
            </Button>
          </div>
          <div className={styles.cancelButtonContainer}>
            <Button size="form" onClick={onClose} disabled={isLoading}>
              No, Go Back
            </Button>
          </div>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default DuplicateStrategyPopup;
