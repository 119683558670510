import { AnyAction } from 'redux';
import { ProcessAutomationActionType } from './ActionTypes';
import {
  AutomatedProcess,
  AutomatedProcessCategory,
  AutomatedProcessGroup,
} from 'api/LoanOriginationSystem/ProcessAutomationApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { SendGridTemplate } from 'SendGrid/Types';
import { TableViewData } from 'api/Types';

export interface SelectCategoryAction extends AnyAction {
  type: ProcessAutomationActionType.SelectCategory;
  payload: {
    category?: AutomatedProcessCategory;
  };
}

export interface SelectGroupAction extends AnyAction {
  type: ProcessAutomationActionType.SelectGroup;
  payload: {
    group: AutomatedProcessGroup;
  };
}

export interface GetAutomatedProcessesAction extends AnyAction {
  type: ProcessAutomationActionType.GetAutomatedProcesses;
  payload: {
    page: number;
    itemsPerPage: number;
    group?: AutomatedProcessGroup;
    category?: AutomatedProcessCategory;
  };
}

export interface GetAutomatedProcessesSuccessAction extends AnyAction {
  type: ProcessAutomationActionType.GetAutomatedProcessesSuccess;
  payload: {
    data: TableViewData<AutomatedProcess>;
  };
}

export interface ChangeActivationStatusAction extends AnyAction {
  type: ProcessAutomationActionType.ChangeActivationStatus;
  payload: {
    id: string;
    status: boolean;
  };
}

export interface ChangeActivationStatusSuccessAction extends AnyAction {
  type: ProcessAutomationActionType.ChangeActivationStatusSuccess;
  payload: {
    automatedProcess: AutomatedProcess;
  };
}

export interface UpdateRuntimeOptionsAction extends AnyAction {
  type: ProcessAutomationActionType.UpdateRuntimeOptions;
  payload: {
    id: string;
    runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>;
  };
}

export interface UpdateRuntimeOptionsSuccessAction extends AnyAction {
  type: ProcessAutomationActionType.UpdateRuntimeOptionsSuccess;
  payload: {
    automatedProcess: AutomatedProcess;
  };
}

export interface GetEmailTemplatesAction extends AnyAction {
  type: ProcessAutomationActionType.GetEmailTemplates;
}

export interface GetEmailTemplateSuccessAction extends AnyAction {
  type: ProcessAutomationActionType.GetEmailTemplatesSuccess;
  payload: {
    data: SendGridTemplate[];
  };
}

export interface OpenChangeActivationStatusPopupAction extends AnyAction {
  type: ProcessAutomationActionType.OpenChangeActivationStatusPopup;
  payload: {
    id: string;
    status: boolean;
  };
}

export interface CloseChangeActivationStatusPopupAction extends AnyAction {
  type: ProcessAutomationActionType.CloseChangeActivationStatusPopup;
}


export const selectCategory = (
  category?: AutomatedProcessCategory 
): SelectCategoryAction => {
  return {
    type: ProcessAutomationActionType.SelectCategory,
    payload: {
      category,
    },
  };
};

export const selectGroup = (
  group: AutomatedProcessGroup 
): SelectGroupAction => {
  return {
    type: ProcessAutomationActionType.SelectGroup,
    payload: {
      group,
    },
  };
};

export const getAutomatedProcesses = (filter: {
  page: number,
  itemsPerPage: number,
  group?: AutomatedProcessGroup,
  category?: AutomatedProcessCategory,
}): GetAutomatedProcessesAction => {
  return {
    type: ProcessAutomationActionType.GetAutomatedProcesses,
    payload: {
      page: filter.page,
      itemsPerPage: filter.itemsPerPage,
      group: filter.group,
      category: filter.category,
    },
  };
};

export const getAutomatedProcessesSuccess = (data: TableViewData<AutomatedProcess>): GetAutomatedProcessesSuccessAction => {
  return {
    type: ProcessAutomationActionType.GetAutomatedProcessesSuccess,
    payload: {
      data,
    },
  };
};

export const changeActivationStatus = (id: string, status: boolean): ChangeActivationStatusAction => {
  return {
    type: ProcessAutomationActionType.ChangeActivationStatus,
    payload: {
      id,
      status,
    },
  };
};

export const changeActivationStatusSuccess = (
  automatedProcess: AutomatedProcess,
): ChangeActivationStatusSuccessAction => {
  return {
    type: ProcessAutomationActionType.ChangeActivationStatusSuccess,
    payload: {
      automatedProcess,
    },
  };
};

export const updateRuntimeOptions = (
  id: string,
  runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>,
): UpdateRuntimeOptionsAction => {
  return {
    type: ProcessAutomationActionType.UpdateRuntimeOptions,
    payload: {
      id,
      runtimeOptionsUpdate,
    },
  };
};

export const updateRuntimeOptionsSuccess = (automatedProcess: AutomatedProcess): UpdateRuntimeOptionsSuccessAction => {
  return {
    type: ProcessAutomationActionType.UpdateRuntimeOptionsSuccess,
    payload: {
      automatedProcess,
    },
  };
};

export const getEmailTemplates = (): GetEmailTemplatesAction => {
  return {
    type: ProcessAutomationActionType.GetEmailTemplates,
  };
};

export const getEmailTemplatesSuccess = (data: SendGridTemplate[]): GetEmailTemplateSuccessAction => {
  return {
    type: ProcessAutomationActionType.GetEmailTemplatesSuccess,
    payload: {
      data,
    },
  };
};

export const openChangeActivationStatusPopup = (id: string, status: boolean): OpenChangeActivationStatusPopupAction => {
  return {
    type: ProcessAutomationActionType.OpenChangeActivationStatusPopup,
    payload: {
      id,
      status,
    },
  };
};

export const closeChangeActivationStatusPopup = (): CloseChangeActivationStatusPopupAction => {
  return {
    type: ProcessAutomationActionType.CloseChangeActivationStatusPopup,
  };
};

export const getAutomatedProcessesFailure = FailedActionCreator(
  ProcessAutomationActionType.GetAutomatedProcessesFailure,
);

export const changeActivationStatusFailure = FailedActionCreator(
  ProcessAutomationActionType.ChangeActivationStatusFailure,
);

export const updateRuntimeOptionsFailure = FailedActionCreator(ProcessAutomationActionType.UpdateRuntimeOptionsFailure);

export const getEmailTemplatesFailure = FailedActionCreator(ProcessAutomationActionType.GetEmailTemplatesFailure);
