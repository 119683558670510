import React, { useEffect } from 'react';
import { mapValues } from 'lodash';
import { useSelector, useDispatch, batch } from 'react-redux';
import IndividualProcessingComponent from 'components/IndividualProcessing';
import MainLayout, { PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ReduxState } from 'types/redux';
import { useParams } from 'react-router';
import PageContent from 'MainLayout/PageContent';
import { TabName } from 'components/IndividualProcessing/IndividualProcessing';
import { useHistory } from 'react-router-dom';
import {
  getStrategiesList,
  getStrategyInputs,
  setIndividualHistorySearchInput,
  resetIndividualStrategyInputs,
  runIndividualStrategy,
} from 'StrategyProcessing/ActionCreator';
import { IndividualRunInputs } from 'api/Types';
import Footer from 'components/Footer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { Tag } from 'components/Tag';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import getNormalizedStatus from 'utils/getNormalizedStatus';

const leftNav = makeLeftNavigation(
  NavigationLinkId.IndividualStrategyProcessing,
  ApplicationSectionName.DecisionEngine,
);

export const statusColors: Map<NormalizedStatus, 'green' | 'gray' | 'blue'> = new Map([
  [NormalizedStatus.Active, 'green'],
  [NormalizedStatus.Inactive, 'gray'],
  [NormalizedStatus.Testing, 'blue'],
]);

interface StrategyProcessingProps {
  tab: TabName;
}

const StrategyProcessing = ({ tab }: StrategyProcessingProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const history = useHistory();
  const { strategy: strategyId } = useParams<{ strategy: string }>();
  const { strategies, inputs: strategyInputs, isInputsLoading } = useSelector(
    (state: ReduxState) => state.individualProcessing,
  );

  useEffect(() => {
    batch(() => {
      dispatch(getStrategiesList());
    });
    return () => {
      dispatch(resetIndividualStrategyInputs());
    };
  }, []);

  useEffect(() => {
    if (strategyId) {
      dispatch(getStrategyInputs(strategyId));
    }
  }, [strategyId]);

  const onTabChange = (tabName: TabName): void => {
    dispatch(resetIndividualStrategyInputs());
    history.push(`/decision/processing/individual/${tabName}`);
  };

  const handleSearchInput = (caseSearchInputValue: string) =>
    dispatch(setIndividualHistorySearchInput(caseSearchInputValue));
  const handleRunDecision = (inputs: IndividualRunInputs) => dispatchRoutine(runIndividualStrategy(strategyId, inputs));
  const initialInputValues: IndividualRunInputs | undefined = mapValues(
    history.location.state,
    (value) => value && `${value}`,
  );
  const strategiesWithStatus = strategies.map((strategy) => ({
    ...strategy,
    iconAfter: <Tag color={statusColors.get(getNormalizedStatus(strategy.status))}>{strategy.status}</Tag>,
  }));

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent>
          <IndividualProcessingComponent
            currentStrategy={strategyId}
            strategies={strategiesWithStatus}
            onTabChange={onTabChange}
            switchBlock={tab}
            strategyInputs={strategyInputs}
            onSearchInputChange={handleSearchInput}
            onRunDecision={handleRunDecision}
            initialInputValues={initialInputValues}
            isInputsLoading={isInputsLoading}
          />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default StrategyProcessing;
