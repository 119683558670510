import React, { ChangeEvent, useEffect, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import replaceAt from 'utils/replaceAt';
import styles from './EditDeclineReasonsPopup.module.scss';
import OptionList from 'components/OptionList/OptionList';
import removeAt from 'utils/removeAt';

export interface EditDeclineReasonsPopupProps {
  declineReasons: string[];
  onPopupClose: () => void;
  onDeclineReasonsUpdate: (declineReasons: string[]) => void;
  isUpdatingInProgress: boolean;
}

const DECLINE_REASON_MAX_LENGTH = 100;

const EditDeclineReasonsPopup = ({
  declineReasons,
  onDeclineReasonsUpdate,
  onPopupClose,
  isUpdatingInProgress,
}: EditDeclineReasonsPopupProps) => {
  const [formDeclineReasons, setFormDeclineReasons] = useState(declineReasons);
  const [blurredByIndex, setBlurredByIndex] = useState({});

  useEffect(() => {
    setFormDeclineReasons(declineReasons);
  }, [declineReasons]);

  const isSaveDeclineReasonsAvailable = () => {
    const declineReasonsAreValid = formDeclineReasons.every((declineReason) => !!declineReason.trim());
    const someDeclineReasonWasChanged = formDeclineReasons.some(
      (declineReason, index) => declineReason !== declineReasons[index],
    );

    return (
      declineReasonsAreValid && (formDeclineReasons.length !== declineReasons.length || someDeclineReasonWasChanged)
    );
  };

  const handleAddDeclineReason = () => {
    setFormDeclineReasons([...formDeclineReasons, '']);
  };

  const handleDeclineReasonsUpdate = () => {
    onDeclineReasonsUpdate(formDeclineReasons);
  };

  const handleRemoveDeclineReason = (reasonToRemove: string, index: number) => {
    setFormDeclineReasons(removeAt(formDeclineReasons, index));
  };

  const renderDeclineReason = (declineReason: string, index: number) => {
    const title = `Decline Reason ${index + 1}`;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setFormDeclineReasons(replaceAt(formDeclineReasons, index, value));
    };

    const handleBlur = () => {
      setBlurredByIndex({ ...blurredByIndex, [index]: true });
    };

    return (
      <TextInput
        labelTitle={title}
        maxLength={DECLINE_REASON_MAX_LENGTH}
        value={declineReason}
        errorMessage={blurredByIndex[index] && !declineReason ? "The 'Decline Reason' field cannot be left empty" : ''}
        onBlur={handleBlur}
        onChange={handleChange}
        containerClassName={styles.declineReasonInput}
      />
    );
  };

  return (
    <PopUp classNames={{ popupCore: styles.popupCore }} closePopUp={onPopupClose} title="Decline Reasons">
      <PopUpContent hasTopMargin>
        <OptionList
          options={formDeclineReasons}
          addButtonText="Add Reason"
          deleteButtonText="Delete Reason"
          renderOption={renderDeclineReason}
          removeOption={handleRemoveDeclineReason}
          addOption={handleAddDeclineReason}
          maxItemsCount={20}
        />
        <Button
          onClick={handleDeclineReasonsUpdate}
          kind="primary"
          size="form"
          disabled={!isSaveDeclineReasonsAvailable()}
          isLoading={isUpdatingInProgress}
          className={styles.saveButton}
        >
          Save
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default EditDeclineReasonsPopup;
