import React, { useEffect, useRef, useState } from 'react';
import Chart from './Chart/Chart';
import styles from './ReportingDashboard.module.scss';
import ReportingDashboardHeader from './ReportingDashboardHeader/ReportingDashboardHeader';
import ResultsCard from './ResultsCard/ResultsCard';
import { Option } from 'components/SelectInput/SelectInput';
import { ReportingTimeDeltas } from 'LoanOriginationSystemReporting/Filters/Types';
import ResultTable from './ResultTable/ResultTable';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ReportingMonthInfo } from 'LoanOriginationSystemReporting/Applications/Types';
import useGetReportingPageData, { formatReportingEntitiesForCSV } from './utils';
import { isEmpty } from 'lodash';
import NoItems from 'components/NoItems/NoItems';
import { useHistory } from 'react-router';
import { NoResultsIcon } from 'static/images';

export interface StatusWithCount {
  name: string;
  count: number;
}

interface ReportingDashboardProps {
  reportingEntitiesMap: Record<string, ReportingMonthInfo>;
  statuses: ApplicationStatus[];
  onOpenFilterPopup: () => void;
  timeDelta: ReportingTimeDeltas;
  onTimeDeltaChange: (value: Option) => void;
  loading: boolean;
  isInitialFilterState: boolean;
  onClearFiltersClick: () => void;
  currency: string;
}

const ReportingDashboard = ({
  reportingEntitiesMap,
  statuses,
  onOpenFilterPopup,
  timeDelta,
  onTimeDeltaChange,
  loading,
  isInitialFilterState,
  onClearFiltersClick,
  currency,
}: ReportingDashboardProps) => {
  const history = useHistory();
  const [chartContainerWidth, setChartContainerWidth] = useState(0);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  const { applicationsAmount, applicationsNumber, dataByMonth, chartData, statusesWithCount } = useGetReportingPageData(
    reportingEntitiesMap,
    statuses,
  );

  useEffect(() => {
    const recomputeChartStyles = () => {
      if (chartContainerRef.current) {
        const chartContainerStyles = chartContainerRef!.current!.getBoundingClientRect();
        setChartContainerWidth(chartContainerStyles.width);
      }
    };
    recomputeChartStyles();
    window.addEventListener('resize', recomputeChartStyles);
    return () => window.removeEventListener('resize', recomputeChartStyles);
  });

  const downloadURL = formatReportingEntitiesForCSV(reportingEntitiesMap);

  const renderContent = () => {
    if (isInitialFilterState && isEmpty(reportingEntitiesMap) && !loading) {
      const onLinkClick = () => history.push('/los/applications');
      return (
        <NoItems
          title="No applications have been created yet!"
          className={styles.noItems}
          titleClassName={styles.noItemsTitle}
          buttonMessage="Go To Applications"
          onButtonClick={onLinkClick}
        />
      );
    }

    return (
      <>
        <ReportingDashboardHeader
          timeDelta={timeDelta}
          onTimeDeltaChange={onTimeDeltaChange}
          onOpenFilterPopup={onOpenFilterPopup}
          isInitialFilterState={isInitialFilterState}
        />
        {!isInitialFilterState && isEmpty(reportingEntitiesMap) && !loading ? (
          <NoItems
            className={styles.notFound}
            title="No Results Found"
            icon={<NoResultsIcon />}
            buttonMessage="Clear all filters"
            subtitle="Please remove or adjust your filters."
            subtitleClassName={styles.notFoundSubtitle}
            titleClassName={styles.notFoundTitle}
            buttonClassName={styles.notFoundButton}
            onButtonClick={onClearFiltersClick}
          />
        ) : (
          <>
            <div className={styles.mainDataContainer}>
              <div className={styles.chartContainer}>
                <div className={styles.chartWrapper} ref={chartContainerRef}>
                  <Chart
                    dateKeys={Object.keys(reportingEntitiesMap)}
                    amountKeys={chartData.amountAxis}
                    numberKeys={chartData.numberAxis}
                    dataByMonth={dataByMonth}
                    factor={chartData.factor}
                    width={chartContainerWidth}
                    height={460}
                    loading={loading}
                    currency={currency}
                  />
                </div>
              </div>
              <ResultsCard
                applicationsNumber={applicationsNumber}
                applicationsAmount={applicationsAmount}
                statusesWithCount={statusesWithCount}
                downloadURL={downloadURL}
                loading={loading}
                currency={currency}
              />
            </div>
            <div className={styles.tableContainer}>
              <ResultTable
                reportingEntitiesMap={reportingEntitiesMap}
                statuses={statuses}
                loading={loading}
                currency={currency}
              />
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.pageTitle}>Reporting</div>
      {renderContent()}
    </div>
  );
};

export default ReportingDashboard;
