import {
  CreateApplicationDataTabCardSuccessAction,
  GetApplicationDataTabCardsSuccessAction,
  UpdateApplicationDataTabCardSuccessAction,
} from 'LoanOriginationSystemApplicationDataTabCards/ActionCreator';
import { ApplicationDataTabCardsActionType } from 'LoanOriginationSystemApplicationDataTabCards/ActionTypes';
import { ApplicationDataTabVariableConfigurationsActionType } from './ActionTypes';
import { ApplicationDataTabVariableConfigurationsState } from './Types';
import {
  CreateApplicationDataTabVariableConfigurationSuccessAction,
  UpdateApplicationDataTabVariableConfigurationSuccessAction,
} from './ActionCreator';

export type ApplicationDataTabVariableConfigurationsActions =
  | CreateApplicationDataTabVariableConfigurationSuccessAction
  | UpdateApplicationDataTabVariableConfigurationSuccessAction
  | CreateApplicationDataTabCardSuccessAction
  | GetApplicationDataTabCardsSuccessAction
  | UpdateApplicationDataTabCardSuccessAction;

const initialState: ApplicationDataTabVariableConfigurationsState = {
  variableConfigurationsById: {},
};

const applicationVariableConfigurationsReducer = (
  state = initialState,
  action: ApplicationDataTabVariableConfigurationsActions,
): ApplicationDataTabVariableConfigurationsState => {
  switch (action.type) {
    case ApplicationDataTabCardsActionType.GetApplicationDataTabCardsSuccess:
    case ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardSuccess:
    case ApplicationDataTabCardsActionType.CreateApplicationDataTabCardSuccess: {
      if (!action.payload.applicationVariableConfigurations) {
        return state;
      }

      return {
        ...state,
        variableConfigurationsById: {
          ...state.variableConfigurationsById,
          ...action.payload.applicationVariableConfigurations,
        },
      };
    }
    case ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationSuccess:
    case ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationSuccess: {
      return {
        ...state,
        variableConfigurationsById: {
          ...state.variableConfigurationsById,
          [action.payload.variableConfiguration.id]: action.payload.variableConfiguration,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationVariableConfigurationsReducer;
