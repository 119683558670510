import React, { useEffect } from 'react';
import ProcessingBatchCaseComponent from 'components/ProcessingBatchCase';
import { useDispatch, useSelector } from 'react-redux';
import { getBatchCase, setBatchCaseSearchInput } from 'StrategyProcessing/ActionCreator';
import { useParams } from 'react-router';
import { ReduxState } from 'types/redux';
import MainLayout, { PageContent, PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { BatchProcessingCaseData } from 'api/Types';
import pagination from './Pagination';
import { BatchProcessingCaseResults } from 'api/DecisionEngineStrategiesType';
import Footer from 'components/Footer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import useStateReset from 'hooks/useStateReset';
import { BatchCasesActionType } from 'StrategyProcessing/ActionTypes';

const leftNav = makeLeftNavigation(NavigationLinkId.BatchStrategyProcessing, ApplicationSectionName.DecisionEngine);

const ProcessingBatchCase = () => {
  const { case: caseId } = useParams<{ case: string }>();
  const dispatch = useDispatch();
  const downloadApi = useDownloadApi();

  useEffect(() => {
    dispatch(getBatchCase(caseId));
  }, []);

  const caseData = useSelector((state: ReduxState) => state.batchProcessingCase.data) as BatchProcessingCaseData;
  const results = useSelector((state: ReduxState) => state.batchProcessingCase.results) as BatchProcessingCaseResults;

  useStateReset(BatchCasesActionType.ResetState);

  const searchInputValue = useSelector<ReduxState, string>(
    ({ batchProcessingCase }) => batchProcessingCase.searchInputValue,
  );

  const paginationProps = pagination.usePagination({ caseId, searchInputValue });

  const individualCasesArray = pagination.usePaginatedItems({ caseId, searchInputValue });

  const empty = paginationProps.itemsTotal === 0;

  const handleSearchInput = (caseSearchInputValue: string) => {
    dispatch(setBatchCaseSearchInput(caseSearchInputValue));
  };

  return (
    <div>
      <MainLayout leftNav={leftNav}>
        <PageWrapper>
          <PageContent>
            {caseData && individualCasesArray && (
              <ProcessingBatchCaseComponent
                handleDownloadResults={() => downloadApi.downloadSimulationResults(caseId)}
                caseData={caseData}
                results={results}
                individualCases={individualCasesArray}
                paginationProps={paginationProps}
                empty={empty}
                searchInputValue={searchInputValue}
                onSearchInputChange={handleSearchInput}
              />
            )}
          </PageContent>
          <PageFooter>
            <Footer separator />
          </PageFooter>
        </PageWrapper>
      </MainLayout>
    </div>
  );
};

export default ProcessingBatchCase;
