import NoItems from 'components/NoItems';
import styles from './TabNoItems.module.scss';
import React from 'react';
import { NoItemsProps } from 'components/NoItems/NoItems';

const TabNoItems = (props: NoItemsProps) => {
  return <NoItems {...props} className={styles.noItems} titleClassName={styles.noItemsTitle} />;
};

export default TabNoItems;
