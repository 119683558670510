import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { closeUpdateLabelPopUp, UpdateLabelSuccessAction, getLabels } from './ActionCreator';

const isUpdateLabelSuccessAction = (action: AnyAction): action is UpdateLabelSuccessAction =>
  action.type === LoanOriginationSystemLabelsActionTypes.UpdateLabelSuccess;

const UpdateLabelSuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateLabelSuccessAction(action)) {
    const { loanOriginationSystemLabelsState } = getState();

    const { filters, sortingType } = loanOriginationSystemLabelsState;

    notification.createNotification(getMessage(MessageType.LabelUpdated), 'success', dispatch);

    dispatch(getLabels({ filters, sortingType }));
    dispatch(closeUpdateLabelPopUp());
  }

  return result;
};

export default UpdateLabelSuccessMiddleware;
