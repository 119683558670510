import {
  ChangePopupFiltersAction,
  ResetAllBorrowersFilters,
} from 'LoanOriginationSystemBorrowers/Filters/ActionCreator';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './ActionTypes';
import { getBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllBorrowersFilters =>
  action.type === LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters;

const onBorrowersFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetAllFiltersAction(action)) {
    const { loanOriginationSystemBorrowers } = getState();
    const { filters, sortingType } = loanOriginationSystemBorrowers;

    dispatch(getBorrowers(filters, sortingType));
  }

  return result;
}) as Middleware;

export default onBorrowersFiltersChangeMiddleware;
