import React from 'react';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import { DatePickerInputStub } from 'components/DatePicker';
import { MonetaryInputStub } from 'components/MonetaryInput';
import { PercentageInputStub } from 'components/PercentageInput';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import { PhoneNumberInputStub } from 'components/PhoneNumberInput';
import { IdentificationNumberInputStub } from 'components/IdentificationNumberInput';
import { TextInputStub } from 'components/TextInput';
import { isDateDataType, isIdentificationNumber, isMonetaryDataType, isPhoneNumber } from './InputWithDataType';
import { AutoCompletionStub } from 'components/AutoCompletion';

export interface InputWithDataTypeStubProps {
  label: string;
  disabled?: boolean;
  className?: string;
  elementBeforeInput?: JSX.Element | null;
}

const InputWithDataTypeStub = (props: InputWithDataTypeStubProps & VisualDataTypeWithAttributes) => {
  const { dataType, disabled, className, elementBeforeInput, label } = props;

  if (isPhoneNumber(props)) {
    const { phoneNumberFormat } = props;

    return (
      <PhoneNumberInputStub
        label={label}
        elementBeforeInput={elementBeforeInput}
        disabled={disabled}
        className={className}
        country={phoneNumberFormat}
      />
    );
  }

  if (isIdentificationNumber(props)) {
    const { identificationNumberType, identificationNumberDescription } = props;

    return (
      <IdentificationNumberInputStub
        label={label}
        className={className}
        disabled={disabled}
        identificationNumberType={identificationNumberType!}
        identificationNumberDescription={identificationNumberDescription}
        elementBeforeInput={elementBeforeInput}
      />
    );
  }

  if (isMonetaryDataType(props)) {
    const { currency } = props;
    const currencySymbol = getCurrencySymbol(currency!);

    return (
      <MonetaryInputStub
        label={label}
        disabled={disabled}
        className={className}
        placeholder={dataType}
        currencySymbol={currencySymbol}
        elementBeforeInput={elementBeforeInput}
      />
    );
  }

  if (isDateDataType(props)) {
    const { dateFormat } = props;

    return (
      <DatePickerInputStub
        label={label}
        disabled={disabled}
        className={className}
        dateFormat={dateFormat!}
        elementBeforeInput={elementBeforeInput}
      />
    );
  }
  switch (dataType) {
    case 'Percentage': {
      return (
        <PercentageInputStub
          label={label}
          disabled={disabled}
          className={className}
          placeholder={dataType}
          elementBeforeInput={elementBeforeInput}
        />
      );
    }
    case 'Number': {
      return (
        <TextInputStub
          label={label}
          disabled={disabled}
          className={className}
          placeholder={dataType}
          elementBeforeInput={elementBeforeInput}
        />
      );
    }
    case 'Boolean':
    case 'List': {
      return (
        <AutoCompletionStub
          label={label}
          disabled={disabled}
          className={className}
          placeholder={dataType}
          elementBeforeInput={elementBeforeInput}
        />
      );
    }
    default:
      return (
        <TextInputStub
          label={label}
          disabled={disabled}
          className={className}
          placeholder={dataType === 'EmailAddress' ? 'email@domain.com' : dataType}
          elementBeforeInput={elementBeforeInput}
        />
      );
  }
};

export default InputWithDataTypeStub;
