import { DownloadResponse } from 'api/APIRest';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerApiRequestTemplateType, BorrowerType } from './LoanOriginationSystemBorrowersApi';

export interface ApiRequestTemplatesApi {
  downloadProductApiRequestTemplate(productId: string, requestType: string): Promise<DownloadResponse>;
  downloadBorrowerApiRequestTemplate(
    borrowerType: BorrowerType,
    requestType: BorrowerApiRequestTemplateType,
  ): Promise<DownloadResponse>;
  downloadIntermediaryApiRequestTemplate(requestType: string): Promise<DownloadResponse>;
}

export default class ApiRequestTemplatesRestApi extends LoanOriginationSystemApi<void>
  implements ApiRequestTemplatesApi {
  public downloadProductApiRequestTemplate(productId: string, requestType: string) {
    const params = new URLSearchParams();

    params.set('productId', productId);
    params.set('requestType', requestType);

    return super.download(`/api-request-templates/product/?${params}`);
  }

  public downloadBorrowerApiRequestTemplate(borrowerType: BorrowerType, requestType: string) {
    const params = new URLSearchParams();

    params.set('borrowerType', borrowerType);
    params.set('requestType', requestType);

    return this.download(`/api-request-templates/borrower/?${params}`);
  }

  public downloadIntermediaryApiRequestTemplate(requestType: string) {
    const params = new URLSearchParams();

    params.set('requestType', requestType);

    return this.download(`/api-request-templates/intermediary/?${params}`);
  }
}
