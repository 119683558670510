import { GetTableViewProductDataResult } from 'api/Types';
import { LoanOriginationSystemApplicationsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { GET_TABLE_VIEW_PRODUCT_DATA } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  getTableViewProductDataSuccess,
  getTableViewProductDataFailed,
  GetTableViewProductDataAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/ApplicationsDashboard/Pagination';
import { applicationDefaultVariables } from 'enums/ApplicationDefaultVariable';

const GetTableViewProductDataMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<GetTableViewProductDataResult, GetTableViewProductDataAction, ReduxState>(
    GET_TABLE_VIEW_PRODUCT_DATA,
    getTableViewProductDataSuccess,
    getTableViewProductDataFailed,
    (action) => {
      const { filters, productId, sortingType } = action.payload;

      return api.getAll(
        productId,
        {
          searchInputValue: filters.searchInputValue,
          selectedMembers: filters.selectedMembers,
          selectedStatusesIds: filters.selectedStatusesIds,
          selectedIntermediaries: filters.selectedIntermediaries,
          selectedLabels: filters.selectedLabels,
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
          pagination: {
            count: APPLICATIONS_PER_PAGE_DEFAULT,
            offset: 0,
          },
          variablesToInclude: applicationDefaultVariables,
        },
        sortingType,
      );
    },
  );

export default GetTableViewProductDataMiddleware;
