import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';
import { NullableItems } from 'pagination';

export const getESignTemplatesById = (state: ReduxState) => state.eSignTemplates.templatesById;
export const getESignTemplate = (state: ReduxState, id: string | null) => {
  return id ? state.eSignTemplates.templatesById[id] : null;
};
export const getESignTemplateIds = (_: ReduxState, templateIds: NullableItems<string>) => templateIds;
export const getESignTemplatesByIds = createSelector(
  [getESignTemplatesById, getESignTemplateIds],
  (templatesById, templateIds) => {
    return templateIds.map((id) => (id ? templatesById[id] : null));
  },
);
