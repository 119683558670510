import React, { useEffect } from 'react';
import { OrganizationInfo } from 'api/Core/OrganizationApi';
import AuthLayout from 'components/Auth/AuthLayout';
import OrganizationList from 'components/Auth/OrganizationList';
import { useAuthProvider } from 'providers/AuthProvider';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import UserCard from 'components/UserCard';
import styles from './SelectOrganization.module.scss';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'routes/RouteService';
import NoActiveOrganizations from 'components/Auth/NoActiveOrganizations/NoActiveOrganizations';
import { getOrganizations } from 'UserOrganizations/UserOrganizationsActionCreators';

const SelectOrganization = () => {
  const dispatch = useDispatch();
  const authProvider = useAuthProvider();
  const history = useHistory();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const userOrganizationsState = useSelector((state: ReduxState) => state.userOrganizations);

  const handleSelectOrganization = async (organization: OrganizationInfo) => {
    await authProvider.selectOrganization(organization.id);
    history.replace(AppRoutes.Home); // TODO return to the last visited screen
  };

  const organizations =
    !userOrganizationsState || userOrganizationsState.isLoading ? null : userOrganizationsState.items;

  useEffect(() => {
    if (!userOrganizationsState?.isLoading) {
      dispatch(getOrganizations());
    }
  }, []);

  const noActiveOrganizations = !!organizations && organizations.length === 0;

  return (
    <AuthLayout title="Select Organization">
      {noActiveOrganizations && <NoActiveOrganizations />}
      {!noActiveOrganizations && <OrganizationList organizations={organizations} onSelect={handleSelectOrganization} />}
      <UserCard
        firstName={accountDetails?.firstName || ''}
        lastName={accountDetails?.lastName || ''}
        imageId={accountDetails?.imageId}
        onLogOutClick={() => authProvider.logout()}
        noMargin
        className={styles.userCard}
      />
    </AuthLayout>
  );
};

export default SelectOrganization;
