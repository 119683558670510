export enum ProcessAutomationActionType {
  SelectCategory = 'processAutomation/selectCategory',
  SelectGroup = 'processAutomation/selectGroup',
  GetAutomatedProcesses = 'processAutomation/getAutomatedProcesses',
  GetAutomatedProcessesSuccess = 'processAutomation/getAutomatedProcessesSuccess',
  GetAutomatedProcessesFailure = 'processAutomation/getAutomatedProcessesFailure',
  ChangeActivationStatus = 'processAutomation/changeActivationStatus',
  ChangeActivationStatusSuccess = 'processAutomation/changeActivationStatusSuccess',
  ChangeActivationStatusFailure = 'processAutomation/changeActivationStatusFailure',
  UpdateRuntimeOptions = 'processAutomation/updateRuntimeOptions',
  UpdateRuntimeOptionsSuccess = 'processAutomation/updateRuntimeOptionsSuccess',
  UpdateRuntimeOptionsFailure = 'processAutomation/updateRuntimeOptionsFailure',
  GetEmailTemplates = 'processAutomation/getEmailTemplates',
  GetEmailTemplatesSuccess = 'processAutomation/getEmailTemplatesSuccess',
  GetEmailTemplatesFailure = 'processAutomation/getEmailTemplatesFailure',
  OpenChangeActivationStatusPopup = 'processAutomation/openChangeActivationStatusPopup',
  CloseChangeActivationStatusPopup = 'processAutomation/closeChangeActivationStatusPopup',
  ResetState = 'processAutomation/resetState',
}
