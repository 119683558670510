import { Middleware } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import {
  confirmAccountEmailSuccess,
  confirmAccountEmailFailure,
  VerifyAccountEmailAction,
} from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { EmailVerificationResult, IEmailVerificationRestApi } from 'api/Core/EmailVerificationApi';
import { EMAIL_VERIFICATION_RESULT } from 'pages/ConfirmEmail/ConfirmEmail';
import { ReduxState } from 'types/redux';

const ConfirmEmailMiddleware: (api: IEmailVerificationRestApi) => Middleware =
  (api) =>
    Fetcher<EmailVerificationResult, VerifyAccountEmailAction, ReduxState, Error, EmailVerificationResult>(
      AccountDetailsActionType.ConfirmAccountEmail,
      confirmAccountEmailSuccess,
      confirmAccountEmailFailure,
      ({ payload }) => {
        return api.verifyEmail(payload.token);
      },
      EMAIL_VERIFICATION_RESULT,
      (value) => value
    );

export default ConfirmEmailMiddleware;
