import Debouncer from 'middlewares/Debouncer';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './Filters/ActionTypes';
import { getBorrowers } from './ActionCreator';

export default Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemBorrowers } = getState();
    const { filters, sortingType } = loanOriginationSystemBorrowers;

    return getBorrowers(filters, sortingType);
  },
  [LoanOriginationSystemBorrowersFiltersActionTypes.SetSearchInputValue],
);
