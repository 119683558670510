import React, { ReactNode } from 'react';
import { TextFieldProps } from '@material-ui/core';
import TextInput from 'components/TextInput';
import { CalendarImage } from 'static/images';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import styles from './Input.module.scss';

interface DatePickerInputProps {
  onIconClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
}

const DatePickerInput = ({
  value,
  label,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  onIconClick,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  inputRef,
  tabIndex,
}: DatePickerInputProps & TextFieldProps) => {
  return (
    <div ref={inputRef} className={styles.container}>
      <TextInput
        value={value as string}
        labelTitle={label as string}
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        hasRightNeighbour
        hasRightPadding
        disabled={disabled}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        loaderClassName={styles.loader}
        tabIndex={tabIndex}
      />
      <div className={styles.calendarInputOverlay} onClick={onIconClick}>
        <p className={styles.calendarIconLeftOffset}>{value ? (value as ReactNode) : placeholder}</p>
        <div className={styles.calendarIconContainer}>
          <CalendarImage className={styles.calendarIcon} />
        </div>
      </div>
    </div>
  );
};

export default DatePickerInput;
