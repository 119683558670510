export const GET_STRATEGIES_LIST = 'GET_STRATEGIES_LIST';
export const GET_STRATEGIES_LIST_SUCCESS = 'GET_STRATEGIES_LIST_SUCCESS';
export const GET_STRATEGIES_LIST_FAILURE = 'GET_STRATEGIES_LIST_FAILURE';

export const GET_STRATEGY_INPUTS = 'GET_STRATEGY_INPUTS';
export const GET_STRATEGY_INPUTS_SUCCESS = 'GET_STRATEGY_INPUTS_SUCCESS';
export const GET_STRATEGY_INPUTS_FAILURE = 'GET_STRATEGY_INPUTS_FAILURE';

export const GET_INDIVIDUAL_HISTORY = 'GET_INDIVIDUAL_HISTORY';
export const GET_INDIVIDUAL_HISTORY_SUCCESS = 'GET_INDIVIDUAL_HISTORY_SUCCESS';
export const GET_INDIVIDUAL_HISTORY_FAILURE = 'GET_INDIVIDUAL_HISTORY_FAILURE';

export const RESET_INDIVIDUAL_STRATEGY_INPUTS = 'RESET_INDIVIDUAL_STRATEGY_INPUTS';

export const SET_INDIVIDUAL_HISTORY_SEARCH_INPUT = 'SET_INDIVIDUAL_HISTORY_SEARCH_INPUT';

export const RUN_INDIVIDUAL_STRATEGY = 'RUN_STRATEGY';
export const RUN_INDIVIDUAL_STRATEGY_SUCCESS = 'RUN_INDIVIDUAL_STRATEGY_SUCCESS';
export const RUN_INDIVIDUAL_STRATEGY_FAILURE = 'RUN_INDIVIDUAL_STRATEGY_FAILURE';

export const GET_INDIVIDUAL_CASE = 'GET_INDIVIDUAL_CASE';
export const GET_INDIVIDUAL_CASE_SUCCESS = 'GET_INDIVIDUAL_CASE_SUCCESS';
export const GET_INDIVIDUAL_CASE_FAILURE = 'GET_INDIVIDUAL_CASE_FAILURE';

export const GET_BATCH_HISTORY = 'GET_BATCH_HISTORY';
export const GET_BATCH_HISTORY_SUCCESS = 'GET_BATCH_HISTORY_SUCCESS';
export const GET_BATCH_HISTORY_FAILURE = 'GET_BATCH_HISTORY_FAILURE';

export const SET_BATCH_HISTORY_SEARCH_INPUT = 'SET_BATCH_HISTORY_SEARCH_INPUT';

export const RUN_BATCH_STRATEGY = 'RUN_BATCH_STRATEGY';
export const RUN_BATCH_STRATEGY_SUCCESS = 'RUN_BATCH_STRATEGY_SUCCESS';
export const RUN_BATCH_STRATEGY_FAILURE = 'RUN_BATCH_STRATEGY_FAILURE';

export const GET_BATCH_CASE = 'GET_BATCH_CASE';
export const GET_BATCH_CASE_SUCCESS = 'GET_BATCH_CASE_SUCCESS';
export const GET_BATCH_CASE_FAILURE = 'GET_BATCH_CASE_FAILURE';

export const SET_BATCH_CASE_SEARCH_INPUT = 'SET_BATCH_CASE_SEARCH_INPUT';

export enum IndividualProcessingHistoryActionType {
  ResetState = 'individualProcessingHistory/resetState',
}

export enum BatchCasesActionType {
  ResetState = 'batchCases/resetState',
}

export enum BatchProcessingHistoryActionType {
  ResetState = 'batchProcessingHistory/resetState',
}
