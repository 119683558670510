import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  GetBorrowerToDeleteApplicationsAction,
  getBorrowerToDeleteApplicationsFailure,
  getBorrowerToDeleteApplicationsSuccess,
  GetBorrowerToDeleteApplicationsSuccessData,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { BORROWER_APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/BorrowerDetails/pagination';

const GetBorrowerToDeleteApplicationsMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<GetBorrowerToDeleteApplicationsSuccessData, GetBorrowerToDeleteApplicationsAction, ReduxState>(
    LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplications,
    getBorrowerToDeleteApplicationsSuccess,
    getBorrowerToDeleteApplicationsFailure,
    async ({ payload: id }, state) => {
      const { applicationsSortingType } = state.loanOriginationSystemBorrowerDetails;
      const applications = await api.getBorrowerApplications(
        id,
        {
          offset: 0,
          count: BORROWER_APPLICATIONS_PER_PAGE_DEFAULT,
        },
        applicationsSortingType,
      );

      return applications;
    },
  );

export default GetBorrowerToDeleteApplicationsMiddleware;
