import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  addApplicationLabelFailure,
  addApplicationLabelSuccess,
  AddApplicationLabelAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const AddApplicationLabelMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, AddApplicationLabelAction>(
    ApplicationPageActionType.AddApplicationLabel,
    addApplicationLabelSuccess,
    addApplicationLabelFailure,
    (action) => {
      return api.editLabels(action.payload.applicationId, {
        labelsToAdd: [action.payload.labelId],
      });
    },
  );

export default AddApplicationLabelMiddleware;
