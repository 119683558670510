import React, { useState } from 'react';
import styles from './BorrowersFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemBorrowers/Filters/ActionCreator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import CustomCheckbox from 'components/CustomCheckbox';

interface BorrowersFilterProps {
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  dueUpdatedDateFrom: Date | null;
  dueUpdatedDateTo: Date | null;
  isCompany: boolean;
  isPerson: boolean;
}

const BorrowersFilter = ({
  dueCreatedDateFrom,
  dueCreatedDateTo,
  dueUpdatedDateFrom,
  dueUpdatedDateTo,
  isCompany,
  isPerson,
}: BorrowersFilterProps) => {
  const dispatch = useDispatch();

  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateFrom);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateTo);
  const [fromUpdatedDate, setFromUpdatedDate] = useState(dueUpdatedDateFrom);
  const [toUpdatedDate, setToUpdatedDate] = useState(dueUpdatedDateTo);
  const [isBorrowerCompany, setIsBorrowerCompany] = useState(isCompany);
  const [isBorrowerPerson, setIsBorrowerPerson] = useState(isPerson);

  const filtersResult: FiltersResult = {
    dueCreatedDateRange: {
      from: fromCreatedDate,
      to: toCreatedDate,
    },
    dueUpdatedDateRange: {
      from: fromUpdatedDate,
      to: toUpdatedDate,
    },
    isCompany: isBorrowerCompany,
    isPerson: isBorrowerPerson,
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleChangeIsCompany = () => {
    setIsBorrowerCompany(!isBorrowerCompany);
  };

  const handleChangeIsPerson = () => {
    setIsBorrowerPerson(!isBorrowerPerson);
  };

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleOnUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromUpdatedDate(from);
    setToUpdatedDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <p className={styles.typeTitle}>Borrower Type</p>
      <div className={styles.borrowerTypesContainer}>
        <CustomCheckbox label="Person" checked={isBorrowerPerson} onChange={handleChangeIsPerson} />
        <CustomCheckbox label="Company" checked={isBorrowerCompany} onChange={handleChangeIsCompany} />
      </div>
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Created Date"
      />
      <DateRangePicker
        from={fromUpdatedDate}
        to={toUpdatedDate}
        onChange={handleOnUpdatedDateRangeChange}
        label="Updated Date"
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default BorrowersFilter;
