import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const UPDATE_VARIABLES_SUCCESS_MESSAGE = 'Changes are saved.';

const VARIABLE_CONFIGURATIONS_CHANGE_ACTIONS = [
  VariablesConfigurationActionType.CreateBorrowerVariableConfigurationSuccess,
  VariablesConfigurationActionType.UpdateBorrowerVariableConfigurationSuccess,
  VariablesConfigurationActionType.DeleteBorrowerVariableConfigurationSuccess,
  VariablesConfigurationActionType.CreateIntermediaryVariableConfigurationSuccess,
  VariablesConfigurationActionType.UpdateIntermediaryVariableConfigurationSuccess,
  VariablesConfigurationActionType.DeleteIntermediaryVariableConfigurationSuccess,
  VariablesConfigurationActionType.CreateApplicationVariableConfigurationSuccess,
  VariablesConfigurationActionType.UpdateApplicationVariableConfigurationSuccess,
  VariablesConfigurationActionType.DeleteApplicationVariableConfigurationSuccess,
];

const isVariableConfigurationsChangeAction = (action: AnyAction) =>
  VARIABLE_CONFIGURATIONS_CHANGE_ACTIONS.includes(action.type);

const notifyOnVariableConfigurationsChangeMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isVariableConfigurationsChangeAction(action)) {
    notification.createNotification(UPDATE_VARIABLES_SUCCESS_MESSAGE, 'success', dispatch);
  }

  return result;
};

export default notifyOnVariableConfigurationsChangeMiddleware;
