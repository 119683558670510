import { normalize } from 'normalizr';
import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationDataTabCardsApi,
  NormalizedApplicationDataTabCard,
} from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabCardsActionType } from './ActionTypes';
import {
  createApplicationDataTabCardSuccess,
  createApplicationDataTabCardFailure,
  CreateApplicationDataTabCardAction,
  GetApplicationDataTabCardsResult,
} from './ActionCreator';
import ApplicationDataTabCardSchema from 'schemas/ApplicationDataTabCardSchema';

const CreateApplicationDataTabCardMiddleware = (api: ApplicationDataTabCardsApi) =>
  Fetcher<GetApplicationDataTabCardsResult, CreateApplicationDataTabCardAction>(
    ApplicationDataTabCardsActionType.CreateApplicationDataTabCard,
    createApplicationDataTabCardSuccess,
    createApplicationDataTabCardFailure,
    async (action) => {
      const card = await api.create({
        name: action.payload.name,
        position: action.payload.position,
        row: action.payload.row,
        tabId: action.payload.tabId,
      });

      const normalizedData = normalize(card, ApplicationDataTabCardSchema);

      return {
        tabId: action.payload.tabId,
        cards: normalizedData.entities.cards || ({} as Record<string, NormalizedApplicationDataTabCard>),
        applicationVariableConfigurations:
          normalizedData.entities.applicationVariableConfigurations ||
          ({} as Record<string, ApplicationDataTabVariableConfiguration>),
        cardIds: [normalizedData.result] as string[],
      };
    },
  );

export default CreateApplicationDataTabCardMiddleware;
