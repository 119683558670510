import { AnyAction } from 'redux';
import { DeleteStrategyWizardState } from './deleteStrategyWizardTypes';
import { OPEN_DELETE_STRATEGY_WIZARD, CLOSE_DELETE_STRATEGY_WIZARD } from './constants';
import {
  DELETE_DECISION_STRATEGY_REQUEST,
  DELETE_DECISION_STRATEGY_REQUEST_SUCCESS,
  DELETE_DECISION_STRATEGY_REQUEST_FAILURE,
} from 'DecisionStrategy/ActionTypes';

const initialState: DeleteStrategyWizardState = {
  isOpen: false,
  isLoading: false,
  id: '',
  name: '',
  version: 0,
  isCurrent: false,
  type: undefined,
  actionOrigin: undefined,
};

const deleteStrategyWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_DELETE_STRATEGY_WIZARD: {
      const { strategyInfo, isCurrent, type } = action.payload;
      const { actionOrigin } = action.meta;
      return { isOpen: true, ...strategyInfo, isCurrent, type, actionOrigin };
    }
    case DELETE_DECISION_STRATEGY_REQUEST: {
      return { ...state, isLoading: true };
    }
    case DELETE_DECISION_STRATEGY_REQUEST_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case DELETE_DECISION_STRATEGY_REQUEST_FAILURE: {
      return { ...state, isLoading: false };
    }
    case CLOSE_DELETE_STRATEGY_WIZARD:
      return { isOpen: false };
    default:
      return state;
  }
};

export default deleteStrategyWizardReducer;
