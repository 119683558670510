import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ApplicationSortingField } from 'api/LoanOriginationSystem/Types';
import { EmptyTableImage } from 'static/images';
import useStandardVariables from 'hooks/useStandardVariables';
import { getBorrowerApplications, sortBorrowerApplications } from 'LoanOriginationSystemBorrowerDetails/ActionCreator';
import NoItems from 'components/NoItems';
import pagination, {
  BorrowerApplicationsPaginationParams,
} from 'components/LoanOriginationSystem/BorrowerDetails/pagination';
import RelatedApplicationsTable from 'components/LoanOriginationSystem/RelatedApplicationsTable';
import styles from './BorrowerApplications.module.scss';

export interface BorrowerApplicationsProps {
  borrowerId: string;
}

const BorrowerApplications = ({ borrowerId }: BorrowerApplicationsProps) => {
  const dispatch = useDispatch();

  const { applicationsSortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowerDetails);

  useEffect(() => {
    dispatch(getBorrowerApplications(borrowerId, applicationsSortingType));
  }, []);

  const params = {
    sortingType: applicationsSortingType,
    borrowerId,
  } as BorrowerApplicationsPaginationParams;

  const applications = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);
  const standardVariables = useStandardVariables();

  const handleSort = (field: ApplicationSortingField, ascending: boolean) => {
    dispatch(sortBorrowerApplications(borrowerId, { field, ascending }));
  };

  if (!applications.length) {
    return (
      <NoItems
        className={styles.noItems}
        titleClassName={styles.title}
        title="This borrower has not applied yet!"
        icon={<EmptyTableImage />}
      />
    );
  }

  return (
    <RelatedApplicationsTable
      className={styles.table}
      source={applications}
      standardVariables={standardVariables}
      paginationProps={paginationProps}
      sortingType={applicationsSortingType}
      onSort={handleSort}
    />
  );
};

export default BorrowerApplications;
