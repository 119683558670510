import React, { ChangeEvent, useEffect, useState } from 'react';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ProductStatus, ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { Option } from 'components/SelectInput/SelectInput';
import { LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS } from 'components/Input';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import SelectableItem from 'components/SelectableItem';
import CustomCheckbox from 'components/CustomCheckbox';
import QuestionIcon from 'components/QuestionIcon';
import { companyProductTypeOptions, personProductTypeOptions } from './ProductTypeOptions';

import styles from './ProductBasicInfoForm.module.scss';

export interface ProductBasicInfoFormProps {
  productName: string;
  borrowerType: BorrowerType;
  productType: ProductType | null;
  initialProductStatus: ProductStatus;
  onProductNameChange: (name: string) => void;
  onBorrowerTypeChange: (borrowerType: BorrowerType) => void;
  onProductTypeChange: (type: ProductType | null) => void;
  onInitialProductStatusChange: (initialProductStatus: ProductStatus) => void;
  borrowerTypeDisabled?: boolean;
  productTypeDisabled?: boolean;
}

export interface TemplateOption {
  name: string;
  type: ProductType;
  image: React.ComponentType;
}

const BORROWER_TYPE_OPTIONS = [
  {
    name: 'Person',
    value: BorrowerType.Person,
  },
  {
    name: 'Company',
    value: BorrowerType.Company,
  },
];

const PRODUCT_TEMPLATE_OPTIONS_BY_BORROWER_TYPE = {
  [BorrowerType.Person]: Object.keys(personProductTypeOptions).map((productType) => ({
    ...personProductTypeOptions[productType],
    type: productType,
  })),
  [BorrowerType.Company]: Object.keys(companyProductTypeOptions).map((productType) => ({
    ...companyProductTypeOptions[productType],
    type: productType,
  })),
};

const MAX_PRODUCT_NAME_LENGTH = 50;

const validateProductNameField = (productName: string) => {
  if (!productName) {
    return 'Product name is required';
  }

  return null;
};

const ProductBasicInfoForm = ({
  productName,
  borrowerType,
  productType,
  initialProductStatus,
  onProductNameChange,
  onBorrowerTypeChange,
  onProductTypeChange,
  onInitialProductStatusChange,
  borrowerTypeDisabled,
  productTypeDisabled,
}: ProductBasicInfoFormProps) => {
  const [productNameError, setProductNameError] = useState<string | null>(null);
  const [productNameFieldBlurred, setProductNameFieldBlurred] = useState(false);

  useEffect(() => {
    if (!PRODUCT_TEMPLATE_OPTIONS_BY_BORROWER_TYPE[borrowerType].find(({ type }) => type === productType)) {
      onProductTypeChange(null);
    }
  }, [borrowerType]);

  const handleProductNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS.test(event.target.value)) {
      return;
    }

    onProductNameChange(event.target.value);
    setProductNameError(validateProductNameField(event.target.value));
  };

  const handleBorrowerTypeChange = ({ value }: Option) => {
    onBorrowerTypeChange(value as BorrowerType);
  };

  const handleProductNameFieldBlur = () => {
    setProductNameFieldBlurred(true);
    setProductNameError(validateProductNameField(productName));
  };

  const renderTemplateOption = ({ name, type, image }: TemplateOption) => (
    <SelectableItem
      className={styles.productTemplateItem}
      key={type}
      title={name}
      selected={type === productType}
      type={type}
      disabled={productTypeDisabled}
      Image={image}
      onClick={() => onProductTypeChange(type)}
    />
  );

  const renderTooltip = () => (
    <>
      <p>Your product must be Active to appear on the</p>
      <p>Applications page. Disabling this toggle sets the</p>
      <p>status to Draft. Products can be activated at any</p>
      <p>time.</p>
    </>
  );

  return (
    <div>
      <TextInput
        value={productName}
        errorMessage={productNameFieldBlurred ? productNameError || '' : ''}
        labelTitle="Product Name"
        maxLength={MAX_PRODUCT_NAME_LENGTH}
        onChange={handleProductNameChange}
        onBlur={handleProductNameFieldBlur}
      />
      <AutoCompletion
        labelTitle="Borrower Type"
        options={BORROWER_TYPE_OPTIONS}
        value={borrowerType}
        hideClearIcon
        onChange={handleBorrowerTypeChange}
        disabled={borrowerTypeDisabled}
      />
      <div className={styles.productTemplateDescription}>Product Template</div>
      <div className={styles.productTemplatesContainer}>
        {PRODUCT_TEMPLATE_OPTIONS_BY_BORROWER_TYPE[borrowerType].map(renderTemplateOption)}
      </div>
      <div className={styles.productStatusSection}>
        <p>Product Status</p>
        <div>
          <CustomCheckbox
            checked={initialProductStatus === ProductStatus.Active}
            onChange={(event, checked) =>
              onInitialProductStatusChange(checked ? ProductStatus.Active : ProductStatus.Draft)
            }
            containerClassName={styles.productStatusCheckboxContainer}
            className={styles.productStatusCheckbox}
            label="This product will be initially set to Active"
          />
          <QuestionIcon className={styles.questionIcon} size={20} tooltip={renderTooltip()} />
        </div>
      </div>
    </div>
  );
};

export default ProductBasicInfoForm;
