import React, { ReactElement } from 'react';
import ComparisonOperatorButton from 'components/ComparisonOperator';
import { VisualDataType } from 'Variables/VariablesTypes';
import { ComparisonOperator } from 'components/SimpleRuleBuilder/Types';

import styles from './ComparisonOperatorPicker.module.scss';
import { TooltipPosition } from 'components/Tooltip';

interface ComparisonProps {
  comparison?: ComparisonOperator;
  onComparisonChange: (comparison: ComparisonOperator) => void;
  dataType: VisualDataType | undefined;
}

interface ComparisonElementType {
  type: string;
  value: ComparisonOperator;
  displayName: string;
  tooltipText: string | ReactElement;
  position?: TooltipPosition;
}

const comparisonElements: ComparisonElementType[] = [
  {
    type: 'symbol',
    value: 'EQUAL',
    displayName: '=',
    tooltipText: 'Equals',
    position: 'top',
  },
  {
    type: 'symbol',
    value: 'NOT EQUAL',
    displayName: '<>',
    tooltipText: 'Does not equal',
    position: 'top',
  },
  {
    type: 'symbol',
    value: 'FLOOR',
    displayName: '>=',
    tooltipText: 'Greater than or equal to',
    position: 'top',
  },
  {
    type: 'symbol',
    value: 'CAP',
    displayName: '<=',
    tooltipText: 'Less than or equal to',
    position: 'top',
  },
  {
    type: 'symbol',
    value: 'GT',
    displayName: '>',
    tooltipText: 'Greater than',
    position: 'top',
  },
  {
    type: 'symbol',
    value: 'LT',
    displayName: '<',
    tooltipText: 'Less than',
    position: 'top',
  },
  {
    type: 'text',
    value: 'RANGE',
    displayName: 'Between',
    tooltipText: (
      <div className={styles.tooltip}>
        <p>Within a range of numeric values,</p>
        <p>inclusive of both ends</p>
      </div>
    ),
    position: 'top',
  },
  {
    type: 'text',
    value: 'IN',
    displayName: 'In',
    tooltipText: (
      <div className={styles.tooltip}>
        <p>Equals one of multiple</p>
        <p>potential values</p>
      </div>
    ),
    position: 'bottom',
  },
  {
    type: 'text',
    value: 'NOT IN',
    displayName: 'Not In',
    tooltipText: (
      <div className={styles.tooltip}>
        <p>Does not equal any of multiple</p>
        <p>potential values</p>
      </div>
    ),
    position: 'bottom',
  },
  {
    type: 'text',
    value: 'IS NULL',
    displayName: 'Is Null',
    tooltipText: 'Has a value of null',
    position: 'bottom',
  },
  {
    type: 'text',
    value: 'IS NOT NULL',
    displayName: 'Is Not Null',
    tooltipText: 'Has any value other than null',
    position: 'bottom',
  },
];

const COMPARISON_OPERATORS_ALL: ComparisonOperator[] = [
  'EQUAL',
  'NOT EQUAL',
  'FLOOR',
  'CAP',
  'LT',
  'GT',
  'RANGE',
  'IS NULL',
  'IS NOT NULL',
];

const COMPARISON_OPERATORS_TEXT: ComparisonOperator[] = [
  'EQUAL',
  'NOT EQUAL',
  'IN',
  'NOT IN',
  'IS NULL',
  'IS NOT NULL',
];

const AVAILABLE_COMPARISON_OPERATORS_FOR_DATA_TYPE: { [dataType in VisualDataType]: ComparisonOperator[] } = {
  Number: COMPARISON_OPERATORS_ALL,
  Monetary: COMPARISON_OPERATORS_ALL,
  Percentage: COMPARISON_OPERATORS_ALL,
  List: COMPARISON_OPERATORS_TEXT,
  Text: COMPARISON_OPERATORS_TEXT,
  LargeText: COMPARISON_OPERATORS_TEXT,
  Boolean: ['EQUAL', 'NOT EQUAL', 'IS NULL', 'IS NOT NULL'],
  PhoneNumber: ['EQUAL', 'NOT EQUAL', 'IS NULL', 'IS NOT NULL'],
  EmailAddress: ['EQUAL', 'NOT EQUAL', 'IS NULL', 'IS NOT NULL'],
  Date: ['EQUAL', 'NOT EQUAL', 'LT', 'GT', 'RANGE', 'IS NULL', 'IS NOT NULL'],
  IdentificationNumber: ['EQUAL', 'NOT EQUAL', 'IS NULL', 'IS NOT NULL'],
};

const Comparison = (props: ComparisonProps) => {
  const { comparison, dataType, onComparisonChange } = props;

  if (!dataType) {
    return null;
  }

  return (
    <div className={styles.comparisonContainer}>
      <p className={styles.comparisonContainerTitle}>Comparison</p>
      <div className={styles.comparisons}>
        {comparisonElements.map((element) => {
          const { type, value, displayName, tooltipText, position } = element;
          return (
            <ComparisonOperatorButton
              type={type}
              value={value}
              displayName={displayName}
              isActive={value === comparison}
              tooltipText={tooltipText}
              handleSetCurrentComparison={() => onComparisonChange(value)}
              key={value}
              disabled={!AVAILABLE_COMPARISON_OPERATORS_FOR_DATA_TYPE[dataType].includes(value)}
              position={position}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Comparison;
