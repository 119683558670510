import React, { FC } from 'react';
import styles from './InputWithDataType.module.scss';
import clsx from 'clsx';
import VariableSelector from 'components/VariableSelector/VariableSelector';
import { AutoCompletionAsyncProps } from 'components/AutoCompletion/AutoCompletionAsync';

interface InputWithDataTypeProps extends Omit<AutoCompletionAsyncProps, 'fetchOptions'> {
  dataType?: string;
  description?: string;
  placeholderType?: string;
  needDataType?: boolean;
}

const InputWithDataTypeLabel: FC<InputWithDataTypeProps> = ({
  dataType,
  link,
  description,
  placeholderType,
  needDataType,
  ...props
}) => {
  const { labelTitle, onChange, value } = props;
  return (
    <div className={styles.container}>
      <div className={styles.fieldContainer}>
        <VariableSelector
          labelTitle={labelTitle}
          onChange={onChange}
          value={value}
          hasRightNeighbour
          dataType={needDataType ? dataType : undefined}
        />
        <div className={styles.dataTypeContainer}>
          <div className={styles.link}>{link}</div>
          <div className={clsx(styles.typeContainer, placeholderType && styles.typeContainerPlaceholder)}>
            {placeholderType || dataType}
          </div>
        </div>
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default InputWithDataTypeLabel;
