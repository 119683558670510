import React from 'react';
import { useDispatch } from 'react-redux';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { setApplicationStatusRulesValidationData } from 'LoanOriginationSystemApplications/ActionCreator';
import styles from './ApplicationStatusChangeErrorNotification.module.scss';

export interface ApplicationStatusChangeErrorNotificationProps {
  application: Application;
  status: ApplicationStatus;
  statusRulesValidationResult: ApplicationStatusRulesValidationResult;
}

const ApplicationStatusChangeErrorNotification = ({
  application,
  status,
  statusRulesValidationResult,
}: ApplicationStatusChangeErrorNotificationProps) => {
  const dispatch = useDispatch();

  const handleViewDetailsClick = () => {
    dispatch(
      setApplicationStatusRulesValidationData({
        application,
        status,
        statusRulesValidationResult,
      }),
    );
  };

  return (
    <div>
      This status change is not allowed because one or more status rules do not pass.{' '}
      <strong className={styles.viewDetails} onClick={handleViewDetailsClick}>
        View Details
      </strong>
    </div>
  );
};

export default ApplicationStatusChangeErrorNotification;
