import { connect } from 'react-redux';
import BranchRow from 'components/BranchRow/BranchRow';
import { openDeleteBranchWizard } from 'components/DeleteBranchWizard/Actions';
import { duplicateBranch } from 'BranchInfo/ActionCreator';

export type BranchRowConnectedProps = typeof mapDispatchToProps;

const mapDispatchToProps = {
  openDeleteBranchPopup: openDeleteBranchWizard,
  duplicateBranch,
};

export default connect(null, mapDispatchToProps)(BranchRow);
