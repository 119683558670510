import { ChangeStrategyStatusWizardActions } from 'components/ArchiveStrategyWizard/constants';
import { ArchiveEntityType } from 'components/ArchiveStrategyWizard/changeStrategyStatusWizardReducer';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export const openArchiveStrategyWizard = (strategyName: string, archived: boolean) => ({
  type: ChangeStrategyStatusWizardActions.OpenArchiveStrategyWizard,
  payload: {
    entityType: ArchiveEntityType.Strategy,
    strategyName,
    archived,
    actionOrigin: StrategyActionOrigin.StrategiesGroupsList,
  },
});

export const openArchiveVersionWizard = (
  strategyId: string,
  strategyName: string,
  archived: boolean,
  versionNumber: number,
  actionOrigin: StrategyActionOrigin,
) => ({
  type: ChangeStrategyStatusWizardActions.OpenArchiveStrategyWizard,
  payload: {
    entityType: ArchiveEntityType.Version,
    strategyId,
    strategyName,
    archived,
    versionNumber,
    actionOrigin,
  },
});

export const closeArchiveStrategyWizard = () => ({
  type: ChangeStrategyStatusWizardActions.CloseArchiveStrategyWizard,
});
