import { TableViewData } from 'api/LoanOriginationSystem/Types';
import {
  Intermediary,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import Fetcher from 'middlewares/Fetcher';
import { GetIntermediariesAction, getIntermediariesFailed, getIntermediariesSuccess } from './ActionCreator';
import { GET_INTERMEDIARIES } from './ActionTypes';
import { INTERMEDIARIES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/IntermediariesDashboard/Pagination';
import { ReduxState } from 'types/redux';

const GetIntermediariesMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<TableViewData<Intermediary> & { searchInputValue: string }, GetIntermediariesAction, ReduxState>(
    GET_INTERMEDIARIES,
    getIntermediariesSuccess,
    getIntermediariesFailed,
    async (action) => {
      const { filters, sortingType } = action.payload;

      const response = await api.getIntermediaries(
        {
          search: filters.searchInputValue,
          offset: 0,
          count: INTERMEDIARIES_PER_PAGE_DEFAULT,
          dueCreatedDateRange: filters.dueCreatedDateRange,
          dueUpdatedDateRange: filters.dueUpdatedDateRange,
          dueCommissionRange: filters.dueCommissionRange,
          borrowerTypes: filters.borrowerTypes,
          members: filters.selectedMembers,
        },
        sortingType,
      );

      return {
        ...response,
        searchInputValue: filters.searchInputValue,
      };
    },
  );

export default GetIntermediariesMiddleware;
