import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import {
  ChangeUserPhotoError,
  ChangeUserPhotoSuccess,
  GetUserInfoRequest,
  ChangeUserPhotoRequestAction,
} from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { User } from './AccountDetailsTypes';
import notification from 'handlers/notification/notificationActionCreator';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';

export const onChangeUserPhotoSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (action.type === AccountDetailsActionType.ChangeAccountPhotoSuccess) {
    notification.createNotification('Photo saved successfully!', 'success', dispatch);
    dispatch(GetUserInfoRequest());
  }
  return result;
};

const ChangeUserPhotoMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<User, ChangeUserPhotoRequestAction>(
    AccountDetailsActionType.ChangeAccountPhoto,
    ChangeUserPhotoSuccess,
    ChangeUserPhotoError,
    ({ payload }) => api.updateAccountPhoto(payload),
  );

export default ChangeUserPhotoMiddleware;
