import React, { FC } from 'react';
import { ModuleBranch } from 'DecisionStrategy/DecisionStrategiesTypes';
import styles from 'components/BranchRow/Branch.module.scss';
import { TreeImage, DragImage, EditImage } from 'static/images';
import BranchNameInput from 'components/BranchRow/BranchNameInput';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import clsx from 'clsx';
import { BranchRowConnectedProps } from 'components/BranchRow/BranchRowConnector';

interface BranchRowProps {
  branchInfo: ModuleBranch;
  branchIndex: number;
  strategyId: string;
  isStrategyLocked: boolean;
  moduleId: string;
  isActive: boolean;
  onEditBranch?: (branchId: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const BranchRow: FC<BranchRowProps & BranchRowConnectedProps> = ({
  branchInfo,
  moduleId,
  strategyId,
  isStrategyLocked,
  branchIndex,
  isActive,
  duplicateBranch,
  openDeleteBranchPopup,
  onEditBranch,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    onEditBranch?.(branchIndex, event);
  };

  const { name: branchName } = branchInfo;

  const containerClassNames = clsx(styles.branchBody, {
    [styles['branchBody--active']]: isActive,
  });

  const renderBranchBody = () => {
    return (
      <div className={containerClassNames}>
        <TreeImage />
        <div className={styles.branchInfo}>
          <div className={styles.branchName}>
            <BranchNameInput
              branchName={branchName}
              moduleId={moduleId}
              branchIndex={branchIndex}
              strategyId={strategyId}
              isStrategyLocked={isStrategyLocked}
              className={styles.branchNameInput}
            />
            <button type="button" className={styles.editButton} onClick={handleClick}>
              <EditImage />
              Edit Rules
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <RowActionsContainer>
      <div className={styles.branch}>
        <div className={styles.dragImage}>
          <DragImage />
        </div>
        {renderBranchBody()}
        <RowActions
          actions={[
            {
              title: 'Duplicate Branch',
              handler: () =>
                duplicateBranch({
                  strategyId,
                  moduleKey: moduleId,
                  branchIndex,
                  branchName,
                }),
              danger: false,
              separatorRequired: true,
            },
            {
              title: 'Delete Branch',
              handler: () => openDeleteBranchPopup(branchIndex, strategyId, moduleId),
              danger: true,
            },
          ]}
          btnClassName={styles.moreButton}
        />
      </div>
    </RowActionsContainer>
  );
};

export default BranchRow;
