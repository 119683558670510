import { ApplicationNote, DataFilter, DataViewSortingType, TableViewData } from 'api/Types';
import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';

export enum ApplicationNotesSortingField {
  UpdatedAt = 'updatedAt',
  Note = 'note',
  CreatedBy = 'createdBy',
}

export type ApplicationNotesSortingType = DataViewSortingType<ApplicationNotesSortingField>;

export interface ApplicationNotesApi {
  getAll(
    applicationId: string,
    filters: DataFilter,
    sortingType: ApplicationNotesSortingType,
  ): Promise<TableViewData<ApplicationNote>>;
  addNote(applicationId: string, note: string): Promise<ApplicationNote>;
  updateNote(applicationId: string, noteId: string, note: string): Promise<ApplicationNote>;
  deleteNote(applicationId: string, noteId: string): Promise<ApplicationNote>;
}

export interface ApplicationNotesResponseData {
  rows: ApplicationNote[];
  numItems: number;
}

export default class ApplicationNotesApiRest extends LoanOriginationSystemApi<ApplicationNote>
  implements ApplicationNotesApi {
  protected resourceName = 'applications/notes';

  public async getAll(applicationId: string, filters: DataFilter, sortingType: ApplicationNotesSortingType) {
    return this.fetch<TableViewData<ApplicationNote>>(
      `/applications/${applicationId}/notes?${this.getPaginationUrlSearchParams(filters, sortingType)}`,
    );
  }

  public async addNote(applicationId: string, note: string) {
    return this.fetch<ApplicationNote>(`/applications/${applicationId}/notes`, 'POST', {
      note,
    });
  }

  public async updateNote(applicationId: string, noteId: string, note: string) {
    return this.fetch<ApplicationNote, { note: string }>(`/applications/${applicationId}/notes/${noteId}`, 'PUT', {
      note,
    });
  }

  public async deleteNote(applicationId: string, noteId: string) {
    return this.fetch<ApplicationNote, { noteId: string }>(`/applications/${applicationId}/notes/${noteId}`, 'DELETE');
  }
}
