import React, { FC } from 'react';
import StyledSwitch from 'components/StyledSwitch';
import clsx from 'clsx';
import styles from 'components/ListHeader/ListHeader.module.scss';

interface SliderButtonProps {
  active: boolean;
  onChange: (active: boolean) => void;
  labelText?: string;
}

const SliderButton: FC<SliderButtonProps> = ({ active, onChange, labelText }) => {
  const labelClasses = clsx(styles.labelText, !active && styles['labelText--inactive']);
  return (
    <>
      <StyledSwitch checked={active} onChange={(_event, checked) => onChange(checked)} />
      {labelText && <p className={labelClasses}>{labelText}</p>}
    </>
  );
};

export default SliderButton;
