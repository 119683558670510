import { SelectProductAction } from 'LoanOriginationSystemApplications/ActionCreator';
import { SELECT_PRODUCT } from 'LoanOriginationSystemApplications/ActionTypes';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  ApplicationStatusesActionOrigin,
  getApplicationStatuses,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';

const isSelectProductAction = (action: AnyAction): action is SelectProductAction => action.type === SELECT_PRODUCT;

const selectProductMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: SelectProductAction,
) => {
  const result = next(action);

  if (isSelectProductAction(action)) {
    const { productId } = action.payload;

    dispatch(setActionOrigin(getApplicationStatuses(productId), ApplicationStatusesActionOrigin.Applications));
  }

  return result;
}) as Middleware;

export default selectProductMiddleware;
