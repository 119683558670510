import React, { forwardRef, useRef, useState } from 'react';
import { CheckedImage } from 'static/images';
import styles from './StatusDropdown.module.scss';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import clsx from 'clsx';
import Separator from 'components/Separator';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import StatusDropdownButton from 'components/LoanOriginationSystem/ApplicationDetails/Title/StatusDropdownButton';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';

interface StatusDropdownProps {
  statusesList: ApplicationStatus[];
  selectedStatus: ApplicationStatus;
  className?: string;
  onSelect: (status: ApplicationStatus) => void;
}

const StatusDropdown = forwardRef<any, StatusDropdownProps>(
  ({ statusesList: statuses, selectedStatus, className, onSelect }, ref) => {
    const ApprovedTitle = 'Approve Application';
    const RejectedTitle = 'Reject Application';

    const statusButtonRef = useRef(null);
    const [open, setOpen] = useState(false);

    const mapStatusDropdownTitle = (statusName: string) => {
      switch (statusName) {
        case DefaultApplicationStatus.Approved:
          return ApprovedTitle;
        case DefaultApplicationStatus.Rejected:
          return RejectedTitle;
        default:
          return statusName;
      }
    };

    const shouldShowSeparator = (statusName: string) => {
      return statusName === DefaultApplicationStatus.Approved || statusName === DefaultApplicationStatus.Rejected;
    };

    const onStatusClick = (status: ApplicationStatus) => {
      if (status.id !== selectedStatus.id) {
        onSelect(status);
        setOpen(false);
      }
    };

    return (
      <div className={clsx(styles.statusDropdown, className)} ref={ref}>
        <StatusDropdownButton onClick={() => setOpen(true)} status={selectedStatus.name} ref={statusButtonRef} />
        <ContextualPopUp anchorEl={statusButtonRef.current} open={open} onClose={() => setOpen(false)}>
          <ActionPopUp title="Change Status To" onClose={() => setOpen(false)}>
            {statuses.map((status) => (
              <div className={styles.statusDropdownItemContainer} key={status.id}>
                {shouldShowSeparator(status.name) && <Separator />}
                <ActionPopUpItem
                  danger={status.name === DefaultApplicationStatus.Rejected}
                  className={clsx(
                    styles.statusDropdownItem,
                    status.id === selectedStatus.id && styles.statusDropdownItemSelected,
                  )}
                  onClick={() => onStatusClick(status)}
                >
                  {mapStatusDropdownTitle(status.name)}
                  {status.id === selectedStatus.id && <CheckedImage />}
                </ActionPopUpItem>
              </div>
            ))}
          </ActionPopUp>
        </ContextualPopUp>
      </div>
    );
  },
);

export default StatusDropdown;
