import React, { FC, ReactElement, ReactNode } from 'react';
import Header from './Header';

interface ToggleableContainerProps {
  className?: string;
  switchButtonClassname?: string;
  children: ReactNode;
  labelText: string;
  expanded: boolean;
  disabled: boolean;
  onToggle: (isExpanded: boolean) => void;
  helpText?: ReactNode;
  downloadCSV?: (population: string) => void;
  tooltipText?: string | ReactElement;
}

const ToggleableContainer: FC<ToggleableContainerProps> = (props: ToggleableContainerProps) => {
  const {
    children,
    className,
    switchButtonClassname,
    labelText,
    expanded,
    disabled,
    onToggle,
    downloadCSV,
    tooltipText,
    helpText,
  } = props;

  return (
    <>
      <Header
        switchButtonClassname={switchButtonClassname}
        className={className}
        labelText={labelText}
        disabled={disabled}
        onChange={onToggle}
        checked={expanded}
        downloadCSV={downloadCSV}
        tooltipText={tooltipText}
        helpText={helpText}
      />
      {expanded && children}
    </>
  );
};

export default ToggleableContainer;
