import { uniq } from 'lodash';

interface EmailFooterParams {
  from: string;
  sent: string;
  to: string[];
  cc: string[];
  subject: string;
  body: string;
}

const MAX_BODY_LENGTH = 1900;
const BODY_END = '[Message continues…]';

const getShortBody = (body: string) => {
  if (body.length > MAX_BODY_LENGTH) {
    return `${body.slice(0, MAX_BODY_LENGTH - BODY_END.length)} ${BODY_END}`;
  }

  return body;
};

export const wrapEmailWithFooter = ({ from, sent, to, cc, subject, body }: EmailFooterParams) => {
  return (
    // eslint-disable-next-line prefer-template
    '\n\n' +
    '---\n' +
    `From: ${from} \n` +
    `Sent: ${sent} \n` +
    `To: ${to.length ? uniq(to).join(', ') : ''} \n` +
    `Cc: ${cc.length ? uniq(cc).join(', ') : ''} \n` +
    `Subject: ${subject} \n\n\n` +
    getShortBody(body)
  );
};
