import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { Label, LabelsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

export const LABELS_PER_PAGE_DEFAULT = 10;

export interface LabelsPaginationParams {
  search: string;
  sortingType: LabelsSortingType;
}

const labelsPagination = Pagination<ReduxState, Label, LabelsPaginationParams>(
  'loanOriginationSystemLabelsState',
  LABELS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default labelsPagination;
