import apiActivationPagination from 'pages/ApiActivation/Pagination';
import { IStrategyGroupsApi } from 'api/DecisionEngine/StrategyGroupApi';

export default (strategyGroupsApi: IStrategyGroupsApi) => {
  return apiActivationPagination.getItemsFetcherMiddleware(async ({ selectedUpdaters, ...params }) => {
    const { items } = await strategyGroupsApi.findGroups({
      updaters: selectedUpdaters.map(({ id }) => id),
      ...params,
    });
    return items;
  });
};
