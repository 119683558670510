import { AnyAction } from 'redux';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import {
  ChangeContextualViewStepAction,
  DeleteBorrowerAction,
  DeleteBorrowerSuccessAction,
  GetBorrowersSuccessAction,
  SelectBorrowerViewTabAction,
  SetBorrowerToDeleteIdAction,
  GetBorrowerToDeleteApplicationsSuccessAction,
  SortBorrowersAction,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersState } from './Types';
import { BorrowersSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import borrowersPagination, {
  BORROWERS_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/BorrowersDashboard/pagination';
import {
  initialState as borrowersFiltersInitialState,
  loanOriginationSystemBorrowersFiltersReducer,
} from './Filters/Reducer';
import {
  initialState as createBorrowerInitialState,
  loanOriginationSystemCreateBorrowerReducer,
} from './CreateBorrower/Reducer';
import {
  initialState as editBorrowerInitialState,
  loanOriginationSystemEditBorrowerReducer,
} from './EditBorrower/Reducer';
import {
  initialState as unlockBorrowerInitialState,
  loanOriginationSystemUnlockBorrowerReducer,
} from './UnlockBorrower/Reducer';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './Filters/ActionTypes';
import {
  ChangePopupFiltersAction,
  FilterBorrowersByMembersAction,
  ResetAllBorrowersFilters,
} from './Filters/ActionCreator';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';

type LoanOriginationSystemBorrowersType = (
  state: LoanOriginationSystemBorrowersState,
  action: AnyAction,
) => LoanOriginationSystemBorrowersState;

const initialState: LoanOriginationSystemBorrowersState = {
  items: [],
  page: 1,
  itemsPerPage: BORROWERS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    field: BorrowersSortingField.UpdatedAt,
    ascending: false,
  },
  searched: {},
  borrowerToDeleteId: null,
  borrowerToDeleteHasApplications: false,
  isDeleting: false,
  filters: borrowersFiltersInitialState,
  createBorrower: createBorrowerInitialState,
  editBorrower: editBorrowerInitialState,
  unlockBorrower: unlockBorrowerInitialState,
  contextualViewTab: BorrowerDetailsTab.Profile,
  contextualViewStep: 0,
};

type LoanOriginationSystemBorrowersActions =
  | GetBorrowersSuccessAction
  | SortBorrowersAction
  | DeleteBorrowerAction
  | DeleteBorrowerSuccessAction
  | SetBorrowerToDeleteIdAction
  | GetBorrowerToDeleteApplicationsSuccessAction
  | FailedAction<LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure>
  | ChangePopupFiltersAction
  | FilterBorrowersByMembersAction
  | ResetAllBorrowersFilters
  | ChangeContextualViewStepAction
  | SelectBorrowerViewTabAction;

const loanOriginationSystemBorrowersReducer = (
  state = initialState,
  action: LoanOriginationSystemBorrowersActions,
): LoanOriginationSystemBorrowersState => {
  switch (action.type) {
    case LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess: {
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: BORROWERS_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || BORROWERS_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SortBorrowers: {
      return {
        ...state,
        sortingType: action.payload,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrower: {
      return {
        ...state,
        isDeleting: true,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess: {
      return {
        ...state,
        borrowerToDeleteId: null,
        isDeleting: false,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure: {
      return {
        ...state,
        isDeleting: false,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDeleteId: {
      if (state.borrowerToDeleteHasApplications) return state;

      return {
        ...state,
        borrowerToDeleteId: action.payload,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplicationsSuccess: {
      return {
        ...state,
        borrowerToDeleteHasApplications: action.payload.total > 0,
      };
    }
    case LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers:
    case LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        itemsTotal: undefined,
        filters: loanOriginationSystemBorrowersFiltersReducer(state.filters, action),
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab: {
      const { tab } = action.payload;
      return {
        ...state,
        contextualViewTab: tab,
      };
    }
    case LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep: {
      const { step } = action.payload;
      return {
        ...state,
        contextualViewStep: step,
      };
    }
    default: {
      return {
        ...state,
        filters: loanOriginationSystemBorrowersFiltersReducer(state.filters, action),
        createBorrower: loanOriginationSystemCreateBorrowerReducer(state.createBorrower, action),
        editBorrower: loanOriginationSystemEditBorrowerReducer(state.editBorrower, action),
        unlockBorrower: loanOriginationSystemUnlockBorrowerReducer(state.unlockBorrower, action),
      };
    }
  }
};

export default borrowersPagination.wrapReducer<LoanOriginationSystemBorrowersState>(
  withStateReset(
    loanOriginationSystemBorrowersReducer,
    LoanOriginationSystemBorrowersActionTypes.ResetState,
    () => initialState,
  ) as LoanOriginationSystemBorrowersType,
);
