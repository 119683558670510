import PopUp from 'components/PopUps/PopUp';
import React from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import styles from './UnlockCustomerPopup.module.scss';
import Button from 'components/Button';

interface UnlockCustomerPopupProps {
  onPopupClose: () => void;
  onUnlockClick: () => void;
  isUnlockingInProgress: boolean;
}

const UnlockCustomerPopup = ({ onPopupClose, onUnlockClick, isUnlockingInProgress }: UnlockCustomerPopupProps) => {
  return (
    <PopUp closePopUp={onPopupClose} title="Unlock Borrower" closable={!isUnlockingInProgress}>
      <PopUpContent hasTopMargin>
        <p className={styles.contentMessage}>Are you sure you want to unlock this borrower?</p>
        <div className={styles.buttonsContainer}>
          <Button
            isLoading={isUnlockingInProgress}
            kind="primary"
            size="form"
            className={styles.unlockCustomerButton}
            onClick={onUnlockClick}
          >
            Yes, Unlock Borrower
          </Button>
          <Button kind="secondary" size="form" onClick={onPopupClose} disabled={isUnlockingInProgress}>
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default UnlockCustomerPopup;
