import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { BatchIndividualCase } from 'api/Types';

// The backend always sends 15 records regardless of the limit parameter
export const BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT = 50;

const batchCasePagination = Pagination<ReduxState, BatchIndividualCase, { caseId: string; searchInputValue: string }>(
  'batchProcessingCase',
  BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT,
  ({ searchInputValue }) => (searchInputValue ? ['searched', searchInputValue] : []),
);

export default batchCasePagination;
