import Fetcher from 'middlewares/Fetcher';
import { ApplicationDocument, ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/pagination';
import {
  getApplicationDocumentsSuccess,
  getApplicationDocumentsFailure,
  GetApplicationDocumentsAction,
} from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

const GetApplicationDocumentsMiddleware = (api: ApplicationDocumentsApi) =>
  Fetcher<TableViewData<ApplicationDocument> & { search: string }, GetApplicationDocumentsAction>(
    ApplicationDocumentsActionType.GetApplicationDocuments,
    getApplicationDocumentsSuccess,
    getApplicationDocumentsFailure,
    async (action) => {
      const data = await api.getAll(
        action.payload.applicationId,
        {
          search: action.payload.search,
          count: APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT,
          offset: 0,
        },
        action.payload.sortingType,
      );

      return {
        ...data,
        search: action.payload.search,
      };
    },
  );

export default GetApplicationDocumentsMiddleware;
