export enum RunDecisionWizardActionType {
  OpenRunDecisionWizard = 'RunDecisionWizard/openRunDecisionWizard',
  CloseRunDecisionWizard = 'RunDecisionWizard/closeRunDecisionWizard',
  GetStrategies = 'applicationDecisionRuns/getStrategies',
  GetStrategiesSuccess = 'applicationDecisionRuns/getStrategiesSuccess',
  GetStrategiesFailure = 'applicationDecisionRuns/getStrategiesFailure',
  GetApplicationStrategiesInputs = 'applicationDecisionRuns/getApplicationStrategiesInputs',
  GetApplicationStrategiesInputsSuccess = 'applicationDecisionRuns/getApplicationStrategiesInputsSuccess',
  GetApplicationStrategiesInputsFailure = 'applicationDecisionRuns/getApplicationStrategiesInputsFailure',
  ResetApplicationStrategiesInputs = 'applicationDecisionRuns/resetApplicationStrategiesInputs',
  GetApplicationProductVariables = 'applicationDecisionRuns/getApplicationVariableConfigurations',
  GetApplicationProductVariablesSuccess = 'applicationDecisionRuns/getApplicationVariableConfigurationsSuccess',
  GetApplicationProductVariablesFailure = 'applicationDecisionRuns/getApplicationVariableConfigurationsFailure',
}
