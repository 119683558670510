import React, { FC } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';
import { DeleteStrategyWizardConnectedProps } from 'DeleteStrategyWizard/DeleteStrategyWizardConnector';

const TYPE_TO_TITLE_TEXT_MAPPING = {
  strategy: 'Delete Strategy',
  version: 'Delete Version',
};

const DeleteStrategyWizard: FC<DeleteStrategyWizardConnectedProps> = (props) => {
  const { closePopUp, type, isOpen, deleteStrategy, id, name, version, isLoading, isCurrent, actionOrigin } = props;
 
  if (!isOpen) {
    return null;
  }

  const message =
    type === 'strategy'
      ? `Please confirm that you would like to delete all versions of ${name}.`
      : `Please confirm that you would like to delete ${name} Version ${version}.`;

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={TYPE_TO_TITLE_TEXT_MAPPING[type!]} closable={!isLoading}>
      <DeletePopup
        isLoading={isLoading}
        handleDelete={() => deleteStrategy({ id, name, version }, isCurrent, type!, actionOrigin)}
        message={message}
        handleClose={closePopUp}
        cancelBtnDisabled={isLoading}
      />
    </PopUpWizard>
  );
};

export default DeleteStrategyWizard;
