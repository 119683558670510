import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Body from './Body';
import {
  changeApplicationUpdatingState,
  getProductDataByViewType,
  getProductsList,
  openDeleteApplicationPopup,
  selectProduct,
  setCreateApplicationProductData,
  sortColumnViewProductData,
  sortTableViewProductData,
  toggleCreateNewApplicationPopupOpen,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { ApplicationSortingField, ApplicationsSortingType } from 'api/Types';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { ReduxState } from 'types/redux';
import pagination, { ApplicationsPaginationParams } from './Pagination';
import {
  clearAllFilters,
  filterApplicationsByMembers,
  setSearchInputValue,
  toggleFiltersPopup,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import useStandardVariables from 'hooks/useStandardVariables';
import { useHistory } from 'react-router';
import { Application, UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { ApplicationFormPage, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import LandingView from 'components/LandingView/LandingView';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import styles from './ApplicationDashboard.module.scss';
import { NoResultsIcon } from 'static/images';
import isDeleteDisabled from 'LoanOriginationSystemApplications/isDeleteDisabled';
import useViewType from 'hooks/useViewType';
import RouteService from 'routes/RouteService';
import Header from './Header';
import useUserRole from 'MyAccount/useUserRole';
import openDuplicateApplicationPopupOrShowErrorMessage from 'LoanOriginationSystemApplications/openDuplicateApplicationPopupOrShowErrorMessage';

const NO_PRODUCTS_DESCRIPTION =
  'To get started with DigiFi’s Loan Origination System and customize that platform for your business, please add your first product.';

interface ApplicationDashboardProps {
  onApplicationsReorder: (
    applicationId: string,
    statusId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
}

const ApplicationsDashboard = ({ onApplicationsReorder }: ApplicationDashboardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const standardVariables = useStandardVariables();

  const {
    tableViewData,
    columnViewData,
    productsList,
    selectedProduct,
    tableViewSortingType,
    filters,
    columnViewSortingType,
    applicationUpdatingStatesById,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplications);

  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, selectedProduct?.id)) || [];

  const userRole = useUserRole();

  const organizationMembers = useOrganizationMembers();

  const viewType = useViewType();

  useEffect(() => {
    if (!productsList) {
      dispatch(getProductsList());
    }
  }, []);

  useEffect(() => {
    if (productsList && productsList.length > 0 && !selectedProduct) {
      dispatch(selectProduct(productsList[0].id));
    }
  }, [productsList]);

  const onViewTypeChange = (newViewType: ViewType) => {
    history.push(RouteService.getApplicationsUrl(newViewType));

    if (selectedProduct) {
      dispatch(
        getProductDataByViewType(
          selectedProduct?.id,
          newViewType,
          filters,
          newViewType === ViewType.Column ? columnViewSortingType : tableViewSortingType,
        ),
      );
    }
  };

  const onSearch = (search: string) => {
    dispatch(setSearchInputValue(search, viewType));
  };

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterApplicationsByMembers(selectedProduct!.id, newSelectedMembers, viewType));
  };

  const onProductSelect = (product: Product) => {
    if (selectedProduct?.id !== product.id) {
      dispatch(selectProduct(product.id));
    }
  };

  const onTableViewSort = (field: ApplicationSortingField, ascending: boolean) => {
    dispatch(sortTableViewProductData(field, ascending));
  };

  const openFiltersPopup = () => {
    dispatch(toggleFiltersPopup());
  };

  const onCreateNewApplication = () => {
    if (!productsList || productsList.length === 0) {
      return;
    }

    const [product] = productsList;

    const isAddCoBorrowerAvailable = product.settings.applicationFormPages.includes(ApplicationFormPage.CoBorrower);
    const isAddIntermediaryAvailable = product.settings.applicationFormPages.includes(ApplicationFormPage.Intermediary);

    if (productsList.length > 1 || isAddCoBorrowerAvailable || isAddIntermediaryAvailable) {
      dispatch(toggleCreateNewApplicationPopupOpen());

      return;
    }

    dispatch(
      setCreateApplicationProductData({
        product,
        additionalFormPages: [],
      }),
    );

    history.push(RouteService.getApplicationsUrl(viewType, true));
  };

  const onColumnViewSortingTypeChange = ({ field, ascending }: ApplicationsSortingType) => {
    dispatch(sortColumnViewProductData(field, ascending));
  };

  const paginationParams = {
    selectedProduct,
    selectedMembers: filters.selectedMembers,
    search: filters.searchInputValue,
    sortingType: tableViewSortingType,
    selectedLabels: filters.selectedLabels,
    createdDateRange: filters.createdDateRange,
    updatedDateRange: filters.updatedDateRange,
    selectedIntermediaries: filters.selectedIntermediaries,
    selectedStatusesIds: filters.selectedStatusesIds,
  } as ApplicationsPaginationParams;

  const paginationItems = pagination.usePaginatedItems(paginationParams);

  const paginationProps = pagination.usePagination(paginationParams);

  if (productsList && productsList.length === 0) {
    return (
      <LandingView
        title="Welcome to DigiFi’s Loan Origination System!"
        description={NO_PRODUCTS_DESCRIPTION}
        buttonText="New Product"
        onButtonClick={() => history.push('/los/configuration/products?new')}
      />
    );
  }

  const areFiltersChanged = () => {
    return (
      (viewType === ViewType.Table && filters.selectedStatusesIds.length !== applicationStatuses.length) ||
      filters.selectedLabels.length > 0 ||
      filters.selectedIntermediaries.length > 0 ||
      filters.createdDateRange.from !== null ||
      filters.createdDateRange.to !== null ||
      filters.updatedDateRange.from !== null ||
      filters.updatedDateRange.to !== null
    );
  };

  const handleClearAllFilters = () => {
    dispatch(
      clearAllFilters(
        applicationStatuses.map(({ id }) => id),
        viewType,
      ),
    );
  };

  const noApplicationsAdded =
    viewType === ViewType.Column ? columnViewData?.length === 0 : tableViewData?.items.length === 0;

  const filtersApplied = areFiltersChanged() || filters.selectedMembers.length > 0;

  const onDeleteApplication = (application: Application) => {
    dispatch(openDeleteApplicationPopup(application.id));
  };

  const handleApplicationUpdatingStateReset = (applicationId: string) => {
    dispatch(changeApplicationUpdatingState(applicationId, null));
  };

  const onEditApplication = (application: Application) => {
    history.push(`/los/applications/${application.displayId}`);
  };

  const onDuplicateApplication = (application: Application) => {
    openDuplicateApplicationPopupOrShowErrorMessage(application, dispatch);
  };

  return (
    <>
      <Header
        selectedProduct={selectedProduct}
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        viewType={viewType}
        searchInputValue={filters.searchInputValue}
        productsList={productsList || []}
        subTitleHidden={!filtersApplied && !filters.searchInputValue && noApplicationsAdded}
        filtersChanged={areFiltersChanged()}
        columnViewSortingType={columnViewSortingType}
        onViewTypeChange={onViewTypeChange}
        onSearch={onSearch}
        onMemberFilterChange={onMemberFilterChange}
        onProductSelect={onProductSelect}
        openFiltersPopup={openFiltersPopup}
        onCreateNewApplication={onCreateNewApplication}
        onColumnViewSortingTypeChange={onColumnViewSortingTypeChange}
      />
      {!filtersApplied && !filters.searchInputValue && noApplicationsAdded && (
        <NoItems
          className={styles.noApplicationsAddedContainer}
          title="No applications have been added yet!"
          buttonMessage="Add New Application"
          buttonClassName={styles.noApplicationsAddedButton}
          titleClassName={styles.noApplicationsAddedTitle}
          onButtonClick={onCreateNewApplication}
        />
      )}
      {filters.searchInputValue && noApplicationsAdded && (
        <SearchNotFound className={styles.searchNotFound} searchValue={filters.searchInputValue} />
      )}
      {!filters.searchInputValue && filtersApplied && noApplicationsAdded && (
        <NoItems
          className={styles.noApplicationsFoundContainer}
          title="No Results Found"
          subtitle="Please remove or adjust your filters."
          buttonMessage="Clear Filters"
          icon={<NoResultsIcon />}
          buttonClassName={styles.noApplicationsFoundButton}
          titleClassName={styles.noApplicationsFoundTitle}
          subtitleClassName={styles.noApplicationsFoundSubtitle}
          onButtonClick={handleClearAllFilters}
        />
      )}
      {!noApplicationsAdded && (
        <Body
          applications={columnViewData}
          viewType={viewType}
          onApplicationsReorder={onApplicationsReorder}
          tableViewData={paginationItems}
          paginationProps={paginationProps}
          tableViewSortingType={tableViewSortingType}
          onTableViewSort={onTableViewSort}
          filters={filters}
          applicationStatuses={applicationStatuses}
          standardVariables={standardVariables}
          deleteDisabled={isDeleteDisabled(userRole)}
          onDeleteApplication={onDeleteApplication}
          applicationUpdatingStatesById={applicationUpdatingStatesById}
          onApplicationUpdatingStateReset={handleApplicationUpdatingStateReset}
          onEditApplication={onEditApplication}
          onDuplicateApplication={onDuplicateApplication}
        />
      )}
    </>
  );
};

export default ApplicationsDashboard;
