import React, { FC, useMemo } from 'react';
import capitalize from 'capitalize';
import { VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import formatValueByDataType from 'utils/formatValueByDataType';
import OverflowedText from 'components/OverflowedText';
import styles from './DataTabCard.module.scss';

export interface DataTabCardProps {
  data: Record<string, VariableValue>;
  fields: VariableConfiguration[];
}

const formatBooleanValue = (value: string) => capitalize(value);

const DataTabCard: FC<DataTabCardProps> = ({ fields, data }) => {
  const sortedFields = useMemo(() => {
    return fields.sort((firstField, secondField) => firstField.position - secondField.position);
  }, [fields]);

  const renderField = (field: VariableConfiguration) => (
    <div className={styles.field} key={field.id}>
      <div className={styles.fieldTitle}>
        <OverflowedText>{field.variable.displayName}</OverflowedText>
      </div>
      <div className={styles.fieldValue}>
        <OverflowedText>
          {formatValueByDataType(data[field.variable.systemName], field.variable, { formatBooleanValue })}
        </OverflowedText>
      </div>
    </div>
  );

  return <div className={styles.fields}>{sortedFields.map(renderField)}</div>;
};

export default DataTabCard;
