import React, { CSSProperties } from 'react';
import { SeriesPoint } from '@visx/shape/lib/types';
import { GridRows } from '@visx/grid';
import { AxisBottom } from '@visx/axis';
import { scaleBand } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { getYScale } from './utils';
import styles from './Chart.module.scss';
import ChartTooltip, { TooltipProps } from './ChartTooltip/ChartTooltip';
import ChartLegend from './ChartLegend/ChartLegend';
import ChartBars from './BarsView';
import { formatDate, getChartBarKeys } from 'components/LoanOriginationSystem/ReportingDashboard/utils';
import LeftAxis from './LeftAxis/LeftAxis';
import RightAxis from './RightAxis/RightAxis';
import { isEmpty } from 'lodash';
import ChartSkeleton from './ChartSkeleton/ChartSkeleton';

export enum BarTypes {
  Amount = 'amount',
  Number = 'number',
}

export interface MonthAmount {
  date: string;
  Amount: number;
  Number: number;
}

export interface TooltipData {
  bar: SeriesPoint<MonthAmount>;
  key: BarTypes;
  index: number;
  height: number;
  width: number;
  x: number;
  y: number;
  color: string;
}

export type BarStackProps = {
  dateKeys: string[];
  amountKeys: number[];
  numberKeys: number[];
  factor: number;
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  dataByMonth: MonthAmount[];
  loading: boolean;
  currency: string;
};

const COLORS_LIST = ['#1E95F8', '#053668'];

const tooltipStyles = {
  position: 'absolute',
  maxWidth: '180px',
  maxHeight: '92px',
  backgroundColor: 'white',
  boxShadow: '0px 8px 24px rgba(15, 35, 66, 0.2)',
};

const BARS_LIMIT = 50;
const ADDITIONAL_CHART_WIDTH_BY_BAR = 50;
const AXIS_WIDTH = 80;

export default function Chart({
  dateKeys,
  amountKeys,
  numberKeys,
  dataByMonth,
  factor,
  width,
  height,
  loading,
  currency,
}: BarStackProps) {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip<TooltipData>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });

  const yLeftScale = getYScale(amountKeys);
  const yRightScale = getYScale(numberKeys);
  yRightScale.range([height, 0]);
  yLeftScale.range([height, 0]);

  const getChartWidth = () => {
    if (dateKeys.length > BARS_LIMIT) {
      return width - AXIS_WIDTH + (dateKeys.length - BARS_LIMIT) * ADDITIONAL_CHART_WIDTH_BY_BAR;
    }
    return width - AXIS_WIDTH;
  };

  const dateScale = scaleBand<string>({
    domain: dateKeys,
    padding: 0.4,
  }).rangeRound([0, getChartWidth()]);

  const renderChartBody = () => {
    if (isEmpty(dataByMonth) && loading) {
      return <ChartSkeleton />;
    }

    return (
      <>
        <div className={styles.chartContainer}>
          <LeftAxis height={height} scale={yLeftScale} />
          <div style={{ width: width - AXIS_WIDTH, height }} className={styles.chartWrapper}>
            <div>
              <svg ref={containerRef} width={getChartWidth()} height={height}>
                <GridRows
                  top={22}
                  scale={yLeftScale}
                  width={getChartWidth()}
                  height={height}
                  stroke="black"
                  strokeOpacity={0.1}
                  numTicks={4}
                  lineStyle={{ strokeWidth: 1 }}
                />
                <ChartBars
                  dateScale={dateScale}
                  dataByMonth={dataByMonth}
                  chartKeys={getChartBarKeys()}
                  height={height}
                  factor={factor}
                  hideTooltip={hideTooltip}
                  showTooltip={showTooltip}
                  yAxisMax={amountKeys[amountKeys.length - 1]}
                  colorsList={COLORS_LIST}
                />
                <AxisBottom
                  top={height - 62}
                  scale={dateScale}
                  tickFormat={formatDate}
                  tickLabelProps={() => ({
                    fill: styles.fontColor,
                    fontSize: styles.fontSize,
                    lineHeight: styles.lineHeight,
                    textAnchor: 'middle',
                    fontFamily: styles.fontFamily,
                    fontWeight: styles.fontWeight,
                  })}
                  hideAxisLine
                  hideTicks
                />
              </svg>
            </div>
          </div>
          <RightAxis height={height} primaryScale={yLeftScale} secondaryScale={yRightScale} />
        </div>
        {tooltipOpen && tooltipData && (
          <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles as CSSProperties}>
            <ChartTooltip tooltipData={tooltipData as TooltipProps} currency={currency} />
          </TooltipInPortal>
        )}
      </>
    );
  };

  return (
    <div className={styles.container} style={{ height }}>
      <ChartLegend domain={getChartBarKeys(currency)} range={COLORS_LIST} />
      {renderChartBody()}
    </div>
  );
}
