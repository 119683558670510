import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { closeCreateLabelPopUp, CreateLabelSuccessAction, getLabels } from './ActionCreator';

const isCreateLabelSuccessAction = (action: AnyAction): action is CreateLabelSuccessAction =>
  action.type === LoanOriginationSystemLabelsActionTypes.CreateLabelSuccess;

const CreateLabelSuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateLabelSuccessAction(action)) {
    const { loanOriginationSystemLabelsState } = getState();

    const { filters, sortingType } = loanOriginationSystemLabelsState;

    notification.createNotification(getMessage(MessageType.LabelCreated), 'success', dispatch);

    dispatch(getLabels({ filters, sortingType }));
    dispatch(closeCreateLabelPopUp());
  }

  return result;
};

export default CreateLabelSuccessMiddleware;
