import Fetcher from 'middlewares/Fetcher';
import { DocuSignIntegration, DocuSignIntegrationApi } from 'api/Core/DocuSignIntegrationApi';
import {
  GetDocuSignIntegrationAction,
  getDocuSignIntegrationSuccess,
  getDocuSignIntegrationFailure,
} from './ActionCreator';
import { ESignIntegrationsActionType } from './ActionTypes';

const GetDocuSignIntegrationMiddleware = (api: DocuSignIntegrationApi) =>
  Fetcher<DocuSignIntegration, GetDocuSignIntegrationAction>(
    ESignIntegrationsActionType.GetDocuSignIntegration,
    getDocuSignIntegrationSuccess,
    getDocuSignIntegrationFailure,
    () => {
      return api.getIntegration();
    },
  );

export default GetDocuSignIntegrationMiddleware;
