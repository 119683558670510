import React, { FC } from 'react';
import { useHistory } from 'react-router';
import styles from './DuplicateApplicationErrorNotification.module.scss';

export interface DuplicateApplicationNotificationProps {
  originalApplicationDisplayId: string;
}

const DuplicateApplicationErrorNotification: FC<DuplicateApplicationNotificationProps> = ({
  originalApplicationDisplayId,
}) => {
  const history = useHistory();

  return (
    <div>
      This application cannot be duplicated because it is not an original application.{' '}
      <span className={styles.link} onClick={() => history.push(`/los/applications/${originalApplicationDisplayId}`)}>
        Open Original Application
      </span>
    </div>
  );
};

export default DuplicateApplicationErrorNotification;
