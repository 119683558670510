import clsx from 'clsx';
import styles from './LinkButton.module.scss';
import React, { PropsWithChildren } from 'react';
import { HTMLButtonProps } from 'components/Button/Button';

interface LinkButtonProps extends HTMLButtonProps {
  className?: string;
}

const LinkButton = ({ className, children, ...restProps }: PropsWithChildren<LinkButtonProps>) => {
  return (
    <button type="button" className={clsx(styles.linkButton, className)} {...restProps}>
      {children}
    </button>
  );
};

export default LinkButton;
