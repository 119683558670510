import { AnyAction } from 'redux';

import {
  ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS,
  ADD_COLLAPSED_STRATEGY_MODULE,
  COLLAPSE_ALL_STRATEGY_MODULES,
  DELETE_DECISION_STRATEGY_MODULE_FAILURE,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST,
  DELETE_DECISION_STRATEGY_REQUEST,
  DELETE_DECISION_STRATEGY_REQUEST_FAILURE,
  DELETE_DECISION_STRATEGY_REQUEST_SUCCESS,
  EXPAND_STRATEGY_MODULE,
  GET_DECISION_STRATEGY_INFO_REQUEST,
  GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE,
  GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS,
  REMOVE_COLLAPSED_STATE,
  REMOVE_COLLAPSED_STRATEGY_MODULE,
  REORDER_MODULE_BRANCHES,
  REORDER_MODULE_BRANCHES_FAILURE,
  REORDER_MODULE_BRANCHES_SUCCESS,
  SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_FAILURE,
  SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_SUCCESS,
  SET_STRATEGY_OVERVIEW_DATA,
  StrategyArchiveStatusActionType,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS,
  UPDATE_STRATEGY_NAME_REQUEST,
  UPDATE_STRATEGY_NAME_REQUEST_FAILURE,
  UPDATE_STRATEGY_NAME_REQUEST_SUCCESS,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS,
} from './ActionTypes';

import { Strategy, StrategyModule } from './DecisionStrategiesTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ChangeStrategyArchiveStatusParams, StrategyGroupVersion } from 'api/Types';
import { StrategyInfo } from 'DecisionStrategy/DeleteDecisionStrategyMiddleware';
import { RequestError } from 'utils/fetch';
import { DeleteEntityType } from 'DeleteStrategyWizard/deleteStrategyWizardTypes';

export interface DeleteDecisionStrategyRequestAction extends AnyAction {
  payload: {
    strategyInfo: StrategyInfo;
    isCurrent : boolean;
    type: DeleteEntityType;
  };
}

export interface DeleteDecisionStrategySuccessAction extends AnyAction {
  payload: DeleteDecisionStrategyRequestAction['payload'];
}

export interface GetDecisionStrategyInfoRequestAction extends AnyAction {
  payload: string;
}

export interface GetDecisionStrategyInfoSuccessAction extends AnyAction {
  payload: Strategy;
}

export interface UpdateStrategyNameRequestAction extends AnyAction {
  payload: {
    id: string;
    name: string;
  };
}

export interface UpdateStrategyDescriptionRequestAction extends AnyAction {
  payload: {
    id: string;
    description: string;
  };
}

export interface UpdateStrategyDescriptionRequestSuccessAction extends AnyAction {
  payload: string;
}

export interface UpdateStrategyNameRequestSuccessAction extends AnyAction {
  payload: { strategyId: string; strategyName: string };
}

export interface DeleteModuleParams {
  strategyId: string;
  moduleLookupName: string;
  moduleName: string;
  moduleType: string;
  moduleIndex: number;
}

export interface DeleteDecisionStrategyModuleRequestAction extends AnyAction {
  payload: DeleteModuleParams;
}

export interface ModuleBranchReorderParams {
  strategyId: string;
  moduleKey: string;
  branchIndices: number[];
}

export interface ModuleBranchReorderAction extends AnyAction {
  type: typeof REORDER_MODULE_BRANCHES;
  payload: ModuleBranchReorderParams;
}

export interface SetStrategyDataAction extends AnyAction {
  type: typeof SET_STRATEGY_OVERVIEW_DATA;
  payload: StrategyGroupVersion;
}

export const deleteDecisionStrategyRequest = (
  strategyInfo: StrategyInfo,
  isCurrent: boolean,
  type: DeleteEntityType,
  actionOrigin?: StrategyActionOrigin,
): DeleteDecisionStrategyRequestAction => {
  return {
    type: DELETE_DECISION_STRATEGY_REQUEST,
    payload: {
      strategyInfo,
      isCurrent,
      type,
    },
    meta: { actionOrigin },
  };
};

export const deleteDecisionStrategySuccess = (
  payload: DeleteDecisionStrategyRequestAction['payload'],
): DeleteDecisionStrategySuccessAction => ({
  type: DELETE_DECISION_STRATEGY_REQUEST_SUCCESS,
  payload,
});

export const deleteDecisionStrategyError = FailedActionCreator(DELETE_DECISION_STRATEGY_REQUEST_FAILURE);

export const getDecisionStrategyInfoRequest = (strategyId: string): GetDecisionStrategyInfoRequestAction => {
  return {
    type: GET_DECISION_STRATEGY_INFO_REQUEST,
    payload: strategyId,
  };
};

export const getDecisionStrategyInfoSuccess = (strategyInfo: Strategy): GetDecisionStrategyInfoSuccessAction => {
  return {
    type: GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS,
    payload: strategyInfo,
  };
};

export const getDecisionStrategyInfoError = FailedActionCreator(GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE);

export const setStrategyData = (strategyData: StrategyGroupVersion): SetStrategyDataAction => {
  return {
    type: SET_STRATEGY_OVERVIEW_DATA,
    payload: strategyData,
  };
};

export const updateStrategyNameRequest = (id: string, name: string): UpdateStrategyNameRequestAction => {
  return {
    type: UPDATE_STRATEGY_NAME_REQUEST,
    payload: {
      id,
      name,
    },
  };
};

export const updateStrategyDescriptionRequest = (
  id: string,
  description: string,
): UpdateStrategyDescriptionRequestAction => {
  return {
    type: UPDATE_STRATEGY_DESCRIPTION_REQUEST,
    payload: {
      id,
      description,
    },
  };
};

export const updateStrategyNameSuccess = (payload: { strategyId: string; strategyName: string }) => {
  return {
    type: UPDATE_STRATEGY_NAME_REQUEST_SUCCESS,
    payload,
  };
};

export const updateStrategyDescriptionSuccess = (payload: string) => {
  return {
    type: UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS,
    payload,
  };
};

export const updateStrategyDescriptionFailure = FailedActionCreator(UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE);

export const updateStrategyNameFailure = FailedActionCreator(UPDATE_STRATEGY_NAME_REQUEST_FAILURE);

export const deleteDecisionStrategyModuleRequest = (
  payload: DeleteModuleParams,
): DeleteDecisionStrategyModuleRequestAction => {
  return {
    type: DELETE_DECISION_STRATEGY_MODULE_REQUEST,
    payload,
  };
};

export const deleteDecisionStrategyModuleSuccess = (params: DeleteModuleParams) => {
  return {
    type: DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS,
    payload: params,
  };
};

export const deleteDecisionStrategyModuleFailure = FailedActionCreator(DELETE_DECISION_STRATEGY_MODULE_FAILURE);

export interface ModuleReorderAction extends AnyAction {
  type: typeof ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST;
  payload: {
    strategyId: string;
    modulesList: StrategyModule[];
    indices: number[];
  };
}

export const reorderModules = (
  strategyId: string,
  modulesList: StrategyModule[],
  indices: number[],
): ModuleReorderAction => {
  return {
    type: ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
    payload: {
      strategyId,
      modulesList,
      indices,
    },
  };
};

export interface ModuleReorderSuccessAction {
  type: typeof ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS;
  payload: {
    strategyId: string;
  };
}

export const reorderModulesSuccess = (strategyId: string): ModuleReorderSuccessAction => ({
  type: ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS,
  payload: { strategyId },
});

export const reorderModulesFailure = FailedActionCreator(ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE);

export const setDecisionStrategyModuleInfoRequestSuccess = () => {
  return {
    type: SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_SUCCESS,
  };
};

export const setDecisionStrategyModuleInfoRequestError = FailedActionCreator(
  SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_FAILURE,
);

export interface DecisionStrategiesSetSearchInput extends AnyAction {
  type: typeof ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT;
  payload: string;
}

export const setDecisionStrategiesSearchInput = (searchInput: string): DecisionStrategiesSetSearchInput => ({
  type: ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT,
  payload: searchInput,
});

export const reorderModuleBranches = (strategyId: string, moduleKey: string, branchIndices: number[]): AnyAction => {
  return {
    type: REORDER_MODULE_BRANCHES,
    payload: {
      strategyId,
      moduleKey,
      branchIndices,
    },
  };
};

export const moduleBranchesReorderSuccess = (params: ModuleBranchReorderParams): AnyAction => {
  return {
    type: REORDER_MODULE_BRANCHES_SUCCESS,
    payload: params,
  };
};

export const moduleBranchesReorderFailure = (): AnyAction => {
  return {
    type: REORDER_MODULE_BRANCHES_FAILURE,
  };
};

export interface CollapseAllStrategyModulesAction extends AnyAction {
  type: typeof COLLAPSE_ALL_STRATEGY_MODULES;
  payload: {
    strategyId: string;
    modulesCount: number;
  };
}

export const collapseAllStrategyModules = (
  strategyId: string,
  modulesCount: number,
): CollapseAllStrategyModulesAction => ({
  type: COLLAPSE_ALL_STRATEGY_MODULES,
  payload: {
    strategyId,
    modulesCount,
  },
});

export interface ChangeCollapseStateStrategyModule extends AnyAction {
  type: typeof EXPAND_STRATEGY_MODULE;
  payload: {
    strategyId: string;
    moduleIndex: number;
    isCollapsed: boolean;
  };
}

export const changeStrategyModuleCollapseState = (
  strategyId: string,
  moduleIndex: number,
  isCollapsed: boolean,
): ChangeCollapseStateStrategyModule => ({
  type: EXPAND_STRATEGY_MODULE,
  payload: {
    strategyId,
    moduleIndex,
    isCollapsed,
  },
});

export interface RemoveCollapsedStrategyModule extends AnyAction {
  type: typeof REMOVE_COLLAPSED_STRATEGY_MODULE;
  payload: {
    strategyId: string;
    moduleIndex: number;
    lookupName: string;
  };
}

export const removeCollapsedStrategyModule = (
  strategyId: string,
  moduleIndex: number,
  lookupName: string,
): RemoveCollapsedStrategyModule => ({
  type: REMOVE_COLLAPSED_STRATEGY_MODULE,
  payload: {
    strategyId,
    moduleIndex,
    lookupName,
  },
});

export interface AddCollapsedStrategyModule extends AnyAction {
  type: typeof ADD_COLLAPSED_STRATEGY_MODULE;
  payload: {
    strategyId: string;
  };
}

export const addCollapsedStrategyModule = (strategyId: string): AddCollapsedStrategyModule => ({
  type: ADD_COLLAPSED_STRATEGY_MODULE,
  payload: {
    strategyId,
  },
});

export interface RemoveCollapsedStateAction extends AnyAction {
  type: typeof REMOVE_COLLAPSED_STATE;
  payload: string;
}

export const removeCollapsedState = (payload: string): RemoveCollapsedStateAction => ({
  type: REMOVE_COLLAPSED_STATE,
  payload,
});

export interface RestoreStrategiesAction extends AnyAction {
  type: typeof StrategyArchiveStatusActionType.RestoreStrategy;
  payload: ChangeStrategyArchiveStatusParams;
}

export interface ArchiveStrategiesAction extends AnyAction {
  type: typeof StrategyArchiveStatusActionType.ArchiveStrategy;
  payload: ChangeStrategyArchiveStatusParams;
}

export enum StrategyActionOrigin {
  StrategiesGroupsList = 'StrategiesGroupsList',
  StrategyOverviewVersionsList = 'StrategyOverviewVersionsList',
  StrategyGroupVersionsList = 'StrategyGroupVersionsList',
  VersionOverview = 'VersionOverview',
}

export const archiveStrategies = (
  payload: ChangeStrategyArchiveStatusParams,
  actionOrigin: StrategyActionOrigin,
): ArchiveStrategiesAction => {
  return {
    type: StrategyArchiveStatusActionType.ArchiveStrategy,
    payload,
    meta: { actionOrigin },
  };
};

export const archiveStrategiesSuccess = (payload: ChangeStrategyArchiveStatusParams): AnyAction => ({
  type: StrategyArchiveStatusActionType.ArchiveStrategySuccess,
  payload,
});

export const archiveStrategiesFailure = (payload: RequestError): AnyAction => {
  return {
    type: StrategyArchiveStatusActionType.ArchiveStrategyFailure,
    payload,
  };
};

export const restoreStrategies = (payload: ChangeStrategyArchiveStatusParams, actionOrigin: StrategyActionOrigin) => ({
  type: StrategyArchiveStatusActionType.RestoreStrategy,
  payload,
  meta: {
    actionOrigin,
  },
});

export const restoreStrategiesSuccess = (payload: ChangeStrategyArchiveStatusParams): AnyAction => ({
  type: StrategyArchiveStatusActionType.RestoreStrategySuccess,
  payload,
});

export const restoreStrategiesFailure = (): AnyAction => ({
  type: StrategyArchiveStatusActionType.RestoreStrategyFailure,
});
