import React, { FC, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DeleteRulePopUpState } from './Types';
import { closeDeleteRulePopUp, deleteRule } from './ActionCreator';
import { useQueryParams } from 'hooks/useQueryParam';
import DeletePopUp from 'components/DeletePopup';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import { EntityType } from 'RuleCreate/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const TITLE = 'Delete Rule';
const QUESTION_TEXT = 'Do you want to permanently delete this rule?';

interface DeleteRulePopUpProps {
  strategyId: string;
}

const DeleteRulePopUp: FC<DeleteRulePopUpProps> = ({ strategyId }) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();

  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, ruleIndex, entityType }: DeleteRulePopUpState = useSelector(
    (state: ReduxState) => state.deleteRulePopUp,
  );

  if (!isOpen) return null;

  const closePopUp = () => {
    dispatch(closeDeleteRulePopUp());
  };

  const onDeleteRule = async () => {
    const moduleKey = params.get('module-branch-key')!;
    const branchIndex = parseInt(params.get('branchIndex')!, 10);
    setIsLoading(true);
    try {
      await batch(async () => {
        await dispatchRoutine(
          deleteRule({ ruleIndex, moduleKey, branchIndex, strategyId, entityType: entityType as EntityType }),
        );
        await dispatchRoutine(closeDeleteRulePopUp());
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={TITLE} closable={!isLoading}>
      <DeletePopUp
        handleDelete={onDeleteRule}
        handleClose={closePopUp}
        message={QUESTION_TEXT}
        isLoading={isLoading}
        cancelBtnDisabled={isLoading}
      />
    </PopUpWizard>
  );
};

export default DeleteRulePopUp;
