import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { ApplicationNote } from 'api/Types';
import { ApplicationNotesSortingType } from 'api/LoanOriginationSystem/ApplicationNotesApi';

export const APPLICATION_NOTES_PER_PAGE_DEFAULT = 20;

export interface ApplicationNotesPaginationParams {
  applicationId: string;
  search: string;
  sortingType: ApplicationNotesSortingType;
}

const applicationNotesPagination = Pagination<ReduxState, ApplicationNote, ApplicationNotesPaginationParams>(
  ['loanOriginationSystemApplicationPage', 'notes'],
  APPLICATION_NOTES_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default applicationNotesPagination;
