import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { ReduxState } from 'types/redux';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import {
  changeContextualViewStep,
  deleteBorrower,
  selectBorrowerViewTab,
  setBorrowerToDeleteId,
  getBorrowerToDeleteApplications,
} from 'LoanOriginationSystemBorrowers/ActionCreator';
import { setBorrowerToUnlock, unlockBorrower } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from 'LoanOriginationSystemBorrowers/ActionTypes';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import BorrowersDashboard from 'components/LoanOriginationSystem/BorrowersDashboard';
import CreateBorrower from 'components/LoanOriginationSystem/CreateBorrower';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import EditBorrower from 'components/LoanOriginationSystem/BorrowerDetails';
import UnlockCustomerPopup from 'components/LoanOriginationSystem/CreateApplication/UnlockCustomerPopup';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import styles from './Borrowers.module.scss';
import NavigationLinkId from 'enums/NavigationLinkId';
import BorrowersFilter from 'components/LoanOriginationSystem/BorrowersDashboard/BorrowersFilter';
import EmailView from 'components/LoanOriginationSystem/EmailView';
import { getOrganizationEmail } from 'utils/emailGetters';
import EmailNotFound from 'components/LoanOriginationSystem/EmailView/EmailNotFound';
import DeleteEmailConfirmationPopup from 'components/LoanOriginationSystem/DeleteEmailConfirmationPopup/DeleteEmailConfirmationPopup';
import { closeDeleteEmailPopup, deleteEmail, getEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import ContextualViewWizard from 'components/ContextualViewWizard';
import { useHistory } from 'react-router-dom';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';
import { resetBorrowerDetails } from 'LoanOriginationSystemBorrowerDetails/ActionCreator';
import { EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER } from 'components/LoanOriginationSystem/BorrowerDetails/Tabs/BorrowerEmails/BorrowerEmails';
import prolongRequest from 'utils/prolongRequest';

const BORROWER_APPLICATIONS_MIN_REQUEST_TIME = 500;

const leftNav = makeLeftNavigation(NavigationLinkId.Borrowers, ApplicationSectionName.LoanOriginationSystem);

const Borrowers = () => {
  const params = useQueryParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const closeContextualView = useCloseContextualView();
  const [createBorrowerDataWasChanged, setCreateBorrowerDataWasChanged] = useState(false);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const { isDeleting, borrowerToDeleteId, filters, contextualViewStep } = useSelector((state: ReduxState) => ({
    isDeleting: state.loanOriginationSystemBorrowers.isDeleting,
    borrowerToDeleteId: state.loanOriginationSystemBorrowers.borrowerToDeleteId,
    filters: state.loanOriginationSystemBorrowers.filters,
    contextualViewStep: state.loanOriginationSystemBorrowers.contextualViewStep,
  }));
  const { isUnlockingInProgress, borrowerToUnlock } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemBorrowers.unlockBorrower,
  );
  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);
  const {
    items: emailList,
    emailIdToDelete,
    deleteEmailInProgress,
    filters: emailsFilters,
    sortingType: emailsSortingType,
    itemsTotal: emailItemsTotal,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  useStateReset(LoanOriginationSystemBorrowersActionTypes.ResetState);

  const createBorrowerFormAvailable = params.has('new');
  const editBorrowerFormAvailable = params.has('edit');
  const editBorrowerId = params.get('edit');
  const borrowerEmailId = params.get('borrowerEmailId');

  const filtersWithBorrowerId = {
    ...emailsFilters,
    selectedBorrower: editBorrowerId,
  };

  useEffect(() => {
    if (!createBorrowerFormAvailable || !editBorrowerFormAvailable) {
      setCreateBorrowerDataWasChanged(false);
    }
  }, [createBorrowerFormAvailable, editBorrowerFormAvailable]);

  useEffect(() => {
    if (emailItemsTotal === undefined) {
      dispatch(getEmails(filtersWithBorrowerId, emailsSortingType));
    }
  }, [borrowerEmailId]);

  const onComponentLeave = () => {
    dispatch(resetBorrowerDetails());
  };

  const confirmBorrowerDelete = () => {
    if (borrowerToDeleteId) {
      dispatch(deleteBorrower(borrowerToDeleteId));
    }
  };

  const onCloseContextualView = () => {
    if (createBorrowerDataWasChanged) {
      setUnsavedChangesPopupOpen(true);

      return;
    }

    dispatch(changeContextualViewStep(0));
    closeContextualView();
  };

  const onChangeContextualViewStep = () => {
    history.replace(`?edit=${editBorrowerId}`);
    batch(() => {
      dispatch(changeContextualViewStep(contextualViewStep - 1));
      dispatch(selectBorrowerViewTab(BorrowerDetailsTab.Emails));
    });
  };

  const prolongedGetBorrowerToDeleteApplications = prolongRequest((id: string) => {
    return dispatchRoutine(getBorrowerToDeleteApplications(id));
  }, BORROWER_APPLICATIONS_MIN_REQUEST_TIME);

  const onBorrowerDelete = async (id: string) => {
    await prolongedGetBorrowerToDeleteApplications(id);

    dispatch(setBorrowerToDeleteId(id));
  };

  const handleUnlockBorrowerPopupClose = () => dispatch(setBorrowerToUnlock(null));
  const handleBorrowerUnlock = () => {
    if (!borrowerToUnlock) {
      return;
    }

    dispatch(unlockBorrower(borrowerToUnlock.id));
  };

  const handleCloseDeleteEmailPopup = () => dispatch(closeDeleteEmailPopup());
  const handleDeleteEmail = async () => {
    await dispatchRoutine(deleteEmail(emailIdToDelete!));
    closeContextualView();
  };

  const renderContextualView = () => {
    if (createBorrowerFormAvailable) {
      return (
        <CreateBorrower
          setDataWasChanged={(changed) => setCreateBorrowerDataWasChanged(changed)}
          onClose={onCloseContextualView}
        />
      );
    }

    if (editBorrowerFormAvailable) {
      return (
        <EditBorrower
          id={params.get('edit')!}
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateBorrowerDataWasChanged(changed)}
        />
      );
    }

    return null;
  };

  const renderEmailView = () => {
    if (borrowerEmailId && emailList.length) {
      const currentEmailInfo = emailList.find((email) => email.id === borrowerEmailId);
      return currentEmailInfo ? (
        <EmailView
          currentEmailInfo={currentEmailInfo}
          onClose={onChangeContextualViewStep}
          organizationEmail={getOrganizationEmail(organizationInfo.id)}
          className={styles.emailViewContainer}
          isWizardView
        />
      ) : (
        <EmailNotFound onClose={onChangeContextualViewStep} className={styles.emailViewContainer} />
      );
    }

    return null;
  };

  const renderContextualViewWizard = () => {
    if (createBorrowerFormAvailable || editBorrowerFormAvailable || borrowerEmailId) {
      if (borrowerEmailId && contextualViewStep !== EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER) {
        dispatch(changeContextualViewStep(EMAIL_DETAILS_CONTEXTUAL_VIEW_STEP_NUMBER));
      }

      if (borrowerEmailId && emailItemsTotal === undefined) {
        return null;
      }

      return (
        <ContextualViewWizard
          step={contextualViewStep}
          onComponentLeave={onComponentLeave}
          width={styles.contextualViewWizard}
        >
          {renderContextualView()}
          {renderEmailView()}
        </ContextualViewWizard>
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <BorrowersFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      isCompany={filters.isCompany}
      isPerson={filters.isPerson}
    />
  );

  const renderOverlay = () => {
    if (unsavedChangesPopupOpen) {
      return (
        <UnsavedChangesPopup
          onPopupClose={() => setUnsavedChangesPopupOpen(false)}
          onLeaveClick={() => {
            closeContextualView();
            setUnsavedChangesPopupOpen(false);
          }}
        />
      );
    }

    if (borrowerToDeleteId) {
      return (
        <ConfirmPopup
          title="Delete Borrower"
          message="Are you sure you want to delete this borrower?"
          confirmText="Yes, Delete Borrower"
          declineText="No, Go Back"
          onPopupClose={() => dispatch(setBorrowerToDeleteId(null))}
          onConfirmClick={confirmBorrowerDelete}
          loading={isDeleting}
        />
      );
    }

    if (borrowerToUnlock) {
      return (
        <UnlockCustomerPopup
          onPopupClose={handleUnlockBorrowerPopupClose}
          onUnlockClick={handleBorrowerUnlock}
          isUnlockingInProgress={isUnlockingInProgress}
        />
      );
    }

    if (emailIdToDelete) {
      return (
        <DeleteEmailConfirmationPopup
          onConfirmClick={handleDeleteEmail}
          onPopupClose={handleCloseDeleteEmailPopup}
          loading={deleteEmailInProgress}
        />
      );
    }

    return null;
  };

  return (
    <>
      <MainLayout
        leftNav={leftNav}
        rightSidePopupView={rightSidePopupView}
        closeContextualView={onCloseContextualView}
        contextualView={renderContextualViewWizard()}
        overlay={renderOverlay()}
      >
        <PageWrapper>
          <PageContent className={styles.pageContent} noPadding>
            <BorrowersDashboard onBorrowerDelete={onBorrowerDelete} />
          </PageContent>
        </PageWrapper>
      </MainLayout>
    </>
  );
};

export default Borrowers;
