import {
  OPEN_GENERATE_FIRST_SECRET_WIZARD,
  CLOSE_GENERATE_FIRST_SECRET_WIZARD,
  NEXT_STEP_GENERATE_FIRST_SECRET,
} from './constants';

export const openGenerateFirstSecretWizard = () => {
  return {
    type: OPEN_GENERATE_FIRST_SECRET_WIZARD,
  };
};

export const closeGenerateFirstSecretWizard = () => {
  return {
    type: CLOSE_GENERATE_FIRST_SECRET_WIZARD,
  };
};

export const nextStep = () => {
  return {
    type: NEXT_STEP_GENERATE_FIRST_SECRET,
  };
};
