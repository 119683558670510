import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  CreateApplicationAction,
  createApplicationFailed,
  createApplicationSuccess,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { CREATE_APPLICATION } from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import ApplicationAttribute from './ApplicationAttribute';

const CreateApplicationMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, CreateApplicationAction, {}, Error, string>(
    CREATE_APPLICATION,
    createApplicationSuccess,
    createApplicationFailed,
    (action) => {
      const { applicationDetailsFormData, borrower, intermediary, coBorrower, productId } = action.payload;

      return api.createApplication(productId, borrower, coBorrower, intermediary, applicationDetailsFormData);
    },
    ApplicationAttribute.ApplicationId,
    (response) => response.displayId,
  );

export default CreateApplicationMiddleware;
