import React, { FC } from 'react';
import UserAvatar, { UserAvatarProps } from 'components/UserAvatar/UserAvatar';

interface UnknownUserAvatarProps extends Omit<UserAvatarProps, 'firstName' | 'lastName'> {
  firstName?: string;
  lastName?: string;
}

const UnknownUserAvatar: FC<UnknownUserAvatarProps> = (props) => {
  const { firstName, lastName } = props;

  return <UserAvatar firstName={firstName || 'Unknown'} lastName={lastName || 'User'} {...props} />;
};

export default UnknownUserAvatar;
