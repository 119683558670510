import { getProductDataByViewType } from 'LoanOriginationSystemApplications/ActionCreator';
import { ChangePopupFiltersAction } from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { CHANGE_POPUP_FILTERS } from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === CHANGE_POPUP_FILTERS;

const onFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action)) {
    const { loanOriginationSystemApplications } = getState();

    dispatch(
      getProductDataByViewType(
        loanOriginationSystemApplications.selectedProduct!.id,
        action.payload.viewType,
        {
          createdDateRange: action.payload.createdDateRange,
          updatedDateRange: action.payload.updatedDateRange,
          selectedIntermediaries: action.payload.intermediaries,
          selectedStatusesIds: action.payload.statusesIds,
          selectedLabels: action.payload.labels,
          selectedMembers: loanOriginationSystemApplications.filters.selectedMembers,
          searchInputValue: loanOriginationSystemApplications.filters.searchInputValue,
        },
        action.payload.viewType === ViewType.Column
          ? loanOriginationSystemApplications.columnViewSortingType
          : loanOriginationSystemApplications.tableViewSortingType,
      ),
    );
  }

  return result;
}) as Middleware;

export default onFiltersChangeMiddleware;
