import React, { FC, useEffect, useState } from 'react';
import styles from './FilePreview.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import { EmptyDocumentsImage, DocumentImage, DownloadImage } from 'static/images';
import Button from 'components/Button';
import { useHistory } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';
import { downloadFile } from 'utils/downloadFile';
import clsx from 'clsx';
import ImagePreview from './ImagePreview';

interface FilePreviewProps {
  fileExtension: DocumentExtension;
  filename: string;
  previewLink?: string;
}

const GOOGLE_DOCS_EXTENSIONS = [
  DocumentExtension.Pdf,
  DocumentExtension.Ppt,
  DocumentExtension.Pptx,
  DocumentExtension.Txt,
  DocumentExtension.Word,
  DocumentExtension.Doc,
  DocumentExtension.Docx,
  DocumentExtension.Xls,
  DocumentExtension.Xlsx,
  DocumentExtension.Csv,
];

const IMAGE_EXTENSIONS = [
  DocumentExtension.Gif,
  DocumentExtension.Jpeg,
  DocumentExtension.Jpg,
  DocumentExtension.Png,
  DocumentExtension.Svg,
];

const isSupportedDocumentExtension = (extension: DocumentExtension) => GOOGLE_DOCS_EXTENSIONS.includes(extension)
  || IMAGE_EXTENSIONS.includes(extension);

const FilePreview: FC<FilePreviewProps> = ({ fileExtension, previewLink, filename }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [noPreviewAvailable, setNoPreviewAvailable] = useState(!isSupportedDocumentExtension(fileExtension));

  const handleLoadSuccess = () => {
    setIsLoading(false);
  };

  const handleLoadFailure = () => {
    setIsLoading(false);
    setNoPreviewAvailable(true);
  };

  useEffect(() => {
    setIsLoading(!previewLink || isSupportedDocumentExtension(fileExtension));
  }, [previewLink]);

  const renderFilePreview = () => {
    if (!noPreviewAvailable && GOOGLE_DOCS_EXTENSIONS.includes(fileExtension)) {
      const url = `https://docs.google.com/gview?url=${previewLink}&embedded=true`;

      return (
        <iframe
          title={filename}
          src={url}
          onLoad={handleLoadSuccess}
          onError={handleLoadFailure}
          className={styles.filePreview}
        />
      );
    }

    if (!noPreviewAvailable && IMAGE_EXTENSIONS.includes(fileExtension)) {
      return (
        <ImagePreview
          previewLink={previewLink!}
          filename={filename}
          onLoad={handleLoadSuccess}
          onError={handleLoadFailure}
        />
      );
    }

    return (
      <>
        <EmptyDocumentsImage />
        <p>No preview is available.</p>
        <Button
          kind="secondary"
          className={styles.downloadButton}
          onClick={handleDownloadFile}
          disabled={!previewLink}
        >
          <DownloadImage className={styles.downloadImage} />
          Download
        </Button>
      </>
    );
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleDownloadFile = () => {
    downloadFile(previewLink!, filename);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ButtonWithImage title="Back" kind="back" onClick={handleGoBack} />
        <h6 className={styles.fileName}>{filename}</h6>
        <ButtonWithImage
          title="Download"
          kind="download"
          onClick={handleDownloadFile}
          disabled={!previewLink}
        />
      </div>
      {isLoading && <StylesProvider injectFirst>
          <LinearProgress className={styles.progressBar}/>
        </StylesProvider>}
      <div className={styles.body}>
        {isLoading && <div className={styles.fileContainer}>
          <DocumentImage/>
          <p>Preparing File For Viewing</p>
          {!!previewLink && <ButtonWithImage
            className={styles.downloadLinkBtn}
            title="Download"
            kind="download"
            onClick={handleDownloadFile}
          />}
        </div>}
        <div className={clsx(styles.fileContainer, isLoading && styles.hidden)}>
          {previewLink && renderFilePreview()}
        </div>
      </div>
    </div>
  );
};

export default FilePreview;
