import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ProcessAutomationActionType } from './ActionTypes';
import { getAutomatedProcesses, SelectGroupAction } from './ActionCreator';

const isSelectGroupAction = (action: AnyAction): action is SelectGroupAction =>
  action.type === ProcessAutomationActionType.SelectGroup;

const SelectGroupMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSelectGroupAction(action)) {
    const { loanOriginationSystemAutomatedProcesses } = getState();
    const { page, itemsPerPage, group, category } = loanOriginationSystemAutomatedProcesses;

    dispatch(getAutomatedProcesses({ page, itemsPerPage, group, category }));
  }

  return result;
};

export default SelectGroupMiddleware;
