import React, { useEffect, useRef, useState } from 'react';
import TabSwitch, { TabSwitchOption, TabSwitchSkeleton } from 'components/TabSwitch';
import styles from './EditApplication.module.scss';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import CreateApplicationForm from 'components/LoanOriginationSystem/CreateApplication/CreateApplicationForm';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import Button from 'components/Button';
import {
  addApplicationCoborrower,
  addApplicationIntermediary,
  updateApplication,
  updateApplicationTeamMembers,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { updateBorrower } from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import { updateIntermediary } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import isFormDataWasChanged from 'components/LoanOriginationSystem/EditApplication/isFormDataWasChanged';
import EditFormDataPopup from 'components/LoanOriginationSystem/EditFormDataPopup';
import {
  getBorrowerVariableConfigurations,
  getIntermediaryVariableConfigurations,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { useConfigurableFormValidation } from 'components/ConfigurableForm';
import BorrowerDefaultVariable, { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import useStandardVariables from 'hooks/useStandardVariables';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import useTabs from './useTabs';
import { debounce } from 'lodash';
import { REQUEST_DELAY_MS } from 'middlewares/Debouncer';
import { Borrower, BorrowerSuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Dispatch } from 'redux';
import difference from 'utils/difference';
import {
  loadBorrowersSuggestions,
  loadIntermediarySuggestions,
  selectCoBorrower,
  selectIntermediary,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import {
  getApplicationVariableConfigurationsSelector,
  getBorrowerVariableConfigurationsSelector,
  getIntermediaryVariableConfigurationsSelector,
} from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import {
  formatDefaultFieldsDisplayTitle as formatBorrowerTitle,
  getBorrowerFullName,
} from 'LoanOriginationSystemBorrowers/utils';
import { formatDefaultFieldsDisplayTitle as formatIntermediaryTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { EditFormDataPopupProps } from 'components/LoanOriginationSystem/EditFormDataPopup/EditFormDataPopup';
import ApplicationContextualViewLayout from 'components/LoanOriginationSystem/CreateApplication/ApplicationContextualViewLayout';
import { getApplicationVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';

interface EditApplicationProps {
  onClose: () => void;
  setDataWasChanged: (changed: boolean) => void;
  onDeleteCoborrower: () => void;
  onDeleteIntermediary: () => void;
  onReset: (step: EditApplicationStep, showUnsavedChangesPopup: boolean) => void;
  initialStep: EditApplicationStep;
}

type EditPopupFormData = Omit<EditFormDataPopupProps, 'isUpdatingInProgress' | 'onPopupClose' | 'onSubmit'>;

const FORM_TABS_TAB_INDEX = 99;
const DEFAULT_SKELETON_TABS_COUNT = 4;

const EditApplication = ({
  onClose,
  setDataWasChanged,
  onDeleteCoborrower,
  onDeleteIntermediary,
  onReset,
  initialStep,
}: EditApplicationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { applicationData, isEditing, isTeamMembersUpdatingInProgress, product } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage,
  );
  const { borrowerSuggestions, intermediarySuggestions, selectedCoBorrower, selectedIntermediary } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplications.createApplication,
  );
  const standardVariables = useStandardVariables();

  const [isEditFormDataPopupUpdateInProgress, setIsEditFormDataPopupUpdateInProgress] = useState(false);
  const [borrowerFormData, setBorrowerFormData] = useState({});
  const [coBorrowerFormData, setCoBorrowerFormData] = useState({});
  const [intermediaryFormData, setIntermediaryFormData] = useState({});
  const [applicationDetailsFormData, setApplicationDetailsFormData] = useState({});
  const [editPopupFormData, setEditPopupFormData] = useState<EditPopupFormData | null>(null);

  const borrowerVariableConfigurations = useSelector((state: ReduxState) => {
    return applicationData
      ? getBorrowerVariableConfigurationsSelector(state, {
          borrowerType: applicationData.borrowerType,
          productId: applicationData.product.id,
        })
      : null;
  });

  const intermediaryVariableConfigurations = useSelector((state: ReduxState) => {
    return applicationData
      ? getIntermediaryVariableConfigurationsSelector(state, { productId: applicationData.product.id })
      : null;
  });

  const applicationVariableConfigurations = useSelector((state: ReduxState) => {
    return applicationData
      ? getApplicationVariableConfigurationsSelector(state, { productId: applicationData.product.id })
      : null;
  });

  const validateBorrowerFormData = useConfigurableFormValidation(
    applicationData?.borrowerType && borrowerVariableConfigurations,
    true,
  );

  const validateIntermediaryData = useConfigurableFormValidation(intermediaryVariableConfigurations, true);

  const getBorrowerFormData = (application: Application, borrowerDefaultVariable: BorrowerDefaultVariableType) => {
    return Object.keys(BorrowerDefaultVariable).reduce(
      (data, key) => ({
        ...data,
        [BorrowerDefaultVariable[key]]: application.variables[borrowerDefaultVariable[key]],
      }),
      {},
    );
  };

  const getIntermediaryFromData = (application: Application) => {
    return Object.keys(IntermediaryDefaultVariable).reduce(
      (data, key) => ({
        ...data,
        [IntermediaryDefaultVariable[key]]: application.variables[IntermediaryDefaultVariable[key]],
      }),
      {},
    );
  };

  const [tabs, selectedTab, setSelectedTab, isAddingCoborrower, isAddingIntermediary] = useTabs(
    applicationData,
    product,
    initialStep,
  );

  const createCoborrower = async () => {
    await dispatchRoutine(
      addApplicationCoborrower(
        applicationData!.id,
        selectedCoBorrower?.id,
        selectedCoBorrower ? undefined : coBorrowerFormData,
      ),
    );

    const borrowerName = getBorrowerFullName(
      applicationData!.borrowerType,
      selectedCoBorrower?.variables || coBorrowerFormData,
    );

    onReset(EditApplicationStep.CoBorrower, false);

    notification.createNotification(
      getMessage(MessageType.ApplicationCoborrowerAdded, { borrowerName }),
      'success',
      dispatch,
    );
  };

  const createIntermediary = async () => {
    await dispatchRoutine(
      addApplicationIntermediary(
        applicationData!.id,
        selectedIntermediary?.id,
        selectedIntermediary ? undefined : intermediaryFormData,
      ),
    );

    const intermediaryName = (selectedIntermediary?.variables || intermediaryFormData)[
      ApplicationDefaultVariable.Intermediary.Name
    ];

    onReset(EditApplicationStep.Intermediary, false);

    notification.createNotification(
      getMessage(MessageType.ApplicationIntermediaryAdded, { intermediaryName }),
      'success',
      dispatch,
    );
  };

  const saveChanges = async () => {
    if (isAddingCoborrower) {
      await createCoborrower();

      return;
    }

    if (isAddingIntermediary) {
      await createIntermediary();
    }
  };

  const getBorrowerConfigurations = () => {
    if (!borrowerVariableConfigurations && applicationData) {
      dispatch(getBorrowerVariableConfigurations(applicationData.borrowerType, applicationData.product.id));
    }
  };

  const getIntermediaryConfigurations = () => {
    if (
      selectedTab.label === EditApplicationStep.Intermediary &&
      !intermediaryVariableConfigurations &&
      applicationData
    ) {
      dispatch(getIntermediaryVariableConfigurations(applicationData.product.id));
    }
  };

  const getApplicationConfigurations = () => {
    if (
      selectedTab.label === EditApplicationStep.ApplicationDetails &&
      !applicationVariableConfigurations &&
      applicationData
    ) {
      dispatch(getApplicationVariableConfigurations(applicationData.product.id));
    }
  };

  useEffect(() => {
    if (applicationData) {
      setBorrowerFormData(getBorrowerFormData(applicationData, ApplicationDefaultVariable.Borrower));
      setApplicationDetailsFormData(applicationData.variables);

      if (applicationData.coborrowerId) {
        setCoBorrowerFormData(getBorrowerFormData(applicationData, ApplicationDefaultVariable.Coborrower));
      }

      if (applicationData.intermediaryId) {
        setIntermediaryFormData(getIntermediaryFromData(applicationData));
      }
    }
  }, [applicationData]);

  useEffect(() => {
    getBorrowerConfigurations();
  }, [applicationData, borrowerVariableConfigurations]);

  useEffect(() => {
    getIntermediaryConfigurations();
  }, [selectedTab, intermediaryVariableConfigurations, applicationData]);

  useEffect(() => {
    getApplicationConfigurations();
  }, [selectedTab]);

  const isSaveButtonDisabled = () => {
    if (selectedTab.label === EditApplicationStep.CoBorrower && isAddingCoborrower) {
      const isCoBorrowerFormDataValid = selectedCoBorrower
        ? validateBorrowerFormData(selectedCoBorrower.variables)
        : validateBorrowerFormData(coBorrowerFormData);

      return !borrowerVariableConfigurations || !isCoBorrowerFormDataValid;
    }

    if (selectedTab.label === EditApplicationStep.Intermediary && isAddingIntermediary) {
      const isIntermediaryFormDataValid = selectedIntermediary
        ? validateIntermediaryData(selectedIntermediary.variables)
        : validateIntermediaryData(intermediaryFormData);

      return !intermediaryVariableConfigurations || !isIntermediaryFormDataValid;
    }

    return true;
  };

  useEffect(() => {
    if (isAddingCoborrower) {
      setDataWasChanged(!!selectedCoBorrower || Object.values(coBorrowerFormData).some((value) => !!value));

      return;
    }

    if (isAddingIntermediary) {
      setDataWasChanged(!!selectedIntermediary || Object.values(intermediaryFormData).some((value) => !!value));

      return;
    }

    const formDataWasChanged = isFormDataWasChanged(
      applicationData,
      borrowerFormData,
      coBorrowerFormData,
      intermediaryFormData,
      applicationDetailsFormData,
      selectedTab.label as EditApplicationStep,
    );

    setDataWasChanged(!!formDataWasChanged);
  }, [
    applicationData,
    borrowerFormData,
    coBorrowerFormData,
    intermediaryFormData,
    applicationDetailsFormData,
    selectedTab,
    isAddingCoborrower,
    isAddingIntermediary,
  ]);

  const shouldRenderDeleteButton =
    (!isAddingCoborrower && selectedTab.label === EditApplicationStep.CoBorrower) ||
    (!isAddingIntermediary && selectedTab.label === EditApplicationStep.Intermediary);

  const handleDelete = () => {
    if (selectedTab.label === EditApplicationStep.CoBorrower) {
      onDeleteCoborrower();
    }

    if (selectedTab.label === EditApplicationStep.Intermediary) {
      onDeleteIntermediary();
    }
  };

  useEffect(() => {
    const currentTab = tabs.find(({ label }) => label === selectedTab.label);

    if (!currentTab) {
      setSelectedTab(tabs[0]);
    }
  }, [tabs]);

  const onLoadBorrowerSuggestions = useRef(
    debounce((filter: BorrowerSuggestionFilter, dispatchAction: Dispatch<ReduxState>) => {
      dispatchAction(loadBorrowersSuggestions(filter));
    }, REQUEST_DELAY_MS),
  );

  const onLoadIntermediarySuggestions = useRef(
    debounce((filter: IntermediarySuggestionFilter, dispatchAction: Dispatch<ReduxState>) => {
      dispatchAction(loadIntermediarySuggestions(filter));
    }, REQUEST_DELAY_MS),
  );

  const handleUnlockCustomerClick = (borrower: Borrower) => {
    dispatch(setBorrowerToUnlock(borrower));
  };

  const handleCoBorrowerSelect = (borrower: Borrower | null) => {
    dispatch(selectCoBorrower(borrower));
  };

  const handleIntermediarySelect = (intermediary: Intermediary | null) => {
    dispatch(selectIntermediary(intermediary));
  };

  const getSubmitEditFormDataAction = (formData: FormLayoutData) => {
    if (!applicationData) {
      return null;
    }

    if (selectedTab.label === EditApplicationStep.Borrower) {
      return updateApplication({
        id: applicationData.id,
        borrower: formData,
      });
    }

    if (selectedTab.label === EditApplicationStep.CoBorrower) {
      return selectedCoBorrower && isAddingCoborrower
        ? updateBorrower(selectedCoBorrower.id, formData)
        : updateApplication({ id: applicationData.id, coborrower: formData });
    }

    if (selectedTab.label === EditApplicationStep.Intermediary) {
      return selectedIntermediary && isAddingIntermediary
        ? updateIntermediary(formData, selectedIntermediary.id)
        : updateApplication({ id: applicationData.id, intermediary: formData });
    }

    return updateApplication({
      id: applicationData.id,
      applicationDetails: difference(applicationData.variables, formData),
    });
  };

  const handleEditFormDataSubmit = async (formData: FormLayoutData) => {
    const action = getSubmitEditFormDataAction(formData);

    if (!action) {
      return;
    }

    try {
      setIsEditFormDataPopupUpdateInProgress(true);

      await dispatchRoutine(action);

      setEditPopupFormData(null);
    } finally {
      setIsEditFormDataPopupUpdateInProgress(false);
    }
  };

  const handleProfileEdit = () => {
    if (!applicationData) {
      return;
    }

    if (selectedTab.label === EditApplicationStep.Borrower) {
      setEditPopupFormData({
        initialData: borrowerFormData,
        title: getBorrowerFullName(applicationData.borrowerType, borrowerFormData),
        configurations: borrowerVariableConfigurations,
        getConfigurations: getBorrowerConfigurations,
        formatDisplayTitle: formatBorrowerTitle,
      });

      return;
    }

    if (selectedTab.label === EditApplicationStep.CoBorrower) {
      const formData = selectedCoBorrower ? selectedCoBorrower.variables : coBorrowerFormData;
      const borrowerType = selectedCoBorrower ? selectedCoBorrower.type : applicationData.coborrowerType;

      setEditPopupFormData({
        initialData: formData,
        title: getBorrowerFullName(borrowerType!, formData),
        configurations: borrowerVariableConfigurations,
        getConfigurations: getBorrowerConfigurations,
        formatDisplayTitle: formatBorrowerTitle,
      });

      return;
    }

    if (selectedTab.label === EditApplicationStep.Intermediary) {
      const formData = selectedIntermediary ? selectedIntermediary.variables : intermediaryFormData;

      setEditPopupFormData({
        initialData: formData,
        title: formData[ApplicationDefaultVariable.Intermediary.Name],
        configurations: intermediaryVariableConfigurations,
        getConfigurations: getIntermediaryConfigurations,
        formatDisplayTitle: formatIntermediaryTitle,
      });

      return;
    }

    setEditPopupFormData({
      initialData: applicationDetailsFormData,
      title: 'Application Details',
      configurations: applicationVariableConfigurations,
      getConfigurations: () => {},
    });
  };

  const handleTabSelect = (tab: TabSwitchOption) => {
    if (isAddingCoborrower || isAddingIntermediary) {
      onReset(tab.label as EditApplicationStep, true);
    } else {
      setSelectedTab(tab);
    }
  };

  const handleTeamMembersChange = async (newTeamMembersIds: string[]) => {
    if (!applicationData) {
      return;
    }

    await dispatchRoutine(updateApplicationTeamMembers(applicationData.id, newTeamMembersIds));
  };

  const renderEditFormDataPopup = () => {
    if (!editPopupFormData) {
      return null;
    }

    return (
      <EditFormDataPopup
        initialData={editPopupFormData.initialData}
        configurations={editPopupFormData.configurations}
        getConfigurations={editPopupFormData.getConfigurations}
        title={editPopupFormData.title}
        onPopupClose={() => setEditPopupFormData(null)}
        onSubmit={handleEditFormDataSubmit}
        isUpdatingInProgress={isEditFormDataPopupUpdateInProgress}
        formatDisplayTitle={editPopupFormData.formatDisplayTitle}
        usePortal
      />
    );
  };

  const shouldRenderCancelButton =
    isAddingIntermediary || (isAddingCoborrower && (!selectedCoBorrower || !selectedCoBorrower.locked));
  const shouldRenderSaveChangesButton = !selectedCoBorrower?.locked && (isAddingCoborrower || isAddingIntermediary);

  const teamMemberIds = (applicationData?.teamMembers || []).map(({ id }) => id);

  const renderTabSwitch = () => {
    if (!applicationData || !product) {
      return <TabSwitchSkeleton className={styles.tabsSwitch} itemsCount={DEFAULT_SKELETON_TABS_COUNT} />;
    }

    return (
      <TabSwitch
        className={styles.tabsSwitch}
        tabClassName={styles.tab}
        buttonTabClassName={styles.buttonTab}
        tabs={tabs}
        selectedTabId={selectedTab.id}
        onSelect={handleTabSelect}
        buttonsTabIndex={FORM_TABS_TAB_INDEX}
      />
    );
  };

  return (
    <ApplicationContextualViewLayout
      onClose={onClose}
      teamMembersIds={teamMemberIds}
      onTeamMembersChange={handleTeamMembersChange}
      isTeamMembersUpdatingInProgress={isTeamMembersUpdatingInProgress}
    >
      {renderTabSwitch()}
      <div className={styles.tabContainer}>
        <CreateApplicationForm
          currentStep={selectedTab.label as EditApplicationStep}
          borrowerFormData={borrowerFormData}
          coBorrowerFormData={coBorrowerFormData}
          intermediaryFormData={intermediaryFormData}
          applicationDetailsFormData={applicationDetailsFormData}
          borrowerConfigurations={borrowerVariableConfigurations}
          intermediaryConfigurations={intermediaryVariableConfigurations}
          applicationDetailsConfigurations={applicationVariableConfigurations}
          standardVariables={standardVariables}
          onBorrowerDataChange={setBorrowerFormData}
          onCoBorrowerDataChange={setCoBorrowerFormData}
          onIntermediaryDataChange={setIntermediaryFormData}
          onApplicationDetailsDataChange={setApplicationDetailsFormData}
          addCoBorrower={isAddingCoborrower}
          addIntermediary={isAddingIntermediary}
          borrowerSuggestions={isAddingCoborrower ? borrowerSuggestions : []}
          intermediarySuggestions={isAddingIntermediary ? intermediarySuggestions : []}
          onLoadBorrowerSuggestions={(filter) => onLoadBorrowerSuggestions.current(filter, dispatch)}
          onLoadIntermediarySuggestions={(filter) => onLoadIntermediarySuggestions.current(filter, dispatch)}
          selectedCoBorrower={selectedCoBorrower}
          selectedIntermediary={selectedIntermediary}
          onCoBorrowerSelect={handleCoBorrowerSelect}
          onIntermediarySelect={handleIntermediarySelect}
          onUnlockCustomerClick={handleUnlockCustomerClick}
          onProfileEditClick={handleProfileEdit}
          borrowerType={applicationData?.borrowerType}
          isEditMode
        />
        <div className={styles.actionContainer}>
          {shouldRenderSaveChangesButton && (
            <Button
              size="form"
              kind="primary"
              disabled={isSaveButtonDisabled()}
              onClick={saveChanges}
              isLoading={isEditing}
            >
              Add {selectedTab.label}
            </Button>
          )}
          {shouldRenderCancelButton && (
            <Button
              size="form"
              kind="secondary"
              disabled={isEditing}
              onClick={() => onReset(EditApplicationStep.Borrower, true)}
              className={styles.cancelButton}
            >
              Cancel
            </Button>
          )}
          {shouldRenderDeleteButton && (
            <Button size="form" kind="warning" onClick={handleDelete} className={styles.removeButton}>
              Remove or Change {selectedTab.label}
            </Button>
          )}
        </div>
        {renderEditFormDataPopup()}
      </div>
    </ApplicationContextualViewLayout>
  );
};

export default EditApplication;
