import { SnackbarActionType } from './ActionTypes';
import { SnackType, SnackbarComponents } from './Types';
import { nanoid } from 'nanoid';

export interface ShowSnackAction {
  type: SnackbarActionType.ShowSnack;
  payload: SnackType<SnackbarComponents>;
}

export const showSnack = (title: string, content: SnackbarComponents): ShowSnackAction => ({
  type: SnackbarActionType.ShowSnack,
  payload: {
    id: nanoid(),
    title,
    content,
  },
});

export interface HideSnackAction {
  type: SnackbarActionType.HideSnack;
  payload: {
    id: string;
  };
}

export const hideSnack = (id: string): HideSnackAction => ({
  type: SnackbarActionType.HideSnack,
  payload: {
    id,
  },
});
