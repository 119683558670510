import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { CreateProductSuccessAction, getProducts } from './ActionCreator';
import { selectProductForConfiguration } from './ProductConfiguration/ActionCreator';
import { History } from 'history';

const isCreateProductSuccessAction = (action: AnyAction): action is CreateProductSuccessAction =>
  action.type === LoanOriginationSystemProductsActionType.CreateProductSuccess;

export const NavigateToProductOnCreateProductSuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateProductSuccessAction(action)) {
    dispatch(selectProductForConfiguration(action.payload));

    replace(`/los/configuration/products/${action.payload.id}`);
  }

  return result;
};

export const reloadProductsOnCreateProductSuccessMiddleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateProductSuccessAction(action)) {
    const { loanOriginationSystemProducts } = getState();
    const { filters } = loanOriginationSystemProducts;

    dispatch(getProducts(filters));
  }

  return result;
};

export const notifyOnCreateProductSuccessMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isCreateProductSuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.ProductCreated, {
        productName: action.payload.name,
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};
