import BorrowerDefaultVariable, { BorrowerDefaultVariableType } from './BorrowerDefaultVariable';
import IntermediaryDefaultVariable, { IntermediaryDefaultVariableType } from './IntermediaryDefaultVariable';

export const convertToCoBorrowerVariable = (variableName: string) => `co${variableName}`;

const CoborrowerDefaultVariable = Object.keys(BorrowerDefaultVariable).reduce(
  (result, key) => ({
    ...result,
    [key]: convertToCoBorrowerVariable(BorrowerDefaultVariable[key]),
  }),
  {},
);

const ApplicationDefaultVariable = {
  Borrower: BorrowerDefaultVariable,
  Coborrower: CoborrowerDefaultVariable as BorrowerDefaultVariableType,
  Intermediary: IntermediaryDefaultVariable,
  LoanAmount: 'loan_amount',
  IncludesCoBorrower: 'coborrower_application',
  IncludesIntermediary: 'intermediary_application',
};

export const applicationDefaultVariables = [
  ...Object.values(ApplicationDefaultVariable.Borrower),
  ...Object.values(ApplicationDefaultVariable.Coborrower),
  ...Object.values(ApplicationDefaultVariable.Intermediary),
  ApplicationDefaultVariable.LoanAmount,
  ApplicationDefaultVariable.IncludesCoBorrower,
  ApplicationDefaultVariable.IncludesIntermediary,
];

type ApplicationDefaultVariableDynamicType = typeof ApplicationDefaultVariable;

export interface ApplicationDefaultVariableType extends ApplicationDefaultVariableDynamicType {
  Coborrower: BorrowerDefaultVariableType;
  Borrower: BorrowerDefaultVariableType;
  Intermediary: IntermediaryDefaultVariableType;
}

export default ApplicationDefaultVariable;
