import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { DropResult } from 'react-beautiful-dnd';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { ApplicationsSortingType, ApplicationSortingField } from 'api/Types';
import { NullableItems, PaginationProps } from 'pagination';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import { LoanOriginationSystemApplicationsFiltersState } from 'LoanOriginationSystemApplications/Filters/Types';
import styles from './Body.module.scss';
import DndListContext from 'components/DndList/DndListContext';
import ColumnBody from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody';
import ColumnHeader from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnHeader';
import ApplicationsTable from './ApplicationsTable';
import SkeletonColumnHeader from './Column/SkeletonColumnHeader';
import SkeletonColumnBody from './Column/SkeletonColumnBody';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { StandardVariables } from 'Variables/VariablesTypes';
import useUserRole from 'MyAccount/useUserRole';

interface BodyProps {
  viewType: ViewType;
  applications: Application[] | null;
  tableViewData: NullableItems<Application>;
  paginationProps: PaginationProps;
  tableViewSortingType: ApplicationsSortingType;
  filters: LoanOriginationSystemApplicationsFiltersState;
  onApplicationsReorder: (
    applicationId: string,
    statusId: string,
    sourceIndex: number,
    destinationIndex: number,
  ) => void;
  onTableViewSort: (field: ApplicationSortingField, ascending: boolean) => void;
  onDeleteApplication: (application: Application) => void;
  onEditApplication: (application: Application) => void;
  onDuplicateApplication: (application: Application) => void;
  applicationStatuses: ApplicationStatus[];
  standardVariables: StandardVariables | null;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  onApplicationUpdatingStateReset: (applicationId: string) => void;
  deleteDisabled: boolean;
}

const COLUMN_SKELETONS = Array.from(
  { length: 5 },
  (item, index): ApplicationStatus => ({
    id: index.toString(),
    name: index.toString(),
    position: 0,
    productId: '',
    rolesAbleToViewApplicationOnBoard: [],
    permissionsToEditApplication: [],
    permissionsToMoveApplicationIntoStatus: [],
    rules: [],
  }),
);

const Body = ({
  viewType,
  applications,
  tableViewData: tableViewProductData,
  paginationProps,
  tableViewSortingType,
  filters,
  deleteDisabled,
  onApplicationsReorder,
  onTableViewSort,
  applicationStatuses,
  standardVariables,
  onDeleteApplication,
  applicationUpdatingStatesById,
  onApplicationUpdatingStateReset,
  onEditApplication,
  onDuplicateApplication,
}: BodyProps) => {
  const columnHeadersRef = useRef<HTMLDivElement>(null);
  const columnBodiesRef = useRef<HTMLDivElement>(null);
  const classNames = clsx(styles.container, viewType === ViewType.Column ? styles.columnView : styles.tableView);
  const userRole = useUserRole();

  const statuses = applications && userRole ? applicationStatuses : COLUMN_SKELETONS;

  const sortedStatuses = useMemo(() => {
    return statuses
      .filter((status) => !userRole || status.rolesAbleToViewApplicationOnBoard.includes(userRole))
      .sort((firstStatus, secondStatus) => firstStatus.position - secondStatus.position);
  }, [statuses]);

  const onReorder = (result: DropResult) => {
    if (result.destination) {
      onApplicationsReorder(
        result.draggableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index,
      );
    }
  };

  const currencySymbol = getFormattedLoanAmountCurrency(standardVariables);

  const renderColumnHeaders = () =>
    sortedStatuses.map((status) => {
      if (!applications || !currencySymbol) {
        return <SkeletonColumnHeader key={status.id} />;
      }

      const applicationByStatus = applications.filter((application) => application.status.name === status.name);

      return (
        <ColumnHeader
          key={status.id}
          applications={applicationByStatus}
          currencySymbol={currencySymbol}
          name={status.name}
        />
      );
    });

  const renderColumnBodies = () => (
    <DndListContext onReorder={onReorder}>
      {(placeholderProps) => (
        <TableWrapperWithFooter ref={columnBodiesRef} className={styles.columnBodiesWrapper} onScroll={onScroll}>
          <div className={styles.columnBodies}>
            {sortedStatuses.map((status) => {
              if (!applications || !currencySymbol) {
                return <SkeletonColumnBody key={status.id} />;
              }

              const applicationByStatus = applications.filter((application) => application.status.name === status.name);

              return (
                <ColumnBody
                  id={status.id}
                  applications={applicationByStatus}
                  key={status.id}
                  currencySymbol={currencySymbol}
                  placeholderStyle={placeholderProps}
                  searchInputValue={filters.searchInputValue}
                  applicationUpdatingStatesById={applicationUpdatingStatesById}
                  onApplicationUpdatingStateReset={onApplicationUpdatingStateReset}
                  deleteDisabled={deleteDisabled}
                  onDeleteApplication={onDeleteApplication}
                  onEditApplication={onEditApplication}
                  onDuplicateApplication={onDuplicateApplication}
                />
              );
            })}
          </div>
        </TableWrapperWithFooter>
      )}
    </DndListContext>
  );

  const onScroll = () => {
    if (columnHeadersRef.current && columnBodiesRef.current) {
      columnHeadersRef.current.scrollLeft = columnBodiesRef.current.scrollLeft;
    }
  };

  return (
    <div className={classNames}>
      {viewType === ViewType.Column && (
        <>
          <div className={styles.columnHeaders} ref={columnHeadersRef}>
            {renderColumnHeaders()}
          </div>
          {renderColumnBodies()}
        </>
      )}
      {viewType === ViewType.Table && tableViewProductData && (
        <ApplicationsTable
          currencySymbol={currencySymbol}
          source={tableViewProductData}
          paginationProps={paginationProps}
          onSort={onTableViewSort}
          sortingType={tableViewSortingType}
          deleteDisabled={deleteDisabled}
          onDeleteApplication={onDeleteApplication}
          onEditApplication={onEditApplication}
          standardVariables={standardVariables}
          onDuplicateApplication={onDuplicateApplication}
        />
      )}
    </div>
  );
};

export default Body;
