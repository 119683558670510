import React from 'react';
import clsx from 'clsx';
import styles from './IntermediaryForm.module.scss';
import ExistingIntermediaryForm from './ExistingIntermediaryForm/ExistingIntermediaryForm';
import NewIntermediaryForm from 'components/LoanOriginationSystem/CreateApplication/Forms/IntermediaryForm/NewIntermediaryForm/NewIntermediaryForm';
import { FormLayoutData, VariableConfiguration } from 'api/Types';
import { FormSkeleton } from 'components/Skeleton';
import InfoCardSkeleton from 'components/InfoCard/InfoCardSkeleton';
import { SuggestionItem } from 'components/LoanOriginationSystem/CreateApplication/BorrowerSuggestionsPopper';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { StandardVariables } from 'Variables/VariablesTypes';

interface IntermediaryFormProps {
  intermediaryData: FormLayoutData;
  selectedIntermediary: Intermediary | null;
  configurations: VariableConfiguration[] | null;
  standardVariables: StandardVariables | null;
  suggestions: Intermediary[];
  onFormDataChange: (borrower: FormLayoutData) => void;
  onLoadSuggestions?: (formData: IntermediarySuggestionFilter) => void;
  onIntermediarySelect?: (intermediary: Intermediary | null) => void;
  onProfileEditClick?: () => void;
  isEditMode?: boolean;
}

const IntermediaryForm = ({
  intermediaryData,
  selectedIntermediary,
  configurations,
  standardVariables,
  suggestions,
  onFormDataChange,
  onLoadSuggestions,
  onIntermediarySelect,
  onProfileEditClick,
  isEditMode,
}: IntermediaryFormProps) => {
  if (!configurations || !standardVariables) {
    return isEditMode ? <InfoCardSkeleton /> : <FormSkeleton />;
  }

  const suggestionOptions = suggestions.map(
    (suggestion) =>
      ({
        id: suggestion.id,
        name: suggestion.variables[IntermediaryDefaultVariable.Name],
        phone: suggestion.variables[IntermediaryDefaultVariable.PhoneNumber],
        email: suggestion.variables[IntermediaryDefaultVariable.Email],
      } as SuggestionItem),
  );

  const onSuggestionSelect = (suggestionItem: SuggestionItem) => {
    onIntermediarySelect?.(suggestions.find(({ id }) => id === suggestionItem.id)!);
  };

  const renderForm = () => {
    if (selectedIntermediary || isEditMode) {
      return (
        <ExistingIntermediaryForm
          standardVariables={standardVariables}
          variables={selectedIntermediary ? selectedIntermediary.variables : intermediaryData}
          onClose={() => onIntermediarySelect?.(null)}
          onProfileEditClick={onProfileEditClick}
          hideCloseButton={isEditMode}
        />
      );
    }

    return (
      <NewIntermediaryForm
        configurations={configurations}
        intermediaryData={intermediaryData}
        standardVariables={standardVariables}
        onChange={onFormDataChange}
        suggestions={suggestionOptions}
        onSuggestionSelect={onSuggestionSelect}
        onLoadSuggestions={onLoadSuggestions}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={clsx((selectedIntermediary || isEditMode) && styles.existingIntermediaryForm)}>
        {renderForm()}
      </div>
    </div>
  );
};

export default IntermediaryForm;
