import React, { useEffect } from 'react';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatch, useSelector, batch } from 'react-redux';
import { ReduxState } from 'types/redux';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import EmailsDashboard from 'components/LoanOriginationSystem/EmailsDashboard';
import NoEmailsConnected from 'components/LoanOriginationSystem/NoEmailsConnected';
import { getOrganizationEmail } from 'utils/emailGetters';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import NavigationLinkId from 'enums/NavigationLinkId';
import EmailsFilter from 'components/LoanOriginationSystem/EmailsDashboard/EmailsFilter';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import EmailView from 'components/LoanOriginationSystem/EmailView';
import EmailNotFound from 'components/LoanOriginationSystem/EmailView/EmailNotFound';
import DeleteEmailConfirmationPopup from 'components/LoanOriginationSystem/DeleteEmailConfirmationPopup/DeleteEmailConfirmationPopup';
import {
  closeDeleteEmailPopup,
  deleteEmail,
  closeConnectEmailsPopup,
  getEmails,
} from 'LoanOriginationSystemEmails/ActionCreator';
import ConnectEmailsPopup from 'components/LoanOriginationSystem/ApplicationTabs/ConnectEmailsPopup';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { LoanOriginationSystemEmailsActionTypes } from 'LoanOriginationSystemEmails/ActionTypes';
import pagination, { EmailsPaginationParams } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';

const leftNav = makeLeftNavigation(NavigationLinkId.Emails, ApplicationSectionName.LoanOriginationSystem);

const Emails = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const closeContextualView = useCloseContextualView();
  const params = useQueryParams();
  const emailId = params.get('emailId');

  const {
    items,
    filters,
    emailIdToDelete,
    deleteEmailInProgress,
    sortingType,
    connectEmailsPopUpOpen,
    isLoading,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);
  const organizationInfo = useSelector((state: ReduxState) => state.organizationInformation);

  const organizationEmail = getOrganizationEmail(organizationInfo.id);

  useStateReset(LoanOriginationSystemEmailsActionTypes.ResetState);

  const isFiltersNotEmpty = filters.selectedBorrower || filters.createdAtRange.from || filters.createdAtRange.to;

  const paginationParams: EmailsPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    createdAtDateRange: filters.createdAtRange,
    selectedBorrower: filters.selectedBorrower,
  };

  const emails = pagination.usePaginatedItems(paginationParams);
  const paginationProps = pagination.usePagination(paginationParams);

  const onConnectEmailsPopupClose = () => dispatch(closeConnectEmailsPopup());

  const rightSidePopupView = filters.isPopupVisible && (
    <EmailsFilter
      dueDateFrom={filters.createdAtRange.from}
      dueDateTo={filters.createdAtRange.to}
      borrower={filters.selectedBorrower}
    />
  );

  useEffect(() => {
    batch(() => {
      dispatch(getOrganizationInfo());
      dispatch(getEmails(filters, sortingType));
    });
  }, []);

  const onCloseContextualView = () => {
    closeContextualView();
  };

  const handleCloseDeleteEmailPopup = () => dispatch(closeDeleteEmailPopup());
  const handleDeleteEmail = async () => {
    await dispatchRoutine(deleteEmail(emailIdToDelete!));
    onCloseContextualView();
  };

  const renderOverlay = () => {
    if (connectEmailsPopUpOpen) {
      return (
        <ConnectEmailsPopup
          emailAddress={getOrganizationEmail(organizationInfo.id)}
          onPopupClose={onConnectEmailsPopupClose}
        />
      );
    }
    if (emailIdToDelete) {
      return (
        <DeleteEmailConfirmationPopup
          onConfirmClick={handleDeleteEmail}
          onPopupClose={handleCloseDeleteEmailPopup}
          loading={deleteEmailInProgress}
        />
      );
    }

    return null;
  };

  const renderContextualView = () => {
    if (emailId) {
      const currentEmailInfo = items.find((email) => email.id === emailId);
      if (currentEmailInfo) {
        return (
          <EmailView
            currentEmailInfo={currentEmailInfo}
            onClose={onCloseContextualView}
            organizationEmail={organizationEmail}
          />
        );
      }
      if (!currentEmailInfo && !isLoading) {
        return <EmailNotFound onClose={onCloseContextualView} />;
      }
    }

    return null;
  };

  const renderNoEmails = () => <NoEmailsConnected headerText="Emails" email={organizationEmail} borrowerEmail="" />;

  return (
    <MainLayout
      leftNav={leftNav}
      rightSidePopupView={rightSidePopupView}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      overlay={renderOverlay()}
    >
      <PageWrapperWithFooter>
        <PageContent>
          {!items.length && !isFiltersNotEmpty && !isLoading ? (
            renderNoEmails()
          ) : (
            <EmailsDashboard emails={emails} paginationProps={paginationProps} organizationId={organizationInfo.id} />
          )}
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Emails;
