import { useRef } from 'react';
import { once } from 'lodash';

const useOnce = <Callback extends (...args: any[]) => any>(callback: Callback) => {
  const callbackOnce = useRef(once(callback));

  return callbackOnce.current;
};

export default useOnce;
