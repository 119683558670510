import UserAvatar, { UserAvatarProps } from 'components/UserAvatar/UserAvatar';
import { useAssetsApi } from 'providers/ApiServiceProvider';
import React, { useEffect, useState } from 'react';

interface ImageFetcherProps extends Omit<UserAvatarProps, 'imageId'> {
  imageId?: string;
}

const ImageFetcher = ({ imageId, imageUrl, ...props }: ImageFetcherProps) => {
  const [image, setImage] = useState<string | null>(null);
  const assetsApi = useAssetsApi();

  useEffect(() => {
    const getImage = async () => {
      if (imageUrl) {
        setImage(imageUrl);

        return;
      }

      if (imageId) {
        const imageBuffer = await assetsApi.getUserImage(imageId);
        setImage(imageBuffer as string | null);
      }
    };
    getImage();
  }, [imageId, imageUrl]);

  return <UserAvatar {...props} imageUrl={image} />;
};

export default ImageFetcher;
