import Fetcher from 'middlewares/Fetcher';
import { Middleware, MiddlewareAPI, AnyAction } from 'redux';
import { DecisionEngineApi } from 'api/Types';
import notification from 'handlers/notification/notificationActionCreator';
import { batch } from 'react-redux';
import { getBranchInfoRequest } from 'BranchInfo/ActionCreator';
import {
  EDIT_CALCULATION_SCRIPT,
  EDIT_CALCULATION_SCRIPT_SUCCESS,
  EDIT_RULE,
  EDIT_RULE_SUCCESS,
  EDIT_SIMPLE_OUTPUT,
  EDIT_SIMPLE_OUTPUT_SUCCESS,
} from './ActionTypes';
import {
  CompleteRuleRequestData,
  CompleteCalculationScriptData,
  SaveRuleAction,
  SaveCalculationScriptAction,
  CompleteSimpleOutputRequestData,
  SaveSimpleOutputAction,
  SaveRuleCommonSuccessAction,
} from 'RuleCreate/Types';
import {
  closeEditRulePopUp,
  editCalculationScriptFailure,
  editCalculationScriptSuccess,
  editRuleFailure,
  editRuleSuccess,
  editSimpleOutputFailure,
  editSimpleOutputSuccess,
} from './ActionsCreator';
import getMessage, { MessageType } from 'constants/messages';

const isCreateNewRuleAction = (action: AnyAction): action is SaveRuleCommonSuccessAction =>
  action.type === EDIT_RULE_SUCCESS ||
  action.type === EDIT_CALCULATION_SCRIPT_SUCCESS ||
  action.type === EDIT_SIMPLE_OUTPUT_SUCCESS;

export const EditRuleMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteRuleRequestData, SaveRuleAction>(EDIT_RULE, editRuleSuccess, editRuleFailure, async ({ payload }) => {
    await api.editRule(payload);
    return payload;
  });

export const EditCalculationScriptMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteCalculationScriptData, SaveCalculationScriptAction>(
    EDIT_CALCULATION_SCRIPT,
    editCalculationScriptSuccess,
    editCalculationScriptFailure,
    async ({ payload }) => {
      await api.editCalculationScript(payload);
      return payload;
    },
  );

export const EditSimpleOutputMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteSimpleOutputRequestData, SaveSimpleOutputAction>(
    EDIT_SIMPLE_OUTPUT,
    editSimpleOutputSuccess,
    editSimpleOutputFailure,
    async ({ payload }) => {
      await api.editSimpleOutput(payload);
      return payload;
    },
  );

export const onEditRuleSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isCreateNewRuleAction(action)) {
    notification.createNotification(getMessage(MessageType.RuleUpdated), 'success', dispatch, 4000);
    const { strategyId, moduleKey, branchIndex } = action.payload;
    batch(() => {
      dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex }));
      dispatch(closeEditRulePopUp());
    });
  }
  return result;
};
