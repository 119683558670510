import React, { FC, useCallback } from 'react';
import { VariablesSortingField } from 'api/Core/VariablesApi';
import { useVariablesApi } from 'providers/ApiServiceProvider';
import AutoCompletion from 'components/AutoCompletion';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { LIST_VARIABLES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/VariablesConfiguration/pagination';

export interface MappingVariableAutoCompletionProps {
  variableKey: string;
  variableSystemName: string;
  updateVariableEntry: (variableKey: string, value: string) => void;
  openVariableCreation: () => void;
  displayCreateVariableLink: boolean;
}

const MappingVariableAutoCompletion: FC<MappingVariableAutoCompletionProps> = ({
  variableSystemName,
  variableKey,
  updateVariableEntry,
  openVariableCreation,
  displayCreateVariableLink,
}) => {
  const variablesApi = useVariablesApi();

  const fetchOptions = useCallback(
    async (search?: string) => {
      const { items } = await variablesApi.getVariables(
        { search, count: LIST_VARIABLES_PER_PAGE_DEFAULT },
        { field: VariablesSortingField.DisplayName, ascending: true },
      );

      return items;
    },
    [variablesApi],
  );

  const fetchSelectedOption = useCallback(async (value: string) => {
    const { items: assignedVariables } = await variablesApi.getVariables(
      { systemNames: [value], count: LIST_VARIABLES_PER_PAGE_DEFAULT },
      { field: VariablesSortingField.DisplayName, ascending: true },
    );

    const selectedVariable = assignedVariables[0];

    if (!selectedVariable) {
       return undefined;
    }

    return {
      value,
      name: selectedVariable.displayName,
    };
  },
  [],
);

  const fetchFormattedOptions = useCallback(
    async (search?: string) => {
      const items = await fetchOptions(search);

      return items.map(({ systemName, displayName }) => ({
        value: systemName,
        name: displayName,
      }));
    },
    [fetchOptions],
  );

  return (
    <AutoCompletion
      value={variableSystemName}
      labelTitle={variableKey}
      hasRightNeighbour
      fetchOptions={fetchFormattedOptions}
      fetchSelectedOption={fetchSelectedOption}
      onChange={({ value }) => updateVariableEntry(variableKey, value)}
      link={displayCreateVariableLink && <CreateNewVariableLink onClick={openVariableCreation} />}
    />
  );
};

export default MappingVariableAutoCompletion;
