import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import { ApplicationHistoryRecord } from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import Pagination from 'components/Pagination';
import HistoryList from 'components/LoanOriginationSystem/ApplicationTabs/HistoryTab/HistoryList';

interface BodyProps {
  historyRecords: NullableItems<ApplicationHistoryRecord>;
  paginationProps: PaginationProps;
}

const Body = ({ historyRecords, paginationProps }: BodyProps) => (
  <div>
    <HistoryList historyRecords={historyRecords} />
    <Pagination {...paginationProps} />
  </div>
);

export default Body;
