import Fetcher from 'middlewares/Fetcher';
import { IntermediaryVariableConfigurationsApi } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import { BulkUpdateVariableConfigurationParams } from 'api/LoanOriginationSystem/Types';
import {
  bulkUpdateIntermediaryVariableConfigurationsFailure,
  bulkUpdateIntermediaryVariableConfigurationsSuccess,
  BulkUpdateIntermediaryVariableConfigurationsAction,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const BulkUpdateBorrowerVariableConfigurationsMiddleware = (api: IntermediaryVariableConfigurationsApi) =>
  Fetcher<BulkUpdateVariableConfigurationParams[], BulkUpdateIntermediaryVariableConfigurationsAction>(
    VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurations,
    bulkUpdateIntermediaryVariableConfigurationsSuccess,
    bulkUpdateIntermediaryVariableConfigurationsFailure,
    async (action) => {
      await api.bulkUpdate(action.payload.configurations);

      return action.payload.configurations;
    },
  );

export default BulkUpdateBorrowerVariableConfigurationsMiddleware;
