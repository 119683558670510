import React from 'react';
import styles from './Subtitle.module.scss';
import PageUpdateInfo from 'components/PageUpdateInfo';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import EditLabels from 'components/LoanOriginationSystem/EditLabels';

interface SubtitleProps {
  member?: UserInfo | null;
  applicationLabels: BaseLabelInfo[];
  date: Date;
  formatDate?: (date: Date) => string;
  productLabels?: BaseLabelInfo[];
  onAddLabel?: (labelId: string) => void;
  onDeleteLabel?: (label: BaseLabelInfo) => void;
  isAddingLabelInProgress?: boolean;
  displayLabelsSection?: boolean;
}

const Subtitle = ({
  applicationLabels,
  member,
  date,
  formatDate,
  productLabels,
  onAddLabel,
  isAddingLabelInProgress,
  onDeleteLabel,
  displayLabelsSection,
}: SubtitleProps) => {
  return (
    <div className={styles.subtitleInner}>
      <PageUpdateInfo formatDate={formatDate} date={date} member={member} />
      {displayLabelsSection && (
        <EditLabels
          className={styles.editLabels}
          applicationLabels={applicationLabels}
          productLabels={productLabels || []}
          onAddLabel={(labelId) => onAddLabel?.(labelId)}
          onDeleteLabel={(label: BaseLabelInfo) => onDeleteLabel?.(label)}
          isAddingLabelInProgress={isAddingLabelInProgress}
        />
      )}
    </div>
  );
};

export default Subtitle;
