import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  Borrower,
  BorrowersSortingField,
  BorrowersSortingType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import BorrowerItemRow from './BorrowerItemRow';
import SkeletonBorrowerItemRow from './SkeletonBorrowerItemRow';
import Pagination from 'components/Pagination';
import { BorrowersColumnSizes } from './TableColumnSizes';
import styles from './BorrowersTable.module.scss';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { StandardVariables } from 'Variables/VariablesTypes';

interface TableProps {
  source: NullableItems<Borrower>;
  paginationProps: PaginationProps;
  sortingType: BorrowersSortingType;
  searchInputValue: string;
  standardVariables: StandardVariables | null;
  onSort: (field: BorrowersSortingField, ascending: boolean) => void;
  onBorrowerEdit: (id: string) => void;
  onBorrowerClick: (id: string) => void;
  onBorrowerDelete: (id: string) => Promise<void>;
}

const BorrowersTable = ({
  source,
  paginationProps,
  sortingType,
  searchInputValue,
  standardVariables,
  onSort,
  onBorrowerDelete,
  onBorrowerClick,
  onBorrowerEdit,
}: TableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonTableRow = (index: number) => <SkeletonBorrowerItemRow key={index} />;

  const renderTableRow = (item: Borrower, index: number) => {
    if (!standardVariables) {
      return renderSkeletonTableRow(index);
    }

    return (
      <BorrowerItemRow
        key={item.id}
        searchInputValue={searchInputValue}
        borrower={item}
        onClick={() => onBorrowerClick(item.id)}
        onBorrowerDelete={onBorrowerDelete}
        onBorrowerEdit={onBorrowerEdit}
        standardVariables={standardVariables}
      />
    );
  };

  return (
    <TableWrapperWithFooter>
      <div className={styles.table}>
        <div className={styles.tableContainer}>
          <Table>
            <TableHead sticky useActions>
              <TableHeadCell
                width={BorrowersColumnSizes.Name}
                ascending={getSortingType(BorrowersSortingField.BorrowerName)}
                onClick={() => changeSorting(BorrowersSortingField.BorrowerName)}
              >
                Name
              </TableHeadCell>
              <TableHeadCell
                width={BorrowersColumnSizes.IdNumber}
                ascending={getSortingType(BorrowersSortingField.IdNumber)}
                onClick={() => changeSorting(BorrowersSortingField.IdNumber)}
              >
                Id Number
              </TableHeadCell>
              <TableHeadCell
                width={BorrowersColumnSizes.Phone}
                ascending={getSortingType(BorrowersSortingField.Phone)}
                onClick={() => changeSorting(BorrowersSortingField.Phone)}
              >
                Phone
              </TableHeadCell>
              <TableHeadCell
                width={BorrowersColumnSizes.Email}
                ascending={getSortingType(BorrowersSortingField.Email)}
                onClick={() => changeSorting(BorrowersSortingField.Email)}
              >
                Email
              </TableHeadCell>
              <TableHeadCell
                width={BorrowersColumnSizes.UpdatedAt}
                ascending={getSortingType(BorrowersSortingField.UpdatedAt)}
                onClick={() => changeSorting(BorrowersSortingField.UpdatedAt)}
              >
                Updated
              </TableHeadCell>
              <TableActionCell />
            </TableHead>
            <TableBody>
              <TableBodyContent
                renderSkeletonTableRow={(index) => <SkeletonBorrowerItemRow key={index} />}
                renderTableRow={renderTableRow}
                rows={source}
              />
            </TableBody>
          </Table>
          <Pagination {...paginationProps} />
        </div>
      </div>
    </TableWrapperWithFooter>
  );
};

export default BorrowersTable;
