import {
  SetSearchInputValueAction,
  ChangePopupFiltersAction,
  FilterBorrowersByMembersAction,
  ResetAllBorrowersFilters,
  ToggleFiltersPopupAction,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './ActionTypes';
import { LoanOriginationSystemBorrowersFiltersState } from './Types';

export const initialState: LoanOriginationSystemBorrowersFiltersState = {
  searchInputValue: '',
  isPopupVisible: false,
  isCompany: false,
  isPerson: false,
  dueUpdatedDateRange: {
    from: null,
    to: null,
  },
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
  selectedMembers: [],
};

type LoanOriginationSystemBorrowersFiltersActions =
  | SetSearchInputValueAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | FilterBorrowersByMembersAction
  | ResetAllBorrowersFilters;

export const loanOriginationSystemBorrowersFiltersReducer = (
  state: LoanOriginationSystemBorrowersFiltersState = initialState,
  action: LoanOriginationSystemBorrowersFiltersActions,
): LoanOriginationSystemBorrowersFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemBorrowersFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchValue,
      };
    }

    case LoanOriginationSystemBorrowersFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }

    case LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        isPopupVisible: false,
        dueCreatedDateRange: action.payload.dueCreatedDateRange,
        dueUpdatedDateRange: action.payload.dueUpdatedDateRange,
        isPerson: action.payload.isPerson,
        isCompany: action.payload.isCompany,
      };
    }

    case LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }

    case LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
