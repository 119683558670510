import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationVariableConfiguration,
  ApplicationVariableConfigurationsApi,
} from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import {
  CreateApplicationVariableConfigurationAction,
  createApplicationVariableConfigurationSuccess,
  createApplicationVariableConfigurationFailure,
} from './ApplicationVariableConfigurationsActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const CreateApplicationVariableConfigurationMiddleware = (api: ApplicationVariableConfigurationsApi) =>
  Fetcher<ApplicationVariableConfiguration, CreateApplicationVariableConfigurationAction>(
    VariablesConfigurationActionType.CreateApplicationVariableConfiguration,
    createApplicationVariableConfigurationSuccess,
    createApplicationVariableConfigurationFailure,
    (action) => {
      return api.create({
        position: action.payload.position,
        column: action.payload.column,
        variableId: action.payload.variable.id,
        productId: action.payload.productId,
      });
    },
  );

export default CreateApplicationVariableConfigurationMiddleware;
