import React from 'react';
import { Route, Switch } from 'react-router';

import Home from './Home';
import Profile from './Profile';
import CompanyInformation from 'pages/CompanyInformation';
import CompanyInformationUsers from 'pages/CompanyInformationUsers';
import DigifiApi from 'pages/DigifiApi';
import DocuSign from 'pages/DocuSign';
import Variables from 'pages/Variables';
import StrategyPage from 'pages/Strategy';
import StrategyGroups from 'pages/StrategyGroups';
import IndividualProcessing from 'pages/IndividualProcessing';
import { TabName } from 'components/IndividualProcessing/IndividualProcessing';
import ProcessingIndividualCase from 'pages/ProcessingIndividualCase';
import LoanOriginationSystem from './LoanOriginationSystem';
import ApiActivationPage from 'pages/ApiActivation';
import BatchProcessing from 'pages/BatchProcessing';
import ProcessingBatchCase from 'pages/ProcessingBatchCase';
import ProductAccessCheck from 'components/RouteWithPermissions/ProductAccessCheck';
import ProductSectionAccessCheck from 'components/RouteWithPermissions/ProductSectionAccessCheck';
import { DigifiProduct, ProductSection } from 'components/RouteWithPermissions/Types';
import SendGridPage from 'pages/SendGrid';
import RegionSettings from 'pages/RegionSettings';
import SecureFileUploadPage from 'pages/SecureFileUpload';
import Billing from 'pages/Billing';
import Permissions from 'pages/Permissions';
import Auth from './Auth';
import { AppRoutes } from './RouteService';
import ConfirmEmail from 'pages/ConfirmEmail';
import PageName from 'constants/PageName';
import NotFoundPage from 'pages/NotFound';

export default () => (
  <Switch>
    <Route path="/los">
      <ProductAccessCheck product={DigifiProduct.LoanOriginationSystem}>
        <LoanOriginationSystem />
      </ProductAccessCheck>
    </Route>
    <Route path={AppRoutes.ConfirmEmail}>
      <ConfirmEmail />
    </Route>
    <Route path="/auth">
      <Auth />
    </Route>
    <Route path="/home">
      <Home />
    </Route>
    <Route path="/dashboard">
      <Home />
    </Route>
    <Route path="/account/profile">
      <Profile />
    </Route>
    <Route path="/company-settings/company_info">
      <ProductSectionAccessCheck
        pageName={PageName.CompanyInformation}
        productSection={ProductSection.CompanySettingsGeneral}
      >
        <CompanyInformation />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/region_settings">
      <ProductSectionAccessCheck
        pageName={PageName.RegionSettings}
        productSection={ProductSection.CompanySettingsGeneral}
      >
        <RegionSettings />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/users">
      <ProductSectionAccessCheck
        pageName={PageName.PlatformUsers}
        productSection={ProductSection.CompanySettingsTeam}
      >
        <CompanyInformationUsers />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/permissions">
      <ProductSectionAccessCheck
        pageName={PageName.AccessPermissions}
        productSection={ProductSection.CompanySettingsTeam}
      >
        <Permissions />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/strategies/:strategy/:tab/">
      <ProductAccessCheck product={DigifiProduct.DecisionEngine}>
        <ProductSectionAccessCheck
          pageName={PageName.DecisionStrategies}
          productSection={ProductSection.DecisionEngineStrategies}
        >
          <StrategyPage />
        </ProductSectionAccessCheck>
      </ProductAccessCheck>
    </Route>
    <Route path="/decision/strategies">
      <ProductAccessCheck product={DigifiProduct.DecisionEngine}>
        <ProductSectionAccessCheck
          pageName={PageName.DecisionStrategies}
          productSection={ProductSection.DecisionEngineStrategies}
        >
          <StrategyGroups />
        </ProductSectionAccessCheck>
      </ProductAccessCheck>
    </Route>
    <Route path="/company-settings/digifi-api">
      <ProductSectionAccessCheck
        pageName={PageName.DigiFiApi}
        productSection={ProductSection.CompanySettingsIntegrations}
      >
        <DigifiApi />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/docu-sign/:tab">
      <ProductSectionAccessCheck
        pageName={PageName.DocuSignIntegration}
        productSection={ProductSection.CompanySettingsIntegrations}
      >
        <DocuSign />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/variables/all">
      <ProductSectionAccessCheck
        pageName={PageName.Variables}
        productSection={ProductSection.CompanySettingsVariables}
      >
        <Variables />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/individual/run/:strategy?">
      <ProductSectionAccessCheck
        pageName={PageName.IndividualProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <IndividualProcessing tab={TabName.RUN} />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/individual/history">
      <ProductSectionAccessCheck
        pageName={PageName.IndividualProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <IndividualProcessing tab={TabName.HISTORY} />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/individual/results/:case">
      <ProductSectionAccessCheck
        pageName={PageName.IndividualProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <ProcessingIndividualCase />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/api/run">
      <ProductSectionAccessCheck
        pageName={PageName.ApiActivation}
        productSection={ProductSection.DecisionEngineApiActivation}
      >
        <ApiActivationPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/batch/run/:strategy?">
      <ProductSectionAccessCheck
        pageName={PageName.BatchProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <BatchProcessing tab={TabName.RUN} />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/batch/history">
      <ProductSectionAccessCheck
        pageName={PageName.BatchProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <BatchProcessing tab={TabName.HISTORY} />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/decision/processing/batch/results/:case?">
      <ProductSectionAccessCheck
        pageName={PageName.BatchProcessing}
        productSection={ProductSection.DecisionEngineProcessing}
      >
        <ProcessingBatchCase />
      </ProductSectionAccessCheck>
    </Route>
    <Route path="/company-settings/send-grid/:tab/">
      <ProductSectionAccessCheck
        pageName={PageName.SendGridIntegration}
        productSection={ProductSection.CompanySettingsIntegrations}
      >
        <SendGridPage />
      </ProductSectionAccessCheck>
    </Route>
    <Route path={AppRoutes.SecureUpload}>
      <SecureFileUploadPage />
    </Route>
    <Route path="/company-settings/billing/:tab?">
      <ProductSectionAccessCheck
        pageName={PageName.PlanAndBilling}
        productSection={ProductSection.CompanySettingsPlanAndBilling}
      >
        <Billing />
      </ProductSectionAccessCheck>
    </Route>
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);
