import { ProcessAutomationState } from './Types';
import {
  ChangeActivationStatusAction,
  ChangeActivationStatusSuccessAction,
  CloseChangeActivationStatusPopupAction,
  GetAutomatedProcessesSuccessAction,
  GetEmailTemplateSuccessAction,
  OpenChangeActivationStatusPopupAction,
  SelectCategoryAction,
  SelectGroupAction,
} from './ActionCreator';
import { ProcessAutomationActionType } from './ActionTypes';
import { AutomatedProcessGroup } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import automatedProcessPagination,
  { AUTOMATED_PROCESSES_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/ProcessAutomationDashboard/Pagination';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';

type ProcessAutomationActions =
  | SelectCategoryAction
  | SelectGroupAction
  | GetAutomatedProcessesSuccessAction
  | GetEmailTemplateSuccessAction
  | OpenChangeActivationStatusPopupAction
  | CloseChangeActivationStatusPopupAction
  | ChangeActivationStatusAction
  | ChangeActivationStatusSuccessAction
  | FailedAction<ProcessAutomationActionType.ChangeActivationStatusFailure>;

const initialState: ProcessAutomationState = {
  group: AutomatedProcessGroup.Standard,
  itemsPerPage: AUTOMATED_PROCESSES_PER_PAGE_DEFAULT,
  page: 1,
  items: [],
  itemsTotal: undefined,
  emailTemplates: [],
  processAutomationIdToChange: null,
  isChangeInProgress: false,
  activationStatusToSet: null,
  error: '',
};

const automatedProcessesReducer = (state = initialState, action: ProcessAutomationActions): ProcessAutomationState => {
  switch (action.type) {
    case ProcessAutomationActionType.SelectCategory: {
      return {
        ...state,
        category: action.payload.category,
        page: 1,
      };
    }

    case ProcessAutomationActionType.SelectGroup: {
      return {
        ...state,
        group: action.payload.group,
        category: undefined,
        page: 1,
      };
    }

    case ProcessAutomationActionType.GetAutomatedProcessesSuccess: {
      return {
        ...state,
        items: action.payload.data.items,
        itemsTotal: action.payload.data.total,
        processAutomationIdToChange: null,
        activationStatusToSet: null,
        isChangeInProgress: false,
      };
    }

    case ProcessAutomationActionType.GetEmailTemplatesSuccess: {
      return {
        ...state,
        emailTemplates: action.payload.data,
      };
    }

    case ProcessAutomationActionType.OpenChangeActivationStatusPopup: {
      return {
        ...state,
        processAutomationIdToChange: action.payload.id,
        activationStatusToSet: action.payload.status,
      };
    }

    case ProcessAutomationActionType.CloseChangeActivationStatusPopup: {
      return {
        ...state,
        processAutomationIdToChange: null,
        activationStatusToSet: null,
      };
    }

    case ProcessAutomationActionType.ChangeActivationStatus: {
      return {
        ...state,
        isChangeInProgress: true,
      };
    }

    case ProcessAutomationActionType.ChangeActivationStatusSuccess: {
      return {
        ...state,
        isChangeInProgress: false,
        processAutomationIdToChange: null,
        activationStatusToSet: null,
      };
    }

    case ProcessAutomationActionType.ChangeActivationStatusFailure: {
      return {
        ...state,
        isChangeInProgress: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default automatedProcessPagination.wrapReducer<ProcessAutomationState>(
  withStateReset(automatedProcessesReducer, ProcessAutomationActionType.ResetState, () => initialState),
);

