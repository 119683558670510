import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { ApplicationDataTabVariableConfigurationsApi } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabVariableConfigurationsActionType } from './ActionTypes';
import {
  updateApplicationDataTabVariableConfigurationSuccess,
  updateApplicationDataTabVariableConfigurationFailure,
  UpdateApplicationDataTabVariableConfigurationAction,
  SuccessUpdateApplicationDataTabVariableConfigurationResult,
} from './ActionCreator';

const UpdateApplicationDataTabVariableConfigurationMiddleware = (api: ApplicationDataTabVariableConfigurationsApi) =>
  Fetcher<
    SuccessUpdateApplicationDataTabVariableConfigurationResult,
    UpdateApplicationDataTabVariableConfigurationAction,
    ReduxState
  >(
    ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfiguration,
    updateApplicationDataTabVariableConfigurationSuccess,
    updateApplicationDataTabVariableConfigurationFailure,
    async (action, state) => {
      const variableConfiguration = await api.update(action.payload.id, {
        position: action.payload.params.position,
        column: action.payload.params.column,
        cardId: action.payload.params.cardId,
      });

      return {
        previousCardId:
          state.applicationDataTabVariableConfigurations.variableConfigurationsById[action.payload.id].cardId,
        variableConfiguration,
      };
    },
  );

export default UpdateApplicationDataTabVariableConfigurationMiddleware;
