import { AnyAction, combineReducers } from 'redux';
import { Reducer } from './reducers/types';

type CombinableReducers = Record<string, Reducer<any, any>>;

type CombinedChildState<ChildReducers extends CombinableReducers> = {
  [Key in keyof ChildReducers]: Parameters<ChildReducers[Key]>[0];
};

type CombinedChildActions<ChildReducers extends CombinableReducers> = Parameters<ChildReducers[keyof ChildReducers]>[1];

const mountChildReducers = <RootState extends {}, Action extends AnyAction, ChildReducers extends CombinableReducers>(
  rootReducer: Reducer<RootState, Action>,
  childReducers: ChildReducers,
) => {
  const combinedChildReducers = combineReducers<CombinedChildState<ChildReducers>>(childReducers);
  const reducerWithChildren: Reducer<
    RootState & CombinedChildState<ChildReducers>,
    Action | CombinedChildActions<ChildReducers>
  > = (state, action) => {
    const nextState = rootReducer(state, action);
    const nextChildState = combinedChildReducers((nextState as unknown) as CombinedChildState<ChildReducers>, action);
    if (nextState === state && nextChildState === state) {
      return state;
    }
    return {
      ...nextState,
      ...nextChildState,
    };
  };
  return reducerWithChildren;
};

export default mountChildReducers;
