import React, { FC } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';

interface DeleteBranchWizardProps {
  closePopUp: () => void;
  isLoading: boolean;
  deleteBranch: () => void;
}

const DeleteBranchWizard: FC<DeleteBranchWizardProps> = ({ closePopUp, isLoading, deleteBranch }) => {
  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title="Delete Branch" closable={!isLoading}>
      <DeletePopup
        message="Do you want to permanently delete this branch?"
        handleDelete={deleteBranch}
        handleClose={closePopUp}
        isLoading={isLoading}
        cancelBtnDisabled={isLoading}
      />
    </PopUpWizard>
  );
};

export default DeleteBranchWizard;
