import { LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import React from 'react';
import styles from './ChartLegend.module.scss';

const SHAPE_STYLES = {
  height: '8px',
  width: '8px',
  borderRadius: '2px',
};

const LEGEND_MARGIN = '0 0 0 4';

interface ChartLegends {
  domain: string[];
  range: string[];
}

const ChartLegend = ({ domain, range }: ChartLegends) => {
  const colorScale = scaleOrdinal<string, string>({
    domain,
    range,
  });

  return (
    <LegendOrdinal
      scale={colorScale}
      direction="row"
      className={styles.legend}
      shapeStyle={() => SHAPE_STYLES}
      legendLabelProps={{ className: styles.label, margin: LEGEND_MARGIN }}
    />
  );
};

export default ChartLegend;
