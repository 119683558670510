import React, { FC } from 'react';
import { Menu, MenuProps } from '@material-ui/core';

const ContextualPopUp: FC<MenuProps> = (props) => {
  return (
    <Menu
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      {...props}
      getContentAnchorEl={null} // needs to be set as null in order to make vertical positioning work
      MenuListProps={{ disablePadding: true }}
    />
  );
};

export default ContextualPopUp;
