import React, { FC } from 'react';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import styles from './DuplicatedApplicationTooltip.module.scss';
import { ShareImageCustomColor } from 'static/images';

interface DuplicatedApplicationTooltipProps {
  originalApplicationDisplayId: string;
}

const DuplicatedApplicationTooltip: FC<DuplicatedApplicationTooltipProps> = ({ originalApplicationDisplayId }) => {
  return (
    <div>
      <p className={styles.title}>Duplicated Application</p>
      <ExternalLink href={`/los/applications/${originalApplicationDisplayId}`} className={styles.link}>
        Open Original <ShareImageCustomColor className={styles.shareIcon} />
      </ExternalLink>
    </div>
  );
};

export default DuplicatedApplicationTooltip;
