import React, { useState } from 'react';
import styles from './RequestCustomerUploadPopup.module.scss';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';

const POPUP_TITLE = (
  <>
    Send Secure Upload Link <br /> to Customer
  </>
);

interface RequestCustomerUploadPopupProps {
  onPopupClose: () => void;
  organizationName: string;
  borrowerEmail: string;
  handleRequestCustomerUpload: (params: { subject: string; description: string }) => Promise<unknown>;
}

const RequestCustomerUploadPopup = ({
  onPopupClose,
  organizationName,
  borrowerEmail,
  handleRequestCustomerUpload,
}: RequestCustomerUploadPopupProps) => {
  const [subject, setSubject] = useState(`${organizationName} Document Upload Request`);
  const [description, setDescription] = useState('');
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);

  const onSubjectChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSubject(e.target.value);
  };

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const requestCustomerUpload = async () => {
    setIsRequestInProgress(true);
    await handleRequestCustomerUpload({
      subject,
      description,
    });
    setIsRequestInProgress(false);
  };

  const isButtonDisabled = !subject || !description;

  return (
    <PopUp title={POPUP_TITLE} closePopUp={onPopupClose}>
      <PopUpContent>
        <TextInput labelTitle="From" value="no-reply@digifi.io" disabled />
        <TextInput labelTitle="Subject" value={subject} onChange={onSubjectChange} maxLength={100} />
        <TextInput labelTitle="Email Address" value={borrowerEmail} disabled />
        <TextInput
          labelTitle="Document Description"
          multiline
          value={description}
          onChange={onDescriptionChange}
          maxLength={1000}
        />
        <Button
          kind="primary"
          size="form"
          className={styles.sendEmailButton}
          disabled={isButtonDisabled}
          onClick={requestCustomerUpload}
          isLoading={isRequestInProgress}
        >
          Send Email
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default RequestCustomerUploadPopup;
