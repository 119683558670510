import { LoanOriginationSystemEditTaskState } from './Types';
import { EditTaskAction, EditTaskSuccessAction, GetTaskDataSuccessAction, ResetEditTaskAction } from './ActionCreator';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';
import { FailedAction } from 'utils/actions/FailedActionCreator';

export const initialState: LoanOriginationSystemEditTaskState = {
  task: null,
  isUpdatingInProgress: false,
};

export type LoanOriginationSystemEditTaskActions =
  | GetTaskDataSuccessAction
  | ResetEditTaskAction
  | EditTaskAction
  | EditTaskSuccessAction
  | FailedAction<LoanOriginationSystemEditTaskActionTypes.EditTaskFailure>;

export const loanOriginationSystemEditTaskReducer = (
  state: LoanOriginationSystemEditTaskState = initialState,
  action: LoanOriginationSystemEditTaskActions,
): LoanOriginationSystemEditTaskState => {
  switch (action.type) {
    case LoanOriginationSystemEditTaskActionTypes.GetTaskSuccess: {
      return {
        ...state,
        task: action.payload.task,
      };
    }

    case LoanOriginationSystemEditTaskActionTypes.ResetEditTask: {
      return {
        ...state,
        task: null,
      };
    }

    case LoanOriginationSystemEditTaskActionTypes.EditTask: {
      return {
        ...state,
        isUpdatingInProgress: true,
      };
    }

    case LoanOriginationSystemEditTaskActionTypes.EditTaskSuccess: {
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    }

    case LoanOriginationSystemEditTaskActionTypes.EditTaskFailure: {
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    }

    default: {
      return state;
    }
  }
};
