import { Application, UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationsSortingType } from 'api/Types';
import Pagination from 'pagination';
import { ReduxState } from 'types/redux';

export const APPLICATIONS_PER_PAGE_DEFAULT = 20;

export interface ApplicationsPaginationParams {
  selectedProduct: Product | null;
  selectedMembers: UserInfo[];
  search: string;
  sortingType: ApplicationsSortingType;
  selectedStatusesIds: string[];
  selectedLabels: Label[];
  selectedIntermediaries: Intermediary[];
  createdDateRange: {
    from: Date | null;
    to: Date | null;
  };
  updatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

const applicationsPagination = Pagination<ReduxState, Application, ApplicationsPaginationParams>(
  'loanOriginationSystemApplications',
  APPLICATIONS_PER_PAGE_DEFAULT,
  () => ['tableViewData'],
);

export default applicationsPagination;
