import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import CoreSystemApi from 'api/Core/CoreSystemApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import { DataViewSortingType, TableViewData } from 'api/LoanOriginationSystem/Types';
import { OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';

interface UpdateUserPrams {
  isActive?: boolean;
  role?: UserRoleName;
}

export interface UpdateUserResult {
  id: string;
  isActive: boolean;
  role: UserRoleName;
  firstName: string;
  lastName: string;
}

export interface UserDataFilter {
  offset: number;
  count: number;
  search: string;
  permissionType: UserRoleName | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
  showInactive: boolean;
}

export enum OrganizationUsersSortingField {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  PermissionType = 'permissionType',
  PhoneAuthentication = 'phoneAuthentication',
  EmailVerified = 'emailVerified',
  AccountStatus = 'accountStatus',
}

export type OrganizationUsersSortingType = DataViewSortingType<OrganizationUsersSortingField>;

export interface UserApi {
  getAll(
    filters?: UserDataFilter,
    sortingType?: OrganizationUsersSortingType,
  ): Promise<TableViewData<OrganizationUser>>;
  getUserInfo(): Promise<UserInfo>;
  update(userId: string, params: UpdateUserPrams): Promise<UpdateUserResult>;
}

export default class UserRestApi extends CoreSystemApi<UserInfo> implements UserApi {
  protected resourceName = 'users';

  public async getAll(filters?: UserDataFilter, sortingType?: OrganizationUsersSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters && filters.permissionType) {
      params.append('permissionType', filters.permissionType);
    }

    if (filters && filters.phoneAuthentication !== null) {
      params.append('phoneAuthentication', filters?.phoneAuthentication!.toString());
    }

    if (filters && filters.emailVerified !== null) {
      params.append('emailVerified', filters.emailVerified.toString());
    }

    if (filters && filters.showInactive) {
      params.append('showInactive', filters.showInactive.toString());
    }

    return this.getResources<TableViewData<OrganizationUser>>(params);
  }

  public async getUserInfo() {
    const currentUser = await this.fetch<UserInfo>('/current-user');
    return currentUser;
  }

  public async update(userId: string, params: UpdateUserPrams) {
    const result = await this.fetch<UpdateUserResult>(`/${this.resourceName}/${userId}`, 'PUT', {
      isActive: params.isActive,
      role: params.role,
    });
    return result;
  }
}
