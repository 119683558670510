import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import { TableBodyCell, TableRow } from 'components/Table';
import TableColumnSize from 'components/LoanOriginationSystem/SendESignaturePopup/FieldsTable/TableColumnSize';

const SkeletonFieldsTableItem = () => (
  <TableRow disableHover>
    <TableBodyCell width={TableColumnSize.FieldName}>
      <SkeletonRectangle width="80%" height="12px" color="primary6" />
    </TableBodyCell>
    <TableBodyCell width={TableColumnSize.FieldValue}>
      <SkeletonRectangle width="80%" height="12px" color="primary6" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonFieldsTableItem;
