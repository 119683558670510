import { IntermediarySuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

const MIN_LENGTH = 2;

const shouldShowSuggestions = (filter: IntermediarySuggestionFilter) => {
  return (
    filter.name?.length >= MIN_LENGTH || filter.phoneNumber?.length >= MIN_LENGTH || filter.email?.length >= MIN_LENGTH
  );
};

export default shouldShowSuggestions;
