import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { Option } from 'components/SelectInput/SelectInput';

export enum ReportingTimeDeltas {
  AllTime = 'All Time',
  ThisYear = 'This Year',
  Last6Months = 'Last 6 Months',
  Last12Months = 'Last 12 Months',
}

export interface ChangeFilterStateConfig {
  entityType: string;
  method: ReportingFilterStateChangeMethods;
  value: string;
}

export interface ReportingFilterBarState {
  open: boolean;
  intermediaries: Option[];
  selectedIntermediaries: string[];
  teamMembers: Option[];
  selectedTeamMembers: string[];
  statuses: ApplicationStatus[];
  selectedStatuses: string[];
  products: Option[];
  selectedProducts: string[];
  labels: Option[];
  selectedLabels: string[];
  timeDelta: ReportingTimeDeltas;
}

export interface FilterInitialData {
  intermediaries: Option[];
  teamMembers: Option[];
  statuses: Option[];
  products: Option[];
  labels: Option[];
}

export enum ReportingFilterStateChangeMethods {
  Add = 'add',
  Delete = 'delete',
}
