import withStateReset from 'utils/reducers/withStateReset';
import { LoanOriginationSystemIntermediariesState } from './Types';
import {
  CLOSE_DELETE_INTERMEDIARY_POPUP,
  DELETE_INTERMEDIARY,
  GET_INTERMEDIARIES_SUCCESS,
  OPEN_DELETE_INTERMEDIARY_POPUP,
  SORT_INTERMEDIARIES,
  LoanOriginationSystemIntermediariesActionType,
  GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS,
} from './ActionTypes';
import { AnyAction } from 'redux';
import intermediariesPagination, {
  INTERMEDIARIES_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/IntermediariesDashboard/Pagination';
import {
  initialState as intermediariesFiltersInitialState,
  loanOriginationSystemIntermediariesFilterReducer,
} from './Filters/Reducer';
import { IntermediariesSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import {
  initialState as createIntermediaryInitialState,
  loanOriginationSystemCreateIntermediaryReducer,
} from './CreateIntermediary/Reducer';
import {
  initialState as editIntermediaryInitialState,
  loanOriginationSystemEditIntermediaryReducer,
} from './EditIntermediary/Reducer';
import {
  CloseDeleteIntermediaryPopupAction,
  DeleteIntermediaryAction,
  GetIntermediariesSuccessAction,
  OpenDeleteIntermediaryPopupAction,
  SortIntermediariesAction,
  GetIntermediaryToDeleteApplicationsSuccessAction,
} from './ActionCreator';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './Filters/ActionTypes';
import {
  ChangePopupFiltersAction,
  FilterIntermediariesByMembersAction,
  ResetAllIntermediariesFilters,
} from './Filters/ActionCreator';

const initialState: LoanOriginationSystemIntermediariesState = {
  filters: intermediariesFiltersInitialState,
  items: [],
  page: 1,
  itemsPerPage: INTERMEDIARIES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  searched: {},
  sortingType: {
    field: IntermediariesSortingField.UpdatedAt,
    ascending: false,
  },
  intermediaryToDeleteId: null,
  intermediaryToDeleteHasApplications: false,
  isDeleteInProgress: false,
  createIntermediary: createIntermediaryInitialState,
  editIntermediary: editIntermediaryInitialState,
};

type LoanOriginationSystemIntermediariesActions =
  | GetIntermediariesSuccessAction
  | SortIntermediariesAction
  | CloseDeleteIntermediaryPopupAction
  | OpenDeleteIntermediaryPopupAction
  | DeleteIntermediaryAction
  | FilterIntermediariesByMembersAction
  | ResetAllIntermediariesFilters
  | ChangePopupFiltersAction
  | GetIntermediaryToDeleteApplicationsSuccessAction;

type LoanOriginationSystemIntermediariesType = (
  state: LoanOriginationSystemIntermediariesState,
  action: AnyAction,
) => LoanOriginationSystemIntermediariesState;

const loanOriginationSystemIntermediariesReducer = (
  state = initialState,
  action: LoanOriginationSystemIntermediariesActions,
): LoanOriginationSystemIntermediariesState => {
  switch (action.type) {
    case GET_INTERMEDIARIES_SUCCESS:
      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.filters.searchInputValue]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: INTERMEDIARIES_PER_PAGE_DEFAULT,
              error: null,
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || INTERMEDIARIES_PER_PAGE_DEFAULT,
        error: null,
        items: action.payload.items,
        itemsTotal: action.payload.total,
        intermediaryToDeleteId: null,
        isDeleteInProgress: false,
      };
    case SORT_INTERMEDIARIES:
      return {
        ...state,
        sortingType: action.payload,
      };
    case OPEN_DELETE_INTERMEDIARY_POPUP:
      if (state.intermediaryToDeleteHasApplications) return state;

      return {
        ...state,
        intermediaryToDeleteId: action.payload.intermediaryToDeleteId,
      };
    case CLOSE_DELETE_INTERMEDIARY_POPUP:
      return {
        ...state,
        intermediaryToDeleteId: null,
      };
    case DELETE_INTERMEDIARY:
      return {
        ...state,
        isDeleteInProgress: true,
      };
    case GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS:
      return {
        ...state,
        intermediaryToDeleteHasApplications: action.payload.applications.total > 0,
      };
    case LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters:
    case LoanOriginationSystemIntermediariesFiltersActionTypes.FilterIntermediariesByMembers:
    case LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        itemsTotal: undefined,
        filters: loanOriginationSystemIntermediariesFilterReducer(state.filters, action),
      };
    }
    default:
      return {
        ...state,
        filters: loanOriginationSystemIntermediariesFilterReducer(state.filters, action),
        createIntermediary: loanOriginationSystemCreateIntermediaryReducer(state.createIntermediary, action),
        editIntermediary: loanOriginationSystemEditIntermediaryReducer(state.editIntermediary, action),
      };
  }
};

export default intermediariesPagination.wrapReducer<LoanOriginationSystemIntermediariesState>(
  withStateReset(
    loanOriginationSystemIntermediariesReducer,
    LoanOriginationSystemIntermediariesActionType.ResetState,
    () => initialState,
  ) as LoanOriginationSystemIntermediariesType,
);
