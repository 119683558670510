import {
  VariableClientType,
  VisualDataType,
  VariableAdvancedDataTypeAttributes,
  VisualDataTypeWithAttributes,
  Variable,
  NewVariableApiType,
  VariableAccessPermissionType,
} from './VariablesTypes';
import getMessage, { MessageType } from 'constants/messages';
import { unhandledCase } from 'utils/unhandledCase';
import { VARIABLE_DATA_TYPES } from 'components/VariableForm/DataTypes';

export type VariableData = Pick<Variable, 'dataType' | 'numberFormat' | 'stringFormat' | 'optionsList'>;

export const getVisualDataType = (variableData: VariableData): VisualDataType => {
  const { dataType, numberFormat, stringFormat, optionsList } = variableData;
  switch (dataType) {
    case 'Boolean': {
      return 'Boolean';
    }
    case 'Date': {
      return 'Date';
    }
    case 'Number': {
      return numberFormat || 'Number';
    }
    case 'String': {
      if (optionsList && optionsList.length) {
        return 'List';
      }
      if (stringFormat) {
        return stringFormat;
      }
      return 'Text';
    }
    default: {
      return dataType;
    }
  }
};

export const getVisualDataTypeWithAttributes = (
  variable: Pick<
    Variable,
    | 'dataType'
    | 'phoneNumberFormat'
    | 'identificationNumberType'
    | 'identificationNumberDescription'
    | 'currency'
    | 'dateFormat'
    | 'optionsList'
    | 'numberFormat'
    | 'stringFormat'
  >,
): VisualDataTypeWithAttributes => {
  const dataType = getVisualDataType(variable);
  if (dataType === 'PhoneNumber') {
    return {
      dataType,
      phoneNumberFormat: variable.phoneNumberFormat,
    };
  }

  if (dataType === 'IdentificationNumber') {
    return {
      dataType,
      identificationNumberType: variable.identificationNumberType,
      identificationNumberDescription: variable.identificationNumberDescription,
    };
  }
  if (dataType === 'Monetary') {
    return {
      dataType,
      currency: variable.currency,
    };
  }
  if (dataType === 'Date') {
    return {
      dataType,
      dateFormat: variable.dateFormat,
    };
  }
  if (dataType === 'List') {
    return {
      dataType,
      optionsList: variable.optionsList!,
    };
  }
  return {
    dataType,
  };
};

const EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES = {
  numberFormat: null,
  stringFormat: null,
  optionsList: null,
  phoneNumberFormat: null,
  identificationNumberType: null,
  identificationNumberDescription: null,
  currency: null,
  dateFormat: null,
};

export const getVariableType = (variable: VariableClientType): VariableAdvancedDataTypeAttributes => {
  const { dataType } = variable;
  switch (dataType) {
    case 'Boolean': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Boolean',
      };
    }
    case 'Date': {
      const { dateFormat } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Date',
        dateFormat,
      };
    }
    case 'Monetary': {
      const { currency } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
        numberFormat: 'Monetary',
        currency,
      };
    }
    case 'Percentage': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
        numberFormat: 'Percentage',
      };
    }
    case 'Number': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'Number',
      };
    }
    case 'List': {
      const { optionsList } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        optionsList,
      };
    }
    case 'Text': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
      };
    }
    case 'LargeText': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
      };
    }
    case 'PhoneNumber': {
      const { phoneNumberFormat } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'PhoneNumber',
        phoneNumberFormat,
      };
    }
    case 'EmailAddress': {
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'EmailAddress',
      };
    }
    case 'IdentificationNumber': {
      const { identificationNumberType, identificationNumberDescription } = variable;
      return {
        ...EMPTY_ADVANCED_DATA_TYPE_ATTRIBUTES,
        dataType: 'String',
        stringFormat: 'IdentificationNumber',
        identificationNumberType,
        identificationNumberDescription,
      };
    }
    default: {
      return unhandledCase(dataType);
    }
  }
};

export const getVariableDataTypeName = (dataType: VisualDataType): string => {
  const option = VARIABLE_DATA_TYPES.find(({ value }) => value === dataType)!;
  return option.name;
};

export const DATA_TYPES_PARTIAL_READ_SUPPORTED: VisualDataType[] = ['Text', 'IdentificationNumber', 'List'];

export const validateVariableUserAccessPermissions = (variable: NewVariableApiType) => {
  const visualDataType = getVisualDataType(variable);
  if (
    Object.values(variable.userAccessPermissions).includes(VariableAccessPermissionType.PartialRead) &&
    !DATA_TYPES_PARTIAL_READ_SUPPORTED.includes(visualDataType)
  ) {
    const dataTypeName = getVariableDataTypeName(visualDataType);
    const errorMessage = getMessage(MessageType.VariablePartiallyHiddenUnsupported, { dataType: dataTypeName });
    throw new TypeError(errorMessage);
  }
};
