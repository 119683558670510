import getMessage from 'constants/messages';
import { validatePassword } from 'utils/validation/validation';

export interface ResetPasswordFormData {
  password: string;
  confirmPassword: string;
}

export type ValidateResetPasswordFormResult = Partial<Record<keyof ResetPasswordFormData, string>>;

const validateResetPasswordForm = (data: ResetPasswordFormData): ValidateResetPasswordFormResult => {
  const errors: ValidateResetPasswordFormResult = {};

  const passwordErrorMessageType = validatePassword(data.password);

  if (passwordErrorMessageType) {
    errors.password = getMessage(passwordErrorMessageType);
  }

  if (data.confirmPassword.length === 0) {
    errors.confirmPassword = 'Password confirmation is required';
  }

  if (data.confirmPassword.length > 0 && data.password !== data.confirmPassword) {
    errors.confirmPassword = 'Password confirmation does not match password';
  }

  return errors;
};

export default validateResetPasswordForm;
