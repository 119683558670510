import APIRest from './APIRest';
import { DownloadAPI } from './Types';

const DOWNLOAD_API = {
  DOWNLOAD_API_TEMPLATE_URL: '/api/download_request/json/rules_engine',
  DOWNLOAD_BATCH_PROCESS_TEMPLATE: '/simulation/api/download/variables',
  DOWNLOAD_SIMULATION_RESULTS_URL: '/simulation/api/simulation_results',
  DOWNLOAD_SIMULATION_URL: '/simulation/api/download/case',
  DOWNLOAD_TRANSACTIONS: '/payment/downloadTransactions',
};

class DownloadAPIRest extends APIRest implements DownloadAPI {
  protected origin = process.env.REACT_APP_DE_API_URL;

  protected async getCommonParams(): Promise<URLSearchParams> {
    const params = new URLSearchParams();
    params.set('export', 'file');
    return params;
  }

  async downloadApiTemplateURL(strategyId: string, type: string): Promise<void> {
    const params = await this.getCommonParams();
    const url = `${this.origin}${DOWNLOAD_API.DOWNLOAD_API_TEMPLATE_URL}/${strategyId}/${type}?${params}`;
    window.open(url);
  }

  downloadRulesTemplate(moduleType: string, strategyId: string, moduleKey: string, branchIndex: number): void {
    const params = new URLSearchParams();
    params.set('type', moduleType);
    params.set('format', 'json');
    params.set('export', 'file');
    params.set('moduleId', moduleKey);
    params.set('segment', branchIndex.toString());

    const url = `${this.origin}/decision/api/strategies/${strategyId}/downloads/rules?${params}`;
    window.open(url);
  }

  async downloadBatchTemplate(strategyId: string): Promise<void> {
    const params = await this.getCommonParams();
    params.set('download', 'true');
    params.set('export_format', 'csv');
    const url = `${this.origin}${DOWNLOAD_API.DOWNLOAD_BATCH_PROCESS_TEMPLATE}/${strategyId}?${params}`;
    window.open(url);
  }

  downloadSimulationResults(caseId: string) {
    const params = new URLSearchParams();

    params.set('export', 'file');
    params.set('section', 'batch');

    window.open(`${this.origin}${DOWNLOAD_API.DOWNLOAD_SIMULATION_RESULTS_URL}/${caseId}/download?${params}`);
  }

  downloadSimulation(caseId: string) {
    const params = new URLSearchParams();

    params.set('export', 'file');

    window.open(`${this.origin}${DOWNLOAD_API.DOWNLOAD_SIMULATION_URL}/${caseId}?${params}`);
  }

  async downloadTransactions(): Promise<void> {
    const params = await this.getCommonParams();

    window.open(`${this.origin}${DOWNLOAD_API.DOWNLOAD_TRANSACTIONS}?${params}`);
  }
}

export default DownloadAPIRest;
