const BorrowerDefaultVariable = {
  FirstName: 'borrower_first_name',
  LastName: 'borrower_last_name',
  CompanyName: 'borrower_company_name',
  PersonalIdNumber: 'borrower_personal_id_number',
  CompanyIdNumber: 'borrower_company_id_number',
  PhoneNumber: 'borrower_phone',
  Email: 'borrower_email',
  DateOfBirth: 'borrower_date_of_birth',
  Street: 'borrower_street_address',
  City: 'borrower_city',
  State: 'borrower_state_or_province',
  Zip: 'borrower_zip_or_postal_code',
  Country: 'borrower_country',
};

export type BorrowerDefaultVariableType = typeof BorrowerDefaultVariable;

export default BorrowerDefaultVariable;
