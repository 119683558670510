import React, { FC, useState } from 'react';

import DeleteDecisionStrategyModule from 'components/StrategyOverview/DecisionProcess/Module/Popups/DeleteDecisionStrategyModule';

import PopUpWizard from 'components/PopUps/PopUpWizard';

interface WizardProps {
  closePopUp: () => void;
  isOpen: boolean;
  title: string;
  deleteModule: (strategyId: string, moduleLookupName: string) => Promise<void>;
  moduleLookupName: string;
  strategyId: string;
}

const DeleteDecisionStrategyModuleWizard: FC<WizardProps> = (props) => {
  const { closePopUp, isOpen, title, deleteModule, moduleLookupName, strategyId } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteModule = async () => {
    setIsDeleting(true);
    try {
      await deleteModule(strategyId, moduleLookupName);
    } finally {
      setIsDeleting(false);
      closePopUp();
    }
  };

  if (!isOpen) return null;

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title={title} closable={!isDeleting}>
      <DeleteDecisionStrategyModule
        deleteModule={handleDeleteModule}
        moduleLookupName={moduleLookupName}
        strategyId={strategyId}
        closePopUp={closePopUp}
        isLoading={isDeleting}
      />
    </PopUpWizard>
  );
};

export default DeleteDecisionStrategyModuleWizard;
