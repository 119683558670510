import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { UpdateProductActionOrigin, UpdateProductSuccessAction } from './ActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';

const isUpdateApplicationFormSettingsSuccessAction = (action: ActionWithOrigin): action is UpdateProductSuccessAction =>
  action.type === LoanOriginationSystemProductsActionType.UpdateProductSuccess &&
  action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings;

const notifyOnApplicationFormSettingsUpdateMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateApplicationFormSettingsSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.FormSettingSaved), 'success', dispatch);
  }

  return result;
};

export default notifyOnApplicationFormSettingsUpdateMiddleware;
