import React from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import VariableForm, { VariableFormProps } from 'components/VariableForm';
import AsideLink from 'components/AsideLink/AsideLink';
import VariableFormButtons from 'components/Variable/VariableFormButtons';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';

interface CreateVariablePopupProps extends Pick<VariableFormProps, 'onSave'> {
  onClose: () => void;
  isSavingInProgress?: boolean;
  className?: string;
}

const CreateVariablePopup = ({ onClose, onSave, isSavingInProgress, className }: CreateVariablePopupProps) => {
  const { regionSettings } = useOrganizationDetailedInfo();

  const topRightElement = (
    <AsideLink href="/decision/variables/all" external>
      Open List of Variables
    </AsideLink>
  );

  return (
    <PopUp classNames={{ popup: className }} title="Create Variable" closePopUp={onClose}>
      <PopUpContent>
        <VariableForm regionSettings={regionSettings} onSave={onSave} topRightElement={topRightElement}>
          <VariableFormButtons isSavingInProgress={isSavingInProgress} onCancel={onClose} />
        </VariableForm>
      </PopUpContent>
    </PopUp>
  );
};

export default CreateVariablePopup;
