import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonHeader.module.scss';

const SkeletonHeader = () => (
  <div className={styles.skeletonHeader}>
    <div className={styles.skeletonTitle}>
      <SkeletonRectangle width="302px" height="44px" color="primary6" />
      <SkeletonRectangle width="113px" height="20px" color="primary6" />
    </div>
    <SkeletonRectangle width="60px" height="60px" color="primary6" />
  </div>
);

export default SkeletonHeader;
