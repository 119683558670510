import Debouncer from 'middlewares/Debouncer';
import { getTasks } from 'LoanOriginationSystemTasks/ActionCreator';
import { LoanOriginationSystemTasksFiltersActionTypes } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export default Debouncer<UserInfo[]>(
  (payload, getState) => {
    const { loanOriginationSystemTasks } = getState();
    const { filters, sortingType, selectedApplicationId: applicationId } = loanOriginationSystemTasks;

    return getTasks({ filters, sortingType, applicationId });
  },
  [LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers],
);
