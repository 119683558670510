import React from 'react';
import { ApplicationHistoryRecord, ApplicationHistoryType } from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import {
  StatusTransitionIcon,
  ApplicationCreatedIcon,
  TaskCompletedIcon,
  TaskDeletedIcon,
  EmailCreatedIcon,
  EmailDeletedIcon,
  DocumentUploadedIcon,
  DocumentDeletedIcon,
  NoteCreatedIcon,
  NoteDeletedIcon,
} from 'static/images';
import styles from './HistoryRecord.module.scss';
import formatDisplayId from 'utils/userIdFormat';

export interface HistoryListItemProps {
  record: ApplicationHistoryRecord;
}

const getHistoryRecordParams = (record: ApplicationHistoryRecord) => {
  switch (record.type) {
    case ApplicationHistoryType.ApplicationCreated: {
      return {
        title: 'Application Created',
        icon: <ApplicationCreatedIcon />,
        description: `The application for ${record.data.borrowerName} was created.`,
      };
    }
    case ApplicationHistoryType.ApplicationDuplicated: {
      return {
        title: 'Application Created',
        icon: <ApplicationCreatedIcon />,
        description: `The application for ${
          record.data.borrowerName
        } was created as a duplicate from Application ${formatDisplayId(record.data.originalApplicationDisplayId)}.`,
      };
    }
    case ApplicationHistoryType.StatusTransition: {
      return {
        title: 'Changed Application Status',
        icon: <StatusTransitionIcon />,
        description: `This status of ${record.data.name}’s application was updated from ${record.data.transitionFrom} to ${record.data.transitionTo}.`,
      };
    }
    case ApplicationHistoryType.TaskCreated: {
      return {
        title: 'Added New Task',
        description: `The following task was created: “${record.data.description}”.`,
        icon: <TaskCompletedIcon />,
      };
    }
    case ApplicationHistoryType.TaskCompleted: {
      return {
        title: 'Completed Task',
        description: `The following task was completed: “${record.data.description}”.`,
        icon: <TaskCompletedIcon />,
      };
    }
    case ApplicationHistoryType.TaskDeleted: {
      return {
        title: 'Deleted Task',
        description: `The following task was deleted: “${record.data.description}”.`,
        icon: <TaskDeletedIcon />,
      };
    }
    case ApplicationHistoryType.EmailCreated: {
      return {
        title: 'Email',
        description: `An email with the subject “${record.data.subject}” was sent from ${record.data.from} to ${record.data.to}.`,
        icon: <EmailCreatedIcon />,
      };
    }
    case ApplicationHistoryType.EmailDeleted: {
      return {
        title: 'Deleted Email',
        description: `An email with the subject “${record.data.subject}” from ${record.data.from} to ${record.data.to} was deleted.`,
        icon: <EmailDeletedIcon />,
      };
    }
    case ApplicationHistoryType.DocumentCreated: {
      return {
        title: 'Uploaded Document',
        description: `The following document was uploaded: ${record.data.documentName}.`,
        icon: <DocumentUploadedIcon />,
      };
    }
    case ApplicationHistoryType.DocumentDeleted: {
      return {
        title: 'Deleted Document',
        description: `The following document was deleted: ${record.data.documentName}.`,
        icon: <DocumentDeletedIcon />,
      };
    }
    case ApplicationHistoryType.NoteCreated: {
      return {
        title: 'Created Note',
        description: `The following note was created: ${record.data.description}.`,
        icon: <NoteCreatedIcon />,
      };
    }
    case ApplicationHistoryType.NoteDeleted: {
      return {
        title: 'Deleted Note',
        description: `The following note was deleted: ${record.data.description}.`,
        icon: <NoteDeletedIcon />,
      };
    }
    default: {
      return {
        title: '',
        description: '',
        icon: null,
      };
    }
  }
};

const HistoryRecord = ({ record }: HistoryListItemProps) => {
  const { title, icon, description } = getHistoryRecordParams(record);

  return (
    <div className={styles.record}>
      <div className={styles.icon}>{icon}</div>
      <div className={styles.recordContent}>
        <h6 className={styles.contentTitle}>{title}</h6>
        <p className={styles.contentDescription}>{description}</p>
      </div>
      <div className={styles.recordInfo}>
        <WithSystemApiUserAvatar size="tiny" user={record.createdBy} />
        <p>{formatDate(record.createdAt, DateTimeFormat.Long)}</p>
      </div>
    </div>
  );
};

export default HistoryRecord;
