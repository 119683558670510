import React from 'react';
import styles from './SkeletonAutomatedProcess.module.scss';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';

const SkeletonAutomatedProcess = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <SkeletonCircle color="primary10" width="60px" height="60px" className={styles.icon} />
        <SkeletonRectangle color="primary10" width="100%" height="16px" className={styles.autoCompletion} />
        <SkeletonRectangle color="primary10" width="100%" height="16px" className={styles.autoCompletion} />
        <SkeletonRectangle color="primary10" width="58%" height="16px" className={styles.autoCompletion} />
      </div>
      <div className={styles.activationContainer}>
        <SkeletonRectangle color="primary10" width="36%" height="16px" />
        <SkeletonRectangle color="primary10" width="13%" height="16px" />
      </div>
    </div>
  );
};

export default SkeletonAutomatedProcess;
