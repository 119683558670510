import React, { useState } from 'react';
import PasswordInput from 'components/PasswordInput';
import Button from 'components/Button';
import styles from './ConfirmPasswordForm.module.scss';
import { confirmPassword } from 'AccountDetails/AccountDetailsActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const INCORRECT_PASSWORD_ERROR = 'Password is incorrect.';

const ConfirmPasswordForm = ({ onPasswordConfirmed }: { onPasswordConfirmed: () => void }) => {
  const dispatchRoutine = useDispatchRoutine();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await dispatchRoutine(confirmPassword(password));
      onPasswordConfirmed();
    } finally {
      setError(INCORRECT_PASSWORD_ERROR);
      setLoading(false);
    }
  };

  return (
    <>
      <p className={styles.description}>Please verify your password to confirm your identity.</p>
      <PasswordInput
        placeholder="Enter your password"
        labelTitle="Your Password"
        containerClassName={styles.passwordInputContainer}
        onChange={(event) => setPassword(event.target.value)}
        errorMessage={error}
      />
      <Button size="form" onClick={handleSubmit} isLoading={loading} disabled={!password}>
        Continue
      </Button>
    </>
  );
};

export default ConfirmPasswordForm;
