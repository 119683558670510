import React, { useMemo } from 'react';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import useStandardVariables from 'hooks/useStandardVariables';
import { RuleBuilderCoreData, RuleRequestData } from 'RuleCreate/Types';
import PopUp from 'components/PopUps/PopUp';
import RuleBuilder from 'components/RuleBuilder';
import { RuleBuilderState } from 'components/RuleBuilder/Types';

export interface EditStatusRulePopupProps {
  onPopupClose: () => void;
  onRuleSave: (multipleRules: RuleBuilderCoreData[]) => void;
  openVariableCreation: () => void;
  rule?: ApplicationStatusRule;
  isSaving?: boolean;
}

const EditStatusRulePopup = ({
  onRuleSave,
  onPopupClose,
  openVariableCreation,
  rule,
  isSaving,
}: EditStatusRulePopupProps) => {
  const standardVariables = useStandardVariables();

  const serializedRules: RuleBuilderState[] | undefined = useMemo(() => {
    return rule?.multipleRules.map((multipleRule, index) => ({
      sourceVariableId: multipleRule.sourceVariable.id,
      selectedComparison: multipleRule.comparisonOperator,
      firstOperandValue: multipleRule.firstOperandValue,
      firstOperandVariableId: multipleRule.firstOperandVariable?.id,
      secondOperandValue: multipleRule.secondOperandValue,
      secondOperandVariableId: multipleRule.secondOperandVariable?.id,
      firstOperandType: multipleRule.firstOperandType,
      secondOperandType: multipleRule.secondOperandType,
      ruleId: index,
    }));
  }, [rule]);

  if (!standardVariables) {
    return null;
  }

  const handleSaveRule = ({ rulesList }: RuleRequestData) => {
    onRuleSave(rulesList);
  };

  return (
    <PopUp closePopUp={onPopupClose} title={serializedRules ? 'Edit Status Rule' : 'Add Status Rule'}>
      <RuleBuilder
        saveRule={handleSaveRule}
        type="simple"
        openVariableCreation={openVariableCreation}
        isSaving={isSaving}
        moduleType={ModuleType.RequirementsRules}
        serializedRules={serializedRules}
        withoutDeclineReason
      />
    </PopUp>
  );
};

export default EditStatusRulePopup;
