import React from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import UploadApplicationDocumentForm from 'components/LoanOriginationSystem/UploadApplicationDocumentForm';

interface UploadApplicationDocumentPopupProps {
  onPopupClose: () => void;
  onApplicationDocumentUpload: (file: File) => Promise<void>;
  isLoading: boolean;
}

const UploadApplicationDocumentPopup = ({
  onPopupClose,
  onApplicationDocumentUpload,
  isLoading,
}: UploadApplicationDocumentPopupProps) => {
  return (
    <PopUp title="Upload Document" closePopUp={onPopupClose} closable={!isLoading}>
      <PopUpContent hasTopMargin>
        <UploadApplicationDocumentForm
          onApplicationDocumentUpload={onApplicationDocumentUpload}
          buttonText="Upload File"
        />
      </PopUpContent>
    </PopUp>
  );
};

export default UploadApplicationDocumentPopup;
