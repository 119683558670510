import AddModule from 'components/AddModulePage';
import { getIntegrationsList } from 'ExternalIntegrationsList/ActionCreator';
import { IntegrationsListState } from 'ExternalIntegrationsList/Types';
import { createNewModuleRequest } from 'ModuleInfo/ModuleInfoActionCreator';
import { CopyModuleData, CreateModuleData } from 'ModuleInfo/Types';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { duplicateModule } from 'DuplicateModule/Actions';
import { useQueryParams } from 'hooks/useQueryParam';
import { useDispatchRoutine } from 'middlewares/Fetcher';

interface AddModuleContainerTypes {
  strategyId: string;
  onClose: () => void;
}

const AddModuleContainer: FC<AddModuleContainerTypes> = ({ strategyId, onClose }) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const urlParams = useQueryParams();
  const moduleIndex = urlParams.get('module-index')!;
  const { integrations }: IntegrationsListState = useSelector((state: ReduxState) => state.externalIntegrations);

  const onCreateNewModuleRequest = async (
    moduleData: Pick<CreateModuleData, 'name' | 'currentType' | 'moduleIndex'>,
  ): Promise<void> => {
    await dispatchRoutine(createNewModuleRequest({ strategyId, ...moduleData }));
  };

  const onModuleCopy = async ({ strategyId: copyFromStrategyId, ...moduleParams }: CopyModuleData['moduleParams']) => {
    await dispatchRoutine(
      duplicateModule({
        copyToStrategyId: strategyId,
        copyFromStrategyId,
        ...moduleParams,
      }),
    );
  };

  const getExternalIntegrationsList = (): void => {
    dispatch(getIntegrationsList());
  };

  return (
    <AddModule
      onModuleSave={onCreateNewModuleRequest}
      onModuleCopy={onModuleCopy}
      onClose={onClose}
      integrationsList={integrations}
      getIntegrationsList={getExternalIntegrationsList}
      moduleIndex={parseInt(moduleIndex, 10)}
    />
  );
};

export default AddModuleContainer;
