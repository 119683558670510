import React from 'react';
import clsx from 'clsx';
import InfoPanelContentItem from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel/InfoPanelContent/InfoPanelContentItem';
import styles from './InfoCardColumn.module.scss';

interface InfoCardColumnItem {
  label: string;
  content: React.ReactNode;
}

interface InfoCardColumnProps {
  items: InfoCardColumnItem[];
  className?: string;
  itemClassName?: string;
}

const InfoCardColumn = ({ items, className, itemClassName }: InfoCardColumnProps) => (
  <div className={clsx(styles.infoCardColumn, className)}>
    {items.map(({ label, content }) => (
      <InfoPanelContentItem className={itemClassName} label={label} key={label}>
        {content}
      </InfoPanelContentItem>
    ))}
  </div>
);

export default InfoCardColumn;
