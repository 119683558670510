import { DecisionEngineApi } from 'api/Types';
import { getBranchInfoRequest } from 'BranchInfo/ActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { deleteRuleFailure, deleteRuleSuccess } from './ActionCreator';
import { DELETE_RULE, DELETE_RULE_SUCCESS } from './ActionTypes';
import { CompletedDeleteRuleData, DeleteRuleAction } from './Types';
import getMessage, { MessageType } from 'constants/messages';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/DecisionStrategyUpdatesActionCreator';

export const DeleteRuleMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompletedDeleteRuleData, DeleteRuleAction>(
    DELETE_RULE,
    deleteRuleSuccess,
    deleteRuleFailure,
    async ({ payload }) => {
      await api.deleteRule(payload);
      return payload;
    },
  );

export const onDeleteRuleSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === DELETE_RULE_SUCCESS) {
    const { strategyId, moduleKey, branchIndex } = action.payload;
    dispatch(getBranchInfoRequest({ strategyId, moduleKey, branchIndex }));
    dispatch(getDecisionStrategyUpdatesRequest(strategyId));
    notification.createNotification(getMessage(MessageType.RuleDeleted), 'success', dispatch);
  }
  return result;
};
