/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import PasswordInput from 'components/PasswordInput';
import styles from './SignInForm.module.scss';
import TopRightLink from 'components/TopRightInputLink';
import TextInput from 'components/TextInput';
import validateSignInForm, { ValidateSignInFormResult } from './validateSignInForm';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import FormFooterLink from 'components/Auth/FormFooterLink';
import { useRecaptcha } from 'CaptchaProvider';
import CaptchaAction from 'enums/CaptchaAction';
import { SignInData } from 'api/Core/AuthApi';
import isAutofilled, { AUTOFILL_DETECTION_TIMEOUT } from 'utils/isAutofilled';
import EnvironmentWrapper from 'components/EnvironmentWrapper';

const StartFreeTrialLinkWrapper = EnvironmentWrapper(
  FormFooterLink,
  process.env.REACT_APP_DISABLE_CREATE_ACCOUNT?.toLocaleLowerCase(),
  'false',
  undefined,
);

interface SignInFormProps {
  onSubmit: (userCredentials: SignInData) => Promise<void>;
}

const SignInForm: FC<SignInFormProps> = ({ onSubmit }) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<ValidateSignInFormResult>({});
  const [hasAutofilled, setHasAutofilled] = useState(false);

  const executeCaptcha = useRecaptcha();

  const handleChangeEmailValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ password: errors.password });

    setEmail(e.target.value);
  };

  const handleChangePasswordValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ email: errors.email });

    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    const validationResult = validateSignInForm({ email, password });

    setErrors(validationResult);

    if (Object.keys(validationResult).length === 0) {
      const token = await executeCaptcha(CaptchaAction.SignIn);

      if (!token) {
        return;
      }

      await onSubmit({ password, email, token });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setHasAutofilled(isAutofilled(emailInputRef.current!) && isAutofilled(passwordInputRef.current!));
    }, AUTOFILL_DETECTION_TIMEOUT);
  }, []);

  const submitIsDisabled = Object.keys(validateSignInForm({ email, password })).length > 0 && !hasAutofilled;

  return (
    <div className={styles.loginFormContent}>
      <TextInput
        inputRef={emailInputRef}
        labelTitle="Email"
        onChange={handleChangeEmailValue}
        value={email}
        tabIndex={1}
        errorMessage={errors.email}
        containerClassName={styles.emailInput}
        onKeyDown={TriggerEventOnEnterKeyDown(passwordInputRef, 'focus')}
        autoFocus
      />
      <PasswordInput
        ref={passwordInputRef}
        labelTitle="Password"
        topRightElement={<TopRightLink path="/auth/forgot">Reset Password</TopRightLink>}
        tabIndex={2}
        onChange={handleChangePasswordValue}
        value={password}
        errorMessage={errors.password}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      <ButtonWithLoadingState
        ref={submitButtonRef}
        disabled={submitIsDisabled}
        tabIndex={3}
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        className={styles.submitButton}
      >
        Sign In
      </ButtonWithLoadingState>
      <StartFreeTrialLinkWrapper linkText="Start Free Trial" text="New User?" to="/auth/create-account" />
    </div>
  );
};

export default SignInForm;
