import { AnyAction, Middleware } from 'redux';
import OrganizationActionType from './ActionTypes';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { RoutineHandler } from 'middlewares/Fetcher';

export const onChangeUserSuccessMiddleware: Middleware = RoutineHandler(async (action: AnyAction, { dispatch }) => {
  if (action.type === OrganizationActionType.ChangeOrganizationInfoSuccess) {
    notification.createNotification(getMessage(MessageType.CompanyInformationUpdated), 'success', dispatch);
    dispatch(getOrganizationInfo());
  }
});

export default onChangeUserSuccessMiddleware;
