import { AnyAction } from 'redux';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { ReportingState } from './Types';

const initialState: ReportingState = {
  reportingEntitiesMap: {},
  currencyType: '',
  loading: false,
};

const ReportingReducer = (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case LoanOriginationSystemReportingActionType.GetReportingData: {
      return {
        ...state,
        loading: true,
      };
    }
    case LoanOriginationSystemReportingActionType.GetReportingDataSuccess: {
      const { applicationReportingData, currencyType } = payload;
      return {
        reportingEntitiesMap: applicationReportingData,
        currencyType,
        loading: false,
      };
    }
    case LoanOriginationSystemReportingActionType.GetReportingDataFailure: {
      return {
        reportingEntitiesMap: {},
        currencyType: '',
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default ReportingReducer;
