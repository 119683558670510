import React, { ChangeEvent, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import styles from './RenameApplicationDocumentPopup.module.scss';

interface UploadApplicationDocumentPopupProps {
  onPopupClose: () => void;
  onRenameApplicationDocument: (updatedName: string) => void;
  isUpdating: boolean;
  fileName: string;
}

const RenameApplicationDocumentPopup = ({
  isUpdating,
  fileName,
  onRenameApplicationDocument,
  onPopupClose,
}: UploadApplicationDocumentPopupProps) => {
  const [name, setName] = useState<string>(fileName);

  const handleRenameDocument = () => {
    onRenameApplicationDocument(name);
  };

  return (
    <PopUp title="Rename" closePopUp={onPopupClose} closable={!isUpdating}>
      <PopUpContent className={styles.popupContent}>
        <TextInput
          containerClassName={styles.nameInputContainer}
          value={name}
          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setName(target.value)}
        />
        <div className={styles.buttonContainer}>
          <Button disabled={!name} onClick={handleRenameDocument} kind="primary" size="form" isLoading={isUpdating}>
            Save Changes
          </Button>
          <Button
            kind="secondary"
            size="form"
            onClick={onPopupClose}
            disabled={isUpdating}
            className={styles.cancelButton}
          >
            Cancel
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default RenameApplicationDocumentPopup;
