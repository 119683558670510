import React, { forwardRef } from 'react';
import styles from './Avatar.module.scss';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

type PreSetTextSize = 'small' | 'large' | 'medium' | 'tiny';

export type TextSize = number | PreSetTextSize;

interface AvatarProps {
  size?: TextSize | number;
  tooltip?: string;
  children?: React.ReactElement;
  className?: string;
  onClick?: () => void;
}

const getComponentSize = (size: TextSize | number): number => {
  if (typeof size === 'string') {
    // eslint-disable-next-line default-case
    switch (size) {
      case 'tiny':
        return 8.5;
      case 'small':
        return 10;
      case 'medium':
        return 13;
      case 'large':
        return 25;
    }
  }
  return size;
};

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ size = 'medium', tooltip = '', children, className, onClick }, ref) => {
    return (
      <WrapperWithTooltip className={styles.tooltip} tooltip={tooltip}>
        <div className={clsx(styles.avatarContainer, className)} onClick={onClick} ref={ref}>
          <div className={styles.avatar} style={{ fontSize: getComponentSize(size) }}>
            {children}
          </div>
        </div>
      </WrapperWithTooltip>
    );
  },
);

export default Avatar;
