import React from 'react';
import BaseTabLayout, {
  BaseTabLayoutProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/BaseTabLayout';
import styles from './SuccessTab.module.scss';

export interface SuccessTabProps extends BaseTabLayoutProps {
  icon: React.ReactNode;
  tabType: string;
}

const SuccessTab = ({ icon, tabType, ...restProps }: SuccessTabProps) => (
  <BaseTabLayout {...restProps}>
    <div className={styles.tabContent}>
      <div className={styles.iconContainer}>{icon}</div>
      <h5 className={styles.successTitle}>
        Success{' '}
        <span aria-label="success" role="img">
          🎉
        </span>
      </h5>
      <p className={styles.description}>The {tabType} tab has been activated for your product.</p>
    </div>
  </BaseTabLayout>
);

export default SuccessTab;
