import CoreSystemApi from 'api/Core/CoreSystemApi';

export interface IPasswordConfirmationApi {
  confirmPassword: (password: string) => Promise<void>;
}

export default class PasswordConfirmationRestApi extends CoreSystemApi implements IPasswordConfirmationApi {
  protected resourceName = '/password-confirmations';

  public async confirmPassword(password: string) {
    await this.fetch(this.resourceName, 'POST', { password });
  }
}
