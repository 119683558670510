import React from 'react';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonSubtitle.module.scss';

const SkeletonSubtitle = () => (
  <div className={styles.skeletonSubtitle}>
    <SkeletonCircle width="24px" height="24px" color="primary10" />
    <SkeletonRectangle width="232px" height="16px" color="primary10" marginLeft="8px" />
  </div>
);

export default SkeletonSubtitle;
