import { VersionsListDataActions } from './ActionTypes';
import FailedActionCreator, { FailedAction } from 'utils/actions/FailedActionCreator';
import { VersionsListData } from 'api/Types';
import { StrategyListParams } from 'api/DecisionEngineStrategiesType';
import { VersionsTableViewSortingType } from 'VersionsViewData/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

interface GetVersionsListParams extends StrategyListParams {
  strategyName?: string;
  sortingType?: VersionsTableViewSortingType;
  showArchived?: boolean;
}

export interface GetVersionsListDataAction {
  type: VersionsListDataActions.GetVersionsListData;
  payload?: GetVersionsListParams;
}

export interface SetShowArchivedAction {
  type: VersionsListDataActions.SetShowArchived;
  payload: boolean;
}

export interface GetVersionsListDataSuccessAction {
  type: VersionsListDataActions.GetVersionsListSuccess;
  payload: VersionsListData;
}

export interface SetVersionsListSearchInputAction {
  type: VersionsListDataActions.SetVersionsListDataSearchInput;
  payload: string;
}

export const getVersionsListData = (payload?: GetVersionsListParams): GetVersionsListDataAction => ({
  type: VersionsListDataActions.GetVersionsListData,
  payload,
});

export const getVersionsListDataSuccess = (
  payload: GetVersionsListDataSuccessAction['payload'],
): GetVersionsListDataSuccessAction => ({
  type: VersionsListDataActions.GetVersionsListSuccess,
  payload,
});

export interface GetVersionsListDataFailureAction
  extends FailedAction<VersionsListDataActions.GetVersionsListDataFailure> {}

export const getVersionsListDataFailure = FailedActionCreator(VersionsListDataActions.GetVersionsListDataFailure);

export const setVersionsListSearchInput = (
  payload: SetVersionsListSearchInputAction['payload'],
): SetVersionsListSearchInputAction => ({
  type: VersionsListDataActions.SetVersionsListDataSearchInput,
  payload,
});

export const setShowArchived = (payload: SetShowArchivedAction['payload']): SetShowArchivedAction => ({
  type: VersionsListDataActions.SetShowArchived,
  payload,
});

export interface ClearListDataAction {
  type: VersionsListDataActions.ClearListData;
}

export const clearListData = (): ClearListDataAction => ({
  type: VersionsListDataActions.ClearListData,
});

export interface SetSortingTypeAction {
  type: VersionsListDataActions.SetSortingType;
  payload: VersionsTableViewSortingType;
}

export const setSortingType = (payload: VersionsTableViewSortingType): SetSortingTypeAction => ({
  type: VersionsListDataActions.SetSortingType,
  payload,
});

export interface ResetFiltersAction {
  type: VersionsListDataActions.ResetFilters;
}

export const resetFilters = (): ResetFiltersAction => ({
  type: VersionsListDataActions.ResetFilters,
});

export interface SetUpdatersAction {
  type: VersionsListDataActions.SetUpdaters;
  payload: UserInfo[];
}

export const setUpdaters = (payload: UserInfo[]): SetUpdatersAction => ({
  type: VersionsListDataActions.SetUpdaters,
  payload,
});
