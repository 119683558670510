import React, { FC, useState } from 'react';
import VariableForm, { VariableFormProps } from 'components/VariableForm';
import { CloseButtonImage } from 'static/images';
import { VariableClientType } from 'Variables/VariablesTypes';
import VariableFormButtons from './VariableFormButtons';
import styles from './Variable.module.scss';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';

const TITLE = 'Add Custom Variable';

interface AddVariableProps extends Omit<VariableFormProps, 'children'> {
  onClose?: () => void;
  regionSettings: RegionSettings;
}

const AddVariable: FC<AddVariableProps> = (props) => {
  const { onClose, onSave, ...formProps } = props;

  const [isSavingInProgress, setIsSavingInProgress] = useState(false);

  const handleSave = async (variable: VariableClientType) => {
    setIsSavingInProgress(true);
    try {
      await onSave(variable);
    } finally {
      setIsSavingInProgress(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{TITLE}</h1>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <VariableForm {...formProps} onSave={handleSave}>
        <VariableFormButtons isSavingInProgress={isSavingInProgress} />
      </VariableForm>
    </div>
  );
};

export default AddVariable;
