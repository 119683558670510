import { Middleware } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import { changeEmailSuccess, changeEmailFailure, ChangeEmailAction } from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';
import { UpdateUserInfoCredentials } from 'api/Types';

const ChangeUserEmailMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<UpdateUserInfoCredentials, ChangeEmailAction>(
    AccountDetailsActionType.ChangeEmail,
    changeEmailSuccess,
    changeEmailFailure,
    ({ payload }) => api.updateEmail(payload.email),
  );

export default ChangeUserEmailMiddleware;
