import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import styles from './UserAvatarInlineList.module.scss';
import { TextSize } from 'components/Avatar';
import RestUsersPopupList from 'components/UserAvatarInlineList/RestUsersPopupList';
import RestUsersAvatar from 'components/UserAvatarInlineList/RestUsersAvatar';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';

interface UserAvatarInlineListProps {
  source: UserInfo[];
  className?: string;
  size?: TextSize;
  maxCount?: number;
  onSelect?: (user: UserInfo) => void;
  selectedUsers?: UserInfo[];
}

const UserAvatarInlineList = ({
  source,
  className,
  maxCount,
  size,
  selectedUsers = [],
  onSelect,
}: UserAvatarInlineListProps) => {
  const [restUsersPopupOpen, setRestUsersPopupOpen] = useState(false);

  const usersCountToView = !maxCount || source.length === maxCount ? source.length : maxCount - 1;
  const usersToView = source.slice(0, usersCountToView);
  const restUsers = source.slice(usersCountToView);
  const avatarCounterRef = useRef<HTMLDivElement>(null);

  const isSelected = (userId: string) => selectedUsers.some(({ id }) => id === userId);

  return (
    <div className={clsx(styles.list, className)}>
      {usersToView.map((user) => (
        <ImageFetcher
          {...user}
          size={size}
          tooltip
          active={isSelected(user.id)}
          className={styles.userAvatar}
          onClick={() => onSelect?.(user)}
          key={user.id}
        />
      ))}
      {restUsers.length > 0 && (
        <RestUsersAvatar
          hasItemsSelected={restUsers.some(({ id }) => isSelected(id))}
          usersCount={restUsers.length}
          onClick={() => setRestUsersPopupOpen(true)}
          ref={avatarCounterRef}
          className={styles.userAvatar}
          size={size}
          isActive={restUsersPopupOpen}
        />
      )}
      <RestUsersPopupList
        open={restUsersPopupOpen}
        anchorEl={avatarCounterRef.current}
        users={restUsers}
        selectedUsers={selectedUsers}
        onUserSelect={onSelect}
        onClose={() => setRestUsersPopupOpen(false)}
      />
    </div>
  );
};

export default UserAvatarInlineList;
