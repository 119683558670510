import { DeleteDecisionWizardActionType } from 'DeleteDecisionWizard/actionTypes';

interface OpenDeleteDecisionWizardAction {
  type: DeleteDecisionWizardActionType;
  payload: {
    decisionRunId: string;
  };
}

export const openDeleteDecisionWizard = (
  payload: OpenDeleteDecisionWizardAction['payload'],
): OpenDeleteDecisionWizardAction => ({
  type: DeleteDecisionWizardActionType.OpenDeleteDecisionWizard,
  payload,
});

export const closeDeleteDecisionWizard = () => {
  return {
    type: DeleteDecisionWizardActionType.CloseDeleteDecisionWizard,
  };
};
