import { LoanOriginationSystemEditIntermediaryState } from './Types';
import { UpdateIntermediaryAction, UpdateIntermediarySuccessAction } from './ActionCreator';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemEditIntermediaryState = {
  isUpdatingInProgress: false,
};

export type LoanOriginationSystemEditIntermediaryActions =
  | UpdateIntermediaryAction
  | UpdateIntermediarySuccessAction
  | FailedAction<LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryFailure>;

export const loanOriginationSystemEditIntermediaryReducer = (
  state = initialState,
  action: LoanOriginationSystemEditIntermediaryActions,
) => {
  switch (action.type) {
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediary:
      return {
        ...state,
        isUpdatingInProgress: true,
      };
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess:
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediaryFailure:
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    default: {
      return state;
    }
  }
};
