import React, { ChangeEvent, FC, ReactElement, ReactNode } from 'react';
import styles from './ToggleableContainer.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import StyledSwitch from 'components/StyledSwitch';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';
import QuestionIcon from 'components/QuestionIcon';

interface ToggleButtonProps {
  className?: string;
  switchButtonClassname?: string;
  labelText: string;
  checked: boolean;
  disabled: boolean;
  onChange: (isChecked: boolean) => void;
  helpText?: ReactNode;
  downloadCSV?: (population: string) => void;
  tooltipText?: string | ReactElement;
}

const Header: FC<ToggleButtonProps> = (props) => {
  const {
    labelText,
    className,
    switchButtonClassname,
    checked,
    onChange,
    disabled,
    tooltipText,
    downloadCSV,
    helpText,
  } = props;

  const handleSwitchChange = (_event: ChangeEvent<HTMLInputElement>, switchChecked: boolean) => {
    onChange(switchChecked);
  };

  const titleClasses = clsx(styles.switchButtonLabel, { [styles.disabled]: disabled && !checked });

  return (
    <section className={clsx(styles.switchButtonContainer, className)}>
      <h4 className={titleClasses}>{labelText}</h4>
      {helpText && <QuestionIcon tooltip={helpText} className={styles.tip} size={24} />}
      <WrapperWithTooltip tooltip={tooltipText || ''} position="top">
        <div>
          <StyledSwitch
            color="primary"
            className={clsx(styles.switchButton, switchButtonClassname)}
            checked={checked}
            disabled={disabled}
            onChange={handleSwitchChange}
          />
        </div>
      </WrapperWithTooltip>
      {checked && downloadCSV && (
        <ButtonWithImage
          className={styles.downloadCSVButton}
          title="Download CSV"
          onClick={() => downloadCSV('population')}
        />
      )}
    </section>
  );
};

export default Header;
