import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import {
  SET_SEARCH_INPUT_VALUE,
  FILTER_APPLICATIONS_BY_MEMBERS,
  TOGGLE_FILTERS_POPUP,
  CHANGE_POPUP_FILTERS,
  GET_PRODUCT_LABELS,
  GET_PRODUCT_LABELS_SUCCESS,
  GET_PRODUCT_LABELS_FAILED,
  GET_PRODUCT_INTERMEDIARIES,
  GET_PRODUCT_INTERMEDIARIES_FAILED,
  GET_PRODUCT_INTERMEDIARIES_SUCCESS,
  ApplicationsActionType,
} from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface SetSearchInputValueActionPayload {
  searchValue: string;
  viewType: ViewType;
}
export interface SetSearchInputValueAction extends AnyAction {
  type: typeof SET_SEARCH_INPUT_VALUE;
  payload: SetSearchInputValueActionPayload;
}

export interface FilterApplicationsByMembersActionPayload {
  members: UserInfo[];
  productId: string;
  viewType: ViewType;
}

export interface FilterApplicationsByMembersAction extends AnyAction {
  type: typeof FILTER_APPLICATIONS_BY_MEMBERS;
  payload: FilterApplicationsByMembersActionPayload;
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: typeof TOGGLE_FILTERS_POPUP;
}

export interface ChangePopupFiltersActionPayload {
  statusesIds: string[];
  labels: Label[];
  intermediaries: Intermediary[];
  createdDateRange: {
    from: Date | null;
    to: Date | null;
  };
  updatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  viewType: ViewType;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: typeof CHANGE_POPUP_FILTERS;
  payload: ChangePopupFiltersActionPayload;
}

export interface GetProductLabelsAction extends AnyAction {
  type: typeof GET_PRODUCT_LABELS;
  payload: {
    productName: string;
  };
}

export interface GetProductLabelsSuccessAction extends AnyAction {
  type: typeof GET_PRODUCT_LABELS_SUCCESS;
  payload: {
    labels: Label[];
  };
}

export interface GetProductIntermediariesAction extends AnyAction {
  type: typeof GET_PRODUCT_INTERMEDIARIES;
  payload: {
    productName: string;
  };
}

export interface GetProductIntermediariesSuccessAction extends AnyAction {
  type: typeof GET_PRODUCT_INTERMEDIARIES_SUCCESS;
  payload: {
    intermediaries: Intermediary[];
  };
}

export interface ClearAllFiltersAction extends AnyAction {
  type: ApplicationsActionType.ClearAllFilters;
  payload: {
    statusesIds: string[];
    viewType: ViewType;
  };
}

export const setSearchInputValue = (searchValue: string, viewType: ViewType): SetSearchInputValueAction => {
  return {
    type: SET_SEARCH_INPUT_VALUE,
    payload: {
      searchValue,
      viewType,
    },
  };
};

export const filterApplicationsByMembers = (
  productId: string,
  members: UserInfo[],
  viewType: ViewType,
): FilterApplicationsByMembersAction => {
  return {
    type: FILTER_APPLICATIONS_BY_MEMBERS,
    payload: {
      members,
      productId,
      viewType,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: TOGGLE_FILTERS_POPUP,
  };
};

export const changePopupFilters = (filter: ChangePopupFiltersActionPayload): ChangePopupFiltersAction => {
  return {
    type: CHANGE_POPUP_FILTERS,
    payload: filter,
  };
};

export const clearPopupFilters = (statuses: string[], viewType: ViewType): ChangePopupFiltersAction => {
  return {
    type: CHANGE_POPUP_FILTERS,
    payload: {
      labels: [],
      intermediaries: [],
      statusesIds: statuses,
      createdDateRange: {
        from: null,
        to: null,
      },
      updatedDateRange: {
        from: null,
        to: null,
      },
      viewType,
    },
  };
};

export const getProductLabels = (productName: string): GetProductLabelsAction => {
  return {
    type: GET_PRODUCT_LABELS,
    payload: {
      productName,
    },
  };
};

export const getProductLabelsSuccess = (labels: Label[]): GetProductLabelsSuccessAction => {
  return {
    type: GET_PRODUCT_LABELS_SUCCESS,
    payload: {
      labels,
    },
  };
};

export const getProductLabelsFailed = FailedActionCreator(GET_PRODUCT_LABELS_FAILED);

export const getProductIntermediaries = (productName: string): GetProductIntermediariesAction => {
  return {
    type: GET_PRODUCT_INTERMEDIARIES,
    payload: {
      productName,
    },
  };
};

export const getProductIntermediariesSuccess = (
  intermediaries: Intermediary[],
): GetProductIntermediariesSuccessAction => {
  return {
    type: GET_PRODUCT_INTERMEDIARIES_SUCCESS,
    payload: {
      intermediaries,
    },
  };
};

export const getProductIntermediariesFailed = FailedActionCreator(GET_PRODUCT_INTERMEDIARIES_FAILED);

export const clearAllFilters = (statuses: string[], viewType: ViewType): ClearAllFiltersAction => {
  return {
    type: ApplicationsActionType.ClearAllFilters,
    payload: {
      statusesIds: statuses,
      viewType,
    },
  };
};
