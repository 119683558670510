export enum LoanOriginationSystemBorrowersActionTypes {
  GetBorrowers = 'loanOriginationSystemBorrowers/getBorrowers',
  GetBorrowersSuccess = 'loanOriginationSystemBorrowers/getBorrowersSuccess',
  GetBorrowersFailed = 'loanOriginationSystemBorrowers/getBorrowersFailed',
  SortBorrowers = 'loanOriginationSystemBorrowers/sortBorrowers',
  SetBorrowerToDeleteId = 'loanOriginationSystemBorrowers/setBorrowerToDeleteId',
  GetBorrowerToDeleteApplications = 'loanOriginationSystemBorrowers/getBorrowerToDeleteApplications',
  GetBorrowerToDeleteApplicationsSuccess = 'loanOriginationSystemBorrowers/getBorrowerToDeleteApplicationsSuccess',
  GetBorrowerToDeleteApplicationsFailure = 'loanOriginationSystemBorrowers/getBorrowerToDeleteApplicationsFailure',
  DeleteBorrower = 'loanOriginationSystemBorrowers/deleteBorrower',
  DeleteBorrowerSuccess = 'loanOriginationSystemBorrowers/deleteBorrowerSuccess',
  DeleteBorrowerFailure = 'loanOriginationSystemBorrowers/deleteBorrowerFailure',
  ResetState = 'loanOriginationSystemBorrowers/resetState',
  SelectBorrowerViewTab = 'loanOriginationSystemBorrowers/selectBorrowerViewTab',
  ChangeContextualViewStep = 'loanOriginationSystemBorrowers/changeContextualViewStep',
}
