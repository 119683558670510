import React from 'react';
import Button from 'components/Button';
import styles from './UnlockBorrower.module.scss';

export interface UnlockBorrowerProps {
  onUnlockBorrower: () => void;
  className?: string;
}

const UnlockBorrower = ({ onUnlockBorrower, className }: UnlockBorrowerProps) => (
  <div className={className}>
    <h3 className={styles.lockedCustomerMessage}>This borrower is not eligible to apply at this time!</h3>
    <Button size="form" kind="warning" onClick={onUnlockBorrower}>
      Unlock Borrower
    </Button>
  </div>
);

export default UnlockBorrower;
