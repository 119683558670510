import React from 'react';
import clsx from 'clsx';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import styles from './ConfirmPopup.module.scss';

export interface ConfirmPopupProps {
  title: string;
  message: string;
  confirmText: string;
  declineText: string;
  onPopupClose: () => void;
  onConfirmClick: () => void;
  loading?: boolean;
  confirmButtonClassName?: string;
  declineButtonClassName?: string;
  subMessage?: string;
  usePortal?: boolean;
}

const ConfirmPopup = ({
  message,
  title,
  confirmText,
  declineText,
  onPopupClose,
  onConfirmClick,
  loading,
  confirmButtonClassName,
  declineButtonClassName,
  subMessage,
  usePortal,
}: ConfirmPopupProps) => {
  return (
    <PopUp usePortal={usePortal} closePopUp={onPopupClose} title={title} closable={!loading}>
      <PopUpContent hasTopMargin>
        <p className={styles.contentMessage}>{message}</p>
        {subMessage && <p className={clsx(styles.contentMessage, styles.subMessage)}>{subMessage}</p>}
        <div className={styles.buttonsContainer}>
          <Button
            isLoading={loading}
            kind="primary"
            size="form"
            className={clsx(styles.confirmButton, confirmButtonClassName)}
            onClick={onConfirmClick}
          >
            {confirmText}
          </Button>
          <Button
            disabled={loading}
            kind="secondary"
            size="form"
            className={clsx(styles.declineButton, declineButtonClassName)}
            onClick={onPopupClose}
          >
            {declineText}
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default ConfirmPopup;
