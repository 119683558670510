import React, { useState, FC, useRef, ChangeEvent, useEffect, forwardRef } from 'react';
import styles from './RuleBuilder.module.scss';
import { RuleBuilderDivider, RuleBuilderDividerTextWrapper } from 'components/RuleBuilderDivider';
import Button from 'components/Button';
import { ComparisonOperandType, ComparisonOperator } from 'components/SimpleRuleBuilder/Types';
import ButtonWithImage from 'components/ButtonWithImage';
import RuleBuilderCoreAdapter from 'components/RuleBuilderCoreAdapter';
import RuleBuilderOutputCoreAdapter from 'components/RuleBuilderOutputCoreAdapter';
import { RulePopUpType, RuleRequestData, RuleBuilderCoreData, RuleOutput } from 'RuleCreate/Types';
import TransitionComponent from 'components/HeightTransitionComponent';
import TextInput from 'components/TextInput';
import { getIsComplexRulesButtonEnabled, getIsOutputsCompleted, getIsRuleCompleted } from './utils';
import { RuleBuilderState } from './Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import ComparisonOperand from 'components/ComparisonOperand';
import { useDynamicSize } from 'components/DynamicSize';
import clsx from 'clsx';
import useOffset from 'hooks/useOffset';
import { OPTION_SEPARATOR } from 'components/ComparisonOperands/ComparisonOperandIn';
import { isEqual } from 'lodash';

interface RuleBuilderProps {
  saveRule: (requestData: RuleRequestData) => void;
  multipleOutputs?: boolean;
  type?: RulePopUpType;
  withoutDeclineReason?: boolean;
  moduleType: ModuleType;
  openVariableCreation: () => void;
  isEdit?: boolean;
  isSaving?: boolean;
  serializedRules?: RuleBuilderState[];
  serializedOutputs?: RuleOutput[];
  handleSetOffset?: (currentOffset: number) => void;
}

const INIT_COMPLEX_RULES_ID_ARRAY = [0, 1];

const getInitialRuleBuilderState = (ruleId = 0): RuleBuilderState => ({
  sourceVariableId: undefined,
  selectedComparison: '' as ComparisonOperator,
  firstOperandValue: '',
  firstOperandVariableId: undefined,
  secondOperandValue: '',
  secondOperandVariableId: undefined,
  firstOperandType: ComparisonOperandType.VALUE,
  secondOperandType: ComparisonOperandType.VALUE,
  ruleId,
});

const getInitialRuleOutputState = (outputId = 0): RuleOutput => ({
  sourceVariableId: undefined,
  operandVariableId: undefined,
  operandValue: '',
  operandType: ComparisonOperandType.VALUE,
  outputId,
});

const RuleBuilder: FC<RuleBuilderProps> = ({
  saveRule,
  type,
  multipleOutputs,
  withoutDeclineReason,
  moduleType,
  serializedOutputs,
  serializedRules,
  isEdit,
  isSaving,
  openVariableCreation,
  handleSetOffset,
}) => {
  const initRuleBuilderState =
    type === 'OR' || type === 'AND'
      ? INIT_COMPLEX_RULES_ID_ARRAY.map(getInitialRuleBuilderState)
      : [getInitialRuleBuilderState()];

  const [simpleRulesList, setSimpleRulesList] = useState<RuleBuilderState[]>(serializedRules || initRuleBuilderState);

  const [simpleOutputsList, setSimpleOutputsList] = useState<RuleOutput[]>(
    serializedOutputs || [getInitialRuleOutputState()],
  );

  const rulesCountRef = useRef(isEdit ? serializedRules!.length : 2);
  const outputsCountRef = useRef(isEdit ? serializedOutputs!.length : 1);

  const onAddRule = () => {
    const ruleBuilder = getInitialRuleBuilderState(rulesCountRef.current);
    rulesCountRef.current += 1;
    setSimpleRulesList([...simpleRulesList, ruleBuilder]);
  };

  const onAddOutput = () => {
    const outputBuilder = getInitialRuleOutputState(outputsCountRef.current);
    outputsCountRef.current += 1;
    setSimpleOutputsList([...simpleOutputsList, outputBuilder]);
  };

  const onDeleteRule = (id: number): void => {
    const filteredRulesList = simpleRulesList.filter((rule) => rule.ruleId !== id);
    setSimpleRulesList(filteredRulesList);
  };

  const onDeleteOutput = (id: number): void => {
    const filteredOutputList = simpleOutputsList.filter((output) => output.outputId !== id);
    setSimpleOutputsList(filteredOutputList);
  };

  const onRuleDataChange = ({ ruleId, key, value }: { ruleId: number; key: string; value: string }): void => {
    const currentRuleBuilder = simpleRulesList.find((rule) => rule.ruleId === ruleId);
    const newCurrentRuleBuilder = {
      ...currentRuleBuilder,
      ...(key === 'sourceVariableId' ? getInitialRuleBuilderState() : {}),
      [key]: value,
      ruleId: currentRuleBuilder!.ruleId,
    } as RuleBuilderState;

    const newRulesList = simpleRulesList.map((rule) => {
      if (rule.ruleId === newCurrentRuleBuilder.ruleId) {
        return newCurrentRuleBuilder;
      }
      return rule;
    });
    setSimpleRulesList(newRulesList);
  };

  const onOutputDataChange = ({ outputId, key, value }: { outputId: number; key: string; value: string }): void => {
    const currentOutputBuilder = simpleOutputsList.find((output) => output.outputId === outputId);
    const newCurrentOutputBuilder = {
      ...currentOutputBuilder,
      ...(key === 'sourceVariableId' ? getInitialRuleOutputState(currentOutputBuilder?.outputId) : {}),
      [key]: value,
    } as RuleOutput;
    const newOutputsList = simpleOutputsList.map((output) => {
      if (output.outputId === newCurrentOutputBuilder.outputId) {
        return newCurrentOutputBuilder;
      }
      return output;
    });
    setSimpleOutputsList(newOutputsList);
  };

  const onSaveRule = () => {
    const formattedRulesList = simpleRulesList.map((rule) => {
      if (rule.selectedComparison === 'IN' || rule.selectedComparison === 'NOT IN') {
        return { ...rule, optionSeparator: OPTION_SEPARATOR };
      }

      return rule;
    });
    saveRule({
      rulesList: formattedRulesList as RuleBuilderCoreData[],
      outputsList: simpleOutputsList,
      ruleType: type as RulePopUpType,
    });
  };

  const isRuleFilled =
    type === 'simple' ? getIsRuleCompleted(simpleRulesList[0]) : getIsComplexRulesButtonEnabled(simpleRulesList);

  const isOutputFilled = withoutDeclineReason || getIsOutputsCompleted(simpleOutputsList, moduleType);

  const outputsShowing = !withoutDeclineReason && isRuleFilled;

  const areRuleChanged = !isEqual(serializedRules, simpleRulesList);
  const areOutputChanged = !isEqual(serializedOutputs, simpleOutputsList);

  const ruleBuilderChanged = areRuleChanged || areOutputChanged;

  const isSaveButtonEnabled =
    (withoutDeclineReason ? isRuleFilled : isRuleFilled && isOutputFilled) && ruleBuilderChanged;

  const dividerWithButtonContainer = clsx({
    [styles.simpleOutputContainer]: type === 'simple',
  });

  const getMultipleOutputs = () => {
    return (
      <div className={dividerWithButtonContainer}>
        <h4 className={styles.outputTitle}>Output</h4>
        {simpleOutputsList.map((output, index) => {
          return (
            <>
              <RuleBuilderOutputCoreAdapter
                key={index}
                {...output}
                onOutputDelete={onDeleteOutput}
                onOutputChange={onOutputDataChange}
                order={index + 1}
              />
            </>
          );
        })}
      </div>
    );
  };

  const getSimpleOutput = () => {
    return (
      <div className={dividerWithButtonContainer}>
        <h4 className={styles.outputTitle}>Output</h4>
        <RuleBuilderDivider>
          <RuleBuilderDividerTextWrapper>
            {moduleType === 'requirements' ? 'If Rule Fails' : 'If Rule passes'}
          </RuleBuilderDividerTextWrapper>
        </RuleBuilderDivider>
        {moduleType === 'requirements' ? (
          <TextInput
            labelTitle="Decline Reason"
            value={simpleOutputsList[0].operandValue}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
              onOutputDataChange({ outputId: 0, key: 'operandValue', value: target.value })
            }
          />
        ) : (
          <ComparisonOperand
            value={simpleOutputsList[0]?.operandValue}
            variableId={simpleOutputsList[0]?.operandVariableId}
            operandType={simpleOutputsList[0]?.operandType}
            dataType="Number"
            onValueChange={(value: string) => onOutputDataChange({ outputId: 0, key: 'operandValue', value })}
            onVariableIdChange={(value: string) => onOutputDataChange({ outputId: 0, key: 'operandVariableId', value })}
            onTypeChange={(value: ComparisonOperandType) =>
              onOutputDataChange({ outputId: 0, key: 'operandType', value })
            }
            labelTitle="Weight"
          />
        )}
      </div>
    );
  };
  const outputBuilderList = multipleOutputs ? getMultipleOutputs() : getSimpleOutput();

  const DynamicSize = useDynamicSize();

  const containerRef = useRef<HTMLDivElement>(null);

  const containerRefPrevHeight = useRef(0);
  const anchorRef = useRef(null);
  const offset = useOffset(anchorRef);

  useEffect(() => {
    if (containerRef.current) {
      const containerRefRect = containerRef.current.getBoundingClientRect();
      if (containerRefPrevHeight.current) {
        const delta = containerRefRect.height - containerRefPrevHeight.current;
        if (delta && handleSetOffset) {
          handleSetOffset(offset);
        }
      }
      containerRefPrevHeight.current = containerRefRect.height;
    }
  });

  return (
    <div className={styles.container} onTransitionEnd={DynamicSize.trigger} ref={containerRef}>
      <DynamicSize.Provider>
        <RuleBuilderDivider>
          <RuleBuilderDividerTextWrapper>Rule Passes If</RuleBuilderDividerTextWrapper>
        </RuleBuilderDivider>

        {simpleRulesList.map((rule, index) => {
          return (
            <RuleBuilderCoreAdapter
              {...rule}
              key={index}
              onRuleDelete={onDeleteRule}
              onRuleChange={onRuleDataChange}
              type={type as RulePopUpType}
              openVariableCreation={openVariableCreation}
            />
          );
        })}
        {(type === 'OR' || type === 'AND') && (
          <div className={styles.dividerWithButtonContainer}>
            <RuleBuilderDivider>
              <div className={styles.addButtonContainer}>
                <ButtonWithImage kind="add" title="Add Condition" onClick={onAddRule} />
              </div>
            </RuleBuilderDivider>
          </div>
        )}
        <TransitionComponent>{outputsShowing ? outputBuilderList : null}</TransitionComponent>
        {multipleOutputs && outputsShowing && (
          <div className={styles.dividerWithButtonContainer}>
            <RuleBuilderDivider>
              <div className={styles.addButtonContainer}>
                <ButtonWithImage kind="add" title="Add Result" onClick={onAddOutput} />
              </div>
            </RuleBuilderDivider>
          </div>
        )}
        <div className={styles.buttonContainer} ref={anchorRef}>
          <Button disabled={!isSaveButtonEnabled} isLoading={isSaving} onClick={onSaveRule} kind="primary" size="form">
            Save
          </Button>
        </div>
      </DynamicSize.Provider>
    </div>
  );
};

export default forwardRef(RuleBuilder);
