import React, { useEffect, useState } from 'react';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { ReduxState } from 'types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { LoanOriginationSystemIntermediariesActionType } from 'LoanOriginationSystemIntermediariesPage/ActionTypes';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import {
  closeDeleteIntermediaryPopup,
  deleteIntermediary,
  openDeleteIntermediaryPopup,
  getIntermediaryToDeleteApplications,
} from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import { useQueryParams } from 'hooks/useQueryParam';
import useStateReset from 'hooks/useStateReset';
import NavigationLinkId from 'enums/NavigationLinkId';
import styles from './Intermediaries.module.scss';
import IntermediariesDashboard from 'components/LoanOriginationSystem/IntermediariesDashboard';
import CreateIntermediary from 'components/LoanOriginationSystem/CreateIntermediary';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import IntermediaryDetails from 'components/LoanOriginationSystem/IntermediaryDetails';
import ConfirmPopup from 'components/ConfirmPopup';
import IntermediariesFilter from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesFilter';
import prolongRequest from 'utils/prolongRequest';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const INTERMEDIARY_APPLICATIONS_MIN_REQUEST_TIME = 500;

const leftNav = makeLeftNavigation(NavigationLinkId.Intermediaries, ApplicationSectionName.LoanOriginationSystem);

const Intermediaries = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();
  const closeContextualView = useCloseContextualView();

  const createIntermediaryFormAvailable = params.has('new');
  const editIntermediaryFormAvailable = params.has('edit');

  useStateReset(LoanOriginationSystemIntermediariesActionType.ResetState);

  const { intermediaryToDeleteId, isDeleteInProgress, filters } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediariesState,
  );

  const [intermediaryDataWasChanged, setIntermediaryDataWasChanged] = useState(false);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);

  const onPopupClose = () => dispatch(closeDeleteIntermediaryPopup());

  const prolongedGetIntermediaryToDeleteApplications = prolongRequest((id: string) => {
    return dispatchRoutine(getIntermediaryToDeleteApplications(id));
  }, INTERMEDIARY_APPLICATIONS_MIN_REQUEST_TIME);
  const onPopupOpen = async (id: string) => {
    await prolongedGetIntermediaryToDeleteApplications(id);

    dispatch(openDeleteIntermediaryPopup(id));
  };

  const onDeleteIntermediary = () => dispatch(deleteIntermediary(intermediaryToDeleteId!));

  useEffect(() => {
    if (!createIntermediaryFormAvailable) {
      setIntermediaryDataWasChanged(false);
    }
  }, [createIntermediaryFormAvailable]);

  const onCloseContextualView = () => {
    if (intermediaryDataWasChanged) {
      setUnsavedChangesPopupOpen(true);

      return;
    }

    closeContextualView();
  };

  const renderContextualView = () => {
    if (createIntermediaryFormAvailable) {
      return (
        <CreateIntermediary
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setIntermediaryDataWasChanged(changed)}
        />
      );
    }

    if (editIntermediaryFormAvailable) {
      return (
        <IntermediaryDetails
          onClose={onCloseContextualView}
          id={params.get('edit')!}
          setDataWasChanged={(changed) => setIntermediaryDataWasChanged(changed)}
        />
      );
    }

    return null;
  };

  const renderOverlay = () => {
    if (intermediaryToDeleteId) {
      return (
        <ConfirmPopup
          title="Delete Intermediary"
          message="Are you sure you want to remove this intermediary?"
          confirmText="Yes, Delete Intermediary"
          declineText="No, Go Back"
          onPopupClose={onPopupClose}
          onConfirmClick={onDeleteIntermediary}
          loading={isDeleteInProgress}
        />
      );
    }

    if (unsavedChangesPopupOpen) {
      return (
        <UnsavedChangesPopup
          onPopupClose={() => setUnsavedChangesPopupOpen(false)}
          onLeaveClick={() => {
            closeContextualView();
            setUnsavedChangesPopupOpen(false);
          }}
        />
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <IntermediariesFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      dueCommissionFrom={filters.dueCommissionRange.from}
      dueCommissionTo={filters.dueCommissionRange.to}
      borrowerTypes={filters.borrowerTypes}
    />
  );

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <IntermediariesDashboard onPopupOpen={onPopupOpen} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Intermediaries;
