import React, { useState, DetailedHTMLProps, InputHTMLAttributes, ReactNode, forwardRef } from 'react';
import styles from './PasswordInput.module.scss';
import clsx from 'clsx';
import useRandomIdFallback from 'hooks/randomIdFallback';

type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface InputProps extends Omit<HTMLInputProps, 'ref'> {
  id?: string;
  labelTitle: string;
  errorMessage?: string;
  topRightElement?: ReactNode;
  containerClassName?: string;
}

const PasswordInput = forwardRef<HTMLInputElement, InputProps>(
  (
    { id: providedId, labelTitle, onChange, errorMessage, value, topRightElement, containerClassName, ...inputProps },
    ref,
  ) => {
    const id = useRandomIdFallback(providedId);
    const [isVisible, setVisible] = useState(false);
    const [isFocused, setFocus] = useState(false);
    const toggleFocusInput = () => {
      setFocus(!isFocused);
    };
    const toggleVisibility = () => {
      setVisible(!isVisible);
    };
    const labelClassNames = clsx(
      styles.inputContainer__input,
      isFocused && styles.inputFocus,
      errorMessage && styles.inputError,
    );

    return (
      <div className={clsx(styles.inputContainer, containerClassName)}>
        <div className={styles.labelWithLink}>
          <label htmlFor={id} className={styles.label}>
            {labelTitle}
          </label>
          {topRightElement}
        </div>
        <label className={labelClassNames}>
          <input
            type={isVisible ? 'text' : 'password'}
            id={id}
            value={value}
            onFocus={toggleFocusInput}
            onBlur={toggleFocusInput}
            onChange={onChange}
            autoComplete="none"
            className={styles.input}
            ref={ref}
            {...inputProps}
          />
          <button
            type="button"
            tabIndex={-1}
            className={isVisible ? styles.hideButton : styles.showButton}
            onClick={toggleVisibility}
          >
            {' '}
          </button>
        </label>
        {errorMessage && <p className={styles.inputContainer__error}>{errorMessage}</p>}
      </div>
    );
  },
);

export default PasswordInput;
