import cronParser from 'cron-parser';
import cronstrue from 'cronstrue';

const CHANGES_OBSERVER_INTERVAL = '45 9,19,29,39,49,59 * * * *';

export const getNextRunDate = (intervalExpression: string) => {
  const changesObserverInterval = cronParser.parseExpression(CHANGES_OBSERVER_INTERVAL);

  const taskIntervalStartingAfterNextChangesObserverRun = cronParser.parseExpression(
    intervalExpression,
    { currentDate: changesObserverInterval.next().toDate() },
  );

  return taskIntervalStartingAfterNextChangesObserverRun.next().toDate();
};

export const getIntervalDescription = (intervalExpression: string) => {
  return cronstrue.toString(intervalExpression, { verbose: true });
}
