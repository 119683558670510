import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import React from 'react';
import SIZES from 'components/ApiActivation/Table/TableColumnSizes';

const SkeletonStrategyTableRow = () => (
  <TableRow disableHover>
    <TableBodyCell width={SIZES.NAME}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={SIZES.TESTING}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={SIZES.ACTIVE}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonStrategyTableRow;
