import Fetcher from 'middlewares/Fetcher';
import { requestCustomerUploadFailure, requestCustomerUploadSuccess, RequestCustomerUpload } from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';

const RequestCustomerUploadMiddleware = (api: ApplicationDocumentsApi) =>
  Fetcher<string, RequestCustomerUpload>(
    ApplicationDocumentsActionType.RequestCustomerUpload,
    requestCustomerUploadSuccess,
    requestCustomerUploadFailure,
    ({ payload }) => {
      return api.requestUpload(payload);
    },
  );

export default RequestCustomerUploadMiddleware;
