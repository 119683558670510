import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { CheckedIcon, XMarkIcon } from 'static/images';
import Spinner from 'components/Spinner';
import styles from './LoaderWithState.module.scss';

export enum LoaderState {
  Failure = 'failure',
  Success = 'success',
  Updating = 'updating',
}

export interface LoaderWithStateProps {
  className?: string;
  state?: LoaderState | null;
  successTimeout?: number;
  failureTimeout?: number;
  onStateReset?: () => void;
  useOnlyUpdatingState?: boolean;
}

const DEFAULT_TRANSITION_TIMEOUT = 1200;
const UPDATING_STATE_DEFAULT_TRANSITION_TIMEOUT_ONLY = 0;
const SPINNER_TRANSITION_TIMEOUT = 300;
const MIN_LOADING_TIME = 1000;

const LoaderWithState = ({
  className,
  state,
  onStateReset,
  failureTimeout = 1500,
  successTimeout = 500,
  useOnlyUpdatingState,
}: LoaderWithStateProps) => {
  const [minLoaderTimer, setMinLoaderTimer] = useState<NodeJS.Timeout | null>(null);
  const [resultAnimationTimer, setResultAnimationTimer] = useState<NodeJS.Timeout | null>(null);
  const [animationState, setAnimationState] = useState(state);

  useEffect(() => {
    return () => {
      if (minLoaderTimer) {
        clearTimeout(minLoaderTimer);
      }

      if (Object.values(LoaderState).some((option) => option === state)) {
        onStateReset?.();
      }
    };
  }, []);

  useEffect(() => {
    if (animationState === LoaderState.Success || animationState === LoaderState.Failure) {
      const resultAnimationTimeout = animationState === LoaderState.Success ? successTimeout : failureTimeout;

      setResultAnimationTimer(
        setTimeout(
          () => {
            onStateReset?.();
            setResultAnimationTimer(null);
          },
          useOnlyUpdatingState
            ? UPDATING_STATE_DEFAULT_TRANSITION_TIMEOUT_ONLY
            : resultAnimationTimeout + DEFAULT_TRANSITION_TIMEOUT,
        ),
      );
    }

    return () => {
      if (resultAnimationTimer) {
        clearTimeout(resultAnimationTimer);
      }
    };
  }, [animationState]);

  useEffect(() => {
    if (minLoaderTimer) {
      return;
    }

    setAnimationState(state);

    if (state === LoaderState.Updating) {
      setMinLoaderTimer(
        setTimeout(() => {
          setMinLoaderTimer(null);
        }, MIN_LOADING_TIME),
      );
    }
  }, [minLoaderTimer, state]);

  const renderSpinner = () => {
    return (
      <CSSTransition
        in={animationState === LoaderState.Updating}
        timeout={SPINNER_TRANSITION_TIMEOUT}
        classNames={{
          enter: styles.spinnerEnter,
          enterActive: styles.spinnerEnterActive,
          appear: styles.spinnerAppear,
          appearActive: styles.spinnerAppearActive,
          exit: styles.spinnerExit,
          exitActive: styles.spinnerExitActive,
        }}
        unmountOnExit={!useOnlyUpdatingState}
        appear
      >
        <Spinner className={styles.spinner} size={24} />
      </CSSTransition>
    );
  };

  const renderCheckIcon = () => {
    return (
      <CSSTransition
        in={animationState === LoaderState.Success}
        timeout={DEFAULT_TRANSITION_TIMEOUT}
        classNames={{
          enter: styles.successEnter,
          enterActive: styles.successEnterActive,
          enterDone: styles.successEnterDone,
          appear: styles.successAppear,
          appearActive: styles.successAppearActive,
          exit: styles.successExit,
          exitActive: styles.successExitActive,
        }}
        unmountOnExit
        appear
      >
        <div className={styles.iconWrapper}>
          <CheckedIcon />
        </div>
      </CSSTransition>
    );
  };

  const renderErrorIcon = () => {
    return (
      <CSSTransition
        in={animationState === LoaderState.Failure}
        timeout={DEFAULT_TRANSITION_TIMEOUT}
        classNames={{
          enter: styles.failureEnter,
          enterActive: styles.failureEnterActive,
          enterDone: styles.failureEnterDone,
          appear: styles.failureAppear,
          appearActive: styles.failureAppearActive,
          exit: styles.failureExit,
          exitActive: styles.failureExitActive,
        }}
        unmountOnExit
        appear
      >
        <div className={styles.iconWrapper}>
          <XMarkIcon />
        </div>
      </CSSTransition>
    );
  };

  return (
    <div className={clsx(styles.loader, className)}>
      {renderSpinner()}
      {!useOnlyUpdatingState && renderCheckIcon()}
      {!useOnlyUpdatingState && renderErrorIcon()}
    </div>
  );
};

export default LoaderWithState;
