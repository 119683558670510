import React from 'react';
import clsx from 'clsx';
import styles from './TabMenu.module.scss';

export interface TabMenuProps {
  options: Array<{
    label: string;
    value: string;
  }>;
  onChange: (value: string) => void;
  value: string;
}

const TabMenu = ({ options, onChange, value }: TabMenuProps) => {
  return (
    <div className={styles.tabMenuContainer}>
      {options.map((option) => {
        const rowStyle = clsx(styles.tabMenuButton, { [styles.selected]: option.value === value });

        return (
          <div className={rowStyle} onClick={() => onChange(option.value)} key={option.value}>
            {option.label}
          </div>
        );
      })}
    </div>
  );
};

export default TabMenu;
