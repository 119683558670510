import React, { ComponentType } from 'react';
import styles from './Snackbar.module.scss';
import Snack from 'components/Snack';
import { SnackType } from 'Snackbar/Types';
import { ComponentTypeWithProps } from 'handlers/notification/notificationTypes';

export interface SnackbarProps<
  SnackContent extends ComponentTypeWithProps<string, any>,
  CustomSnackbarComponents extends Record<SnackContent['componentType'], ComponentType<any>>
> {
  snackbarList: SnackType<SnackContent>[];
  customSnackbarComponents: CustomSnackbarComponents;
}

const Snackbar = <
  SnackContent extends ComponentTypeWithProps<string, any>,
  CustomSnackbarComponents extends Record<SnackContent['componentType'], ComponentType<any>>
>({
  snackbarList,
  customSnackbarComponents,
}: SnackbarProps<SnackContent, CustomSnackbarComponents>) => {
  const renderSnack = (snack: SnackContent) => {
    const SnackComponent = customSnackbarComponents[snack.componentType];

    return <SnackComponent {...snack.props} />;
  };

  return (
    <div className={styles.snackbarsContainer}>
      {snackbarList.map((snack, index) => (
        <Snack key={index} snackId={snack.id} title={snack.title}>
          {renderSnack(snack.content)}
        </Snack>
      ))}
    </div>
  );
};

export default Snackbar;
