import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getBorrowers, SortBorrowersAction } from './ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';

const isSortBorrowersAction = (action: AnyAction): action is SortBorrowersAction =>
  action.type === LoanOriginationSystemBorrowersActionTypes.SortBorrowers;

const sortBorrowersMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortBorrowersAction(action)) {
    const { loanOriginationSystemBorrowers } = getState();

    dispatch(getBorrowers(loanOriginationSystemBorrowers.filters, action.payload));
  }

  return result;
}) as Middleware;

export default sortBorrowersMiddleware;
