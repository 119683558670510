import React, { FocusEvent } from 'react';
import clsx from 'clsx';
import { ApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import ConfigurableForm from 'components/ConfigurableForm';
import styles from 'components/LoanOriginationSystem/ApplicationTabs/DataTab/DataTab.module.scss';
import DataTabCard from './DataTabCard/DataTabCard';

export interface DataTabRowProps {
  cards: ApplicationDataTabCard[];
  data: FormLayoutData;
  onFieldChange: (variableConfiguration: VariableConfiguration, value: VariableValue) => void;
  onFieldFocus: (variableConfiguration: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur: (variableConfiguration: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => void;
  loaderStateById: Record<string, LoaderState | null | undefined>;
  onLoaderStateReset: (variableConfiguration: VariableConfiguration) => void;
  isEditMode?: boolean;
}

const DEFAULT_COLUMNS_LENGTH = 1;

const DataTabRow = ({
  cards,
  data,
  onFieldChange,
  onFieldBlur,
  onFieldFocus,
  loaderStateById,
  onLoaderStateReset,
  isEditMode,
}: DataTabRowProps) => {
  const renderCardBody = (card: ApplicationDataTabCard) => {
    if (isEditMode) {
      return (
        <ConfigurableForm
          showLoader
          configurations={card.fields}
          data={data}
          loaderStateById={loaderStateById}
          onFieldChange={onFieldChange}
          onFieldFocus={onFieldFocus}
          onFieldBlur={onFieldBlur}
          onLoaderStateReset={onLoaderStateReset}
          columns={DEFAULT_COLUMNS_LENGTH}
        />
      );
    }

    return <DataTabCard data={data} fields={card.fields} />;
  };

  const renderCard = (card: ApplicationDataTabCard) => (
    <div key={card.id} className={styles.card}>
      <h5 className={styles.cardName}>{card.name}</h5>
      {renderCardBody(card)}
    </div>
  );

  return <div className={clsx(styles.row, isEditMode && styles.editableRow)}>{cards.map(renderCard)}</div>;
};

export default DataTabRow;
