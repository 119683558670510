import React, { FC } from 'react';
import TableBodyCell from 'components/Table/TableBodyCell';
import { SIZES } from 'components/SendGrid/Integration/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import styles from './CredentialLine.module.scss';

interface VariableLineProps {
  clientApiKey: string;
}

const CredentialLine: FC<VariableLineProps> = ({ clientApiKey }) => {
  return (
    <TableRow disableHover className={styles.row}>
      <TableBodyCell className={styles.nameColumn}>
        <p className={styles.nameColumnText}>API Key</p>
      </TableBodyCell>
      <TableBodyCell width={SIZES.VALUE} noPadding>
        <p>{clientApiKey}</p>
      </TableBodyCell>
    </TableRow>
  );
};

export default CredentialLine;
