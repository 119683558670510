import React, { FC } from 'react';
import { CloseImage } from 'static/images';
import styles from './FilterLayout.module.scss';
import clsx from 'clsx';

interface ApplicationFilterLayoutProps {
  onClose: () => void;
  onClearFiltersClick: () => void;
  filterTitleClassName?: string;
}

const FilterLayout: FC<ApplicationFilterLayoutProps> = ({
  children,
  onClose,
  onClearFiltersClick,
  filterTitleClassName,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={clsx(styles.title, filterTitleClassName)}>Filters</h3>
        <button type="button" className={styles.clearFilters} onClick={onClearFiltersClick}>
          Reset
        </button>
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <CloseImage />
        </button>
      </div>
      {children}
    </div>
  );
};

export default FilterLayout;
