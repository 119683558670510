import React from 'react';
import { SkeletonApplicationStatusCard } from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationWorkflowSetup/ApplicationStatusCard';
import styles from './ApplicationStatusesList.module.scss';

const APPLICATION_STATUSES_LENGTH = 3;

const SkeletonApplicationStatusesList = () => (
  <div className={styles.container}>
    <div className={styles.statusesListContent}>
      <div className={styles.applicationStatusesList}>
        {new Array(APPLICATION_STATUSES_LENGTH).fill(null).map((item, index) => (
          <SkeletonApplicationStatusCard key={index} className={styles.statusCard} displayStatusIndex />
        ))}
      </div>
    </div>
  </div>
);

export default SkeletonApplicationStatusesList;
