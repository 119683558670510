import React from 'react';
import { SIZES } from 'components/BatchProcessing/History/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';

const SkeletonHistoryLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.DATE}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.BATCH_NAME}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PROGRESS}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonHistoryLine;
