import Fetcher from 'middlewares/Fetcher';
import { DocuSignImportApi, ImportableDocuSignTemplate } from 'api/Core/DocuSignImportApi';
import { GetTemplatesToImportAction, getTemplatesToImportSuccess, getTemplatesToImportFailure } from './ActionCreator';
import { DocuSignActionType } from './ActionTypes';

const GetTemplatesToImportMiddleware = (api: DocuSignImportApi) =>
  Fetcher<ImportableDocuSignTemplate[], GetTemplatesToImportAction>(
    DocuSignActionType.GetTemplatesToImport,
    getTemplatesToImportSuccess,
    getTemplatesToImportFailure,
    () => {
      return api.getTemplatesToImport();
    },
  );

export default GetTemplatesToImportMiddleware;
