import React, { useMemo } from 'react';
import AutoCompletion from 'components/AutoCompletion';
import styles from './EmailsDropdown.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import { SendGridTemplate } from 'SendGrid/Types';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';

interface EmailsDropdownProps {
  selectedEmailTemplateId: string | undefined;
  onEmailTemplateSelect: (id: string) => void;
  isAutomatedProcessActive: boolean;
  emailTemplates: SendGridTemplate[];
  disabled?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
}

const EmailsDropdown = ({
  selectedEmailTemplateId,
  onEmailTemplateSelect,
  isAutomatedProcessActive,
  emailTemplates,
  disabled,
  loaderState,
  onLoaderStateReset,
}: EmailsDropdownProps) => {
  const emailTemplateOptions = useMemo(() => {
    return emailTemplates.map((item) => ({
      value: item!.id,
      name: item!.name,
    }));
  }, [emailTemplates]);

  const onSelectedEmailChange = ({ value }: Option) => {
    onEmailTemplateSelect(value);
  };

  return (
    <AutoCompletion
      onChange={onSelectedEmailChange}
      options={emailTemplateOptions}
      selectedOption={emailTemplateOptions.find(({ value }) => selectedEmailTemplateId === value)}
      hideClearIcon={isAutomatedProcessActive}
      placeholder="Email Template"
      className={styles.autoCompletion}
      disabled={disabled}
      onLoaderStateReset={onLoaderStateReset}
      loaderState={loaderState}
      showLoader
    />
  );
};

export default EmailsDropdown;
