import React from 'react';
import clsx from 'clsx';
import { DragImage } from 'static/images';
import InputWithDataTypeStub, { InputWithDataTypeStubProps } from 'components/InputWithDataType/InputWithDataTypeStub';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import styles from './DraggableInputStub.module.scss';

export interface DraggableInputStubProps extends InputWithDataTypeStubProps {
  isDragging?: boolean;
  onDelete?: () => void;
  showActions?: boolean;
  className?: string;
}

const DraggableInputStub = ({
  onDelete,
  showActions,
  isDragging,
  className,
  ...restProps
}: DraggableInputStubProps & VisualDataTypeWithAttributes) => (
  <RowActionsContainer>
    <div className={clsx(styles.draggableInputStub, { [styles.dragging]: isDragging }, className)}>
      <div className={styles.innerContainer}>
        {showActions && (
          <RowActions
            className={styles.rowActions}
            actions={[
              {
                title: 'Remove Variable',
                handler: () => onDelete?.(),
                danger: true,
              },
            ]}
          />
        )}
        <DragImage className={styles.dragImage} />
        <InputWithDataTypeStub {...restProps} />
      </div>
    </div>
  </RowActionsContainer>
);

export default DraggableInputStub;
