import { OrganizationApiType } from 'api/Types';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import { OrganizationInfoType } from 'CompanyInformation/CompanyInformationTypes';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { batch } from 'react-redux';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { updateRegionSettingsSuccess, updateRegionSettingsFailure, closesRegionSettingsConfirmDialog } from './Actions';
import { RegionSettings } from './ActionTypes';

export const UpdateRegionSettingsMiddleware: (api: OrganizationApiType) => Middleware = (api) =>
  Fetcher<OrganizationInfoType>(
    RegionSettings.UpdateRegionSettings,
    updateRegionSettingsSuccess,
    updateRegionSettingsFailure,
    ({ payload }) => api.updateRegionSettings(payload),
  );

export const onUpdateRegionSettingsSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === RegionSettings.UpdateRegionSettingsSuccess) {
    notification.createNotification(getMessage(MessageType.RegionSettingsUpdate), 'success', dispatch);

    batch(() => {
      dispatch(getOrganizationInfo());
      dispatch(closesRegionSettingsConfirmDialog());
    });
  }
  return result;
};
