import { Dispatch } from 'react';
import * as Sentry from '@sentry/browser';

import { NotificationComponents, ShowNotification, UserInfo } from './notificationTypes';

import { NotificationType } from 'components/Notification';
import NotificationActionType from './notificationActionType';

const LOCAL_STORAGE_KEY_PROFILE = 'Admin Panel_jwt_profile';
const EMPTY_PROFILE_SERIALIZED_VALUE = 'undefined';
const ERROR_NOTIFICATION_DEFAULT_TIMEOUT = 10000; // 10s
const DEFAULT_NOTIFICATION_DURATION = 4000; // 4s

const generateNotificationID = () => {
  return `${(Math.random() * 1000000).toFixed(0)}${new Date().valueOf()}`;
};

const removeNotificationTimer = (id: string, timeout: number, dispatch: Dispatch<any>) => {
  if (timeout) {
    const t = setTimeout(() => {
      dispatch(notification.hideNotification(id));
      clearTimeout(t);
    }, timeout);
  }
};

const notification = {
  hideNotification(id: string) {
    return {
      type: NotificationActionType.HideNotification,
      payload: { id },
    };
  },

  showNotification(options: ShowNotification) {
    return {
      type: NotificationActionType.ShowNotification,
      payload: options,
    };
  },

  errorNotification(
    error: Error | NotificationComponents,
    dispatch: Dispatch<any>,
    timeout = ERROR_NOTIFICATION_DEFAULT_TIMEOUT,
  ) {
    const id = generateNotificationID();
    const profile: string | null = localStorage.getItem(LOCAL_STORAGE_KEY_PROFILE);
    const userInfo: UserInfo =
      profile !== null && profile !== EMPTY_PROFILE_SERIALIZED_VALUE ? JSON.parse(profile) : null;
    removeNotificationTimer(id, timeout, dispatch);

    const notificationBody = error instanceof Error ? error.message.toString() : error;

    dispatch(this.showNotification({ id, type: 'error', notification: notificationBody, timeout }));

    Sentry.configureScope((scope) => {
      if (userInfo) {
        scope.setUser({ id: userInfo._id, username: userInfo.first_name });
      }
      Sentry.captureException(error);
    });
  },

  createNotification(
    notificationBody: string | NotificationComponents,
    type: NotificationType,
    dispatch: Dispatch<any>,
    timeout: number = DEFAULT_NOTIFICATION_DURATION,
  ) {
    const id = generateNotificationID();
    const time = timeout;
    removeNotificationTimer(id, time, dispatch);
    dispatch(this.showNotification({ type, notification: notificationBody, timeout, id }));
  },
};

export default notification;
