import { ApplicationNotesApi } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import notesPagination from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/pagination';

export default (api: ApplicationNotesApi) => {
  return notesPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getAll(
      params.applicationId,
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
      },
      params.sortingType,
    );
    return items;
  });
};
