import clsx from 'clsx';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableRow from 'components/Table/TableRow';
import React from 'react';
import styles from './StaticTablePart.module.scss';

interface StaticTablePartProps {
  statuses: ApplicationStatus[];
  tableSections: string[];
}

const renderStaticTableCore = (items: ApplicationStatus[], type: string) => {
  return (
    <div className={styles.content}>
      <p className={styles.amount}>{type}</p>
      <div className={styles.rows}>
        {items.map((item, index) => {
          return (
            <TableRow
              disableHover
              className={clsx(styles.leftPaddingZero, styles.dashedBorderBottom)}
              key={`${item}_${index}`}
            >
              <TableBodyCell width={100} disableHover>
                <p>{item.name}</p>
              </TableBodyCell>
            </TableRow>
          );
        })}
        <TableRow disableHover className={styles.leftPaddingZero}>
          <TableBodyCell width={100}>
            <p className={styles.total}>Total</p>
          </TableBodyCell>
        </TableRow>
      </div>
    </div>
  );
};

const StaticTablePart = ({ statuses, tableSections }: StaticTablePartProps) => {
  return (
    <div className={styles.container}>
      <TableHead>
        <TableHeadCell width={100}>
          <p className={styles.header}>Status</p>
        </TableHeadCell>
      </TableHead>
      {tableSections.map((section) => renderStaticTableCore(statuses, section))}
    </div>
  );
};

export default StaticTablePart;
