import React, { FC } from 'react';

import styles from './NeedMoreHelp.module.scss';

import { ArrowRightImage } from 'static/images';
import ExternalLink from 'components/ExternalLink/ExternalLink';

interface NeedMoreHelpProps {
  link: string;
}

const NeedMoreHelp: FC<NeedMoreHelpProps> = ({ link }) => {
  return (
    <div className={styles.helpContainer}>
      <p>Need more help? Send us a note.</p>
      <ExternalLink href={link}>
        <ArrowRightImage />
      </ExternalLink>
    </div>
  );
};

export default NeedMoreHelp;
