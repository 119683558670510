import React, { FC } from 'react';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { ESignTemplateRecipient } from 'api/Core/ESignTemplatesApi';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import TableColumnSize from 'components/LoanOriginationSystem/SendESignaturePopup/RecipientsTable/TableColumnSize';
import { getTableDisplayValue } from 'components/LoanOriginationSystem/SendESignaturePopup/Utils';
import OverflowedText from 'components/OverflowedText';

export interface RecipientTableItem extends ESignTemplateRecipient {
  values: {
    name: VariableValue;
    email: VariableValue;
  };
}

export interface RecipientTableRowProps {
  item: RecipientTableItem;
}

const RecipientTableRow: FC<RecipientTableRowProps> = ({ item }) => {
  const isEmailEmpty = isEmptyVariableValue(item.values.email);
  const isNameEmpty = isEmptyVariableValue(item.values.name);

  return (
    <TableRow disableHover error={isEmailEmpty || isNameEmpty}>
      <TableBodyCell withRightPadding asNameCell error={isEmailEmpty || isNameEmpty} width={TableColumnSize.Recipient}>
        <OverflowedText>{item.name}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell withRightPadding error={isEmailEmpty} width={TableColumnSize.Name}>
        <OverflowedText>{getTableDisplayValue(item.values.name)}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell error={isNameEmpty} width={TableColumnSize.Email}>
        <OverflowedText>{getTableDisplayValue(item.values.email)}</OverflowedText>
      </TableBodyCell>
    </TableRow>
  );
};

export default RecipientTableRow;
