import { scaleBand } from '@visx/scale';

export const getYScale = (valuesArray: string[] | number[]) => {
  return scaleBand<string | number>({
    domain: valuesArray,
  });
};

export const currencyFormatter = (currency?: string, needCeil?: boolean) => {
  const abbrev = 'KMB';

  const round = (n: number, precision: number) => {
    const prec = 10 ** precision;
    const roundValue = Math.round(n * prec) / prec;
    return needCeil ? Math.ceil(roundValue) : roundValue;
  };

  return (amount: number) => {
    let base = Math.floor(Math.log(Math.abs(amount)) / Math.log(1000));
    const suffix = abbrev[Math.min(2, base - 1)];
    base = abbrev.indexOf(suffix) + 1;
    const isSpaceNeeded = (currency && currency.length > 1) || false;
    return `${currency}${isSpaceNeeded ? ' ' : ''}${suffix ? round(amount / 1000 ** base, 2) + suffix : amount}`;
  };
};

export const getAxisMax = (value: number) => {
  const valueLength = value.toString().length;
  const valueFirstSymbol = value.toString()[0];
  let temporaryValue;

  let zeros = '';

  for (let index = Number(valueFirstSymbol) + 1; ; index++) {
    if (index % 4 === 0) {
      temporaryValue = index;
      break;
    }
  }

  for (let i = 0; i < valueLength - 1; i++) {
    zeros += '0';
  }

  const resultValue = `${temporaryValue}${zeros}`;
  return Number(resultValue);
};
