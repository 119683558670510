import React from 'react';
import { EmailAttachment } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { EmailsColumnSizes } from 'components/LoanOriginationSystem/EmailView/EmailAttachmentsTable/TableColumnSizes';
import { batch, useDispatch } from 'react-redux';
import {
  downloadEmailAttachments,
  downloadEmailAttachmentsFailure,
  downloadEmailAttachmentsSuccess,
} from 'LoanOriginationSystemEmails/ActionCreator';
import { showSnack } from 'Snackbar/ActionCreator';
import { SnackbarComponentTypes } from 'Snackbar/Types';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { nanoid } from 'nanoid';
import { useEmailsApi } from 'providers/ApiServiceProvider';
import { useHistory } from 'react-router';
import { FileType } from 'pages/LoanOriginationSystem/FilePreview';
import styles from './EmailAttachmentLine.module.scss';
import { FileIcon } from 'static/images';
import {
  getBytesInMb,
  iconsByExtension,
  SMALL_SIZE_DISPLAY_SIZE,
} from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/ApplicationDocumentsTable/ApplicationDocumentItemRow/ApplicationDocumentItemRow';
import { DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';

interface EmailAttachmentLineProps {
  attachment: EmailAttachment;
}

const FILE_PREVIEW_PATH = `/los/filepreview/${FileType.EmailAttachment}`;

const EmailAttachmentLine = ({ attachment }: EmailAttachmentLineProps) => {
  const { id } = attachment;
  const dispatch = useDispatch();
  const downloadApi = useEmailsApi();
  const history = useHistory();

  const fileSize = Number(getBytesInMb(attachment.size).toFixed(1)) || SMALL_SIZE_DISPLAY_SIZE;

  const handleDownloadAttachment = async () => {
    const attachmentDownloadingId = nanoid();

    batch(() => {
      dispatch(
        showSnack('Downloading Attachment', {
          componentType: SnackbarComponentTypes.ApplicationSingleEmailAttachmentComponent,
          props: {
            attachment,
            attachmentDownloadingId,
          },
        }),
      );
      dispatch(downloadEmailAttachments(attachmentDownloadingId));
    });
    try {
      const response = await downloadApi.downloadSingleAttachment(id);
      downloadBlobFile(response);

      dispatch(downloadEmailAttachmentsSuccess(attachmentDownloadingId));
    } catch (error) {
      dispatch(downloadEmailAttachmentsFailure(attachmentDownloadingId));
    }
  };

  const attachmentExtension = attachment.name.slice(attachment.name.lastIndexOf('.') + 1);

  const handleOpenAttachmentPreview = () => {
    history.push(`${FILE_PREVIEW_PATH}/${attachmentExtension}/${attachment.id}/${attachment.name}`);
  };

  const generateRowActions = () => {
    return (
      <RowActions
        actions={[
          {
            title: 'View File',
            handler: () => handleOpenAttachmentPreview(),
            danger: false,
          },
          {
            title: 'Download File',
            handler: handleDownloadAttachment,
            danger: false,
          },
        ]}
      />
    );
  };

  return (
    <TableRow useActions onClick={() => handleOpenAttachmentPreview()}>
      <TableBodyCell width={EmailsColumnSizes.Name} noPadding>
        <div className={styles.formatIcon}>
          {iconsByExtension.get(attachmentExtension as DocumentExtension) || <FileIcon />}
        </div>
        {attachment.name}
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Format} overflowed disableHover>
        {attachmentExtension}
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Size}>{`${fileSize} Mb`}</TableBodyCell>
      {generateRowActions()}
    </TableRow>
  );
};

export default EmailAttachmentLine;
