import { MinifiedSubscription } from 'eventHandlers/AuthEventEmitter';
import base64ToObject from 'utils/base64ToObject';
import { ProductAccess } from 'components/RouteWithPermissions/Types';
import { mapValues } from 'lodash';

const SUBSCRIPTION_INFO_HEADER = 'x-digifi-subscription';

const getSubscription = (response: Response): ProductAccess | null => {
  const headerValue = response.headers.get(SUBSCRIPTION_INFO_HEADER);

  if (headerValue) {
    const parsedValue = base64ToObject<MinifiedSubscription>(headerValue);
    const subscription: ProductAccess = mapValues(parsedValue, (value) => value === 1)
    return subscription;
  }

  return null;
};

export default getSubscription;
