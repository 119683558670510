import formattedCountryList from 'components/CompanyInformation/CountryList';

const getCountryFromLocale = () => {
  const parsedLanguage = /^\w{1,2}-(\w{1,2})$/.exec(navigator.language);
  if (!parsedLanguage) {
    return '';
  }

  const [, countryCode] = parsedLanguage;
  const countryCodeValid = formattedCountryList.some(({ value }) => value === countryCode);
  return countryCodeValid ? countryCode : '';
};

export default getCountryFromLocale;
