import { ReduxState } from 'types/redux';
import { Task, TasksSortingType, TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import Pagination from 'pagination';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const TASKS_PER_PAGE_DEFAULT = 20;

export interface TasksPaginationParams {
  search: string;
  sortingType: TasksSortingType;
  dueDateRange: {
    from: Date | null;
    to: Date | null;
  };
  selectedStatus: TaskStatus | null;
  selectedMembers: UserInfo[];
  selectedApplicationId?: string;
}

const tasksPagination = Pagination<ReduxState, Task, TasksPaginationParams>(
  'loanOriginationSystemTasks',
  TASKS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default tasksPagination;
