import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';
import { DataFilter, DataViewSortingType, TableViewData } from './Types';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';

export enum ApplicationHistoryType {
  ApplicationCreated = 'applicationCreated',
  ApplicationDuplicated = 'applicationDuplicated',
  StatusTransition = 'statusTransition',
  DocumentCreated = 'documentCreated',
  DocumentDeleted = 'documentDeleted',
  NoteCreated = 'noteCreated',
  NoteDeleted = 'noteDeleted',
  TaskCreated = 'taskCreated',
  TaskDeleted = 'taskDeleted',
  TaskCompleted = 'taskCompleted',
  EmailCreated = 'emailCreated',
  EmailDeleted = 'emailDeleted',
  DecisionRun = 'decisionRun',
}

export enum ApplicationHistorySortingField {
  CreatedAt = 'createdAt',
}

export enum ApplicationHistoryFilterType {
  AllTypes = 'allTypes',
  ApplicationStatus = 'applicationStatus',
  Tasks = 'tasks',
  Emails = 'emails',
  Documents = 'documents',
  DecisionEngine = 'decisionEngine',
  Notes = 'notes',
}

export type ApplicationHistorySortingType = DataViewSortingType<ApplicationHistorySortingField>;

export interface BaseApplicationHistoryFilters {
  applicationId: string;
  selectedMembers: UserInfo[];
  filterType: ApplicationHistoryFilterType;
}

export type ApplicationHistoryFilters = BaseApplicationHistoryFilters & DataFilter;

interface BaseApplicationHistoryRecord<Type, Data> {
  createdAt: Date;
  createdBy?: UserInfo | null;
  type: Type;
  data: Data;
}

interface ApplicationCreatedData {
  borrowerName: string;
}

interface ApplicationDuplicatedData extends ApplicationCreatedData {
  originalApplicationDisplayId: string;
}

interface StatusTransitionData {
  transitionFrom: string;
  transitionTo: string;
  name: string;
}

interface TaskEventData {
  description: string;
}

interface EmailEventData {
  from: string;
  to: string;
  subject: string;
}

interface DecisionRunEventData {
  decisionName: string;
  strategyName: string;
  version: string;
  result: 'pass' | 'fail' | 'error';
}

interface DocumentEventData {
  documentName: string;
}

interface NoteEventData {
  description: string;
}

export type ApplicationHistoryRecord =
  | BaseApplicationHistoryRecord<ApplicationHistoryType.ApplicationCreated, ApplicationCreatedData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.ApplicationDuplicated, ApplicationDuplicatedData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.StatusTransition, StatusTransitionData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskCreated, TaskEventData & { taskId: string }>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskCompleted, TaskEventData & { taskId: string }>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.TaskDeleted, TaskEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.EmailCreated, EmailEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.EmailDeleted, EmailEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DecisionRun, DecisionRunEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DocumentCreated, DocumentEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.DocumentDeleted, DocumentEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.NoteCreated, NoteEventData>
  | BaseApplicationHistoryRecord<ApplicationHistoryType.NoteDeleted, NoteEventData>;

export interface ApplicationHistoryApi {
  getHistoryRecords(
    filters: ApplicationHistoryFilters,
    sortingType?: ApplicationHistorySortingType,
  ): Promise<TableViewData<ApplicationHistoryRecord>>;
}

const typesByHistoryFilterTypeMap = new Map([
  [ApplicationHistoryFilterType.AllTypes, []],
  [ApplicationHistoryFilterType.ApplicationStatus, [ApplicationHistoryType.StatusTransition]],
  [
    ApplicationHistoryFilterType.Tasks,
    [ApplicationHistoryType.TaskCreated, ApplicationHistoryType.TaskCompleted, ApplicationHistoryType.TaskDeleted],
  ],
  [ApplicationHistoryFilterType.Emails, [ApplicationHistoryType.EmailCreated, ApplicationHistoryType.EmailDeleted]],
  [
    ApplicationHistoryFilterType.Documents,
    [ApplicationHistoryType.DocumentCreated, ApplicationHistoryType.DocumentDeleted],
  ],
  [ApplicationHistoryFilterType.DecisionEngine, [ApplicationHistoryType.DecisionRun]],
  [ApplicationHistoryFilterType.Notes, [ApplicationHistoryType.NoteCreated, ApplicationHistoryType.NoteDeleted]],
]);

export default class ApplicationHistoryRestApi extends LoanOriginationSystemApi<ApplicationHistoryRecord>
  implements ApplicationHistoryApi {
  protected resourceName = 'application-history';

  public getHistoryRecords(filters: ApplicationHistoryFilters, sortingType?: ApplicationHistorySortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);
    const selectedTypes = typesByHistoryFilterTypeMap.get(filters.filterType) || [];

    params.set('applicationId', filters.applicationId);

    filters.selectedMembers.forEach((member) => params.append('teamMemberIds', member.id));
    selectedTypes.forEach((type) => params.append('types', type));

    return this.getResources<TableViewData<ApplicationHistoryRecord>>(params);
  }
}
