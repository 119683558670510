const ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_REQUEST =
  'ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_REQUEST';
const ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_SUCCESS =
  'ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_SUCCESS';
const ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_FAILURE =
  'ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_FAILURE';
const ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST = 'ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST';
const ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS = 'ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS';
const ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_FAILURE = 'ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_FAILURE';

export {
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_REQUEST,
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_SUCCESS,
  ACTION_TYPE_VERIFY_PASSWORD_GENERATE_SECOND_SECRET_FAILURE,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS,
  ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_FAILURE,
};
