import clsx from 'clsx';
import React, { FC } from 'react';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import ShareButton from 'components/BillingDashboard/ShareButton';
import styles from './TrialInfo.module.scss';
import { AppRoutes } from 'routes/RouteService';

interface TrialInfoProps {
  className?: string;
  innerContainerClassName?: string;
  endDate: Date;
}

const TrialInfo: FC<TrialInfoProps> = ({ className, endDate, innerContainerClassName }) => {
  return (
    <div className={clsx(className, styles.container)}>
      <div className={clsx(styles.innerContainer, innerContainerClassName)}>
        <div className={styles.info}>
          <p className={styles.infoTitle}>Free Trial</p>
          <div className={styles.infoContent}>
            Your free trial will end on <p className={styles.date}>{formatDate(endDate, DateTimeFormat.ShortDate)}</p>.
            Please add your payment details prior to this date to avoid being locked out of the platform.
          </div>
        </div>
        <ShareButton size="form" kind="primary" onClick={() => window.open(AppRoutes.CustomerPortal)}>
          Add Payment Details
        </ShareButton>
      </div>
    </div>
  );
};

export default TrialInfo;
