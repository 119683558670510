import {
  ProductSection,
  ProductSectionAccessPermissionsMap,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import { invert, mapKeys, mapValues } from 'lodash';

type SystemSectionShortNames = {
  [fullName in ProductSection]: string;
};

const systemSectionShortNames: SystemSectionShortNames = {
  loanOriginationSystemApplications: 'losApps',
  loanOriginationSystemBorrowers: 'losBorrow',
  loanOriginationSystemIntermediaries: 'losInterm',
  loanOriginationSystemTasks: 'losTasks',
  loanOriginationSystemEmails: 'losEmails',
  loanOriginationSystemReporting: 'losReports',
  loanOriginationSystemConfiguration: 'losConfig',

  decisionEngineStrategies: 'deStrateg',
  decisionEngineProcessing: 'deProcess',
  decisionEngineApiActivation: 'deApiActiv',
  decisionEngineReporting: 'deReports',

  companySettingsGeneral: 'csGeneral',
  companySettingsPlanAndBilling: 'csBilling',
  companySettingsTeam: 'csTeam',
  companySettingsVariables: 'csVars',
  companySettingsIntegrations: 'csIntegr',
} as const;

type SystemSectionFullNames = Record<SystemSectionShortNames[keyof SystemSectionShortNames], ProductSection>;
const systemSectionFullNames = invert(systemSectionShortNames) as SystemSectionFullNames;

export type MinifiedPermissions = Record<SystemSectionShortNames[keyof SystemSectionShortNames], 1 | 0>;

export const prettifySystemSectionAccessPermissions = (
  minifiedPermissions: MinifiedPermissions,
): ProductSectionAccessPermissionsMap => {
  const prettifiedKeys = mapKeys(minifiedPermissions, (value, key) => systemSectionFullNames[key]) as Record<
    ProductSection,
    1 | 0
  >;
  return mapValues(prettifiedKeys, (value) => value === 1);
};
