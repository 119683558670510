import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTasks, openDeleteTaskPopup, sortTasks } from 'LoanOriginationSystemTasks/ActionCreator';
import {
  filterTasksByMembers,
  resetAllTasksFilters,
  setSearchInputValue,
  toggleFiltersPopup,
} from 'LoanOriginationSystemTasks/Filters/ActionCreator';
import { editTask } from 'LoanOriginationSystemTasks/EditTask/ActionCreator';
import { ReduxState } from 'types/redux';
import { TasksPaginationParams } from './Pagination';
import { NewTask, TasksSortingFields, TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { LoanOriginationSystemTasksActionTypes } from 'LoanOriginationSystemTasks/ActionTypes';
import pagination from 'components/LoanOriginationSystem/TasksDashboard/Pagination';
import { initialState as filtersInitialState } from 'LoanOriginationSystemTasks/Filters/Reducer';
import { NoResultsIcon } from 'static/images';
import { isEqual } from 'lodash';
import styles from './TasksDashboard.module.scss';
import NoItems from 'components/NoItems';
import SearchNotFound from 'components/SearchNotFound';
import Title from './Title';
import TasksTable, { TasksTableProps } from './TasksTable';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import useStateReset from 'hooks/useStateReset';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import clsx from 'clsx';
import prolongRequest from 'utils/prolongRequest';

const EDIT_TASK_MIN_REQUEST_TIME = 1000;

interface TasksDashboardProps {
  size?: 'small' | 'normal';
  applicationId?: string;
  onCreate: () => void;
  onEdit: (id: string) => void;
  stickyMarginTop?: TasksTableProps['stickyMarginTop'];
}

const TasksDashboard = ({ size = 'normal', applicationId, onCreate, onEdit, stickyMarginTop }: TasksDashboardProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemTasks);
  const organizationMembers = useOrganizationMembers();

  useStateReset(LoanOriginationSystemTasksActionTypes.ResetState);

  const params: TasksPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    dueDateRange: filters.dueDateRange,
    selectedStatus: filters.selectedStatus,
    selectedMembers: filters.selectedMembers,
    selectedApplicationId: applicationId,
  };

  const tasks = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  useEffect(() => {
    dispatch(getTasks({ filters, sortingType, applicationId }));
  }, []);

  const onSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const onSort = (field: TasksSortingFields, ascending: boolean) => {
    dispatch(sortTasks(field, ascending));
  };

  const onFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const onTaskDeletePopupOpen = (id: string) => {
    dispatch(openDeleteTaskPopup(id));
  };

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterTasksByMembers(newSelectedMembers));
  };

  const onResetAllFilters = () => {
    dispatch(resetAllTasksFilters());
  };

  const prolongedChangeStatus = prolongRequest((id: string, editTaskData: Partial<NewTask>) => {
    return dispatchRoutine(editTask(id, editTaskData));
  }, EDIT_TASK_MIN_REQUEST_TIME);

  const onStatusChange = async (id: string, editTaskData: Partial<NewTask>) => {
    await prolongedChangeStatus(id, editTaskData);

    const message = getMessage(
      editTaskData.status === TaskStatus.Done ? MessageType.TaskMarkedAsCompleted : MessageType.TaskMarkedAsIncomplete,
    );

    notification.createNotification(message, 'success', dispatch);
  };

  const isNoTasks = !tasks.length && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const areFiltersChanged = () => {
    return filters.dueDateRange.from !== null || filters.dueDateRange.to !== null || filters.selectedStatus !== null;
  };

  const renderBody = () => {
    if (filters.searchInputValue && !tasks.length) {
      return (
        <SearchNotFound
          className={clsx(styles.notFoundContainer, size === 'normal' && styles.normal)}
          searchValue={filters.searchInputValue}
        />
      );
    }

    if (!tasks.length && isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No tasks have been added yet!"
          titleClassName={styles.tasksNoDataTitle}
          buttonMessage="Create Task"
          onButtonClick={onCreate}
          className={size === 'normal' ? styles.tasksNoData : styles.tasksNoDataSmall}
        />
      );
    }

    if (!tasks.length && !isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          className={size === 'normal' ? styles.tasksNoFilterResults : styles.tasksNoFilterResultsSmall}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noItemsSubtitle}
          onButtonClick={onResetAllFilters}
        />
      );
    }

    return (
      <TasksTable
        onTaskDeletePopupOpen={onTaskDeletePopupOpen}
        paginationProps={paginationProps}
        tasks={tasks}
        onSort={onSort}
        sortingType={sortingType}
        onTaskEdit={onEdit}
        onStatusChange={onStatusChange}
        size={size}
        stickyMarginTop={stickyMarginTop}
      />
    );
  };

  return (
    <>
      <Title
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        onMemberFilterChange={onMemberFilterChange}
        hideItem={isNoTasks}
        onSearch={onSearch}
        onFilterButtonClick={onFilterButtonClick}
        onCreateNewTask={onCreate}
        size={size}
        filtersChanged={areFiltersChanged()}
        search={filters.searchInputValue}
      />
      <div className={styles.contentContainer}>{renderBody()}</div>
    </>
  );
};

export default TasksDashboard;
