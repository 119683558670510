import { AnyAction, Middleware, MiddlewareAPI } from 'redux';

import { ACTION_TYPE_VERIFY_PASSWORD_GENERATE_FIRST_SECRET_SUCCESS } from './ActionTypes';

import notification from 'handlers/notification/notificationActionCreator';

import { nextStep } from 'GenerateFirstSecretWizard/Actions';

const onVerifyPasswordSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === ACTION_TYPE_VERIFY_PASSWORD_GENERATE_FIRST_SECRET_SUCCESS) {
    notification.createNotification('Password Verified.', 'success', dispatch);
    dispatch(nextStep());
  }
  return result;
};

export default onVerifyPasswordSuccessMiddleware;
