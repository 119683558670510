import { useContext, useLayoutEffect, useRef } from 'react';
import {
  PAGE_WRAPPER_OFFSET_EVENT_OFFSET,
  PAGE_WRAPPER_OFFSET_EVENT_REFRESH,
  PageWrapperOffsetContext,
} from './MainLayout';

const usePageWrapperOffset = () => {
  const offsetEmitter = useContext(PageWrapperOffsetContext)!;

  const offsetRef = useRef(0);

  useLayoutEffect(() => {
    const listener = () => {
      offsetEmitter.emit(PAGE_WRAPPER_OFFSET_EVENT_OFFSET, offsetRef.current);
    };
    offsetEmitter.addListener(PAGE_WRAPPER_OFFSET_EVENT_REFRESH, listener);
    return () => {
      offsetEmitter.removeListener(PAGE_WRAPPER_OFFSET_EVENT_REFRESH, listener);
    };
  }, []);

  return (offset: number) => {
    offsetRef.current = offset;
  };
};

export default usePageWrapperOffset;
