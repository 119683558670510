import React, { Children, FC, ReactElement, useEffect, useRef, useState } from 'react';
import styles from './ContextualViewWizard.module.scss';
import useCachedList from 'hooks/cachedList';

interface ContextualViewWizardProps {
  children: (ReactElement | null)[];
  step: number;
  onComponentLeave?: () => void;
  width?: string;
}

const ContextualViewWizard: FC<ContextualViewWizardProps> = ({
  children,
  step,
  onComponentLeave = () => {},
  width,
}) => {
  const [currentChildHeight, setCurrentChildHeight] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rafRef = useRef<number | null>(null);

  const recalculateHeight = () => {
    const { children: containerChildren } = containerRef.current!;
    const { offsetHeight } = containerChildren[step] as HTMLElement;

    setCurrentChildHeight(offsetHeight);

    rafRef.current = window.requestAnimationFrame(recalculateHeight);
  };

  useEffect(() => {
    rafRef.current = window.requestAnimationFrame(recalculateHeight);

    return () => {
      cancelAnimationFrame(rafRef.current!);
    };
  }, [step]);

  useEffect(() => {
    return () => {
      onComponentLeave();
    };
  }, []);

  const [visibleChildren, onTransitionEnd, cachedStep] = useCachedList(children, step);

  const renderChildren = () => {
    return Children.map(visibleChildren, (popup, index) => (
      <div
        className={styles.contextualViewSlide}
        style={step !== index ? { height: currentChildHeight || 'auto' } : {}}
      >
        {popup}
      </div>
    ));
  };

  return (
    <div
      className={styles.contextualViewContentSlideContainer}
      style={{
        transform: `translateX(-${cachedStep! * 100}%)`,
        height: currentChildHeight || 'auto',
        width,
      }}
      ref={containerRef}
      onTransitionEnd={onTransitionEnd}
    >
      {renderChildren()}
    </div>
  );
};

export default ContextualViewWizard;
