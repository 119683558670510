import React from 'react';
import styles from './InfoPanelTitle.module.scss';
import { DropdownImage } from 'static/images';
import clsx from 'clsx';
import EditLink from 'components/EditLink';
import { useQueryParams } from 'hooks/useQueryParam';

interface InfoPanelTitleProps {
  statusName: string;
  productName: string;
  statusNumber: number;
  statusesAmount: number;
  setOpen: () => void;
  currentState: boolean;
  hideEditApplicationLink?: boolean;
  isStatusVisible: boolean;
}

const InfoPanelTitle = ({
  productName,
  statusesAmount,
  statusName,
  statusNumber,
  setOpen,
  currentState,
  hideEditApplicationLink,
  isStatusVisible,
}: InfoPanelTitleProps) => {
  const queryParams = useQueryParams();

  return (
    <div className={styles.infoPanelTitleInner}>
      <div className={styles.infoPanelTitle}>
        <span className={styles.infoPanelTitleName}>
          {productName}: {statusName}
        </span>
        {statusName !== 'Rejected' && statusName !== 'Approved' && isStatusVisible && (
          <p className={styles.infoPanelTitleNumber}>
            {statusNumber}/{statusesAmount}
          </p>
        )}
        {!hideEditApplicationLink && (
          <div className={styles.editLinkContainer}>
            <EditLink href={`?tab-id=${queryParams.get('tab-id') || ''}&edit`}>Edit Application Form</EditLink>
          </div>
        )}
      </div>
      <DropdownImage
        onClick={setOpen}
        className={clsx(styles.infoPanelTitleDropdownIcon, currentState && styles.infoPanelTitleDropdownIconActive)}
      />
    </div>
  );
};

export default InfoPanelTitle;
