import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';
import { OrganizationUsersSortingType } from 'api/Core/UserApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';

export const USERS_PER_PAGE_DEFAULT = 10;

export interface UsersPaginationParams {
  searchInputValue: string;
  sortingType: OrganizationUsersSortingType;
  permissionType: UserRoleName | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
  showInactive: boolean;
}

const usersPagination = Pagination<ReduxState, OrganizationUser, UsersPaginationParams>(
  'organizationUsers',
  USERS_PER_PAGE_DEFAULT,
);

export default usersPagination;
