import { useMemo } from 'react';
import { getSamplePhoneNumber } from './utils';
import { CountryCode } from 'libphonenumber-js';

const DEFAULT_PLACEHOLDER = 'Phone Number';

const useSamplePhoneNumber = (country: string | null) => {
  return useMemo(() => {
    if (!country) {
      return DEFAULT_PLACEHOLDER;
    }
    return getSamplePhoneNumber(country as CountryCode) || DEFAULT_PLACEHOLDER;
  }, [country]);
};

export default useSamplePhoneNumber;
