import React, { FC, useState, FocusEvent } from 'react';
import TextInput from 'components/TextInput/TextInput';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import useMask from 'hooks/useMask';
import getIdentificationNumberMask from 'utils/masks/maskIdentificationNumber';
import { removeNonDigits } from 'utils/masks/maskNumberValue';

interface IdentificationNumberInput {
  value: string;
  onChange: (value: string) => void;
  identificationNumberType: string;
  identificationNumberDescription: string | null;
  labelTitle: string;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  errorMessage: string;
  tabIndex?: number;
}

const otherTypeMask = (value: string) => {
  if (value.length > 100) {
    return value.slice(0, 100);
  }
  return value;
};

export const getPlaceholder = (
  identificationNumberType: string | null,
  identificationNumberDescription: string | null,
) => {
  switch (identificationNumberType) {
    case 'USSocial':
      return 'U.S. Social Security Number (###-##-####)';
    case 'USEmployer':
      return 'U.S. Employer Identification Number (##-#######)';
    case 'CanadaSocial':
      return 'Canada Social Insurance Number (###-###-###)';
    default:
      return identificationNumberDescription || '';
  }
};

const IdentificationNumberInput: FC<IdentificationNumberInput> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  disabled,
  identificationNumberType,
  labelTitle,
  identificationNumberDescription,
  errorMessage,
  loaderState,
  showLoader,
  onLoaderStateReset,
  tabIndex,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setInputValue(value);
    setIsFocused(true);

    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onChange(inputValue);
    setIsFocused(false);

    onBlur?.(event);
  };

  const handleChange = (val: string) => {
    setInputValue(val);
    onChange(val);
  };

  const visualValue = isFocused ? inputValue : value;

  const maskIdentificationNumberValue = getIdentificationNumberMask(identificationNumberType);

  const isOtherIdentificationNumberType = identificationNumberType === 'Other';

  const [maskedValue, inputRef, handleInputChange, handleKeyDown, handleKeyUp, handleClick] = useMask(
    visualValue,
    (val: string) => handleChange(val),
    maskIdentificationNumberValue,
    isOtherIdentificationNumberType ? otherTypeMask : removeNonDigits,
    (char: string) => char === '-',
  );

  return (
    <TextInput
      labelTitle={labelTitle}
      placeholder={getPlaceholder(identificationNumberType, identificationNumberDescription)}
      value={maskedValue}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onClick={handleClick}
      inputRef={inputRef}
      disabled={disabled}
      hasRightNeighbour
      errorMessage={errorMessage}
      showLoader={showLoader}
      loaderState={loaderState}
      onLoaderStateReset={onLoaderStateReset}
      tabIndex={tabIndex}
    />
  );
};

export default IdentificationNumberInput;
