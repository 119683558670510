import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { CloseButtonImage } from 'static/images';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ProductStatus, ProductType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { createProduct } from 'LoanOriginationSystemProducts/ActionCreator';
import ProductBasicInfoForm from 'components/LoanOriginationSystem/ProductBasicInfoForm';
import Button from 'components/Button';
import styles from './CreateProduct.module.scss';

export interface BasicInfoFormData {
  productName: string;
  borrowerType: BorrowerType;
  type: ProductType | null;
  status: ProductStatus;
}

export interface CreateProductProps {
  onClose: () => void;
}

const CreateProduct = ({ onClose }: CreateProductProps) => {
  const dispatch = useDispatch();

  const [basicInfoData, setBasicInfoData] = useState<BasicInfoFormData>({
    productName: '',
    borrowerType: BorrowerType.Person,
    type: ProductType.Custom,
    status: ProductStatus.Active,
  });

  const { isProductCreatingInProgress } = useSelector((state: ReduxState) => state.loanOriginationSystemProducts);

  const isBasicInfoFormValid = () =>
    basicInfoData.productName.trim() && basicInfoData.borrowerType && basicInfoData.type;

  const handleProductNameChange = (productName: string) => setBasicInfoData({ ...basicInfoData, productName });
  const handleBorrowerTypeChange = (borrowerType: BorrowerType) => setBasicInfoData({ ...basicInfoData, borrowerType });
  const handleProductTypeChange = (type: ProductType | null) => setBasicInfoData({ ...basicInfoData, type });
  const handleInitialProductStatusChange = (status: ProductStatus) => setBasicInfoData({ ...basicInfoData, status });

  const handleProductCreate = () => {
    dispatch(
      createProduct(
        basicInfoData.productName.trim(),
        basicInfoData.borrowerType,
        basicInfoData.type!,
        basicInfoData.status,
      ),
    );
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Create Product</h2>
      <ProductBasicInfoForm
        productName={basicInfoData.productName}
        borrowerType={basicInfoData.borrowerType}
        productType={basicInfoData.type}
        initialProductStatus={basicInfoData.status}
        onProductNameChange={handleProductNameChange}
        onBorrowerTypeChange={handleBorrowerTypeChange}
        onProductTypeChange={handleProductTypeChange}
        onInitialProductStatusChange={handleInitialProductStatusChange}
      />
      <Button
        className={styles.createProductButton}
        onClick={handleProductCreate}
        kind="primary"
        size="form"
        isLoading={isProductCreatingInProgress}
        disabled={!isBasicInfoFormValid()}
      >
        Create Product
      </Button>
      <CloseButtonImage onClick={onClose} className={styles.closeImage} />
    </div>
  );
};

export default CreateProduct;
