import React, { FC } from 'react';

import styles from './EditCompanyUser.module.scss';
import { CloseButtonImage } from 'static/images';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';

interface EditCompanyUserProps {
  onClose: () => void;
}

const EditCompanyUser: FC<EditCompanyUserProps> = ({ onClose }) => {
  return (
    <div className={styles.editUser}>
      <div className={styles.notFoundUserHeader}>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <ErrorPageInfo errorType={ErrorPageType.NotFoundContextualView} errorTitle="The page was not found." />
    </div>
  );
};

export default EditCompanyUser;
