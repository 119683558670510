import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import { Variable } from 'Variables/VariablesTypes';

const getDataTypeAttributes = (sourceVariable?: Variable) => {
  return sourceVariable
    ? getVisualDataTypeWithAttributes(sourceVariable!)
    : {
        dataType: undefined,
      };
};

export default getDataTypeAttributes;
