export enum ApplicationDataTabVariableConfigurationsActionType {
  CreateApplicationDataTabVariableConfiguration = 'applicationDataTabVariableConfiguration/createApplicationDataTabVariableConfiguration',
  CreateApplicationDataTabVariableConfigurationSuccess = 'applicationDataTabVariableConfiguration/createApplicationDataTabVariableConfigurationSuccess',
  CreateApplicationDataTabVariableConfigurationFailure = 'applicationDataTabVariableConfiguration/createApplicationDataTabVariableConfigurationFailure',
  UpdateApplicationDataTabVariableConfiguration = 'applicationDataTabVariableConfiguration/updateApplicationDataTabVariableConfiguration',
  UpdateApplicationDataTabVariableConfigurationSuccess = 'applicationDataTabVariableConfiguration/updateApplicationDataTabVariableConfigurationSuccess',
  UpdateApplicationDataTabVariableConfigurationFailure = 'applicationDataTabVariableConfiguration/updateApplicationDataTabVariableConfigurationFailure',
  DeleteApplicationDataTabVariableConfiguration = 'applicationDataTabVariableConfiguration/deleteApplicationDataTabVariableConfiguration',
  DeleteApplicationDataTabVariableConfigurationSuccess = 'applicationDataTabVariableConfiguration/deleteApplicationDataTabVariableConfigurationSuccess',
  DeleteApplicationDataTabVariableConfigurationFailure = 'applicationDataTabVariableConfiguration/deleteApplicationDataTabVariableConfigurationFailure',
}
