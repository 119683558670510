import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropResult } from 'react-beautiful-dnd';
import { ReduxState } from 'types/redux';
import Button from 'components/Button';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import VariablesConfiguration from 'components/LoanOriginationSystem/VariablesConfiguration';
import BaseTabLayout, {
  BaseTabLayoutProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/BaseTabLayout';
import { getCardsByTabId } from 'LoanOriginationSystemApplicationDataTabCards/Selectors';
import {
  createApplicationDataTabCard,
  deleteApplicationDataTabCard,
  getApplicationDataTabCards,
  updateApplicationDataTabCard,
} from 'LoanOriginationSystemApplicationDataTabCards/ActionCreator';
import DataTabRow from './DataTabRow';
import SkeletonDataTab from './SkeletonDataTab';
import AddCardButton from './AddCardButton';
import styles from './DataTab.module.scss';
import {
  createApplicationDataTabVariableConfiguration,
  deleteApplicationDataTabVariableConfiguration,
  updateApplicationDataTabVariableConfiguration,
} from 'ApplicationDataTabVariableConfigurations/ActionCreator';
import getPosition from 'utils/getPosition';
import { ApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { Variable } from 'Variables/VariablesTypes';

const DataTab = ({ tab, ...restProps }: BaseTabLayoutProps) => {
  const dispatch = useDispatch();
  const [variablesPaneOpen, setVariablesPaneOpen] = useState(false);
  const cards: ApplicationDataTabCard[] | null = useSelector((state: ReduxState) => getCardsByTabId(state, tab.id));

  useEffect(() => {
    if (!cards) {
      dispatch(getApplicationDataTabCards(tab.id));
    }
  }, [tab]);

  const handleAddVariableButtonClick = () => {
    setVariablesPaneOpen(true);
  };

  const cardsLayout = useFormLayout(cards);

  const handleCardNameUpdate = (cardId: string, name: string) => {
    dispatch(updateApplicationDataTabCard(cardId, name));
  };

  const handleCardDelete = (cardId: string) => {
    dispatch(deleteApplicationDataTabCard(cardId));
  };

  const handleCardAdd = (row: number, column: number) => {
    if (!cards) {
      return;
    }

    const rowCards = cards
      .filter((card) => card.row === row)
      .sort((firstCard, secondCard) => firstCard.position - secondCard.position);

    const position = getPosition(rowCards, column);

    dispatch(createApplicationDataTabCard('New Card', tab.id, position, row));
  };

  const handleVariableAdd = (cardId: string, column: number, row: number, variable: Variable) => {
    const targetCard = cards?.find((card) => card.id === cardId);

    if (!targetCard) {
      return;
    }

    const targetColumn = targetCard.fields
      .filter((field) => field.column === column)
      .sort((firstField, secondField) => firstField.position - secondField.position);

    const position = getPosition(targetColumn, row);

    dispatch(createApplicationDataTabVariableConfiguration(cardId, tab.productId, column, position, variable));
  };

  const handleVariableDelete = (cardId: string, variableConfigurationId: string) => {
    dispatch(deleteApplicationDataTabVariableConfiguration(variableConfigurationId));
  };

  const handleVariableReorder = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination || !cards) {
      return;
    }

    const [cardId, columnIndex] = destination.droppableId.split('-');
    const [sourceCardId, sourceColumnIndex] = source.droppableId.split('-');

    const sourceCard = cards.find((card) => card.id === sourceCardId);
    const targetCard = cards.find((card) => card.id === cardId);

    if (!targetCard || !sourceCard) {
      return;
    }

    const sourceColumn = sourceCard.fields
      .filter((field) => field.column === Number(sourceColumnIndex))
      .sort((firstField, secondField) => firstField.position - secondField.position);

    const variableConfigurationIdToReorder = sourceColumn[source.index].id;

    const targetColumn = targetCard.fields
      .filter((field) => field.column === Number(columnIndex) && field.id !== variableConfigurationIdToReorder)
      .sort((firstField, secondField) => firstField.position - secondField.position);

    const position = getPosition(targetColumn, destination.index);

    dispatch(
      updateApplicationDataTabVariableConfiguration(variableConfigurationIdToReorder, {
        position,
        column: Number(columnIndex),
        cardId: targetCard.id,
      }),
    );
  };

  const renderActions = () => {
    return (
      <div>
        <Button
          className={styles.addVariableButton}
          disabled={!cards || !cards.length}
          kind="secondary"
          onClick={handleAddVariableButtonClick}
        >
          Add Custom Variable
        </Button>
      </div>
    );
  };

  const renderDataTabContent = () => {
    if (!cardsLayout) {
      return <SkeletonDataTab />;
    }

    if (!cardsLayout.length) {
      return <AddCardButton onClick={() => handleCardAdd(0, 0)} />;
    }

    return (
      <VariablesConfiguration
        variablesPaneOpen={variablesPaneOpen}
        onClosePane={() => setVariablesPaneOpen(false)}
        onReorder={handleVariableReorder}
        onAddVariable={handleVariableAdd}
      >
        {(placeholderStyles, droppableType, draggingId, sourceDroppableId) => (
          <div className={styles.rowsContainer}>
            {cardsLayout.map((cardsRow, index) => (
              <DataTabRow
                key={index}
                cards={cardsRow}
                droppableType={droppableType}
                sourceDroppableId={sourceDroppableId}
                draggingId={draggingId}
                placeholderStyles={placeholderStyles}
                onCardAdd={handleCardAdd}
                onCardNameUpdate={handleCardNameUpdate}
                onCardDelete={handleCardDelete}
                onVariableDelete={handleVariableDelete}
                rowIndex={index}
              />
            ))}
          </div>
        )}
      </VariablesConfiguration>
    );
  };

  return (
    <BaseTabLayout {...restProps} tab={tab} className={styles.container} renderActions={renderActions}>
      {renderDataTabContent()}
    </BaseTabLayout>
  );
};

export default DataTab;
