import Pagination from 'pagination';
import { ESignTemplateFilterStatus, ESignTemplatesSortingType } from 'api/Core/ESignTemplatesApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ReduxState } from 'types/redux';
import { DateRange } from 'api/LoanOriginationSystem/Types';

export const DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT = 20;

export interface DocuSignTemplatesPaginationParams {
  search: string;
  sortingType: ESignTemplatesSortingType;
  integrationId?: string;
  selectedMembers: UserInfo[];
  selectedStatus: ESignTemplateFilterStatus | null;
  createdDateRange: DateRange;
  updatedDateRange: DateRange;
}

const docuSignTemplatesPagination = Pagination<ReduxState, string, DocuSignTemplatesPaginationParams>(
  'docuSign',
  DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default docuSignTemplatesPagination;
