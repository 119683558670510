import { useEffect, useRef, useState } from 'react';

const DEFAULT_OBSERVER_OPTIONS = {
  root: document.body,
  rootMargin: '0px 0px -100% 0px',
  threshold: 0,
};

const useSticky = <HTMLElement extends Element>() => {
  const [isStuck, setIsStuck] = useState(false);
  const elementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsStuck(entry.isIntersecting), DEFAULT_OBSERVER_OPTIONS);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  return { isStuck, elementRef };
};

export default useSticky;
