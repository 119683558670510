import { AnyAction } from 'redux';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { Task, TasksSortingFields, TasksSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemTasksFiltersState } from './Filters/Types';

export type GetTasksDataSuccess = TableViewData<Task> & { searchInputValue: string; applicationId?: string };

export interface GetTasksAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.GetTasks;
  payload: {
    filters: LoanOriginationSystemTasksFiltersState;
    sortingType: TasksSortingType;
    applicationId?: string;
    notReload?: boolean;
  };
}

export interface GetTasksSuccessAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.GetTasksSuccess;
  payload: GetTasksDataSuccess;
}

export interface SortTasksAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.SortTasks;
  payload: TasksSortingType;
}

export interface DeleteTaskAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.DeleteTask;
  payload: {
    taskIdToDelete: string;
  };
}

export interface DeleteTaskSuccessAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.DeleteTaskSuccess;
}

export interface OpenDeleteTaskPopupAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.OpenDeleteTaskPopup;
  payload: {
    taskIdToDelete: string;
  };
}

export interface CloseDeleteTaskPopupAction extends AnyAction {
  type: LoanOriginationSystemTasksActionTypes.CloseDeleteTaskPopup;
}

export const getTasks = (params: {
  filters: LoanOriginationSystemTasksFiltersState;
  sortingType: TasksSortingType;
  applicationId?: string;
  notReload?: boolean;
}): GetTasksAction => {
  const { filters, sortingType, applicationId, notReload } = params;

  return {
    type: LoanOriginationSystemTasksActionTypes.GetTasks,
    payload: {
      filters,
      sortingType,
      applicationId,
      notReload,
    },
  };
};

export const getTasksSuccess = (tasksData: GetTasksDataSuccess): GetTasksSuccessAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.GetTasksSuccess,
    payload: tasksData,
  };
};

export const getTasksFailed = FailedActionCreator(LoanOriginationSystemTasksActionTypes.GetTasksFailure);

export const sortTasks = (field: TasksSortingFields, ascending: boolean): SortTasksAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.SortTasks,
    payload: {
      field,
      ascending,
    },
  };
};

export const deleteTask = (taskIdToDelete: string): DeleteTaskAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.DeleteTask,
    payload: {
      taskIdToDelete,
    },
  };
};

export const deleteTaskSuccess = (): DeleteTaskSuccessAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.DeleteTaskSuccess,
  };
};

export const deleteTasksFailure = FailedActionCreator(LoanOriginationSystemTasksActionTypes.DeleteTaskFailure);

export const openDeleteTaskPopup = (taskIdToDelete: string): OpenDeleteTaskPopupAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.OpenDeleteTaskPopup,
    payload: {
      taskIdToDelete,
    },
  };
};

export const closeDeleteTaskPopup = (): CloseDeleteTaskPopupAction => {
  return {
    type: LoanOriginationSystemTasksActionTypes.CloseDeleteTaskPopup,
  };
};
