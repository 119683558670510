import { FailedAction } from 'utils/actions/FailedActionCreator';
import { BorrowerConfigurationState } from './Types';
import { BorrowerConfigurationActionType } from './ActionTypes';
import { DownloadApiRequestTemplateAction, DownloadApiRequestTemplateSuccessAction } from './ActionCreator';

export type BorrowerConfigurationActions =
  | DownloadApiRequestTemplateAction
  | DownloadApiRequestTemplateSuccessAction
  | FailedAction<BorrowerConfigurationActionType.DownloadApiRequestTemplateFailure>;

export const initialState: BorrowerConfigurationState = {
  isDownloadApiRequestTemplateInProgress: false,
};

const borrowerConfigurationReducer = (
  state: BorrowerConfigurationState = initialState,
  action: BorrowerConfigurationActions,
): BorrowerConfigurationState => {
  switch (action.type) {
    case BorrowerConfigurationActionType.DownloadApiRequestTemplate: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: true,
      };
    }
    case BorrowerConfigurationActionType.DownloadApiRequestTemplateFailure:
    case BorrowerConfigurationActionType.DownloadApiRequestTemplateSuccess: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default borrowerConfigurationReducer;
