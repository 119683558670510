import { AnyAction } from 'redux';
import { CompanyLogoUploadActionType } from './ActionTypes';
import { CompanyLogoUploadDialogState } from './Types';

const initialState: CompanyLogoUploadDialogState = {
  isOpen: false,
  isLoading: false,
};

const CompanyLogoUploadDialogReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CompanyLogoUploadActionType.OpenCompanyLogoUploadDialog: {
      return { ...state, isOpen: true };
    }
    case CompanyLogoUploadActionType.CloseCompanyLogoUploadDialog: {
      return { ...state, isOpen: false };
    }
    case CompanyLogoUploadActionType.UploadCompanyLogo: {
      return { ...state, isLoading: true };
    }
    case CompanyLogoUploadActionType.UploadCompanyLogoSuccess: {
      return { ...state, isLoading: false };
    }
    case CompanyLogoUploadActionType.UploadCompanyLogoFailure: {
      return { ...state, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export default CompanyLogoUploadDialogReducer;
