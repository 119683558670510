import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemTasksApi, Task } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { GetTaskDataAction, getTaskDataFailure, getTaskDataSuccess } from './ActionCreator';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';

const GetTaskDataMiddleware = (api: LoanOriginationSystemTasksApi) =>
  Fetcher<Task, GetTaskDataAction>(
    LoanOriginationSystemEditTaskActionTypes.GetTask,
    getTaskDataSuccess,
    getTaskDataFailure,
    (action) => {
      return api.getTaskData(action.payload.id);
    },
  );

export default GetTaskDataMiddleware;
