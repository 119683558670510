import React, { useEffect, useState } from 'react';
import { CloseButtonImage, EmailImage, PhoneImage } from 'static/images';
import { useDispatch, useSelector } from 'react-redux';
import { SkeletonRectangle } from 'components/Skeleton';
import { updateIntermediary } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { ReduxState } from 'types/redux';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import styles from './IntermediaryDetails.module.scss';
import EditIntermediary from './Tabs/EditIntermediary';
import IntermediaryApplications from './Tabs/IntermediaryApplications';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { useHistory } from 'react-router';
import { getIntermediaries } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';
import {
  getIntermediaryData,
  resetIntermediaryDetails,
  selectIntermediaryViewTab,
} from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';
import ButtonRound from 'components/ButtonRound';
import { getMailtoURI } from 'Email/utils';

interface IntermediaryDetailsProps {
  onClose: () => void;
  id: string;
  setDataWasChanged: (changed: boolean) => void;
}

export enum IntermediaryDetailsTab {
  Profile = 'Profile',
  Applications = 'Applications',
}

const TABS: TabSwitchOption[] = [
  { label: IntermediaryDetailsTab.Profile, id: IntermediaryDetailsTab.Profile },
  { label: IntermediaryDetailsTab.Applications, id: IntermediaryDetailsTab.Applications },
];

const EMAIL_BUTTON_TOOLTIP = 'Email is not provided';
const PHONE_BUTTON_TOOLTIP = 'Phone number is not provided';

const IntermediaryDetails = ({ onClose, id, setDataWasChanged }: IntermediaryDetailsProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const { intermediary, contextualViewTab } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediaryDetails,
  );

  const history = useHistory();

  const { isUpdatingInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediariesState.editIntermediary,
  );

  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemIntermediariesState);

  const [intermediaryData, setIntermediaryData] = useState({});

  useEffect(() => {
    if (!intermediary) {
      dispatch(getIntermediaryData(id));

      return;
    }

    setIntermediaryData(intermediary.variables);
  }, [intermediary]);

  useEffect(() => {
    return () => {
      dispatch(resetIntermediaryDetails());
    };
  }, []);

  const handleIntermediaryUpdate = async () => {
    await dispatchRoutine(updateIntermediary(intermediaryData, id));

    history.push('/los/intermediaries');

    dispatch(getIntermediaries(filters, sortingType));
  };

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: intermediary!.variables[IntermediaryDefaultVariable.Email]
        ? intermediary!.variables[IntermediaryDefaultVariable.Email]!.toString()
        : '',
    });
  };

  const handlePhoneCall = () => {
    window.location.href = `tel:${intermediary!.variables[IntermediaryDefaultVariable.PhoneNumber]}`;
  };

  const renderHeader = () => {
    if (!intermediary) {
      return <SkeletonRectangle width="40%" height="44px" color="primary6" />;
    }

    return (
      <div className={styles.titleContainer}>
        <h2>{intermediary.variables[IntermediaryDefaultVariable.Name]}</h2>
        <ButtonRound
          className={styles.emailButton}
          disabled={!intermediary.variables[IntermediaryDefaultVariable.Email]}
          tooltip={!intermediary.variables[IntermediaryDefaultVariable.Email] ? EMAIL_BUTTON_TOOLTIP : ''}
          tooltipPosition="bottom-end"
          onClick={handleSendEmail}
        >
          <EmailImage />
        </ButtonRound>
        <ButtonRound
          disabled={!intermediary.variables[IntermediaryDefaultVariable.PhoneNumber]}
          tooltip={!intermediary.variables[IntermediaryDefaultVariable.PhoneNumber] ? PHONE_BUTTON_TOOLTIP : ''}
          tooltipPosition="bottom-start"
          onClick={handlePhoneCall}
        >
          <PhoneImage />
        </ButtonRound>
      </div>
    );
  };

  const renderTabs = () => {
    if (contextualViewTab === IntermediaryDetailsTab.Profile) {
      return (
        <EditIntermediary
          intermediaryFormData={intermediaryData}
          setIntermediaryFormData={setIntermediaryData}
          onIntermediaryUpdate={handleIntermediaryUpdate}
          isUpdatingInProgress={isUpdatingInProgress}
          intermediary={intermediary}
          setDataWasChanged={setDataWasChanged}
        />
      );
    }

    if (contextualViewTab === IntermediaryDetailsTab.Applications) {
      return <IntermediaryApplications intermediaryId={id} />;
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          {renderHeader()}
          <CloseButtonImage className={styles.closeButton} onClick={onClose} />
        </div>
        <div className={styles.tabsInner}>
          <TabSwitch
            tabs={TABS}
            selectedTabId={contextualViewTab}
            onSelect={({ id: optionId }) => dispatch(selectIntermediaryViewTab(optionId as IntermediaryDetailsTab))}
          />
          {renderTabs()}
        </div>
      </div>
    </div>
  );
};

export default IntermediaryDetails;
