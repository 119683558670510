import React, { FC, ChangeEvent, useEffect } from 'react';
import { BatchProcessingHistoryCase } from 'api/Types';
import { SIZES } from './TableColumnSizes';
import styles from './History.module.scss';
import { getBatchHistory } from 'StrategyProcessing/ActionCreator';
import SearchNotFound from 'components/SearchNotFound';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableActionCell from 'components/Table/TableActionCell';
import SkeletonHistoryLine from './SkeletonHistoryLine';
import HistoryLine from 'components/BatchProcessing/History/HistoryLine';
import TableBodyContent from 'components/Table/TableBodyContent';
import SearchInput from 'components/SearchInput';
import NoItems from 'components/NoItems';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import pagination from 'pages/BatchProcessing/Pagination';
import useStateReset from 'hooks/useStateReset';
import { BatchProcessingHistoryActionType } from 'StrategyProcessing/ActionTypes';

interface HistoryProps {
  empty?: boolean;
  onSearchInputChange: (searchInputValue: string) => void;
}

const History: FC<HistoryProps> = ({ onSearchInputChange }) => {
  const dispatch = useDispatch();

  const handleSearchInputChange =
    onSearchInputChange && (({ target }: ChangeEvent<HTMLInputElement>) => onSearchInputChange(target.value));

  useEffect(() => {
    dispatch(getBatchHistory());
  }, []);

  const searchInputValue = useSelector<ReduxState, string>(
    ({ batchProcessing }) => batchProcessing.history.searchInputValue,
  );

  const batchCases = pagination.usePaginatedItems({ searchInputValue });

  const paginationProps = pagination.usePagination({ searchInputValue });

  useStateReset(BatchProcessingHistoryActionType.ResetState);

  const isNoBatchHistory = !batchCases.length && !searchInputValue;

  const renderRow = (batchCase: BatchProcessingHistoryCase) => (
    <HistoryLine batchCase={batchCase} key={batchCase.id} searchInputValue={searchInputValue} />
  );

  const renderBody = () => {
    if (searchInputValue && !batchCases.length) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }

    if (!batchCases.length) {
      return (
        <NoItems
          title="No recorded activity yet!"
          className={styles.batchHistoryNoData}
          titleClassName={styles.batchHistoryNoDataTitle}
        />
      );
    }

    return (
      <div className={styles.table}>
        <Table>
          <TableHead useActions>
            <TableHeadCell width={SIZES.DATE}>Date</TableHeadCell>
            <TableHeadCell width={SIZES.BATCH_NAME}>Batch Name</TableHeadCell>
            <TableHeadCell width={SIZES.PROGRESS}>Progress</TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>
            <TableBodyContent
              rows={batchCases}
              renderSkeletonTableRow={(index) => <SkeletonHistoryLine key={index} />}
              renderTableRow={renderRow}
            />
          </TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    );
  };

  return (
    <div>
      <h4 className={styles.historyHeader__title}>History</h4>
      {!isNoBatchHistory && (
        <SearchInput
          placeholder="Search"
          containerClassName={styles.historySearchContainer}
          value={searchInputValue}
          onChange={handleSearchInputChange}
          onClear={() => onSearchInputChange('')}
        />
      )}
      {renderBody()}
    </div>
  );
};

export default History;
