import React from 'react';
import { DualOperandProps } from 'components/ComparisonOperatorPicker/Types';
import { ComparisonOperator } from 'components/SimpleRuleBuilder/Types';
import ComparisonOperand from 'components/ComparisonOperand';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import ComparisonOperandIn from 'components/ComparisonOperands/ComparisonOperandIn';

interface ComparisonOperandsProps extends DualOperandProps {
  comparison: ComparisonOperator;
}

const ComparisonOperands = (props: ComparisonOperandsProps & VisualDataTypeWithAttributes) => {
  const {
    comparison,

    firstOperandValue,
    firstOperandVariableId,
    firstOperandType,
    secondOperandValue,
    secondOperandVariableId,
    secondOperandType,

    onFirstOperandValueChange,
    onFirstOperandVariableIdChange,
    onFirstOperandTypeChange,
    onSecondOperandValueChange,
    onSecondOperandVariableIdChange,
    onSecondOperandTypeChange,

    ...dataTypeAttributes
  } = props;

  if (comparison === 'IS NULL' || comparison === 'IS NOT NULL') {
    return null;
  }

  if (comparison === 'IN' || comparison === 'NOT IN') {
    return (
      <ComparisonOperandIn
        firstOperandValue={firstOperandValue}
        onFirstOperandValueChange={onFirstOperandValueChange}
        {...dataTypeAttributes}
      />
    );
  }

  if (comparison === 'RANGE') {
    return (
      <div>
        <ComparisonOperand
          value={firstOperandValue}
          variableId={firstOperandVariableId}
          operandType={firstOperandType}
          onValueChange={onFirstOperandValueChange}
          onVariableIdChange={onFirstOperandVariableIdChange}
          onTypeChange={onFirstOperandTypeChange}
          {...dataTypeAttributes}
          labelTitle="Minimum"
        />
        <ComparisonOperand
          value={secondOperandValue}
          variableId={secondOperandVariableId}
          operandType={secondOperandType}
          onValueChange={onSecondOperandValueChange}
          onVariableIdChange={onSecondOperandVariableIdChange}
          onTypeChange={onSecondOperandTypeChange}
          {...dataTypeAttributes}
          labelTitle="Maximum"
        />
      </div>
    );
  }

  return (
    <ComparisonOperand
      value={firstOperandValue}
      variableId={firstOperandVariableId}
      operandType={firstOperandType}
      onValueChange={onFirstOperandValueChange}
      onVariableIdChange={onFirstOperandVariableIdChange}
      onTypeChange={onFirstOperandTypeChange}
      {...dataTypeAttributes}
      labelTitle="Value"
    />
  );
};

export default ComparisonOperands;
