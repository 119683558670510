import React from 'react';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import styles from './ChangeStatusConfirmationPopupContent.module.scss';
import DeclineReasonsForm from './DeclineReasonsForm/DeclineReasonsForm';
import clsx from 'clsx';

interface ChangeStatusConfirmationPopupContentProps {
  statusToChange: string;
  declineReasons: string[];
  selectedDeclineReasons: string[];
  onDeclineReasonsChange: (declineReason: string[]) => void;
}

const ChangeStatusConfirmationPopupContent = ({
  declineReasons,
  statusToChange,
  selectedDeclineReasons,
  onDeclineReasonsChange,
}: ChangeStatusConfirmationPopupContentProps) => {
  switch (statusToChange) {
    case DefaultApplicationStatus.Approved:
      return (
        <p className={clsx(styles.container, styles.changeStatusQuestion)}>
          Are you sure you want to approve this loan application?
        </p>
      );
    case DefaultApplicationStatus.Rejected:
      return (
        <DeclineReasonsForm
          selectedDeclineReasons={selectedDeclineReasons}
          declineReasons={declineReasons}
          onChange={onDeclineReasonsChange}
          className={styles.container}
        />
      );
    default:
      return (
        <p className={clsx(styles.container, styles.changeStatusQuestion)}>
          Are you sure you want to move this loan to the {statusToChange} status?
        </p>
      );
  }
};

export default ChangeStatusConfirmationPopupContent;
