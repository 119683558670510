import Debouncer from 'middlewares/Debouncer';
import { ApplicationNotesActionType } from './ActionTypes';
import { getApplicationNotes } from './ActionCreator';

export default Debouncer(
  (payload: { applicationId: string; search: string }, getState) => {
    const { loanOriginationSystemApplicationPage } = getState();
    const { notes } = loanOriginationSystemApplicationPage;

    return getApplicationNotes(payload.applicationId, payload.search, notes.sortingType);
  },
  [ApplicationNotesActionType.SetSearchValue],
);
