import React, { useEffect } from 'react';
import BillingTab from 'BillingPage/BillingTab';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import Title from './Title';
import styles from './BillingDashboard.module.scss';
import Overview from './Overview';
import Invoices from './Invoices';
import TrialInfo from './TrialInfo';
import { CSSTransition } from 'react-transition-group';
import { ReduxState } from 'types/redux';
import { useSelector, useDispatch } from 'react-redux';
import {
  getApplicationsCount,
  getApplicationsCountSuccess as setApplicationsCount,
  getBillingInfo,
  getDecisionsCount,
  getDecisionsCountSuccess as setDecisionsCountSuccess,
} from 'BillingPage/ActionCreator';

const TABS: TabSwitchOption[] = [
  { id: BillingTab.Overview, label: BillingTab.Overview },
  { id: BillingTab.Invoices, label: BillingTab.Invoices },
];

interface BillingDashboardProps {
  tab: BillingTab;
  onTabChange: (tab: BillingTab) => void;
}

const BillingDashboard = ({ tab, onTabChange }: BillingDashboardProps) => {
  const dispatch = useDispatch();

  const { isLoading, infoData, decisionsCount, applicationsCount, invoices, invoicesSortingType } = useSelector(
    (state: ReduxState) => state.billingPage,
  );

  const renderTrialInfo = () => {
    if (isLoading || !infoData || infoData.trialEndDate === null) {
      return null;
    }

    return (
      <CSSTransition
        in
        appear
        timeout={0}
        classNames={{
          enter: styles.trialInfoEnter,
          enterDone: styles.trialInfoEnterActive,
          appear: styles.trialInfoEnter,
          appearDone: styles.trialInfoEnterActive,
        }}
      >
        <TrialInfo
          endDate={infoData.trialEndDate}
          className={styles.trialInfo}
          innerContainerClassName={styles.trialInfoInnerContainer}
        />
      </CSSTransition>
    );
  };

  useEffect(() => {
    dispatch(getBillingInfo());
  }, []);

  useEffect(() => {
    if (infoData) {
      const currentDate = new Date();
      const firstDayOfTheMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

      if (infoData.products.loadOriginationSystem) {
        dispatch(getApplicationsCount(firstDayOfTheMonthDate, currentDate));
      } else {
        dispatch(setApplicationsCount(0));
      }

      if (infoData.products.decisionEngine) {
        dispatch(getDecisionsCount(firstDayOfTheMonthDate, currentDate));
      } else {
        dispatch(setDecisionsCountSuccess(0));
      }
    }
  }, [infoData]);

  return (
    <div>
      <Title className={styles.title} hidePaymentPortalLink={tab === BillingTab.Overview} />
      {renderTrialInfo()}
      <TabSwitch
        className={styles.tabSwitch}
        tabClassName={styles.tab}
        tabs={TABS}
        selectedTabId={tab}
        onSelect={({ id }) => onTabChange(id as BillingTab)}
      />
      {tab === BillingTab.Overview && (
        <Overview
          infoData={isLoading ? null : infoData}
          decisionsCount={decisionsCount}
          applicationsCount={applicationsCount}
        />
      )}
      {tab === BillingTab.Invoices && <Invoices invoices={invoices} sortingType={invoicesSortingType} />}
    </div>
  );
};

export default BillingDashboard;
