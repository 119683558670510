import { Middleware } from 'redux';
import { GET_VARIABLES_REQUEST } from './ActionTypes';
import {
  getVariablesError,
  GetVariablesRequestAction,
  getVariablesSuccess,
  VariablesWithParams,
} from './VariablesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { VARIABLES_PER_PAGE_DEFAULT } from 'pages/Variables/Pagination';
import { ReduxState } from 'types/redux';
import { VariablesApi } from 'api/Core/VariablesApi';

const GetVariablesMiddleware: (api: VariablesApi) => Middleware = (api) =>
  Fetcher<VariablesWithParams, GetVariablesRequestAction, ReduxState>(
    GET_VARIABLES_REQUEST,
    getVariablesSuccess,
    getVariablesError,
    async (action, { variables }) => {
      const { filters, sortingType, showArchived } = variables;
      const {
        searchInputValue,
        dueUpdatedDateRange,
        dueCreatedDateRange,
        onlyStandard,
        onlyCustom,
        dataType,
        selectedMembers,
      } = filters;

      const response = await api.getVariables(
        {
          offset: 0,
          count: VARIABLES_PER_PAGE_DEFAULT,
          search: searchInputValue,
          dueUpdatedDateRange,
          dueCreatedDateRange,
          onlyStandard,
          onlyCustom,
          visualDataType: dataType || undefined,
          teamMembers: selectedMembers,
          includeArchived: showArchived,
        },
        sortingType,
      );
      return {
        items: response.items,
        itemsTotal: response.total,
        searchInputValue,
      };
    },
  );

export default GetVariablesMiddleware;
