import CoreSystemApi from 'api/Core/CoreSystemApi';

export enum ImportableDocuSignTemplateStatus {
  New = 'new',
  Active = 'active',
  Inactive = 'inactive',
}

export enum ImportableDocuSignTemplateRecipientType {
  Agents = 'agents',
  CarbonCopies = 'carbonCopies',
  CertifiedDeliveries = 'certifiedDeliveries',
  Editors = 'editors',
  InPersonSigners = 'inPersonSigners',
  Intermediaries = 'intermediaries',
  Seals = 'seals',
  Signers = 'signers',
  Witnesses = 'witnesses',
}

export interface ImportableDocuSignTemplate {
  name: string;
  templateId: string;
  status: ImportableDocuSignTemplateStatus;
  recipientTypes: ImportableDocuSignTemplateRecipientType[];
}

export interface DocuSignImportApi {
  getTemplatesToImport(): Promise<ImportableDocuSignTemplate[]>;
  importBatch(templates: ImportableDocuSignTemplate[]): Promise<void>;
}

export default class DocuSignImportRestApi extends CoreSystemApi<ImportableDocuSignTemplate>
  implements DocuSignImportApi {
  public getTemplatesToImport() {
    return this.fetch<ImportableDocuSignTemplate[]>('/import-docu-sign-templates', 'GET');
  }

  public importBatch(templates: ImportableDocuSignTemplate[]) {
    return this.fetch<void>(`/import-docu-sign-templates`, 'POST', { templates });
  }
}
