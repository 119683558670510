import { Middleware } from 'redux';
import OrganizationActionType from './ActionTypes';
import {
  GetOrganizationInfoAction,
  getOrganizationInfoSuccess,
  getOrganizationInfoError,
} from './CompanyInformationActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { OrganizationApi, OrganizationDetailedInfo } from 'api/Core/OrganizationApi';

const GetCompanyInformationMiddleware: (api: OrganizationApi) => Middleware = (api) =>
  Fetcher<OrganizationDetailedInfo, GetOrganizationInfoAction>(
    OrganizationActionType.GetOrganizationInfo,
    getOrganizationInfoSuccess,
    getOrganizationInfoError,
    () => api.getCurrent(),
  );

export default GetCompanyInformationMiddleware;
