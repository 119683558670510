import {
  ApplicationsSortingType,
  DataFilter,
  DataViewSortingType,
  FormLayoutData,
  TableViewData,
  VariableValue,
} from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Application, UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import formatDate from 'utils/dateFormat';
import { MAX_COMMISSION_VALUE, MIN_COMMISSION_VALUE } from 'LoanOriginationSystemIntermediariesPage/Filters/Reducer';

export interface IntermediarySuggestionFilter {
  name: string;
  email: string;
  phoneNumber: string;
}

export interface GetIntermediariesDataResult {
  items: Intermediary[];
  total: number;
}

export interface ColumnProductDataFilter {
  search: string;
  members: UserInfo[];
  statuses: string[];
  labels: Label[];
  intermediaries: UserInfo[];
  createdDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

export interface Intermediary {
  id: string;
  variables: Record<string, VariableValue>;
  createdAt: Date;
  updatedAt: Date;
  createdBy: UserInfo;
  updatedBy: UserInfo;
}

export enum IntermediariesSortingField {
  IntermediaryName = 'name',
  IntermediaryType = 'type',
  Phone = 'phoneNumber',
  Email = 'email',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CommissionRate = 'commissionRate',
}

export enum IntermediaryApiRequestTemplateType {
  CreateIntermediary = 'createIntermediary',
  UpdateIntermediary = 'updateIntermediary',
}

interface IntermediaryDataFilter extends DataFilter {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueCommissionRange: {
    from: number;
    to: number;
  };
  borrowerTypes: string[];
  members: UserInfo[];
}

export type IntermediariesSortingType = DataViewSortingType<IntermediariesSortingField>;

export interface LoanOriginationSystemIntermediariesApi {
  getIntermediaries(
    filters?: IntermediaryDataFilter,
    sortingType?: IntermediariesSortingType,
  ): Promise<TableViewData<Intermediary>>;

  getIntermediary(id: string): Promise<Intermediary>;

  deleteIntermediary(id: string): Promise<Intermediary>;

  updateIntermediary(id: string, data: FormLayoutData): Promise<Intermediary>;

  createIntermediary(data: FormLayoutData): Promise<Intermediary>;

  getIntermediaryApplications(
    id: string,
    filter: DataFilter,
    sortingType: ApplicationsSortingType,
  ): Promise<TableViewData<Application>>;

  getIntermediarySuggestions(filter: IntermediarySuggestionFilter): Promise<Intermediary[]>;
}

export default class LoanOriginationSystemIntermediariesRestApi extends LoanOriginationSystemApi<Intermediary>
  implements LoanOriginationSystemIntermediariesApi {
  protected resourceName = 'intermediaries';

  public getIntermediaries(filters?: IntermediaryDataFilter, sortingType?: IntermediariesSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters?.dueCreatedDateRange.from) {
      params.append('dueCreatedDateFrom', formatDate(filters.dueCreatedDateRange.from));
    }

    if (filters?.dueCreatedDateRange.to) {
      params.append('dueCreatedDateTo', formatDate(filters.dueCreatedDateRange.to));
    }

    if (filters?.dueUpdatedDateRange.from) {
      params.append('dueUpdatedDateFrom', formatDate(filters.dueUpdatedDateRange.from));
    }

    if (filters?.dueUpdatedDateRange.to) {
      params.append('dueUpdatedDateTo', formatDate(filters.dueUpdatedDateRange.to));
    }

    if (filters && filters.dueCommissionRange.from !== MIN_COMMISSION_VALUE) {
      params.append('dueCommissionFrom', filters!.dueCommissionRange.from.toString());
    }

    if (filters && filters.dueCommissionRange.to !== MAX_COMMISSION_VALUE) {
      params.append('dueCommissionTo', filters!.dueCommissionRange.to.toString());
    }

    filters?.borrowerTypes.forEach((type) => params.append('borrowerTypeIds', type));
    filters?.members.forEach((member) => params.append('teamMemberIds', member.id));

    return this.getResources<TableViewData<Intermediary>>(params);
  }

  public getIntermediary(id: string) {
    return this.getResourceById(id);
  }

  public deleteIntermediary(id: string) {
    return this.deleteResource<Intermediary>(id);
  }

  public createIntermediary(data: FormLayoutData) {
    return this.createResource({
      intermediary: {
        variables: data,
      },
    });
  }

  public updateIntermediary(id: string, data: FormLayoutData) {
    return this.updateResource(id, { intermediary: { variables: data } });
  }

  public getIntermediaryApplications(id: string, filter: DataFilter, sortingType: ApplicationsSortingType) {
    const params = this.getPaginationUrlSearchParams(filter, sortingType);

    params.append('intermediaryIds', id);

    return this.fetch<TableViewData<Application>>(`/applications?${params}`);
  }

  public getIntermediarySuggestions(filter: IntermediarySuggestionFilter) {
    const params = new URLSearchParams();

    Object.keys(filter).forEach((key) => {
      if (filter[key]) {
        params.set(key, filter[key]);
      }
    });

    return this.fetch<Intermediary[]>(`/${this.resourceName}/suggestions/?${params}`);
  }
}
