type DateTime = string;

export type ComparisonOperator =
  | 'EQUAL'
  | 'NOT EQUAL'
  | 'FLOOR'
  | 'CAP'
  | 'GT'
  | 'LT'
  | 'IN'
  | 'NOT IN'
  | 'RANGE'
  | 'IS NULL'
  | 'IS NOT NULL';

export type GenericValue = boolean | number | string | DateTime;

export enum ComparisonOperandType {
  VARIABLE = 'variable',
  VALUE = 'value',
}

interface WithDataSource {
  dataSource: ComparisonOperandType;
}

interface ValueInput<Value> extends WithDataSource {
  value: Value;
  dataSource: ComparisonOperandType.VALUE;
}

interface VariableInput extends WithDataSource {
  variableId: string;
  dataSource: ComparisonOperandType.VARIABLE;
}

export type ValueRangeBorder<Value> = ValueInput<Value> | VariableInput;

export interface ValueRange<Value> {
  min: ValueRangeBorder<Value>;
  max: ValueRangeBorder<Value>;
}

interface ComparisonWithVariableOperand extends WithDataSource {
  operator: 'EQUAL' | 'NOT EQUAL' | 'FLOOR' | 'CAP' | 'GT' | 'LT' | 'IN' | 'NOT IN';
  operand: string;
  dataSource: ComparisonOperandType.VARIABLE;
}

interface GenericComparisonWithOperand extends WithDataSource {
  operator: ComparisonOperator;
  operand: GenericValue | GenericValue[] | ValueRange<GenericValue>;
  dataSource: ComparisonOperandType.VALUE;
}

interface EqualityComparisonWithOperand extends GenericComparisonWithOperand {
  operator: 'EQUAL' | 'NOT EQUAL';
  operand: GenericValue;
}

type ArithmeticComparableValue = number | DateTime;

interface ArithmeticComparisonWithOperand extends GenericComparisonWithOperand {
  operator: 'FLOOR' | 'CAP' | 'GT' | 'LT';
  operand: ArithmeticComparableValue;
}

interface InclusionComparisonWithOperand extends GenericComparisonWithOperand {
  operator: 'IN' | 'NOT IN';
  operand: GenericValue[];
}

interface RangeComparisonWithOperand {
  operator: 'RANGE';
  operand: ValueRange<ArithmeticComparableValue>;
}

interface NullEqualityComparison {
  operator: 'IS NULL' | 'IS NOT NULL';
}

type ComparisonWithValueOperand =
  | EqualityComparisonWithOperand
  | ArithmeticComparisonWithOperand
  | InclusionComparisonWithOperand;

// main type comparison widget should provide
export type ComparisonWithOperand =
  | ComparisonWithValueOperand
  | RangeComparisonWithOperand
  | ComparisonWithVariableOperand
  | NullEqualityComparison;

export const isNullEqualityComparison = (comparison: ComparisonWithOperand): comparison is NullEqualityComparison =>
  comparison.operator === 'IS NULL' || comparison.operator === 'IS NOT NULL';

export const isRangeComparison = (comparison: ComparisonWithOperand): comparison is RangeComparisonWithOperand =>
  comparison.operator === 'RANGE';

export const isComparisonWithVariableOperand = (
  comparison: ComparisonWithOperand,
): comparison is ComparisonWithVariableOperand =>
  !isNullEqualityComparison(comparison) &&
  !isRangeComparison(comparison) &&
  comparison.dataSource === ComparisonOperandType.VARIABLE;

export const isComparisonWithValueOperand = (
  comparison: ComparisonWithOperand,
): comparison is ComparisonWithValueOperand =>
  !isNullEqualityComparison(comparison) &&
  !isRangeComparison(comparison) &&
  comparison.dataSource === ComparisonOperandType.VALUE;
