import React, { FC } from 'react';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/DecisionStrategiesTypes';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { SIZES } from 'components/StrategyOverview/StrategyUpdates/TableColumnSizes';
import styles from './UpdateLine.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';

interface UpdateLineProps {
  strategyUpdate: StrategyUpdateType;
}

const UpdateLine: FC<UpdateLineProps> = ({ strategyUpdate }) => {
  const { firstName, lastName, imageId } = strategyUpdate.updatedBy;
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.UPDATED}>
        <OverflowedText className={styles.updateLineDateText}>
          {formatDate(strategyUpdate.updatedAt, DateTimeFormat.Short)}
        </OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={SIZES.EDITOR} noPadding>
        <ImageFetcher tooltip firstName={firstName} lastName={lastName} size="small" imageId={imageId} />
        <OverflowedText className={styles.updateLineEditorText}>{firstName} {lastName}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={SIZES.TYPE} overflowed>
        {strategyUpdate.changeType}
      </TableBodyCell>
    </TableRow>
  );
};

export default UpdateLine;
