import React from 'react';
import AutoCompletion from 'components/AutoCompletion';
import OptionList from 'components/OptionList/OptionList';
import removeAt from 'utils/removeAt';
import replaceAt from 'utils/replaceAt';

interface DeclineReasonsFormProps {
  declineReasons: string[];
  selectedDeclineReasons: string[];
  className?: string;
  onChange: (declineReasons: string[]) => void;
}

const DeclineReasonsForm = ({
  declineReasons,
  selectedDeclineReasons,
  className,
  onChange,
}: DeclineReasonsFormProps) => {
  const allowedDeclineReasons = declineReasons.filter((reason) => !selectedDeclineReasons.includes(reason));
  const declineOptions = declineReasons.map((declineOption) => ({
    name: declineOption,
    value: declineOption,
  }));

  const renderDeclineReason = (declineReason: string, index: number) => {
    const title = `Decline Reason ${index + 1}`;

    return (
      <AutoCompletion
        labelTitle={title}
        onChange={(option) => onChange(replaceAt(selectedDeclineReasons, index, option.value))}
        value={declineReason}
        options={declineOptions}
        hideClearIcon
      />
    );
  };

  return (
    <OptionList
      addButtonText="Add Reason"
      deleteButtonText="Delete Reason"
      options={selectedDeclineReasons}
      addOption={() => onChange([...selectedDeclineReasons, allowedDeclineReasons[0]])}
      removeOption={(option, index) => onChange(removeAt(selectedDeclineReasons, index))}
      renderOption={renderDeclineReason}
      maxItemsCount={declineReasons.length}
      className={className}
    />
  );
};

export default DeclineReasonsForm;
