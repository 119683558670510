import { LoanOriginationSystemEmailsFiltersState } from './Types';
import {
  ChangePopupFiltersAction,
  ResetAllEmailsFilters,
  SetSearchInputValueAction,
  ToggleFiltersPopupAction,
} from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemEmailsFiltersState = {
  searchInputValue: '',
  isPopupVisible: false,
  createdAtRange: {
    from: null,
    to: null,
  },
  selectedBorrower: null,
};

type LoanOriginationSystemEmailsFilterAction =
  | SetSearchInputValueAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | ResetAllEmailsFilters;

export const loanOriginationSystemEmailsFilterReducer = (
  state: LoanOriginationSystemEmailsFiltersState,
  action: LoanOriginationSystemEmailsFilterAction,
): LoanOriginationSystemEmailsFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemEmailsFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchInputValue,
      };
    }
    case LoanOriginationSystemEmailsFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }
    case LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        isPopupVisible: false,
        createdAtRange: action.payload.createdAtRange,
        selectedBorrower: action.payload.borrower,
      };
    }
    case LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
