import React, { useEffect } from 'react';
import styles from './ProcessAutomationDashboard.module.scss';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import { AutomatedProcess, AutomatedProcessGroup } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import { useDispatch, useSelector } from 'react-redux';
import { getAutomatedProcesses, getEmailTemplates, selectGroup } from 'ProcessAutomation/ActionCreator';
import { ReduxState } from 'types/redux';
import { getLabels } from 'LoanOriginationSystemLabelsPage/ActionCreator';
import ProcessAutomationTab from './ProcessAutomationTab';
import { NullableItems, PaginationProps } from 'pagination';
import { LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

const Tabs: TabSwitchOption[] = [
  { label: 'Standard', id: AutomatedProcessGroup.Standard },
  { label: 'Custom', id: AutomatedProcessGroup.Custom },
];

interface ProcessAutomationDashboardProps {
  paginationProps: PaginationProps;
  automatedProcesses: NullableItems<AutomatedProcess>;
  onActivationStatusChange: (id: string, status: boolean) => void;
  onRuntimeOptionsChange: (id: string, runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>) => void;
}

const ProcessAutomationDashboard = ({
  paginationProps,
  automatedProcesses,
  onActivationStatusChange,
  onRuntimeOptionsChange,
}: ProcessAutomationDashboardProps) => {
  const dispatch = useDispatch();

  const { emailTemplates, page, itemsPerPage, group, category } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemAutomatedProcesses,
  );
  const { items: labels } = useSelector((state: ReduxState) => state.loanOriginationSystemLabelsState);

  useEffect(() => {
    if (!labels.length) {
      dispatch(getLabels({ sortingType: { field: LabelsSortingField.LabelName, ascending: true } }));
    }

    if (!emailTemplates.length) {
      dispatch(getEmailTemplates());
    }

    dispatch(getAutomatedProcesses({ page, itemsPerPage, group, category }));
  }, []);

  const handleGroupChange = (option: AutomatedProcessGroup) => {
    dispatch(selectGroup(option));
  };

  return (
    <div>
      <h2 className={styles.title}>Process Automation</h2>
      <TabSwitch
        tabs={Tabs}
        selectedTabId={group}
        onSelect={({ id: optionId }) => handleGroupChange(optionId as AutomatedProcessGroup)}
        className={styles.tabSwitch}
      />
      <ProcessAutomationTab
        automatedProcesses={automatedProcesses}
        labels={labels}
        emailTemplates={emailTemplates}
        paginationProps={paginationProps}
        selectedGroup={group}
        selectedCategory={category}
        onActivationStatusChange={onActivationStatusChange}
        onRuntimeOptionsChange={onRuntimeOptionsChange}
      />
    </div>
  );
};

export default ProcessAutomationDashboard;
