import {
  LoanOriginationSystemIntermediariesApi,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import {
  LoadIntermediarySuggestionsAction,
  loadIntermediarySuggestionsSuccess,
  loadIntermediarySuggestionsFailed,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { LoanOriginationSystemCreateApplicationActionType } from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const LoadIntermediarySuggestionsMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary[], LoadIntermediarySuggestionsAction>(
    LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestions,
    loadIntermediarySuggestionsSuccess,
    loadIntermediarySuggestionsFailed,
    (action) => {
      const { filter } = action.payload;

      return api.getIntermediarySuggestions(filter);
    },
  );

export default LoadIntermediarySuggestionsMiddleware;
