import React, { FC, useState, ComponentType } from 'react';
import styles from './NewRulePopUp.module.scss';
import SelectableItem from 'components/SelectableItem';
import Button from 'components/Button';

export interface RuleCreateOptionsType {
  Image: ComponentType;
  title: string;
  description: string;
  type: string;
}

interface NewRulePopUpProps {
  options: RuleCreateOptionsType[];
  onContinue: (type: string) => void;
}

const NewRulePopUp: FC<NewRulePopUpProps> = ({ options, onContinue }) => {
  const [activeType, setActiveType] = useState<string>('');

  const handleSetActiveType = (type: string): void => setActiveType(type);

  return (
    <div className={styles.container}>
      {options.map((option: RuleCreateOptionsType, index: number) => {
        const { Image, title, description, type } = option;
        return (
          <SelectableItem
            key={index}
            Image={Image}
            title={title}
            description={description}
            onClick={handleSetActiveType}
            selected={type === activeType}
            type={type}
          />
        );
      })}
      <div className={styles.buttonContainer}>
        <Button size="form" disabled={!activeType} onClick={() => onContinue(activeType)}>
          Continue
        </Button>
      </div>
    </div>
  );
};
export default NewRulePopUp;
