import { DataViewSortingType, TableViewData } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerForEmailsFilterType } from 'LoanOriginationSystemEmails/Types';
import formatDate from 'utils/dateFormat';
import { DownloadResponse } from 'api/APIRest';
import { DocumentExtension } from './DocumentsApi';

export interface EmailsFilter {
  search: string;
  offset: number;
  count: number;
  createdAtDateRange: {
    from: Date | null;
    to: Date | null;
  };
  borrower: string | null;
}

export type EmailsSortingType = DataViewSortingType<EmailsSortingField>;

export interface Email {
  id: string;
  sentDate: Date;
  from: string;
  to: string[];
  cc: string[];
  subject: string;
  html: string;
  text: string;
  files: EmailAttachment[];
}

export interface EmailArchiveAttachments {
  id: string;
  name: string;
  format: string;
  size: string;
}

export interface EmailAttachment {
  id: string;
  name: string;
  type: DocumentExtension;
  size: number;
}

export enum EmailsSortingField {
  CreatedAt = 'sentDate',
  SentFrom = 'from',
  SentTo = 'to',
  Subject = 'subject',
}

export interface LoanOriginationSystemEmailsApi {
  getEmails(filters?: EmailsFilter, sortingType?: EmailsSortingType): Promise<TableViewData<Email>>;
  getEmailById(id: string): Promise<Email>;
  deleteEmail(id: string): Promise<void>;
  getBorrowers(): Promise<BorrowerForEmailsFilterType[]>;
  getAttachmentPublicLink(attachmentId: string): Promise<string>;
  downloadAttachments(emailId: string): Promise<DownloadResponse>;
  downloadSingleAttachment(attachmentId: string): Promise<DownloadResponse>;
}

export default class LoanOriginationSystemEmailsRestApi extends LoanOriginationSystemApi<Email>
  implements LoanOriginationSystemEmailsApi {
  protected resourceName = 'emails';

  public getEmails(filters?: EmailsFilter, sortingType?: EmailsSortingType): Promise<TableViewData<Email>> {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    if (filters?.borrower) {
      params.set('borrower', filters.borrower);
    }

    if (filters?.createdAtDateRange.from) {
      params.append('dueDateFrom', formatDate(filters.createdAtDateRange.from));
    }

    if (filters?.createdAtDateRange.to) {
      params.append('dueDateTo', formatDate(filters.createdAtDateRange.to));
    }

    return this.getResources<TableViewData<Email>>(params);
  }

  public getEmailById(id: string): Promise<Email> {
    return this.fetch(`/${this.resourceName}/${id}`, 'GET');
  }

  public deleteEmail(id: string): Promise<void> {
    return this.deleteResource(id);
  }

  public async getBorrowers(): Promise<BorrowerForEmailsFilterType[]> {
    const { borrowers } = await this.fetch(`/${this.resourceName}/borrowers`, 'GET');

    return borrowers;
  }

  public async getAttachmentPublicLink(attachmentId: string) {
    const { token } = await this.fetch(`/email-attachments-preview/${attachmentId}`, 'GET');

    const params = new URLSearchParams();
    params.set('token', token);

    return `${this.origin}/document-preview?${params}`;
  }

  public downloadAttachments(emailId: string) {
    return this.download(`/${this.resourceName}/${emailId}/attachments`);
  }

  public downloadSingleAttachment(attachmentId: string) {
    return this.download(`/email-attachments/${attachmentId}`);
  }
}
