import { AnyAction } from 'redux';

import {
  OPEN_CREATE_NEW_STRATEGY_VERSION_POP_UP,
  CLOSE_CREATE_NEW_STRATEGY_VERSION_POP_UP,
  CREATE_NEW_STRATEGY_VERSION_REQUEST,
  CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS,
  CREATE_NEW_STRATEGY_VERSION_REQUEST_FAILURE,
} from './ActionTypes';
import { NewStrategyVersionState } from './Types';

const initialState: NewStrategyVersionState = {
  isOpen: false,
  isLoading: false,
  strategyId: '',
  strategyName: '',
};

const NewStrategyVersionReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_CREATE_NEW_STRATEGY_VERSION_POP_UP: {
      const { strategyId, strategyName } = action.payload;
      return { ...state, isOpen: true, strategyId, strategyName };
    }
    case CLOSE_CREATE_NEW_STRATEGY_VERSION_POP_UP: {
      return { isLoading: false, isOpen: false };
    }
    case CREATE_NEW_STRATEGY_VERSION_REQUEST: {
      return { ...state, isLoading: true };
    }
    case CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS: {
      return { isOpen: false, isLoading: false };
    }
    case CREATE_NEW_STRATEGY_VERSION_REQUEST_FAILURE: {
      return { isOpen: false, isLoading: false };
    }
    default: {
      return state;
    }
  }
};

export default NewStrategyVersionReducer;
