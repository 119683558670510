import React, { ChangeEvent, FC, useRef, useState } from 'react';
import styles from './OptionsInput.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import { KeyCode } from 'common/KeyCode';
import MultipleOptionsSelect, { MultipleOptionsInputProps } from 'components/MultipleOptionsSelect';

export interface OptionsInputProps
  extends Pick<MultipleOptionsInputProps, 'id' | 'disabled' | 'selectedOptions' | 'labelTitle' | 'onDeleteOption'> {
  onAddOption: (option: string) => void;
  onFormatValue?: (value: string) => string;
}

const ERROR_MESSAGE_DUPLICATE_OPTIONS = 'Duplicates are not allowed in the list of options.';

const OptionsInput: FC<OptionsInputProps> = ({ onAddOption, onFormatValue, selectedOptions, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const addButtonRef = useRef<HTMLButtonElement>(null);

  const handleSaveOption = () => {
    if (inputValue.trim()) {
      if (selectedOptions.includes(inputValue)) {
        setErrorMessage(ERROR_MESSAGE_DUPLICATE_OPTIONS);
      } else {
        onAddOption(inputValue);
      }
      setInputValue('');
    }
    inputRef!.current!.focus();
  };

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    const value = onFormatValue ? onFormatValue(target.value) : target.value;
    setInputValue(value);
  };

  const handleInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event;

    if (keyCode === KeyCode.Enter) {
      handleSaveOption();
    }

    if (keyCode === KeyCode.Esc) {
      setInputValue('');
    }
  };

  const handleInputBlur = () => {
    setInputValue('');
  };

  const isBlurRequired = (eventTarget: EventTarget | null) => {
    return addButtonRef.current !== eventTarget;
  };

  const optionsList = selectedOptions.map((option) => ({ value: option, name: option }));

  return (
    <div className={styles.inputContainer}>
      <MultipleOptionsSelect
        inputRef={inputRef}
        options={optionsList}
        matchingOptions={[]}
        handleInputBlur={handleInputBlur}
        handleInputKeyUp={handleInputKeyUp}
        onInputChange={onInputChange}
        inputValue={inputValue}
        isBlurRequired={isBlurRequired}
        selectedOptions={selectedOptions}
        errorMessage={errorMessage}
        hideSearchIcon
        {...props}
      >
        <div className={styles.addButtonContainer}>
          <ButtonWithImage ref={addButtonRef} onClick={handleSaveOption} title={`Add ${inputValue}`} kind="add" />
        </div>
      </MultipleOptionsSelect>
    </div>
  );
};

export default OptionsInput;
