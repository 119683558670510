import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  AddApplicationCoborrowerAction,
  addApplicationCoborrowerFailed,
  addApplicationCoborrowerSuccess,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';

const AddApplicationCoborrower = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, AddApplicationCoborrowerAction, ReduxState>(
    ApplicationPageActionType.AddApplicationCoborrower,
    addApplicationCoborrowerSuccess,
    addApplicationCoborrowerFailed,
    (action) => {
      return api.addCoborrower(action.payload.applicationId, action.payload.coborrowerId, action.payload.coborrower);
    },
  );

export default AddApplicationCoborrower;
