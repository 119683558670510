import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './AddModuleButton.module.scss';
import { AddImage, ArrowDownCircle } from 'static/images';
import Spinner from 'components/Spinner';
import WrapperWithTooltip from 'components/Tooltip';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface AddModuleButtonProps extends HTMLButtonProps {
  isLoading?: boolean;
  blockingActionInProgress?: boolean;
  isActive: boolean;
}

const AddModuleButton: FC<AddModuleButtonProps> = ({
  isLoading,
  onClick,
  blockingActionInProgress,
  isActive,
  ...props
}) => {
  return (
    <div
      className={clsx(
        styles.addModuleButtonContainer,
        blockingActionInProgress && styles.addModuleButtonContainer__blocked,
      )}
    >
      <WrapperWithTooltip tooltip={!isLoading ? 'Add Module' : ''} position="right">
        <button
          type="button"
          className={clsx(
            styles.addModuleButton,
            isLoading && styles.addModuleLoading,
            isActive && styles.addModuleButton__active,
          )}
          onClick={onClick}
          disabled={isLoading || blockingActionInProgress}
          {...props}
        >
          {!isLoading ? (
            <AddImage />
          ) : (
            <div className={styles.inputLoader}>
              <Spinner size={16} background="light" />
            </div>
          )}
        </button>
      </WrapperWithTooltip>
      {blockingActionInProgress && <div className={styles.addModuleIsBlocking} />}
      <ArrowDownCircle />
    </div>
  );
};

export default AddModuleButton;
