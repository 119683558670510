import { FormLayoutData } from 'api/Types';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import { getBorrowerIdNumber } from 'LoanOriginationSystemBorrowers/utils';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import BorrowerDefaultVariable, { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';

const isPersonNameChanged = (
  application: Application,
  formData: FormLayoutData,
  borrowerDefaultVariable: BorrowerDefaultVariableType,
) => {
  return (
    application.variables[borrowerDefaultVariable.FirstName] !== formData[BorrowerDefaultVariable.FirstName] ||
    application.variables[borrowerDefaultVariable.LastName] !== formData[BorrowerDefaultVariable.LastName]
  );
};

const isCompanyNameChanged = (
  application: Application,
  formData: FormLayoutData,
  borrowerDefaultVariable: BorrowerDefaultVariableType,
) => {
  return application.variables[borrowerDefaultVariable.CompanyName] !== formData[BorrowerDefaultVariable.CompanyName];
};

const isBorrowerChanged = (
  application: Application,
  formData: FormLayoutData,
  borrowerType: BorrowerType,
  borrowerDefaultVariable: BorrowerDefaultVariableType,
) => {
  return (
    application.variables[borrowerDefaultVariable.PhoneNumber] !== formData[BorrowerDefaultVariable.PhoneNumber] ||
    application.variables[borrowerDefaultVariable.Street] !== formData[BorrowerDefaultVariable.Street] ||
    application.variables[borrowerDefaultVariable.City] !== formData[BorrowerDefaultVariable.City] ||
    application.variables[borrowerDefaultVariable.State] !== formData[BorrowerDefaultVariable.State] ||
    application.variables[borrowerDefaultVariable.Zip] !== formData[BorrowerDefaultVariable.Zip] ||
    application.variables[borrowerDefaultVariable.Country] !== formData[BorrowerDefaultVariable.Country] ||
    application.variables[borrowerDefaultVariable.Email] !== formData[BorrowerDefaultVariable.Email] ||
    getBorrowerIdNumber(borrowerType, application.variables, borrowerDefaultVariable) !==
      getBorrowerIdNumber(borrowerType, formData) ||
    (borrowerType === BorrowerType.Person && isPersonNameChanged(application, formData, borrowerDefaultVariable)) ||
    (borrowerType === BorrowerType.Person &&
      application.variables[borrowerDefaultVariable.DateOfBirth] !== formData[BorrowerDefaultVariable.DateOfBirth]) ||
    (borrowerType === BorrowerType.Company && isCompanyNameChanged(application, formData, borrowerDefaultVariable))
  );
};

const isIntermediaryChanged = (application: Application, formData: FormLayoutData) => {
  const formDataCommissionRateValue = formData[IntermediaryDefaultVariable.CommissionRate]
    ? Number(formData[IntermediaryDefaultVariable.CommissionRate])
    : formData[IntermediaryDefaultVariable.CommissionRate];

  return (
    application.variables[IntermediaryDefaultVariable.CommissionRate] !== formDataCommissionRateValue ||
    application.variables[IntermediaryDefaultVariable.Name] !== formData[IntermediaryDefaultVariable.Name] ||
    application.variables[IntermediaryDefaultVariable.IdNumber] !== formData[IntermediaryDefaultVariable.IdNumber] ||
    application.variables[IntermediaryDefaultVariable.Email] !== formData[IntermediaryDefaultVariable.Email] ||
    application.variables[IntermediaryDefaultVariable.Type] !== formData[IntermediaryDefaultVariable.Type] ||
    application.variables[IntermediaryDefaultVariable.PhoneNumber] !== formData[IntermediaryDefaultVariable.PhoneNumber]
  );
};

const isFormDataWasChanged = (
  applicationData: Application | null,
  borrowerFormData: FormLayoutData,
  coBorrowerFormData: FormLayoutData,
  intermediaryFormData: FormLayoutData,
  applicationDetailsData: FormLayoutData,
  step: EditApplicationStep,
) => {
  if (!applicationData) {
    return false;
  }

  if (step === EditApplicationStep.Borrower) {
    return isBorrowerChanged(
      applicationData,
      borrowerFormData,
      applicationData.borrowerType,
      ApplicationDefaultVariable.Borrower,
    );
  }

  if (step === EditApplicationStep.CoBorrower) {
    return (
      applicationData.coborrowerId &&
      isBorrowerChanged(
        applicationData,
        coBorrowerFormData,
        applicationData.coborrowerType!,
        ApplicationDefaultVariable.Coborrower,
      )
    );
  }

  if (step === EditApplicationStep.Intermediary) {
    return applicationData.intermediaryId && isIntermediaryChanged(applicationData, intermediaryFormData);
  }

  return isConfigurableFormDataChanged(applicationData.variables, applicationDetailsData);
};

export default isFormDataWasChanged;
