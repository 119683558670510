import convertColor from 'color-convert';
import { random } from 'lodash';

const SATURATION_RANGE = {
  min: 40,
  max: 55,
};

const LIGHTNESS_RANGE = {
  min: 37,
  max: 42,
};

const generateRandomColor = (): string => {
  const hue = random(360, true);
  const saturation = random(SATURATION_RANGE.min, SATURATION_RANGE.max, true);
  const lightness = random(LIGHTNESS_RANGE.min, LIGHTNESS_RANGE.max, true);

  return convertColor.hsl.hex([hue, saturation, lightness]);
};

export { generateRandomColor };
