import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { GetBorrowerToDeleteApplicationsSuccessAction } from './ActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { selectBorrowerViewTab } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';

const isGetBorrowerToDeleteApplicationsSuccessAction = (
  action: AnyAction,
): action is GetBorrowerToDeleteApplicationsSuccessAction =>
  action.type === LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplicationsSuccess;

const BORROWER_HAS_APPLICATIONS_NOTIFICATION_TIMEOUT = 7000;

export const notifyOnBorrowerToDeleteHasApplications = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isGetBorrowerToDeleteApplicationsSuccessAction(action)) {
    if (action.payload.total > 0) {
      const { borrowerType, variables, borrowerId } = action.payload.items[0];
      const message = getMessage(MessageType.BorrowerToDeleteHasApplications, {
        borrowerName: getBorrowerFullName(borrowerType, variables),
      });

      notification.createNotification(
        {
          componentType: NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent,
          props: {
            message,
            url: `/los/borrowers/?edit=${borrowerId}`,
            selectApplicationsTabActionCreator: () => selectBorrowerViewTab(BorrowerDetailsTab.Applications),
          },
        },
        'error',
        dispatch,
        BORROWER_HAS_APPLICATIONS_NOTIFICATION_TIMEOUT,
      );
    }
  }

  return next(action);
};
