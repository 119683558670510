import React from 'react';
import styles from './Application.module.scss';
import { SkeletonRectangle} from 'components/Skeleton';
import { SkeletonInfoPanel } from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel';
import { SkeletonSubtitle } from 'components/LoanOriginationSystem/ApplicationDetails/Subtitle';
import { SkeletonTabsList } from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/TabsList';
import SkeletonDataTab from 'components/LoanOriginationSystem/ApplicationTabs/DataTab/SkeletonDataTab';

const ApplicationSkeleton = () => {
  return (
    <>
      <div className={styles.skeletonHeader}>
        <div className={styles.skeletonTitle}>
          <div>
            <SkeletonRectangle width="500px" height="25px" color="primary20" />
            <div>
              <SkeletonRectangle width="164px" height="44px" color="primary6" />
              <SkeletonRectangle width="44px" height="44px" color="primary6" />
            </div>
          </div>
          <SkeletonSubtitle />
        </div>
        <SkeletonInfoPanel />
      </div>
      <SkeletonTabsList />
      <div className={styles.tabsContainer}>
        <SkeletonDataTab />
      </div>
      
    </>
  );
};

export default ApplicationSkeleton;

