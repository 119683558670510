import LoanOriginationSystemApi from './LoanOriginationSystemApi';

export interface DocuSignEnvelopeApi {
  createEnvelope: (applicationId: string, templateId: string) => Promise<void>;
}

export default class DocuSignEnvelopeRestApi extends LoanOriginationSystemApi implements DocuSignEnvelopeApi {
  protected resourceName = 'docu-sign-envelope';

  public createEnvelope(applicationId: string, templateId: string) {
    return this.fetch<void>(`/${this.resourceName}/`, 'POST', {
      applicationId,
      templateId,
    });
  }
}
