import React, { FC } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { SIZES } from 'components/StrategyRunHistoryTable/columnSizes';
import { CaseResult } from 'components/ProcessingBatchCase/ProcessingBatchCase';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import { IndividualProcessingHistoryCase } from 'api/Types';
import styles from 'components/StrategyRunHistoryTable/HistoryLine/HistoryLine.module.scss';
import SearchHighlight from 'components/SearchHighlight';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import RowActions from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import { StrategyRunTableColumnDisplayName } from 'components/StrategyRunHistoryTable/types';

export type StatusType = 'Active' | 'Inactive' | 'Testing';

interface HistoryLineProps {
  individualCase: IndividualProcessingHistoryCase;
  searchInputValue: string;
  getRowActions: (individualCase: IndividualProcessingHistoryCase) => RowAction[];
}

const statusStyles = new Map([
  [CaseResult.Passed, styles.statusPassed],
  [CaseResult.Failed, styles.statusFailed],
  [CaseResult.Error, styles.statusError],
]);

const getStateColor = (status: CaseResult) => {
  return statusStyles.get(status);
};

const HistoryLine: FC<HistoryLineProps> = ({ individualCase, searchInputValue, getRowActions }) => {
  const history = useHistory();

  const caseResultType = (): CaseResult => {
    if (individualCase.passed) {
      return CaseResult.Passed;
    }
    return individualCase.errors.length ? CaseResult.Error : CaseResult.Failed;
  };

  const caseResult = caseResultType();

  const handleCaseClick = (caseId: string) => {
    history.push(`/decision/processing/individual/results/${caseId}`);
  };

  return (
    <TableRow key={individualCase.id} useActions onClick={() => handleCaseClick(individualCase.id)}>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Date]} noPadding overflowed>
        <DateTime time={individualCase.date} format={DateTimeFormat.Long} />
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Type]} noPadding overflowed>
        {individualCase.type}
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.DecisionName]} noPadding overflowed>
        <SearchHighlight search={searchInputValue}>{individualCase.name}</SearchHighlight>
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.StrategyName]} noPadding overflowed>
        {individualCase.strategy_name}
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Result]} noPadding>
        <div className={clsx(styles.caseStatus, getStateColor(caseResult))}>{caseResult}</div>
      </TableBodyCell>
      <RowActions actions={getRowActions(individualCase)} id={individualCase.id} />
    </TableRow>
  );
};

export default HistoryLine;
