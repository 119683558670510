import { Moment } from 'moment';
import React, { FC } from 'react';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';

export interface DateTimeProps {
  time: string | Moment | Date;
  format?: DateTimeFormat;
}

const DateTime: FC<DateTimeProps> = ({ time, format = DateTimeFormat.Short }) => {
  return <span>{formatDate(time, format)}</span>;
};

export default DateTime;
