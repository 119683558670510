import { AnyAction } from 'redux';
import docuSignTemplatesPagination, { DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT } from 'components/DocuSign/pagination';
import { ESignTemplatesSortingField } from 'api/Core/ESignTemplatesApi';
import mountChildReducers from 'utils/childReducers';
import withStateReset from 'utils/reducers/withStateReset';
import {
  CreateDocuSignIntegrationAction,
  CreateDocuSignIntegrationSuccessAction,
  ESignIntegrationActionOrigin,
  UpdateDocuSignIntegrationAction,
  UpdateDocuSignIntegrationSuccessAction,
} from 'ESignIntegrations/ActionCreator';
import {
  CreateVariableActionOrigin,
  CreateVariableRequestAction,
  CreateVariableSuccessAction,
} from 'Variables/VariablesActionCreator';
import {
  ACTION_TYPE_CREATE_VARIABLE_FAILURE,
  ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
} from 'Variables/ActionTypes';
import {
  DeleteESignTemplateAction,
  DeleteESignTemplateSuccessAction,
  ESignTemplatesActionOrigin,
  GetESignTemplatesSuccessAction,
  UpdateESignTemplateAction,
  UpdateESignTemplateSuccessAction,
} from 'ESignTemplates/ActionCreator';
import { ESignTemplatesActionType } from 'ESignTemplates/ActionTypes';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import { ESignIntegrationsActionType } from 'ESignIntegrations/ActionTypes';
import { DocuSignState } from './Types';
import {
  ChangeSortingTypeAction,
  GetTemplatesToImportSuccessAction,
  ImportDocuSignTemplatesAction,
  ImportDocuSignTemplatesSuccessAction,
  SetDeleteTemplateIdAction,
  ToggleCreateVariablePopupActiveAction,
  ToggleImportTemplatesPopupActiveAction,
  ToggleRecipientsMappingPopupActiveAction,
  ToggleVariablesMappingPopupActiveAction,
} from './ActionCreator';
import { DocuSignActionType } from './ActionTypes';
import docuSignFiltersReducer, { initialState as docuSignFiltersInitialState } from './Filters/Reducer';
import { DocuSignFiltersActionType } from './Filters/ActionTypes';
import { ChangeFiltersAction, FilterTemplatesByMembersAction, ResetAllFiltersAction } from './Filters/ActionCreator';

type DocuSignType = (state: DocuSignState, action: AnyAction) => DocuSignState;

const initialState: DocuSignState = {
  items: [],
  page: 1,
  itemsPerPage: DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    ascending: false,
    field: ESignTemplatesSortingField.UpdatedAt,
  },
  searched: {},
  templateIdToDelete: null,
  templatesToImport: null,
  isImportTemplatesPopUpOpen: false,
  isVariableMappingPopUpOpen: false,
  isRecipientsMappingPopupOpen: false,
  isCreateVariablePopupOpen: false,
  isVariableCreationInProgress: false,
  isRecipientsMappingSavingInProgress: false,
  isIntegrationSavingInProgress: false,
  isTemplateDeleteInProgress: false,
  isTemplatesImportInProgress: false,
  isVariablesMappingSavingInProgress: false,
  filters: docuSignFiltersInitialState,
};

export type DocuSignActions =
  | CreateVariableRequestAction
  | CreateVariableSuccessAction
  | GetESignTemplatesSuccessAction
  | CreateDocuSignIntegrationAction
  | CreateDocuSignIntegrationSuccessAction
  | UpdateDocuSignIntegrationSuccessAction
  | UpdateDocuSignIntegrationAction
  | GetTemplatesToImportSuccessAction
  | SetDeleteTemplateIdAction
  | ChangeSortingTypeAction
  | ToggleImportTemplatesPopupActiveAction
  | ToggleVariablesMappingPopupActiveAction
  | ToggleRecipientsMappingPopupActiveAction
  | ToggleCreateVariablePopupActiveAction
  | ImportDocuSignTemplatesAction
  | ImportDocuSignTemplatesSuccessAction
  | ChangeFiltersAction
  | ResetAllFiltersAction
  | FilterTemplatesByMembersAction
  | DeleteESignTemplateAction
  | UpdateESignTemplateAction
  | DeleteESignTemplateSuccessAction
  | UpdateESignTemplateSuccessAction
  | FailedAction<typeof ACTION_TYPE_CREATE_VARIABLE_FAILURE, CreateVariableActionOrigin.DocuSignVariablesMapping>
  | FailedAction<ESignIntegrationsActionType.CreateDocuSignIntegrationFailure, ESignIntegrationActionOrigin.DocuSign>
  | FailedAction<ESignIntegrationsActionType.UpdateDocuSignIntegrationFailure, ESignIntegrationActionOrigin.DocuSign>
  | FailedAction<DocuSignActionType.ImportDocuSignTemplatesFailure>
  | FailedAction<ESignTemplatesActionType.UpdateESignTemplateFailure, ESignTemplatesActionOrigin.DocuSignConfiguration>
  | FailedAction<ESignTemplatesActionType.DeleteESignTemplateFailure, ESignTemplatesActionOrigin.DocuSignConfiguration>;

const docuSignReducer = (state: DocuSignState = initialState, action: DocuSignActions): DocuSignState => {
  switch (action.type) {
    case ESignTemplatesActionType.GetESignTemplatesSuccess: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      if (action.payload.searchInputValue) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [action.payload.searchInputValue]: {
              items: action.payload.items.map((item) => item.id),
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items.map((item) => item.id),
        itemsTotal: action.payload.total,
      };
    }
    case DocuSignActionType.GetTemplatesToImportSuccess: {
      return {
        ...state,
        templatesToImport: action.payload.templatesToImport,
      };
    }
    case ESignIntegrationsActionType.CreateDocuSignIntegration:
    case ESignIntegrationsActionType.UpdateDocuSignIntegration: {
      if (action.meta?.actionOrigin !== ESignIntegrationActionOrigin.DocuSign) {
        return state;
      }

      return {
        ...state,
        isIntegrationSavingInProgress: true,
      };
    }
    case ESignIntegrationsActionType.UpdateDocuSignIntegrationSuccess:
    case ESignIntegrationsActionType.CreateDocuSignIntegrationSuccess:
    case ESignIntegrationsActionType.CreateDocuSignIntegrationFailure:
    case ESignIntegrationsActionType.UpdateDocuSignIntegrationFailure: {
      if (action.meta?.actionOrigin !== ESignIntegrationActionOrigin.DocuSign) {
        return state;
      }

      return {
        ...state,
        isIntegrationSavingInProgress: false,
      };
    }
    case DocuSignActionType.ToggleImportTemplatesPopupActive: {
      return {
        ...state,
        isImportTemplatesPopUpOpen: !state.isImportTemplatesPopUpOpen,
        templatesToImport: state.isImportTemplatesPopUpOpen ? null : state.templatesToImport,
      };
    }
    case DocuSignActionType.ToggleVariablesMappingPopupActive: {
      return {
        ...state,
        isVariableMappingPopUpOpen: !state.isVariableMappingPopUpOpen,
      };
    }
    case DocuSignActionType.ToggleRecipientsMappingPopupActive: {
      return {
        ...state,
        isRecipientsMappingPopupOpen: !state.isRecipientsMappingPopupOpen,
      };
    }
    case DocuSignActionType.SetDeleteTemplateId: {
      return {
        ...state,
        templateIdToDelete: action.payload.id,
      };
    }
    case DocuSignActionType.ImportDocuSignTemplates: {
      return {
        ...state,
        isTemplatesImportInProgress: true,
      };
    }
    case DocuSignActionType.ImportDocuSignTemplatesSuccess: {
      return {
        ...state,
        templatesToImport: null,
        isImportTemplatesPopUpOpen: false,
        isTemplatesImportInProgress: false,
      };
    }
    case DocuSignActionType.ImportDocuSignTemplatesFailure: {
      return {
        ...state,
        isTemplatesImportInProgress: false,
      };
    }
    case DocuSignActionType.ChangeSortingType: {
      return {
        ...state,
        sortingType: action.payload.sortingType,
      };
    }
    case DocuSignFiltersActionType.ResetAllFilters:
    case DocuSignFiltersActionType.FilterTemplatesByMembers:
    case DocuSignFiltersActionType.ChangeFilters: {
      return {
        ...state,
        filters: docuSignFiltersReducer(state.filters, action),
        itemsTotal: undefined,
      };
    }
    case ESignTemplatesActionType.UpdateESignTemplate: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isVariablesMappingSavingInProgress:
          !!action.payload.template.variablesMapping || state.isVariablesMappingSavingInProgress,
        isRecipientsMappingSavingInProgress:
          !!action.payload.template.recipientsMapping || state.isRecipientsMappingSavingInProgress,
      };
    }
    case ESignTemplatesActionType.UpdateESignTemplateSuccess: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isVariablesMappingSavingInProgress: false,
        isVariableMappingPopUpOpen: false,
        isRecipientsMappingSavingInProgress: false,
        isRecipientsMappingPopupOpen: false,
      };
    }
    case ESignTemplatesActionType.UpdateESignTemplateFailure: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isVariablesMappingSavingInProgress: false,
        isRecipientsMappingSavingInProgress: false,
      };
    }
    case ESignTemplatesActionType.DeleteESignTemplate: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isTemplateDeleteInProgress: true,
      };
    }
    case ESignTemplatesActionType.DeleteESignTemplateSuccess: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isTemplateDeleteInProgress: false,
        templateIdToDelete: null,
      };
    }
    case ESignTemplatesActionType.DeleteESignTemplateFailure: {
      if (action.meta?.actionOrigin !== ESignTemplatesActionOrigin.DocuSignConfiguration) {
        return state;
      }

      return {
        ...state,
        isTemplateDeleteInProgress: false,
      };
    }
    case DocuSignActionType.ToggleCreateVariablePopupActive: {
      return {
        ...state,
        isCreateVariablePopupOpen: !state.isCreateVariablePopupOpen,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_REQUEST: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.DocuSignVariablesMapping) {
        return state;
      }

      return {
        ...state,
        isVariableCreationInProgress: true,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_SUCCESS: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.DocuSignVariablesMapping) {
        return state;
      }

      return {
        ...state,
        isCreateVariablePopupOpen: false,
        isVariableCreationInProgress: false,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_FAILURE: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.DocuSignVariablesMapping) {
        return state;
      }

      return {
        ...state,
        isVariableCreationInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};

const childReducers = {
  filters: docuSignFiltersReducer,
};

export default docuSignTemplatesPagination.wrapReducer<DocuSignState>(
  withStateReset(
    mountChildReducers(docuSignReducer, childReducers) as DocuSignType,
    DocuSignActionType.ResetTemplatesState,
    (state) => ({
      ...state,
      items: [],
      page: 1,
      itemsPerPage: DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
      itemsTotal: undefined,
      error: null,
      sortingType: {
        ascending: false,
        field: ESignTemplatesSortingField.UpdatedAt,
      },
      searched: {},
      filters: docuSignFiltersInitialState,
    }),
  ),
);
