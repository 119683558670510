import Fetcher from 'middlewares/Fetcher';
import { BorrowerVariableConfigurationsApi } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import { BorrowerVariableConfiguration } from 'api/Types';
import {
  getBorrowerVariableConfigurationsSuccess,
  getBorrowerVariableConfigurationsFailure,
  GetBorrowerVariableConfigurationsAction,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const GetBorrowerVariableConfigurationsMiddleware = (api: BorrowerVariableConfigurationsApi) =>
  Fetcher<BorrowerVariableConfiguration[], GetBorrowerVariableConfigurationsAction>(
    VariablesConfigurationActionType.GetBorrowerVariableConfigurations,
    getBorrowerVariableConfigurationsSuccess,
    getBorrowerVariableConfigurationsFailure,
    async (action) => {
      return api.getAll(action.payload.borrowerType, action.payload.productId);
    },
  );

export default GetBorrowerVariableConfigurationsMiddleware;
