import { AnyAction } from 'redux';

import OrganizationActionType from './ActionTypes';
import { OrganizationState } from './CompanyInformationTypes';

const initialState: OrganizationState = {
  id: '',
  name: '',
  streetAddress: '',
  city: '',
  state: '',
  postalCode: '',
  organizationLogo: null,
  regionSettings: {
    currencyFormat: '',
    dateFormat: '',
    phoneNumberFormat: '',
    idNumberType: '',
  },
  primaryContact: '',
  country: '',
  apiCredentials: {
    clientId: '',
    clientSecret1: '',
    clientSecret2: '',
    publicKey: '',
  },
  isLoading: false,
  purchasedSeats: 0,
  occupiedSeats: 0,
  isSeatsLoading: false,
  isSeatsAreFullPopUpOpen: false,
};

const CompanyInformationReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OrganizationActionType.GetOrganizationInfoSuccess: {
      const {
        id,
        street,
        city,
        state: stateName,
        postalCode,
        country,
        name,
        products,
        primaryContact,
        imageId,
        regionSettings,
        apiCredentials,
      } = action.payload;

      return {
        ...state,
        error: '',
        isLoading: false,
        id,
        streetAddress: street,
        city,
        state: stateName,
        postalCode,
        country,
        primaryContact,
        organizationLogo: imageId,
        name,
        regionSettings,
        products,
        apiCredentials,
      };
    }
    case OrganizationActionType.GetOrganizationInfoFailure: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case OrganizationActionType.GetOrganizationInfo: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }
    case OrganizationActionType.ChangeOrganizationInfoSuccess: {
      const { address } = action.payload.org;
      return {
        ...state,
        error: '',
        streetAddress: address.street,
        city: address.city,
        state: address.state,
        postalCode: address.postal_code,
        isLoading: false,
      };
    }
    case OrganizationActionType.ChangeOrganizationInfoFailure: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case OrganizationActionType.ChangeOrganizationInfoRequest: {
      const { streetAddress, city, state: companyState, postal } = action.payload;

      return {
        ...state,
        streetAddress,
        city,
        state: companyState,
        postalCode: postal,
        error: '',
        isLoading: true,
      };
    }
    case OrganizationActionType.GetSeats: {
      return {
        ...state,
        isSeatsLoading: true,
      };
    }
    case OrganizationActionType.GetSeatsSuccess: {
      const { purchasedSeats, occupiedSeats } = action.payload;

      return {
        ...state,
        purchasedSeats,
        occupiedSeats,
        isSeatsLoading: false,
      };
    }
    case OrganizationActionType.OpenSeatsAreFullPopUp: {
      return {
        ...state,
        isSeatsAreFullPopUpOpen: true,
      };
    }
    case OrganizationActionType.CloseSeatsAreFullPopUp: {
      return {
        ...state,
        isSeatsAreFullPopUpOpen: false,
      };
    }
    default:
      return state;
  }
};

export default CompanyInformationReducer;
