import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import {
  BorrowerApiRequestTemplateType,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import MainLayout, { PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageContent from 'MainLayout/PageContent';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import { downloadApiRequestTemplate } from 'LoanOriginationSystemBorrowerConfiguration/ActionCreator';
import BorrowerVariablesConfiguration from 'components/LoanOriginationSystem/BorrowerVariablesConfiguration';
import BorrowerApiRequestTemplatePopup from 'components/LoanOriginationSystem/BorrowerApiRequestTemplatePopup';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import styles from './BorrowersConfiguration.module.scss';

const BorrowersConfiguration = () => {
  const dispatchRoutine = useDispatchRoutine();
  const [borrowerType, setBorrowerType] = useState(BorrowerType.Person);
  const [isDownloadApiRequestTemplatePopupOpen, setDownloadApiRequestTemplatePopupOpen] = useState(false);
  const { isDownloadApiRequestTemplateInProgress } = useSelector((state: ReduxState) => state.borrowerConfiguration);
  const leftNav = makeLeftNavigation(
    NavigationLinkId.BorrowersConfiguration,
    ApplicationSectionName.LoanOriginationSystem,
  );

  const handleBorrowerTypeChange = (newBorrowerType: BorrowerType) => setBorrowerType(newBorrowerType);

  const handleDownloadApiTemplateClick = () => {
    setDownloadApiRequestTemplatePopupOpen(true);
  };

  const handleDownloadApiTemplatePopupClose = () => {
    setDownloadApiRequestTemplatePopupOpen(false);
  };

  const handleDownloadApiTemplate = async (requestType: BorrowerApiRequestTemplateType) => {
    await dispatchRoutine(downloadApiRequestTemplate(borrowerType, requestType));

    setDownloadApiRequestTemplatePopupOpen(false);
  };

  const renderOverlay = () => {
    if (isDownloadApiRequestTemplatePopupOpen) {
      return (
        <BorrowerApiRequestTemplatePopup
          onDownload={handleDownloadApiTemplate}
          onClose={handleDownloadApiTemplatePopupClose}
          isDownloadInProgress={isDownloadApiRequestTemplateInProgress}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout leftNav={leftNav} overlay={renderOverlay()}>
      <PageWrapperWithFooter footerClassName={styles.footer}>
        <PageContent className={styles.pageContent}>
          <BorrowerVariablesConfiguration
            borrowerType={borrowerType}
            onBorrowerTypeChange={handleBorrowerTypeChange}
            onDownloadApiTemplate={handleDownloadApiTemplateClick}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default BorrowersConfiguration;
