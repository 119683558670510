import { BillingApi, BillingInfo } from 'api/BillingApi';
import Fetcher from 'middlewares/Fetcher';
import { GetBillingInfoAction, getBillingInfoSuccess, gitBillingInfoFailed } from './ActionCreator';
import BillingPageActionType from './ActionTypes';

const GetBillingInfoMiddleware = (api: BillingApi) =>
  Fetcher<BillingInfo, GetBillingInfoAction>(
    BillingPageActionType.GetBillingInfo,
    getBillingInfoSuccess,
    gitBillingInfoFailed,
    () => {
      return api.getBillingInfo();
    },
  );

export default GetBillingInfoMiddleware;
