import React, { FC, useEffect, useState } from 'react';
import FilePreview from 'components/FilePreview';
import { useParams } from 'react-router';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useEmailsApi, useApplicationDocumentsApi } from 'providers/ApiServiceProvider';
import { DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';

export enum FileType {
  EmailAttachment = 'emailAttachment',
  ApplicationDocument = 'applicationDocument',
}

const FilePreviewPage: FC<{}> = () => {
  const { fileType, fileExtension, fileId, filename } = useParams<{
    fileType: string;
    fileExtension: DocumentExtension;
    fileId: string;
    filename: string;
  }>();
  const emailsDownloadApi = useEmailsApi();
  const documentsDownloadApi = useApplicationDocumentsApi();
  const [previewLink, setPreviewLink] = useState<string>();

  useEffect(() => {
    if (!fileType || !fileId) {
      return;
    }
    (async () => {
      if (fileType === FileType.EmailAttachment) {
        const link = await emailsDownloadApi.getAttachmentPublicLink(fileId);
        setPreviewLink(link);
      }
      if (fileType === FileType.ApplicationDocument) {
        const link = await documentsDownloadApi.getPublicLink(fileId);
        setPreviewLink(link);
      }
    })();
  }, []);

  const leftNav = makeLeftNavigation(NavigationLinkId.Applications, ApplicationSectionName.LoanOriginationSystem);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent noPadding>
          <FilePreview previewLink={previewLink} filename={filename} fileExtension={fileExtension} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default FilePreviewPage;
