import { Email } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import { getMailtoURI } from 'Email/utils';
import { wrapEmailWithFooter } from './wrapEmailWithFooter';

export const forwardEmail = (email: Email, organizationEmail: string) => {
  const { from, sentDate, text, subject, to, cc } = email;
  const subjectValue = subject.includes('FW: ') ? subject : `FW: ${subject}`;

  window.location.href = getMailtoURI({
    subject: subjectValue,
    cc: [...cc, organizationEmail],
    body: wrapEmailWithFooter({
      from,
      sent: formatDate(sentDate, DateTimeFormat.LongWithDayOfTheWeek),
      to,
      cc,
      subject,
      body: text,
    }),
  });
};
