import Fetcher from 'middlewares/Fetcher';
import {
  getOrganizationInfoByTokenSuccess,
  getOrganizationInfoByTokenFailure,
  GetOrganizationInfoAction,
} from './ActionCreator';
import { UploadSecureDocumentActionType } from './ActionTypes';
import {
  CustomerDocumentUploadApiType,
  ShortOrganizationInfo,
} from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';

const GetOrganizationInfoMiddleware = (api: CustomerDocumentUploadApiType) =>
  Fetcher<ShortOrganizationInfo, GetOrganizationInfoAction>(
    UploadSecureDocumentActionType.GetOrganizationInfo,
    getOrganizationInfoByTokenSuccess,
    getOrganizationInfoByTokenFailure,
    ({ payload }) => {
      return api.getOrganizationInfo(payload.token);
    },
  );

export default GetOrganizationInfoMiddleware;
