import React, { FC, useState } from 'react';
import ComparisonOperand from 'components/ComparisonOperand';
import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { getVisualDataType } from 'Variables/utils';
import { DataType, VisualDataType } from 'Variables/VariablesTypes';
import CreateNewVariableLink from 'components/CreateNewVariableLink';

interface InputWithVariablesProps {
  name: string;
  example: string;
  variable: string;
  initialVariable: string;
  type: DataType;
  inputType: ComparisonOperandType;
  initialInputType: ComparisonOperandType;
  description: string;
  updateInput: (number: number, inputType: ComparisonOperandType, value: string) => void;
  id: number;
  openVariableCreation: () => void;
}

const InputWithVariables: FC<InputWithVariablesProps> = ({
  name,
  variable,
  initialVariable,
  example,
  type,
  inputType,
  initialInputType,
  description,
  updateInput,
  id,
  openVariableCreation,
}) => {
  const [variableInputType, setVariableInputType] = useState<ComparisonOperandType>(inputType);

  const updateInputValue = (value: string) => {
    updateInput(id, variableInputType, value);
  };

  const updateInputVariableId = (value: string) => {
    updateInput(id, variableInputType, value);
  };

  const updateInputType = (typeValue: ComparisonOperandType) => {
    setVariableInputType(typeValue);
    updateInput(id, typeValue, typeValue === initialInputType ? initialVariable : '');
  };

  const visualDataType = getVisualDataType({
    dataType: type,
    numberFormat: null,
    stringFormat: null,
    optionsList: null,
  }) as Exclude<VisualDataType, 'PhoneNumber' | 'IdentificationNumber' | 'Monetary'>;

  return (
    <div>
      <ComparisonOperand
        value={inputType === ComparisonOperandType.VALUE ? variable : ''}
        operandType={variableInputType}
        variableId={inputType === ComparisonOperandType.VARIABLE ? variable : ''}
        dataType={visualDataType}
        onVariableIdChange={updateInputVariableId}
        onValueChange={updateInputValue}
        onTypeChange={updateInputType}
        labelTitle={name}
        description={`${description || ''}${description ? ', ' : ''}e.g. ${example}`}
        link={id === 0 && <CreateNewVariableLink onClick={openVariableCreation} />}
      />
    </div>
  );
};

export default InputWithVariables;
