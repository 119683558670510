import clsx from 'clsx';
import React, { FC } from 'react';
import styles from './TableBody.module.scss';

interface TableBodyProps {
  className?: string;
}

const TableBody: FC<TableBodyProps> = ({ children, className }) => {
  return <div className={clsx(styles.tableBody, className)}>{children}</div>;
};

export default TableBody;
