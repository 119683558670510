import { AnyAction } from 'redux';
import { BorrowerVariableConfiguration, BulkUpdateVariableConfigurationParams, VariableConfiguration } from 'api/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { VariablesConfigurationActionType } from './ActionTypes';
import { UpdateIntermediaryVariableConfigurationParams } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import { UpdateBorrowerVariableConfigurationParams } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import { Variable } from 'Variables/VariablesTypes';

export enum BulkUpdateVariableConfigurationsActionOrigin {
  EditRequiredVariablesPopup = 'EditRequiredVariablePopup',
}

export interface GetBorrowerVariableConfigurationsAction extends AnyAction {
  type: VariablesConfigurationActionType.GetBorrowerVariableConfigurations;
  payload: {
    borrowerType: BorrowerType;
    productId?: string;
  };
}

export interface GetBorrowerVariableConfigurationsSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.GetBorrowerVariableConfigurationsSuccess;
  payload: {
    configurations: BorrowerVariableConfiguration[];
  };
}

export interface UpdateBorrowerVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.UpdateBorrowerVariableConfiguration;
  payload: {
    id: string;
    borrowerType: BorrowerType;
    params: UpdateBorrowerVariableConfigurationParams;
  };
}

export interface CreateBorrowerVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateBorrowerVariableConfiguration;
  payload: {
    position: number;
    column: number;
    borrowerType: BorrowerType;
    variable: Variable;
  };
}

export interface CreateBorrowerVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateBorrowerVariableConfigurationSuccess;
  payload: {
    configuration: BorrowerVariableConfiguration;
  };
}

export interface UpdateBorrowerVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.UpdateBorrowerVariableConfigurationSuccess;
  payload: {
    configuration: BorrowerVariableConfiguration;
  };
}

export interface DeleteBorrowerVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteBorrowerVariableConfiguration;
  payload: {
    id: string;
    borrowerType: BorrowerType;
  };
}

export interface DeleteBorrowerVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteBorrowerVariableConfigurationSuccess;
}

export interface GetIntermediaryVariableConfigurationsAction extends AnyAction {
  type: VariablesConfigurationActionType.GetIntermediaryVariableConfigurations;
  payload: {
    productId?: string;
  };
}

export interface GetIntermediaryVariableConfigurationsSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.GetIntermediaryVariableConfigurationsSuccess;
  payload: {
    configurations: VariableConfiguration[];
  };
}

export interface CreateIntermediaryVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateIntermediaryVariableConfiguration;
  payload: {
    position: number;
    column: number;
    variable: Variable;
  };
}

export interface CreateIntermediaryVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.CreateIntermediaryVariableConfigurationSuccess;
  payload: {
    configuration: VariableConfiguration;
  };
}

export interface UpdateIntermediaryVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.UpdateIntermediaryVariableConfiguration;
  payload: {
    params: UpdateIntermediaryVariableConfigurationParams;
    id: string;
  };
}

export interface UpdateIntermediaryVariableConfigurationSuccessAction {
  type: VariablesConfigurationActionType.UpdateIntermediaryVariableConfigurationSuccess;
  payload: {
    configuration: VariableConfiguration;
  };
}

export interface DeleteIntermediaryVariableConfigurationAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteIntermediaryVariableConfiguration;
  payload: {
    id: string;
  };
}

export interface DeleteIntermediaryVariableConfigurationSuccessAction extends AnyAction {
  type: VariablesConfigurationActionType.DeleteIntermediaryVariableConfigurationSuccess;
}

export interface BulkUpdateBorrowerVariableConfigurationsAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurations;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export interface BulkUpdateBorrowerVariableConfigurationsSuccessAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsSuccess;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export interface BulkUpdateIntermediaryVariableConfigurationsAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurations;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export interface BulkUpdateIntermediaryVariableConfigurationsSuccessAction
  extends ActionWithOrigin<BulkUpdateVariableConfigurationsActionOrigin> {
  type: VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsSuccess;
  payload: {
    configurations: BulkUpdateVariableConfigurationParams[];
  };
}

export const getBorrowerVariableConfigurations = (
  borrowerType: BorrowerType,
  productId?: string,
): GetBorrowerVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.GetBorrowerVariableConfigurations,
  payload: {
    borrowerType,
    productId,
  },
});

export const getBorrowerVariableConfigurationsSuccess = (
  configurations: BorrowerVariableConfiguration[],
): GetBorrowerVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.GetBorrowerVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const createBorrowerVariableConfiguration = (
  borrowerType: BorrowerType,
  column: number,
  position: number,
  variable: Variable,
): CreateBorrowerVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.CreateBorrowerVariableConfiguration,
  payload: {
    borrowerType,
    column,
    position,
    variable,
  },
});

export const createBorrowerVariableConfigurationSuccess = (
  configuration: BorrowerVariableConfiguration,
): CreateBorrowerVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.CreateBorrowerVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const updateBorrowerVariableConfiguration = (
  id: string,
  borrowerType: BorrowerType,
  params: UpdateBorrowerVariableConfigurationParams,
): UpdateBorrowerVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.UpdateBorrowerVariableConfiguration,
  payload: {
    id,
    borrowerType,
    params,
  },
});

export const updateBorrowerVariableConfigurationSuccess = (
  configuration: BorrowerVariableConfiguration,
): UpdateBorrowerVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.UpdateBorrowerVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const deleteBorrowerVariableConfiguration = (
  id: string,
  borrowerType: BorrowerType,
): DeleteBorrowerVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.DeleteBorrowerVariableConfiguration,
  payload: {
    id,
    borrowerType,
  },
});

export const deleteBorrowerVariableConfigurationSuccess = (): DeleteBorrowerVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.DeleteBorrowerVariableConfigurationSuccess,
});

export const getIntermediaryVariableConfigurations = (
  productId?: string,
): GetIntermediaryVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.GetIntermediaryVariableConfigurations,
  payload: {
    productId,
  },
});

export const getIntermediaryVariableConfigurationsSuccess = (
  configurations: VariableConfiguration[],
): GetIntermediaryVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.GetIntermediaryVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const createIntermediaryVariableConfiguration = (
  column: number,
  position: number,
  variable: Variable,
): CreateIntermediaryVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.CreateIntermediaryVariableConfiguration,
  payload: {
    column,
    position,
    variable,
  },
});

export const createIntermediaryVariableConfigurationSuccess = (
  configuration: VariableConfiguration,
): CreateIntermediaryVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.CreateIntermediaryVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const updateIntermediaryVariableConfiguration = (
  id: string,
  params: UpdateIntermediaryVariableConfigurationParams,
): UpdateIntermediaryVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.UpdateIntermediaryVariableConfiguration,
  payload: {
    params,
    id,
  },
});

export const updateIntermediaryVariableConfigurationSuccess = (
  configuration: VariableConfiguration,
): UpdateIntermediaryVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.UpdateIntermediaryVariableConfigurationSuccess,
  payload: {
    configuration,
  },
});

export const deleteIntermediaryVariableConfiguration = (id: string): DeleteIntermediaryVariableConfigurationAction => ({
  type: VariablesConfigurationActionType.DeleteIntermediaryVariableConfiguration,
  payload: {
    id,
  },
});

export const deleteIntermediaryVariableConfigurationSuccess = (): DeleteIntermediaryVariableConfigurationSuccessAction => ({
  type: VariablesConfigurationActionType.DeleteIntermediaryVariableConfigurationSuccess,
});

export const bulkUpdateBorrowerVariableConfigurations = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateBorrowerVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurations,
  payload: {
    configurations,
  },
});

export const bulkUpdateBorrowerVariableConfigurationsSuccess = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateBorrowerVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const bulkUpdateIntermediaryVariableConfigurations = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateIntermediaryVariableConfigurationsAction => ({
  type: VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurations,
  payload: {
    configurations,
  },
});

export const bulkUpdateIntermediaryVariableConfigurationsSuccess = (
  configurations: BulkUpdateVariableConfigurationParams[],
): BulkUpdateIntermediaryVariableConfigurationsSuccessAction => ({
  type: VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsSuccess,
  payload: {
    configurations,
  },
});

export const getBorrowerVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.GetBorrowerVariableConfigurationsFailure,
);
export const createBorrowerVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.CreateBorrowerVariableConfigurationFailure,
);
export const updateBorrowerVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.UpdateBorrowerVariableConfigurationFailure,
);
export const deleteBorrowerVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.DeleteBorrowerVariableConfigurationFailure,
);
export const getIntermediaryVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.GetIntermediaryVariableConfigurationsFailure,
);
export const createIntermediaryVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.CreateIntermediaryVariableConfigurationFailure,
);
export const updateIntermediaryVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.UpdateIntermediaryVariableConfigurationFailure,
);
export const deleteIntermediaryVariableConfigurationFailure = FailedActionCreator(
  VariablesConfigurationActionType.DeleteIntermediaryVariableConfigurationFailure,
);
export const bulkUpdateBorrowerVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsFailure,
);
export const bulkUpdateIntermediaryVariableConfigurationsFailure = FailedActionCreator(
  VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsFailure,
);
