import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  updateApplicationVariablesFailure,
  updateApplicationVariablesSuccess,
  UpdateApplicationVariablesAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const UpdateApplicationVariablesMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationVariablesAction>(
    ApplicationPageActionType.UpdateApplicationVariables,
    updateApplicationVariablesSuccess,
    updateApplicationVariablesFailure,
    (action) => {
      return api.updateApplicationVariables(action.payload.applicationId, action.payload.variables);
    },
  );

export default UpdateApplicationVariablesMiddleware;
