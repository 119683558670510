import Fetcher from 'middlewares/Fetcher';
import { IntermediaryVariableConfigurationsApi } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import { VariableConfiguration } from 'api/Types';
import {
  CreateIntermediaryVariableConfigurationAction,
  createIntermediaryVariableConfigurationSuccess,
  createIntermediaryVariableConfigurationFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const CreateIntermediaryVariableConfigurationMiddleware = (api: IntermediaryVariableConfigurationsApi) =>
  Fetcher<VariableConfiguration, CreateIntermediaryVariableConfigurationAction>(
    VariablesConfigurationActionType.CreateIntermediaryVariableConfiguration,
    createIntermediaryVariableConfigurationSuccess,
    createIntermediaryVariableConfigurationFailure,
    (action) => {
      return api.create({
        position: action.payload.position,
        column: action.payload.column,
        variable: action.payload.variable.id,
      });
    },
  );

export default CreateIntermediaryVariableConfigurationMiddleware;
