import { useDispatch } from 'react-redux';
import useUnmountAction from './useUnmountAction';
import { simpleAction } from 'utils/actions/SimpleAction';

const useStateReset = (action: string) => {
  const dispatch = useDispatch();

  useUnmountAction(() => dispatch(simpleAction(action)));
};

export default useStateReset;
