import { AnyAction } from 'redux';
import withStateReset from 'utils/reducers/withStateReset';
import {
  ApplicationHistoryFilterType,
  ApplicationHistorySortingField,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import applicationHistoryPagination, {
  APPLICATION_HISTORY_RECORDS_PER_PAGE,
} from 'components/LoanOriginationSystem/ApplicationTabs/HistoryTab/pagination';
import {
  ChangeFilterTypeAction,
  ChangeSelectedMembersAction,
  ChangeSortingTypeAction,
  GetApplicationHistorySuccessAction,
} from './ActionCreator';
import { ApplicationHistoryState } from './Types';
import { ApplicationHistoryActionType } from './ActionTypes';

export type ApplicationDocumentsActions =
  | GetApplicationHistorySuccessAction
  | ChangeSortingTypeAction
  | ChangeSelectedMembersAction
  | ChangeFilterTypeAction;

type LoanOriginationSystemApplicationHistoryType = (
  state: ApplicationHistoryState,
  action: AnyAction,
) => ApplicationHistoryState;

export const initialState: ApplicationHistoryState = {
  items: [],
  page: 1,
  itemsPerPage: APPLICATION_HISTORY_RECORDS_PER_PAGE,
  itemsTotal: undefined,
  error: null,
  selectedMembers: [],
  sortingType: {
    field: ApplicationHistorySortingField.CreatedAt,
    ascending: false,
  },
  filterType: ApplicationHistoryFilterType.AllTypes,
};

const applicationHistoryReducer = (
  state: ApplicationHistoryState = initialState,
  action: ApplicationDocumentsActions,
): ApplicationHistoryState => {
  switch (action.type) {
    case ApplicationHistoryActionType.GetApplicationHistorySuccess: {
      return {
        ...state,
        page: 1,
        itemsPerPage: state.itemsPerPage || APPLICATION_HISTORY_RECORDS_PER_PAGE,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
      };
    }
    case ApplicationHistoryActionType.ChangeSortingType: {
      return {
        ...state,
        sortingType: action.payload.sortingType,
      };
    }
    case ApplicationHistoryActionType.ChangeSelectedMembers: {
      return {
        ...state,
        selectedMembers: action.payload.selectedMembers,
      };
    }
    case ApplicationHistoryActionType.ChangeFilterType: {
      return {
        ...state,
        filterType: action.payload.filterType,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationHistoryPagination.wrapReducer<ApplicationHistoryState>(
  withStateReset(
    applicationHistoryReducer,
    ApplicationHistoryActionType.ResetState,
    () => initialState,
  ) as LoanOriginationSystemApplicationHistoryType,
);
