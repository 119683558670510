import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { FormSkeleton } from 'components/Skeleton';
import ConfigurableForm, { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { getIntermediaryVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getIntermediaryVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import Button from 'components/Button';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import styles from './EditIntermediary.module.scss';

const SKELETON_FIELDS_COUNT = 6;

interface EditIntermediaryForm {
  intermediaryFormData: FormLayoutData;
  setIntermediaryFormData: (data: FormLayoutData) => void;
  onIntermediaryUpdate: () => void;
  isUpdatingInProgress: boolean;
  intermediary: Intermediary | null;
  setDataWasChanged?: (changed: boolean) => void;
}

const EditIntermediary = ({
  intermediaryFormData,
  setIntermediaryFormData,
  onIntermediaryUpdate,
  isUpdatingInProgress,
  intermediary,
  setDataWasChanged,
}: EditIntermediaryForm) => {
  const intermediaryVariableConfigurations = useSelector((state: ReduxState) =>
    getIntermediaryVariableConfigurationsSelector(state, {}),
  );

  const validateIntermediaryData = useConfigurableFormValidation(intermediaryVariableConfigurations!);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getIntermediaryVariableConfigurations());
  }, []);

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    setIntermediaryFormData({
      ...intermediaryFormData,
      [variable.systemName]: value,
    });
  };

  const isIntermediaryDataWasChanged = useCallback(() => {
    return !!(intermediary?.variables && isConfigurableFormDataChanged(intermediary.variables, intermediaryFormData));
  }, [intermediary, intermediaryFormData]);

  useEffect(() => {
    setDataWasChanged?.(isIntermediaryDataWasChanged());
  }, [isIntermediaryDataWasChanged]);

  const renderForm = () => {
    if (!intermediaryVariableConfigurations) {
      return <FormSkeleton fieldsCount={SKELETON_FIELDS_COUNT} />;
    }

    return (
      <ConfigurableForm
        configurations={intermediaryVariableConfigurations}
        data={intermediaryFormData}
        onFieldChange={handleFieldChange}
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
      />
    );
  };

  return (
    <div>
      {renderForm()}
      <Button
        size="form"
        kind="primary"
        className={styles.saveChangesButton}
        disabled={
          !isIntermediaryDataWasChanged() ||
          !intermediaryVariableConfigurations ||
          !validateIntermediaryData(intermediaryFormData)
        }
        onClick={onIntermediaryUpdate}
        isLoading={isUpdatingInProgress}
      >
        Save Changes
      </Button>
    </div>
  );
};

export default EditIntermediary;
