import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import { ApplicationStatusRule } from './ApplicationStatusRuleApi';

export enum DefaultApplicationStatusName {
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export type PermissionsField =
  | 'permissionsToEditApplication'
  | 'permissionsToMoveApplicationIntoStatus'
  | 'rolesAbleToViewApplicationOnBoard';

export interface ApplicationStatus {
  id: string;
  permissionsToEditApplication: UserRoleName[];
  permissionsToMoveApplicationIntoStatus: UserRoleName[];
  rolesAbleToViewApplicationOnBoard: UserRoleName[];
  rules: ApplicationStatusRule[];
  position: number;
  productId: string;
  name: string;
}

export interface LoanOriginationSystemApplicationStatusesApi {
  getAll(productId?: string): Promise<ApplicationStatus[]>;
  create(name: string, position: number, productId: string): Promise<ApplicationStatus>;
  delete(id: string): Promise<void>;
  update(id: string, status: Partial<ApplicationStatus>): Promise<ApplicationStatus>;
}

export default class LoanOriginationSystemApplicationStatusesRestApi extends LoanOriginationSystemApi<ApplicationStatus>
  implements LoanOriginationSystemApplicationStatusesApi {
  protected resourceName = 'application-statuses';

  public async getAll(productId?: string) {
    const params = new URLSearchParams();
    if (productId) {
      params.append('productId', productId);
    }
    return this.getResources(params);
  }

  public async create(name: string, position: number, productId: string) {
    return this.createResource({
      status: {
        name,
        productId,
        position,
      },
    });
  }

  public async delete(id: string) {
    return this.deleteResource(id);
  }

  public async update(id: string, status: Partial<ApplicationStatus>) {
    return this.updateResource(id, { status });
  }
}
