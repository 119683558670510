import clsx from 'clsx';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableRow from 'components/Table/TableRow';
import times from 'lodash/times';
import React from 'react';
import styles from './StaticTablePartSkeleton.module.scss';

const renderStaticTableCoreSkeleton = (type: string) => {
  const renderRowSkeleton = () => {
    return (
      <TableRow disableHover className={clsx(styles.leftPaddingZero, styles.dashedBorderBottom)}>
        <TableBodyCell width={100} disableHover>
          <SkeletonRectangle width="120px" color="primary6" height="16px" />
        </TableBodyCell>
      </TableRow>
    );
  };

  return (
    <div className={styles.content}>
      <p className={clsx(styles.amount, styles.amountStylesAdd)}>{type}</p>
      <div className={styles.rows}>
        {times(5, renderRowSkeleton)}
        <TableRow disableHover className={styles.leftPaddingZero}>
          <TableBodyCell width={100}>
            <SkeletonRectangle width="80px" color="primary20" height="16px" />
          </TableBodyCell>
        </TableRow>
      </div>
    </div>
  );
};

const StaticTablePartSkeleton = () => {
  return (
    <div className={styles.container}>
      <TableHead>
        <TableHeadCell width={100}>
          <SkeletonRectangle width="70px" color="primary6" height="16px" marginLeft="110px" />
        </TableHeadCell>
      </TableHead>
      {renderStaticTableCoreSkeleton('Amount')}
      {renderStaticTableCoreSkeleton('Number')}
    </div>
  );
};

export default StaticTablePartSkeleton;
