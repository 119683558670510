import { LoanOriginationSystemCreateTaskState } from './Types';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import { CreateTaskAction, CreateTaskSuccessAction } from './ActionCreator';
import { LoanOriginationSystemCreateTaskActionTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemCreateTaskState = {
  isCreating: false,
};

type LoanOriginationSystemCreateTaskActions =
  | CreateTaskAction
  | CreateTaskSuccessAction
  | FailedAction<LoanOriginationSystemCreateTaskActionTypes.CreateTaskFailure>;

export const loanOriginationSystemCreateTaskReducer = (
  state: LoanOriginationSystemCreateTaskState = initialState,
  action: LoanOriginationSystemCreateTaskActions,
): LoanOriginationSystemCreateTaskState => {
  switch (action.type) {
    case LoanOriginationSystemCreateTaskActionTypes.CreateTask: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case LoanOriginationSystemCreateTaskActionTypes.CreateTaskSuccess: {
      return {
        ...state,
        isCreating: false,
      };
    }
    case LoanOriginationSystemCreateTaskActionTypes.CreateTaskFailure: {
      return {
        ...state,
        isCreating: false,
      };
    }
    default: {
      return state;
    }
  }
};
