import React, { useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import BatchProcessingComponent from 'components/BatchProcessing';
import MainLayout, { PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ReduxState } from 'types/redux';
import { useParams } from 'react-router';
import PageContent from 'MainLayout/PageContent';
import { TabName } from 'components/IndividualProcessing/IndividualProcessing';
import { useHistory } from 'react-router-dom';
import { getStrategiesList, setBatchHistorySearchInput, runBatchStrategy } from 'StrategyProcessing/ActionCreator';
import Footer from 'components/Footer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import { useDownloadApi } from 'providers/ApiServiceProvider';

const leftNav = makeLeftNavigation(NavigationLinkId.BatchStrategyProcessing, ApplicationSectionName.DecisionEngine);

interface BatchProcessingProps {
  tab: TabName;
}

const BatchProcessing = ({ tab }: BatchProcessingProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const history = useHistory();
  const { strategy: strategyId } = useParams<{ strategy: string }>();

  const { strategies } = useSelector((state: ReduxState) => state.batchProcessing);

  useEffect(() => {
    batch(() => {
      dispatch(getStrategiesList());
    });
  }, []);

  const onTabChange = (tabName: TabName): void => {
    history.push(`/decision/processing/batch/${tabName}`);
  };

  const handleSearchInput = (caseSearchInputValue: string) => {
    dispatch(setBatchHistorySearchInput(caseSearchInputValue));
  };

  const handleRunProcess = (file: File, processName?: string) =>
    dispatchRoutine(runBatchStrategy(strategyId, file, processName));

  const downloadApi = useDownloadApi();

  const handleDownloadTemplate = async () => {
    downloadApi.downloadBatchTemplate(strategyId);
  };

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent>
          <BatchProcessingComponent
            currentStrategy={strategyId}
            strategies={strategies}
            onTabChange={onTabChange}
            switchBlock={tab}
            onSearchInputChange={handleSearchInput}
            onRunProcess={handleRunProcess}
            downloadTemplate={handleDownloadTemplate}
          />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default BatchProcessing;
