import React, { FC, useEffect, useState } from 'react';
import RulesListTableFooter from 'components/RulesListTableFooter';
import styles from './BranchConditions.module.scss';
import ToggleableContainer from 'components/ToggleableContainer';
import { RuleData, RuleType } from 'BranchInfo/Types';
import RuleRow from 'components/RuleRow';
import WithBordersDndList from 'components/WithBordersDndList';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import DndList from 'components/DndList';
import { EntityType } from 'RuleCreate/Types';
import { RuleEditPopUpData } from 'RuleEdit/Types';
import SkeletonConditionLine from './SkeletonConditionLine';
import clsx from 'clsx';
import LoadingMask from 'components/LoadingMask';

interface BranchConditionsProps {
  addBranchCondition: () => void;
  branchConditions: RuleData[];
  multipleBranches: boolean;
  downloadCSV?: (population: string) => void;
  handleRuleReorder: (rulesList: RuleData[], ruleType: RuleType) => void;
  openDeleteBranchPopUp?: (index: number, entityType: EntityType) => void;
  onOpenEditBranchPopUp?: (data: RuleEditPopUpData) => void;
  conditionsBlockingActionInProgress: boolean;
  isLoading: boolean;
  strategyId: string;
}

interface TableHeaderProps {
  conditionLength: number;
}

const BRANCH_CONDITIONS_HELP_TEXT = (
  <p className={styles.tooltip}>
    This branch will only run if all Branch Conditions pass. If <br />
    there are no Branch Conditions, this branch will always run.
  </p>
);

const DEFAULT_ROWS_AMOUNT = 3;

const TableHeader: FC<TableHeaderProps> = ({ conditionLength }) => (
  <div className={clsx(styles.tableHeaderRow, !conditionLength && styles.tableHeaderUnderline)}>
    <div className={styles.variableHeaderCell}>Variable</div>
    <div className={styles.comparisonHeaderCell}>comparison</div>
    <div className={styles.valueHeaderCell}>Value</div>
  </div>
);

const branchConditionSkeletonRender = () => {
  const skeletonRows = new Array(DEFAULT_ROWS_AMOUNT).fill(null);
  return skeletonRows.map((_, index) => <SkeletonConditionLine key={index} />);
};

const BranchConditions: FC<BranchConditionsProps> = ({
  strategyId,
  addBranchCondition,
  branchConditions,
  multipleBranches,
  downloadCSV,
  handleRuleReorder,
  openDeleteBranchPopUp,
  onOpenEditBranchPopUp,
  conditionsBlockingActionInProgress,
  isLoading,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const isInitiallyExpanded = multipleBranches || branchConditions.length > 0;
    setIsExpanded(isInitiallyExpanded);
    setIsDisabled(isInitiallyExpanded);
  }, [branchConditions, multipleBranches]);

  const handleExpandedStateChange = (expandedByUser: boolean) => {
    if (isDisabled) {
      return;
    }
    setIsExpanded(expandedByUser);
  };

  const tooltipText = () => {
    if (branchConditions.length > 0) {
      return (
        <div>
          <p>Cannot deactivate while you</p>
          <p>have Branch Conditions</p>
        </div>
      );
    }
    if (multipleBranches) {
      return (
        <div>
          <p>Branch Conditions are required</p>
          <p>for modules with 2+ branches</p>
        </div>
      );
    }
    return '';
  };

  const renderRule = (rule: RuleData, index: number, draggableSnapshot: DraggableStateSnapshot) => {
    const { id, ruleType, type: ruleModuleType } = rule;
    const openEditBranchPopUp = () => onOpenEditBranchPopUp?.({ id, ruleType, ruleModuleType, entityType: 'branch' });
    return (
      <RuleRow
        strategyId={strategyId}
        key={`branch_rule_${index}`}
        type="conditions"
        variableCellClass={styles.variable}
        comparisonCellClass={styles.comparison}
        valueCellClass={styles.value}
        rule={rule}
        isDragging={draggableSnapshot.isDragging}
        openDeleteRulePopUp={() => openDeleteBranchPopUp?.(index, 'branch')}
        openEditRulePopUp={openEditBranchPopUp}
      />
    );
  };

  return (
    <ToggleableContainer
      labelText="Branch Conditions"
      expanded={isExpanded}
      disabled={isDisabled}
      onToggle={handleExpandedStateChange}
      downloadCSV={downloadCSV}
      tooltipText={tooltipText()}
      helpText={BRANCH_CONDITIONS_HELP_TEXT}
    >
      <div className={styles.conditionsTable}>
        {conditionsBlockingActionInProgress && <LoadingMask background="light" />}
        <TableHeader conditionLength={branchConditions.length} />
        {!isLoading ? (
          <WithBordersDndList
            droppableId="branchConditionsList"
            items={branchConditions}
            renderListItem={renderRule}
            handleReorder={(list: RuleData[]) => handleRuleReorder(list, 'conditions')}
            renderInPortal
          >
            {(listProps) => <DndList {...listProps} />}
          </WithBordersDndList>
        ) : (
          <div className={styles.skeletonTable}>{branchConditionSkeletonRender()}</div>
        )}
        <RulesListTableFooter addButtonTitle="Add Branch Condition" addRule={addBranchCondition} />
      </div>
    </ToggleableContainer>
  );
};

export default BranchConditions;
