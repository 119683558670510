import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemUnlockBorrowerType } from './ActionTypes';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export interface UnlockBorrowerAction extends AnyAction {
  type: LoanOriginationSystemUnlockBorrowerType.UnlockBorrower;
  payload: string;
}

export interface UnlockBorrowerSuccessAction {
  type: LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess;
  payload: string;
}

export interface SetBorrowerToUnlockAction {
  type: LoanOriginationSystemUnlockBorrowerType.SetBorrowerToUnlock;
  payload: Borrower | null;
}

export const unlockBorrower = (borrowerId: string): UnlockBorrowerAction => ({
  type: LoanOriginationSystemUnlockBorrowerType.UnlockBorrower,
  payload: borrowerId,
});

export const unlockBorrowerSuccess = (borrowerId: string): UnlockBorrowerSuccessAction => ({
  type: LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess,
  payload: borrowerId,
});

export const unlockBorrowerFailure = FailedActionCreator(LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerFailure);

export const setBorrowerToUnlock = (borrower: Borrower | null): SetBorrowerToUnlockAction => ({
  type: LoanOriginationSystemUnlockBorrowerType.SetBorrowerToUnlock,
  payload: borrower,
});
