import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { getBorrowerMappings, BorrowerMappingsItem } from './BorrowerMappings';
import { AvatarIcon, DuplicateImage } from 'static/images';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import {
  getFormattedBorrowerDateOfBirth,
  getFormattedBorrowerIdNumber,
  getFormattedBorrowerPhoneNumber,
  getFormattedIntermediaryCommissionRate,
  getIdNumberVariableNameByType,
} from 'LoanOriginationSystemOrganization/Utils';
import InfoPanelContentItem, { InfoPanelContentItemProps } from './InfoPanelContentItem';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import WrapperWithTooltip from 'components/Tooltip';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import styles from './InfoPanelContent.module.scss';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerDefaultVariableType } from 'enums/BorrowerDefaultVariable';
import { getMailtoURI } from 'Email/utils';
import { StandardVariables } from 'Variables/VariablesTypes';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';
import DuplicatedApplicationTooltip from './DuplicatedApplicationTooltip/DuplicatedApplicationTooltip';
import getOriginalDisplayIdFromDuplicated from 'utils/getOriginalDisplayIdFromDuplicated';

interface InfoPanelContentProps {
  isOpen: boolean;
  applicationData: Application;
  formatApplicationDate?: (date: Date) => string;
  standardVariables: StandardVariables;
  organizationEmail: string;
}

const MAX_AVATARS_COUNT = 3;

const StyledInfoPanelContentItem = (props: PropsWithChildren<InfoPanelContentItemProps>) => (
  <InfoPanelContentItem {...props} itemClassName={styles.infoPanelContentItem} />
);

const InfoPanelContent = ({
  isOpen,
  applicationData,
  formatApplicationDate,
  standardVariables,
  organizationEmail,
}: InfoPanelContentProps) => {
  const formatValue = (
    mapping: BorrowerMappingsItem,
    application: Application,
    borrowerDefaultVariables: BorrowerDefaultVariableType,
    borrowerType: BorrowerType,
  ) => {
    if (mapping.isDateOfBirth) {
      return getFormattedBorrowerDateOfBirth(
        application.variables[mapping.key],
        standardVariables,
        borrowerDefaultVariables,
      );
    }

    if (mapping.isAddress) {
      const addressFields = [
        application.variables[borrowerDefaultVariables.Street],
        application.variables[borrowerDefaultVariables.City],
        application.variables[borrowerDefaultVariables.State],
        application.variables[borrowerDefaultVariables.Zip],
        application.variables[borrowerDefaultVariables.Country],
      ];

      return addressFields.filter((field) => !!field).join(', ');
    }

    if (mapping.isIdNumber) {
      const idNumberVariableName = getIdNumberVariableNameByType(borrowerType, borrowerDefaultVariables);

      return getFormattedBorrowerIdNumber(
        application.variables[idNumberVariableName],
        standardVariables[idNumberVariableName],
      );
    }

    if (mapping.isPhoneNumber) {
      return (
        getFormattedBorrowerPhoneNumber(
          application.variables[mapping.key],
          standardVariables,
          borrowerDefaultVariables,
        ) || application.variables[mapping.key]
      );
    }

    return application.variables[mapping.key];
  };

  const renderContentItem = (
    mapping: BorrowerMappingsItem,
    borrowerType: BorrowerType,
    borrowerDefaultVariables = ApplicationDefaultVariable.Borrower,
  ) => {
    const contentValue = formatValue(mapping, applicationData, borrowerDefaultVariables, borrowerType);

    const getContentValue = () => {
      if (mapping.label === 'Email') {
        return <a href={getMailtoURI({ to: contentValue, cc: organizationEmail })}>{contentValue}</a>;
      }
      if (mapping.label === 'Phone') {
        return <a href={`tel:${contentValue}`}>{contentValue}</a>;
      }
      return contentValue;
    };

    return (
      <div key={mapping.label}>
        <StyledInfoPanelContentItem label={mapping.label}>{getContentValue()}</StyledInfoPanelContentItem>
      </div>
    );
  };

  const renderRejectionBlock = () => {
    return (
      <div>
        <StyledInfoPanelContentItem label="Rejection Date">
          {formatDate(applicationData.rejectedAt!, DateTimeFormat.Long)}
        </StyledInfoPanelContentItem>
        <StyledInfoPanelContentItem label="Decline Reasons">
          {applicationData.declineReasons?.map((reason) => (
            <div key={reason}>{reason}</div>
          ))}
        </StyledInfoPanelContentItem>
      </div>
    );
  };

  const renderApprovalBlock = () => {
    return (
      <div>
        <StyledInfoPanelContentItem label="Approval Date">
          {formatDate(applicationData.approvedAt!, DateTimeFormat.Long)}
        </StyledInfoPanelContentItem>
      </div>
    );
  };

  const intermediaryName = applicationData.variables[ApplicationDefaultVariable.Intermediary.Name];
  const intermediaryCommissionRate = applicationData.variables[ApplicationDefaultVariable.Intermediary.CommissionRate];
  const formattedCommissionRate = intermediaryCommissionRate
    ? `(${getFormattedIntermediaryCommissionRate(intermediaryCommissionRate, standardVariables)})`
    : '';

  const borrowerMapping = getBorrowerMappings(ApplicationDefaultVariable.Borrower, applicationData.borrowerType);
  const coborrowerMapping =
    applicationData.coborrowerType &&
    getBorrowerMappings(ApplicationDefaultVariable.Coborrower, applicationData.coborrowerType);

  const renderTeamMembersSection = () => {
    if (applicationData.teamMembers?.length > 0) {
      return (
        <div>
          <StyledInfoPanelContentItem label="Team Members" className={styles.teamMembers}>
            <UserAvatarInlineList source={applicationData.teamMembers} size="small" maxCount={MAX_AVATARS_COUNT} />
          </StyledInfoPanelContentItem>
        </div>
      );
    }

    return (
      <div>
        <StyledInfoPanelContentItem label="Team Members" className={styles.teamMembers}>
          <WrapperWithTooltip tooltip="Unassigned">
            <AvatarIcon />
          </WrapperWithTooltip>
        </StyledInfoPanelContentItem>
      </div>
    );
  };

  const originalApplicationDisplayId =
    applicationData.originalApplicationId && getOriginalDisplayIdFromDuplicated(applicationData.displayId);

  return (
    <div className={clsx(styles.infoPanelContentInner, isOpen && styles.additionalContentOpen)}>
      <div className={styles.infoPanelContentBox}>
        <p className={styles.infoPanelColumnTitle}>
          Application ID: #{applicationData.displayId}
          {originalApplicationDisplayId && (
            <WrapperWithHoverableTooltip
              tooltip={<DuplicatedApplicationTooltip originalApplicationDisplayId={originalApplicationDisplayId} />}
            >
              <DuplicateImage className={styles.duplicateIcon} />
            </WrapperWithHoverableTooltip>
          )}
        </p>
        <div>
          <StyledInfoPanelContentItem label="Application Date">
            {!formatApplicationDate
              ? formatDate(applicationData.createdAt, DateTimeFormat.Long)
              : formatApplicationDate(applicationData.createdAt)}
          </StyledInfoPanelContentItem>
        </div>
        <div>{renderTeamMembersSection()}</div>
        {applicationData.status.name === DefaultApplicationStatus.Approved && renderApprovalBlock()}
        {applicationData.status.name === DefaultApplicationStatus.Rejected && renderRejectionBlock()}
        <div>
          {applicationData.intermediaryId && (
            <div>
              <StyledInfoPanelContentItem label="Intermediary">
                {`${intermediaryName} ${formattedCommissionRate}`}
              </StyledInfoPanelContentItem>
            </div>
          )}
        </div>
      </div>
      <div className={styles.infoPanelContentBox}>
        <p className={styles.infoPanelColumnTitle}>
          Borrower: {getBorrowerFullName(applicationData.borrowerType, applicationData.variables)}
        </p>
        {borrowerMapping.map((mapping) => renderContentItem(mapping, applicationData.borrowerType))}
      </div>
      {applicationData.coborrowerType && (
        <div className={styles.infoPanelContentBox}>
          <p className={styles.infoPanelColumnTitle}>
            Co-Borrower:{' '}
            {getBorrowerFullName(
              applicationData.coborrowerType,
              applicationData.variables,
              ApplicationDefaultVariable.Coborrower,
            )}
          </p>
          {coborrowerMapping?.map((mapping) =>
            renderContentItem(mapping, applicationData.coborrowerType!, ApplicationDefaultVariable.Coborrower),
          )}
        </div>
      )}
    </div>
  );
};

export default InfoPanelContent;
