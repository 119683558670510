/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, forwardRef, LegacyRef, Ref } from 'react';
import styles from './AutoCompleteWithError.module.scss';
import clsx from 'clsx';
import AutoCompletionSync, { AutoCompletionSyncProps } from 'components/AutoCompletion/AutoCompletionSync';
import useRandomIdFallback from 'hooks/randomIdFallback';
import useWindowSize from 'hooks/useWindowSize';
import variables from 'components/Auth/Variables.module.scss';

const AutoCompleteWithError = forwardRef<HTMLSelectElement | HTMLInputElement, AutoCompletionSyncProps>(
  ({ errorMessage, className, options, labelTitle, ...restProps }, ref) => {
    const windowSize = useWindowSize();

    const mobileSelectId = useRandomIdFallback();

    const handleMobileChange = (e: ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = options.find(({ value }) => value === e.target.options[e.target.selectedIndex].value);

      if (selectedOption) {
        restProps.onChange(selectedOption);
      }
    };

    const renderMobileSelect = () => {
      return (
        <div className={styles.mobileSelectContainer}>
          <div className={styles.headerContainer}>
            <label htmlFor={mobileSelectId} className={styles.label}>
              {labelTitle}
            </label>
          </div>
          <select
            id={mobileSelectId}
            className={clsx(styles.mobileSelect, errorMessage && styles.hasError, className)}
            onChange={handleMobileChange}
            defaultValue=""
            ref={ref as LegacyRef<HTMLSelectElement>}
          >
            <option value="" disabled />
            {options.map(({ name, value }) => (
              <option key={value} value={value}>
                {name}
              </option>
            ))}
          </select>
        </div>
      );
    };

    const isMobile = windowSize.width !== undefined && windowSize.width <= parseInt(variables.mobileMaxWidth, 10);

    return (
      <>
        {!isMobile && (
          <AutoCompletionSync
            labelTitle={labelTitle}
            options={options}
            className={clsx(styles.desktopSelect, className)}
            hasError={!!errorMessage}
            {...restProps}
            ref={ref as Ref<HTMLInputElement>}
          />
        )}
        {isMobile && renderMobileSelect()}
        {errorMessage && <p className={styles.errorNotification}>{errorMessage}</p>}
      </>
    );
  },
);

export default AutoCompleteWithError;
