import React, { useEffect } from 'react';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationTab, ApplicationTabType } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import TabSwitch from 'components/TabSwitch';
import DocumentsTab from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab';
import EmailsTab from 'components/LoanOriginationSystem/ApplicationTabs/EmailsTab/Emails';
import TasksTab from 'components/LoanOriginationSystem/ApplicationTabs/TasksTab';
import DataTab from 'components/LoanOriginationSystem/ApplicationTabs/DataTab';
import NotesTab from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab';
import DecisionEngineTab from 'components/LoanOriginationSystem/ApplicationTabs/DecisionEngineTab';
import StatusRulesTab from './StatusRulesTab';
import styles from './ApplicationTabs.module.scss';
import HistoryTab from './HistoryTab';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';

interface ApplicationTabsProps {
  tabs: ApplicationTab[] | null;
  onGetApplicationTabs: (productId: string) => void;
  onGetProduct: (productId: string) => void;
  product: Product | null;
  productId: string | null;
  application: Application | null;
  onCreateTask: () => void;
  onEditTask: (id: string) => void;
  onTabChange: (tabName: string) => void;
  selectedTabId: string | null;
  organizationEmail: string;
}

const ApplicationTabs = ({
  tabs,
  onGetApplicationTabs,
  onGetProduct,
  onCreateTask,
  onEditTask,
  onTabChange,
  selectedTabId,
  productId,
  product,
  application,
  organizationEmail,
}: ApplicationTabsProps) => {
  useEffect(() => {
    if (productId && !tabs) {
      onGetApplicationTabs(productId);
    }

    if (productId) {
      onGetProduct(productId);
    }
  }, [productId]);

  if (!productId || !tabs || !application) {
    return null;
  }

  const tabsSwitchOptions = tabs.map(({ id, name }) => ({
    id,
    label: name,
  }));

  const renderTab = (tab: ApplicationTab) => {
    switch (tab.type) {
      case ApplicationTabType.DataTab: {
        return <DataTab tab={tab} application={application} />;
      }
      case ApplicationTabType.Documents: {
        return (
          <DocumentsTab
            applicationId={application.id}
            product={product}
            applicationBorrowerFirstName={application.variables[BorrowerDefaultVariable.FirstName]}
            applicationBorrowerLastName={application.variables[BorrowerDefaultVariable.LastName]}
          />
        );
      }
      case ApplicationTabType.Emails: {
        return (
          <EmailsTab
            borrowerId={application.borrowerId}
            borrowerEmail={application.variables.borrower_email ? application.variables.borrower_email?.toString() : ''}
            coBorrowerEmail={
              application.variables.coborrower_email ? application.variables.coborrower_email?.toString() : ''
            }
            organizationEmail={organizationEmail}
          />
        );
      }
      case ApplicationTabType.Tasks: {
        return <TasksTab applicationId={application.id} onCreate={onCreateTask} onEdit={onEditTask} />;
      }
      case ApplicationTabType.Notes: {
        return <NotesTab applicationId={application.id} />;
      }
      case ApplicationTabType.DecisionEngine: {
        return <DecisionEngineTab applicationId={application.id} />;
      }
      case ApplicationTabType.StatusRules: {
        return <StatusRulesTab onTabChange={onTabChange} availableTabs={tabs} application={application} />;
      }
      case ApplicationTabType.History: {
        return <HistoryTab applicationId={application.id} />;
      }
      default: {
        throw new Error(`Incorrect tab type: ${tab.type}`);
      }
    }
  };

  const selectionOption = tabsSwitchOptions.find(({ id }) => id === selectedTabId) || tabsSwitchOptions[0];

  return (
    <section className={styles.tabsContainer}>
      <TabSwitch tabs={tabsSwitchOptions} selectedTabId={selectionOption.id} onSelect={({ id }) => onTabChange(id)} />
      <div className={styles.tabContainer}>{renderTab(tabs.find((tab) => tab.id === selectionOption.id)!)}</div>
    </section>
  );
};

export default ApplicationTabs;
