import React from 'react';
import { flags } from 'components/PhoneInput';

import countryCodeStyles from 'components/CountryCodeSelect/CountryCodeSelect.module.scss';
import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import useSamplePhoneNumber from 'components/PhoneNumberInput/useSamplePhoneNumber';

export interface PhoneNumberInputStubProps extends Omit<TextInputStubProps, 'placeholder'> {
  country: string | null;
}

const PhoneNumberInputStub = ({ country, ...restProps }: PhoneNumberInputStubProps) => {
  const Flag = country && flags[country];

  const placeholder = useSamplePhoneNumber(country);

  const renderPlaceholder = () => (
    <>
      {Flag && <Flag className={countryCodeStyles.flag} />}
      <div>{placeholder}</div>
    </>
  );

  return <TextInputStub {...restProps} placeholder={renderPlaceholder()} />;
};

export default PhoneNumberInputStub;
