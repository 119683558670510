import React, { FC } from 'react';
import clsx from 'clsx';
import { DownloadImage } from 'static/images';
import Button from 'components/Button';
import styles from './DownloadApiTemplateButton.module.scss';

export interface DownloadApiTemplateButtonProps {
  onDownloadApiRequestTemplate: () => void;
  className?: string;
}

const DownloadApiTemplateButton: FC<DownloadApiTemplateButtonProps> = ({ onDownloadApiRequestTemplate, className }) => (
  <Button
    className={clsx(styles.downloadApiRequestTemplateButton, className)}
    kind="secondary"
    onClick={onDownloadApiRequestTemplate}
  >
    <DownloadImage />
    Download API Template
  </Button>
);

export default DownloadApiTemplateButton;
