import { AnyAction } from 'redux';
import { LoanOriginationSystemCreateTaskActionTypes } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { NewTask } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';

export interface CreateTaskAction extends AnyAction {
  type: LoanOriginationSystemCreateTaskActionTypes.CreateTask;
  payload: {
    data: NewTask;
  };
}

export interface CreateTaskSuccessAction extends AnyAction {
  type: LoanOriginationSystemCreateTaskActionTypes.CreateTaskSuccess;
}

export const createTask = (data: NewTask): CreateTaskAction => {
  return {
    type: LoanOriginationSystemCreateTaskActionTypes.CreateTask,
    payload: {
      data,
    },
  };
};

export const createTaskSuccess = (): CreateTaskSuccessAction => {
  return {
    type: LoanOriginationSystemCreateTaskActionTypes.CreateTaskSuccess,
  };
};

export const createTaskFailure = FailedActionCreator(LoanOriginationSystemCreateTaskActionTypes.CreateTaskFailure);
