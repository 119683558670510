import { NotificationType } from 'components/Notification';
import { ApplicationStatusChangeErrorNotificationProps } from 'components/ApplicationStatusChangeErrorNotification/ApplicationStatusChangeErrorNotification';
import { ItemHasApplicationsNotificationProps } from 'components/ItemHasApplicationsErrorNotification/ItemHasApplicationsErrorNotification';

export enum NotificationComponentTypes {
  ApplicationStatusChangeErrorNotificationComponent = 'ApplicationStatusChangeErrorNotification',
  DuplicateApplicationError = 'DuplicateApplicationError',
  ResetPasswordExpiredErrorNotificationComponent = 'ResetPasswordExpiredNotificationComponent',
  ItemHasApplicationsErrorNotificationComponent = 'ItemHasApplicationsErrorNotification',
}

export type ComponentTypeWithProps<ComponentType, Props> = {
  componentType: ComponentType;
  props: Props;
};

export type NotificationComponents =
  | ComponentTypeWithProps<
      NotificationComponentTypes.ApplicationStatusChangeErrorNotificationComponent,
      ApplicationStatusChangeErrorNotificationProps
    >
  | ComponentTypeWithProps<NotificationComponentTypes.ResetPasswordExpiredErrorNotificationComponent, undefined>
  | ComponentTypeWithProps<NotificationComponentTypes.DuplicateApplicationError, any>
  | ComponentTypeWithProps<
      NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent,
      ItemHasApplicationsNotificationProps
    >;

export interface NotificationItem {
  type: NotificationType;
  notification: string | NotificationComponents;
  timeout: number;
  id: string;
}

export interface ShowNotification {
  type: string;
  notification: string | NotificationComponents;
  timeout?: number;
  id: string;
}

export interface UserInfo {
  _id: string;
  first_name: string;
}
