import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import documentsPagination from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/pagination';

export default (api: ApplicationDocumentsApi) => {
  return documentsPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getAll(
      params.applicationId,
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.search,
      },
      params.sortingType,
    );
    return items;
  });
};
