import { ActionWithOrigin } from './ActionWithOrigin';

export interface FailedAction<TypeType = string, ActionOrigin = undefined> extends ActionWithOrigin<ActionOrigin> {
  type: TypeType;
  payload: Error;
  error: true;
}

const FailedActionCreator = (type: string) => (error: Error): FailedAction<typeof type> => {
  return {
    type,
    payload: error,
    error: true,
  };
};

export default FailedActionCreator;
