import React, { FC } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import { ReduxState } from 'types/redux';
import { deleteDecisionStrategyModuleRequest } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import DeleteDecisionStrategyModuleWizard from 'DeleteDecisionStrategyModuleWizard/DeleteDecisionStrategyModuleWizard';
import { closeDeleteDecisionStrategyModuleWizard } from 'DeleteDecisionStrategyModuleWizard/Actions';
import { DeleteDecisionStrategyModuleWizardState } from 'DeleteDecisionStrategyModuleWizard/deleteDecisionStrategyModuleWizardTypes';
import { useHistory } from 'react-router';
import DeleteBranchWizard from 'components/DeleteBranchWizard';
import { NewStrategyVersionState } from 'CreateNewStrategyVersion/Types';
import {
  closeCreateNewStrategyVersionPopUp,
  createNewStrategyVersionRequest,
} from 'CreateNewStrategyVersion/CreateNewStrategyVersionActionCreator';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { CREATE_NEW_STRATEGY_VERSION_ACTION_ORIGIN_BUTTON } from 'CreateNewStrategyVersion/CreateNewStrategyVersionMiddlewares';
import CreateNewStrategyVersion from 'CreateNewStrategyVersion/CreateNewStrategyVersion';
import { DecisionStrategiesState } from 'DecisionStrategy/DecisionStrategiesTypes';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import ApplicationStatusRulesValidationPopup from 'components/ApplicationStatusRulesValidationPopup';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { setApplicationStatusRulesValidationData } from 'LoanOriginationSystemApplications/ActionCreator';
import { closeChangeApplicationStatusPopup } from 'LoanOriginationSystemApplicationPage/ActionCreator';

const GlobalOverlays: FC = () => {
  const deleteDecisionStrategyModuleWizard: DeleteDecisionStrategyModuleWizardState = useSelector(
    (state: ReduxState) => state.deleteDecisionStrategyModuleWizard,
  );
  const createNewStrategyVersion = useSelector<ReduxState, NewStrategyVersionState>(
    (state: ReduxState) => state.newStrategyVersion,
  );
  const strategies = useSelector<ReduxState, DecisionStrategiesState>(({ decisionStrategies }) => decisionStrategies);
  const applicationStatusRulesValidationData = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplications.applicationStatusRulesValidationData,
  );
  const history = useHistory();

  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const closeModulePopup = () => {
    dispatch(closeDeleteDecisionStrategyModuleWizard());
  };

  const handleApplicationStatusRulesValidationPopupClose = () =>
    batch(() => {
      dispatch(closeChangeApplicationStatusPopup());
      dispatch(setApplicationStatusRulesValidationData(null));
    });

  const handleViewApplication = () => {
    if (!applicationStatusRulesValidationData) {
      return;
    }

    history.push(`/los/applications/${applicationStatusRulesValidationData.application.displayId}`);

    handleApplicationStatusRulesValidationPopupClose();
  };

  const deleteDecisionStrategyModule = async (strategyId: string, moduleLookupName: string) => {
    const strategyInfo = strategies.strategiesDetailedAttributes[strategyId];
    const { type, name } = strategyInfo.moduleList.find(({ lookupName }) => lookupName === moduleLookupName)!;

    await dispatchRoutine(
      deleteDecisionStrategyModuleRequest({
        strategyId,
        moduleLookupName,
        moduleType: type,
        moduleName: name,
        moduleIndex: deleteDecisionStrategyModuleWizard.moduleIndex,
      }),
    );
  };

  const createNewStrategyRender = () => {
    if (createNewStrategyVersion.isOpen) {
      const handleNewStrategyVersionCreation = async () => {
        const action = createNewStrategyVersionRequest(
          createNewStrategyVersion.strategyId,
          createNewStrategyVersion.strategyName,
        );
        await dispatchRoutine(setActionOrigin(action, CREATE_NEW_STRATEGY_VERSION_ACTION_ORIGIN_BUTTON));
      };
      return (
        <CreateNewStrategyVersion
          strategyId={createNewStrategyVersion.strategyId}
          title="Create New Version"
          closePopUp={() => dispatch(closeCreateNewStrategyVersionPopUp())}
          onCreateNewStrategyVersion={handleNewStrategyVersionCreation}
        />
      );
    }
    return null;
  };

  const renderStatusRuleCheckPopup = () => {
    if (!applicationStatusRulesValidationData) {
      return null;
    }

    const { application, status, statusRulesValidationResult } = applicationStatusRulesValidationData;

    const applicationName = getBorrowerFullName(application.borrowerType, application.variables);

    return (
      <ApplicationStatusRulesValidationPopup
        applicationName={applicationName}
        statusName={status.name}
        statusRulesValidationResult={statusRulesValidationResult}
        onViewApplication={handleViewApplication}
        onClose={handleApplicationStatusRulesValidationPopupClose}
      />
    );
  };

  return (
    <>
      <DeleteDecisionStrategyModuleWizard
        closePopUp={closeModulePopup}
        isOpen={deleteDecisionStrategyModuleWizard.isOpen}
        title="Delete Module"
        deleteModule={deleteDecisionStrategyModule}
        strategyId={deleteDecisionStrategyModuleWizard.strategyId}
        moduleLookupName={deleteDecisionStrategyModuleWizard.moduleLookupName}
      />
      <DeleteBranchWizard />
      {createNewStrategyRender()}
      {renderStatusRuleCheckPopup()}
    </>
  );
};

export default GlobalOverlays;
