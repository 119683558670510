import React, { FC, useState } from 'react';
import styles from './ManagePhoneAuthenticationPopup.module.scss';
import Button from 'components/Button';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import InputWithDataType from 'components/InputWithDataType';

interface PhoneNumberFormProps {
  phoneNumberFormat: string;
  onPhoneEntered: (phone: string) => void;
  loading: boolean;
}

const DEFAULT_PHONE_COUNTRY_CODE = 'US';

const PhoneNumberForm: FC<PhoneNumberFormProps> = ({ onPhoneEntered, loading, phoneNumberFormat }) => {
  const [phone, setPhone] = useState('');
  const [phoneFormat, setPhoneFormat] = useState(phoneNumberFormat || DEFAULT_PHONE_COUNTRY_CODE);

  return (
    <>
      <p className={styles.description}>
        Please provide your mobile phone number. This number must be capable of receiving text messages (standard rates
        apply).
      </p>
      <div className={styles.inputContainer}>
        <CountryCodeSelect
          hideClearIcon
          hasRightNeighbour
          labelTitle="Phone Number"
          className={styles.countryCodeSelectorContainer}
          value={phoneFormat}
          onChange={(option) => {
            setPhoneFormat(option.value);
          }}
        />
        <InputWithDataType
          dataType="PhoneNumber"
          required
          options={null}
          labelTitle=""
          hasLeftNeighbour
          containerClassName={styles.phoneNumberInputContainer}
          value={phone}
          phoneNumberFormat={phoneFormat}
          phoneNumberWithFlag={false}
          onChange={setPhone}
        />
      </div>
      <Button size="form" onClick={() => onPhoneEntered(phone)} isLoading={loading} disabled={!phone}>
        Continue
      </Button>
    </>
  );
};

export default PhoneNumberForm;
