import styles from './ManagePhoneAuthenticationPopup.module.scss';
import React, { ChangeEvent, FC, useState } from 'react';
import Button from 'components/Button';
import TextInput from 'components/TextInput';
import { formatPhoneNumber } from 'components/PhoneInput';

interface PhoneCodeProps {
  onCodeEntered: (code: string) => void;
  loading: boolean;
  phone: string;
  action: string;
}

const PhoneCodeForm: FC<PhoneCodeProps> = ({ loading, onCodeEntered, phone, action }) => {
  const [code, setCode] = useState('');
  const formattedPhone = formatPhoneNumber(phone);

  return (
    <>
      <p className={styles.description}>Please enter the code sent to {formattedPhone}.</p>

      <div className={styles.inputContainer}>
        <TextInput
          type="text"
          containerClassName={styles.codeInputContainer}
          labelTitle="Authentication Code"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setCode(e.target.value)}
          errorMessage=""
          value={code}
          placeholder="6 digits"
        />
      </div>

      <Button size="form" kind="primary" onClick={() => onCodeEntered(code)} isLoading={loading} disabled={!code}>
        {action} Phone Authentication
      </Button>
    </>
  );
};

export default PhoneCodeForm;
