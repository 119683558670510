import AutoCompletion from 'components/AutoCompletion';
import { AutoCompletionAsyncProps } from 'components/AutoCompletion/AutoCompletionAsync';
import React, { FC, useCallback } from 'react';
import { useVariablesApi } from 'providers/ApiServiceProvider';
import { DataType } from 'Variables/VariablesTypes';

export interface VariableSelectorProps extends Omit<AutoCompletionAsyncProps, 'fetchOptions' | 'fetchSelectedOption'> {
  dataType?: string;
}

const MAX_VARIABLES_COUNT = 100;

const VariableSelector: FC<VariableSelectorProps> = (props) => {
  const variablesApi = useVariablesApi();

  const fetchSelectedOption = useCallback(async (value: string) => {
      const selectedVariableData = await variablesApi.findById(value);
      if (!selectedVariableData) {
        return undefined;
      }

      return {
        value: selectedVariableData.id,
        name: selectedVariableData.displayName,
      };
    },
    [],
  );

  const fetchDropdownVariables = useCallback(async (search: string, abortSignal: AbortSignal) => {
      const { items } = await variablesApi.getVariables(
        {
          search,
          dataType: props.dataType as unknown as DataType,
          count: MAX_VARIABLES_COUNT,
        },
        undefined,
        abortSignal,
      );

      return items.map(({ id, displayName }) => ({
        value: id,
        name: displayName,
      }));
    },
    [],
  );

  return <AutoCompletion
    {...props}
    fetchOptions={fetchDropdownVariables}
    fetchSelectedOption={fetchSelectedOption}
  />;
};

export default VariableSelector;
