import React from 'react';
import styles from './FilterButton.module.scss';
import { FilterIcon } from 'static/images';
import Button, { ButtonProps } from 'components/Button/Button';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

const BUTTON_TOOLTIP = 'Filters';

interface FilterButtonProps extends ButtonProps {
  active?: boolean;
  containerClassName?: string;
}

const FilterButton = ({ active, containerClassName, ...buttonProps }: FilterButtonProps) => {
  return (
    <WrapperWithTooltip tooltip={BUTTON_TOOLTIP}>
      <div className={containerClassName}>
        <Button
          kind="with-state"
          className={clsx(styles.filterButton, active && styles.active)}
          type="button"
          {...buttonProps}
        >
          <FilterIcon />
        </Button>
      </div>
    </WrapperWithTooltip>
  );
};

export default FilterButton;
