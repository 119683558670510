import React from 'react';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import MainLayout, { PageWrapperWithFooter } from 'MainLayout';
import PageContent from 'MainLayout/PageContent';
import ProcessAutomationDashboard from 'components/LoanOriginationSystem/ProcessAutomationDashboard';
import ConfirmPopup from 'components/ConfirmPopup';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeActivationStatus,
  closeChangeActivationStatusPopup,
  openChangeActivationStatusPopup,
  updateRuntimeOptions,
} from 'ProcessAutomation/ActionCreator';
import NavigationLinkId from 'enums/NavigationLinkId';
import { AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import { ReduxState } from 'types/redux';
import { getNextRunDate, getIntervalDescription } from 'utils/processAutomationInterval';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import pagination from 'components/LoanOriginationSystem/ProcessAutomationDashboard/Pagination';
import useStateReset from 'hooks/useStateReset';
import { ProcessAutomationActionType } from 'ProcessAutomation/ActionTypes';

const leftNav = makeLeftNavigation(NavigationLinkId.ProcessAutomation, ApplicationSectionName.LoanOriginationSystem);

const ProcessAutomation = () => {
  const dispatch = useDispatch();

  useStateReset(ProcessAutomationActionType.ResetState);

  const { processAutomationIdToChange, activationStatusToSet, isChangeInProgress, group, category } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemAutomatedProcesses,
  );

  const paginationProps = pagination.usePagination({ group, category });
  const automatedProcesses = pagination.usePaginatedItems({ group, category });

  const renderActivationStatusPopupTitle = () => {
    return activationStatusToSet ? 'Confirm Activation' : 'Confirm Deactivation';
  };

  const renderActivationStatusPopupMessage = () => {
    return activationStatusToSet
      ? 'Please confirm that you would like to activate this process automation.'
      : 'Please confirm that you would like to deactivate this process automation.';
  };

  const renderActivationPopupSubMessage = () => {
    if (!activationStatusToSet) {
      return undefined;
    }

    const processToUpdate = automatedProcesses.find((process) => process?.id === processAutomationIdToChange)!;

    const intervalDescription = processToUpdate.intervalDescription || getIntervalDescription(processToUpdate.interval);

    const nexRunDate = getNextRunDate(processToUpdate.interval);

    return (
      `This will run ${intervalDescription.toLowerCase()}. ` +
      `The first time it runs will be ${formatDate(nexRunDate, DateTimeFormat.Long)}.`
    );
  };

  const handleChangeActivationStatusPopupOpen = (id: string, status: boolean) => {
    dispatch(openChangeActivationStatusPopup(id, status));
  };

  const handleActivationStatusPopupClose = () => {
    dispatch(closeChangeActivationStatusPopup());
  };

  const handleRuntimeOptionsChange = (
    id: string,
    runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>,
  ) => {
    dispatch(updateRuntimeOptions(id, runtimeOptionsUpdate));
  };

  const handleChangeActivationStatus = () => {
    dispatch(changeActivationStatus(processAutomationIdToChange!, activationStatusToSet!));
  };

  const renderOverlay = () => {
    if (processAutomationIdToChange) {
      return (
        <ConfirmPopup
          title={renderActivationStatusPopupTitle()}
          message={renderActivationStatusPopupMessage()}
          confirmText="Confirm"
          declineText="No, Go Back"
          onConfirmClick={handleChangeActivationStatus}
          onPopupClose={handleActivationStatusPopupClose}
          loading={isChangeInProgress}
          subMessage={renderActivationPopupSubMessage()}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout leftNav={leftNav} overlay={renderOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <ProcessAutomationDashboard
            paginationProps={paginationProps}
            automatedProcesses={automatedProcesses}
            onActivationStatusChange={handleChangeActivationStatusPopupOpen}
            onRuntimeOptionsChange={handleRuntimeOptionsChange}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default ProcessAutomation;
