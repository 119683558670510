import Fetcher from 'middlewares/Fetcher';
import UpdateApplicationStatusError from 'errors/UpdateApplicationStatusError';
import {
  LoanOriginationSystemApplicationStatusesApi,
  ApplicationStatus,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  UpdateApplicationStatusAction,
  updateApplicationStatusSuccess,
  updateApplicationStatusFailure,
} from './ActionCreator';

const UpdateApplicationStatusMiddleware = (api: LoanOriginationSystemApplicationStatusesApi) =>
  Fetcher<ApplicationStatus, UpdateApplicationStatusAction>(
    ApplicationStatusesActionType.UpdateApplicationStatus,
    updateApplicationStatusSuccess,
    updateApplicationStatusFailure,
    async (action) => {
      try {
        return await api.update(action.payload.statusId, action.payload.status);
      } catch (err) {
        throw new UpdateApplicationStatusError(err.message, action.payload.statusId);
      }
    },
  );

export default UpdateApplicationStatusMiddleware;
