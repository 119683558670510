import React, { FC } from 'react';

import styles from './DropdownContent.module.scss';
import { NestedLinks } from 'MainLayout/leftNavReducer/LeftNavTypes';
import clsx from 'clsx';

export interface DropdownContentType {
  isDropdownOpen: boolean;
  isExpanded: boolean;
  nestedLinks: NestedLinks;
  onUpdateActiveLinkTitle: (title: string) => void;
  activeLinkId: string;
  nestedItemClassName?: string;
}

const DropdownContent: FC<DropdownContentType> = ({
  isDropdownOpen,
  isExpanded,
  nestedLinks,
  onUpdateActiveLinkTitle,
  activeLinkId,
  nestedItemClassName,
}) => {
  return (
    <div
      className={
        isDropdownOpen && isExpanded ? styles.nestedLinksContainerExpanded : styles.nestedLinksContainerCollapsed
      }
    >
      {isExpanded &&
        nestedLinks.map((link, ind) => {
          const { title, route, id } = link;
          const className = id === activeLinkId ? styles.activeNestedItem : styles.nestedItem;
          return (
            <div
              className={clsx(className, nestedItemClassName)}
              key={ind}
              onClick={() => onUpdateActiveLinkTitle(route)}
            >
              {title}
            </div>
          );
        })}
    </div>
  );
};

export default DropdownContent;
