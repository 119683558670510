import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'types/redux';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationFormPage, ProductSettings } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import ConfirmPopup from 'components/ConfirmPopup';
import { RuleBuilderCoreData } from 'RuleCreate/Types';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { deleteApplicationTab } from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import {
  getApplicationVariableConfigurationsSelector,
  getBorrowerVariableConfigurationsSelector,
  getIntermediaryVariableConfigurationsSelector,
} from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { formatDefaultFieldsDisplayTitle as formatBorrowerDisplayName } from 'LoanOriginationSystemBorrowers/utils';
import { formatDefaultFieldsDisplayTitle as formatIntermediaryDisplayName } from 'LoanOriginationSystemIntermediariesPage/utils';
import {
  changeProductStatus,
  duplicateProduct,
  setProductToDuplicate,
  setStatusChangeData,
  updateProduct,
  UpdateProductActionOrigin,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { setTabToDelete } from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationSetup/ActionCreator';
import {
  closeCreateVariablePopup,
  closeDeclineReasonsPopup,
  openCreateVariablePopup,
  setApplicationStatusToDelete,
  setDeleteStatusRuleData,
  setStatusRuleToEdit,
  setStatusToAddRule,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationsWorkflowSetup/ActionCreator';
import {
  setFormPageToDelete,
  setPageToEditRequiredFields,
  toggleFormSettingsPopupVisibility,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationFormConfiguration/ActionCreator';
import { VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import {
  bulkUpdateBorrowerVariableConfigurations,
  bulkUpdateIntermediaryVariableConfigurations,
  BulkUpdateVariableConfigurationsActionOrigin,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { bulkUpdateApplicationVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';
import EditDeclineReasonsPopup from 'components/LoanOriginationSystem/ProductConfiguration/EditDeclineReasonsPopup';
import ProductConfiguration from 'components/LoanOriginationSystem/ProductConfiguration';
import EditStatusRulePopup from 'components/LoanOriginationSystem/ProductConfiguration/EditStatusRulePopup';
import CreateVariablePopup from 'components/CreateVariablePopup';
import EditRequiredFieldsPopup from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/EditRequiredFieldsPopup';
import CoBorrowerEditRequiredFieldsPopup from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/CoBorrowerEditRequiredFieldsPopup';
import FormSettingsPopup from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/FormSettingsPopup';
import { labelsByFormPageMap } from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/labels';
import ChangeProductStatusConfirmPopup from 'components/LoanOriginationSystem/ProductsDashboard/ChangeProductStatusConfirmPopup';
import DuplicateProductConfirmationPopup from 'components/LoanOriginationSystem/ProductsDashboard/DuplicateProductConfirmationPopup';
import { updateDeclineReasons } from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import {
  createApplicationStatusRule,
  deleteApplicationStatus,
  deleteApplicationStatusRule,
  updateApplicationStatusRule,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import { VariableClientType } from 'Variables/VariablesTypes';
import { getVariableType } from 'Variables/utils';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import styles from './Product.module.scss';

const leftNav = makeLeftNavigation(NavigationLinkId.Products, ApplicationSectionName.LoanOriginationSystem);

const getBulkUpdateConfigurationParams = (configurations: VariableConfiguration[], requiredFields: string[]) => {
  return configurations
    .filter(({ variable, required }) => required !== requiredFields.includes(variable.systemName))
    .map(({ id, variable }) => ({ id, required: requiredFields.includes(variable.systemName) }));
};

const Product = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productId, step } = useParams();

  const {
    selectedProduct,
    isDeclineReasonsPopupOpen,
    tabToDelete,
    isTabDeletingInProgress,
    applicationStatusToDelete,
    isApplicationStatusDeletingInProgress,
    isDeclineReasonsUpdatingInProgress,
    statusToAddRule,
    statusRuleToEdit,
    isStatusRuleCreatingInProgress,
    isStatusDeletingInProgress,
    isStatusRuleUpdatingInProgress,
    deleteStatusRuleData,
    isCreateVariablePopupOpen,
    isVariableCreatingInProgress,
    pageToEditRequiredFields,
    isRequiredVariablesEditInProgress,
    isFormSettingsPopupOpen,
    isFormSettingUpdateInProgress,
    formPageToDelete,
    isFormPageDeleteInProgress,
    statusChangeData,
    productToDuplicate,
    isDuplicatingInProgress,
    isStatusUpdatingInProgress,
  } = useSelector((state: ReduxState) => ({
    isTabDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationSetup.isTabDeletingInProgress,
    selectedProduct: state.loanOriginationSystemProducts.productConfiguration.product,
    tabToDelete: state.loanOriginationSystemProducts.productConfiguration.applicationSetup.tabToDelete,
    applicationStatusToDelete:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.applicationStatusToDelete,
    isApplicationStatusDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusDeletingInProgress,
    isDeclineReasonsPopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isDeclineReasonsPopupOpen,
    isDeclineReasonsUpdatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup
        .isDeclineReasonsUpdatingInProgress,
    statusToAddRule: state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.statusToAddRule,
    statusRuleToEdit:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.statusRuleToEdit,
    isStatusRuleCreatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusRuleCreatingInProgress,
    isStatusDeletingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusDeletingInProgress,
    isStatusRuleUpdatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isStatusRuleUpdatingInProgress,
    deleteStatusRuleData:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.deleteStatusRuleData,
    isCreateVariablePopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isCreateVariablePopupOpen,
    isVariableCreatingInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup.isVariableCreatingInProgress,
    pageToEditRequiredFields:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.pageToEditRequiredFields,
    isRequiredVariablesEditInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration
        .isRequiredVariablesEditInProgress,
    isFormSettingsPopupOpen:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.isFormSettingsPopupOpen,
    isFormSettingUpdateInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration
        .isFormSettingUpdateInProgress,
    formPageToDelete:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.formPageToDelete,
    isFormPageDeleteInProgress:
      state.loanOriginationSystemProducts.productConfiguration.applicationFormConfiguration.isFormPageDeleteInProgress,
    statusChangeData: state.loanOriginationSystemProducts.statusChangeData,
    productToDuplicate: state.loanOriginationSystemProducts.productToDuplicate,
    isDuplicatingInProgress: state.loanOriginationSystemProducts.isDuplicatingInProgress,
    isStatusUpdatingInProgress: state.loanOriginationSystemProducts.isStatusUpdatingInProgress,
  }));
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);

  const borrowerVariableConfigurations = useSelector((state: ReduxState) => {
    return getBorrowerVariableConfigurationsSelector(state, {
      borrowerType: selectedProduct?.borrowerType,
      productId: selectedProduct?.id,
    });
  });

  const intermediaryVariableConfigurations = useSelector((state: ReduxState) => {
    if (!selectedProduct) {
      return null;
    }

    return getIntermediaryVariableConfigurationsSelector(state, {
      productId: selectedProduct?.id,
    });
  });

  const applicationVariableConfigurations = useSelector((state: ReduxState) => {
    return getApplicationVariableConfigurationsSelector(state, { productId: selectedProduct?.id });
  });

  const getEditRequiredFieldsParams = () => {
    switch (pageToEditRequiredFields) {
      case ApplicationFormPage.Borrower: {
        return {
          description: 'Borrower Fields Required To Submit Application',
          configurations: borrowerVariableConfigurations || [],
          alwaysDisabledFields: [
            BorrowerDefaultVariable.FirstName,
            BorrowerDefaultVariable.LastName,
            BorrowerDefaultVariable.CompanyName,
          ],
          formatDisplayName: formatBorrowerDisplayName,
        };
      }
      case ApplicationFormPage.Intermediary: {
        return {
          description: 'Intermediary Fields Required To Submit Application',
          configurations: intermediaryVariableConfigurations || [],
          alwaysDisabledFields: [IntermediaryDefaultVariable.Name],
          formatDisplayName: formatIntermediaryDisplayName,
        };
      }
      case ApplicationFormPage.ApplicationDetails: {
        return {
          description: 'Required Fields (Leave Blank If Optional)',
          configurations: applicationVariableConfigurations || [],
          alwaysDisabledFields: [ApplicationDefaultVariable.LoanAmount],
        };
      }
      default: {
        return null;
      }
    }
  };

  const handleStepChange = (nextStep: ProductConfigurationStep) => {
    history.push(`/los/configuration/products/${productId}/${nextStep}`);
  };

  const handleEditRequiredVariablesSave = (requiredFields: string[]) => {
    if (!pageToEditRequiredFields) {
      return;
    }

    if (pageToEditRequiredFields === ApplicationFormPage.Borrower && borrowerVariableConfigurations) {
      const action = setActionOrigin(
        bulkUpdateBorrowerVariableConfigurations(
          getBulkUpdateConfigurationParams(borrowerVariableConfigurations, requiredFields),
        ),
        BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup,
      );

      dispatch(action);
    }

    if (pageToEditRequiredFields === ApplicationFormPage.Intermediary && intermediaryVariableConfigurations) {
      const action = setActionOrigin(
        bulkUpdateIntermediaryVariableConfigurations(
          getBulkUpdateConfigurationParams(intermediaryVariableConfigurations, requiredFields),
        ),
        BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup,
      );

      dispatch(action);
    }

    if (pageToEditRequiredFields === ApplicationFormPage.ApplicationDetails && applicationVariableConfigurations) {
      const action = setActionOrigin(
        bulkUpdateApplicationVariableConfigurations(
          getBulkUpdateConfigurationParams(applicationVariableConfigurations, requiredFields),
        ),
        BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup,
      );

      dispatch(action);
    }
  };

  const handleConfirmDeleteApplicationTabPopupClose = () => dispatch(setTabToDelete(null));
  const handleConfirmDeleteApplicationStatusPopupClose = () => dispatch(setApplicationStatusToDelete(null));
  const handleEditDeclineReasonsPopupClose = () => dispatch(closeDeclineReasonsPopup());
  const handleAddStatusRulePopupClose = () => dispatch(setStatusToAddRule(null));
  const handleEditStatusRulePopupClose = () => dispatch(setStatusRuleToEdit(null));
  const handleConfirmDeleteApplicationStatusRulePopupClose = () => dispatch(setDeleteStatusRuleData(null));
  const handleConfirmProductDuplicatePopupClose = () => dispatch(setProductToDuplicate(null));
  const handleConfirmChangeProductStatusPopupClose = () => dispatch(setStatusChangeData(null));

  const handleConfirmDeleteApplicationTab = () => {
    if (!tabToDelete || !selectedProduct) {
      return;
    }

    dispatch(deleteApplicationTab(tabToDelete.id));
  };

  const handleConfirmDeleteApplicationStatus = () => {
    if (!applicationStatusToDelete || !selectedProduct) {
      return;
    }

    dispatch(deleteApplicationStatus(applicationStatusToDelete.id));
  };

  const handleUpdateDeclineReasons = (newDeclineReasons: string[]) => {
    if (!selectedProduct) {
      return;
    }

    dispatch(updateDeclineReasons(selectedProduct.id, newDeclineReasons));
  };

  const handleCreateApplicationStatusRule = (multipleRules: RuleBuilderCoreData[]) => {
    if (!statusToAddRule) {
      return;
    }

    dispatch(createApplicationStatusRule(statusToAddRule.id, multipleRules));
  };

  const handleConfirmDeleteApplicationStatusRule = () => {
    if (!deleteStatusRuleData) {
      return;
    }

    dispatch(deleteApplicationStatusRule(deleteStatusRuleData.statusId, deleteStatusRuleData.ruleId));
  };

  const handleEditApplicationStatusRule = (multipleRules: RuleBuilderCoreData[]) => {
    if (!statusRuleToEdit) {
      return;
    }

    dispatch(updateApplicationStatusRule(statusRuleToEdit.id, multipleRules));
  };

  const handleCreateVariable = (variable: VariableClientType) => {
    const simpleVariableData = getVariableType(variable);
    const formattedVariable = { ...variable, ...simpleVariableData };

    const action = setActionOrigin(
      createVariableRequest(formattedVariable),
      CreateVariableActionOrigin.ApplicationStatusRule,
    );

    dispatch(action);
  };

  const handleOpenVariableCreation = () => dispatch(openCreateVariablePopup());
  const handleCloseVariableCreation = () => dispatch(closeCreateVariablePopup());
  const handleCloseEditRequiredVariablesPopup = () => dispatch(setPageToEditRequiredFields(null));

  const handleExit = () => history.push('/los/configuration/products');

  const handleProductFormSettingsSave = (settings: Partial<ProductSettings>) => {
    if (!selectedProduct) {
      return;
    }

    const action = setActionOrigin(
      updateProduct(selectedProduct.id, { settings }),
      UpdateProductActionOrigin.FormSettings,
    );

    dispatch(action);
  };

  const handleConfirmDeleteApplicationFormPage = () => {
    if (!formPageToDelete || !selectedProduct) {
      return;
    }

    const newApplicationFormPages = selectedProduct.settings.applicationFormPages.filter(
      (formPage) => formPageToDelete !== formPage,
    );

    dispatch(
      setActionOrigin(
        updateProduct(selectedProduct.id, { settings: { applicationFormPages: newApplicationFormPages } }),
        UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage,
      ),
    );
  };

  const handleFormSettingsPopupClose = () => dispatch(toggleFormSettingsPopupVisibility());
  const handleConfirmDeleteApplicationFormPageClose = () => dispatch(setFormPageToDelete(null));

  const handleConfirmChangeProductStatus = () => {
    if (!statusChangeData) {
      return;
    }

    dispatch(changeProductStatus(statusChangeData.product.id, statusChangeData.status));
  };

  const handleConfirmProductDuplicating = () => {
    if (!productToDuplicate) {
      return;
    }

    dispatch(duplicateProduct(productToDuplicate.id));
  };

  const renderOverlay = () => {
    if (tabToDelete) {
      const title = `Delete ${tabToDelete.name} Tab`;
      const message = `Are you sure you want to delete the ${tabToDelete.name} Tab?`;

      return (
        <ConfirmPopup
          title={title}
          message={message}
          confirmText="Yes, Delete Tab"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationTabPopupClose}
          onConfirmClick={handleConfirmDeleteApplicationTab}
          loading={isTabDeletingInProgress}
        />
      );
    }

    if (applicationStatusToDelete) {
      return (
        <ConfirmPopup
          title="Delete Status"
          message="Are you sure you want to delete this status?"
          confirmText="Delete Status"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationStatusPopupClose}
          onConfirmClick={handleConfirmDeleteApplicationStatus}
          loading={isApplicationStatusDeletingInProgress}
        />
      );
    }

    if (isDeclineReasonsPopupOpen && selectedProduct) {
      return (
        <EditDeclineReasonsPopup
          declineReasons={declineReasonsByProductId[selectedProduct.id] || []}
          onPopupClose={handleEditDeclineReasonsPopupClose}
          onDeclineReasonsUpdate={handleUpdateDeclineReasons}
          isUpdatingInProgress={isDeclineReasonsUpdatingInProgress}
        />
      );
    }

    if (isCreateVariablePopupOpen) {
      return (
        <CreateVariablePopup
          onClose={handleCloseVariableCreation}
          onSave={handleCreateVariable}
          isSavingInProgress={isVariableCreatingInProgress}
        />
      );
    }

    if (statusToAddRule) {
      return (
        <EditStatusRulePopup
          onPopupClose={handleAddStatusRulePopupClose}
          onRuleSave={handleCreateApplicationStatusRule}
          openVariableCreation={handleOpenVariableCreation}
          isSaving={isStatusRuleCreatingInProgress}
        />
      );
    }

    if (statusRuleToEdit) {
      return (
        <EditStatusRulePopup
          onPopupClose={handleEditStatusRulePopupClose}
          onRuleSave={handleEditApplicationStatusRule}
          openVariableCreation={handleOpenVariableCreation}
          rule={statusRuleToEdit}
          isSaving={isStatusRuleUpdatingInProgress}
        />
      );
    }

    if (deleteStatusRuleData) {
      return (
        <ConfirmPopup
          title="Delete Status Rule"
          message="Are you sure you want to delete this status rule?"
          confirmText="Delete Rule"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationStatusRulePopupClose}
          onConfirmClick={handleConfirmDeleteApplicationStatusRule}
          loading={isStatusDeletingInProgress}
        />
      );
    }

    if (pageToEditRequiredFields === ApplicationFormPage.CoBorrower) {
      return <CoBorrowerEditRequiredFieldsPopup onClose={handleCloseEditRequiredVariablesPopup} />;
    }

    const editRequiredFieldsParams = getEditRequiredFieldsParams();

    if (pageToEditRequiredFields && editRequiredFieldsParams) {
      return (
        <EditRequiredFieldsPopup
          {...editRequiredFieldsParams}
          onClose={handleCloseEditRequiredVariablesPopup}
          onSave={handleEditRequiredVariablesSave}
          isUpdatingInProgress={isRequiredVariablesEditInProgress}
        />
      );
    }

    if (isFormSettingsPopupOpen && selectedProduct) {
      return (
        <FormSettingsPopup
          isSavingInProgress={isFormSettingUpdateInProgress}
          product={selectedProduct}
          onSave={handleProductFormSettingsSave}
          onClose={handleFormSettingsPopupClose}
        />
      );
    }

    if (formPageToDelete) {
      const label = labelsByFormPageMap.get(formPageToDelete);

      return (
        <ConfirmPopup
          title={`Delete ${label} Page`}
          message={`Are you sure you want to delete the ${label} page?`}
          confirmText="Yes, Delete Page"
          declineText="No, Go Back"
          onPopupClose={handleConfirmDeleteApplicationFormPageClose}
          onConfirmClick={handleConfirmDeleteApplicationFormPage}
          loading={isFormPageDeleteInProgress}
        />
      );
    }

    if (productToDuplicate) {
      return (
        <DuplicateProductConfirmationPopup
          productName={productToDuplicate.name}
          onPopupClose={handleConfirmProductDuplicatePopupClose}
          onConfirmClick={handleConfirmProductDuplicating}
          loading={isDuplicatingInProgress}
        />
      );
    }

    if (statusChangeData) {
      const { status, product } = statusChangeData;

      return (
        <ChangeProductStatusConfirmPopup
          product={product}
          status={status}
          onPopupClose={handleConfirmChangeProductStatusPopupClose}
          onConfirmClick={handleConfirmChangeProductStatus}
          loading={isStatusUpdatingInProgress}
        />
      );
    }

    return null;
  };

  if (!Object.values(ProductConfigurationStep).includes(step)) {
    return (
      <Redirect
        to={`/los/configuration/products/${productId}/${ProductConfigurationStep.ApplicationFormConfiguration}`}
      />
    );
  }

  return (
    <MainLayout leftNav={leftNav} overlay={renderOverlay()}>
      <PageWrapper>
        <PageContent className={styles.pageContent} noPadding>
          <ProductConfiguration productId={productId} step={step} onStepChange={handleStepChange} onExit={handleExit} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Product;
