const DELETE_DECISION_STRATEGY_REQUEST = 'DELETE_DECISION_STRATEGY_REQUEST';
const DELETE_DECISION_STRATEGY_REQUEST_SUCCESS = 'DELETE_DECISION_STRATEGY_REQUEST_SUCCESS';
const DELETE_DECISION_STRATEGY_REQUEST_FAILURE = 'DELETE_DECISION_STRATEGY_REQUEST_FAILURE';

const GET_DECISION_STRATEGY_INFO_REQUEST = 'GET_DECISION_STRATEGY_INFO_REQUEST';
const GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS = 'GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS';
const GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE = 'GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE';

const UPDATE_STRATEGY_DESCRIPTION_REQUEST = 'UPDATE_STRATEGY_DESCRIPTION_REQUEST';
const UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS = 'UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS';
const UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE = 'UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE';

const UPDATE_STRATEGY_NAME_REQUEST = 'UPDATE_STRATEGY_NAME_REQUEST';
const UPDATE_STRATEGY_NAME_REQUEST_SUCCESS = 'UPDATE_STRATEGY_NAME_REQUEST_SUCCESS';
const UPDATE_STRATEGY_NAME_REQUEST_FAILURE = 'UPDATE_STRATEGY_NAME_REQUEST_FAILURE';

const DELETE_DECISION_STRATEGY_MODULE_REQUEST = 'DELETE_DECISION_STRATEGY_MODULE_REQUEST';
const DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS = 'DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS';
const DELETE_DECISION_STRATEGY_MODULE_FAILURE = 'DELETE_DECISION_STRATEGY_MODULE_FAILURE';

const ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST = 'ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST';
const ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS = 'ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS';
const ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE = 'ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE';

const SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_SUCCESS = 'SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_SUCCESS';
const SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_FAILURE = 'SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_FAILURE';

const ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT = 'ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT';

const REORDER_MODULE_BRANCHES = 'REORDER_MODULE_BRANCHES';
const REORDER_MODULE_BRANCHES_SUCCESS = 'REORDER_MODULE_BRANCHES_SUCCESS';
const REORDER_MODULE_BRANCHES_FAILURE = 'REORDER_MODULE_BRANCHES_FAILURE';

const COLLAPSE_ALL_STRATEGY_MODULES = 'COLLAPSE_ALL_MODULES';
const EXPAND_STRATEGY_MODULE = 'EXPAND_STRATEGY_MODULE';
const REMOVE_COLLAPSED_STRATEGY_MODULE = 'REMOVE_COLLAPSED_STRATEGY_MODULE';
const ADD_COLLAPSED_STRATEGY_MODULE = 'ADD_COLLAPSED_STRATEGY_MODULE';
const REMOVE_COLLAPSED_STATE = 'REMOVE_COLLAPSED_STATE';

const SET_STRATEGY_OVERVIEW_DATA = 'SET_STRATEGY_OVERVIEW_DATA';

export {
  DELETE_DECISION_STRATEGY_REQUEST,
  DELETE_DECISION_STRATEGY_REQUEST_SUCCESS,
  DELETE_DECISION_STRATEGY_REQUEST_FAILURE,
  GET_DECISION_STRATEGY_INFO_REQUEST,
  GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS,
  GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE,
  UPDATE_STRATEGY_NAME_REQUEST,
  UPDATE_STRATEGY_NAME_REQUEST_SUCCESS,
  UPDATE_STRATEGY_NAME_REQUEST_FAILURE,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST_SUCCESS,
  DELETE_DECISION_STRATEGY_MODULE_FAILURE,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE,
  SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_SUCCESS,
  SET_DECISION_STRATEGY_MODULE_INFO_REQUEST_FAILURE,
  ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT,
  REORDER_MODULE_BRANCHES,
  REORDER_MODULE_BRANCHES_SUCCESS,
  REORDER_MODULE_BRANCHES_FAILURE,
  COLLAPSE_ALL_STRATEGY_MODULES,
  EXPAND_STRATEGY_MODULE,
  REMOVE_COLLAPSED_STRATEGY_MODULE,
  ADD_COLLAPSED_STRATEGY_MODULE,
  REMOVE_COLLAPSED_STATE,
  SET_STRATEGY_OVERVIEW_DATA,
};

export enum StrategyArchiveStatusActionType {
  ArchiveStrategy = 'decisionStrategy/archiveStrategy',
  ArchiveStrategySuccess = 'decisionStrategy/archiveStrategySuccess',
  ArchiveStrategyFailure = 'decisionStrategy/archiveStrategyFailure',
  RestoreStrategy = 'decisionStrategy/restoreStrategy',
  RestoreStrategySuccess = 'decisionStrategy/restoreStrategySuccess',
  RestoreStrategyFailure = 'decisionStrategy/restoreStrategyFailure',
}
