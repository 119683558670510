import React from 'react';
import WrapperWithTooltip from 'components/Tooltip';
import WrapperWithHoverableTooltip from 'components/WrapperWithHoverableTooltip/WrapperWithHoverableTooltip';
import CoBorrowerInfoTooltipContent from './CoBorrowerInfoTooltipContent/CoBorrowerInfoTooltipContent';
import styles from './CoBorrowerInfo.module.scss';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export interface CoBorrowerInfoProps {
  coborrowerType: BorrowerType;
  variables: Record<string, VariableValue>;
  withHoverableTooltip?: boolean;
}

const CoBorrowerInfo = ({ coborrowerType, variables, withHoverableTooltip }: CoBorrowerInfoProps) => {
  const coborrowerName = getBorrowerFullName(coborrowerType, variables, ApplicationDefaultVariable.Coborrower);
  const coborrowerInfo = <div className={styles.info}>+1</div>;

  return withHoverableTooltip ? (
    <div onClick={(e) => e.stopPropagation()}>
      <WrapperWithHoverableTooltip
        className={styles.tooltip}
        tooltip={<CoBorrowerInfoTooltipContent coborrowerName={coborrowerName} />}
      >
        {coborrowerInfo}
      </WrapperWithHoverableTooltip>
    </div>
  ) : (
    <WrapperWithTooltip tooltip={coborrowerName}>{coborrowerInfo}</WrapperWithTooltip>
  );
};

export default CoBorrowerInfo;
