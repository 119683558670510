import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { setFilterStatuses, setSearchInputValue } from 'LoanOriginationSystemProducts/Filters/ActionCreator';
import { getProducts, setProductToDuplicate, setStatusChangeData } from 'LoanOriginationSystemProducts/ActionCreator';
import ProductsDashboardHeader from './ProductsDashboardHeader';
import ProductsList from './ProductsList';
import styles from './ProductsDashboard.module.scss';

export interface ProductsDashboardProps {
  onCreateProduct: () => void;
  onProductSelect: (product: Product) => void;
  onDownloadApiRequestTemplate: () => void;
}

const DEFAULT_PRODUCTS = new Array(8).fill(null);

const ProductsDashboard = ({
  onCreateProduct,
  onProductSelect,
  onDownloadApiRequestTemplate,
}: ProductsDashboardProps) => {
  const dispatch = useDispatch();
  const { filters, items: products } = useSelector((state: ReduxState) => state.loanOriginationSystemProducts);

  useEffect(() => {
    if (!products) {
      dispatch(getProducts(filters));
    }
  }, []);

  const handleSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const handleChangeShowArchived = () => {
    const statuses = filters.statuses.includes(ProductStatus.Archived)
      ? [ProductStatus.Active, ProductStatus.Draft]
      : [ProductStatus.Active, ProductStatus.Draft, ProductStatus.Archived];

    dispatch(setFilterStatuses(statuses));
  };

  const handleProductDuplicate = (product: Product) => {
    dispatch(setProductToDuplicate(product));
  };

  const handleChangeProductStatus = (product: Product, status: ProductStatus) => {
    dispatch(
      setStatusChangeData({
        product,
        status,
      }),
    );
  };

  const displayFilters = !products || !!products.length || !!filters.search;

  const renderBody = () => {
    if (products && !products.length) {
      return filters.search ? (
        <SearchNotFound searchValue={filters.search} />
      ) : (
        <NoItems
          className={styles.noItems}
          titleClassName={styles.noItemsTitle}
          buttonClassName={styles.noItemsButton}
          title="No products have been added yet!"
          buttonMessage="Add Product"
          onButtonClick={onCreateProduct}
        />
      );
    }

    return (
      <ProductsList
        products={products ?? DEFAULT_PRODUCTS}
        onProductSelect={onProductSelect}
        onProductDuplicate={handleProductDuplicate}
        onChangeProductStatus={handleChangeProductStatus}
      />
    );
  };

  return (
    <div className={styles.container}>
      <ProductsDashboardHeader
        search={filters.search}
        showArchived={filters.statuses.includes(ProductStatus.Archived)}
        onSearch={handleSearch}
        onAddProduct={onCreateProduct}
        onChangeShowArchived={handleChangeShowArchived}
        onDownloadApiRequestTemplate={onDownloadApiRequestTemplate}
        displayFilters={displayFilters}
      />
      {renderBody()}
    </div>
  );
};

export default ProductsDashboard;
