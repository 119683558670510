import {
  ChangeFilterStateAction,
  ChangeTimeDeltaAction,
  CloseReportingFiltersBarAction,
  GetFilterDataAction,
  GetFilterDataSuccessAction,
  OpenReportingFiltersBarAction,
  ResetFilterStateAction,
  ToggleStatusAction,
} from './ActionCreator';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { ReportingFilterBarState, ReportingTimeDeltas } from './Types';
import { getNewSelectedStatuses, getUpdatedValue } from './utils';

const initialState: ReportingFilterBarState = {
  open: false,
  intermediaries: [],
  selectedIntermediaries: [],
  teamMembers: [],
  selectedTeamMembers: [],
  statuses: [],
  selectedStatuses: [],
  products: [],
  selectedProducts: [],
  labels: [],
  selectedLabels: [],
  timeDelta: ReportingTimeDeltas.AllTime,
};

type ReportingFilterActionTypes =
  | ChangeFilterStateAction
  | GetFilterDataSuccessAction
  | GetFilterDataAction
  | ChangeTimeDeltaAction
  | ToggleStatusAction
  | OpenReportingFiltersBarAction
  | CloseReportingFiltersBarAction
  | ResetFilterStateAction;

const ReportingFilterBarReducer = (state = initialState, action: ReportingFilterActionTypes) => {
  switch (action.type) {
    case LoanOriginationSystemReportingActionType.OpenFiltersBar:
      return {
        ...state,
        open: true,
      };
    case LoanOriginationSystemReportingActionType.CloseFiltersBar:
      return {
        ...state,
        open: false,
      };
    case LoanOriginationSystemReportingActionType.ChangeFilterState: {
      const { method, entityType, value } = action.payload;
      return {
        ...state,
        [entityType]: getUpdatedValue(state[entityType], method, value),
      };
    }
    case LoanOriginationSystemReportingActionType.GetFilterDataSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case LoanOriginationSystemReportingActionType.ChangeTimeDelta:
      return {
        ...state,
        timeDelta: action.payload,
      };
    case LoanOriginationSystemReportingActionType.ToggleStatus: {
      const statusId = action.payload;
      const newSelectedStatuses = getNewSelectedStatuses(statusId, state.selectedStatuses);
      return {
        ...state,
        selectedStatuses: newSelectedStatuses,
      };
    }
    case LoanOriginationSystemReportingActionType.ResetFilterState: {
      return {
        ...state,
        selectedIntermediaries: [],
        selectedTeamMembers: [],
        selectedStatuses: [],
        selectedProducts: [],
        selectedLabels: [],
        timeDelta: ReportingTimeDeltas.AllTime,
      };
    }
    default: {
      return state;
    }
  }
};

export default ReportingFilterBarReducer;
