import React from 'react';
import LogoLoader from 'components/LogoLoader';
import styles from './Loading.module.scss';

const Loading = () => {
  return (
    <div className={styles.container}>
      <LogoLoader />
    </div>
  );
};

export default Loading;
