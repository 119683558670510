import { LoanOriginationSystemBorrowersFiltersActionTypes } from './ActionTypes';
import { AnyAction } from 'redux';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface FiltersResult {
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  isPerson: boolean;
  isCompany: boolean;
}

export interface SetSearchInputValueAction extends AnyAction {
  type: LoanOriginationSystemBorrowersFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchValue: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemBorrowersFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface FilterBorrowersByMembersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers;
  payload: {
    members: UserInfo[];
  };
}

export interface ResetAllBorrowersFilters extends AnyAction {
  type: LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters;
}

export const setSearchInputValue = (searchValue: string): SetSearchInputValueAction => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchValue,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.ChangePopupFilters,
    payload: {
      dueCreatedDateRange: {
        from: null,
        to: null,
      },
      dueUpdatedDateRange: {
        from: null,
        to: null,
      },
      isPerson: false,
      isCompany: false,
    },
  };
};

export const filterBorrowersByMembers = (members: UserInfo[]): FilterBorrowersByMembersAction => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers,
    payload: {
      members,
    },
  };
};

export const resetAllBorrowersFilters = (): ResetAllBorrowersFilters => {
  return {
    type: LoanOriginationSystemBorrowersFiltersActionTypes.ResetAllFilters,
  };
};
