import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Variables from 'components/Variables';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import pagination, { VariablesPaginationParams } from './Pagination';
import {
  getVariablesRequest,
  editVariableRequest,
  createVariableRequest,
  CreateVariableActionOrigin,
  sortVariables,
  setShowArchived,
} from 'Variables/VariablesActionCreator';
import { openVariableStatusChangePopup } from 'VariableStatusChangePopup/Actions';
import { VariableClientType, VariablesState } from 'Variables/VariablesTypes';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from 'hooks/useQueryParam';
import { EditVariable, AddVariable } from 'components/Variable';
import { getVariableType } from 'Variables/utils';
import { ReduxState } from 'types/redux';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import VariableStatusChangePopup from 'VariableStatusChangePopup/VariableStatusChangePopup';
import { VariableStatusChangePopupState } from 'VariableStatusChangePopup/Types';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import VariableNotFound from 'components/Variable/VariableNotFound';
import useStateReset from 'hooks/useStateReset';
import { VariablesActionType } from 'Variables/ActionTypes';
import { VariablesSortingField } from 'api/Core/VariablesApi';
import { setSearchInputValue } from 'Variables/Filters/ActionCreator';
import VariablesFilter from 'components/Variables/VariablesFilter';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';

const VariablesPage = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const { regionSettings } = useOrganizationDetailedInfo();
  const history = useHistory();
  const params = useQueryParams();
  const currentVariableId = params.get('edit');
  const onCloseVariablePage = useCloseContextualView();

  const userState = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);
  const { isLoading, sortingType, filters, showArchived } = useSelector<ReduxState, VariablesState>(
    ({ variables }) => variables,
  );

  useEffect(() => {
    dispatch(getVariablesRequest(filters, sortingType));
  }, []);

  const paginationParams = {
    searchInputValue: filters.searchInputValue,
    sortingType,
    dueCreatedDateRange: filters.dueCreatedDateRange,
    dueUpdatedDateRange: filters.dueUpdatedDateRange,
    onlyCustom: filters.onlyCustom,
    onlyStandard: filters.onlyStandard,
    dataType: filters.dataType,
    showArchived,
  } as VariablesPaginationParams;

  const variableList = pagination.usePaginatedItems(paginationParams);
  const paginationProps = pagination.usePagination(paginationParams);

  const handleSearchInput = (value: string) => dispatch(setSearchInputValue(value));
  const handleOpenVariableStatusChangePopup = (id: string) => dispatch(openVariableStatusChangePopup(id));
  const onAddNewVariable = () => history.replace('all?new');
  const onEditVariable = (variableId: string) => history.replace(`all?edit=${variableId}`);
  const { isOpen: isOpenVariableStatusChangePopup }: VariableStatusChangePopupState = useSelector(
    (state: ReduxState) => state.variableStatusChangePopup,
  );

  useStateReset(VariablesActionType.ResetVariablesState);

  if (!userState) {
    return null;
  }

  const onSort = (field: VariablesSortingField, ascending: boolean) => {
    dispatch(sortVariables(field, ascending));
  };

  const handleSetShowArchived = (enabled: boolean) => {
    dispatch(setShowArchived(enabled));
  };

  const getContextualViewPage = () => {
    if (params.has('new')) {
      const onCreateVariable = async (variableData: VariableClientType) => {
        const simpleVariableData = getVariableType(variableData);
        const formattedVariable = { ...variableData, ...simpleVariableData };
        await dispatchRoutine(
          setActionOrigin(createVariableRequest(formattedVariable), CreateVariableActionOrigin.Default),
        );
        onCloseVariablePage();
      };
      return <AddVariable onClose={onCloseVariablePage} onSave={onCreateVariable} regionSettings={regionSettings} />;
    }
    if (params.has('edit')) {
      const currentVariable = variableList && variableList.find((variable) => variable?.id === currentVariableId);

      const onSaveVariable = async (variableData: VariableClientType) => {
        const simpleVariableData = getVariableType(variableData);
        await dispatchRoutine(editVariableRequest({ ...variableData, ...simpleVariableData }));
        onCloseVariablePage();
      };
      if (currentVariable) {
        return (
          <EditVariable
            regionSettings={regionSettings}
            onClose={onCloseVariablePage}
            variable={currentVariable}
            onSaveVariable={onSaveVariable}
          />
        );
      }
      if (!currentVariable && !isLoading) return <VariableNotFound onClose={onCloseVariablePage} />;
    }
    return null;
  };

  const getOverlay = (): ReactElement | null => {
    if (isOpenVariableStatusChangePopup) {
      return <VariableStatusChangePopup />;
    }
    return null;
  };

  const getFilterVariableType = () => {
    if (filters.onlyStandard) {
      return 'standard';
    }
    if (filters.onlyCustom) {
      return 'custom';
    }
    return '';
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <VariablesFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      variableDataType={filters.dataType}
      type={getFilterVariableType()}
    />
  );

  const leftNav = makeLeftNavigation(NavigationLinkId.Variables, ApplicationSectionName.CompanySettings);

  return (
    <MainLayout
      leftNav={leftNav}
      contextualView={getContextualViewPage()}
      closeContextualView={onCloseVariablePage}
      overlay={getOverlay()}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <Variables
            variables={variableList}
            onAddNewVariable={onAddNewVariable}
            onEditVariable={onEditVariable}
            openVariableStatusChangePopup={handleOpenVariableStatusChangePopup}
            paginationProps={paginationProps}
            onSearchInputChange={handleSearchInput}
            searchInputValue={filters.searchInputValue}
            empty={paginationProps.itemsTotal === 0}
            currentVariableId={currentVariableId}
            sortingType={sortingType}
            onSort={onSort}
            filters={filters}
            showArchived={showArchived}
            setShowArchived={handleSetShowArchived}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default VariablesPage;
