import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  BulkUpdateBorrowerVariableConfigurationsAction,
  BulkUpdateBorrowerVariableConfigurationsSuccessAction,
  BulkUpdateIntermediaryVariableConfigurationsAction,
  BulkUpdateIntermediaryVariableConfigurationsSuccessAction,
  BulkUpdateVariableConfigurationsActionOrigin,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { VariablesConfigurationActionType } from 'LoanOriginationSystemVariablesConfiguration/ActionTypes';
import {
  UpdateProductAction,
  UpdateProductActionOrigin,
  UpdateProductSuccessAction,
} from 'LoanOriginationSystemProducts/ActionCreator';
import {
  BulkUpdateApplicationVariableConfigurationsAction,
  BulkUpdateApplicationVariableConfigurationsSuccessAction,
} from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import { ApplicationFormConfigurationState } from './Types';
import {
  SetFormPageToDeleteAction,
  SetPageToEditRequiredFieldsAction,
  ToggleFormSettingsPopupVisibilityAction,
} from './ActionCreator';
import { ApplicationFormConfigurationActionType } from './ActionTypes';

export const initialState: ApplicationFormConfigurationState = {
  pageToEditRequiredFields: null,
  isRequiredVariablesEditInProgress: false,
  isFormSettingsPopupOpen: false,
  isFormSettingUpdateInProgress: false,
  isFormPageDeleteInProgress: false,
  formPageToDelete: null,
};

type ApplicationFormConfigurationActions =
  | SetFormPageToDeleteAction
  | SetPageToEditRequiredFieldsAction
  | BulkUpdateIntermediaryVariableConfigurationsAction
  | BulkUpdateIntermediaryVariableConfigurationsSuccessAction
  | ToggleFormSettingsPopupVisibilityAction
  | UpdateProductAction
  | UpdateProductSuccessAction
  | FailedAction<LoanOriginationSystemProductsActionType.UpdateProductFailure, UpdateProductActionOrigin>
  | BulkUpdateBorrowerVariableConfigurationsAction
  | BulkUpdateBorrowerVariableConfigurationsSuccessAction
  | BulkUpdateApplicationVariableConfigurationsAction
  | BulkUpdateApplicationVariableConfigurationsSuccessAction
  | FailedAction<
      VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsFailure,
      BulkUpdateVariableConfigurationsActionOrigin
    >
  | FailedAction<
      VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsFailure,
      BulkUpdateVariableConfigurationsActionOrigin
    >
  | FailedAction<
      VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsFailure,
      BulkUpdateVariableConfigurationsActionOrigin
    >;

const applicationFormConfigurationReducer = (state = initialState, action: ApplicationFormConfigurationActions) => {
  switch (action.type) {
    case ApplicationFormConfigurationActionType.SetPageForEditRequiredFields: {
      return {
        ...state,
        pageToEditRequiredFields: action.payload.page,
      };
    }
    case ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility: {
      return {
        ...state,
        isFormSettingsPopupOpen: !state.isFormSettingsPopupOpen,
      };
    }
    case VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurations:
    case VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurations:
    case VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurations: {
      if (action.meta?.actionOrigin !== BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup) {
        return state;
      }

      return {
        ...state,
        isRequiredVariablesEditInProgress: true,
      };
    }
    case VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsSuccess:
    case VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsSuccess:
    case VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsSuccess: {
      if (action.meta?.actionOrigin !== BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup) {
        return state;
      }

      return {
        ...state,
        pageToEditRequiredFields: null,
        isRequiredVariablesEditInProgress: false,
      };
    }
    case VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsFailure:
    case VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsFailure:
    case VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsFailure: {
      if (action.meta?.actionOrigin !== BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup) {
        return state;
      }

      return {
        ...state,
        isRequiredVariablesEditInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.UpdateProduct: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: true,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: true,
        };
      }

      return state;
    }
    case LoanOriginationSystemProductsActionType.UpdateProductSuccess: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: false,
          isFormSettingsPopupOpen: false,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: false,
          formPageToDelete: null,
        };
      }

      return state;
    }
    case LoanOriginationSystemProductsActionType.UpdateProductFailure: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          isFormSettingUpdateInProgress: false,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage) {
        return {
          ...state,
          isFormPageDeleteInProgress: false,
        };
      }

      return state;
    }
    case ApplicationFormConfigurationActionType.SetFormPageToDelete: {
      return {
        ...state,
        formPageToDelete: action.payload.formPage,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationFormConfigurationReducer;
