import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { ReportingData } from './Types';
import { GetReportingDataAction, getReportingDataFailure, getReportingDataSuccess } from './ActionsCreator';
import { LoanOriginationSystemApplicationReportingApi } from 'api/LoanOriginationSystem/LoanOriginationSystemReportingApi';

const GetReportingDataMiddleware = (api: LoanOriginationSystemApplicationReportingApi) =>
  Fetcher<ReportingData, GetReportingDataAction>(
    LoanOriginationSystemReportingActionType.GetReportingData,
    getReportingDataSuccess,
    getReportingDataFailure,
    async ({ payload }) => {
      return api.getReportingData(payload);
    },
  );

export default GetReportingDataMiddleware;
