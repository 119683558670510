import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { closeDuplicateStrategyWizard } from 'DuplicateStrategyWizard/Actions';
import DuplicateStrategyPopup from 'components/DuplicateStrategyPopUp';
import { duplicateStrategy } from 'Strategies/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const DuplicateStrategyWizard: FC = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const {
    isOpen,
    strategyId,
    strategyName,
    actionOrigin,
  } = useSelector((state: ReduxState) => state.duplicateStrategyWizard);
  if (!isOpen) {
    return null;
  }

  const handleClose = () => dispatch(closeDuplicateStrategyWizard());
  const handleDuplicate = async () => {
    await dispatchRoutine(duplicateStrategy(strategyId, actionOrigin!));
    dispatch(closeDuplicateStrategyWizard());
  }

  return (
    <DuplicateStrategyPopup
      onDuplicateStrategy={handleDuplicate}
      onClose={handleClose}
      name={strategyName}
    />
  );
};

export default DuplicateStrategyWizard;
