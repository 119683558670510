import React, { FC, useEffect, useRef, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import NewRulePopUp from 'components/NewRulePopUp';
import UploadFromFile from 'components/AddRuleDialog/UploadFromFile';
import AddCalculationScript from 'components/CalculationScripts/PopUps/AddCalculationScript/AddCalculationScript';
import AddSimpleOutputs from 'components/SimpleOutputs/PopUps/AddSimpleOutputs';
import getOptionsByModuleType from './constants';
import { EntityType, SimpleOutputRequestData, CalculationScriptData, RulePopUpType, RuleRequestData } from './Types';
import { BranchItemInfo } from 'api/Types';
import RuleBuilder from 'components/RuleBuilder';
import ImportEntireRuleset from 'components/AddRuleDialog/ImportEntireRuleset';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { scrollTo } from 'utils/scrollTo';

const WARNING_TEXT_RULES_REPLACE = 'This will replace all current rules and cannot be reversed.';

interface AddRulePopUpProps {
  moduleType: ModuleType;
  type: RulePopUpType | null;
  entityType: EntityType | null;
  closePopUp: () => void;
  onSaveFile: (file: File) => void;
  createSimpleOutput: (data: SimpleOutputRequestData) => void;
  createCalculationScript: (data: CalculationScriptData) => void;
  downloadTemplate: () => void;
  onContinue: (type: string) => void;
  createRule: (data: RuleRequestData) => void;
  isDuplicatingBranch: boolean;
  branchOptions: BranchItemInfo[] | null;
  getBranchOptions: () => void;
  duplicateBranch: (branchOption: BranchItemInfo) => void;
  openVariableCreation: () => void;
  isLoading?: boolean;
}

const getTitleByModuleType = (moduleType: ModuleType) => {
  switch (moduleType) {
    case 'requirements':
      return 'Add Requirements Rule';
    case 'output':
      return 'Add Rule-Based Output';
    case 'calculations':
      return 'Add Calculation Script';
    case 'scorecard':
      return 'Add Scoring Rule';
    case 'assignments':
      return 'Add Output';
    default:
      return '';
  }
};

const AddRulePopUp: FC<AddRulePopUpProps> = ({
  moduleType,
  type,
  isDuplicatingBranch,
  branchOptions,
  getBranchOptions,
  duplicateBranch,
  entityType,
  closePopUp,
  onContinue,
  onSaveFile,
  createSimpleOutput,
  createCalculationScript,
  downloadTemplate,
  createRule,
  openVariableCreation,
  isLoading,
}) => {
  const getTitleByType = () => {
    if (entityType === 'branch') {
      return 'Add Branch Condition';
    }

    switch (type) {
      case 'upload':
        return 'Upload Entire Rule Set';
      case 'import':
        return 'Import Entire Rule Set';
      default:
        return getTitleByModuleType(moduleType);
    }
  };
  const anchorRef = useRef(null);

  const [offset, setOffset] = useState(0);
  const getContent = () => {
    if (type === 'simple' || type === 'OR' || type === 'AND') {
      return (
        <RuleBuilder
          saveRule={createRule}
          type={type}
          withoutDeclineReason={entityType === 'branch'}
          multipleOutputs={moduleType === 'output'}
          moduleType={moduleType}
          openVariableCreation={openVariableCreation}
          ref={anchorRef}
          handleSetOffset={(currentOffset: number) => setOffset(currentOffset)}
          isSaving={isLoading}
        />
      );
    }
    if (type === 'upload') {
      return <UploadFromFile downloadTemplate={downloadTemplate} onSaveFile={onSaveFile} />;
    }
    if (type === 'addScript') {
      return (
        <AddCalculationScript
          saveCalculationScript={createCalculationScript}
          openVariableCreation={openVariableCreation}
          isSaving={isLoading}
        />
      );
    }
    if (type === 'addOutput') {
      return (
        <AddSimpleOutputs
          saveSimpleOutput={createSimpleOutput}
          openVariableCreation={openVariableCreation}
          isSaving={isLoading}
        />
      );
    }
    if (type === 'import') {
      return (
        <ImportEntireRuleset
          isDuplicating={isDuplicatingBranch}
          branchOptions={branchOptions}
          getBranchOptions={getBranchOptions}
          onSubmit={duplicateBranch}
        />
      );
    }
    const currentModuleType = entityType === 'branch' ? undefined : moduleType;
    return <NewRulePopUp onContinue={onContinue} options={getOptionsByModuleType(currentModuleType)} />;
  };

  const popupRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (popupRef && popupRef.current) {
      const pos = popupRef.current.scrollTop;
      scrollTo(offset, popupRef, pos);
    }
  }, [offset]);

  const getWarningText = () => {
    if (type === 'import' || type === 'upload') {
      return WARNING_TEXT_RULES_REPLACE;
    }
    return '';
  };

  return (
    <PopUp title={getTitleByType()} closePopUp={closePopUp} ref={popupRef} warningText={getWarningText()}>
      {getContent()}
    </PopUp>
  );
};

export default AddRulePopUp;
