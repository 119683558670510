import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { GetProductAction, getProductFailure, getProductSuccess } from './ActionCreator';

const GetProductMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product, GetProductAction>(
    LoanOriginationSystemProductsActionType.GetProduct,
    getProductSuccess,
    getProductFailure,
    (action) => {
      return api.getById(action.payload.productId);
    },
  );

export default GetProductMiddleware;
