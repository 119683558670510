import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemDeclineReasonsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemDeclineReasonsApi';
import { UpdateDeclineReasonsAction, updateDeclineReasonsFailure, updateDeclineReasonsSuccess } from './ActionCreator';
import { DeclineReasonsActionType } from './ActionTypes';
import { DeclineReasonByProduct } from './Types';

const UpdateDeclineReasonsMiddleware = (api: LoanOriginationSystemDeclineReasonsApi) =>
  Fetcher<DeclineReasonByProduct, UpdateDeclineReasonsAction>(
    DeclineReasonsActionType.UpdateDeclineReasons,
    updateDeclineReasonsSuccess,
    updateDeclineReasonsFailure,
    async (action) => {
      const declineReasons = await api.update(action.payload.productId, action.payload.declineReasons);

      return {
        productId: action.payload.productId,
        declineReasons,
      };
    },
  );

export default UpdateDeclineReasonsMiddleware;
