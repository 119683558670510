import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

export const getCardIdsByTabId = (state: ReduxState, tabId: string) => {
  return state.applicationDataTabCards.cardsByTabId[tabId];
};

export const getCardsById = (state: ReduxState) => {
  return state.applicationDataTabCards.cardsById;
};

export const getApplicationDataTabVariableConfigurationsById = (state: ReduxState) => {
  return state.applicationDataTabVariableConfigurations.variableConfigurationsById;
};

export const getCardsByTabId = createSelector(
  [getCardIdsByTabId, getCardsById, getApplicationDataTabVariableConfigurationsById],
  (cardsIds, cardsById, applicationDataTabVariablesConfigurationsById) => {
    if (!cardsIds) {
      return null;
    }

    return cardsIds.map((id) => ({
      ...cardsById[id],
      fields: cardsById[id].fields.map((fieldId) => applicationDataTabVariablesConfigurationsById[fieldId]),
    }));
  },
);
