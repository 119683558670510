import React, { FC, useState } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';

interface DeleteApplicationNotePopUpProps {
  deleteApplicationNote: () => Promise<unknown>;
  closePopUp: () => void;
}

const DeleteApplicationNotePopUp: FC<DeleteApplicationNotePopUpProps> = (props) => {
  const { closePopUp, deleteApplicationNote } = props;
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);

  const handleDelete = async () => {
    setIsBlockingActionInProgress(true);
    await deleteApplicationNote();
    setIsBlockingActionInProgress(false);
  };

  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title="Delete Note" closable={!isBlockingActionInProgress}>
      <DeletePopup
        isLoading={isBlockingActionInProgress}
        handleDelete={handleDelete}
        message="Are you sure you want to delete this note?"
        handleClose={closePopUp}
        cancelBtnDisabled={isBlockingActionInProgress}
        deleteBtnText="Delete Note"
      />
    </PopUpWizard>
  );
};

export default DeleteApplicationNotePopUp;
