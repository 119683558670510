import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  Borrower,
  LoanOriginationSystemBorrowersApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemBorrowerDetailsActionType } from './ActionTypes';
import { getBorrowerFailure, getBorrowerSuccess, GetBorrowerAction } from './ActionCreator';

const GetBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower, GetBorrowerAction, ReduxState>(
    LoanOriginationSystemBorrowerDetailsActionType.GetBorrower,
    getBorrowerSuccess,
    getBorrowerFailure,
    async (action) => {
      return api.getBorrowerById(action.payload);
    },
  );

export default GetBorrowerMiddleware;
