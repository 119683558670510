enum AccountDetailsActionType {
  GetAccountDetails = 'accountDetails/getAccountDetails',
  GetAccountDetailsSuccess = 'accountDetails/getAccountDetailsSuccess',
  GetAccountDetailsFailure = 'accountDetails/getAccountDetailsFailure',

  UpdateAccountDetails = 'accountDetails/updateAccountDetails',
  UpdateAccountDetailsSuccess = 'accountDetails/updateAccountDetailsSuccess',
  UpdateAccountDetailsFailure = 'accountDetails/updateAccountDetailsFailure',

  ChangeAccountPhoto = 'accountDetails/changeAccountPhoto',
  ChangeAccountPhotoSuccess = 'accountDetails/changeAccountPhotoSuccess',
  ChangeAccountPhotoFailure = 'accountDetails/changeAccountPhotoFailure',

  ConfirmPassword = 'accountDetails/confirmPassword',
  ConfirmPasswordSuccess = 'accountDetails/confirmPasswordSuccess',
  ConfirmPasswordFailure = 'accountDetails/confirmPasswordFailure',

  ChangeEmail = 'accountDetails/changeEmail',
  ChangeEmailSuccess = 'accountDetails/changeEmailSuccess',
  ChangeEmailFailure = 'accountDetails/changeEmailFailure',

  DisablePhoneAuthentication = 'accountDetails/disablePhoneAuthentication',
  DisablePhoneAuthenticationSuccess = 'accountDetails/disablePhoneAuthenticationSuccess',
  DisablePhoneAuthenticationFailure = 'accountDetails/disablePhoneAuthenticationFailure',

  EnablePhoneAuthentication = 'accountDetails/enablePhoneAuthentication',
  EnablePhoneAuthenticationSuccess = 'accountDetails/enablePhoneAuthenticationSuccess',
  EnablePhoneAuthenticationFailure = 'accountDetails/enablePhoneAuthenticationFailure',

  RequestPhoneAuthentication = 'accountDetails/requestPhoneAuthentication',
  RequestPhoneAuthenticationSuccess = 'accountDetails/requestPhoneAuthenticationSuccess',
  RequestPhoneAuthenticationFailure = 'accountDetails/requestPhoneAuthenticationFailure',

  ConfirmAccountEmail = 'accountDetails/confirmAccountEmail',
  ConfirmAccountEmailSuccess = 'accountDetails/confirmAccountEmailSuccess',
  ConfirmAccountEmailFailure = 'accountDetails/confirmAccountEmailFailure',

  ResendEmailVerificationForExpiredToken = 'accountDetails/resendEmailVerificationForExpiredToken',
}

export default AccountDetailsActionType;
