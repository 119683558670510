import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import CompanyInformation from 'components/CompanyInformation';
import { updateOrganizationInfoRequest } from 'CompanyInformation/CompanyInformationActionCreator';
import { UpdateOrganizationInfoParams } from 'CompanyInformation/CompanyInformationTypes';
import MainLayout, { PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageContent from 'MainLayout/PageContent';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import CompanyLogoUploadDialog from 'CompanyLogoUpload/CompanyLogoUploadDialog';
import { openCompanyLogoUploadDialog } from 'CompanyLogoUpload/Actions';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import useOrganizationMembers from 'hooks/useOrganizationMembers';

const CompanyInformationPage = () => {
  const companyLogoUploadDialog = useSelector((state: ReduxState) => state.companyLogoUploadDialog);

  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const organizationInfo = useOrganizationDetailedInfo();
  const organizationUsers = useOrganizationMembers();

  const changeCompanyInfo = (changeCompanyCredentials: UpdateOrganizationInfoParams) =>
    dispatchRoutine(updateOrganizationInfoRequest(changeCompanyCredentials));

  const leftNav = makeLeftNavigation(NavigationLinkId.CompanyInformation, ApplicationSectionName.CompanySettings);

  const getOverlay = (): ReactElement | null => {
    if (companyLogoUploadDialog.isOpen) {
      return <CompanyLogoUploadDialog isLoading={companyLogoUploadDialog.isLoading} />;
    }
    return null;
  };

  const handleOpenCompanyLogoUploadDialog = () => {
    dispatch(openCompanyLogoUploadDialog());
  };

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <CompanyInformation
            organizationInfo={organizationInfo}
            organizationUsers={organizationUsers}
            changeCompanyInfo={changeCompanyInfo}
            onOpenUploadCompanyLogoDialog={handleOpenCompanyLogoUploadDialog}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default CompanyInformationPage;
