import { DEFAULT_DATE_FORMAT } from 'components/DatePicker/DatePicker';
import moment from 'moment';

const getCustomDateFormat = (variableValue: string, dateFormat: string) => {
  const UTCFormatDate = moment(variableValue.toString(), DEFAULT_DATE_FORMAT).toDate();
  const normalizedDate = moment(UTCFormatDate).format(dateFormat);
  return normalizedDate;
};

export default getCustomDateFormat;
