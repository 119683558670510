import { Option } from 'components/SelectInput/SelectInput';

const PRODUCT_TEMPLATES: Option[] = [
  { name: 'Auto Loan', value: 'autoLoan' },
  { name: 'Commercial Loan', value: 'commercialLoan' },
  { name: 'Credit Card', value: 'creditCard' },
  { name: 'Home Equity Loan', value: 'homeEquityLoan' },
  { name: 'Home Improvement Loan', value: 'homeImprovementLoan' },
  { name: 'Mortgage', value: 'mortgage' },
  { name: 'Personal Loan', value: 'personalLoan' },
  { name: 'Point-Of-Sale Financing', value: 'pointOfSaleFinancing' },
  { name: 'Small Business Loan', value: 'smallBusinessLoan' },
];

export default PRODUCT_TEMPLATES;
