import { AnyAction, Middleware } from 'redux';

import { VariablesActionType } from './ActionTypes';

import notification from 'handlers/notification/notificationActionCreator';
import { ChangeVariableStatusSuccessAction } from './VariablesActionCreator';
import getMessage from 'constants/messages';
import { RoutineHandler } from 'middlewares/Fetcher';
import variablesPagination from 'pages/Variables/Pagination';
import { ReduxState } from 'types/redux';

const isChangeVariableStatusSuccessAction = (action: AnyAction): action is ChangeVariableStatusSuccessAction =>
  action.type === VariablesActionType.ChangeVariableStatusSuccess;

export const onChangeVariableStatusSuccessMiddleware: Middleware = RoutineHandler<ReduxState>(
  async (action: AnyAction, { getState, dispatch }) => {
    if (!isChangeVariableStatusSuccessAction(action)) {
      return;
    }
    const { variableName, messageType, isError } = action.payload;
    notification.createNotification(getMessage(messageType, { variableName }), isError ? 'error' : 'success', dispatch);
    if (!isError) {
      const { variables } = getState();
      const { filters, sortingType, showArchived } = variables;
      dispatch(variablesPagination.reloadCurrentPage({ ...filters, sortingType, showArchived }));
    }
  },
);

export default onChangeVariableStatusSuccessMiddleware;
