export enum ApplicationsWorkflowSetupActionType {
  SetApplicationStatusToDelete = 'applicationsWorkflowSetup/setApplicationStatusToDelete',
  OpenDeclineReasonsPopup = 'applicationsWorkflowSetup/openDeclineReasonsPopup',
  CloseDeclineReasonsPopup = 'applicationsWorkflowSetup/closeDeclineReasonsPopup',
  SetStatusToAddRule = 'applicationsWorkflowSetup/setStatusToAddRule',
  SetStatusRuleToEdit = 'applicationsWorkflowSetup/setStatusRuleToEdit',
  SetDeleteStatusRuleData = 'applicationsWorkflowSetup/SetDeleteStatusRuleData',
  OpenCreateVariablePopup = 'applicationsWorkflowSetup/openCreateVariablePopup',
  CloseCreateVariablePopup = 'applicationsWorkflowSetup/closeCreateVariablePopup',
}
