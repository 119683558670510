import { Option } from 'components/SelectInput/SelectInput';

export const TEXT_DATA_TYPES: Option[] = [
  { value: 'List', name: 'List', description: 'List of pre-defined options where only one can be selected' },
  { value: 'Text', name: 'Text', description: 'Any combination of letters, numbers and special characters' },
];

export const NUMBER_DATA_TYPES: Option[] = [
  { value: 'Number', name: 'Number', description: 'For simple numerical data, including integers and decimals' },
  {
    value: 'Monetary',
    name: 'Monetary ($)',
    description: 'For numerical data that should be displayed in monetary format',
  },
  {
    value: 'Percentage',
    name: 'Percentage (%)',
    description: 'For numerical data that should be displayed in percentage format',
  },
];

export const VARIABLE_DATA_TYPES: Option[] = [
  ...NUMBER_DATA_TYPES,
  ...TEXT_DATA_TYPES,
  { value: 'Boolean', name: 'Boolean', description: 'For data that is either true or false' },
  { value: 'Date', name: 'Date', description: 'For data that represents a date' },
  {
    value: 'PhoneNumber',
    name: 'Phone Number',
    description: 'For data that represents a phone number',
  },
  { value: 'EmailAddress', name: 'Email Address', description: 'For data that represents an email address' },
  {
    value: 'IdentificationNumber',
    name: 'Identification Number',
    description: 'For data that represents an identification number (e.g. Passport Number, SSN, etc.)',
  },
];

export const IDENTIFICATION_NUMBER_TYPES: Option[] = [
  { value: 'USSocial', name: 'U.S. Social Security Number (###-##-####)', description: 'For United States SSNs (9 numbers formatted ###-##-####)' },
  { value: 'USEmployer', name: 'U.S. Employer Identification Number (##-#######)', description: 'For United States EINs (9 numbers formatted ##-#######)' },
  { value: 'CanadaSocial', name: 'Canada Social Insurance Number (###-###-###)', description: 'For Canadian SINs (9 numbers formatted ###-###-###)' },
  { value: 'Other', name: 'Other', description: 'For all other types of identification numbers' }
]

export const DATE_FORMATS: Option[] = [
  {
    value: 'MM/DD/YYYY',
    name: 'MM/DD/YYYY',
    description: 'Dates displayed in the format MM/DD/YYYY'
  },
  {
    value: 'DD/MM/YYYY',
    name: 'DD/MM/YYYY',
    description: 'Dates displayed in the format DD/MM/YYYY'
  },
  {
    value: 'MM/DD/YY',
    name: 'MM/DD/YY',
    description: 'Dates displayed in the format MM/DD/YY'
  },
  {
    value: 'DD/MM/YY',
    name: 'DD/MM/YY',
    description: 'Dates displayed in the format DD/MM/YY'
  },
  {
    value: 'MM/YYYY',
    name: 'MM/YYYY',
    description: 'Dates displayed in the format MM/YYYY'
  },
  {
    value: 'MM/YY',
    name: 'MM/YY',
    description: 'Dates displayed in the format MM/YY'
  }
]
