import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ProcessAutomationActionType } from './ActionTypes';
import { ChangeActivationStatusSuccessAction } from './ActionCreator';
import automatedProcessPagination from 'components/LoanOriginationSystem/ProcessAutomationDashboard/Pagination';

const isChangeActivationStatusSuccessAction = (action: AnyAction): action is ChangeActivationStatusSuccessAction =>
  action.type === ProcessAutomationActionType.ChangeActivationStatusSuccess;

const ChangeActivationStatusSuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangeActivationStatusSuccessAction(action)) {
    const { active } = action.payload.automatedProcess;

    const messageType = active
      ? MessageType.AutomatedProcessActivated
      : MessageType.AutomatedProcessDeactivated;

    notification.createNotification(getMessage(messageType), 'success', dispatch);

    const { loanOriginationSystemAutomatedProcesses } = getState();
    const { group, category } = loanOriginationSystemAutomatedProcesses;

    dispatch(automatedProcessPagination.reloadCurrentPage({ group, category }));
  }

  return result;
};

export default ChangeActivationStatusSuccessMiddleware;
