enum BillingPageActionType {
  GetBillingInfo = 'billingPage/getBillingInfo',
  GetBillingInfoSuccess = 'billingPage/getBillingInfoSuccess',
  GetBillingInfoFailed = 'billingPage/getBillingInfoFailed',
  GetInvoices = 'billingPage/getInvoices',
  GetInvoicesSuccess = 'billingPage/getInvoicesSuccess',
  GetInvoicesFailed = 'billingPage/getInvoicesFailed',
  GetApplicationsCount = 'billingPage/getApplicationsCount',
  GetApplicationsCountSuccess = 'billingPage/getApplicationsCountSuccess',
  GetApplicationsCountFailed = 'billingPage/getApplicationsCountFailed',
  GetDecisionsCount = 'billingPage/getDecisionsCount',
  GetDecisionsCountSuccess = 'billingPage/getDecisionsCountSuccess',
  GetDecisionsCountFailed = 'billingPage/getDecisionsCountFailed',
}

export default BillingPageActionType;
