import React, { FC, useState, FocusEvent, useEffect } from 'react';
import TextInput from 'components/TextInput/TextInput';
import LimitDecimalNumbers from './utils';
import useMask from 'hooks/useMask';
import maskNumberValue from 'utils/masks/maskNumberValue';
import { removeNonNumericChars } from 'components/NumberInput/utils';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import styles from './MonetaryInput.module.scss';

interface MonetaryInputProps {
  value: string;
  labelTitle: string;
  onChange: (value: string) => void;
  placeholder?: string;
  currencySymbol?: string;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
}

const formatMonetaryValue = LimitDecimalNumbers(2);

const MonetaryInput: FC<MonetaryInputProps> = ({
  value,
  labelTitle,
  onChange,
  onFocus,
  disabled,
  onBlur,
  placeholder,
  currencySymbol = '',
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
}) => {
  const [inputValue, setInputValue] = useState(formatMonetaryValue(value));

  useEffect(() => {
    const monetaryValue = formatMonetaryValue(value);
    setInputValue(monetaryValue);
  }, [value]);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    onChange(inputValue);
    onBlur?.(event);
  };

  const handleChange = (val: string) => {
    setInputValue(formatMonetaryValue(val));
    onChange(val);
  };

  const [maskedValue, inputRef, handleInputChange, handleKeyDown, handleKeyUp, handleClick] = useMask(
    inputValue,
    (val: string) => handleChange(formatMonetaryValue(val)),
    maskNumberValue,
    removeNonNumericChars,
    (char: string) => char === ',',
  );

  return (
    <div className={styles.container}>
      <div className={styles.dataTypeContainer}>
        <span className={styles.symbol}>{currencySymbol}</span>
      </div>
      <TextInput
        value={maskedValue}
        labelTitle={labelTitle}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onClick={handleClick}
        inputRef={inputRef}
        disabled={disabled}
        hasLeftPadding
        hasRightNeighbour
        placeholder={placeholder}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        tabIndex={tabIndex}
      />
    </div>
  );
};

export default MonetaryInput;
