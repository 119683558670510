import { AnyAction } from 'redux';

import { GenerateSecondSecretWizardState } from './generateSecondSecretWizardTypes';

import {
  OPEN_GENERATE_SECOND_SECRET_WIZARD,
  CLOSE_GENERATE_SECOND_SECRET_WIZARD,
  NEXT_STEP_GENERATE_SECOND_SECRET,
} from './constants';

const initialState: GenerateSecondSecretWizardState = {
  currentStepIndex: 0,
  isOpen: false,
};

const generateSecondSecretWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_GENERATE_SECOND_SECRET_WIZARD:
      return { isOpen: true, currentStepIndex: state.currentStepIndex };
    case CLOSE_GENERATE_SECOND_SECRET_WIZARD:
      return { isOpen: false, currentStepIndex: 0 };
    case NEXT_STEP_GENERATE_SECOND_SECRET: {
      return { isOpen: state.isOpen, currentStepIndex: state.currentStepIndex + 1 };
    }
    default:
      return state;
  }
};

export default generateSecondSecretWizardReducer;
