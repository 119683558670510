import React, { FC } from 'react';
import styles from './EmailView.module.scss';
import { CloseButtonImage } from 'static/images';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import clsx from 'clsx';

interface VariableNotFoundTypes {
  onClose?: () => void;
  className?: string;
}

const EmailNotFound: FC<VariableNotFoundTypes> = ({ onClose, className }) => {
  return (
    <div className={clsx(styles.notFoundContainer, className)}>
      <div className={styles.notFoundHeader}>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <ErrorPageInfo errorType={ErrorPageType.NotFoundContextualView} errorTitle="The page was not found." />
    </div>
  );
};

export default EmailNotFound;
