import React from 'react';
import { Email } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { EmailsColumnSizes } from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable/TableColumnSizes';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import { AttachmentsImage } from 'static/images';
import { sendReplyEmail } from 'components/LoanOriginationSystem/EmailsDashboard/utils/sendReplyEmail';
import { forwardEmail } from 'components/LoanOriginationSystem/EmailsDashboard/utils/forwardEmail';
import { parseEmailAddress } from 'components/LoanOriginationSystem/EmailsDashboard/utils/parseEmailAddress';
import SearchHighlight from 'components/SearchHighlight';

interface EmailLineProps {
  email: Email;
  onOpenEmail: (emailId: string) => void;
  onDeleteEmail: (emailId: string) => void;
  organizationEmail: string;
  searchValue: string;
}

const EmailLine = ({ email, onOpenEmail, onDeleteEmail, organizationEmail, searchValue }: EmailLineProps) => {
  const generateRowActions = () => {
    return (
      <RowActions
        actions={[
          {
            title: 'Open Email',
            handler: () => onOpenEmail(email.id),
            danger: false,
          },
          {
            title: 'Send Reply',
            handler: () => sendReplyEmail(email, organizationEmail),
            danger: false,
          },
          {
            title: 'Forward Email',
            handler: () => forwardEmail(email, organizationEmail),
            danger: false,
            separatorRequired: true,
          },
          {
            title: 'Delete Email',
            handler: () => onDeleteEmail(email.id),
            danger: true,
          },
        ]}
      />
    );
  };

  const { email: senderEmail } = parseEmailAddress(email.from);

  const getRecipientNames = () => {
    const recipientNames = email.to.map((recipient) => {
      if (recipient !== organizationEmail) {
        return parseEmailAddress(recipient).email;
      }
      return recipient;
    });

    return recipientNames.join(', ');
  };

  return (
    <TableRow useActions onClick={() => onOpenEmail(email.id)}>
      <TableBodyCell width={EmailsColumnSizes.CreatedAt} noPadding overflowed>
        <DateTime time={email.sentDate.toString()} format={DateTimeFormat.Short} />
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.SentFrom} overflowed>
        <SearchHighlight search={searchValue}>{senderEmail}</SearchHighlight>
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.SentTo} overflowed>
        <SearchHighlight search={searchValue}>{getRecipientNames()}</SearchHighlight>
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Subject} overflowed>
        <SearchHighlight search={searchValue}>{email.subject}</SearchHighlight>
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Attachments} noPadding>
        {email.files.length ? <AttachmentsImage /> : <></>}
      </TableBodyCell>
      {generateRowActions()}
    </TableRow>
  );
};

export default EmailLine;
