import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { BorrowerType } from './LoanOriginationSystemBorrowersApi';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';

export enum ProductStatus {
  Archived = 'archived',
  Active = 'active',
  Draft = 'draft',
}

export enum ProductType {
  Custom = 'custom',
  CreditCard = 'creditCard',
  PersonalLoan = 'personalLoan',
  AutoLoanRefinancing = 'autoLoanRefinancing',
  Mortgage = 'mortgage',
  HomeEquityLoan = 'homeEquityLoan',
  HomeImprovementLoan = 'homeImprovementLoan',
  PointOfSaleFinancing = 'pointOfSaleFinancing',
  SmallBusinessLoan = 'smallBusinessLoan',
  CommercialLoan = 'commercialLoan',
}

export enum ProductApiRequestTemplateType {
  CreateApplication = 'createApplication',
  UpdateApplication = 'updateApplication',
  CreateTask = 'createTask',
  UpdateTask = 'updateTask',
}

export enum ApplicationFormPage {
  Borrower = 'borrower',
  CoBorrower = 'coBorrower',
  Intermediary = 'intermediary',
  ApplicationDetails = 'applicationDetails',
}

export enum AssigneeTeamMembersType {
  OnCreate = 'onCreate',
  RoundRobin = 'roundRobin',
  SpecificTeamMembers = 'specificTeamMembers',
}

export interface ProductSettings {
  docuSignEnabled: boolean;
  autoAssignLabels: string[];
  applicationFormPages: ApplicationFormPage[];
  assigneeTeamMembersType: AssigneeTeamMembersType | null;
  teamMembersToAssign: string[];
  teamMembersToRoundRobin: string[];
  lockBorrowerOnApplicationCreation: boolean;
}

export interface Product {
  id: string;
  type: ProductType;
  name: string;
  status: ProductStatus;
  borrowerType: BorrowerType;
  declineReasons: string[];
  statuses: string[];
  settings: ProductSettings;
  createdAt: Date;
  updatedAt: Date;
  createdBy: UserInfo | null;
  updatedBy: UserInfo | null;
}

export interface CreateProductParams {
  name: string;
  borrowerType: BorrowerType;
  type: ProductType;
  status: ProductStatus;
}

export interface UpdateProductParams {
  status?: ProductStatus;
  name?: string;
  settings?: Partial<ProductSettings>;
}

export interface ProductsDataFilter {
  search: string;
  statuses?: ProductStatus[];
}

export interface LoanOriginationSystemProductsApi {
  getAll(filters: ProductsDataFilter): Promise<Product[]>;
  getDropdownProducts(search: string, abortSignal: AbortSignal): Promise<Product[]>;
  getById(productId: string): Promise<Product>;
  update(id: string, product: UpdateProductParams): Promise<Product>;
  duplicate(id: string): Promise<Product>;
  create(params: CreateProductParams): Promise<Product>;
}

export default class LoanOriginationSystemProductsRestApi extends LoanOriginationSystemApi<Product>
  implements LoanOriginationSystemProductsApi {
  protected resourceName = 'products';

  public getAll(filters: ProductsDataFilter) {
    const params = new URLSearchParams();
    const statuses = filters.statuses || [ProductStatus.Active, ProductStatus.Draft, ProductStatus.Archived];

    params.set('search', filters.search);

    statuses.forEach((status) => {
      params.append('statuses', status);
    });

    return this.getResources(params);
  }

  public getDropdownProducts(search: string, abortSignal: AbortSignal) {
    const params = new URLSearchParams();

    params.append('statuses', ProductStatus.Active);
    params.append('search', search);

    return this.fetch<Product[]>(`/${this.resourceName}/?${params}`, 'GET', undefined, { abortSignal });
  }

  public getById(productId: string) {
    return this.getResourceById(productId);
  }

  public update(id: string, product: UpdateProductParams) {
    return this.updateResource(id, { product });
  }

  public duplicate(id: string) {
    return this.fetch<Product>(`/${this.resourceName}/${id}/duplicate`, 'POST');
  }

  public create(params: CreateProductParams) {
    return this.createResource({ product: params });
  }
}
