import { connect } from 'react-redux';
import ModuleList from 'components/StrategyOverview/DecisionProcess/ModuleList/ModuleList';
import { reorderModuleBranches, reorderModules } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { DecisionStrategiesState } from 'DecisionStrategy/DecisionStrategiesTypes';

export type ModuleListConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ decisionStrategies }: { decisionStrategies: DecisionStrategiesState }) => {
  return {
    strategies: decisionStrategies.strategiesDetailedAttributes,
  };
};

const mapDispatchToProps = {
  reorderModuleBranches,
  reorderModules,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModuleList);
