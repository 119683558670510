import React, { ChangeEvent, useState, useEffect } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './DescriptionArea.module.scss';

interface DescriptionAreaProps {
  description: string;
  handleDescriptionUpdate: (description: string) => void;
}

const MAX_COLLAPSED_DESCRIPTION_LENGTH = 150;

const DescriptionArea = ({ description = '', handleDescriptionUpdate }: DescriptionAreaProps) => {
  const [descriptionUserInput, setDescriptionUserInput] = useState(description);
  const [inFocus, setInFocus] = useState(false);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setDescriptionUserInput(description);
  }, [description]);

  const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setDescriptionUserInput(e.target.value || '');
  };

  const openDescription = () => {
    setOpen(true);
  };

  const closeDescription = () => {
    setOpen(false);
    setInFocus(false);
  };

  const onBlur = () => {
    if (description === descriptionUserInput) {
      setInFocus(false);
      return;
    }
    handleDescriptionUpdate(descriptionUserInput);
    setInFocus(false);
  };

  const onFocus = () => {
    setInFocus(true);
    openDescription();
  };

  const getDescriptionValue = () => {
    if (inFocus) {
      return descriptionUserInput;
    }
    return isOpen ? descriptionUserInput : descriptionUserInput.slice(0, MAX_COLLAPSED_DESCRIPTION_LENGTH);
  };

  const isDescriptionLong = descriptionUserInput.length >= MAX_COLLAPSED_DESCRIPTION_LENGTH;
  const isShowHideButtonsVisible = isDescriptionLong && !inFocus;

  return (
    <div className={styles.strategyDescription}>
      <TextareaAutosize
        value={getDescriptionValue()}
        onChange={handleDescriptionChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Add Description"
        className={isOpen ? styles.focusTextarea : ''}
      />
      <p
        className={`${isOpen ? styles.focusParagraph : ''} ${!descriptionUserInput.slice(
          0,
          MAX_COLLAPSED_DESCRIPTION_LENGTH,
        ) && styles.emptyParagraph}`}
      >
        {getDescriptionValue() || 'Add Description'}
        {!isOpen && isShowHideButtonsVisible && <span onClick={openDescription}>Read More</span>}
      </p>
      {isOpen && isShowHideButtonsVisible && (
        <span className={styles.showLessButton} onClick={closeDescription}>
          Show Less
        </span>
      )}
    </div>
  );
};

export default DescriptionArea;
