import React, { FC } from 'react';
import InvoiceStatus from 'BillingPage/InvoiceStatus';
import { Tag } from 'components/Tag';
import { TagColor } from 'components/Tag/Tag';
import { Invoice } from 'api/BillingApi';

interface InvoiceStatusTagProps {
  invoice: Invoice;
}

const InvoiceStatusTag: FC<InvoiceStatusTagProps> = ({ invoice }) => {
  const isPastDue =
    invoice.status === InvoiceStatus.Open && (!invoice.dueDate || invoice.dueDate < invoice.invoiceDate);

  const getTagColor = (): TagColor => {
    if (isPastDue) {
      return 'red';
    }

    switch (invoice.status.toLowerCase()) {
      case InvoiceStatus.Paid:
        return 'green';
      case InvoiceStatus.Open:
        return 'blue';
      case InvoiceStatus.Uncollectible:
        return 'red';
      case InvoiceStatus.Void:
        return 'gray';
      default:
        return 'gray';
    }
  };

  const getStatus = () => {
    if (isPastDue) {
      return 'Past Due';
    }

    return invoice.status;
  };

  return <Tag color={getTagColor()}>{getStatus()}</Tag>;
};

export default InvoiceStatusTag;
