import Fetcher from 'middlewares/Fetcher';
import { ApplicationTabsApi, ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';
import { UpdateApplicationTabAction, updateApplicationTabSuccess, updateApplicationTabFailure } from './ActionCreator';

const UpdateApplicationTabMiddleware = (api: ApplicationTabsApi) =>
  Fetcher<ApplicationTab, UpdateApplicationTabAction>(
    LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTab,
    updateApplicationTabSuccess,
    updateApplicationTabFailure,
    (action) => {
      return api.update(action.payload.id, action.payload.params);
    },
  );

export default UpdateApplicationTabMiddleware;
