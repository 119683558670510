import React, { FC, ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router';
import AutoCompletion from 'components/AutoCompletion';
import { StrategiesListItem } from 'api/Types';
import styles from './RunProcess.module.scss';
import Button from 'components/Button';
import UploadFile from 'components/UploadFile';
import TextInput from 'components/TextInput';
import ButtonWithImage from 'components/ButtonWithImage';
import { Option } from 'components/SelectInput/SelectInput';
import { Tag } from 'components/Tag';
import { statusColors } from 'pages/IndividualProcessing/IndividualProcessing';
import getNormalizedStatus from 'utils/getNormalizedStatus';

interface RunProcessProps {
  currentStrategy?: string;
  strategies: StrategiesListItem[];
  onRunProcess: (file: File, processName?: string) => Promise<unknown>;
  downloadTemplate: () => void;
}

const ACCEPT_FORMATS = '.csv';

const RunProcess: FC<RunProcessProps> = ({ currentStrategy = '', strategies, onRunProcess, downloadTemplate }) => {
  const [file, setFile] = useState<File | null>(null);
  const [processName, setProcessName] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProcessName(e.target.value);
  };

  const handleStrategyChange = ({ value }: Option) => {
    history.push(`/decision/processing/batch/run/${value}`);
  };

  const handleClick = async () => {
    if (file) {
      setIsLoading(true);
      await onRunProcess(file, processName);
      setIsLoading(false);
    }
  };

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };


  return (
    <>
      <h4 className={styles.title}>Inputs</h4>
      <div className={styles.inputContainer}>
        <div className={styles.input}>
          <AutoCompletion
            labelTitle="Strategy Name"
            onChange={handleStrategyChange}
            value={currentStrategy}
            options={strategies.map(({ id, name, status }) => ({
              name,
              value: id,
              iconAfter: id !== null && (
                <Tag color={statusColors.get(getNormalizedStatus(status))}>{getNormalizedStatus(status)}</Tag>
              ),
            }))}
          />
        </div>
        {currentStrategy && (
          <div className={styles.input}>
            <TextInput
              labelTitle="Batch Process Name"
              value={processName}
              onChange={handleInputChange}
              placeholder="Optional"
            />
          </div>
        )}
      </div>
      <div />
      {currentStrategy && (
        <>
          <p className={styles.dropzoneLabel}>Source Data File</p>
          <UploadFile
            onFileChange={handleFileChange}
            file={file}
            accept={ACCEPT_FORMATS}
            text={
              <>
                Drag a batch processing file here or <span className={styles.link}>browse</span> for a file to upload.
              </>
            }
          />
        </>
      )}
      <div className={styles.buttonsContainer}>
        <Button kind="primary" size="form" disabled={!file} onClick={handleClick} isLoading={isLoading}>
          Run Decision
        </Button>
        {currentStrategy && <ButtonWithImage title="Download Template" onClick={downloadTemplate} />}
      </div>
    </>
  );
};

export default RunProcess;
