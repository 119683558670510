import React from 'react';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import styles from './PageUpdateInfo.module.scss';

interface PageUpdateInfoProps {
  member?: UserInfo | null;
  date: Date;
  formatDate?: (date: Date) => string;
}

const PageUpdateInfo = ({ member, date, formatDate: customFormatDate }: PageUpdateInfoProps) => {
  return (
    <div className={styles.pageUpdateInfo}>
      <WithSystemApiUserAvatar className={styles.pageUpdateInfoUser} user={member} size={8.57} />
      <p className={styles.pageUpdateInfoDate}>
        Updated {!customFormatDate ? formatDate(date, DateTimeFormat.Long) : customFormatDate(date)}
      </p>
    </div>
  );
};

export default PageUpdateInfo;
