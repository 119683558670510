import { template, mapValues } from 'lodash';

export enum MessageType {
  VersionDeleted,
  StrategyCreated,
  StrategyNameUpdated,
  StrategyVersionCreated,
  ModuleAdded,
  BranchAdded,
  ModuleNameUpdated,
  BranchNameUpdated,
  ModuleDeleted,
  BranchDeleted,
  ModulesReOrdered,
  BranchesReOrdered,
  RuleOrderUpdated,
  RuleAdded,
  RuleUpdated,
  RuleDeleted,
  RuleSetFromCSVUploaded,
  RuleSetFromCSVImported,
  RanIndividualProcessing,
  RanBatchProcessing,
  CompanyInformationUpdated,
  UserInformationUpdated,
  VariableCreated,
  VariableUpdated,
  VariableArchived,
  VariableRestored,
  VariableDeletionForbiddenDependencies,
  VariableDataTypeChangeForbidden,
  AccountInfoUpdated,
  BranchDuplicated,
  BorrowerDeleted,
  BorrowerUpdated,
  VersionArchived,
  VersionRestored,
  StrategyArchived,
  StrategyRestored,
  StrategyHasActiveVersion,
  StrategyHasLockedVersion,
  BorrowerUnlocked,
  LabelDeleted,
  LabelCreated,
  LabelUpdated,
  VersionsIsActive,
  VersionsIsLocked,
  IntermediaryUpdated,
  PasswordResetEmailSent,
  ProductStatusChanged,
  ProductArchived,
  ProductDuplicated,
  ProductCreated,
  AutomatedProcessActivated,
  AutomatedProcessDeactivated,
  ApplicationDocumentUploaded,
  ApplicationDocumentRenamed,
  ApplicationDocumentDeleted,
  ApplicationDocumentIncorrectNameProvided,
  RequestCustomerUploadSuccess,
  ApplicationNoteAdded,
  ApplicationNoteDeleted,
  ApplicationNoteUpdated,
  ResultRemoved,
  UploadSecretDocument,
  CompanyLogoUpdated,
  RegionSettingsUpdate,
  ApplicationStatusRuleCreated,
  ApplicationStatusRuleUpdated,
  ApplicationStatusRuleDeleted,
  VariablePartiallyHiddenUnsupported,
  EmailDeleted,
  PasswordRequired,
  PasswordRequirementsNotMet,
  TaskMarkedAsCompleted,
  TaskMarkedAsIncomplete,
  TaskUpdated,
  TaskDeleted,
  TaskCreated,
  ApplicationCoborrowerDeleted,
  ApplicationIntermediaryDeleted,
  ApplicationCoborrowerAdded,
  ApplicationIntermediaryAdded,
  StrategyDuplicated,
  UpdateTemplateStatusSuccess,
  DocuIntegrationSaved,
  DocuSignTemplatesImported,
  ESignTemplateDeleted,
  RestrictCreateNewVersionForEmptyStrategy,
  IntermediaryDeleted,
  DocuSignEnvelopeCreated,
  ApplicationTeamMembersUpdated,
  AuthenticationCodeSent,
  ActivationEmailSent,
  FormSettingSaved,
  RequiredVariablesUpdated,
  BorrowerToDeleteHasApplications,
  IntermediaryToDeleteHasApplications,
}

const messages = {
  [MessageType.VersionDeleted]: 'Version <%= strategyVersion %> of <%= strategyName %> has been deleted.',
  [MessageType.StrategyCreated]: 'Your strategy has been created. Please update the strategy name.',
  [MessageType.StrategyNameUpdated]: 'Your strategy name has been updated to <%= strategyName %>.',
  [MessageType.StrategyVersionCreated]: 'Successfully created Version <%= strategyVersion %> of <%= strategyName %>.',
  [MessageType.ModuleAdded]: 'Successfully added the <%= moduleTypeName %> module named <%= moduleName %>.',
  [MessageType.BranchAdded]: 'Successfully added a new branch to the module named <%= moduleName %>.',
  [MessageType.BranchDuplicated]: 'The branch <%= originalBranchName %> was copied to create <%= copiedBranchName %>.',
  [MessageType.ModuleNameUpdated]: 'Your module name has been updated to <%= moduleName %>.',
  [MessageType.BranchNameUpdated]: 'Your branch name has been updated to <%= branchName %>.',
  [MessageType.ModuleDeleted]: 'The <%= moduleType %> module named <%= moduleName %> has been deleted.',
  [MessageType.BranchDeleted]: 'The branch named <%= branchName %> has been deleted.',
  [MessageType.ModulesReOrdered]: 'Your module order has updated successfully.',
  [MessageType.BranchesReOrdered]: 'Your branch order has updated successfully.',
  [MessageType.RuleOrderUpdated]: 'Your rule order has updated successfully.',
  [MessageType.RuleAdded]: 'Your rule has been added.',
  [MessageType.RuleUpdated]: 'Your rule has been modified.',
  [MessageType.RuleDeleted]: 'Your rule has been deleted.',
  [MessageType.RuleSetFromCSVUploaded]: 'Your rule set has successfully uploaded.',
  [MessageType.RuleSetFromCSVImported]: 'Your rule set has successfully imported.',
  [MessageType.RanIndividualProcessing]: 'The decision finished processing.',
  [MessageType.RanBatchProcessing]:
    'The batch process has started running and will show 100% on the History tab upon completion.',
  [MessageType.CompanyInformationUpdated]: 'Your company information has been updated.',
  [MessageType.UserInformationUpdated]: 'The user profile for <%= firstName %> <%= lastName %> has been updated.',
  [MessageType.VariableCreated]: 'The variable named <%= variableName %> has been created.',
  [MessageType.VariableUpdated]: 'The variable named <%= variableName %> has been updated.',
  [MessageType.VariableArchived]: 'The variable named <%= variableName %> has been archived.',
  [MessageType.VariableRestored]: 'The variable named <%= variableName %> has been restored.',
  [MessageType.VariableDeletionForbiddenDependencies]:
    'The variable "<%= variableName %>"  cannot be deleted because it is currently being used.',
  [MessageType.VariableDataTypeChangeForbidden]: `Cannot change data type from <%= sourceDataType %> to <%= targetDataType %>`,
  [MessageType.AccountInfoUpdated]: 'Your profile information has been updated.',
  [MessageType.BorrowerDeleted]: 'The borrower named <%= borrowerName %> has been deleted.',
  [MessageType.BorrowerUpdated]: 'The borrower <%= borrowerName %> has been updated.',
  [MessageType.VersionArchived]: 'Version <%= version %> of <%= strategyName %> has been archived.',
  [MessageType.VersionRestored]: 'Version <%= version %> of <%= strategyName %> has been restored.',
  [MessageType.StrategyArchived]: 'The strategy named <%= strategyName %> has been archived.',
  [MessageType.StrategyRestored]: 'The strategy named <%= strategyName %> has been restored.',
  [MessageType.StrategyHasActiveVersion]:
    'The strategy <%= strategyName %> cannot be archived because a version is set to Active. Please disable this version before archiving.',
  [MessageType.StrategyHasLockedVersion]:
    'The strategy <%= strategyName %> cannot be deleted because one or more versions is locked. Please archive this strategy instead.',
  [MessageType.VersionsIsActive]:
    'Version <%= version %> of <%= strategyName %> cannot be archived because it is set to Active. Please disable this version before archiving.',
  [MessageType.VersionsIsLocked]:
    'Version <%= version %> of <%= strategyName %> cannot be deleted because it is Locked.',
  [MessageType.BorrowerUnlocked]: 'The borrower <%= borrowerName %> has been unlocked.',
  [MessageType.LabelDeleted]: 'The label named <%= labelName %> has been deleted.',
  [MessageType.LabelCreated]: 'New label has been created.',
  [MessageType.LabelUpdated]: 'The label has been updated.',
  [MessageType.IntermediaryUpdated]: 'The intermediary <%= intermediaryName %> has been updated.',
  [MessageType.PasswordResetEmailSent]:
    'If you have a DigiFi account, a reset email will have been sent to <%= email %>! If you are unable to locate the email, please check your spam or junk folder.',
  [MessageType.ProductStatusChanged]: 'The status of the product <%= productName %> has been changed.',
  [MessageType.ProductArchived]: 'The product <%= productName %> has been archived.',
  [MessageType.ProductDuplicated]: 'The product <%= productName %> has been duplicated.',
  [MessageType.ProductCreated]: 'The product <%= productName %> has been created.',
  [MessageType.AutomatedProcessActivated]: 'The automation process has been activated.',
  [MessageType.AutomatedProcessDeactivated]: 'The automation process has been deactivated.',
  [MessageType.ApplicationDocumentUploaded]: 'The document has been uploaded.',
  [MessageType.ApplicationDocumentDeleted]: 'The document has been deleted.',
  [MessageType.RequestCustomerUploadSuccess]: 'The email has been sent.',
  [MessageType.ApplicationNoteAdded]: 'The note has been created.',
  [MessageType.ApplicationNoteDeleted]: 'The note has been deleted.',
  [MessageType.ApplicationNoteUpdated]: 'The note has been updated.',
  [MessageType.ResultRemoved]: 'The result has been removed.',
  [MessageType.ApplicationDocumentRenamed]: 'The document has been renamed.',
  [MessageType.ApplicationDocumentIncorrectNameProvided]: 'Provided name incorrect.',
  [MessageType.UploadSecretDocument]:
    'Your document has been uploaded. If you would like to upload additional documents, please do so now',
  [MessageType.CompanyLogoUpdated]: 'The logo has been successfully uploaded.',
  [MessageType.RegionSettingsUpdate]: 'Region settings have been successfully updated.',
  [MessageType.ApplicationStatusRuleCreated]: 'Status Rule was successfully created.',
  [MessageType.ApplicationStatusRuleUpdated]: 'Status Rule was successfully updated.',
  [MessageType.ApplicationStatusRuleDeleted]: 'Status Rule was successfully deleted.',
  [MessageType.VariablePartiallyHiddenUnsupported]:
    'Variables with data type <%= dataType %> cannot be partially hidden.',
  [MessageType.EmailDeleted]: 'Email was successfully deleted.',
  [MessageType.PasswordRequired]: 'Password is required.',
  [MessageType.PasswordRequirementsNotMet]:
    'Password does not meet requirements (8+ characters in length, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number).',
  [MessageType.TaskMarkedAsCompleted]: 'The task has been marked as completed.',
  [MessageType.TaskMarkedAsIncomplete]: 'The task has been marked as incomplete.',
  [MessageType.TaskUpdated]: 'The task has been updated.',
  [MessageType.TaskDeleted]: 'The task has been deleted.',
  [MessageType.TaskCreated]: 'New task has been created.',
  [MessageType.ApplicationCoborrowerDeleted]:
    'The co-borrower named <%= borrowerName %> has been deleted from this application.',
  [MessageType.ApplicationIntermediaryDeleted]:
    'The intermediary named <%= intermediaryName %> has been deleted from this application.',
  [MessageType.ApplicationCoborrowerAdded]:
    'The co-borrower named <%= borrowerName %> has been added to this application.',
  [MessageType.ApplicationIntermediaryAdded]:
    'The intermediary named <%= intermediaryName %> has been added to this application.',
  [MessageType.StrategyDuplicated]: 'Your duplicated strategy has been created. Please update the strategy name.',
  [MessageType.UpdateTemplateStatusSuccess]: 'The template <%= templateName %> has been <%= statusMessage %>.',
  [MessageType.DocuIntegrationSaved]: 'Credentials have been saved.',
  [MessageType.DocuSignTemplatesImported]: 'Templates have been imported.',
  [MessageType.ESignTemplateDeleted]: 'The template <%= templateName %> has been deleted.',
  [MessageType.RestrictCreateNewVersionForEmptyStrategy]:
    'Please add at least one module before creating a new version.',
  [MessageType.IntermediaryDeleted]: 'The intermediary named <%= intermediaryName %> has been deleted.',
  [MessageType.DocuSignEnvelopeCreated]:
    'Your document was sent. A signed copy will appear in the Documents list upon completion.',
  [MessageType.ApplicationTeamMembersUpdated]: 'The assigned team members have been updated.',
  [MessageType.AuthenticationCodeSent]: 'A new authentication code has been sent to <%=phone%>.',
  [MessageType.ActivationEmailSent]:
    'A new verification email has been sent to <%= email %>! If you are unable to locate the email, please check your spam or junk folder.',
  [MessageType.FormSettingSaved]: 'Your application form settings have been updated.',
  [MessageType.RequiredVariablesUpdated]: 'Your required <%= pageName %> fields have been updated.',
  [MessageType.BorrowerToDeleteHasApplications]:
    "The borrower named <%= borrowerName %> can't be deleted because they have applications.",
  [MessageType.IntermediaryToDeleteHasApplications]:
    "The intermediary named <%= companyName %> can't be deleted because it is connected to applications.",
};

const messageTemplates = mapValues(messages, (templateString) => template(templateString));

const getMessage = (messageType: MessageType, data?: any) => messageTemplates[messageType](data);

export default getMessage;
