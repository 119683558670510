enum EditApplicationStep {
  Borrower = 'Borrower',
  CoBorrower = 'Co-Borrower',
  Intermediary = 'Intermediary',
  ApplicationDetails = 'Application Details',
  AddCoborrower = 'Add Co-Borrower',
  AddIntermediary = 'Add Intermediary',
}

export default EditApplicationStep;
