import React, { FC, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import AddCalculationScript from 'components/CalculationScripts/PopUps/AddCalculationScript/AddCalculationScript';
import AddSimpleOutputs from 'components/SimpleOutputs/PopUps/AddSimpleOutputs';
import RuleBuilder from 'components/RuleBuilder';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import {
  RulePopUpType,
  SimpleOutputRequestData,
  CalculationScriptData,
  RuleRequestData,
  EntityType,
} from 'RuleCreate/Types';
import { getSerializedRules } from 'containers/EditRulePopUpContainer/utils';
import { RuleData } from 'BranchInfo/Types';

interface EditRulePopUpProps {
  moduleType: ModuleType | null;
  ruleType: RulePopUpType | null;
  entityType: EntityType | null;
  currentRule: RuleData;
  currentCondition: RuleData;
  closePopUp: () => void;
  editSimpleOutput: (data: SimpleOutputRequestData) => void;
  editCalculationScript: (data: CalculationScriptData) => void;
  editRule: (data: RuleRequestData) => void;
  openVariableCreation: () => void;
}

const getTitleByModuleType = (moduleType: ModuleType) => {
  switch (moduleType) {
    case 'requirements':
      return 'Edit Requirements Rule';
    case 'output':
      return 'Edit Rule-Based Output';
    case 'calculations':
      return 'Edit Calculation Script';
    case 'scorecard':
      return 'Edit Scoring Rule';
    case 'assignments':
      return 'Edit Output';
    default:
      return '';
  }
};

const EditRulePopUp: FC<EditRulePopUpProps> = ({
  moduleType,
  ruleType,
  currentRule,
  currentCondition,
  entityType,
  closePopUp,
  editSimpleOutput,
  editRule,
  editCalculationScript,
  openVariableCreation,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const currentRuleByEntityType = entityType === 'branch' ? currentCondition : currentRule;
  const { serializedRules, conditionOutput } = getSerializedRules(currentRuleByEntityType);

  const title = entityType === 'branch' ? 'Edit Branch Condition' : getTitleByModuleType(moduleType as ModuleType);

  const handleEditRule = async (data: RuleRequestData) => {
    try {
      setIsSaving(true);
      await editRule(data);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditCalculationScript = async (data: CalculationScriptData) => {
    try {
      setIsSaving(true);
      await editCalculationScript(data);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEditSimpleOutputs = async (data: SimpleOutputRequestData) => {
    try {
      setIsSaving(true);
      await editSimpleOutput(data);
    } finally {
      setIsSaving(false);
    }
  };

  const getContent = () => {
    if (
      moduleType === 'requirements' ||
      moduleType === 'output' ||
      moduleType === 'scorecard' ||
      entityType === 'branch'
    ) {
      return (
        <RuleBuilder
          saveRule={handleEditRule}
          type={ruleType as RulePopUpType}
          multipleOutputs={moduleType === 'output'}
          withoutDeclineReason={entityType === 'branch'}
          moduleType={moduleType as ModuleType}
          serializedRules={serializedRules}
          serializedOutputs={entityType === 'branch' ? [] : conditionOutput}
          isEdit
          openVariableCreation={openVariableCreation}
          isSaving={isSaving}
        />
      );
    }
    if (moduleType === 'calculations') {
      return (
        <AddCalculationScript
          serializedRules={serializedRules}
          calculationInputs={currentRule.calculationInputs}
          saveCalculationScript={handleEditCalculationScript}
          openVariableCreation={openVariableCreation}
          isSaving={isSaving}
        />
      );
    }
    if (moduleType === 'assignments') {
      return (
        <AddSimpleOutputs
          saveSimpleOutput={handleEditSimpleOutputs}
          serializedRules={serializedRules}
          serializedOutputs={conditionOutput}
          openVariableCreation={openVariableCreation}
          isSaving={isSaving}
        />
      );
    }
    return null;
  };

  return (
    <PopUp title={title} closePopUp={closePopUp}>
      {getContent()}
    </PopUp>
  );
};

export default EditRulePopUp;
