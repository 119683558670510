import React, { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import useCachedForTransition from 'hooks/cachedForTransition';

import styles from './TransitionExpand.module.scss';

interface TransitionExpandProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  anchorElement?: Element;
  left?: number;
  top?: number;
}

interface Size {
  width: number;
  height: number;
}

interface Offset {
  top: number;
  left: number;
}

interface Bounds extends Size, Offset {}

const collapsedSize: Size = {
  width: 0,
  height: 0,
};

const TransitionExpand: FC<TransitionExpandProps> = ({
  anchorElement,
  left: leftOffset = 0,
  top: topOffset = 0,
  className,
  children,
  ...props
}) => {
  const [visibleChildren, onTransitionEnd] = useCachedForTransition(children);

  const [measuredSize, setMeasuredSize] = useState<Size>(collapsedSize);
  const [anchorBounds, setAnchorBounds] = useState<Bounds>();
  const [isInitiallyPositioned, setIsInitiallyPositioned] = useState(false);

  const wrapperElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!anchorElement) {
      return;
    }
    const { top, left, width, height } = anchorElement.getBoundingClientRect();
    if (!isEqual({ top, left, width, height }, anchorBounds)) {
      setAnchorBounds({ top, left, width, height });
    }
  });

  useEffect(() => {
    if (anchorBounds) {
      setIsInitiallyPositioned(true);
    }
  }, [anchorBounds]);

  useEffect(() => {
    if (!children) {
      setMeasuredSize(collapsedSize);
      return;
    }
    const contentElement = wrapperElementRef.current!.children[0];
    const { width, height } = contentElement.getBoundingClientRect();
    setMeasuredSize({ width, height });
  }, [children]);

  const size = isInitiallyPositioned ? measuredSize : collapsedSize;

  const getOffset = ({ left, top, width, height }: Bounds): Offset => {
    if (size === collapsedSize) {
      return {
        left: leftOffset + left + width / 2,
        top: topOffset + top + height / 2,
      };
    }
    return { left: left + leftOffset, top: top + topOffset };
  };

  const getTransformString = (): string => {
    if (!anchorBounds) {
      return '';
    }
    const { left, top } = getOffset(anchorBounds);
    return `translate(${left}px, ${top}px)`;
  };

  const style = {
    ...size,
    transform: getTransformString(),
  };

  const mergedClassName = clsx(
    styles.transitionExpand,
    isInitiallyPositioned ? styles.transitionExpand__animated : styles.transitionExpand__initial,
    className,
  );

  return (
    <div ref={wrapperElementRef} className={mergedClassName} style={style} onTransitionEnd={onTransitionEnd} {...props}>
      {visibleChildren}
    </div>
  );
};

export default TransitionExpand;
