import Fetcher from 'middlewares/Fetcher';
import {
  GetStrategyGroupsDataAction,
  getStrategyGroupsDataFailure,
  getStrategyGroupsDataSuccess,
} from 'StrategyGroupsData/Actions';
import { StrategyGroupActionType } from 'StrategyGroupsData/ActionTypes';
import { ReduxState } from 'types/redux';
import { StrategyGroupsWithParams } from './Types';
import { IStrategyGroupsApi } from 'api/DecisionEngine/StrategyGroupApi';

export const GetStrategyGroupsDataMiddleware = (api: IStrategyGroupsApi) =>
  Fetcher<StrategyGroupsWithParams, GetStrategyGroupsDataAction, ReduxState>(
    StrategyGroupActionType.GetStrategyGroupsData,
    getStrategyGroupsDataSuccess,
    getStrategyGroupsDataFailure,
    async (action, { strategyGroups }) => {
      const { page, itemsPerPage, showArchived, searchInputValue, selectedUpdaters, versionsStatuses } = strategyGroups;
      const { payload = {} } = action;

      const response = await api.findGroups({
        page,
        perPage: itemsPerPage,
        showArchived,
        searchInputValue,
        updaters: selectedUpdaters.map(({ id }) => id),
        versionsStatuses,
        ...payload,
      });

      return {
        ...response,
        searchInputValue,
        selectedUpdaters,
      };
    },
  );
