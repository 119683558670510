import {
  CompleteCalculationScriptData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  SaveCalculationScriptAction,
  SaveCalculationScriptSuccessAction,
  SaveRuleAction,
  SaveRuleSuccessAction,
  SaveSimpleOutputAction,
  SaveSimpleOutputSuccessAction,
} from 'RuleCreate/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  CLOSE_EDIT_RULE_POP_UP,
  EDIT_CALCULATION_SCRIPT,
  EDIT_CALCULATION_SCRIPT_FAILURE,
  EDIT_CALCULATION_SCRIPT_SUCCESS,
  EDIT_RULE,
  EDIT_RULE_FAILURE,
  EDIT_RULE_SUCCESS,
  EDIT_SIMPLE_OUTPUT,
  EDIT_SIMPLE_OUTPUT_FAILURE,
  EDIT_SIMPLE_OUTPUT_SUCCESS,
  OPEN_EDIT_RULE_POP_UP,
} from './ActionTypes';
import { RuleEditPopUpData } from './Types';

export const openEditRulePopUp = (payload: RuleEditPopUpData) => ({
  type: OPEN_EDIT_RULE_POP_UP,
  payload,
});

export const closeEditRulePopUp = () => ({
  type: CLOSE_EDIT_RULE_POP_UP,
});

export const editRule = (payload: CompleteRuleRequestData): SaveRuleAction => ({
  type: EDIT_RULE,
  payload,
});

export const editRuleSuccess = (payload: CompleteRuleRequestData): SaveRuleSuccessAction => ({
  type: EDIT_RULE_SUCCESS,
  payload,
});

export const editRuleFailure = FailedActionCreator(EDIT_RULE_FAILURE);

export const editCalculationScript = (payload: CompleteCalculationScriptData): SaveCalculationScriptAction => ({
  type: EDIT_CALCULATION_SCRIPT,
  payload,
});

export const editCalculationScriptSuccess = (
  payload: CompleteCalculationScriptData,
): SaveCalculationScriptSuccessAction => ({
  type: EDIT_CALCULATION_SCRIPT_SUCCESS,
  payload,
});

export const editCalculationScriptFailure = FailedActionCreator(EDIT_CALCULATION_SCRIPT_FAILURE);

export const editSimpleOutput = (payload: CompleteSimpleOutputRequestData): SaveSimpleOutputAction => ({
  type: EDIT_SIMPLE_OUTPUT,
  payload,
});

export const editSimpleOutputSuccess = (payload: CompleteSimpleOutputRequestData): SaveSimpleOutputSuccessAction => ({
  type: EDIT_SIMPLE_OUTPUT_SUCCESS,
  payload,
});

export const editSimpleOutputFailure = FailedActionCreator(EDIT_SIMPLE_OUTPUT_FAILURE);
