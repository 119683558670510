import React, { useState } from 'react';
import styles from './IntermediariesFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemIntermediariesPage/Filters/ActionCreator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import CheckboxMultiSelectList from 'components/CheckboxMultiSelectList';
import borrowerTypesData from './BorrowerTypes';
import DoubleTextInput from 'components/DoubleTextInput';
import useMask from 'hooks/useMask';
import { removeNonNumericChars } from 'components/NumberInput/utils';
import maskValueWithPercentage from 'utils/masks/maskValueWithPercentage';
import { removeNonDigits } from 'utils/masks/maskNumberValue';

interface IntermediariesFilterProps {
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  dueUpdatedDateFrom: Date | null;
  dueUpdatedDateTo: Date | null;
  dueCommissionFrom: number;
  dueCommissionTo: number;
  borrowerTypes: string[];
}

const IntermediariesFilter = ({
  dueCreatedDateFrom,
  dueCreatedDateTo,
  dueUpdatedDateFrom,
  dueUpdatedDateTo,
  dueCommissionFrom,
  dueCommissionTo,
  borrowerTypes,
}: IntermediariesFilterProps) => {
  const dispatch = useDispatch();

  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateFrom);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateTo);
  const [fromUpdatedDate, setFromUpdatedDate] = useState(dueUpdatedDateFrom);
  const [toUpdatedDate, setToUpdatedDate] = useState(dueUpdatedDateTo);
  const [commissionFrom, setCommissionFrom] = useState(dueCommissionFrom.toString());
  const [commissionTo, setCommissionTo] = useState(dueCommissionTo.toString());
  const [selectedBorrowerTypes, setSelectedBorrowerTypes] = useState<string[]>(borrowerTypes);

  const getCorrectPercentageValue = (value: string, onChangeValue: (value: string) => void) => {
    const onlyDigitsValue = removeNonDigits(value);
    const valueAsNumber = Number(onlyDigitsValue);

    if (valueAsNumber >= 0 && valueAsNumber <= 100) {
      onChangeValue(valueAsNumber.toString());
    }
  };

  const [maskedCommissionFromValue, inputCommissionFromRef, handleCommissionFromValueChange] = useMask(
    commissionFrom,
    (value: string) => getCorrectPercentageValue(value, setCommissionFrom),
    maskValueWithPercentage,
    removeNonNumericChars,
    (char: string) => char === '-',
  );

  const [maskedCommissionToValue, inputCommissionToRef, handleCommissionToValueChange] = useMask(
    commissionTo,
    (value: string) => getCorrectPercentageValue(value, setCommissionTo),
    maskValueWithPercentage,
    removeNonNumericChars,
    (char: string) => char === '-',
  );

  const filtersResult: FiltersResult = {
    dueCreatedDateRange: {
      from: fromCreatedDate,
      to: toCreatedDate,
    },
    dueUpdatedDateRange: {
      from: fromUpdatedDate,
      to: toUpdatedDate,
    },
    dueCommissionRange: {
      from: Number(commissionFrom),
      to: Number(commissionTo),
    },
    borrowerTypes: selectedBorrowerTypes,
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleOnUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromUpdatedDate(from);
    setToUpdatedDate(to);
  };

  const onBorrowerTypesChange = (typeId: string) => {
    const updatedSelectedTypes = selectedBorrowerTypes.includes(typeId)
      ? selectedBorrowerTypes.filter((type) => type !== typeId)
      : [...selectedBorrowerTypes, borrowerTypesData.find((type) => type.id === typeId)!.id];

    setSelectedBorrowerTypes(updatedSelectedTypes);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <p className={styles.title}>Borrower Type</p>
      <CheckboxMultiSelectList
        items={borrowerTypesData}
        selectedItemsIds={selectedBorrowerTypes}
        onChange={onBorrowerTypesChange}
      />
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Created Date"
      />
      <DateRangePicker
        from={fromUpdatedDate}
        to={toUpdatedDate}
        onChange={handleOnUpdatedDateRangeChange}
        label="Updated Date"
      />
      <DoubleTextInput
        labelTitle="Commission Range"
        firstInputValue={maskedCommissionFromValue}
        firstInputRef={inputCommissionFromRef}
        onFirstInputChange={handleCommissionFromValueChange}
        secondInputValue={maskedCommissionToValue}
        onSecondInputChange={handleCommissionToValueChange}
        secondInputRef={inputCommissionToRef}
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default IntermediariesFilter;
