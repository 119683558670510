import {
  GetProductLabelsAction,
  getProductLabelsFailed,
  getProductLabelsSuccess,
} from 'LoanOriginationSystemApplications/Filters/ActionCreator';
import { GET_PRODUCT_LABELS } from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import {
  Label,
  LabelsSortingField,
  LoanOriginationSystemLabelsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

const GetFilterLabelsMiddleware = (api: LoanOriginationSystemLabelsApi) =>
  Fetcher<Label[], GetProductLabelsAction>(
    GET_PRODUCT_LABELS,
    getProductLabelsSuccess,
    getProductLabelsFailed,
    async () => {
      const labels = await api.getLabels({ sortingType: { field: LabelsSortingField.LabelName, ascending: true } });

      return labels.items;
    },
  );

export default GetFilterLabelsMiddleware;
