import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { ApplicationStatusRulesValidationActionType } from './ActionTypes';

export interface GetStatusesRulesValidationResultsAction extends AnyAction {
  type: ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResults;
  payload: {
    applicationId: string;
  };
}

export interface GetStatusesRulesValidationResultsSuccessAction extends AnyAction {
  type: ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResultsSuccess;
  payload: {
    validationResults: ApplicationStatusRulesValidationResult[];
  };
}

export interface ResetStatusesRulesValidationResultsAction extends AnyAction {
  type: ApplicationStatusRulesValidationActionType.ResetStatusesRulesValidationResults;
}

export const getStatusesRulesValidationResults = (applicationId: string): GetStatusesRulesValidationResultsAction => ({
  type: ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResults,
  payload: {
    applicationId,
  },
});

export const getStatusesRulesValidationResultsSuccess = (
  validationResults: ApplicationStatusRulesValidationResult[],
): GetStatusesRulesValidationResultsSuccessAction => ({
  type: ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResultsSuccess,
  payload: {
    validationResults,
  },
});

export const resetStatusesRulesValidationResults = (): ResetStatusesRulesValidationResultsAction => ({
  type: ApplicationStatusRulesValidationActionType.ResetStatusesRulesValidationResults,
});

export const getStatusesRulesValidationResultsFailure = FailedActionCreator(
  ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResultsFailure,
);
