import React, { forwardRef, ReactNode, Ref } from 'react';

import styles from './FormRow.module.scss';

interface ButtonsFormRowProps {
  children: ReactNode;
}

const FormRow = forwardRef<HTMLElement, ButtonsFormRowProps>(({ children }, ref) => (
  <div ref={ref as Ref<HTMLDivElement>} className={styles.formRow}>
    {children}
  </div>
));

export default FormRow;
