import { VariableAdvancedDataTypeAttributes } from 'Variables/VariablesTypes';
import { getVisualDataType } from 'Variables/utils';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import { formatPhoneNumber } from 'components/PhoneInput';
import getIdentificationNumberMask from './masks/maskIdentificationNumber';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import getCustomDateFormat from './getCustomDateFormat';
import formatMonetaryValue from './formatMonetaryValue';
import formatPercentageValue from './formatPercentageValue';
import maskNumberValue from './masks/maskNumberValue';

export interface FormatValueByDataTypeOptions {
  formatBooleanValue?: (value: string) => string;
}

const DEFAULT_FORMAT_VALUE_BY_DATA_TYPE_OPTIONS = {
  formatBooleanValue: (value: string) => value.toUpperCase(),
};

const formatValueByDataType = (
  variableValue: VariableValue | null | undefined,
  sourceVariableData: VariableAdvancedDataTypeAttributes,
  options: FormatValueByDataTypeOptions = DEFAULT_FORMAT_VALUE_BY_DATA_TYPE_OPTIONS,
) => {
  if (typeof variableValue === 'undefined' || variableValue === null || sourceVariableData === undefined) {
    return variableValue;
  }

  const visualDataType = getVisualDataType(sourceVariableData);
  const { currency, identificationNumberType, dateFormat } = sourceVariableData;

  const currencySymbol = currency ? getCurrencySymbol(currency) : '';

  if (visualDataType === 'Number') {
    return maskNumberValue(variableValue.toString());
  }

  if (visualDataType === 'Monetary') {
    return formatMonetaryValue(variableValue as number, currencySymbol);
  }

  if (visualDataType === 'Percentage') {
    return formatPercentageValue(variableValue as number);
  }

  if (visualDataType === 'PhoneNumber') {
    try {
      return formatPhoneNumber(variableValue.toString());
    } catch (e) {
      return variableValue;
    }
  }

  if (visualDataType === 'IdentificationNumber' && identificationNumberType) {
    const INMask = getIdentificationNumberMask(identificationNumberType);

    return INMask(variableValue.toString());
  }

  if (visualDataType === 'Date' && dateFormat) {
    const dateValue = getCustomDateFormat(variableValue.toString(), dateFormat);
    return dateValue;
  }

  if (visualDataType === 'Boolean') {
    return options.formatBooleanValue ? options.formatBooleanValue(variableValue.toString()) : variableValue.toString();
  }

  return variableValue;
};

export default formatValueByDataType;
