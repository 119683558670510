import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getEmails, SortEmailsAction } from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';

const isSortEmailsAction = (action: AnyAction): action is SortEmailsAction =>
  action.type === LoanOriginationSystemEmailsActionTypes.SortEmails;

const SortEmailsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortEmailsAction(action)) {
    const { loanOriginationSystemEmails } = getState();
    const { field, ascending, borrowerId } = action.payload;
    dispatch(
      getEmails(
        {
          ...loanOriginationSystemEmails.filters,
          selectedBorrower: borrowerId || '',
        },
        {
          field,
          ascending,
        },
      ),
    );
  }

  return result;
}) as Middleware;

export default SortEmailsMiddleware;
