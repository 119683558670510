import Fetcher from 'middlewares/Fetcher';
import { BorrowerVariableConfigurationsApi } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import { BulkUpdateVariableConfigurationParams } from 'api/LoanOriginationSystem/Types';
import {
  BulkUpdateBorrowerVariableConfigurationsAction,
  bulkUpdateBorrowerVariableConfigurationsFailure,
  bulkUpdateBorrowerVariableConfigurationsSuccess,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const BulkUpdateBorrowerVariableConfigurationsMiddleware = (api: BorrowerVariableConfigurationsApi) =>
  Fetcher<BulkUpdateVariableConfigurationParams[], BulkUpdateBorrowerVariableConfigurationsAction>(
    VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurations,
    bulkUpdateBorrowerVariableConfigurationsSuccess,
    bulkUpdateBorrowerVariableConfigurationsFailure,
    async (action) => {
      await api.bulkUpdate(action.payload.configurations);

      return action.payload.configurations;
    },
  );

export default BulkUpdateBorrowerVariableConfigurationsMiddleware;
