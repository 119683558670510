import { connect } from 'react-redux';
import ListHeader from 'components/ListHeader';
import { StrategyGroupsState } from 'StrategyGroupsData/Reducer';
import {
  getStrategyGroupsData,
  setShowArchived,
  setStrategyGroupsSearchInput,
  setUpdaters,
} from 'StrategyGroupsData/Actions';

export type HeaderConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ strategyGroups }: { strategyGroups: StrategyGroupsState }) => {
  return {
    updaters: strategyGroups.updaters,
    selectedUpdaters: strategyGroups.selectedUpdaters,
    showArchived: strategyGroups.showArchived,
    searchInputValue: strategyGroups.searchInputValue,
  };
};

const mapDispatchToProps = {
  setUpdaters,
  setShowArchived,
  fetchListData: getStrategyGroupsData,
  setSearchInputValue: setStrategyGroupsSearchInput,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListHeader);
