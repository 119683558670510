import React from 'react';
import styles from './ErrorPage.module.scss';
import Footer from 'components/Footer';
import FirstWaveForGeneralErrorBackground from 'static/images/general-error-waves-first.png';
import SecondWaveForGeneralErrorBackground from 'static/images/general-error-waves-second.png';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import { DigifiAuthLogoBlack } from 'static/images';

const ErrorPage = () => {
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorInfo}>
        <DigifiAuthLogoBlack />
        <ErrorPageInfo errorType={ErrorPageType.SystemError} errorTitle="Oops. An error has occurred." />
        <Footer separator />
      </div>
      <div className={styles.errorImage}>
        <img src={SecondWaveForGeneralErrorBackground} alt="" />
        <img src={FirstWaveForGeneralErrorBackground} alt="" />
      </div>
    </div>
  );
};

export default ErrorPage;
