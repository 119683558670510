export enum LoanOriginationSystemApplicationTabsActionTypes {
  GetApplicationTabs = 'applicationTabs/getApplicationTabs',
  GetApplicationTabsSuccess = 'applicationTabs/getApplicationTabsSuccess',
  GetApplicationTabsFailure = 'applicationTabs/getApplicationTabsFailure',
  UpdateApplicationTab = 'applicationTabs/updateApplicationTab',
  UpdateApplicationTabSuccess = 'applicationTabs/updateApplicationTabSuccess',
  UpdateApplicationTabFailure = 'applicationTabs/updateApplicationTabFailure',
  CreateApplicationTab = 'applicationTabs/createApplicationTab',
  CreateApplicationTabSuccess = 'applicationTabs/createApplicationTabSuccess',
  CreateApplicationTabFailure = 'applicationTabs/createApplicationTabFailure',
  DeleteApplicationTab = 'applicationTabs/deleteApplicationTab',
  DeleteApplicationTabSuccess = 'applicationTabs/deleteApplicationTabSuccess',
  DeleteApplicationTabFailure = 'applicationTabs/deleteApplicationTabFailure',
}
