export const GET_INTERMEDIARIES = 'GET_INTERMEDIARIES';
export const GET_INTERMEDIARIES_SUCCESS = 'GET_INTERMEDIARIES_SUCCESS';
export const GET_INTERMEDIARIES_FAILED = 'GET_INTERMEDIARIES_FAILED';
export const SORT_INTERMEDIARIES = 'SORT_INTERMEDIARIES';
export const OPEN_DELETE_INTERMEDIARY_POPUP = 'OPEN_DELETE_INTERMEDIARY_POPUP';
export const CLOSE_DELETE_INTERMEDIARY_POPUP = 'CLOSE_DELETE_INTERMEDIARY_POPUP';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS';
export const GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE = 'GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE';
export const DELETE_INTERMEDIARY = 'DELETE_INTERMEDIARY';
export const DELETE_INTERMEDIARY_SUCCESS = 'DELETE_INTERMEDIARY_SUCCESS';
export const DELETE_INTERMEDIARY_FAILURE = 'DELETE_INTERMEDIARY_FAILURE';

export enum LoanOriginationSystemIntermediariesActionType {
  ResetState = 'loanOriginationSystemIntermediaries/resetState',
}
