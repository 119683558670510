import { RuleCreateOptionsType } from 'components/NewRulePopUp';
import {
  ComplexRuleOrImage,
  ComplexRuleAndImage,
  ImportRuleImage,
  UploadRuleImage,
  SimpleRuleImage,
  CreateNew,
} from 'static/images';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';

const defaultOptions: RuleCreateOptionsType[] = [
  {
    Image: SimpleRuleImage,
    title: 'Simple Rule',
    description: 'A rule with a single comparative condition',
    type: 'simple',
  },
  {
    Image: ComplexRuleAndImage,
    title: 'Complex Rule: and',
    description: 'A rule with multiple comparative conditions that all must pass',
    type: 'AND',
  },
  {
    Image: ComplexRuleOrImage,
    title: 'Complex Rule: or',
    description: 'A rule with multiple comparative conditions, of which only one must pass',
    type: 'OR',
  },
  {
    Image: UploadRuleImage,
    title: 'Upload Entire Rule Set',
    description: 'From a CSV file',
    type: 'upload',
  },
  {
    Image: ImportRuleImage,
    title: 'Import Entire Rule Set',
    description: 'From an existing Strategy',
    type: 'import',
  },
];

const calculationScriptOptions: RuleCreateOptionsType[] = [
  {
    Image: CreateNew,
    title: 'Add New Script',
    description: 'JavaScript calculation that assigns the result to an Output Variable',
    type: 'addScript',
  },
  {
    Image: UploadRuleImage,
    title: 'Upload Entire Set of Calculations',
    description: 'From a CSV file',
    type: 'upload',
  },
  {
    Image: ImportRuleImage,
    title: 'Import Entire Set of Calculations ',
    description: 'From an existing Strategy',
    type: 'import',
  },
];

const simpleOutputsOptions: RuleCreateOptionsType[] = [
  {
    Image: CreateNew,
    title: 'Add New Output',
    description: 'Assign a value to an Output Variable',
    type: 'addOutput',
  },
  {
    Image: UploadRuleImage,
    title: 'Upload Entire Set of Outputs',
    description: 'From a CSV file',
    type: 'upload',
  },
  {
    Image: ImportRuleImage,
    title: 'Import Entire Set of Outputs',
    description: 'From an existing Strategy',
    type: 'import',
  },
];

const getOptionsByModuleType = (moduleType?: ModuleType) => {
  if (moduleType === 'calculations') return calculationScriptOptions;
  if (moduleType === 'assignments') return simpleOutputsOptions;
  return defaultOptions;
};

export default getOptionsByModuleType;
