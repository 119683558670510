import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import { ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { CloseButtonImage } from 'static/images';
import Button from 'components/Button';
import FieldsTable from 'components/DocuSign/FieldsTable';
import RecipientsTable from 'components/DocuSign/RecipientsTable';
import StyledSwitch from 'components/StyledSwitch';
import { SkeletonRectangle } from 'components/Skeleton';
import QuestionIcon from 'components/QuestionIcon';
import WrapperWithTooltip from 'components/Tooltip';
import styles from './EditTemplate.module.scss';

interface EditTemplateProps {
  template: ESignTemplate | null;
  onClose: () => void;
  onVariablesMappingPopUpOpen: () => void;
  onRecipientsMappingPopupOpen: () => void;
  onChangeTemplateActive: (active: boolean) => void;
  loadTemplate: () => void;
}

const areTemplateVariablesUnmapped = (template: ESignTemplate) => {
  return (
    Object.values(template.variablesMapping).length !== template.fields.length ||
    Object.values(template.recipientsMapping).length !== template.recipients.length
  );
};

const EditTemplate: FC<EditTemplateProps> = ({
  template,
  onClose,
  onVariablesMappingPopUpOpen,
  onRecipientsMappingPopupOpen,
  onChangeTemplateActive,
  loadTemplate,
}) => {
  useEffect(() => {
    if (!template) {
      loadTemplate();
    }
  }, []);

  const renderTitle = () => {
    if (!template) {
      return <SkeletonRectangle width="347px" height="44px" color="primary6" />;
    }

    return <h2>{template.name}</h2>;
  };

  const renderSwitchText = () => {
    if (!template) {
      return <SkeletonRectangle width="68px" height="20px" color="primary6" />;
    }

    return (
      <p className={clsx(template.active && styles.activeSwitchText)}>
        {template.active ? 'Activated' : 'Not Activated'}
      </p>
    );
  };

  const renderRecipientsMappingSection = () => {
    if (template && !template.recipients.length) {
      return null;
    }

    return (
      <div className={styles.section}>
        <h4 className={styles.tableTitle}>Recipient Mapping</h4>
        <RecipientsTable template={template} />
        {template && (
          <Button
            className={styles.editRecipientsMappingButton}
            disabled={!template}
            type="submit"
            kind="secondary"
            onClick={onRecipientsMappingPopupOpen}
          >
            Edit Recipient Mapping
          </Button>
        )}
      </div>
    );
  };

  const renderVariablesMappingSection = () => {
    if (template && !template.fields.length) {
      return null;
    }

    return (
      <div className={styles.section}>
        <div className={styles.fieldsSectionHeader}>
          <h4 className={styles.tableTitle}>Field Mapping</h4>
          <QuestionIcon
            className={styles.questionIcon}
            tooltip={
              <div className={styles.questionTooltip}>
                <p>Includes recipient fields tagged as Read Only in DocuSign.</p>
                <p>These will pre-populate when the template is sent.</p>
              </div>
            }
            size={24}
          />
        </div>
        <FieldsTable template={template} />
        {template && (
          <Button
            className={styles.editVariablesMappingButton}
            disabled={!template}
            type="submit"
            kind="secondary"
            onClick={onVariablesMappingPopUpOpen}
          >
            Edit Variable Mapping
          </Button>
        )}
      </div>
    );
  };

  const getSwitchTooltip = () => {
    if (!template) {
      return '';
    }

    return areTemplateVariablesUnmapped(template) ? 'Please map all recipients and fields before activating.' : '';
  };

  return (
    <div className={styles.editTemplate}>
      <div className={styles.editTemplateHeader}>
        <div className={styles.headerInnerContainer}>
          {renderTitle()}
          <div className={styles.switchContainer}>
            <WrapperWithTooltip tooltip={getSwitchTooltip()}>
              <div>
                <StyledSwitch
                  checked={!!template?.active}
                  disabled={!template || areTemplateVariablesUnmapped(template)}
                  onChange={(event, checked) => onChangeTemplateActive(checked)}
                />
              </div>
            </WrapperWithTooltip>
            {renderSwitchText()}
          </div>
        </div>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.editTemplateContent}>
        <p className={styles.editTemplateDescription}>
          Set basic information and map template fields to system variables to populate dynamic data.
        </p>
        <div>
          {renderRecipientsMappingSection()}
          {renderVariablesMappingSection()}
        </div>
      </div>
    </div>
  );
};

export default EditTemplate;
