import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  CreateApplicationAction,
  CreateApplicationSuccessAction,
  LoadBorrowersSuggestionsSuccessAction,
  LoadIntermediarySuggestionsSuccessAction,
  SelectBorrowerAction,
  SelectBorrowerToEdit,
  SelectCoBorrowerAction,
  SelectIntermediaryAction,
  SelectIntermediaryToEditAction,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { UnlockBorrowerSuccessAction } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import {
  UpdateBorrowerSuccessAction,
  UpdateBorrowerSuccessActionOrigin,
} from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import {
  CREATE_APPLICATION,
  CREATE_APPLICATION_FAILED,
  CREATE_APPLICATION_SUCCESS,
  LOAD_BORROWERS_SUGGESTIONS_SUCCESS,
  LoanOriginationSystemCreateApplicationActionType,
  SELECT_BORROWER,
  SELECT_BORROWER_TO_EDIT,
  SELECT_CO_BORROWER,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import { LoanOriginationSystemCreateApplicationState } from 'LoanOriginationSystemApplications/CreateApplication/Types';
import { LoanOriginationSystemUnlockBorrowerType } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionTypes';
import { LoanOriginationSystemEditBorrowerActionType } from 'LoanOriginationSystemBorrowers/EditBorrower/ActionTypes';
import { UpdateIntermediarySuccessAction } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from 'LoanOriginationSystemIntermediariesPage/EditIntermediary/ActionTypes';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { FailedAction } from 'utils/actions/FailedActionCreator';

export const createApplicationInitialState: LoanOriginationSystemCreateApplicationState = {
  borrowerSuggestions: [],
  intermediarySuggestions: [],
  selectedBorrower: null,
  selectedCoBorrower: null,
  selectedIntermediary: null,
  borrowerToEdit: null,
  intermediaryToEdit: null,
  isCreating: false,
};

const unlockBorrower = (borrower: Borrower | null, borrowerId: string) => {
  if (!borrower) {
    return null;
  }

  return {
    ...borrower,
    locked: borrower.id === borrowerId ? false : borrower.locked,
  };
};

export const getUpdatedBorrower = (borrower: Borrower | null, newBorrower: Borrower) => {
  if (!borrower) {
    return null;
  }

  return borrower.id === newBorrower.id ? newBorrower : borrower;
};

export const getUpdatedIntermediary = (intermediary: Intermediary | null, newIntermediary: Intermediary) => {
  if (!intermediary) {
    return null;
  }

  return intermediary.id === newIntermediary.id ? newIntermediary : intermediary;
};

type LoanOriginationSystemCreateApplicationsActions =
  | LoadBorrowersSuggestionsSuccessAction
  | LoadIntermediarySuggestionsSuccessAction
  | SelectBorrowerAction
  | SelectCoBorrowerAction
  | CreateApplicationAction
  | CreateApplicationSuccessAction
  | UpdateBorrowerSuccessAction
  | SelectBorrowerToEdit
  | UnlockBorrowerSuccessAction
  | SelectIntermediaryAction
  | SelectIntermediaryToEditAction
  | UpdateIntermediarySuccessAction
  | FailedAction<typeof CREATE_APPLICATION_FAILED>;

export const loanOriginationSystemCreateApplicationReducer = (
  state: LoanOriginationSystemCreateApplicationState = createApplicationInitialState,
  action: LoanOriginationSystemCreateApplicationsActions,
): LoanOriginationSystemCreateApplicationState => {
  switch (action.type) {
    case LOAD_BORROWERS_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        borrowerSuggestions: action.payload.suggestions,
      };
    case LoanOriginationSystemCreateApplicationActionType.LoadIntermediarySuggestionsSuccess:
      return {
        ...state,
        intermediarySuggestions: action.payload.suggestions,
      };
    case LoanOriginationSystemUnlockBorrowerType.UnlockBorrowerSuccess:
      return {
        ...state,
        selectedBorrower: unlockBorrower(state.selectedBorrower, action.payload),
        selectedCoBorrower: unlockBorrower(state.selectedCoBorrower, action.payload),
      };
    case SELECT_BORROWER: {
      return {
        ...state,
        selectedBorrower: action.payload.borrower,
      };
    }
    case SELECT_CO_BORROWER: {
      return {
        ...state,
        selectedCoBorrower: action.payload.borrower,
      };
    }
    case LoanOriginationSystemCreateApplicationActionType.SelectIntermediary:
      return {
        ...state,
        selectedIntermediary: action.payload.intermediary,
      };
    case CREATE_APPLICATION:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_APPLICATION_SUCCESS:
      return {
        ...state,
        selectedBorrower: null,
        selectedCoBorrower: null,
        borrowerSuggestions: [],
        isCreating: false,
      };
    case CREATE_APPLICATION_FAILED:
      return {
        ...state,
        isCreating: false,
      };
    case LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess: {
      return {
        ...state,
        selectedBorrower: getUpdatedBorrower(state.selectedBorrower, action.payload),
        selectedCoBorrower: getUpdatedBorrower(state.selectedCoBorrower, action.payload),
        borrowerToEdit:
          action.meta?.actionOrigin === UpdateBorrowerSuccessActionOrigin.CreateApplication
            ? null
            : state.borrowerToEdit,
      };
    }
    case SELECT_BORROWER_TO_EDIT: {
      return {
        ...state,
        borrowerToEdit: action.payload,
      };
    }
    case LoanOriginationSystemCreateApplicationActionType.SelectIntermediaryToEdit: {
      return {
        ...state,
        intermediaryToEdit: action.payload.intermediary,
      };
    }
    case LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess:
      return {
        ...state,
        selectedIntermediary: getUpdatedIntermediary(state.selectedIntermediary, action.payload),
        intermediaryToEdit: null,
      };
    default:
      return state;
  }
};
