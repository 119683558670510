enum PageName {
  CompanyInformation = 'Company Information',
  RegionSettings = 'Region Settings',
  PlatformUsers = 'Platform Users',
  AccessPermissions = 'Access Permissions',
  DecisionStrategies = 'Decision Strategies',
  DigiFiApi = 'DigiFi API',
  DocuSignIntegration = 'DocuSign Integration',
  Variables = 'Variables',
  IndividualProcessing = 'Individual Processing',
  ApiActivation = 'API Activation',
  BatchProcessing = 'Batch Processing',
  SendGridIntegration = 'SendGrid Integration',
  PlanAndBilling = 'Plan & Billing',
}

export default PageName;
