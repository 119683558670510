const borrowerTypesData: {
  id: string;
  label: string;
}[] = [
  {
    id: 'Affiliate',
    label: 'Affiliate',
  },
  {
    id: 'Broker',
    label: 'Broker',
  },
  {
    id: 'Contractor',
    label: 'Contractor',
  },
  {
    id: 'Correspondent',
    label: 'Correspondent',
  },
  {
    id: 'Dealer',
    label: 'Dealer',
  },
  {
    id: 'Retailer',
    label: 'Retailer',
  },
  {
    id: 'Other',
    label: 'Other',
  },
];

export default borrowerTypesData;
