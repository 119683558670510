import { DEFAULT_DATE_FORMAT } from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';

export enum DateTimeFormat {
  Long = 'MMM D, YYYY, h:mm A',
  Short = 'lll',
  LongWithSeconds = 'MMM D, YYYY h:mm:ss A',
  ShortDate = 'MMMM DD, YYYY',
  LongWithDayOfTheWeek = 'dddd, MMMM D, YYYY h:mm A',
}

const formatDate = (date: string | Moment | Date, format: string = DEFAULT_DATE_FORMAT) => {
  return moment(date).format(format);
};

export default formatDate;
