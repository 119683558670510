import { ReduxState } from 'types/redux';
import createSelectorWithDispatch from 'hooks/createSelectorWithDispatch';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';

const useOrganizationDetailedInfo = createSelectorWithDispatch(
  getOrganizationInfo,
  (state: ReduxState) => state.organizationInformation,
);

export default useOrganizationDetailedInfo;
