import React from 'react';
import styles from './ActionsBar.module.scss';
import AutoCompletion from 'components/AutoCompletion';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import { Option } from 'components/SelectInput/SelectInput';
import { ReportingTimeDeltas } from 'LoanOriginationSystemReporting/Filters/Types';

interface ActionsBarProps {
  timeDelta: ReportingTimeDeltas;
  onTimeDeltaChange: (value: Option) => void;
}

const ActionsBar = ({ timeDelta, onTimeDeltaChange }: ActionsBarProps) => {
  const options = variableOptionsListToSelectInputOptions(Object.values(ReportingTimeDeltas));

  return (
    <div className={styles.container}>
      <AutoCompletion
        options={options}
        onChange={onTimeDeltaChange}
        className={styles.inputZeroMarginTop}
        value={timeDelta}
        hideClearIcon
        selectControlClassName={styles.changeHeight}
      />
    </div>
  );
};

export default ActionsBar;
