import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ReportingMonthInfo } from 'LoanOriginationSystemReporting/Applications/Types';
import moment from 'moment';
import { getMaxOfArray } from 'utils/getMaxOfArray';
import { getAxisMax } from './Chart/utils';
import { Parser } from 'json2csv';
import { isEmpty } from 'lodash';

const useGetReportingPageData = (
  reportingEntitiesMap: Record<string, ReportingMonthInfo>,
  statuses: ApplicationStatus[],
) => {
  const getTotalAmount = (type: string) => {
    return Object.keys(reportingEntitiesMap).reduce((totalAmount: number, entity: string) => {
      const totalForMonth = (Object.values(reportingEntitiesMap[entity][type]) as number[]).reduce(
        (monthAmount: number, value: number) => {
          return monthAmount + value;
        },
        0,
      ) as number;
      return totalAmount + totalForMonth;
    }, 0);
  };

  const dataByMonth = Object.keys(reportingEntitiesMap).map((entity) => {
    const totalAmountByMonth = Object.values(reportingEntitiesMap[entity].amount).reduce((acc, value) => {
      return acc + value;
    }, 0);

    const totalNumberByMonth = Object.values(reportingEntitiesMap[entity].number).reduce((acc, value) => {
      return acc + value;
    }, 0);

    return {
      date: entity,
      Number: totalNumberByMonth,
      Amount: totalAmountByMonth,
    };
  });

  const statusesWithCount = statuses.map((status) => {
    const statusCommonAmount = Object.keys(reportingEntitiesMap).reduce((total, entity) => {
      const statusAmount = reportingEntitiesMap[entity].number[status.name];
      return total + statusAmount;
    }, 0);

    return {
      name: status.name,
      count: statusCommonAmount || 0,
    };
  });

  const getChartData = () => {
    const AXIS_FACTORS_ARRAY = [0, 1, 2, 3, 4];

    const maxAmount = dataByMonth.length && getMaxOfArray(dataByMonth.map((data) => data.Amount));
    const maxNumber = dataByMonth.length && getMaxOfArray(dataByMonth.map((data) => data.Number));
    const maxYLeftAxisAmount = maxAmount && getAxisMax(maxAmount);
    const yLeftAxisStep = maxYLeftAxisAmount / 4;
    const maxYRightAxisAmount = maxNumber && getAxisMax(maxNumber);
    const yRightAxisStep = maxYRightAxisAmount / 4;

    const yLeftAxisValues = AXIS_FACTORS_ARRAY.map((value) => value * yLeftAxisStep);
    const yRightAxisValues = AXIS_FACTORS_ARRAY.map((value) => value * yRightAxisStep);

    const factor = maxYLeftAxisAmount / maxYRightAxisAmount;

    return {
      amountAxis: yLeftAxisValues,
      numberAxis: yRightAxisValues,
      factor,
    };
  };

  return {
    applicationsAmount: getTotalAmount('amount'),
    applicationsNumber: getTotalAmount('number'),
    dataByMonth,
    chartData: getChartData(),
    statusesWithCount,
  };
};

export const formatDate = (date: string) => moment(date).format('MMM YYYY');

export const formatReportingEntitiesForCSV = (reportingEntitiesMap: Record<string, ReportingMonthInfo>) => {
  if (isEmpty(reportingEntitiesMap)) {
    return '';
  }
  const amountList = Object.keys(reportingEntitiesMap).map((entity) => {
    const amountMap = reportingEntitiesMap[entity].amount;
    return {
      Date: formatDate(entity),
      ...amountMap,
    };
  });
  const numberList = Object.keys(reportingEntitiesMap).map((entity) => {
    const numberMap = reportingEntitiesMap[entity].number;
    return {
      Date: formatDate(entity),
      ...numberMap,
    };
  });

  const fields = Object.keys(numberList[0]);
  const newLine = '\r\n';
  const json2csv = new Parser({ fields });
  const amountCsv = json2csv.parse(amountList);
  const numberCsv = json2csv.parse(numberList);
  const amountCsvHeader = ', Amount';
  const numberCsvHeader = ', Number';
  const resultCsv = amountCsvHeader + newLine + amountCsv + newLine + newLine + numberCsvHeader + newLine + numberCsv;
  const csvDownloadUrl = `data:attachment/csv,${encodeURIComponent(resultCsv)}`;

  return csvDownloadUrl;
};

export const getChartBarKeys = (currency?: string) => {
  return [`Amount${currency ? ` (${currency})` : ''}`, 'Number'];
};

export default useGetReportingPageData;
