import React, { ReactNode, useState } from 'react';
import { DragDropContext, DragStart, DropResult, DragUpdate, BeforeCapture } from 'react-beautiful-dnd';
import { calculateHorizontalPlaceholderOffset, calculateVerticalPlaceholderOffset } from './calculatePlaceholderOffset';

interface DndListContextProps {
  children: (placeholderStyle: React.CSSProperties | null) => ReactNode;
  onDragStart?: (event: DragStart) => void;
  onDragEnd?: (result: DropResult) => void;
  onDragUpdate?: (event: DragUpdate) => void;
  onBeforeCapture?: (event: BeforeCapture) => void;
  onReorder: (result: DropResult) => void;
  direction?: 'vertical' | 'horizontal';
}

const DRAGGABLE_ID_ATTRIBUTE = 'data-rbd-drag-handle-draggable-id';
const DROPPABLE_ID_ATTRIBUTE = 'data-rbd-droppable-id';

const DndListContext = ({
  children,
  onReorder,
  onBeforeCapture,
  onDragEnd,
  onDragUpdate,
  onDragStart,
  direction = 'vertical',
}: DndListContextProps) => {
  const [placeholderStyle, setPlaceholderStyle] = useState<React.CSSProperties | null>(null);

  const getDraggableElement = (draggableId: string) =>
    document.querySelector(`[${DRAGGABLE_ID_ATTRIBUTE}='${draggableId}']`);

  const getDroppableElement = (droppableId: string) =>
    document.querySelector(`[${DROPPABLE_ID_ATTRIBUTE}='${droppableId}']`);

  const getHorizontalPlaceholderProps = (
    draggedItem: Element,
    droppableContainer: Element,
    targetItemIndex: number,
    sourceItemIndex: number,
  ) => {
    const { clientWidth } = draggedItem;

    const offsetLeft = calculateHorizontalPlaceholderOffset(
      droppableContainer,
      draggedItem,
      targetItemIndex,
      sourceItemIndex,
    );

    return {
      height: droppableContainer.clientHeight,
      width: clientWidth,
      top: parseFloat(window.getComputedStyle(droppableContainer).paddingTop),
      left: offsetLeft,
    };
  };

  const getVerticalPlaceholderProps = (
    draggedItem: Element,
    droppableContainer: Element,
    targetItemIndex: number,
    sourceItemIndex: number,
  ) => {
    const { clientHeight } = draggedItem;

    const offsetTop = calculateVerticalPlaceholderOffset(
      droppableContainer,
      draggedItem,
      targetItemIndex,
      sourceItemIndex,
    );

    return {
      height: clientHeight,
      width: droppableContainer.clientWidth,
      top: offsetTop,
      left: parseFloat(window.getComputedStyle(droppableContainer).paddingLeft),
    };
  };

  const updatePlaceholderProps = (
    draggedItem: Element,
    droppableContainer: Element,
    targetItemIndex: number,
    sourceItemIndex: number,
  ) => {
    const placeholderProps =
      direction === 'vertical'
        ? getVerticalPlaceholderProps(draggedItem, droppableContainer, targetItemIndex, sourceItemIndex)
        : getHorizontalPlaceholderProps(draggedItem, droppableContainer, targetItemIndex, sourceItemIndex);

    setPlaceholderStyle(placeholderProps);
  };

  const handleDragStart = (event: DragStart) => {
    const draggedItem = getDraggableElement(event.draggableId);

    onDragStart?.(event);

    if (!draggedItem) {
      return;
    }

    updatePlaceholderProps(draggedItem, draggedItem.parentElement as Element, event.source.index, event.source.index);
  };

  const handleDragUpdate = (event: DragUpdate) => {
    onDragUpdate?.(event);

    const draggedItem = getDraggableElement(event.draggableId);
    const droppableContainer = event.destination && getDroppableElement(event.destination.droppableId);

    if (!draggedItem || !event.destination || !droppableContainer) {
      return;
    }

    updatePlaceholderProps(draggedItem, droppableContainer, event.destination.index, event.source.index);
  };

  const handleDragEnd = (result: DropResult) => {
    setPlaceholderStyle(null);

    onDragEnd?.(result);

    if (!result.destination) {
      return;
    }

    onReorder(result);
  };

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCapture}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
      onDragEnd={handleDragEnd}
    >
      {children(placeholderStyle)}
    </DragDropContext>
  );
};

export default DndListContext;
