import useOnce from 'hooks/useOnce';
import { getStandardVariables } from 'LoanOriginationSystemOrganization/ActionCreator';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { SimplifiedVariable } from 'Variables/VariablesTypes';

const useStandardVariables = () => {
  const dispatch = useDispatch();

  const { standardVariables } = useSelector((state: ReduxState) => state.loanOriginationSystemOrganization);

  const loadStandardVariables = useOnce(() => {
    dispatch(getStandardVariables());
  });

  useEffect(() => {
    if (!standardVariables) {
      loadStandardVariables();
    }
  }, []);

  return useMemo(() => {
    if (!standardVariables) {
      return null;
    }

    return standardVariables.reduce((standardVariablesBySystemName, standardVariable) => {
      return { ...standardVariablesBySystemName, [standardVariable.systemName]: standardVariable };
    }, {} as Record<string, SimplifiedVariable>);
  }, [standardVariables]);
};

export default useStandardVariables;
