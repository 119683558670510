import { AnyAction } from 'redux';
import { SnackbarActionType } from './ActionTypes';
import { SnackbarComponents, SnackType } from './Types';

const initialState: SnackType<SnackbarComponents>[] = [];

const snackbarReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SnackbarActionType.ShowSnack:
      return [...state, action.payload];

    case SnackbarActionType.HideSnack:
      return state.filter((notification) => notification.id !== action.payload.id);

    default:
      return state;
  }
};

export default snackbarReducer;
