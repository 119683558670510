import React from 'react';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import BillingDashboard from 'components/BillingDashboard';
import { useHistory, useParams } from 'react-router';
import BillingTab from 'BillingPage/BillingTab';
import RouteService from 'routes/RouteService';

const leftNav = makeLeftNavigation(NavigationLinkId.PlanAndBilling, ApplicationSectionName.CompanySettings);

interface RouteParams {
  tab?: string;
}

const Billing = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const tab = params.tab === BillingTab.Invoices.toLowerCase() ? BillingTab.Invoices : BillingTab.Overview;

  const handleTabChange = (newTab: BillingTab) => {
    history.push(RouteService.getBillingPage(newTab));
  };

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapperWithFooter>
        <PageContent>
          <BillingDashboard tab={tab} onTabChange={handleTabChange} />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Billing;
