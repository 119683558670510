import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { AutomatedProcess, AutomatedProcessCategory, AutomatedProcessGroup } from 'api/LoanOriginationSystem/ProcessAutomationApi';

export const AUTOMATED_PROCESSES_PER_PAGE_DEFAULT = 8;

export interface ProcessAutomationPaginationParams {
  group: AutomatedProcessGroup;
  category?: AutomatedProcessCategory;
}

const processAutomationPagination = Pagination<ReduxState, AutomatedProcess, ProcessAutomationPaginationParams>(
  'loanOriginationSystemAutomatedProcesses',
  AUTOMATED_PROCESSES_PER_PAGE_DEFAULT,
);

export default processAutomationPagination;
