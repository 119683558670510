import Debouncer from 'middlewares/Debouncer';
import { ApiActivationActionType } from './ActionTypes';
import { getApiActivationsRequest } from './ActionCreator';

export default Debouncer(
  (payload: string, getState) => {
    const { apiActivation } = getState();
    const { showArchived, searchInputValue, selectedUpdaters, versionsStatuses, sortingType } = apiActivation;

    return getApiActivationsRequest({
      showArchived,
      searchInputValue,
      selectedUpdaters,
      versionsStatuses,
      sortingType,
    });
  },
  [ApiActivationActionType.SetStrategyGroupsSearchInput],
);
