import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import * as Sentry from '@sentry/browser';
// @ts-ignore
import AppRoutes from 'routes';
import store, {
  history,
  decisionEngineApi,
  variablesApi,
  downloadApi,
  assetsApi,
  labelsApi,
  organizationCoreApi,
  authHandler,
} from 'stores';
import {
  applicationDocumentsApi,
  loanOriginationSystemProductsApi,
  loanOriginationSystemEmailsApi,
  eSignTemplatesApi,
} from 'stores/loanOriginationSystem';
import ErrorBoundary from 'components/ErrorBoundary';
import Dev from './Dev/Dev';
import ReactGA from 'react-ga';

import 'whatwg-fetch';
import 'babel-polyfill';
import 'animate.css/animate.css';
import 'semantic-ui-css/semantic.min.css';
import 'styles/basic.scss';

import './index.scss';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import DebouncingAccumulator from 'utils/DebouncingAccumulator';
import { getVariablesById } from 'VariablesAttributes/Actions';
import { VariablesRequesterContextProvider } from 'hooks/useVariableDataType';
import ApiProvider, { ApiProviderValue } from './providers/ApiServiceProvider';
import AuthProvider from 'providers/AuthProvider';
import AppInitializer from 'AppInitializer';
import CaptchaProvider from 'CaptchaProvider';
import { subscribeToHistoryChange } from 'RedirectionsMiddleware';
import { LayoutMountPoint } from 'layout';
import GlobalOverlays from 'pages/Main';

Sentry.init({
  dsn: 'https://2cee2ac49cae45eb8a1da42040a2b825@sentry.io/2969933',
  blacklistUrls: ['localhost:3000/'],
});

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
if (trackingId) {
  ReactGA.initialize(trackingId);
}

const theme = createMuiTheme();

const apiProviderValue: ApiProviderValue = {
  downloadApi,
  decisionEngineApi,
  variablesApi,
  productsApi: loanOriginationSystemProductsApi,
  applicationDocumentsApi,
  emailsApi: loanOriginationSystemEmailsApi,
  assetsApi,
  eSignTemplatesApi,
  labelsApi,
  organizationApi: organizationCoreApi,
};

const variableRequester = DebouncingAccumulator(async (variableIds: string[]) => {
  store.dispatch(getVariablesById(variableIds));
  return [] as void[];
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ErrorBoundary>
      {/* @ts-ignore */}
      <Provider store={store}>
        <CaptchaProvider>
          <AuthProvider value={authHandler}>
            <VariablesRequesterContextProvider value={variableRequester}>
              <Router history={history}>
                <ApiProvider value={apiProviderValue}>
                  <LayoutMountPoint>
                    <AppInitializer>
                      <AppRoutes />
                      {process.env.NODE_ENV === 'production' ? null : <Dev />}
                    </AppInitializer>
                  </LayoutMountPoint>
                  <GlobalOverlays />
                </ApiProvider>
              </Router>
            </VariablesRequesterContextProvider>
          </AuthProvider>
        </CaptchaProvider>
      </Provider>
    </ErrorBoundary>
  </ThemeProvider>,
  document.getElementById('root'),
  /**
   * It is important to subscribe to history changes after ReactRouter does,
   * otherwise synchronous calls to push()/replace() from the history.listen callback are silently swallowed
   * without triggering a re-render which leads to the UI state not being consistent with the address bar.
   */
  () => store.dispatch(subscribeToHistoryChange()),
);
