export enum LoanOriginationSystemReportingActionType {
  OpenFiltersBar = 'LoanOriginationSystemReporting/openFiltersBar',
  CloseFiltersBar = 'LoanOriginationSystemReporting/closeFiltersBar',
  ChangeFilterState = 'LoanOriginationSystemReporting/changeFilterState',
  GetFilterData = 'LoanOriginationSystemReporting/getFilterData',
  GetFilterDataSuccess = 'LoanOriginationSystemReporting/getFilterDataSuccess',
  GetFilterDataFailure = 'LoanOriginationSystemReporting/getFilterDataFailure',
  ChangeTimeDelta = 'LoanOriginationSystemReporting/changeTimeDelta',
  ToggleStatus = 'LoanOriginationSystemReporting/toggleStatus',
  GetFilteredData = 'LoanOriginationSystemReporting/getFilteredData',
  GetFilteredDataSuccess = 'LoanOriginationSystemReporting/getFilteredDataSuccess',
  GetFilteredDataFailure = 'LoanOriginationSystemReporting/getFilteredDataFailure',
  ResetFilterState = 'LoanOriginationSystemReporting/resetFilterState',
}
