import Fetcher from 'middlewares/Fetcher';
import { BorrowerVariableConfigurationsApi } from 'api/LoanOriginationSystem/BorrowerVariableConfigurationsApi';
import { BorrowerVariableConfiguration } from 'api/Types';
import {
  CreateBorrowerVariableConfigurationAction,
  createBorrowerVariableConfigurationSuccess,
  createBorrowerVariableConfigurationFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const CreateBorrowerVariableConfigurationMiddleware = (api: BorrowerVariableConfigurationsApi) =>
  Fetcher<BorrowerVariableConfiguration, CreateBorrowerVariableConfigurationAction>(
    VariablesConfigurationActionType.CreateBorrowerVariableConfiguration,
    createBorrowerVariableConfigurationSuccess,
    createBorrowerVariableConfigurationFailure,
    (action) => {
      return api.create({
        position: action.payload.position,
        column: action.payload.column,
        variable: action.payload.variable.id,
        borrowerType: action.payload.borrowerType,
      });
    },
  );

export default CreateBorrowerVariableConfigurationMiddleware;
