import React from 'react';
import { SIZES } from 'components/StrategyRunHistoryTable/columnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';
import { StrategyRunTableColumnDisplayName } from 'components/StrategyRunHistoryTable/types';

const SkeletonHistoryLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Date]}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Type]}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.DecisionName]}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.StrategyName]}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES[StrategyRunTableColumnDisplayName.Result]}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonHistoryLine;
