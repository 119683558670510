import { LoanOriginationSystemTemplatesFiltersState } from './Types';
import {
  TemplatesSearchInputValueSetAction,
  FilterTemplatesByMembersAction,
  ResetAllTemplatesFilters,
  ToggleFiltersPopupAction,
  ChangePopupFiltersAction,
} from './ActionCreator';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemTemplatesFiltersState = {
  searchInputValue: '',
  selectedMembers: [],
  dueCreatedDateRange: {
    from: null,
    to: null,
  },
  dueUpdatedDateRange: {
    from: null,
    to: null,
  },
  active: null,
  isPopupVisible: false,
};

export type LoanOriginationSystemTemplatesFilterAction =
  | TemplatesSearchInputValueSetAction
  | FilterTemplatesByMembersAction
  | ToggleFiltersPopupAction
  | ChangePopupFiltersAction
  | ResetAllTemplatesFilters;

export const loanOriginationSystemTemplatesFilterReducer = (
  state: LoanOriginationSystemTemplatesFiltersState,
  action: LoanOriginationSystemTemplatesFilterAction,
): LoanOriginationSystemTemplatesFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemTemplatesFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchInputValue,
      };
    }

    case LoanOriginationSystemTemplatesFiltersActionTypes.FilterTemplatesByMembers: {
      return {
        ...state,
        selectedMembers: action.payload.members,
      };
    }

    case LoanOriginationSystemTemplatesFiltersActionTypes.ToggleFiltersPopup: {
      return {
        ...state,
        isPopupVisible: !state.isPopupVisible,
      };
    }

    case LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters: {
      return {
        ...state,
        ...action.payload,
        isPopupVisible: false,
      };
    }

    case LoanOriginationSystemTemplatesFiltersActionTypes.ResetAllFilters: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
