import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ReduxState } from 'types/redux';
import { ImportableSendGridTemplate, SendGridState } from 'SendGrid/Types';
import { useDispatch, useSelector } from 'react-redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQueryParams } from 'hooks/useQueryParam';
import DeleteTemplatePopUp from 'components/SendGrid/DeleteTemplatePopUp';
import EditTemplate from 'components/SendGrid/EditTemplate';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import MappingVariablePopUp from 'components/SendGrid/MappingVariablePopUp';
import NavigationLinkId from 'enums/NavigationLinkId';
import pagination, { SendGridTemplatePaginationParams } from './Pagination';
import React, { ReactElement, useEffect, useState } from 'react';
import SendGrid from 'components/SendGrid';
import {
  closeDeleteTemplatePopUp,
  openDeleteTemplatePopUp,
  importTemplatesRequest,
  openImportTemplatePopUp,
  closeImportTemplatePopUp,
  openVariablesMappingPopUp,
  closeVariablesMappingPopUp,
  updateTemplateVariablesMappingRequest,
  SaveSendGridIntegrationRequest,
  GetSendGridIntegrationRequest,
  DeleteTemplateRequest,
  closeCreateVariablePopup,
  GetTemplatesRequest,
  GetTemplatesToImportRequest,
  getTemplate,
} from 'SendGrid/ActionCreator';
import { VariableClientType } from 'Variables/VariablesTypes';
import CreateVariablePopup from 'components/CreateVariablePopup';
import { getVariableType } from 'Variables/utils';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { CreateVariableActionOrigin, createVariableRequest } from 'Variables/VariablesActionCreator';
import { setTemplatesSearchInputValue } from 'SendGrid/Filters/ActionCreator';
import TemplatesFilter from 'components/SendGrid/TemplatesFilter';
import ImportTemplatesPopup from 'components/ImportTemplatesPopup';
import { SendGridTab } from 'components/SendGrid/SendGridTab';

const IMPORT_TEMPLATES_WARNING_MESSAGE =
  'Importing a template will automatically overwrite any matching template (based on the SendGrid Template ID), including your variable mapping. Only import templates that you would like to add or replace.';
const SEND_GRID_LINK = 'https://sendgrid.com/';

const SendGridPage = () => {
  const dispatch = useDispatch();
  const { tab: pageTab } = useParams<{ tab: SendGridTab }>();
  const history = useHistory();
  const params = useQueryParams();
  const currentTemplateId = params.get('edit');
  const dispatchRoutine = useDispatchRoutine();
  const saveIntegration = async (clientApiKey: string) => {
    await dispatchRoutine(SaveSendGridIntegrationRequest({ clientApiKey }));
  };

  const {
    integration,
    sortingType,
    filters,
    templateIdToDelete,
    isImportTemplatePopUpOpen,
    isVariableMappingPopUpOpen,
    isCreatingVariablePopupOpen,
    isCreatingVariableInProgress,
    importableTemplates,
    senderEmails,
    isUpdatingSenderEmail,
    isUpdatingVariablesMapping,
    isIntegrationLoaded,
    isImportInProgress,
    isDeleteTemplateInProgress,
    selectedTemplate,
  } = useSelector<ReduxState, SendGridState>(({ sendGrid }) => sendGrid);

  const [variablesMapping, setVariablesMapping] = useState<{}>({});

  const selectedTemplateInfo = selectedTemplate?.id === currentTemplateId ? selectedTemplate : null;

  const paginationParams: SendGridTemplatePaginationParams = {
    searchInputValue: filters.searchInputValue,
    sortingType,
    selectedMembers: filters.selectedMembers,
    active: filters.active,
    dueCreatedDateRange: filters.dueCreatedDateRange,
    dueUpdatedDateRange: filters.dueUpdatedDateRange,
  };

  const paginationProps = pagination.usePagination(paginationParams);
  const templates = pagination.usePaginatedItems(paginationParams);

  useEffect(() => {
    if (currentTemplateId) {
      dispatch(getTemplate(currentTemplateId));
    }
  }, [currentTemplateId]);

  useEffect(() => {
    if (isImportTemplatePopUpOpen) {
      dispatch(GetTemplatesToImportRequest());
    }
  }, [isImportTemplatePopUpOpen]);

  useEffect(() => {
    if (pageTab === 'templates') {
      dispatch(
        GetTemplatesRequest({
          filters,
          sortingType,
        }),
      );
    }
  }, [pageTab]);

  useEffect(() => {
    setVariablesMapping(selectedTemplateInfo ? selectedTemplateInfo.variables : {});
  }, [selectedTemplateInfo, isVariableMappingPopUpOpen]);

  useEffect(() => {
    dispatch(GetSendGridIntegrationRequest());
  }, []);

  const onTabChange = (tab: SendGridTab): void => {
    history.replace(tab);
  };

  const onSearchInputChange = (value: string) => dispatch(setTemplatesSearchInputValue(value));

  const onDeletePopupOpen = (id: string) => dispatch(openDeleteTemplatePopUp(id));
  const onDeletePopupClose = () => dispatch(closeDeleteTemplatePopUp());
  const onDeleteLabel = (templateId: string) => {
    dispatch(DeleteTemplateRequest(templateId));
  };

  const onImportTemplates = (templatesToImport: ImportableSendGridTemplate[]) => {
    if (!importableTemplates) {
      return;
    }

    dispatch(
      importTemplatesRequest(
        templatesToImport.map((template) => ({
          name: template.name,
          templateId: template.id,
        })),
      ),
    );
  };

  const onImportTemplatePopUpOpen = () => dispatch(openImportTemplatePopUp());
  const onImportTemplatesPopUpClose = () => dispatch(closeImportTemplatePopUp());

  const onVariablesMappingPopUpOpen = () => dispatch(openVariablesMappingPopUp());
  const onVariablesMappingPopUpClose = () => dispatch(closeVariablesMappingPopUp());
  const onMappingSave = () =>
    dispatch(updateTemplateVariablesMappingRequest(selectedTemplateInfo!.id, variablesMapping));

  const openEditTemplateContextualView = (templateId: string) => {
    history.replace(`templates?edit=${templateId}`);
  };

  const leftNav = makeLeftNavigation(NavigationLinkId.SendGrid, ApplicationSectionName.CompanySettings);

  const handleCloseCreateVariablePopup = () => dispatch(closeCreateVariablePopup());
  const handleCreateVariable = (variable: VariableClientType) => {
    const simpleVariableData = getVariableType(variable);
    const formattedVariable = { ...variable, ...simpleVariableData };

    const action = setActionOrigin(
      createVariableRequest(formattedVariable),
      CreateVariableActionOrigin.SendGridVariablesMapping,
    );

    dispatch(action);
  };

  const getOverlay = (): ReactElement | null => {
    if (templateIdToDelete) {
      return (
        <DeleteTemplatePopUp
          isDeleteInProgress={isDeleteTemplateInProgress}
          onDeleteLabel={() => onDeleteLabel(templateIdToDelete)}
          onPopupClose={onDeletePopupClose}
        />
      );
    }

    if (isImportTemplatePopUpOpen) {
      const templatesToImport =
        importableTemplates &&
        importableTemplates.map((template) => ({
          ...template,
          templateId: template.id,
        }));

      const getCheckboxErrorByTemplate = (templateToImport: ImportableSendGridTemplate) => {
        return templateToImport.status === 'active'
          ? 'This template must be deactivated before it can be re-imported.'
          : '';
      };

      return (
        <ImportTemplatesPopup
          onPopupClose={onImportTemplatesPopUpClose}
          onImportTemplates={onImportTemplates}
          isImportInProgress={isImportInProgress}
          templatesToImport={templatesToImport}
          getCheckboxErrorByTemplate={getCheckboxErrorByTemplate}
          warningTextMessage={IMPORT_TEMPLATES_WARNING_MESSAGE}
          integrationLink={SEND_GRID_LINK}
          integrationTitle="SendGrid"
        />
      );
    }

    if (isCreatingVariablePopupOpen) {
      return (
        <CreateVariablePopup
          isSavingInProgress={isCreatingVariableInProgress}
          onClose={handleCloseCreateVariablePopup}
          onSave={handleCreateVariable}
        />
      );
    }

    if (isVariableMappingPopUpOpen && selectedTemplateInfo) {
      const updateVariableEntry = (name: string, variable: string) => {
        const updatedVariables = { ...variablesMapping };

        updatedVariables[name] = variable;

        setVariablesMapping(updatedVariables);
      };

      return (
        <MappingVariablePopUp
          isSaveInProgress={isUpdatingVariablesMapping}
          initialVariablesMapping={selectedTemplateInfo.variables}
          variablesMapping={variablesMapping}
          onPopupClose={onVariablesMappingPopUpClose}
          onMappingSave={onMappingSave}
          updateVariableEntry={updateVariableEntry}
        />
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <TemplatesFilter
      dueCreatedDateFrom={filters.dueCreatedDateRange.from}
      dueCreatedDateTo={filters.dueCreatedDateRange.to}
      dueUpdatedDateFrom={filters.dueUpdatedDateRange.from}
      dueUpdatedDateTo={filters.dueUpdatedDateRange.to}
      status={filters.active}
    />
  );

  const handleCloseContextualView = useCloseContextualView();

  const getContextualViewPage = () => {
    if (params.has('edit')) {
      return (
        <EditTemplate
          senderEmails={senderEmails}
          selectedTemplateInfo={selectedTemplateInfo}
          onClose={handleCloseContextualView}
          onVariablesMappingPopUpOpen={onVariablesMappingPopUpOpen}
          isUpdatingSenderEmail={isUpdatingSenderEmail}
        />
      );
    }

    return null;
  };

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={getOverlay()}
      contextualView={getContextualViewPage()}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <SendGrid
            onDeletePopupOpen={onDeletePopupOpen}
            onImportTemplatePopUpOpen={onImportTemplatePopUpOpen}
            onSearchInputChange={onSearchInputChange}
            onTabChange={onTabChange}
            openEditTemplateContextualView={openEditTemplateContextualView}
            pageTab={pageTab}
            paginationProps={paginationProps}
            saveIntegration={saveIntegration}
            searchInputValue={filters.searchInputValue}
            sortingType={sortingType}
            integration={integration}
            isIntegrationLoaded={isIntegrationLoaded}
            templates={templates}
            selectedMembers={filters.selectedMembers}
            filters={filters}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default SendGridPage;
