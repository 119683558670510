import React, { FC } from 'react';
import { SkeletonText } from 'components/Skeleton';
import styles from './InfoCardSkeleton.module.scss';

export interface InfoCardSkeletonProps {
  columns?: number;
}

const DEFAULT_SKELETON_COLUMN = 2;

const InfoCardSkeleton: FC<InfoCardSkeletonProps> = ({ columns = DEFAULT_SKELETON_COLUMN }) => {
  const renderColumn = (item: null, columnIndex: number) => (
    <div className={styles.column} key={columnIndex}>
      {new Array(columnIndex + 1).fill(null).map((skeletonItem, index) => (
        <SkeletonText key={index} width="100%" height="20px" lineHeight="48px" color="primary20" />
      ))}
    </div>
  );

  return <div className={styles.container}>{new Array(columns).fill(null).map(renderColumn)}</div>;
};

export default InfoCardSkeleton;
