import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { ESignTemplatesActionOrigin, getESignTemplates } from 'ESignTemplates/ActionCreator';
import { DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT } from 'components/DocuSign/pagination';
import { ChangeFiltersAction, ResetAllFiltersAction } from './ActionCreator';
import { DocuSignFiltersActionType } from './ActionTypes';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangeFiltersAction =>
  action.type === DocuSignFiltersActionType.ChangeFilters;

const isResetPopupFiltersAction = (action: AnyAction): action is ResetAllFiltersAction =>
  action.type === DocuSignFiltersActionType.ResetAllFilters;

const onDocuSignTemplatesFilterChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetPopupFiltersAction(action)) {
    const { docuSign } = getState();
    const { filters, sortingType } = docuSign;

    const actionToDispatch = setActionOrigin(
      getESignTemplates(
        {
          status: filters.selectedStatus,
          search: filters.searchInputValue,
          integrationId: action.payload.integrationId,
          members: filters.selectedMembers,
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
        },
        sortingType,
        DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
      ),
      ESignTemplatesActionOrigin.DocuSignConfiguration,
    );

    dispatch(actionToDispatch);
  }

  return result;
}) as Middleware;

export default onDocuSignTemplatesFilterChangeMiddleware;
