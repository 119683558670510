import APIRest, { RequestBody } from 'api/APIRest';
import { DataFilter, DataViewSortingType } from 'api/LoanOriginationSystem/Types';

export default class BaseSystemApi<Resource = unknown> extends APIRest {
  protected resourceName = '';

  protected getResources<Result = Resource[]>(
    params: URLSearchParams = new URLSearchParams(),
    abortSignal?: AbortSignal,
  ): Promise<Result> {
    let queryString = params.toString();
    if (queryString) {
      queryString = `?${queryString}`;
    }

    return this.fetch<Result>(`/${this.resourceName}/${queryString}`, 'GET', undefined, { abortSignal });
  }

  protected getResourceById<Result = Resource>(id: string): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}/${id}`);
  }

  protected createResource<Result = Resource>(body: RequestBody): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}`, 'POST', body);
  }

  protected deleteResource<Result = void>(id: string): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}/${id}`, 'DELETE');
  }

  protected updateResource<Result = Resource>(id: string, body: RequestBody): Promise<Result> {
    return this.fetch<Result>(`/${this.resourceName}/${id}`, 'PUT', body);
  }

  protected getPaginationUrlSearchParams(filters?: DataFilter, sortingType?: DataViewSortingType): URLSearchParams {
    const params = new URLSearchParams();

    if (filters) {
      params.set('offset', filters.offset.toString());
      params.set('count', filters.count.toString());
      params.set('search', filters.search || '');
    }

    if (sortingType) {
      params.set('sortField', sortingType.field);
      params.set('sortDirection', sortingType.ascending ? 'asc' : 'desc');
    }

    return params;
  }
}
