const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
const GET_PRODUCTS_LIST_SUCCESS = 'GET_PRODUCTS_LIST_SUCCESS';
const GET_PRODUCTS_LIST_FAILED = 'GET_PRODUCTS_LIST_FAILED';
const GET_COLUMN_VIEW_PRODUCT_DATA = 'GET_COLUMN_VIEW_PRODUCT_DATA';
const GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS = 'GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS';
const GET_COLUMN_VIEW_PRODUCT_DATA_FAILED = 'GET_COLUMN_VIEW_PRODUCT_DATA_FAILED';
const GET_TABLE_VIEW_PRODUCT_DATA = 'GET_TABLE_VIEW_PRODUCT_DATA';
const GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS = 'GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS';
const GET_TABLE_VIEW_PRODUCT_DATA_FAILED = 'GET_TABLE_VIEW_PRODUCT_DATA_FAILED';
const SELECT_PRODUCT = 'SELECT_PRODUCT';
const SORT_TABLE_VIEW_PRODUCT_DATA = 'SORT_TABLE_VIEW_PRODUCT_DATA';

export {
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAILED,
  GET_COLUMN_VIEW_PRODUCT_DATA,
  GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS,
  GET_COLUMN_VIEW_PRODUCT_DATA_FAILED,
  GET_TABLE_VIEW_PRODUCT_DATA,
  GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS,
  GET_TABLE_VIEW_PRODUCT_DATA_FAILED,
  SELECT_PRODUCT,
  SORT_TABLE_VIEW_PRODUCT_DATA,
};

export enum LoanOriginationSystemApplicationsActionType {
  ChangeApplicationStatus = 'loanOriginationSystemApplications/changeApplicationStatus',
  ChangeApplicationStatusSuccess = 'loanOriginationSystemApplications/changeApplicationStatusSuccess',
  ChangeApplicationStatusFailed = 'loanOriginationSystemApplications/changeApplicationStatusFailed',
  OpenDeleteApplicationPopup = 'loanOriginationSystemApplications/openDeleteApplicationPopup',
  CloseDeleteApplicationPopup = 'loanOriginationSystemApplications/closeDeleteApplicationPopup',
  CloseDuplicateApplicationPopup = 'loanOriginationSystemApplications/closeDuplicateApplicationPopup',
  DeleteApplication = 'loanOriginationSystemApplications/deleteApplication',
  DeleteApplicationSuccess = 'loanOriginationSystemApplications/deleteApplicationSuccess',
  DeleteApplicationFailed = 'loanOriginationSystemApplications/deleteApplicationFailed',
  DuplicateApplication = 'loanOriginationSystemApplications/duplicateApplication',
  DuplicateApplicationSuccess = 'loanOriginationSystemApplications/duplicateApplicationSuccess',
  DuplicateApplicationFailed = 'loanOriginationSystemApplications/duplicateApplicationFailed',
  ChangeApplicationUpdatingState = 'loanOriginationSystemApplications/ChangeApplicationUpdatingState',
  SetApplicationStatusRulesValidationData = 'loanOriginationSystemApplications/setApplicationStatusRulesValidationData',
  RemoveApplicationSuccessId = 'loanOriginationSystemApplications/removeApplicationSuccessId',
  MoveApplicationWithoutChangingStatus = 'loanOriginationSystemApplications/moveApplicationWithoutChangingStatus',
  SortColumnViewProductData = 'loanOriginationSystemApplications/sortColumnViewProductData',
  ResetState = 'loanOriginationSystemApplications/resetState',
  ToggleCreateNewApplicationPopupOpen = 'loanOriginationSystemApplications/toggleCreateNewApplicationPopupOpen',
  SetCreateApplicationProductData = 'loanOriginationSystemApplications/setCreateApplicationProductData',
  OpenDuplicateApplicationPopup = 'loanOriginationSystemApplications/openDuplicateApplicationPopup',
}
