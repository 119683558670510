import { Middleware } from 'redux';

import { ACTION_TYPE_CREATE_VARIABLE_REQUEST } from './ActionTypes';
import { createVariableFailure, CreateVariableRequestAction, createVariableSuccess } from './VariablesActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { VariablesApi } from 'api/Core/VariablesApi';

const CreateVariableMiddleware: (api: VariablesApi) => Middleware = (api) =>
  Fetcher<{ variableName: string }, CreateVariableRequestAction>(
    ACTION_TYPE_CREATE_VARIABLE_REQUEST,
    createVariableSuccess,
    createVariableFailure,
    async ({ payload }) => {
      await api.createVariable(payload);
      return { variableName: payload.displayName };
    },
  );

export default CreateVariableMiddleware;
