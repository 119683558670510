import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrganizationPermissionsRequest,
  openResetToDefaultsPopUp,
  resetPermissionsRequest,
  resetPermissionUpdatingState,
  updateOrganizationPermissionRequest,
  closeResetToDefaultsPopUp,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsActions';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import NavigationLinkId from 'enums/NavigationLinkId';
import Permissions from 'components/Permissions';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { PermissionsProps } from 'components/Permissions/Permissions';
import ResetToDefaultsPopUp from 'components/Permissions/ResetToDefaultsPopUp';
import { ProductSectionAccessPermissionsState } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsReducer';

const PermissionsPage = () => {
  const dispatch = useDispatch();

  const { permissions, permissionsUpdatingState, isResetToDefaultsPopUpOpen } = useSelector<
    ReduxState,
    ProductSectionAccessPermissionsState
  >((state) => state.productSectionAccessPermissions);

  useEffect(() => {
    dispatch(getOrganizationPermissionsRequest());
  }, []);

  const leftNav = makeLeftNavigation(NavigationLinkId.Permissions, ApplicationSectionName.CompanySettings);

  const resetPermissions = async () => {
    await dispatch(resetPermissionsRequest());
    notification.createNotification(getMessage(MessageType.CompanyInformationUpdated), 'success', dispatch);
    await dispatch(getOrganizationPermissionsRequest());
  };

  const updatePermissions: PermissionsProps['updatePermissions'] = (userRole, productSection, value) => {
    dispatch(updateOrganizationPermissionRequest(userRole, productSection, value));
  };

  const handleResetPermissionsUpdatingState: PermissionsProps['resetPermissionsUpdatingState'] = (
    userRole,
    productSection,
  ) => {
    dispatch(resetPermissionUpdatingState(userRole, productSection));
  };

  const openPopUp = () => {
    dispatch(openResetToDefaultsPopUp());
  };

  const closePopUp = () => {
    dispatch(closeResetToDefaultsPopUp());
  };

  const getOverlay = () => {
    if (isResetToDefaultsPopUpOpen) {
      return <ResetToDefaultsPopUp onPopupClose={closePopUp} resetPermissions={resetPermissions} />;
    }
    return null;
  };

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <Permissions
            permissions={permissions}
            permissionsUpdatingState={permissionsUpdatingState}
            openPopUp={openPopUp}
            updatePermissions={updatePermissions}
            resetPermissionsUpdatingState={handleResetPermissionsUpdatingState}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default PermissionsPage;
