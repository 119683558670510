import {
  ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_REQUEST,
  ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_SUCCESS,
} from './ActionTypes';
import {
  editExternalIntegrationSuccess,
  editExternalIntegrationFailure,
  EditExternalIntegrationRequestAction,
  EditExternalIntegrationSuccessAction,
} from './ActionCreator';
import { Middleware, MiddlewareAPI, AnyAction } from 'redux';
import { DecisionEngineApi } from 'api/Types';
import notification from 'handlers/notification/notificationActionCreator';
import Fetcher from 'middlewares/Fetcher';
import {
  closeEditExternalIntegrationVariablesPopUp,
  getExternalIntegrationVariables,
} from 'EditExternalIntegrationVariablesPopUp/ActionCreator';
import { CompleteEditExternalIntegrationRequestData } from 'EditExternalIntegration/Types';

export const EditExternalIntegrationMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<CompleteEditExternalIntegrationRequestData, EditExternalIntegrationRequestAction>(
    ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_REQUEST,
    editExternalIntegrationSuccess,
    editExternalIntegrationFailure,
    async ({ payload }) => {
      await api.updateExternalIntegration(payload);
      return payload;
    },
  );

const isEditExternalIntegrationSuccessAction = (action: AnyAction): action is EditExternalIntegrationSuccessAction =>
  action.type === ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_SUCCESS;

export const onEditExternalIntegrationSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (isEditExternalIntegrationSuccessAction(action)) {
    dispatch(
      getExternalIntegrationVariables({
        strategyId: action.payload.strategyId,
        moduleId: action.payload.moduleKey,
      }),
    );
    notification.createNotification('Changes saved successfully!', 'success', dispatch);
    dispatch(closeEditExternalIntegrationVariablesPopUp());
  }
  return result;
};

export default onEditExternalIntegrationSuccessMiddleware;
