import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';
import { IndividualProcessingHistoryCase, ItemsWithTotalCount } from 'api/Types';
import { DecisionRunsSortingField, DecisionRunsSortingFieldType } from 'ApplicationDecisionRuns/types';

export interface GetApplicationDecisionRunsAction {
  type: ApplicationDecisionRunActionType;
  payload: { applicationId: string; sortingType: DecisionRunsSortingFieldType };
}

export interface DeleteApplicationDecisionRunAction {
  type: ApplicationDecisionRunActionType;
  payload: { decisionRunId: string };
}

export interface GetApplicationDecisionRunsActionSuccess {
  type: ApplicationDecisionRunActionType;
  payload: ItemsWithTotalCount<IndividualProcessingHistoryCase>;
}

export const getApplicationDecisionRuns = (
  applicationId: string,
  sortingType: DecisionRunsSortingFieldType,
): GetApplicationDecisionRunsAction => ({
  type: ApplicationDecisionRunActionType.GetApplicationDecisionRuns,
  payload: { applicationId, sortingType },
});

export const getApplicationDecisionRunsSuccess = (
  payload: ItemsWithTotalCount<IndividualProcessingHistoryCase>,
): GetApplicationDecisionRunsActionSuccess => ({
  type: ApplicationDecisionRunActionType.GetApplicationDecisionRunsSuccess,
  payload,
});

export const getApplicationDecisionRunsFailure = FailedActionCreator(
  ApplicationDecisionRunActionType.GetApplicationDecisionRunsFailure,
);

export const sortApplicationCases = (field: DecisionRunsSortingField, ascending: boolean) => ({
  type: ApplicationDecisionRunActionType.Sort,
  payload: { field, ascending },
});

export const deleteDecisionRun = (decisionRunId: string): DeleteApplicationDecisionRunAction => ({
  type: ApplicationDecisionRunActionType.DeleteApplicationDecisionRun,
  payload: { decisionRunId },
});

export const deleteApplicationDecisionRunSuccess = () => ({
  type: ApplicationDecisionRunActionType.DeleteApplicationDecisionRunSuccess,
});

export const deleteApplicationDecisionRunFailure = FailedActionCreator(
  ApplicationDecisionRunActionType.DeleteApplicationDecisionRunFailure,
);
