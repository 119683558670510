import { AnyAction } from 'redux';
import {
  BorrowerApiRequestTemplateType,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerConfigurationActionType } from 'LoanOriginationSystemBorrowerConfiguration/ActionTypes';
import { DownloadResponse } from 'api/APIRest';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface DownloadApiRequestTemplateAction extends AnyAction {
  type: BorrowerConfigurationActionType.DownloadApiRequestTemplate;
  payload: {
    borrowerType: BorrowerType;
    requestType: BorrowerApiRequestTemplateType;
  };
}

export interface DownloadApiRequestTemplateSuccessAction extends AnyAction {
  type: BorrowerConfigurationActionType.DownloadApiRequestTemplateSuccess;
  payload: {
    response: DownloadResponse;
  };
}

export const downloadApiRequestTemplate = (
  borrowerType: BorrowerType,
  requestType: BorrowerApiRequestTemplateType,
): DownloadApiRequestTemplateAction => ({
  type: BorrowerConfigurationActionType.DownloadApiRequestTemplate,
  payload: {
    borrowerType,
    requestType,
  },
});

export const downloadApiRequestTemplateSuccess = (
  response: DownloadResponse,
): DownloadApiRequestTemplateSuccessAction => ({
  type: BorrowerConfigurationActionType.DownloadApiRequestTemplateSuccess,
  payload: {
    response,
  },
});

export const downloadApiRequestTemplateFailure = FailedActionCreator(
  BorrowerConfigurationActionType.DownloadApiRequestTemplateFailure,
);
