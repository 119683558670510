import React, { useRef } from 'react';
import clsx from 'clsx';
import StatusDropdown from './StatusDropdown';
import styles from './Title.module.scss';
import {
  ApplicationStatus,
  DefaultApplicationStatusName,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import ApplicationRowActions from 'components/LoanOriginationSystem/ApplicationsDashboard/ApplicationRowActions/ApplicationRowActions';
import useSticky from 'hooks/useSticky';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation';

interface TitleProps {
  name: string;
  amount: number;
  currencySymbol: string;
  statuses: ApplicationStatus[];
  onStatusSelect: (status: ApplicationStatus) => void;
  selectedStatus: ApplicationStatus;
  deleteDisabled: boolean;
  onEditApplication: () => void;
  onDeleteApplication: () => void;
  onDuplicateApplication: () => void;
  sticky?: boolean;
  updatingState: ApplicationUpdatingState | null;
  onApplicationUpdatingStateReset: () => void;
}

const Title = ({
  name,
  amount,
  currencySymbol,
  statuses,
  deleteDisabled,
  onStatusSelect,
  onEditApplication,
  onDeleteApplication,
  selectedStatus,
  sticky,
  onDuplicateApplication,
  updatingState,
  onApplicationUpdatingStateReset,
}: TitleProps) => {
  const containerRef = useRef(null);
  const { isStuck, elementRef } = useSticky<HTMLDivElement>();
  const loanAmountDisplayValue = formatMonetaryValue(amount, currencySymbol);

  const shouldShowApprovalAnimation =
    updatingState === ApplicationUpdatingState.Success && selectedStatus.name === DefaultApplicationStatusName.Approved;

  return (
    <div ref={elementRef} className={clsx(styles.title, sticky && styles.stickyTitle, isStuck && styles.stuck)}>
      <div className={styles.titleContent}>
        <h2 className={styles.titleName}>{name}</h2>
        <span>{loanAmountDisplayValue}</span>
      </div>
      <StatusDropdown
        className={styles.statusDropdown}
        statusesList={statuses}
        selectedStatus={selectedStatus}
        onSelect={onStatusSelect}
        ref={containerRef}
      />
      <ApplicationRowActions
        deleteDisabled={deleteDisabled}
        alwaysVisible
        className={styles.moreButton}
        onEdit={onEditApplication}
        onDelete={onDeleteApplication}
        onDuplicate={onDuplicateApplication}
        editApplicationTitle="Edit Application Form"
      />
      {shouldShowApprovalAnimation && (
        <ConfettiAnimation
          targetElementRef={containerRef}
          className={styles.approvalAnimation}
          offset={{ top: 15, left: 0 }}
          onAnimationComplete={onApplicationUpdatingStateReset}
        />
      )}
    </div>
  );
};

export default Title;
