import { AnyAction } from 'redux';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { TableViewData } from 'api/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemBorrowersFiltersState } from './Filters/Types';
import {
  Borrower,
  BorrowersSortingField,
  BorrowersSortingType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { BorrowerDetailsTab } from 'components/LoanOriginationSystem/BorrowerDetails/BorrowerDetails';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export type GetBorrowersSuccessData = TableViewData<Borrower> & { searchInputValue: string };

export interface GetBorrowersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowers;
  payload: {
    filters: LoanOriginationSystemBorrowersFiltersState;
    sortingType: BorrowersSortingType;
  };
}

export interface GetBorrowersSuccessAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess;
  payload: GetBorrowersSuccessData;
}

export interface SortBorrowersAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.SortBorrowers;
  payload: BorrowersSortingType;
}

export interface DeleteBorrowerAction extends AnyAction {
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrower;
  payload: string;
}

export interface DeleteBorrowerSuccessAction {
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess;
  payload: DeleteBorrowerSuccessResult;
}

export interface DeleteBorrowerSuccessResult {
  name: string;
}

export interface SetBorrowerToDeleteIdAction {
  type: LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDeleteId;
  payload: string | null;
}

export interface GetBorrowerToDeleteApplicationsAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplications;
  payload: string;
}

export type GetBorrowerToDeleteApplicationsSuccessData = TableViewData<Application>;

export interface GetBorrowerToDeleteApplicationsSuccessAction {
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplicationsSuccess;
  payload: GetBorrowerToDeleteApplicationsSuccessData;
}

export interface SelectBorrowerViewTabAction {
  type: LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab;
  payload: {
    tab: BorrowerDetailsTab;
  };
}

export interface ChangeContextualViewStepAction {
  type: LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep;
  payload: {
    step: number;
  };
}

export const getBorrowers = (
  filters: LoanOriginationSystemBorrowersFiltersState,
  sortingType: BorrowersSortingType,
): GetBorrowersAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowers,
  payload: {
    filters,
    sortingType,
  },
});

export const deleteBorrower = (id: string): DeleteBorrowerAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrower,
  payload: id,
});

export const getBorrowersSuccess = (borrowersData: GetBorrowersSuccessData): GetBorrowersSuccessAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowersSuccess,
  payload: borrowersData,
});

export const deleteBorrowerSuccess = (data: DeleteBorrowerSuccessResult): DeleteBorrowerSuccessAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess,
  payload: data,
});

export const getBorrowersFailed = FailedActionCreator(LoanOriginationSystemBorrowersActionTypes.GetBorrowersFailed);

export const deleteBorrowerFailure = FailedActionCreator(
  LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerFailure,
);

export const sortBorrowers = (field: BorrowersSortingField, ascending: boolean): SortBorrowersAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SortBorrowers,
  payload: {
    ascending,
    field,
  },
});

export const setBorrowerToDeleteId = (id: string | null): SetBorrowerToDeleteIdAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SetBorrowerToDeleteId,
  payload: id,
});

export const getBorrowerToDeleteApplications = (id: string): GetBorrowerToDeleteApplicationsAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplications,
  payload: id,
});

export const getBorrowerToDeleteApplicationsSuccess = (
  data: GetBorrowerToDeleteApplicationsSuccessData,
): GetBorrowerToDeleteApplicationsSuccessAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplicationsSuccess,
  payload: data,
});

export const getBorrowerToDeleteApplicationsFailure = FailedActionCreator(
  LoanOriginationSystemBorrowersActionTypes.GetBorrowerToDeleteApplicationsFailure,
);

export const selectBorrowerViewTab = (tab: BorrowerDetailsTab): SelectBorrowerViewTabAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.SelectBorrowerViewTab,
  payload: { tab },
});

export const changeContextualViewStep = (step: number): ChangeContextualViewStepAction => ({
  type: LoanOriginationSystemBorrowersActionTypes.ChangeContextualViewStep,
  payload: { step },
});
