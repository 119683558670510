import intermediaryApplicationsPagination from 'components/LoanOriginationSystem/IntermediaryDetails/pagination';
import { LoanOriginationSystemIntermediariesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

const IntermediaryApplicationsPaginationMiddleware = (api: LoanOriginationSystemIntermediariesApi) => {
  return intermediaryApplicationsPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getIntermediaryApplications(
      params.intermediaryId,
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
      params.sortingType,
    );

    return items;
  });
};

export default IntermediaryApplicationsPaginationMiddleware;
