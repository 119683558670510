import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';

const deepAssign = <Tree, Leaf = {}>(tree: Tree, path: string[], props: Partial<Leaf>): Tree => {
  const before = path.length === 0 ? tree : get(tree, path);
  const after = {
    ...before,
    ...props,
  };
  if (path.length === 0) {
    return after;
  }
  const parentPath = initial(path);
  const lastSegment = last(path)!;
  return deepAssign(tree, parentPath, { [lastSegment]: after });
};

export default deepAssign;
