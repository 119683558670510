import { ReduxState } from 'types/redux';
import { useSelector } from 'react-redux';
import { UserOrganizationsState } from 'UserOrganizations/UserOrganizationsReducer';
import { OrganizationInfo } from 'api/Core/OrganizationApi';

const useOrganizationInfo = (): OrganizationInfo | null => {
  const organizationId = useSelector<ReduxState, string | undefined>((state) => state.sessionInfo.organization?.id);
  const userOrganizations = useSelector<ReduxState, UserOrganizationsState | null>((state) => state.userOrganizations);
  if (!organizationId || !userOrganizations) {
    return null;
  }
  /**
   * It's impossible for a user to have a session under an organization he's not a member of,
   * so it's safe to assume that once we got here `find()` always returns current organization.
   */
  return userOrganizations.items.find(({ id }) => id === organizationId)!;
};

export default useOrganizationInfo;
