import { OrganizationSubscriptionStatus } from 'eventHandlers/AuthEventEmitter';
import { AuthHandler } from 'AuthHandler';
import { History, createLocation, locationsAreEqual } from 'history';
import { matchPath } from 'react-router';
import { AppRoutes } from 'routes/RouteService';
import { ReduxState } from 'types/redux';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { tap } from 'lodash';

const AUTHENTICATION_ROUTES = [
  AppRoutes.SignIn,
  AppRoutes.Mfa,
  AppRoutes.Activation,
  AppRoutes.InactiveUser,
  AppRoutes.CancelledSubscription,
  AppRoutes.PastDueSubscription,
  AppRoutes.CreateAccount,
];

const NO_AUTH_REQUIRED_ROUTES = [
  AppRoutes.SignIn,
  AppRoutes.ForgotPassword,
  AppRoutes.ResetPassword,
  AppRoutes.CreateAccount,
  AppRoutes.LoggedOut,
];

const SHOULD_SKIP_REDIRECTS_ROUTES = [AppRoutes.AcceptInvite, AppRoutes.SecureUpload, AppRoutes.ConfirmEmail];

const ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE = Symbol('ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE');

export const subscribeToHistoryChange = () => ({
  type: ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE
});

const RedirectionsMiddleware = (authHandler: AuthHandler, history: History) => (({ getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => {
  const redirect = (path: string) => {
    const nextLocation = createLocation(path);

    const isNewLocation = !locationsAreEqual({
      ...history.location,
      key: undefined,
    }, nextLocation);

    if (isNewLocation) {
      history.replace(nextLocation);
    }
  };

  const handleRedirects = () => {
    const {
      sessionInfo: { user, organization },
      accountDetails
    } = getState();

    if (matchPath(history.location.pathname, { path: SHOULD_SKIP_REDIRECTS_ROUTES })) {
      return;
    }

    if (!authHandler.isLoggedIn() && !matchPath(history.location.pathname, { path: NO_AUTH_REQUIRED_ROUTES })) {
      redirect(AppRoutes.SignIn);
      return;
    }

    if (!user || !accountDetails?.id) {
      return;
    }

    const { isMfaIncomplete, isEmailVerified, isActive } = user;

    if (isMfaIncomplete) {
      redirect(AppRoutes.Mfa);
      return;
    }

    if (!isEmailVerified) {
      redirect(AppRoutes.Activation);
      return;
    }

    if (!organization) {
      redirect(AppRoutes.SelectOrganization);
      return;
    }

    if (!isActive) {
      redirect(AppRoutes.InactiveUser);
      return;
    }

    if (
      organization.subscriptionStatus === OrganizationSubscriptionStatus.Canceled ||
      organization.subscriptionStatus === OrganizationSubscriptionStatus.IncompleteExpired
    ) {
      redirect(AppRoutes.CancelledSubscription);
      return;
    }

    if (organization.subscriptionStatus === OrganizationSubscriptionStatus.Unpaid) {
      redirect(AppRoutes.PastDueSubscription);
      return;
    }

    if (matchPath(history.location.pathname, { path: AUTHENTICATION_ROUTES }) || history.location.pathname === '/') {
      redirect(AppRoutes.Home);
    }
  }

  handleRedirects();

  return (action: AnyAction) => {
    if (action.type === ACTION_TYPE_SUBSCRIBE_TO_HISTORY_CHANGE) {
      /**
       * It is important to subscribe to history changes after ReactRouter does,
       * otherwise synchronous calls to push()/replace() from the history.listen callback are silently swallowed
       * without triggering a re-render which leads to the UI state not being consistent with the address bar.
       */
      history.listen(handleRedirects);
      return action;
    }
    return tap(next(action), handleRedirects);
  };
}) as Middleware;

export default RedirectionsMiddleware;
