import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import times from 'lodash/times';
import React from 'react';
import styles from './ChartSkeleton.module.scss';

const renderSkeletonRow = () => {
  return (
    <div className={styles.chartRowSkeleton}>
      <SkeletonRectangle width="32px" color="primary6" height="16px" />
      <SkeletonRectangle width="32px" color="primary6" height="16px" />
    </div>
  );
};

const ChartSkeleton = () => {
  return (
    <div className={styles.container}>
      {times(5, renderSkeletonRow)}
      <div className={styles.bottomAxis}>
        {times(8, () => (
          <SkeletonRectangle width="60px" color="primary6" height="16px" />
        ))}
      </div>
    </div>
  );
};

export default ChartSkeleton;
