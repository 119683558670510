import React, { FC } from 'react';
import { EditImage } from 'static/images';
import { FormLayoutData, VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import InfoCard from 'components/InfoCard/InfoCard';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import InfoCardSkeleton from 'components/InfoCard/InfoCardSkeleton';
import formatValueByDataType from 'utils/formatValueByDataType';
import styles from './ExistingApplicationDetailsForm.module.scss';

interface ExistingApplicationDetailsFormProps {
  configurations: VariableConfiguration[] | null;
  formData: FormLayoutData;
  onProfileEdit?: () => void;
}

const ExistingApplicationDetailsForm: FC<ExistingApplicationDetailsFormProps> = ({
  configurations,
  formData,
  onProfileEdit,
}: ExistingApplicationDetailsFormProps) => {
  const layout = useFormLayout(configurations);

  if (!layout) {
    return <InfoCardSkeleton />;
  }

  const renderVariable = (column: Array<VariableConfiguration>) => (
    <>
      {column.map((configuration) => (
        <tr className={styles.infoPanelRow}>
          <th className={styles.infoPanelContentItemInner}>
            <p className={styles.infoPanelLabel}>{configuration.variable.displayName}</p>
          </th>
          <th className={styles.infoPanelContentItemInner}>
            <p className={styles.infoPanelContentItem}>
              {formatValueByDataType(formData[configuration.variable.systemName], configuration.variable)}
            </p>
          </th>
        </tr>
      ))}
    </>
  );

  const configurationFormStyle = { gridTemplateColumns: `repeat(${layout.length}, minmax(0, 1fr))` };

  return (
    <div className={styles.existingApplicationDetailsContainer}>
      <InfoCard
        title="Application Details"
        titleLink={
          <>
            <EditImage className={styles.editIcon} />
            Edit Data
          </>
        }
        hideCloseButton
        onTitleLinkClick={onProfileEdit}
      >
        <div style={configurationFormStyle} className={styles.columnsContainer}>
          {layout.map((column) => (
            <table className={styles.column}>{renderVariable(column)}</table>
          ))}
        </div>
      </InfoCard>
    </div>
  );
};

export default ExistingApplicationDetailsForm;
