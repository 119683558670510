import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { AppRoutes } from 'routes/RouteService';
import styles from './ResetPasswordExpiredErrorNotification.module.scss';

const ResetPasswordExpiredErrorNotification: FC = () => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push(AppRoutes.SignIn);
  };

  return (
    <div>
      It looks like you already reset your password.{' '}
      <strong className={styles.signInLink} onClick={handleSignInClick}>
        Sign In
      </strong>
    </div>
  );
};

export default ResetPasswordExpiredErrorNotification;
