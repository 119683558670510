import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { getESignTemplates, ESignTemplatesActionOrigin } from 'ESignTemplates/ActionCreator';
import { DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT } from 'components/DocuSign/pagination';
import { DocuSignActionType } from './ActionTypes';
import { ChangeSortingTypeAction } from './ActionCreator';

const isChangeChangeSortingTypeAction = (action: AnyAction): action is ChangeSortingTypeAction =>
  action.type === DocuSignActionType.ChangeSortingType;

const sortDocuSignTemplatesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangeChangeSortingTypeAction(action)) {
    const { docuSign } = getState();
    const { filters } = docuSign;

    const actionToDispatch = setActionOrigin(
      getESignTemplates(
        {
          status: filters.selectedStatus,
          members: filters.selectedMembers,
          search: filters.searchInputValue,
          integrationId: action.payload.integrationId,
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
        },
        action.payload.sortingType,
        DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
      ),
      ESignTemplatesActionOrigin.DocuSignConfiguration,
    );

    dispatch(actionToDispatch);
  }

  return result;
}) as Middleware;

export default sortDocuSignTemplatesMiddleware;
