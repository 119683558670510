import React from 'react';
import styles from './ChartTooltip.module.scss';
import moment from 'moment';
import { TooltipData } from 'components/LoanOriginationSystem/ReportingDashboard/Chart/Chart';
import formatMonetaryValue from 'utils/formatMonetaryValue';

export interface TooltipProps extends TooltipData {
  date: string;
  numberAmount: number;
  dollarAmount: number;
}
interface ChartTooltipProps {
  tooltipData: TooltipProps;
  currency: string;
}

const formatDateForTooltip = (date: string) => moment(date).format('MMMM YYYY');

const ChartTooltip = ({ tooltipData, currency }: ChartTooltipProps) => {
  const { date, numberAmount, dollarAmount } = tooltipData;
  return (
    <div className={styles.tooltipContent}>
      <p className={styles.tooltipDate}>{formatDateForTooltip(date)}</p>
      <div className={styles.tooltipInfo}>
        {`${numberAmount} Applications for ${formatMonetaryValue(dollarAmount, currency)}`}
      </div>
    </div>
  );
};

export default ChartTooltip;
