import React, { FC } from 'react';
import styles from './LoadingMask.module.scss';
import clsx from 'clsx';
import Spinner, { Colors } from 'components/Spinner';
import CenteredRelativeToVisibleArea from 'components/CenteredRelativeToVisibleArea/CenteredRelativeToVisibleArea';

type HTMLDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

interface LoadingMaskProps extends HTMLDivProps {
  background?: 'light' | 'dark';
  transparent?: boolean;
  loaderSize?: number;
}

const LoadingMask: FC<LoadingMaskProps> = ({ className, background, transparent, loaderSize, ...props }) => {
  return (
    <div className={clsx(styles.loadingPopUpContainer, className)} {...props}>
      <div
        className={clsx(styles.loadingPopUp, transparent && styles.transparentLoadingPopUp)}
        style={!transparent && background === 'light' ? { backgroundColor: Colors.White } : {}}
      />
      <CenteredRelativeToVisibleArea>
        <Spinner size={loaderSize} background={background} />
      </CenteredRelativeToVisibleArea>
    </div>
  );
};
export default LoadingMask;
