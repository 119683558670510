import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getBorrowerApplications, SortBorrowerApplicationsAction } from './ActionCreator';
import { LoanOriginationSystemBorrowerDetailsActionType } from './ActionTypes';

const isSortBorrowerApplicationsAction = (action: AnyAction): action is SortBorrowerApplicationsAction =>
  action.type === LoanOriginationSystemBorrowerDetailsActionType.SortBorrowerApplications;

const sortBorrowerApplicationsMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortBorrowerApplicationsAction(action)) {
    dispatch(getBorrowerApplications(action.payload.borrowerId, action.payload.sortingType));
  }

  return result;
}) as Middleware;

export default sortBorrowerApplicationsMiddleware;
