import React, { useMemo } from 'react';
import clsx from 'clsx';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import ButtonWithImage from 'components/ButtonWithImage';
import StatusRule from './StatusRule';
import styles from './StatusRules.module.scss';

export interface StatusRulesProps {
  rules: ApplicationStatusRule[];
  onStatusRuleAdd: () => void;
  onStatusRuleEdit: (statusRule: ApplicationStatusRule) => void;
  onStatusRuleDelete: (statusRule: ApplicationStatusRule) => void;
  isAddRuleDisabled?: boolean;
}

const MAX_STATUS_RULES_LENGTH = 20;

const StatusRules = ({
  rules,
  onStatusRuleAdd,
  onStatusRuleEdit,
  onStatusRuleDelete,
  isAddRuleDisabled,
}: StatusRulesProps) => {
  const renderRule = (rule: ApplicationStatusRule) => (
    <StatusRule key={rule.id} rule={rule} onRuleDelete={onStatusRuleDelete} onRuleEdit={onStatusRuleEdit} />
  );

  const sortedStatusRules = useMemo(() => {
    return rules.sort((firstRule, secondRule) => {
      return new Date(firstRule.createdAt).getTime() - new Date(secondRule.createdAt).getTime();
    });
  }, [rules]);

  return (
    <div className={styles.container}>
      <div>{sortedStatusRules.map(renderRule)}</div>
      {rules.length < MAX_STATUS_RULES_LENGTH && (
        <ButtonWithImage
          disabled={isAddRuleDisabled}
          className={clsx(styles.addStatusRuleButton, !rules.length && styles.withBorderAddStatusRuleButton)}
          title="Add Status Rule"
          kind="add"
          onClick={onStatusRuleAdd}
        />
      )}
    </div>
  );
};

export default StatusRules;
