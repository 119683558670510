import React, { FC } from 'react';
import TableBodyContent from 'components/Table/TableBodyContent';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import { NullableItems } from 'pagination';
import SkeletonVersionTableRow from './SkeletonVersionTableRow';
import VersionTableRow from './VersionTableRow';
import { StrategyGroupVersion } from 'api/Types';
import useSorting from 'hooks/useSorting';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { VersionsTableSortingField } from 'VersionsViewData/Types';
import { getVersionsListData, setSortingType } from 'VersionsViewData/Actions';
import { VERSIONS_TABLE_COLUMNS_SIZES } from 'components/VersionsView/VersionsTableView/TableColumnSizes';
import { TableActionCell } from 'components/Table';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

interface StrategiesTableViewProps {
  items: NullableItems<StrategyGroupVersion>;
  origin: StrategyActionOrigin;
  currentVersionId: string | undefined;
}

const VersionsTableView: FC<StrategiesTableViewProps> = ({ items, origin, currentVersionId }) => {
  const { sortingType } = useSelector((state: ReduxState) => state.versionsListData);
  const dispatch = useDispatch();
  const onSort = (field: VersionsTableSortingField, ascending: boolean) =>
    batch(() => {
      const sortingTypeData = { field, ascending };
      dispatch(setSortingType(sortingTypeData));
      dispatch(getVersionsListData({ sortingType: sortingTypeData }));
    });
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);
  const renderRow = (item: StrategyGroupVersion) => <VersionTableRow versionData={item} key={item.id} origin={origin} isCurrent={currentVersionId === item.id} />;

  return (
    <div>
      <TableHead useActions>
        <TableHeadCell
          ascending={getSortingType(VersionsTableSortingField.Version)}
          onClick={() => changeSorting(VersionsTableSortingField.Version)}
          width={VERSIONS_TABLE_COLUMNS_SIZES.VERSION}
        >
          Version
        </TableHeadCell>
        <TableHeadCell
          ascending={getSortingType(VersionsTableSortingField.Status)}
          onClick={() => changeSorting(VersionsTableSortingField.Status)}
          width={VERSIONS_TABLE_COLUMNS_SIZES.STATUS}
        >
          Status
        </TableHeadCell>
        <TableHeadCell
          ascending={getSortingType(VersionsTableSortingField.UpdatedDate)}
          onClick={() => changeSorting(VersionsTableSortingField.UpdatedDate)}
          width={VERSIONS_TABLE_COLUMNS_SIZES.UPDATED}
        >
          Updated
        </TableHeadCell>
        <TableHeadCell width={VERSIONS_TABLE_COLUMNS_SIZES.DESCRIPTION}>Description</TableHeadCell>
        <TableActionCell />
      </TableHead>
      <TableBodyContent
        rows={items}
        renderSkeletonTableRow={(index) => <SkeletonVersionTableRow key={index} />}
        renderTableRow={renderRow}
      />
    </div>
  );
};

export default VersionsTableView;
