import React from 'react';
import times from 'lodash/times';
import styles from './StrategyTileSkeleton.module.scss';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';

const StrategyTileSkeleton = () => {
  const renderBodyItem = (index: number) => (
    <div key={index}>
      <div className={styles.divider} />
      <div className={styles.line}>
        <SkeletonRectangle width="92px" color="primary20" height="16px" />
        <SkeletonRectangle width="16px" color="primary10" height="16px" marginLeft="4px" />
        <SkeletonRectangle width="51px" color="primary10" height="16px" marginLeft="8px" />
      </div>
      <div className={styles.line}>
        <SkeletonCircle width="24px" color="primary10" height="24px" />
        <SkeletonRectangle width="232px" color="primary10" height="16px" marginLeft="8px" />
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <SkeletonRectangle width="229px" color="primary20" height="24px" />
      </div>
      <div className={styles.body}>{times(3, renderBodyItem)}</div>
      <div className={styles.divider} />
      <div className={styles.footer}>
        <SkeletonRectangle width="150px" color="primary10" height="16px" />
      </div>
    </div>
  );
};

export default StrategyTileSkeleton;
