import { AnyAction } from 'redux';
import { DeclineReasonByProduct } from 'LoanOriginationSystemDeclineReasons/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { DeclineReasonsActionType } from './ActionTypes';

export interface GetDeclineReasonsAction extends AnyAction {
  type: DeclineReasonsActionType.GetDeclineReasons;
  payload: {
    productId: string;
  };
}

export interface GetDeclineReasonsSuccessAction extends AnyAction {
  type: DeclineReasonsActionType.GetDeclineReasonsSuccess;
  payload: {
    declineReasons: string[];
    productId: string;
  };
}

export interface UpdateDeclineReasonsAction extends AnyAction {
  type: DeclineReasonsActionType.UpdateDeclineReasons;
  payload: {
    declineReasons: string[];
    productId: string;
  };
}

export interface UpdateDeclineReasonsSuccessAction extends AnyAction {
  type: DeclineReasonsActionType.UpdateDeclineReasonsSuccess;
  payload: DeclineReasonByProduct;
}

export const getDeclineReasons = (productId: string): GetDeclineReasonsAction => {
  return {
    type: DeclineReasonsActionType.GetDeclineReasons,
    payload: {
      productId,
    },
  };
};

export const getDeclineReasonsSuccess = (declineReasonInfo: DeclineReasonByProduct): GetDeclineReasonsSuccessAction => {
  return {
    type: DeclineReasonsActionType.GetDeclineReasonsSuccess,
    payload: {
      productId: declineReasonInfo.productId,
      declineReasons: declineReasonInfo.declineReasons,
    },
  };
};

export const updateDeclineReasons = (productId: string, declineReasons: string[]): UpdateDeclineReasonsAction => ({
  type: DeclineReasonsActionType.UpdateDeclineReasons,
  payload: {
    productId,
    declineReasons,
  },
});

export const updateDeclineReasonsSuccess = (result: DeclineReasonByProduct): UpdateDeclineReasonsSuccessAction => ({
  type: DeclineReasonsActionType.UpdateDeclineReasonsSuccess,
  payload: result,
});

export const updateDeclineReasonsFailure = FailedActionCreator(DeclineReasonsActionType.UpdateDeclineReasonsFailure);

export const getDeclineReasonsFailure = FailedActionCreator(DeclineReasonsActionType.GetDeclineReasonsFailure);
