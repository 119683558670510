import React from 'react';
import styles from './ResultTable.module.scss';
import ScrollTablePartSkeleton from './ScrollTablePart/ScrollTablePartSkeleton/ScrollTablePartSkeleton';
import StaticTablePartSkeleton from './StaticTablePart/StaticTablePartSkeleton/StaticTablePartSkeleton';

const ResultTableSkeleton = () => {
  return (
    <div className={styles.container}>
      <StaticTablePartSkeleton />
      <ScrollTablePartSkeleton />
    </div>
  );
};

export default ResultTableSkeleton;
