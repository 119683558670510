import React from 'react';
import ButtonWithImage from 'components/ButtonWithImage';
import styles from './OptionList.module.scss';

interface OptionList<Option> {
  options: Option[];
  addButtonText: string;
  deleteButtonText: string;
  maxItemsCount?: number;
  className?: string;
  renderOption: (option: Option, index: number) => React.ReactElement;
  removeOption: (option: Option, index: number) => void;
  addOption: () => void;
}

const OptionList = <Option extends object | string>({
  options,
  deleteButtonText,
  addButtonText,
  maxItemsCount = Infinity,
  renderOption,
  removeOption,
  addOption,
  className,
}: OptionList<Option>) => {
  const renderRow = (option: Option, index: number, showRemoveOption: boolean) => {
    return (
      <div key={index} className={styles.option}>
        {showRemoveOption && (
          <div className={styles.deleteAction} onClick={() => removeOption(option, index)}>
            {deleteButtonText}
          </div>
        )}
        {renderOption(option, index)}
      </div>
    );
  };

  return (
    <div className={className}>
      {options.map((option, index) => renderRow(option, index, options.length > 1))}
      {options.length < maxItemsCount && (
        <div className={styles.addOptionContainer}>
          <div className={styles.dashes} />
          <ButtonWithImage kind="add" title={addButtonText} onClick={() => addOption()} />
          <div className={styles.dashes} />
        </div>
      )}
    </div>
  );
};

export default OptionList;
