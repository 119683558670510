import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { ApplicationsSortingType } from 'api/LoanOriginationSystem/Types';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT = 10;

export interface IntermediaryApplicationsPaginationParams {
  sortingType: ApplicationsSortingType;
  intermediaryId: string;
}

const intermediaryApplicationsPagination = Pagination<
  ReduxState,
  Application,
  IntermediaryApplicationsPaginationParams
>('loanOriginationSystemIntermediaryDetails', INTERMEDIARY_APPLICATIONS_PER_PAGE_DEFAULT, () => ['applications']);

export default intermediaryApplicationsPagination;
