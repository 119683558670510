export enum LoanOriginationSystemProductsActionType {
  GetProducts = 'loanOriginationSystemProducts/getProducts',
  GetProductsSuccess = 'loanOriginationSystemProducts/getProductsSuccess',
  GetProductsFailure = 'loanOriginationSystemProducts/getProductsFailure',
  SetSearchInputValue = 'loanOriginationSystemProducts/setSearchInputValue',
  SetProductToDuplicate = 'loanOriginationSystemProducts/setProductIdToDuplicate',
  SetStatusChangeData = 'loanOriginationSystemProducts/setStatusChangeData',
  ChangeProductStatus = 'loanOriginationSystemProducts/changeProductStatus',
  ChangeProductStatusSuccess = 'loanOriginationSystemProducts/changeProductStatusSuccess',
  ChangeProductStatusFailure = 'loanOriginationSystemProducts/changeProductStatusFailure',
  DuplicateProduct = 'loanOriginationSystemProducts/duplicateProduct',
  DuplicateProductSuccess = 'loanOriginationSystemProducts/duplicateProductSuccess',
  DuplicateProductFailure = 'loanOriginationSystemProducts/duplicateProductFailure',
  CreateProduct = 'loanOriginationSystemProducts/createProduct',
  CreateProductSuccess = 'loanOriginationSystemProducts/createProductSuccess',
  CreateProductFailure = 'loanOriginationSystemProducts/createProductFailure',
  UpdateProduct = 'loanOriginationSystemProducts/updateProduct',
  UpdateProductSuccess = 'loanOriginationSystemProducts/updateProductSuccess',
  UpdateProductFailure = 'loanOriginationSystemProducts/updateProductFailure',
  GetProduct = 'loanOriginationSystemProducts/getProduct',
  GetProductSuccess = 'loanOriginationSystemProducts/getProductSuccess',
  GetProductFailure = 'loanOriginationSystemProducts/getProductFailure',
  DownloadApiRequestTemplate = 'loanOriginationSystemProducts/downloadApiRequestTemplate',
  DownloadApiRequestTemplateSuccess = 'loanOriginationSystemProducts/downloadApiRequestTemplateSuccess',
  DownloadApiRequestTemplateFailure = 'loanOriginationSystemProducts/downloadApiRequestTemplateFailure',
  ResetState = 'loanOriginationSystemProducts/resetState',
}
