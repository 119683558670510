import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './Table.module.scss';

export interface TableProps {
  className?: string;
}

const Table = ({ children, className }: PropsWithChildren<TableProps>) => {
  return <div className={clsx(styles.table, className)}>{children}</div>;
};

export default Table;
