import { Middleware } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import { RunDecisionWizardActionType } from 'RunDecisionWizard/actionTypes';
import {
  GetApplicationStrategyInputs,
  getApplicationStrategyInputsFailure,
  getApplicationStrategyInputsSuccess,
} from 'RunDecisionWizard/actions';
import ApplicationDecisionRunInputsLegacyApi, {
  ApplicationStrategyInputs,
} from 'api/LoanOriginationSystem/ApplicationDecisionRunInputsLegacyApi';

const GetApplicationStrategyInputsMiddleware: (api: ApplicationDecisionRunInputsLegacyApi) => Middleware = (api) =>
  Fetcher<ApplicationStrategyInputs, GetApplicationStrategyInputs>(
    RunDecisionWizardActionType.GetApplicationStrategiesInputs,
    getApplicationStrategyInputsSuccess,
    getApplicationStrategyInputsFailure,
    ({ payload }) => api.getApplicationStrategyInputs(payload.applicationId, payload.strategyId),
  );

export default GetApplicationStrategyInputsMiddleware;
