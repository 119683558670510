import {
  OrganizationInfoType,
  UpdateOrganizationInfoParams,
  UpdateRegionSettingsRequestData,
} from 'CompanyInformation/CompanyInformationTypes';
import { ApiActivationSortingType, ModuleType, Strategy, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { VariableItem } from './VariablesType';
import { CopyModuleData, CreateModuleData, DuplicateBranchData, ModuleData } from 'ModuleInfo/Types';
import {
  Integration,
  ResponseWithMessage,
  IndividualCaseData,
  BatchProcessingCaseResults,
  BatchCaseUser,
  CompleteGetExternalIntegrationRequestData,
  CompleteGetExternalIntegrationResponseData,
  ResponseBranchData,
  OutputData,
  ProcessingStrategyInput,
} from './DecisionEngineStrategiesType';
import { BranchInfo, BranchInfoRequestData, BranchParams, RuleData, RuleType } from 'BranchInfo/Types';
import { CompleteEditExternalIntegrationRequestData } from 'EditExternalIntegration/Types';
import { BranchData } from 'AddBranch/Types';
import {
  CompleteCalculationScriptData,
  CompleteUploadRuleSetRequestData,
  CompleteRuleRequestData,
  CompleteSimpleOutputRequestData,
  DuplicateRuleRequestData,
} from 'RuleCreate/Types';
import { CompletedScoringModelRequestData } from 'ScoringModel/Types';
import { CompletedDeleteRuleData } from 'RuleDelete/Types';
import { CaseResult } from 'components/ProcessingBatchCase/ProcessingBatchCase';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/DecisionStrategiesTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { UpdateVersionStatusesParams } from 'ApiActivation/ActionCreator';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export interface UpdateUserInfoCredentials {
  _id: string;
  firstName: string;
  lastName: string;
}

export interface OrganizationApiType {
  updateOrganizationInfo(organizationInfo: UpdateOrganizationInfoParams): Promise<OrganizationInfoType>;
  updateRegionSettings(regionSettingsData: UpdateRegionSettingsRequestData): Promise<OrganizationInfoType>;
  updateOrganizationLogo(logo: File): Promise<OrganizationInfoType>;
}

export interface PaginationParams {
  page: number;
  perPage: number;
}

export interface UpdateRulesOrderParams {
  reorderedRuleIds: string[];
  moduleKey: string;
  branchIndex: number;
  strategyId: string;
  ruleType: RuleType;
}

export interface UpdateModuleBranchesOrderParams {
  strategyId: string;
  moduleKey: string;
  branchIndices: number[];
}

export interface ItemsWithTotalCount<Item> {
  items: Item[];
  itemsTotal: number | undefined;
}

export interface DecisionStrategiesRequestParams extends PaginationParams {
  search?: string;
}

export interface IndividualHistoryRequestParams extends PaginationParams {
  search?: string;
}

export interface BatchCaseRequestParams extends PaginationParams {
  search?: string;
}

export type BranchRuleType = 'ruleset' | 'conditions';

export interface GetBranchOptionsParams {
  moduleType: ModuleType | null;
  ruleType: BranchRuleType;
}

export interface ModuleItem {
  id: string;
  strategyId: string;
  moduleKey: string;
  strategyName: string;
  moduleName: string;
  moduleType: ModuleType;
}

export interface BranchItemInfo {
  strategyId: string;
  strategyName: string;
  moduleKey: string;
  moduleName: string;
  moduleType: ModuleType;
  branchIndex: number;
  branchName: string;
  rulesCount: number;
}

export interface BranchKeys {
  moduleKey: string;
  branchIndex: number;
}

export interface BranchIdentityAttrs extends BranchKeys {
  strategyId: string;
  moduleType: ModuleType;
}

export interface BranchIdentityParams {
  moduleKey: string;
  strategyId: string;
  branchIndex: number;
}

export interface CopyBranchRulesParams {
  ruleType: BranchRuleType;
  copyToBranchParams: BranchIdentityParams;
  copyFromBranchPrams: BranchIdentityParams;
}

export interface BatchHistoryRequestParams extends PaginationParams {
  search?: string;
}

export interface StrategyGroupVersion {
  id: string;
  locked: boolean;
  name: string;
  status: SystemStatus;
  title: string;
  displayTitle: string;
  updatedDate: string;
  version: number;
  description: string;
  updatedByUserInfo: UserInfo;
  hasModules: boolean;
}

export interface StrategyGroup {
  name: string;
  title: string;
  versionsNumber: number;
  archived: boolean;
  versions: StrategyGroupVersion[];
}

interface StrategyListData<ItemType> {
  items: ItemType[];
  page: number;
  perPage: number;
  total: number;
  users: UserInfo[];
  selectedUpdaters: UserInfo[];
}

export type StrategyGroupsData = StrategyListData<StrategyGroup>;
export type VersionsListData = StrategyListData<StrategyGroupVersion> & { searchInputValue?: string };

export interface DropdownStrategy {
  id: string;
  status: SystemStatus;
  version: number;
  title: string;
  name: string;
}

export interface DecisionEngineApi {
  deleteDecisionStrategyModule(strategyId: string, moduleLookupName: string): Promise<void>;
  updateModuleBranchesOrder(data: UpdateModuleBranchesOrderParams): Promise<void>;
  updateStrategyName(id: string, name: string): Promise<void>;
  updateStrategyDescription(id: string, description: string): Promise<void>;
  getVariablesForExternalIntegration(
    externalIntegrationData: CompleteGetExternalIntegrationRequestData,
  ): Promise<CompleteGetExternalIntegrationResponseData>;
  uploadBulkVariable(variable: File): Promise<VariableItem>;
  updateModulesOrder(strategyId: string, moduleIndices: number[]): Promise<Strategy>;
  updateModuleInfo(moduleInfo: ModuleData, strategyId: string): Promise<void>;
  updateBranchInfo(branchInfo: BranchParams): Promise<BranchParams>;
  createNewModule(moduleData: CreateModuleData): Promise<ResponseWithMessage>;
  duplicateModule(moduleData: CopyModuleData): Promise<ResponseWithMessage>;
  duplicateBranch(branchData: DuplicateBranchData): Promise<ResponseBranchData>;
  createNewBranch(branchData: BranchData): Promise<ResponseWithMessage>;
  copyBranchRules(
    ruleType: BranchRuleType,
    copyToParams: BranchIdentityParams,
    copyFromParams: BranchIdentityParams,
  ): Promise<void>;
  getDecisionStrategyUpdates(
    params: PaginationParams,
    strategyId: string,
  ): Promise<ItemsWithTotalCount<StrategyUpdateType>>;
  getBranchInfo({ strategyId, moduleKey, branchIndex }: BranchInfoRequestData): Promise<BranchInfo>;
  deleteBranch({ strategyId, moduleKey, branchIndex }: BranchParams): Promise<BranchParams>;
  getBranchOptions(params: GetBranchOptionsParams): Promise<BranchItemInfo[]>;
  addCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void>;
  editCalculationScript(scriptData: CompleteCalculationScriptData): Promise<void>;
  addRule(ruleData: CompleteRuleRequestData): Promise<void>;
  deleteRule(data: CompletedDeleteRuleData): Promise<void>;
  editRule(ruleData: CompleteRuleRequestData): Promise<void>;
  addSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void>;
  editSimpleOutput(simpleOutputData: CompleteSimpleOutputRequestData): Promise<void>;
  getIntegrationsList(): Promise<Integration[]>;
  getStrategiesList(): Promise<StrategiesListItem[]>;
  getStrategyInputs(strategyId: string): Promise<ProcessingStrategyInput[]>;
  getIndividualCaseList(
    params: IndividualHistoryRequestParams,
  ): Promise<ItemsWithTotalCount<IndividualProcessingHistoryCase>>;
  updateExternalIntegration(externalIntegrationData: CompleteEditExternalIntegrationRequestData): Promise<void>;
  findModule(moduleName: string, abortSignal: AbortSignal): Promise<ModuleItem[]>;
  runIndividualStrategy(strategyId: string, inputs: IndividualRunInputs, applicationId?: string): Promise<string>;
  getIndividualCase(caseId: string): Promise<IndividualCaseData>;
  uploadRuleSet(ruleSet: CompleteUploadRuleSetRequestData): Promise<void>;
  updateScoringRuleSegment(data: CompletedScoringModelRequestData): Promise<void>;
  updateApiActivations(activationList: UpdateVersionStatusesParams): Promise<void>;
  updateRulesOrder(data: UpdateRulesOrderParams): Promise<void>;
  getBatchCaseList(params: BatchHistoryRequestParams): Promise<ItemsWithTotalCount<BatchProcessingHistoryCase>>;
  runBatchStrategy(strategyId: string, batchData: File, processName?: string): Promise<void>;
  getBatchCase(strategyId: string, params: BatchCaseRequestParams): Promise<BatchProcessingCase>;
  duplicateRule(ruleData: DuplicateRuleRequestData): Promise<RuleData>;
  archiveStrategies(params: ChangeArchiveStatusParams): Promise<void>;
  restoreStrategies(params: ChangeArchiveStatusParams): Promise<void>;
  getVersionsData(strategyName: string, params: GetVersionsListParams): Promise<VersionsListData>;
  assignOutputVariable(
    strategyId: string,
    outputIndex: number,
    variableId: string,
    moduleName: string,
  ): Promise<OutputData>;
}

export interface ChangeArchiveStatusParams {
  id?: string;
  groupName?: string;
}

export enum OrderBy {
  Asc = 'asc',
  Desc = 'desc',
}

export interface GetStrategyListParams {
  page: number;
  perPage: number;
  showArchived: boolean;
  searchInputValue?: string;
  updaters?: string[];
  versionsStatuses?: SystemStatus[];
  sortingType?: ApiActivationSortingType;
}

export interface GetVersionsListParams extends GetStrategyListParams {
  sortBy: string;
  orderBy: OrderBy;
}

export interface DownloadAPI {
  downloadApiTemplateURL(strategyId: string, type: string): Promise<void>;
  downloadRulesTemplate(moduleType: string, strategyId: string, moduleKey: string, branchIndex: number): void;
  downloadBatchTemplate(strategyId: string): Promise<void>;
  downloadSimulationResults(caseId: string): void;
  downloadSimulation(caseId: string): void;
  downloadTransactions(): Promise<void>;
}

export interface ChangeStrategyArchiveStatusParams {
  type: string;
  strategyName: string;
  actionOrigin?: StrategyActionOrigin;
  id?: string;
  version?: number;
}

export interface StrategiesListItem {
  id: string;
  name: string;
  status: SystemStatus;
}

export interface IndividualProcessingHistoryCase {
  id: string;
  name: string;
  type: 'Web' | 'API';
  createdBy: string;
  updatedBy: string;
  date: string;
  strategy_name: string;
  strategyId: string;
  inputs: IndividualRunInputs;
  passed: boolean;
  errors: string[];
}

export interface ApplicationNote {
  id: string;
  createdAt: string;
  updatedAt: string;
  note: string;
  author: {
    id: string;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

export interface IndividualRunInputs {
  [key: string]: string | number | null;
}

export interface BatchProcessingHistoryCase {
  id: string;
  name: string;
  date: string;
  strategy_name: string;
  progress: number;
}

export interface BatchProcessingCase extends ItemsWithTotalCount<BatchIndividualCase> {
  data: BatchProcessingCaseData;
  results: BatchProcessingCaseResults;
}

export interface BatchProcessingCaseData {
  date: string;
  displayTitle: string;
  strategyId: string;
  strategyName: string;
  user: BatchCaseUser;
  id: string;
}

export interface BatchIndividualCase {
  name: string;
  status: CaseResult;
  id: string;
  strategyDisplayName: string;
}

export * from './LoanOriginationSystem/Types';
