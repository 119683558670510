import { AnyAction } from 'redux';

import {
  ACTION_TYPE_EDIT_COMPANY_USER_REQUEST,
  ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS,
  ACTION_TYPE_EDIT_COMPANY_USER_FAILURE,
} from './ActionTypes';
import { EditUserState } from './EditCompanyUserTypes';
import { GetCompanyUserFailureAction } from './EditCompanyUserActionCreator';

const initialState: EditUserState = {
  isLoading: false,
  error: '',
};

const EditCompanyUserReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS: {
      return {
        ...state,
        error: '',
        isLoading: false,
      };
    }
    case ACTION_TYPE_EDIT_COMPANY_USER_FAILURE: {
      const { message } = (action as GetCompanyUserFailureAction).payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case ACTION_TYPE_EDIT_COMPANY_USER_REQUEST: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }
    default:
      return state;
  }
};

export default EditCompanyUserReducer;
