const cumulativeElementOffset = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  return {
    top: rect.y,
    left: rect.x,
  };
};

export default cumulativeElementOffset;
