import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import {
  CreateDocuSignIntegrationSuccessAction,
  UpdateDocuSignIntegrationSuccessAction,
} from 'ESignIntegrations/ActionCreator';
import { ESignIntegrationsActionType } from 'ESignIntegrations/ActionTypes';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';

const isCreateDocuSignIntegrationSuccessAction = (
  action: AnyAction,
): action is CreateDocuSignIntegrationSuccessAction =>
  action.type === ESignIntegrationsActionType.CreateDocuSignIntegrationSuccess;

const isUpdateDocuSignIntegrationSuccessAction = (
  action: AnyAction,
): action is UpdateDocuSignIntegrationSuccessAction =>
  action.type === ESignIntegrationsActionType.UpdateDocuSignIntegrationSuccess;

const notifyOnCredentialsUpdateMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateDocuSignIntegrationSuccessAction(action) || isUpdateDocuSignIntegrationSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.DocuIntegrationSaved), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default notifyOnCredentialsUpdateMiddleware;
