import React, { useState, ChangeEvent, FC } from 'react';
import styles from './AddCalculationScript.module.scss';
import Button from 'components/Button';
import OverviewOfScripts from './OverviewOfScripts';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import InputWithCode from 'components/InputWithCode';
import CreateNewVariableLink from 'components/CreateNewVariableLink';
import { isEqual, noop } from 'lodash';
import { Option } from 'components/SelectInput/SelectInput';
import { CalculationScriptData } from 'RuleCreate/Types';
import { RuleBuilderState } from 'components/RuleBuilder/Types';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import getDataTypeAttributes from 'utils/ruleBuilder/getDataTypeAttributes';
import AutoCompletionMultiSelectFetch from 'components/AutoCompletionMultiselectFetch/AutoCompletionMultiSelectFetch';
import getShowingDataType from 'utils/ruleBuilder/getShowingDataType';

interface AddCalculationScriptProps {
  saveCalculationScript: (data: CalculationScriptData) => void;
  openVariableCreation: () => void;
  serializedRules?: RuleBuilderState[];
  calculationInputs?: string[];
  isSaving?: boolean;
}

const AddCalculationScript: FC<AddCalculationScriptProps> = ({
  saveCalculationScript,
  openVariableCreation,
  serializedRules = [],
  calculationInputs = [],
  isSaving,
}) => {
  const derivedSourceVariableId = serializedRules[0]?.sourceVariableId;
  const derivedJSCalculation = serializedRules[0]?.firstOperandValue;

  const [selectedRequiredVariablesOptionsList, setSelectedRequiredVariablesOptionsList] = useState(calculationInputs);

  const [sourceVariableId, setSourceVariableId] = useState(derivedSourceVariableId || undefined);

  const [jsCalculation, setJSCalculation] = useState(derivedJSCalculation || '');

  const onAddOption = (value: string) => {
    if (selectedRequiredVariablesOptionsList.includes(value)) {
      return;
    }
    setSelectedRequiredVariablesOptionsList([...selectedRequiredVariablesOptionsList, value]);
  };

  const handleJSCalculationChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setJSCalculation(event.target.value);
  };

  const onDeleteOption = (value: string) => {
    setSelectedRequiredVariablesOptionsList(selectedRequiredVariablesOptionsList.filter((option) => option !== value));
  };

  const areCalculationScriptChanged = [
    [sourceVariableId, derivedSourceVariableId],
    [jsCalculation, derivedJSCalculation],
    [selectedRequiredVariablesOptionsList, calculationInputs],
  ].some(([a, b]) => !isEqual(a, b));

  const isSaveButtonEnabled = sourceVariableId && jsCalculation.trim() && areCalculationScriptChanged;

  const onSaveCalculationScript = () =>
    saveCalculationScript({
      propertyAttribute: sourceVariableId as string,
      requiredVariables: selectedRequiredVariablesOptionsList,
      comparisonValue: jsCalculation,
    });

  const sourceVariable = useVariableAttributes(sourceVariableId!);

  const dataTypeWithAttributes = getDataTypeAttributes(sourceVariable);

  return (
    <div className={styles.content}>
      <OverviewOfScripts />
      <InputWithDataTypeLabel
        labelTitle="Output Variable"
        onChange={({ value }: Option) => setSourceVariableId(value)}
        value={sourceVariableId}
        className={styles.outputVariablesInput}
        dataType={getShowingDataType(dataTypeWithAttributes)}
        link={<CreateNewVariableLink onClick={openVariableCreation} />}
        hasRightNeighbour
      />
      <AutoCompletionMultiSelectFetch
        id="requiredVariablesSelect"
        labelTitle="Required Variables"
        selectedOptions={selectedRequiredVariablesOptionsList}
        onInputChange={noop}
        onDeleteOption={onDeleteOption}
        onSelect={onAddOption}
      />
      <div className={styles.equalsSeparator}>
        <div className={styles.separatorLine} />
        <p className={styles.separatorText}>Equals</p>
      </div>
      <InputWithCode labelText="JavaScript Calculation" value={jsCalculation} onChange={handleJSCalculationChange} />
      <Button
        size="form"
        kind="primary"
        className={styles.continueButton}
        onClick={onSaveCalculationScript}
        disabled={!isSaveButtonEnabled}
        isLoading={isSaving}
      >
        Save
      </Button>
    </div>
  );
};

export default AddCalculationScript;
