import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { ChangeFilterStateConfig, FilterInitialData, ReportingTimeDeltas } from './Types';

export interface ChangeFilterStateAction {
  type: LoanOriginationSystemReportingActionType.ChangeFilterState;
  payload: ChangeFilterStateConfig;
}

export interface GetFilterDataSuccessAction {
  type: LoanOriginationSystemReportingActionType.GetFilterDataSuccess;
  payload: FilterInitialData;
}

export interface GetFilterDataAction {
  type: LoanOriginationSystemReportingActionType.GetFilterData;
}

export interface ChangeTimeDeltaAction {
  type: LoanOriginationSystemReportingActionType.ChangeTimeDelta;
  payload: ReportingTimeDeltas;
}

export interface ToggleStatusAction {
  type: LoanOriginationSystemReportingActionType.ToggleStatus;
  payload: string;
}

export interface OpenReportingFiltersBarAction {
  type: LoanOriginationSystemReportingActionType.OpenFiltersBar;
}

export interface CloseReportingFiltersBarAction {
  type: LoanOriginationSystemReportingActionType.CloseFiltersBar;
}

export interface ResetFilterStateAction {
  type: LoanOriginationSystemReportingActionType.ResetFilterState;
}

export const openReportingFiltersBar = (): OpenReportingFiltersBarAction => {
  return {
    type: LoanOriginationSystemReportingActionType.OpenFiltersBar,
  };
};

export const closeReportingFiltersBar = (): CloseReportingFiltersBarAction => {
  return {
    type: LoanOriginationSystemReportingActionType.CloseFiltersBar,
  };
};

export const changeFilterState = (payload: ChangeFilterStateConfig): ChangeFilterStateAction => {
  return {
    type: LoanOriginationSystemReportingActionType.ChangeFilterState,
    payload,
  };
};

export const getFilterData = (): GetFilterDataAction => {
  return {
    type: LoanOriginationSystemReportingActionType.GetFilterData,
  };
};

export const getFilterDataSuccess = (payload: FilterInitialData): GetFilterDataSuccessAction => {
  return {
    type: LoanOriginationSystemReportingActionType.GetFilterDataSuccess,
    payload,
  };
};

export const getFilterDataFailure = FailedActionCreator(LoanOriginationSystemReportingActionType.GetFilterDataFailure);

export const changeTimeDelta = (payload: ReportingTimeDeltas): ChangeTimeDeltaAction => {
  return {
    type: LoanOriginationSystemReportingActionType.ChangeTimeDelta,
    payload,
  };
};

export const toggleStatus = (payload: string): ToggleStatusAction => {
  return {
    type: LoanOriginationSystemReportingActionType.ToggleStatus,
    payload,
  };
};

export const resetFilterState = (): ResetFilterStateAction => {
  return {
    type: LoanOriginationSystemReportingActionType.ResetFilterState,
  };
};
