import React from 'react';
import styles from './ApplicationEmailAttachmentsDownloadSnackbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState';
import { DocumentDownloadingState } from 'LoanOriginationSystemApplicationPage/Documents/Types';
import { FileIcon } from 'static/images';
import { DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION } from 'Snackbar/Middleware';
import { EmailArchiveAttachments } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { removeDownloadedEmailAttachment } from 'LoanOriginationSystemEmails/ActionCreator';
import { getAbbreviatedTextLine } from 'utils/getAbbreviatedString';

export interface ApplicationEmailAttachmentsDownloadSnackbarProps {
  attachment: Omit<EmailArchiveAttachments, 'id' | 'size'>;
  attachmentDownloadingId: string;
}

const MAX_ATTACHMENT_NAME_LENGTH = 38;

const ApplicationEmailAttachmentsDownloadSnackbar = ({
  attachment,
  attachmentDownloadingId,
}: ApplicationEmailAttachmentsDownloadSnackbarProps) => {
  const dispatch = useDispatch();

  const emailAttachmentsDownloadingState: DocumentDownloadingState = useSelector(
    (state: ReduxState) => state.loanOriginationSystemEmails.downloadableAttachments[attachmentDownloadingId],
  );

  const onDocumentDownloadingStateReset = () => {
    dispatch(removeDownloadedEmailAttachment(attachmentDownloadingId));
  };

  return (
    <div className={styles.documentContainer}>
      <div className={styles.documentIcon}>
        <FileIcon />
      </div>
      <div className={styles.documentName}>
        {getAbbreviatedTextLine(`${attachment.name}.zip`, MAX_ATTACHMENT_NAME_LENGTH)}
      </div>
      <LoaderWithState
        state={emailAttachmentsDownloadingState === 'loading' ? LoaderState.Updating : emailAttachmentsDownloadingState as LoaderState}
        onStateReset={onDocumentDownloadingStateReset}
        className={styles.loader}
        successTimeout={DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION}
        failureTimeout={DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION}
      />
    </div>
  );
};

export default ApplicationEmailAttachmentsDownloadSnackbar;
