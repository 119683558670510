import React, { FC } from 'react';
import ConfirmPopup from 'components/ConfirmPopup';
import styles from './DuplicateProductConfirmationPopup.module.scss';

export interface DuplicateProductConfirmationPopupProps {
  onPopupClose: () => void;
  onConfirmClick: () => void;
  loading?: boolean;
  productName?: string;
}

const DuplicateProductConfirmationPopup: FC<DuplicateProductConfirmationPopupProps> = ({
  onConfirmClick,
  onPopupClose,
  loading,
  productName,
}) => {
  const message = `Please confirm that you would like to duplicate ${productName} product.`;

  return (
    <ConfirmPopup
      title="Duplicate Product"
      message={message}
      confirmText="Duplicate"
      declineText="No, Go Back"
      confirmButtonClassName={styles.confirmButton}
      declineButtonClassName={styles.declineButton}
      onPopupClose={onPopupClose}
      onConfirmClick={onConfirmClick}
      loading={loading}
    />
  );
};

export default DuplicateProductConfirmationPopup;
