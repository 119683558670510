import { FailedAction } from 'utils/actions/FailedActionCreator';
import { IntermediaryConfigurationState } from './Types';
import { IntermediaryConfigurationActionType } from './ActionTypes';
import { DownloadApiRequestTemplateAction, DownloadApiRequestTemplateSuccessAction } from './ActionCreator';

export type IntermediaryConfigurationActions =
  | DownloadApiRequestTemplateAction
  | DownloadApiRequestTemplateSuccessAction
  | FailedAction<IntermediaryConfigurationActionType.DownloadApiRequestTemplateFailure>;

export const initialState: IntermediaryConfigurationState = {
  isDownloadApiRequestTemplateInProgress: false,
};

const intermediaryConfigurationReducer = (
  state: IntermediaryConfigurationState = initialState,
  action: IntermediaryConfigurationActions,
): IntermediaryConfigurationState => {
  switch (action.type) {
    case IntermediaryConfigurationActionType.DownloadApiRequestTemplate: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: true,
      };
    }
    case IntermediaryConfigurationActionType.DownloadApiRequestTemplateFailure:
    case IntermediaryConfigurationActionType.DownloadApiRequestTemplateSuccess: {
      return {
        ...state,
        isDownloadApiRequestTemplateInProgress: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default intermediaryConfigurationReducer;
