import clsx from 'clsx';
import React, { FC, DetailedHTMLProps } from 'react';
import styles from './ExternalLink.module.scss';

type HTMLLinkProps = DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;

const ExternalLink: FC<HTMLLinkProps> = ({ children, className, ...props }) => {
  return (
    <a {...props} target="_blank" rel="noopener noreferrer" className={clsx(styles.externalLink, className)}>
      {children}
    </a>
  );
};

export default ExternalLink;
