import { Middleware } from 'redux';
import { GetUserInfoError, GetUserInfoSuccess } from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { UserApi } from 'api/Core/UserApi';
import AccountDetailsActionType from 'AccountDetails/ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

const AccountDetailsMiddleware: (api: UserApi) => Middleware = (api) =>
  Fetcher<UserInfo>(AccountDetailsActionType.GetAccountDetails, GetUserInfoSuccess, GetUserInfoError, async () => {
    const user = await api.getUserInfo();
    return user;
  });

export default AccountDetailsMiddleware;
