import React, { forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import SelectInput, { Option, SelectInputProps } from 'components/SelectInput/SelectInput';
import { findSelectedOption } from './utils';

export interface AutoCompletionAsyncProps
  extends Omit<SelectInputProps, 'open' | 'userInput' | 'options' | 'onInputChange'> {
  fetchOptions: (value: string, abortSignal: AbortSignal) => Promise<Option[]>;
  fetchSelectedOption?: (value: string) => Promise<Option | undefined>;
}

const FETCH_DEBOUNCE_TIME = 300;

const AutoCompletionAsync = forwardRef<HTMLInputElement, AutoCompletionAsyncProps>(
  ({ value, onChange, fetchOptions, fetchSelectedOption, ...selectInputProps }, ref) => {
    const [userInput, setUserInput] = useState<string | null>(null);
    const [optionsList, setOptionsList] = useState<Option[]>([]);
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [selectedOption, setSelectedOption] = useState<Option | undefined>(undefined);

    const fetchAbortController = useRef<AbortController | null>(null);
    const fetchOptionsList = useCallback(
      async (optionName: string) => {
        // Abort previous fetch if exists
        if (fetchAbortController.current) {
          fetchAbortController.current.abort();
        }

        fetchAbortController.current = new AbortController();
        setLoading(true);

        try {
          const suggestedOptions = await fetchOptions(optionName, fetchAbortController.current.signal);
          if (selectedOption) {
            suggestedOptions.unshift(selectedOption);
          }
          setOptionsList(suggestedOptions);
          fetchAbortController.current = null;
          setLoading(false);
          setLoaded(true);
        } catch (error) {
          setLoading(false);
          setLoaded(true);
          setOptionsList([]);
        }
      },
      [fetchOptions],
    );

    const updateOptionsListWithCurrentValue = useCallback(
      async (currentOptionsList: Option[], currentValue?: string) => {
        if (!currentValue) {
          setSelectedOption(undefined);
        }

        if (!currentValue) {
          return;
        }

        const currentOptionInList = findSelectedOption(currentOptionsList, currentValue);

        if (currentOptionInList) {
          setSelectedOption(currentOptionInList);
          return;
        }

        if (!fetchSelectedOption) {
          return;
        }

        setLoading(true);

        try {
          const currentOption = await fetchSelectedOption(currentValue);
          setSelectedOption(currentOption);

          if (currentOption) {
            setOptionsList([currentOption, ...currentOptionsList]);
          }
        } catch (error) {
          setSelectedOption(undefined);
        }

        setLoading(false);
        setLoaded(true);
      },
      [fetchSelectedOption],
    );

    useEffect(() => {
      updateOptionsListWithCurrentValue(optionsList, value);
    }, [optionsList, value]);

    const fetchOptionsListDebounced = useRef(debounce(fetchOptionsList, FETCH_DEBOUNCE_TIME));

    const handleInputChange = (inputValue: string) => {
      fetchOptionsListDebounced.current(inputValue);

      setUserInput(inputValue);
    };

    const handleChange = (option: Option) => {
      setUserInput(null);
      onChange(option);
    };

    useEffect(() => {
      fetchOptionsList('');
    }, [fetchOptionsList]);

    return (
      <SelectInput
        selectedOption={selectedOption}
        userInput={userInput}
        options={optionsList}
        onInputChange={handleInputChange}
        onChange={handleChange}
        loading={loading}
        loaded={loaded}
        {...selectInputProps}
        ref={ref}
      />
    );
  },
);

export default AutoCompletionAsync;
