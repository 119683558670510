import RowActions from 'components/RowActions';
import React, { FC } from 'react';
import { openArchiveStrategyWizard } from 'components/ArchiveStrategyWizard/Actions';
import { useDispatch } from 'react-redux';
import { openDeleteStrategyWizard } from 'DeleteStrategyWizard/Actions';
import styles from './StrategyActions.module.scss';
import { RowActionsProps } from 'components/RowActions/RowActions';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

interface StrategyActionsProps extends Omit<RowActionsProps, 'actions'> {
  name: string;
  archived: boolean;
}

const StrategyActions: FC<StrategyActionsProps> = ({ name, archived, ...props }) => {
  const dispatch = useDispatch();

  return (
    <RowActions
      {...props}
      className={styles.container}
      actions={[
        {
          title: `${archived ? 'Restore' : 'Archive'} Strategy`,
          handler: () => dispatch(openArchiveStrategyWizard(name, archived)),
          separatorRequired: true,
        },
        {
          title: 'Delete Strategy',
          handler: () => dispatch(openDeleteStrategyWizard(
            'strategy',
            { name },
            StrategyActionOrigin.StrategiesGroupsList,
            true
          )),
          danger: true,
        },
      ]}
    />
  );
};

export default StrategyActions;
