import React, { ReactNode, FC } from 'react';
import clsx from 'clsx';

import styles from './PopUpContent.module.scss';

interface PopUpContentProps {
  children: ReactNode;
  hasTopMargin?: boolean;
  className?: string;
}

const PopUpContent: FC<PopUpContentProps> = ({ children, hasTopMargin = false, className }) => {
  const popupClassNames = clsx(styles.popupContent, hasTopMargin && styles.popupContentWithTopMargin, className);

  return <div className={popupClassNames}>{children}</div>;
};

export default PopUpContent;
