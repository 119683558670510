import React, { FC, FocusEvent } from 'react';
import TextInput from 'components/TextInput/TextInput';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import useMask from 'hooks/useMask';
import maskNumberValue from 'utils/masks/maskNumberValue';
import { DecimalPartLimiter, removeNonNumericChars } from './utils';

interface NumberInputProps {
  value: string;
  labelTitle?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  onBlur?: (value: string, event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  containerClassName?: string;
  disabled?: boolean;
  maxLength?: number;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  loaderClassName?: string;
}

const MAX_DECIMAL_PART_LENGTH = 20;

const limitDecimalPart = DecimalPartLimiter(MAX_DECIMAL_PART_LENGTH);

const NumberInput: FC<NumberInputProps> = ({
  value,
  labelTitle,
  onChange,
  onFocus,
  placeholder,
  onBlur,
  containerClassName,
  disabled,
  maxLength,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  loaderClassName,
}) => {
  const [maskedValue, inputRef, handleInputChange, handleKeyDown, handleKeyUp, handleClick] = useMask(
    value,
    (val: string) => onChange(limitDecimalPart(val)),
    maskNumberValue,
    removeNonNumericChars,
    (char: string) => char === ',',
  );

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => onBlur?.(value, event);

  return (
    <TextInput
      value={maskedValue}
      labelTitle={labelTitle}
      onChange={handleInputChange}
      inputRef={inputRef}
      hasRightNeighbour
      placeholder={placeholder}
      onBlur={handleBlur}
      onFocus={onFocus}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onClick={handleClick}
      containerClassName={containerClassName}
      maxLength={maxLength}
      disabled={disabled}
      showLoader={showLoader}
      loaderState={loaderState}
      onLoaderStateReset={onLoaderStateReset}
      tabIndex={tabIndex}
      loaderClassName={loaderClassName}
    />
  );
};

export default NumberInput;
