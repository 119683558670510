import React, { FC } from 'react';
import styles from './Variable.module.scss';
import { CloseButtonImage } from 'static/images';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';

interface VariableNotFoundTypes {
  onClose?: () => void;
}

const VariableNotFound: FC<VariableNotFoundTypes> = ({ onClose }) => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.notFoundHeader}>
        <CloseButtonImage onClick={onClose} className={styles.closeButton} />
      </div>
      <ErrorPageInfo errorType={ErrorPageType.NotFoundContextualView} errorTitle="The page was not found." />
    </div>
  );
};

export default VariableNotFound;
