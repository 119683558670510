import React from 'react';
import SIZES from 'components/Permissions/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';

const SkeletonProductRow = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.PRODUCT} noPadding>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.SECTION}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PERMISSION}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PERMISSION}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PERMISSION}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonProductRow;
