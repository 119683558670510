import Debouncer from 'middlewares/Debouncer';
import { getTasks } from './ActionCreator';
import { LoanOriginationSystemTasksFiltersActionTypes } from './Filters/ActionTypes';

export default Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemTasks } = getState();
    const { filters, sortingType, selectedApplicationId: applicationId } = loanOriginationSystemTasks;

    return getTasks({ filters, sortingType, applicationId });
  },
  [LoanOriginationSystemTasksFiltersActionTypes.SetSearchInputValue],
);
