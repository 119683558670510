import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DocuSignLogo, EmptyDocumentsImage } from 'static/images';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationDocument, ApplicationDocumentsSortingField } from 'api/LoanOriginationSystem/DocumentsApi';
import {
  closeRenameApplicationDocumentPopup,
  downloadApplicationDocument,
  downloadApplicationDocumentFailure,
  downloadApplicationDocumentSuccess,
  getApplicationDocuments,
  openRenameApplicationDocumentPopup,
  openRequestCustomerUploadPopup,
  openUploadApplicationDocumentPopup,
  renameApplicationDocument,
  setApplicationDocumentToDelete,
  setSearchValue,
  sortApplicationDocuments,
  toggleSendDocuSignSignaturePopup,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import TabHeader from 'components/LoanOriginationSystem/ApplicationTabs/TabHeader';
import TabNoItems from 'components/LoanOriginationSystem/ApplicationTabs/TabNoItems';
import SearchInput from 'components/SearchInput';
import SearchNotFound from 'components/SearchNotFound';
import Button from 'components/Button';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ActionPopUp from 'components/ActionPopUp';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import ApplicationDocumentsTable from './ApplicationDocumentsTable';
import pagination from './pagination';
import styles from './ApplicationDocuments.module.scss';
import { ApplicationDocumentsActionType } from 'LoanOriginationSystemApplicationPage/Documents/ActionTypes';
import RenameApplicationDocumentPopup from './RenameApplicationDocumentPopup';
import { downloadBlobFile } from 'utils/downloadBlobFile';
import { showSnack } from 'Snackbar/ActionCreator';
import { SnackbarComponentTypes } from 'Snackbar/Types';
import { nanoid } from 'nanoid';
import ButtonWithImage from 'components/ButtonWithImage';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import { useHistory } from 'react-router-dom';
import useStateReset from 'hooks/useStateReset';
import { FileType } from 'pages/LoanOriginationSystem/FilePreview';
import { useApplicationDocumentsApi } from 'providers/ApiServiceProvider';

export interface ApplicationDocumentsProps {
  applicationId: string;
  applicationBorrowerFirstName: VariableValue;
  applicationBorrowerLastName: VariableValue;
  product: Product | null;
}

const FILE_PREVIEW_PATH = `/los/filepreview/${FileType.ApplicationDocument}`;

const ApplicationDocuments = ({
  applicationId,
  product,
  applicationBorrowerFirstName,
  applicationBorrowerLastName,
}: ApplicationDocumentsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addDocumentContextualPopupOpen, setAddDocumentContextualPopupOpen] = useState(false);
  const addDocumentContainerRef = useRef<HTMLInputElement | null>(null);

  const { search, sortingType, renameDocumentPopupOpen, documentToRename, renameDocumentInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemDocuments,
  );
  const applicationDocumentsApi = useApplicationDocumentsApi();

  useStateReset(ApplicationDocumentsActionType.ResetState);

  const applicationDocuments = pagination.usePaginatedItems({ applicationId, search, sortingType });
  const paginationProps = pagination.usePagination({ applicationId, search, sortingType });

  useEffect(() => {
    dispatch(getApplicationDocuments(applicationId, search, sortingType));
  }, []);

  const handleSearchClear = () => {
    dispatch(setSearchValue(applicationId, ''));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchValue(applicationId, event.target.value));
  };

  const handleApplicationDocumentDownload = async (document: ApplicationDocument) => {
    const documentDownloadingId = nanoid();

    batch(() => {
      dispatch(
        showSnack('Downloading Document', {
          componentType: SnackbarComponentTypes.ApplicationDownloadDocumentComponent,
          props: { document, documentDownloadingId },
        }),
      );
      dispatch(downloadApplicationDocument(documentDownloadingId));
    });
    try {
      const response = await applicationDocumentsApi.download(document.id);

      downloadBlobFile(response);
      dispatch(downloadApplicationDocumentSuccess(documentDownloadingId));
    } catch (error) {
      dispatch(downloadApplicationDocumentFailure(documentDownloadingId));
    }
  };

  const handleDownloadArchiveOfApplicationDocuments = async () => {
    const documentDownloadingId = nanoid();

    batch(() => {
      dispatch(
        showSnack('Downloading Document', {
          componentType: SnackbarComponentTypes.ApplicationDownloadDocumentsComponent,
          props: {
            documentName: `Application Documents - ${applicationBorrowerFirstName} ${applicationBorrowerLastName}`,
            documentDownloadingId,
          },
        }),
      );
      dispatch(downloadApplicationDocument(documentDownloadingId));
    });
    try {
      const response = await applicationDocumentsApi.downloadMany(applicationId);

      downloadBlobFile(response);
      dispatch(downloadApplicationDocumentSuccess(documentDownloadingId));
    } catch (error) {
      dispatch(downloadApplicationDocumentFailure(documentDownloadingId));
    }
  };

  const handleOpenDocumentPreview = (document: ApplicationDocument) => {
    history.push(`${FILE_PREVIEW_PATH}/${document.extension}/${document.id}/${document.name}`);
  };

  const handleOpenRenameApplicationDocumentPopup = (document: ApplicationDocument) => {
    dispatch(openRenameApplicationDocumentPopup(document));
  };

  const handleRenameApplicationDocument = async (updatedName: string) => {
    dispatch(renameApplicationDocument(applicationId, documentToRename!.id, updatedName));
  };

  const handleSort = (field: ApplicationDocumentsSortingField, ascending: boolean) => {
    dispatch(sortApplicationDocuments(applicationId, { field, ascending }));
  };

  const handleOpenAddDocumentContextualPopup = () => {
    setAddDocumentContextualPopupOpen(true);
  };

  const handleCloseAddDocumentContextualPopup = (handler?: () => void) => {
    setAddDocumentContextualPopupOpen(false);

    handler?.();
  };

  const handleApplicationDocumentDelete = (document: ApplicationDocument) => {
    dispatch(setApplicationDocumentToDelete(document));
  };

  const handleRenameDocumentPopupClose = () => {
    dispatch(closeRenameApplicationDocumentPopup());
  };

  const handleDocuSignButtonClick = () => {
    dispatch(toggleSendDocuSignSignaturePopup());
  };

  const actions = [
    {
      title: 'Upload Document',
      handler: () => dispatch(openUploadApplicationDocumentPopup()),
    },
    {
      title: 'Request Customer Upload',
      handler: () => dispatch(openRequestCustomerUploadPopup()),
    },
  ];

  const renderNoItems = () => {
    if (search) {
      return <SearchNotFound searchValue={search} />;
    }

    return <TabNoItems title="This folder is empty" icon={<EmptyDocumentsImage />} />;
  };

  const renderTable = () => (
    <ApplicationDocumentsTable
      source={applicationDocuments}
      paginationProps={paginationProps}
      sortingType={sortingType}
      searchInputValue={search}
      onSort={handleSort}
      onApplicationDocumentDownload={handleApplicationDocumentDownload}
      onApplicationDocumentClick={handleOpenDocumentPreview}
      onApplicationDocumentDelete={handleApplicationDocumentDelete}
      onRenameApplicationDocument={handleOpenRenameApplicationDocumentPopup}
    />
  );

  const renderDocuSignButton = () => {
    if (!product || !product.settings.docuSignEnabled) {
      return null;
    }

    return (
      <Button onClick={handleDocuSignButtonClick} className={styles.docuSignButton} kind="secondary" size="default">
        <DocuSignLogo />
      </Button>
    );
  };

  const renderActions = () => (
    <>
      {renderDocuSignButton()}
      <div ref={addDocumentContainerRef}>
        <Button
          className={styles.addDocumentButton}
          kind="primary"
          size="default"
          onClick={handleOpenAddDocumentContextualPopup}
        >
          Add Document
        </Button>
        <ContextualPopUp
          open={addDocumentContextualPopupOpen}
          anchorEl={addDocumentContainerRef.current}
          onClose={() => handleCloseAddDocumentContextualPopup()}
        >
          <ActionPopUp onClose={() => handleCloseAddDocumentContextualPopup()}>
            {actions.map(({ title, handler }) => (
              <ActionPopUpItem key={title} onClick={() => handleCloseAddDocumentContextualPopup(handler)}>
                {title}
              </ActionPopUpItem>
            ))}
          </ActionPopUp>
        </ContextualPopUp>
      </div>
    </>
  );

  return (
    <>
      <TabHeader title="Documents" actions={renderActions()} className={styles.tabHeader} />
      {(paginationProps.itemsTotal !== 0 || search) && (
        <SearchInput placeholder="Search" value={search} onChange={handleSearchChange} onClear={handleSearchClear} />
      )}
      {paginationProps.itemsTotal === 0 ? (
        renderNoItems()
      ) : (
        <>
          {renderTable()}
          <ButtonWithImage
            title="Download All Documents"
            onClick={handleDownloadArchiveOfApplicationDocuments}
            kind="download"
            className={styles.downloadAttachments}
          />
        </>
      )}
      {renameDocumentPopupOpen && (
        <RenameApplicationDocumentPopup
          fileName={documentToRename!.name}
          onRenameApplicationDocument={handleRenameApplicationDocument}
          isUpdating={renameDocumentInProgress}
          onPopupClose={handleRenameDocumentPopupClose}
        />
      )}
    </>
  );
};

export default ApplicationDocuments;
