import { DataViewSortingType, TableViewData } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';

export interface LabelsFilter {
  search: string;
  offset: number;
  count: number;
}

export type LabelsSortingType = DataViewSortingType<LabelsSortingField>;

export interface BaseLabelInfo {
  id: string;
  name: string;
  color: string;
}

export interface Label extends BaseLabelInfo {
  organization: string;
  createdAt: Date;
  updatedAt: Date;
  createdBy?: UserInfo | null;
  updatedBy?: UserInfo | null;
}

export enum LabelsSortingField {
  LabelName = 'name',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export interface LoanOriginationSystemLabelsApi {
  getLabels({
    filters,
    sortingType,
  }: {
    filters?: LabelsFilter;
    sortingType?: LabelsSortingType;
  }): Promise<TableViewData<Label>>;
  deleteLabel(id: string): void;
  createLabel(name: string, color: string): Promise<Label>;
  updateLabel(id: string, name: string, color: string): Promise<Label>;
}

export default class LoanOriginationSystemLabelsRestApi extends LoanOriginationSystemApi<Label>
  implements LoanOriginationSystemLabelsApi {
  protected resourceName = 'labels';

  public getLabels({
    filters,
    sortingType,
  }: {
    filters?: LabelsFilter;
    sortingType?: LabelsSortingType;
  }): Promise<TableViewData<Label>> {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    return this.getResources<TableViewData<Label>>(params);
  }

  public getLabelById(id: string): Promise<Label> {
    return this.fetch(`/${this.resourceName}/${id}`, 'GET');
  }

  public deleteLabel(id: string) {
    return this.fetch(`/${this.resourceName}/`, 'DELETE', {
      id,
    });
  }

  public createLabel(name: string, color: string) {
    return this.fetch<Label>(`/${this.resourceName}/`, 'POST', {
      name,
      color: `#${color}`,
    });
  }

  public updateLabel(id: string, name: string, color: string) {
    return this.fetch<Label>(`/${this.resourceName}/${id}`, 'PUT', {
      name,
      color: `#${color}`,
    });
  }
}
