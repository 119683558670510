import React from 'react';
import clsx from 'clsx';
import { ApplicationSortingField } from 'api/Types';
import useSorting from 'hooks/useSorting';
import { useHistory } from 'react-router';
import { ApplicationsTableProps } from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import Pagination from 'components/Pagination/Pagination';
import RelatedApplicationTableRow from './RelatedApplicationTableRow';
import SkeletonRelatedApplicationTableRow from './SkeletonRelatedApplicationTableRow';
import { RelatedApplicationsTableColumnSizes } from './TableColumnSizes';
import styles from './RelatedApplicationsTable.module.scss';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { StandardVariables } from 'Variables/VariablesTypes';

export interface RelatedApplicationsTableProps
  extends Omit<ApplicationsTableProps, 'currencySymbol' | 'onDelete' | 'deleteDisabled'> {
  className?: string;
  standardVariables: StandardVariables | null;
}

const RelatedApplicationsTable = ({
  source,
  standardVariables,
  paginationProps,
  sortingType,
  onSort,
  className,
}: RelatedApplicationsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);
  const history = useHistory();

  const onClick = (application: Application) => {
    history.push(`/los/applications/${application.displayId}`);
  };

  const getTableBody = () => {
    return source.map((application, index) =>
      application && standardVariables ? (
        <RelatedApplicationTableRow
          standardVariables={standardVariables}
          key={application.id}
          application={application}
          onClick={onClick}
        />
      ) : (
        <SkeletonRelatedApplicationTableRow key={index} />
      ),
    );
  };

  return (
    <div className={clsx(styles.table, className)}>
      <Table>
        <TableHead sticky>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.BorrowerFullName}
            ascending={getSortingType(ApplicationSortingField.BorrowerFullName)}
            onClick={() => changeSorting(ApplicationSortingField.BorrowerFullName)}
          >
            Borrower
          </TableHeadCell>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.Product}
            ascending={getSortingType(ApplicationSortingField.ProductName)}
            onClick={() => changeSorting(ApplicationSortingField.ProductName)}
          >
            Product
          </TableHeadCell>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.Id}
            ascending={getSortingType(ApplicationSortingField.DisplayId)}
            onClick={() => changeSorting(ApplicationSortingField.DisplayId)}
          >
            Application ID
          </TableHeadCell>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.ApplicationDate}
            ascending={getSortingType(ApplicationSortingField.CreatedAt)}
            onClick={() => changeSorting(ApplicationSortingField.CreatedAt)}
          >
            Application date
          </TableHeadCell>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.Status}
            ascending={getSortingType(ApplicationSortingField.Status)}
            onClick={() => changeSorting(ApplicationSortingField.Status)}
          >
            Status
          </TableHeadCell>
          <TableHeadCell
            width={RelatedApplicationsTableColumnSizes.LoanAmount}
            className={styles.totalColumnHeadCell}
            ascending={getSortingType(ApplicationSortingField.LoanAmount)}
            onClick={() => changeSorting(ApplicationSortingField.LoanAmount)}
          >
            Loan Amount
          </TableHeadCell>
        </TableHead>
        <TableBody>{getTableBody()}</TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </div>
  );
};

export default RelatedApplicationsTable;
