import { AutomatedProcessesApi, AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import Fetcher from 'middlewares/Fetcher';
import {
  ChangeActivationStatusAction,
  changeActivationStatusFailure,
  changeActivationStatusSuccess,
} from './ActionCreator';
import { ProcessAutomationActionType } from './ActionTypes';

const ChangeActivationStatusMiddleware = (api: AutomatedProcessesApi) =>
  Fetcher<AutomatedProcess, ChangeActivationStatusAction>(
    ProcessAutomationActionType.ChangeActivationStatus,
    changeActivationStatusSuccess,
    changeActivationStatusFailure,
    (action) => {
      return api.changeActivationStatus(action.payload.id, action.payload.status);
    },
  );

export default ChangeActivationStatusMiddleware;
