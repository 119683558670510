import React, { useEffect, useState, ChangeEvent, FC } from 'react';
import styles from './CompanyInformation.module.scss';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { isEmptyField } from 'utils/validation/validation';
import stateList from 'utils/states';
import { OrganizationState, UpdateOrganizationInfoParams } from 'CompanyInformation/CompanyInformationTypes';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import formattedCountryList from './CountryList';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import PageName from 'constants/PageName';

interface CompanyInformationProps {
  organizationInfo: OrganizationState;
  organizationUsers: UserInfo[];
  changeCompanyInfo: (info: UpdateOrganizationInfoParams) => Promise<unknown>;
  onOpenUploadCompanyLogoDialog: () => void;
}

const stateSelectOptions: Option[] = stateList.map(({ abbreviation }) => ({
  name: abbreviation,
  value: abbreviation,
}));

const CompanyInformation: FC<CompanyInformationProps> = ({
  organizationInfo,
  organizationUsers,
  changeCompanyInfo,
  onOpenUploadCompanyLogoDialog,
}) => {
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postal, setPostal] = useState('');
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [primaryContact, setPrimaryContact] = useState('');
  const [country, setCountry] = useState('');

  const [streetAddressError, setStreetAddressError] = useState('');
  const [cityError, setCityError] = useState('');
  const [postalError, setPostalError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [primaryContactError, setPrimaryContactError] = useState('');

  useEffect(() => {
    setStreetAddress(organizationInfo.streetAddress || '');
    setCity(organizationInfo.city || '');
    setState(organizationInfo.state || '');
    setPostal(organizationInfo.postalCode || '');
    setPrimaryContact(organizationInfo.primaryContact || '');
    setCountry(organizationInfo.country || '');
  }, [organizationInfo]);

  const handleStreetAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStreetAddressError('');
    setStreetAddress(e.target.value);
  };

  const handleCityChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCityError('');
    setCity(e.target.value);
  };

  const handleStateChange = ({ value }: Option) => {
    setState(value);
  };

  const handlePostalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPostalError('');
    if ((e.target.value.length <= 5 && /^[0-9\b]+$/.test(e.target.value)) || e.target.value === '') {
      setPostal(e.target.value);
    }
  };

  const handlePrimaryContactChange = ({ value }: Option) => {
    setPrimaryContact('');
    setPrimaryContact(value);
  };

  const handleCountryChange = ({ value }: Option) => {
    setCountry('');
    setCountry(value);
  };

  const handleSaveChanges = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const isEmptyStreetAddress = isEmptyField(streetAddress);
    if (isEmptyStreetAddress) {
      setStreetAddressError('Street Address is required');
    }

    const isEmptyCity = isEmptyField(city);
    if (isEmptyCity) {
      setCityError('City is required');
    }

    const isEmptyPostal = isEmptyField(postal);
    if (isEmptyPostal) {
      setPostalError('Postal is required');
    }

    const isEmptyCountry = isEmptyField(country);
    if (isEmptyCountry) {
      setCountryError('Country is required');
    }

    const isEmptyPrimaryContact = isEmptyField(primaryContact);
    if (isEmptyPrimaryContact) {
      setPrimaryContactError('Primary Contact is required');
    }

    if (isEmptyStreetAddress || isEmptyCity || isEmptyPostal || isEmptyCountry || isEmptyPrimaryContact) {
      return;
    }

    if (isEmptyStreetAddress || isEmptyCity || isEmptyPostal) return;

    setIsSavingInProgress(true);
    await changeCompanyInfo({ streetAddress, city, state, postal, primaryContact, country });
    setIsSavingInProgress(false);
  };

  const isFormUnchanged =
    organizationInfo.streetAddress === streetAddress.trim() &&
    organizationInfo.city === city.trim() &&
    organizationInfo.state === state.trim() &&
    organizationInfo.postalCode === postal.trim() &&
    organizationInfo.country === country.trim() &&
    organizationInfo.primaryContact === primaryContact.trim();

  const isFormFilled = streetAddress && city && postal && country && primaryContact;

  const isButtonDisabled = isFormUnchanged || !isFormFilled;

  const formattedUsers = organizationUsers.map(({ firstName, lastName, id, imageId }) => {
    const icon = (
      <ImageFetcher
        size="small"
        firstName={firstName}
        lastName={lastName}
        imageId={imageId}
        className={styles.userAvatar}
      />
    );
    return {
      name: `${firstName} ${lastName}`,
      value: id,
      icon,
    };
  });

  const organizationLogo = organizationInfo.organizationLogo ? (
    <div className={styles.logo}>
      <img src={organizationInfo.organizationLogo} alt="Logo" />
    </div>
  ) : null;

  return (
    <div>
      <div className={styles.companyInformationHeader__title}>
        <h2>{PageName.CompanyInformation}</h2>
        <div className={styles.companyInformationHeader__info}>
          {organizationLogo}
          <div className={styles.companyName}>
            <h3>{organizationInfo.name}</h3>
            <button className={styles.uploadLogoButton} type="button" onClick={onOpenUploadCompanyLogoDialog}>
              {organizationInfo.organizationLogo ? 'Change Logo' : 'Upload Logo'}
            </button>
          </div>
        </div>
      </div>
      <div className={styles.companyInformationBody}>
        <div className={styles.companyInformationBody__mainInfo}>
          <div className={styles.companyInformationBody__infoItem}>
            <TextInput type="text" labelTitle="Name" value={organizationInfo.name} disabled />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <AutoCompletion
              type="text"
              labelTitle="Primary Contact"
              onChange={handlePrimaryContactChange}
              options={formattedUsers || []}
              value={primaryContact}
              errorMessage={primaryContactError}
            />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <TextInput
              type="text"
              labelTitle="City"
              onChange={handleCityChange}
              errorMessage={cityError}
              value={city}
            />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <TextInput
              type="text"
              labelTitle="Street Address"
              onChange={handleStreetAddressChange}
              errorMessage={streetAddressError}
              value={streetAddress}
            />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <AutoCompletion
              labelTitle="State or Province"
              onChange={handleStateChange}
              value={state}
              options={stateSelectOptions}
            />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <TextInput
              type="text"
              labelTitle="ZIP or Postal Code"
              onChange={handlePostalChange}
              errorMessage={postalError}
              value={postal}
            />
          </div>
          <div className={styles.companyInformationBody__infoItem}>
            <AutoCompletion
              type="text"
              labelTitle="Country"
              options={formattedCountryList}
              onChange={handleCountryChange}
              value={country}
              errorMessage={countryError}
            />
          </div>
        </div>
        <div className={styles.companyInformationBody__save}>
          <Button
            kind="primary"
            size="form"
            onClick={handleSaveChanges}
            disabled={isButtonDisabled}
            isLoading={isSavingInProgress}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
