import React, { useMemo } from 'react';
import { ProductApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import ApiRequestTemplatePopup from 'components/LoanOriginationSystem/ApiRequestTemplatePopup';
import { useProductsApi } from 'providers/ApiServiceProvider';

export interface ProductApiRequestsTemplatePopupProps {
  onDownload: (productId: string, requestType: ProductApiRequestTemplateType) => void;
  onClose: () => void;
  isDownloadInProgress?: boolean;
}

enum ProductApiRequestInputType {
  ProductId = 'productId',
  RequestType = 'requestType',
}

const ProductApiRequestsTemplatePopup = ({
  onClose,
  onDownload,
  isDownloadInProgress,
}: ProductApiRequestsTemplatePopupProps) => {
  const productsApi = useProductsApi();

  const apiRequestInputs = useMemo(
    () => [
      {
        label: 'Product',
        type: ProductApiRequestInputType.ProductId,
        fetchOptions: async (optionName: string, abortSignal: AbortSignal) => {
          const products = await productsApi.getDropdownProducts(optionName, abortSignal);

          return products.map((product) => ({
            name: product.name,
            value: product.id,
          }));
        },
      },
      {
        label: 'API Request Type',
        type: ProductApiRequestInputType.RequestType,
        options: [
          {
            name: 'Create Application',
            value: ProductApiRequestTemplateType.CreateApplication,
          },
          {
            name: 'Update Application',
            value: ProductApiRequestTemplateType.UpdateApplication,
          },
          {
            name: 'Create Task',
            value: ProductApiRequestTemplateType.CreateTask,
          },
          {
            name: 'Update Task',
            value: ProductApiRequestTemplateType.UpdateTask,
          },
        ],
      },
    ],
    [productsApi],
  );

  const handleDownload = (values: Record<string, string>) => {
    onDownload(
      values[ProductApiRequestInputType.ProductId],
      values[ProductApiRequestInputType.RequestType] as ProductApiRequestTemplateType,
    );
  };

  return (
    <ApiRequestTemplatePopup
      inputs={apiRequestInputs}
      title="Download API Request Template"
      onClose={onClose}
      onDownload={handleDownload}
      isDownloadInProgress={isDownloadInProgress}
    />
  );
};

export default ProductApiRequestsTemplatePopup;
