import React, { FC, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import styles from './TopRightButton.module.scss';

type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const TopRightButton: FC<HTMLButtonProps> = ({ ...props }) => {
  return <button type="button" className={styles.navButton} {...props} />;
};

export default TopRightButton;
