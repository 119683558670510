import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { CreateVariableActionOrigin } from 'Variables/VariablesActionCreator';
import { isCreateVariableSuccessAction } from 'Variables/OnSaveVariableSuccessMiddleware';
import { getListVariables } from './ActionCreator';

const isCreateListVariableSuccessAction = (action: AnyAction) =>
  isCreateVariableSuccessAction(action) &&
  action.meta?.actionOrigin === CreateVariableActionOrigin.VariablesConfiguration;

const createListVariableSuccessMiddleware: Middleware = ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  const { loanOriginationSystemVariablesList } = getState();

  if (isCreateListVariableSuccessAction(action)) {
    dispatch(getListVariables(loanOriginationSystemVariablesList.search));
  }

  return result;
};

export default createListVariableSuccessMiddleware;
