import { DataViewSortingType, IndividualProcessingHistoryCase, ItemsWithTotalCount } from 'api/Types';

export enum DecisionRunsSortingField {
  Date = 'date',
  Type = 'type',
  DecisionName = 'name',
  StrategyName = 'strategyName',
  Result = 'passed',
}

export type DecisionRunsSortingFieldType = DataViewSortingType<DecisionRunsSortingField>;

export interface ApplicationDecisionRunsDataState extends ItemsWithTotalCount<IndividualProcessingHistoryCase> {
  page: number;
  itemsPerPage: number | undefined;
  sortingType: DecisionRunsSortingFieldType;
  loaded: boolean;
}
