import { AnyAction } from 'redux';
import { ProductApplicationSetupActionType } from './ActionTypes';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';

export interface SelectTabAction extends AnyAction {
  type: ProductApplicationSetupActionType.SelectTab;
  payload: ApplicationTab;
}

export interface SetTabToDeleteAction extends AnyAction {
  type: ProductApplicationSetupActionType.SetTabToDelete;
  payload: ApplicationTab | null;
}

export const selectTab = (tab: ApplicationTab): SelectTabAction => ({
  type: ProductApplicationSetupActionType.SelectTab,
  payload: tab,
});

export const setTabToDelete = (tab: ApplicationTab | null): SetTabToDeleteAction => ({
  type: ProductApplicationSetupActionType.SetTabToDelete,
  payload: tab,
});
