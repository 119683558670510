import React from 'react';
import SIZES from 'components/CompanyInformationUsers/TableColumnSizes';
import styles from 'components/CompanyInformationUsers/UserLine/UserLine.module.scss';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableActionCell from 'components/Table/TableActionCell';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';

const SkeletonUserLine = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.FIRST_NAME} noPadding>
        <SkeletonCircle width="28px" height="28px" color="primary6" className={styles.userLine__image} />
        <SkeletonRectangle width="60%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.LAST_NAME}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.EMAIL}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PERMISSIONS_TYPE}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PHONE_AUTHENTICATION}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.EMAIL_VERIFIED}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.ACCOUNT_STATUS}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonUserLine;
