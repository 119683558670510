import Fetcher from 'middlewares/Fetcher';
import { ApplicationDataTabVariableConfigurationsApi } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabVariableConfigurationsActionType } from './ActionTypes';
import {
  deleteApplicationDataTabVariableConfigurationSuccess,
  deleteApplicationDataTabVariableConfigurationFailure,
  DeleteApplicationDataTabVariableConfigurationAction,
} from './ActionCreator';

const DeleteApplicationDataTabVariableConfigurationMiddleware = (api: ApplicationDataTabVariableConfigurationsApi) =>
  Fetcher<string, DeleteApplicationDataTabVariableConfigurationAction>(
    ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfiguration,
    deleteApplicationDataTabVariableConfigurationSuccess,
    deleteApplicationDataTabVariableConfigurationFailure,
    async (action) => {
      await api.delete(action.payload.id);

      return action.payload.id;
    },
  );

export default DeleteApplicationDataTabVariableConfigurationMiddleware;
