import React from 'react';
import clsx from 'clsx';

import inputStyles from './TextInput.module.scss';
import stubStyles from './TextInputStub.module.scss';

export interface TextInputStubProps {
  label: string;
  placeholder: string | JSX.Element;
  disabled?: boolean;
  stubInputClassName?: string;
  className?: string;
  elementBeforeInput?: JSX.Element | null;
}

const TextInputStub = ({
  label,
  placeholder,
  elementBeforeInput,
  disabled,
  stubInputClassName,
  className,
}: TextInputStubProps) => {
  return (
    <div className={clsx(stubStyles.stubInputContainer, className)}>
      <div className={inputStyles.labelWithLink}>
        <label className={inputStyles.label}>{label}</label>
      </div>
      <div className={clsx(stubStyles.stubInput, { [stubStyles.disabled]: disabled }, stubInputClassName)}>
        {elementBeforeInput && <div className={stubStyles.beforeStubInputContainer}>{elementBeforeInput}</div>}
        {placeholder}
      </div>
    </div>
  );
};

export default TextInputStub;
