import React, { useState } from 'react';
import MainLayout, { PageContent, PageWrapper } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { ReduxState } from 'types/redux';
import { useDispatch, useSelector } from 'react-redux';
import { closeDeleteTaskPopup, deleteTask } from 'LoanOriginationSystemTasks/ActionCreator';
import { useQueryParams } from 'hooks/useQueryParam';
import styles from './Tasks.module.scss';
import TasksFilter from 'components/LoanOriginationSystem/TasksDashboard/TasksFilter';
import CreateTask from 'components/LoanOriginationSystem/CreateTask';
import TasksDashboard from 'components/LoanOriginationSystem/TasksDashboard';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import EditTask from 'components/LoanOriginationSystem/EditTask';
import { useHistory } from 'react-router';
import NavigationLinkId from 'enums/NavigationLinkId';
import ConfirmPopup from 'components/ConfirmPopup';

const leftNav = makeLeftNavigation(NavigationLinkId.Tasks, ApplicationSectionName.LoanOriginationSystem);

const Tasks = () => {
  const dispatch = useDispatch();
  const params = useQueryParams();
  const history = useHistory();
  const closeContextualView = useCloseContextualView();

  const [createOrEditTaskDataWasChanged, setCreateOrEditTaskDataWasChanged] = useState(false);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);

  const onCloseContextualView = () => {
    if (createOrEditTaskDataWasChanged) {
      setUnsavedChangesPopupOpen(true);

      return;
    }

    setCreateOrEditTaskDataWasChanged(false);
    closeContextualView();
  };

  const renderOverlay = () => {
    if (taskIdToDelete) {
      return (
        <ConfirmPopup
          title="Delete Task"
          message="Are you sure you want to remove this task?"
          confirmText="Yes, Delete Task"
          declineText="No, Go Back"
          onPopupClose={onDeleteTaskPopupClose}
          onConfirmClick={onDeleteTask}
          loading={isDeleteInProgress}
        />
      );
    }

    if (unsavedChangesPopupOpen) {
      return (
        <UnsavedChangesPopup
          onPopupClose={() => setUnsavedChangesPopupOpen(false)}
          onLeaveClick={() => {
            closeContextualView();
            setUnsavedChangesPopupOpen(false);
          }}
        />
      );
    }

    return null;
  };

  const createTaskFormAvailable = params.has('new');
  const editTaskFormAvailable = params.has('edit');

  const { filters, taskIdToDelete, isDeleteInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemTasks,
  );

  const rightSidePopupView = filters.isPopupVisible && (
    <TasksFilter
      dueDateFrom={filters.dueDateRange.from}
      dueDateTo={filters.dueDateRange.to}
      status={filters.selectedStatus}
    />
  );

  const handleEditTaskSuccess = () => {
    closeContextualView();
  };

  const renderContextualView = () => {
    if (createTaskFormAvailable) {
      return (
        <CreateTask
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          onCreated={() => closeContextualView()}
        />
      );
    }

    if (editTaskFormAvailable) {
      return (
        <EditTask
          onClose={onCloseContextualView}
          id={params.get('edit')!}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          onEditSuccess={handleEditTaskSuccess}
        />
      );
    }

    return null;
  };

  const onDeleteTaskPopupClose = () => {
    dispatch(closeDeleteTaskPopup());
  };

  const onDeleteTask = () => {
    dispatch(deleteTask(taskIdToDelete!));
  };

  const onCreateNewTask = () => {
    history.replace('?new');
  };

  const onEditTask = (id: string) => {
    history.replace(`?edit=${id}`);
  };

  return (
    <MainLayout
      leftNav={leftNav}
      rightSidePopupView={rightSidePopupView}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
    >
      <PageWrapper>
        <PageContent noPadding className={styles.pageContent}>
          <TasksDashboard onCreate={onCreateNewTask} onEdit={onEditTask} />
        </PageContent>
      </PageWrapper>
    </MainLayout>
  );
};

export default Tasks;
