import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ESignTemplatesSortingType } from 'api/Core/ESignTemplatesApi';
import { ImportableDocuSignTemplate } from 'api/Core/DocuSignImportApi';
import { DocuSignActionType } from './ActionTypes';

export interface ImportDocuSignTemplatesAction extends AnyAction {
  type: DocuSignActionType.ImportDocuSignTemplates;
  payload: {
    templates: ImportableDocuSignTemplate[];
  };
}

export interface ImportDocuSignTemplatesSuccessAction extends AnyAction {
  type: DocuSignActionType.ImportDocuSignTemplatesSuccess;
}

export interface GetTemplatesToImportAction extends AnyAction {
  type: DocuSignActionType.GetTemplatesToImport;
}

export interface GetTemplatesToImportSuccessAction extends AnyAction {
  type: DocuSignActionType.GetTemplatesToImportSuccess;
  payload: {
    templatesToImport: ImportableDocuSignTemplate[];
  };
}

export interface SetDeleteTemplateIdAction extends AnyAction {
  type: DocuSignActionType.SetDeleteTemplateId;
  payload: {
    id: string | null;
  };
}

export interface ChangeSortingTypeAction extends AnyAction {
  type: DocuSignActionType.ChangeSortingType;
  payload: {
    sortingType: ESignTemplatesSortingType;
    integrationId?: string;
  };
}

export interface ToggleImportTemplatesPopupActiveAction extends AnyAction {
  type: DocuSignActionType.ToggleImportTemplatesPopupActive;
}

export interface ToggleVariablesMappingPopupActiveAction extends AnyAction {
  type: DocuSignActionType.ToggleVariablesMappingPopupActive;
}

export interface ToggleRecipientsMappingPopupActiveAction extends AnyAction {
  type: DocuSignActionType.ToggleRecipientsMappingPopupActive;
}

export interface ToggleCreateVariablePopupActiveAction extends AnyAction {
  type: DocuSignActionType.ToggleCreateVariablePopupActive;
}

export const importDocuSignTemplates = (templates: ImportableDocuSignTemplate[]): ImportDocuSignTemplatesAction => ({
  type: DocuSignActionType.ImportDocuSignTemplates,
  payload: {
    templates,
  },
});

export const importDocuSignTemplatesSuccess = (): ImportDocuSignTemplatesSuccessAction => ({
  type: DocuSignActionType.ImportDocuSignTemplatesSuccess,
});

export const getTemplatesToImport = (): GetTemplatesToImportAction => ({
  type: DocuSignActionType.GetTemplatesToImport,
});

export const getTemplatesToImportSuccess = (
  templatesToImport: ImportableDocuSignTemplate[],
): GetTemplatesToImportSuccessAction => ({
  type: DocuSignActionType.GetTemplatesToImportSuccess,
  payload: {
    templatesToImport,
  },
});

export const setDeleteTemplateId = (id: string | null): SetDeleteTemplateIdAction => ({
  type: DocuSignActionType.SetDeleteTemplateId,
  payload: {
    id,
  },
});

export const changeSortingType = (
  sortingType: ESignTemplatesSortingType,
  integrationId?: string,
): ChangeSortingTypeAction => ({
  type: DocuSignActionType.ChangeSortingType,
  payload: {
    integrationId,
    sortingType,
  },
});

export const toggleImportTemplatesPopupActive = (): ToggleImportTemplatesPopupActiveAction => ({
  type: DocuSignActionType.ToggleImportTemplatesPopupActive,
});

export const toggleVariablesMappingPopupActive = (): ToggleVariablesMappingPopupActiveAction => ({
  type: DocuSignActionType.ToggleVariablesMappingPopupActive,
});

export const toggleRecipientsMappingPopupActive = (): ToggleRecipientsMappingPopupActiveAction => ({
  type: DocuSignActionType.ToggleRecipientsMappingPopupActive,
});

export const toggleCreateVariablePopupActive = (): ToggleCreateVariablePopupActiveAction => ({
  type: DocuSignActionType.ToggleCreateVariablePopupActive,
});

export const getTemplatesToImportFailure = FailedActionCreator(DocuSignActionType.GetTemplatesToImportFailure);
export const importDocuSignTemplatesFailure = FailedActionCreator(DocuSignActionType.ImportDocuSignTemplatesFailure);
