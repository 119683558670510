import React, { FC, useState, ComponentType } from 'react';

import styles from './NavigationLink.module.scss';

import { VectorBottomImage } from 'static/images';

import DropdownContent from './DropdownContent';
import { NestedLinks } from 'MainLayout/leftNavReducer/LeftNavTypes';
import clsx from 'clsx';

export interface NavigationLinkType {
  title: string;
  id: string;
  Icon: ComponentType<{ className?: string }>;
  isExpanded: boolean;
  isActive: boolean;
  onUpdateActiveLinkTitle: (title: string) => void;
  nestedLinks?: NestedLinks;
  activeLinkId: string;
  route: string;
}

const NavigationLink: FC<NavigationLinkType> = ({
  title,
  id,
  Icon,
  isExpanded,
  isActive,
  activeLinkId,
  onUpdateActiveLinkTitle,
  nestedLinks,
  route,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOnLinkClick = (): void => {
    if (nestedLinks) {
      setIsDropdownOpen(!isDropdownOpen);
      return;
    }
    onUpdateActiveLinkTitle(route);
  };

  const dropdownIconStyle = isDropdownOpen ? styles.dropdownIconOpened : styles.dropdownIconClosed;
  const activeDropdownIconStyle = isActive ? styles.activeDropdownIcon : '';

  return (
    <li className={styles.linkContainer}>
      <div className={styles.link} onClick={handleOnLinkClick}>
        <Icon className={isActive ? styles.iconActive : styles.icon} />
        <div className={isActive ? styles.titleActive : styles.title}>
          <p>{title}</p>
        </div>
        {nestedLinks && <VectorBottomImage className={`${dropdownIconStyle} ${activeDropdownIconStyle}`} />}
        {!isExpanded && (
          <div className={styles.subLinkList}>
            <div
              className={clsx(
                id === activeLinkId && styles.activeSubTitleLink,
                nestedLinks ? styles.subTitleLinkNested : styles.subTitleLink,
              )}
              onClick={handleOnLinkClick}
            >
              {title.toUpperCase()}
            </div>
            {nestedLinks && (
              <div className={styles.nestedLinksList}>
                {nestedLinks.map((link, index) => (
                  <div
                    key={index}
                    className={link.id === activeLinkId ? styles.activeSubLink : styles.subLink}
                    onClick={() => onUpdateActiveLinkTitle(link.route)}
                  >
                    {link.title}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {nestedLinks && (
        <DropdownContent
          isDropdownOpen={isDropdownOpen}
          isExpanded={isExpanded}
          nestedLinks={nestedLinks}
          onUpdateActiveLinkTitle={onUpdateActiveLinkTitle}
          activeLinkId={activeLinkId}
          nestedItemClassName={styles.nestedLinkItem}
        />
      )}
    </li>
  );
};

export default NavigationLink;
