import { AnyAction } from 'redux';
import { UploadSecureDocumentActionType } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ShortOrganizationInfo } from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';

export interface GetOrganizationInfoAction extends AnyAction {
  type: UploadSecureDocumentActionType.GetOrganizationInfo;
  payload: {
    token: string;
  };
}

export interface GetOrganizationInfoByTokenSuccessAction extends AnyAction {
  type: UploadSecureDocumentActionType.GetOrganizationInfoSuccess;
  payload: ShortOrganizationInfo;
}

export interface UploadSecureDocumentAction extends AnyAction {
  type: UploadSecureDocumentActionType.UploadSecureDocument;
  payload: {
    token: string;
    file: File;
  };
}

export interface UploadSecureDocumentSuccessAction extends AnyAction {
  type: UploadSecureDocumentActionType.UploadSecureDocumentSuccess;
}

export const getOrganizationInfo = (token: string): GetOrganizationInfoAction => ({
  type: UploadSecureDocumentActionType.GetOrganizationInfo,
  payload: { token },
});

export const getOrganizationInfoByTokenSuccess = (
  organizationInfo: ShortOrganizationInfo,
): GetOrganizationInfoByTokenSuccessAction => ({
  type: UploadSecureDocumentActionType.GetOrganizationInfoSuccess,
  payload: organizationInfo,
});

export const uploadSecureDocument = (token: string, file: File): UploadSecureDocumentAction => ({
  type: UploadSecureDocumentActionType.UploadSecureDocument,
  payload: { token, file },
});

export const uploadSecureDocumentSuccess = (): UploadSecureDocumentSuccessAction => ({
  type: UploadSecureDocumentActionType.UploadSecureDocumentSuccess,
});

export const getOrganizationInfoByTokenFailure = FailedActionCreator(
  UploadSecureDocumentActionType.GetOrganizationInfoFailure,
);
export const uploadSecureDocumentFailure = FailedActionCreator(
  UploadSecureDocumentActionType.UploadSecureDocumentFailure,
);
