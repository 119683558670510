import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import Button from 'components/Button';
import styles from './LoggedOut.module.scss';
import { useHistory } from 'react-router';
import { AppRoutes } from 'routes/RouteService';

const LoggedOut: FC = () => {
  const history = useHistory();

  const handleSignInClick = () => {
    history.push(AppRoutes.SignIn);
  };

  return (
    <AuthLayout
      title="You Have Been Logged Out"
      subTitle="For security reasons, you have been logged out due to inactivity."
    >
      <div className={styles.container}>
        <Button fullWidth size="form" kind="secondary" onClick={handleSignInClick}>
          Sign In
        </Button>
      </div>
    </AuthLayout>
  );
};

export default LoggedOut;
