import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonDataTab.module.scss';

const SkeletonDataTab = () => (
  <div className={styles.container}>
    <div className={styles.row}>
      <SkeletonRectangle width="100%" height="96px" color="primary10" />
      <SkeletonRectangle width="100%" height="120px" color="primary10" />
      <SkeletonRectangle width="100%" height="130px" color="primary10" />
    </div>
    <div className={styles.row}>
      <SkeletonRectangle width="100%" height="96px" color="primary10" />
    </div>
    <div className={styles.row}>
      <SkeletonRectangle width="100%" height="96px" color="primary10" />
      <SkeletonRectangle width="100%" height="96px" color="primary10" />
    </div>
  </div>
);

export default SkeletonDataTab;
