import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ProcessAutomationActionType } from './ActionTypes';
import { UpdateRuntimeOptionsSuccessAction } from './ActionCreator';
import automatedProcessPagination from 'components/LoanOriginationSystem/ProcessAutomationDashboard/Pagination';

const isUpdateRuntimeOptionsSuccessAction = (action: AnyAction): action is UpdateRuntimeOptionsSuccessAction =>
  action.type === ProcessAutomationActionType.UpdateRuntimeOptionsSuccess;

const UpdateRuntimeOptionsSuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateRuntimeOptionsSuccessAction(action)) {
    const { loanOriginationSystemAutomatedProcesses } = getState();
    const { group, category } = loanOriginationSystemAutomatedProcesses;

    dispatch(automatedProcessPagination.reloadCurrentPage({ group, category }));
  }

  return result;
};

export default UpdateRuntimeOptionsSuccessMiddleware;
