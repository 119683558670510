import { ErrorType } from 'constants/ErrorType';

export class RequestError extends Error {
  constructor(
    message: string,
    readonly responseStatus: number,
    readonly errorType?: ErrorType,
    readonly data?: Record<string, unknown>,
  ) {
    super(message);
  }
}

const getRequestErrorData = async (
  response: Response,
): Promise<{ message: string; type?: ErrorType; data?: Record<string, unknown> }> => {
  // not all failed requests will provide a JSON response
  try {
    const body = await response.json();
    const getErrorMessage = () => {
      if (body.message) {
        return body.message;
      }
      if (!body.data) {
        return body.error?.message || response.statusText;
      }
      return typeof body.data.error === 'string' ? body.data.error : body.data.error.message;
    };
    const message = getErrorMessage();
    return { message, type: body.data?.errorType || body.data?.error?.name, data: body.data };
  } catch (error) {
    return { message: response.statusText };
  }
};

export const readResponseAsJSON = async (response: Response) => {
  if (response.ok) {
    return response.json();
  }

  const { message, type, data } = await getRequestErrorData(response);
  const { status } = response;

  throw new RequestError(message, status, type, data);
};

const loadChunks = async (
  reader: ReadableStreamDefaultReader<Uint8Array>,
  chunks: Uint8Array[],
  received: number,
): Promise<{ chunks: Uint8Array[]; received: number }> => {
  const { done, value } = await reader.read();
  if (done) {
    return { chunks, received };
  }

  // @ts-ignore
  chunks.push(value);

  // @ts-ignore
  return loadChunks(reader, chunks, received + value.length);
};

export const readResponseAsBinaryStream = async (response: Response) => {
  if (response.ok) {
    const reader = response.body!.getReader();
    const { chunks, received } = await loadChunks(reader, [], 0);
    const chunksAll = new Uint8Array(received);
    let position = 0;
    for (const chunk of chunks) {
      chunksAll.set(chunk, position);
      position += chunk.length;
    }

    return new Blob([chunksAll], { type: 'image/png' });
  }

  const { message, type, data } = await getRequestErrorData(response);
  const { status } = response;
  throw new RequestError(message, status, type, data);
};
