import { connect } from 'react-redux';
import RuleRow from 'components/RuleRow/RuleRow';
import { duplicateRule } from 'RuleCreate/ActionCreator';
import { DecisionStrategiesState } from 'DecisionStrategy/DecisionStrategiesTypes';

export type RuleRowConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ decisionStrategies }: { decisionStrategies: DecisionStrategiesState }) => {
  return {
    strategies: decisionStrategies.strategiesDetailedAttributes,
  };
};

const mapDispatchToProps = {
  duplicateRule,
};

export default connect(null, mapDispatchToProps)(RuleRow);
