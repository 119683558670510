import { BulkUpdateVariableConfigurationParams, VariableConfiguration } from './Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';

export interface CreateIntermediaryVariableConfigurationParams {
  column: number;
  position: number;
  variable: string;
}

export type UpdateIntermediaryVariableConfigurationParams = Partial<
  Omit<CreateIntermediaryVariableConfigurationParams, 'variable'>
>;

export interface IntermediaryVariableConfigurationsApi {
  getAll(productId?: string): Promise<VariableConfiguration[]>;
  create(params: CreateIntermediaryVariableConfigurationParams): Promise<VariableConfiguration>;
  update(id: string, params: UpdateIntermediaryVariableConfigurationParams): Promise<VariableConfiguration>;
  bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]): Promise<void>;
  delete(id: string): Promise<void>;
}

export default class IntermediaryVariableConfigurationsRestApi extends LoanOriginationSystemApi<VariableConfiguration>
  implements IntermediaryVariableConfigurationsApi {
  protected resourceName = 'intermediary-variable-configurations';

  public getAll(productId?: string) {
    const params = new URLSearchParams();

    if (productId) {
      params.set('productId', productId);
    }

    return this.getResources<VariableConfiguration[]>(params);
  }

  public create(params: CreateIntermediaryVariableConfigurationParams) {
    return this.createResource({
      variableConfiguration: params,
    });
  }

  public update(id: string, params: UpdateIntermediaryVariableConfigurationParams) {
    return this.updateResource(id, {
      variableConfiguration: params,
    });
  }

  public bulkUpdate(configurations: BulkUpdateVariableConfigurationParams[]) {
    return this.fetch<void>(`/${this.resourceName}/`, 'PUT', {
      configurations,
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
