import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './TabHeader.module.scss';

interface TabHeaderProps {
  title: string;
  actions: React.ReactNode;
  className?: string;
}

const TabHeader: FC<TabHeaderProps> = ({ title, actions, className }) => {
  return (
    <header className={clsx(styles.header, className)}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.actions}>{actions}</div>
    </header>
  );
};

export default TabHeader;
