import React from 'react';
import clsx from 'clsx';
import NewBorrowerForm from 'components/LoanOriginationSystem/CreateApplication/Forms/BorrowerForm/NewBorrowerForm';
import { SuggestionItem } from 'components/LoanOriginationSystem/CreateApplication/BorrowerSuggestionsPopper';
import ExistingBorrowerForm from 'components/LoanOriginationSystem/CreateApplication/Forms/BorrowerForm/ExistingBorrowerForm';
import { FormSkeleton } from 'components/Skeleton';
import InfoCardSkeleton from 'components/InfoCard/InfoCardSkeleton';
import { FormLayoutData, VariableConfiguration } from 'api/Types';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import UnlockBorrower from 'components/LoanOriginationSystem/UnlockBorrower';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import styles from './BorrowerForm.module.scss';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { StandardVariables } from 'Variables/VariablesTypes';

export interface BorrowerFormProps {
  borrowerType?: BorrowerType;
  formData: FormLayoutData;
  suggestions: Borrower[];
  selectedBorrower: Borrower | null;
  configurations: VariableConfiguration[] | null;
  standardVariables: StandardVariables | null;
  onLoadSuggestions?: (formData: BorrowerSuggestionFilter) => void;
  onUnlockCustomerClick?: (borrower: Borrower) => void;
  onBorrowerSelect?: (borrower: Borrower | null) => void;
  onFormDataChange: (borrower: FormLayoutData) => void;
  onProfileEditClick?: () => void;
  isEditMode?: boolean;
}

const BorrowerForm = ({
  suggestions,
  formData,
  selectedBorrower,
  configurations,
  standardVariables,
  onFormDataChange,
  onBorrowerSelect,
  onLoadSuggestions,
  onUnlockCustomerClick,
  onProfileEditClick,
  isEditMode,
  borrowerType,
}: BorrowerFormProps) => {
  const onBorrowerFormChange = (newFormData: FormLayoutData) => {
    onFormDataChange(newFormData);
  };

  const onSuggestionSelect = (suggestionItem: SuggestionItem) => {
    onBorrowerSelect?.(suggestions.find(({ id }) => id === suggestionItem.id)!);
  };

  const onCloseExistingBorrowerForm = () => {
    onBorrowerSelect?.(null);
  };

  const suggestionOptions = suggestions.map(
    (suggestion) =>
      ({
        id: suggestion.id,
        name: getBorrowerFullName(suggestion.type, suggestion.variables),
        phone: suggestion.variables[BorrowerDefaultVariable.PhoneNumber],
        email: suggestion.variables[BorrowerDefaultVariable.Email],
      } as SuggestionItem),
  );

  if (!configurations || !standardVariables || !borrowerType) {
    return isEditMode ? <InfoCardSkeleton /> : <FormSkeleton />;
  }

  const renderForm = () => {
    if (selectedBorrower || isEditMode) {
      return (
        <ExistingBorrowerForm
          standardVariables={standardVariables}
          borrowerType={selectedBorrower ? selectedBorrower.type : borrowerType}
          variables={selectedBorrower ? selectedBorrower.variables : formData}
          onClose={onCloseExistingBorrowerForm}
          onProfileEditClick={onProfileEditClick}
          hideCloseButton={isEditMode}
        />
      );
    }

    return (
      <NewBorrowerForm
        standardVariables={standardVariables}
        borrowerData={formData}
        onChange={onBorrowerFormChange}
        configurations={configurations}
        suggestions={suggestionOptions}
        onSuggestionSelect={onSuggestionSelect}
        onLoadSuggestions={onLoadSuggestions}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.form, (selectedBorrower || isEditMode) && styles.existingBorrowerForm)}>
        {renderForm()}
      </div>
      {selectedBorrower?.locked && (
        <div className={clsx(styles.footer, selectedBorrower?.locked && styles.locked)}>
          {selectedBorrower?.locked && (
            <UnlockBorrower onUnlockBorrower={() => onUnlockCustomerClick?.(selectedBorrower)} />
          )}
        </div>
      )}
    </div>
  );
};

export default BorrowerForm;
