import { StrategyGroup } from 'api/Types';
import TableBodyContent from 'components/Table/TableBodyContent';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import { NullableItems } from 'pagination';
import React, { FC } from 'react';
import SkeletonStrategyTableRow from './SkeletonStrategyTableRow';
import StrategyTableRow from './StrategyTableRow';
import { STRATEGY_GROUPS_TABLE_COLUMNS_SIZES } from './TableColumnSizes';
import styles from './StrategiesTableView.module.scss';

interface StrategiesTableViewProps {
  groups: NullableItems<StrategyGroup>;
  handleRowClick: (name: string) => void;
}

const StrategiesTableView: FC<StrategiesTableViewProps> = ({ groups, handleRowClick }) => {
  const renderRow = (group: StrategyGroup) => (
    <StrategyTableRow {...group} key={group.name} onClick={() => handleRowClick(group.name)} />
  );

  return (
    <div className={styles.table}>
      <TableHead>
        <TableHeadCell width={STRATEGY_GROUPS_TABLE_COLUMNS_SIZES.DISPLAY_NAME}>Strategy Name</TableHeadCell>
        <TableHeadCell width={STRATEGY_GROUPS_TABLE_COLUMNS_SIZES.VERSIONS}>Key Versions</TableHeadCell>
      </TableHead>
      <TableBodyContent
        rows={groups}
        renderSkeletonTableRow={(index) => <SkeletonStrategyTableRow key={index} />}
        renderTableRow={renderRow}
      />
    </div>
  );
};

export default StrategiesTableView;
