import React, { FC } from 'react';

import styles from './ListOfApplications.module.scss';

import { ShareImage } from 'static/images';

interface appInfoLink {
  title: string;
  link: string;
}

interface ListOfApplicationsProps {
  title: string;
  mainLink: string;
  listOfSubLinks: appInfoLink[];
}

const ListOfApplications: FC<ListOfApplicationsProps> = ({ title, mainLink, listOfSubLinks }) => {
  return (
    <div className={styles.applicationLink}>
      <div className={styles.linksTitle}>
        {/* Redirect to external resource */}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={mainLink} target="_blank">
          <h5>{title}</h5>
          <ShareImage />
        </a>
      </div>
      <div className={styles.linksList}>
        {listOfSubLinks.map((appInfoLink) => (
          <div key={appInfoLink.title} className={styles.link}>
            {/* Redirect to external resource */}
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={appInfoLink.link} target="_blank">
              {appInfoLink.title}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListOfApplications;
