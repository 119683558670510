enum NavigationLinkId {
  // Loan Origination System
  Applications = 'Applications',
  Borrowers = 'Borrowers',
  Intermediaries = 'Intermediaries',
  Tasks = 'Tasks',
  Reporting = 'Reporting',
  Emails = 'Emails',
  Configuration = 'Configuration',
  Products = 'Products',
  BorrowersConfiguration = 'Borrowers Configuration',
  IntermediariesConfiguration = 'Intermediaries Configuration',
  ProcessAutomation = 'Process Automation',
  ApplicationLabels = 'Application Labels',

  // Decision Engine
  Strategies = 'Decision Strategies',
  StrategyProcessing = 'Strategy Processing',
  IndividualStrategyProcessing = 'Individual Processing',
  BatchStrategyProcessing = 'Batch Processing',
  APIActivation = 'API Activation',

  // Company Settings
  General = 'General',
  CompanyInformation = 'Company Information',
  RegionSettings = 'Region Settings',
  Team = 'Team',
  Users = 'Platform Users',
  Permissions = 'Access Permissions',
  Variables = 'Variables',
  Integrations = 'Integrations',
  DigiFiAPI = 'DigiFi API',
  DocuSign = 'DocuSign Integration',
  SendGrid = 'SendGrid Integration',
  PlanAndBilling = 'Plan & Billing',
}

export default NavigationLinkId;
