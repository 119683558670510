import React, { ReactElement, ReactNode } from 'react';
import clsx from 'clsx';
import { FolderImage } from 'static/images';
import styles from './NoItems.module.scss';
import LinkButton from 'components/LinkButton';

export interface NoItemsProps {
  title: string;
  titleClassName?: string;
  subtitle?: string;
  subtitleClassName?: string;
  icon?: ReactNode;
  buttonMessage?: string;
  buttonClassName?: string;
  className?: string;
  onButtonClick?: () => void;
  buttonImage?: ReactElement;
  buttonImageClassName?: string;
}

const NoItems = ({
  className,
  icon,
  title,
  titleClassName,
  buttonMessage,
  buttonClassName,
  subtitle,
  subtitleClassName,
  onButtonClick,
  buttonImage,
  buttonImageClassName,
}: NoItemsProps) => (
  <div className={clsx(styles.noItemsContainer, className)}>
    {icon || <FolderImage />}
    <p className={clsx(styles.title, titleClassName)}>{title}</p>
    {subtitle && <p className={clsx(styles.subtitle, subtitleClassName)}>{subtitle}</p>}
    {buttonMessage && (
      <LinkButton className={buttonClassName} onClick={() => onButtonClick!()}>
        {buttonImage && <div className={clsx(styles.buttonImage, buttonImageClassName)}>{buttonImage}</div>}
        {buttonMessage}
      </LinkButton>
    )}
  </div>
);

export default NoItems;
