import { OrganizationInfo } from 'api/Core/OrganizationApi';
import {
  GetOrganizationsAction,
  GetOrganizationsFailedAction,
  GetOrganizationsSuccessAction,
} from 'UserOrganizations/UserOrganizationsActionCreators';
import { UserOrganizationsActionTypes } from 'UserOrganizations/UserOrganizationsActionTypes';

export interface UserOrganizationsState {
  items: OrganizationInfo[];
  isLoading: boolean;
}

type HandledAction = GetOrganizationsAction | GetOrganizationsSuccessAction | GetOrganizationsFailedAction;

const getInitialState: () => UserOrganizationsState = () => ({
  items: [],
  isLoading: false,
});

const UserOrganizationsReducer = (
  state: UserOrganizationsState | null = null,
  action: HandledAction,
): UserOrganizationsState | null => {
  switch (action.type) {
    case UserOrganizationsActionTypes.GetOrganizations: {
      return {
        ...(state || getInitialState()),
        isLoading: true,
      };
    }
    case UserOrganizationsActionTypes.GetOrganizationsSuccess: {
      return {
        ...(state || getInitialState()),
        items: action.payload.organizations,
        isLoading: false,
      };
    }
    case UserOrganizationsActionTypes.GetOrganizationsFailed: {
      return {
        ...(state || getInitialState()),
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default UserOrganizationsReducer;
