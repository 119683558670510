import clsx from 'clsx';
import { RowActionsContainer } from 'components/RowActions';
import React, { FC } from 'react';
import { CrossIcon } from 'static/images';
import styles from './TableRow.module.scss';

interface TableRowProps {
  useActions?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disableHover?: boolean;
  className?: string;
  error?: boolean;
  disabled?: boolean;
}

const TableRow: FC<TableRowProps> = ({
  children,
  useActions,
  onClick,
  disableHover = false,
  className,
  error,
  disabled,
}) => {
  const renderContainer = () => (
    <div
      className={clsx(
        disableHover ? styles.tableRow : styles.tableRowHover,
        useActions && styles.tableRowWithActions,
        className,
        disabled && styles.disabledTableRow,
      )}
      onClick={disabled ? undefined : onClick}
    >
      {children}
      {error && <CrossIcon className={styles.alertIcon} />}
    </div>
  );

  return useActions ? <RowActionsContainer>{renderContainer()}</RowActionsContainer> : renderContainer();
};

export default TableRow;
