import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';

export enum Product {
  LoanOriginationSystem = 'loanOriginationSystem',
  DecisionEngine = 'decisionEngine',
  CompanySettings = 'companySettings',
}

export enum ProductSection {
  LoanOriginationSystemApplications = 'loanOriginationSystemApplications',
  LoanOriginationSystemBorrowers = 'loanOriginationSystemBorrowers',
  LoanOriginationSystemIntermediaries = 'loanOriginationSystemIntermediaries',
  LoanOriginationSystemTasks = 'loanOriginationSystemTasks',
  LoanOriginationSystemEmails = 'loanOriginationSystemEmails',
  LoanOriginationSystemReporting = 'loanOriginationSystemReporting',
  LoanOriginationSystemConfiguration = 'loanOriginationSystemConfiguration',

  DecisionEngineStrategies = 'decisionEngineStrategies',
  DecisionEngineProcessing = 'decisionEngineProcessing',
  DecisionEngineApiActivation = 'decisionEngineApiActivation',
  DecisionEngineReporting = 'decisionEngineReporting',

  CompanySettingsGeneral = 'companySettingsGeneral',
  CompanySettingsPlanAndBilling = 'companySettingsPlanAndBilling',
  CompanySettingsTeam = 'companySettingsTeam',
  CompanySettingsVariables = 'companySettingsVariables',
  CompanySettingsIntegrations = 'companySettingsIntegrations',
}

export type ProductSectionAccessPermissionsMap = Record<ProductSection, boolean>;

export interface ProductSectionAccessPermissions {
  organization: string;
  userRole: UserRoleName;
  permissions: ProductSectionAccessPermissionsMap;
}

type PermissionUpdatingState = Partial<Record<ProductSection, LoaderState | undefined>>;

export type PermissionsUpdatingState = Record<UserRoleName, PermissionUpdatingState>;

export interface SectionPermissions {
  productSection: ProductSection;
  [UserRoleName.User]: boolean;
  [UserRoleName.Admin]: boolean;
  [UserRoleName.Owner]: boolean;
}

export interface ProductPermissions {
  product: Product;
  sections: SectionPermissions[];
}
