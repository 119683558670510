import React, { ChangeEvent, FC, Ref } from 'react';
import styles from './DoubleTextInput.module.scss';
import TextInput from 'components/TextInput';
import clsx from 'clsx';

interface DoubleTextInputProps {
  firstInputValue: string;
  secondInputValue: string;
  onFirstInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSecondInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  labelTitle: string;
  className?: string;
  firstInputRef?: Ref<HTMLInputElement>;
  secondInputRef?: Ref<HTMLInputElement>;
}

const DoubleTextInput: FC<DoubleTextInputProps> = ({
  firstInputValue,
  secondInputValue,
  onFirstInputChange,
  onSecondInputChange,
  labelTitle,
  className,
  firstInputRef,
  secondInputRef,
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <p className={styles.title}>{labelTitle}</p>
      <div className={styles.inputContainer}>
        <TextInput
          value={firstInputValue}
          onChange={onFirstInputChange}
          containerClassName={styles.firstTextInput}
          inputRef={firstInputRef}
        />
        <TextInput
          value={secondInputValue}
          onChange={onSecondInputChange}
          containerClassName={styles.secondTextInput}
          inputRef={secondInputRef}
        />
      </div>
    </div>
  );
};

export default DoubleTextInput;
