import Debouncer from 'middlewares/Debouncer';
import { VariablesListActionType } from './ActionTypes';
import { getListVariables } from './ActionCreator';

export default Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemVariablesList } = getState();
    const { search } = loanOriginationSystemVariablesList;

    return getListVariables(search);
  },
  [VariablesListActionType.SetSearchInputValue],
);
