import React, { FC } from 'react';
import styles from './TableRow.module.scss';
import { ExternalIntegrationVariableType, ExternalIntegrationOutputsList } from 'api/DecisionEngineStrategiesType';
import { Option } from 'components/SelectInput/SelectInput';
import RowActions, { RowActionsContainer } from 'components/RowActions';

interface TableRowProps {
  variable: ExternalIntegrationOutputsList;
  numberVariables: Option[];
  stringVariables: Option[];
  booleanVariables: Option[];
  dateVariables: Option[];
  onOpenAssignOutputVariablePopUp: (assignedOutput: string) => void;
}

const TableRow: FC<TableRowProps> = ({
  variable,
  numberVariables,
  stringVariables,
  booleanVariables,
  dateVariables,
  onOpenAssignOutputVariablePopUp,
}) => {
  const { name, variable: variableValue, type, apiName } = variable;

  const getVariableName = () => {
    if (variableValue) {
      // eslint-disable-next-line default-case
      switch (type) {
        case ExternalIntegrationVariableType.String:
          return stringVariables.find((stringVariable) => stringVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Number:
          return numberVariables.find((numberVariable) => numberVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Boolean:
          return booleanVariables.find((booleanVariable) => booleanVariable.value === variableValue)!.name;
        case ExternalIntegrationVariableType.Date:
          return dateVariables.find((dateVariable) => dateVariable.value === variableValue)!.name;
      }
    }
    return '';
  };

  return (
    <RowActionsContainer>
      <div className={styles.ruleRow} onClick={() => onOpenAssignOutputVariablePopUp(apiName)}>
        <div className={styles.conditionContainer}>
          <div className={styles.ruleContainer}>
            <div className={styles.variable}>{name}</div>
            <div className={styles.value}>{getVariableName()}</div>
            <RowActions
              className={styles.actionsButton}
              actions={[
                {
                  title: 'Edit Assigned Variable',
                  handler: () => onOpenAssignOutputVariablePopUp(apiName),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </RowActionsContainer>
  );
};

export default TableRow;
