import { AnyAction } from 'redux';
import { VariableStatusChangePopupState } from './Types';
import { VariableStatusChangePopupReducerActionType } from './ActionTypes';

const initialState: VariableStatusChangePopupState = {
  isOpen: false,
  id: '',
};

const variableStatusChangePopupReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case VariableStatusChangePopupReducerActionType.OpenVariableStatusChangePopup: {
      const { id } = action.payload;
      return { isOpen: true, id };
    }
    case VariableStatusChangePopupReducerActionType.CloseVariableStatusChangePopup:
      return { ...initialState };
    default:
      return state;
  }
};

export default variableStatusChangePopupReducer;
