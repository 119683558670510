import React, { ChangeEvent, useState } from 'react';
import TextInput from 'components/TextInput';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import styles from './ChangeEmailPopup.module.scss';
import ConfirmPasswordForm from 'components/ConfirmPasswordForm';
import { changeEmail } from 'AccountDetails/AccountDetailsActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { AppRoutes } from 'routes/RouteService';
import { useHistory } from 'react-router-dom';
import useLoadingState from 'hooks/useLoadingState';

interface IChangeEmailPopupProps {
  onClose: () => void;
}

const ChangeEmailPopup = ({ onClose }: IChangeEmailPopupProps) => {
  const dispatchRoutine = useDispatchRoutine();
  const history = useHistory();
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [email, setEmail] = useState('');

  const [loading, handleChangeEmail] = useLoadingState(async () => {
    await dispatchRoutine(changeEmail(email));
    onClose();
    history.push(AppRoutes.Activation, { waitForResend: true });
  });

  const handlePasswordConfirmed = () => setPasswordConfirmed(true);
  const renderChangeEmailForm = () => (
    <>
      <p className={styles.description}>Please provide your new email address.</p>
      <TextInput
        labelTitle="New Email Address"
        value={email}
        placeholder="Enter new email address"
        containerClassName={styles.inputContainer}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
      />
      <Button size="form" kind="primary" isLoading={loading} disabled={!email} onClick={handleChangeEmail}>
        Change Email
      </Button>
    </>
  );

  return (
    <PopUp title="Change Email" closePopUp={onClose}>
      <PopUpContent hasTopMargin>
        {passwordConfirmed ? (
          renderChangeEmailForm()
        ) : (
          <ConfirmPasswordForm onPasswordConfirmed={handlePasswordConfirmed} />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default ChangeEmailPopup;
