import React from 'react';
import styles from './ApplicationDocumentsDownloadSnackbar.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState';
import { DocumentDownloadingState } from 'LoanOriginationSystemApplicationPage/Documents/Types';
import { ArchiveImage } from 'static/images';
import { removeDownloadedDocument } from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import { DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION } from 'Snackbar/Middleware';
import { getAbbreviatedTextLine } from 'utils/getAbbreviatedString';

export interface ApplicationDocumentsDownloadSnackbarProps {
  documentName: string;
  documentDownloadingId: string;
}

const MAX_DOCUMENT_NAME_LENGTH = 38;

const ApplicationDocumentsDownloadSnackbar = ({
  documentName,
  documentDownloadingId,
}: ApplicationDocumentsDownloadSnackbarProps) => {
  const dispatch = useDispatch();

  const documentDownloadingState: DocumentDownloadingState = useSelector(
    (state: ReduxState) =>
      state.loanOriginationSystemApplicationPage.documents.downloadableDocuments[documentDownloadingId],
  );

  const onDocumentDownloadingStateReset = () => {
    dispatch(removeDownloadedDocument(documentDownloadingId));
  };

  return (
    <div className={styles.documentContainer}>
      <div className={styles.documentIcon}>
        <ArchiveImage />
      </div>
      <div className={styles.documentName}>
        {getAbbreviatedTextLine(`${documentName}.zip`, MAX_DOCUMENT_NAME_LENGTH)}
      </div>
      <LoaderWithState
        state={documentDownloadingState === 'loading' ? LoaderState.Updating : documentDownloadingState as LoaderState}
        onStateReset={onDocumentDownloadingStateReset}
        className={styles.loader}
        successTimeout={DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION}
        failureTimeout={DOCUMENT_DOWNLOADING_SNACK_LIFE_DURATION}
      />
    </div>
  );
};

export default ApplicationDocumentsDownloadSnackbar;
