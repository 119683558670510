import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BatchProcessingHistoryCase } from 'api/Types';
import { StylesProvider } from '@material-ui/core';
import { SIZES } from 'components/BatchProcessing/History/TableColumnSizes';
import styles from './HistoryLine.module.scss';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import SearchHighlight from 'components/SearchHighlight';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import RowActions from 'components/RowActions';
import { useDownloadApi } from 'providers/ApiServiceProvider';

interface HistoryLineProps {
  batchCase: BatchProcessingHistoryCase;
  searchInputValue: string;
}

const PROGRESS_STEP = 10;
const PROGRESS_PASSED = 100;
const PROGRESS_FILL_DELAY = 800;

const HistoryLine: FC<HistoryLineProps> = ({ batchCase, searchInputValue }) => {
  const history = useHistory();
  const downloadApi = useDownloadApi();

  const [progress, setProgress] = useState(batchCase.progress);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress < PROGRESS_PASSED ? prevProgress + PROGRESS_STEP : PROGRESS_PASSED));
    }, PROGRESS_FILL_DELAY);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleCaseClick = (caseId: string) => {
    history.push(`/decision/processing/batch/results/${caseId}`);
  };

  return (
    <TableRow key={batchCase.id} useActions onClick={() => handleCaseClick(batchCase.id)}>
      <TableBodyCell width={SIZES.DATE} noPadding overflowed>
        <DateTime
          time={batchCase.date}
          format={DateTimeFormat.Long}
        />
      </TableBodyCell>
      <TableBodyCell width={SIZES.BATCH_NAME} noPadding overflowed>
        <SearchHighlight search={searchInputValue}>{batchCase.name}</SearchHighlight>
      </TableBodyCell>
      <TableBodyCell width={SIZES.PROGRESS} noPadding>
        <StylesProvider injectFirst>
          <LinearProgress className={styles.progressBar} variant="determinate" value={progress} />
        </StylesProvider>
        <p className={styles.progressText}>{progress}%</p>
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Download Results',
            handler: () => downloadApi.downloadSimulationResults(batchCase.id),
          },
        ]}
      />
    </TableRow>
  );
};

export default HistoryLine;
