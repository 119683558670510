import React from 'react';
import ConfirmPopup from 'components/ConfirmPopup';

interface UnsavedChangesPopupProps {
  onPopupClose: () => void;
  onLeaveClick: () => void;
  usePortal?: boolean;
}

const UnsavedChangesPopup = ({ onPopupClose, onLeaveClick, usePortal }: UnsavedChangesPopupProps) => (
  <ConfirmPopup
    title="Unsaved Changes"
    message="You have unsaved changes. If you navigate away from the page without first saving your data, the changes will be lost."
    confirmText="Leave Without Saving"
    declineText="No, Go Back"
    onPopupClose={onPopupClose}
    onConfirmClick={onLeaveClick}
    usePortal={usePortal}
  />
);

export default UnsavedChangesPopup;
