import React from 'react';
import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import { getPlaceholder } from './IdentificationNumberInput';

interface IdentificationNumberInputStubProps extends Omit<TextInputStubProps, 'placeholder'> {
  identificationNumberType: string;
  identificationNumberDescription: string | null;
}

const IdentificationNumberInputStub = ({
  identificationNumberType,
  identificationNumberDescription,
  ...restProps
}: IdentificationNumberInputStubProps) => (
  <TextInputStub
    {...restProps}
    placeholder={getPlaceholder(identificationNumberType, identificationNumberDescription)}
  />
);

export default IdentificationNumberInputStub;
