import React from 'react';
import Card from './Card';
import styles from './ColumnBody.module.scss';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import clsx from 'clsx';
import DroppableList from 'components/DndList/DroppableList';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';

interface ColumnBodyProps {
  id: string;
  applications: Application[];
  currencySymbol: string;
  placeholderStyle: React.CSSProperties | null;
  searchInputValue: string;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  deleteDisabled: boolean;
  onApplicationUpdatingStateReset: (applicationId: string) => void;
  onEditApplication: (application: Application) => void;
  onDeleteApplication: (application: Application) => void;
  onDuplicateApplication: (application: Application) => void;
}

const ColumnBody = ({
  id,
  applications,
  currencySymbol,
  placeholderStyle,
  searchInputValue,
  applicationUpdatingStatesById,
  onApplicationUpdatingStateReset,
  onEditApplication,
  onDeleteApplication,
  onDuplicateApplication,
  deleteDisabled,
}: ColumnBodyProps) => {
  const renderApplication = (
    targetApplication: Application,
    _index: number,
    draggableSnapshot: DraggableStateSnapshot,
  ) => {
    return (
      <Card
        {...targetApplication}
        key={targetApplication.id}
        currencySymbol={currencySymbol}
        highlightString={searchInputValue}
        className={clsx(draggableSnapshot.isDragging && styles.draggingCard)}
        updatingState={applicationUpdatingStatesById[targetApplication.id]}
        onApplicationUpdatingStateReset={() => onApplicationUpdatingStateReset(targetApplication.id)}
        disabled={applicationUpdatingStatesById[targetApplication.id] === 'updating'}
        onEdit={() => onEditApplication(targetApplication)}
        onDelete={() => onDeleteApplication(targetApplication)}
        onDuplicate={() => onDuplicateApplication(targetApplication)}
        deleteDisabled={deleteDisabled}
      />
    );
  };

  return (
    <div className={styles.content}>
      <DroppableList<Application>
        droppableId={id}
        droppableType="card"
        items={applications}
        renderListItem={renderApplication}
        placeholderStyles={placeholderStyle}
        listItemClassName={styles.card}
        withPlaceholder
        isDragDisabled={(item) => applicationUpdatingStatesById[item.id] === 'updating'}
        getDraggableId={(itemId) => itemId as string}
      />
    </div>
  );
};

export default ColumnBody;
