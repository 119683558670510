const SET_SEARCH_INPUT_VALUE = 'SET_SEARCH_INPUT_VALUE';
const FILTER_APPLICATIONS_BY_MEMBERS = 'FILTER_APPLICATIONS_BY_MEMBERS';
const GET_PRODUCT_LABELS = 'GET_PRODUCT_LABELS';
const GET_PRODUCT_LABELS_SUCCESS = 'GET_PRODUCT_LABELS_SUCCESS';
const GET_PRODUCT_LABELS_FAILED = 'GET_PRODUCT_LABELS_FAILED';
const GET_PRODUCT_INTERMEDIARIES = 'GET_PRODUCT_INTERMEDIARIES';
const GET_PRODUCT_INTERMEDIARIES_SUCCESS = 'GET_PRODUCT_INTERMEDIARIES_SUCCESS';
const GET_PRODUCT_INTERMEDIARIES_FAILED = 'GET_PRODUCT_INTERMEDIARIES_FAILED';
const TOGGLE_FILTERS_POPUP = 'TOGGLE_FILTERS_POPUP';
const CHANGE_POPUP_FILTERS = 'CHANGE_POPUP_FILTERS';

export {
  SET_SEARCH_INPUT_VALUE,
  FILTER_APPLICATIONS_BY_MEMBERS,
  GET_PRODUCT_LABELS,
  GET_PRODUCT_LABELS_SUCCESS,
  GET_PRODUCT_LABELS_FAILED,
  GET_PRODUCT_INTERMEDIARIES,
  GET_PRODUCT_INTERMEDIARIES_SUCCESS,
  GET_PRODUCT_INTERMEDIARIES_FAILED,
  TOGGLE_FILTERS_POPUP,
  CHANGE_POPUP_FILTERS,
};

export enum ApplicationsActionType {
  ClearAllFilters = 'applications/clearAllFilters',
}
