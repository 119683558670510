import Fetcher from 'middlewares/Fetcher';
import { GetTasksDataSuccess } from 'LoanOriginationSystemTasks/ActionCreator';
import { LoanOriginationSystemTasksApi } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { GetTasksAction, getTasksFailed, getTasksSuccess } from './ActionCreator';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import { TASKS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/TasksDashboard/Pagination';

const GetTasksMiddleware = (api: LoanOriginationSystemTasksApi) =>
  Fetcher<GetTasksDataSuccess, GetTasksAction>(
    LoanOriginationSystemTasksActionTypes.GetTasks,
    getTasksSuccess,
    getTasksFailed,
    async (action) => {
      const { filters, sortingType, applicationId } = action.payload;

      const response = await api.getTasks(
        {
          search: filters.searchInputValue,
          offset: 0,
          count: TASKS_PER_PAGE_DEFAULT,
          dueDateRange: filters.dueDateRange,
          status: filters.selectedStatus,
          members: filters.selectedMembers,
        },
        sortingType,
        applicationId,
      );

      return {
        ...response,
        searchInputValue: filters.searchInputValue,
        applicationId,
      };
    },
  );

export default GetTasksMiddleware;
