export const GET_API_ACTIVATIONS_REQUEST = 'GET_API_ACTIVATIONS_REQUEST';
export const GET_API_ACTIVATIONS_SUCCESS = 'GET_API_ACTIVATIONS_SUCCESS';
export const GET_API_ACTIVATIONS_FAILURE = 'GET_API_ACTIVATIONS_FAILURE';

export const UPDATE_API_ACTIVATIONS_REQUEST = 'UPDATE_API_ACTIVATIONS_REQUEST';
export const UPDATE_API_ACTIVATIONS_SUCCESS = 'UPDATE_API_ACTIVATIONS_SUCCESS';
export const UPDATE_API_ACTIVATIONS_FAILURE = 'UPDATE_API_ACTIVATIONS_FAILURE';

export const OPEN_API_ACTIVATIONS_POPUP = 'OPEN_API_ACTIVATIONS_POPUP';
export const CLOSE_API_ACTIVATIONS_POPUP = 'CLOSE_API_ACTIVATIONS_POPUP';

export const DOWNLOAD_API_TEMPLATE = 'DOWNLOAD_API_TEMPLATE';
export const DOWNLOAD_API_TEMPLATE_SUCCESS = 'DOWNLOAD_API_TEMPLATE_SUCCESS';
export const DOWNLOAD_API_TEMPLATE_FAILURE = 'DOWNLOAD_API_TEMPLATE_FAILURE';

export enum ApiActivationActionType {
  GetDropdownGroupedStrategies = 'apiActivation/getDropdownGroupedStrategies',
  GetDropdownGroupedStrategiesSuccess = 'apiActivation/getDropdownGroupedStrategiesSuccess',
  GetDropdownGroupedStrategiesFailure = 'apiActivation/getDropdownGroupedStrategiesFailure',
  ClearDropdownStrategiesList = 'apiActivation/clearDropdownStrategiesList',
  SetStrategyGroupsSearchInput = 'apiActivation/setStrategyGroupsSearchInput',
  SetShowArchived = 'apiActivation/setShowArchived',
  SetUpdaters = 'apiActivation/setUpdaters',
  SortStrategies = 'apiActivation/sortStrategies',
  ClearApiActivationData = 'apiActivation/clearApiActivationData',
}
