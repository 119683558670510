import AutoCompletionMultiselect, { AutoCompletionProps } from 'components/AutoCompletionMultiselect';
import { Option } from 'components/SelectInput/SelectInput';
import React, { FC, useEffect, useState } from 'react';
import { useVariablesApi } from 'providers/ApiServiceProvider';

interface AutoCompletionMultiSelectFetch extends Omit<AutoCompletionProps, 'options'> {}

const AutocompletionMultiSelectFetch: FC<AutoCompletionMultiSelectFetch> = (props) => {
  const [options, setOptions] = useState<Option[]>([]);

  const variablesApi = useVariablesApi();
  const fetchRequiredVariables = async () => {
    const fetchedVariables = await variablesApi.getAllVariables();
    const variables = fetchedVariables.map(({ id, displayName }) => ({
      value: id,
      name: displayName,
    }));
    setOptions(variables);
  };

  useEffect(() => {
    fetchRequiredVariables();
  }, []);

  return <AutoCompletionMultiselect options={options} {...props} />;
};

export default AutocompletionMultiSelectFetch;
