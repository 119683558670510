import NoItems from 'components/NoItems';
import styles from './NoStrategyFilterResults.module.scss';
import { NoResultsIcon } from 'static/images';
import React from 'react';

interface NoStrategyFilterResultsProps {
  onClick: () => void;
}

const NoStrategyFilterResults = ({ onClick }: NoStrategyFilterResultsProps) => (
  <NoItems
    title="No Results Found"
    subtitle="Please remove or adjust your filters."
    buttonMessage="Clear Filters"
    className={styles.noStrategyItems}
    titleClassName={styles.noResultsMessage}
    buttonClassName={styles.clearFiltersButton}
    icon={<NoResultsIcon className={styles.noFilterResultsImg} />}
    onButtonClick={onClick}
  />
);

export default NoStrategyFilterResults;
