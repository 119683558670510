import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { ApplicationNotesActionType } from './ActionTypes';
import { SortApplicationNotesAction, getApplicationNotes } from './ActionCreator';

const isSortApplicationNotesAction = (action: AnyAction): action is SortApplicationNotesAction =>
  action.type === ApplicationNotesActionType.SortApplicationNotes;

const sortApplicationNotesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortApplicationNotesAction(action)) {
    const { loanOriginationSystemApplicationPage } = getState();

    dispatch(
      getApplicationNotes(
        action.payload.applicationId,
        loanOriginationSystemApplicationPage.notes.search,
        action.payload.sortingType,
      ),
    );
  }

  return result;
}) as Middleware;

export default sortApplicationNotesMiddleware;
