import React, { useMemo, FC } from 'react';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import InfoPanel from 'components/LoanOriginationSystem/ApplicationDetails/InfoPanel';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import styles from './ApplicationHeaderPreview.module.scss';
import { StandardVariables } from 'Variables/VariablesTypes';

export interface ApplicationHeaderPreviewProps {
  borrowerType: BorrowerType;
  productName: string;
  standardVariables: StandardVariables;
}

const getStubApplicationData = (borrowerType: BorrowerType) => ({
  applicationName: 'Borrower Name',
  applicationAmount: 12345,
  status: 'Status',
  statusesAmount: 5,
  statusNumber: 1,
  applicationData: {
    id: '123456789',
    status: {
      id: 'status',
      name: 'status',
    },
    intermediaryId: 'TEST',
    teamMembers: [
      {
        id: 'TEST',
        firstName: 'None',
        lastName: 'None',
      },
    ],
    borrowerType,
    coborrowerType: borrowerType,
    variables: {
      [ApplicationDefaultVariable.Borrower.CompanyName]: 'Name',
      [ApplicationDefaultVariable.Borrower.FirstName]: 'Name',
      [ApplicationDefaultVariable.Borrower.LastName]: '',
      [ApplicationDefaultVariable.Borrower.Email]: 'email@domain.com',
      [ApplicationDefaultVariable.Borrower.PhoneNumber]: '(555) 555-5555',
      [ApplicationDefaultVariable.Borrower.CompanyIdNumber]: '1234567',
      [ApplicationDefaultVariable.Borrower.PersonalIdNumber]: '1234567',
      [ApplicationDefaultVariable.Borrower.DateOfBirth]: '04/07/1998',
      [ApplicationDefaultVariable.Borrower.Street]: 'Street Address',
      [ApplicationDefaultVariable.Borrower.City]: 'City',
      [ApplicationDefaultVariable.Borrower.Country]: 'Country',
      [ApplicationDefaultVariable.Borrower.State]: 'State',
      [ApplicationDefaultVariable.Borrower.Zip]: '12345',
      [ApplicationDefaultVariable.Coborrower.CompanyName]: 'Name',
      [ApplicationDefaultVariable.Coborrower.FirstName]: 'Name',
      [ApplicationDefaultVariable.Coborrower.LastName]: '',
      [ApplicationDefaultVariable.Coborrower.Email]: 'email@domain.com',
      [ApplicationDefaultVariable.Coborrower.PhoneNumber]: '(555) 555-5555',
      [ApplicationDefaultVariable.Coborrower.CompanyIdNumber]: '1234567',
      [ApplicationDefaultVariable.Coborrower.PersonalIdNumber]: '1234567',
      [ApplicationDefaultVariable.Coborrower.DateOfBirth]: '04/07/1998',
      [ApplicationDefaultVariable.Coborrower.Street]: 'Street Address',
      [ApplicationDefaultVariable.Coborrower.City]: 'City',
      [ApplicationDefaultVariable.Coborrower.Country]: 'Country',
      [ApplicationDefaultVariable.Coborrower.State]: 'State',
      [ApplicationDefaultVariable.Coborrower.Zip]: '12345',
      [ApplicationDefaultVariable.Intermediary.Name]: 'Intermediary Name',
      [ApplicationDefaultVariable.Intermediary.CommissionRate]: 0.2,
    },
    displayId: '123456',
    updatedAt: new Date(),
    createdAt: new Date(),
    updatedBy: {
      id: 'updatedById',
      firstName: 'None',
      lastName: 'None',
    },
    labels: [
      {
        id: '1',
        name: 'Label 1',
        color: '#00C4AC',
      },
      {
        id: '2',
        name: 'Label 2',
        color: '#1E95F8',
      },
    ],
  } as Application,
});

const ApplicationHeaderPreview: FC<ApplicationHeaderPreviewProps> = ({
  borrowerType,
  standardVariables,
  productName,
}) => {
  const applicationStubData = useMemo(() => {
    return getStubApplicationData(borrowerType);
  }, [borrowerType]);

  const handleFormatDate = () => {
    return 'Mon DD, YYYY, HH:MM PM';
  };

  return (
    <div className={styles.headerPreview}>
      <InfoPanel
        className={styles.infoPanel}
        productName={productName}
        statusName={applicationStubData.status}
        statusNumber={applicationStubData.statusNumber}
        statusesAmount={applicationStubData.statusesAmount}
        applicationData={applicationStubData.applicationData}
        standardVariables={standardVariables}
        isStatusVisible
        formatApplicationDate={handleFormatDate}
        hideEditApplicationLink
      />
    </div>
  );
};

export default ApplicationHeaderPreview;
