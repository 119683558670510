import React, { CSSProperties, FC } from 'react';
import styles from './Module.module.scss';
import {
  AddImage,
  CalculationScriptsImage,
  DragImage,
  DropdownImage,
  EndProcessImage,
  ExternalIntegrationImage,
  RequirementsRulesImage,
  RuleBasedOutputsImage,
  ScoringModelImage,
  SimpleOutputsImage,
  ArrowRightCircle,
  DatabaseIcon,
} from 'static/images';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import { ModuleBranch, ModuleType, StrategyModule } from 'DecisionStrategy/DecisionStrategiesTypes';
import BranchRow from 'components/BranchRow';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import EditableInput from 'components/EditableInput';
import clsx from 'clsx';
import WithBordersDndList from 'components/WithBordersDndList';
import { DroppableList } from 'components/DndList';
import { DroppableListItemProps } from 'components/DndList/DroppableList';
import { ModuleConnectedProps } from 'components/StrategyOverview/DecisionProcess/ModuleList/Module/ModuleConnector';
import AddModuleButton from 'components/StrategyOverview/DecisionProcess/AddModuleButton';
import { Tag } from 'components/Tag';
import trimAll from 'utils/trimAll';
import { BranchKeys } from 'api/Types';

export interface ModuleProps {
  strategyId: string;
  isStrategyLocked: boolean;
  module: StrategyModule;
  branches: ModuleBranch[];
  openDeleteModulePopUp: (moduleLookupName: string, moduleIndex: number) => void;
  isDraggable: boolean;
  currentModuleKey: string;
  currentBranchIndex: number;
  listPlaceholderStyles?: CSSProperties | null;
  isModuleCollapsed: boolean;
  changeModuleCollapseState: (moduleIndex: number, isCollapsed: boolean) => void;
  moduleIndex: number;
  onAddNewModule: (e: React.MouseEvent<HTMLElement>) => void;
  addedModuleIndex: string;
  onEditBranch?: (branchKeys: BranchKeys, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface ModuleInfo {
  name: string;
  image: JSX.Element;
}

// eslint-disable-next-line consistent-return
const getModuleTypeProperty = (type: ModuleType): ModuleInfo => {
  // eslint-disable-next-line default-case
  switch (type) {
    case ModuleType.RequirementsRules:
      return {
        name: 'REQUIREMENTS RULES',
        image: <RequirementsRulesImage />,
      };
    case ModuleType.CalculationScripts:
      return {
        name: 'CALCULATION SCRIPTS',
        image: <CalculationScriptsImage />,
      };
    case ModuleType.RuleBasedOutputs:
      return {
        name: 'RULE-BASED OUTPUTS',
        image: <RuleBasedOutputsImage />,
      };
    case ModuleType.SimpleOutputs:
      return {
        name: 'SIMPLE OUTPUTS',
        image: <SimpleOutputsImage />,
      };
    case ModuleType.ScoringModel:
      return {
        name: 'SCORING MODEL',
        image: <ScoringModelImage />,
      };
    case ModuleType.ExternalIntegration:
      return {
        name: 'EXTERNAL INTEGRATION',
        image: <ExternalIntegrationImage />,
      };
    case ModuleType.ImportExistingModule:
      return {
        name: 'IMPORT EXISTING MODULE',
        image: <ExternalIntegrationImage />,
      };
  }
};

export const ERROR_TEXT_CHANGE_MODULE =
  'This strategy is locked and cannot be edited. To make changes, you must create a new version.';

const Module: FC<ModuleProps & ModuleConnectedProps> = ({
  strategyId,
  isStrategyLocked,
  module,
  openDeleteModulePopUp,
  isDraggable,
  currentModuleKey,
  currentBranchIndex,
  branches,
  listPlaceholderStyles,
  isModuleCollapsed,
  changeModuleCollapseState,
  moduleIndex,
  duplicateModule,
  updateModuleNameRequest,
  updateModuleInfoRequest,
  createBranch,
  onAddNewModule,
  addedModuleIndex,
  onEditBranch,
}) => {
  const { name, type, active, lookupName, moduleId } = module;

  const moduleNameInputProps = useAsyncValueInputProps({
    value: name,
    handleChange: (moduleName) => {
      const correctModuleName = trimAll(moduleName);
      updateModuleNameRequest({
        active,
        name: correctModuleName,
        lookupName,
        currentType: type,
        strategyId,
      });
    },
    notEditable: isStrategyLocked,
    notEditableErrorNotificationText: ERROR_TEXT_CHANGE_MODULE,
    placeholderText: 'New Process Module',
  });

  const { name: fullName, image } = getModuleTypeProperty(type);

  const deleteStrategyModule = () => {
    openDeleteModulePopUp(lookupName, moduleIndex);
  };

  const handleDuplicateModule = () => {
    duplicateModule({
      copyToStrategyId: strategyId,
      copyFromStrategyId: strategyId,
      moduleKey: lookupName,
      moduleName: `Copy of ${name}`,
      moduleType: type,
      moduleIndex: moduleIndex + 1,
    });
  };

  const toggleModalActiveStatus = () => {
    updateModuleInfoRequest({
      active: !active,
      name,
      lookupName,
      currentType: type,
      strategyId,
    });
  };

  const handleAddBranch = () => {
    createBranch({
      moduleId: module.moduleId,
      strategyId,
      moduleName: module.name,
    });
    changeModuleCollapseState(moduleIndex, false);
  };

  const toggleBranchesVisibilityButtonClassName = clsx(!isModuleCollapsed && styles.isOpen);

  const handleEditBranch = (branchIndex: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    onEditBranch?.({ moduleKey: moduleId, branchIndex }, event);
  };

  const renderBranch = (branch: ModuleBranch, index: number) => (
    <BranchRow
      key={`${branch.id}`}
      isStrategyLocked={isStrategyLocked}
      branchInfo={branch}
      moduleId={moduleId}
      strategyId={strategyId}
      branchIndex={index}
      isActive={currentModuleKey === moduleId && currentBranchIndex === index}
      onEditBranch={handleEditBranch}
    />
  );

  const moduleActions = [
    {
      title: active ? 'Disable Module' : 'Enable Module',
      handler: toggleModalActiveStatus,
      danger: false,
    },
    {
      title: 'Duplicate Module',
      handler: handleDuplicateModule,
      danger: false,
      separatorRequired: true,
    },
    {
      title: 'Delete Module',
      handler: deleteStrategyModule,
      danger: true,
    },
  ];

  return (
    <div className={styles.moduleContainer}>
      <div className={clsx(styles.module, !active && styles.moduleDisabled, isDraggable && styles.moduleDraggable)}>
        <RowActionsContainer>
          <div className={styles.moduleMainInfo}>
            <div className={styles.moduleLeft}>
              <div className={styles.dragImage}>
                <DragImage />
              </div>
              <div className={styles.moduleContent}>
                <div className={styles.hideButton}>
                  <button
                    type="button"
                    onClick={() => changeModuleCollapseState(moduleIndex, !isModuleCollapsed)}
                    className={toggleBranchesVisibilityButtonClassName}
                  >
                    <DropdownImage />
                  </button>
                </div>
                <div className={styles.moduleImage}>{image}</div>
                <div className={styles.moduleInfo}>
                  <Tag color="blue">{fullName}</Tag>
                  <div className={styles.moduleContent}>
                    <div className={styles.moduleNameWithDisabledTag}>
                      <EditableInput
                        {...moduleNameInputProps}
                        className={clsx(styles.moduleName, !active && styles.disabledModuleName)}
                      />
                      {!active && <div className={styles.disabledModuleTag}>Disabled</div>}
                    </div>
                    <div className={styles.addBranchButton}>
                      <button type="button" onClick={handleAddBranch}>
                        <AddImage /> Add Branch
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.moduleRight}>
              <div className={styles.moreImage}>
                <RowActions actions={moduleActions} btnClassName={styles.moreButton} />
              </div>
            </div>
          </div>
        </RowActionsContainer>
        {!isModuleCollapsed && (
          <WithBordersDndList<ModuleBranch>
            items={branches}
            droppableId={module.moduleId}
            listClassName={styles.branchesContainer}
            listItemClassName={styles.branchListItem}
            renderListItem={renderBranch}
            placeholderStyles={listPlaceholderStyles}
            withPlaceholder
          >
            {(listProps: DroppableListItemProps<ModuleBranch>) => <DroppableList {...listProps} />}
          </WithBordersDndList>
        )}

        <div className={styles.addModule}>
          <AddModuleButton onClick={onAddNewModule} isActive={addedModuleIndex === (moduleIndex + 1).toString()} />
        </div>
      </div>
      {module.type === ModuleType.RequirementsRules && (
        <div className={styles.requirementsRulesContainer}>
          <ArrowRightCircle />
          <div className={styles.requirementsRules}>
            <EndProcessImage />
            <p>End If Fail</p>
          </div>
        </div>
      )}
      {module.type === ModuleType.ExternalIntegration && (
        <div className={styles.externalIntegrationContainer}>
          <div className={styles.integrationArrows}>
            <ArrowRightCircle />
            <div className={styles.integrationArrowLeft}>
              <ArrowRightCircle />
            </div>
          </div>
          <div className={styles.externalIntegration}>
            <DatabaseIcon />
            <p>Data API</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Module;
