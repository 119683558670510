import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemApplicationStatusesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  deleteApplicationStatusSuccess,
  deleteApplicationStatusFailure,
  DeleteApplicationStatusAction,
} from './ActionCreator';

const DeleteApplicationStatusMiddleware = (api: LoanOriginationSystemApplicationStatusesApi) =>
  Fetcher<string, DeleteApplicationStatusAction>(
    ApplicationStatusesActionType.DeleteApplicationStatus,
    deleteApplicationStatusSuccess,
    deleteApplicationStatusFailure,
    async (action) => {
      await api.delete(action.payload.statusId);

      return action.payload.statusId;
    },
  );

export default DeleteApplicationStatusMiddleware;
