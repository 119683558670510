import clsx from 'clsx';
import Button from 'components/Button';
import OverlayScrollContainer from 'components/OverlayScrollContainer';
import SkeletonText from 'components/Skeleton/SkeletonText';
import { isNumber } from 'lodash';
import React, { FC } from 'react';
import { ShareImageCustomColor } from 'static/images';
import styles from './StatisticBlock.module.scss';

export interface StatisticBlockItem {
  name: string;
  value: string | number;
}

interface StatisticBlockProps {
  title: string;
  subTitle?: string | null;
  value: string | null;
  secondaryTitle: string;
  buttonTitle: string;
  buttonSubTitle: string;
  items: StatisticBlockItem[] | null;
  total?: number | null;
  onButtonClick?: () => void;
}

const SKELETON_SECONDARY_ITEMS: StatisticBlockItem[] = Array.from({ length: 4 }, () => ({ name: '', value: 0 }));

const StatisticBlock: FC<StatisticBlockProps> = ({
  title,
  subTitle,
  value,
  secondaryTitle,
  items,
  buttonTitle: downloadButtonTitle,
  buttonSubTitle: downloadButtonSubTitle,
  total,
  onButtonClick: onDownload,
}) => {
  const isLoading = value === null || subTitle === null || items === null || total === null;

  return (
    <div className={styles.container}>
      <div className={styles.primaryInfo}>
        <p className={styles.title}>{title}</p>
        <div className={styles.value}>
          {isLoading ? <SkeletonText color="primary20" width="168px" height="24px" lineHeight="36px" /> : value}
        </div>
        {subTitle !== undefined && (
          <div className={styles.subTitle}>
            {isLoading ? <SkeletonText color="primary6" width="100px" height="16px" lineHeight="20px" /> : subTitle}
          </div>
        )}
      </div>
      <div className={styles.secondaryInfo}>
        <p className={styles.title}>{secondaryTitle}</p>
        <OverlayScrollContainer className={styles.secondaryItemContainer}>
          {(items || SKELETON_SECONDARY_ITEMS).map((item, index) => (
            <div className={styles.secondaryItem} key={index}>
              {!isLoading && <p className={styles.secondaryItemTitle}>{item.name}</p>}
              {!isLoading && <p className={styles.secondaryItemValue}>{item.value}</p>}
              {isLoading && <SkeletonText color="primary6" width="100px" height="16px" lineHeight="20px" />}
              {isLoading && <SkeletonText color="primary20" width="70px" height="16px" lineHeight="20px" />}
            </div>
          ))}
        </OverlayScrollContainer>
        {isNumber(total) && (
          <div className={styles.total}>
            {isLoading && <SkeletonText color="primary6" width="100px" height="16px" lineHeight="20px" />}
            {isLoading && <SkeletonText color="primary20" width="70px" height="16px" lineHeight="20px" />}
            {!isLoading && <div className={styles.totalTitle}>Total</div>}
            {!isLoading && <div className={styles.totalValue}>{total}</div>}
          </div>
        )}
      </div>
      <Button
        kind="primary"
        className={clsx(styles.downloadButton, isLoading && styles.disabled)}
        disabled={isLoading}
        onClick={() => onDownload?.()}
      >
        <ShareImageCustomColor />
        <div className={styles.downloadButtonText}>
          <p className={styles.downloadButtonTitle}>{downloadButtonTitle}</p>
          <p className={styles.downloadButtonSubTitle}>{downloadButtonSubTitle}</p>
        </div>
      </Button>
    </div>
  );
};

export default StatisticBlock;
