import React, { useState, useEffect } from 'react';
import { SendGridTemplate } from 'SendGrid/Types';
import SIZES from 'components/SendGrid/EditTemplateVariablesTable/TableColumnSizes';
import styles from './EditTemplateVariablesTable.module.scss';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import EditTemplateVariablesTableBody from './EditTemplateVariablesTableBody';
import SkeletonEditTemplateTableBody from './SkeletonEditTemplateVariablesTableBody';
import { useVariablesApi } from 'providers/ApiServiceProvider';
import { Variable } from 'Variables/VariablesTypes';

interface TableProps {
  template?: SendGridTemplate | null;
}

const TEMPLATES_PER_PAGE_DEFAULT = 5;

const EditTemplateVariablesTable = ({ template }: TableProps) => {
  const variablesApi = useVariablesApi();
  const [applicationVariables, setApplicationVariables] = useState<Variable[]>([]);

  const fetchVariables = async () => {
    if (!template) {
      return;
    }

    const { items } = await variablesApi.getVariables({
      systemNames: Object.values(template.variables).filter(Boolean),
    });

    setApplicationVariables(items);
  };

  useEffect(() => {
    fetchVariables();
  }, [template]);

  const getTemplatesTableBody = () => {
    if (!template) {
      return new Array(TEMPLATES_PER_PAGE_DEFAULT)
        .fill(null)
        .map((_, index) => <SkeletonEditTemplateTableBody key={index} />);
    }

    return Object.keys(template.variables).map((name, index) => (
      <EditTemplateVariablesTableBody
        key={index}
        name={name}
        variable={template.variables[name]}
        applicationVariables={applicationVariables}
      />
    ));
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell className={styles.tableHeadCell} width={SIZES.FIELD}>
            Template Data Field
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={SIZES.VARIABLE}>
            Variable Used
          </TableHeadCell>
        </TableHead>
        <TableBody>{getTemplatesTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default EditTemplateVariablesTable;
