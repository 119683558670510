import React from 'react';
import styles from './SkeletonColumnHeader.module.scss';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';

const SkeletonColumnHeader = () => {
  return (
    <div className={styles.skeletonColumnHeader}>
      <div className={styles.skeletonColumnHeaderTopInner}>
        <SkeletonRectangle width="180px" height="24px" color="primary20" className={styles.skeletonColumnHeaderTitle} />
        <SkeletonRectangle width="28px" height="28px" color="primary10" />
      </div>
      <SkeletonRectangle width="84px" height="12px" color="primary10" />
    </div>
  );
};

export default SkeletonColumnHeader;
