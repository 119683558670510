import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { isEmptyField, validateEmail, validateIDNumber, validatePhoneNumber } from 'utils/validation/validation';

interface VariableValueValidator {
  (variableConfiguration: VariableConfiguration, value: VariableValue): boolean;
}

const isOptionalStringVariableValue = (value: VariableValue): value is Exclude<VariableValue, number | boolean> =>
  typeof value !== 'number' && typeof value !== 'boolean';

const validateRequiredField: VariableValueValidator = ({ required }: VariableConfiguration, value) => {
  if (!required) {
    return true;
  }

  return value !== null && value !== undefined && value !== '' && !isEmptyField(value.toString());
};

const validateEmailVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat } = variable;

  if (dataType !== 'String' || stringFormat !== 'EmailAddress') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateEmail(value || '', required);
};

const validateIDNumberVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat, identificationNumberType } = variable;

  if (dataType !== 'String' || stringFormat !== 'IdentificationNumber') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validateIDNumber(value || '', identificationNumberType!, required);
};

const validatePhoneNumberVariable: VariableValueValidator = (variableConfiguration, value) => {
  const { variable, required } = variableConfiguration;
  const { dataType, stringFormat } = variable;

  if (dataType !== 'String' || stringFormat !== 'PhoneNumber') {
    return true;
  }

  if (!isOptionalStringVariableValue(value)) {
    return false;
  }

  return !validatePhoneNumber(value || '', required);
};

const validationRequirements: VariableValueValidator[] = [
  validateRequiredField,
  validateEmailVariable,
  validateIDNumberVariable,
  validatePhoneNumberVariable,
];

export const validateConfigurableFormData = (data: FormLayoutData, layoutItems: Array<VariableConfiguration>) => {
  return layoutItems.every((layoutItem) =>
    validationRequirements.every((isValid) => isValid(layoutItem, data[layoutItem.variable.systemName])),
  );
};
