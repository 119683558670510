import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import RightSidePopupLayout from 'MainLayout/RightSidePopupLayout';
import styles from './VariablesRightSidePopupLayout.module.scss';

export interface VariablesRightSidePopupLayoutProps {
  className?: string;
}

const VariablesRightSidePopupLayout = ({
  children,
  className,
}: PropsWithChildren<VariablesRightSidePopupLayoutProps>) => (
  <RightSidePopupLayout className={clsx(styles.rightSidePopupLayout, className)} useOffset>
    {children}
  </RightSidePopupLayout>
);

export default VariablesRightSidePopupLayout;
