import { AnyAction } from 'redux';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { VariablesListActionType } from './ActionTypes';
import { Variable } from 'Variables/VariablesTypes';

export interface GetListVariablesAction extends AnyAction {
  type: VariablesListActionType.GetListVariables;
  payload: {
    search: string;
  };
}

export interface GetListVariablesSuccessAction {
  type: VariablesListActionType.GetListVariablesSuccess;
  payload: TableViewData<Variable> & { search: string };
}

export interface OpenCreateVariablePopupAction {
  type: VariablesListActionType.OpenCreateVariablePopup;
}

export interface CloseCreateVariablePopupAction {
  type: VariablesListActionType.CloseCreateVariablePopup;
}

export interface SetSearchInputValueAction {
  type: VariablesListActionType.SetSearchInputValue;
  payload: string;
}

export interface ResetVariablesListAction {
  type: VariablesListActionType.ResetVariablesList;
}

export const openCreateVariablePopup = (): OpenCreateVariablePopupAction => ({
  type: VariablesListActionType.OpenCreateVariablePopup,
});

export const closeCreateVariablePopup = (): CloseCreateVariablePopupAction => ({
  type: VariablesListActionType.CloseCreateVariablePopup,
});

export const getListVariables = (search: string): GetListVariablesAction => ({
  type: VariablesListActionType.GetListVariables,
  payload: {
    search,
  },
});

export const getListVariablesSuccess = (
  data: TableViewData<Variable> & { search: string },
): GetListVariablesSuccessAction => ({
  type: VariablesListActionType.GetListVariablesSuccess,
  payload: data,
});

export const setSearchInputValue = (search: string): SetSearchInputValueAction => ({
  type: VariablesListActionType.SetSearchInputValue,
  payload: search,
});

export const resetVariablesList = (): ResetVariablesListAction => ({
  type: VariablesListActionType.ResetVariablesList,
});

export const getListVariablesFailure = FailedActionCreator(VariablesListActionType.GetListVariablesFailure);
