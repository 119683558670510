import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { ProcessAutomationActionType } from './ActionTypes';
import { getAutomatedProcesses, SelectCategoryAction } from './ActionCreator';

const isSelectCategoryAction = (action: AnyAction): action is SelectCategoryAction =>
  action.type === ProcessAutomationActionType.SelectCategory;

const SelectCategoryMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSelectCategoryAction(action)) {
    const { loanOriginationSystemAutomatedProcesses } = getState();
    const { page, itemsPerPage, group, category } = loanOriginationSystemAutomatedProcesses;

    dispatch(getAutomatedProcesses({ page, itemsPerPage, group, category }));
  }

  return result;
};

export default SelectCategoryMiddleware;
