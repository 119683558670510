import React, { FC, ChangeEvent, useState, useEffect } from 'react';

import styles from './AddNewCompanyUser.module.scss';
import { CloseButtonImage } from 'static/images';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import { AddNewUserParams } from 'AddNewCompanyUser/AddNewCompanyUserTypes';
import { validateEmail } from 'utils/validation/validation';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import PaymentRestrictionMessage from 'components/PaymentRestrictionMessage';
import { getOrganizationSeats } from 'CompanyInformation/CompanyInformationActionCreator';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import AddNewCompanyUserSkeleton from './AddNewCompanyUserSkeleton';
import { AppRoutes } from 'routes/RouteService';

const typeList = [
  { value: 'owner', name: 'Owner' },
  { value: 'admin', name: 'Admin' },
  { value: 'user', name: 'User' },
];

interface AddNewCompanyUser {
  addNewUser: (userInfo: AddNewUserParams) => Promise<unknown>;
  onClose: () => void;
  maxSeats: number;
  occupiedSeats: number;
  isSeatsLoading: boolean;
}

const AddNewCompanyUser: FC<AddNewCompanyUser> = ({ addNewUser, onClose, maxSeats, occupiedSeats, isSeatsLoading }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [type, setType] = useState('');
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);

  const [usernameError, setUsernameError] = useState('');

  useEffect(() => {
    dispatch(getOrganizationSeats());
  }, []);

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUsernameError('');
    setUsername(e.target.value);
  };

  const handleTypeChange = ({ value }: Option) => {
    setType(value);
  };

  const isUserInfoEmpty = !(firstName && lastName && username && type);
  const isSeatsAreFull = maxSeats <= occupiedSeats;

  const handleAddNewUser = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const usernameErrorMessage = validateEmail(username);
    if (usernameErrorMessage) {
      setUsernameError(usernameErrorMessage);
      return;
    }
    setIsAddingInProgress(true);
    try {
      await addNewUser({ firstName, lastName, username, type });
      onClose();
    } finally {
      setIsAddingInProgress(false);
    }
  };

  const paymentMessageDescription = (
    <p>
      You are currently using{' '}
      <span className={styles.seatsCount}>
        {occupiedSeats} of {maxSeats}
      </span>{' '}
      purchased seats. Please add more seats to continue.
    </p>
  );

  const renderBody = () => {
    return (
      <>
        {isSeatsAreFull && (
          <CSSTransition
            in
            appear
            timeout={0}
            classNames={{
              enter: styles.restrictionMessageEnter,
              enterDone: styles.restrictionMessageEnterActive,
              appear: styles.restrictionMessageEnter,
              appearDone: styles.restrictionMessageEnterActive,
            }}
          >
            <PaymentRestrictionMessage
              title="Your Seats Are Full"
              description={paymentMessageDescription}
              textButton="Add Seats"
              onButtonClick={() => window.open(AppRoutes.CustomerPortal)}
              className={styles.restrictionMessage}
              innerContainerClassName={styles.restrictionMessageInnerContainer}
            />
          </CSSTransition>
        )}
        <TextInput
          type="text"
          labelTitle="First Name"
          onChange={handleFirstNameChange}
          value={firstName}
          disabled={isSeatsAreFull}
        />
        <TextInput
          type="text"
          labelTitle="Last Name"
          onChange={handleLastNameChange}
          value={lastName}
          disabled={isSeatsAreFull}
        />
        <TextInput
          type="text"
          labelTitle="Email"
          onChange={handleUsernameChange}
          errorMessage={usernameError}
          value={username}
          disabled={isSeatsAreFull}
        />
        <AutoCompletion
          labelTitle="Permissions Type"
          onChange={handleTypeChange}
          value={type}
          options={typeList}
          disabled={isSeatsAreFull}
        />
        <Button
          kind="primary"
          size="form"
          onClick={handleAddNewUser}
          disabled={isUserInfoEmpty || isSeatsAreFull}
          isLoading={isAddingInProgress}
        >
          Add User
        </Button>
      </>
    );
  };

  return (
    <div className={styles.addNewUser}>
      <div className={styles.addNewUser__header}>
        <h2>Add User</h2>
        <button type="button" onClick={onClose}>
          <CloseButtonImage />
        </button>
      </div>
      <div className={styles.addNewUser__content}>{isSeatsLoading ? <AddNewCompanyUserSkeleton /> : renderBody()}</div>
    </div>
  );
};

export default AddNewCompanyUser;
