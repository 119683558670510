import React, { FC } from 'react';
import TableBody from 'components/Table/TableBody';
import Table from 'components/Table';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import SIZES from './tableColumnSize';
import styles from 'components/DigifiApi/DigifiApi.module.scss';
import { SkeletonRectangle } from 'components/Skeleton';

const DEFAULT_SKELETON_ITEMS_LENGTH = 4;

interface CredentialsTableProps {
  clientId: string;
  clientSecret1: string;
  clientPublicKey: string;
  clientSecret2: string;
}

const CredentialsTable: FC<CredentialsTableProps> = ({ clientId, clientSecret1, clientSecret2, clientPublicKey }) => {
  const isCredentialsLoaded = Boolean(clientId && clientSecret1 && clientSecret2 && clientPublicKey);

  const renderTableBody = () => {
    return (
      <>
        <TableRow disableHover>
          <TableBodyCell width={SIZES.CREDENTIAL_NAME}>
            <p className={styles.credentialsName}>Client ID (client_id)</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.CREDENTIAL_VALUE}>
            <p>{clientId}</p>
          </TableBodyCell>
        </TableRow>
        <TableRow disableHover>
          <TableBodyCell width={SIZES.CREDENTIAL_NAME}>
            <p className={styles.credentialsName}>Client Secret 1 (client_secret)</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.CREDENTIAL_VALUE}>
            <p>{clientSecret1}</p>
          </TableBodyCell>
        </TableRow>
        <TableRow disableHover>
          <TableBodyCell width={SIZES.CREDENTIAL_NAME}>
            <p className={styles.credentialsName}>Client Public Key (client_public_key)</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.CREDENTIAL_VALUE}>
            <p>{clientPublicKey}</p>
          </TableBodyCell>
        </TableRow>
        <TableRow disableHover>
          <TableBodyCell width={SIZES.CREDENTIAL_NAME}>
            <p className={styles.credentialsName}>Client Secret 2 (client_secret)</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.CREDENTIAL_VALUE}>
            <p>{clientSecret2}</p>
          </TableBodyCell>
        </TableRow>
      </>
    );
  };

  const renderSkeleton = () => {
    return new Array(DEFAULT_SKELETON_ITEMS_LENGTH).fill(null).map((item, index) => (
      <TableRow disableHover>
        <TableBodyCell width={SIZES.CREDENTIAL_NAME}>
          <SkeletonRectangle key={index} width="80%" height="16px" color="primary6" />
        </TableBodyCell>
        <TableBodyCell width={SIZES.CREDENTIAL_VALUE}>
          <SkeletonRectangle key={index} width="40%" height="16px" color="primary6" />
        </TableBodyCell>
      </TableRow>
    ));
  };

  return (
    <Table>
      <TableBody className={styles.credentialsTable}>
        {isCredentialsLoaded ? renderTableBody() : renderSkeleton()}
      </TableBody>
    </Table>
  );
};

export default CredentialsTable;
