import { useState } from 'react';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationFormPage, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { TabSwitchOption } from 'components/TabSwitch';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';

const useTabs = (
  applicationData: Application | null,
  product: Product | null,
  initialStep: EditApplicationStep,
): [TabSwitchOption[], TabSwitchOption, (tab: TabSwitchOption) => void, boolean, boolean] => {
  const [isAddingCoborrower, setIsAddingCoborrower] = useState(false);
  const [isAddingIntermediary, setIsAddingIntermediary] = useState(false);

  const tabs: TabSwitchOption[] = [
    { label: EditApplicationStep.Borrower, id: 'borrower' },
    { label: EditApplicationStep.CoBorrower, id: 'co-borrower' },
    { label: EditApplicationStep.Intermediary, id: 'intermediary' },
    { label: EditApplicationStep.ApplicationDetails, id: 'application-details' },
    { label: EditApplicationStep.AddCoborrower, id: 'add-coborrower', type: 'button', onClick: onAddCoborrower },
    { label: EditApplicationStep.AddIntermediary, id: 'add-intermediary', type: 'button', onClick: onAddIntermediary },
  ];

  const [, coborrowerStep, intermediaryStep] = tabs;

  const [selectedTab, setSelectedTab] = useState(tabs.find(({ label }) => label === initialStep)!);

  const isAddCoBorrowerAllowed = product?.settings.applicationFormPages.includes(ApplicationFormPage.CoBorrower);
  const isAddIntermediaryAllowed = product?.settings.applicationFormPages.includes(ApplicationFormPage.Intermediary);

  const filteredTabs = tabs.filter(
    (tab) =>
      tab.label === EditApplicationStep.Borrower ||
      (tab.label === EditApplicationStep.CoBorrower && (isAddingCoborrower || applicationData?.coborrowerId)) ||
      (tab.label === EditApplicationStep.Intermediary && (isAddingIntermediary || applicationData?.intermediaryId)) ||
      tab.label === EditApplicationStep.ApplicationDetails ||
      (tab.label === EditApplicationStep.AddCoborrower &&
        !isAddingCoborrower &&
        !applicationData?.coborrowerId &&
        isAddCoBorrowerAllowed) ||
      (tab.label === EditApplicationStep.AddIntermediary &&
        !isAddingIntermediary &&
        !applicationData?.intermediaryId &&
        isAddIntermediaryAllowed),
  );

  function onAddCoborrower() {
    if (isAddingIntermediary) {
      return;
    }

    setIsAddingCoborrower(true);
    setSelectedTab(coborrowerStep);
  }

  function onAddIntermediary() {
    if (isAddingCoborrower) {
      return;
    }

    setIsAddingIntermediary(true);
    setSelectedTab(intermediaryStep);
  }

  return [filteredTabs, selectedTab, setSelectedTab, isAddingCoborrower, isAddingIntermediary];
};

export default useTabs;
