import React, { FC, useState } from 'react';
import styles from './ApplicationNoteForm.module.scss';
import Button from 'components/Button';
import { ApplicationNotePopUpType } from 'LoanOriginationSystemApplicationPage/Notes/Types';

interface ApplicationNoteFormProps {
  onSave: (note: string) => void;
  isLoading: boolean;
  popUpType: ApplicationNotePopUpType;
  note?: string;
}

const MAX_NOTE_LENGTH = 1000;

const ApplicationNoteForm: FC<ApplicationNoteFormProps> = ({ note, isLoading, onSave, popUpType }) => {
  const [noteText, setNoteText] = useState<string | undefined | null>(
    popUpType === ApplicationNotePopUpType.Update ? note : '',
  );
  const isUpdate = !!note;
  const buttonText = isUpdate ? 'Update Note' : 'Add Note';

  return (
    <section className={styles.applicationForm}>
      <p>Content</p>
      <textarea
        name="note"
        id="note"
        value={noteText || ''}
        onChange={({ target }) => setNoteText(target.value)}
        maxLength={MAX_NOTE_LENGTH}
      />
      <Button
        isLoading={isLoading}
        disabled={!noteText || note === noteText}
        onClick={() => noteText && onSave(noteText)}
        size="form"
        className={styles.submitButton}
        kind="primary"
      >
        {buttonText}
      </Button>
    </section>
  );
};

export default ApplicationNoteForm;
