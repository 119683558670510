import { AnyAction } from 'redux';
import { ApplicationsSortingType, TableViewData } from 'api/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemBorrowerDetailsActionType } from './ActionTypes';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetBorrowerAction extends AnyAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrower;
  payload: string;
}

export interface GetBorrowerApplicationsAction extends AnyAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplications;
  payload: {
    id: string;
    sortingType: ApplicationsSortingType;
  };
}

export interface ResetBorrowerDetailsAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.ResetBorrowersDetails;
}

export interface GetBorrowerSuccessAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerSuccess;
  payload: Borrower;
}

export interface GetBorrowerApplicationsSuccessAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplicationsSuccess;
  payload: TableViewData<Application>;
}

export interface SortBorrowerApplicationsAction {
  type: LoanOriginationSystemBorrowerDetailsActionType.SortBorrowerApplications;
  payload: {
    borrowerId: string;
    sortingType: ApplicationsSortingType;
  };
}

export const getBorrower = (id: string): GetBorrowerAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrower,
  payload: id,
});

export const getBorrowerSuccess = (data: Borrower): GetBorrowerSuccessAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerSuccess,
  payload: data,
});

export const getBorrowerApplications = (
  id: string,
  sortingType: ApplicationsSortingType,
): GetBorrowerApplicationsAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplications,
  payload: {
    id,
    sortingType,
  },
});

export const getBorrowerApplicationsSuccess = (
  data: TableViewData<Application>,
): GetBorrowerApplicationsSuccessAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplicationsSuccess,
  payload: data,
});

export const sortBorrowerApplications = (
  borrowerId: string,
  sortingType: ApplicationsSortingType,
): SortBorrowerApplicationsAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.SortBorrowerApplications,
  payload: {
    borrowerId,
    sortingType,
  },
});

export const resetBorrowerDetails = (): ResetBorrowerDetailsAction => ({
  type: LoanOriginationSystemBorrowerDetailsActionType.ResetBorrowersDetails,
});

export const getBorrowerFailure = FailedActionCreator(
  LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerFailure,
);

export const getBorrowerApplicationsFailure = FailedActionCreator(
  LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplicationsFailure,
);
