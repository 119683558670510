/* eslint-disable prefer-template */
import { getNumberFromString } from 'utils/masks/maskNumberValue';

export const removeNonNumericChars = (value: string): string => {
  const firstDotPosition = value.indexOf('.');
  if (firstDotPosition === -1) {
    return getNumberFromString(value);
  }
  return (
    getNumberFromString(value.slice(0, firstDotPosition)) +
    value[firstDotPosition] +
    getNumberFromString(value.slice(firstDotPosition + 1))
  );
};

export const DecimalPartLimiter = (maxDecimalPartLength: number) => (value: string): string => {
  const firstDotPosition = value.indexOf('.');
  if (firstDotPosition === -1) {
    return value;
  }
  const decimalPartStartIndex = firstDotPosition + 1;
  return (
    value.slice(0, decimalPartStartIndex) +
    value.slice(decimalPartStartIndex, decimalPartStartIndex + maxDecimalPartLength)
  );
};
