const prolongRequest = <Response>(fn: (...args: any[]) => Promise<Response>, time: number) => {
  return async (...args: any[]) => {
    const [promiseResult] = await Promise.allSettled([
      fn(...args),
      new Promise((resolve) => setTimeout(() => resolve(), time)),
    ]);

    if (promiseResult.status === 'rejected') {
      throw promiseResult.reason;
    }

    return promiseResult.value;
  };
};

export default prolongRequest;
