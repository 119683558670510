import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  UPDATE_SCORING_RULE_SEGMENT,
  UPDATE_SCORING_RULE_SEGMENT_FAILURE,
  UPDATE_SCORING_RULE_SEGMENT_SUCCESS,
} from './ActionTypes';
import { CompletedScoringModelRequestData, UpdateScoringRuleSegmentAction } from './Types';

export const updateScoringRuleSegment = (
  payload: CompletedScoringModelRequestData,
): UpdateScoringRuleSegmentAction => ({
  type: UPDATE_SCORING_RULE_SEGMENT,
  payload,
});

export const updateScoringRuleSegmentSuccess = (payload: CompletedScoringModelRequestData) => ({
  type: UPDATE_SCORING_RULE_SEGMENT_SUCCESS,
  payload,
});

export const updateScoringRuleSegmentFailure = FailedActionCreator(UPDATE_SCORING_RULE_SEGMENT_FAILURE);
