import UnauthorizedMiddleware from './UnauthorizedMiddleware';
import emitSessionInfo from 'api/ResponseHandlers/EmitSessionInfo';

interface ResponseHandler {
  (response: Response): void;
}

const responseHandlers: ResponseHandler[] = [
  emitSessionInfo,
  UnauthorizedMiddleware,
];

export default responseHandlers;
