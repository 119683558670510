import { AnyAction } from 'redux';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ESignTemplateFilterStatus } from 'api/Core/ESignTemplatesApi';
import { DateRange } from 'api/LoanOriginationSystem/Types';
import { DocuSignFiltersActionType } from './ActionTypes';

export interface ChangeFiltersParams {
  status: ESignTemplateFilterStatus | null;
  createdDateRange: DateRange;
  updatedDateRange: DateRange;
}

export interface SetSearchInputValueAction extends AnyAction {
  type: DocuSignFiltersActionType.SetSearchInputValue;
  payload: {
    search: string;
    integrationId?: string;
  };
}

export interface ChangeFiltersAction extends AnyAction {
  type: DocuSignFiltersActionType.ChangeFilters;
  payload: {
    params: ChangeFiltersParams;
    integrationId?: string;
  };
}

export interface ResetAllFiltersAction extends AnyAction {
  type: DocuSignFiltersActionType.ResetAllFilters;
  payload: {
    integrationId?: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: DocuSignFiltersActionType.ToggleFiltersPopup;
}

export interface FilterTemplatesByMembersAction extends AnyAction {
  type: DocuSignFiltersActionType.FilterTemplatesByMembers;
  payload: {
    members: UserInfo[];
    integrationId?: string;
  };
}

export const setSearchInputValue = (search: string, integrationId?: string): SetSearchInputValueAction => ({
  type: DocuSignFiltersActionType.SetSearchInputValue,
  payload: {
    search,
    integrationId,
  },
});

export const changeFilters = (params: ChangeFiltersParams, integrationId?: string): ChangeFiltersAction => ({
  type: DocuSignFiltersActionType.ChangeFilters,
  payload: {
    integrationId,
    params,
  },
});

export const resetAllFilters = (integrationId?: string): ResetAllFiltersAction => ({
  type: DocuSignFiltersActionType.ResetAllFilters,
  payload: {
    integrationId,
  },
});

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => ({
  type: DocuSignFiltersActionType.ToggleFiltersPopup,
});

export const filterTemplatesByMembers = (
  members: UserInfo[],
  integrationId?: string,
): FilterTemplatesByMembersAction => ({
  type: DocuSignFiltersActionType.FilterTemplatesByMembers,
  payload: {
    integrationId,
    members,
  },
});
