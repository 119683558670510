import { Middleware } from 'redux';

import { ACTION_TYPE_VERIFY_PASSWORD_GENERATE_FIRST_SECRET_REQUEST } from './ActionTypes';
import {
  VerifyPasswordError,
  VerifyPasswordSuccess,
  VerifyPasswordRequestAction,
} from './GenerateFirstSecretActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { IPasswordConfirmationApi } from 'api/Core/PasswordConfirmationApi';

const VerifyPasswordMiddleware: (api: IPasswordConfirmationApi) => Middleware = (api) =>
  Fetcher<void, VerifyPasswordRequestAction>(
    ACTION_TYPE_VERIFY_PASSWORD_GENERATE_FIRST_SECRET_REQUEST,
    VerifyPasswordSuccess,
    VerifyPasswordError,
    ({ payload }) => api.confirmPassword(payload),
  );

export default VerifyPasswordMiddleware;
