export const GET_APPLICATION_DATA = 'GET_APPLICATION_DATA';
export const GET_APPLICATION_DATA_SUCCESS = 'GET_APPLICATION_DATA_SUCCESS';
export const GET_APPLICATION_DATA_FAILED = 'GET_APPLICATION_DATA_FAILED';
export const OPEN_CHANGE_APPLICATION_STATUS_POPUP = 'OPEN_CHANGE_APPLICATION_STATUS_POPUP';
export const CLOSE_CHANGE_APPLICATION_STATUS_POPUP = 'CANCEL_APPLICATION_STATUS_CHANGE';
export const UPDATE_APPLICATION = 'UPDATE_APPLICATION';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';

export enum ApplicationPageActionType {
  UpdateApplicationVariables = 'loanOriginationSystemApplicationPage/updateApplicationVariables',
  UpdateApplicationVariablesSuccess = 'loanOriginationSystemApplicationPage/updateApplicationVariablesSuccess',
  UpdateApplicationVariablesFailure = 'loanOriginationSystemApplicationPage/updateApplicationVariablesFailure',
  GetApplicationVariables = 'loanOriginationSystemApplicationPage/getApplicationVariables',
  GetApplicationVariablesSuccess = 'loanOriginationSystemApplicationPage/getApplicationVariablesSuccess',
  GetApplicationVariablesFailed = 'loanOriginationSystemApplicationPage/getApplicationVariablesFailed',
  DeleteApplicationCoborrower = 'loanOriginationSystemApplicationPage/deleteApplicationCoborrower',
  DeleteApplicationCoborrowerSuccess = 'loanOriginationSystemApplicationPage/deleteApplicationCoborrowerSuccess',
  DeleteApplicationCoborrowerFailed = 'loanOriginationSystemApplicationPage/deleteApplicationCoborrowerFailed',
  DeleteApplicationIntermediary = 'loanOriginationSystemApplicationPage/deleteApplicationIntermediary',
  DeleteApplicationIntermediarySuccess = 'loanOriginationSystemApplicationPage/deleteApplicationIntermediarySuccess',
  DeleteApplicationIntermediaryFailed = 'loanOriginationSystemApplicationPage/deleteApplicationIntermediaryFailed',
  AddApplicationCoborrower = 'loanOriginationSystemApplicationPage/addApplicationCoborrower',
  AddApplicationCoborrowerSuccess = 'loanOriginationSystemApplicationPage/addApplicationCoborrowerSuccess',
  AddApplicationCoborrowerFailed = 'loanOriginationSystemApplicationPage/addApplicationCoborrowerFailed',
  AddApplicationIntermediary = 'loanOriginationSystemApplicationPage/addApplicationIntermediary',
  AddApplicationIntermediarySuccess = 'loanOriginationSystemApplicationPage/addApplicationIntermediarySuccess',
  AddApplicationIntermediaryFailed = 'loanOriginationSystemApplicationPage/addApplicationIntermediaryFailed',
  SortApplicationVariables = 'loanOriginationSystemApplicationPage/sortApplicationVariables',
  UpdateApplicationTeamMembers = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembers',
  UpdateApplicationTeamMembersSuccess = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembersSuccess',
  UpdateApplicationTeamMembersFailure = 'loanOriginationSystemApplicationPage/updateApplicationTeamMembersFailure',
  AddApplicationLabel = 'loanOriginationSystemApplicationPage/addApplicationLabel',
  AddApplicationLabelSuccess = 'loanOriginationSystemApplicationPage/addApplicationLabelSuccess',
  AddApplicationLabelFailure = 'loanOriginationSystemApplicationPage/addApplicationLabelFailure',
  DeleteApplicationLabel = 'loanOriginationSystemApplicationPage/deleteApplicationLabel',
  DeleteApplicationLabelSuccess = 'loanOriginationSystemApplicationPage/deleteApplicationLabelSuccess',
  DeleteApplicationLabelFailure = 'loanOriginationSystemApplicationPage/deleteApplicationLabelFailure',
  SetLabelToDelete = 'loanOriginationSystemApplicationPage/setLabelToDelete',
  ClearApplicationData = 'loanOriginationSystemApplicationPage/ClearApplicationData',
}
