import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemOrganizationActionType } from './ActionTypes';
import { GetOrganizationUsersAction, getOrganizationUsersFailure, getOrganizationUsersSuccess } from './ActionCreator';
import { UserApi } from 'api/Core/UserApi';
import mapAccountToApplicationMember from './mapAccountToApplicationMember';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ReduxState } from 'types/redux';

const GetOrganizationUsersMiddleware = (api: UserApi) =>
  Fetcher<UserInfo[], GetOrganizationUsersAction, ReduxState>(
    LoanOriginationSystemOrganizationActionType.GetOrganizationUsers,
    getOrganizationUsersSuccess,
    getOrganizationUsersFailure,
    async (action, { organizationUsers }) => {
      const {
        page,
        itemsPerPage,
        showInactive,
        searchInputValue,
        permissionType,
        phoneAuthentication,
        emailVerified,
        sortingType,
      } = organizationUsers;

      const { accountDetails, disablePagination } = action.payload;
      const currentUser = mapAccountToApplicationMember(accountDetails);

      // TODO always fetch users, move data processing into a selector
      if (!currentUser) {
        return [];
      }

      const { items } = await api.getAll(
        disablePagination
          ? undefined
          : {
              offset: page,
              count: itemsPerPage,
              search: searchInputValue,
              permissionType,
              phoneAuthentication,
              emailVerified,
              showInactive,
            },
        disablePagination ? undefined : sortingType,
      );

      const users = items.map((user) => ({
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        imageId: user.imageId,
        active: user.isActive,
      }));

      const otherUsers = users.filter((user) => user.id !== currentUser.id);

      return [currentUser, ...otherUsers];
    },
  );

export default GetOrganizationUsersMiddleware;
