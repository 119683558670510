import React, { FC } from 'react';
import clsx from 'clsx';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './TabSwitchSkeleton.module.scss';

export interface TabSwitchSkeletonProps {
  className?: string;
  itemsCount?: number;
}

const MIN_TAB_WIDTH = 74;
const MAX_TAB_WIDTH = 100;
const DEFAULT_TABS_LENGTH = 7;

const TabSwitchSkeleton: FC<TabSwitchSkeletonProps> = React.memo(({ itemsCount = DEFAULT_TABS_LENGTH, className }) => (
  <div className={clsx(styles.container, className)}>
    {new Array(itemsCount).fill(null).map((item, index) => (
      <SkeletonRectangle
        key={index}
        width={`${Math.floor(Math.random() * (MAX_TAB_WIDTH - MIN_TAB_WIDTH) + MIN_TAB_WIDTH)}px`}
        height="20px"
        color="primary6"
      />
    ))}
  </div>
));

export default TabSwitchSkeleton;
