import CoreSystemApi from 'api/Core/CoreSystemApi';

export enum EmailVerificationResult {
  EmailVerified,
  TokenExpired
}

export interface IEmailVerificationRestApi {
  verifyEmail(token: string): Promise<EmailVerificationResult>;
}

export default class EmailVerificationApi extends CoreSystemApi implements IEmailVerificationRestApi {
  protected resourceName = '/email-verifications';

  public async verifyEmail(verificationToken: string) {
    try {
      await this.fetch(this.resourceName, 'POST', {
        verificationToken,
      });
      return EmailVerificationResult.EmailVerified;
    } catch (error) {
      if (error.message === 'Token has expired.') {
        return EmailVerificationResult.TokenExpired;
      }
      throw error;
    }
  }
}
