import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationDataTabVariableConfiguration,
  ApplicationDataTabVariableConfigurationsApi,
} from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabVariableConfigurationsActionType } from './ActionTypes';
import {
  createApplicationDataTabVariableConfigurationSuccess,
  createApplicationDataTabVariableConfigurationFailure,
  CreateApplicationDataTabVariableConfigurationAction,
} from './ActionCreator';

const CreateApplicationDataTabVariableConfigurationMiddleware = (api: ApplicationDataTabVariableConfigurationsApi) =>
  Fetcher<ApplicationDataTabVariableConfiguration, CreateApplicationDataTabVariableConfigurationAction>(
    ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfiguration,
    createApplicationDataTabVariableConfigurationSuccess,
    createApplicationDataTabVariableConfigurationFailure,
    (action) => {
      return api.create({
        cardId: action.payload.cardId,
        column: action.payload.column,
        productId: action.payload.productId,
        position: action.payload.position,
        variableId: action.payload.variable.id,
      });
    },
  );

export default CreateApplicationDataTabVariableConfigurationMiddleware;
