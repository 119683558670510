import borrowerApplicationsPagination from 'components/LoanOriginationSystem/BorrowerDetails/pagination';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

const BorrowerApplicationsPaginationMiddleware = (api: LoanOriginationSystemBorrowersApi) => {
  return borrowerApplicationsPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getBorrowerApplications(
      params.borrowerId,
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
      params.sortingType,
    );

    return items;
  });
};

export default BorrowerApplicationsPaginationMiddleware;
