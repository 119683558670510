import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import {
  ApplicationHistoryFilterType,
  ApplicationHistorySortingType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import useStateReset from 'hooks/useStateReset';
import {
  changeFilterType,
  changeSelectedMembers,
  changeSortingType,
  getApplicationHistory,
} from 'LoanOriginationSystemApplicationPage/History/ActionCreator';
import { ApplicationHistoryActionType } from 'LoanOriginationSystemApplicationPage/History/ActionTypes';
import Header from './Header';
import Body from './Body';
import pagination from './pagination';

export interface HistoryTabProps {
  applicationId: string;
}

const HistoryTab = ({ applicationId }: HistoryTabProps) => {
  const dispatch = useDispatch();
  const organizationMembers = useOrganizationMembers();

  const selectedMembers = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.selectedMembers,
  );
  const filterType = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage.history.filterType);
  const sortingType = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.history.sortingType,
  );

  useStateReset(ApplicationHistoryActionType.ResetState);

  const applicationHistoryResults = pagination.usePaginatedItems({
    applicationId,
    selectedMembers,
    filterType,
    sortingType,
  });
  const paginationProps = pagination.usePagination({ applicationId, selectedMembers, filterType, sortingType });

  useEffect(() => {
    dispatch(getApplicationHistory({ applicationId, selectedMembers, filterType }, sortingType));
  }, []);

  const handleSortingTypeChange = (newSortingType: ApplicationHistorySortingType) =>
    dispatch(changeSortingType(applicationId, newSortingType));
  const handleSelectedMembersChange = (members: UserInfo[]) => dispatch(changeSelectedMembers(applicationId, members));
  const handleFilterTypeChange = (newFilterType: ApplicationHistoryFilterType) =>
    dispatch(changeFilterType(applicationId, newFilterType));

  return (
    <div>
      <Header
        selectedMembers={selectedMembers}
        members={organizationMembers}
        sortingType={sortingType}
        filterType={filterType}
        onSortingTypeChange={handleSortingTypeChange}
        onSelectedMembersChange={handleSelectedMembersChange}
        onFilterTypeChange={handleFilterTypeChange}
      />
      <Body historyRecords={applicationHistoryResults} paginationProps={paginationProps} />
    </div>
  );
};

export default HistoryTab;
