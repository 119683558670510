import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableRow from 'components/Table/TableRow';
import React from 'react';
import styles from './ScrollTablePart.module.scss';
import { getTotal } from './utils';
import clsx from 'clsx';
import { ReportingMonthInfo } from 'LoanOriginationSystemReporting/Applications/Types';
import { formatDate } from 'components/LoanOriginationSystem/ReportingDashboard/utils';
import maskNumberValue from 'utils/masks/maskNumberValue';

interface ScrollTablePartProps {
  reportingEntitiesMap: Record<string, ReportingMonthInfo>;
  statuses: ApplicationStatus[];
}

const renderColumnPart = (data: number[]) => {
  const formattedData = data.map((value) => {
    return maskNumberValue(value);
  });

  const formattedTotalValue = maskNumberValue(getTotal(data));

  const renderColumnData = (item: string) => {
    return (
      <TableRow disableHover className={styles.dashedBorderBottom}>
        <TableBodyCell width={74} pixels>
          <p className={styles.value}>{item}</p>
        </TableBodyCell>
      </TableRow>
    );
  };

  return (
    <>
      {formattedData.map(renderColumnData)}
      <TableRow disableHover>
        <TableBodyCell width={74} pixels>
          <p className={clsx(styles.value, styles.total)}>{formattedTotalValue}</p>
        </TableBodyCell>
      </TableRow>
    </>
  );
};

const ScrollTablePart = ({ reportingEntitiesMap, statuses }: ScrollTablePartProps) => {
  const renderColumn = (key: string, index: number, array: string[]) => {
    const getDataByStatus = (type: string) => {
      return statuses.map((status) => {
        return reportingEntitiesMap[key][type][status.name] || 0;
      });
    };

    const lastElement = index === array.length - 1;

    return (
      <div className={clsx(styles.column, lastElement && styles.fullWidth)}>
        <TableHead>
          <TableHeadCell width={74} pixels>
            <p className={styles.value}>{formatDate(key)}</p>
          </TableHeadCell>
        </TableHead>
        {renderColumnPart(getDataByStatus('amount'))}
        {renderColumnPart(getDataByStatus('number'))}
      </div>
    );
  };

  return <div className={styles.container}>{Object.keys(reportingEntitiesMap).map(renderColumn)}</div>;
};

export default ScrollTablePart;
