import { DecisionEngineApi, OrderBy, VersionsListData } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { VersionsListDataActions } from 'VersionsViewData/ActionTypes';
import {
  GetVersionsListDataAction,
  getVersionsListDataFailure,
  getVersionsListDataSuccess,
} from 'VersionsViewData/Actions';

export const GetVersionsListDataMiddleware = (api: DecisionEngineApi) =>
  Fetcher<VersionsListData, GetVersionsListDataAction, ReduxState>(
    VersionsListDataActions.GetVersionsListData,
    getVersionsListDataSuccess,
    getVersionsListDataFailure,
    async (action, { versionsListData }) => {
      const {
        page,
        itemsPerPage,
        searchInputValue,
        showArchived,
        strategyName,
        sortingType,
        selectedUpdaters,
      } = versionsListData;

      const response = await api.getVersionsData(strategyName, {
        page,
        perPage: itemsPerPage,
        showArchived,
        searchInputValue,
        updaters: selectedUpdaters.map(({ id }) => id),
        sortBy: sortingType.field,
        orderBy: sortingType.ascending ? OrderBy.Asc : OrderBy.Desc,
      });

      return {
        ...response,
        searchInputValue,
        selectedUpdaters,
      };
    },
  );
