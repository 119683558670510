import React, { ChangeEvent, FC, useRef, useState } from 'react';
import ButtonWithLoadingState from 'components/ButtonWithLoadingState/ButtonWithLoadingState';
import validateResetPasswordForm, { ValidateResetPasswordFormResult } from './validateNewPasswordForm';
import PasswordInput from 'components/PasswordInput';
import TriggerEventOnEnterKeyDown from 'utils/TriggerEventOnEnterKeyDown';
import styles from './NewPasswordForm.module.scss';

interface NewPasswordFormProps {
  onSubmit: (password: string) => Promise<void>;
  buttonTitle: string;
}

const NewPasswordForm: FC<NewPasswordFormProps> = ({ onSubmit, buttonTitle }) => {
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<ValidateResetPasswordFormResult>({});

  const handleChangePasswordValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ confirmPassword: errors.confirmPassword });

    setPassword(e.target.value);
  };

  const handleChangeConfirmPasswordValue = (e: ChangeEvent<HTMLInputElement>) => {
    setErrors({ password: errors.password });

    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async () => {
    const validationResult = validateResetPasswordForm({ password, confirmPassword });

    setErrors(validationResult);

    if (Object.keys(validationResult).length === 0) {
      await onSubmit(password);
    }
  };

  const handlePasswordBlur = () => {
    const validationResult = validateResetPasswordForm({ password, confirmPassword });

    setErrors({ confirmPassword: errors.confirmPassword, password: validationResult.password });
  };

  const handleConfirmPasswordBlur = () => {
    const validationResult = validateResetPasswordForm({ password, confirmPassword });

    setErrors({ confirmPassword: validationResult.confirmPassword, password: errors.password });
  };

  const submitIsDisabled = Object.keys(validateResetPasswordForm({ password, confirmPassword })).length > 0;

  return (
    <div className={styles.container}>
      <PasswordInput
        labelTitle="New Password"
        onChange={handleChangePasswordValue}
        value={password}
        errorMessage={errors.password}
        containerClassName={styles.password}
        onBlur={handlePasswordBlur}
        onKeyDown={TriggerEventOnEnterKeyDown(confirmPasswordRef, 'focus')}
        autoFocus
      />
      <PasswordInput
        labelTitle="Confirm New Password"
        onChange={handleChangeConfirmPasswordValue}
        value={confirmPassword}
        errorMessage={errors.confirmPassword}
        ref={confirmPasswordRef}
        onBlur={handleConfirmPasswordBlur}
        onKeyDown={TriggerEventOnEnterKeyDown(submitButtonRef, 'click')}
      />
      <ButtonWithLoadingState
        kind="primary"
        size="form"
        onClick={handleSubmit}
        fullWidth
        className={styles.submitButton}
        disabled={submitIsDisabled}
        ref={submitButtonRef}
      >
        {buttonTitle}
      </ButtonWithLoadingState>
    </div>
  );
};

export default NewPasswordForm;
