import React, { FC } from 'react';
import Lottie from 'react-lottie';
import animationData from './animationData.json';
import styles from './LogoLoader.module.scss';

const LogoLoader: FC = () => {
  return (
    <div className={styles.container}>
      <Lottie options={{ animationData }} height={120} width={120} />
    </div>
  );
};

export default LogoLoader;
