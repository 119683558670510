import { SetSearchInputValueAction, SetFilterStatusesAction } from './ActionCreator';
import { LoanOriginationSystemProductsFiltersActionTypes } from './ActionTypes';
import { LoanOriginationSystemProductsFiltersState } from './Types';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';

export const initialState: LoanOriginationSystemProductsFiltersState = {
  search: '',
  statuses: [ProductStatus.Active, ProductStatus.Draft],
};

type LoanOriginationSystemProductsFiltersActions = SetSearchInputValueAction | SetFilterStatusesAction;

const loanOriginationSystemProductsFiltersReducer = (
  state: LoanOriginationSystemProductsFiltersState = initialState,
  action: LoanOriginationSystemProductsFiltersActions,
): LoanOriginationSystemProductsFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case LoanOriginationSystemProductsFiltersActionTypes.SetFilterStatuses: {
      return {
        ...state,
        statuses: action.payload.statuses,
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemProductsFiltersReducer;
