import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import ChangeApplicationStatusError from './ChangeApplicationStatusError';

export default class ApplicationStatusRulesValidationError extends ChangeApplicationStatusError {
  constructor(
    application: Application,
    status: ApplicationStatus,
    index: number | undefined,
    readonly statusRulesValidationResult: ApplicationStatusRulesValidationResult,
  ) {
    super('', application, status, index);
  }
}
