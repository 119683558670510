import React, { FC, ReactNode } from 'react';
import styles from './RuleBuilderDivider.module.scss';

interface RuleBuilderDividerProps {
  children: ReactNode;
}

const RuleBuilderDivider: FC<RuleBuilderDividerProps> = ({ children }) => (
  <div className={styles.titleContainer}>{children}</div>
);

export default RuleBuilderDivider;
