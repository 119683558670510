import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { CloseButtonImage, EmailImage, PhoneImage } from 'static/images';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { SkeletonRectangle } from 'components/Skeleton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import {
  updateBorrower,
  UpdateBorrowerSuccessActionOrigin,
} from 'LoanOriginationSystemBorrowers/EditBorrower/ActionCreator';
import { getBorrower } from 'LoanOriginationSystemBorrowerDetails/ActionCreator';
import { setBorrowerToUnlock } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import EditBorrower from './Tabs/EditBorrower/EditBorrower';
import BorrowerApplications from './Tabs/BorrowerApplications';
import styles from './BorrowerDetails.module.scss';
import ButtonRound from 'components/ButtonRound';
import { getMailtoURI } from 'Email/utils';
import BorrowerEmails from './Tabs/BorrowerEmails';
import { selectBorrowerViewTab } from 'LoanOriginationSystemBorrowers/ActionCreator';

interface BorrowerDetailsProps {
  id: string;
  onClose: () => void;
  setDataWasChanged: (changed: boolean) => void;
}

export enum BorrowerDetailsTab {
  Profile = 'Profile',
  Applications = 'Applications',
  Emails = 'Emails',
}

const TABS: TabSwitchOption[] = [
  { label: BorrowerDetailsTab.Profile, id: BorrowerDetailsTab.Profile },
  { label: BorrowerDetailsTab.Applications, id: BorrowerDetailsTab.Applications },
  { label: BorrowerDetailsTab.Emails, id: BorrowerDetailsTab.Emails },
];

const EMAIL_BUTTON_TOOLTIP = 'Email is not provided';
const PHONE_BUTTON_TOOLTIP = 'Phone number is not provided';

const BorrowerDetails = ({ id, onClose, setDataWasChanged }: BorrowerDetailsProps) => {
  const dispatch = useDispatch();

  const [borrowerData, setBorrowerData] = useState({});
  const { editBorrower, contextualViewTab } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);
  const { borrower } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowerDetails);

  useEffect(() => {
    if (!borrower) {
      dispatch(getBorrower(id));

      return;
    }

    setBorrowerData(borrower.variables);
  }, [borrower]);

  const selectTab = (tab: BorrowerDetailsTab) => {
    dispatch(selectBorrowerViewTab(tab));
  };

  useEffect(() => {
    return () => {
      selectTab(BorrowerDetailsTab.Profile);
    };
  }, []);

  const handleBorrowerEdit = () => {
    const action = setActionOrigin(updateBorrower(id, borrowerData), UpdateBorrowerSuccessActionOrigin.BorrowerDetails);

    dispatch(action);
  };

  const handleBorrowerUnlock = () => {
    if (!borrower) {
      return;
    }

    dispatch(setBorrowerToUnlock(borrower));
  };

  const handleSendEmail = () => {
    window.location.href = getMailtoURI({
      to: borrower!.variables.borrower_email ? borrower!.variables.borrower_email.toString() : '',
    });
  };

  const handlePhoneCall = () => {
    window.location.href = `tel:${borrower!.variables.borrower_phone}`;
  };

  const renderHeader = () => {
    if (!borrower) {
      return <SkeletonRectangle width="40%" height="44px" color="primary6" />;
    }

    const name = getBorrowerFullName(borrower.type, borrower.variables);

    return (
      <div className={styles.titleContainer}>
        <h2>{name}</h2>
        <ButtonRound
          className={styles.emailButton}
          disabled={!borrower.variables.borrower_email}
          tooltip={!borrower.variables.borrower_email ? EMAIL_BUTTON_TOOLTIP : ''}
          tooltipPosition="bottom-end"
          onClick={handleSendEmail}
        >
          <EmailImage />
        </ButtonRound>
        <ButtonRound
          disabled={!borrower.variables.borrower_phone}
          tooltip={!borrower.variables.borrower_phone ? PHONE_BUTTON_TOOLTIP : ''}
          tooltipPosition="bottom-start"
          onClick={handlePhoneCall}
        >
          <PhoneImage />
        </ButtonRound>
      </div>
    );
  };

  const renderTab = () => {
    if (contextualViewTab === BorrowerDetailsTab.Profile) {
      return (
        <EditBorrower
          borrower={borrower}
          borrowerFormData={borrowerData}
          setBorrowerFormData={setBorrowerData}
          setDataWasChanged={setDataWasChanged}
          onBorrowerUpdate={handleBorrowerEdit}
          isUpdating={editBorrower.isUpdatingInProgress}
          onBorrowerUnlock={handleBorrowerUnlock}
        />
      );
    }

    if (contextualViewTab === BorrowerDetailsTab.Applications) {
      return <BorrowerApplications borrowerId={id} />;
    }

    if (contextualViewTab === BorrowerDetailsTab.Emails && borrower) {
      return <BorrowerEmails borrowerId={id} borrowerEmail={borrower!.variables.borrower_email as string} />;
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{renderHeader()}</div>
        <div className={styles.formContainer}>
          <TabSwitch
            tabs={TABS}
            selectedTabId={contextualViewTab}
            onSelect={({ id: optionId }) => selectTab(optionId as BorrowerDetailsTab)}
          />
          {renderTab()}
        </div>
      </div>
      <CloseButtonImage className={styles.closeButton} onClick={onClose} />
    </div>
  );
};

export default BorrowerDetails;
