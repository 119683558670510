import React, { ReactElement, useEffect } from 'react';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import LabelsDetails from 'components/LoanOriginationSystem/LabelsDetails';
import useStateReset from 'hooks/useStateReset';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setSearchInputValue } from 'LoanOriginationSystemLabelsPage/Filters/ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from 'LoanOriginationSystemLabelsPage/ActionTypes';
import {
  closeDeleteLabelPopup,
  getLabels,
  sortLabels,
  openDeleteLabelPopup,
  deleteLabel,
  closeCreateLabelPopUp,
  openCreateLabelPopUp,
  createLabel,
  openUpdateLabelPopUp,
  closeUpdateLabelPopUp,
  getLabelInfo,
  updateLabel,
} from 'LoanOriginationSystemLabelsPage/ActionCreator';
import { LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import pagination, { LabelsPaginationParams } from 'components/LoanOriginationSystem/LabelsDetails/Pagination';
import CreateLabelPopUp from 'components/LoanOriginationSystem/LabelsDetails/CreateLabelPopUp';
import EditLabelPopUp from 'components/LoanOriginationSystem/LabelsDetails/EditLabelPopUp';
import NavigationLinkId from 'enums/NavigationLinkId';
import ConfirmPopup from 'components/ConfirmPopup';

const leftNav = makeLeftNavigation(NavigationLinkId.ApplicationLabels, ApplicationSectionName.LoanOriginationSystem);

const Labels = () => {
  const {
    sortingType,
    filters,
    labelIdToDelete,
    blockingActionInProgress,
    isCreateLabelPopUpOpen,
    labelIdToUpdate,
    labelsDetailedAttributes,
    isLoading,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemLabelsState);

  const params: LabelsPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
  };

  const labels = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);

  useStateReset(LoanOriginationSystemLabelsActionTypes.ResetState);

  useEffect(() => {
    if (labelIdToUpdate) {
      dispatch(getLabelInfo(labelIdToUpdate));
    }
  }, [isCreateLabelPopUpOpen, labelIdToUpdate]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLabels({ filters, sortingType }));
  }, []);

  const onSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const onSort = (field: LabelsSortingField, ascending: boolean) => {
    dispatch(sortLabels(field, ascending));
  };

  const isNoLabels = !labels.length && !filters.searchInputValue;

  const onDeletePopupClose = () => dispatch(closeDeleteLabelPopup());
  const onDeletePopupOpen = (id: string) => dispatch(openDeleteLabelPopup(id));
  const onDeleteLabel = () => dispatch(deleteLabel(labelIdToDelete!));

  const onCreatePopupOpen = () => dispatch(openCreateLabelPopUp());
  const onCreatePopupClose = () => {
    dispatch(closeCreateLabelPopUp());
  };
  const onCreateLabel = (name: string, color: string) => dispatch(createLabel(name, color));

  const onUpdatePopupOpen = (id: string) => dispatch(openUpdateLabelPopUp(id));
  const onUpdatePopupClose = () => {
    dispatch(closeUpdateLabelPopUp());
  };
  const onUpdateLabel = (name: string, color: string) => dispatch(updateLabel(labelIdToUpdate!, name, color));

  const getOverlay = (): ReactElement | null => {
    if (labelIdToDelete) {
      return (
        <ConfirmPopup
          title="Delete Label"
          message="Are you sure you want to remove this label?"
          confirmText="Yes, Delete Label"
          declineText="No, Go Back"
          onPopupClose={onDeletePopupClose}
          onConfirmClick={onDeleteLabel}
          loading={blockingActionInProgress}
        />
      );
    }
    if (isCreateLabelPopUpOpen) {
      return (
        <CreateLabelPopUp
          onPopupClose={onCreatePopupClose}
          onCreateLabel={onCreateLabel}
          blockingActionInProgress={blockingActionInProgress}
        />
      );
    }
    if (labelIdToUpdate) {
      const label = labelsDetailedAttributes[labelIdToUpdate];

      return (
        <EditLabelPopUp
          onPopupClose={onUpdatePopupClose}
          blockingActionInProgress={blockingActionInProgress}
          initialLabelName={label ? label.name : ''}
          initialLabelColor={label ? label.color.substring(1) : ''}
          onUpdateLabel={onUpdateLabel}
          isLoading={isLoading}
        />
      );
    }
    return null;
  };

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapperWithFooter>
        <PageContent>
          <LabelsDetails
            onSearch={onSearch}
            onSort={onSort}
            isNoLabels={isNoLabels}
            paginationProps={paginationProps}
            filters={filters}
            labels={labels}
            sortingType={sortingType}
            onDeletePopupOpen={onDeletePopupOpen}
            onCreatePopupOpen={onCreatePopupOpen}
            onUpdatePopupOpen={onUpdatePopupOpen}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Labels;
