import React from 'react';
import clsx from 'clsx';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import {
  companyProductTypeOptions,
  personProductTypeOptions,
} from 'components/LoanOriginationSystem/ProductBasicInfoForm/ProductTypeOptions';
import ProductStatusTag from 'components/LoanOriginationSystem/ProductConfiguration/ProductStatusTag';
import ProductRowActions from 'components/LoanOriginationSystem/ProductConfiguration/ProductRowActions';
import { RowActionsContainer } from 'components/RowActions';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import styles from './ProductsListItem.module.scss';

export interface ProductsListItemProps {
  product: Product;
  onDuplicate: (product: Product) => void;
  onChangeStatus: (product: Product, status: ProductStatus) => void;
  onProductSelect: (product: Product) => void;
}

const ProductsListItem = ({ product, onDuplicate, onChangeStatus, onProductSelect }: ProductsListItemProps) => {
  const handleProductSelect = () => {
    onProductSelect(product);
  };

  const productTypeOptions =
    product.borrowerType === BorrowerType.Person ? personProductTypeOptions : companyProductTypeOptions;
  const templateOption = productTypeOptions[product.type];
  const ProductTypeImage = templateOption.image;

  const handleDuplicate = () => {
    onDuplicate(product);
  };

  const handleChangeStatus = (status: ProductStatus) => {
    onChangeStatus(product, status);
  };

  return (
    <RowActionsContainer>
      <div
        className={clsx(styles.container, product.status === ProductStatus.Archived && styles.archivedContainer)}
        onClick={handleProductSelect}
      >
        <div className={styles.content}>
          <p className={styles.productType}>{templateOption.name}</p>
          <div className={styles.productNameContainer}>
            <span className={styles.productName}>{product.name}</span>
            <span className={styles.tag}>
              <ProductStatusTag status={product.status} />
            </span>
          </div>
          <div className={styles.productFooter}>
            <WithSystemApiUserAvatar className={styles.avatar} size={7.15} user={product.updatedBy} />
            <p>Updated {formatDate(product.updatedAt, DateTimeFormat.Short)}</p>
          </div>
        </div>
        <div className={styles.productTypeImage}>
          <ProductTypeImage />
        </div>
        <ProductRowActions
          className={styles.rowActionsContainer}
          productStatus={product.status}
          onDuplicate={handleDuplicate}
          onChangeStatus={handleChangeStatus}
        />
      </div>
    </RowActionsContainer>
  );
};

export default ProductsListItem;
