import { Middleware } from 'redux';

import { ACTION_TYPE_GENERATE_FIRST_SECRET_REQUEST } from './ActionTypes';
import { GenerateFirstSecretError, GenerateFirstSecretSuccess } from './GenerateFirstSecretActionCreator';

import AuthenticationAPIRest from 'api/Authentication';
import Fetcher from 'middlewares/Fetcher';

const GenerateFirstSecretMiddleware: (api: AuthenticationAPIRest) => Middleware = (api) =>
  Fetcher<void>(ACTION_TYPE_GENERATE_FIRST_SECRET_REQUEST, GenerateFirstSecretSuccess, GenerateFirstSecretError, () =>
    api.generateFirstSecret(),
  );

export default GenerateFirstSecretMiddleware;
