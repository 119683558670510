import { RuleData } from 'BranchInfo/Types';
import { RuleBuilderState } from 'components/RuleBuilder/Types';

export const getSerializedRules = (currentRule: RuleData) => {
  const { multipleRules = [], conditionOutput = [] } = currentRule || {};

  const serializedRules: RuleBuilderState[] = multipleRules.map((rule) => {
    const {
      sourceVariableId,
      selectedComparison,
      ruleId,
      firstOperandValue,
      firstOperandVariableId,
      secondOperandValue,
      secondOperandVariableId,
      firstOperandType,
      secondOperandType,
      firstOperandScript,
    } = rule;

    return {
      sourceVariableId,
      selectedComparison,
      ruleId,
      firstOperandValue: firstOperandScript || firstOperandValue,
      firstOperandVariableId,
      secondOperandValue,
      secondOperandVariableId,
      firstOperandType,
      secondOperandType,
    };
  });

  return { serializedRules, conditionOutput };
};
