import React, { FC, useRef } from 'react';

import styles from './LeftNavigationHeader.module.scss';

import { BurgerImage, DigiFiLogoImage, ContactUsImage } from 'static/images';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { NOT_FOUND_PAGE_SECTION } from 'MainLayout/utils';

interface LeftNavigationHeaderProps {
  isExpanded: boolean;
  toggleBurgerMenu: (anchor: SVGSVGElement) => void;
  currentProductTitle?: ApplicationSectionName | NOT_FOUND_PAGE_SECTION;
  handleClickOnLogo: (event: React.MouseEvent<SVGSVGElement>) => void;
}

const LeftNavigationHeader: FC<LeftNavigationHeaderProps> = ({
  isExpanded,
  toggleBurgerMenu,
  currentProductTitle,
  handleClickOnLogo,
}) => {
  const anchorRef = useRef<SVGSVGElement>(null);

  return (
    <div className={styles.headerContainer}>
      <div className={isExpanded ? styles.mainLogoContainerExpanded : styles.mainLogoContainerCollapsed}>
        <DigiFiLogoImage className={styles.mainLogo} onClick={handleClickOnLogo} />
        <a
          className={styles.contactUsIconContainer}
          href="https://www.digifi.io/contact-support"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ContactUsImage className={styles.contactUsIcon} onClick={() => {}} />
        </a>
      </div>
      <div className={styles.titleContainer} onClick={() => toggleBurgerMenu(anchorRef.current!)}>
        <BurgerImage className={styles.titleIcon} ref={anchorRef} />
        <div className={styles.titleText}>
          <h6>{currentProductTitle}</h6>
        </div>
      </div>
    </div>
  );
};

export default LeftNavigationHeader;
