import React from 'react';
import styles from './SkeletonDataTab.module.scss';
import { SkeletonRectangle, SkeletonText } from 'components/Skeleton';

const SkeletonDataTab = () => {
  const renderColumnRow = (index: number) => {
    return (
      <div className={styles.columnRow} key={index}>
        <SkeletonText lineHeight="44px" width="100%" lineWidth="66%" height="14px" color="primary6" />
        <SkeletonText
          className={styles.columnRowValue}
          lineHeight="44px"
          width="100%"
          lineWidth="44%"
          height="14px"
          color="primary6"
        />
      </div>
    );
  };

  const renderColumn = () => {
    return (
      <div className={styles.column}>
        <SkeletonRectangle width="240px" height="20px" color="primary20" marginBottom="20px" />
        {new Array(8).fill(null).map((item, index) => renderColumnRow(index))}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderColumn()}
      {renderColumn()}
      {renderColumn()}
    </div>
  );
};

export default SkeletonDataTab;
