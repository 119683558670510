import React from 'react';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import EditableInput from 'components/EditableInput';
import { ValueValidationType } from 'components/Input';
import LoadingMask from 'components/LoadingMask';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';
import ProductStatusTag from 'components/LoanOriginationSystem/ProductConfiguration/ProductStatusTag';
import ProductRowActions from 'components/LoanOriginationSystem/ProductConfiguration/ProductRowActions';
import { CheckedIcon } from 'static/images';
import styles from './Header.module.scss';

export interface HeaderProps {
  productName: string;
  productStatus: ProductStatus;
  productUpdatedAt: Date;
  onProductStatusChange: (status: ProductStatus) => void;
  onDuplicate: () => void;
  onProductNameChange: (productName: string) => void;
  isUpdating: boolean;
  productUpdatedBy?: UserInfo | null;
}

const MAX_PRODUCT_NAME_LENGTH = 50;

const Header = ({
  productName,
  productStatus,
  isUpdating,
  onProductStatusChange,
  onProductNameChange,
  onDuplicate,
  productUpdatedAt,
  productUpdatedBy,
}: HeaderProps) => {
  const productNameInputProps = useAsyncValueInputProps({
    value: productName,
    placeholderText: productName,
    handleChange: (newNabName) => {
      onProductNameChange(newNabName.trim());
    },
  });

  const renderIcon = () => {
    if (productStatus !== ProductStatus.Active) {
      return null;
    }

    return <CheckedIcon className={styles.checkedIcon} />;
  };

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <EditableInput
          {...productNameInputProps}
          maxLength={MAX_PRODUCT_NAME_LENGTH}
          className={styles.productNameInput}
          valueValidationType={ValueValidationType.LettersNumbersWithSpecialCharacters}
        />
        <div className={styles.subtitle}>
          <WithSystemApiUserAvatar className={styles.avatar} size={8.57} user={productUpdatedBy} />
          <p>Updated {formatDate(productUpdatedAt, DateTimeFormat.Short)}</p>
        </div>
      </div>
      <div className={styles.rightSideSection}>
        <ProductStatusTag className={styles.tag} status={productStatus} icon={renderIcon()} useBackgroundForArchived />
        <ProductRowActions
          className={styles.moreButton}
          productStatus={productStatus}
          onChangeStatus={onProductStatusChange}
          onDuplicate={onDuplicate}
        />
      </div>
      {isUpdating && <LoadingMask background="light" />}
    </div>
  );
};

export default Header;
