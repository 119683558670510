import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import { DuplicateApplicationAction, duplicateApplicationFailed, duplicateApplicationSuccess } from './ActionCreator';
import ApplicationAttribute from './CreateApplication/ApplicationAttribute';

const DuplicateApplicationMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, DuplicateApplicationAction, ReduxState, Error, string>(
    LoanOriginationSystemApplicationsActionType.DuplicateApplication,
    duplicateApplicationSuccess,
    duplicateApplicationFailed,
    async (action) => {
      const { applicationId } = action.payload;

      const application = await api.duplicateApplication(applicationId);

      return application;
    },
    ApplicationAttribute.ApplicationId,
    (response) => response.displayId,
  );

export default DuplicateApplicationMiddleware;
