import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { Variable } from 'Variables/VariablesTypes';
import { VariablesAttributesActionTypes } from './ActionTypes';

export interface GetVariablesByIdAction {
  type: VariablesAttributesActionTypes.GetVariables;
  payload: string[];
}

export interface GetVariablesByIdSuccessAction {
  type: VariablesAttributesActionTypes.GetVariablesSuccess;
  payload: Variable[];
}

export const getVariablesById = (variableIds: string[]): GetVariablesByIdAction => ({
  type: VariablesAttributesActionTypes.GetVariables,
  payload: variableIds,
});

export const getVariablesByIdSuccess = (payload: Variable[]): GetVariablesByIdSuccessAction => ({
  type: VariablesAttributesActionTypes.GetVariablesSuccess,
  payload,
});

export const getVariablesByIdFailure = FailedActionCreator(VariablesAttributesActionTypes.GetVariablesFailure);
