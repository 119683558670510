import {
  ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_REQUEST,
  ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_SUCCESS,
  ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_FAILURE,
} from './ActionTypes';
import { CompleteEditExternalIntegrationRequestData } from './Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface EditExternalIntegrationRequestAction {
  type: typeof ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_REQUEST;
  payload: CompleteEditExternalIntegrationRequestData;
}

export interface EditExternalIntegrationSuccessAction {
  type: typeof ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_SUCCESS;
  payload: CompleteEditExternalIntegrationRequestData;
}

export const editExternalIntegrationRequest = (
  payload: CompleteEditExternalIntegrationRequestData,
): EditExternalIntegrationRequestAction => ({
  type: ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_REQUEST,
  payload,
});

export const editExternalIntegrationSuccess = (
  payload: CompleteEditExternalIntegrationRequestData,
): EditExternalIntegrationSuccessAction => ({
  type: ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_SUCCESS,
  payload,
});

export const editExternalIntegrationFailure = FailedActionCreator(ACTION_TYPE_EDIT_EXTERNAL_INTEGRATION_FAILURE);
