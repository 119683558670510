import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Users from 'components/CompanyInformationUsers';

import { ReduxState } from 'types/redux';

import {
  getOrganizationInfo,
  openSeatsAreFullPopUp,
  closeSeatsAreFullPopUp,
} from 'CompanyInformation/CompanyInformationActionCreator';
import { OrganizationState, OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation, useCloseContextualView } from 'MainLayout/utils';
import AddNewCompanyUser from 'components/AddNewCompanyUser';
import { AddNewCompanyUserRequest } from 'AddNewCompanyUser/AddNewCompanyUserActionCreator';
import { AddNewUserParams } from 'AddNewCompanyUser/AddNewCompanyUserTypes';
import { useQueryParams } from 'hooks/useQueryParam';
import EditCompanyUser from 'components/EditCompanyUser';
import { EditCompanyUserRequest } from 'EditCompanyUser/EditCompanyUserActionCreator';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import { useHistory } from 'react-router-dom';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import NotFoundCompanyUser from 'components/EditCompanyUser/NotFoundCompanyUser';
import SeatsAreFullPopUp from 'components/CompanyInformationUsers/SeatsAreFullPopUp';
import {
  OrganizationUsersState,
  requestOrganizationUsers,
  RESET_ORGANIZATION_USERS_STATE_ACTION_PREFIX,
  toggleFiltersOrganizationUsersPopUp,
} from 'OrganizationUsers/OrganizationUserStore';
import UsersFilter from 'components/CompanyInformationUsers/UsersFilter';
import usersPagination, { UsersPaginationParams } from './Pagination';
import { OrganizationUsersSortingField, OrganizationUsersSortingType } from 'api/Core/UserApi';
import useStateReset from 'hooks/useStateReset';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';

const FIRST_PAGE_NUMBER = 1;

const CompanyInformationUsers = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();
  const selectedUserId = params.get('edit');
  const history = useHistory();

  const { isSeatsAreFullPopUpOpen, purchasedSeats, occupiedSeats, isSeatsLoading } = useSelector<
    ReduxState,
    OrganizationState
  >((state) => state.organizationInformation);

  useStateReset(RESET_ORGANIZATION_USERS_STATE_ACTION_PREFIX);

  const {
    page,
    itemsPerPage,
    searchInputValue,
    showInactive,
    isFiltersPopUpVisible,
    permissionType,
    emailVerified,
    phoneAuthentication,
    sortingType,
  } = useSelector<ReduxState, OrganizationUsersState>((state) => state.organizationUsers);

  const paginationParams = {
    searchInputValue,
    sortingType,
    permissionType,
    phoneAuthentication,
    emailVerified,
    showInactive,
  } as UsersPaginationParams;

  const usersList = usersPagination.usePaginatedItems(paginationParams);
  const paginationProps = usersPagination.usePagination(paginationParams);

  const getOrganizationUsers = (
    userOffset: number,
    userItemsPerPage: number,
    userSearch: string,
    userShowInactive: boolean,
    userPermissionType: UserRoleName | null,
    userPhoneAuthentication: boolean | null,
    userEmailVerified: boolean | null,
    userSortingType: OrganizationUsersSortingType,
  ) => {
    dispatch(
      requestOrganizationUsers({
        filters: {
          offset: userOffset,
          count: userItemsPerPage,
          search: userSearch,
          showInactive: userShowInactive,
          permissionType: userPermissionType,
          emailVerified: userEmailVerified,
          phoneAuthentication: userPhoneAuthentication,
        },
        sortingType: userSortingType,
      }),
    );
  };

  useEffect(() => {
    dispatch(getOrganizationInfo());
    getOrganizationUsers(
      page,
      itemsPerPage,
      searchInputValue,
      showInactive,
      permissionType,
      phoneAuthentication,
      emailVerified,
      sortingType,
    );
  }, []);

  const currentUserId = useSelector<ReduxState>(({ accountDetails }) => accountDetails?.id) as string | undefined;
  const leftNav = makeLeftNavigation(NavigationLinkId.Users, ApplicationSectionName.CompanySettings);

  const updateUser = (userInfo: EditUserParams) => dispatchRoutine(EditCompanyUserRequest(userInfo));

  const handleCloseContextualView = useCloseContextualView();

  const getContextualViewPage = () => {
    if (params.has('new')) {
      const addNewUser = (userInfo: AddNewUserParams) => dispatchRoutine(AddNewCompanyUserRequest(userInfo));
      return (
        <AddNewCompanyUser
          addNewUser={addNewUser}
          onClose={handleCloseContextualView}
          maxSeats={purchasedSeats}
          occupiedSeats={occupiedSeats}
          isSeatsLoading={isSeatsLoading}
        />
      );
    }
    if (params.has('edit')) {
      // TODO: when we add pagination for users page, we will need to make a request to find the user info
      const selectedUserInfo = usersList.find((user) => user!.id === selectedUserId);

      return usersList && !selectedUserInfo ? (
        <NotFoundCompanyUser onClose={handleCloseContextualView} />
      ) : (
        <EditCompanyUser
          updateUser={updateUser}
          selectedUserInfo={selectedUserInfo as OrganizationUser}
          onClose={handleCloseContextualView}
          selfEdit={selectedUserId === currentUserId}
          handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
        />
      );
    }
    return null;
  };

  const handleOpenSeatsAreFullPopUp = () => {
    dispatch(openSeatsAreFullPopUp());
  };

  const handleCloseSeatsAreFullPopUp = () => {
    dispatch(closeSeatsAreFullPopUp());
  };

  const renderOverlay = () => {
    if (isSeatsAreFullPopUpOpen) {
      return (
        <SeatsAreFullPopUp
          maxSeats={purchasedSeats}
          currentSeats={occupiedSeats}
          onPopupClose={handleCloseSeatsAreFullPopUp}
        />
      );
    }

    return null;
  };

  const resetAllFilters = () => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchInputValue,
      showInactive,
      null,
      null,
      null,
      sortingType,
    );
  };

  const rightSidePopupView = isFiltersPopUpVisible && (
    <UsersFilter getOrganizationUsers={getOrganizationUsers} resetAllFilters={resetAllFilters} />
  );

  const openAddNewUserContextualView = () => {
    history.replace('users?new');
  };

  const openEditUserContextualView = (userId: string) => {
    history.replace(`users?edit=${userId}`);
  };

  const setSearchValue = (searchValue: string) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchValue,
      showInactive,
      permissionType,
      phoneAuthentication,
      emailVerified,
      sortingType,
    );
  };

  const onChangeShowInactive = (active: boolean) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchInputValue,
      active,
      permissionType,
      phoneAuthentication,
      emailVerified,
      sortingType,
    );
  };

  const togglePopUpFilters = (popUpOpeningStatus: boolean) => {
    dispatch(toggleFiltersOrganizationUsersPopUp({ popUpOpeningStatus }));
  };

  const onSort = (field: OrganizationUsersSortingField, ascending: boolean) => {
    getOrganizationUsers(
      FIRST_PAGE_NUMBER,
      itemsPerPage,
      searchInputValue,
      showInactive,
      permissionType,
      phoneAuthentication,
      emailVerified,
      {
        field,
        ascending,
      },
    );
  };

  return (
    <MainLayout
      contextualView={getContextualViewPage()}
      leftNav={leftNav}
      closeContextualView={handleCloseContextualView}
      overlay={renderOverlay()}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent>
          <Users
            organizationUsers={usersList}
            paginationProps={paginationProps}
            openAddNewUserContextualView={openAddNewUserContextualView}
            openEditUserContextualView={openEditUserContextualView}
            updateUser={updateUser}
            selectedUserId={selectedUserId}
            currentUserId={currentUserId}
            handleOpenSeatsAreFullPopUp={handleOpenSeatsAreFullPopUp}
            setSearchValue={setSearchValue}
            searchInputValue={searchInputValue}
            showInactive={showInactive}
            onChangeShowInactive={onChangeShowInactive}
            togglePopUpFilters={togglePopUpFilters}
            sortingType={sortingType}
            onSort={onSort}
            permissionType={permissionType}
            emailVerified={emailVerified}
            phoneAuthentication={phoneAuthentication}
            resetAllFilters={resetAllFilters}
          />
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default CompanyInformationUsers;
