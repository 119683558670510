import { ViewType } from 'LoanOriginationSystemApplications/Types';
import { useQueryParams } from './useQueryParam';

const useViewType = (defaultViewType = ViewType.Column) => {
  const queryParams = useQueryParams();

  const viewType = queryParams.get('view') as string;

  const isValid = viewType && Object.values(ViewType).includes(viewType.toLowerCase() as ViewType);

  return isValid ? (viewType as ViewType) : defaultViewType;
};

export default useViewType;
