import Fetcher from 'middlewares/Fetcher';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import { ESignTemplatesApi, ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { ESignTemplatesActionType } from './ActionTypes';
import { GetESignTemplatesAction, getESignTemplatesFailure, getESignTemplatesSuccess } from './ActionCreator';

const GetESignTemplatesMiddleware = (api: ESignTemplatesApi) =>
  Fetcher<TableViewData<ESignTemplate> & { searchInputValue: string }, GetESignTemplatesAction>(
    ESignTemplatesActionType.GetESignTemplates,
    getESignTemplatesSuccess,
    getESignTemplatesFailure,
    async (action) => {
      const result = await api.getAll(
        {
          integrationId: action.payload.filters.integrationId,
          search: action.payload.filters.search,
          offset: 0,
          count: action.payload.itemsPerPage,
          status: action.payload.filters.status,
          members: action.payload.filters.members,
          createdDateRange: action.payload.filters.createdDateRange,
          updatedDateRange: action.payload.filters.updatedDateRange,
        },
        action.payload.sortingType,
      );

      return {
        ...result,
        searchInputValue: action.payload.filters.search,
      };
    },
  );

export default GetESignTemplatesMiddleware;
