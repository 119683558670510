import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { Variable, VisualDataType } from 'Variables/VariablesTypes';
import { VariablesSortingType } from 'api/Core/VariablesApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const VARIABLES_PER_PAGE_DEFAULT = 15;

export interface VariablesPaginationParams {
  searchInputValue: string;
  sortingType: VariablesSortingType;
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  onlyCustom: boolean;
  onlyStandard: boolean;
  dataType: VisualDataType | '';
  selectedMembers: UserInfo[];
  showArchived: boolean;
}

const variablesPagination = Pagination<ReduxState, Variable, VariablesPaginationParams>(
  'variables',
  VARIABLES_PER_PAGE_DEFAULT,
  ({ searchInputValue }) => (searchInputValue ? ['searched', searchInputValue] : []),
);

export default variablesPagination;
