import Fetcher from 'middlewares/Fetcher';
import { ApplicationNotesApi } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import { addApplicationNoteSuccess, addApplicationNoteFailure, AddApplicationNoteAction } from './ActionCreator';
import { ApplicationNotesActionType } from './ActionTypes';
import { ApplicationNote } from 'api/Types';

const AddApplicationNoteMiddleware = (api: ApplicationNotesApi) =>
  Fetcher<ApplicationNote, AddApplicationNoteAction>(
    ApplicationNotesActionType.AddApplicationNote,
    addApplicationNoteSuccess,
    addApplicationNoteFailure,
    (action) => {
      return api.addNote(action.payload.applicationId, action.payload.note);
    },
  );

export default AddApplicationNoteMiddleware;
