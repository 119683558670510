import React from 'react';
import { ColorResult, CustomPicker, InjectedColorProps } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';

import styles from './ColorPicker.module.scss';

const SaturationPointer = () => <div className={styles.saturationPointer} />;

const HuePointer = () => <div className={styles.huePointer} />;

const ColorPickerUI = ({ onChange, ...props }: InjectedColorProps) => {
  const handleChange = (colorResult: ColorResult) => onChange?.(colorResult);

  return (
    <div className={styles.container}>
      <div className={styles.saturation}>
        <Saturation {...props} onChange={handleChange} pointer={SaturationPointer} />
      </div>
      <div className={styles.hue}>
        <Hue {...props} onChange={handleChange} pointer={HuePointer} />
      </div>
    </div>
  );
};

const WrappedPicker = CustomPicker(ColorPickerUI);

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
  const handleChange = ({ hex }: ColorResult) => onChange(hex);

  return <WrappedPicker color={value} onChange={handleChange} />;
};

export default ColorPicker;
