import Fetcher from 'middlewares/Fetcher';
import { ApplicationTab, ApplicationTabsApi } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';
import { CreateApplicationTabAction, createApplicationTabSuccess, createApplicationTabFailure } from './ActionCreator';

const CreateApplicationTabMiddleware = (api: ApplicationTabsApi) =>
  Fetcher<ApplicationTab, CreateApplicationTabAction>(
    LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTab,
    createApplicationTabSuccess,
    createApplicationTabFailure,
    async (action) => {
      return api.create(action.payload);
    },
  );

export default CreateApplicationTabMiddleware;
