import { CLOSE_DELETE_BRANCH_WIZARD, OPEN_DELETE_BRANCH_WIZARD } from 'components/DeleteBranchWizard/constants';

export const openDeleteBranchWizard = (branchIndex: number, strategyId: string, moduleKey: string) => {
  return {
    type: OPEN_DELETE_BRANCH_WIZARD,
    payload: { branchIndex, strategyId, moduleKey },
  };
};

export const closeDeleteBranchWizard = () => {
  return {
    type: CLOSE_DELETE_BRANCH_WIZARD,
  };
};
