import React, { FC } from 'react';
import styles from './Products.module.scss';
import { Tag } from 'components/Tag';
import { SkeletonRectangle } from 'components/Skeleton';

interface ProductsProps {
  products: {
    loadOriginationSystem: boolean;
    decisionEngine: boolean;
    digitalLendingPortal: boolean;
  } | null;
}

const Products: FC<ProductsProps> = ({ products }) => {
  const renderStatusTag = (active: boolean | null) => {
    if (active === null) {
      return <SkeletonRectangle color="primary6" width="74px" height="24px" />;
    }

    return active ? (
      <Tag medium color="green">
        Active
      </Tag>
    ) : (
      <Tag medium>Inactive</Tag>
    );
  };

  const renderProduct = (name: string, active: boolean | null) => {
    return (
      <div className={styles.product}>
        <div className={styles.productName}>{name}</div>
        {renderStatusTag(active)}
      </div>
    );
  };

  return (
    <div>
      <p className={styles.label}>Products</p>
      {renderProduct('Loan Origination System', products && products.loadOriginationSystem)}
      {renderProduct('Decision Engine', products && products.decisionEngine)}
      {renderProduct('Digital Lending Portal', products && products.digitalLendingPortal)}
    </div>
  );
};

export default Products;
