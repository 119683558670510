import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';
import { ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS } from 'EditCompanyUser/ActionTypes';
import { EditCompanyUserSuccessAction } from 'EditCompanyUser/EditCompanyUserActionCreator';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import UserRestApi, {
  OrganizationUsersSortingField,
  OrganizationUsersSortingType,
  UserDataFilter,
} from 'api/Core/UserApi';
import { ItemsStateWithPagination } from 'pagination';
import usersPagination from 'pages/CompanyInformationUsers/Pagination';
import responseHandlers from 'api/ResponseHandlers';
import withStateReset from 'utils/reducers/withStateReset';

export interface OrganizationUsersState extends ItemsStateWithPagination<OrganizationUser> {
  sortingType: OrganizationUsersSortingType;
  searchInputValue: string;
  showInactive: boolean;
  isFiltersPopUpVisible: boolean;
  permissionType: UserRoleName | null;
  phoneAuthentication: boolean | null;
  emailVerified: boolean | null;
}

export interface FetchUsersParams {
  filters: UserDataFilter;
  sortingType: OrganizationUsersSortingType;
}

const userApi = new UserRestApi(responseHandlers);

const initialState: OrganizationUsersState = {
  items: [],
  page: 1,
  itemsPerPage: 10,
  itemsTotal: undefined,
  searchInputValue: '',
  showInactive: false,
  isFiltersPopUpVisible: false,
  permissionType: null,
  phoneAuthentication: null,
  emailVerified: null,
  sortingType: {
    field: OrganizationUsersSortingField.FirstName,
    ascending: true,
  },
  error: null,
};

export const RESET_ORGANIZATION_USERS_STATE_ACTION_PREFIX = 'resetOrganizationUsersState';

export const requestOrganizationUsers = createAsyncThunk(
  'requestOrganizationUsers',
  async ({ filters, sortingType }: FetchUsersParams) => {
    const users = await userApi.getAll(
      {
        count: filters.count,
        offset: (filters.offset - 1) * filters.count,
        search: filters.search,
        permissionType: filters.permissionType,
        phoneAuthentication: filters.phoneAuthentication,
        emailVerified: filters.emailVerified,
        showInactive: filters.showInactive,
      },
      sortingType,
    );

    return users;
  },
);

export const toggleFiltersOrganizationUsersPopUp = createAction<{ popUpOpeningStatus: boolean }>(
  'toggleFiltersOrganizationUsersPopUp',
);

export const resetOrganizationUsersState = createAction(RESET_ORGANIZATION_USERS_STATE_ACTION_PREFIX);

const OrganizationUsersReducer = createReducer<OrganizationUsersState>(initialState, (builder) => {
  builder.addCase(requestOrganizationUsers.pending, (state, action) => {
    state.showInactive = action.meta.arg.filters.showInactive!;
    state.permissionType = action.meta.arg.filters.permissionType!;
    state.phoneAuthentication = action.meta.arg.filters.phoneAuthentication!;
    state.emailVerified = action.meta.arg.filters.emailVerified!;
    state.sortingType = action.meta.arg.sortingType;
    state.searchInputValue = action.meta.arg.filters.search;
    state.itemsTotal = undefined;
  });
  builder.addCase(requestOrganizationUsers.fulfilled, (state, action) => {
    state.items = action.payload.items;
    state.itemsTotal = action.payload.total;
    state.page = 1;
  });
  builder.addCase(toggleFiltersOrganizationUsersPopUp, (state, action) => {
    state.isFiltersPopUpVisible = action.payload.popUpOpeningStatus;
  });
  builder.addCase<string, EditCompanyUserSuccessAction>(ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS, (state, action) => {
    if (!state.items) {
      return;
    }

    const user = state.items.find(({ id }) => id === action.payload.id);

    if (!user) {
      return;
    }

    user.role = action.payload.role;
    user.isActive = action.payload.isActive;
  });
});

export default usersPagination.wrapReducer<OrganizationUsersState>(
  withStateReset(OrganizationUsersReducer, RESET_ORGANIZATION_USERS_STATE_ACTION_PREFIX, () => initialState),
);
