import { AnyAction } from 'redux';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import variablesListPagination, {
  LIST_VARIABLES_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/VariablesConfiguration/pagination';
import {
  CreateVariableActionOrigin,
  CreateVariableRequestAction,
  CreateVariableSuccessAction,
} from 'Variables/VariablesActionCreator';
import {
  ACTION_TYPE_CREATE_VARIABLE_FAILURE,
  ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
} from 'Variables/ActionTypes';
import { LoanOriginationSystemVariablesListState } from './Types';
import {
  CloseCreateVariablePopupAction,
  GetListVariablesSuccessAction,
  OpenCreateVariablePopupAction,
  ResetVariablesListAction,
  SetSearchInputValueAction,
} from './ActionCreator';
import { VariablesListActionType } from './ActionTypes';

type LoanOriginationSystemVariablesListReducer = (
  state: LoanOriginationSystemVariablesListState,
  action: AnyAction,
) => LoanOriginationSystemVariablesListState;

type LoanOriginationSystemVariablesConfigurationActions =
  | GetListVariablesSuccessAction
  | OpenCreateVariablePopupAction
  | CloseCreateVariablePopupAction
  | CreateVariableRequestAction
  | CreateVariableSuccessAction
  | SetSearchInputValueAction
  | ResetVariablesListAction
  | FailedAction<typeof ACTION_TYPE_CREATE_VARIABLE_FAILURE>;

export const initialState: LoanOriginationSystemVariablesListState = {
  items: [],
  page: 1,
  itemsPerPage: LIST_VARIABLES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  search: '',
  searched: {},
  isCreatingVariableInProgress: false,
  isCreatingVariablePopupOpen: false,
};

const loanOriginationSystemVariablesListReducer = (
  state = initialState,
  action: LoanOriginationSystemVariablesConfigurationActions,
): LoanOriginationSystemVariablesListState => {
  switch (action.type) {
    case VariablesListActionType.SetSearchInputValue: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case VariablesListActionType.GetListVariablesSuccess: {
      if (action.payload.search) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.search]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: LIST_VARIABLES_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state?.itemsPerPage || LIST_VARIABLES_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
      };
    }
    case VariablesListActionType.OpenCreateVariablePopup: {
      return {
        ...state,
        isCreatingVariablePopupOpen: true,
      };
    }
    case VariablesListActionType.CloseCreateVariablePopup: {
      return {
        ...state,
        isCreatingVariablePopupOpen: false,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_REQUEST: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.VariablesConfiguration) {
        return state;
      }

      return {
        ...state,
        isCreatingVariableInProgress: true,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_SUCCESS: {
      return {
        ...state,
        isCreatingVariableInProgress: false,
        isCreatingVariablePopupOpen: false,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_FAILURE: {
      return {
        ...state,
        isCreatingVariableInProgress: false,
      };
    }
    case VariablesListActionType.ResetVariablesList: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default variablesListPagination.wrapReducer<LoanOriginationSystemVariablesListState>(
  loanOriginationSystemVariablesListReducer as LoanOriginationSystemVariablesListReducer,
);
