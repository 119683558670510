import React from 'react';
import { SIZES } from 'components/SendGrid/Integration/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import styles from 'components/SendGrid/Integration/CredentialLine/CredentialLine.module.scss';

const SkeletonCredentialLine = () => {
  return (
    <TableRow disableHover className={styles.row}>
      <TableBodyCell width={SIZES.NAME}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.VALUE}>
        <SkeletonRectangle width="40%" color="primary6" height="16px" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonCredentialLine;
