import React, { FC, useState } from 'react';

import styles from './PopUp.module.scss';

import Button from 'components/Button';

interface GenerateSecondNewSecretProps {
  generateSecondSecret: () => Promise<unknown>;
  closePopUp: () => void;
}

const GenerateSecondNewSecret: FC<GenerateSecondNewSecretProps> = ({ closePopUp, generateSecondSecret }) => {
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);

  const handleGetPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsBlockingActionInProgress(true);
    await generateSecondSecret();
    setIsBlockingActionInProgress(false);
    closePopUp();
  };

  return (
    <div className={styles.popUpBody}>
      <p className={styles.popUpBody__description}>
        Please confirm that you would like to generate a new secret for Client Secret 2.
      </p>
      <div className={styles.popUpBody__button}>
        <Button kind="primary" size="form" onClick={handleGetPassword} isLoading={isBlockingActionInProgress}>
          Confirm
        </Button>
        <Button kind="secondary" size="form" onClick={closePopUp} disabled={isBlockingActionInProgress}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default GenerateSecondNewSecret;
