import React, { ChangeEvent, FC, FocusEvent } from 'react';
import TextInput from 'components/TextInput/TextInput';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import styles from './EmailInput.module.scss';

interface EmailInputProps {
  value: string;
  labelTitle: string;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  errorMessage: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
}

const PLACEHOLDER = 'email@domain.com';

const EmailInput: FC<EmailInputProps> = ({
  value,
  labelTitle,
  onChange,
  onBlur,
  onFocus,
  disabled,
  errorMessage,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
}) => {
  return (
    <div className={styles.container}>
      <TextInput
        value={value}
        labelTitle={labelTitle}
        onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
        hasRightNeighbour
        placeholder={PLACEHOLDER}
        onFocus={onFocus}
        onBlur={onBlur}
        errorMessage={errorMessage}
        disabled={disabled}
        showLoader={showLoader}
        loaderState={loaderState}
        onLoaderStateReset={onLoaderStateReset}
        tabIndex={tabIndex}
      />
    </div>
  );
};

export default EmailInput;
