import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { DuplicateProductAction, duplicateProductFailure, duplicateProductSuccess } from './ActionCreator';

const DuplicateProductMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product, DuplicateProductAction>(
    LoanOriginationSystemProductsActionType.DuplicateProduct,
    duplicateProductSuccess,
    duplicateProductFailure,
    (action) => {
      return api.duplicate(action.payload);
    },
  );

export default DuplicateProductMiddleware;
