import React from 'react';
import {
  IntermediariesSortingField,
  IntermediariesSortingType,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { NullableItems, PaginationProps } from 'pagination';
import { SIZES } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import useSorting from 'hooks/useSorting';
import styles from './IntermadiariesTable.module.scss';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import SkeletonIntermediaryLine from './SkeletonIntermediaryLine';
import IntermediaryLine from './IntermediaryLine';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableActionCell from 'components/Table/TableActionCell';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { StandardVariables } from 'Variables/VariablesTypes';

interface IntermediariesTableProps {
  intermediaries: NullableItems<Intermediary>;
  standardVariables: StandardVariables | null;
  paginationProps: PaginationProps;
  onSort: (field: IntermediariesSortingField, ascending: boolean) => void;
  sortingType: IntermediariesSortingType;
  onPopupOpen: (id: string) => Promise<void>;
  onEdit: (id: string) => void;
  searchInputValue: string;
}

const IntermediariesTable = ({
  intermediaries,
  standardVariables,
  paginationProps,
  onSort,
  sortingType,
  onPopupOpen,
  onEdit,
  searchInputValue,
}: IntermediariesTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderSkeletonRow = (index: number) => <SkeletonIntermediaryLine key={index} />;

  const renderRow = (intermediary: Intermediary, index: number) => {
    if (!intermediary || !standardVariables) {
      return renderSkeletonRow(index);
    }

    return (
      <IntermediaryLine
        key={intermediary.id}
        intermediary={intermediary}
        standardVariables={standardVariables}
        onPopupOpen={onPopupOpen}
        onEdit={onEdit}
        searchInputValue={searchInputValue}
      />
    );
  };

  return (
    <div className={styles.intermediariesTableInner}>
      <TableWrapperWithFooter>
        <div className={styles.intermediariesTable}>
          <Table>
            <TableHead sticky useActions>
              <TableHeadCell
                width={SIZES.NAME}
                ascending={getSortingType(IntermediariesSortingField.IntermediaryName)}
                onClick={() => changeSorting(IntermediariesSortingField.IntermediaryName)}
              >
                Name
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.TYPE}
                ascending={getSortingType(IntermediariesSortingField.IntermediaryType)}
                onClick={() => changeSorting(IntermediariesSortingField.IntermediaryType)}
              >
                Type
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.PHONE}
                ascending={getSortingType(IntermediariesSortingField.Phone)}
                onClick={() => changeSorting(IntermediariesSortingField.Phone)}
              >
                Contact phone
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.EMAIL}
                ascending={getSortingType(IntermediariesSortingField.Email)}
                onClick={() => changeSorting(IntermediariesSortingField.Email)}
              >
                Contact email
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.DATE}
                ascending={getSortingType(IntermediariesSortingField.UpdatedAt)}
                onClick={() => changeSorting(IntermediariesSortingField.UpdatedAt)}
              >
                Updated
              </TableHeadCell>
              <TableHeadCell
                width={SIZES.RATE}
                lastColumnCell
                ascending={getSortingType(IntermediariesSortingField.CommissionRate)}
                onClick={() => changeSorting(IntermediariesSortingField.CommissionRate)}
              >
                Commission Rate
              </TableHeadCell>
              <TableActionCell />
            </TableHead>
            <TableBody>
              <TableBodyContent
                renderSkeletonTableRow={renderSkeletonRow}
                rows={intermediaries}
                renderTableRow={renderRow}
              />
            </TableBody>
          </Table>
          <Pagination {...paginationProps} />
        </div>
      </TableWrapperWithFooter>
    </div>
  );
};

export default IntermediariesTable;
