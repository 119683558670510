import React, { FocusEvent, useMemo, useState } from 'react';
import SuggestionsPopper, {
  SuggestionItem,
} from 'components/LoanOriginationSystem/CreateApplication/BorrowerSuggestionsPopper';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/Types';
import { BorrowerSuggestionFilter } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import { getFormattedBorrowerPhoneNumber } from 'LoanOriginationSystemOrganization/Utils';
import shouldShowSuggestions from 'components/LoanOriginationSystem/CreateApplication/Forms/BorrowerForm/NewBorrowerForm/shouldShowSuggestions';
import { removeWhiteSpace } from 'utils/validation/validation';
import ConfigurableForm from 'components/ConfigurableForm';
import styles from './NewBorrowerForm.module.scss';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import { PopperProps } from '@material-ui/core';
import { StandardVariables } from 'Variables/VariablesTypes';

interface NewBorrowerFormProps {
  borrowerData: FormLayoutData;
  suggestions: SuggestionItem[];
  onChange: (formData: FormLayoutData) => void;
  onSuggestionSelect: (suggestionItem: SuggestionItem) => void;
  onLoadSuggestions?: (filter: BorrowerSuggestionFilter) => void;
  configurations: VariableConfiguration[];
  standardVariables: StandardVariables;
}

const VARIABLE_NAMES_WITH_SUGGESTIONS = [
  BorrowerDefaultVariable.CompanyName,
  BorrowerDefaultVariable.FirstName,
  BorrowerDefaultVariable.LastName,
  BorrowerDefaultVariable.Email,
  BorrowerDefaultVariable.PhoneNumber,
];

const NewBorrowerForm = ({
  onSuggestionSelect,
  onLoadSuggestions,
  suggestions,
  borrowerData,
  onChange,
  configurations,
  standardVariables,
}: NewBorrowerFormProps) => {
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionAnchorElement, setSuggestionAnchorElement] = useState<PopperProps['anchorEl']>(null);

  const formattedSuggestions = useMemo(() => {
    return suggestions.map((suggestion) => {
      return {
        ...suggestion,
        phone: getFormattedBorrowerPhoneNumber(suggestion.phone, standardVariables),
      };
    });
  }, [suggestions]);

  const getBorrowerSuggestionFilter = (data: FormLayoutData) =>
    ({
      email: data[BorrowerDefaultVariable.Email],
      firstName: data[BorrowerDefaultVariable.FirstName],
      lastName: data[BorrowerDefaultVariable.LastName],
      phoneNumber: data[BorrowerDefaultVariable.PhoneNumber],
      companyName: data[BorrowerDefaultVariable.CompanyName],
    } as BorrowerSuggestionFilter);

  const emitOnLoadSuggestion = (filter: BorrowerSuggestionFilter) => {
    if (shouldShowSuggestions(filter) && suggestionAnchorElement) {
      onLoadSuggestions?.(filter);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    const newBorrowerData = {
      ...borrowerData,
      [variable.systemName]: removeWhiteSpace(value),
    };

    onChange(newBorrowerData);

    if (VARIABLE_NAMES_WITH_SUGGESTIONS.includes(variable.systemName)) {
      emitOnLoadSuggestion(getBorrowerSuggestionFilter(newBorrowerData));
    }
  };

  const handleFieldFocus = ({ variable }: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => {
    if (VARIABLE_NAMES_WITH_SUGGESTIONS.includes(variable.systemName)) {
      setSuggestionAnchorElement(event.target);
    }
  };

  const onClosePopper = () => {
    setShowSuggestions(false);
  };

  return (
    <div className={styles.container}>
      <ConfigurableForm
        configurations={configurations}
        data={borrowerData}
        onFieldChange={handleFieldChange}
        onFieldFocus={handleFieldFocus}
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
        hideCustomFields
      />
      <SuggestionsPopper
        placement="bottom-start"
        className={styles.popper}
        suggestions={formattedSuggestions}
        anchorEl={suggestionAnchorElement}
        open={suggestions && showSuggestions}
        onClose={onClosePopper}
        onSelectSuggestion={onSuggestionSelect}
      />
    </div>
  );
};

export default NewBorrowerForm;
