import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemApplicationTabsActionTypes } from 'LoanOriginationSystemApplicationTabs/ActionTypes';
import {
  CreateApplicationDataTabCardAction,
  CreateApplicationDataTabCardSuccessAction,
  DeleteApplicationDataTabCardAction,
  DeleteApplicationDataTabCardSuccessAction,
  UpdateApplicationDataTabCardAction,
  UpdateApplicationDataTabCardSuccessAction,
} from 'LoanOriginationSystemApplicationDataTabCards/ActionCreator';
import { ApplicationDataTabCardsActionType } from 'LoanOriginationSystemApplicationDataTabCards/ActionTypes';
import { ApplicationDataTabVariableConfigurationsActionType } from 'ApplicationDataTabVariableConfigurations/ActionTypes';
import {
  ApplicationTabsActionOrigin,
  CreateApplicationTabAction,
  CreateApplicationTabSuccessAction,
  DeleteApplicationTabAction,
  DeleteApplicationTabSuccessAction,
  GetApplicationTabsActionSuccess,
  UpdateApplicationTabAction,
  UpdateApplicationTabSuccessAction,
} from 'LoanOriginationSystemApplicationTabs/ActionCreator';
import {
  CreateApplicationDataTabVariableConfigurationAction,
  CreateApplicationDataTabVariableConfigurationSuccessAction,
  DeleteApplicationDataTabVariableConfigurationAction,
  DeleteApplicationDataTabVariableConfigurationSuccessAction,
  UpdateApplicationDataTabVariableConfigurationAction,
  UpdateApplicationDataTabVariableConfigurationSuccessAction,
} from 'ApplicationDataTabVariableConfigurations/ActionCreator';
import { LoanOriginationSystemProductApplicationSetupState } from './Types';
import { SelectTabAction, SetTabToDeleteAction } from './ActionCreator';
import { ProductApplicationSetupActionType } from './ActionTypes';

export const initialState: LoanOriginationSystemProductApplicationSetupState = {
  selectedTab: null,
  tabToDelete: null,
  isTabDeletingInProgress: false,
  isBlockingUpdateInProgress: false,
};

type LoanOriginationSystemProductApplicationSetupActions =
  | SelectTabAction
  | SetTabToDeleteAction
  | GetApplicationTabsActionSuccess
  | CreateApplicationTabAction
  | UpdateApplicationTabAction
  | DeleteApplicationTabAction
  | CreateApplicationDataTabCardAction
  | UpdateApplicationDataTabCardAction
  | DeleteApplicationDataTabCardAction
  | CreateApplicationDataTabVariableConfigurationAction
  | UpdateApplicationDataTabVariableConfigurationAction
  | DeleteApplicationDataTabVariableConfigurationAction
  | CreateApplicationTabSuccessAction
  | UpdateApplicationTabSuccessAction
  | DeleteApplicationTabSuccessAction
  | CreateApplicationDataTabCardSuccessAction
  | UpdateApplicationDataTabCardSuccessAction
  | DeleteApplicationDataTabCardSuccessAction
  | CreateApplicationDataTabVariableConfigurationSuccessAction
  | UpdateApplicationDataTabVariableConfigurationSuccessAction
  | DeleteApplicationDataTabVariableConfigurationSuccessAction
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabFailure>
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabFailure>
  | FailedAction<LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabFailure>
  | FailedAction<ApplicationDataTabCardsActionType.CreateApplicationDataTabCardFailure>
  | FailedAction<ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardFailure>
  | FailedAction<ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardFailure>
  | FailedAction<
      ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationFailure
    >
  | FailedAction<
      ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationFailure
    >
  | FailedAction<
      ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationFailure
    >;

const loanOriginationSystemProductApplicationSetupReducer = (
  state = initialState,
  action: LoanOriginationSystemProductApplicationSetupActions,
) => {
  switch (action.type) {
    case ProductApplicationSetupActionType.SelectTab: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case ProductApplicationSetupActionType.SetTabToDelete: {
      return {
        ...state,
        tabToDelete: action.payload,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabsSuccess: {
      if (action.meta?.actionOrigin !== ApplicationTabsActionOrigin.ApplicationSetup) {
        return state;
      }

      const sortedTabs = action.payload.tabs.sort((firstTab, secondTab) => firstTab.position - secondTab.position);

      return {
        ...state,
        selectedTab: sortedTabs[0],
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
        selectedTab: action.payload.tab,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabSuccess: {
      return {
        ...state,
        tabToDelete: null,
        isTabDeletingInProgress: false,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabSuccess: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
        selectedTab:
          state.selectedTab && state.selectedTab.id === action.payload.tab.id ? action.payload.tab : state.selectedTab,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTab: {
      return {
        ...state,
        isTabDeletingInProgress: true,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTab:
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTab:
    case ApplicationDataTabCardsActionType.CreateApplicationDataTabCard:
    case ApplicationDataTabCardsActionType.UpdateApplicationDataTabCard:
    case ApplicationDataTabCardsActionType.DeleteApplicationDataTabCard:
    case ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfiguration:
    case ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfiguration:
    case ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfiguration: {
      return {
        ...state,
        isBlockingUpdateInProgress: true,
      };
    }
    case LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabFailure: {
      return {
        ...state,
        isTabDeletingInProgress: false,
      };
    }
    case ApplicationDataTabCardsActionType.CreateApplicationDataTabCardSuccess:
    case ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardSuccess:
    case ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardSuccess:
    case ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationSuccess:
    case ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationSuccess:
    case ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationSuccess:
    case LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabFailure:
    case LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabFailure:
    case ApplicationDataTabCardsActionType.CreateApplicationDataTabCardFailure:
    case ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardFailure:
    case ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardFailure:
    case ApplicationDataTabVariableConfigurationsActionType.CreateApplicationDataTabVariableConfigurationFailure:
    case ApplicationDataTabVariableConfigurationsActionType.UpdateApplicationDataTabVariableConfigurationFailure:
    case ApplicationDataTabVariableConfigurationsActionType.DeleteApplicationDataTabVariableConfigurationFailure: {
      return {
        ...state,
        isBlockingUpdateInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemProductApplicationSetupReducer;
