export const OPEN_ADD_RULE_POP_UP = 'OPEN_ADD_RULE_POP_UP';
export const CLOSE_ADD_RULE_POP_UP = 'CLOSE_ADD_RULE_POP_UP';
export const SET_CURRENT_RULE_TYPE = 'SET_CURRENT_RULE_TYPE';
export const UPLOAD_RULE_SET = 'UPLOAD_RULE_SET';
export const UPLOAD_RULE_SET_SUCCESS = 'UPLOAD_RULE_SET_SUCCESS';
export const UPLOAD_RULE_SET_FAILURE = 'UPLOAD_RULE_SET_FAILURE';
export const DUPLICATE_RULE = 'DUPLICATE_RULE';
export const DUPLICATE_RULE_SUCCESS = 'DUPLICATE_RULE_SUCCESS';
export const DUPLICATE_RULE_FAILURE = 'DUPLICATE_RULE_FAILURE';
export const CREATE_RULE = 'CREATE_SIMPLE_RULE';
export const CREATE_RULE_SUCCESS = 'CREATE_SIMPLE_RULE_SUCCESS';
export const CREATE_RULE_FAILURE = 'CREATE_SIMPLE_RULE_FAILURE';
export const CREATE_CALCULATION_SCRIPT = 'CREATE_CALCULATION_SCRIPT';
export const CREATE_CALCULATION_SCRIPT_SUCCESS = 'CREATE_CALCULATION_SCRIPT_SUCCESS';
export const CREATE_CALCULATION_SCRIPT_FAILURE = 'CREATE_CALCULATION_SCRIPT_FAILURE';
export const CREATE_SIMPLE_OUTPUT = 'CREATE_SIMPLE_OUTPUT';
export const CREATE_SIMPLE_OUTPUT_SUCCESS = 'CREATE_SIMPLE_OUTPUT_SUCCESS';
export const CREATE_SIMPLE_OUTPUT_FAILURE = 'CREATE_SIMPLE_OUTPUT_FAILURE';

export const ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE = 'ADD_RULE_POPUP_VARIABLE_CREATION_TOGGLE';
