import React from 'react';
import { ApplicationTab, ApplicationTabType } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DefaultApplicationStatusName } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import StatusesRulesValidationContent from './StatusesRulesValidationContent';
import FinalStatusInformation from './FinalStatusInformation';

export interface StatusRulesTabProps {
  application: Application;
  availableTabs: ApplicationTab[];
  onTabChange: (tabId: string) => void;
}

const StatusRulesTab = ({ application, availableTabs, onTabChange }: StatusRulesTabProps) => {
  if (Object.values(DefaultApplicationStatusName).includes(application.status.name as DefaultApplicationStatusName)) {
    const type = application.status.name === DefaultApplicationStatusName.Approved ? 'approved' : 'rejected';

    const historyTab = availableTabs.find((tab) => tab.type === ApplicationTabType.History);

    const handleViewHistory = () => {
      if (!historyTab) {
        return;
      }

      onTabChange(historyTab.id);
    };

    return (
      <FinalStatusInformation displayViewHistoryButton={!!historyTab} type={type} onViewHistory={handleViewHistory} />
    );
  }

  return <StatusesRulesValidationContent application={application} />;
};

export default StatusRulesTab;
