import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import {
  ApplicationDocumentsSortingType,
  ApplicationDocument,
  ApplicationDocumentsSortingField,
} from 'api/LoanOriginationSystem/DocumentsApi';
import useSorting from 'hooks/useSorting';
import Table, { TableHead, TableHeadCell, TableBody, TableActionCell, TableBodyContent } from 'components/Table';
import DocumentItemRow from './ApplicationDocumentItemRow';
import SkeletonDocumentItemRow from './SkeletonApplicationDocumentItemRow';
import Pagination from 'components/Pagination';
import { ApplicationDocumentsTableSize } from './TableColumnSizes';
import styles from './ApplicationDocumentsTable.module.scss';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';

interface ApplicationDocumentsTableProps {
  source: NullableItems<ApplicationDocument>;
  paginationProps: PaginationProps;
  sortingType: ApplicationDocumentsSortingType;
  searchInputValue: string;
  onSort: (field: ApplicationDocumentsSortingField, ascending: boolean) => void;
  onApplicationDocumentDownload: (document: ApplicationDocument) => void;
  onApplicationDocumentClick: (document: ApplicationDocument) => void;
  onApplicationDocumentDelete: (document: ApplicationDocument) => void;
  onRenameApplicationDocument: (document: ApplicationDocument) => void;
}

const ApplicationDocumentsTable = ({
  source,
  paginationProps,
  sortingType,
  searchInputValue,
  onSort,
  onApplicationDocumentDownload,
  onRenameApplicationDocument,
  onApplicationDocumentClick,
  onApplicationDocumentDelete,
}: ApplicationDocumentsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  return (
    <div className={styles.table}>
      <Table>
        <TableHead useActions sticky stickyMarginTop={TABLE_HEADER_OFFSET}>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Name}
            ascending={getSortingType(ApplicationDocumentsSortingField.Name)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Name)}
          >
            Name
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Type}
            ascending={getSortingType(ApplicationDocumentsSortingField.Extension)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Extension)}
          >
            Type
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.Size}
            ascending={getSortingType(ApplicationDocumentsSortingField.Size)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.Size)}
          >
            Size
          </TableHeadCell>
          <TableHeadCell
            width={ApplicationDocumentsTableSize.CreatedAt}
            ascending={getSortingType(ApplicationDocumentsSortingField.CreatedAt)}
            onClick={() => changeSorting(ApplicationDocumentsSortingField.CreatedAt)}
          >
            Created
          </TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            renderSkeletonTableRow={(index) => <SkeletonDocumentItemRow key={index} />}
            renderTableRow={(item) => (
              <DocumentItemRow
                key={item.id}
                searchInputValue={searchInputValue}
                document={item}
                onClick={onApplicationDocumentClick}
                onApplicationDocumentDelete={onApplicationDocumentDelete}
                onApplicationDocumentDownload={onApplicationDocumentDownload}
                onRenameApplicationDocument={onRenameApplicationDocument}
              />
            )}
            rows={source}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </div>
  );
};

export default ApplicationDocumentsTable;
