import React, { FC } from 'react';
import styles from './RulesListTableHeader.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import QuestionIcon from 'components/QuestionIcon';

interface TableHeaderProps {
  title: string;
  description: string;
  handleDownloadCSV?: () => void;
}

const TableHeader: FC<TableHeaderProps> = ({ title, description, handleDownloadCSV }) => (
  <div className={styles.header}>
    <div className={styles.titleContainer}>
      <h4 className={styles.title}>{title}</h4>
      <QuestionIcon tooltip={<p className={styles.tip}>{description}</p>} size={24} />
      {handleDownloadCSV && (
        <ButtonWithImage title="Download CSV" onClick={() => handleDownloadCSV()} className={styles.downloadButton} />
      )}
    </div>
  </div>
);

export default TableHeader;
