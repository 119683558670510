import { Middleware } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';
import AccountDetailsActionType from 'AccountDetails/ActionTypes';
import {
  enablePhoneAuthenticationFailure,
  enablePhoneAuthenticationSuccess,
} from 'AccountDetails/AccountDetailsActionCreator';
import { User } from 'AccountDetails/AccountDetailsTypes';

const EnablePhoneAuthenticationMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<User>(
    AccountDetailsActionType.EnablePhoneAuthentication,
    enablePhoneAuthenticationSuccess,
    enablePhoneAuthenticationFailure,
    ({ payload }) => {
      return api.enablePhoneAuthentication(payload.phone, payload.code);
    },
  );

export default EnablePhoneAuthenticationMiddleware;
