import {
  OPEN_GENERATE_SECOND_SECRET_WIZARD,
  CLOSE_GENERATE_SECOND_SECRET_WIZARD,
  NEXT_STEP_GENERATE_SECOND_SECRET,
} from './constants';

export const openGenerateSecondSecretWizard = () => {
  return {
    type: OPEN_GENERATE_SECOND_SECRET_WIZARD,
  };
};

export const closeGenerateSecondSecretWizard = () => {
  return {
    type: CLOSE_GENERATE_SECOND_SECRET_WIZARD,
  };
};

export const nextStep = () => {
  return {
    type: NEXT_STEP_GENERATE_SECOND_SECRET,
  };
};
