import React, { FC } from 'react';

import styles from './DeleteDecisionStrategyModule.module.scss';

import Button from 'components/Button';

interface DeleteDecisionStrategyModuleProps {
  deleteModule: (id: string, moduleLookupName: string) => Promise<void>;
  strategyId: string;
  moduleLookupName: string;
  closePopUp: () => void;
  isLoading?: boolean;
}

const DeleteDecisionStrategyModule: FC<DeleteDecisionStrategyModuleProps> = ({
  deleteModule,
  strategyId,
  moduleLookupName,
  closePopUp,
  isLoading,
}) => {
  return (
    <div className={styles.popUpBody}>
      <p className={styles.popUpBody__description}>Do you want to permanently delete this process module?</p>
      <div className={styles.popUpBody__button}>
        <Button
          kind="warning"
          size="form"
          onClick={() => deleteModule(strategyId, moduleLookupName)}
          isLoading={isLoading}
        >
          Delete
        </Button>
        <Button kind="secondary" size="form" onClick={closePopUp} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeleteDecisionStrategyModule;
