import clsx from 'clsx';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import styles from './HomeApplication.module.scss';

interface HomeApplicationCardProps {
  name: string;
  description: string;
  link: string;
  icon: any;
  disabled?: boolean;
}

const HomeApplicationCard: FC<HomeApplicationCardProps> = ({ name, description, link, icon, disabled = false }) => {
  return (
    <div className={clsx(styles.card, disabled && styles.disabledCard)}>
      <Link to={disabled ? '/home' : link}>
        {icon}
        <div>
          <h4>{name}</h4>
          <p>{description}</p>
        </div>
      </Link>
    </div>
  );
};

export default HomeApplicationCard;
