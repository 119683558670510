import React, { FC } from 'react';
import TableColumnSizes from 'components/DocuSign/RecipientsTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';

const SkeletonFieldItem: FC = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={TableColumnSizes.Recipient}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Variable}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonFieldItem;
