import { AnyAction } from 'redux';
import { OPEN_DELETE_BRANCH_WIZARD, CLOSE_DELETE_BRANCH_WIZARD } from './constants';
import { DeleteBranchWizardState } from 'components/DeleteBranchWizard/deleteBranchWizardTypes';
import { DELETE_BRANCH_SUCCESS } from 'BranchInfo/ActionTypes';

const initialState: DeleteBranchWizardState = {
  isOpen: false,
  strategyId: '',
  moduleKey: '',
  branchIndex: null,
};

const deleteBranchWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_DELETE_BRANCH_WIZARD: {
      return { isOpen: true, ...action.payload };
    }
    case CLOSE_DELETE_BRANCH_WIZARD: {
      return initialState;
    }
    case DELETE_BRANCH_SUCCESS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default deleteBranchWizardReducer;
