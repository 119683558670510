import Fetcher from 'middlewares/Fetcher';
import { GetEmailsDataSuccess } from 'LoanOriginationSystemEmails/ActionCreator';
import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { GetEmailsAction, getEmailsFailed, getEmailsSuccess } from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import { EMAILS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/EmailsDashboard/Pagination';

const GetEmailsMiddleware = (api: LoanOriginationSystemEmailsApi) =>
  Fetcher<GetEmailsDataSuccess, GetEmailsAction>(
    LoanOriginationSystemEmailsActionTypes.GetEmails,
    getEmailsSuccess,
    getEmailsFailed,
    async (action) => {
      const { filters, sortingType, applicationId } = action.payload;

      const response = await api.getEmails(
        {
          search: filters.searchInputValue,
          offset: 0,
          count: EMAILS_PER_PAGE_DEFAULT,
          createdAtDateRange: filters.createdAtRange,
          borrower: filters.selectedBorrower,
        },
        sortingType,
      );

      return {
        ...response,
        searchInputValue: filters.searchInputValue,
        applicationId,
      };
    },
  );

export default GetEmailsMiddleware;
