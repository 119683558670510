import React, { FC } from 'react';
import clsx from 'clsx';
import QuestionIcon from 'components/QuestionIcon';
import styles from './ProductConfigurationSubHeader.module.scss';

export interface ProductConfigurationSubHeaderProps {
  title: string;
  renderQuestionTooltip: () => React.ReactNode;
  className?: string;
  renderActions?: () => React.ReactNode;
}

const ProductConfigurationSubHeader: FC<ProductConfigurationSubHeaderProps> = ({
  renderActions,
  renderQuestionTooltip,
  title,
  className,
}) => (
  <div className={clsx(styles.subheader, className)}>
    <h4>{title}</h4>
    <QuestionIcon size={24} className={styles.questionTooltip} tooltip={renderQuestionTooltip()} />
    {renderActions?.()}
  </div>
);

export default ProductConfigurationSubHeader;
