import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { GetProductsAction, getProductsFailure, getProductsSuccess } from './ActionCreator';

const GetProductsMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product[], GetProductsAction>(
    LoanOriginationSystemProductsActionType.GetProducts,
    getProductsSuccess,
    getProductsFailure,
    (action) => {
      return api.getAll(action.payload);
    },
  );

export default GetProductsMiddleware;
