import React from 'react';
import styles from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TasksTable.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';
import { SkeletonCirclesList } from 'components/Skeleton';
import { TasksColumnSizes } from 'components/LoanOriginationSystem/TasksDashboard/TasksTable/TableColumnSizes';

const SkeletonTaskLine = () => {
  return (
    <TableRow disableHover useActions className={styles.tableRow}>
      <TableBodyCell width={TasksColumnSizes.Checkbox}>
        <SkeletonRectangle width="50%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TasksColumnSizes.Description}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TasksColumnSizes.ApplicationId}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TasksColumnSizes.BorrowerName}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TasksColumnSizes.TeamMembers} noPadding>
        <SkeletonCirclesList width="28px" height="28px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={TasksColumnSizes.DueDate}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonTaskLine;
