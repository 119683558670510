import React, { useMemo } from 'react';
import ApiRequestTemplatePopup from 'components/LoanOriginationSystem/ApiRequestTemplatePopup';
import { BorrowerApiRequestTemplateType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';

export interface BorrowerApiRequestsTemplatePopupProps {
  onDownload: (requestType: BorrowerApiRequestTemplateType) => void;
  onClose: () => void;
  isDownloadInProgress?: boolean;
}

enum BorrowerApiRequestInputType {
  RequestType = 'requestType',
}

const BorrowerApiRequestsTemplatePopup = ({
  onClose,
  onDownload,
  isDownloadInProgress,
}: BorrowerApiRequestsTemplatePopupProps) => {
  const apiRequestInputs = useMemo(
    () => [
      {
        label: 'API Request Type',
        type: BorrowerApiRequestInputType.RequestType,
        options: [
          {
            name: 'Create Borrower',
            value: BorrowerApiRequestTemplateType.CreateBorrower,
          },
          {
            name: 'Update Borrower',
            value: BorrowerApiRequestTemplateType.UpdateBorrower,
          },
        ],
      },
    ],
    [],
  );

  const handleDownload = (values: Record<string, string>) => {
    onDownload(values[BorrowerApiRequestInputType.RequestType] as BorrowerApiRequestTemplateType);
  };

  return (
    <ApiRequestTemplatePopup
      inputs={apiRequestInputs}
      title="Download API Request Template"
      onClose={onClose}
      onDownload={handleDownload}
      isDownloadInProgress={isDownloadInProgress}
    />
  );
};

export default BorrowerApiRequestsTemplatePopup;
