import React from 'react';
import clsx from 'clsx';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import styles from 'components/LoanOriginationSystem/UserFilter/UserFilter.module.scss';
import { without } from 'lodash';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

const MAX_AVATARS_COUNT = 6;

interface UserFilterProps {
  users: UserInfo[];
  selectedUsers: UserInfo[];
  onChange: (members: UserInfo[]) => void;
  className?: string;
}

const UserFilter = ({ users, selectedUsers, onChange, className }: UserFilterProps) => {
  const clearUserFilter = () => onChange([]);

  const onSelect = (user: UserInfo) => {
    const userToRemove = selectedUsers.find(({ id }) => id === user.id);
    const selectedUsersList = userToRemove ? without(selectedUsers, userToRemove) : [...selectedUsers, user];

    onChange(selectedUsersList);
  };

  return (
    <div className={clsx(styles.userFilter, className)}>
      <UserAvatarInlineList
        className={styles.userFilter__people}
        source={users}
        maxCount={MAX_AVATARS_COUNT}
        selectedUsers={selectedUsers}
        onSelect={onSelect}
      />
      {selectedUsers.length > 0 && (
        <button type="button" className={styles.userFilter__clearAll} onClick={clearUserFilter}>
          Clear All
        </button>
      )}
    </div>
  );
};

export default UserFilter;
