import { FailedAction } from 'utils/actions/FailedActionCreator';
import withStateReset from 'utils/reducers/withStateReset';
import { ApplicationDocumentsSortingField } from 'api/LoanOriginationSystem/DocumentsApi';
import applicationDocumentsPagination, {
  APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT,
} from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/pagination';
import {
  CloseRenameApplicationDocumentPopupAction,
  CloseRequestCustomerUploadPopupAction,
  CloseUploadApplicationDocumentPopupAction,
  CreateDocuSignEnvelopeAction,
  CreateDocuSignEnvelopeSuccessAction,
  DeleteApplicationDocumentAction,
  DeleteApplicationDocumentSuccessAction,
  DownloadApplicationDocumentAction,
  DownloadApplicationDocumentFailureAction,
  DownloadApplicationDocumentSuccessAction,
  DownloadArchiveOfApplicationDocumentsAction,
  DownloadArchiveOfApplicationDocumentsFailureAction,
  DownloadArchiveOfApplicationDocumentsSuccessAction,
  GetApplicationDocumentsSuccessAction,
  OpenRenameApplicationDocumentPopupAction,
  OpenRequestCustomerUploadPopupAction,
  OpenUploadApplicationDocumentPopupAction,
  RemoveDownloadedDocumentAction,
  RenameApplicationDocumentAction,
  RenameApplicationDocumentSuccessAction,
  SetApplicationDocumentToDeleteAction,
  SetSearchValueAction,
  SortApplicationDocumentsAction,
  ToggleSendDocuSignSignaturePopupAction,
  UploadApplicationDocumentAction,
  UploadApplicationDocumentSuccessAction,
} from './ActionCreator';
import { ApplicationDocumentsState } from './Types';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { AnyAction } from 'redux';

export type ApplicationDocumentsActions =
  | GetApplicationDocumentsSuccessAction
  | UploadApplicationDocumentAction
  | UploadApplicationDocumentSuccessAction
  | OpenUploadApplicationDocumentPopupAction
  | CloseUploadApplicationDocumentPopupAction
  | SetSearchValueAction
  | SortApplicationDocumentsAction
  | SetApplicationDocumentToDeleteAction
  | DeleteApplicationDocumentAction
  | DeleteApplicationDocumentSuccessAction
  | OpenRenameApplicationDocumentPopupAction
  | RenameApplicationDocumentAction
  | RenameApplicationDocumentSuccessAction
  | CloseRenameApplicationDocumentPopupAction
  | FailedAction<ApplicationDocumentsActionType.RenameApplicationDocumentFailure>
  | OpenRequestCustomerUploadPopupAction
  | CloseRequestCustomerUploadPopupAction
  | FailedAction<ApplicationDocumentsActionType.DeleteApplicationDocumentFailure>
  | FailedAction<ApplicationDocumentsActionType.UploadApplicationDocumentFailure>
  | DownloadApplicationDocumentAction
  | DownloadApplicationDocumentSuccessAction
  | DownloadApplicationDocumentFailureAction
  | RemoveDownloadedDocumentAction
  | DownloadArchiveOfApplicationDocumentsAction
  | DownloadArchiveOfApplicationDocumentsSuccessAction
  | DownloadArchiveOfApplicationDocumentsFailureAction
  | ToggleSendDocuSignSignaturePopupAction
  | CreateDocuSignEnvelopeAction
  | CreateDocuSignEnvelopeSuccessAction
  | FailedAction<ApplicationDocumentsActionType.CreateDocuSignEnvelopeFailure>;

type LoanOriginationSystemDocumentsType = (
  state: ApplicationDocumentsState,
  action: AnyAction,
) => ApplicationDocumentsState;

export const initialState: ApplicationDocumentsState = {
  search: '',
  items: [],
  page: 1,
  itemsPerPage: APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
  error: null,
  sortingType: {
    field: ApplicationDocumentsSortingField.CreatedAt,
    ascending: false,
  },
  searched: {},
  documentToDelete: null,
  documentCreatePopupOpen: false,
  documentToRename: null,
  renameDocumentPopupOpen: false,
  renameDocumentInProgress: false,
  documentUploadingInProgress: false,
  documentDeletingInProgress: false,
  documentRequestCustomerUploadPopUpOpen: false,
  downloadableDocuments: {},
  isDocuSignSignatureRequestPopupOpen: false,
  isCreatingDocuSignEnvelopeInProgress: false,
};

const applicationDocumentsReducer = (
  state: ApplicationDocumentsState = initialState,
  action: ApplicationDocumentsActions,
): ApplicationDocumentsState => {
  switch (action.type) {
    case ApplicationDocumentsActionType.GetApplicationDocumentsSuccess: {
      if (action.payload.search) {
        return {
          ...state,
          searched: {
            ...state.searched,
            [state.search]: {
              items: action.payload.items,
              itemsTotal: action.payload.total,
              page: 1,
              itemsPerPage: APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        page: 1,
        itemsPerPage: state.itemsPerPage || APPLICATION_DOCUMENTS_PER_PAGE_DEFAULT,
        error: '',
        items: action.payload.items,
        itemsTotal: action.payload.total,
      };
    }
    case ApplicationDocumentsActionType.UploadApplicationDocument: {
      return {
        ...state,
        documentUploadingInProgress: true,
      };
    }
    case ApplicationDocumentsActionType.UploadApplicationDocumentSuccess: {
      return {
        ...state,
        documentUploadingInProgress: false,
        documentCreatePopupOpen: false,
      };
    }
    case ApplicationDocumentsActionType.UploadApplicationDocumentFailure: {
      return {
        ...state,
        documentUploadingInProgress: false,
      };
    }
    case ApplicationDocumentsActionType.OpenApplicationDocumentUploadPopup: {
      return {
        ...state,
        documentCreatePopupOpen: true,
      };
    }
    case ApplicationDocumentsActionType.CloseApplicationDocumentUploadPopup: {
      return {
        ...state,
        documentCreatePopupOpen: false,
      };
    }
    case ApplicationDocumentsActionType.OpenRenameApplicationDocumentPopup: {
      return {
        ...state,
        renameDocumentPopupOpen: true,
        documentToRename: action.payload,
      };
    }
    case ApplicationDocumentsActionType.CloseRenameApplicationDocumentPopup: {
      return {
        ...state,
        renameDocumentPopupOpen: false,
        renameDocumentInProgress: false,
        documentToRename: null,
      };
    }
    case ApplicationDocumentsActionType.RenameApplicationDocument: {
      return {
        ...state,
        renameDocumentInProgress: true,
      };
    }
    case ApplicationDocumentsActionType.RenameApplicationDocumentFailure: {
      return {
        ...state,
        renameDocumentInProgress: false,
      };
    }
    case ApplicationDocumentsActionType.RenameApplicationDocumentSuccess: {
      return {
        ...state,
        renameDocumentInProgress: false,
        renameDocumentPopupOpen: false,
      };
    }
    case ApplicationDocumentsActionType.SetSearchValue: {
      return {
        ...state,
        search: action.payload.search,
      };
    }
    case ApplicationDocumentsActionType.SortApplicationDocuments: {
      return {
        ...state,
        sortingType: action.payload.sortingType,
      };
    }
    case ApplicationDocumentsActionType.SetApplicationDocumentToDelete: {
      return {
        ...state,
        documentToDelete: action.payload.document,
      };
    }
    case ApplicationDocumentsActionType.DeleteApplicationDocument: {
      return {
        ...state,
        documentDeletingInProgress: true,
      };
    }
    case ApplicationDocumentsActionType.DeleteApplicationDocumentSuccess: {
      return {
        ...state,
        documentDeletingInProgress: false,
        documentToDelete: null,
      };
    }
    case ApplicationDocumentsActionType.DeleteApplicationDocumentFailure: {
      return {
        ...state,
        documentDeletingInProgress: false,
      };
    }
    case ApplicationDocumentsActionType.OpenRequestCustomerUploadPopup: {
      return {
        ...state,
        documentRequestCustomerUploadPopUpOpen: true,
      };
    }
    case ApplicationDocumentsActionType.CloseRequestCustomerUploadPopup: {
      return {
        ...state,
        documentRequestCustomerUploadPopUpOpen: false,
      };
    }
    case ApplicationDocumentsActionType.DownloadDocument: {
      const { documentId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [documentId]: 'loading',
        },
      };
    }
    case ApplicationDocumentsActionType.DownloadDocumentSuccess: {
      const { documentDownloadingId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [documentDownloadingId]: 'success',
        },
      };
    }
    case ApplicationDocumentsActionType.DownloadDocumentFailure: {
      const { documentDownloadingId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [documentDownloadingId]: 'failure',
        },
      };
    }
    case ApplicationDocumentsActionType.RemoveDownloadedDocument: {
      const { documentDownloadingId } = action.payload;
      const updatedDocumentList = state.downloadableDocuments;
      delete updatedDocumentList[documentDownloadingId];

      return {
        ...state,
        downloadableDocuments: updatedDocumentList,
      };
    }
    case ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocuments: {
      const { applicationId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [applicationId]: 'loading',
        },
      };
    }
    case ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsSuccess: {
      const { documentDownloadingId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [documentDownloadingId]: 'success',
        },
      };
    }
    case ApplicationDocumentsActionType.DownloadArchiveOfApplicationDocumentsFailure: {
      const { documentDownloadingId } = action.payload;
      return {
        ...state,
        downloadableDocuments: {
          ...state.downloadableDocuments,
          [documentDownloadingId]: 'failure',
        },
      };
    }
    case ApplicationDocumentsActionType.ToggleSendDocuSignSignaturePopup: {
      return {
        ...state,
        isDocuSignSignatureRequestPopupOpen: !state.isDocuSignSignatureRequestPopupOpen,
      };
    }
    case ApplicationDocumentsActionType.CreateDocuSignEnvelope: {
      return {
        ...state,
        isCreatingDocuSignEnvelopeInProgress: true,
      };
    }
    case ApplicationDocumentsActionType.CreateDocuSignEnvelopeSuccess: {
      return {
        ...state,
        isCreatingDocuSignEnvelopeInProgress: false,
        isDocuSignSignatureRequestPopupOpen: false,
      };
    }
    case ApplicationDocumentsActionType.CreateDocuSignEnvelopeFailure: {
      return {
        ...state,
        isCreatingDocuSignEnvelopeInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationDocumentsPagination.wrapReducer<ApplicationDocumentsState>(
  withStateReset(
    applicationDocumentsReducer,
    ApplicationDocumentsActionType.ResetState,
    () => initialState,
  ) as LoanOriginationSystemDocumentsType,
);
