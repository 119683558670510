import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styles from './ModuleList.module.scss';
import Module from './Module';
import { ModuleBranch, StrategyModule } from 'DecisionStrategy/DecisionStrategiesTypes';
import { DraggableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import DndList from 'components/DndList';
import { ModuleListConnectedProps } from 'components/StrategyOverview/DecisionProcess/ModuleList/ModuleListConnector';
import { findIndex, find } from 'lodash';
import arrayMove from 'utils/arrayMove';
import { ModuleProps } from 'components/StrategyOverview/DecisionProcess/ModuleList/Module/Module';

export interface ModuleListProps {
  strategyId: string;
  isStrategyLocked: boolean;
  openDeleteModulePopUp: (moduleLookupName: string, moduleIndex: number) => void;
  currentModuleKey: string;
  currentBranchIndex: number;
  collapsedModuleList: boolean[];
  changeModuleCollapseState: (moduleIndex: number, isCollapsed: boolean) => void;
  onAddNewModule: (index: number) => void;
  addedModuleIndex: string;
  onEditModuleBranch: ModuleProps['onEditBranch'];
}

const ALL_EMPTY_SPACES_REGEXP = / /g;

const ModuleList: FC<ModuleListProps & ModuleListConnectedProps> = ({
  strategyId,
  isStrategyLocked,
  openDeleteModulePopUp,
  currentModuleKey,
  currentBranchIndex,
  reorderModuleBranches,
  strategies,
  reorderModules,
  collapsedModuleList,
  changeModuleCollapseState,
  onAddNewModule,
  addedModuleIndex,
  onEditModuleBranch,
}) => {
  const [moduleList, setModuleList] = useState<StrategyModule[]>([]);
  const [branchesList, setBranchesList] = useState<{ [moduleKey: string]: ModuleBranch[] }>({});

  useEffect(() => {
    const strategyInfo = strategies ? strategies[strategyId] : null;
    const list = strategyInfo?.moduleList || [];

    setModuleList(list);
    const branchesListData = list.reduce((result, { moduleId, branches }) => {
      return {
        ...result,
        [moduleId]: branches,
      };
    }, {});
    setBranchesList(branchesListData);
  }, [strategies]);

  const handleReorder = (reorderedItems: StrategyModule[]): void => {
    const updatedModuleList = reorderedItems.map((module: StrategyModule, index: number) => {
      const correctModuleName = module.name.toLocaleLowerCase().replace(ALL_EMPTY_SPACES_REGEXP, '_');
      const updatedModuleKey = `${correctModuleName}_${module.type}_${index}`;
      return {
        ...module,
        lookupName: updatedModuleKey,
        moduleId: updatedModuleKey,
      };
    });
    const moduleIndices = updatedModuleList.map(({ id }) => findIndex(moduleList, { id }));
    setModuleList(updatedModuleList);
    reorderModules(strategyId, updatedModuleList, moduleIndices);
  };

  const handleBranchReorder = (dropResult: DropResult) => {
    const moduleKey = dropResult.source.droppableId;
    const module = find(moduleList, { lookupName: moduleKey })!;
    const updatedBranchList = arrayMove(module.branches, dropResult.source.index, dropResult.destination!.index);
    const moduleBranchIndices = updatedBranchList.map(({ id }) => findIndex(module.branches, { id }));
    const updatedModuleList = moduleList.map((moduleData) => {
      if (moduleData.lookupName === moduleKey) {
        return {
          ...moduleData,
          branches: updatedBranchList,
        };
      }
      return moduleData;
    });

    setModuleList(updatedModuleList);
    setBranchesList({
      ...branchesList,
      [moduleKey]: updatedBranchList,
    });
    reorderModuleBranches(strategyId, moduleKey, moduleBranchIndices);
  };

  const renderModule = (
    module: StrategyModule,
    index: number,
    draggableSnapshot: DraggableStateSnapshot,
    placeholderStyles?: CSSProperties | null,
  ) => {
    return (
      <>
        <Module
          key={module.id}
          strategyId={strategyId}
          isStrategyLocked={isStrategyLocked}
          module={module}
          branches={branchesList[module.lookupName] || []}
          openDeleteModulePopUp={openDeleteModulePopUp}
          isDraggable={draggableSnapshot.isDragging}
          currentModuleKey={currentModuleKey}
          currentBranchIndex={currentBranchIndex}
          listPlaceholderStyles={placeholderStyles}
          isModuleCollapsed={collapsedModuleList[index]}
          changeModuleCollapseState={changeModuleCollapseState}
          moduleIndex={index}
          onAddNewModule={() => onAddNewModule(index + 1)}
          addedModuleIndex={addedModuleIndex}
          onEditBranch={onEditModuleBranch}
        />
      </>
    );
  };

  return (
    <DndList
      handleReorder={handleReorder}
      items={moduleList}
      droppableId="moduleList"
      listClassName={styles.modulesListContainer}
      renderListItem={renderModule}
      handleChildListReorder={handleBranchReorder}
      withPlaceholder
      renderInPortal
    />
  );
};

export default ModuleList;
