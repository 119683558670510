import React, { FC, useEffect, ReactNode, useRef, useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import useCachedForTransition from 'hooks/cachedForTransition';
import styles from './TransitionComponent.module.scss';
import { useDynamicSize } from 'components/DynamicSize';

interface TransitionComponentProps {
  children: ReactNode;
  useHeightRecalculation?: boolean;
  className?: string;
  animateOnMount?: boolean;
}

const DEFAULT_INITIAL_HEIGHT = 0;

const TransitionComponent: FC<TransitionComponentProps> = ({
  className,
  children,
  useHeightRecalculation,
  animateOnMount = true,
}) => {
  const [visibleChildren, onTransitionEnd] = useCachedForTransition<ReactNode>(children);
  const childrenContainerRef = useRef<HTMLDivElement>(null);
  const rafRef = useRef<number | null>(null);
  const [blockHeight, setBlockHeight] = useState<number | undefined>(
    animateOnMount ? DEFAULT_INITIAL_HEIGHT : undefined,
  );

  const getContainerHeight = () => {
    if (!children) {
      return 0;
    }

    const contextualViewWrapperElement = childrenContainerRef.current!;
    const contextualViewElement = contextualViewWrapperElement.children[0];
    const rect = (contextualViewElement && contextualViewElement.getBoundingClientRect()) || {};

    return rect.height || 0;
  };

  const recalculateHeight = () => {
    setBlockHeight(getContainerHeight());

    rafRef.current = window.requestAnimationFrame(recalculateHeight);
  };

  // Use useLayoutEffect here, to guarantee that cancelAnimationFrame will cancel correct frame id.
  useLayoutEffect(() => {
    if (useHeightRecalculation && children) {
      rafRef.current = window.requestAnimationFrame(recalculateHeight);
    }

    return () => {
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [children]);

  const DynamicSize = useDynamicSize();

  useEffect(() => {
    setBlockHeight(getContainerHeight());
  }, [children]);

  const handleTransitionEnd = (event: React.TransitionEvent<HTMLElement>) => {
    onTransitionEnd(event);
    DynamicSize.trigger();
  };

  return (
    <div
      className={clsx(styles.container, className)}
      onTransitionEnd={handleTransitionEnd}
      style={{
        height: blockHeight,
      }}
    >
      <div ref={childrenContainerRef}>
        <DynamicSize.Provider>{visibleChildren}</DynamicSize.Provider>
      </div>
    </div>
  );
};

export default TransitionComponent;
