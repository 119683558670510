import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { DeleteTaskSuccessAction, getTasks } from './ActionCreator';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import getMessage, { MessageType } from 'constants/messages';

const isDeleteTaskSuccessAction = (action: AnyAction): action is DeleteTaskSuccessAction =>
  action.type === LoanOriginationSystemTasksActionTypes.DeleteTaskSuccess;

const DeleteTaskSuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteTaskSuccessAction(action)) {
    const { loanOriginationSystemTasks } = getState();

    const { filters, sortingType, selectedApplicationId: applicationId } = loanOriginationSystemTasks;

    dispatch(getTasks({ filters, sortingType, applicationId }));

    notification.createNotification(getMessage(MessageType.TaskDeleted), 'success', dispatch);
  }

  return result;
};

export default DeleteTaskSuccessMiddleware;
