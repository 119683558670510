import React, { FC } from 'react';
import clsx from 'clsx';
import TransitionComponent from 'components/HeightTransitionComponent';
import QuestionIcon from 'components/QuestionIcon';
import { ChevronIcon } from 'static/images';
import styles from './ApplicationStatusCardExpandSection.module.scss';

export interface ApplicationStatusCardExpandSectionProps {
  title: string;
  expanded: boolean;
  onExpandClick: () => void;
  tooltip?: React.ReactNode;
  transitionContainerClassName?: string;
  animateOnMount?: boolean;
}

const ApplicationStatusCardExpandSection: FC<ApplicationStatusCardExpandSectionProps> = ({
  title,
  expanded,
  children,
  onExpandClick,
  tooltip,
  transitionContainerClassName,
  animateOnMount,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header} onClick={onExpandClick}>
        <h6>{title}</h6>
        {tooltip && <QuestionIcon tooltipPosition="top" className={styles.questionIcon} size={20} tooltip={tooltip} />}
        <ChevronIcon className={clsx(styles.chevronIcon, expanded && styles.expandedChevronIcon)} />
      </div>
      <TransitionComponent animateOnMount={animateOnMount} className={transitionContainerClassName}>
        {expanded && <div className={styles.expandSectionContent}>{children}</div>}
      </TransitionComponent>
    </div>
  );
};

export default ApplicationStatusCardExpandSection;
