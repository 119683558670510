import { EventEmitter } from 'events';

export type UnsubscribeCallback = () => void;

export default class EventEmitterWithUnsubscribeCallback {
  private eventEmitter: EventEmitter;

  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  on(event: string, listener: (...args: any[]) => void): UnsubscribeCallback {
    this.eventEmitter.on(event, listener);

    return () => this.eventEmitter.off(event, listener);
  }

  emit(event: string, ...args: any[]) {
    this.eventEmitter.emit(event, ...args);
  }
}
