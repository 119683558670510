import React, { useState } from 'react';
import { CheckedImage } from 'static/images';
import { DocumentExtension } from 'api/LoanOriginationSystem/DocumentsApi';
import UploadFile from 'components/UploadFile';
import Button from 'components/Button';
import styles from './UploadApplicationDocumentForm.module.scss';
import clsx from 'clsx';

interface UploadApplicationDocumentFormProps {
  buttonText: string;
  onApplicationDocumentUpload: (file: File) => Promise<void>;
  buttonFullWidth?: boolean;
}

const acceptFileTypes = Object.values(DocumentExtension)
  .map((extension) => `.${extension}`)
  .join(',');

const requirements = [
  'The following file formats are accepted: .pdf, .txt, .doc, .docx, .xls, .csv, .xlsx, .ppt, .pptx, .png, .jpg, .gif',
  'The maximum file size is 25MB',
];

const MAX_FILE_SIZE = 1024 * 1024 * 25; // 25 Mb

const UploadApplicationDocumentForm = ({
  onApplicationDocumentUpload,
  buttonText,
  buttonFullWidth,
}: UploadApplicationDocumentFormProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploadingInProgress, setIsUploadingInProgress] = useState(false);

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  const handleDocumentUpload = async () => {
    if (!file) {
      return;
    }

    try {
      setIsUploadingInProgress(true);
      await onApplicationDocumentUpload(file);

      setFile(null);
    } finally {
      setIsUploadingInProgress(false);
    }
  };

  const renderRequirement = (requirement: string, index: number) => (
    <li key={index} className={styles.requirement}>
      <CheckedImage className={styles.checkedIcon} />
      {requirement}
    </li>
  );

  return (
    <div>
      <UploadFile maxFileSize={MAX_FILE_SIZE} accept={acceptFileTypes} file={file} onFileChange={handleFileChange} />
      <ul className={styles.requirements}>{requirements.map(renderRequirement)}</ul>
      <Button
        disabled={!file}
        onClick={handleDocumentUpload}
        kind="primary"
        size="form"
        isLoading={isUploadingInProgress}
        className={clsx(buttonFullWidth && styles.buttonFullWidth)}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default UploadApplicationDocumentForm;
