import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import {
  ApplicationHistoryFilterType,
  ApplicationHistoryRecord,
  ApplicationHistorySortingType,
  ApplicationHistoryType,
  BaseApplicationHistoryFilters,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { ApplicationHistoryActionType } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetApplicationHistoryFilters {
  selectedMembers: UserInfo[];
  selectedTypes: ApplicationHistoryType;
}

export interface GetApplicationHistoryAction extends AnyAction {
  type: ApplicationHistoryActionType.GetApplicationHistory;
  payload: {
    filters: BaseApplicationHistoryFilters;
    sortingType?: ApplicationHistorySortingType;
  };
}

export interface GetApplicationHistorySuccessAction extends AnyAction {
  type: ApplicationHistoryActionType.GetApplicationHistorySuccess;
  payload: TableViewData<ApplicationHistoryRecord>;
}

export interface ChangeSortingTypeAction extends AnyAction {
  type: ApplicationHistoryActionType.ChangeSortingType;
  payload: {
    applicationId: string;
    sortingType: ApplicationHistorySortingType;
  };
}

export interface ChangeSelectedMembersAction extends AnyAction {
  type: ApplicationHistoryActionType.ChangeSelectedMembers;
  payload: {
    applicationId: string;
    selectedMembers: UserInfo[];
  };
}

export interface ChangeFilterTypeAction extends AnyAction {
  type: ApplicationHistoryActionType.ChangeFilterType;
  payload: {
    applicationId: string;
    filterType: ApplicationHistoryFilterType;
  };
}

export const getApplicationHistory = (
  filters: BaseApplicationHistoryFilters,
  sortingType?: ApplicationHistorySortingType,
): GetApplicationHistoryAction => ({
  type: ApplicationHistoryActionType.GetApplicationHistory,
  payload: {
    filters,
    sortingType,
  },
});

export const getApplicationHistorySuccess = (
  data: TableViewData<ApplicationHistoryRecord>,
): GetApplicationHistorySuccessAction => ({
  type: ApplicationHistoryActionType.GetApplicationHistorySuccess,
  payload: data,
});

export const changeSortingType = (
  applicationId: string,
  sortingType: ApplicationHistorySortingType,
): ChangeSortingTypeAction => ({
  type: ApplicationHistoryActionType.ChangeSortingType,
  payload: {
    sortingType,
    applicationId,
  },
});

export const changeSelectedMembers = (applicationId: string, members: UserInfo[]): ChangeSelectedMembersAction => ({
  type: ApplicationHistoryActionType.ChangeSelectedMembers,
  payload: {
    applicationId,
    selectedMembers: members,
  },
});

export const changeFilterType = (
  applicationId: string,
  filterType: ApplicationHistoryFilterType,
): ChangeFilterTypeAction => ({
  type: ApplicationHistoryActionType.ChangeFilterType,
  payload: {
    applicationId,
    filterType,
  },
});

export const getApplicationHistoryFailure = FailedActionCreator(
  ApplicationHistoryActionType.GetApplicationHistoryFailure,
);
