import { ApiModuleBranch, DecisionStrategy, Module } from 'api/DecisionEngineStrategiesType';
import { ModuleBranch, StrategyModule } from 'DecisionStrategy/DecisionStrategiesTypes';
import { uniqueId } from 'lodash';

const formattedStrategyModules = (data: DecisionStrategy) => {
  const modules: StrategyModule[] = data.moduleRuns.map((module: Module) => {
    const branchesList: ApiModuleBranch[] = data.modules[module.lookup_name] || [];
    const moduleBranches: ModuleBranch[] = branchesList.map((branch: ApiModuleBranch) => {
      return {
        id: uniqueId(),
        name: branch.display_name,
        description: branch.description,
      };
    });

    return {
      id: uniqueId(),
      active: module.active,
      description: module.description,
      name: module.display_name,
      lookupName: module.lookup_name,
      branches: moduleBranches,
      type: module.type,
      moduleId: module.lookup_name,
    };
  });

  return modules;
};

export default formattedStrategyModules;
