const sumVerticalOffsets = (total: number, element: Element) => {
  const style = window.getComputedStyle(element);
  const marginBottom = parseFloat(style.marginBottom);

  return total + element.getBoundingClientRect().height + marginBottom;
};

const sumHorizontalOffsets = (total: number, element: Element) => {
  const style = window.getComputedStyle(element);
  const marginRight = parseFloat(style.marginRight);

  return total + element.getBoundingClientRect().width + marginRight;
};

const calculatePlaceholderOffset = (
  listContainer: Element,
  draggedItem: Element,
  destinationIndex: number,
  sourceDestinationIndex: number,
  sumOffsets: (total: number, element: Element) => number,
) => {
  const elementsArray = Array.from(listContainer.children);
  const filteredElementsArray = elementsArray.includes(draggedItem)
    ? elementsArray.filter((element, index) => index !== sourceDestinationIndex)
    : elementsArray;

  return filteredElementsArray.slice(0, destinationIndex).reduce(sumOffsets, 0);
};

export const calculateVerticalPlaceholderOffset = (
  listContainer: Element,
  draggedItem: Element,
  destinationIndex: number,
  sourceDestinationIndex: number,
) => {
  return (
    parseFloat(window.getComputedStyle(listContainer).paddingTop) +
    calculatePlaceholderOffset(listContainer, draggedItem, destinationIndex, sourceDestinationIndex, sumVerticalOffsets)
  );
};

export const calculateHorizontalPlaceholderOffset = (
  listContainer: Element,
  draggedItem: Element,
  destinationIndex: number,
  sourceDestinationIndex: number,
) => {
  return (
    parseFloat(window.getComputedStyle(listContainer).paddingLeft) +
    calculatePlaceholderOffset(
      listContainer,
      draggedItem,
      destinationIndex,
      sourceDestinationIndex,
      sumHorizontalOffsets,
    )
  );
};
