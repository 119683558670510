import { AnyAction } from 'redux';
import {
  ProductSection,
  ProductSectionAccessPermissions,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import ActionType from './ProductSectionAccessPermissionsActionTypes';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import FailedActionCreator, { FailedAction } from 'utils/actions/FailedActionCreator';

export interface GetOrganizationPermissionsSuccessAction extends AnyAction {
  type: ActionType.GetProductSectionAccessPermissionsSuccess;
  payload: ProductSectionAccessPermissions[];
}

export interface UpdateOrganizationPermissionRequestAction extends AnyAction {
  type: ActionType.UpdateProductSectionAccessPermissionRequest;
  payload: {
    userRole: UserRoleName;
    productSection: ProductSection;
    value: boolean;
  };
}

export interface UpdateOrganizationPermissionSuccessAction extends AnyAction {
  type: ActionType.UpdateProductSectionAccessPermissionSuccess;
  payload: ProductSectionAccessPermissions & { productSection: ProductSection };
}

export interface UpdateProductSectionAccessPermissionFailureAction extends AnyAction {
  type: ActionType.UpdateProductSectionAccessPermissionFailure;
  payload: {
    userRole: UserRoleName;
    productSection: ProductSection;
  };
}

export interface ResetOrganizationPermissionUpdatingStateAction extends AnyAction {
  type: ActionType.ResetProductSectionAccessPermissionUpdatingState;
  payload: {
    userRole: UserRoleName;
    productSection: ProductSection;
  };
}

export interface ResetPermissionsRequestAction extends AnyAction {
  type: ActionType.ResetPermissionsRequest;
}

export interface OpenResetToDefaultsPopUpAction extends AnyAction {
  type: ActionType.OpenResetToDefaultsPopUp;
}

export interface CloseResetToDefaultsPopUpAction extends AnyAction {
  type: ActionType.CloseResetToDefaultsPopUp;
}

export type ProductSectionAccessPermissionsAction =
  | GetOrganizationPermissionsSuccessAction
  | UpdateOrganizationPermissionRequestAction
  | UpdateOrganizationPermissionSuccessAction
  | ResetPermissionsRequestAction
  | ResetOrganizationPermissionUpdatingStateAction
  | FailedAction<ActionType.GetProductSectionAccessPermissionsFailure>
  | UpdateProductSectionAccessPermissionFailureAction
  | FailedAction<ActionType.ResetPermissionsFailure>
  | OpenResetToDefaultsPopUpAction
  | CloseResetToDefaultsPopUpAction;

export const getOrganizationPermissionsRequest = (): AnyAction => {
  return {
    type: ActionType.GetProductSectionAccessPermissionsRequest,
  };
};

export const getOrganizationPermissionsSuccess = (
  permissions: ProductSectionAccessPermissions[],
): GetOrganizationPermissionsSuccessAction => {
  return {
    type: ActionType.GetProductSectionAccessPermissionsSuccess,
    payload: permissions,
  };
};

export const getOrganizationPermissionsError = FailedActionCreator(
  ActionType.GetProductSectionAccessPermissionsFailure,
);

export const updateOrganizationPermissionRequest = (
  userRole: UserRoleName,
  productSection: ProductSection,
  value: boolean,
): UpdateOrganizationPermissionRequestAction => {
  return {
    type: ActionType.UpdateProductSectionAccessPermissionRequest,
    payload: {
      userRole,
      productSection,
      value,
    },
  };
};

export const updateOrganizationPermissionSuccess = (
  permission: ProductSectionAccessPermissions,
  productSection: ProductSection,
): UpdateOrganizationPermissionSuccessAction => {
  return {
    type: ActionType.UpdateProductSectionAccessPermissionSuccess,
    payload: {
      ...permission,
      productSection,
    },
  };
};

export const updateProductSectionAccessPermissionFailure = (
  userRole: UserRoleName,
  productSection: ProductSection,
) => ({
  type: ActionType.UpdateProductSectionAccessPermissionFailure,
  payload: {
    userRole,
    productSection,
  },
});

export const resetPermissionUpdatingState = (
  userRole: UserRoleName,
  productSection: ProductSection,
): ResetOrganizationPermissionUpdatingStateAction => {
  return {
    type: ActionType.ResetProductSectionAccessPermissionUpdatingState,
    payload: {
      userRole,
      productSection,
    },
  };
};

export const resetPermissionsRequest = () => {
  return {
    type: ActionType.ResetPermissionsRequest,
  };
};

export const resetPermissionsSuccess = () => {
  return {
    type: ActionType.ResetPermissionsSuccess,
  };
};

export const resetPermissionsError = FailedActionCreator(ActionType.ResetPermissionsFailure);

export const openResetToDefaultsPopUp = (): OpenResetToDefaultsPopUpAction => {
  return {
    type: ActionType.OpenResetToDefaultsPopUp,
  };
};

export const closeResetToDefaultsPopUp = (): CloseResetToDefaultsPopUpAction => {
  return {
    type: ActionType.CloseResetToDefaultsPopUp,
  };
};
