import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import tap from 'lodash/tap';

import { LeftNavState } from './LeftNavTypes';

import { LEFT_NAV_TOGGLE_EXPAND } from './ActionTypes';

const LeftNavMiddleware: Middleware = ({ getState }: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (
  action: AnyAction,
) => {
  return tap(next(action), () => {
    if (action.type !== LEFT_NAV_TOGGLE_EXPAND) {
      return;
    }
    const {
      leftNavigation: { isLeftNavOpen },
    }: { leftNavigation: LeftNavState } = getState();
    localStorage.setItem('isLeftNavOpen', isLeftNavOpen ? 'open' : '');
  });
};

export default LeftNavMiddleware;
