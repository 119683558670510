import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { History } from 'history';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { LoanOriginationSystemCreateBorrowerTypes } from './ActionTypes';
import { CreateBorrowerSuccessAction } from './ActionCreator';

const CREATE_BORROWER_SUCCESS_MESSAGE = 'New borrower has been created.';

const isCreateBorrowerSuccessAction = (action: AnyAction): action is CreateBorrowerSuccessAction =>
  action.type === LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerSuccess;

const CreateBorrowerSuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateBorrowerSuccessAction(action)) {
    replace(`/los/borrowers`);

    const { loanOriginationSystemBorrowers } = getState();

    const { filters, sortingType } = loanOriginationSystemBorrowers;

    notification.createNotification(CREATE_BORROWER_SUCCESS_MESSAGE, 'success', dispatch);

    dispatch(getBorrowers(filters, sortingType));
  }

  return result;
};

export default CreateBorrowerSuccessMiddleware;
