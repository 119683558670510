import { AnyAction } from 'redux';
import { FormLayoutData } from 'api/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';

export enum UpdateBorrowerSuccessActionOrigin {
  BorrowerDetails = 'BorrowerDetails',
  CreateApplication = 'CreateApplication',
}

export interface UpdateBorrowerAction extends AnyAction {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrower;
  payload: {
    id: string;
    data: FormLayoutData;
  };
}

export interface UpdateBorrowerSuccessAction extends ActionWithOrigin<UpdateBorrowerSuccessActionOrigin> {
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess;
  payload: Borrower;
}

export const updateBorrower = (id: string, borrowerData: FormLayoutData): UpdateBorrowerAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrower,
  payload: {
    id,
    data: borrowerData,
  },
});

export const updateBorrowerSuccess = (newBorrower: Borrower): UpdateBorrowerSuccessAction => ({
  type: LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess,
  payload: newBorrower,
});

export const updateBorrowerFailure = FailedActionCreator(
  LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerFailure,
);
