import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ImportDocuSignTemplatesSuccessAction } from './ActionCreator';
import { DocuSignActionType } from './ActionTypes';

const isImportDocuSignTemplatesSuccessAction = (action: AnyAction): action is ImportDocuSignTemplatesSuccessAction =>
  action.type === DocuSignActionType.ImportDocuSignTemplatesSuccess;

const notifyOnSuccessTemplatesImportMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isImportDocuSignTemplatesSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.DocuSignTemplatesImported), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default notifyOnSuccessTemplatesImportMiddleware;
