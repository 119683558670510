import { openCreateNewStrategyVersionPopUp } from 'CreateNewStrategyVersion/CreateNewStrategyVersionActionCreator';
import { openArchiveVersionWizard } from 'components/ArchiveStrategyWizard/Actions';
import { openDeleteStrategyWizard } from 'DeleteStrategyWizard/Actions';
import RowActions from 'components/RowActions';
import React, { FC } from 'react';
import { StrategyGroupVersion } from 'api/Types';
import { NormalizedStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RowActionsProps } from 'components/RowActions/RowActions';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';
import { openDuplicateStrategyWizard } from 'DuplicateStrategyWizard/Actions';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';

interface VersionActionsProps extends Omit<RowActionsProps, 'actions'> {
  version: StrategyGroupVersion;
  origin: StrategyActionOrigin;
  btnClassName?: string;
  isCurrent?: boolean;
}

const VersionActions: FC<VersionActionsProps> = ({ version: versionData, origin, className, isCurrent, ...props }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id, status, version, displayTitle, hasModules } = versionData;
  const isArchived = getNormalizedStatus(status) === NormalizedStatus.Archive;

  const handleCreateNewVersion = () => {
    if (hasModules) {
      dispatch(openCreateNewStrategyVersionPopUp(id, displayTitle));
      return;
    }

    notification.createNotification(
      getMessage(MessageType.RestrictCreateNewVersionForEmptyStrategy),
      'warning',
      dispatch,
    );
  };

  return (
    <RowActions
      className={className}
      {...props}
      actions={[
        {
          title: 'Create New Version',
          handler: handleCreateNewVersion,
        },
        {
          title: 'Create Duplicate',
          handler: () => dispatch(openDuplicateStrategyWizard(id, displayTitle, origin)),
        },
        {
          title: `${isArchived ? 'Restore' : 'Archive'} Version`,
          handler: () => dispatch(openArchiveVersionWizard(id, displayTitle, isArchived, version, origin)),
        },
        {
          title: 'Edit Version',
          handler: () => history.push(`/decision/strategies/${id}/overview`),
          separatorRequired: true,
        },
        {
          title: 'Delete Version',
          handler: () =>
            dispatch(
              openDeleteStrategyWizard(
                'version',
                { id, name: displayTitle, version },
                StrategyActionOrigin.StrategyGroupVersionsList,
                isCurrent,
              ),
            ),
          danger: true,
        },
      ]}
    />
  );
};

export default VersionActions;
