import React from 'react';
import { Tag } from 'components/Tag';
import { Variable } from 'Variables/VariablesTypes';

interface VariableTypeTagProps {
  variable: Variable;
  medium?: boolean;
  className?: string;
}

const getText = (isStandardVariable: boolean, isFullText: boolean) => {
  if (isFullText) {
    return isStandardVariable ? 'Standard variable' : 'Custom variable';
  }
  return isStandardVariable ? 'Standard' : 'Custom';
};

const VariableTypeTag = ({ variable, medium = false, className }: VariableTypeTagProps) => {
  const { isStandard = false } = variable;

  return (
    <Tag color={variable.isStandard ? 'blue' : 'gray'} medium={medium} className={className}>
      {getText(isStandard, medium)}
    </Tag>
  );
};

export default VariableTypeTag;
