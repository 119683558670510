import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { SnackbarComponents, SnackbarComponentTypes, SnackType } from './Types';
import Snackbar from 'components/Snackbar';
import ApplicationDocumentDownloadSnackbar from 'components/ApplicationDocumentDownloadSnackbar';
import ApplicationEmailAttachmentsDownloadSnackbar from 'components/ApplicationEmailAttachmentsDownloadSnackbar';
import ApplicationSingleEmailAttachmentDownloadSnackbar from 'components/ApplicationSingleEmailAttachmentDownloadSnackbar';
import ApplicationDocumentsDownloadSnackbar from 'components/ApplicationDocumentsDownloadSnackbar';

const snackbarComponentsByType = {
  [SnackbarComponentTypes.ApplicationDownloadDocumentComponent]: ApplicationDocumentDownloadSnackbar,
  [SnackbarComponentTypes.ApplicationDownloadDocumentsComponent]: ApplicationDocumentsDownloadSnackbar,
  [SnackbarComponentTypes.ApplicationEmailAttachmentsComponent]: ApplicationEmailAttachmentsDownloadSnackbar,
  [SnackbarComponentTypes.ApplicationSingleEmailAttachmentComponent]: ApplicationSingleEmailAttachmentDownloadSnackbar,
};

const ConnectedSnackbar = () => {
  const snackbarList = useSelector<ReduxState, SnackType<SnackbarComponents>[]>((state) => state.snackbar);

  return <Snackbar customSnackbarComponents={snackbarComponentsByType} snackbarList={snackbarList} />;
};

export default ConnectedSnackbar;
