import React, { useEffect, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './TemplatesFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { TemplatesFilterOptions } from 'api/LoanOriginationSystem/SendGridTemplateApi';
import {
  resetAllTemplatesFilters,
  FiltersResult,
  toggleFiltersPopup,
  changePopupFilters,
} from 'SendGrid/Filters/ActionCreator';

interface TemplatesFilterProps {
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  dueUpdatedDateFrom: Date | null;
  dueUpdatedDateTo: Date | null;
  status: TemplatesFilterOptions | null;
}

const filterOptions = [
  { name: TemplatesFilterOptions.All, value: '' },
  { name: TemplatesFilterOptions.Active, value: TemplatesFilterOptions.Active },
  { name: TemplatesFilterOptions.Inactive, value: TemplatesFilterOptions.Inactive },
];

const TemplatesFilter = ({
  dueCreatedDateFrom,
  dueCreatedDateTo,
  dueUpdatedDateFrom,
  dueUpdatedDateTo,
  status,
}: TemplatesFilterProps) => {
  const dispatch = useDispatch();

  const [selectedFilterInputOption, setSelectedInputFilterOption] = useState(filterOptions[0]);

  const initialSelectedFilterOption = filterOptions.find((option) => (option.value || null) === status)!;

  useEffect(() => {
    setSelectedInputFilterOption(initialSelectedFilterOption);
  }, [status]);

  const [createdFromDate, setCreatedFromDate] = useState(dueCreatedDateFrom);
  const [createdToDate, setCreatedToDate] = useState(dueCreatedDateTo);
  const [updatedFromDate, setUpdatedFromDate] = useState(dueUpdatedDateFrom);
  const [updatedToDate, setUpdatedToDate] = useState(dueUpdatedDateTo);

  const filtersResult: FiltersResult = {
    dueCreatedDateRange: {
      from: createdFromDate,
      to: createdToDate,
    },
    dueUpdatedDateRange: {
      from: updatedFromDate,
      to: updatedToDate,
    },
    active: (selectedFilterInputOption.value as TemplatesFilterOptions) || null,
  };

  const onFilterChange = (newOption: Option) => {
    setSelectedInputFilterOption(filterOptions.find(({ value }) => value === newOption.value)!);
  };

  const onClearFiltersClick = () => {
    dispatch(resetAllTemplatesFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setCreatedFromDate(from);
    setCreatedToDate(to);
  };

  const handleOnUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setUpdatedFromDate(from);
    setUpdatedToDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Status"
        onChange={onFilterChange}
        options={filterOptions}
        selectedOption={selectedFilterInputOption}
        hideClearIcon
        className={styles.statusDropdownContainer}
      />
      <DateRangePicker
        from={createdFromDate}
        to={createdToDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Created"
      />
      <DateRangePicker
        from={updatedFromDate}
        to={updatedToDate}
        onChange={handleOnUpdatedDateRangeChange}
        label="Updated"
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default TemplatesFilter;
