import React from 'react';
import { AxisLeft } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { GridScale } from '@visx/grid/lib/types';
import { currencyFormatter } from 'components/LoanOriginationSystem/ReportingDashboard/Chart/utils';
import styles from 'components/LoanOriginationSystem/ReportingDashboard/Chart/Chart.module.scss';

interface LeftAxisProps {
  height: number;
  scale: GridScale;
}

const LeftAxis = ({ height, scale }: LeftAxisProps) => {
  return (
    <svg height={height} width={40}>
      <GridRows top={22} scale={scale} width={40} height={height} stroke="black" strokeOpacity={0.1} numTicks={4} />
      <AxisLeft
        left={8}
        top={-30}
        scale={scale}
        tickFormat={currencyFormatter('', true)}
        tickLabelProps={() => ({
          fill: styles.fontColor,
          fontSize: styles.fontSize,
          lineHeight: styles.lineHeight,
          textAnchor: 'start',
          fontFamily: styles.fontFamily,
          fontWeight: styles.fontWeight,
        })}
        hideAxisLine
        hideTicks
      />
    </svg>
  );
};

export default LeftAxis;
