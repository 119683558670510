import React, { FC } from 'react';
import { ESignTemplateField, ESignTemplateFieldType } from 'api/Core/ESignTemplatesApi';
import TableColumnSizes from 'components/DocuSign/FieldsTable/TableColumnSizes';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import OverflowedText from 'components/OverflowedText';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import styles from './FieldTableRow.module.scss';

interface FieldTableRowProps {
  field: ESignTemplateField;
  recipientNamesById: Record<string, string>;
  variablesMapping: Record<string, string>;
}

const getTypeDisplayName = (type: ESignTemplateFieldType) => {
  switch (type) {
    case ESignTemplateFieldType.Text: {
      return 'Text';
    }
    case ESignTemplateFieldType.Radio: {
      return 'Radio';
    }
    case ESignTemplateFieldType.Checkbox: {
      return 'Checkbox';
    }
    default: {
      return null as never;
    }
  }
};

const FieldTableRow: FC<FieldTableRowProps> = ({ field, variablesMapping, recipientNamesById }: FieldTableRowProps) => {
  const { name, fieldId, recipientId, type } = field;

  const variable = useVariableAttributes(variablesMapping[fieldId]);

  return (
    <TableRow disableHover>
      <TableBodyCell className={styles.nameCell} width={TableColumnSizes.Field}>
        <OverflowedText>{name}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Recipient}>
        <OverflowedText>{recipientId && recipientNamesById[recipientId]}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Type}>{getTypeDisplayName(type)}</TableBodyCell>
      <TableBodyCell width={TableColumnSizes.Variable}>
        <OverflowedText>{variable?.displayName}</OverflowedText>
      </TableBodyCell>
    </TableRow>
  );
};

export default FieldTableRow;
