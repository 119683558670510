import { createSelector } from 'reselect';
import { ReduxState } from 'types/redux';

export const getStatusesIdsByProductId = (state: ReduxState, productId?: string) => {
  return productId ? state.applicationStatusesData.statusesIdsByProductId[productId] : null;
};

export const getStatusesById = (state: ReduxState) => {
  return state.applicationStatusesData.statusesById;
};

export const getStatusesByProductId = createSelector(
  [getStatusesIdsByProductId, getStatusesById],
  (statusesIds, statusesById) => {
    if (!statusesIds) {
      return null;
    }

    return statusesIds
      .map((id) => statusesById[id])
      .sort((firstStatus, secondStatus) => {
        return firstStatus.position - secondStatus.position;
      });
  },
);
