import {
  getProductDataByViewType,
  SortColumnViewProductDataAction,
  SortTableViewProductDataAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import {
  LoanOriginationSystemApplicationsActionType,
  SORT_TABLE_VIEW_PRODUCT_DATA,
} from 'LoanOriginationSystemApplications/ActionTypes';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';

const isSortTableOrColumnViewProductDataAction = (
  action: AnyAction,
): action is SortTableViewProductDataAction | SortColumnViewProductDataAction =>
  action.type === SORT_TABLE_VIEW_PRODUCT_DATA ||
  action.type === LoanOriginationSystemApplicationsActionType.SortColumnViewProductData;

const sortProductDataMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: SortTableViewProductDataAction | SortColumnViewProductDataAction) => {
  const result = next(action);

  if (isSortTableOrColumnViewProductDataAction(action)) {
    const { loanOriginationSystemApplications } = getState();

    dispatch(
      getProductDataByViewType(
        loanOriginationSystemApplications.selectedProduct!.id,
        action.payload.viewType,
        loanOriginationSystemApplications.filters,
        action.payload,
      ),
    );
  }

  return result;
}) as Middleware;

export default sortProductDataMiddleware;
