import React, { FocusEvent } from 'react';
import TextInputWrapper, { TextInputWrapperProps } from 'components/TextInputWrapper/TextInputWrapper';
import { useTrimmedPhoneNumber } from './utils';
import useSamplePhoneNumber from 'components/PhoneNumberInput/useSamplePhoneNumber';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import countryCodeStyles from 'components/CountryCodeSelect/CountryCodeSelect.module.scss';
import PhoneInput, { flags } from 'components/PhoneInput';
import styles from 'components/PhoneNumberInput/PhoneNumberInput.module.scss';
import clsx from 'clsx';

interface PhoneNumberInputProps extends Omit<TextInputWrapperProps<any>, 'children'> {
  value: string;
  country: string | null;
  onChange: (value: string) => void;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  containerClassName?: string;
  withFlag?: boolean;
}

const PhoneNumberInput = ({
  value,
  country,
  onChange,
  onFocus,
  onBlur,
  disabled,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
  withFlag,
  ...wrapperProps
}: PhoneNumberInputProps) => {
  const placeholder = useSamplePhoneNumber(country);

  const [trimmedValue, handleChange] = useTrimmedPhoneNumber(value, onChange, country);
  const Flag = withFlag && country && flags[country];

  const focusInputElement = ({ currentTarget }: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    currentTarget.querySelector('input')!.focus();
  };

  return (
    <TextInputWrapper {...wrapperProps} disabled={disabled}>
      <div className={clsx(styles.container, disabled && styles.container__disabled)} onClick={focusInputElement}>
        {withFlag && Flag && <Flag className={countryCodeStyles.flag} />}
        <PhoneInput
          className={styles.phoneNumberInput}
          value={trimmedValue}
          placeholder={placeholder}
          country={country}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          tabIndex={tabIndex}
        />
        {showLoader && <LoaderWithState state={loaderState} onStateReset={onLoaderStateReset} />}
      </div>
    </TextInputWrapper>
  );
};

export default PhoneNumberInput;
