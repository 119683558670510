import React, { CSSProperties, ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface CenteredRelativeToVisibleAreaProps {
  children: ReactElement;
}

const SCROLL_EVENT_LISTENER_OPTIONS = { capture: true, passive: true };

interface Offset {
  top: number;
  left: number;
}

const INITIAL_OFFSET: Offset = {
  top: 0,
  left: 0,
};

const CenteredRelativeToVisibleArea = ({ children }: CenteredRelativeToVisibleAreaProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [offset, setOffset] = useState<Offset>(INITIAL_OFFSET);

  const measure = () => {
    const element = elementRef.current;

    if (!element?.parentElement) {
      return;
    }

    const { parentElement } = element;

    setOffset((prevOffset) => {
      const parentOffset = parentElement.getBoundingClientRect();
      const elementOffset = element.getBoundingClientRect();

      const { innerHeight, innerWidth } = parentElement.ownerDocument!.defaultView!;

      const top =
        innerHeight / 2 -
        (parentOffset.top < 0 ? parentOffset.top : parentOffset.top / 2) -
        (parentOffset.bottom < innerHeight ? (innerHeight - parentOffset.bottom) / 2 : 0) -
        elementOffset.height / 2;

      const left =
        innerWidth / 2 -
        (parentOffset.left < 0 ? parentOffset.left : parentOffset.left / 2) -
        (parentOffset.right < innerWidth ? (innerWidth - parentOffset.right) / 2 : 0) -
        elementOffset.width / 2;

      if (top === prevOffset.top && left === prevOffset.left) {
        return prevOffset;
      }

      return {
        top,
        left,
      };
    });
  };

  useLayoutEffect(measure);

  useEffect(() => {
    document.documentElement.addEventListener('scroll', measure, SCROLL_EVENT_LISTENER_OPTIONS);
    window.addEventListener('resize', measure);
    return () => {
      document.documentElement.removeEventListener('scroll', measure, SCROLL_EVENT_LISTENER_OPTIONS);
      window.removeEventListener('resize', measure);
    };
  }, []);

  const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    transform: `translate(${offset.left}px, ${offset.top}px)`,
  };

  return (
    <div ref={elementRef} style={style}>
      {children}
    </div>
  );
};

export default CenteredRelativeToVisibleArea;
