import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createIntermediaryVariableConfiguration,
  deleteIntermediaryVariableConfiguration,
  getIntermediaryVariableConfigurations,
  updateIntermediaryVariableConfiguration,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getIntermediaryVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { ReduxState } from 'types/redux';
import BaseVariablesConfigurationLayout from 'components/LoanOriginationSystem/VariablesConfiguration/BaseVariablesConfigurationLayout';
import IntermediaryVariablesConfigurationHeader from './IntermediaryVariablesConfigurationHeader';
import { Variable } from 'Variables/VariablesTypes';

export interface IntermediaryVariablesConfigurationProps {
  onDownloadApiTemplate: () => void;
}

const IntermediaryVariablesConfiguration = ({ onDownloadApiTemplate }: IntermediaryVariablesConfigurationProps) => {
  const dispatch = useDispatch();
  const intermediaryVariableConfigurations = useSelector((state: ReduxState) =>
    getIntermediaryVariableConfigurationsSelector(state, {}),
  );

  useEffect(() => {
    if (!intermediaryVariableConfigurations) {
      dispatch(getIntermediaryVariableConfigurations());
    }
  }, []);

  const handleVariableReorder = (id: string, column: number, position: number) => {
    dispatch(updateIntermediaryVariableConfiguration(id, { column, position }));
  };

  const handleVariableAdd = (column: number, position: number, variable: Variable) => {
    dispatch(createIntermediaryVariableConfiguration(column, position, variable));
  };

  const handleVariableDelete = (id: string) => {
    dispatch(deleteIntermediaryVariableConfiguration(id));
  };

  const renderHeader = () => <IntermediaryVariablesConfigurationHeader onDownloadApiTemplate={onDownloadApiTemplate} />;

  return (
    <BaseVariablesConfigurationLayout
      configurations={intermediaryVariableConfigurations}
      renderHeader={renderHeader}
      onVariableReorder={handleVariableReorder}
      onVariableDelete={handleVariableDelete}
      onVariableAdd={handleVariableAdd}
      formHeader="Intermediary"
      cardHeader="Intermediary Data Fields"
      formatDisplayTitle={formatDefaultFieldsDisplayTitle}
    />
  );
};

export default IntermediaryVariablesConfiguration;
