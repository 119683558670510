import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemEditBorrowerState } from './Types';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';
import { UpdateBorrowerAction, UpdateBorrowerSuccessAction } from './ActionCreator';

export const initialState: LoanOriginationSystemEditBorrowerState = {
  isUpdatingInProgress: false,
};

type LoanOriginationSystemEditBorrowerActions =
  | UpdateBorrowerAction
  | UpdateBorrowerSuccessAction
  | FailedAction<LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerFailure>;

export const loanOriginationSystemEditBorrowerReducer = (
  state = initialState,
  action: LoanOriginationSystemEditBorrowerActions,
) => {
  switch (action.type) {
    case LoanOriginationSystemEditBorrowerActionType.UpdateBorrower: {
      return {
        ...state,
        isUpdatingInProgress: true,
      };
    }
    case LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerSuccess: {
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    }
    case LoanOriginationSystemEditBorrowerActionType.UpdateBorrowerFailure: {
      return {
        ...state,
        isUpdatingInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};
