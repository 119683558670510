import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import TabMenu from 'components/TabMenu';
import { CloseButtonImage } from 'static/images';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { FormSkeleton } from 'components/Skeleton';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import { createBorrower } from 'LoanOriginationSystemBorrowers/CreateBorrower/ActionCreator';
import ConfigurableForm, { useConfigurableFormValidation } from 'components/ConfigurableForm';
import Button from 'components/Button';
import { getBorrowerVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getBorrowerVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import styles from './CreateBorrower.module.scss';

interface CreateBorrowerProps {
  onClose: () => void;
  setDataWasChanged: (changed: boolean) => void;
}

type DataByBorrowerType = {
  [index in BorrowerType]?: FormLayoutData;
};

const switchOptions = [
  {
    label: 'Person',
    value: BorrowerType.Person,
  },
  {
    label: 'Company',
    value: BorrowerType.Company,
  },
];

const CreateBorrower = ({ onClose, setDataWasChanged }: CreateBorrowerProps) => {
  const dispatch = useDispatch();

  const [dataByBorrowerType, setDataByBorrowerType] = useState<DataByBorrowerType>({});
  const [borrowerType, changeBorrowerType] = useState<BorrowerType>(BorrowerType.Person);
  const configurations = useSelector((state: ReduxState) =>
    getBorrowerVariableConfigurationsSelector(state, { borrowerType }),
  );
  const { isCreating } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers.createBorrower);

  const borrowerData = dataByBorrowerType[borrowerType] || {};

  const validateBorrowerData = useConfigurableFormValidation(borrowerType && configurations);

  useEffect(() => {
    setDataWasChanged(Object.values(borrowerData).some((value) => !isEmptyVariableValue(value)));
  }, [dataByBorrowerType, borrowerType]);

  useEffect(() => {
    if (!configurations) {
      dispatch(getBorrowerVariableConfigurations(borrowerType));
    }
  }, [borrowerType]);

  const handleBorrowerCreate = () => dispatch(createBorrower(borrowerType, borrowerData));

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    const newData = {
      ...dataByBorrowerType,
      [borrowerType]: dataByBorrowerType[borrowerType]
        ? { ...dataByBorrowerType[borrowerType], [variable.systemName]: value }
        : {},
    };

    setDataByBorrowerType(newData);
  };

  const renderForm = () => {
    if (!configurations) {
      return <FormSkeleton />;
    }

    return (
      <ConfigurableForm
        key={borrowerType}
        configurations={configurations}
        data={borrowerData}
        onFieldChange={handleFieldChange}
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
      />
    );
  };

  const isCreateDisabled = !configurations || !validateBorrowerData(borrowerData);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Add Borrower</h2>
        </div>
        <TabMenu
          options={switchOptions}
          value={borrowerType}
          onChange={(type) => changeBorrowerType(type as BorrowerType)}
        />
        {renderForm()}
        <Button
          size="form"
          kind="secondary"
          className={styles.createButton}
          disabled={isCreateDisabled}
          onClick={handleBorrowerCreate}
          isLoading={isCreating}
        >
          Add Borrower
        </Button>
        <CloseButtonImage className={styles.closeButton} onClick={onClose} />
      </div>
    </div>
  );
};

export default CreateBorrower;
