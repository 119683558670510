import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { DocuSignIntegration, DocuSignIntegrationSettings } from 'api/Core/DocuSignIntegrationApi';
import { ESignIntegrationsActionType } from './ActionTypes';

export enum ESignIntegrationActionOrigin {
  DocuSign = 'DocuSignConfiguration',
}

export interface GetDocuSignIntegrationAction extends AnyAction {
  type: ESignIntegrationsActionType.GetDocuSignIntegration;
}

export interface GetDocuSignIntegrationSuccessAction extends AnyAction {
  type: ESignIntegrationsActionType.GetDocuSignIntegrationSuccess;
  payload: {
    integration: DocuSignIntegration;
  };
}

export interface CreateDocuSignIntegrationAction extends ActionWithOrigin<ESignIntegrationActionOrigin> {
  type: ESignIntegrationsActionType.CreateDocuSignIntegration;
  payload: {
    settings: DocuSignIntegrationSettings;
  };
}

export interface CreateDocuSignIntegrationSuccessAction extends ActionWithOrigin<ESignIntegrationActionOrigin> {
  type: ESignIntegrationsActionType.CreateDocuSignIntegrationSuccess;
  payload: {
    integration: DocuSignIntegration;
  };
}

export interface UpdateDocuSignIntegrationAction extends ActionWithOrigin<ESignIntegrationActionOrigin> {
  type: ESignIntegrationsActionType.UpdateDocuSignIntegration;
  payload: {
    id: string;
    settings: DocuSignIntegrationSettings;
  };
}

export interface UpdateDocuSignIntegrationSuccessAction extends ActionWithOrigin<ESignIntegrationActionOrigin> {
  type: ESignIntegrationsActionType.UpdateDocuSignIntegrationSuccess;
  payload: {
    integration: DocuSignIntegration;
  };
}

export const getDocuSignIntegration = (): GetDocuSignIntegrationAction => ({
  type: ESignIntegrationsActionType.GetDocuSignIntegration,
});

export const getDocuSignIntegrationSuccess = (
  integration: DocuSignIntegration,
): GetDocuSignIntegrationSuccessAction => ({
  type: ESignIntegrationsActionType.GetDocuSignIntegrationSuccess,
  payload: {
    integration,
  },
});

export const createDocuSignIntegration = (settings: DocuSignIntegrationSettings): CreateDocuSignIntegrationAction => ({
  type: ESignIntegrationsActionType.CreateDocuSignIntegration,
  payload: {
    settings,
  },
});

export const createDocuSignIntegrationSuccess = (
  integration: DocuSignIntegration,
): CreateDocuSignIntegrationSuccessAction => ({
  type: ESignIntegrationsActionType.CreateDocuSignIntegrationSuccess,
  payload: {
    integration,
  },
});

export const updateDocuSignIntegration = (
  id: string,
  settings: DocuSignIntegrationSettings,
): UpdateDocuSignIntegrationAction => ({
  type: ESignIntegrationsActionType.UpdateDocuSignIntegration,
  payload: {
    id,
    settings,
  },
});

export const updateDocuSignIntegrationSuccess = (
  integration: DocuSignIntegration,
): UpdateDocuSignIntegrationSuccessAction => ({
  type: ESignIntegrationsActionType.UpdateDocuSignIntegrationSuccess,
  payload: {
    integration,
  },
});

export const getDocuSignIntegrationFailure = FailedActionCreator(
  ESignIntegrationsActionType.GetDocuSignIntegrationFailure,
);
export const createDocuSignIntegrationFailure = FailedActionCreator(
  ESignIntegrationsActionType.CreateDocuSignIntegrationFailure,
);
export const updateDocuSignIntegrationFailure = FailedActionCreator(
  ESignIntegrationsActionType.UpdateDocuSignIntegrationFailure,
);
