import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { RunDecisionWizard } from 'RunDecisionWizard/RunDecisionWizard';
import { ReduxState } from 'types/redux';
import {
  closeRunDecisionWizard,
  getApplicationStrategyInputs,
  getApplicationProductVariables,
  getStrategies,
  resetApplicationStrategiesInputs,
} from 'RunDecisionWizard/actions';
import { runIndividualStrategy } from 'StrategyProcessing/ActionCreator';
import { IndividualRunInputs } from 'api/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const RunDecisionWizardConnector = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const runDecisionWizard = useSelector((state: ReduxState) => state.runDecisionWizard);
  const individualProcessing = useSelector((state: ReduxState) => state.individualProcessing);
  const applicationData = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplicationPage.applicationData,
  )!;
  const handleRunDecision = (strategyId: string, inputs: IndividualRunInputs) => {
    batch(async () => {
      await dispatchRoutine(runIndividualStrategy(strategyId, inputs, runDecisionWizard.applicationId));
      dispatch(closeRunDecisionWizard());
    });
  };
  const handleGetStrategyInputs = (strategyId: string) => {
    dispatch(
      getApplicationStrategyInputs({
        strategyId,
        applicationId: runDecisionWizard.applicationId,
      }),
    );
  };
  const handleGetApplicationProductConfigurations = () => {
    dispatch(getApplicationProductVariables({ productId: applicationData.product!.id }));
  };

  if (!runDecisionWizard.isOpen) {
    return null;
  }

  return (
    <RunDecisionWizard
      isLoading={individualProcessing.isLoading}
      closePopUp={() => dispatch(closeRunDecisionWizard())}
      strategies={runDecisionWizard.strategyList}
      strategyInputs={runDecisionWizard.inputs}
      inputValues={runDecisionWizard.applicationValues}
      applicationProductInputs={runDecisionWizard.applicationProductInputs}
      dataLoading={runDecisionWizard.inputsLoading || runDecisionWizard.productInputsLoading}
      onRunDecision={handleRunDecision}
      getStrategiesList={() => dispatch(getStrategies())}
      resetStrategyInputs={() => dispatch(resetApplicationStrategiesInputs())}
      getStrategyInputs={handleGetStrategyInputs}
      getApplicationProductConfigurations={handleGetApplicationProductConfigurations}
    />
  );
};

export default RunDecisionWizardConnector;
