import { Middleware } from 'redux';

import { ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST } from './ActionTypes';
import { getIntegrationsListSuccess, getIntegrationsListError } from './ActionCreator';

import Fetcher from 'middlewares/Fetcher';
import { Integration } from 'api/DecisionEngineStrategiesType';
import { DecisionEngineApi } from 'api/Types';

const GetIntegrationsListMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<Integration[]>(
    ACTION_TYPE_GET_INTEGRATIONS_LIST_REQUEST,
    getIntegrationsListSuccess,
    getIntegrationsListError,
    () => api.getIntegrationsList(),
  );

export default GetIntegrationsListMiddleware;
