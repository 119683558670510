import { AnyAction } from 'redux';
import { BatchProcessingCaseState } from './Types';
import { BatchCasesActionType, GET_BATCH_CASE_SUCCESS, SET_BATCH_CASE_SEARCH_INPUT } from './ActionTypes';
import { GetBatchCaseSuccessAction, BatchCaseSetSearchInput } from './ActionCreator';
import batchCasePagination, { BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT } from 'pages/ProcessingBatchCase/Pagination';
import withStateReset from 'utils/reducers/withStateReset';

const initialState: BatchProcessingCaseState = {
  data: null,
  searchInputValue: '',
  error: null,
  items: [],
  results: null,
  searched: {},
  page: 1,
  itemsPerPage: BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT,
  itemsTotal: undefined,
};

const BatchCaseReducer = (state = initialState, action: AnyAction): BatchProcessingCaseState => {
  switch (action.type) {
    case GET_BATCH_CASE_SUCCESS: {
      const { data, results, items, itemsTotal, searchInputValue } = (action as GetBatchCaseSuccessAction).payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          data,
          results,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal,
              page: 1,
              itemsPerPage: BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT,
              error: '',
            },
          },
        };
      }

      return {
        ...state,
        data,
        results,
        items,
        page: 1,
        itemsPerPage: state?.itemsPerPage || BATCH_INDIVIDUAL_CASES_PER_PAGE_DEFAULT,
        itemsTotal,
        error: '',
      };
    }

    case SET_BATCH_CASE_SEARCH_INPUT: {
      const searchInputValue = (action as BatchCaseSetSearchInput).payload;
      return {
        ...state,
        searchInputValue,
      };
    }

    default:
      return state;
  }
};

export default batchCasePagination.wrapReducer<BatchProcessingCaseState>(
  withStateReset(BatchCaseReducer, BatchCasesActionType.ResetState, () => initialState),
);
