import React from 'react';
import BorrowerForm from 'components/LoanOriginationSystem/CreateApplication/Forms/BorrowerForm';
import ApplicationDetailsForm from 'components/LoanOriginationSystem/CreateApplication/Forms/ApplicationDetailsForm';
import { FormLayoutData, VariableConfiguration } from 'api/Types';
import {
  Borrower,
  BorrowerSuggestionFilter,
  BorrowerType,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import styles from './CreateApplicationForm.module.scss';
import IntermediaryForm from 'components/LoanOriginationSystem/CreateApplication/Forms/IntermediaryForm/IntermediaryForm';
import {
  Intermediary,
  IntermediarySuggestionFilter,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { StandardVariables } from 'Variables/VariablesTypes';

interface CreateApplicationFormProps {
  currentStep: EditApplicationStep;
  borrowerFormData: FormLayoutData;
  coBorrowerFormData: FormLayoutData;
  intermediaryFormData: FormLayoutData;
  borrowerSuggestions?: Borrower[];
  intermediarySuggestions?: Intermediary[];
  selectedBorrower?: Borrower | null;
  selectedCoBorrower?: Borrower | null;
  selectedIntermediary?: Intermediary | null;
  addCoBorrower?: boolean;
  addIntermediary?: boolean;
  borrowerConfigurations: VariableConfiguration[] | null;
  intermediaryConfigurations: VariableConfiguration[] | null;
  applicationDetailsConfigurations: VariableConfiguration[] | null;
  applicationDetailsFormData: FormLayoutData;
  standardVariables: StandardVariables | null;
  onBorrowerDataChange: (borrowerData: FormLayoutData) => void;
  onApplicationDetailsDataChange: (data: FormLayoutData) => void;
  onBorrowerSelect?: (borrower: Borrower | null) => void;
  onLoadBorrowerSuggestions?: (formData: BorrowerSuggestionFilter) => void;
  onLoadIntermediarySuggestions?: (formData: IntermediarySuggestionFilter) => void;
  onUnlockCustomerClick?: (borrower: Borrower) => void;
  onCoBorrowerDataChange: (borrowerData: FormLayoutData) => void;
  onIntermediaryDataChange: (intermediaryData: FormLayoutData) => void;
  onProfileEditClick: () => void;
  onCoBorrowerSelect?: (borrower: Borrower | null) => void;
  onIntermediarySelect?: (intermediary: Intermediary | null) => void;
  borrowerType?: BorrowerType;
  isEditMode?: boolean;
}

const CreateApplicationForm = ({
  currentStep,
  borrowerFormData,
  coBorrowerFormData,
  intermediaryFormData,
  borrowerSuggestions = [],
  selectedBorrower = null,
  selectedCoBorrower = null,
  borrowerConfigurations,
  applicationDetailsFormData,
  intermediaryConfigurations,
  standardVariables,
  intermediarySuggestions = [],
  selectedIntermediary = null,
  onBorrowerSelect,
  onLoadBorrowerSuggestions,
  onUnlockCustomerClick,
  onBorrowerDataChange,
  onCoBorrowerDataChange,
  onCoBorrowerSelect,
  onIntermediarySelect,
  onApplicationDetailsDataChange,
  onIntermediaryDataChange,
  onLoadIntermediarySuggestions,
  applicationDetailsConfigurations,
  onProfileEditClick,
  isEditMode,
  addCoBorrower,
  addIntermediary,
  borrowerType,
}: CreateApplicationFormProps) => {
  const borrowerFormCommonProps = {
    onUnlockCustomerClick,
    onLoadSuggestions: onLoadBorrowerSuggestions,
    onProfileEditClick,
    borrowerType,
    suggestions: borrowerSuggestions,
    configurations: borrowerConfigurations,
    standardVariables,
    isEditMode: isEditMode && !addCoBorrower,
  };

  const getStepTitle = () => {
    if (addCoBorrower && currentStep === EditApplicationStep.CoBorrower) {
      return EditApplicationStep.AddCoborrower;
    }

    if (addIntermediary && currentStep === EditApplicationStep.Intermediary) {
      return EditApplicationStep.AddIntermediary;
    }

    return currentStep;
  };

  return (
    <div className={styles.container}>
      <h3 className={styles.stepTitle}>{getStepTitle()}</h3>
      {currentStep === EditApplicationStep.Borrower && (
        <BorrowerForm
          {...borrowerFormCommonProps}
          formData={borrowerFormData}
          selectedBorrower={selectedBorrower}
          onBorrowerSelect={onBorrowerSelect}
          onFormDataChange={onBorrowerDataChange}
        />
      )}
      {currentStep === EditApplicationStep.CoBorrower && (
        <BorrowerForm
          {...borrowerFormCommonProps}
          formData={coBorrowerFormData}
          suggestions={borrowerSuggestions}
          selectedBorrower={selectedCoBorrower}
          onBorrowerSelect={onCoBorrowerSelect}
          onFormDataChange={onCoBorrowerDataChange}
        />
      )}
      {currentStep === EditApplicationStep.Intermediary && (
        <IntermediaryForm
          selectedIntermediary={selectedIntermediary}
          configurations={intermediaryConfigurations}
          onFormDataChange={onIntermediaryDataChange}
          intermediaryData={intermediaryFormData}
          standardVariables={standardVariables}
          onLoadSuggestions={onLoadIntermediarySuggestions}
          suggestions={intermediarySuggestions}
          onIntermediarySelect={onIntermediarySelect}
          onProfileEditClick={onProfileEditClick}
          isEditMode={isEditMode && !addIntermediary}
        />
      )}
      {currentStep === EditApplicationStep.ApplicationDetails && (
        <ApplicationDetailsForm
          formData={applicationDetailsFormData}
          onFormDataChange={onApplicationDetailsDataChange}
          configurations={applicationDetailsConfigurations}
          onProfileEdit={onProfileEditClick}
          isEditMode={isEditMode}
        />
      )}
    </div>
  );
};

export default CreateApplicationForm;
