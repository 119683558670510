import React, { useState, ChangeEvent, FC } from 'react';
import PasswordInput from 'components/PasswordInput';
import Button from 'components/Button';
import { validatePasswordPresence } from 'components/Profile/utils/validation/validation';
import getMessage from 'constants/messages';

import styles from './PopUp.module.scss';

interface GenerateSecondNewSecretPasswordProps {
  verifyPassword: (password: string) => Promise<unknown>;
}

const GenerateSecondNewSecretPassword: FC<GenerateSecondNewSecretPasswordProps> = ({ verifyPassword }) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  const handleGetPassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const passwordErrorMessage = validatePasswordPresence(password);
    if (passwordErrorMessage) {
      setPasswordError(getMessage(passwordErrorMessage));
      return;
    }
    setIsBlockingActionInProgress(true);
    await verifyPassword(password);
    setIsBlockingActionInProgress(false);
  };

  return (
    <div className={styles.popUpBody}>
      <p className={styles.popUpBody__description}>Please verify your password to confirm your identity.</p>
      <div className={styles.popUpBody__input}>
        <PasswordInput
          labelTitle="Your Password"
          onChange={handlePasswordChange}
          errorMessage={passwordError}
          value={password}
        />
      </div>
      <div className={styles.popUpBody__button}>
        <Button
          kind="secondary"
          size="form"
          onClick={handleGetPassword}
          disabled={!password}
          isLoading={isBlockingActionInProgress}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default GenerateSecondNewSecretPassword;
