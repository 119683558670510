import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import {
  ApplicationHistoryFilterType,
  ApplicationHistoryRecord,
  ApplicationHistorySortingType,
} from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const APPLICATION_HISTORY_RECORDS_PER_PAGE = 20;

export interface ApplicationHistoryPaginationParams {
  applicationId: string;
  selectedMembers: UserInfo[];
  filterType: ApplicationHistoryFilterType;
  sortingType: ApplicationHistorySortingType;
}

const applicationHistoryPagination = Pagination<
  ReduxState,
  ApplicationHistoryRecord,
  ApplicationHistoryPaginationParams
>(['loanOriginationSystemApplicationPage', 'history'], APPLICATION_HISTORY_RECORDS_PER_PAGE);

export default applicationHistoryPagination;
