import { OPEN_DELETE_DECISION_STRATEGY_MODULE_WIZARD, CLOSE_DELETE_DECISION_STRATEGY_MODULE_WIZARD } from './constants';
import { AnyAction } from 'redux';

export interface OpenDeleteDecisionStrategyModuleWizardAction extends AnyAction {
  payload: {
    strategyId: string;
    moduleLookupName: string;
    moduleIndex: number;
  };
}

export const openDeleteDecisionStrategyModuleWizard = (
  strategyId: string,
  moduleLookupName: string,
  moduleIndex: number,
): OpenDeleteDecisionStrategyModuleWizardAction => {
  return {
    type: OPEN_DELETE_DECISION_STRATEGY_MODULE_WIZARD,
    payload: {
      strategyId,
      moduleLookupName,
      moduleIndex,
    },
  };
};

export const closeDeleteDecisionStrategyModuleWizard = () => {
  return {
    type: CLOSE_DELETE_DECISION_STRATEGY_MODULE_WIZARD,
  };
};
