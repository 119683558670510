import React, { FC } from 'react';
import styles from './BasicColors.module.scss';
import basicColors from './basicColors.json';
import Tooltip from 'components/Tooltip';
import randomColorBackground from 'static/images/random-color-background.png';
import { CheckedImage, ReloadImage } from 'static/images';
import { generateRandomColor } from './utils';

interface BasicColorsProps {
  checkedColor: string;
  onBasicColorChange: (colorCode: string) => void;
}

const BasicColors: FC<BasicColorsProps> = ({ checkedColor, onBasicColorChange }) => {
  const selectRandomColor = () => {
    const color = generateRandomColor();
    onBasicColorChange(color);
  };

  return (
    <div className={styles.container}>
      <p className={styles.basicColorsTitle}>Basic Colors</p>
      <div className={styles.colorsLine}>
        {basicColors.map((color) => {
          const { name, color: colorCode } = color;
          return (
            <Tooltip tooltip={name} key={colorCode}>
              <div style={{ backgroundColor: `#${colorCode}` }} className={styles.colorBlock}>
                <input
                  type="radio"
                  name="basic-color"
                  id={name}
                  checked={checkedColor === colorCode}
                  onChange={() => onBasicColorChange(colorCode)}
                />
                <label htmlFor={name}>
                  <CheckedImage />
                </label>
              </div>
            </Tooltip>
          );
        })}
        <Tooltip tooltip="Random Color">
          <button type="button" className={styles.randomColorButton} onClick={selectRandomColor}>
            <img src={randomColorBackground} alt="Random Color" />
            <ReloadImage />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default BasicColors;
