import React from 'react';
import Button from 'components/Button';
import { ShareImage } from 'static/images';
import styles from './NoActiveOrganizations.module.scss';
import { ExternalRoutes } from 'routes/RouteService';

const NoActiveOrganizations = () => {
  const handleContactSalesClick = () => {
    window.open(ExternalRoutes.ContactSales);
  };

  return (
    <div>
      <p className={styles.message}>
        You have no active organizations. To reactivate an existing organization please contact us.
      </p>
      <Button kind="secondary" size="form" fullWidth className={styles.contactUs} onClick={handleContactSalesClick}>
        <ShareImage className={styles.shareIcon} />
        Contact Sales
      </Button>
    </div>
  );
};

export default NoActiveOrganizations;
