import { DecisionEngineApi } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { ModuleReorderAction, reorderModulesSuccess, reorderModulesFailure } from './DecisionStrategiesActionCreator';
import {
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS,
} from './ActionTypes';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { batch } from 'react-redux';
import { getDecisionStrategyUpdatesRequest } from 'DecisionStrategyUpdates/DecisionStrategyUpdatesActionCreator';

const DecisionStrategyModuleReorderMiddleware = (api: DecisionEngineApi) =>
  Fetcher<string, ModuleReorderAction>(
    ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
    reorderModulesSuccess,
    reorderModulesFailure,
    async ({ payload }) => {
      await api.updateModulesOrder(payload.strategyId, payload.indices);
      return payload.strategyId;
    },
  );

export const onUpdateModuleOrderSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS) {
    const { strategyId } = action.payload;
    batch(() => {
      dispatch(getDecisionStrategyUpdatesRequest(strategyId));
      notification.createNotification(getMessage(MessageType.ModulesReOrdered), 'success', dispatch);
    });
  }
  return result;
};

export default DecisionStrategyModuleReorderMiddleware;
