import usersPagination from 'pages/CompanyInformationUsers/Pagination';
import { UserApi } from 'api/Core/UserApi';

export default (api: UserApi) => {
  return usersPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getAll(
      {
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
        search: params.searchInputValue,
        permissionType: params.permissionType,
        emailVerified: params.emailVerified,
        phoneAuthentication: params.phoneAuthentication,
        showInactive: params.showInactive,
      },
      params.sortingType,
    );
    return items;
  });
};
