import { UploadSecureDocumentState } from './Types';
import { UploadSecureDocumentActionType } from './ActionTypes';
import { GetOrganizationInfoByTokenSuccessAction } from './ActionCreator';
import { FailedAction } from 'utils/actions/FailedActionCreator';

export type UploadSecureDocumentActions =
  | GetOrganizationInfoByTokenSuccessAction
  | FailedAction<UploadSecureDocumentActionType.GetOrganizationInfoFailure>;

export const initialState: UploadSecureDocumentState = {
  organizationName: '',
  organizationLogo: '',
  linkExpired: false,
};

const uploadSecureDocumentReducer = (
  state: UploadSecureDocumentState = initialState,
  action: UploadSecureDocumentActions,
) => {
  switch (action.type) {
    case UploadSecureDocumentActionType.GetOrganizationInfoSuccess: {
      const { organizationName, organizationLogo } = action.payload;

      return {
        linkExpired: false,
        organizationName,
        organizationLogo,
      };
    }
    case UploadSecureDocumentActionType.GetOrganizationInfoFailure: {
      return {
        ...initialState,
        linkExpired: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default uploadSecureDocumentReducer;
