import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Variable } from 'Variables/VariablesTypes';

export interface ApplicationDataTabVariableConfiguration {
  id: string;
  cardId: string;
  column: number;
  position: number;
  variable: Variable;
}

export interface CreateApplicationDataTabVariableConfigurationParams {
  productId: string;
  cardId: string;
  column: number;
  position: number;
  variableId: string;
}

export interface FindApplicationDataTabVariableConfigurationParams {
  productId: string;
}

export type UpdateApplicationDataTabVariableConfigurationParams = Partial<
  Omit<CreateApplicationDataTabVariableConfigurationParams, 'productId' | 'variableId'>
>;

export interface ApplicationDataTabVariableConfigurationsApi {
  find(params: FindApplicationDataTabVariableConfigurationParams): Promise<ApplicationDataTabVariableConfiguration[]>;
  create(params: CreateApplicationDataTabVariableConfigurationParams): Promise<ApplicationDataTabVariableConfiguration>;
  update(
    id: string,
    params: UpdateApplicationDataTabVariableConfigurationParams,
  ): Promise<ApplicationDataTabVariableConfiguration>;
  delete(id: string): Promise<void>;
}

export default class ApplicationDataTabVariableConfigurationsRestApi
  extends LoanOriginationSystemApi<ApplicationDataTabVariableConfiguration>
  implements ApplicationDataTabVariableConfigurationsApi {
  protected resourceName = 'application-data-tab-variable-configurations';

  public find(params: FindApplicationDataTabVariableConfigurationParams) {
    const urlParams = new URLSearchParams();
    urlParams.set('productId', params.productId);
    return this.getResources(urlParams);
  }

  public create(params: CreateApplicationDataTabVariableConfigurationParams) {
    return this.createResource({
      variableConfiguration: {
        ...params,
      },
    });
  }

  public update(id: string, params: UpdateApplicationDataTabVariableConfigurationParams) {
    return this.updateResource(id, {
      variableConfiguration: { ...params },
    });
  }

  public delete(id: string) {
    return this.deleteResource(id);
  }
}
