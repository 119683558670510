import { ChangePopupFiltersAction, ResetAllIntermediariesFilters } from './ActionCreator';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './ActionTypes';
import { getIntermediaries } from 'LoanOriginationSystemIntermediariesPage/ActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === LoanOriginationSystemIntermediariesFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllIntermediariesFilters =>
  action.type === LoanOriginationSystemIntermediariesFiltersActionTypes.ResetAllFilters;

const onIntermediariesFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangePopupFiltersAction(action) || isResetAllFiltersAction(action)) {
    const { loanOriginationSystemIntermediariesState } = getState();
    const { filters, sortingType } = loanOriginationSystemIntermediariesState;

    dispatch(getIntermediaries(filters, sortingType));
  }

  return result;
}) as Middleware;

export default onIntermediariesFiltersChangeMiddleware;
