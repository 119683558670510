import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import styles from './CancelledSubscription.module.scss';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { useAuthProvider } from 'providers/AuthProvider';
import UserCard from 'components/UserCard';
import ShareButton from 'components/BillingDashboard/ShareButton';
import { ExternalRoutes } from 'routes/RouteService';

const CancelledSubscription: FC = () => {
  const authProvider = useAuthProvider();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);

  return (
    <AuthLayout title="Your Subscription is Cancelled" subTitle="Please contact our team to restart your subscription.">
      <ShareButton
        className={styles.contactSalesButton}
        fullWidth
        size="form"
        onClick={() => window.open(ExternalRoutes.ContactSales)}
      >
        Contact Sales
      </ShareButton>
      <UserCard
        firstName={accountDetails!.firstName}
        lastName={accountDetails!.lastName}
        imageId={accountDetails!.imageId}
        onLogOutClick={() => authProvider.logout()}
        noMargin
        className={styles.userCard}
      />
    </AuthLayout>
  );
};

export default CancelledSubscription;
