import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { Borrower } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { getBorrowerVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getBorrowerVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import ConfigurableForm, { useConfigurableFormValidation } from 'components/ConfigurableForm';
import Button from 'components/Button';
import { FormSkeleton } from 'components/Skeleton';
import styles from './EditBorrowerForm.module.scss';

export interface EditBorrowerFormProps {
  borrower: Borrower | null;
  borrowerFormData: FormLayoutData;
  setBorrowerFormData: (data: FormLayoutData) => void;
  setDataWasChanged?: (changed: boolean) => void;
  ignoreCustomFields?: boolean;
  onBorrowerUpdate: () => void;
  isUpdating: boolean;
}

const EditBorrower = ({
  borrower,
  borrowerFormData,
  setDataWasChanged,
  onBorrowerUpdate,
  setBorrowerFormData,
  isUpdating,
  ignoreCustomFields,
}: EditBorrowerFormProps) => {
  const dispatch = useDispatch();

  const borrowerType = borrower?.type;
  const configurations = useSelector((state: ReduxState) =>
    getBorrowerVariableConfigurationsSelector(state, { borrowerType }),
  );

  const validateBorrowerData = useConfigurableFormValidation(configurations, ignoreCustomFields);

  const isBorrowerDataWasChanged = useCallback(() => {
    return !!(borrower?.variables && isConfigurableFormDataChanged(borrower.variables, borrowerFormData));
  }, [borrower, borrowerFormData]);

  useEffect(() => {
    if (borrowerType && !configurations) {
      dispatch(getBorrowerVariableConfigurations(borrowerType));
    }
  }, [borrowerType]);

  useEffect(() => {
    if (setDataWasChanged) {
      setDataWasChanged(isBorrowerDataWasChanged());
    }
  }, [isBorrowerDataWasChanged]);

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    setBorrowerFormData({
      ...borrowerFormData,
      [variable.systemName]: value,
    });
  };

  const renderForm = () => {
    if (!configurations) {
      return <FormSkeleton />;
    }

    return (
      <ConfigurableForm
        configurations={configurations}
        data={borrowerFormData}
        onFieldChange={handleFieldChange}
        hideCustomFields={ignoreCustomFields}
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
      />
    );
  };

  return (
    <>
      {renderForm()}
      <Button
        size="form"
        kind="secondary"
        className={styles.saveChangesButton}
        disabled={!isBorrowerDataWasChanged() || !configurations || !validateBorrowerData(borrowerFormData)}
        onClick={onBorrowerUpdate}
        isLoading={isUpdating}
      >
        Save Changes
      </Button>
    </>
  );
};

export default EditBorrower;
