import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './OverflowedText.module.scss';

interface OverflowedTextProps {
  className?: string;
}

const OverflowedText: FC<OverflowedTextProps> = ({ className, children }) => {
  return <div className={clsx(styles.overflowedText, className)}>{children}</div>;
};

export default OverflowedText;
