import { LoanOriginationSystemLabelsFiltersState } from './Types';
import { LoanOriginationSystemLabelsFiltersActionTypes } from './ActionTypes';
import { SetSearchInputValueAction } from './ActionCreator';

export const initialState: LoanOriginationSystemLabelsFiltersState = {
  searchInputValue: '',
};

type LoanOriginationSystemLabelsFilterAction = SetSearchInputValueAction;

export const loanOriginationSystemLabelsFilterReducer = (
  state: LoanOriginationSystemLabelsFiltersState,
  action: LoanOriginationSystemLabelsFilterAction,
): LoanOriginationSystemLabelsFiltersState => {
  switch (action.type) {
    case LoanOriginationSystemLabelsFiltersActionTypes.SetSearchInputValue: {
      return {
        ...state,
        searchInputValue: action.payload.searchInputValue,
      };
    }
    default: {
      return state;
    }
  }
};
