import { uniq } from 'lodash';

const difference = (object: Record<string, unknown>, objectToCompare: Record<string, unknown>) => {
  const keys = uniq([...Object.keys(object), ...Object.keys(objectToCompare)]);

  return keys.reduce((differenceObject, key) => {
    return object[key] !== objectToCompare[key]
      ? { ...differenceObject, [key]: objectToCompare[key] }
      : differenceObject;
  }, {});
};

export default difference;
