import React, { FC, useEffect } from 'react';
import { GetUserInfoRequest } from 'AccountDetails/AccountDetailsActionCreator';
import { getOrganizations } from 'UserOrganizations/UserOrganizationsActionCreators';
import Loading from 'pages/Loading';
import AuthEventEmitter, {
  AuthEventMessage,
  PendingOperation,
} from 'eventHandlers/AuthEventEmitter';
import {
  setIsLoading,
  updateSessionInfo,
} from 'SessionInfo/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { useAuthProvider } from 'providers/AuthProvider';
import { logOut, switchOrganization } from 'stores/Actions';
import { SessionInfo } from 'SessionInfo/Types';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

const AppInitializer: FC = ({ children }) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const location = useLocation();

  const authProvider = useAuthProvider();

  const { isLoading } = useSelector((state: ReduxState) => state.sessionInfo);

  const handleSessionInfoUpdate = (sessionInfo: Partial<SessionInfo>) => {
    dispatch(updateSessionInfo(sessionInfo));
  };

  const initialize = async () => {
    if (authProvider.isLoggedIn()) {
      dispatch(setIsLoading(true));

      try {
        await dispatchRoutine(GetUserInfoRequest());
      } finally {
        dispatch(setIsLoading(false));
      }
    }
  };

  const handleUnauthorizedRequest = () => {
    authProvider.clearState();

    dispatch(logOut());
  };

  const handleLogoutRequest = () => {
    dispatch(logOut());
  };

  const handleSelectOrganization = async (operation: PendingOperation) => {
    dispatch(setIsLoading(true));
    await operation;
    dispatch(switchOrganization());
    await dispatchRoutine(getOrganizations());
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    initialize();

    const unsubscribeCallbacks = [
      AuthEventEmitter.on(AuthEventMessage.SessionInfoUpdate, handleSessionInfoUpdate),
      AuthEventEmitter.on(AuthEventMessage.UnauthorizedRequest, handleUnauthorizedRequest),
      AuthEventEmitter.on(AuthEventMessage.Logout, handleLogoutRequest),
      AuthEventEmitter.on(AuthEventMessage.SelectOrganization, handleSelectOrganization),
    ];

    return () => unsubscribeCallbacks.forEach((callback) => callback());
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default AppInitializer;
