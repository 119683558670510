import React, { FC, ReactElement } from 'react';
import styles from './ComparisonElement.module.scss';
import clsx from 'clsx';
import WrapperWithTooltip, { TooltipPosition } from 'components/Tooltip';

interface ComparisonOperatorProps {
  type: string;
  displayName: string;
  value: string;
  isActive: boolean;
  handleSetCurrentComparison: (value: string) => void;
  tooltipText: string | ReactElement;
  disabled: boolean;
  position?: TooltipPosition;
}

const ComparisonOperator: FC<ComparisonOperatorProps> = ({
  type,
  displayName,
  value,
  isActive,
  handleSetCurrentComparison,
  tooltipText,
  disabled,
  position,
}) => {
  const sizeStyles = type === 'symbol' ? styles.symbolElementContainer : styles.textElementContainer;
  const containerClassNames = clsx({
    [styles.elementContainer]: true,
    [styles.activeElementContainer]: isActive,
    [styles.disabledElementContainer]: disabled,
  });

  const handleOnCLick = () => {
    if (disabled) return;
    handleSetCurrentComparison(value);
  };

  return (
    <WrapperWithTooltip tooltip={tooltipText} position={position}>
      <div className={`${sizeStyles} ${containerClassNames}`} onClick={handleOnCLick}>
        <span className={styles.displayNameContainer}>{displayName}</span>
      </div>
    </WrapperWithTooltip>
  );
};

export default ComparisonOperator;
