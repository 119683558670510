import {
  GET_API_ACTIVATIONS_REQUEST,
  GET_API_ACTIVATIONS_SUCCESS,
  GET_API_ACTIVATIONS_FAILURE,
  UPDATE_API_ACTIVATIONS_REQUEST,
  UPDATE_API_ACTIVATIONS_SUCCESS,
  UPDATE_API_ACTIVATIONS_FAILURE,
  OPEN_API_ACTIVATIONS_POPUP,
  CLOSE_API_ACTIVATIONS_POPUP,
  DOWNLOAD_API_TEMPLATE,
  DOWNLOAD_API_TEMPLATE_SUCCESS,
  DOWNLOAD_API_TEMPLATE_FAILURE,
  ApiActivationActionType,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { DropdownStrategy, StrategyGroup, StrategyGroupsData } from 'api/Types';
import { ApiActivationParams } from 'api/DecisionEngineStrategiesType';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApiActivationSortingFields, ApiActivationSortingType } from 'DecisionStrategy/DecisionStrategiesTypes';

export interface GetApiActivationsRequestAction {
  type: typeof GET_API_ACTIVATIONS_REQUEST;
  payload?: ApiActivationParams;
}

export interface GetApiActivationsSuccessAction {
  type: typeof GET_API_ACTIVATIONS_SUCCESS;
  payload: StrategyGroupsData;
}

export interface UpdateApiActivationsRequestAction {
  type: typeof UPDATE_API_ACTIVATIONS_REQUEST;
  payload: UpdateVersionStatusesParams;
}

export interface UpdateVersionStatusesParams {
  groupName: string;
  active: string | null;
  testing: string | null;
}

export interface UpdateApiActivationsSuccessAction {
  type: typeof UPDATE_API_ACTIVATIONS_SUCCESS;
}

export interface SetShowArchivedAction {
  type: typeof ApiActivationActionType.SetShowArchived;
  payload: boolean;
}

export interface SetUpdatersAction {
  type: typeof ApiActivationActionType.SetUpdaters;
  payload: UserInfo[];
}

export interface SortApiActivationAction {
  type: typeof ApiActivationActionType.SortStrategies;
  payload: ApiActivationSortingType;
}

export interface SetStrategyGroupsSearchInputAction {
  type: typeof ApiActivationActionType.SetStrategyGroupsSearchInput;
  payload: string;
}

export interface GetDropdownGroupedStrategiesAction {
  type: typeof ApiActivationActionType.GetDropdownGroupedStrategies;
  payload: {
    groupTitle: string;
  };
}

export interface GetDropdownGroupedStrategiesSuccessAction {
  type: typeof ApiActivationActionType.GetDropdownGroupedStrategiesSuccess;
  payload: {
    strategies: DropdownStrategy[];
  };
}

export interface ClearDropdownStrategiesListAction {
  type: typeof ApiActivationActionType.ClearDropdownStrategiesList;
}

export interface ClearApiActivationAction {
  type: typeof ApiActivationActionType.ClearApiActivationData;
}

export const getApiActivationsRequest = (payload?: ApiActivationParams): GetApiActivationsRequestAction => ({
  type: GET_API_ACTIVATIONS_REQUEST,
  payload,
});

export const getApiActivationsSuccess = (payload: StrategyGroupsData): GetApiActivationsSuccessAction => ({
  type: GET_API_ACTIVATIONS_SUCCESS,
  payload,
});

export const getApiActivationsFailure = FailedActionCreator(GET_API_ACTIVATIONS_FAILURE);

export const updateActivationsRequest = (payload: UpdateVersionStatusesParams): UpdateApiActivationsRequestAction => ({
  type: UPDATE_API_ACTIVATIONS_REQUEST,
  payload,
});

export const updateActivationsSuccess = (): UpdateApiActivationsSuccessAction => ({
  type: UPDATE_API_ACTIVATIONS_SUCCESS,
});

export const updateActivationsFailure = FailedActionCreator(UPDATE_API_ACTIVATIONS_FAILURE);

export interface OpenApiActivationsPopUpAction {
  type: typeof OPEN_API_ACTIVATIONS_POPUP;
  payload: {
    popUpType: string;
    activationInfo?: StrategyGroup;
  };
}

export const openApiActivationsPopUp = (
  popUpType: string,
  activationInfo?: StrategyGroup,
): OpenApiActivationsPopUpAction => ({
  type: OPEN_API_ACTIVATIONS_POPUP,
  payload: {
    popUpType,
    activationInfo,
  },
});

export interface CloseApiActivationsPopUpAction {
  type: typeof CLOSE_API_ACTIVATIONS_POPUP;
}

export const closeApiActivationsPopUp = (): CloseApiActivationsPopUpAction => ({
  type: CLOSE_API_ACTIVATIONS_POPUP,
});

export interface DownloadApiTemplateAction {
  type: typeof DOWNLOAD_API_TEMPLATE;
  payload: {
    strategyId: string;
    type: string;
  };
}

export const downloadApiTemplate = (strategyId: string, type: string): DownloadApiTemplateAction => ({
  type: DOWNLOAD_API_TEMPLATE,
  payload: {
    strategyId,
    type,
  },
});

export const downloadApiTemplateSuccess = () => ({
  type: DOWNLOAD_API_TEMPLATE_SUCCESS,
});

export const downloadApiTemplateFailure = FailedActionCreator(DOWNLOAD_API_TEMPLATE_FAILURE);

export const getDropdownGroupedStrategies = (groupTitle: string): GetDropdownGroupedStrategiesAction => ({
  type: ApiActivationActionType.GetDropdownGroupedStrategies,
  payload: {
    groupTitle,
  },
});

export const getDropdownGroupedStrategiesSuccess = (
  strategies: DropdownStrategy[],
): GetDropdownGroupedStrategiesSuccessAction => ({
  type: ApiActivationActionType.GetDropdownGroupedStrategiesSuccess,
  payload: {
    strategies,
  },
});

export const getDropdownGroupedStrategiesFailure = FailedActionCreator(
  ApiActivationActionType.GetDropdownGroupedStrategiesFailure,
);

export const clearDropdownStrategiesList = (): ClearDropdownStrategiesListAction => ({
  type: ApiActivationActionType.ClearDropdownStrategiesList,
});

export const setStrategyGroupsSearchInput = (
  payload: SetStrategyGroupsSearchInputAction['payload'],
): SetStrategyGroupsSearchInputAction => ({
  type: ApiActivationActionType.SetStrategyGroupsSearchInput,
  payload,
});

export const setShowArchived = (payload: SetShowArchivedAction['payload']): SetShowArchivedAction => ({
  type: ApiActivationActionType.SetShowArchived,
  payload,
});

export const setUpdaters = (payload: UserInfo[]): SetUpdatersAction => ({
  type: ApiActivationActionType.SetUpdaters,
  payload,
});

export const sortApiActivation = (field: ApiActivationSortingFields, ascending: boolean): SortApiActivationAction => ({
  type: ApiActivationActionType.SortStrategies,
  payload: {
    ascending,
    field,
  },
});

export const clearApiActivationData = (): ClearApiActivationAction => ({
  type: ApiActivationActionType.ClearApiActivationData,
});
