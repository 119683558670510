import { TableViewData } from 'api/LoanOriginationSystem/Types';
import Fetcher from 'middlewares/Fetcher';
import { GetLabelsAction, getLabelsFailure, getLabelsSuccess } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { ReduxState } from 'types/redux';
import LoanOriginationSystemLabelsRestApi, { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { LABELS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/LabelsDetails/Pagination';

const GetLabelsMiddleware = (api: LoanOriginationSystemLabelsRestApi) =>
  Fetcher<TableViewData<Label> & { searchInputValue: string }, GetLabelsAction, ReduxState>(
    LoanOriginationSystemLabelsActionTypes.GetLabels,
    getLabelsSuccess,
    getLabelsFailure,
    async (action) => {
      const { filters, sortingType } = action.payload;

      const response = await api.getLabels({
        filters: filters && {
          search: filters.searchInputValue,
          offset: 0,
          count: LABELS_PER_PAGE_DEFAULT,
        },
        sortingType,
      });

      return {
        ...response,
        searchInputValue: filters ? filters.searchInputValue : '',
      };
    },
  );

export default GetLabelsMiddleware;
