export enum LoanOriginationSystemOrganizationActionType {
  GetOrganizationUsers = 'loanOriginationSystemOrganization/getOrganizationMembers',
  GetOrganizationUsersSuccess = 'loanOriginationSystemOrganization/getOrganizationSuccess',
  GetOrganizationUsersFailure = 'loanOriginationSystemOrganization/getOrganizationFailure',
  GetOrganizationApplications = 'loanOriginationSystemOrganization/getOrganizationApplications',
  GetOrganizationApplicationsSuccess = 'loanOriginationSystemOrganization/getOrganizationApplicationsSuccess',
  GetOrganizationApplicationsFailure = 'loanOriginationSystemOrganization/getOrganizationApplicationsFailure',
  GetStandardVariables = 'loanOriginationSystemOrganization/getStandardVariables',
  GetStandardVariablesSuccess = 'loanOriginationSystemOrganization/getStandardVariablesSuccess',
  GetStandardVariablesFailure = 'loanOriginationSystemOrganization/getStandardVariablesFailure',
}
