import { AnyAction } from 'redux';
import { ApplicationFormPage } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationFormConfigurationActionType } from './ActionTypes';

export interface SetPageToEditRequiredFieldsAction extends AnyAction {
  type: ApplicationFormConfigurationActionType.SetPageForEditRequiredFields;
  payload: {
    page: ApplicationFormPage | null;
  };
}

export interface ToggleFormSettingsPopupVisibilityAction extends AnyAction {
  type: ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility;
}

export interface SetFormPageToDeleteAction extends AnyAction {
  type: ApplicationFormConfigurationActionType.SetFormPageToDelete;
  payload: {
    formPage: ApplicationFormPage | null;
  };
}

export const setPageToEditRequiredFields = (page: ApplicationFormPage | null): SetPageToEditRequiredFieldsAction => ({
  type: ApplicationFormConfigurationActionType.SetPageForEditRequiredFields,
  payload: {
    page,
  },
});

export const toggleFormSettingsPopupVisibility = (): ToggleFormSettingsPopupVisibilityAction => ({
  type: ApplicationFormConfigurationActionType.ToggleFormSettingsPopupVisibility,
});

export const setFormPageToDelete = (formPage: ApplicationFormPage | null): SetFormPageToDeleteAction => ({
  type: ApplicationFormConfigurationActionType.SetFormPageToDelete,
  payload: {
    formPage,
  },
});
