import React, { FC } from 'react';
import styles from './RuleBuilderDivider.module.scss';

interface RuleBuilderDividerTextWrapperProps {
  children: string;
}

const RuleBuilderDividerTextWrapper: FC<RuleBuilderDividerTextWrapperProps> = ({ children }) => (
  <p className={styles.title}>{children}</p>
);

export default RuleBuilderDividerTextWrapper;
