import React, { FC } from 'react';
import styles from './StrategyTableRow.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { StrategyGroupVersion } from 'api/Types';
import clsx from 'clsx';
import RowActions from 'components/RowActions';
import SearchHighlight from 'components/SearchHighlight';
import StrategyTableVersion from 'components/ApiActivation/Table/StrategyTableVersion';

interface StrategyTableRowProps {
  name: string;
  archived: boolean;
  versions: StrategyGroupVersion[];
  versionsNumber: number;
  searchInputValue: string;
  onOpenPopUp: () => void;
}

const StrategyTableRow: FC<StrategyTableRowProps> = ({
  name,
  archived,
  versions,
  versionsNumber,
  searchInputValue,
  onOpenPopUp,
}) => {
  return (
    <TableRow className={styles.row} useActions onClick={onOpenPopUp}>
      <TableBodyCell className={styles.infoCell}>
        <div className={styles.displayName}>
          <p className={clsx(styles.strategyName, archived && styles.strategyName__inactive)}>
            <SearchHighlight search={searchInputValue}>{name}</SearchHighlight>
          </p>
          <p className={styles.versionsCount}>{`${versionsNumber} ${versionsNumber > 1 ? 'versions' : 'version'}`}</p>
        </div>
      </TableBodyCell>
      <TableBodyCell className={styles.versionCell}>
        <StrategyTableVersion versions={versions} status="active" />
      </TableBodyCell>
      <TableBodyCell className={styles.versionCell}>
        <StrategyTableVersion versions={versions} status="testing" />
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'Edit API Versions',
            handler: () => onOpenPopUp(),
          },
        ]}
      />
    </TableRow>
  );
};

export default StrategyTableRow;
