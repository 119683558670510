export enum ApplicationDataTabCardsActionType {
  GetApplicationDataTabCards = 'applicationDataTabCards/getApplicationDataTabCards',
  GetApplicationDataTabCardsSuccess = 'applicationDataTabCards/getApplicationDataTabCardsSuccess',
  GetApplicationDataTabCardsFailure = 'applicationDataTabCards/getApplicationDataTabCardsFailure',
  CreateApplicationDataTabCard = 'applicationDataTabCards/createApplicationDataTabCard',
  CreateApplicationDataTabCardSuccess = 'applicationDataTabCards/createApplicationDataTabCardSuccess',
  CreateApplicationDataTabCardFailure = 'applicationDataTabCards/createApplicationDataTabCardFailure',
  UpdateApplicationDataTabCard = 'applicationDataTabCards/updateApplicationDataTabCard',
  UpdateApplicationDataTabCardSuccess = 'applicationDataTabCards/updateApplicationDataTabCardSuccess',
  UpdateApplicationDataTabCardFailure = 'applicationDataTabCards/updateApplicationDataTabCardFailure',
  DeleteApplicationDataTabCard = 'applicationDataTabCards/deleteApplicationDataTabCard',
  DeleteApplicationDataTabCardSuccess = 'applicationDataTabCards/deleteApplicationDataTabCardSuccess',
  DeleteApplicationDataTabCardFailure = 'applicationDataTabCards/deleteApplicationDataTabCardFailure',
}
