import React from 'react';
import TagLine from './TagLine';
import styles from './ApplicationLabels.module.scss';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

export interface ApplicationsLabelsProps {
  labels: BaseLabelInfo[];
}

const ApplicationLabels = ({ labels }: ApplicationsLabelsProps) => (
  <div className={styles.labels}>
    {labels.map((label) => (
      <TagLine key={label.id} name={label.name} color={label.color} />
    ))}
  </div>
);

export default ApplicationLabels;
