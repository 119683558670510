import ConfirmPopup from 'components/ConfirmPopup';
import React from 'react';

interface DeleteApplicationConfirmationPopupProps {
  onConfirmClick: () => void;
  onClose: () => void;
  loading: boolean;
}

const DeleteApplicationConfirmationPopup = ({
  onConfirmClick,
  onClose,
  loading,
}: DeleteApplicationConfirmationPopupProps) => {
  return (
    <ConfirmPopup
      message="Are you sure you want to delete this application?"
      confirmText="Delete Application"
      declineText="No, Go Back"
      title="Delete Application"
      onConfirmClick={onConfirmClick}
      onPopupClose={onClose}
      loading={loading}
    />
  );
};

export default DeleteApplicationConfirmationPopup;
