import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { ApplicationHistoryActionType } from './ActionTypes';
import {
  ChangeSelectedMembersAction,
  ChangeFilterTypeAction,
  ChangeSortingTypeAction,
  getApplicationHistory,
} from './ActionCreator';

const isSortHistoryAction = (action: AnyAction): action is ChangeSortingTypeAction =>
  action.type === ApplicationHistoryActionType.ChangeSortingType;

const isChangeSelectedTeamMembersAction = (action: AnyAction): action is ChangeSelectedMembersAction =>
  action.type === ApplicationHistoryActionType.ChangeSelectedMembers;

const isChangeFilterTypeAction = (action: AnyAction): action is ChangeFilterTypeAction =>
  action.type === ApplicationHistoryActionType.ChangeFilterType;

const reloadHistoryRecordsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);
  const { loanOriginationSystemApplicationPage } = getState();
  const { history } = loanOriginationSystemApplicationPage;

  if (isSortHistoryAction(action)) {
    dispatch(
      getApplicationHistory(
        {
          applicationId: action.payload.applicationId,
          filterType: history.filterType,
          selectedMembers: history.selectedMembers,
        },
        action.payload.sortingType,
      ),
    );
  }

  if (isChangeFilterTypeAction(action)) {
    dispatch(
      getApplicationHistory(
        {
          applicationId: action.payload.applicationId,
          filterType: action.payload.filterType,
          selectedMembers: history.selectedMembers,
        },
        history.sortingType,
      ),
    );
  }

  if (isChangeSelectedTeamMembersAction(action)) {
    dispatch(
      getApplicationHistory(
        {
          applicationId: action.payload.applicationId,
          filterType: history.filterType,
          selectedMembers: action.payload.selectedMembers,
        },
        history.sortingType,
      ),
    );
  }

  return result;
}) as Middleware;

export default reloadHistoryRecordsMiddleware;
