import React, { FC } from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import styles from './CoBorrowerEditRequiredFieldsPopup.module.scss';

export interface CoBorrowerEditRequiredFieldsPopupProps {
  onClose: () => void;
}

const CoBorrowerEditRequiredFieldsPopup: FC<CoBorrowerEditRequiredFieldsPopupProps> = ({ onClose }) => (
  <PopUp closePopUp={onClose} title="Required Fields">
    <PopUpContent hasTopMargin>
      <div className={styles.description}>
        The Co-Borrower field requirements will match the Borrower field requirements.
      </div>
      <Button onClick={() => onClose()} kind="secondary" size="form" className={styles.closeWindowButton}>
        Close Window
      </Button>
    </PopUpContent>
  </PopUp>
);

export default CoBorrowerEditRequiredFieldsPopup;
