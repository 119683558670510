import React, { FC, ChangeEvent, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import styles from './InputWithCode.module.scss';
import TextareaAutosize from 'react-textarea-autosize';
import useRandomIdFallback from 'hooks/randomIdFallback';

interface InputWithCodeProps {
  labelText: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  id?: string;
}

const generateLineNumbers = () => {
  const lineNumbers = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 1; index < 100; index++) {
    lineNumbers.push(<p key={index}>{index}</p>);
  }
  return lineNumbers;
};

const InputWithCode: FC<InputWithCodeProps> = ({ id: providedId, labelText, value, onChange }) => {
  const id = useRandomIdFallback(providedId);

  const textRef = useRef<HTMLTextAreaElement>(null);
  const [textareaHeight, setTextareaHeight] = useState(0);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    const { current } = textRef;
    if (current) {
      const { offsetHeight } = current;
      setTextareaHeight(offsetHeight);
    }
  }, [value]);

  const className = clsx(styles.input, isInputFocused && styles.inputFocus);

  return (
    <div className={styles.inputContainer}>
      <label htmlFor={id} className={styles.inputLabel}>
        {labelText}
      </label>
      <label className={className}>
        <div className={styles.lineNumbersBackground} style={{ height: value ? `${textareaHeight + 32}px` : '52px' }}>
          <div className={styles.lineNumbers}>
            <div className={styles.lineNumbersContent}>{generateLineNumbers()}</div>
          </div>
        </div>
        <TextareaAutosize
          id={id}
          value={value}
          onChange={onChange}
          className={styles.textarea}
          ref={textRef}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </label>
    </div>
  );
};

export default InputWithCode;
