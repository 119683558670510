import React from 'react';
import styles from './ExtendedTagLine.module.scss';
import clsx from 'clsx';

interface ExtendedTagLine {
  title: string;
  color: string;
  className?: string;
}

const ALPHA_TRANSPARENCY = '20';

const ExtendedTagLine = ({ title, color, className }: ExtendedTagLine) => {
  const backgroundColor = `${color}${ALPHA_TRANSPARENCY}`;
  return (
    <div className={clsx(styles.extendedTagLine, className)} style={{ color, background: backgroundColor }}>
      {title}
    </div>
  );
};

export default ExtendedTagLine;
