import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import EditableInput from 'components/EditableInput';
import DroppableList from 'components/DndList/DroppableList';
import DraggableInputStub from 'components/DraggableInputStub/DraggableInputStub';
import { InputWithDataTypeStub } from 'components/InputWithDataType';
import { FormSkeleton } from 'components/Skeleton';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import styles from './VariablesConfigurationCard.module.scss';
import useFormLayout from 'components/ConfigurableForm/useFormLayout';
import { ValueValidationType } from 'components/Input';
import trimAll from 'utils/trimAll';

export interface VariablesConfigurationCardProps {
  title?: string;
  isTitleEditable?: boolean;
  className?: string;
  columns?: number;
  hideTitle?: boolean;
  placeholderStyle: CSSProperties | null;
  configuration: VariableConfiguration[] | null;
  onVariableDelete: (id: string) => void;
  draggingId: string | null;
  cardId: string;
  droppableType: string;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  isDragDisabled?: boolean;
  isDropDisabled?: boolean;
  showActions?: boolean;
  actions?: RowAction[];
  onCardNameUpdate?: (name: string) => void;
}

const DEFAULT_VARIABLES_COLUMNS_LENGTH = 2;
const MAX_CARD_NAME_LENGTH = 40;
const DEFAULT_VARIABLE_OFFSET = 24;

const getDroppableId = (cardId: string, column: number) => `${cardId}-${column}`;
const getDraggableId = (itemId: string | number, containerDroppableId: string) =>
  `item-${containerDroppableId}-${itemId}`;

const VariablesConfigurationCard = ({
  configuration,
  columns = DEFAULT_VARIABLES_COLUMNS_LENGTH,
  className,
  cardId,
  isTitleEditable,
  placeholderStyle,
  onVariableDelete,
  title,
  formatDisplayTitle,
  isDragDisabled,
  isDropDisabled,
  draggingId,
  droppableType,
  showActions,
  actions,
  onCardNameUpdate,
  hideTitle,
}: VariablesConfigurationCardProps) => {
  const cardNameInputProps = useAsyncValueInputProps({
    value: title || '',
    handleChange: (newCardName) => {
      const correctCardName = trimAll(newCardName);

      onCardNameUpdate?.(correctCardName);
    },
    saveEmptyValue: true,
  });

  const layout = useFormLayout(configuration, columns);

  const variablesContainerStyle = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  const renderDraggableLayoutItem = ({ variable, id }: VariableConfiguration, columnIndex: number) => (
    <DraggableInputStub
      key={id}
      className={styles.layoutItem}
      label={formatDisplayTitle ? formatDisplayTitle(variable.systemName, variable.displayName) : variable.displayName}
      isDragging={getDraggableId(id, getDroppableId(cardId, columnIndex)) === draggingId}
      showActions
      onDelete={() => onVariableDelete(id)}
      {...getVisualDataTypeWithAttributes(variable)}
    />
  );

  const renderDefaultLayoutItem = ({ id, variable }: VariableConfiguration) => (
    <InputWithDataTypeStub
      key={id}
      label={formatDisplayTitle ? formatDisplayTitle(variable.systemName, variable.displayName) : variable.displayName}
      disabled
      className={clsx(styles.layoutItem, styles.disabledLayoutItem)}
      {...getVisualDataTypeWithAttributes(variable)}
    />
  );

  const renderColumn = (column: Array<VariableConfiguration>, index: number) => {
    const defaultLayoutItems = column.filter((item) => item.default);
    const customLayoutItems = column.filter((item) => !item.default);

    return (
      <div className={styles.variablesColumn} key={index}>
        <div>{defaultLayoutItems.map(renderDefaultLayoutItem)}</div>
        <DroppableList<VariableConfiguration>
          droppableId={getDroppableId(cardId, index)}
          droppableType={droppableType}
          items={customLayoutItems}
          renderListItem={(variable) => {
            return renderDraggableLayoutItem(variable, index);
          }}
          listItemClassName={styles.variable}
          withPlaceholder
          placeholderStyles={{
            ...placeholderStyle,
            width: placeholderStyle?.width
              ? `calc(${placeholderStyle.width}px - ${DEFAULT_VARIABLE_OFFSET}px)`
              : placeholderStyle?.width,
          }}
          getDraggableId={getDraggableId}
          isDropDisabled={isDropDisabled}
          isDragDisabled={isDragDisabled}
          placeholderClassName={styles.placeholder}
        />
      </div>
    );
  };

  const renderVariablesConfigurationCardBody = () => {
    if (!layout) {
      return <FormSkeleton />;
    }

    return (
      <div style={variablesContainerStyle} className={styles.variablesContainer}>
        {layout.map(renderColumn)}
      </div>
    );
  };

  const renderTitle = () => (
    <EditableInput
      {...cardNameInputProps}
      className={clsx(styles.configurationCardTitle, !configuration?.length && styles.noItemsTitle)}
      disabled={!isTitleEditable}
      maxLength={MAX_CARD_NAME_LENGTH}
      valueValidationType={ValueValidationType.LettersNumbersWithSpecialCharacters}
    />
  );

  return (
    <RowActionsContainer>
      <div className={clsx(styles.configurationCard, hideTitle && styles.withoutTitleConfigurationCard, className)}>
        <div className={styles.configurationCardHeader}>
          {!hideTitle && renderTitle()}
          {showActions && <RowActions className={styles.rowActions} actions={actions || []} />}
        </div>
        {renderVariablesConfigurationCardBody()}
      </div>
    </RowActionsContainer>
  );
};

export default VariablesConfigurationCard;
