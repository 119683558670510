import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { UpdateIntermediarySuccessAction } from './ActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from './ActionTypes';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';

const isUpdateIntermediarySuccessAction = (action: AnyAction): action is UpdateIntermediarySuccessAction =>
  action.type === LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediarySuccess;

const UpdateIntermediarySuccessMiddleware = (): Middleware => ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isUpdateIntermediarySuccessAction(action)) {
    notification.createNotification(
      getMessage(MessageType.IntermediaryUpdated, {
        intermediaryName: action.payload.variables[IntermediaryDefaultVariable.Name],
      }),
      'success',
      dispatch,
    );
  }

  return next(action);
};

export default UpdateIntermediarySuccessMiddleware;
