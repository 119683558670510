import { Middleware } from 'redux';
import Fetcher from 'middlewares/Fetcher';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';
import AccountDetailsActionType from 'AccountDetails/ActionTypes';
import {
  requestPhoneAuthenticationFailure,
  requestPhoneAuthenticationSuccess,
} from 'AccountDetails/AccountDetailsActionCreator';

const RequestPhoneAuthenticationMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<void>(
    AccountDetailsActionType.RequestPhoneAuthentication,
    requestPhoneAuthenticationSuccess,
    requestPhoneAuthenticationFailure,
    async ({ payload }) => {
      await api.requestPhoneCode(payload.phone);
    },
  );

export default RequestPhoneAuthenticationMiddleware;
