import React, { FC, ReactNode } from 'react';
import stringMatches, { StringFragment } from 'utils/stringMatches';

import styles from './SearchHighlight.module.scss';

interface SearchHighlightProps {
  children: string;
  search: string;
  caseSensitive?: boolean;
  wrapper?: WrapperFunction;
}

const handleCase = (string: string, isCaseSensitive: boolean) => (isCaseSensitive ? string : string.toLowerCase());

interface WrapperFunction {
  (string: string, match: StringFragment, index: number): ReactNode;
}

const wrapperDefault: WrapperFunction = (string, { start, end, isMatched }, index) => {
  const subString = string.slice(start, end);
  if (!isMatched) {
    return subString;
  }
  return (
    <span key={index} className={styles.searchHighlight}>
      {subString}
    </span>
  );
};

const SearchHighlight: FC<SearchHighlightProps> = ({
  children,
  search,
  caseSensitive = false,
  wrapper = wrapperDefault,
}) => {
  const matches = stringMatches(handleCase(children, caseSensitive), handleCase(search, caseSensitive));

  return <>{matches ? matches.map((match, index) => wrapper(children, match, index)) : children}</>;
};

export default SearchHighlight;
