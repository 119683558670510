import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemApplicationsActionType } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  changeApplicationStatusSuccess,
  changeApplicationStatusFailed,
  ChangeApplicationStatusAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { RequestError } from 'utils/fetch';
import ChangeApplicationStatusError from 'errors/ChangeApplicationStatusError';
import ApplicationStatusRulesValidationError from 'errors/ApplicationStatusRulesValidationError';
import prolongRequest from 'utils/prolongRequest';

const CHANGE_APPLICATION_STATUS_MIN_REQUEST_TIME = 1000;

const ChangeApplicationStatusMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, ChangeApplicationStatusAction>(
    LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus,
    changeApplicationStatusSuccess,
    changeApplicationStatusFailed,
    prolongRequest(async (action: ChangeApplicationStatusAction) => {
      const { application, status, declineReasons, sourceIndex } = action.payload;

      try {
        const updatedApplication = await api.changeApplicationStatus(application.id, status.id, declineReasons);

        return updatedApplication;
      } catch (error) {
        if (error instanceof RequestError && error.data && error.data.statusRulesValidationResult) {
          throw new ApplicationStatusRulesValidationError(
            application,
            status,
            sourceIndex,
            error.data.statusRulesValidationResult as ApplicationStatusRulesValidationResult,
          );
        }

        throw new ChangeApplicationStatusError(error.message, application, status, sourceIndex);
      }
    }, CHANGE_APPLICATION_STATUS_MIN_REQUEST_TIME),
  );

export default ChangeApplicationStatusMiddleware;
