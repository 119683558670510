import Fetcher from 'middlewares/Fetcher';
import { ESignTemplatesApi } from 'api/Core/ESignTemplatesApi';
import { ESignTemplatesActionType } from './ActionTypes';
import { DeleteESignTemplateAction, deleteESignTemplateSuccess, deleteESignTemplateFailure } from './ActionCreator';

const DeleteESignTemplateMiddleware = (api: ESignTemplatesApi) =>
  Fetcher<string, DeleteESignTemplateAction>(
    ESignTemplatesActionType.DeleteESignTemplate,
    deleteESignTemplateSuccess,
    deleteESignTemplateFailure,
    async (action) => {
      await api.delete(action.payload.id);

      return action.payload.id;
    },
  );

export default DeleteESignTemplateMiddleware;
