import React from 'react';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import styles from './BorrowerVariablesConfigurationHeader.module.scss';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';

export interface BorrowerConfigurationHeaderProps {
  onChangeBorrowerType: (borrowerType: BorrowerType) => void;
  onDownloadApiTemplate: () => void;
  borrowerType: BorrowerType;
  tabs: TabSwitchOption[];
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test,
);

const BorrowerVariablesConfigurationHeader = ({
  tabs,
  borrowerType,
  onChangeBorrowerType,
  onDownloadApiTemplate,
}: BorrowerConfigurationHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2 className={styles.headerTitle}>Borrowers</h2>
        <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiTemplate} />
      </div>
      <p className={styles.subTitle}>
        Tailor your borrower data to your business' specific needs by adding additional variables to the basic system
        configuration
      </p>
      <TabSwitch
        displayBorder={false}
        tabs={tabs}
        selectedTabId={borrowerType}
        tabClassName={styles.tab}
        onSelect={({ id }) => onChangeBorrowerType(id as BorrowerType)}
      />
    </div>
  );
};

export default BorrowerVariablesConfigurationHeader;
