import { connect } from 'react-redux';
import Module from './Module';
import { updateModuleInfoRequest, updateModuleNameRequest } from 'ModuleInfo/ModuleInfoActionCreator';
import { createBranch } from 'AddBranch/AddBranchActionCreator';
import { duplicateModule } from 'DuplicateModule/Actions';

export type ModuleConnectedProps = typeof mapDispatchToProps;

const mapDispatchToProps = {
  duplicateModule,
  updateModuleNameRequest,
  updateModuleInfoRequest,
  createBranch,
};

export default connect(null, mapDispatchToProps)(Module);
