import React, { forwardRef, useEffect, useState } from 'react';
import { HTMLInputProps } from 'components/TextInput/TextInput';

export enum ValueValidationType {
  LettersNumbersOnly = 'LettersNumbersOnly',
  LettersNumbersWithSpecialCharacters = 'LettersNumbersWithSpecialCharacters',
}

const LETTERS_AND_NUMBERS_REG_EXP = /^[\w\s]*$/;
// eslint-disable-next-line no-useless-escape
export const LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS = /^[a-zA-Z0-9~`!@#$%^&*()-_=+{}\[\]|\/:;"'<>,.? ]*$/;

const ValueValidatorsMapping = {
  [ValueValidationType.LettersNumbersOnly]: (value: string) => LETTERS_AND_NUMBERS_REG_EXP.test(value),
  [ValueValidationType.LettersNumbersWithSpecialCharacters]: (value: string) =>
    LETTERS_AND_NUMBERS_WITH_SPECIAL_CHARACTERS.test(value),
};

export interface InputProps extends HTMLInputProps {
  valueValidationType?: ValueValidationType;
  value?: string | number;
}

const Input = forwardRef<HTMLInputElement, InputProps>(({ value, onChange, valueValidationType, ...props }, ref) => {
  const [currentValue, setValue] = useState<string | number | undefined>('');

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueValidator = valueValidationType ? ValueValidatorsMapping[valueValidationType] : null;
    const valueToSet = event.target.value;

    if (!valueValidator && onChange) {
      onChange(event);
    }

    if (valueValidator && valueValidator(valueToSet)) {
      setValue(valueToSet);

      if (onChange) {
        onChange(event);
      }
    }
  };

  return <input onChange={handleValueChange} value={valueValidationType ? currentValue : value} ref={ref} {...props} />;
});

export default Input;
