import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { VariablesActionType } from 'Variables/ActionTypes';
import { getVariablesRequest, GetVariablesRequestAction } from 'Variables/VariablesActionCreator';

const isToggleShowArchivedAction = (action: AnyAction): action is GetVariablesRequestAction =>
  action.type === VariablesActionType.SetShowArchived;

const OnToggleShowArchivedMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isToggleShowArchivedAction(action)) {
    const { variables } = getState();
    const { filters, sortingType } = variables;

    dispatch(getVariablesRequest(filters, sortingType, action.payload));
  }

  return result;
}) as Middleware;

export default OnToggleShowArchivedMiddleware;
