import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import Profile from 'components/Profile';
import {
  GetUserInfoRequest,
  ChangeUserInfoRequest,
  ChangeUserPhotoRequest,
} from 'AccountDetails/AccountDetailsActionCreator';
import MainLayout, { PageWrapperWithFooter, PageContent } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { ChangeUserInfoParams } from 'AccountDetails/AccountDetailsTypes';
import ChangeEmailPopup from 'components/ChangeEmailPopup';
import ManagePhoneAuthenticationPopup from 'components/ManagePhoneAuthenticationPopup';
import useOrganizationDetailedInfo from 'hooks/useOrganizationDetailedInfo';
import getCountryFromLocale from 'utils/getCountryFromLocale';

const AccountDetails: FC = () => {
  const [changeEmailPopupOpen, setChangeEmailPopupOpen] = useState(false);
  const [managePhoneAuthenticationPopupOpen, setManagePhoneAuthenticationPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const organizationInfo = useOrganizationDetailedInfo();

  useEffect(() => {
    dispatch(GetUserInfoRequest());
  }, []);

  const accountDetails = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);

  if (!accountDetails) {
    return null;
  }

  const handleChangeUserInfo = (changeUserCredentials: ChangeUserInfoParams) =>
    dispatchRoutine(ChangeUserInfoRequest(changeUserCredentials));
  const handleChangeUserPhoto = (photo: File | null) => {
    if (!photo) {
      return;
    }

    dispatch(ChangeUserPhotoRequest({ photo }));
  };

  const leftNav = makeLeftNavigation('UserPage', ApplicationSectionName.MyAccount);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapperWithFooter>
        <PageContent>
          <Profile
            userInfo={accountDetails}
            changeUserInfo={handleChangeUserInfo}
            managePhoneAuthentication={() => setManagePhoneAuthenticationPopupOpen(true)}
            openChangeEmail={() => setChangeEmailPopupOpen(true)}
            changeUserPhoto={handleChangeUserPhoto}
          />
          {changeEmailPopupOpen && <ChangeEmailPopup onClose={() => setChangeEmailPopupOpen(false)} />}
          {managePhoneAuthenticationPopupOpen && (
            <ManagePhoneAuthenticationPopup
              phoneNumberFormat={organizationInfo.regionSettings.phoneNumberFormat || getCountryFromLocale()}
              onClose={() => setManagePhoneAuthenticationPopupOpen(false)}
              authenticationEnabled={accountDetails.isMfaEnabled}
              userPhone={accountDetails.phone}
              requestPhoneAuthenticationError={accountDetails.requestPhoneAuthenticationError}
            />
          )}
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default AccountDetails;
