import {
  CREATE_NEW_MODULE_REQUEST,
  CREATE_NEW_MODULE_REQUEST_FAILURE,
  MODULE_ADDED_SUCCESS,
  UPDATE_MODULE_INFO_REQUEST,
  UPDATE_MODULE_INFO_REQUEST_FAILURE,
  UPDATE_MODULE_INFO_REQUEST_SUCCESS,
  UPDATE_MODULE_NAME,
  UPDATE_MODULE_NAME_FAILURE,
  UPDATE_MODULE_NAME_SUCCESS,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { CreateModuleData, ModuleRequestDataType } from './Types';
import { AnyAction } from 'redux';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';

export interface CreateNewModuleRequestAction extends AnyAction {
  type: typeof CREATE_NEW_MODULE_REQUEST;
  payload: CreateModuleData;
}

export interface ModuleAddedSuccessAction {
  type: typeof MODULE_ADDED_SUCCESS;
  payload: {
    strategyId: string;
    moduleName: string;
    moduleType: ModuleType;
  };
}

export interface UpdateModuleInfoRequestAction {
  type: typeof UPDATE_MODULE_INFO_REQUEST;
  payload: ModuleRequestDataType;
}

export interface UpdateModuleNameAction {
  type: typeof UPDATE_MODULE_NAME;
  payload: ModuleRequestDataType;
}

export interface UpdateModuleInfoRequestSuccessAction {
  type: typeof UPDATE_MODULE_INFO_REQUEST_SUCCESS;
  payload: ModuleRequestDataType;
}

export interface UpdateModuleNameSuccessAction {
  type: typeof UPDATE_MODULE_NAME_SUCCESS;
  payload: ModuleRequestDataType;
}

export const createNewModuleRequest = (payload: CreateModuleData): CreateNewModuleRequestAction => ({
  type: CREATE_NEW_MODULE_REQUEST,
  payload,
});

export const createModuleAddedRequestSuccess = (
  payload: ModuleAddedSuccessAction['payload'],
): ModuleAddedSuccessAction => ({
  type: MODULE_ADDED_SUCCESS,
  payload,
});

export const updateModuleInfoRequestSuccess = (
  payload: ModuleRequestDataType,
): UpdateModuleInfoRequestSuccessAction => ({
  type: UPDATE_MODULE_INFO_REQUEST_SUCCESS,
  payload,
});

export const updateModuleNameSuccess = (payload: ModuleRequestDataType): UpdateModuleNameSuccessAction => ({
  type: UPDATE_MODULE_NAME_SUCCESS,
  payload,
});

export const updateModuleInfoRequest = (moduleInfo: ModuleRequestDataType): UpdateModuleInfoRequestAction => {
  return {
    type: UPDATE_MODULE_INFO_REQUEST,
    payload: {
      ...moduleInfo,
    },
  };
};

export const updateModuleNameRequest = (moduleInfo: ModuleRequestDataType): UpdateModuleNameAction => {
  return {
    type: UPDATE_MODULE_NAME,
    payload: {
      ...moduleInfo,
    },
  };
};

export const updateModuleInfoRequestFailure = FailedActionCreator(UPDATE_MODULE_INFO_REQUEST_FAILURE);
export const updateModuleNameFailure = FailedActionCreator(UPDATE_MODULE_NAME_FAILURE);
export const createNewModuleRequestFailure = FailedActionCreator(CREATE_NEW_MODULE_REQUEST_FAILURE);
