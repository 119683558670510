import React from 'react';
import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import InfoCard from 'components/InfoCard/InfoCard';
import InfoCardColumn from 'components/InfoCard/InfoCardColumn';
import { EditImage } from 'static/images';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import {
  getFormattedIntermediaryCommissionRate,
  getFormattedIntermediaryIdNumber,
  getFormattedIntermediaryPhoneNumber,
} from 'LoanOriginationSystemOrganization/Utils';
import styles from './ExistingIntermediaryForm.module.scss';
import { StandardVariables } from 'Variables/VariablesTypes';

interface ExistingIntermediaryFormProps {
  variables: FormLayoutData;
  standardVariables: StandardVariables | null;
  onClose: () => void;
  onProfileEditClick?: () => void;
  hideCloseButton?: boolean;
}

const ExistingIntermediaryForm = ({
  variables,
  standardVariables,
  onClose,
  onProfileEditClick,
  hideCloseButton,
}: ExistingIntermediaryFormProps) => {
  const formattedIdNumber = getFormattedIntermediaryIdNumber(variables, standardVariables);
  const formattedPhoneNumber = getFormattedIntermediaryPhoneNumber(
    variables[IntermediaryDefaultVariable.PhoneNumber],
    standardVariables,
  );

  const formattedCommissionRate = getFormattedIntermediaryCommissionRate(
    variables[IntermediaryDefaultVariable.CommissionRate],
    standardVariables,
  );

  return (
    <div>
      <InfoCard
        title={variables[IntermediaryDefaultVariable.Name] as string}
        titleLink={
          <>
            <EditImage className={styles.editIcon} />
            Edit Profile
          </>
        }
        onTitleLinkClick={() => onProfileEditClick?.()}
        onClose={onClose}
        hideCloseButton={hideCloseButton}
      >
        <div className={styles.columnsContainer}>
          <InfoCardColumn
            itemClassName={styles.leftColumnItem}
            items={[
              {
                label: 'Email',
                content: variables[IntermediaryDefaultVariable.Email] as string,
              },
              {
                label: 'Phone',
                content: formattedPhoneNumber,
              },
              {
                label: 'Type',
                content: variables[IntermediaryDefaultVariable.Type] as string,
              },
            ]}
          />
          <InfoCardColumn
            className={styles.rightColumn}
            itemClassName={styles.rightColumnItem}
            items={[
              {
                label: 'ID Number',
                content: formattedIdNumber,
              },
              {
                label: 'Commission Rate',
                content: formattedCommissionRate,
              },
            ]}
          />
        </div>
      </InfoCard>
    </div>
  );
};

export default ExistingIntermediaryForm;
