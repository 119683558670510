export const LOAD_BORROWERS_SUGGESTIONS = 'LOAD_BORROWERS_SUGGESTIONS';
export const LOAD_BORROWERS_SUGGESTIONS_SUCCESS = 'LOAD_BORROWERS_SUGGESTIONS_SUCCESS';
export const LOAD_BORROWERS_SUGGESTIONS_FAILED = 'LOAD_BORROWERS_SUGGESTIONS_FAILED';
export const SELECT_BORROWER = 'SELECT_BORROWER';
export const SELECT_CO_BORROWER = 'SELECT_CO_BORROWER';
export const CREATE_APPLICATION = 'CREATE_APPLICATION';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const SELECT_BORROWER_TO_EDIT = 'SELECT_BORROWER_TO_EDIT';

export enum LoanOriginationSystemCreateApplicationActionType {
  LoadIntermediarySuggestions = 'loanOriginationSystemCreateApplication/loadIntermediarySuggestions',
  LoadIntermediarySuggestionsSuccess = 'loanOriginationSystemCreateApplication/loadIntermediarySuggestionsSuccess',
  LoadIntermediarySuggestionsFailed = 'loanOriginationSystemCreateApplication/loadIntermediarySuggestionsFailed',
  SelectIntermediary = 'loanOriginationSystemCreateApplication/selectIntermediary',
  SelectIntermediaryToEdit = 'loanOriginationSystemCreateApplication/selectIntermediaryToEdit',
}
