import React, { FC, ReactElement, ReactNode } from 'react';
import { Tooltip } from '@material-ui/core';
import styles from './WrapperWithHoverableTooltip.module.scss';
import clsx from 'clsx';

interface WrapperWithHoverableTooltipProps {
  tooltip: ReactNode;
  className?: string;
}

const WrapperWithHoverableTooltip: FC<WrapperWithHoverableTooltipProps> = ({ tooltip, children, className }) => {
  return (
    <Tooltip
      classes={{ popperInteractive: clsx(styles.tooltip, className) }}
      title={tooltip || ''}
      placement="bottom-start"
      interactive
    >
      {children as ReactElement}
    </Tooltip>
  );
};

export default WrapperWithHoverableTooltip;
