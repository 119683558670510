import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { Borrower, BorrowersSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const BORROWERS_PER_PAGE_DEFAULT = 20;

export interface BorrowersPaginationParams {
  search: string;
  sortingType: BorrowersSortingType;
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  isCompany: boolean;
  isPerson: boolean;
  selectedMembers: UserInfo[];
}

const borrowersPagination = Pagination<ReduxState, Borrower, BorrowersPaginationParams>(
  'loanOriginationSystemBorrowers',
  BORROWERS_PER_PAGE_DEFAULT,
  ({ search }) => (search ? ['searched', search] : []),
);

export default borrowersPagination;
