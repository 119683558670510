import { AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { GridScale } from '@visx/grid/lib/types';
import React from 'react';
import styles from 'components/LoanOriginationSystem/ReportingDashboard/Chart/Chart.module.scss';

interface RightAxisProps {
  height: number;
  primaryScale: GridScale;
  secondaryScale: GridScale;
}

const RightAxis = ({ height, primaryScale, secondaryScale }: RightAxisProps) => {
  return (
    <svg height={height - 20} width={40}>
      <GridRows
        top={22}
        scale={primaryScale}
        width={40}
        height={height}
        stroke="black"
        strokeOpacity={0.1}
        numTicks={4}
      />
      <AxisRight
        left={32}
        top={-30}
        scale={secondaryScale}
        tickLabelProps={() => ({
          fill: styles.fontColor,
          fontSize: styles.fontSize,
          lineHeight: styles.lineHeight,
          textAnchor: 'end',
          fontFamily: styles.fontFamily,
          fontWeight: styles.fontWeight,
        })}
        hideAxisLine
        hideTicks
      />
    </svg>
  );
};

export default RightAxis;
