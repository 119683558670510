import React, { useState } from 'react';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import { Option } from 'components/SelectInput/SelectInput';
import AutoCompletion from 'components/AutoCompletion';
import styles from './ApiRequestTemplatePopup.module.scss';

interface BaseApiRequestTemplatePopupInput {
  label: string;
  type: string;
}

interface SyncApiRequestTemplateInput extends BaseApiRequestTemplatePopupInput {
  options: Array<Option>;
}

interface AsyncApiRequestTemplateInput extends BaseApiRequestTemplatePopupInput {
  fetchOptions: (optionName: string, abortSignal: AbortSignal) => Promise<Option[]>;
}

export type ApiRequestTemplateInput = SyncApiRequestTemplateInput | AsyncApiRequestTemplateInput;

export interface ApiRequestTemplatePopupProps {
  inputs: ApiRequestTemplateInput[];
  title: string;
  onClose: () => void;
  onDownload(inputs: Record<string, string>): void;
  isDownloadInProgress?: boolean;
}

const ApiRequestTemplatePopup = ({
  inputs,
  title,
  onClose,
  onDownload,
  isDownloadInProgress,
}: ApiRequestTemplatePopupProps) => {
  const [inputValues, setInputValues] = useState<Record<string, string>>({});

  const renderInput = ({ label, type, ...restProps }: ApiRequestTemplateInput) => (
    <AutoCompletion
      key={type}
      {...restProps}
      labelTitle={label}
      value={inputValues[type]}
      onChange={(value) => setInputValues({ ...inputValues, [type]: value.value })}
    />
  );

  const downloadButtonDisabled = inputs.some((input) => !inputValues[input.type]);

  return (
    <PopUp classNames={{ popupCore: styles.popupCore }} closePopUp={onClose} title={title}>
      <PopUpContent hasTopMargin>
        {inputs.map(renderInput)}
        <Button
          onClick={() => onDownload(inputValues)}
          kind="primary"
          size="form"
          isLoading={isDownloadInProgress}
          disabled={downloadButtonDisabled}
          className={styles.downloadButton}
        >
          Download Template
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default ApiRequestTemplatePopup;
