import React, { FC } from 'react';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import RecipientTableRow, { RecipientTableItem } from './RecipientTableRow/RecipientTableRow';
import SkeletonRecipientsTableItem from './SkeletonRecipientsTableItem';
import TableColumnSize from './TableColumnSize';
import styles from './RecipientsTable.module.scss';

export interface RecipientsTableProps {
  items: RecipientTableItem[] | null;
}

const DEFAULT_SKELETON_ITEMS_LENGTH = 5;

const RecipientsTable: FC<RecipientsTableProps> = ({ items }) => {
  const renderRecipientsTableBody = () => {
    if (!items) {
      return (
        <>
          {new Array(DEFAULT_SKELETON_ITEMS_LENGTH).fill(null).map((item, index) => (
            <SkeletonRecipientsTableItem key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        {items.map((item) => (
          <RecipientTableRow key={item.recipientId} item={item} />
        ))}
      </>
    );
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSize.Recipient}>
            Recipient
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSize.Name}>
            Name
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSize.Email}>
            Email Address
          </TableHeadCell>
        </TableHead>
        <TableBody>{renderRecipientsTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default RecipientsTable;
