import React, { FC, useCallback, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import styles from './Menu.module.scss';

import { Product } from 'MainLayout/leftNavReducer/LeftNavTypes';
import { CloseImage, CheckedImage } from 'static/images';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { batch } from 'react-redux';
import { ApplicationSectionName, ProductAccess } from 'components/RouteWithPermissions/Types';
import { NOT_FOUND_PAGE_SECTION } from 'MainLayout/utils';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

type BurgerTypes = {
  pagesList: Product[];
  onClose: () => void;
  activeProductTitle?: ApplicationSectionName | NOT_FOUND_PAGE_SECTION;
  onLogOut: () => void;
  multipleOrganizations: boolean;
  productAccess: ProductAccess;
};

const burgerMenuTitle = 'MENU';
const INACTIVE_PRODUCT_TOOLTIP_TEXT = (
  <p>
    This product is not active <br /> for your company
  </p>
);

const Menu: FC<BurgerTypes> = ({
  pagesList,
  onClose,
  activeProductTitle,
  onLogOut,
  multipleOrganizations,
  productAccess,
}) => {
  const handleLogOut = () => {
    batch(() => {
      onClose();
      onLogOut();
    });
  };

  const preventDefault = useCallback((event: MouseEvent<HTMLAnchorElement>) => event.preventDefault(), []);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <div className={styles.burgerMenuContainer}>
        <div className={styles.menuHeader}>
          <CloseImage onClick={onClose} className={styles.closeButton} />
          <h6 className={styles.menuTitle}>{burgerMenuTitle}</h6>
        </div>
        <div className={styles.linksContainer}>
          {pagesList.map((page) => {
            const { title, route } = page;

            const isEnabled = page.product ? productAccess[page.product] : true;
            const isActive = title === activeProductTitle;

            return (
              <WrapperWithTooltip tooltip={!isEnabled && INACTIVE_PRODUCT_TOOLTIP_TEXT} position="top" key={title}>
                <NavLink
                  className={clsx(
                    styles.link,
                    isActive && styles.linkActive,
                    isEnabled ? styles.linkEnabled : styles.linkDisabled,
                  )}
                  to={route}
                  onClick={isEnabled ? onClose : preventDefault}
                >
                  {title === activeProductTitle && <CheckedImage className={styles.checkedIcon} />}
                  <p>{title}</p>
                </NavLink>
              </WrapperWithTooltip>
            );
          })}
          {multipleOrganizations && (
            <NavLink className={styles.switchOrganizationLink} to="/auth/select-organization" onClick={onClose}>
              <p>Switch Organization</p>
            </NavLink>
          )}
          <button type="button" className={styles.logOutLink} onClick={handleLogOut}>
            <p>Log Out</p>
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Menu;
