import { AutomatedProcessesApi, AutomatedProcess } from 'api/LoanOriginationSystem/ProcessAutomationApi';
import Fetcher from 'middlewares/Fetcher';
import {
  UpdateRuntimeOptionsAction,
  updateRuntimeOptionsFailure,
  updateRuntimeOptionsSuccess,
} from './ActionCreator';
import { ProcessAutomationActionType } from './ActionTypes';

const UpdateRuntimeOptionsMiddleware = (api: AutomatedProcessesApi) =>
  Fetcher<AutomatedProcess, UpdateRuntimeOptionsAction>(
    ProcessAutomationActionType.UpdateRuntimeOptions,
    updateRuntimeOptionsSuccess,
    updateRuntimeOptionsFailure,
    (action) => {
      return api.updateRuntimeOptions(action.payload.id, action.payload.runtimeOptionsUpdate);
    },
  );

export default UpdateRuntimeOptionsMiddleware;
