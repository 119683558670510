import { AnyAction } from 'redux';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ApplicationsWorkflowSetupActionType } from './ActionTypes';
import { DeleteStatusRuleData } from './Types';

export interface SetApplicationStatusToDeleteAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.SetApplicationStatusToDelete;
  payload: {
    status: ApplicationStatus | null;
  };
}

export interface OpenDeclineReasonsPopupAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.OpenDeclineReasonsPopup;
}

export interface CloseDeclineReasonsPopupAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.CloseDeclineReasonsPopup;
}

export interface OpenCreateVariablePopupAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.OpenCreateVariablePopup;
}

export interface CloseCreateVariablePopupAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.CloseCreateVariablePopup;
}

export interface SetStatusToAddRuleAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.SetStatusToAddRule;
  payload: {
    status: ApplicationStatus | null;
  };
}

export interface SetStatusRuleToEditAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.SetStatusRuleToEdit;
  payload: {
    statusRule: ApplicationStatusRule | null;
  };
}

export interface SetDeleteStatusRuleDataAction extends AnyAction {
  type: ApplicationsWorkflowSetupActionType.SetDeleteStatusRuleData;
  payload: DeleteStatusRuleData | null;
}

export const setApplicationStatusToDelete = (
  applicationStatus: ApplicationStatus | null,
): SetApplicationStatusToDeleteAction => ({
  type: ApplicationsWorkflowSetupActionType.SetApplicationStatusToDelete,
  payload: {
    status: applicationStatus,
  },
});

export const openDeclineReasonsPopup = (): OpenDeclineReasonsPopupAction => ({
  type: ApplicationsWorkflowSetupActionType.OpenDeclineReasonsPopup,
});

export const closeDeclineReasonsPopup = (): CloseDeclineReasonsPopupAction => ({
  type: ApplicationsWorkflowSetupActionType.CloseDeclineReasonsPopup,
});

export const setStatusToAddRule = (status: ApplicationStatus | null): SetStatusToAddRuleAction => ({
  type: ApplicationsWorkflowSetupActionType.SetStatusToAddRule,
  payload: {
    status,
  },
});

export const setStatusRuleToEdit = (statusRule: ApplicationStatusRule | null): SetStatusRuleToEditAction => ({
  type: ApplicationsWorkflowSetupActionType.SetStatusRuleToEdit,
  payload: {
    statusRule,
  },
});

export const setDeleteStatusRuleData = (data: DeleteStatusRuleData | null): SetDeleteStatusRuleDataAction => ({
  type: ApplicationsWorkflowSetupActionType.SetDeleteStatusRuleData,
  payload: data,
});

export const openCreateVariablePopup = (): OpenCreateVariablePopupAction => ({
  type: ApplicationsWorkflowSetupActionType.OpenCreateVariablePopup,
});

export const closeCreateVariablePopup = (): CloseCreateVariablePopupAction => ({
  type: ApplicationsWorkflowSetupActionType.CloseCreateVariablePopup,
});
