import React, { FC } from 'react';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/DecisionStrategiesTypes';
import { NullableItems, PaginationProps } from 'pagination';
import { SIZES } from 'components/StrategyOverview/StrategyUpdates/TableColumnSizes';
import styles from './StrategyUpdates.module.scss';
import UpdateLine from './UpdateLine';
import Pagination from 'components/Pagination/Pagination';
import SkeletonUpdateLine from './SkeletonUpdateLine';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableBodyContent from 'components/Table/TableBodyContent';
import NoItems from 'components/NoItems';

interface StrategyUpdatesProps {
  decisionStrategyUpdates: NullableItems<StrategyUpdateType>;
  decisionStrategyUpdatesPaginationProps: PaginationProps;
}

const StrategyUpdates: FC<StrategyUpdatesProps> = ({
  decisionStrategyUpdates,
  decisionStrategyUpdatesPaginationProps,
}) => {
  const renderRow = (strategyUpdate: StrategyUpdateType) => (
    <UpdateLine strategyUpdate={strategyUpdate} key={strategyUpdate.updatedAt} />
  );

  const renderBody = () => {
    if (!decisionStrategyUpdates.length) {
      return (
        <NoItems
          title="No updates have been made yet!"
          titleClassName={styles.updatesNoDataTitle}
          className={styles.updatesNoData}
        />
      );
    }

    return (
      <>
        <div className={styles.strategyUpdatesContent__table}>
          <TableHead>
            <TableHeadCell width={SIZES.UPDATED}>Updated</TableHeadCell>
            <TableHeadCell width={SIZES.EDITOR}>Editor</TableHeadCell>
            <TableHeadCell width={SIZES.TYPE}>Update Type</TableHeadCell>
          </TableHead>
          <TableBody>
            <TableBodyContent
              rows={decisionStrategyUpdates}
              renderSkeletonTableRow={(index) => <SkeletonUpdateLine key={index} />}
              renderTableRow={renderRow}
            />
          </TableBody>
        </div>
        <Pagination {...decisionStrategyUpdatesPaginationProps} />
      </>
    );
  };

  return (
    <div className={styles.strategyUpdatesContent}>
      <h4 className={styles.strategyUpdatesContent__title}>Update History</h4>
      {renderBody()}
    </div>
  );
};

export default StrategyUpdates;
