import React, { FC, ReactElement, ReactNode } from 'react';
import Footer from 'components/Footer';
import ConnectedNotifications from 'Notifications/Notifications';
import styles from './AuthLayout.module.scss';
import { DigifiAuthLogoBlack, DigifiAuthLogoWhite, MoveBackImage } from 'static/images';
import clsx from 'clsx';

interface AuthLayoutProps {
  title?: string;
  subTitle?: ReactElement | string;
  titleImage?: ReactElement;
  showBackLink?: boolean;
  onClickBack?: () => void;
  organizationLogo?: ReactNode;
  formContainerClassName?: string;
  formContainerBodyClassName?: string;
  children: ReactNode;
}

const AuthLayout: FC<AuthLayoutProps> = ({
  children,
  title,
  subTitle,
  titleImage,
  showBackLink = false,
  onClickBack,
  organizationLogo,
  formContainerClassName,
  formContainerBodyClassName,
}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={clsx(styles.formContainer, formContainerClassName)}>
          <div className={styles.formContainerHeader}>
            {organizationLogo || <DigifiAuthLogoBlack className={styles.darkLogo} />}
          </div>
          <div className={clsx(styles.formContainerBody, formContainerBodyClassName)}>
            {showBackLink && (
              <div className={styles.backLink} onClick={onClickBack}>
                <MoveBackImage />
                Back
              </div>
            )}
            {title && (
              <div className={styles.formContainerBodyTitleContainer}>
                {titleImage && <div className={styles.formContainerBodyImageTitle}>{titleImage}</div>}
                <h2 className={styles.formContainerBodyTitle}>{title}</h2>
                {subTitle && <div className={styles.formContainerBodySubTitle}>{subTitle}</div>}
              </div>
            )}
            {children}
          </div>
          <Footer separator className={styles.footer} />
        </div>
        <div className={styles.waveContainer}>
          <DigifiAuthLogoWhite className={styles.lightLogo} />
        </div>
      </div>
      <ConnectedNotifications className={styles.notifications} />
    </>
  );
};

export default AuthLayout;
