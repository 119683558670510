import React, { FC } from 'react';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { ESignTemplateField } from 'api/Core/ESignTemplatesApi';
import { VariableValue } from 'api/LoanOriginationSystem/Types';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import TableColumnSize from 'components/LoanOriginationSystem/SendESignaturePopup/FieldsTable/TableColumnSize';
import { getTableDisplayValue } from 'components/LoanOriginationSystem/SendESignaturePopup/Utils';
import OverflowedText from 'components/OverflowedText';

export interface FieldTableItem extends ESignTemplateField {
  value: VariableValue;
}

export interface FieldTableRowProps {
  item: FieldTableItem;
}

const FieldTableRow: FC<FieldTableRowProps> = ({ item }) => {
  const isEmptyFieldValue = isEmptyVariableValue(item.value) && item.required;

  return (
    <TableRow error={isEmptyFieldValue} disableHover>
      <TableBodyCell withRightPadding asNameCell error={isEmptyFieldValue} width={TableColumnSize.FieldName}>
        <OverflowedText>{item.name}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell error={isEmptyFieldValue} width={TableColumnSize.FieldValue}>
        <OverflowedText>{getTableDisplayValue(item.value)}</OverflowedText>
      </TableBodyCell>
    </TableRow>
  );
};

export default FieldTableRow;
