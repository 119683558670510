import { AnyAction } from 'redux';

import {
  ACTION_TYPE_EDIT_COMPANY_USER_REQUEST,
  ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS,
  ACTION_TYPE_EDIT_COMPANY_USER_FAILURE,
} from './ActionTypes';
import { EditUserParams } from './EditCompanyUserTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { UpdateUserResult } from 'api/Core/UserApi';

export interface EditCompanyUserRequestAction extends AnyAction {
  payload: EditUserParams;
}

export interface EditCompanyUserSuccessAction extends AnyAction {
  payload: UpdateUserResult;
}

export interface GetCompanyUserRequestAction extends AnyAction {
  payload: string;
}

export interface GetCompanyUserFailureAction extends AnyAction {
  payload: {
    message: string;
    responseStatus: number;
    userId: string;
  };
}

export const EditCompanyUserSuccess = (userInfo: UpdateUserResult): EditCompanyUserSuccessAction => {
  return {
    type: ACTION_TYPE_EDIT_COMPANY_USER_SUCCESS,
    payload: userInfo,
  };
};

export const EditCompanyUserError = FailedActionCreator(ACTION_TYPE_EDIT_COMPANY_USER_FAILURE);

export const EditCompanyUserRequest = (userParams: EditUserParams): EditCompanyUserRequestAction => {
  return {
    type: ACTION_TYPE_EDIT_COMPANY_USER_REQUEST,
    payload: userParams,
  };
};
