import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BorrowerVariablesConfigurationHeader from './BorrowerVariablesConfigurationHeader';
import {
  createBorrowerVariableConfiguration,
  deleteBorrowerVariableConfiguration,
  getBorrowerVariableConfigurations,
  updateBorrowerVariableConfiguration,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getBorrowerVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemBorrowers/utils';
import { BorrowerType } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { ReduxState } from 'types/redux';
import { TabSwitchOption } from 'components/TabSwitch';
import BaseVariablesConfigurationLayout from 'components/LoanOriginationSystem/VariablesConfiguration/BaseVariablesConfigurationLayout';
import { Variable } from 'Variables/VariablesTypes';

const Tabs: TabSwitchOption[] = [
  { label: 'Person', id: BorrowerType.Person },
  { label: 'Company', id: BorrowerType.Company },
];

export interface BorrowerVariablesConfigurationProps {
  borrowerType: BorrowerType;
  onBorrowerTypeChange: (borrowerType: BorrowerType) => void;
  onDownloadApiTemplate: () => void;
}

const BorrowerVariablesConfiguration = ({
  borrowerType,
  onBorrowerTypeChange,
  onDownloadApiTemplate,
}: BorrowerVariablesConfigurationProps) => {
  const dispatch = useDispatch();
  const configurations = useSelector((state: ReduxState) =>
    getBorrowerVariableConfigurationsSelector(state, { borrowerType }),
  );

  useEffect(() => {
    if (!configurations) {
      dispatch(getBorrowerVariableConfigurations(borrowerType));
    }
  }, [borrowerType]);

  const handleVariableReorder = (id: string, column: number, position: number) => {
    dispatch(updateBorrowerVariableConfiguration(id, borrowerType, { column, position }));
  };

  const handleVariableAdd = (column: number, position: number, variable: Variable) => {
    dispatch(createBorrowerVariableConfiguration(borrowerType, column, position, variable));
  };

  const handleVariableDelete = (id: string) => {
    dispatch(deleteBorrowerVariableConfiguration(id, borrowerType));
  };

  const getFormHeader = () => {
    const tab = Tabs.find(({ id }) => id === borrowerType);

    return tab ? tab.label : '';
  };

  const renderHeader = () => (
    <BorrowerVariablesConfigurationHeader
      onChangeBorrowerType={onBorrowerTypeChange}
      onDownloadApiTemplate={onDownloadApiTemplate}
      borrowerType={borrowerType}
      tabs={Tabs}
    />
  );

  return (
    <BaseVariablesConfigurationLayout
      configurations={configurations || null}
      renderHeader={renderHeader}
      onVariableReorder={handleVariableReorder}
      onVariableDelete={handleVariableDelete}
      onVariableAdd={handleVariableAdd}
      formHeader={getFormHeader()}
      cardHeader="Borrower Data Fields"
      formatDisplayTitle={formatDefaultFieldsDisplayTitle}
    />
  );
};

export default BorrowerVariablesConfiguration;
