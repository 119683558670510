import { FailedAction } from 'utils/actions/FailedActionCreator';
import UpdateApplicationStatusError from 'errors/UpdateApplicationStatusError';
import {
  ACTION_TYPE_CREATE_VARIABLE_FAILURE,
  ACTION_TYPE_CREATE_VARIABLE_REQUEST,
  ACTION_TYPE_CREATE_VARIABLE_SUCCESS,
} from 'Variables/ActionTypes';
import {
  CreateVariableActionOrigin,
  CreateVariableRequestAction,
  CreateVariableSuccessAction,
} from 'Variables/VariablesActionCreator';
import {
  CreateApplicationStatusAction,
  CreateApplicationStatusRuleAction,
  CreateApplicationStatusRuleSuccessAction,
  CreateApplicationStatusSuccessAction,
  DeleteApplicationStatusAction,
  DeleteApplicationStatusRuleAction,
  DeleteApplicationStatusRuleSuccessAction,
  DeleteApplicationStatusSuccessAction,
  GetApplicationStatusesSuccessAction,
  UpdateApplicationStatusAction,
  UpdateApplicationStatusActionOrigin,
  UpdateApplicationStatusRuleAction,
  UpdateApplicationStatusRuleSuccessAction,
  UpdateApplicationStatusSuccessAction,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import {
  UpdateDeclineReasonsAction,
  UpdateDeclineReasonsSuccessAction,
} from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import { ApplicationStatusesActionType } from 'LoanOriginationSystemApplicationStatuses/ActionTypes';
import { DeclineReasonsActionType } from 'LoanOriginationSystemDeclineReasons/ActionTypes';
import {
  CloseCreateVariablePopupAction,
  CloseDeclineReasonsPopupAction,
  OpenCreateVariablePopupAction,
  OpenDeclineReasonsPopupAction,
  SetApplicationStatusToDeleteAction,
  SetDeleteStatusRuleDataAction,
  SetStatusRuleToEditAction,
  SetStatusToAddRuleAction,
} from './ActionCreator';
import { ApplicationsWorkflowSetupState } from './Types';
import { ApplicationsWorkflowSetupActionType } from './ActionTypes';

export const initialState: ApplicationsWorkflowSetupState = {
  applicationStatusToDelete: null,
  isStatusDeletingInProgress: false,
  isBlockingRequestInProgress: false,
  isDeclineReasonsPopupOpen: false,
  isDeclineReasonsUpdatingInProgress: false,
  isStatusRuleCreatingInProgress: false,
  isStatusRuleDeletingInProgress: false,
  isStatusRuleUpdatingInProgress: false,
  isCreateVariablePopupOpen: false,
  isVariableCreatingInProgress: false,
  statusUpdateIds: [],
  statusToAddRule: null,
  statusRuleToEdit: null,
  deleteStatusRuleData: null,
};

export type ApplicationsWorkflowSetupActions =
  | OpenCreateVariablePopupAction
  | CloseCreateVariablePopupAction
  | SetStatusToAddRuleAction
  | SetStatusRuleToEditAction
  | SetDeleteStatusRuleDataAction
  | SetApplicationStatusToDeleteAction
  | OpenDeclineReasonsPopupAction
  | CloseDeclineReasonsPopupAction
  | UpdateApplicationStatusAction
  | CreateApplicationStatusAction
  | UpdateDeclineReasonsAction
  | DeleteApplicationStatusAction
  | CreateApplicationStatusSuccessAction
  | GetApplicationStatusesSuccessAction
  | DeleteApplicationStatusSuccessAction
  | UpdateApplicationStatusSuccessAction
  | UpdateDeclineReasonsSuccessAction
  | CreateApplicationStatusRuleAction
  | CreateApplicationStatusRuleSuccessAction
  | DeleteApplicationStatusRuleAction
  | DeleteApplicationStatusRuleSuccessAction
  | UpdateApplicationStatusRuleAction
  | UpdateApplicationStatusRuleSuccessAction
  | CreateVariableRequestAction
  | CreateVariableSuccessAction
  | FailedAction<typeof ACTION_TYPE_CREATE_VARIABLE_FAILURE>
  | FailedAction<DeclineReasonsActionType.UpdateDeclineReasonsFailure>
  | FailedAction<ApplicationStatusesActionType.UpdateApplicationStatusFailure>
  | FailedAction<ApplicationStatusesActionType.CreateApplicationStatusFailure>
  | FailedAction<ApplicationStatusesActionType.DeleteApplicationStatusFailure>
  | FailedAction<ApplicationStatusesActionType.CreateApplicationStatusRuleFailure>
  | FailedAction<ApplicationStatusesActionType.DeleteApplicationStatusRuleFailure>
  | FailedAction<ApplicationStatusesActionType.UpdateApplicationStatusRuleFailure>;

const applicationsWorkflowSetupReducer = (state = initialState, action: ApplicationsWorkflowSetupActions) => {
  switch (action.type) {
    case ApplicationsWorkflowSetupActionType.SetApplicationStatusToDelete: {
      return {
        ...state,
        applicationStatusToDelete: action.payload.status,
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatus: {
      return {
        ...state,
        isBlockingRequestInProgress: true,
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusSuccess:
    case ApplicationStatusesActionType.CreateApplicationStatusFailure: {
      return {
        ...state,
        isBlockingRequestInProgress: false,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatus: {
      return {
        ...state,
        isStatusDeletingInProgress: true,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusSuccess: {
      return {
        ...state,
        isStatusDeletingInProgress: false,
        applicationStatusToDelete: null,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusFailure: {
      return {
        ...state,
        isStatusDeletingInProgress: false,
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatus: {
      if (action.meta?.actionOrigin === UpdateApplicationStatusActionOrigin.StatusesBoard) {
        return {
          ...state,
          isBlockingRequestInProgress: true,
        };
      }

      return {
        ...state,
        statusUpdateIds: [...state.statusUpdateIds, action.payload.statusId],
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusSuccess: {
      if (action.meta?.actionOrigin === UpdateApplicationStatusActionOrigin.StatusesBoard) {
        return {
          ...state,
          isBlockingRequestInProgress: false,
        };
      }

      return {
        ...state,
        statusUpdateIds: state.statusUpdateIds.filter((statusId) => statusId !== action.payload.status.id),
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusFailure: {
      if (action.meta?.actionOrigin === UpdateApplicationStatusActionOrigin.StatusesBoard) {
        return {
          ...state,
          isBlockingRequestInProgress: false,
        };
      }

      const error = action.payload;

      if (!(error instanceof UpdateApplicationStatusError)) {
        return state;
      }

      return {
        ...state,
        statusUpdateIds: state.statusUpdateIds.filter((statusId) => statusId !== error.statusId),
      };
    }
    case ApplicationsWorkflowSetupActionType.OpenDeclineReasonsPopup: {
      return {
        ...state,
        isDeclineReasonsPopupOpen: true,
      };
    }
    case ApplicationsWorkflowSetupActionType.CloseDeclineReasonsPopup: {
      return {
        ...state,
        isDeclineReasonsPopupOpen: false,
      };
    }
    case DeclineReasonsActionType.UpdateDeclineReasons: {
      return {
        ...state,
        isDeclineReasonsUpdatingInProgress: true,
      };
    }
    case DeclineReasonsActionType.UpdateDeclineReasonsSuccess: {
      return {
        ...state,
        isDeclineReasonsPopupOpen: false,
        isDeclineReasonsUpdatingInProgress: false,
      };
    }
    case DeclineReasonsActionType.UpdateDeclineReasonsFailure: {
      return {
        ...state,
        isDeclineReasonsUpdatingInProgress: false,
      };
    }
    case ApplicationsWorkflowSetupActionType.SetStatusToAddRule: {
      return {
        ...state,
        statusToAddRule: action.payload.status,
      };
    }
    case ApplicationsWorkflowSetupActionType.SetStatusRuleToEdit: {
      return {
        ...state,
        statusRuleToEdit: action.payload.statusRule,
      };
    }
    case ApplicationsWorkflowSetupActionType.SetDeleteStatusRuleData: {
      return {
        ...state,
        deleteStatusRuleData: action.payload,
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusRule: {
      return {
        ...state,
        isStatusRuleCreatingInProgress: true,
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusRuleSuccess: {
      return {
        ...state,
        isStatusRuleCreatingInProgress: false,
        statusToAddRule: null,
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusRuleFailure: {
      return {
        ...state,
        isStatusRuleCreatingInProgress: false,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusRule: {
      return {
        ...state,
        isStatusDeletingInProgress: true,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusRuleSuccess: {
      return {
        ...state,
        isStatusDeletingInProgress: false,
        deleteStatusRuleData: null,
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusRuleFailure: {
      return {
        ...state,
        isStatusDeletingInProgress: false,
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusRule: {
      return {
        ...state,
        isStatusRuleUpdatingInProgress: true,
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusRuleSuccess: {
      return {
        ...state,
        isStatusRuleUpdatingInProgress: false,
        statusRuleToEdit: null,
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusRuleFailure: {
      return {
        ...state,
        isStatusRuleUpdatingInProgress: false,
      };
    }
    case ApplicationsWorkflowSetupActionType.CloseCreateVariablePopup: {
      return {
        ...state,
        isCreateVariablePopupOpen: false,
      };
    }
    case ApplicationsWorkflowSetupActionType.OpenCreateVariablePopup: {
      return {
        ...state,
        isCreateVariablePopupOpen: true,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_REQUEST: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.ApplicationStatusRule) {
        return state;
      }

      return {
        ...state,
        isVariableCreatingInProgress: true,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_SUCCESS: {
      if (action.meta?.actionOrigin !== CreateVariableActionOrigin.ApplicationStatusRule) {
        return state;
      }

      return {
        ...state,
        isCreateVariablePopupOpen: false,
        isVariableCreatingInProgress: false,
      };
    }
    case ACTION_TYPE_CREATE_VARIABLE_FAILURE: {
      return {
        ...state,
        isVariableCreatingInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default applicationsWorkflowSetupReducer;
