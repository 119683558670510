import Fetcher from 'middlewares/Fetcher';
import {
  DeleteEmailAction,
  deleteEmailFailure,
  deleteEmailSuccess,
} from 'LoanOriginationSystemEmails/ActionCreator';
import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';

const GetEmailsMiddleware = (api: LoanOriginationSystemEmailsApi) =>
  Fetcher<void, DeleteEmailAction>(
    LoanOriginationSystemEmailsActionTypes.DeleteEmail,
    deleteEmailSuccess,
    deleteEmailFailure,
    async (action) => {
      const { emailId } = action.payload;

      await api.deleteEmail(emailId);
    },
  );

export default GetEmailsMiddleware;
