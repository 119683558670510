import Debouncer, { REQUEST_DELAY_MS } from 'middlewares/Debouncer';
import { ESignTemplatesActionOrigin, getESignTemplates } from 'ESignTemplates/ActionCreator';
import { DocuSignFiltersActionType } from './ActionTypes';
import { DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT } from 'components/DocuSign/pagination';

export default Debouncer<{ search: string; integrationId: string }>(
  (payload, getState) => {
    const { docuSign } = getState();
    const { sortingType, filters } = docuSign;

    return getESignTemplates(
      {
        integrationId: payload.integrationId,
        search: payload.search,
        status: filters.selectedStatus,
        members: filters.selectedMembers,
        createdDateRange: filters.createdDateRange,
        updatedDateRange: filters.updatedDateRange,
      },
      sortingType,
      DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
    );
  },
  [DocuSignFiltersActionType.SetSearchInputValue],
  REQUEST_DELAY_MS,
  ESignTemplatesActionOrigin.DocuSignConfiguration,
);
