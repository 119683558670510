import React, { FC } from 'react';
import styles from './VersionTableRow.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { VERSIONS_TABLE_COLUMNS_SIZES } from 'components/VersionsView/VersionsTableView/TableColumnSizes';
import { StrategyGroupVersion } from 'api/Types';
import { LockedImage, UnlockedImage } from 'static/images';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import StrategyStatusLabel from 'components/StrategyStatusLabel';
import VersionActions from 'components/StrategyGroups/StrategiesTableView/VersionActions';
import { useHistory } from 'react-router-dom';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';
import UnknownUserAvatar from 'components/UnknownUserAvatar';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

interface VersionTableRowProps {
  versionData: StrategyGroupVersion;
  origin: StrategyActionOrigin;
  isCurrent: boolean;
}

const VersionTableRow: FC<VersionTableRowProps> = ({ versionData, origin, isCurrent }) => {
  const history = useHistory();
  const { id, title, status, locked, version, updatedDate, description, updatedByUserInfo, hasModules } = versionData;
  const versionDataForActions = { id, status, version, displayTitle: title, hasModules } as StrategyGroupVersion;

  const openVersion = () => {
    history.push(`/decision/strategies/${id}/overview`);
  };

  return (
    <div onClick={() => openVersion()}>
      <TableRow useActions className={styles.tableRow}>
        <TableBodyCell className={styles.versionCell} width={VERSIONS_TABLE_COLUMNS_SIZES.VERSION} noPadding>
          {version}
          {locked ? <LockedImage /> : <UnlockedImage />}
        </TableBodyCell>
        <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.STATUS}>
          <StrategyStatusLabel status={getNormalizedStatus(status)} />
        </TableBodyCell>
        <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.UPDATED} noPadding>
          <div className={styles.strategyUpdateInfo}>
            {updatedByUserInfo ? (
              <ImageFetcher
                firstName={updatedByUserInfo.firstName}
                lastName={updatedByUserInfo.lastName}
                imageId={updatedByUserInfo.imageId}
                size="tiny"
                tooltip
              />
            ) : (
              <UnknownUserAvatar size="tiny" tooltip />
            )}
            <DateTime time={updatedDate} format={DateTimeFormat.Long} />
          </div>
        </TableBodyCell>
        <TableBodyCell width={VERSIONS_TABLE_COLUMNS_SIZES.DESCRIPTION}>{description}</TableBodyCell>
        <VersionActions version={versionDataForActions} origin={origin} isCurrent={isCurrent} />
      </TableRow>
    </div>
  );
};

export default VersionTableRow;
