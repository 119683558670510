import React, { FC, useState } from 'react';
import PopUp from 'components/PopUps/PopUp';
import EditExternalIntegrationVariables from 'components/ExternalIntegration/PopUps/EditExternalIntegrationVariables';
import { EditExternalIntegrationVariablesPopUpState } from './Types';
import { ExternalIntegrationInputsList } from 'api/DecisionEngineStrategiesType';
import AssignOutputVariable from 'components/ExternalIntegration/PopUps/AssignOutputVariable';

interface EditExternalIntegrationVariablesPopUpProps {
  closePopUp: () => void;
  externalIntegrationInfo: EditExternalIntegrationVariablesPopUpState;
  editExternalIntegration: (inputList: ExternalIntegrationInputsList[]) => Promise<void>;
  openVariableCreation: () => void;
  popUpType: 'edit' | 'assign';
  handleOpenVariableCreation: () => void;
  assignedOutputId: string;
  handleAssignOutputVariable: (outputIndex: number, variableId: string) => Promise<void>;
}

const EDIT_EXTERNAL_INTEGRATION_POP_UP_NAME = 'Variables Required for Data Integration';
const ASSIGN_VARIABLE_POP_UP_NAME = 'Assign Output Variable';

const EditExternalIntegrationVariablesPopUp: FC<EditExternalIntegrationVariablesPopUpProps> = ({
  closePopUp,
  externalIntegrationInfo,
  editExternalIntegration,
  openVariableCreation,
  popUpType,
  handleOpenVariableCreation,
  assignedOutputId,
  handleAssignOutputVariable,
}) => {
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const assignedOutput = externalIntegrationInfo.outputList.find((output) => output.apiName === assignedOutputId);
  const assignedOutputIndex = externalIntegrationInfo.outputList.indexOf(assignedOutput!);

  const handleEditIntegration = async (inputList: ExternalIntegrationInputsList[]) => {
    try {
      setUpdateInProgress(true);
      await editExternalIntegration(inputList);
    } finally {
      setUpdateInProgress(false);
    }
  };

  const handleAssignVariable = async (outputIndex: number, variableId: string) => {
    try {
      setUpdateInProgress(true);
      await handleAssignOutputVariable(outputIndex, variableId);
    } finally {
      setUpdateInProgress(false);
    }
  };

  return (
    <PopUp
      title={popUpType === 'edit' ? EDIT_EXTERNAL_INTEGRATION_POP_UP_NAME : ASSIGN_VARIABLE_POP_UP_NAME}
      closePopUp={closePopUp}
    >
      {popUpType === 'edit' ? (
        <EditExternalIntegrationVariables
          externalIntegrationInfo={externalIntegrationInfo}
          editExternalIntegration={handleEditIntegration}
          openVariableCreation={openVariableCreation}
          isLoading={updateInProgress}
        />
      ) : (
        <AssignOutputVariable
          openVariableCreation={handleOpenVariableCreation}
          assignedOutput={assignedOutput!}
          assignOutputVariable={handleAssignVariable}
          assignedOutputIndex={assignedOutputIndex}
          isLoading={updateInProgress}
        />
      )}
    </PopUp>
  );
};

export default EditExternalIntegrationVariablesPopUp;
