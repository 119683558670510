import React, { ReactElement, Fragment } from 'react';
import clsx from 'clsx';
import styles from './TabSwitch.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import { RemoveTabImage } from 'static/images';

export interface TabSwitchOption<TabSwitchOptionLabelType = ReactElement | string> {
  label: TabSwitchOptionLabelType;
  id: string;
  type?: 'default' | 'button';
  ref?: React.RefObject<HTMLButtonElement>;
  disableDelete?: boolean;
  onClick?: () => void;
}

interface TabSwitchProps<TabSwitchOptionLabelType> {
  tabs: TabSwitchOption<TabSwitchOptionLabelType>[];
  selectedTabId: string;
  onSelect: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  className?: string;
  buttonsTabIndex?: number;
  allowTabDelete?: boolean;
  onTabDelete?: (tab: TabSwitchOption<TabSwitchOptionLabelType>) => void;
  tabClassName?: string;
  buttonTabClassName?: string;
  removeTabClassName?: string;
  borderContainerClassName?: string;
  displayBorder?: boolean;
}

const TabSwitch = <TabSwitchOption,>({
  tabs,
  selectedTabId,
  onSelect,
  onTabDelete,
  allowTabDelete,
  className,
  tabClassName,
  buttonsTabIndex,
  buttonTabClassName,
  removeTabClassName,
  borderContainerClassName,
  displayBorder = true,
}: TabSwitchProps<TabSwitchOption>) => {
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.tabs}>
        {tabs.map((tab) => (
          <Fragment key={tab.id}>
            {(!tab.type || tab.type === 'default') && (
              <div className={clsx(styles.tab, tabClassName)}>
                <div className={clsx(styles.defaultTabContent, selectedTabId === tab.id && styles.checked)}>
                  <input
                    type="radio"
                    id={tab.id}
                    name="switch"
                    checked={selectedTabId === tab.id}
                    onChange={() => onSelect(tab)}
                  />
                  <label htmlFor={tab.id}>{tab.label}</label>
                  {allowTabDelete && !tab.disableDelete && (
                    <div className={clsx(styles.closeImage, removeTabClassName)} onClick={() => onTabDelete?.(tab)}>
                      <RemoveTabImage />
                    </div>
                  )}
                </div>
              </div>
            )}
            {tab.type === 'button' && (
              <div className={clsx(styles.tab, buttonTabClassName)} key={tab.id}>
                <label className={styles.addTabButton}>
                  <ButtonWithImage
                    ref={tab.ref}
                    title={(tab.label as unknown) as ReactElement | string}
                    kind="add"
                    onClick={tab.onClick}
                    tabIndex={buttonsTabIndex}
                  />
                </label>
              </div>
            )}
          </Fragment>
        ))}
      </div>
      {displayBorder && (
        <div className={clsx(styles.borderContainer, borderContainerClassName)}>
          <div className={styles.border} />
        </div>
      )}
    </div>
  );
};

export default TabSwitch;
