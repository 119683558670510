import { AnyAction } from 'redux';
import { EditExternalIntegrationVariablesPopUpState } from './Types';
import {
  OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
  CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP,
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES,
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS,
  ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE,
  OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP,
  CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP,
} from './ActionTypes';

const initialState: EditExternalIntegrationVariablesPopUpState = {
  inputList: [],
  outputList: [],
  numberVariables: [],
  booleanVariables: [],
  stringVariables: [],
  dateVariables: [],
  assignedOutput: '',
  isOpen: false,
  type: 'edit',
  isLoading: false,
  error: null,
};

const editExternalIntegrationVariablesPopUpReducer = (
  state = initialState,
  action: AnyAction,
): EditExternalIntegrationVariablesPopUpState => {
  switch (action.type) {
    case OPEN_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP:
      return { ...state, isOpen: true, type: 'edit' };
    case CLOSE_EDIT_EXTERNAL_INTEGRATION_VARIABLES_POP_UP:
      return { ...state, isOpen: false };
    case ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES:
      return { ...state, isLoading: true, error: null };
    case ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null,
        ...action.payload,
      };
    }
    case ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES_FAILURE: {
      const { message } = action.payload;
      return { ...state, isOpen: false, isLoading: false, error: message };
    }
    case OPEN_ASSIGN_OUTPUT_VARIABLE_POP_UP: {
      return {
        ...state,
        assignedOutput: action.payload,
        isOpen: true,
        type: 'assign',
      };
    }
    case CLOSE_ASSIGN_OUTPUT_VARIABLE_POP_UP: {
      return {
        ...state,
        assignedOutput: '',
        isOpen: false,
      };
    }

    default:
      return state;
  }
};

export default editExternalIntegrationVariablesPopUpReducer;
