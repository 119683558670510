import Fetcher from 'middlewares/Fetcher';
import {
  Intermediary,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { UpdateIntermediaryAction, updateIntermediaryFailure, updateIntermediarySuccess } from './ActionCreator';
import { LoanOriginationSystemEditIntermediaryTypes } from './ActionTypes';

const UpdateIntermediaryMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary, UpdateIntermediaryAction>(
    LoanOriginationSystemEditIntermediaryTypes.UpdateIntermediary,
    updateIntermediarySuccess,
    updateIntermediaryFailure,
    (action) => {
      return api.updateIntermediary(action.payload.id, action.payload.data);
    },
  );

export default UpdateIntermediaryMiddleware;
