import React, { FC } from 'react';
import ErrorPageInfo, { ErrorPageType } from 'components/ErrorPageInfo';
import styles from './NotFound.module.scss';

const NotFound: FC<{}> = () => {
  return (
    <ErrorPageInfo
      errorType={ErrorPageType.NotFound}
      errorTitle="Oops. The page was not found."
      className={styles.errorInfo}
    />
  );
};

export default NotFound;
