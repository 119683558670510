import React, { DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import { ClearIcon, SearchImage } from 'static/images';
import styles from './SearchInput.module.scss';
import Spinner from 'components/Spinner';

type InputProps = DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface SearchInputProps extends InputProps {
  containerClassName?: string;
  showLoader?: boolean;
  onClear: () => void;
}

const SPINNER_SIZE = 24;

const SearchInput = ({ containerClassName, showLoader, onClear, ...inputProps }: SearchInputProps) => (
  <div className={clsx(styles.search, containerClassName)}>
    <SearchImage className={styles.searchIcon} />
    <input {...inputProps} />
    {inputProps.value && !showLoader && (
      <div className={styles.clearIconContainer}>
        <ClearIcon className={styles.clearIcon} onClick={onClear} />
      </div>
    )}
    {showLoader && (
      <div className={styles.loader}>
        <Spinner size={SPINNER_SIZE} />
      </div>
    )}
  </div>
);

export default SearchInput;
