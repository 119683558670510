import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  Borrower,
  LoanOriginationSystemBorrowersApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemEditBorrowerActionType } from './ActionTypes';
import { UpdateBorrowerAction, updateBorrowerSuccess, updateBorrowerFailure } from './ActionCreator';

const UpdateBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower, UpdateBorrowerAction, ReduxState>(
    LoanOriginationSystemEditBorrowerActionType.UpdateBorrower,
    updateBorrowerSuccess,
    updateBorrowerFailure,
    async (action) => {
      return api.updateBorrower(action.payload.id, action.payload.data);
    },
  );

export default UpdateBorrowerMiddleware;
