export enum SendGridActionType {
  CloseDeleteTemplatePopUp = 'sendGridTemplates/CloseDeleteTemplatePopUp',
  CloseImportTemplatePopUp = 'sendGridTemplates/CloseImportTemplatePopUp',
  CloseVariablesMappingPopUp = 'sendGridTemplates/CloseVariablesMappingPopUp',
  DeleteTemplateRequest = 'sendGridTemplates/DeleteTemplateRequest',
  DeleteTemplateRequestSuccess = 'sendGridTemplates/DeleteTemplateRequestSuccess',
  DeleteTemplateRequestError = 'sendGridTemplates/DeleteTemplateRequestError',
  GetSenderEmailsRequest = 'sendGridTemplates/GetSenderEmailsRequest',
  GetSenderEmailsRequestError = 'sendGridTemplates/GetSenderEmailsRequestError',
  GetSenderEmailsRequestSuccess = 'sendGridTemplates/GetSenderEmailsRequestSuccess',
  GetSendGridIntegrationRequest = 'sendGridIntegration/GetSendGridIntegrationRequest',
  GetSendGridIntegrationRequestError = 'sendGridIntegration/GetSendGridIntegrationRequestError',
  GetSendGridIntegrationRequestSuccess = 'sendGridIntegration/GetSendGridIntegrationRequestSuccess',
  GetApplicationVariablesRequestSuccess = 'sendGridIntegration/GetApplicationVariablesRequestSuccess',
  GetApplicationVariablesRequestError = 'sendGridIntegration/GetApplicationVariablesRequestError',
  GetTemplatesRequest = 'sendGridTemplates/GetTemplatesRequest',
  GetTemplatesRequestError = 'sendGridTemplates/GetTemplatesRequestError',
  GetTemplatesRequestSuccess = 'sendGridTemplates/GetTemplatesRequestSuccess',
  GetTemplatesToImportRequest = 'sendGridTemplates/GetTemplatesToImportRequest',
  GetTemplatesToImportRequestError = 'sendGridTemplates/GetTemplatesToImportRequestError',
  GetTemplatesToImportRequestSuccess = 'sendGridTemplates/GetTemplatesToImportRequestSuccess',
  ImportTemplatesRequest = 'sendGridTemplates/ImportTemplatesRequest',
  ImportTemplatesRequestError = 'sendGridTemplates/ImportTemplatesRequestError',
  ImportTemplatesRequestSuccess = 'sendGridTemplates/ImportTemplatesRequestSuccess',
  OpenDeleteTemplatePopUp = 'sendGridTemplates/OpenDeleteTemplatePopUp',
  OpenImportTemplatePopUp = 'sendGridTemplates/OpenImportTemplatePopUp',
  OpenVariablesMappingPopUp = 'sendGridTemplates/OpenVariablesMappingPopUp',
  SaveSendGridIntegrationRequest = 'sendGridIntegartion/SaveSendGridIntegrationRequest',
  SaveSendGridIntegrationRequestError = 'sendGridIntegartion/GaveSendGridIntegrationRequestError',
  SaveSendGridIntegrationRequestSuccess = 'sendGridIntegartion/SaveSendGridIntegrationRequestSuccess',
  SetSendGridTemplatesSearchInput = 'sendGridTemplates/SetSendGridTemplatesSearchInput',
  SortTemplates = 'sendGridTemplates/SortTemplates',
  OpenCreateVariablePopup = 'sendGridTemplates/OpenCreateVariablePopup',
  CloseCreateVariablePopup = 'sendGridTemplates/CloseCreateVariablePopup',
  UpdateTemplateStatusRequest = 'sendGridTemplates/UpdateTemplateStatusRequest',
  UpdateTemplateStatusRequestError = 'sendGridTemplates/UpdateTemplateStatusRequestError',
  UpdateTemplateStatusRequestSuccess = 'sendGridTemplates/UpdateTemplateStatusRequestSuccess',
  UpdateTemplateSenderEmailRequest = 'sendGridTemplates/UpdateTemplateSenderEmailRequest',
  UpdateTemplateSenderEmailRequestError = 'sendGridTemplates/UpdateTemplateSenderEmailRequestError',
  UpdateTemplateSenderEmailRequestSuccess = 'sendGridTemplates/UpdateTemplateSenderEmailRequestSuccess',
  UpdateTemplateVariablesMappingRequest = 'sendGridTemplates/UpdateTemplateVariablesMappingRequest',
  UpdateTemplateVariablesMappingRequestError = 'sendGridTemplates/UpdateTemplateVariablesMappingRequestError',
  UpdateTemplateVariablesMappingRequestSuccess = 'sendGridTemplates/UpdateTemplateVariablesMappingRequestSuccess',
  FilterTemplatesByMembers = 'sendGridTemplates/FilterTemplatesByMembers',
  GetTemplate = 'sendGridTemplates/getTemplate',
  GetTemplateSuccess = 'sendGridTemplates/getTemplateSuccess',
  GetTemplateFailure = 'sendGridTemplates/getTemplateFailure',
  ResetTemplatesState = 'sendGridTemplates/resetTemplatesState',
}
