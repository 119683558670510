import React from 'react';
import SIZES from 'components/SendGrid/EditTemplateVariablesTable/TableColumnSizes';
import styles from './EditTemplateVariablesTableBody.module.scss';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import { Variable } from 'Variables/VariablesTypes';

interface EditTemplateTableBodyProps {
  name: string;
  variable: string;
  applicationVariables: Variable[];
}

const EditTemplateVariablesTableBody = ({ name, variable, applicationVariables }: EditTemplateTableBodyProps) => {
  const variableInfo = applicationVariables.find(({ systemName }) => systemName === variable);

  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.FIELD} className={styles.templateVariableKey}>{name}</TableBodyCell>
      <TableBodyCell width={SIZES.VARIABLE}>{variableInfo?.displayName || variable || ''}</TableBodyCell>
    </TableRow>
  );
};

export default EditTemplateVariablesTableBody;
