import React from 'react';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonHistoryRecord.module.scss';

const SkeletonHistoryRecord = () => (
  <div className={styles.skeletonRecord}>
    <div className={styles.icon}>
      <SkeletonCircle width="36px" height="36px" color="primary10" />
    </div>
    <div>
      <SkeletonRectangle width="207px" height="16px" color="primary10" marginBottom="7px" />
      <SkeletonRectangle width="469px" height="14px" color="primary10" />
    </div>
    <div className={styles.skeletonRecordInfo}>
      <SkeletonCircle width="20px" height="20px" color="primary10" />
      <SkeletonRectangle marginLeft="8px" width="170px" height="14px" color="primary10" />
    </div>
  </div>
);

export default SkeletonHistoryRecord;
