import {
  PermissionsUpdatingState,
  ProductSection,
  ProductSectionAccessPermissions,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import { ProductSectionAccessPermissionsAction } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsActions';
import ProductSectionAccessPermissionsActionTypes from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsActionTypes';
import { LoaderState } from 'components/LoaderWithState';

export interface ProductSectionAccessPermissionsState {
  permissions: ProductSectionAccessPermissions[] | null;
  permissionsUpdatingState: PermissionsUpdatingState;
  isResetToDefaultsPopUpOpen: boolean;
}

const initialState: ProductSectionAccessPermissionsState = {
  permissions: null,
  permissionsUpdatingState: {
    [UserRoleName.User]: {},
    [UserRoleName.Admin]: {},
    [UserRoleName.Owner]: {},
  },
  isResetToDefaultsPopUpOpen: false,
};

const setUpdatingState = (
  state: PermissionsUpdatingState,
  userRole: UserRoleName,
  productSection: ProductSection,
  value: LoaderState | null,
): PermissionsUpdatingState => {
  return {
    ...state,
    [userRole]: {
      ...state[userRole],
      [productSection]: value || undefined,
    },
  };
};

const productSectionAccessPermissionsReducer = (
  state: ProductSectionAccessPermissionsState = initialState,
  action: ProductSectionAccessPermissionsAction,
) => {
  switch (action.type) {
    case ProductSectionAccessPermissionsActionTypes.GetProductSectionAccessPermissionsSuccess: {
      const permissions = action.payload;

      return {
        ...state,
        permissions,
      };
    }
    case ProductSectionAccessPermissionsActionTypes.UpdateProductSectionAccessPermissionRequest: {
      const { userRole, productSection } = action.payload;

      return {
        ...state,
        permissionsUpdatingState: setUpdatingState(
          state.permissionsUpdatingState,
          userRole,
          productSection,
          LoaderState.Updating,
        ),
      };
    }
    case ProductSectionAccessPermissionsActionTypes.UpdateProductSectionAccessPermissionSuccess: {
      const { productSection, ...updatedPermissions } = action.payload;
      // It's not possible to update permissions before they are loaded
      const otherPermissions = state.permissions!.filter(
        (permissions) => permissions.userRole !== updatedPermissions.userRole,
      );
      return {
        ...state,
        permissions: [...otherPermissions, updatedPermissions],
        permissionsUpdatingState: setUpdatingState(
          state.permissionsUpdatingState,
          updatedPermissions.userRole,
          productSection,
          LoaderState.Success,
        ),
      };
    }
    case ProductSectionAccessPermissionsActionTypes.UpdateProductSectionAccessPermissionFailure: {
      const { userRole, productSection } = action.payload;
      return {
        ...state,
        permissionsUpdatingState: setUpdatingState(
          state.permissionsUpdatingState,
          userRole,
          productSection,
          LoaderState.Failure,
        ),
      };
    }
    case ProductSectionAccessPermissionsActionTypes.ResetProductSectionAccessPermissionUpdatingState: {
      const { userRole, productSection } = action.payload;

      return {
        ...state,
        permissionsUpdatingState: setUpdatingState(state.permissionsUpdatingState, userRole, productSection, null),
      };
    }
    case ProductSectionAccessPermissionsActionTypes.OpenResetToDefaultsPopUp: {
      return {
        ...state,
        isResetToDefaultsPopUpOpen: true,
      };
    }
    case ProductSectionAccessPermissionsActionTypes.CloseResetToDefaultsPopUp: {
      return {
        ...state,
        isResetToDefaultsPopUpOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default productSectionAccessPermissionsReducer;
