import { ItemsStateWithPagination } from 'pagination';
import { VersionsListDataActions } from 'VersionsViewData/ActionTypes';
import versionsPagination, { PER_PAGE_DEFAULT } from 'components/VersionsView/Pagination';
import { VersionsTableSortingField, VersionsTableViewSortingType } from 'VersionsViewData/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  ClearListDataAction,
  GetVersionsListDataAction,
  GetVersionsListDataFailureAction,
  GetVersionsListDataSuccessAction,
  SetShowArchivedAction,
  SetSortingTypeAction,
  SetUpdatersAction,
  SetVersionsListSearchInputAction,
  ResetFiltersAction,
} from 'VersionsViewData/Actions';
import { StrategyGroupVersion } from 'api/Types';

export interface VersionsListDataState {
  items: StrategyGroupVersion[];
  page: number;
  itemsTotal?: number;
  itemsPerPage: number;
  updaters: UserInfo[];
  selectedUpdaters: UserInfo[];
  searchInputValue: string;
  showArchived: boolean;
  searched: {
    [searchInputValue: string]: ItemsStateWithPagination<StrategyGroupVersion> & {
      selectedUpdaters: UserInfo[];
      updaters: UserInfo[];
    };
  };
  strategyName: string;
  sortingType: VersionsTableViewSortingType;
}

const initialState: VersionsListDataState = {
  items: [],
  page: 1,
  itemsTotal: undefined,
  itemsPerPage: PER_PAGE_DEFAULT,
  updaters: [],
  selectedUpdaters: [],
  searchInputValue: '',
  searched: {},
  showArchived: false,
  strategyName: '',
  sortingType: {
    field: VersionsTableSortingField.Version,
    ascending: false,
  },
};

type HandledAction =
  | GetVersionsListDataAction
  | GetVersionsListDataSuccessAction
  | GetVersionsListDataFailureAction
  | SetSortingTypeAction
  | SetVersionsListSearchInputAction
  | SetShowArchivedAction
  | ClearListDataAction
  | SetUpdatersAction
  | ResetFiltersAction;

const VersionsListDataReducer = (state = initialState, action: HandledAction): VersionsListDataState => {
  switch (action.type) {
    case VersionsListDataActions.GetVersionsListData: {
      return {
        ...initialState,
        strategyName: state.strategyName,
        showArchived: state.showArchived,
        searchInputValue: state.searchInputValue,
        sortingType: state.sortingType,
        updaters: state.updaters,
        ...(action.payload || {}),
      };
    }
    case VersionsListDataActions.GetVersionsListSuccess: {
      const { perPage, total, searchInputValue, items, users, selectedUpdaters } = action.payload;

      if (searchInputValue) {
        const { searched } = state;
        return {
          ...state,
          searched: {
            ...searched,
            [searchInputValue]: {
              items,
              itemsTotal: total,
              page: 1,
              itemsPerPage: PER_PAGE_DEFAULT,
              selectedUpdaters,
              updaters: users,
              error: null,
            },
          },
        };
      }
      return {
        ...state,
        itemsTotal: total,
        itemsPerPage: perPage,
        items,
        updaters: users,
        selectedUpdaters,
      };
    }
    case VersionsListDataActions.GetVersionsListDataFailure: {
      return initialState;
    }
    case VersionsListDataActions.SetSortingType: {
      return { ...state, sortingType: action.payload };
    }
    case VersionsListDataActions.SetVersionsListDataSearchInput: {
      return {
        ...state,
        searchInputValue: action.payload,
        page: 1,
      };
    }
    case VersionsListDataActions.SetShowArchived: {
      return {
        ...state,
        showArchived: action.payload,
        page: 1,
      };
    }
    case VersionsListDataActions.ClearListData: {
      return initialState;
    }
    case VersionsListDataActions.SetUpdaters: {
      return { ...state, selectedUpdaters: action.payload, page: 1 };
    }
    case VersionsListDataActions.ResetFilters: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default versionsPagination.wrapReducer<VersionsListDataState>(VersionsListDataReducer);
