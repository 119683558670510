import React, { FocusEvent } from 'react';
import clsx from 'clsx';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/Types';
import InputWithDataType from 'components/InputWithDataType';
import { getVisualDataTypeWithAttributes } from 'Variables/utils';
import useFormLayout from './useFormLayout';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import { convertVariableInput, convertVariableValueToString } from 'components/ConfigurableForm/utils';
import { Variable, VariableAccessPermissionType } from 'Variables/VariablesTypes';
import styles from './ConfigurableForm.module.scss';
import MonetaryInput from 'components/MonetaryInput';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import variableOptionsListToSelectInputOptions from 'utils/variableOptionsListToSelectInputOptions';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { isNumber } from 'lodash';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import useUserRole from 'MyAccount/useUserRole';
import { isPhoneNumber } from 'components/InputWithDataType/InputWithDataType';

const getInputModeFromVariableConfiguration = (variable: Variable, role: UserRoleName) => {
  switch (variable.userAccessPermissions[role]) {
    case VariableAccessPermissionType.ReadOnly: {
      return {
        readOnly: true,
      };
    }
    case VariableAccessPermissionType.NoAccess:
    case VariableAccessPermissionType.PartialRead:
      return {
        disabled: true,
      };
    default: {
      return {};
    }
  }
};

interface ConfigurableFormProps {
  configurations: VariableConfiguration[];
  data: FormLayoutData;
  columns?: number;
  columnClassName?: string;
  inputContainerClassname?: string;
  onFieldChange: (variableConfiguration: VariableConfiguration, value: VariableValue) => void;
  onFieldFocus?: (variableConfiguration: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => void;
  onFieldBlur?: (variableConfiguration: VariableConfiguration, event: FocusEvent<HTMLInputElement>) => void;
  showLoader?: boolean;
  loaderStateById?: Record<string, LoaderState | undefined | null>;
  onLoaderStateReset?: (variableConfiguration: VariableConfiguration) => void;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  hideCustomFields?: boolean;
}

const DEFAULT_COLUMNS_LENGTH = 2;

const ConfigurableForm = ({
  configurations,
  columns = DEFAULT_COLUMNS_LENGTH,
  onFieldChange,
  onFieldFocus,
  onFieldBlur,
  data,
  columnClassName,
  inputContainerClassname,
  formatDisplayTitle,
  hideCustomFields,
  showLoader,
  onLoaderStateReset,
  loaderStateById,
}: ConfigurableFormProps) => {
  const userRole = useUserRole();
  const layout = useFormLayout(configurations, columns);
  if (!layout || !userRole) {
    return null;
  }

  const inputChangeHandler = (variableConfiguration: VariableConfiguration) => (valueInput: string) => {
    const value = convertVariableInput(valueInput, variableConfiguration.variable.dataType);

    onFieldChange(variableConfiguration, value);
  };

  const renderLayoutItem = (variableConfiguration: VariableConfiguration, index: number) => {
    if (!variableConfiguration || (!variableConfiguration.default && hideCustomFields)) {
      return null;
    }

    const { variable, id, required } = variableConfiguration;
    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      onFieldFocus?.(variableConfiguration, event);
    };
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      onFieldBlur?.(variableConfiguration, event);
    };

    const value = data[variable.systemName];
    const valueAsString = convertVariableValueToString(value);
    const displayValue = variable.currency && isNumber(value) ? formatMonetaryValue(value) : valueAsString;

    const visualDataTypeWithAttributes = getVisualDataTypeWithAttributes(variable);
    const baseOptions = {
      labelTitle: formatDisplayTitle
        ? formatDisplayTitle(variable.systemName, variable.displayName)
        : variable.displayName,
      onChange: inputChangeHandler(variableConfiguration),
      onFocus: handleFocus,
      onBlur: handleBlur,
      showLoader,
      loaderState: loaderStateById && loaderStateById[variableConfiguration.id],
      onLoaderStateReset: () => onLoaderStateReset?.(variableConfiguration),
      ...visualDataTypeWithAttributes,
      ...getInputModeFromVariableConfiguration(variable, userRole),
      tabIndex: index + 1,
      required: !!required,
      disableResetValueOnError: true,
    };
    const currencySymbol = variable.currency ? getCurrencySymbol(variable.currency!) : null;

    return (
      <div key={id} className={clsx(styles.inputContainer, inputContainerClassname)}>
        {variable.currency ? (
          <MonetaryInput value={valueAsString} {...baseOptions} currencySymbol={currencySymbol!} />
        ) : (
          <InputWithDataType
            value={displayValue}
            {...baseOptions}
            options={variable.optionsList ? variableOptionsListToSelectInputOptions(variable.optionsList) : null}
            {...(isPhoneNumber(visualDataTypeWithAttributes) ? { phoneNumberWithFlag: true } : {})}
          />
        )}
      </div>
    );
  };

  const renderLayoutItems = (layoutItems: Array<VariableConfiguration>, index: number) => (
    <div key={index} className={columnClassName}>
      {layoutItems.map(renderLayoutItem)}
    </div>
  );

  const configurationFormStyle = { gridTemplateColumns: `repeat(${layout!.length || columns}, minmax(0, 1fr))` };
  return (
    <div style={configurationFormStyle} className={styles.configurableFormContainer}>
      {layout.map(renderLayoutItems)}
    </div>
  );
};

export default ConfigurableForm;
