import React, { FC, ReactNode } from 'react';
import InputWithDataTypeLabel from 'components/InputWithDataTypeLabel';
import TransitionComponent from 'components/HeightTransitionComponent';
import Comparison from 'components/ComparisonOperatorPicker';
import ComparisonOperands from 'components/ComparisonOperands';
import styles from './RuleBuilderCore.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import { ComparisonOperator, ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';
import getShowingDataType from 'utils/ruleBuilder/getShowingDataType';
import { useVariableAttributes } from 'hooks/useVariableDataType';
import getDataTypeAttributes from 'utils/ruleBuilder/getDataTypeAttributes';

export interface RuleBuilderCoreProps {
  sourceVariableId: string | undefined;
  setSourceVariableId: (id: string) => void;
  selectedComparison: ComparisonOperator | undefined;
  setSelectedComparison: (comparison: ComparisonOperator) => void;
  firstOperandValue: string;
  setFirstOperandValue: (value: string) => void;
  firstOperandVariableId: string | undefined;
  setFirstOperandVariableId: (id: string) => void;
  secondOperandValue: string;
  setSecondOperandValue: (value: string) => void;
  secondOperandVariableId: string | undefined;
  setSecondOperandVariableId: (id: string) => void;
  firstOperandType: ComparisonOperandType;
  setFirstOperandType: (type: ComparisonOperandType) => void;
  secondOperandType: ComparisonOperandType;
  setSecondOperandType: (type: ComparisonOperandType) => void;
  link: ReactNode;
}

const RuleBuilderCore: FC<RuleBuilderCoreProps> = ({
  sourceVariableId,
  setSourceVariableId,
  selectedComparison,
  setSelectedComparison,
  firstOperandValue,
  setFirstOperandValue,
  firstOperandVariableId,
  setFirstOperandVariableId,
  secondOperandValue,
  setSecondOperandValue,
  secondOperandVariableId,
  setSecondOperandVariableId,
  firstOperandType,
  setFirstOperandType,
  secondOperandType,
  setSecondOperandType,
  link,
}) => {
  const sourceVariable = useVariableAttributes(sourceVariableId!);
  const dataTypeWithAttributes = getDataTypeAttributes(sourceVariable);

  return (
    <>
      <div className={styles.variableDropdown}>
        <InputWithDataTypeLabel
          labelTitle="Variable"
          value={sourceVariableId}
          onChange={({ value }: Option) => setSourceVariableId(value)}
          dataType={getShowingDataType(dataTypeWithAttributes)}
          link={link}
        />
      </div>
      <TransitionComponent>
        <Comparison
          comparison={selectedComparison}
          onComparisonChange={setSelectedComparison}
          dataType={dataTypeWithAttributes.dataType}
        />
      </TransitionComponent>
      <TransitionComponent>
        {selectedComparison && (
          <ComparisonOperands
            {...{
              ...(dataTypeWithAttributes as VisualDataTypeWithAttributes),
              firstOperandValue,
              firstOperandVariableId,
              firstOperandType,
              secondOperandValue,
              secondOperandVariableId,
              secondOperandType,
            }}
            onFirstOperandValueChange={setFirstOperandValue}
            onFirstOperandVariableIdChange={setFirstOperandVariableId}
            onFirstOperandTypeChange={setFirstOperandType}
            onSecondOperandValueChange={setSecondOperandValue}
            onSecondOperandVariableIdChange={setSecondOperandVariableId}
            onSecondOperandTypeChange={setSecondOperandType}
            comparison={selectedComparison}
          />
        )}
      </TransitionComponent>
    </>
  );
};

export default RuleBuilderCore;
