import Debouncer from 'middlewares/Debouncer';
import { GetTemplatesRequest } from 'SendGrid/ActionCreator';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export default Debouncer<UserInfo[]>(
  (payload, getState) => {
    const { sendGrid } = getState();
    const { filters, sortingType } = sendGrid;

    return GetTemplatesRequest({ filters, sortingType });
  },
  [LoanOriginationSystemTemplatesFiltersActionTypes.FilterTemplatesByMembers],
);
