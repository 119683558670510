import { BillingApi, Invoice } from 'api/BillingApi';
import Fetcher from 'middlewares/Fetcher';
import { GetInvoicesAction, getInvoicesFailed, getInvoicesSuccess } from './ActionCreator';
import BillingPageActionType from './ActionTypes';

const GetInvoicesMiddleware = (api: BillingApi) =>
  Fetcher<Invoice[], GetInvoicesAction>(
    BillingPageActionType.GetInvoices,
    getInvoicesSuccess,
    getInvoicesFailed,
    (action) => {
      return api.getInvoices(action.payload.sortingType);
    },
  );

export default GetInvoicesMiddleware;
