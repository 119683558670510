import React, { FC, useEffect, useState } from 'react';
import AutoCompletion from 'components/AutoCompletion';
import { StrategiesListItem, IndividualRunInputs } from 'api/Types';
import InputWithDataType from 'components/InputWithDataType';
import styles from 'components/ApplicationDecisionRunForm/ApplicationDecisionRunForm.module.scss';
import Button from 'components/Button';
import { Option } from 'components/SelectInput/SelectInput';
import { ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';
import { Tag } from 'components/Tag';
import { statusColors } from 'pages/IndividualProcessing/IndividualProcessing';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import formatValueByDataType from 'utils/formatValueByDataType';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import { CrossIcon } from 'static/images';
import clsx from 'clsx';
import { has } from 'lodash';
import { InputValues } from 'components/RunDecisionForm/RunDecisionForm';

interface RunDecisionFormProps {
  strategies: StrategiesListItem[];
  strategyInputs: ProcessingStrategyInput[] | null;
  applicationProductInputs: string[];
  onRunDecision: (inputs: IndividualRunInputs) => void;
  onStrategyChange: (strategyId: string) => void;
  currentStrategy?: string;
  inputValues: IndividualRunInputs;
  inputContainerClassName?: string;
  dataLoading?: boolean;
  isLoading?: boolean;
}

const TABLE_CELL_WIDTH = 300;

const ApplicationDecisionRunForm: FC<RunDecisionFormProps> = ({
  currentStrategy = '',
  strategies,
  strategyInputs,
  inputValues,
  applicationProductInputs,
  onRunDecision,
  onStrategyChange,
  inputContainerClassName,
  dataLoading,
  isLoading,
}) => {
  const [caseName, setCaseName] = useState<string>('');
  const [inputs, setInputs] = useState<InputValues | null>(null);
  const [requiredInputs, setRequiredInputs] = useState<string[]>([]);

  useEffect(() => {
    if (currentStrategy && strategyInputs) {
      const missingInputs: string[] = [];
      const inputsToSet: Record<string, string> = strategyInputs.reduce(
        (resultInputs, { systemName }) => {
          const inputExists = has(inputValues, systemName) || applicationProductInputs.includes(systemName);
          if (!inputExists) {
            missingInputs.push(systemName);
          }

          const inputValue = inputValues[systemName];
          const value = isEmptyVariableValue(inputValue) ? '' : inputValue;

          return {
            ...resultInputs,
            [systemName]: value.toString(),
          };
        },
        { case_name: '' },
      );

      setInputs(inputsToSet);
      setRequiredInputs(missingInputs);
    } else {
      setRequiredInputs([]);
    }
    setCaseName('');
  }, [strategyInputs, currentStrategy, applicationProductInputs]);

  const handleRunDecision = () => {
    if (!currentStrategy || !strategyInputs) {
      return;
    }
    onRunDecision(inputs!);
  };

  const getInputDisplayEmptyValue = (inputName: string) => (requiredInputs.includes(inputName) ? 'undefined' : 'null');
  const renderInput = (input: ProcessingStrategyInput) => {
    const isRequired = requiredInputs.includes(input.systemName);
    const inputValue = isRequired ? 'undefined' : inputs?.[input.systemName];

    return (
      <TableRow disableHover key={input.displayName} className={clsx(isRequired && styles.rowWithError)}>
        <TableBodyCell width={TABLE_CELL_WIDTH}>{input.displayName}</TableBodyCell>
        <TableBodyCell width={TABLE_CELL_WIDTH} className={styles.iconContainer}>
          {isEmptyVariableValue(inputValue)
            ? getInputDisplayEmptyValue(input.systemName)
            : formatValueByDataType(inputValue, input)}
          {isRequired && <CrossIcon className={styles.alertIcon} />}
        </TableBodyCell>
      </TableRow>
    );
  };

  const renderInputs = () => {
    if (!strategyInputs || !strategyInputs.length) {
      return null;
    }

    return (
      <>
        <div className={inputContainerClassName || styles.input}>
          <InputWithDataType
            dataType="Text"
            labelTitle="Decision Name"
            value={caseName}
            options={null}
            onChange={(value: string) => setCaseName(value)}
          />
        </div>

        <Table className={styles.table}>
          <TableHead>
            <TableHeadCell width={TABLE_CELL_WIDTH}>Variable name</TableHeadCell>
            <TableHeadCell width={TABLE_CELL_WIDTH}>Value</TableHeadCell>
          </TableHead>
          <TableBody>{strategyInputs.map(renderInput)}</TableBody>
        </Table>
      </>
    );
  };

  const getStrategyOptions = (): Option[] => {
    return strategies.map(({ id, name, status }: StrategiesListItem) => {
      const normalizedStatus = getNormalizedStatus(status);
      return {
        name,
        value: id,
        iconAfter: <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
      };
    });
  };

  const renderRequiredInputs = () => (
    <div className={styles.bottomError}>
      This strategy cannot be run for this application because it is missing the following required variables:{' '}
      {requiredInputs.join(', ')}
    </div>
  );

  return (
    <>
      <div className={styles.inputContainer}>
        <div className={inputContainerClassName || styles.input}>
          <AutoCompletion
            labelTitle="Strategy Name"
            onChange={({ value }: Option) => onStrategyChange(value || '')}
            value={currentStrategy}
            options={getStrategyOptions()}
            loading={dataLoading}
          />
        </div>
        {!dataLoading && renderInputs()}
      </div>
      <div className={styles.buttonContainer}>
        <Button
          size="form"
          disabled={!currentStrategy || !strategyInputs || !!requiredInputs.length || dataLoading}
          isLoading={isLoading}
          onClick={handleRunDecision}
        >
          Run Decision
        </Button>
      </div>
      {!!requiredInputs.length && !dataLoading && renderRequiredInputs()}
    </>
  );
};

export default ApplicationDecisionRunForm;
