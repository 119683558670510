import { FailedAction } from 'utils/actions/FailedActionCreator';
import { CreateBorrowerAction, CreateBorrowerSuccessAction } from './ActionCreator';
import { LoanOriginationSystemCreateBorrowerTypes } from './ActionTypes';
import { LoanOriginationSystemCreateBorrowerState } from './Types';

export const initialState: LoanOriginationSystemCreateBorrowerState = {
  isCreating: false,
};

type LoanOriginationSystemCreateBorrowerActions =
  | CreateBorrowerAction
  | CreateBorrowerSuccessAction
  | FailedAction<LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerFailure>;

export const loanOriginationSystemCreateBorrowerReducer = (
  state: LoanOriginationSystemCreateBorrowerState = initialState,
  action: LoanOriginationSystemCreateBorrowerActions,
): LoanOriginationSystemCreateBorrowerState => {
  switch (action.type) {
    case LoanOriginationSystemCreateBorrowerTypes.CreateBorrower: {
      return {
        ...state,
        isCreating: true,
      };
    }
    case LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerSuccess: {
      return {
        ...state,
        isCreating: false,
      };
    }
    case LoanOriginationSystemCreateBorrowerTypes.CreateBorrowerFailure: {
      return {
        ...state,
        isCreating: false,
      };
    }
    default: {
      return state;
    }
  }
};
