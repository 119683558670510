import CoreSystemApi from 'api/Core/CoreSystemApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import {
  ProductSectionAccessPermissions,
  ProductSectionAccessPermissionsMap,
} from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';

export interface IPermissionApi {
  getAll(): Promise<ProductSectionAccessPermissions[]>;
  update(
    userRole: UserRoleName,
    updateData: ProductSectionAccessPermissionsMap,
  ): Promise<ProductSectionAccessPermissions>;
  resetToDefault(): Promise<void>;
}

export default class PermissionApi extends CoreSystemApi<ProductSectionAccessPermissions> implements IPermissionApi {
  protected resourceName = 'permissions';

  public async getAll() {
    return this.getResources();
  }

  public async update(userRole: UserRoleName, updateData: ProductSectionAccessPermissionsMap) {
    return this.fetch<ProductSectionAccessPermissions>(`/${this.resourceName}/${userRole}`, 'PATCH', updateData);
  }

  public async resetToDefault() {
    return this.fetch<void>(`/${this.resourceName}`, 'DELETE');
  }
}
