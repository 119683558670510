import React, { useState } from 'react';
import styles from './VariablesFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'Variables/Filters/ActionCreator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import AutoCompletion from 'components/AutoCompletion';
import { VARIABLE_DATA_TYPES } from 'components/VariableForm/DataTypes';
import { Option } from 'components/SelectInput/SelectInput';
import { VisualDataType } from 'Variables/VariablesTypes';

interface VariablesFilterProps {
  dueCreatedDateFrom: Date | null;
  dueCreatedDateTo: Date | null;
  dueUpdatedDateFrom: Date | null;
  dueUpdatedDateTo: Date | null;
  variableDataType: VisualDataType | '';
  type: 'custom' | 'standard' | '';
}

const DATA_TYPES = VARIABLE_DATA_TYPES.map(({ name, value }) => ({
  name,
  value,
}));

const VARIABLE_TYPES = [
  {
    name: 'Custom',
    value: 'custom',
    color: styles.customVariableType,
  },
  {
    name: 'Standard',
    value: 'standard',
    color: styles.standardVariableType,
  },
];

const VariablesFilter = ({
  dueCreatedDateFrom,
  dueCreatedDateTo,
  dueUpdatedDateFrom,
  dueUpdatedDateTo,
  variableDataType,
  type,
}: VariablesFilterProps) => {
  const dispatch = useDispatch();

  const [fromCreatedDate, setFromCreatedDate] = useState(dueCreatedDateFrom);
  const [toCreatedDate, setToCreatedDate] = useState(dueCreatedDateTo);
  const [fromUpdatedDate, setFromUpdatedDate] = useState(dueUpdatedDateFrom);
  const [toUpdatedDate, setToUpdatedDate] = useState(dueUpdatedDateTo);
  const [dataType, setDataType] = useState<VisualDataType | ''>(variableDataType);
  const [variableType, setVariableType] = useState<'standard' | 'custom' | ''>(type);

  const filtersResult: FiltersResult = {
    dueCreatedDateRange: {
      from: fromCreatedDate,
      to: toCreatedDate,
    },
    dueUpdatedDateRange: {
      from: fromUpdatedDate,
      to: toUpdatedDate,
    },
    dataType,
    onlyStandard: variableType === 'standard',
    onlyCustom: variableType === 'custom',
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnCreatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromCreatedDate(from);
    setToCreatedDate(to);
  };

  const handleOnUpdatedDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromUpdatedDate(from);
    setToUpdatedDate(to);
  };

  const handleDataTypeChange = ({ value }: Option) => {
    setDataType(value as VisualDataType);
  };

  const handleVariableTypeChange = ({ value }: Option) => {
    setVariableType(value as 'custom' | 'standard' | '');
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Data Type"
        options={DATA_TYPES}
        onChange={handleDataTypeChange}
        value={dataType}
        className={styles.dataTypeInput}
      />
      <AutoCompletion
        labelTitle="Variable Type"
        options={VARIABLE_TYPES}
        onChange={handleVariableTypeChange}
        value={variableType}
        isLabelTag
      />
      <DateRangePicker
        from={fromCreatedDate}
        to={toCreatedDate}
        onChange={handleOnCreatedDateRangeChange}
        label="Created"
      />
      <DateRangePicker
        from={fromUpdatedDate}
        to={toUpdatedDate}
        onChange={handleOnUpdatedDateRangeChange}
        label="Updated"
      />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default VariablesFilter;
