/* eslint-disable import/no-cycle */
import {
  COPY_BRANCH_RULES,
  COPY_BRANCH_RULES_FAILURE,
  COPY_BRANCH_RULES_SUCCESS,
  GET_BRANCH_OPTIONS,
  GET_BRANCH_OPTIONS_FAILURE,
  GET_BRANCH_OPTIONS_SUCCESS,
} from './ActionTypes';
import { BranchIdentityParams, BranchItemInfo, CopyBranchRulesParams, GetBranchOptionsParams } from 'api/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface GetBranchOptionsAction {
  type: typeof GET_BRANCH_OPTIONS;
  payload: GetBranchOptionsParams;
}

export const getBranchOptions = (payload: GetBranchOptionsParams): GetBranchOptionsAction => ({
  type: GET_BRANCH_OPTIONS,
  payload,
});

export interface GetBranchOptionsSuccessAction {
  type: typeof GET_BRANCH_OPTIONS_SUCCESS;
  payload: BranchItemInfo[];
}

export const getBranchOptionsSuccess = (options: BranchItemInfo[]): GetBranchOptionsSuccessAction => ({
  type: GET_BRANCH_OPTIONS_SUCCESS,
  payload: options,
});

export const getBranchOptionsFailure = FailedActionCreator(GET_BRANCH_OPTIONS_FAILURE);

export interface CopyBranchRulesAction {
  type: typeof COPY_BRANCH_RULES;
  payload: CopyBranchRulesParams;
}

export const copyBranchRuleset = (copyBranchRulesParams: CopyBranchRulesParams): CopyBranchRulesAction => ({
  type: COPY_BRANCH_RULES,
  payload: copyBranchRulesParams,
});

export interface CopyBranchRulesSuccessAction {
  type: typeof COPY_BRANCH_RULES_SUCCESS;
  payload: BranchIdentityParams;
}

export const copyBranchRulesSuccess = (payload: BranchIdentityParams): CopyBranchRulesSuccessAction => ({
  type: COPY_BRANCH_RULES_SUCCESS,
  payload,
});

export const copyBranchRulesFailure = FailedActionCreator(COPY_BRANCH_RULES_FAILURE);
