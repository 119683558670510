import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemProductConfigurationState } from './Types';
import { LoanOriginationSystemProductsActionType } from 'LoanOriginationSystemProducts/ActionTypes';
import {
  ChangeProductStatusSuccessAction,
  GetProductActionOrigin,
  GetProductSuccessAction,
  UpdateProductAction,
  UpdateProductActionOrigin,
  UpdateProductSuccessAction,
} from 'LoanOriginationSystemProducts/ActionCreator';
import { ResetProductConfigurationAction, SelectProductForConfigurationAction } from './ActionCreator';
import { LoanOriginationSystemProductConfigurationType } from './ActionTypes';
import loanOriginationSystemProductApplicationSetupReducer, {
  initialState as applicationSetupInitialState,
} from './ApplicationSetup/Reducer';
import applicationsWorkflowSetupReducer, {
  initialState as applicationsWorkflowSetupInitialState,
} from './ApplicationsWorkflowSetup/Reducer';
import applicationFormConfigurationReducer, {
  initialState as applicationFormConfigurationInitialState,
} from './ApplicationFormConfiguration/Reducer';
import mountChildReducers from 'utils/childReducers';

export const initialState: LoanOriginationSystemProductConfigurationState = {
  product: null,
  applicationWorkflowSetup: applicationsWorkflowSetupInitialState,
  applicationSetup: applicationSetupInitialState,
  applicationFormConfiguration: applicationFormConfigurationInitialState,
  isProductUpdateInProgress: false,
};

type LoanOriginationSystemProductDetailsActions =
  | ChangeProductStatusSuccessAction
  | SelectProductForConfigurationAction
  | ResetProductConfigurationAction
  | GetProductSuccessAction
  | UpdateProductSuccessAction
  | UpdateProductAction
  | FailedAction<LoanOriginationSystemProductsActionType.UpdateProductFailure, UpdateProductActionOrigin>;

const loanOriginationSystemProductConfigurationReducer = (
  state = initialState,
  action: LoanOriginationSystemProductDetailsActions,
) => {
  switch (action.type) {
    case LoanOriginationSystemProductConfigurationType.ResetProductConfiguration: {
      return initialState;
    }
    case LoanOriginationSystemProductConfigurationType.SelectProduct: {
      return {
        ...state,
        product: action.payload.product,
      };
    }
    case LoanOriginationSystemProductsActionType.UpdateProduct: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          applicationFormConfiguration: applicationFormConfigurationReducer(state.applicationFormConfiguration, action),
        };
      }

      if (action.meta?.actionOrigin !== UpdateProductActionOrigin.ProductConfigurationName) {
        return state;
      }

      return {
        ...state,
        isProductUpdateInProgress: true,
      };
    }
    case LoanOriginationSystemProductsActionType.UpdateProductSuccess: {
      if (
        action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings ||
        action.meta?.actionOrigin === UpdateProductActionOrigin.DeleteConfigurableApplicationFormPage
      ) {
        return {
          ...state,
          applicationFormConfiguration: applicationFormConfigurationReducer(state.applicationFormConfiguration, action),
          product:
            state.product && action.payload.product.id === state.product.id ? action.payload.product : state.product,
        };
      }

      if (
        action.meta?.actionOrigin === UpdateProductActionOrigin.ConfigurationDocumentsTab ||
        action.meta?.actionOrigin === UpdateProductActionOrigin.AddConfigurableApplicationFormPage
      ) {
        return {
          ...state,
          product:
            state.product && action.payload.product.id === state.product.id ? action.payload.product : state.product,
        };
      }

      if (action.meta?.actionOrigin === UpdateProductActionOrigin.ProductConfigurationName) {
        return {
          ...state,
          isProductUpdateInProgress: false,
          product:
            state.product && action.payload.product.id === state.product.id ? action.payload.product : state.product,
        };
      }

      return state;
    }
    case LoanOriginationSystemProductsActionType.UpdateProductFailure: {
      if (action.meta?.actionOrigin === UpdateProductActionOrigin.FormSettings) {
        return {
          ...state,
          applicationFormConfiguration: applicationFormConfigurationReducer(state.applicationFormConfiguration, action),
        };
      }

      return {
        ...state,
        isProductUpdateInProgress: false,
      };
    }
    case LoanOriginationSystemProductsActionType.GetProductSuccess: {
      if (action.meta?.actionOrigin !== GetProductActionOrigin.ProductConfiguration) {
        return state;
      }

      return {
        ...state,
        product: action.payload.product,
      };
    }
    case LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess: {
      return {
        ...state,
        product: state.product?.id === action.payload.id ? action.payload : state.product,
      };
    }
    default: {
      return state;
    }
  }
};

const childReducers = {
  applicationSetup: loanOriginationSystemProductApplicationSetupReducer,
  applicationWorkflowSetup: applicationsWorkflowSetupReducer,
  applicationFormConfiguration: applicationFormConfigurationReducer,
};

export default mountChildReducers(loanOriginationSystemProductConfigurationReducer, childReducers);
