import { AnyAction, MiddlewareAPI } from 'redux';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { ChangeProductStatusSuccessAction } from './ActionCreator';

const isChangeProductStatusSuccessAction = (action: AnyAction): action is ChangeProductStatusSuccessAction =>
  action.type === LoanOriginationSystemProductsActionType.ChangeProductStatusSuccess;

const notifyOnProductStatusChangeSuccessMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isChangeProductStatusSuccessAction(action)) {
    const messageType =
      action.payload.status === ProductStatus.Archived ? MessageType.ProductArchived : MessageType.ProductStatusChanged;

    notification.createNotification(getMessage(messageType, { productName: action.payload.name }), 'success', dispatch);
  }

  return result;
};

export default notifyOnProductStatusChangeSuccessMiddleware;
