import React, { ChangeEvent, cloneElement, ReactElement, ReactNode, useRef, useState, useEffect } from 'react';
import TextInput from 'components/TextInput';
import AutoCompletion from 'components/AutoCompletion';
import OptionsInput from 'components/OptionsInput';
import {
  DATE_FORMATS,
  IDENTIFICATION_NUMBER_TYPES,
  NUMBER_DATA_TYPES,
  TEXT_DATA_TYPES,
  VARIABLE_DATA_TYPES,
} from './DataTypes';
import {
  Variable,
  VariableAccessPermissionType,
  VariableClientType,
  VariableUserAccessPermissions,
  VisualDataType,
} from 'Variables/VariablesTypes';
import { DATA_TYPES_PARTIAL_READ_SUPPORTED, getVariableDataTypeName, getVisualDataType } from 'Variables/utils';
import { isEqual, startCase } from 'lodash';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import FormRow from 'components/FormRow/FormRow';
import { ValueValidationType } from 'components/Input';
import { Option } from 'components/SelectInput/SelectInput';
import CountryCodeSelect from 'components/CountryCodeSelect/CountryCodeSelect';
import { formattedCurrencies } from 'components/CurrencySelect/currencies';
import { SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import getMessage, { MessageType } from 'constants/messages';
import { RegionSettings } from 'CompanyInformation/CompanyInformationTypes';
import trimAll from 'utils/trimAll';
import VariableTooltipWrapper from './VariableTooltipWrapper';

export interface VariableFormProps {
  onSave: (variableData: VariableClientType) => void;
  children: ReactElement<{ disabled?: boolean; onSave?: () => void }>;
  variable?: Variable;
  topRightElement?: ReactNode;
  regionSettings?: RegionSettings;
}

const EMPTY_SPACE_REGEXP = /\s+/g;
const SPECIAL_SYMBOLS_REGEXP = /[^a-z\d_]/g;
export const FORBIDDEN_OPTION_SYMBOLS_REGEXP = /[,;<>]/g;

const ACCESS_PERMISSIONS_USER_ROLES = [UserRoleName.User, UserRoleName.Admin, UserRoleName.Owner];

const CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description', 'value'];
const IDENTIFICATION_NUMBER_AUTOCOMPLETION_SEARCH_ATTRIBUTES: SearchableOptionAttribute[] = ['name', 'description'];

interface OptionSpec extends Omit<Option, 'disabled'> {
  isAvailable: (dataType: VisualDataType) => boolean;
}

const ACCESS_PERMISSIONS_SELECT_OPTIONS: OptionSpec[] = [
  {
    name: 'Read and Edit',
    value: VariableAccessPermissionType.ReadWrite,
    description: 'Data will be visible and editable',
    isAvailable: () => true,
  },
  {
    name: 'Read Only',
    value: VariableAccessPermissionType.ReadOnly,
    description: 'Data will be visible but not editable',
    isAvailable: () => true,
  },
  {
    name: 'Partially Hidden',
    value: VariableAccessPermissionType.PartialRead,
    description: 'Only the last 4 characters will be visible',
    isAvailable: (dataType) => DATA_TYPES_PARTIAL_READ_SUPPORTED.includes(dataType),
  },
  {
    name: 'Fully Hidden',
    value: VariableAccessPermissionType.NoAccess,
    description: 'Data will be completely hidden',
    isAvailable: () => true,
  },
];

const getAccessPermissionsOptions = (dataType: VisualDataType | undefined): Option[] =>
  ACCESS_PERMISSIONS_SELECT_OPTIONS.map(({ isAvailable, ...option }) => {
    if (!dataType) {
      return option;
    }

    const isDisabled = !isAvailable(dataType);

    const tooltip = isDisabled
      ? getMessage(MessageType.VariablePartiallyHiddenUnsupported, { dataType: getVariableDataTypeName(dataType) })
      : undefined;

    return {
      ...option,
      tooltip,
      disabled: isDisabled,
    };
  });

const hasAccessPermissionsForEachRole = (
  permissions: Partial<VariableUserAccessPermissions>,
): permissions is VariableUserAccessPermissions => {
  const permissionTypes = Object.values(VariableAccessPermissionType);
  return Object.values(UserRoleName).every((roleName) =>
    permissionTypes.includes(permissions[roleName] as VariableAccessPermissionType),
  );
};

const getCompatibleDataTypes = (currentDataType: VisualDataType) => {
  if (currentDataType === 'Number' || currentDataType === 'Monetary' || currentDataType === 'Percentage') {
    return NUMBER_DATA_TYPES;
  }
  if (currentDataType === 'Text' || currentDataType === 'List') {
    return TEXT_DATA_TYPES;
  }
  return VARIABLE_DATA_TYPES;
};

const getDataTypeChangeTooltip = (sourceDataType: VisualDataType, targetDataType: VisualDataType) => {
  const sourceDataTypeName = getVariableDataTypeName(sourceDataType);
  const targetDataTypeName = getVariableDataTypeName(targetDataType);
  return getMessage(MessageType.VariableDataTypeChangeForbidden, {
    sourceDataType: sourceDataTypeName,
    targetDataType: targetDataTypeName,
  });
};

const getDataTypeOptions = (currentDataType: VisualDataType | undefined, isVariableSaved: boolean) => {
  if (typeof currentDataType === 'undefined' || !isVariableSaved) {
    return VARIABLE_DATA_TYPES;
  }
  const compatibleDataTypes = getCompatibleDataTypes(currentDataType);
  return VARIABLE_DATA_TYPES.map((option) => {
    const isEnabled = compatibleDataTypes.includes(option);
    const tooltip = isEnabled ? null : getDataTypeChangeTooltip(currentDataType, option.value as VisualDataType);
    return {
      ...option,
      disabled: !isEnabled,
      tooltip,
    };
  });
};

const getValue = (value: string | null, defaultValue: string): string | null => {
  if (value) {
    return value;
  }

  if (defaultValue) {
    return defaultValue;
  }

  return null;
};

const VariableForm = ({ onSave, children, variable, topRightElement, regionSettings }: VariableFormProps) => {
  const {
    displayName: name,
    description: fetchedDescription,
    systemName: fetchedSystemName,
    optionsList: fetchedOptionsList,
    userAccessPermissions = {
      [UserRoleName.Owner]: VariableAccessPermissionType.ReadWrite,
      [UserRoleName.Admin]: VariableAccessPermissionType.ReadWrite,
      [UserRoleName.User]: VariableAccessPermissionType.ReadWrite,
    },
    phoneNumberFormat = null,
    identificationNumberType = null,
    identificationNumberDescription = null,
    currency = null,
    dateFormat = null,
  } = variable || {};

  const dataType = variable && getVisualDataType(variable);

  const {
    phoneNumberFormat: defaultPhoneNumberFormat = '',
    currencyFormat: defaultCurrencyFormat = '',
    dateFormat: defaultDateFormat = '',
    idNumberType: defaultIdentificationNumberType = '',
  } = regionSettings || {};

  const [displayName, setDisplayName] = useState<string>(name || '');
  const [systemName, setSystemName] = useState<string>(fetchedSystemName || '');
  const [description, setDescription] = useState<string>(fetchedDescription || '');
  const [currentDataType, setCurrentDataType] = useState<VisualDataType | undefined>(dataType);
  const [optionsList, setOptionsList] = useState<string[]>(fetchedOptionsList || []);
  const [changedUserAccessPermissions, setChangedUserAccessPermissions] = useState<
    Partial<VariableUserAccessPermissions>
  >(userAccessPermissions);
  const [changedPhoneNumberFormat, setChangedPhoneNumberFormat] = useState(
    getValue(phoneNumberFormat, defaultPhoneNumberFormat),
  );
  const [changedIdentificationNumberType, setChangedIdentificationNumberType] = useState(
    getValue(identificationNumberType, defaultIdentificationNumberType),
  );
  const [changedIdentificationNumberDescription, setChangedIdentificationNumberDescription] = useState(
    identificationNumberDescription,
  );
  const [changedCurrency, setChangedCurrency] = useState(getValue(currency, defaultCurrencyFormat));
  const [changedDateFormat, setChangedDateFormat] = useState(getValue(dateFormat, defaultDateFormat));

  useEffect(() => {
    setChangedPhoneNumberFormat(variable ? phoneNumberFormat : defaultPhoneNumberFormat);
    setChangedCurrency(variable ? currency : defaultCurrencyFormat);
    setChangedDateFormat(variable ? dateFormat : defaultDateFormat);
    setChangedIdentificationNumberType(variable ? identificationNumberType : defaultIdentificationNumberType);
  }, [variable]);

  const accessPermissionsFormRowRef = useRef<HTMLElement>(null);

  const onVariableSave = () => {
    if (
      !hasAccessPermissionsForEachRole(changedUserAccessPermissions) ||
      (currentDataType === 'PhoneNumber' && !changedPhoneNumberFormat)
    ) {
      return;
    }

    const correctDisplayName = trimAll(displayName);
    const variableData: VariableClientType = {
      displayName: correctDisplayName,
      description,
      dataType: currentDataType as VisualDataType,
      optionsList,
      userAccessPermissions: changedUserAccessPermissions as VariableUserAccessPermissions,
      phoneNumberFormat: changedPhoneNumberFormat as string | null,
      identificationNumberType: changedIdentificationNumberType,
      identificationNumberDescription: changedIdentificationNumberDescription,
      currency: changedCurrency!,
      dateFormat: changedDateFormat!,
    };

    onSave(variableData);
  };

  const onPhoneNumberFormatChange = ({ value }: Option) => {
    setChangedPhoneNumberFormat(value);
  };

  const onIdentificationNumberTypeChange = ({ value }: Option) => {
    setChangedIdentificationNumberType(value);
  };

  const onIdentificationNumberDescriptionChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setChangedIdentificationNumberDescription(target.value);
  };

  const onCurrencyChange = ({ value }: Option) => {
    setChangedCurrency(value);
  };
  const onDateFormatChange = ({ value }: Option) => {
    setChangedDateFormat(value);
  };

  const updateSystemName = (displayNameToSet: string) => {
    const systemNameToSet = displayNameToSet
      .trim()
      .toLowerCase()
      .replace(EMPTY_SPACE_REGEXP, '_')
      .replace(SPECIAL_SYMBOLS_REGEXP, '');
    setSystemName(systemNameToSet);
  };

  const onDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const displayNameToSet = e.target.value;
    setDisplayName(displayNameToSet);
    if (!variable?.id) {
      updateSystemName(displayNameToSet);
    }
  };

  const onDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
  const onDataTypeChange = ({ value }: Option) => setCurrentDataType(value as VisualDataType);

  const onAddOption = (value: string) => setOptionsList((prevOptionsList) => [...prevOptionsList, value]);
  const onDeleteOption = (value: string) => setOptionsList(optionsList.filter((option) => option !== value));

  const handleAccessPermissionsChange = (permissionType: VariableAccessPermissionType, userRole: UserRoleName) => {
    setChangedUserAccessPermissions({
      ...changedUserAccessPermissions,
      [userRole]: permissionType,
    });
  };

  const areRequiredFieldsSet = displayName && currentDataType;
  const areAttributesChanged = [
    [name, displayName],
    [description, fetchedDescription],
    [currentDataType, dataType],
    [userAccessPermissions, changedUserAccessPermissions],
    [phoneNumberFormat, changedPhoneNumberFormat],
    [identificationNumberType, changedIdentificationNumberType],
    [identificationNumberDescription, changedIdentificationNumberDescription],
    [currency, changedCurrency],
    [dateFormat, changedDateFormat],
  ].some(([a, b]) => !isEqual(a, b));

  const areAttributesWithOptionsListChanged = areAttributesChanged || !isEqual(fetchedOptionsList, optionsList);

  const areExtraAttributesSet = () => {
    switch (currentDataType) {
      case 'List': {
        return Boolean(optionsList?.length);
      }
      case 'Monetary': {
        return Boolean(changedCurrency);
      }
      case 'PhoneNumber': {
        return Boolean(changedPhoneNumberFormat);
      }
      case 'IdentificationNumber': {
        return Boolean(changedIdentificationNumberType);
      }
      default: {
        return true;
      }
    }
  };

  const isSaveButtonEnabled =
    areRequiredFieldsSet &&
    (currentDataType === 'List' ? areAttributesWithOptionsListChanged : areAttributesChanged) &&
    hasAccessPermissionsForEachRole(changedUserAccessPermissions) &&
    areExtraAttributesSet();

  return (
    <>
      <VariableTooltipWrapper variable={variable} fieldName="Display Name">
        <TextInput
          disabled={variable?.isStandard || !!variable?.dependsOn}
          labelTitle="Display Name"
          value={displayName}
          onChange={onDisplayNameChange}
          valueValidationType={ValueValidationType.LettersNumbersOnly}
          topRightElement={topRightElement}
        />
      </VariableTooltipWrapper>
      <VariableTooltipWrapper tooltip="The System Name cannot be modified">
        <TextInput labelTitle="System Name" value={systemName} disabled />
      </VariableTooltipWrapper>
      <VariableTooltipWrapper variable={variable} fieldName="Data Type">
        <AutoCompletion
          disabled={variable?.isStandard || !!variable?.dependsOn}
          labelTitle="Data Type"
          onChange={onDataTypeChange}
          value={currentDataType}
          options={getDataTypeOptions(currentDataType, Boolean(variable?.id))}
        />
      </VariableTooltipWrapper>
      {currentDataType === 'List' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <OptionsInput
            disabled={!!variable?.dependsOn}
            id="listOptions"
            selectedOptions={optionsList}
            labelTitle="List of Options"
            onDeleteOption={onDeleteOption}
            onAddOption={onAddOption}
            onFormatValue={(value: string) => value.replace(FORBIDDEN_OPTION_SYMBOLS_REGEXP, '')}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'PhoneNumber' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <CountryCodeSelect
            disabled={!!variable?.dependsOn}
            labelTitle="Phone Number Format"
            onChange={onPhoneNumberFormatChange}
            value={changedPhoneNumberFormat || undefined}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'IdentificationNumber' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Identification Number Type"
            onChange={onIdentificationNumberTypeChange}
            value={changedIdentificationNumberType || undefined}
            options={IDENTIFICATION_NUMBER_TYPES}
            searchedOptionAttrs={IDENTIFICATION_NUMBER_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
          />
        </VariableTooltipWrapper>
      )}
      {changedIdentificationNumberType === 'Other' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <TextInput
            disabled={!!variable?.dependsOn}
            labelTitle="Identification Number Description"
            value={changedIdentificationNumberDescription || ''}
            onChange={onIdentificationNumberDescriptionChange}
            placeholder="Type up to 35 characters"
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'Monetary' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Currency Format"
            onChange={onCurrencyChange}
            value={changedCurrency || undefined}
            options={formattedCurrencies}
            searchedOptionAttrs={CURRENCY_FORMAT_AUTOCOMPLETION_SEARCH_ATTRIBUTES}
          />
        </VariableTooltipWrapper>
      )}
      {currentDataType === 'Date' && (
        <VariableTooltipWrapper variable={variable} ignoreStandard>
          <AutoCompletion
            disabled={!!variable?.dependsOn}
            labelTitle="Date Format"
            onChange={onDateFormatChange}
            value={changedDateFormat || undefined}
            options={DATE_FORMATS}
          />
        </VariableTooltipWrapper>
      )}
      <FormRow ref={accessPermissionsFormRowRef}>
        {ACCESS_PERMISSIONS_USER_ROLES.map((userRole) => {
          const value = changedUserAccessPermissions[userRole];

          const onChange = ({ value: type }: Option) => {
            handleAccessPermissionsChange(type as VariableAccessPermissionType, userRole);
          };

          return (
            <VariableTooltipWrapper key={`${userRole}_permissions`} variable={variable} ignoreStandard>
              <AutoCompletion
                disabled={!!variable?.dependsOn}
                labelTitle={`${startCase(userRole)} Permissions`}
                onChange={onChange}
                value={value}
                options={getAccessPermissionsOptions(currentDataType)}
                dropdownAnchorRef={accessPermissionsFormRowRef}
              />
            </VariableTooltipWrapper>
          );
        })}
      </FormRow>
      <TextInput labelTitle="Description" value={description} onChange={onDescriptionChange} />
      {cloneElement(children, { disabled: !isSaveButtonEnabled, onSave: onVariableSave })}
    </>
  );
};

export default VariableForm;
