import { FormLayoutData } from 'api/LoanOriginationSystem/Types';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';

const isApplicationDetailsFormDataValid = (data: FormLayoutData) => {
  const loanAmount = data[ApplicationDefaultVariable.LoanAmount];

  if (typeof loanAmount !== 'number') {
    return false;
  }

  return loanAmount > 0;
};

export default isApplicationDetailsFormDataValid;
