import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { DocuSignIntegrationSettings } from 'api/Core/DocuSignIntegrationApi';
import {
  createDocuSignIntegration,
  ESignIntegrationActionOrigin,
  getDocuSignIntegration,
  updateDocuSignIntegration,
} from 'ESignIntegrations/ActionCreator';
import Button from 'components/Button';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import Header from './Header';
import DocuSignCredentials from './DocuSignCredentials';
import DocuSignTemplates from './DocuSignTemplates';
import { DocuSignTab } from './DocuSignTab';
import styles from './DocuSign.module.scss';

interface DocuSignProps {
  tab: DocuSignTab;
  onTabChange: (tab: DocuSignTab) => void;
  onTemplatesImport: () => void;
}

const TABS = [
  {
    label: 'Credentials',
    id: DocuSignTab.Credentials,
  },
  {
    label: 'Templates',
    id: DocuSignTab.Templates,
  },
];

const DocuSign: FC<DocuSignProps> = ({ tab, onTabChange, onTemplatesImport }) => {
  const dispatch = useDispatch();
  const { docuSignIntegration, isDocuSignIntegrationLoaded } = useSelector(
    (state: ReduxState) => state.eSignIntegrations,
  );
  const isIntegrationSavingInProgress = useSelector(
    (state: ReduxState) => state.docuSign.isIntegrationSavingInProgress,
  );

  useEffect(() => {
    dispatch(getDocuSignIntegration());
  }, []);

  const handleTabChange = (newTab: TabSwitchOption) => {
    onTabChange(newTab.id as DocuSignTab);
  };

  const handleIntegrationSave = (settings: DocuSignIntegrationSettings) => {
    if (!docuSignIntegration) {
      return dispatch(setActionOrigin(createDocuSignIntegration(settings), ESignIntegrationActionOrigin.DocuSign));
    }

    return dispatch(
      setActionOrigin(
        updateDocuSignIntegration(docuSignIntegration.id, settings),
        ESignIntegrationActionOrigin.DocuSign,
      ),
    );
  };

  const renderContent = () => {
    if (tab === DocuSignTab.Credentials) {
      return (
        <DocuSignCredentials
          isIntegrationLoaded={isDocuSignIntegrationLoaded}
          integrationSavingInProgress={isIntegrationSavingInProgress}
          onSaveIntegration={handleIntegrationSave}
          integration={docuSignIntegration}
        />
      );
    }

    return (
      <DocuSignTemplates
        isIntegrationLoaded={isDocuSignIntegrationLoaded}
        onGoToCredentials={() => onTabChange(DocuSignTab.Credentials)}
        onTemplatesImport={onTemplatesImport}
        integration={docuSignIntegration}
      />
    );
  };

  const renderHeaderButton = () => {
    if (tab === DocuSignTab.Templates) {
      return (
        <Button disabled={!docuSignIntegration} kind="primary" size="default" onClick={onTemplatesImport}>
          Import Templates
        </Button>
      );
    }

    return null;
  };

  return (
    <div className={styles.pageContainer}>
      <Header renderButton={renderHeaderButton} />
      <TabSwitch className={styles.tabSwitch} tabs={TABS} selectedTabId={tab} onSelect={handleTabChange} />
      <div>{renderContent()}</div>
    </div>
  );
};

export default DocuSign;
