import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemApplicationStatusesApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  GetApplicationStatusesAction,
  getApplicationStatusesFailed,
  getApplicationStatusesSuccess,
  GetApplicationStatusesResult,
} from './ActionCreator';

const GetApplicationStatusesMiddleware = (api: LoanOriginationSystemApplicationStatusesApi) =>
  Fetcher<GetApplicationStatusesResult, GetApplicationStatusesAction>(
    ApplicationStatusesActionType.GetApplicationStatuses,
    getApplicationStatusesSuccess,
    getApplicationStatusesFailed,
    async (action) => {
      const statuses = await api.getAll(action.payload.productId);

      return {
        statuses,
        productId: action.payload.productId,
      };
    },
  );

export default GetApplicationStatusesMiddleware;
