import { DataViewSortingType, StrategyGroupsData } from 'api/Types';

export interface StrategyGroupsWithParams extends StrategyGroupsData {
  searchInputValue: string;
}

export enum VersionsTableSortingField {
  Version = 'version',
  Status = 'status',
  UpdatedDate = 'updatedDate',
}

export interface StrategyVersionType {
  id: string;
  version: number;
  isLocked: boolean;
  updated: string;
  editorFirstName: string;
  editorLastName: string;
  description?: string;
  status: string;
}

export type VersionsTableViewSortingType = DataViewSortingType<VersionsTableSortingField>;
