import React, { FC } from 'react';
import Title from './Title';
import { Label, LabelsSortingField, LabelsSortingType } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import LabelsTable from './Table';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import styles from './LabelsDetails.module.scss';
import { NullableItems, PaginationProps } from 'pagination';
import { LoanOriginationSystemLabelsFiltersState } from 'LoanOriginationSystemLabelsPage/Filters/Types';

interface LabelsDetailsProps {
  onSearch: (searchValue: string) => void;
  onSort: (field: LabelsSortingField, ascending: boolean) => void;
  isNoLabels: boolean;
  paginationProps: PaginationProps;
  filters: LoanOriginationSystemLabelsFiltersState;
  labels: NullableItems<Label>;
  sortingType: LabelsSortingType;
  onDeletePopupOpen: (id: string) => void;
  onCreatePopupOpen: () => void;
  onUpdatePopupOpen: (id: string) => void;
}

const LabelsDetails: FC<LabelsDetailsProps> = ({
  onSearch,
  onSort,
  isNoLabels,
  paginationProps,
  filters,
  labels,
  sortingType,
  onDeletePopupOpen,
  onCreatePopupOpen,
  onUpdatePopupOpen,
}) => {
  const renderBody = () => {
    if (filters.searchInputValue && !labels.length) {
      return <SearchNotFound searchValue={filters.searchInputValue} />;
    }

    if (!labels.length) {
      return (
        <NoItems
          title="No labels have been added yet!"
          buttonMessage="Add Label"
          onButtonClick={onCreatePopupOpen}
          className={styles.labelsNoData}
          titleClassName={styles.tasksNoDataTitle}
        />
      );
    }

    return (
      <LabelsTable
        labels={labels}
        paginationProps={paginationProps}
        onSort={onSort}
        sortingType={sortingType}
        onPopUpOpen={onDeletePopupOpen}
        onUpdatePopupOpen={onUpdatePopupOpen}
      />
    );
  };

  return (
    <div>
      <Title
        onSearch={onSearch}
        hideSearch={isNoLabels}
        onCreatePopupOpen={onCreatePopupOpen}
        searchInputValue={filters.searchInputValue}
      />
      <div className={styles.contentContainer}>{renderBody()}</div>
    </div>
  );
};

export default LabelsDetails;
