export enum LoanOriginationSystemEmailsActionTypes {
  GetEmails = 'loanOriginationSystemEmails/getEmails',
  GetEmailsSuccess = 'loanOriginationSystemEmails/getEmailsSuccess',
  GetEmailsFailure = 'loanOriginationSystemEmails/getEmailsFailure',
  SortEmails = 'loanOriginationSystemEmails/sortEmails',
  OpenDeleteEmailPopup = 'loanOriginationSystemEmails/openDeleteEmailPopup',
  CloseDeleteEmailPopup = 'loanOriginationSystemEmails/closeDeleteEmailPopup',
  DeleteEmail = 'loanOriginationSystemEmails/deleteEmail',
  DeleteEmailSuccess = 'loanOriginationSystemEmails/deleteEmailSuccess',
  DeleteEmailFailure = 'loanOriginationSystemEmails/deleteEmailFailure',
  OpenConnectEmailsPopup = 'loanOriginationSystemEmails/openConnectEmailsPopup',
  CloseConnectEmailsPopup = 'loanOriginationSystemEmails/closeConnectEmailsPopup',
  GetBorrowersForEmailsFilter = 'loanOriginationSystemEmails/getBorrowersForEmailsFilter',
  GetBorrowersForEmailsFilterSuccess = 'loanOriginationSystemEmails/getBorrowersForEmailsFilterSuccess',
  GetBorrowersForEmailsFilterFailure = 'loanOriginationSystemEmails/getBorrowersForEmailsFilterFailure',
  DownloadEmailAttachments = 'loanOriginationSystemEmails/downloadEmailAttachments',
  DownloadEmailAttachmentsSuccess = 'loanOriginationSystemEmails/downloadEmailAttachmentsSuccess',
  DownloadEmailAttachmentsFailure = 'loanOriginationSystemEmails/downloadEmailAttachmentsFailure',
  DownloadSingleEmailAttachment = 'loanOriginationSystemEmails/downloadSingleEmailAttachment',
  DownloadSingleEmailAttachmentSuccess = 'loanOriginationSystemEmails/downloadSingleEmailAttachmentSuccess',
  DownloadSingleEmailAttachmentFailure = 'loanOriginationSystemEmails/downloadSingleEmailAttachmentFailure',
  RemoveDownloadedAttachment = 'loanOriginationSystemEmails/removeDownloadedAttachment',
  ResetState = 'loanOriginationSystemEmails/resetState',
}
