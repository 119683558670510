import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import LoanOriginationSystemLabelsRestApi, { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { UpdateLabelAction, updateLabelFailure, updateLabelSuccess } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';

const UpdateLabelMiddleware = (api: LoanOriginationSystemLabelsRestApi) =>
  Fetcher<Label, UpdateLabelAction, ReduxState>(
    LoanOriginationSystemLabelsActionTypes.UpdateLabel,
    updateLabelSuccess,
    updateLabelFailure,
    async (action) => {
      const { id, name, color } = action.payload;
      const label = await api.updateLabel(id, name, color);
      return label;
    },
  );

export default UpdateLabelMiddleware;
