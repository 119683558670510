import React from 'react';
import SIZES from 'components/SendGrid/EditTemplateVariablesTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';

const SkeletonEditTemplateVariablesBody = () => {
  return (
    <TableRow disableHover>
      <TableBodyCell width={SIZES.FIELD}>
        <SkeletonRectangle color="primary6" width="80%" height="16px" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.VARIABLE}>
        <SkeletonRectangle width="80%" color="primary6" height="16px" />
      </TableBodyCell>
    </TableRow>
  );
};

export default SkeletonEditTemplateVariablesBody;
