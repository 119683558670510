import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { CompanyLogoUploadActionType } from './ActionTypes';

export interface UploadCompanyLogoAction {
  type: CompanyLogoUploadActionType.UploadCompanyLogo;
  payload: File;
}

export interface UploadCompanyLogoSuccessAction {
  type: CompanyLogoUploadActionType.UploadCompanyLogoSuccess;
  payload: string;
}

export const uploadCompanyLogo = (payload: File): UploadCompanyLogoAction => ({
  type: CompanyLogoUploadActionType.UploadCompanyLogo,
  payload,
});

export const uploadCompanyLogoSuccess = (payload: string): UploadCompanyLogoSuccessAction => ({
  type: CompanyLogoUploadActionType.UploadCompanyLogoSuccess,
  payload,
});

export const uploadCompanyLogoFailure = FailedActionCreator(CompanyLogoUploadActionType.UploadCompanyLogoFailure);

export const openCompanyLogoUploadDialog = () => ({
  type: CompanyLogoUploadActionType.OpenCompanyLogoUploadDialog,
});

export const closeCompanyLogoUploadDialog = () => ({
  type: CompanyLogoUploadActionType.CloseCompanyLogoUploadDialog,
});
