import Fetcher from 'middlewares/Fetcher';
import { RunDecisionWizardActionType } from 'RunDecisionWizard/actionTypes';
import { GetApplicationDecisionRunsAction } from 'ApplicationDecisionRuns/actions';
import { getStrategiesFailure, getStrategiesSuccess } from 'RunDecisionWizard/actions';
import { StrategiesListItem } from 'api/Types';
import { ApplicationDecisionRunsApi } from 'api/LoanOriginationSystem/ApplicationDecisionRunsApi';

const GetApplicationStrategyListMiddleware = (api: ApplicationDecisionRunsApi) =>
  Fetcher<StrategiesListItem[], GetApplicationDecisionRunsAction>(
    RunDecisionWizardActionType.GetStrategies,
    getStrategiesSuccess,
    getStrategiesFailure,
    () => {
      return api.getStrategyList();
    },
  );

export default GetApplicationStrategyListMiddleware;
