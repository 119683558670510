import React, { FC, useEffect, useState } from 'react';
import ConfirmPasswordForm from 'components/ConfirmPasswordForm';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import PhoneNumberForm from 'components/ManagePhoneAuthenticationPopup/PhoneNumberForm';
import PhoneCodeForm from 'components/ManagePhoneAuthenticationPopup/PhoneCodeForm';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import {
  disablePhoneAuthentication,
  enablePhoneAuthentication,
  requestPhoneAuthentication,
} from 'AccountDetails/AccountDetailsActionCreator';

interface ManagePhoneAuthenticationPopupProps {
  authenticationEnabled: boolean;
  onClose: () => void;
  phoneNumberFormat: string;
  requestPhoneAuthenticationError: string;
  userPhone?: string | null;
}

const CODE_ALREADY_SENT_ERROR = 'The code has been already sent.';
const PASSWORD_CONFIRMATION_EXPIRED_ERROR = 'Password confirmation has expired.';

const ManagePhoneAuthenticationPopup: FC<ManagePhoneAuthenticationPopupProps> = ({
  authenticationEnabled,
  onClose,
  userPhone,
  phoneNumberFormat,
  requestPhoneAuthenticationError,
}) => {
  const dispatchRoutine = useDispatchRoutine();
  const [loading, setLoading] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [codeRequested, setCodeRequested] = useState(false);
  const [phone, setPhone] = useState(userPhone || '');

  useEffect(() => {
    if (requestPhoneAuthenticationError === CODE_ALREADY_SENT_ERROR) {
      setCodeRequested(true);
    }
    if (requestPhoneAuthenticationError === PASSWORD_CONFIRMATION_EXPIRED_ERROR) {
      setPhone('');
      setPasswordConfirmed(false);
    }
  }, [requestPhoneAuthenticationError]);

  const handleCodeEnter = async (code: string) => {
    setLoading(true);

    try {
      const actionData = authenticationEnabled
        ? disablePhoneAuthentication(code)
        : enablePhoneAuthentication(code, phone);
      await dispatchRoutine(actionData);
      onClose();
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordConfirmed = async () => {
    setPasswordConfirmed(true);
    if (authenticationEnabled) {
      await dispatchRoutine(requestPhoneAuthentication(phone));
      setCodeRequested(true);
    }
  };

  const handlePhoneNumberEnter = async (phoneNumber: string) => {
    setPhone(phoneNumber);
    setLoading(true);
    try {
      await dispatchRoutine(requestPhoneAuthentication(phoneNumber));
      setCodeRequested(true);
    } finally {
      setLoading(false);
    }
  };

  const getTitle = () => (authenticationEnabled ? 'Disable Phone Authentication' : 'Phone Authentication');

  return (
    <PopUp title={getTitle()} closePopUp={onClose}>
      <PopUpContent hasTopMargin>
        {!passwordConfirmed && <ConfirmPasswordForm onPasswordConfirmed={handlePasswordConfirmed} />}
        {!codeRequested && passwordConfirmed && !authenticationEnabled && (
          <PhoneNumberForm
            phoneNumberFormat={phoneNumberFormat}
            onPhoneEntered={handlePhoneNumberEnter}
            loading={loading}
          />
        )}
        {passwordConfirmed && codeRequested && (
          <PhoneCodeForm
            onCodeEntered={handleCodeEnter}
            loading={loading}
            phone={phone}
            action={authenticationEnabled ? 'Disable' : 'Enable'}
          />
        )}
      </PopUpContent>
    </PopUp>
  );
};

export default ManagePhoneAuthenticationPopup;
