import Fetcher from 'middlewares/Fetcher';
import { ApplicationNotesApi } from 'api/LoanOriginationSystem/ApplicationNotesApi';
import {
  updateApplicationNoteSuccess,
  updateApplicationNoteFailure,
  UpdateApplicationNoteAction,
} from './ActionCreator';
import { ApplicationNotesActionType } from './ActionTypes';
import { ApplicationNote } from 'api/Types';

const UpdateApplicationNoteMiddleware = (api: ApplicationNotesApi) =>
  Fetcher<ApplicationNote, UpdateApplicationNoteAction>(
    ApplicationNotesActionType.UpdateApplicationNote,
    updateApplicationNoteSuccess,
    updateApplicationNoteFailure,
    (action) => {
      return api.updateNote(action.payload.applicationId, action.payload.noteId, action.payload.note);
    },
  );

export default UpdateApplicationNoteMiddleware;
