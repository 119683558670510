import { TableViewData } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  getBorrowerApplicationsSuccess,
  getBorrowerApplicationsFailure,
  GetBorrowerApplicationsAction,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { LoanOriginationSystemBorrowerDetailsActionType } from './ActionTypes';
import { BORROWER_APPLICATIONS_PER_PAGE_DEFAULT } from 'components/LoanOriginationSystem/BorrowerDetails/pagination';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

const GetBorrowerApplicationsMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<TableViewData<Application>, GetBorrowerApplicationsAction, ReduxState>(
    LoanOriginationSystemBorrowerDetailsActionType.GetBorrowerApplications,
    getBorrowerApplicationsSuccess,
    getBorrowerApplicationsFailure,
    (action) => {
      const { id, sortingType } = action.payload;

      return api.getBorrowerApplications(
        id,
        {
          offset: 0,
          count: BORROWER_APPLICATIONS_PER_PAGE_DEFAULT,
        },
        sortingType,
      );
    },
  );

export default GetBorrowerApplicationsMiddleware;
