export enum StrategyGroupActionType {
  GetStrategyGroupsData = 'strategyGroupsData/getStrategyGroups',
  GetStrategyGroupsDataSuccess = 'strategyGroupsData/getStrategyGroupsSuccess',
  GetStrategyGroupsDataFailure = 'strategyGroupsData/getStrategyGroupsFailure',
  SetStrategyGroupsSearchInput = 'strategyGroupsData/setStrategyGroupsSearchInput',
  SetShowArchived = 'strategyGroupsData/setShowArchived',
  ResetFilters = 'strategyGroupsData/resetFilters',
  SetUpdaters = 'strategyGroupsData/setUpdaters',
  ClearStrategyGroupsData = 'strategyGroupsData/clearStrategyGroupsData',
}
