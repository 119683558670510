import {
  APIActivationImage,
  ApplicationsImage,
  BillingImage,
  CompanyInformationImage,
  CustomersImage,
  EmailsImage,
  IntegrationImage,
  IntermediariesImage,
  ReportingImage,
  RulesManagementImage,
  SettingsImage,
  StrategyProcessImage,
  TasksImage,
  VariablesImage,
} from 'static/images';
import { Product } from 'MainLayout/leftNavReducer/LeftNavTypes';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationSectionName, DigifiProduct } from 'components/RouteWithPermissions/Types';
import PageName from 'constants/PageName';

const productsList: Product[] = [
  {
    title: ApplicationSectionName.Dashboard,
    route: '/home',
  },
  {
    title: ApplicationSectionName.LoanOriginationSystem,
    route: '/los/applications',
    product: DigifiProduct.LoanOriginationSystem,
    links: [
      {
        id: NavigationLinkId.Applications,
        title: 'Applications',
        route: '/los/applications',
        Icon: ApplicationsImage,
      },
      {
        id: NavigationLinkId.Borrowers,
        title: 'Borrowers',
        route: '/los/borrowers',
        Icon: CustomersImage,
      },
      {
        id: NavigationLinkId.Intermediaries,
        title: 'Intermediaries',
        route: '/los/intermediaries',
        Icon: IntermediariesImage,
      },
      {
        id: NavigationLinkId.Tasks,
        title: 'Tasks',
        Icon: TasksImage,
        route: '/los/tasks',
      },
      {
        id: NavigationLinkId.Emails,
        title: 'Emails',
        Icon: EmailsImage,
        route: '/los/emails',
      },
      {
        id: NavigationLinkId.Reporting,
        title: 'Reporting',
        Icon: ReportingImage,
        route: '/los/reporting',
      },
      {
        id: NavigationLinkId.Configuration,
        title: 'Configuration',
        Icon: SettingsImage,
        nestedLinks: [
          {
            id: NavigationLinkId.Products,
            title: 'Products',
            route: '/los/configuration/products',
          },
          {
            id: NavigationLinkId.BorrowersConfiguration,
            title: 'Borrowers',
            route: '/los/configuration/borrowers',
          },
          {
            id: NavigationLinkId.IntermediariesConfiguration,
            title: 'Intermediaries',
            route: '/los/configuration/intermediaries',
          },
          {
            id: NavigationLinkId.ProcessAutomation,
            title: 'Process Automation',
            route: '/los/configuration/processautomation',
          },
          {
            id: NavigationLinkId.ApplicationLabels,
            title: 'Application Labels',
            route: '/los/settings/applicationlabels',
          },
        ],
      },
    ],
  },
  {
    title: ApplicationSectionName.DecisionEngine,
    route: '/decision/strategies',
    product: DigifiProduct.DecisionEngine,
    links: [
      {
        id: NavigationLinkId.Strategies,
        title: 'Strategies',
        Icon: RulesManagementImage,
        route: '/decision/strategies',
      },
      {
        id: NavigationLinkId.StrategyProcessing,
        title: 'Processing',
        Icon: StrategyProcessImage,
        nestedLinks: [
          {
            id: NavigationLinkId.IndividualStrategyProcessing,
            title: 'Individual',
            route: '/decision/processing/individual/run',
          },
          {
            id: NavigationLinkId.BatchStrategyProcessing,
            title: 'Batch',
            route: '/decision/processing/batch/run',
          },
        ],
      },
      {
        id: NavigationLinkId.APIActivation,
        title: PageName.ApiActivation,
        Icon: APIActivationImage,
        route: '/decision/processing/api/run',
      },
    ],
  },
  {
    title: ApplicationSectionName.CompanySettings,
    route: '/company-settings/company_info',
    links: [
      {
        id: NavigationLinkId.General,
        title: 'General',
        Icon: CompanyInformationImage,
        nestedLinks: [
          {
            id: NavigationLinkId.CompanyInformation,
            title: PageName.CompanyInformation,
            route: '/company-settings/company_info',
          },
          {
            id: NavigationLinkId.RegionSettings,
            title: PageName.RegionSettings,
            route: '/company-settings/region_settings',
          },
        ],
      },
      {
        id: NavigationLinkId.PlanAndBilling,
        title: PageName.PlanAndBilling,
        Icon: BillingImage,
        route: '/company-settings/billing',
      },
      {
        id: NavigationLinkId.Team,
        title: 'Team',
        Icon: CustomersImage,
        nestedLinks: [
          {
            id: NavigationLinkId.Users,
            title: PageName.PlatformUsers,
            route: '/company-settings/users',
          },
          {
            id: NavigationLinkId.Permissions,
            title: PageName.AccessPermissions,
            route: '/company-settings/permissions',
          },
        ],
      },
      { id: NavigationLinkId.Variables, title: 'Variables', Icon: VariablesImage, route: '/decision/variables/all' },
      {
        title: 'Integrations',
        id: NavigationLinkId.Integrations,
        Icon: IntegrationImage,
        nestedLinks: [
          {
            id: NavigationLinkId.DigiFiAPI,
            title: PageName.DigiFiApi,
            route: '/company-settings/digifi-api',
          },
          {
            id: NavigationLinkId.DocuSign,
            title: 'DocuSign',
            route: '/company-settings/docu-sign/credentials',
          },
          {
            id: NavigationLinkId.SendGrid,
            title: 'SendGrid',
            route: '/company-settings/send-grid/credentials',
          },
        ],
      },
    ],
  },
  {
    title: ApplicationSectionName.MyAccount,
    route: '/account/profile',
  },
];

export default productsList;
