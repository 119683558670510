import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Popper, PopperProps } from '@material-ui/core';
import styles from './CustomPopper.module.scss';

export interface CustomPopperProps extends PopperProps {
  onClose?: () => void;
}

const ESCAPE_KEY_CODE = 'Escape';

const CustomPopper = ({ children, open, onClose, className, ...restPopperProps }: CustomPopperProps) => {
  const onDocumentClick = (event: MouseEvent) => {
    if (!open) {
      return;
    }

    const popperElement = (event.target as Element).closest(`.${styles.popper}`);

    if (!popperElement) {
      onClose?.();
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (open && event.key === ESCAPE_KEY_CODE) {
      onClose?.();
    }
  };

  const clearEventListeners = () => {
    document.removeEventListener('click', onDocumentClick);
    document.removeEventListener('keydown', onKeyDown);
  };

  useEffect(() => {
    clearEventListeners();

    document.addEventListener('click', onDocumentClick);
    document.addEventListener('keydown', onKeyDown);

    return clearEventListeners;
  }, [open]);

  return (
    <Popper
      placement="bottom-start"
      className={clsx(styles.popper, className)}
      disablePortal
      open={open}
      {...restPopperProps}
    >
      {children}
    </Popper>
  );
};

export default CustomPopper;
