import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { LoanOriginationSystemTemplatesFiltersActionTypes } from './ActionTypes';
import { ResetAllTemplatesFilters, ChangePopupFiltersAction } from './ActionCreator';
import { GetTemplatesRequest } from 'SendGrid/ActionCreator';

const isChangePopupFiltersAction = (action: AnyAction): action is ChangePopupFiltersAction =>
  action.type === LoanOriginationSystemTemplatesFiltersActionTypes.ChangePopupFilters;

const isResetAllFiltersAction = (action: AnyAction): action is ResetAllTemplatesFilters =>
  action.type === LoanOriginationSystemTemplatesFiltersActionTypes.ResetAllFilters;

const onTemplatesFiltersChangeMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isResetAllFiltersAction(action) || isChangePopupFiltersAction(action)) {
    const { sendGrid } = getState();
    const { filters, sortingType } = sendGrid;

    dispatch(
      GetTemplatesRequest({
        filters,
        sortingType,
      }),
    );
  }

  return result;
}) as Middleware;

export default onTemplatesFiltersChangeMiddleware;
