import React from 'react';

import TextInputStub, { TextInputStubProps } from 'components/TextInput/TextInputStub';
import { DropdownImage } from 'static/images';

import styles from './AutoCompletionStub.module.scss';

const AutoCompletionStub = ({ placeholder, ...restProps }: TextInputStubProps) => {
  const renderPlaceholder = () => (
    <>
      <div>{placeholder}</div>
      <DropdownImage />
    </>
  );

  return <TextInputStub {...restProps} stubInputClassName={styles.stubInput} placeholder={renderPlaceholder()} />;
};

export default AutoCompletionStub;
