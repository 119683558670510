import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ApplicationNotesActionType } from './ActionTypes';
import { closeApplicationNotePopup, getApplicationNotes, UpdateApplicationNoteSuccessAction } from './ActionCreator';

const isUpdateApplicationNoteSuccessAction = (action: AnyAction): action is UpdateApplicationNoteSuccessAction =>
  action.type === ApplicationNotesActionType.UpdateApplicationNoteSuccess;

export const UpdateApplicationNotesSuccessMiddlewares = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateApplicationNoteSuccessAction(action)) {
    const { loanOriginationSystemApplicationPage } = getState();

    const { notes, applicationData } = loanOriginationSystemApplicationPage;

    if (!applicationData) {
      return result;
    }
    notification.createNotification(getMessage(MessageType.ApplicationNoteUpdated), 'success', dispatch);

    dispatch(getApplicationNotes(applicationData.id, notes.search, notes.sortingType));
    dispatch(closeApplicationNotePopup());
  }

  return result;
}) as Middleware;
