import React from 'react';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';
import { EmailsColumnSizes } from 'components/LoanOriginationSystem/EmailsDashboard/EmailsTable/TableColumnSizes';

const SkeletonEmailLine = () => {
  return (
    <TableRow disableHover useActions>
      <TableBodyCell width={EmailsColumnSizes.CreatedAt}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.SentFrom}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.SentTo}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Subject}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={EmailsColumnSizes.Attachments}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonEmailLine;
