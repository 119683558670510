import React, { useEffect, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './EmailsFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { getBorrowersForEmailsFilter } from 'LoanOriginationSystemEmails/ActionCreator';
import { ReduxState } from 'types/redux';

interface EmailsFilterProps {
  dueDateFrom: Date | null;
  dueDateTo: Date | null;
  borrower: string | null;
}

const EmailsFilter = ({ dueDateFrom, dueDateTo, borrower }: EmailsFilterProps) => {
  const dispatch = useDispatch();

  const { borrowersForFilter, getBorrowersInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemEmails,
  );

  const formattedBorrowers = borrowersForFilter.map((borrowerData) => ({
    name: borrowerData.name,
    value: borrowerData.id,
  }));

  formattedBorrowers.unshift({ name: 'All', value: '' });

  const [selectedFilterInputOption, setSelectedInputFilterOption] = useState(formattedBorrowers[0]);

  const initialSelectedFilterOption = formattedBorrowers.find((option) => (option.value || null) === borrower)!;

  useEffect(() => {
    dispatch(getBorrowersForEmailsFilter());
    setSelectedInputFilterOption(initialSelectedFilterOption);
  }, []);

  const [fromDate, setFromDate] = useState(dueDateFrom);
  const [toDate, setToDate] = useState(dueDateTo);

  const filtersResult: FiltersResult = {
    createdAtRange: {
      from: fromDate,
      to: toDate,
    },
    borrower: selectedFilterInputOption.value || null,
  };

  const onFilterChange = (newOption: Option) => {
    setSelectedInputFilterOption(formattedBorrowers.find(({ value }) => value === newOption.value)!);
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Borrower"
        onChange={onFilterChange}
        options={formattedBorrowers}
        selectedOption={selectedFilterInputOption}
        loading={getBorrowersInProgress}
        className={styles.statusDropdownContainer}
      />
      <DateRangePicker from={fromDate} to={toDate} onChange={handleOnDateRangeChange} label="Due Date" />
      <Button kind="primary" size="form" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default EmailsFilter;
