import Fetcher from 'middlewares/Fetcher';
import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import {
  deleteApplicationDocumentSuccess,
  deleteApplicationDocumentFailure,
  DeleteApplicationDocumentAction,
} from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

const DeleteApplicationDocumentMiddleware = (api: ApplicationDocumentsApi) =>
  Fetcher<void, DeleteApplicationDocumentAction>(
    ApplicationDocumentsActionType.DeleteApplicationDocument,
    deleteApplicationDocumentSuccess,
    deleteApplicationDocumentFailure,
    (action) => {
      return api.delete(action.payload.documentId);
    },
  );

export default DeleteApplicationDocumentMiddleware;
