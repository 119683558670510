import React from 'react';
import Notifications from 'components/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import notificationActions from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes, NotificationItem } from 'handlers/notification/notificationTypes';
import { NotificationListProps } from 'components/Notifications/NotificationList';
import ApplicationStatusChangeErrorNotification from 'components/ApplicationStatusChangeErrorNotification';
import DuplicateApplicationErrorNotification from 'components/DuplicateApplicationErrorNotification/DuplicateApplicationErrorNotification';
import ItemHasApplicationsErrorNotification from 'components/ItemHasApplicationsErrorNotification/ItemHasApplicationsErrorNotification';
import ResetPasswordExpiredErrorNotification from 'pages/ResetPassword/ResetPasswordExpiredErrorNotification';

const notificationsComponentsByType = {
  [NotificationComponentTypes.ApplicationStatusChangeErrorNotificationComponent]: ApplicationStatusChangeErrorNotification,
  [NotificationComponentTypes.DuplicateApplicationError]: DuplicateApplicationErrorNotification,
  [NotificationComponentTypes.ResetPasswordExpiredErrorNotificationComponent]: ResetPasswordExpiredErrorNotification,
  [NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent]: ItemHasApplicationsErrorNotification,
};

type ConnectedNotificationsProps = Omit<
  NotificationListProps<typeof notificationsComponentsByType>,
  'notificationList' | 'hideNotification' | 'customNotificationsComponents'
>;

const ConnectedNotifications = (props: ConnectedNotificationsProps) => {
  const dispatch = useDispatch();
  const notificationsList: NotificationItem[] = useSelector((state: ReduxState) => state.notification);

  const hideNotification = (id: string) => {
    dispatch(notificationActions.hideNotification(id));
  };

  return (
    <Notifications
      customNotificationsComponents={notificationsComponentsByType}
      notificationList={notificationsList}
      hideNotification={hideNotification}
      {...props}
    />
  );
};

export default ConnectedNotifications;
