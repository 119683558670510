import { AnyAction } from 'redux';
import { DuplicateStrategyWizardState } from './types';
import { DuplicateStrategyWizardActionType } from 'DuplicateStrategyWizard/ActionTypes';

const initialState: DuplicateStrategyWizardState = {
  isOpen: false,
  strategyId: '',
  strategyName: '',
  actionOrigin: undefined,
};

const duplicateStrategyWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case DuplicateStrategyWizardActionType.OpenWizard: {
      const { strategyId, strategyName } = action.payload;
      const { actionOrigin } = action.meta;
      return { isOpen: true, strategyId, strategyName, actionOrigin };
    }
    case DuplicateStrategyWizardActionType.CloseWizard: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default duplicateStrategyWizardReducer;
