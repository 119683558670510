import React from 'react';
import {
  ApplicationStatusRulesValidationResult,
  RuleValidationResult,
} from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import PopUpContent from 'components/PopUps/PopUpContent';
import PopUp from 'components/PopUps/PopUp';
import Button from 'components/Button';
import ApplicationStatusRuleValidationResult from 'components/ApplicationStatusRuleValidationResult';
import styles from './ApplicationStatusRulesValidationPopup.module.scss';

export interface ApplicationStatusRulesValidationPopupProps {
  applicationName: string;
  statusName: string;
  statusRulesValidationResult: ApplicationStatusRulesValidationResult;
  onClose: () => void;
  onViewApplication: () => void;
}

const ApplicationStatusRulesValidationPopup = ({
  applicationName,
  statusName,
  statusRulesValidationResult,
  onClose,
  onViewApplication,
}: ApplicationStatusRulesValidationPopupProps) => {
  const renderRulesChecks = (ruleValidationResult: RuleValidationResult, index: number) => {
    const [multipleRule] = statusRulesValidationResult.status.rules[index].multipleRules;
    const [multipleRuleValidationResult] = ruleValidationResult.multipleRuleValidationResults;

    if (!multipleRule || !multipleRuleValidationResult) {
      return null;
    }

    return (
      <ApplicationStatusRuleValidationResult
        key={multipleRule.id}
        rule={multipleRule}
        ruleValidationResult={multipleRuleValidationResult}
      />
    );
  };

  return (
    <PopUp classNames={{ popupCore: styles.popup }} closePopUp={onClose} title="Failed Status Change">
      <PopUpContent hasTopMargin>
        <div className={styles.description}>
          You cannot change the application status for <b className={styles.bold}>{applicationName}</b> to{' '}
          <b className={styles.bold}>{statusName}</b> because one or more status rules do not pass.
        </div>
        <h5 className={styles.header}>Status Rules for {statusName}</h5>
        <div className={styles.statusRules}>
          {statusRulesValidationResult.ruleValidationResults.map(renderRulesChecks)}
        </div>
        <Button className={styles.closeButton} kind="primary" size="form" onClick={onViewApplication}>
          View Application
        </Button>
      </PopUpContent>
    </PopUp>
  );
};

export default ApplicationStatusRulesValidationPopup;
