import { AnyAction } from 'redux';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';
import { NewTask, Task } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';

export interface GetTaskDataAction extends AnyAction {
  type: LoanOriginationSystemEditTaskActionTypes.GetTask;
  payload: {
    id: string;
  };
}

export interface GetTaskDataSuccessAction extends AnyAction {
  type: LoanOriginationSystemEditTaskActionTypes.GetTaskSuccess;
  payload: {
    task: Task;
  };
}

export interface ResetEditTaskAction extends AnyAction {
  type: LoanOriginationSystemEditTaskActionTypes.ResetEditTask;
}

export interface EditTaskAction extends AnyAction {
  type: LoanOriginationSystemEditTaskActionTypes.EditTask;
  payload: {
    id: string;
    editTaskData: Partial<NewTask>;
  };
}

export interface EditTaskSuccessAction extends AnyAction {
  type: LoanOriginationSystemEditTaskActionTypes.EditTaskSuccess;
  payload: {
    updatedTask: Task;
  };
}

export const getTaskData = (id: string): GetTaskDataAction => {
  return {
    type: LoanOriginationSystemEditTaskActionTypes.GetTask,
    payload: {
      id,
    },
  };
};

export const getTaskDataSuccess = (task: Task): GetTaskDataSuccessAction => {
  return {
    type: LoanOriginationSystemEditTaskActionTypes.GetTaskSuccess,
    payload: {
      task,
    },
  };
};

export const resetEditTask = (): ResetEditTaskAction => {
  return {
    type: LoanOriginationSystemEditTaskActionTypes.ResetEditTask,
  };
};

export const editTask = (id: string, editTaskData: Partial<NewTask>): EditTaskAction => {
  return {
    type: LoanOriginationSystemEditTaskActionTypes.EditTask,
    payload: {
      editTaskData,
      id,
    },
  };
};

export const editTaskSuccess = (updatedTask: Task): EditTaskSuccessAction => {
  return {
    type: LoanOriginationSystemEditTaskActionTypes.EditTaskSuccess,
    payload: {
      updatedTask,
    },
  };
};

export const getTaskDataFailure = FailedActionCreator(LoanOriginationSystemEditTaskActionTypes.GetTaskFailure);

export const editTaskFailure = FailedActionCreator(LoanOriginationSystemEditTaskActionTypes.EditTaskFailure);
