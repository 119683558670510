import React, { FC, useMemo } from 'react';
import { ESignTemplate } from 'api/Core/ESignTemplatesApi';
import TableColumnSizes from 'components/DocuSign/FieldsTable/TableColumnSizes';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import FieldTableRow from './FieldTableRow';
import SkeletonFieldItem from './SkeletonFieldItem';
import styles from './FieldsTable.module.scss';

interface FieldsTableProps {
  template?: ESignTemplate | null;
}

const SKELETON_ITEMS_DEFAULT = 5;

const FieldsTable: FC<FieldsTableProps> = ({ template }) => {
  const recipientNamesById = useMemo(() => {
    if (!template) {
      return {};
    }

    return template.recipients.reduce(
      (result, recipient) => ({ ...result, [recipient.recipientId]: recipient.name }),
      {},
    );
  }, [template]);

  const renderFieldsTableBody = () => {
    if (!template) {
      return (
        <>
          {new Array(SKELETON_ITEMS_DEFAULT).fill(null).map((item, index) => (
            <SkeletonFieldItem key={index} />
          ))}
        </>
      );
    }

    return (
      <>
        {template.fields.map((field, index) => (
          <FieldTableRow
            recipientNamesById={recipientNamesById}
            key={index}
            field={field}
            variablesMapping={template.variablesMapping}
          />
        ))}
      </>
    );
  };

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Field}>
            Template Data Field
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Recipient}>
            Recipient
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Type}>
            Field Type
          </TableHeadCell>
          <TableHeadCell className={styles.tableHeadCell} width={TableColumnSizes.Variable}>
            Variable Used
          </TableHeadCell>
        </TableHead>
        <TableBody>{renderFieldsTableBody()}</TableBody>
      </Table>
    </div>
  );
};

export default FieldsTable;
