import Fetcher from 'middlewares/Fetcher';
import { ApplicationDocument, ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import {
  uploadApplicationDocumentSuccess,
  uploadApplicationDocumentFailure,
  UploadApplicationDocumentAction,
} from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

const UploadApplicationDocumentMiddleware = (api: ApplicationDocumentsApi) =>
  Fetcher<ApplicationDocument, UploadApplicationDocumentAction>(
    ApplicationDocumentsActionType.UploadApplicationDocument,
    uploadApplicationDocumentSuccess,
    uploadApplicationDocumentFailure,
    (action) => {
      return api.upload(action.payload.applicationId, action.payload.file);
    },
  );

export default UploadApplicationDocumentMiddleware;
