import Fetcher from 'middlewares/Fetcher';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';
import {
  GetApplicationDecisionRunsAction,
  getApplicationDecisionRunsFailure,
  getApplicationDecisionRunsSuccess,
} from 'ApplicationDecisionRuns/actions';
import { ApplicationDecisionRunsApi } from 'api/LoanOriginationSystem/ApplicationDecisionRunsApi';
import { IndividualProcessingHistoryCase, ItemsWithTotalCount } from 'api/Types';

const GetApplicationDecisionRunsMiddleware = (api: ApplicationDecisionRunsApi) =>
  Fetcher<ItemsWithTotalCount<IndividualProcessingHistoryCase>, GetApplicationDecisionRunsAction>(
    ApplicationDecisionRunActionType.GetApplicationDecisionRuns,
    getApplicationDecisionRunsSuccess,
    getApplicationDecisionRunsFailure,
    (action) => {
      return api.getAll(action.payload.applicationId, action.payload.sortingType);
    },
  );

export default GetApplicationDecisionRunsMiddleware;
