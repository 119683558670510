import styles from './MultipleOptionsSelect.module.scss';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Option from 'components/MultipleOptionsSelect/Option';
import CustomBlurInput from 'components/SelectInput/CustomBlurInput';
import DropdownContentWithPortal from 'components/DropdownContentWithPortal';
import React, { ChangeEvent, cloneElement, FC, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { Option as OptionType } from 'components/SelectInput/SelectInput';
import { KeyCode } from 'common/KeyCode';
import DropdownContent from 'components/SelectInput/DropdownContent';
import { DropdownImage, SelectInputSearchImage } from 'static/images';
import clsx from 'clsx';
import WrapperWithTooltip from 'components/Tooltip';

export interface OptionElementType {
  name: string;
  value: string;
  type: string;
}

export interface MultipleOptionsInputProps {
  id: string;
  inputRef: RefObject<HTMLInputElement>;
  options: OptionType[];
  selectedOptions: string[];
  matchingOptions: OptionType[];
  labelTitle: string;
  placeholder?: string;
  onDeleteOption: (value: string) => void;
  handleInputBlur: () => void;
  handleInputKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelect?: (value: string) => void;
  children?: ReactNode;
  inputValue: string;
  disabled?: boolean;
  renderInputOptionPrefix?: (value: string) => ReactNode;
  isBlurRequired?: (eventTarget: EventTarget | null) => boolean;
  errorMessage?: string;
  containerClassName?: string;
  optionClassName?: string;
  selectContainerClassName?: string;
  isLabelTags?: boolean;
  tooltip?: string;
  hideSearchIcon?: boolean;
  hideDropdownOnSelect?: boolean;
}

const MultipleOptionsSelect: FC<MultipleOptionsInputProps> = ({
  id,
  inputRef,
  options,
  labelTitle,
  placeholder,
  disabled = false,
  onDeleteOption,
  handleInputKeyUp,
  inputValue,
  handleInputBlur,
  onInputChange,
  isBlurRequired,
  onSelect,
  selectedOptions,
  children,
  matchingOptions,
  renderInputOptionPrefix,
  errorMessage,
  containerClassName,
  optionClassName,
  selectContainerClassName,
  isLabelTags,
  tooltip,
  hideSearchIcon,
  hideDropdownOnSelect,
}) => {
  const labelRef = useRef<HTMLLabelElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemIndexToFocus, setItemIndexToFocus] = useState<number | undefined>(0);
  const [hasFocus, setHasFocus] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(!!inputValue);
  }, [inputValue]);

  const getActiveItemIndex = (eventType: KeyCode.ArrowUp | KeyCode.ArrowDown) => {
    const maxItemIndex = matchingOptions.length - 1;
    if (eventType === KeyCode.ArrowDown) {
      return focusedItemIndex < maxItemIndex ? focusedItemIndex + 1 : 0;
    }
    return focusedItemIndex <= 0 ? maxItemIndex : focusedItemIndex - 1;
  };

  const onInputKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { keyCode } = event;

    if (keyCode === KeyCode.Esc) {
      setIsOpen(false);
      event.currentTarget.blur();
      onInputBlur(null);
    }

    if (keyCode === KeyCode.ArrowDown && !isOpen && hasFocus) {
      setIsOpen(true);
      return;
    }

    if (keyCode === KeyCode.ArrowDown || keyCode === KeyCode.ArrowUp) {
      const itemIndexToSet = getActiveItemIndex(keyCode);
      setFocusedItemIndex(itemIndexToSet);
      setItemIndexToFocus(itemIndexToSet);
    }

    if (keyCode === KeyCode.Enter) {
      inputRef!.current!.focus();
      if (onSelect && matchingOptions[focusedItemIndex]) {
        onSelect(matchingOptions[focusedItemIndex].value);
      }
    }

    handleInputKeyUp(event);
  };

  const onInputBlur = (event: EventTarget | null) => {
    if (isBlurRequired && !isBlurRequired(event)) {
      return;
    }

    handleInputBlur();
    setIsOpen(false);
    setHasFocus(false);
  };

  const handleOptionSelect = ({ value: valueToSelect }: OptionType) => {
    if (selectedOptions.includes(valueToSelect)) {
      return;
    }

    if (hideDropdownOnSelect) {
      setIsOpen(false);
    }

    if (onSelect) {
      onSelect(valueToSelect);
    }
    inputRef!.current!.focus();
  };

  const getOption = (value: string) => {
    return options.find(({ value: optionValue }) => optionValue === value);
  };

  const renderInputIcon = () => {
    if (!isOpen) {
      return <DropdownImage />;
    }

    return !hideSearchIcon && <SelectInputSearchImage />;
  };

  const renderContent = () => (
    <DropdownContent
      ref={dropdownRef}
      autoscroll
      options={matchingOptions}
      itemIndexToFocus={itemIndexToFocus}
      setFocusedItemIndex={setFocusedItemIndex}
      handleSelect={handleOptionSelect}
      selectedValue={selectedOptions}
      focusedItemIndex={focusedItemIndex}
      isLabelTag={isLabelTags}
    />
  );

  return (
    <div className={clsx(styles.inputContainer, containerClassName)}>
      <label htmlFor={id} className={styles.label}>
        {labelTitle}
      </label>
      {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
      <ClickAwayListener onClickAway={(_event: React.MouseEvent<Document>) => onInputBlur(null)}>
        <WrapperWithTooltip tooltip={tooltip}>
          <div id={id} className={clsx(styles.selectContainer, selectContainerClassName)}>
            <label
              ref={labelRef}
              className={`${disabled ? styles.disabledSelectControl : styles.selectControl} ${hasFocus &&
                styles.inputFocus}`}
            >
              <div
                className={clsx(
                  styles.optionsContainer,
                  isLabelTags && styles.labelsContainer,
                  isLabelTags && isOpen && styles.labelsContainerWithInput,
                )}
              >
                {selectedOptions.map((optionValue, index) => {
                  const option = getOption(optionValue);
                  const optionName = option ? option.name : optionValue;

                  return (
                    <Option
                      color={option?.color}
                      className={optionClassName}
                      value={optionName}
                      key={index}
                      onDeleteOption={() => onDeleteOption(optionValue)}
                      disabled={disabled}
                      renderOptionPrefix={renderInputOptionPrefix && (() => renderInputOptionPrefix(optionValue))}
                      isLabelTag={isLabelTags}
                    />
                  );
                })}
              </div>
              <CustomBlurInput
                type="text"
                ref={inputRef}
                value={inputValue}
                placeholder={placeholder}
                disabled={disabled}
                onFocus={() => setHasFocus(true)}
                onClick={() => setIsOpen((previousIsOpen) => !previousIsOpen)}
                onChange={onInputChange}
                onKeyUp={onInputKeyUp}
                onCustomBlur={onInputBlur}
                className={clsx(!isOpen && styles.emptyInput)}
              />
              {renderInputIcon()}
            </label>
            {isOpen && (
              <DropdownContentWithPortal dropdownRef={dropdownRef} handlerRef={labelRef}>
                {children
                  ? cloneElement<{ ref: RefObject<HTMLDivElement> }>(children as React.ReactElement, {
                      ref: dropdownRef,
                    })
                  : renderContent()}
              </DropdownContentWithPortal>
            )}
          </div>
        </WrapperWithTooltip>
      </ClickAwayListener>
      {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default MultipleOptionsSelect;
