import deepAssign from 'utils/deepAssign';
import { AnyAction } from 'redux';

interface ActionWithOriginMeta<Origin> {
  actionOrigin: Origin;
}

export interface ActionWithOrigin<
  Origin = string,
  Meta extends ActionWithOriginMeta<Origin> = ActionWithOriginMeta<Origin>
> extends AnyAction {
  meta?: Meta;
}

const isActionWithOrigin = <Origin>(action: AnyAction): action is ActionWithOrigin<Origin> =>
  Boolean(action.meta?.actionOrigin);

export const setActionOrigin = <Action extends AnyAction, Origin = string>(
  action: Action,
  actionOrigin: Origin,
): Action => {
  return deepAssign(action, ['meta'], { actionOrigin });
};

export const traceActionOrigin = <TargetAction extends ActionWithOrigin<Origin>, Origin = string>(
  sourceAction: AnyAction,
  targetAction: TargetAction,
) => {
  if (!isActionWithOrigin<Origin>(sourceAction)) {
    return targetAction;
  }
  const { actionOrigin } = sourceAction.meta!;
  return setActionOrigin(targetAction, actionOrigin);
};
