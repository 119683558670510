import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { IndividualProcessingHistoryCase } from 'api/Types';

// The backend always sends 10 records regardless of the limit parameter
export const HISTORY_CASES_PER_PAGE_DEFAULT = 10;

const individualHistoryPagination = Pagination<
  ReduxState,
  IndividualProcessingHistoryCase,
  { searchInputValue: string }
>(['individualProcessing', 'history'], HISTORY_CASES_PER_PAGE_DEFAULT, ({ searchInputValue }) =>
  searchInputValue ? ['searched', searchInputValue] : [],
);

export default individualHistoryPagination;
