import { LoanOriginationSystemCreateIntermediaryState } from './Types';
import { CreateIntermediaryAction, CreateIntermediarySuccessAction } from './ActionCreator';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemCreateIntermediaryTypes } from './ActionTypes';

export const initialState: LoanOriginationSystemCreateIntermediaryState = {
  isCreating: false,
};

export type LoanOriginationSystemCreateIntermediaryAction =
  | CreateIntermediaryAction
  | CreateIntermediarySuccessAction
  | FailedAction<LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediaryFailure>;

export const loanOriginationSystemCreateIntermediaryReducer = (
  state: LoanOriginationSystemCreateIntermediaryState = initialState,
  action: LoanOriginationSystemCreateIntermediaryAction,
): LoanOriginationSystemCreateIntermediaryState => {
  switch (action.type) {
    case LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediary:
      return {
        ...state,
        isCreating: true,
      };
    case LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediarySuccess:
      return {
        ...state,
        isCreating: false,
      };
    case LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediaryFailure:
      return {
        ...state,
        isCreating: false,
      };
    default: {
      return state;
    }
  }
};
