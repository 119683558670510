import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemOrganizationActionType } from './ActionTypes';
import { GetStandardVariablesAction, getStandardVariablesSuccess, getStandardVariablesFailure } from './ActionCreator';
import { VariablesApi } from 'api/Core/VariablesApi';
import { Variable } from 'Variables/VariablesTypes';

const GetStandardVariablesMiddleware = (api: VariablesApi) =>
  Fetcher<Variable[], GetStandardVariablesAction>(
    LoanOriginationSystemOrganizationActionType.GetStandardVariables,
    getStandardVariablesSuccess,
    getStandardVariablesFailure,
    async () => {
      return api.getStandardVariables();
    },
  );

export default GetStandardVariablesMiddleware;
