import React, { FC } from 'react';

import PopUpWizard from 'components/PopUps/PopUpWizard';
import GenerateFirstNewSecretPassword from 'components/DigifiApi/PopUps/GenerateFirstNewSecretPassword';
import GenerateFirstNewSecret from 'components/DigifiApi/PopUps/GenerateFirstNewSecret';

interface GenerateFirstSecretWizardProps {
  step: number;
  closePopUp: () => void;
  title: string;
  verifyPassword: (password: string) => Promise<unknown>;
  generateFirstSecret: () => Promise<unknown>;
}

export const WARNING_TEXT_GENERATE_SECRET =
  'Changing your secret will result in an immediate change to the system and the current secret will no longer be active.';
export const STEP_NUMBER_GENERATE_SECRET_POP_UP = 1;

const GenerateFirstSecretWizard: FC<GenerateFirstSecretWizardProps> = (props) => {
  const { step, closePopUp, title, verifyPassword, generateFirstSecret } = props;

  return (
    <PopUpWizard
      step={step}
      closePopUp={closePopUp}
      title={title}
      warningText={step === STEP_NUMBER_GENERATE_SECRET_POP_UP ? WARNING_TEXT_GENERATE_SECRET : ''}
    >
      <GenerateFirstNewSecretPassword verifyPassword={verifyPassword} />
      <GenerateFirstNewSecret closePopUp={closePopUp} generateFirstSecret={generateFirstSecret} />
    </PopUpWizard>
  );
};

export default GenerateFirstSecretWizard;
