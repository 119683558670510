import { OPEN_DELETE_STRATEGY_WIZARD, CLOSE_DELETE_STRATEGY_WIZARD } from './constants';
import { StrategyInfo } from 'DecisionStrategy/DeleteDecisionStrategyMiddleware';
import { StrategyActionOrigin } from 'DecisionStrategy/DecisionStrategiesActionCreator';

export const openDeleteStrategyWizard = (
  type: 'strategy' | 'version',
  strategyInfo: StrategyInfo,
  actionOrigin: StrategyActionOrigin,
  isCurrent?: boolean,
) => {
  return {
    type: OPEN_DELETE_STRATEGY_WIZARD,
    payload: {
      strategyInfo,
      isCurrent,
      type,
    },
    meta: { actionOrigin },
  };
};

export const closeDeleteStrategyWizard = () => {
  return {
    type: CLOSE_DELETE_STRATEGY_WIZARD,
  };
};
