import React from 'react';
import StatusHeader from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/StatusHeader';
import styles from './ColumnHeader.module.scss';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

interface ColumnHeaderProps {
  name: string;
  currencySymbol: string;
  applications: Application[] | null;
}

const ColumnHeader = ({ name, applications, currencySymbol }: ColumnHeaderProps) => {
  return (
    <div className={styles.header}>
      <StatusHeader title={name} applications={applications} currencySymbol={currencySymbol} />
    </div>
  );
};

export default ColumnHeader;
