import { AnyAction } from 'redux';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './ActionTypes';

export interface FiltersResult {
  borrower: string | null;
  createdAtRange: {
    from: Date | null;
    to: Date | null;
  };
}

export interface SetSearchInputValueAction extends AnyAction {
  type: LoanOriginationSystemEmailsFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchInputValue: string;
    borrowerId?: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemEmailsFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface ResetAllEmailsFilters extends AnyAction {
  type: LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters;
}

export const setSearchInputValue = (searchInputValue: string, borrowerId?: string): SetSearchInputValueAction => {
  return {
    type: LoanOriginationSystemEmailsFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchInputValue,
      borrowerId,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemEmailsFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemEmailsFiltersActionTypes.ChangePopupFilters,
    payload: {
      createdAtRange: {
        from: null,
        to: null,
      },
      borrower: null,
    },
  };
};

export const resetAllEmailsFilters = (): ResetAllEmailsFilters => {
  return {
    type: LoanOriginationSystemEmailsFiltersActionTypes.ResetAllFilters,
  };
};
