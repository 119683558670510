import { AnyAction, MiddlewareAPI } from 'redux';
import tap from 'lodash/tap';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import { RequestError } from 'utils/fetch';

const isFailedAction = (action: AnyAction): action is FailedAction => {
  /**
   * https://github.com/redux-utilities/flux-standard-action#error
   * The optional error property MAY be set to true if the action represents an error.
   * An action whose error is true is analogous to a rejected Promise. By convention, the payload SHOULD be an error object.
   * If error has any other value besides true, including undefined and null, the action MUST NOT be interpreted as an error.
   */
  return action.error === true;
};

const RESPONSE_STATUS_EXCEPTIONS = [401];

const errorNotificationsMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (
  action: AnyAction,
) =>
  tap(next(action), () => {
    if (!isFailedAction(action)) {
      return;
    }

    const { message, responseStatus } = action.payload as RequestError;

    if (!RESPONSE_STATUS_EXCEPTIONS.includes(responseStatus)) {
      notification.createNotification(message, 'error', dispatch);
    }
  });

export default errorNotificationsMiddleware;
