import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { StrategyUpdateType } from 'DecisionStrategyUpdates/DecisionStrategiesTypes';

export const STRATEGY_UPDATES_PER_PAGE_DEFAULT = 5;

interface PaginationParams {
  strategyId: string;
}

export const decisionStrategyUpdatesPagination = Pagination<ReduxState, StrategyUpdateType, PaginationParams>(
  'decisionStrategyUpdates',
  STRATEGY_UPDATES_PER_PAGE_DEFAULT,
  ({ strategyId }) => [strategyId],
);
