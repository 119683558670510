import React, { FC } from 'react';
import Button from 'components/Button';
import { EmptyDE } from 'static/images';
import styles from './RestrictPermissions.module.scss';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'routes/RouteService';

export interface RestrictPermissionsProps {
  pageName: string;
}

const RestrictPermissions: FC<RestrictPermissionsProps> = ({ pageName }) => {
  const history = useHistory();

  const handleGoToDashboard = () => {
    history.push(AppRoutes.Home);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.restrictPermissionsContainer}>
      <h3 className={styles.restrictPermissionsHeader}>{pageName}</h3>
      <div className={styles.restrictPermissionsBody}>
        <EmptyDE />
        <h6 className={styles.title}>You don’t have access to this page</h6>
        <p className={styles.description}>
          You do not have the required permissions to access {pageName}. <br />
          Please contact your account owner to request access.
        </p>
        <div className={styles.buttonContainer}>
          <Button kind="primary" size="form" className={styles.restrictPermissionsButton} onClick={handleGoToDashboard}>
            Go to Dashboard
          </Button>
          <Button className={styles.goBackButton} size="form" onClick={handleGoBack}>
            Go back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestrictPermissions;
