import React, { FC } from 'react';
import { FormLayoutData, VariableConfiguration } from 'api/LoanOriginationSystem/Types';
import ExistingApplicationDetailsForm from './ExistingApplicationDetailsForm';
import NewApplicationDetailsForm from './NewApplicationDetailsForm';

interface ApplicationDetailsFormProps {
  configurations: VariableConfiguration[] | null;
  formData: FormLayoutData;
  onFormDataChange: (formData: FormLayoutData) => void;
  onProfileEdit?: () => void;
  isEditMode?: boolean;
}

const ApplicationDetailsForm: FC<ApplicationDetailsFormProps> = ({
  formData,
  onFormDataChange,
  configurations,
  onProfileEdit,
  isEditMode,
}) => {
  if (isEditMode) {
    return (
      <ExistingApplicationDetailsForm
        configurations={configurations}
        formData={formData}
        onProfileEdit={onProfileEdit}
      />
    );
  }

  return (
    <NewApplicationDetailsForm
      configurations={configurations}
      formData={formData}
      onFormDataChange={onFormDataChange}
    />
  );
};

export default ApplicationDetailsForm;
