import React, { FC } from 'react';
import { isEqual } from 'lodash';
import SearchInput from 'components/SearchInput';
import TemplatesTable from 'components/SendGrid/TemplatesTable';
import { SendGridTemplate } from 'SendGrid/Types';
import Pagination from 'components/Pagination';
import { NullableItems, PaginationProps } from 'pagination';
import { sortTemplates } from 'SendGrid/ActionCreator';
import { initialState as filtersInitialState } from 'SendGrid/Filters/Reducer';
import { NoResultsIcon } from 'static/images';
import { useDispatch } from 'react-redux';
import {
  SendGridTemplatesSortingField,
  SendGridTemplatesSortingType,
} from 'api/LoanOriginationSystem/SendGridTemplateApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { LoanOriginationSystemTemplatesFiltersState } from 'SendGrid/Filters/Types';
import { filterTemplatesByMembers, resetAllTemplatesFilters, toggleFiltersPopup } from 'SendGrid/Filters/ActionCreator';
import FilterButton from 'components/FilterButton';
import NoItems from 'components/NoItems';
import SearchNotFound from 'components/SearchNotFound';
import styles from './Templates.module.scss';

interface SendGridTemplatesProps {
  templates: NullableItems<SendGridTemplate>;
  paginationProps: PaginationProps;
  onDeletePopupOpen: (id: string) => void;
  onSearchInputChange: (value: string) => void;
  searchInputValue: string;
  sortingType: SendGridTemplatesSortingType;
  openEditTemplateContextualView: (id: string) => void;
  selectedMembers: UserInfo[];
  filters: LoanOriginationSystemTemplatesFiltersState;
}

const SendGridTemplates: FC<SendGridTemplatesProps> = ({
  templates,
  paginationProps,
  onDeletePopupOpen,
  onSearchInputChange,
  searchInputValue,
  sortingType,
  openEditTemplateContextualView,
  selectedMembers,
  filters,
}) => {
  const dispatch = useDispatch();
  const organizationMembers = useOrganizationMembers();

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterTemplatesByMembers(newSelectedMembers));
  };

  const handleSort = (field: SendGridTemplatesSortingField, ascending: boolean) => {
    dispatch(sortTemplates({ field, ascending }));
  };

  const handleOpenFiltersPopUp = () => {
    dispatch(toggleFiltersPopup());
  };

  const handleResetAllFilters = () => {
    dispatch(resetAllTemplatesFilters());
  };

  const areFiltersChanged = () => {
    return (
      filters.dueCreatedDateRange.from !== null ||
      filters.dueCreatedDateRange.to !== null ||
      filters.dueUpdatedDateRange.from !== null ||
      filters.dueUpdatedDateRange.to !== null ||
      filters.active !== null
    );
  };

  const renderTemplateTableBody = () => {
    if (!templates.length && filters.searchInputValue) {
      return <SearchNotFound searchValue={filters.searchInputValue} />;
    }

    if (!templates.length && !isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          className={styles.noFiltersContainer}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noFiltersSubtitle}
          onButtonClick={handleResetAllFilters}
        />
      );
    }

    return (
      <>
        <TemplatesTable
          source={templates}
          searchInputValue={searchInputValue}
          sortingType={sortingType}
          onSort={handleSort}
          onDeletePopupOpen={onDeletePopupOpen}
          openEditTemplateContextualView={openEditTemplateContextualView}
        />
        <Pagination {...paginationProps} />
      </>
    );
  };

  return (
    <div>
      <div className={styles.header}>
        <div className={styles.filtersContainer}>
          <SearchInput
            value={searchInputValue}
            onChange={({ target }) => onSearchInputChange(target.value)}
            onClear={() => onSearchInputChange('')}
            placeholder="Search"
          />
          <UserFilter
            className={styles.userFilter}
            users={organizationMembers}
            selectedUsers={selectedMembers}
            onChange={onMemberFilterChange}
          />
        </div>
        <FilterButton onClick={handleOpenFiltersPopUp} active={areFiltersChanged()} />
      </div>
      {renderTemplateTableBody()}
    </div>
  );
};

export default SendGridTemplates;
