import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { History } from 'history';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { DeleteLabelSuccessAction, getLabels } from './ActionCreator';

const isDeleteLabelSuccessAction = (action: AnyAction): action is DeleteLabelSuccessAction =>
  action.type === LoanOriginationSystemLabelsActionTypes.DeleteLabelSuccess;

const DeleteLabelSuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteLabelSuccessAction(action)) {
    replace(`/los/settings/applicationlabels`);

    const { loanOriginationSystemLabelsState } = getState();

    const { filters, sortingType } = loanOriginationSystemLabelsState;

    notification.createNotification(
      getMessage(MessageType.LabelDeleted, {
        labelName: action.payload.name,
      }),
      'success',
      dispatch,
    );

    dispatch(getLabels({ filters, sortingType }));
  }

  return result;
};

export default DeleteLabelSuccessMiddleware;
