import { DecisionEngineApi, BranchIdentityParams, BranchItemInfo } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import {
  CopyBranchRulesAction,
  copyBranchRulesFailure,
  copyBranchRulesSuccess,
  GetBranchOptionsAction,
  getBranchOptionsFailure,
  getBranchOptionsSuccess,
} from './Actions';
import { COPY_BRANCH_RULES, GET_BRANCH_OPTIONS } from './ActionTypes';

export const GetBranchOptionsMiddleware = (api: DecisionEngineApi) =>
  Fetcher<BranchItemInfo[], GetBranchOptionsAction>(
    GET_BRANCH_OPTIONS,
    getBranchOptionsSuccess,
    getBranchOptionsFailure,
    ({ payload }) => api.getBranchOptions(payload),
  );

export const CopyModuleBranchRulesMiddleware = (api: DecisionEngineApi) =>
  Fetcher<BranchIdentityParams, CopyBranchRulesAction>(
    COPY_BRANCH_RULES,
    copyBranchRulesSuccess,
    copyBranchRulesFailure,
    async ({ payload }) => {
      const { copyFromBranchPrams, copyToBranchParams, ruleType } = payload;
      await api.copyBranchRules(ruleType, copyToBranchParams, copyFromBranchPrams);
      return copyToBranchParams;
    },
  );
