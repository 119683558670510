import React from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import useSorting from 'hooks/useSorting';
import { useHistory } from 'react-router';
import Table, { TableActionCell } from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import ApplicationTableRow from './ApplicationTableRow';
import Pagination from 'components/Pagination/Pagination';
import SkeletonApplicationTableBody from './SkeletonApplicationTableBoby';
import TableWrapperWithFooter from 'components/Table/TableWrapperWithFooter';
import { ApplicationsTableSizes } from './TableColumnSizes';
import styles from './ApplicationsTable.module.scss';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationSortingField, ApplicationsSortingType } from 'api/Types';
import { StandardVariables } from 'Variables/VariablesTypes';

export interface ApplicationsTableProps {
  source: NullableItems<Application>;
  currencySymbol: string | null;
  paginationProps: PaginationProps;
  standardVariables: StandardVariables | null;
  deleteDisabled: boolean;
  sortingType: ApplicationsSortingType;
  onSort: (field: ApplicationSortingField, ascending: boolean) => void;
  onDeleteApplication?: (application: Application) => void;
  onEditApplication?: (application: Application) => void;
  onDuplicateApplication?: (application: Application) => void;
}

const ApplicationsTable = ({
  source,
  currencySymbol,
  paginationProps,
  sortingType,
  standardVariables,
  deleteDisabled,
  onSort,
  onDeleteApplication,
  onEditApplication,
  onDuplicateApplication,
}: ApplicationsTableProps) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);
  const history = useHistory();

  const onClick = (application: Application) => {
    history.push(`/los/applications/${application.displayId}`);
  };

  const getApplicationTableBody = () => {
    return source.map((application, index) =>
      application && currencySymbol ? (
        <ApplicationTableRow
          standardVariables={standardVariables}
          key={application.id}
          application={application}
          currencySymbol={currencySymbol}
          deleteDisabled={deleteDisabled}
          onClick={onClick}
          onEdit={onEditApplication}
          onDelete={onDeleteApplication}
          onDuplicate={onDuplicateApplication}
        />
      ) : (
        <SkeletonApplicationTableBody key={index} />
      ),
    );
  };

  return (
    <TableWrapperWithFooter>
      <div className={styles.table}>
        <Table>
          <TableHead sticky useActions>
            <TableHeadCell
              width={ApplicationsTableSizes.BorrowerFullName}
              ascending={getSortingType(ApplicationSortingField.BorrowerFullName)}
              onClick={() => changeSorting(ApplicationSortingField.BorrowerFullName)}
            >
              Borrower
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableSizes.DisplayId}
              ascending={getSortingType(ApplicationSortingField.DisplayId)}
              onClick={() => changeSorting(ApplicationSortingField.DisplayId)}
            >
              Application ID
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableSizes.CreatedAt}
              ascending={getSortingType(ApplicationSortingField.CreatedAt)}
              onClick={() => changeSorting(ApplicationSortingField.CreatedAt)}
            >
              Application Date
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableSizes.Status}
              ascending={getSortingType(ApplicationSortingField.Status)}
              onClick={() => changeSorting(ApplicationSortingField.Status)}
            >
              Status
            </TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableSizes.BorrowerPhoneNumber}
              ascending={getSortingType(ApplicationSortingField.BorrowerPhoneNumber)}
              onClick={() => changeSorting(ApplicationSortingField.BorrowerPhoneNumber)}
            >
              Phone
            </TableHeadCell>
            <TableHeadCell width={ApplicationsTableSizes.TeamMembers}>Team Members</TableHeadCell>
            <TableHeadCell
              width={ApplicationsTableSizes.LoanAmount}
              className={styles.totalColumnHeadCell}
              ascending={getSortingType(ApplicationSortingField.LoanAmount)}
              onClick={() => changeSorting(ApplicationSortingField.LoanAmount)}
            >
              Loan Amount
            </TableHeadCell>
            <TableActionCell />
          </TableHead>
          <TableBody>{getApplicationTableBody()}</TableBody>
        </Table>
        <Pagination {...paginationProps} />
      </div>
    </TableWrapperWithFooter>
  );
};

export default ApplicationsTable;
