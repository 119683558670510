import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { DocuSignLogo, FolderImage, ShareImageCustomColor } from 'static/images';
import { ReduxState } from 'types/redux';
import { getDocuSignIntegration } from 'ESignIntegrations/ActionCreator';
import { updateProduct, UpdateProductActionOrigin } from 'LoanOriginationSystemProducts/ActionCreator';
import SuccessTab, {
  SuccessTabProps,
} from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationSetup/Tabs/SuccessTab/SuccessTab';
import StyledSwitch from 'components/StyledSwitch';
import Button from 'components/Button';
import styles from './DocumentsTab.module.scss';

export type DocumentsTabProps = Omit<SuccessTabProps, 'tabType' | 'icon'> & {
  product: Product;
};

const DocumentsTab = ({ product, ...restProps }: DocumentsTabProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { docuSignIntegration, isDocuSignIntegrationLoaded } = useSelector(
    (state: ReduxState) => state.eSignIntegrations,
  );

  useEffect(() => {
    dispatch(getDocuSignIntegration());
  }, []);

  const handleDocuSignEnabledChange = (checked: boolean) => {
    dispatch(
      setActionOrigin(
        updateProduct(product.id, { settings: { docuSignEnabled: checked } }),
        UpdateProductActionOrigin.ConfigurationDocumentsTab,
      ),
    );
  };

  const handleOpenDocuSignSettingsClick = () => {
    history.push('/company-settings/docu-sign/credentials');
  };

  return (
    <div className={styles.container}>
      <SuccessTab {...restProps} className={styles.successTab} icon={<FolderImage />} tabType="Documents" />
      <div className={styles.configurationContainer}>
        <h5 className={styles.configurationTitle}>Electronic Signature</h5>
        <div className={styles.docuSignSwitchContainer}>
          <StyledSwitch
            disabled={!docuSignIntegration}
            color="primary"
            checked={product.settings.docuSignEnabled}
            className={styles.switchButton}
            onChange={(event, checked) => handleDocuSignEnabledChange(checked)}
          />
          <DocuSignLogo />
        </div>
        <div className={styles.informationSection}>
          {!docuSignIntegration && isDocuSignIntegrationLoaded && (
            <p>Please add your DocuSign credentials to activate.</p>
          )}
          <Button
            onClick={handleOpenDocuSignSettingsClick}
            size="form"
            className={styles.openDocuSignSettingsButton}
            kind="secondary"
          >
            <ShareImageCustomColor className={styles.shareImage} />
            Open DocuSign Settings
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentsTab;
