import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';
import { Droppable, Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { NullableItems } from 'pagination';
import styles from 'components/DndList/DndList.module.scss';
import useDraggableInPortal from 'hooks/useDraggableInPortal';
import DndListPlaceholder from 'components/DndList/DndListPlaceholder';

export interface DroppableListItemProps<Item> {
  droppableId: string;
  items: NullableItems<Item>;
  renderListItem: (
    item: Item,
    index: number,
    snapshot: DraggableStateSnapshot,
    placeholderStyles?: CSSProperties | null,
  ) => ReactNode;
  renderInPortal?: boolean;
  placeholderStyles?: React.CSSProperties | null;
  listItemClassName?: string;
  listClassName?: string;
  placeholderClassName?: string;
  withPlaceholder?: boolean;
  isDropDisabled?: boolean;
  isDragDisabled?: boolean | ((item: Item, index: number) => boolean);
  droppableType?: string;
  direction?: 'vertical' | 'horizontal';
  renderSkeletonItem?: (index: number) => ReactNode;
  getDraggableId?: (itemId: string | number, droppableId: string) => string;
}

const DroppableList = <Item extends { id: string | number }>({
  droppableId,
  renderListItem,
  items,
  renderInPortal,
  withPlaceholder,
  listItemClassName,
  listClassName,
  placeholderStyles,
  isDragDisabled,
  droppableType,
  getDraggableId,
  isDropDisabled,
  renderSkeletonItem,
  direction = 'vertical',
  placeholderClassName,
}: DroppableListItemProps<Item>) => {
  const renderDraggableInPortal = useDraggableInPortal();

  const renderItemContainer = (
    draggableProvided: DraggableProvided,
    item: Item,
    index: number,
    draggableSnapshot: DraggableStateSnapshot,
  ) => {
    const listItemClasses = clsx(listItemClassName, styles.draggableItem, {
      [styles.draggableItem__dragging]: draggableSnapshot.isDragging,
    });

    return (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        className={listItemClasses}
      >
        {renderListItem(item, index, draggableSnapshot, placeholderStyles)}
      </div>
    );
  };

  const renderDraggableContent = (item: Item, index: number) => {
    return renderInPortal
      ? renderDraggableInPortal((draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) =>
          renderItemContainer(draggableProvided, item, index, draggableSnapshot),
        )
      : (draggableProvided: DraggableProvided, draggableSnapshot: DraggableStateSnapshot) =>
          renderItemContainer(draggableProvided, item, index, draggableSnapshot);
  };

  const renderPlaceholder = () => {
    return withPlaceholder && <DndListPlaceholder className={placeholderClassName} style={placeholderStyles!} />;
  };

  const containerClasses = clsx(
    styles.listContainer,
    { [styles.vertical]: direction === 'vertical', [styles.horizontal]: direction === 'horizontal' },
    listClassName,
  );

  return (
    <Droppable
      direction={direction}
      droppableId={droppableId}
      type={droppableType || droppableId}
      isDropDisabled={isDropDisabled}
    >
      {(droppableProvided, snapshot) => (
        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps} className={containerClasses}>
          {items.map((item: Item | null, index: number) => {
            if (!item) {
              return renderSkeletonItem ? renderSkeletonItem(index) : null;
            }

            return (
              <Draggable
                isDragDisabled={typeof isDragDisabled === 'function' ? isDragDisabled(item, index) : isDragDisabled}
                key={item.id}
                draggableId={getDraggableId ? getDraggableId(item.id, droppableId) : `item-${item.id}`}
                index={index}
              >
                {renderDraggableContent(item, index)}
              </Draggable>
            );
          })}
          {droppableProvided.placeholder}
          {placeholderStyles && snapshot.isDraggingOver && renderPlaceholder()}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableList;
