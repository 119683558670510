import React, { FC } from 'react';
import { ESignTemplatesSortingType, ESignTemplatesSortingField, ESignTemplate } from 'api/Core/ESignTemplatesApi';
import useSorting from 'hooks/useSorting';
import TableColumnSizes from 'components/DocuSign/TemplatesTable/TableColumnSizes';
import Table, { TableBodyContent } from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableActionCell from 'components/Table/TableActionCell';
import Pagination from 'components/Pagination';
import TemplatesTableRow from './TemplateTableRow';
import SkeletonTemplateItem from './SkeletonTemplateItem';
import { NullableItems, PaginationProps } from 'pagination';
import styles from './TemplatesTable.module.scss';

interface TableProps {
  source: NullableItems<ESignTemplate>;
  searchInputValue: string;
  sortingType: ESignTemplatesSortingType;
  onSort: (field: ESignTemplatesSortingField, ascending: boolean) => void;
  onTemplateDelete: (id: string) => void;
  onTemplateEdit: (id: string) => void;
  paginationProps: PaginationProps;
}

const TemplatesTable: FC<TableProps> = ({
  source,
  searchInputValue,
  onTemplateDelete,
  onTemplateEdit,
  paginationProps,
  sortingType,
  onSort,
}) => {
  const [changeSorting, getSortingType] = useSorting(sortingType.field, sortingType.ascending, onSort);

  const renderTableRow = (template: ESignTemplate, index: number) => (
    <TemplatesTableRow
      key={index}
      template={template}
      searchInputValue={searchInputValue}
      onTemplateDelete={onTemplateDelete}
      onTemplateEdit={onTemplateEdit}
    />
  );

  return (
    <div className={styles.table}>
      <Table>
        <TableHead sticky useActions>
          <TableHeadCell
            width={TableColumnSizes.Name}
            ascending={getSortingType(ESignTemplatesSortingField.Name)}
            onClick={() => changeSorting(ESignTemplatesSortingField.Name)}
          >
            Template Name
          </TableHeadCell>
          <TableHeadCell
            width={TableColumnSizes.UpdatedAt}
            ascending={getSortingType(ESignTemplatesSortingField.UpdatedAt)}
            onClick={() => changeSorting(ESignTemplatesSortingField.UpdatedAt)}
          >
            Updated
          </TableHeadCell>
          <TableHeadCell width={TableColumnSizes.Status}>Status</TableHeadCell>
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            renderSkeletonTableRow={(index) => <SkeletonTemplateItem key={index} />}
            renderTableRow={renderTableRow}
            rows={source}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </div>
  );
};

export default TemplatesTable;
