import React, { ChangeEvent, FocusEvent } from 'react';
import TextInput from 'components/TextInput/TextInput';
import {
  DateWithAttributes,
  IdentificationNumberWithAttributes,
  ListWithAttributes,
  MonetaryWithAttributes,
  PhoneNumberWithAttributes,
  UndefinedVisualDataTypeWithNoAttributes,
  VisualDataTypeWithAttributes,
} from 'Variables/VariablesTypes';
import DatePicker from 'components/DatePicker';
import AutoCompletion from 'components/AutoCompletion';
import MonetaryInput from 'components/MonetaryInput';
import PercentageInput from 'components/PercentageInput';
import NumberInput from 'components/NumberInput';
import { Option } from 'components/SelectInput/SelectInput';
import PhoneNumberInput from 'components/PhoneNumberInput/PhoneNumberInput';
import IdentificationNumberInput from 'components/IdentificationNumberInput';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import EmailInput from 'components/EmailInput';
import useInputValidationMessage from 'hooks/useInputValidationMessage';
import styles from './InputWithDataType.module.scss';

interface InputWithDataTypeProps {
  value: string;
  options: Option[] | null;
  labelTitle: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabledValidation?: boolean;
  showLoader?: boolean;
  disableResetValueOnError?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
  hasLeftNeighbour?: boolean;
  containerClassName?: string;
}

const BOOLEAN_VALUE_DROPDOWN_OPTIONS = [
  { name: 'True', value: 'true' },
  { name: 'False', value: 'false' },
];

export const isPhoneNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is PhoneNumberWithAttributes => props.dataType === 'PhoneNumber';

export const isIdentificationNumber = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is IdentificationNumberWithAttributes => props.dataType === 'IdentificationNumber';

export const isMonetaryDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is MonetaryWithAttributes => props.dataType === 'Monetary';

export const isDateDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is DateWithAttributes => props.dataType === 'Date';

export const isListDataType = (
  props: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
): props is ListWithAttributes => props.dataType === 'List';

const InputWithDataType = (props: InputWithDataTypeProps & VisualDataTypeWithAttributes) => {
  const {
    dataType,
    value,
    labelTitle,
    onChange,
    onFocus,
    onBlur,
    disabled,
    required,
    showLoader,
    loaderState,
    onLoaderStateReset,
    disabledValidation,
    disableResetValueOnError,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
  } = props;
  const handleChange = ({ value: booleanValue }: Option) => onChange(booleanValue);
  const [handleBlur, handleFocus, errorMessage] = useInputValidationMessage(
    props,
    value,
    onChange,
    onFocus,
    onBlur,
    required,
    disabledValidation,
    disableResetValueOnError,
  );

  const commonInputProps = {
    value,
    labelTitle,
    disabled,
    onBlur,
    onChange,
    showLoader,
    loaderState,
    onLoaderStateReset,
    tabIndex,
    hasLeftNeighbour,
    containerClassName,
  };

  if (isPhoneNumber(props)) {
    const { phoneNumberFormat, phoneNumberWithFlag } = props;
    return (
      <PhoneNumberInput
        {...commonInputProps}
        withFlag={phoneNumberWithFlag}
        country={phoneNumberFormat}
        onFocus={handleFocus}
        onBlur={handleBlur}
        errorMessage={errorMessage}
      />
    );
  }

  if (isIdentificationNumber(props)) {
    const { identificationNumberType, identificationNumberDescription } = props;
    return (
      <IdentificationNumberInput
        {...commonInputProps}
        onFocus={handleFocus}
        onBlur={handleBlur}
        identificationNumberType={identificationNumberType!}
        identificationNumberDescription={identificationNumberDescription}
        errorMessage={errorMessage}
      />
    );
  }

  if (isMonetaryDataType(props)) {
    const { currency } = props;
    const currencySymbol = getCurrencySymbol(currency!);
    return <MonetaryInput {...commonInputProps} placeholder={dataType} currencySymbol={currencySymbol} />;
  }

  if (isDateDataType(props)) {
    const { dateFormat } = props;
    return <DatePicker {...commonInputProps} dateFormat={dateFormat!} onFocus={onFocus} />;
  }

  if (isListDataType(props)) {
    const selectedOption = value
      ? {
          name: value,
          value,
        }
      : undefined;

    const formattedOptionsList = props.optionsList.map((option) => ({
      name: option,
      value: option,
    }));

    return (
      <AutoCompletion
        {...commonInputProps}
        selectedOption={selectedOption}
        options={formattedOptionsList || []}
        onChange={handleChange}
        onFocus={onFocus}
        placeholder={dataType}
        hasRightNeighbour
      />
    );
  }
  switch (dataType) {
    case 'Percentage':
      return <PercentageInput {...commonInputProps} placeholder={dataType} />;
    case 'Number':
      return (
        <NumberInput
          {...commonInputProps}
          onFocus={onFocus}
          onBlur={(_value, event) => onBlur?.(event)}
          placeholder={dataType}
        />
      );
    case 'Boolean':
      return (
        <AutoCompletion
          {...commonInputProps}
          options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={dataType}
          hasRightNeighbour
        />
      );
    case 'EmailAddress':
      return <EmailInput {...commonInputProps} onFocus={handleFocus} onBlur={handleBlur} errorMessage={errorMessage} />;
    default:
      return (
        <div className={styles.container}>
          <TextInput
            {...commonInputProps}
            onChange={({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.value)}
            hasRightNeighbour
            placeholder={dataType}
            onFocus={onFocus}
          />
        </div>
      );
  }
};

export default InputWithDataType;
