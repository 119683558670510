import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import styles from './VerificationLinkExpired.module.scss';
import { ErrorInSystemImage } from 'static/images';
import Button from 'components/Button';
import useLoadingState from 'hooks/useLoadingState';
import useTimer from 'hooks/useTimer';

const TIMER_TIMEOUT = 60;

interface VerificationLinkExpiredProps {
  onClickResendEmail: () => Promise<void>;
}

const VerificationLinkExpired: FC<VerificationLinkExpiredProps> = ({ onClickResendEmail }) => {
  const [isLoading, onClick] = useLoadingState(onClickResendEmail);
  const { seconds, restart, isRunning } = useTimer(TIMER_TIMEOUT, { autoStart: false });

  const resendInSeconds = isRunning ? seconds : 0;

  const handleResendEmail = async () => {
    try {
      await onClick();
    } finally {
      restart();
    }
  };

  const subTitle = (
    <>
      <div className={styles.subTitleRow}>
        Your verification link has expired. Please request a new account verification email.
      </div>
    </>
  );

  return (
    <AuthLayout title="Email Verification Link Expired" subTitle={subTitle} titleImage={<ErrorInSystemImage />}>
      <Button size="form" fullWidth onClick={handleResendEmail} isLoading={isLoading} disabled={!!resendInSeconds}>
        Send Verification Email
      </Button>
      {resendInSeconds ? (
        <div className={styles.resendCodeTimeout}>
          You can resend the email in <p className={styles.resendCodeTimeoutSeconds}>{resendInSeconds}s</p>
        </div>
      ) : null}
    </AuthLayout>
  );
};

export default VerificationLinkExpired;
