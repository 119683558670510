import React from 'react';
import clsx from 'clsx';
import styles from './SkeletonCirclesList.module.scss';
import SkeletonCircle from 'components/Skeleton/SkeletonCircle';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

interface SkeletonCirclesListProps {
  width: string;
  height: string;
  color: SkeletonColorType;
  className?: string;
  stopAnimation?: boolean;
}

const SkeletonCirclesList = ({ width, height, color, stopAnimation, className }: SkeletonCirclesListProps) => {
  return (
    <div className={clsx(styles.skeletonCirclesList, className)}>
      <SkeletonCircle
        stopAnimation={stopAnimation}
        width={width}
        height={height}
        color={color}
        className={styles.skeletonCirclesListItem}
      />
      <SkeletonCircle
        stopAnimation={stopAnimation}
        width={width}
        height={height}
        color={color}
        className={styles.skeletonCirclesListItem}
      />
      <SkeletonCircle
        stopAnimation={stopAnimation}
        width={width}
        height={height}
        color={color}
        className={styles.skeletonCirclesListItem}
      />
    </div>
  );
};

export default SkeletonCirclesList;
