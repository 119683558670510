import React, { FC } from 'react';
import StrategyGroupCard from 'components/StrategyGroups/StrategyGroupCard';
import styles from './StrategiesTilesView.module.scss';
import { StrategyGroup } from 'api/Types';
import StrategyTileSkeleton from 'components/StrategyGroups/StrategyTileSkeleton';
import { NullableItems } from 'pagination';

interface StrategiesTilesViewProps {
  groups: NullableItems<StrategyGroup>;
  selectedGroupName: string | null;
  handleViewAllClick: (name: string) => void;
}

const StrategiesTilesView: FC<StrategiesTilesViewProps> = ({ groups, selectedGroupName, handleViewAllClick }) => {
  return (
    <div className={styles.tilesContainer}>
      {groups.map((group, index) => {
        if (group === null) {
          return <StrategyTileSkeleton key={index} />;
        }
        return (
          <StrategyGroupCard
            {...group}
            key={group.name}
            selected={group.name === selectedGroupName}
            onViewAll={() => handleViewAllClick(group.name)}
          />
        );
      })}
    </div>
  );
};

export default StrategiesTilesView;
