import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { AvatarIcon, DuplicateImage } from 'static/images';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import { DATE_FORMAT } from 'LoanOriginationSystemApplications/constants';
import formatDate from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import { TagLine } from 'components/LoanOriginationSystem/ApplicationLabels';
import SearchHighlight from 'components/SearchHighlight';
import { useHistory } from 'react-router';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { Application, UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import LoaderWithState, { LoaderState } from 'components/LoaderWithState';
import { ApplicationUpdatingState } from 'LoanOriginationSystemApplications/ActionCreator';
import styles from './Card.module.scss';
import { RowActionsContainer } from 'components/RowActions';
import WrapperWithTooltip from 'components/Tooltip';
import ApplicationRowActions from 'components/LoanOriginationSystem/ApplicationsDashboard/ApplicationRowActions/ApplicationRowActions';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import CoBorrowerInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';
import ConfettiAnimation from 'components/ConfettiAnimation/ConfettiAnimation';
import { DefaultApplicationStatusName } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';

interface CardProps extends Application {
  className?: string;
  currencySymbol: string;
  highlightString: string;
  selectedMembers?: UserInfo[];
  updatingState: ApplicationUpdatingState | null;
  disabled?: boolean;
  onApplicationUpdatingStateReset: () => void;
  deleteDisabled: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

const MAX_AVATARS_COUNT = 3;
const APPLICATION_FAILURE_RESET_TIMEOUT = 5800;

const Card = ({
  className,
  currencySymbol,
  highlightString,
  selectedMembers,
  updatingState,
  disabled,
  onApplicationUpdatingStateReset,
  deleteDisabled,
  onEdit,
  onDelete,
  onDuplicate,
  ...data
}: CardProps) => {
  const [shouldShowApprovalAnimation, setShouldShowApprovalAnimation] = useState(false);

  const containerRef = useRef(null);
  const history = useHistory();
  const onClick = () => {
    if (disabled) {
      return;
    }

    history.push(`/los/applications/${data.displayId}`);
  };
  const loanAmountDisplayValue = formatMonetaryValue(
    (data.variables[ApplicationDefaultVariable.LoanAmount] as number) || 0,
    currencySymbol,
  );

  const renderTeamMembersSection = () => {
    if (!data.teamMembers.length) {
      return (
        <WrapperWithTooltip tooltip="Unassigned">
          <AvatarIcon className={styles.avatarIcon} />
        </WrapperWithTooltip>
      );
    }

    return (
      <UserAvatarInlineList
        source={data.teamMembers}
        size="small"
        maxCount={MAX_AVATARS_COUNT}
        className={styles.members}
      />
    );
  };

  const handleLoaderStateReset = () => {
    onApplicationUpdatingStateReset();
    setShouldShowApprovalAnimation(data.status.name === DefaultApplicationStatusName.Approved);
  };

  const handleApprovalAnimationComplete = () => {
    setShouldShowApprovalAnimation(false);
  };

  return (
    <RowActionsContainer>
      <div className={clsx(styles.card, className)} onClick={onClick} ref={containerRef}>
        <div className={styles.body}>
          <div>
            <LoaderWithState
              className={styles.loader}
              onStateReset={handleLoaderStateReset}
              failureTimeout={APPLICATION_FAILURE_RESET_TIMEOUT}
              state={updatingState as LoaderState | null}
            />
            <div className={styles.card__labels}>
              {data.labels.map((label) => (
                <TagLine key={label.id} {...label} />
              ))}
            </div>
            <div className={styles.card__title}>
              <SearchHighlight search={highlightString}>
                {getBorrowerFullName(data.borrowerType, data.variables)}
              </SearchHighlight>
              {data.coborrowerType && (
                <CoBorrowerInfo coborrowerType={data.coborrowerType} variables={data.variables} />
              )}
            </div>
            <p className={styles.card__subTitle}>
              {formatDisplayId(data.displayId)}{' '}
              {data.originalApplicationId && (
                <WrapperWithTooltip tooltip="Duplicated Application">
                  <DuplicateImage className={styles.duplicateIcon} />
                </WrapperWithTooltip>
              )}
              <p className={clsx(styles.dot, data.originalApplicationId && styles.hasIcon)}>&middot;</p>
              {formatDate(data.createdAt, DATE_FORMAT)}
            </p>
          </div>
          {!updatingState && (
            <ApplicationRowActions
              onEdit={onEdit}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
              className={styles.moreButton}
              deleteDisabled={deleteDisabled}
            />
          )}
        </div>
        <div className={styles.card__footer}>
          <h5 className={styles.card__value}>{loanAmountDisplayValue}</h5>
          {renderTeamMembersSection()}
        </div>
        {shouldShowApprovalAnimation && (
          <ConfettiAnimation onAnimationComplete={handleApprovalAnimationComplete} targetElementRef={containerRef} />
        )}
      </div>
    </RowActionsContainer>
  );
};

export default Card;
