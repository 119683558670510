import Fetcher from 'middlewares/Fetcher';
import { DeleteIntermediaryAction, deleteIntermediaryFailed, deleteIntermediarySuccess } from './ActionCreator';
import { DELETE_INTERMEDIARY } from './ActionTypes';
import {
  Intermediary,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';

const DeleteIntermediaryMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary, DeleteIntermediaryAction>(
    DELETE_INTERMEDIARY,
    deleteIntermediarySuccess,
    deleteIntermediaryFailed,
    async (action) => {
      const { intermediaryToDeleteId } = action.payload;

      return api.deleteIntermediary(intermediaryToDeleteId);
    },
  );

export default DeleteIntermediaryMiddleware;
