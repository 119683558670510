import {
  GET_STRATEGIES_LIST,
  GET_STRATEGIES_LIST_SUCCESS,
  GET_STRATEGIES_LIST_FAILURE,
  GET_STRATEGY_INPUTS,
  GET_STRATEGY_INPUTS_SUCCESS,
  GET_STRATEGY_INPUTS_FAILURE,
  GET_INDIVIDUAL_HISTORY,
  GET_INDIVIDUAL_HISTORY_SUCCESS,
  GET_INDIVIDUAL_HISTORY_FAILURE,
  SET_INDIVIDUAL_HISTORY_SEARCH_INPUT,
  RESET_INDIVIDUAL_STRATEGY_INPUTS,
  RUN_INDIVIDUAL_STRATEGY,
  RUN_INDIVIDUAL_STRATEGY_SUCCESS,
  RUN_INDIVIDUAL_STRATEGY_FAILURE,
  GET_INDIVIDUAL_CASE,
  GET_INDIVIDUAL_CASE_SUCCESS,
  GET_INDIVIDUAL_CASE_FAILURE,
  GET_BATCH_HISTORY,
  GET_BATCH_HISTORY_SUCCESS,
  GET_BATCH_HISTORY_FAILURE,
  SET_BATCH_HISTORY_SEARCH_INPUT,
  RUN_BATCH_STRATEGY,
  RUN_BATCH_STRATEGY_SUCCESS,
  RUN_BATCH_STRATEGY_FAILURE,
  GET_BATCH_CASE,
  GET_BATCH_CASE_SUCCESS,
  GET_BATCH_CASE_FAILURE,
  SET_BATCH_CASE_SEARCH_INPUT,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  StrategiesListItem,
  IndividualProcessingHistoryCase,
  ItemsWithTotalCount,
  IndividualRunInputs,
  BatchProcessingHistoryCase,
  BatchProcessingCase,
} from 'api/Types';
import { AnyAction } from 'redux';
import { IndividualCaseData, ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';

export interface GetStrategiesListAction {
  type: typeof GET_STRATEGIES_LIST;
}

export interface GetStrategiesListSuccessAction {
  type: typeof GET_STRATEGIES_LIST_SUCCESS;
  payload: StrategiesListItem[];
}

export interface GetStrategyInputsAction {
  type: typeof GET_STRATEGY_INPUTS;
  payload: string;
}

export interface GetStrategyInputsSuccessAction {
  type: typeof GET_STRATEGY_INPUTS_SUCCESS;
  payload: ProcessingStrategyInput[];
}

export interface GetIndividualHistoryAction {
  type: typeof GET_INDIVIDUAL_HISTORY;
}

export interface GetIndividualHistorySuccessAction extends AnyAction {
  type: typeof GET_INDIVIDUAL_HISTORY_SUCCESS;
  payload: IndividualHistoryWithParams;
}

export interface IndividualHistorySetSearchInput extends AnyAction {
  type: typeof SET_INDIVIDUAL_HISTORY_SEARCH_INPUT;
  payload: string;
}

export interface IndividualHistoryWithParams extends ItemsWithTotalCount<IndividualProcessingHistoryCase> {
  searchInputValue: string;
}

export interface ResetIndividualStrategyInputsAction {
  type: typeof RESET_INDIVIDUAL_STRATEGY_INPUTS;
}

export interface RunIndividualStrategyAction {
  type: typeof RUN_INDIVIDUAL_STRATEGY;
  payload: {
    strategyId: string;
    inputs: IndividualRunInputs;
    applicationId?: string;
  };
}

export interface RunIndividualStrategySuccessAction {
  type: typeof RUN_INDIVIDUAL_STRATEGY_SUCCESS;
  payload: {
    caseId: string;
  };
}

export interface GetIndividualCaseAction {
  type: typeof GET_INDIVIDUAL_CASE;
  payload: string;
}

export interface GetBatchHistoryAction {
  type: typeof GET_BATCH_HISTORY;
}

export interface GetBatchHistorySuccessAction extends AnyAction {
  type: typeof GET_BATCH_HISTORY_SUCCESS;
  payload: BatchHistoryWithParams;
}

export interface BatchHistorySetSearchInput extends AnyAction {
  type: typeof SET_BATCH_HISTORY_SEARCH_INPUT;
  payload: string;
}

export interface BatchHistoryWithParams extends ItemsWithTotalCount<BatchProcessingHistoryCase> {
  searchInputValue: string;
}

export interface RunBatchStrategyAction {
  type: typeof RUN_BATCH_STRATEGY;
  payload: {
    strategyId: string;
    batchData: File;
    processName?: string;
  };
}

export interface RunBatchStrategySuccessAction {
  type: typeof RUN_BATCH_STRATEGY_SUCCESS;
}

export interface GetBatchCaseAction {
  type: typeof GET_BATCH_CASE;
  payload: string;
}

export interface GetBatchCaseSuccessAction {
  type: typeof GET_BATCH_CASE_SUCCESS;
  payload: BatchCaseWithParams;
}

export interface BatchCaseWithParams extends BatchProcessingCase {
  searchInputValue: string;
}

export interface BatchCaseSetSearchInput extends AnyAction {
  type: typeof SET_BATCH_CASE_SEARCH_INPUT;
  payload: string;
}

export const getStrategiesList = (): GetStrategiesListAction => ({
  type: GET_STRATEGIES_LIST,
});

export const getStrategiesListSuccess = (payload: StrategiesListItem[]): GetStrategiesListSuccessAction => ({
  type: GET_STRATEGIES_LIST_SUCCESS,
  payload,
});

export const getStrategyInputs = (payload: string): GetStrategyInputsAction => ({
  type: GET_STRATEGY_INPUTS,
  payload,
});

export const getStrategyInputsSuccess = (payload: ProcessingStrategyInput[]): GetStrategyInputsSuccessAction => ({
  type: GET_STRATEGY_INPUTS_SUCCESS,
  payload,
});

export const getIndividualHistory = (): GetIndividualHistoryAction => ({
  type: GET_INDIVIDUAL_HISTORY,
});

export const getIndividualHistorySuccess = (
  payload: IndividualHistoryWithParams,
): GetIndividualHistorySuccessAction => ({
  type: GET_INDIVIDUAL_HISTORY_SUCCESS,
  payload,
});

export const setIndividualHistorySearchInput = (searchInput: string): IndividualHistorySetSearchInput => ({
  type: SET_INDIVIDUAL_HISTORY_SEARCH_INPUT,
  payload: searchInput,
});

export const resetIndividualStrategyInputs = (): ResetIndividualStrategyInputsAction => ({
  type: RESET_INDIVIDUAL_STRATEGY_INPUTS,
});

export const runIndividualStrategy = (
  strategyId: string,
  inputs: IndividualRunInputs,
  applicationId?: string,
): RunIndividualStrategyAction => ({
  type: RUN_INDIVIDUAL_STRATEGY,
  payload: { strategyId, inputs, applicationId },
});

export const runIndividualStrategySuccess = (payload: { caseId: string }): RunIndividualStrategySuccessAction => ({
  type: RUN_INDIVIDUAL_STRATEGY_SUCCESS,
  payload,
});

export const getIndividualCase = (payload: string): GetIndividualCaseAction => ({
  type: GET_INDIVIDUAL_CASE,
  payload,
});

export const getIndividualCaseSuccess = (payload: IndividualCaseData) => ({
  type: GET_INDIVIDUAL_CASE_SUCCESS,
  payload,
});

export const getBatchHistory = (): GetBatchHistoryAction => ({
  type: GET_BATCH_HISTORY,
});

export const getBatchHistorySuccess = (payload: BatchHistoryWithParams): GetBatchHistorySuccessAction => ({
  type: GET_BATCH_HISTORY_SUCCESS,
  payload,
});

export const setBatchHistorySearchInput = (searchInput: string): BatchHistorySetSearchInput => ({
  type: SET_BATCH_HISTORY_SEARCH_INPUT,
  payload: searchInput,
});

export const runBatchStrategy = (
  strategyId: string,
  batchData: File,
  processName?: string,
): RunBatchStrategyAction => ({
  type: RUN_BATCH_STRATEGY,
  payload: { strategyId, batchData, processName },
});

export const runBatchStrategySuccess = (): RunBatchStrategySuccessAction => ({
  type: RUN_BATCH_STRATEGY_SUCCESS,
});

export const getBatchCase = (payload: string): GetBatchCaseAction => ({
  type: GET_BATCH_CASE,
  payload,
});

export const getBatchCaseSuccess = (payload: BatchCaseWithParams): GetBatchCaseSuccessAction => ({
  type: GET_BATCH_CASE_SUCCESS,
  payload,
});

export const setBatchCaseSearchInput = (searchInput: string): BatchCaseSetSearchInput => ({
  type: SET_BATCH_CASE_SEARCH_INPUT,
  payload: searchInput,
});

export const getStrategiesListFailure = FailedActionCreator(GET_STRATEGIES_LIST_FAILURE);
export const getStrategyInputsFailure = FailedActionCreator(GET_STRATEGY_INPUTS_FAILURE);
export const getIndividualHistoryFailure = FailedActionCreator(GET_INDIVIDUAL_HISTORY_FAILURE);
export const runIndividualStrategyFailure = FailedActionCreator(RUN_INDIVIDUAL_STRATEGY_FAILURE);
export const getIndividualCaseFailure = FailedActionCreator(GET_INDIVIDUAL_CASE_FAILURE);
export const getBatchHistoryFailure = FailedActionCreator(GET_BATCH_HISTORY_FAILURE);
export const runBatchStrategyFailure = FailedActionCreator(RUN_BATCH_STRATEGY_FAILURE);
export const getBatchCaseFailure = FailedActionCreator(GET_BATCH_CASE_FAILURE);
