import { BranchItemInfo } from 'api/Types';
import {
  CopyBranchRulesAction,
  copyBranchRulesFailure,
  CopyBranchRulesSuccessAction,
  GetBranchOptionsSuccessAction,
} from './Actions';
import {
  COPY_BRANCH_RULES,
  COPY_BRANCH_RULES_FAILURE,
  COPY_BRANCH_RULES_SUCCESS,
  GET_BRANCH_OPTIONS_SUCCESS,
} from './ActionTypes';

export interface CopyModuleBranchRulesReducerState {
  branchOptions: BranchItemInfo[] | null;
  selectedBranchName: string | null;
  isDuplicating: boolean;
}

const defaultState: CopyModuleBranchRulesReducerState = {
  branchOptions: null,
  selectedBranchName: null,
  isDuplicating: false,
};

type HandledAction =
  | GetBranchOptionsSuccessAction
  | CopyBranchRulesAction
  | CopyBranchRulesSuccessAction
  | ReturnType<typeof copyBranchRulesFailure>;

export default (state: CopyModuleBranchRulesReducerState = defaultState, action: HandledAction) => {
  switch (action.type) {
    case GET_BRANCH_OPTIONS_SUCCESS: {
      return {
        ...state,
        branchOptions: (action as GetBranchOptionsSuccessAction).payload,
      };
    }
    case COPY_BRANCH_RULES: {
      return {
        ...state,
        isDuplicating: true,
      };
    }
    case COPY_BRANCH_RULES_SUCCESS:
    case COPY_BRANCH_RULES_FAILURE: {
      return {
        ...state,
        isDuplicating: false,
      };
    }
    default: {
      return state;
    }
  }
};
