import React, { useState } from 'react';
import { Intermediary } from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { SIZES } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import styles from './IntermediaryLine.module.scss';
import SearchHighlight from 'components/SearchHighlight';
import RowActions from 'components/RowActions';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import {
  getFormattedIntermediaryCommissionRate,
  getFormattedIntermediaryPhoneNumber,
} from 'LoanOriginationSystemOrganization/Utils';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import { StandardVariables } from 'Variables/VariablesTypes';
import WithSystemApiUserAvatar from 'components/WithSystemApiUserAvatar';

interface IntermediaryLineProps {
  intermediary: Intermediary;
  standardVariables: StandardVariables;
  onPopupOpen: (id: string) => Promise<void>;
  onEdit: (id: string) => void;
  searchInputValue: string;
}

const IntermediaryLine = ({
  intermediary,
  searchInputValue,
  standardVariables,
  onPopupOpen,
  onEdit,
}: IntermediaryLineProps) => {
  const formattedCommissionRate = getFormattedIntermediaryCommissionRate(
    intermediary.variables[IntermediaryDefaultVariable.CommissionRate],
    standardVariables,
  );
  const formattedPhone = getFormattedIntermediaryPhoneNumber(
    intermediary.variables[IntermediaryDefaultVariable.PhoneNumber],
    standardVariables,
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleDeleteClick = async (id: string) => {
    setIsLoading(true);
    await onPopupOpen(id);
    setIsLoading(false);
  };

  const renderCellContent = (content: string) => (
    <OverflowedText>
      <SearchHighlight search={searchInputValue}>{content || '-'}</SearchHighlight>
    </OverflowedText>
  );

  return (
    <TableRow useActions onClick={() => onEdit(intermediary.id)} disabled={isLoading}>
      <TableBodyCell width={SIZES.NAME} className={styles.intermediaryName}>
        {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Name] as string)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.TYPE}>
        {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Type] as string)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.PHONE}>{renderCellContent(formattedPhone)}</TableBodyCell>
      <TableBodyCell width={SIZES.EMAIL}>
        {renderCellContent(intermediary.variables[IntermediaryDefaultVariable.Email] as string)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.DATE} noPadding>
        <WithSystemApiUserAvatar user={intermediary.updatedBy} className={styles.avatar} size="small" />
        {formatDate(intermediary.updatedAt, DateTimeFormat.Short)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.RATE} lastColumnCell>
        {renderCellContent(formattedCommissionRate)}
      </TableBodyCell>

      <RowActions
        actions={[
          {
            title: 'Edit Intermediary',
            handler: () => onEdit(intermediary.id),
            danger: false,
            separatorRequired: true,
          },
          {
            title: 'Delete Intermediary',
            handler: () => handleDeleteClick(intermediary.id),
            danger: true,
          },
        ]}
        isLoading={isLoading}
      />
    </TableRow>
  );
};

export default IntermediaryLine;
