import React from 'react';
import { SIZES } from 'components/LoanOriginationSystem/IntermediariesDashboard/IntermediariesTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableActionCell from 'components/Table/TableActionCell';

const SkeletonIntermediaryLine = () => {
  return (
    <TableRow disableHover useActions>
      <TableBodyCell width={SIZES.NAME}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.TYPE}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.PHONE}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.EMAIL}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.DATE}>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableBodyCell width={SIZES.RATE} lastColumnCell>
        <SkeletonRectangle width="80%" height="16px" color="primary6" />
      </TableBodyCell>
      <TableActionCell />
    </TableRow>
  );
};

export default SkeletonIntermediaryLine;
