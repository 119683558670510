import { DecisionEngineApi } from 'api/Types';
import Fetcher from 'middlewares/Fetcher';
import { DuplicateModuleAction, duplicateModuleFailure } from './Actions';
import { DUPLICATE_MODULE } from './ActionTypes';
import { createModuleAddedRequestSuccess, ModuleAddedSuccessAction } from 'ModuleInfo/ModuleInfoActionCreator';

export const DuplicateModuleMiddleware = (api: DecisionEngineApi) =>
  Fetcher<ModuleAddedSuccessAction['payload'], DuplicateModuleAction>(
    DUPLICATE_MODULE,
    createModuleAddedRequestSuccess,
    duplicateModuleFailure,
    async ({ payload }) => {
      const { copyToStrategyId, copyFromStrategyId, ...moduleInfoParams } = payload;
      await api.duplicateModule({
        strategyId: copyToStrategyId,
        moduleParams: {
          strategyId: copyFromStrategyId,
          ...moduleInfoParams,
        },
      });

      return {
        strategyId: payload.copyToStrategyId,
        ...moduleInfoParams,
      };
    },
  );
