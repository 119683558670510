import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  deleteApplicationLabelFailure,
  deleteApplicationLabelSuccess,
  DeleteApplicationLabelAction,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const DeleteApplicationLabelMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, DeleteApplicationLabelAction>(
    ApplicationPageActionType.DeleteApplicationLabel,
    deleteApplicationLabelSuccess,
    deleteApplicationLabelFailure,
    (action) => {
      return api.editLabels(action.payload.applicationId, {
        labelsToDelete: [action.payload.labelId],
      });
    },
  );

export default DeleteApplicationLabelMiddleware;
