import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import LoanOriginationSystemLabelsRestApi from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { DeleteLabelAction, deleteLabelFailure, deleteLabelSuccess, DeleteLabelSuccessResult } from './ActionCreator';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';

const DeleteLabelMiddleware = (api: LoanOriginationSystemLabelsRestApi) =>
  Fetcher<DeleteLabelSuccessResult, DeleteLabelAction, ReduxState>(
    LoanOriginationSystemLabelsActionTypes.DeleteLabel,
    deleteLabelSuccess,
    deleteLabelFailure,
    async (action, state) => {
      await api.deleteLabel(action.payload);

      const labels = state.loanOriginationSystemLabelsState.items;

      const deletedLabel = labels.find((label) => label.id === action.payload);

      return {
        name: deletedLabel ? deletedLabel.name : '',
      };
    },
  );

export default DeleteLabelMiddleware;
