import { getCurrencySymbol } from 'components/CurrencySelect/currencies';
import {
  MonetaryWithAttributes,
  UndefinedVisualDataTypeWithNoAttributes,
  VisualDataTypeWithAttributes,
} from 'Variables/VariablesTypes';

const getShowingDataType = (
  visualDataTypeAttributes: VisualDataTypeWithAttributes | UndefinedVisualDataTypeWithNoAttributes,
) => {
  const { dataType } = visualDataTypeAttributes;
  if (dataType === 'Monetary') {
    const { currency } = visualDataTypeAttributes as MonetaryWithAttributes;
    return `${dataType} (${getCurrencySymbol(currency!)})`;
  }
  if (dataType === 'EmailAddress') {
    return 'Email Address';
  }
  if (dataType === 'IdentificationNumber') {
    return 'Identification Number';
  }
  if (dataType === 'PhoneNumber') {
    return 'Phone Number';
  }
  if (dataType === 'Percentage') {
    return 'Percentage (%)';
  }
  return dataType;
};

export default getShowingDataType;
