import Debouncer from 'middlewares/Debouncer';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { getApplicationDocuments } from './ActionCreator';

export default Debouncer(
  (payload: { applicationId: string; search: string }, getState) => {
    const { loanOriginationSystemDocuments } = getState();

    return getApplicationDocuments(payload.applicationId, payload.search, loanOriginationSystemDocuments.sortingType);
  },
  [ApplicationDocumentsActionType.SetSearchValue],
);
