import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getVariablesRequest, SortVariablesAction } from './VariablesActionCreator';
import { VariablesActionType } from './ActionTypes';

const isSortVariablesAction = (action: AnyAction): action is SortVariablesAction =>
  action.type === VariablesActionType.SortVariables;

const sortVariablesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<AnyAction>) => (
  action: AnyAction,
) => {
  const result = next(action);

  if (isSortVariablesAction(action)) {
    const { variables } = getState();

    dispatch(getVariablesRequest(variables.filters, action.payload));
  }

  return result;
}) as Middleware;

export default sortVariablesMiddleware;
