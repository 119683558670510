import hexColorToRgb, { RGB } from 'utils/hexColorToRgb';
import { RGBA } from 'utils/addAlphaToColor';

const WHITE = '#fff';

const blendColors = (color: RGBA, backgroundColor: string = WHITE): RGB => {
  const background = hexColorToRgb(backgroundColor);
  const [red, green, blue, alpha] = color;
  return [red, green, blue].map((value, i) => Math.round(value * alpha + background[i] * (1 - alpha))) as RGB;
};

export default blendColors;
