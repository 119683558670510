import { AnyAction, Middleware } from 'redux';
import { nanoid } from 'nanoid';
import Routines from 'routine/Routines';
import { ActionWithOrigin, traceActionOrigin } from 'utils/actions/ActionWithOrigin';

type ActionType = string;

const { Dispatcher, useDispatchRoutine, useDispatchRoutineWithResult, RoutineHandler } = Routines(nanoid);
export { Dispatcher, useDispatchRoutine, useDispatchRoutineWithResult, RoutineHandler };

function Fetcher<
  Response,
  TriggerAction extends AnyAction = AnyAction,
  State = {},
  ErrorType = Error,
  ProvidedResult = undefined
>(
  triggerActionType: ActionType,
  successActionCreator: (response: Response) => AnyAction,
  errorActionCreator: (error: ErrorType) => AnyAction,
  request: (action: TriggerAction, state: State) => Promise<Response>,
  key?: string | symbol,
  mapProvidedResult?: (response: Response) => ProvidedResult,
): Middleware {
  const isTriggerAction = (action: AnyAction): action is TriggerAction => action.type === triggerActionType;

  return RoutineHandler<State, ProvidedResult>(async (action, { dispatch, getState }, provideResult) => {
    if (!isTriggerAction(action)) {
      return;
    }
    try {
      const response: Response = await request(action, getState());

      if (mapProvidedResult) {
        provideResult(mapProvidedResult(response));
      }

      const successAction = traceActionOrigin(action, successActionCreator(response) as ActionWithOrigin);
      dispatch(successAction);
    } catch (error) {
      const errorAction = traceActionOrigin(action, errorActionCreator(error) as ActionWithOrigin);

      dispatch(errorAction);

      throw error;
    }
  }, key);
}

export default Fetcher;
