import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  UpdateApplicationAction,
  updateApplicationFailed,
  updateApplicationSuccess,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { UPDATE_APPLICATION } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';

const UpdateApplicationMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application, UpdateApplicationAction, ReduxState>(
    UPDATE_APPLICATION,
    updateApplicationSuccess,
    updateApplicationFailed,
    (action) => {
      return api.updateApplication(action.payload.data);
    },
  );

export default UpdateApplicationMiddleware;
