import {
  Application,
  LoanOriginationSystemApplicationsApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { GET_COLUMN_VIEW_PRODUCT_DATA } from 'LoanOriginationSystemApplications/ActionTypes';
import {
  getColumnViewProductDataSuccess,
  getColumnViewProductDataFailed,
  GetColumnViewProductDataAction,
} from 'LoanOriginationSystemApplications/ActionCreator';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';

const GetColumnViewProductDataMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<Application[], GetColumnViewProductDataAction, ReduxState>(
    GET_COLUMN_VIEW_PRODUCT_DATA,
    getColumnViewProductDataSuccess,
    getColumnViewProductDataFailed,
    async (action) => {
      const { productId, filters } = action.payload;

      const apiFilters = {
        ...filters,
        variablesToInclude: [
          ApplicationDefaultVariable.LoanAmount,
          ...Object.values(ApplicationDefaultVariable.Borrower),
          ...Object.values(ApplicationDefaultVariable.Coborrower),
        ],
        selectedStatusesIds: [],
      };

      const applications = await api.getAll(productId, apiFilters, action.payload.sortingType);

      return applications.items;
    },
  );

export default GetColumnViewProductDataMiddleware;
