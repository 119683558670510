import { AnyAction } from 'redux';
import { DeleteDecisionWizardActionType } from 'DeleteDecisionWizard/actionTypes';
import { DeleteDecisionWizardState } from 'DeleteDecisionWizard/types';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';

const initialState: DeleteDecisionWizardState = {
  isOpen: false,
  isLoading: false,
  decisionRunId: null,
};

const deleteDecisionWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case DeleteDecisionWizardActionType.OpenDeleteDecisionWizard: {
      return { ...initialState, isOpen: true, ...action.payload };
    }
    case DeleteDecisionWizardActionType.CloseDeleteDecisionWizard: {
      return { ...initialState };
    }
    case ApplicationDecisionRunActionType.DeleteApplicationDecisionRun: {
      return { ...state, isLoading: true };
    }
    case ApplicationDecisionRunActionType.DeleteApplicationDecisionRunFailure:
    case ApplicationDecisionRunActionType.DeleteApplicationDecisionRunSuccess: {
      return { ...state, isLoading: false, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

export default deleteDecisionWizardReducer;
