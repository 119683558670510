import React, { FC, ReactNode, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import usePageWrapperOffset from './usePageWrapperOffset';

import styles from './PageWrapper.module.scss';
import PastDueAlert from './PastDueAlert';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { OrganizationSubscriptionStatus } from 'eventHandlers/AuthEventEmitter';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';

interface PageWrapperProps {
  className?: string;
  children: ReactNode;
}

const PageWrapper: FC<PageWrapperProps> = ({ className, children, ...props }) => {
  const { organization, user } = useSelector((state: ReduxState) => state.sessionInfo);

  const mergedClassName = clsx(styles.pageWrapper, className);

  const wrapperElementRef = useRef<HTMLDivElement>(null);

  const setOffset = usePageWrapperOffset();

  useLayoutEffect(() => {
    const { width } = wrapperElementRef.current!.getBoundingClientRect();
    const { width: parentWidth } = wrapperElementRef.current!.parentElement!.getBoundingClientRect();
    const rightOffset = parentWidth - width;
    setOffset(rightOffset);
  });

  const shouldShowPastDueAlert = organization?.subscriptionStatus === OrganizationSubscriptionStatus.PastDue;

  return (
    <div ref={wrapperElementRef} className={mergedClassName} {...props}>
      {shouldShowPastDueAlert && <PastDueAlert hideLink={user?.role !== UserRoleName.Owner} />}
      {children}
    </div>
  );
};

export default PageWrapper;
