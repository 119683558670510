import { RunDecisionWizardActionType } from 'RunDecisionWizard/actionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { StrategiesListItem } from 'api/Types';
import { ApplicationStrategyInputs } from 'api/LoanOriginationSystem/ApplicationDecisionRunInputsLegacyApi';

interface OpenRunDecisionWizardAction {
  type: RunDecisionWizardActionType;
  payload: {
    applicationId: string;
  };
}

export const openRunDecisionWizard = (
  payload: OpenRunDecisionWizardAction['payload'],
): OpenRunDecisionWizardAction => ({
  type: RunDecisionWizardActionType.OpenRunDecisionWizard,
  payload,
});

export const closeRunDecisionWizard = () => {
  return {
    type: RunDecisionWizardActionType.CloseRunDecisionWizard,
  };
};

export interface GetStrategiesAction {
  type: RunDecisionWizardActionType;
}

export interface GetStrategiesSuccess {
  type: RunDecisionWizardActionType;
  payload: StrategiesListItem[];
}

export interface GetApplicationStrategyInputs {
  type: RunDecisionWizardActionType;
  payload: {
    strategyId: string;
    applicationId: string;
  };
}

export interface GetApplicationProductVariables {
  type: RunDecisionWizardActionType;
  payload: {
    productId: string;
  };
}

export interface GetApplicationStrategyInputsSuccess {
  type: RunDecisionWizardActionType;
  payload: ApplicationStrategyInputs;
}

export interface GetApplicationProductVariablesSuccess {
  type: RunDecisionWizardActionType;
  payload: string[];
}

export const getStrategies = (): GetStrategiesAction => ({
  type: RunDecisionWizardActionType.GetStrategies,
});

export const getStrategiesSuccess = (payload: StrategiesListItem[]): GetStrategiesSuccess => ({
  type: RunDecisionWizardActionType.GetStrategiesSuccess,
  payload,
});

export const getStrategiesFailure = FailedActionCreator(RunDecisionWizardActionType.GetStrategiesFailure);

export const getApplicationStrategyInputs = (
  payload: GetApplicationStrategyInputs['payload'],
): GetApplicationStrategyInputs => ({
  type: RunDecisionWizardActionType.GetApplicationStrategiesInputs,
  payload,
});

export const getApplicationStrategyInputsSuccess = (
  payload: GetApplicationStrategyInputsSuccess['payload'],
): GetApplicationStrategyInputsSuccess => ({
  type: RunDecisionWizardActionType.GetApplicationStrategiesInputsSuccess,
  payload,
});

export const getApplicationStrategyInputsFailure = FailedActionCreator(
  RunDecisionWizardActionType.GetApplicationStrategiesInputsFailure,
);

export const resetApplicationStrategiesInputs = () => ({
  type: RunDecisionWizardActionType.ResetApplicationStrategiesInputs,
});

export const getApplicationProductVariables = (
  payload: GetApplicationProductVariables['payload'],
): GetApplicationProductVariables => ({
  type: RunDecisionWizardActionType.GetApplicationProductVariables,
  payload,
});

export const getApplicationProductVariablesSuccess = (
  payload: GetApplicationProductVariablesSuccess['payload'],
): GetApplicationProductVariablesSuccess => ({
  type: RunDecisionWizardActionType.GetApplicationProductVariablesSuccess,
  payload,
});

export const getApplicationProductVariablesFailure = FailedActionCreator(
  RunDecisionWizardActionType.GetApplicationProductVariablesFailure,
);
