import React, { FC } from 'react';
import NewPasswordForm from 'components/Auth/NewPasswordForm';

interface CreateAccountInviteFormProps {
  onSubmit: (password: string) => Promise<void>;
}

const CreateAccountInviteForm: FC<CreateAccountInviteFormProps> = ({ onSubmit }) => {
  return <NewPasswordForm buttonTitle="Create Account" onSubmit={onSubmit} />;
};

export default CreateAccountInviteForm;
