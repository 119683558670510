import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApiActivationSortingType, SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';
import { StrategyGroup } from 'api/Types';

interface ApiActivationPaginationParams {
  searchInputValue: string;
  showArchived: boolean;
  selectedUpdaters: UserInfo[];
  versionsStatuses: SystemStatus[];
  sortingType: ApiActivationSortingType;
}

const API_ACTIVATION_PER_PAGE_DEFAULT = 10;

const apiActivationPagination = Pagination<ReduxState, StrategyGroup, ApiActivationPaginationParams>(
  'apiActivation',
  API_ACTIVATION_PER_PAGE_DEFAULT,
  ({ searchInputValue }) => (searchInputValue ? ['searched', searchInputValue] : []),
);

export default apiActivationPagination;
