const arrayMove = <Item>(array: Item[], from: number, to: number) => {
  const arrayCopy = [...array];
  const startIndex = from < 0 ? array.length + from : from;

  if (startIndex >= 0 && startIndex < arrayCopy.length) {
    const endIndex = to < 0 ? arrayCopy.length + to : to;
    const [item] = arrayCopy.splice(from, 1);

    arrayCopy.splice(endIndex, 0, item);
  }

  return arrayCopy;
};

export default arrayMove;
