import Debouncer from 'middlewares/Debouncer';
import { getIntermediaries } from './ActionCreator';
import { LoanOriginationSystemIntermediariesFiltersActionTypes } from './Filters/ActionTypes';

export default Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemIntermediariesState } = getState();
    const { filters, sortingType } = loanOriginationSystemIntermediariesState;

    return getIntermediaries(filters, sortingType);
  },
  [LoanOriginationSystemIntermediariesFiltersActionTypes.SetSearchInputValue],
);
