import React, { FC, useRef, useState } from 'react';
import clsx from 'clsx';
import { BaseLabelInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import ApplicationTag from 'components/Tag/ApplicationTag';
import { AddImage, CheckedImage } from 'static/images';
import WrapperWithTooltip from 'components/Tooltip';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import Spinner from 'components/Spinner';
import styles from './EditLabels.module.scss';

export interface EditLabelsProps {
  className?: string;
  applicationLabels: BaseLabelInfo[];
  productLabels: BaseLabelInfo[];
  onAddLabel: (labelId: string) => void;
  onDeleteLabel: (label: BaseLabelInfo) => void;
  isAddingLabelInProgress?: boolean;
}

const EditLabels: FC<EditLabelsProps> = ({
  className,
  applicationLabels,
  isAddingLabelInProgress,
  productLabels,
  onDeleteLabel,
  onAddLabel,
}) => {
  const addLabelButtonRef = useRef<HTMLDivElement | null>(null);
  const [isAddLabelPopupOpen, setIsAddLabelPopupOpen] = useState(false);

  const renderApplicationLabel = (label: BaseLabelInfo) => (
    <ApplicationTag className={styles.labelTag} key={label.id} onClose={() => onDeleteLabel(label)} color={label.color}>
      {label.name}
    </ApplicationTag>
  );

  const renderProductLabel = (label: BaseLabelInfo) => {
    const isLabelSelected = !!applicationLabels.find(({ id }) => label.id === id);

    const handleItemClick = () => {
      onAddLabel(label.id);
      setIsAddLabelPopupOpen(false);
    };

    return (
      <ActionPopUpItem
        onClick={handleItemClick}
        key={label.id}
        disabled={isLabelSelected}
        className={clsx(styles.productLabel)}
      >
        <ApplicationTag className={styles.tag} color={label.color}>
          {label.name}
        </ApplicationTag>
        {isLabelSelected && <CheckedImage />}
      </ActionPopUpItem>
    );
  };

  const handleAddLabelsButtonClick = () => {
    if (isAddingLabelInProgress) {
      return;
    }

    setIsAddLabelPopupOpen(true);
  };

  return (
    <div className={clsx(styles.editLabelsContainer, className)}>
      <div className={styles.labels}>{applicationLabels.map(renderApplicationLabel)}</div>
      <WrapperWithTooltip tooltip="Add Label">
        <div
          className={clsx(
            styles.addLabelsButton,
            isAddingLabelInProgress && styles.disabledAddLabelsButton,
            isAddLabelPopupOpen && styles.activeAddLabelsButton,
          )}
          ref={addLabelButtonRef}
          onClick={handleAddLabelsButtonClick}
        >
          {!isAddingLabelInProgress && <AddImage />}
          {isAddingLabelInProgress && <Spinner size={12} />}
        </div>
      </WrapperWithTooltip>
      <ContextualPopUp
        anchorEl={addLabelButtonRef.current}
        open={isAddLabelPopupOpen}
        onClose={() => setIsAddLabelPopupOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={styles.actionsContainer}>{productLabels.map(renderProductLabel)}</div>
      </ContextualPopUp>
    </div>
  );
};

export default EditLabels;
