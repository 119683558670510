import React from 'react';
import DatePicker from 'components/DatePicker';
import { formatDate, getOnDateChange } from 'components/DatePicker/utils';
import styles from './DateRangePicker.module.scss';

interface DateRangePickerProps {
  label: string;
  from: Date | null;
  to: Date | null;
  onChange: (from: Date | null, to: Date | null) => void;
}

const DateRangePicker = ({ label, from, to, onChange }: DateRangePickerProps) => {
  return (
    <div>
      <p className={styles.datePickersLabel}>{label}</p>
      <div className={styles.datePickersInner}>
        <div className={styles.datePickerFrom}>
          <DatePicker
            value={formatDate(from)}
            onChange={getOnDateChange((date) => onChange(date, to))}
            labelTitle=""
            placeholder="Start"
          />
        </div>
        <div className={styles.datePickerTo}>
          <DatePicker
            value={formatDate(to)}
            onChange={getOnDateChange((date) => onChange(from, date))}
            labelTitle=""
            placeholder="End"
          />
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;
