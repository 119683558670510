import React, { FC, useState } from 'react';
import DeleteBranchWizard from './DeleteBranchWizard';
import { useSelector, useDispatch } from 'react-redux';
import { ReduxState } from 'types/redux';
import { DecisionStrategiesState } from 'DecisionStrategy/DecisionStrategiesTypes';
import { DeleteBranchWizardState } from './deleteBranchWizardTypes';
import { closeDeleteBranchWizard } from './Actions';
import { deleteBranchRequest } from 'BranchInfo/ActionCreator';
import { useDispatchRoutine } from 'middlewares/Fetcher';

const DeleteBranchWizardContainer: FC<{}> = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const { strategiesDetailedAttributes } = useSelector<ReduxState, DecisionStrategiesState>(
    ({ decisionStrategies }) => decisionStrategies,
  );

  const { strategyId, moduleKey, branchIndex, isOpen } = useSelector<ReduxState, DeleteBranchWizardState>(
    ({ deleteBranchWizard }) => deleteBranchWizard,
  );

  const strategy = strategiesDetailedAttributes[strategyId];
  const module = strategy?.moduleList.find(({ lookupName }) => lookupName === moduleKey);
  const branch = module?.branches[branchIndex!];
  const branchName = branch?.name || '';

  const onClosePopUp = () => {
    dispatch(closeDeleteBranchWizard());
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteBranch = async () => {
    try {
      setIsLoading(true);
      await dispatchRoutine(deleteBranchRequest({ strategyId, moduleKey, branchIndex: branchIndex!, branchName }));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) {
    return null;
  }

  return <DeleteBranchWizard isLoading={isLoading} closePopUp={onClosePopUp} deleteBranch={handleDeleteBranch} />;
};

export default DeleteBranchWizardContainer;
