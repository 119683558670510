import Debouncer from 'middlewares/Debouncer';
import { LoanOriginationSystemBorrowersFiltersActionTypes } from './ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { getBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';

export default Debouncer<UserInfo[]>(
  (payload, getState) => {
    const { loanOriginationSystemBorrowers } = getState();
    const { filters, sortingType } = loanOriginationSystemBorrowers;

    return getBorrowers(filters, sortingType);
  },
  [LoanOriginationSystemBorrowersFiltersActionTypes.FilterBorrowersByMembers],
);
