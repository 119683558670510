import React from 'react';
import clsx from 'clsx';
import { SkeletonRectangle } from 'components/Skeleton';
import SkeletonCard from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody/SkeletonCard';
import { APPLICATION_CARDS_COUNT } from './ApplicationStatusCard';
import styles from './SkeletonApplicationStatusCard.module.scss';

export interface SkeletonApplicationStatusCardProps {
  className?: string;
  statusOptionsCount?: number;
  displayStatusIndex?: boolean;
  displayApplicationCards?: boolean;
}

const SkeletonApplicationStatusCard = ({
  className,
  statusOptionsCount = 3,
  displayStatusIndex,
  displayApplicationCards,
}: SkeletonApplicationStatusCardProps) => (
  <div className={clsx(styles.card, className)}>
    {displayStatusIndex && (
      <SkeletonRectangle className={styles.statusIndex} width="49px" height="12px" color="primary6" />
    )}
    <SkeletonRectangle className={styles.statusName} width="180px" height="24px" color="primary6" />
    <div className={styles.statusOptions}>
      {new Array(statusOptionsCount).fill(null).map((item, index) => (
        <div key={index} className={styles.statusOption}>
          <SkeletonRectangle width="211px" height="20px" color="primary6" />
          <SkeletonRectangle marginRight="12px" width="20px" height="20px" color="primary6" />
        </div>
      ))}
    </div>
    {displayApplicationCards && (
      <div className={styles.applicationCards}>
        {new Array(APPLICATION_CARDS_COUNT).fill(null).map((item, index) => (
          <SkeletonCard key={index} avatarsListClassname={styles.skeletonAvatarList} primaryColor="primary20" />
        ))}
      </div>
    )}
  </div>
);

export default SkeletonApplicationStatusCard;
