import React, { FC, useState } from 'react';
import styles from './UploadFromFile.module.scss';
import Button from 'components/Button';
import AddRuleDialogContainer from 'components/AddRuleDialog/Shared/Container';
import UploadFile from 'components/UploadFile';

interface UploadFromFileProps {
  downloadTemplate: () => void;
  onSaveFile: (file: File) => void;
}

const ACCEPT_FORMATS =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv';

const UploadFromFile: FC<UploadFromFileProps> = ({ downloadTemplate, onSaveFile }) => {
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (newFile: File | null) => {
    setFile(newFile);
  };

  const handleSaveFile = async () => {
    try {
      setIsUploading(true);
      if (file) {
        await onSaveFile(file);
      }
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <AddRuleDialogContainer hasTopMargin>
      <UploadFile file={file} onFileChange={handleFileChange} accept={ACCEPT_FORMATS} />
      <div className={styles.buttonsContainer}>
        <Button kind="primary" size="form" disabled={!file} onClick={handleSaveFile} isLoading={isUploading}>
          Upload File
        </Button>
        <Button kind="secondary" size="form" onClick={downloadTemplate} disabled={isUploading}>
          Download Template
        </Button>
      </div>
    </AddRuleDialogContainer>
  );
};

export default UploadFromFile;
