import Debouncer from 'middlewares/Debouncer';
import { getProductDataByViewType } from 'LoanOriginationSystemApplications/ActionCreator';
import { SET_SEARCH_INPUT_VALUE } from 'LoanOriginationSystemApplications/Filters/ActionTypes';
import { SetSearchInputValueActionPayload } from './Filters/ActionCreator';
import { ViewType } from './Types';

export default Debouncer(
  (payload: SetSearchInputValueActionPayload, getState) => {
    const { loanOriginationSystemApplications } = getState();
    const { selectedProduct, filters, tableViewSortingType, columnViewSortingType } = loanOriginationSystemApplications;

    return getProductDataByViewType(
      selectedProduct!.id,
      payload.viewType,
      filters,
      payload.viewType === ViewType.Column ? columnViewSortingType : tableViewSortingType,
    );
  },
  [SET_SEARCH_INPUT_VALUE],
);
