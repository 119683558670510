import React, { FC } from 'react';

import styles from './NavigationLinks.module.scss';

import NavigationLink from 'components/LeftNav/NavigationLinks/NavigationLink';
import { Link } from 'MainLayout/leftNavReducer/LeftNavTypes';

interface NavigationLinksType {
  isExpanded: boolean;
  links?: Link[];
  activeLinkId: string;
  setActiveLinkTitle: (title: string) => void;
}

const NavigationLinks: FC<NavigationLinksType> = ({ isExpanded, links, activeLinkId, setActiveLinkTitle }) => {
  return (
    <nav className={styles.navLinksContainer}>
      <ul>
        {(links || []).map((item, ind) => {
          const { title, id, Icon, nestedLinks, route = '/dashboard' } = item;
          return (
            <NavigationLink
              title={title}
              id={id}
              Icon={Icon}
              key={ind}
              isExpanded={isExpanded}
              onUpdateActiveLinkTitle={setActiveLinkTitle}
              isActive={activeLinkId === id}
              activeLinkId={activeLinkId}
              nestedLinks={nestedLinks}
              route={route}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default NavigationLinks;
