import { AnyAction } from 'redux';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ESignTemplateFilterStatus, ESignTemplatesSortingType, ESignTemplate } from 'api/Core/ESignTemplatesApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { DateRange, TableViewData } from 'api/Types';
import { ESignTemplatesActionType } from './ActionTypes';

export enum ESignTemplatesActionOrigin {
  DocuSignConfiguration = 'DocuSignConfiguration',
}

export interface ESignTemplatesFilter {
  integrationId?: string;
  search: string;
  status?: ESignTemplateFilterStatus | null;
  createdDateRange: DateRange;
  updatedDateRange: DateRange;
  members?: UserInfo[];
}

export interface GetESignTemplatesAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.GetESignTemplates;
  payload: {
    filters: ESignTemplatesFilter;
    sortingType: ESignTemplatesSortingType;
    itemsPerPage: number;
  };
}

export interface GetESignTemplatesSuccessAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.GetESignTemplatesSuccess;
  payload: TableViewData<ESignTemplate> & { searchInputValue: string };
}

export interface GetESignTemplateAction extends AnyAction {
  type: ESignTemplatesActionType.GetESignTemplate;
  payload: {
    id: string;
  };
}

export interface GetESignTemplateSuccessAction extends AnyAction {
  type: ESignTemplatesActionType.GetESignTemplateSuccess;
  payload: {
    template: ESignTemplate;
  };
}

export interface UpdateESignTemplateAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.UpdateESignTemplate;
  payload: {
    id: string;
    template: Partial<ESignTemplate>;
  };
}

export interface UpdateESignTemplateSuccessAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.UpdateESignTemplateSuccess;
  payload: {
    template: ESignTemplate;
  };
}

export interface DeleteESignTemplateAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.DeleteESignTemplate;
  payload: {
    id: string;
  };
}

export interface DeleteESignTemplateSuccessAction extends ActionWithOrigin<ESignTemplatesActionOrigin> {
  type: ESignTemplatesActionType.DeleteESignTemplateSuccess;
  payload: {
    id: string;
  };
}

export interface SaveESignTemplatesAction extends AnyAction {
  type: ESignTemplatesActionType.SaveESignTemplates;
  payload: {
    templates: ESignTemplate[];
  };
}

export const getESignTemplates = (
  filters: ESignTemplatesFilter,
  sortingType: ESignTemplatesSortingType,
  itemsPerPage: number,
): GetESignTemplatesAction => ({
  type: ESignTemplatesActionType.GetESignTemplates,
  payload: {
    filters,
    sortingType,
    itemsPerPage,
  },
});

export const getESignTemplatesSuccess = (
  result: TableViewData<ESignTemplate> & { searchInputValue: string },
): GetESignTemplatesSuccessAction => ({
  type: ESignTemplatesActionType.GetESignTemplatesSuccess,
  payload: result,
});

export const getESignTemplate = (id: string): GetESignTemplateAction => ({
  type: ESignTemplatesActionType.GetESignTemplate,
  payload: {
    id,
  },
});

export const getESignTemplateSuccess = (template: ESignTemplate): GetESignTemplateSuccessAction => ({
  type: ESignTemplatesActionType.GetESignTemplateSuccess,
  payload: {
    template,
  },
});

export const updateESignTemplate = (id: string, params: Partial<ESignTemplate>): UpdateESignTemplateAction => ({
  type: ESignTemplatesActionType.UpdateESignTemplate,
  payload: {
    id,
    template: params,
  },
});

export const updateESignTemplateSuccess = (template: ESignTemplate): UpdateESignTemplateSuccessAction => ({
  type: ESignTemplatesActionType.UpdateESignTemplateSuccess,
  payload: {
    template,
  },
});

export const deleteESignTemplate = (id: string): DeleteESignTemplateAction => ({
  type: ESignTemplatesActionType.DeleteESignTemplate,
  payload: {
    id,
  },
});

export const deleteESignTemplateSuccess = (id: string): DeleteESignTemplateSuccessAction => ({
  type: ESignTemplatesActionType.DeleteESignTemplateSuccess,
  payload: {
    id,
  },
});

export const saveESignTemplates = (templates: ESignTemplate[]): SaveESignTemplatesAction => ({
  type: ESignTemplatesActionType.SaveESignTemplates,
  payload: {
    templates,
  },
});

export const getESignTemplatesFailure = FailedActionCreator(ESignTemplatesActionType.GetESignTemplatesFailure);
export const getESignTemplateFailure = FailedActionCreator(ESignTemplatesActionType.GetESignTemplateFailure);
export const updateESignTemplateFailure = FailedActionCreator(ESignTemplatesActionType.GetESignTemplatesFailure);
export const deleteESignTemplateFailure = FailedActionCreator(ESignTemplatesActionType.DeleteESignTemplateFailure);
