import { debounce } from 'lodash';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';

export const REQUEST_DELAY_MS = 250; // 1/4 of a second

const Debouncer = <TriggerActionPayload, State = {}>(
  actionCreator: (action: TriggerActionPayload, getState: () => ReduxState) => AnyAction,
  actionTypes: string[],
  delayMs = REQUEST_DELAY_MS,
  actionOrigin?: string,
): Middleware => {
  return (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (next: Dispatch<State>) => {
    const request = debounce((action) => {
      const actionToDispatch = actionOrigin
        ? setActionOrigin(actionCreator(action.payload, getState), actionOrigin)
        : actionCreator(action.payload, getState);

      return dispatch(actionToDispatch);
    }, delayMs);

    return (action: AnyAction) => {
      const result = next(action);

      if (actionTypes.includes(action.type)) {
        request(action);
      }

      return result;
    };
  }) as Middleware;
};

export default Debouncer;
