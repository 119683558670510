import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import {
  Borrower,
  LoanOriginationSystemBorrowersApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import { CreateBorrowerAction, createBorrowerFailure, createBorrowerSuccess } from './ActionCreator';
import { LoanOriginationSystemCreateBorrowerTypes } from './ActionTypes';

const CreateBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower, CreateBorrowerAction, ReduxState>(
    LoanOriginationSystemCreateBorrowerTypes.CreateBorrower,
    createBorrowerSuccess,
    createBorrowerFailure,
    async (action) => {
      return api.createBorrower(action.payload.borrowerType, action.payload.data);
    },
  );

export default CreateBorrowerMiddleware;
