import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemApplicationsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { GetApplicationsCountAction, getApplicationsCountFailed, getApplicationsCountSuccess } from './ActionCreator';
import BillingPageActionType from './ActionTypes';

const GetApplicationsCountMiddleware = (api: LoanOriginationSystemApplicationsApi) =>
  Fetcher<number, GetApplicationsCountAction>(
    BillingPageActionType.GetApplicationsCount,
    getApplicationsCountSuccess,
    getApplicationsCountFailed,
    (action) => {
      return api.getCount({
        createdDateRange: action.payload.createdDate,
      });
    },
  );

export default GetApplicationsCountMiddleware;
