/* eslint-disable no-param-reassign */

import { RefObject } from 'react';

const SCROLL_DURATION_DEFAULT_VALUE = 1000;

const easeInOutQuad = (currentTime: number, start: number, delta: number, duration: number) => {
  currentTime /= duration / 2;
  if (currentTime < 1) {
    return (delta / 2) * currentTime * currentTime + start;
  }
  currentTime--;
  return (-delta / 2) * (currentTime * (currentTime - 2) - 1) + start;
};

export const scrollTo = (
  to: number,
  ref: RefObject<HTMLElement>,
  position: number,
  duration: number = SCROLL_DURATION_DEFAULT_VALUE,
) => {
  const move = (value: number) => {
    if (ref.current) {
      ref.current.scrollTop = value;
    }
  };
  let time = 0;
  const start = position;
  const delta = to - start;
  let currentTime = 0;
  const increment = 20;
  const animateScroll = (timestamp: number) => {
    currentTime += increment;
    time = timestamp;
    const val = easeInOutQuad(currentTime, start, delta, duration);
    move(val);
    if (currentTime < duration) {
      requestAnimationFrame(animateScroll);
    }
  };
  animateScroll(time);
};
