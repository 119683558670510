import React, { useEffect, useMemo, useState } from 'react';
import { Label, LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import ExtendedTagLine from 'components/LoanOriginationSystem/ExtendedTagLine';
import AutoCompletionMultiselect, { AutoCompletionProps } from 'components/AutoCompletionMultiselect';
import { useLabelsApi } from 'providers/ApiServiceProvider';
import styles from './LabelsMultiSelect.module.scss';

export type LabelsMultiSelectProps = Omit<AutoCompletionProps, 'options'>;

const LabelsMultiSelect = ({ selectedOptions, ...restProps }: LabelsMultiSelectProps) => {
  const [labels, setLabels] = useState<Label[]>([]);

  const labelsApi = useLabelsApi();

  const fetchLabels = async () => {
    const { items } = await labelsApi.getLabels({
      sortingType: {
        field: LabelsSortingField.LabelName,
        ascending: true,
      },
    });

    setLabels(items);
  };

  useEffect(() => {
    fetchLabels();
  }, []);

  const labelOptions = useMemo(() => {
    return labels.map((item, index) => {
      const icon = <ExtendedTagLine key={index} title={item.name} color={item.color} />;

      return {
        value: item.id,
        name: item.name,
        componentOption: icon,
        color: item.color,
      };
    });
  }, [labels]);

  return (
    <AutoCompletionMultiselect
      {...restProps}
      selectedOptions={labels.length ? selectedOptions : []}
      options={labelOptions}
      containerClassName={styles.container}
      selectContainerClassName={styles.selectContainer}
      optionClassName={styles.option}
    />
  );
};

export default LabelsMultiSelect;
