const normalizeEntityArray = <Entity extends { id: string }>(array: Array<Entity>) => {
  return array.reduce(
    (entitiesById, entity) => ({
      ...entitiesById,
      [entity.id]: entity,
    }),
    {} as Record<string, Entity>,
  );
};

export default normalizeEntityArray;
