import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './PageContent.module.scss';

interface PageContentProps {
  className?: string;
  children: ReactNode;
  noPadding?: boolean;
}

const PageContent: FC<PageContentProps> = ({ className, noPadding = false, ...props }) => {
  const mergedClassName = clsx(styles.pageContent, !noPadding && styles.pagePaddings, className);

  return <div className={mergedClassName} {...props} />;
};

export default PageContent;
