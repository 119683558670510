import { IndividualProcessingHistoryCase, ItemsWithTotalCount, StrategiesListItem, TableViewData } from 'api/Types';
import { DecisionRunsSortingFieldType } from 'ApplicationDecisionRuns/types';
import LoanOriginationSystemApi from 'api/LoanOriginationSystem/LoanOriginationSystemApi';

export interface ApplicationDecisionRunsApi {
  getAll(
    applicationId: string,
    sortingType: DecisionRunsSortingFieldType,
  ): Promise<ItemsWithTotalCount<IndividualProcessingHistoryCase>>;
  getStrategyList(): Promise<StrategiesListItem[]>;
}

interface ApplicationDecisionRunsResponse {
  items: IndividualProcessingHistoryCase[];
  total: number;
}

export default class ApplicationDecisionRunsApiRest extends LoanOriginationSystemApi<IndividualProcessingHistoryCase>
  implements ApplicationDecisionRunsApi {
  protected resourceName = 'applications/decision-runs';

  public async getAll(applicationId: string, sortingType: DecisionRunsSortingFieldType) {
    const params = this.getPaginationUrlSearchParams(undefined, sortingType);
    const { items, total }: TableViewData<IndividualProcessingHistoryCase> = await this.fetch<
      ApplicationDecisionRunsResponse
    >(`/applications/${applicationId}/decision-runs?${params}`);

    return {
      items,
      itemsTotal: total,
    };
  }

  public getStrategyList() {
    return this.fetch<StrategiesListItem[]>(`/${this.resourceName}/strategies`);
  }

  public deleteDecisionRun(id: string) {
    return this.deleteResource(id);
  }
}
