import Debouncer from 'middlewares/Debouncer';
import { getLabels } from './ActionCreator';
import { LoanOriginationSystemLabelsFiltersActionTypes } from './Filters/ActionTypes';

export default Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemLabelsState } = getState();
    const { filters, sortingType } = loanOriginationSystemLabelsState;

    return getLabels({ filters, sortingType });
  },
  [LoanOriginationSystemLabelsFiltersActionTypes.SetSearchInputValue],
);
