import React from 'react';
import RowActions from 'components/RowActions';
import { RowActionsProps } from 'components/RowActions/RowActions';

interface ApplicationRowActionsProps extends Partial<RowActionsProps> {
  onEdit: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  deleteDisabled: boolean;
  editApplicationTitle?: string;
}

const ApplicationRowActions = ({
  onEdit,
  onDelete,
  onDuplicate,
  actions,
  deleteDisabled,
  editApplicationTitle,
  ...rowActionsProps
}: ApplicationRowActionsProps) => {
  return (
    <RowActions
      actions={[
        {
          title: editApplicationTitle || 'Edit Application',
          handler: onEdit,
          danger: false,
          separatorRequired: false,
        },
        {
          title: 'Duplicate Application',
          handler: onDuplicate,
          danger: false,
          separatorRequired: true,
        },
        {
          title: 'Delete Application',
          danger: true,
          handler: onDelete,
          disabled: deleteDisabled,
          tooltip: deleteDisabled ? 'You need Permission to delete application' : undefined,
        },
        ...(actions || []),
      ]}
      {...rowActionsProps}
    />
  );
};

export default ApplicationRowActions;
