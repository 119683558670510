import { ApplicationHistoryApi } from 'api/LoanOriginationSystem/ApplicationHistoryApi';
import historyPagination from 'components/LoanOriginationSystem/ApplicationTabs/HistoryTab/pagination';

export default (api: ApplicationHistoryApi) => {
  return historyPagination.getItemsFetcherMiddleware(async (params) => {
    const { items } = await api.getHistoryRecords(
      {
        applicationId: params.applicationId,
        selectedMembers: params.selectedMembers,
        filterType: params.filterType,
        count: params.perPage,
        offset: (params.page - 1) * params.perPage,
      },
      params.sortingType,
    );
    return items;
  });
};
