import { DataFilter, TableViewData } from 'api/Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';

export enum TaskType {
  // standard
  AssignLabelToNotUpdatingApplicationTask = 'AssignLabelToNotUpdatingApplicationTask',
  AssignLabelToOldApplicationTask = 'AssignLabelToOldApplicationTask',
  EmailCustomerWithActiveApplicationDailyTask = 'EmailCustomerWithActiveApplicationDailyTask',
  EmailOnApplicationApprovedTask = 'EmailOnApplicationApprovedTask',
  EmailOnApplicationReceivedTask = 'EmailOnApplicationReceivedTask',
  EmailOnApplicationRejectedTask = 'EmailOnApplicationRejectedTask',
  RejectOldApplicationTask = 'RejectOldApplicationTask',
  ReopenBorrowerToNewApplicationsTask = 'ReopenBorrowerToNewApplicationsTask',

  // custom
  AbandonedVerificationApplicationTask = 'AbandonedVerifingApplicationTask',
  CancelNeverBeenSignedApplicationTask = 'CancelNeverBeenSignedApplicationTask',
  EmailOnApplicationSubmittedForManualReviewTask = 'EmailOnApplicationSubmittedForManualReviewTask',
  RunStrategyOnApplicationTask = 'RunStrategyOnApplicationTask',
  UpdateBearerTokenTask = 'UpdateBearerTokenTask',
}

export enum AutomatedProcessCategory {
  Activities = 'activities',
  Emails = 'emails',
  Labels = 'labels',
}

export enum AutomatedProcessGroup {
  Standard = 'standard',
  Custom = 'custom',
}

export interface AutomatedProcessDataFilter extends DataFilter {
  group?: AutomatedProcessGroup;
  category?: AutomatedProcessCategory;
}

export interface AutomatedProcess {
  id: string;
  active: boolean;
  description?: string;
  name?: string;
  type: TaskType;
  group: AutomatedProcessGroup;
  categories: AutomatedProcessCategory[];
  interval: string;
  intervalDescription: string;
  runtimeOptions: {
    numberOfDays?: number;
    template?: string;
    label?: string;
    timezone?: string;
    dataIntegrationIdToUpdate?: string;
    declineReasonToSetName?: string;
    caseName?: string;
    statusToFindName?: string;
    strategyName?: string;
    strategyStatus?: string;
    strategyToApplicationVariablesMapping?: Record<string, string>;
    variablesToUpdate?: Record<string, string>;
  };
}

export interface AutomatedProcessesApi {
  getAutomatedProcesses(filter: AutomatedProcessDataFilter): Promise<TableViewData<AutomatedProcess>>;
  changeActivationStatus(id: string, status: boolean): Promise<AutomatedProcess>;
  updateRuntimeOptions(id: string, runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>): Promise<AutomatedProcess>;
}

export default class AutomatedProcessesRestApi extends LoanOriginationSystemApi<AutomatedProcess>
  implements AutomatedProcessesApi {
  protected resourceName = 'process-automation';

  public async getAutomatedProcesses(filter: AutomatedProcessDataFilter) {
    const params = this.getPaginationUrlSearchParams(filter);

    if (filter.group !== undefined) {
      params.set('group', filter.group);
    }

    if (filter.category !== undefined) {
      params.set('category', filter.category);
    }

    return this.getResources<TableViewData<AutomatedProcess>>(params);
  }

  public async changeActivationStatus(id: string, status: boolean) {
    return this.fetch<AutomatedProcess>(
      `/${this.resourceName}/${id}`,
      'PATCH',
      { active: status },
    );
  }

  public async updateRuntimeOptions(
    id: string,
    runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>,
  ) {
    return this.fetch<AutomatedProcess>(
      `/${this.resourceName}/${id}`,
      'PATCH',
      { runtimeOptions: runtimeOptionsUpdate },
    );
  }
}
