import React, { useEffect, useState } from 'react';
import AddRuleDialogContainer from 'components/AddRuleDialog/Shared/Container';
import AutoCompletion from 'components/AutoCompletion';
import { BranchItemInfo } from 'api/Types';
import { Option } from 'components/SelectInput/SelectInput';
import Button from 'components/Button';
import styles from './ImportEntireRuleset.module.scss';

const BRANCH_SELECT_LABEL = 'Import From (Strategy & Branch Name)';

interface ImportEntireRulesetProps {
  branchOptions: BranchItemInfo[] | null;
  getBranchOptions: () => void;
  isDuplicating?: boolean;
  onSubmit: (option: BranchItemInfo) => void;
}

const ImportEntireRuleset = ({
  branchOptions,
  getBranchOptions,
  isDuplicating = false,
  onSubmit,
}: ImportEntireRulesetProps) => {
  const [selectedBranchKey, setSelectedBranchKey] = useState<string>();

  const handleBranchSelection = ({ value }: Option) => {
    setSelectedBranchKey(value);
  };

  useEffect(() => {
    getBranchOptions();
  }, []);

  const getBranchUniqKey = ({ strategyId, moduleKey, branchIndex }: BranchItemInfo) =>
    `${strategyId}_${moduleKey}_${branchIndex}`;

  const handleSubmit = () => {
    const branchOption = branchOptions!.find((option) => getBranchUniqKey(option) === selectedBranchKey)!;
    onSubmit(branchOption);
  };

  const branchSelectOptions: Option[] = branchOptions
    ? branchOptions.map(
        (option): Option => ({
          name: `${option.strategyName} | ${option.moduleName} | ${option.branchName} (${option.rulesCount} rule${
            option.rulesCount > 1 ? 's' : ''
          })`,
          value: getBranchUniqKey(option),
        }),
      )
    : [];

  return (
    <AddRuleDialogContainer hasTopMargin>
      <AutoCompletion
        value={selectedBranchKey}
        labelTitle={BRANCH_SELECT_LABEL}
        options={branchSelectOptions}
        onChange={handleBranchSelection}
      />
      <Button
        size="form"
        kind="primary"
        className={styles.submitButton}
        onClick={handleSubmit}
        disabled={!selectedBranchKey}
        isLoading={isDuplicating}
      >
        Import
      </Button>
    </AddRuleDialogContainer>
  );
};

export default ImportEntireRuleset;
