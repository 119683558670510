import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { DeleteDecisionWizard } from 'DeleteDecisionWizard/DeleteDecisionWizard';
import { closeDeleteDecisionWizard } from 'DeleteDecisionWizard/actions';
import { deleteDecisionRun } from 'ApplicationDecisionRuns/actions';

interface DeleteDecisionWizardProps {
  onDeleteComplete: () => void;
}

const DeleteDecisionWizardConnector = ({ onDeleteComplete }: DeleteDecisionWizardProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const deleteDecisionRunWizard = useSelector((state: ReduxState) => state.deleteDecisionRunWizard);
  const handleDeleteDecision = (decisionRunId: string) => {
    batch(async () => {
      await dispatchRoutine(deleteDecisionRun(decisionRunId));
      dispatch(closeDeleteDecisionWizard());
      onDeleteComplete();
    });
  };

  if (!deleteDecisionRunWizard.isOpen) {
    return null;
  }

  return (
    <DeleteDecisionWizard
      isLoading={deleteDecisionRunWizard.isLoading}
      closePopUp={() => dispatch(closeDeleteDecisionWizard())}
      decisionRunId={deleteDecisionRunWizard.decisionRunId!}
      onDeleteDecisionRun={handleDeleteDecision}
    />
  );
};

export default DeleteDecisionWizardConnector;
