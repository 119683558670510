import { EntityType } from 'RuleCreate/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import {
  CLOSE_DELETE_RULE_POP_UP,
  DELETE_RULE,
  DELETE_RULE_FAILURE,
  DELETE_RULE_SUCCESS,
  OPEN_DELETE_RULE_POP_UP,
} from './ActionTypes';
import { CompletedDeleteRuleData, DeleteRuleAction } from './Types';

export const deleteRule = (payload: CompletedDeleteRuleData): DeleteRuleAction => ({ type: DELETE_RULE, payload });

export const deleteRuleSuccess = (payload: CompletedDeleteRuleData) => ({ type: DELETE_RULE_SUCCESS, payload });

export const deleteRuleFailure = FailedActionCreator(DELETE_RULE_FAILURE);

export const openDeleteRulePopUp = (payload: { ruleIndex: number; entityType: EntityType }) => ({
  type: OPEN_DELETE_RULE_POP_UP,
  payload,
});

export const closeDeleteRulePopUp = () => ({ type: CLOSE_DELETE_RULE_POP_UP });
