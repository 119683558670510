import React, { FC } from 'react';
import clsx from 'clsx';
import { EditImage } from 'static/images';
import styles from './EditLink.module.scss';
import { Link } from 'react-router-dom';

interface EditLinkProps {
  className?: string;
  href: string;
}

const EditLink: FC<EditLinkProps> = ({ className, children, href }) => {
  return (
    <div className={styles.editLinkContainer}>
      <Link className={clsx(styles.editLink, className)} to={href}>
        <EditImage className={styles.editImage} />
        {children}
      </Link>
    </div>
  );
};

export default EditLink;
