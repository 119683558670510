import React from 'react';
import clsx from 'clsx';
import styles from './DndListPlaceholder.module.scss';

interface PlaceholderProps {
  className?: string;
  style: React.CSSProperties;
}

const DndListPlaceholder = ({ style, className }: PlaceholderProps) => {
  return <div className={clsx(styles.placeholder, className)} style={style} />;
};

export default DndListPlaceholder;
