import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationVariableConfiguration,
  ApplicationVariableConfigurationsApi,
} from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import {
  UpdateApplicationVariableConfigurationAction,
  updateApplicationVariableConfigurationSuccess,
  updateApplicationVariableConfigurationFailure,
} from './ApplicationVariableConfigurationsActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const UpdateApplicationVariableConfigurationMiddleware = (api: ApplicationVariableConfigurationsApi) =>
  Fetcher<ApplicationVariableConfiguration, UpdateApplicationVariableConfigurationAction>(
    VariablesConfigurationActionType.UpdateApplicationVariableConfiguration,
    updateApplicationVariableConfigurationSuccess,
    updateApplicationVariableConfigurationFailure,
    (action) => {
      return api.update(action.payload.id, action.payload.params);
    },
  );

export default UpdateApplicationVariableConfigurationMiddleware;
