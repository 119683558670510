export enum LoanOriginationSystemIntermediaryDetailsActionTypes {
  GetIntermediaryData = 'loanOriginationSystemIntermediaryDetails/getIntermediaryData',
  GetIntermediaryDataSuccess = 'loanOriginationSystemIntermediaryDetails/getIntermediaryDataSuccess',
  GetIntermediaryDataFailure = 'loanOriginationSystemIntermediaryDetails/getIntermediaryDataFailure',
  ResetIntermediaryDetails = 'loanOriginationSystemIntermediaryDetails/resetIntermediaryDetails',
  GetIntermediaryApplications = 'loanOriginationSystemIntermediaryDetails/getIntermediaryApplications',
  GetIntermediaryApplicationsSuccess = 'loanOriginationSystemIntermediaryDetails/getIntermediaryApplicationsSuccess',
  GetIntermediaryApplicationsFailure = 'loanOriginationSystemIntermediaryDetails/getIntermediaryApplicationsFailure',
  SortIntermediaryApplications = 'loanOriginationSystemIntermediaryDetails/sortIntermediaryApplications',
  SelectIntermediaryViewTab = 'loanOriginationSystemIntermediaryDetails/SelectIntermediaryViewTab',
}
