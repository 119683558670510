import OrganizationApi from 'api/Organization';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { batch } from 'react-redux';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { closeCompanyLogoUploadDialog, uploadCompanyLogoFailure, uploadCompanyLogoSuccess } from './Actions';
import { CompanyLogoUploadActionType } from './ActionTypes';

const UploadCompanyLogoMiddleware: (api: OrganizationApi) => Middleware = (api) =>
  Fetcher<any>(
    CompanyLogoUploadActionType.UploadCompanyLogo,
    uploadCompanyLogoSuccess,
    uploadCompanyLogoFailure,
    ({ payload }) => api.updateOrganizationLogo(payload),
  );

export const onChangeLogoSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === CompanyLogoUploadActionType.UploadCompanyLogoSuccess) {
    notification.createNotification(getMessage(MessageType.CompanyLogoUpdated), 'success', dispatch);
    batch(() => {
      dispatch(getOrganizationInfo());
      dispatch(closeCompanyLogoUploadDialog());
    });
  }
  return result;
};

export default UploadCompanyLogoMiddleware;
