import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  DeleteESignTemplateSuccessAction,
  ESignTemplatesActionOrigin,
  getESignTemplates,
  UpdateESignTemplateSuccessAction,
} from 'ESignTemplates/ActionCreator';
import { ESignTemplatesActionType } from 'ESignTemplates/ActionTypes';
import { DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT } from 'components/DocuSign/pagination';
import { DocuSignActionType } from './ActionTypes';
import { ImportDocuSignTemplatesSuccessAction } from './ActionCreator';

const isDeleteTemplateSuccessAction = (action: AnyAction): action is DeleteESignTemplateSuccessAction =>
  action.type === ESignTemplatesActionType.DeleteESignTemplateSuccess &&
  action.meta?.actionOrigin === ESignTemplatesActionOrigin.DocuSignConfiguration;

const isUpdateTemplateSuccessAction = (action: AnyAction): action is UpdateESignTemplateSuccessAction =>
  action.type === ESignTemplatesActionType.UpdateESignTemplateSuccess &&
  action.meta?.actionOrigin === ESignTemplatesActionOrigin.DocuSignConfiguration;

const isImportTemplatesSuccessAction = (action: AnyAction): action is ImportDocuSignTemplatesSuccessAction =>
  action.type === DocuSignActionType.ImportDocuSignTemplatesSuccess;

const reloadTemplatesOnSuccessUpdateMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (
    isDeleteTemplateSuccessAction(action) ||
    isUpdateTemplateSuccessAction(action) ||
    isImportTemplatesSuccessAction(action)
  ) {
    const { docuSign, eSignIntegrations } = getState();
    const { filters, sortingType } = docuSign;

    const actionToDispatch = setActionOrigin(
      getESignTemplates(
        {
          status: filters.selectedStatus,
          search: filters.searchInputValue,
          integrationId: eSignIntegrations.docuSignIntegration?.id,
          members: filters.selectedMembers,
          createdDateRange: filters.createdDateRange,
          updatedDateRange: filters.updatedDateRange,
        },
        sortingType,
        DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
      ),
      ESignTemplatesActionOrigin.DocuSignConfiguration,
    );

    dispatch(actionToDispatch);
  }

  return result;
}) as Middleware;

export default reloadTemplatesOnSuccessUpdateMiddleware;
