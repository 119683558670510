import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getApplicationDocuments, SortApplicationDocumentsAction } from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

const isSortApplicationDocumentsAction = (action: AnyAction): action is SortApplicationDocumentsAction =>
  action.type === ApplicationDocumentsActionType.SortApplicationDocuments;

const sortApplicationDocumentsMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortApplicationDocumentsAction(action)) {
    const { loanOriginationSystemDocuments } = getState();

    dispatch(
      getApplicationDocuments(
        action.payload.applicationId,
        loanOriginationSystemDocuments.search,
        action.payload.sortingType,
      ),
    );
  }

  return result;
}) as Middleware;

export default sortApplicationDocumentsMiddleware;
