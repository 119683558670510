import React, { memo } from 'react';
import getRandomNumber from 'utils/random';
import styles from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody/ColumnBody.module.scss';
import SkeletonCard from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/Column/ColumnBody/SkeletonCard';

const MIN_CARDS_AMOUNT = 3;
const MAX_CARDS_AMOUNT = 6;

const SkeletonColumnBody = memo(() => {
  const randomNumber = getRandomNumber(MIN_CARDS_AMOUNT, MAX_CARDS_AMOUNT);
  const cardsArray = new Array(randomNumber).fill(null);

  return (
    <div className={styles.content}>
      <div>
        {cardsArray.map((item, index) => (
          <SkeletonCard key={index} className={styles.card} />
        ))}
      </div>
    </div>
  );
});

export default SkeletonColumnBody;
