import React from 'react';
import SignInForm from 'components/Auth/SignInForm/SignInForm';
import { useAuthProvider } from 'providers/AuthProvider';
import AuthLayout from 'components/Auth/AuthLayout';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { GetUserInfoRequest } from 'AccountDetails/AccountDetailsActionCreator';
import { getOrganizations } from 'UserOrganizations/UserOrganizationsActionCreators';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';
import { SignInData } from 'api/Core/AuthApi';

const SignIn = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const authProvider = useAuthProvider();

  const handleSignIn = async (data: SignInData): Promise<void> => {
    try {
      await authProvider.signIn(data);
      await dispatchRoutine(GetUserInfoRequest());
      dispatch(getOrganizations());
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  return (
    <AuthLayout title={`Welcome!\nPlease Sign In`}>
      <SignInForm onSubmit={handleSignIn} />
    </AuthLayout>
  );
};

export default SignIn;
