import React from 'react';
import { RelatedApplicationsTableColumnSizes } from 'components/LoanOriginationSystem/RelatedApplicationsTable/TableColumnSizes';
import { ApplicationTableRowProps } from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable/ApplicationTableRow';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatDisplayId from 'utils/userIdFormat';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import ApplicationLabels from 'components/LoanOriginationSystem/ApplicationLabels';
import { getFormattedLoanAmountCurrency } from 'LoanOriginationSystemOrganization/Utils';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import styles from './RelatedApplicationTableRow.module.scss';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { StandardVariables } from 'Variables/VariablesTypes';
import WrapperWithTooltip from 'components/Tooltip';
import { DuplicateImage } from 'static/images';
import CoBorrowerInfo from 'components/LoanOriginationSystem/CoBorrowerInfo';

export interface RelatedApplicationTableRowProps
  extends Omit<ApplicationTableRowProps, 'currencySymbol' | 'deleteDisabled'> {
  standardVariables: StandardVariables;
}

const RelatedApplicationTableRow = ({ application, standardVariables, onClick }: RelatedApplicationTableRowProps) => {
  const loanAmountDisplayValue = formatMonetaryValue(
    application.variables[ApplicationDefaultVariable.LoanAmount] as number,
    getFormattedLoanAmountCurrency(standardVariables) || '',
  );

  return (
    <TableRow key={application.id} onClick={() => onClick(application)}>
      <TableBodyCell width={RelatedApplicationsTableColumnSizes.BorrowerFullName} className={styles.nameCell} noPadding>
        <OverflowedText className={styles.nameLine}>
          <div className={styles.label}>{getBorrowerFullName(application.borrowerType, application.variables)}</div>
          {application.coborrowerType && (
            <CoBorrowerInfo
              coborrowerType={application.coborrowerType}
              variables={application.variables}
              withHoverableTooltip
            />
          )}
        </OverflowedText>
      </TableBodyCell>
      <TableBodyCell width={RelatedApplicationsTableColumnSizes.Product} className={styles.productCell} noPadding>
        <ApplicationLabels labels={application.labels} />
        <OverflowedText>{application.product.name}</OverflowedText>
      </TableBodyCell>
      <TableBodyCell
        overflowContainerClassName={styles.displayId}
        width={RelatedApplicationsTableColumnSizes.Id}
        overflowed
      >
        {formatDisplayId(application.displayId)}
        {application.originalApplicationId && (
          <WrapperWithTooltip tooltip="Duplicated Application">
            <DuplicateImage className={styles.duplicateIcon} />
          </WrapperWithTooltip>
        )}
      </TableBodyCell>
      <TableBodyCell width={RelatedApplicationsTableColumnSizes.ApplicationDate} overflowed>
        {formatDate(application.createdAt, DateTimeFormat.Short)}
      </TableBodyCell>
      <TableBodyCell width={RelatedApplicationsTableColumnSizes.Status} overflowed>
        {application.status.name}
      </TableBodyCell>
      <TableBodyCell
        width={RelatedApplicationsTableColumnSizes.LoanAmount}
        className={styles.loanAmountCell}
        overflowed
      >
        {loanAmountDisplayValue}
      </TableBodyCell>
    </TableRow>
  );
};

export default RelatedApplicationTableRow;
