import React, { ReactNode } from 'react';
import { PageFooter } from './index';
import Footer from 'components/Footer';
import PageWrapper from './PageWrapper';

interface PageWrapperWithFooterProps {
  children: ReactNode;
  footerClassName?: string;
}

const PageWrapperWithFooter = ({ children, footerClassName }: PageWrapperWithFooterProps) => {
  return (
    <PageWrapper>
      {children}
      <PageFooter className={footerClassName}>
        <Footer separator />
      </PageFooter>
    </PageWrapper>
  );
};

export default PageWrapperWithFooter;
