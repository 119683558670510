import React, { FC, useState } from 'react';
import styles from './ExternalIntegration.module.scss';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import AutoCompletion from 'components/AutoCompletion';
import { Integration } from 'api/DecisionEngineStrategiesType';
import { Option } from 'components/SelectInput/SelectInput';

interface ExternalIntegrationSelectorProps {
  integrationsList: Integration[];
  setSelectedIntegration: (integrationName: string) => void;
}

const ExternalIntegrationSelector: FC<ExternalIntegrationSelectorProps> = ({
  integrationsList,
  setSelectedIntegration,
}) => {
  const [integration, setIntegration] = useState('');

  if (!integrationsList.length) {
    return (
      <div className={styles.contactUs}>
        You don't have any integrations set up yet. Please&nbsp;
        <ExternalLink href="https://www.digifi.io/contact-support">contact us</ExternalLink> for assistance.
      </div>
    );
  }

  const handleIntegrationChange = ({ value }: Option) => {
    const selectedIntegration = integrationsList.find((integrationItem) => {
      return integrationItem.value === value;
    })!;

    setIntegration(value);
    setSelectedIntegration!(selectedIntegration.name);
  };

  return (
    <div className={styles.integrationInput}>
      <AutoCompletion
        labelTitle="Data Integration Name"
        value={integration}
        onChange={handleIntegrationChange}
        options={integrationsList}
        optionsListClassName={styles.integrationOptions}
        lightLabel
      />
    </div>
  );
};

export default ExternalIntegrationSelector;
