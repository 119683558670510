import React, { ReactNode } from 'react';

import styles from './ButtonsFormRow.module.scss';

interface ButtonsFormRowProps {
  children: ReactNode;
}

const ButtonsFormRow = ({ children }: ButtonsFormRowProps) => <div className={styles.buttonContainer}>{children}</div>;

export default ButtonsFormRow;
