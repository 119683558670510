import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemDeclineReasonsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemDeclineReasonsApi';
import { GetDeclineReasonsAction, getDeclineReasonsFailure, getDeclineReasonsSuccess } from './ActionCreator';
import { DeclineReasonsActionType } from './ActionTypes';
import { DeclineReasonByProduct } from './Types';

const GetDeclineReasonsMiddleware = (api: LoanOriginationSystemDeclineReasonsApi) =>
  Fetcher<DeclineReasonByProduct, GetDeclineReasonsAction>(
    DeclineReasonsActionType.GetDeclineReasons,
    getDeclineReasonsSuccess,
    getDeclineReasonsFailure,
    async (action) => {
      const declineReasons = await api.getAll(action.payload.productId);

      return {
        productId: action.payload.productId,
        declineReasons,
      };
    },
  );

export default GetDeclineReasonsMiddleware;
