import { SendGridIntegration } from 'SendGrid/Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';

class SendGridIntegrationApi extends LoanOriginationSystemApi<SendGridIntegration> {
  protected resourceName = 'email-integrations';

  getSendGridIntegration(): Promise<SendGridIntegration> {
    return this.fetch<SendGridIntegration>(`/${this.resourceName}`);
  }

  saveSendGridIntegration(integration: SendGridIntegration): Promise<SendGridIntegration> {
    return this.fetch<SendGridIntegration>(
      `/${this.resourceName}`,
      'POST',
      integration,
    );
  }
}

export default SendGridIntegrationApi;
