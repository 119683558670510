import React from 'react';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonProductsListItem.module.scss';

const SkeletonProductListItem = () => (
  <div className={styles.container}>
    <div>
      <SkeletonRectangle width="100px" height="12px" color="primary6" marginBottom="6px" />
      <div className={styles.header}>
        <SkeletonRectangle width="230px" height="16px" color="primary20" marginRight="10px" />
        <SkeletonRectangle width="51px" height="16px" color="primary6" />
      </div>
      <div className={styles.footer}>
        <SkeletonCircle className={styles.avatar} width="20px" height="20px" color="primary6" />
        <SkeletonRectangle width="180px" height="16px" color="primary6" />
      </div>
    </div>
    <div>
      <SkeletonCircle width="60px" height="60px" color="primary6" />
    </div>
  </div>
);

export default SkeletonProductListItem;
