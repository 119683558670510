import React from 'react';
import SkeletonHistoryLine from './SkeletonHistoryLine';
import { IndividualProcessingHistoryCase } from 'api/Types';
import HistoryLine from 'components/StrategyRunHistoryTable/HistoryLine';
import { SIZES } from 'components/StrategyRunHistoryTable/columnSizes';
import { NullableItems, PaginationProps } from 'pagination';
import Pagination from 'components/Pagination/Pagination';
import Table from 'components/Table';
import TableHead from 'components/Table/TableHead';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableBody from 'components/Table/TableBody';
import TableActionCell from 'components/Table/TableActionCell';
import TableBodyContent from 'components/Table/TableBodyContent';
import { RowAction } from 'components/RowActions/RowActions';
import useSorting from 'hooks/useSorting';
import { DecisionRunsSortingField, DecisionRunsSortingFieldType } from 'ApplicationDecisionRuns/types';
import { StrategyRunTableColumnDisplayName } from 'components/StrategyRunHistoryTable/types';

interface StrategyRunHistoryTableProps {
  individualCases: NullableItems<IndividualProcessingHistoryCase>;
  paginationProps: PaginationProps;
  getRowActions: (individualCase: IndividualProcessingHistoryCase) => RowAction[];
  sortableFieldKeys?: string[];
  onSort?: (fieldName: DecisionRunsSortingField, ascending: boolean) => void;
  searchInputValue?: string;
  sortingType?: DecisionRunsSortingFieldType;
  stickyMarginTop?: number;
}

const DisplayNameToFieldNameMapping = {
  [StrategyRunTableColumnDisplayName.Date]: DecisionRunsSortingField.Date,
  [StrategyRunTableColumnDisplayName.DecisionName]: DecisionRunsSortingField.DecisionName,
  [StrategyRunTableColumnDisplayName.Result]: DecisionRunsSortingField.Result,
  [StrategyRunTableColumnDisplayName.StrategyName]: DecisionRunsSortingField.StrategyName,
  [StrategyRunTableColumnDisplayName.Type]: DecisionRunsSortingField.Type,
};

const StrategyRunHistoryTable = ({
  individualCases,
  paginationProps,
  getRowActions,
  sortableFieldKeys = [],
  searchInputValue = '',
  onSort = () => undefined,
  sortingType = { field: DecisionRunsSortingField.Date, ascending: false },
  stickyMarginTop,
}: StrategyRunHistoryTableProps) => {
  const [changeSorting, getSortingType] = useSorting<DecisionRunsSortingField>(
    sortingType.field,
    sortingType.ascending,
    onSort,
  );

  const handleChangeSorting = (fieldKeyName: DecisionRunsSortingField) => {
    changeSorting(fieldKeyName);
  };

  const renderRow = (individualCase: IndividualProcessingHistoryCase) => (
    <HistoryLine
      key={individualCase.id}
      getRowActions={getRowActions}
      individualCase={individualCase}
      searchInputValue={searchInputValue}
    />
  );
  const renderTableHeadCell = (fieldDisplayName: StrategyRunTableColumnDisplayName) => {
    const fieldKeyName = DisplayNameToFieldNameMapping[fieldDisplayName];
    return (
      <TableHeadCell
        key={fieldKeyName}
        width={SIZES[fieldDisplayName]}
        {...(sortableFieldKeys.includes(fieldKeyName) ? { onClick: () => handleChangeSorting(fieldKeyName) } : {})}
        ascending={getSortingType(fieldKeyName)}
      >
        {fieldDisplayName}
      </TableHeadCell>
    );
  };

  return (
    <>
      <Table>
        <TableHead useActions sticky stickyMarginTop={stickyMarginTop}>
          {Object.values(StrategyRunTableColumnDisplayName).map(renderTableHeadCell)}
          <TableActionCell />
        </TableHead>
        <TableBody>
          <TableBodyContent
            rows={individualCases}
            renderTableRow={renderRow}
            renderSkeletonTableRow={(index: number) => <SkeletonHistoryLine key={index} />}
          />
        </TableBody>
      </Table>
      <Pagination {...paginationProps} />
    </>
  );
};

export default StrategyRunHistoryTable;
