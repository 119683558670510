export enum VersionsListDataActions {
  GetVersionsListData = 'versionsViewData/getVersionsData',
  GetVersionsListSuccess = 'versionsViewData/getVersionsDataSuccess',
  GetVersionsListDataFailure = 'versionsViewData/getVersionsDataFailure',
  SetVersionsListDataSearchInput = 'versionsViewData/setStrategyGroupsSearchInput',
  SetShowArchived = 'versionsViewData/setShowArchived',
  ClearListData = 'versionsViewData/clearListData',
  SetUpdaters = 'versionsViewData/setUpdaters',
  SetSortingType = 'versionsViewData/setSortingType',
  ResetFilters = 'versionsViewData/resetFilters',
}
