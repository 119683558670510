import { AnyAction, Middleware } from 'redux';
import { EditTaskSuccessAction } from './ActionCreator';
import { LoanOriginationSystemEditTaskActionTypes } from './ActionTypes';
import { getTasks } from 'LoanOriginationSystemTasks/ActionCreator';
import { ReduxState } from 'types/redux';
import { RoutineHandler } from 'middlewares/Fetcher';

const isEditTaskSuccessAction = (action: AnyAction): action is EditTaskSuccessAction =>
  action.type === LoanOriginationSystemEditTaskActionTypes.EditTaskSuccess;

const EditTaskSuccessMiddleware: Middleware = RoutineHandler(async (action: AnyAction, { dispatch, getState }) => {
  if (isEditTaskSuccessAction(action)) {
    const { loanOriginationSystemTasks } = getState() as ReduxState;

    const { filters, sortingType, selectedApplicationId: applicationId } = loanOriginationSystemTasks;

    dispatch(getTasks({ filters, sortingType, applicationId, notReload: true }));
  }
});

export default EditTaskSuccessMiddleware;
