import React, { useState, FC, useMemo, useEffect, FocusEvent } from 'react';
import moment, { Moment } from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core';
import DatePickerInput from './Input';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';
import clsx from 'clsx';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import getDefaultDateFormat from 'utils/getDefaultDateFormat';
import getCustomDateFormat from 'utils/getCustomDateFormat';
import styles from './DatePickerWrapper.module.scss';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: styles.palettePrimaryMain,
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        borderRadius: '6px',
        transition: '0.25s color ease-in-out, 0.25s background-color ease-in-out',
        '&:hover': {
          backgroundColor: styles.hoverColor,
          color: styles.palettePrimaryMain,
        },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#000000',
      },
      caption: {
        fontWeight: 'bold',
        fontSize: '9px',
        lineHeight: '12px',
        letterSpacing: '0.1em',
        color: styles.captionColor,
      },
      body2: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
      },
      subtitle1: {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '28px',
        lineHeight: '36px',
        fontWeight: 'bold',
      },
    },
    // @ts-ignore
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: styles.toolbarTextColor,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: styles.dayOfWeekColor,
      },
    },
    MuiPickersDay: {
      day: {
        color: styles.dayNumberColor,
      },
      current: {
        border: `2px solid ${styles.palettePrimaryMain}`,
        color: styles.palettePrimaryMain,
        '&:hover': {
          border: `2px solid ${styles.palettePrimaryMain}`,
          color: styles.palettePrimaryMain,
          backgroundColor: styles.currentDay,
        },
      },
      daySelected: {
        '&:hover': {
          color: styles.currentDay,
        },
      },
    },
  },
  typography: {
    fontFamily: styles.fontFamily,
  },
});

interface DatePickerProps {
  value: string;
  labelTitle?: string;
  onChange: (value: string) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  dateFormat?: string;
  placeholder?: string;
  showLoader?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
  tabIndex?: number;
}

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

const DatePickerWrapper: FC<DatePickerProps> = ({
  value,
  labelTitle,
  disabled,
  onChange,
  dateFormat = DEFAULT_DATE_FORMAT,
  onFocus,
  onBlur,
  placeholder = dateFormat,
  showLoader,
  loaderState,
  onLoaderStateReset,
  tabIndex,
}) => {
  const UtilsClass = useMemo(() => {
    return class LocalizedUtils extends MomentAdapter {
      getCalendarHeaderText(date: Moment): string {
        return date.format('MMMM, YYYY');
      }

      // rewrite added to add date format that is not normally used by function
      date(dateString: string | null): Moment {
        // copied from original function
        if (dateString === null) {
          return (null as unknown) as Moment;
        }

        return dateString ? moment(dateString, dateFormat, true) : moment();
      }
    };
  }, [dateFormat]);

  const isValidCurrentDateFormat = (dateString: string) => {
    const date = moment(dateString, dateFormat, true);
    return date.isValid();
  };

  const isValidDefaultDateFormat = (dateString: string) => {
    const date = moment(dateString, DEFAULT_DATE_FORMAT, true);
    return date.isValid();
  };

  const formatInputValue = (dateString: string) => {
    if (!dateString) {
      return null;
    }

    return isValidDefaultDateFormat(dateString) ? getCustomDateFormat(dateString, dateFormat) : dateString;
  };

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [inputValue, setInputValue] = useState(formatInputValue(value));
  const onCalendarIconClick = () => setIsCalendarOpen(!disabled && !isCalendarOpen);

  const handleChange = (date: MaterialUiPickersDate, val?: string | null) => {
    if (!val) {
      onChange('');
    } else if (isValidCurrentDateFormat(val)) {
      onChange(getDefaultDateFormat(val, dateFormat));
    } else {
      setInputValue(formatInputValue(val));
    }
  };

  useEffect(() => {
    setInputValue(formatInputValue(value));
  }, [value]);

  const TextFieldComponent = DatePickerInput as React.ComponentType<TextFieldProps>;
  const textFieldProps = {
    showLoader,
    loaderState,
    onLoaderStateReset,
    onIconClick: onCalendarIconClick,
    tabIndex,
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={UtilsClass}>
        <div className={clsx(styles.datePickerContainer, !labelTitle && styles.noLabel)}>
          <KeyboardDatePicker
            TextFieldComponent={TextFieldComponent}
            open={isCalendarOpen}
            onClose={() => setIsCalendarOpen(false)}
            format={dateFormat}
            label={labelTitle}
            value={inputValue}
            inputValue={inputValue || undefined}
            onChange={handleChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoOk
            variant="inline"
            placeholder={placeholder}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            disabled={disabled}
            {...textFieldProps}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DatePickerWrapper;
