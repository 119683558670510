import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { EventEmitter } from 'events';
import { useDispatch } from 'react-redux';
import { tap } from 'lodash';
import { Routine } from 'routine/Types';

const EVENT_NAME_RELEASE = 'release';

export const isRoutine = <RoutineId>(action: AnyAction): action is Routine<RoutineId> => action.meta?.routineId;

type RoutineResultSetter<Result> = (key: string | symbol, result: Result) => void;

type ResultProvider<Result> = (result: Result) => void;

type ActionHandler<State, Result> = (
  action: AnyAction,
  store: MiddlewareAPI<State>,
  resultProvider: ResultProvider<Result>,
) => Promise<unknown>;

type RoutineHandlerWrapper = <State = any, Result = any>(
  handler: ActionHandler<State, Result>,
  key?: string | symbol,
) => Middleware;

type PromiseExecutorResolveFn<Result> = (result: Result) => void;
type PromiseExecutorRejectFn = (reason: Error) => void;
type RoutineResolver = (
  meta: RoutineMeta,
  resolve: PromiseExecutorResolveFn<RoutineResults>,
  reject: PromiseExecutorRejectFn,
) => void;

type RoutineResults = Record<string | symbol, unknown>;

interface RoutineMeta {
  holdsAcquired: number;
  results: RoutineResults;
  didThrow: boolean;
}

export interface RoutineAPI<RoutineId> {
  Dispatcher: (dispatch: Dispatch<AnyAction>) => (action: AnyAction) => Promise<RoutineResults>;
  DispatcherWithResult: (dispatch: Dispatch<AnyAction>) => (action: AnyAction) => Promise<RoutineResults>;
  assignRoutineId: <Action extends AnyAction>(action: Action, routineId: RoutineId) => Action & Routine<RoutineId>;
  useDispatchRoutine: () => (action: AnyAction) => Promise<RoutineResults>;
  useDispatchRoutineWithResult: () => (action: AnyAction) => Promise<RoutineResults>;
  RoutineHandler: RoutineHandlerWrapper;
  acquireHold: (routineId: RoutineId) => void;
  releaseHold: (routineId: RoutineId, isSuccess: boolean, key?: string, result?: any) => void;
}

const Routines = <RoutineId>(makeRoutineId: () => RoutineId): RoutineAPI<RoutineId> => {
  const assignRoutineId = <Action extends AnyAction>(
    action: Action,
    routineId: RoutineId,
  ): Action & Routine<RoutineId> => {
    const { meta = {} } = action;
    return {
      ...action,
      meta: {
        ...meta,
        routineId,
      },
    };
  };

  const routines = new Map<RoutineId, RoutineMeta>();

  const events = new EventEmitter();

  events.on(EVENT_NAME_RELEASE, (routineId) => {
    routines.delete(routineId);
  });

  const ResultSetter = <Result>(routineId: RoutineId): RoutineResultSetter<Result> => {
    return (key: string | symbol, result: Result) => {
      const meta: RoutineMeta = routines.get(routineId)!;
      const { results } = meta;
      routines.set(routineId, {
        ...meta,
        results: {
          ...results,
          [key]: result,
        },
      });
    };
  };

  const acquireHold = <Result>(routineId: RoutineId): RoutineResultSetter<Result> => {
    const meta: RoutineMeta = routines.get(routineId) || {
      holdsAcquired: 0,
      results: {},
      didThrow: false,
    };
    routines.set(routineId, {
      ...meta,
      holdsAcquired: meta.holdsAcquired + 1,
    });
    return ResultSetter(routineId);
  };

  const releaseHold = (routineId: RoutineId, isSuccess: boolean) => {
    if (!routines.has(routineId)) {
      return;
    }

    const meta = routines.get(routineId)!;

    const holdsAcquired = meta.holdsAcquired - 1;

    const newMeta = {
      ...meta,
      holdsAcquired,
      didThrow: meta.didThrow || !isSuccess,
    };

    routines.set(routineId, newMeta);

    if (holdsAcquired === 0) {
      events.emit(EVENT_NAME_RELEASE, routineId, newMeta);
    }
  };

  const DispatcherFactory = (resolver: RoutineResolver) => (dispatch: Dispatch<AnyAction>) => (action: AnyAction) =>
    new Promise<RoutineResults>((resolve, reject) => {
      const routineId = makeRoutineId();

      const onRelease = (releasedRoutineId: RoutineId, meta: RoutineMeta) => {
        if (releasedRoutineId !== routineId) {
          return;
        }
        events.removeListener(EVENT_NAME_RELEASE, onRelease);
        resolver(meta, resolve, reject);
      };

      events.on(EVENT_NAME_RELEASE, onRelease);

      dispatch(assignRoutineId(action, routineId));

      if (!routines.has(routineId)) {
        events.removeListener(EVENT_NAME_RELEASE, onRelease);
        resolver(
          {
            holdsAcquired: 0,
            results: {},
            didThrow: false,
          },
          resolve,
          reject,
        );
      }
    });

  const Dispatcher = DispatcherFactory((meta, resolve, reject) => {
    if (meta.didThrow) {
      reject(new Error('Routine did throw'));
    } else {
      resolve(meta.results);
    }
  });

  const useDispatchRoutine = () => {
    const dispatch = useDispatch();
    return Dispatcher(dispatch);
  };

  const DispatcherWithResult = DispatcherFactory((meta, resolve) => {
    resolve(meta.results);
  });

  const useDispatchRoutineWithResult = () => {
    const dispatch = useDispatch();
    return DispatcherWithResult(dispatch);
  };

  const RoutineHandler = <State = any, Result = any>(handler: ActionHandler<State, Result>, key?: string | symbol) => {
    return (({ getState, dispatch }: MiddlewareAPI<State>) => {
      const handleRoutine = async (action: Routine<RoutineId>) => {
        const { routineId } = action.meta;
        const storeResult = acquireHold(routineId);
        const dispatchWithRoutineId: Dispatch<State> = (emittedAction) =>
          dispatch(assignRoutineId(emittedAction, routineId));
        const provideResult = (result: Result) => {
          if (key) {
            storeResult(key, result);
          }
        };
        try {
          await handler(action, { getState, dispatch: dispatchWithRoutineId }, provideResult);
          releaseHold(routineId, true);
        } catch (err) {
          releaseHold(routineId, false);
        }
      };

      return (next: Dispatch<State>) => (action: AnyAction) =>
        tap(next(action), () => {
          if (isRoutine<RoutineId>(action)) {
            handleRoutine(action);
          } else {
            handler(action, { getState, dispatch }, () => {});
          }
        });
    }) as Middleware;
  };

  return {
    Dispatcher,
    DispatcherWithResult,
    assignRoutineId,
    useDispatchRoutine,
    useDispatchRoutineWithResult,
    RoutineHandler,
    acquireHold,
    releaseHold,
  };
};

export default Routines;
