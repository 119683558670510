import { CreateAccountInformation } from 'api/Core/AuthApi';
import { validateEmail, validatePassword } from 'utils/validation/validation';
import getMessage from 'constants/messages';

const MIN_LENGTH = 2;

export type AccountInformationStepValidationResult = Partial<CreateAccountInformation>;

export const LABELS = {
  companyName: 'Company Name',
  country: 'Country',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  password: 'Create Password',
};

const validateAccountInformationStep = (
  data: Partial<CreateAccountInformation>,
): AccountInformationStepValidationResult => {
  const errors: AccountInformationStepValidationResult = Object.keys(data).reduce((result, key) => {
    if (data[key].length === 0) {
      return { ...result, [key]: `${LABELS[key]} is required` };
    }

    if (data[key].length < MIN_LENGTH) {
      return { ...result, [key]: `Minimum length is ${MIN_LENGTH}` };
    }

    return result;
  }, {});

  const emailError = validateEmail(data.email || '');
  if (emailError) {
    errors.email = emailError;
  }

  const passwordErrorMessageType = validatePassword(data.password || '');
  if (passwordErrorMessageType) {
    errors.password = getMessage(passwordErrorMessageType);
  }

  return errors;
};

export default validateAccountInformationStep;
