import React, { ChangeEvent, FC } from 'react';
import styles from './ImageUpload.module.scss';

interface ImageUpload {
  onImageUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  title: string;
}

const ImageUpload: FC<ImageUpload> = ({ onImageUpload, title }) =>
(
  <div className={styles.changeImage}>
    <label htmlFor="changeImageInput" className={styles.changeImageLabel}>
      {title}
    </label>
    <input
      type="file"
      id="changeImageInput"
      className={styles.changeImageInput}
      onChange={onImageUpload}
    />
  </div>
)

export default ImageUpload;
