import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getIntermediaries, SortIntermediariesAction } from './ActionCreator';
import { SORT_INTERMEDIARIES } from './ActionTypes';

const isSortIntermediariesAction = (action: AnyAction): action is SortIntermediariesAction =>
  action.type === SORT_INTERMEDIARIES;

const sortIntermediariesMiddleware = (({ dispatch, getState }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortIntermediariesAction(action)) {
    const { loanOriginationSystemIntermediariesState } = getState();

    dispatch(getIntermediaries(loanOriginationSystemIntermediariesState.filters, action.payload));
  }

  return result;
}) as Middleware;

export default sortIntermediariesMiddleware;
