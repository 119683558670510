import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { DataFilter, DataViewSortingType, TableViewData } from './Types';
import { UserInfo } from './LoanOriginationSystemApplicationsApi';
import { RequestUploadParams } from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';
import { DownloadResponse } from 'api/APIRest';

export enum DocumentExtension {
  Pdf = 'pdf',
  Txt = 'txt',
  Doc = 'doc',
  Docx = 'docx',
  Xls = 'xls',
  Xml = 'xml',
  Xlsx = 'xlsx',
  Json = 'json',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Svg = 'svg',
  Jpeg = 'jpeg',
  Png = 'png',
  Jpg = 'jpg',
  Gif = 'gif',
  Csv = 'csv',
  Word = 'word',
}

export interface ApplicationDocument {
  id: string;
  name: string;
  extension: DocumentExtension;
  size: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy?: UserInfo | null;
}

export enum ApplicationDocumentsSortingField {
  Name = 'name',
  Extension = 'extension',
  Size = 'size',
  CreatedAt = 'createdAt',
}

export type ApplicationDocumentsSortingType = DataViewSortingType<ApplicationDocumentsSortingField>;

export interface ApplicationDocumentsApi {
  getAll(
    applicationId: string,
    filters: DataFilter,
    sortingType: ApplicationDocumentsSortingType,
  ): Promise<TableViewData<ApplicationDocument>>;

  upload(applicationId: string, file: File): Promise<ApplicationDocument>;
  rename(applicationId: string, documentId: string, updatedName: string): Promise<void>;
  delete(documentId: string): Promise<void>;
  requestUpload(requestUploadParams: RequestUploadParams): Promise<string>;
  getPublicLink(documentId: string): Promise<string>;
  download(documentId: string): Promise<DownloadResponse>;
  downloadMany(applicationId: string): Promise<DownloadResponse>;
}

export default class ApplicationDocumentsRestApi extends LoanOriginationSystemApi<ApplicationDocument>
  implements ApplicationDocumentsApi {
  public async getAll(applicationId: string, filters: DataFilter, sortingType: ApplicationDocumentsSortingType) {
    const params = this.getPaginationUrlSearchParams(filters, sortingType);

    params.append('applicationId', applicationId);

    return this.fetch<TableViewData<ApplicationDocument>>(`/application-documents?${params}`);
  }

  public async upload(applicationId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('applicationId', applicationId);
    formData.append('document.name', file.name);

    return this.fetch<ApplicationDocument>(`/application-documents`, 'POST', formData, {
      contentType: null,
    });
  }

  public async delete(documentId: string) {
    return this.fetch<void>(`/application-documents/${documentId}`, 'DELETE');
  }

  public async rename(applicationId: string, documentId: string, updatedName: string) {
    return this.fetch<void>(`/application-documents/${documentId}`, 'PUT', {
      document: {
        name: updatedName,
      },
    });
  }

  public async requestUpload(requestUploadParams: RequestUploadParams): Promise<string> {
    return this.fetch('/application-documents/customer-uploads', 'POST', {
      ...requestUploadParams,
    });
  }

  public async getPublicLink(documentId: string) {
    const { token } = await this.fetch(`/application-documents-preview/${documentId}`, 'GET');

    const params = new URLSearchParams();
    params.set('token', token);

    return `${this.origin}/document-preview?${params}`;
  }

  public async download(documentId: string) {
    return super.download(`/application-documents-downloads/${documentId}`);
  }

  public async downloadMany(applicationId: string) {
    const params = new URLSearchParams();

    params.set('applicationId', applicationId);
    return super.download(`/application-documents-downloads/?${params}`);
  }
}
