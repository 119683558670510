import React, { useEffect, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';
import styles from './TasksFilter.module.scss';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import {
  changePopupFilters,
  clearPopupFilters,
  FiltersResult,
  toggleFiltersPopup,
} from 'LoanOriginationSystemTasks/Filters/ActionCreator';
import { TasksFilterOptions, TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';

interface TasksFilterProps {
  dueDateFrom: Date | null;
  dueDateTo: Date | null;
  status: TaskStatus | null;
}

const filterOptions = [
  { name: TasksFilterOptions.All, value: '' },
  { name: TasksFilterOptions.NotDone, value: TaskStatus.NotDone },
  { name: TasksFilterOptions.Done, value: TaskStatus.Done },
];

const TasksFilter = ({ dueDateFrom, dueDateTo, status }: TasksFilterProps) => {
  const dispatch = useDispatch();

  const [selectedFilterInputOption, setSelectedInputFilterOption] = useState(filterOptions[0]);

  const initialSelectedFilterOption = filterOptions.find((option) => (option.value || null) === status)!;

  useEffect(() => {
    setSelectedInputFilterOption(initialSelectedFilterOption);
  }, []);

  const [fromDate, setFromDate] = useState(dueDateFrom);
  const [toDate, setToDate] = useState(dueDateTo);

  const filtersResult: FiltersResult = {
    dueDateRange: {
      from: fromDate,
      to: toDate,
    },
    status: (selectedFilterInputOption.value as TaskStatus) || null,
  };

  const onFilterChange = (newOption: Option) => {
    setSelectedInputFilterOption(filterOptions.find(({ value }) => value === newOption.value)!);
  };

  const onClearFiltersClick = () => {
    dispatch(clearPopupFilters());
  };

  const onFiltersPopupClose = () => {
    dispatch(toggleFiltersPopup());
  };

  const onFiltersApply = () => {
    dispatch(changePopupFilters(filtersResult));
  };

  const handleOnDateRangeChange = (from: Date | null, to: Date | null) => {
    setFromDate(from);
    setToDate(to);
  };

  return (
    <FilterLayout
      onClearFiltersClick={onClearFiltersClick}
      onClose={onFiltersPopupClose}
      filterTitleClassName={styles.filterLayoutTitle}
    >
      <AutoCompletion
        labelTitle="Task Status"
        onChange={onFilterChange}
        options={filterOptions}
        selectedOption={selectedFilterInputOption}
        hideClearIcon
        className={styles.statusDropdownContainer}
      />
      <DateRangePicker from={fromDate} to={toDate} onChange={handleOnDateRangeChange} label="Due Date" />
      <Button kind="primary" size="default" className={styles.applyFilter} onClick={onFiltersApply}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default TasksFilter;
