import React, { FC } from 'react';
import SIZES from 'components/BillingDashboard/Invoices/TableColumnSizes';
import formatDate, { DateTimeFormat } from 'utils/dateFormat';
import formatMonetaryValue from 'utils/formatMonetaryValue';
import { TableRow, TableBodyCell } from 'components/Table';
import { Invoice } from 'api/BillingApi';
import InvoiceStatusTag from 'components/BillingDashboard/Invoices/InvoiceStatusTag';
import RowActions from 'components/RowActions';
import { getCurrencySymbol } from 'components/CurrencySelect/currencies';

interface InvoicesTableRowProps {
  invoice: Invoice;
}

const InvoicesTableRow: FC<InvoicesTableRowProps> = ({ invoice }) => {
  const goToDetails = () => {
    if (invoice.linkToDetails) {
      window.open(invoice.linkToDetails);
    }
  };

  const formattedAmount = formatMonetaryValue(
    invoice.amount,
    (invoice.currency && getCurrencySymbol(invoice.currency.toUpperCase())) || '',
    true,
  );

  return (
    <TableRow onClick={goToDetails} useActions>
      <TableBodyCell width={SIZES.InvoiceDate}>
        {invoice.invoiceDate && formatDate(invoice.invoiceDate, DateTimeFormat.Long)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.DueDate}>
        {invoice.dueDate && formatDate(invoice.dueDate, DateTimeFormat.Long)}
      </TableBodyCell>
      <TableBodyCell width={SIZES.Description} overflowed>
        {invoice.description}
      </TableBodyCell>
      <TableBodyCell width={SIZES.InvoiceNumber}>{invoice.invoiceNumber}</TableBodyCell>
      <TableBodyCell width={SIZES.Amount}>{formattedAmount}</TableBodyCell>
      <TableBodyCell width={SIZES.Status}>
        <InvoiceStatusTag invoice={invoice} />
      </TableBodyCell>
      <RowActions
        actions={[
          {
            title: 'View Invoice',
            handler: goToDetails,
          },
          {
            title: 'Download Invoice',
            handler: () => invoice.linkToPdf && window.open(invoice.linkToPdf),
          },
        ]}
      />
    </TableRow>
  );
};

export default InvoicesTableRow;
