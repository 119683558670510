import React, { FC, useEffect, useState } from 'react';
import { validationApiKey } from 'components/SendGrid/utils/validation/validation';
import { SendGridIntegration } from 'SendGrid/Types';
import CredentialsEditor from './CredentialsEditor';
import CredentialLine from './CredentialLine';
import styles from 'components/SendGrid/SendGrid.module.scss';
import ButtonWithImage from 'components/ButtonWithImage';
import Button from 'components/Button';
import SkeletonCredentialLine from './SkeletonCredentialLine';

interface IntegrationProps {
  integration: SendGridIntegration | null;
  isIntegrationLoaded: boolean;
  onIntegrationSave: (clientApiKey: string) => Promise<void>;
}

const Integration: FC<IntegrationProps> = ({ integration, onIntegrationSave, isIntegrationLoaded }) => {
  const [clientApiKey, setClientApiKey] = useState(integration?.clientApiKey || '');
  const [isBlockingActionInProgress, setIsBlockingActionInProgress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (integration) {
      setClientApiKey(integration.clientApiKey || '');
    }
  }, [integration]);

  const onApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (validationApiKey(e.target.value)) {
      setClientApiKey(e.target.value);
    }
  };

  const isSubmitButtonDisabled = integration?.clientApiKey === clientApiKey;

  const handleSave = async () => {
    try {
      setIsBlockingActionInProgress(true);

      await onIntegrationSave(clientApiKey);
      setIsEdit(false);
    } finally {
      setIsBlockingActionInProgress(false);
    }
  };

  const renderCredentialLine = () => {
    if (!isIntegrationLoaded) {
      return <SkeletonCredentialLine />;
    }

    return <CredentialLine clientApiKey={clientApiKey} />;
  };

  return (
    <>
      <div className={styles.title}>
        <h4>Credentials</h4>
        {isEdit ? (
          <Button className={styles.closeEditorButton} onClick={() => setIsEdit(false)}>
            Close Editor
          </Button>
        ) : (
          <ButtonWithImage
            onClick={() => setIsEdit(true)}
            title="Edit Credentials"
            className={styles.editCredentialsButton}
            kind="edit"
          />
        )}
      </div>
      {isEdit ? (
        <CredentialsEditor
          clientApiKey={clientApiKey}
          onApiKeyChange={onApiKeyChange}
          handleSaveKey={handleSave}
          isBlockingActionInProgress={isBlockingActionInProgress}
          isSubmitButtonDisabled={isSubmitButtonDisabled}
        />
      ) : (
        renderCredentialLine()
      )}
    </>
  );
};

export default Integration;
