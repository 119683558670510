import Debouncer from 'middlewares/Debouncer';
import { getIndividualHistory, getBatchHistory, getBatchCase } from './ActionCreator';
import {
  SET_BATCH_CASE_SEARCH_INPUT,
  SET_BATCH_HISTORY_SEARCH_INPUT,
  SET_INDIVIDUAL_HISTORY_SEARCH_INPUT,
} from './ActionTypes';

const individualHistorySearchMiddleware = Debouncer(getIndividualHistory, [SET_INDIVIDUAL_HISTORY_SEARCH_INPUT]);

const batchHistorySearchMiddleware = Debouncer(getBatchHistory, [SET_BATCH_HISTORY_SEARCH_INPUT]);

const batchCaseSearchMiddleware = Debouncer(
  (action, getState) => getBatchCase(getState().batchProcessingCase.data!.id),
  [SET_BATCH_CASE_SEARCH_INPUT],
);

export { individualHistorySearchMiddleware, batchHistorySearchMiddleware, batchCaseSearchMiddleware };
