import React, { ReactElement, useEffect } from 'react';
import MainLayout, { PageFooter, PageWrapper } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageContent from 'MainLayout/PageContent';
import ApiActivation from 'components/ApiActivation';
import {
  closeApiActivationsPopUp,
  downloadApiTemplate,
  getApiActivationsRequest,
  openApiActivationsPopUp,
  setShowArchived,
  updateActivationsRequest,
  setUpdaters,
  setStrategyGroupsSearchInput,
  sortApiActivation,
  getDropdownGroupedStrategies,
  clearApiActivationData,
} from 'ApiActivation/ActionCreator';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ApiActivationsPopUpType } from 'ApiActivation/Types';
import Footer from 'components/Footer';
import ApiIntegrationPopUp from 'components/ApiActivation/PopUps';
import { getStrategiesList } from 'StrategyProcessing/ActionCreator';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import NavigationLinkId from 'enums/NavigationLinkId';
import { StrategyGroup } from 'api/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { StrategyListParams } from 'api/DecisionEngineStrategiesType';
import pagination from 'pages/ApiActivation/Pagination';
import { ApiActivationSortingFields } from 'DecisionStrategy/DecisionStrategiesTypes';

const ApiActivationPage = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();

  const leftNav = makeLeftNavigation(NavigationLinkId.APIActivation, ApplicationSectionName.DecisionEngine);
  const {
    searchInputValue,
    showArchived,
    selectedUpdaters,
    versionsStatuses,
    popUpType,
    isPopUpOpen,
    updaters,
    sortingType,
    currentGroup,
    versionsList,
  } = useSelector((state: ReduxState) => state.apiActivation);
  const decisionStrategies = useSelector((state: ReduxState) => state.individualProcessing.strategies);

  const groups = pagination.usePaginatedItems({
    searchInputValue,
    showArchived,
    selectedUpdaters,
    versionsStatuses,
    sortingType,
  });

  useEffect(() => {
    dispatch(getApiActivationsRequest());

    return () => {
      dispatch(clearApiActivationData());
    };
  }, []);

  const onOpenApiActivationsPopUp = (typeOfPopUp: ApiActivationsPopUpType, info?: StrategyGroup) => {
    batch(() => {
      dispatch(openApiActivationsPopUp(typeOfPopUp, info));
      if (typeOfPopUp === 'download') {
        dispatch(getStrategiesList());
      }
      if (typeOfPopUp === 'change') {
        dispatch(getDropdownGroupedStrategies(info!.title));
      }
    });
  };

  const onCloseApiIntegrationsPopUp = () => dispatch(closeApiActivationsPopUp());

  const updateActivationList = async (groupName: string, active: string | null, testing: string | null) => {
    await dispatchRoutine(updateActivationsRequest({ groupName, active, testing }));
    dispatch(closeApiActivationsPopUp());
  };

  const downloadTemplate = (strategyId: string, type: string) => {
    dispatch(downloadApiTemplate(strategyId, type));
  };

  const getOverlay = (): ReactElement | null => {
    if (isPopUpOpen && versionsList.length !== 0) {
      return (
        <ApiIntegrationPopUp
          type={popUpType}
          strategies={versionsList}
          closePopUp={onCloseApiIntegrationsPopUp}
          updateActivationList={updateActivationList}
          decisionStrategies={decisionStrategies}
          activationInfo={currentGroup!}
          downloadTemplate={downloadTemplate}
        />
      );
    }
    return null;
  };

  const onChangeShowArchived = (enabled: boolean) => {
    dispatch(setShowArchived(enabled));
  };

  const onChangeUpdaters = (users: UserInfo[]) => {
    dispatch(setUpdaters(users));
  };

  const getGroupedStrategies = (params: StrategyListParams) => {
    dispatch(getApiActivationsRequest(params));
  };

  const onSearch = (search: string) => {
    dispatch(setStrategyGroupsSearchInput(search));
  };

  const onSort = (field: ApiActivationSortingFields, ascending: boolean) => {
    dispatch(sortApiActivation(field, ascending));
  };

  return (
    <MainLayout leftNav={leftNav} overlay={getOverlay()}>
      <PageWrapper>
        <PageContent>
          <ApiActivation
            groups={groups}
            onOpenApiActivationsPopUp={onOpenApiActivationsPopUp}
            onSort={onSort}
            sortingType={sortingType}
            updaters={updaters}
            onSearch={onSearch}
            searchInputValue={searchInputValue}
            selectedUpdaters={selectedUpdaters}
            showArchived={showArchived}
            setShowArchived={onChangeShowArchived}
            setUpdaters={onChangeUpdaters}
            fetchListData={getGroupedStrategies}
          />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default ApiActivationPage;
