import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationVariableConfiguration,
  ApplicationVariableConfigurationsApi,
} from 'api/LoanOriginationSystem/ApplicationVariableConfigurationsApi';
import {
  getApplicationVariableConfigurationsSuccess,
  getApplicationVariableConfigurationsFailure,
  GetApplicationVariableConfigurationsAction,
} from './ApplicationVariableConfigurationsActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const GetApplicationVariableConfigurationsMiddleware = (api: ApplicationVariableConfigurationsApi) =>
  Fetcher<ApplicationVariableConfiguration[], GetApplicationVariableConfigurationsAction>(
    VariablesConfigurationActionType.GetApplicationVariableConfigurations,
    getApplicationVariableConfigurationsSuccess,
    getApplicationVariableConfigurationsFailure,
    async (action) => {
      return api.find(action.payload.productId);
    },
  );

export default GetApplicationVariableConfigurationsMiddleware;
