import applicationsPagination from 'components/LoanOriginationSystem/ApplicationsDashboard/Pagination';
import { LoanOriginationSystemApplicationsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export default (api: LoanOriginationSystemApplicationsApi) => {
  return applicationsPagination.getItemsFetcherMiddleware(async (params) => {
    const applications = await api.getAll(
      params.selectedProduct!.id,
      {
        searchInputValue: params.search,
        selectedMembers: params.selectedMembers,
        selectedStatusesIds: params.selectedStatusesIds,
        selectedLabels: params.selectedLabels,
        selectedIntermediaries: params.selectedIntermediaries,
        createdDateRange: params.createdDateRange,
        updatedDateRange: params.updatedDateRange,
        pagination: {
          count: params.perPage,
          offset: (params.page - 1) * params.perPage,
        },
      },
      params.sortingType,
    );

    return applications.items;
  });
};
