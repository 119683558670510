import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import { NotificationComponentTypes } from 'handlers/notification/notificationTypes';
import getMessage, { MessageType } from 'constants/messages';
import { GetIntermediaryToDeleteApplicationsSuccessAction } from './ActionCreator';
import { GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS } from './ActionTypes';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';
import { selectIntermediaryViewTab } from 'LoanOriginationSystemIntermediaryDetails/ActionCreator';
import { IntermediaryDetailsTab } from 'components/LoanOriginationSystem/IntermediaryDetails';

const isGetIntermediaryToDeleteApplicationsSuccessAction = (
  action: AnyAction,
): action is GetIntermediaryToDeleteApplicationsSuccessAction =>
  action.type === GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS;

const INTERMEDIARY_HAS_APPLICATIONS_NOTIFICATION_TIMEOUT = 7000;

export const notifyOnIntermediaryToDeleteHasApplications = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  if (isGetIntermediaryToDeleteApplicationsSuccessAction(action)) {
    if (action.payload.applications.total > 0) {
      const { variables, intermediaryId } = action.payload.applications.items[0];
      const message = getMessage(MessageType.IntermediaryToDeleteHasApplications, {
        companyName: variables[IntermediaryDefaultVariable.Name],
      });

      notification.createNotification(
        {
          componentType: NotificationComponentTypes.ItemHasApplicationsErrorNotificationComponent,
          props: {
            message,
            url: `/los/intermediaries?edit=${intermediaryId}`,
            selectApplicationsTabActionCreator: () => selectIntermediaryViewTab(IntermediaryDetailsTab.Applications),
          },
        },
        'error',
        dispatch,
        INTERMEDIARY_HAS_APPLICATIONS_NOTIFICATION_TIMEOUT,
      );
    }
  }

  return next(action);
};
