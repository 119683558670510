import { ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { ESignTemplatesState } from './Types';
import {
  DeleteESignTemplateSuccessAction,
  GetESignTemplatesSuccessAction,
  GetESignTemplateSuccessAction,
  SaveESignTemplatesAction,
  UpdateESignTemplateSuccessAction,
} from './ActionCreator';
import { ESignTemplatesActionType } from './ActionTypes';

export type ESignTemplatesActions =
  | GetESignTemplatesSuccessAction
  | GetESignTemplateSuccessAction
  | UpdateESignTemplateSuccessAction
  | SaveESignTemplatesAction
  | DeleteESignTemplateSuccessAction;

const initialState: ESignTemplatesState = {
  templatesById: {},
};

const getTemplatesById = (templates: ESignTemplate[]) => {
  return templates.reduce(
    (templatesById, template) => ({
      ...templatesById,
      [template.id]: template,
    }),
    {},
  );
};

const eSignTemplatesReducer = (state: ESignTemplatesState = initialState, action: ESignTemplatesActions) => {
  switch (action.type) {
    case ESignTemplatesActionType.GetESignTemplatesSuccess: {
      return {
        ...state,
        templatesById: {
          ...state.templatesById,
          ...getTemplatesById(action.payload.items),
        },
      };
    }
    case ESignTemplatesActionType.UpdateESignTemplateSuccess:
    case ESignTemplatesActionType.GetESignTemplateSuccess: {
      return {
        ...state,
        templatesById: {
          ...state.templatesById,
          [action.payload.template.id]: action.payload.template,
        },
      };
    }
    case ESignTemplatesActionType.SaveESignTemplates: {
      return {
        ...state,
        templatesById: {
          ...state.templatesById,
          ...getTemplatesById(action.payload.templates),
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default eSignTemplatesReducer;
