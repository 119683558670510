import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

const mapAccountToApplicationMember = (account: AccountDetailsReducerState): UserInfo | null => {
  if (!account || account.isLoading) {
    return null;
  }

  return {
    id: account.id,
    firstName: account.firstName,
    lastName: account.lastName,
    imageId: account.imageId,
    active: account.isActive,
  };
};

export default mapAccountToApplicationMember;
