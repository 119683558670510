import React, { ChangeEvent, FC, useRef, useState } from 'react';
import MultipleOptionsSelect, { MultipleOptionsInputProps } from 'components/MultipleOptionsSelect';
import { KeyCode } from 'common/KeyCode';

export interface AutoCompletionProps
  extends Pick<
    MultipleOptionsInputProps,
    | 'id'
    | 'disabled'
    | 'labelTitle'
    | 'onDeleteOption'
    | 'selectedOptions'
    | 'options'
    | 'onInputChange'
    | 'placeholder'
    | 'onSelect'
    | 'renderInputOptionPrefix'
    | 'containerClassName'
    | 'optionClassName'
    | 'selectContainerClassName'
    | 'isLabelTags'
    | 'hideDropdownOnSelect'
  > {
  onSelect: (value: string) => void;
}

const AutoCompletionMultiselect: FC<AutoCompletionProps> = ({ options, onSelect, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const matchingOptions = options.filter(({ name }) => {
    return name.toLowerCase().includes(inputValue.trim().toLowerCase());
  });

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleSelect = (optionValue: string) => {
    setInputValue('');
    onSelect(optionValue);
  };

  const handleInputBlur = () => {
    setInputValue('');
  };

  const handleInputKeyUp = ({ keyCode }: React.KeyboardEvent<HTMLInputElement>) => {
    if (keyCode === KeyCode.Esc) {
      setInputValue('');
    }
  };

  return (
    <MultipleOptionsSelect
      {...props}
      inputValue={inputValue}
      inputRef={inputRef}
      options={options}
      matchingOptions={matchingOptions}
      onInputChange={onInputChange}
      handleInputBlur={handleInputBlur}
      handleInputKeyUp={handleInputKeyUp}
      onSelect={handleSelect}
    />
  );
};

export default AutoCompletionMultiselect;
