import React, { FC, useState } from 'react';
import styles from './SeatsAreFullPopUp.module.scss';
import Button from 'components/Button';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';

interface SeatsAreFullPopUpProps {
  onPopupClose: () => void;
  resetPermissions: () => Promise<void>;
}

const ResetToDefaultsPopUp: FC<SeatsAreFullPopUpProps> = ({ onPopupClose, resetPermissions }) => {
  const [isLoading, setIsLoading] = useState(false);

  const resetToDefaults = async () => {
    setIsLoading(true);
    await resetPermissions();
    setIsLoading(false);
    onPopupClose();
  };

  return (
    <PopUp title="Reset To Defaults" closePopUp={onPopupClose} closable={!isLoading}>
      <PopUpContent hasTopMargin>
        <p className={styles.description}>Do you want to reset permissions to system defaults?</p>
        <div className={styles.buttonsContainer}>
          <Button kind="primary" size="form" onClick={resetToDefaults} isLoading={isLoading}>
            Yes, Reset
          </Button>
          <Button
            kind="secondary"
            size="form"
            className={styles.goBackButton}
            onClick={onPopupClose}
            disabled={isLoading}
          >
            No, Go Back
          </Button>
        </div>
      </PopUpContent>
    </PopUp>
  );
};

export default ResetToDefaultsPopUp;
