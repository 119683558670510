import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { StrategyGroup } from 'api/Types';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { SystemStatus } from 'DecisionStrategy/DecisionStrategiesTypes';

export const STRATEGY_GROUPS_PER_PAGE_DEFAULT = 10;

const strategyGroupsPagination = Pagination<
  ReduxState,
  StrategyGroup,
  { searchInputValue: string; showArchived: boolean; selectedUpdaters: UserInfo[]; versionsStatuses: SystemStatus[] }
>('strategyGroups', STRATEGY_GROUPS_PER_PAGE_DEFAULT, ({ searchInputValue }) =>
  searchInputValue ? ['searched', searchInputValue] : [],
);

export default strategyGroupsPagination;
