import {
  closeAddRulePopUp,
  createCalculationScript,
  createRule,
  createSimpleOutput,
  setCurrentRuleType,
  uploadRuleSet,
} from 'RuleCreate/ActionCreator';
import AddRulePopUp from 'RuleCreate/AddRulePopUp';
import { RulePopUpType, SimpleOutputRequestData, CalculationScriptData, RuleRequestData } from 'RuleCreate/Types';
import { copyBranchRuleset, getBranchOptions } from 'CopyModuleBranchRules/Actions';
import { CopyModuleBranchRulesReducerState } from 'CopyModuleBranchRules/Reducer';
import { useQueryParams } from 'hooks/useQueryParam';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { BranchItemInfo } from 'api/Types';
import { useDispatchRoutine } from 'middlewares/Fetcher';

interface RuleBuilderPopUpContainerTypes {
  strategyId: string;
  handleOpenDownloadPage: (population?: string) => void;
  handleOpenVariableCreation: () => void;
}

const RuleBuilderPopUpContainer: FC<RuleBuilderPopUpContainerTypes> = ({
  strategyId,
  handleOpenDownloadPage,
  handleOpenVariableCreation,
}) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const params = useQueryParams();

  const moduleKey = params.get('module-branch-key')!;
  const branchIndex = parseInt(params.get('branchIndex')!, 10);

  const [isSaveLoading, setIsSaveLoading] = useState(false);

  const { entityType, moduleType: moduleTypeName, rulePopUpType } = useSelector(
    (state: ReduxState) => state.ruleCreate,
  );
  const moduleType = moduleTypeName as ModuleType;

  const { branchOptions, isDuplicating: isDuplicatingBranch } = useSelector<
    ReduxState,
    CopyModuleBranchRulesReducerState
  >(({ duplicateModuleBranch }) => duplicateModuleBranch);

  const onCreateRule = async (data: RuleRequestData): Promise<void> => {
    try {
      setIsSaveLoading(true);
      await dispatchRoutine(createRule({ ...data, strategyId, moduleKey, branchIndex, entityType }));
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleUploadRuleSet = async (file: File): Promise<void> => {
    await dispatchRoutine(uploadRuleSet({ file, moduleKey, strategyId, branchIndex, entityType }));
  };

  const onCreateSimpleOutput = async (simpleOutputData: SimpleOutputRequestData): Promise<void> => {
    try {
      setIsSaveLoading(true);
      await dispatchRoutine(
        createSimpleOutput({
          ...simpleOutputData,
          strategyId,
          moduleKey,
          branchIndex,
          entityType,
        }),
      );
    } finally {
      setIsSaveLoading(false);
    }
  };

  const addModuleCalculationScript = async (calculationScriptData: CalculationScriptData): Promise<void> => {
    const { requiredVariables, comparisonValue, propertyAttribute } = calculationScriptData;
    try {
      setIsSaveLoading(true);
      await dispatchRoutine(
        createCalculationScript({
          propertyAttribute,
          strategyId,
          moduleKey,
          variables: requiredVariables,
          comparisonValue,
          branchIndex,
          entityType,
        }),
      );
    } finally {
      setIsSaveLoading(false);
    }
  };

  const ruleType = entityType === 'branch' ? 'conditions' : 'ruleset';

  const handleGetBranchOptions = (): void => {
    const includeModuleType = ruleType === 'ruleset';
    dispatch(
      getBranchOptions({
        moduleType: includeModuleType ? moduleType : null,
        ruleType,
      }),
    );
  };

  const handleBranchDuplication = ({
    strategyId: optionStrategyId,
    moduleKey: optionModuleKey,
    branchIndex: optionBranchIndex,
  }: BranchItemInfo): void => {
    dispatch(
      copyBranchRuleset({
        ruleType,
        copyFromBranchPrams: {
          strategyId: optionStrategyId,
          moduleKey: optionModuleKey,
          branchIndex: optionBranchIndex,
        },
        copyToBranchParams: {
          strategyId,
          moduleKey,
          branchIndex,
        },
      }),
    );
  };

  const closePopUp = () => dispatch(closeAddRulePopUp());

  const downloadTemplate = () => {
    const currentArgument = entityType === 'branch' ? 'population' : undefined;
    handleOpenDownloadPage(currentArgument);
  };

  return (
    <AddRulePopUp
      moduleType={moduleType}
      type={rulePopUpType}
      entityType={entityType}
      isDuplicatingBranch={isDuplicatingBranch}
      branchOptions={branchOptions}
      createRule={onCreateRule}
      onSaveFile={handleUploadRuleSet}
      createSimpleOutput={onCreateSimpleOutput}
      createCalculationScript={addModuleCalculationScript}
      getBranchOptions={handleGetBranchOptions}
      duplicateBranch={handleBranchDuplication}
      closePopUp={closePopUp}
      onContinue={(type) => dispatch(setCurrentRuleType(type as RulePopUpType))}
      downloadTemplate={downloadTemplate}
      openVariableCreation={handleOpenVariableCreation}
      isLoading={isSaveLoading}
    />
  );
};

export default RuleBuilderPopUpContainer;
