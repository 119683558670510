import React, { CSSProperties } from 'react';
import { ApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { VariablesConfigurationCard } from 'components/LoanOriginationSystem/VariablesConfiguration';
import styles from './DataTab.module.scss';

export interface DataTabCardProps {
  card: ApplicationDataTabCard;
  draggingId: string | null;
  sourceDroppableId: string | null;
  droppableType: string;
  onCardNameUpdate: (id: string, name: string) => void;
  onCardDelete: (id: string) => void;
  onVariableDelete: (cardId: string, variableConfigurationId: string) => void;
  placeholderStyles: CSSProperties | null;
}

const CARD_COLUMNS = 1;
const MAX_VARIABLES_ON_CARD_COUNT = 50;

const DataTabCard = ({
  card,
  placeholderStyles,
  draggingId,
  sourceDroppableId,
  droppableType,
  onCardNameUpdate,
  onCardDelete,
  onVariableDelete,
}: DataTabCardProps) => {
  return (
    <VariablesConfigurationCard
      title={card.name}
      columns={CARD_COLUMNS}
      isTitleEditable
      className={styles.variablesConfigurationCard}
      placeholderStyle={placeholderStyles}
      configuration={card.fields}
      onVariableDelete={(id) => onVariableDelete(card.id, id)}
      onCardNameUpdate={(name) => onCardNameUpdate(card.id, name)}
      draggingId={draggingId}
      cardId={card.id}
      isDropDisabled={card.id !== sourceDroppableId && card.fields.length >= MAX_VARIABLES_ON_CARD_COUNT}
      droppableType={droppableType}
      showActions
      actions={[
        {
          title: 'Delete Card',
          danger: true,
          handler: () => onCardDelete(card.id),
        },
      ]}
    />
  );
};

export default DataTabCard;
