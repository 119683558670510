export type ContentType = 'application/json' | 'text/html' | null;

const API_VERSION = '2.0';

const getBasicHeaders = (contentType?: ContentType): Headers => {
  const headers = new Headers();
  headers.set('clientid', process.env.REACT_APP_CLIENT_ID!);
  headers.set('entitytype', process.env.REACT_APP_ENTITY_TYPE!);
  headers.set('apiversion', API_VERSION);
  if (contentType) {
    headers.set('Accept', contentType);
    headers.set('Content-Type', contentType);
  }
  return headers;
};

export default getBasicHeaders;
