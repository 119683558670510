import React, { FC } from 'react';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import ConfigurableForm from 'components/ConfigurableForm';
import { FormSkeleton } from 'components/Skeleton';

interface ApplicationDetailsFormProps {
  configurations: VariableConfiguration[] | null;
  formData: FormLayoutData;
  onFormDataChange: (formData: FormLayoutData) => void;
  onProfileEdit?: () => void;
}

const ApplicationDetailsForm: FC<ApplicationDetailsFormProps> = ({ formData, onFormDataChange, configurations }) => {
  if (!configurations) {
    return <FormSkeleton />;
  }

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    onFormDataChange({
      ...formData,
      [variable.systemName]: value,
    });
  };

  return (
    <div>
      <ConfigurableForm configurations={configurations} data={formData} onFieldChange={handleFieldChange} />
    </div>
  );
};

export default ApplicationDetailsForm;
