import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { UpdateProductAction, updateProductSuccess, updateProductFailure } from './ActionCreator';

const UpdateProductMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product, UpdateProductAction>(
    LoanOriginationSystemProductsActionType.UpdateProduct,
    updateProductSuccess,
    updateProductFailure,
    (action) => {
      return api.update(action.payload.productId, action.payload.product);
    },
  );

export default UpdateProductMiddleware;
