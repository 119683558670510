import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './PageFooter.module.scss';

interface PageFooterProps {
  className?: string;
  children: ReactNode;
}

const PageFooter: FC<PageFooterProps> = ({ className, ...props }) => {
  const mergedClassName = clsx(styles.pageFooter, className);

  return <div className={mergedClassName} {...props} />;
};

export default PageFooter;
