import React from 'react';
import clsx from 'clsx';
import styles from './skeletonCard.module.scss';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCirclesList from 'components/Skeleton/SkeletonCirclesList';
import SkeletonColorType from 'components/Skeleton/SkeletonColorType';

interface SkeletonCardProps {
  className?: string;
  avatarsListClassname?: string;
  primaryColor?: SkeletonColorType;
  secondaryColor?: SkeletonColorType;
  stopAnimation?: boolean;
}

const SkeletonCard = ({
  className,
  primaryColor = 'primary6',
  secondaryColor = 'primary20',
  avatarsListClassname,
  stopAnimation,
}: SkeletonCardProps) => {
  return (
    <div className={clsx(styles.skeletonCard, className)}>
      <div className={styles.skeletonCardLabelsInner}>
        <SkeletonRectangle
          stopAnimation={stopAnimation}
          className={styles.skeletonCardTag}
          width="20px"
          height="4px"
          color={primaryColor}
        />
        <SkeletonRectangle stopAnimation={stopAnimation} width="20px" height="4px" color={primaryColor} />
      </div>
      <SkeletonRectangle
        stopAnimation={stopAnimation}
        className={styles.skeletonCardLineTop}
        width="67%"
        height="16px"
        color={primaryColor}
      />
      <SkeletonRectangle
        stopAnimation={stopAnimation}
        className={styles.skeletonCardLineBottom}
        width="53%"
        height="14px"
        color={primaryColor}
      />
      <div className={styles.skeletonCardFooter}>
        <SkeletonRectangle stopAnimation={stopAnimation} width="30%" height="18px" color={secondaryColor} />
        <SkeletonCirclesList
          stopAnimation={stopAnimation}
          className={avatarsListClassname}
          width="28px"
          height="28px"
          color={primaryColor}
        />
      </div>
    </div>
  );
};

export default SkeletonCard;
