import React, { FC } from 'react';
import StrategyVersionInfo from 'components/StrategyGroups/StrategyVersionInfo';
import styles from './StrategyGroupCard.module.scss';
import { StrategyGroupVersion } from 'api/Types';
import clsx from 'clsx';
import StrategyActions from 'components/StrategyGroups/StrategyActions';
import WrapperWithTooltip from 'components/Tooltip';

interface StrategyGroupCardProps {
  name: string;
  selected: boolean;
  archived: boolean;
  versions: StrategyGroupVersion[];
  versionsNumber: number;
  onViewAll: () => void;
}

const StrategyGroupCard: FC<StrategyGroupCardProps> = ({
  name,
  selected,
  archived,
  versions = [],
  versionsNumber,
  onViewAll,
}) => {
  const viewAllBtnActive = versionsNumber > 3;
  const getFooterContent = () => {
    if (viewAllBtnActive) {
      return (
        <span onClick={() => onViewAll()} className={styles.footerLink}>{`View all ${versionsNumber} versions`}</span>
      );
    }

    return <span className={styles.footerText}>No other versions</span>;
  };

  const renderVersion = (groupVersion: StrategyGroupVersion) => {
    return (
      <div className={styles.versionInfoRow} key={groupVersion.id}>
        <div className={styles.divider} />
        <div className={styles.versionsInfoWithAction}>
          <StrategyVersionInfo
            version={groupVersion}
            versionActionsClassName={styles.actionsContainer}
            isStatusDisplay
          />
        </div>
        <div className={styles.lastDivider} />
      </div>
    );
  };

  const cardClasses = clsx(styles.cardContainer, {
    [styles.cardContainer__selected]: selected,
    [styles.cardContainer__inactive]: archived,
  });

  return (
    <div className={cardClasses}>
      <div className={styles.cardHeader}>
        <WrapperWithTooltip tooltip={name}>
          <h5 className={clsx(styles.strategyName, archived && styles.strategyName__inactive)}>{name}</h5>
        </WrapperWithTooltip>
        <StrategyActions archived={archived} name={name} />
      </div>
      <div className={styles.strategiesList}>{versions.map(renderVersion)}</div>
      <div className={clsx(styles.footer, viewAllBtnActive && styles.footer__hoverable)}>{getFooterContent()}</div>
    </div>
  );
};

export default StrategyGroupCard;
