import React, { FC } from 'react';
import TextInput from 'components/TextInput';
import Button from 'components/Button';
import styles from 'components/SendGrid/SendGrid.module.scss';

interface CredentialsEditorProps {
  clientApiKey: string;
  onApiKeyChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSaveKey: () => Promise<void>;
  isSubmitButtonDisabled: boolean;
  isBlockingActionInProgress: boolean;
}

const CredentialsEditor: FC<CredentialsEditorProps> = ({
  clientApiKey,
  onApiKeyChange,
  handleSaveKey,
  isSubmitButtonDisabled,
  isBlockingActionInProgress,
}) => {
  return (
    <>
      <TextInput
        labelTitle="API Key"
        value={clientApiKey}
        onChange={onApiKeyChange}
        containerClassName={styles.inputKey}
        className={styles.inputKey}
      />
      <Button
        size="form"
        kind="primary"
        className={styles.formButton}
        onClick={handleSaveKey}
        disabled={isSubmitButtonDisabled}
        isLoading={isBlockingActionInProgress}
      >
        Save Changes
      </Button>
    </>
  );
};

export default CredentialsEditor;
