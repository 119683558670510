import { useState, FocusEvent } from 'react';
import { validateIDNumber, validateEmail, validatePhoneNumber } from 'utils/validation/validation';
import { IdentificationNumberWithAttributes, VisualDataTypeWithAttributes } from 'Variables/VariablesTypes';

const getValidationMessage = (
  variableWithAttributes: VisualDataTypeWithAttributes,
  value: string,
  required?: boolean,
): string => {
  const { dataType } = variableWithAttributes;

  switch (dataType) {
    case 'PhoneNumber': {
      return validatePhoneNumber(value, required);
    }
    case 'IdentificationNumber': {
      const { identificationNumberType } = variableWithAttributes as IdentificationNumberWithAttributes;
      return validateIDNumber(value, identificationNumberType!, required);
    }
    case 'EmailAddress': {
      return validateEmail(value, required);
    }
    default: {
      return '';
    }
  }
};

const useInputValidationMessage = (
  variableWithAttributes: VisualDataTypeWithAttributes,
  value: string,
  onChange: (value: string) => void,
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void,
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void,
  required?: boolean,
  disabledValidation?: boolean,
  disableResetValueOnError?: boolean,
): [(event: FocusEvent<HTMLInputElement>) => void, (event: FocusEvent<HTMLInputElement>) => void, string] => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const validationMessage = !disabledValidation ? getValidationMessage(variableWithAttributes, value, required) : '';

    if (validationMessage) {
      setErrorMessage(validationMessage);
      onChange(disableResetValueOnError ? value : '');
      return;
    }

    setErrorMessage('');
    onChange(value);
    onBlur?.(event);
  };

  const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
    if (errorMessage) {
      setErrorMessage('');
    }
    onFocus?.(event);
  };

  return [handleBlur, handleFocus, errorMessage];
};

export default useInputValidationMessage;
