import React, { FC, useState } from 'react';
import { Email } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import styles from './Header.module.scss';
import { DropdownImage } from 'static/images';
import clsx from 'clsx';
import { parseEmailAddress } from 'components/LoanOriginationSystem/EmailsDashboard/utils/parseEmailAddress';
import { DateTimeFormat } from 'utils/dateFormat';
import DateTime from 'components/DateTime';

interface EmailHeaderProps {
  email: Email;
  organizationEmail: string;
}

const Header: FC<EmailHeaderProps> = ({ email, organizationEmail }) => {
  const { from, sentDate, to, cc } = email;
  const [isHeaderOpen, setIsHeaderOpen] = useState(true);

  const { name: senderName, email: senderEmail } = parseEmailAddress(from);

  const filteredTo = to.filter((mail) => {
    return mail !== organizationEmail;
  });

  return (
    <div className={clsx(styles.headerContainer, isHeaderOpen && styles.headerContainerExpanded)}>
      <div className={styles.headerTitle}>
        <div className={styles.senderInfo}>
          <h5 className={styles.senderName}>{senderName}</h5>
          <p className={styles.senderEmail}>{`<${senderEmail}>`}</p>
        </div>
        <p className={styles.sentDate}>
          <DateTime time={sentDate} format={DateTimeFormat.Short} />
        </p>
        <DropdownImage
          onClick={() => setIsHeaderOpen(!isHeaderOpen)}
          className={clsx(styles.headerTitleDropdownIcon, isHeaderOpen && styles.headerTitleDropdownIconActive)}
        />
      </div>
      <div className={styles.emailInfoContainer}>
        <div className={clsx(styles.emailInfoItem, !isHeaderOpen && styles.emailInfoItemCollapsed)}>
          <p className={styles.emailInfoItemTitle}>To</p>
          <p className={styles.emailInfoItemValue}>{filteredTo.join(', ')}</p>
        </div>
        <div className={clsx(styles.emailInfoItem, styles.emailInfoLastItem)}>
          <p className={styles.emailInfoItemTitle}>Cc</p>
          <p className={styles.emailInfoItemValue}>{cc.length ? cc.join(', ') : ''}</p>
        </div>
      </div>
    </div>
  );
};

export default Header;
