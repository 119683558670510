import { useState } from 'react';

type Callback<Args extends unknown[], Result = void> = (...args: Args) => Promise<Result>;
type LoadingState<Args extends unknown[], Result = void> = [boolean, Callback<Args, Result>];

const useLoadingState = <Args extends unknown[], Result = void>(
  callback: Callback<Args, Result>,
  initialState = false,
): LoadingState<Args, Result> => {
  const [isLoading, setIsLoading] = useState(initialState);

  const withLoadingState = async (...args: Args) => {
    setIsLoading(true);
    try {
      return await callback(...args);
    } finally {
      setIsLoading(false);
    }
  };

  return [isLoading, withLoadingState];
};

export default useLoadingState;
