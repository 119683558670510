import React, { FC } from 'react';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import { ApplicationNote } from 'api/Types';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import RowActions from 'components/RowActions';
import { RowAction } from 'components/RowActions/RowActions';
import SIZES from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/TableColumnSizes';
import styles from './NoteRow.module.scss';
import NoteContentCell from 'components/LoanOriginationSystem/ApplicationTabs/NotesTab/NoteContentCell';
import OverflowedText from 'components/OverflowedText/OverflowedText';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';

interface NoteRowProps {
  note: ApplicationNote;
  rowActions: RowAction[];
  onClick: () => void;
  searchInputValue: string;
}

const NoteRow: FC<NoteRowProps> = ({ note, onClick, rowActions, searchInputValue }) => {
  return (
    <TableRow key={note.id} useActions onClick={onClick}>
      <TableBodyCell width={SIZES.DATE} className={styles.row} overflowed>
        <DateTime time={note.updatedAt} format={DateTimeFormat.Long} />
      </TableBodyCell>
      <TableBodyCell width={SIZES.CONTENT} className={styles.row}>
        <NoteContentCell note={note.note} searchInputValue={searchInputValue} />
      </TableBodyCell>
      <TableBodyCell width={SIZES.AUTHOR} noPadding className={styles.row}>
        <ImageFetcher
          firstName={note.author.firstName}
          lastName={note.author.lastName}
          imageId={note.author.avatarUrl}
          size="small"
          tooltip
        />
        <OverflowedText className={styles.authorName}>
          {note.author.firstName} {note.author.lastName}
        </OverflowedText>
      </TableBodyCell>
      <RowActions actions={rowActions} id={note.id} />
    </TableRow>
  );
};

export default NoteRow;
