import { Middleware } from 'redux';

import { GET_DECISION_STRATEGY_UPDATES_REQUEST } from './ActionTypes';
import {
  DecisionStrategyUpdates,
  getDecisionStrategyUpdatesError,
  getDecisionStrategyUpdatesSuccess,
} from './DecisionStrategyUpdatesActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { STRATEGY_UPDATES_PER_PAGE_DEFAULT } from 'pages/Strategy/Pagination';
import { DecisionEngineApi } from 'api/Types';

const GetDecisionStrategyUpdates: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<DecisionStrategyUpdates>(
    GET_DECISION_STRATEGY_UPDATES_REQUEST,
    getDecisionStrategyUpdatesSuccess,
    getDecisionStrategyUpdatesError,
    async ({ payload: strategyId }) => {
      const strategyUpdates = await api.getDecisionStrategyUpdates(
        { page: 1, perPage: STRATEGY_UPDATES_PER_PAGE_DEFAULT },
        strategyId,
      );
      return {
        strategyId,
        ...strategyUpdates,
      };
    },
  );

export default GetDecisionStrategyUpdates;
