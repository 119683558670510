import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { SendGridTemplate } from 'SendGrid/Types';
import { SendGridTemplatesSortingType, TemplatesFilterOptions } from 'api/LoanOriginationSystem/SendGridTemplateApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export const TEMPLATES_PER_PAGE_DEFAULT = 20;

export interface SendGridTemplatePaginationParams {
  active: TemplatesFilterOptions | null;
  searchInputValue: string;
  sortingType: SendGridTemplatesSortingType;
  selectedMembers: UserInfo[];
  dueCreatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange: {
    from: Date | null;
    to: Date | null;
  };
}

const templatesPagination = Pagination<ReduxState, SendGridTemplate, SendGridTemplatePaginationParams>(
  'sendGrid',
  TEMPLATES_PER_PAGE_DEFAULT,
  ({ searchInputValue }) => (searchInputValue ? ['searched', searchInputValue] : []),
);

export default templatesPagination;
