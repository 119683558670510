import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemTasksApi } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { LoanOriginationSystemTasksActionTypes } from './ActionTypes';
import { DeleteTaskAction, deleteTaskSuccess, deleteTasksFailure } from './ActionCreator';

const DeleteTaskMiddleware = (api: LoanOriginationSystemTasksApi) =>
  Fetcher<void, DeleteTaskAction>(
    LoanOriginationSystemTasksActionTypes.DeleteTask,
    deleteTaskSuccess,
    deleteTasksFailure,
    (action) => {
      const { taskIdToDelete } = action.payload;

      return api.deleteTask(taskIdToDelete);
    },
  );

export default DeleteTaskMiddleware;
