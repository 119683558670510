import Fetcher from 'middlewares/Fetcher';
import { ReduxState } from 'types/redux';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { LoanOriginationSystemBorrowersApi } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  DeleteBorrowerAction,
  deleteBorrowerFailure,
  deleteBorrowerSuccess,
  DeleteBorrowerSuccessResult,
} from './ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';

const DeleteBorrowerMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<DeleteBorrowerSuccessResult, DeleteBorrowerAction, ReduxState>(
    LoanOriginationSystemBorrowersActionTypes.DeleteBorrower,
    deleteBorrowerSuccess,
    deleteBorrowerFailure,
    async (action, state) => {
      await api.deleteBorrower(action.payload);

      const borrowers = state.loanOriginationSystemBorrowers.items;

      const deletedBorrower = borrowers.find((borrower) => borrower.id === action.payload);

      return {
        name: deletedBorrower ? getBorrowerFullName(deletedBorrower.type, deletedBorrower.variables) : '',
      };
    },
  );

export default DeleteBorrowerMiddleware;
