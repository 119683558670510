import { AnyAction } from 'redux';
import { StrategiesState } from 'Strategies/Types';

const initialState: StrategiesState = {};

const strategiesReducer = (state: StrategiesState = initialState, action: AnyAction): StrategiesState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default strategiesReducer;
