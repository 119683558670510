import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { ReduxState } from 'types/redux';
import { BorrowersSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import SearchNotFound from 'components/SearchNotFound';
import NoItems from 'components/NoItems';
import {
  setSearchInputValue,
  toggleFiltersPopup,
  filterBorrowersByMembers,
  resetAllBorrowersFilters,
} from 'LoanOriginationSystemBorrowers/Filters/ActionCreator';
import { getBorrowers, sortBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';
import BorrowersTable from './BorrowersTable';
import BorrowersDashboardHeader from './BorrowersDashboardHeader';
import pagination, { BorrowersPaginationParams } from './pagination';
import styles from './BorrowersDashboard.module.scss';
import useStandardVariables from 'hooks/useStandardVariables';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { isEqual } from 'lodash';
import { initialState as filtersInitialState } from 'LoanOriginationSystemBorrowers/Filters/Reducer';
import { NoResultsIcon } from 'static/images';

export interface BorrowersDashboardProps {
  onBorrowerDelete: (id: string) => Promise<void>;
}

const BorrowersDashboard = ({ onBorrowerDelete }: BorrowersDashboardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const organizationMembers = useOrganizationMembers();

  const { sortingType, filters } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);

  useEffect(() => {
    dispatch(getBorrowers(filters, sortingType));
  }, []);

  const onSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const onSort = (field: BorrowersSortingField, ascending: boolean) => {
    dispatch(sortBorrowers(field, ascending));
  };

  const onCreateNewBorrower = () => {
    history.replace('?new');
  };

  const onBorrowerEdit = (id: string) => {
    history.replace(`?edit=${id}`);
  };

  const onFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const areFiltersChanged = () => {
    return (
      filters.dueCreatedDateRange.from !== null ||
      filters.dueCreatedDateRange.to !== null ||
      filters.dueUpdatedDateRange.from !== null ||
      filters.dueUpdatedDateRange.to !== null ||
      filters.isPerson ||
      filters.isCompany
    );
  };

  const onMemberFilterChange = (newSelectedMembers: UserInfo[]) => {
    dispatch(filterBorrowersByMembers(newSelectedMembers));
  };

  const onResetAllFilters = () => {
    dispatch(resetAllBorrowersFilters());
  };

  const params = {
    search: filters.searchInputValue,
    sortingType,
    dueCreatedDateRange: filters.dueCreatedDateRange,
    dueUpdatedDateRange: filters.dueUpdatedDateRange,
    selectedMembers: filters.selectedMembers,
    isCompany: filters.isCompany,
    isPerson: filters.isPerson,
  } as BorrowersPaginationParams;

  const borrowers = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);
  const standardVariables = useStandardVariables();

  const isNoBorrowers = !borrowers.length && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const containerStyle = clsx(styles.container, {
    [styles.emptyContent]: isNoBorrowers,
  });

  const renderBody = () => {
    if (filters.searchInputValue && !borrowers.length) {
      return <SearchNotFound searchValue={filters.searchInputValue} className={styles.notFoundContainer} footer />;
    }

    if (!borrowers.length && isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No borrowers have been added yet!"
          buttonMessage="Add Borrower"
          onButtonClick={onCreateNewBorrower}
        />
      );
    }

    if (!borrowers.length && !isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noItemsSubtitle}
          onButtonClick={onResetAllFilters}
          className={styles.noResultsFoundContainer}
        />
      );
    }

    return (
      <BorrowersTable
        standardVariables={standardVariables}
        source={borrowers}
        paginationProps={paginationProps}
        searchInputValue={filters.searchInputValue}
        sortingType={sortingType}
        onSort={onSort}
        onBorrowerClick={onBorrowerEdit}
        onBorrowerEdit={onBorrowerEdit}
        onBorrowerDelete={onBorrowerDelete}
      />
    );
  };

  return (
    <>
      <BorrowersDashboardHeader
        searchInputValue={filters.searchInputValue}
        onSearch={onSearch}
        onCreateNewBorrower={onCreateNewBorrower}
        hideSubtitle={isNoBorrowers}
        onFilterButtonClick={onFilterButtonClick}
        filtersChanged={areFiltersChanged()}
        members={organizationMembers}
        selectedMembers={filters.selectedMembers}
        onMemberFilterChange={onMemberFilterChange}
      />
      <div className={containerStyle}>{renderBody()}</div>
    </>
  );
};

export default BorrowersDashboard;
