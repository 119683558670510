import { UpdateRegionSettingsRequestData } from 'CompanyInformation/CompanyInformationTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { RegionSettings } from './ActionTypes';

export interface UpdateRegionSettingsAction {
  type: RegionSettings.UpdateRegionSettings;
  payload: UpdateRegionSettingsRequestData;
}

export interface UpdateRegionSettingsSuccessAction {
  type: RegionSettings.UpdateRegionSettingsSuccess;
}

export const updateRegionSettings = (payload: UpdateRegionSettingsRequestData): UpdateRegionSettingsAction => ({
  type: RegionSettings.UpdateRegionSettings,
  payload,
});

export const updateRegionSettingsSuccess = (): UpdateRegionSettingsSuccessAction => ({
  type: RegionSettings.UpdateRegionSettingsSuccess,
});

export const updateRegionSettingsFailure = FailedActionCreator(RegionSettings.UpdateRegionSettingsFailure);

export const openRegionSettingsConfirmDialog = () => ({
  type: RegionSettings.OpenRegionSettingsConfirmDialog,
});

export const closesRegionSettingsConfirmDialog = () => ({
  type: RegionSettings.CloseRegionSettingsConfirmDialog,
});
