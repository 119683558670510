import React, { useEffect, useState } from 'react';
import { CloseButtonImage } from 'static/images';
import { useDispatch, useSelector } from 'react-redux';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { getIntermediaryVariableConfigurations } from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { getIntermediaryVariableConfigurationsSelector } from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { createIntermediary } from 'LoanOriginationSystemIntermediariesPage/CreateIntermediary/ActionCreator';
import { formatDefaultFieldsDisplayTitle } from 'LoanOriginationSystemIntermediariesPage/utils';
import { ReduxState } from 'types/redux';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import { FormSkeleton } from 'components/Skeleton';
import ConfigurableForm, { useConfigurableFormValidation } from 'components/ConfigurableForm';
import Button from 'components/Button';
import styles from './CreateIntermediary.module.scss';

const SKELETON_FIELDS_COUNT = 6;

interface CreateIntermediaryProps {
  onClose: () => void;
  setDataWasChanged: (changed: boolean) => void;
}

const CreateIntermediary = ({ onClose, setDataWasChanged }: CreateIntermediaryProps) => {
  const dispatch = useDispatch();

  const [data, setData] = useState<FormLayoutData>({});
  const intermediaryVariableConfigurations = useSelector((state: ReduxState) =>
    getIntermediaryVariableConfigurationsSelector(state, {}),
  );
  const { isCreating } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemIntermediariesState.createIntermediary,
  );

  const intermediariesData = data || {};
  const validateIntermediariesData = useConfigurableFormValidation(intermediaryVariableConfigurations!);

  useEffect(() => {
    dispatch(getIntermediaryVariableConfigurations());
  }, []);

  useEffect(() => {
    setDataWasChanged(Object.values(intermediariesData).some((value) => !isEmptyVariableValue(value)));
  }, [data]);

  const handleIntermediaryCreate = () => {
    dispatch(createIntermediary(intermediariesData));
  };

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    const newData = {
      ...data,
      [variable.systemName]: value,
    };

    setData(newData);
  };

  const renderForm = () => {
    if (!intermediaryVariableConfigurations) {
      return <FormSkeleton fieldsCount={SKELETON_FIELDS_COUNT} />;
    }

    return (
      <ConfigurableForm
        configurations={intermediaryVariableConfigurations}
        data={data}
        onFieldChange={handleFieldChange}
        formatDisplayTitle={formatDefaultFieldsDisplayTitle}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2>Add Intermediary</h2>
          <CloseButtonImage className={styles.closeButton} onClick={onClose} />
        </div>
        <div className={styles.formContainer}>
          {renderForm()}
          <Button
            size="form"
            kind="primary"
            className={styles.createButton}
            disabled={!intermediaryVariableConfigurations || !validateIntermediariesData(intermediariesData)}
            onClick={handleIntermediaryCreate}
            isLoading={isCreating}
          >
            Add Intermediary
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateIntermediary;
