import { VariableStatusChangePopupReducerActionType } from './ActionTypes';
import { AnyAction } from 'redux';

export interface OpenVariableStatusChangePopupAction extends AnyAction {
  type: VariableStatusChangePopupReducerActionType.OpenVariableStatusChangePopup;
  payload: { id: string };
}
export interface CloseVariableStatusChangePopupAction extends AnyAction {
  type: VariableStatusChangePopupReducerActionType.CloseVariableStatusChangePopup;
}

export const openVariableStatusChangePopup = (id: string): OpenVariableStatusChangePopupAction => {
  return {
    type: VariableStatusChangePopupReducerActionType.OpenVariableStatusChangePopup,
    payload: { id },
  };
};

export const closeVariableStatusChangePopup = (): CloseVariableStatusChangePopupAction => {
  return {
    type: VariableStatusChangePopupReducerActionType.CloseVariableStatusChangePopup,
  };
};
