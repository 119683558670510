import React, { useEffect } from 'react';
import styles from './DecisionEngine.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import StrategyRunHistoryTable from 'components/StrategyRunHistoryTable';
import pagination from './Pagination';
import { useHistory } from 'react-router-dom';
import { getApplicationDecisionRuns, sortApplicationCases } from 'ApplicationDecisionRuns/actions';
import TabNoItems from 'components/LoanOriginationSystem/ApplicationTabs/TabNoItems';
import TabHeader from 'components/LoanOriginationSystem/ApplicationTabs/TabHeader';
import { openRunDecisionWizard } from 'RunDecisionWizard/actions';
import DeleteDecisionWizard from 'DeleteDecisionWizard';
import RunDecisionWizard from 'RunDecisionWizard';
import Button from 'components/Button';
import useStateReset from 'hooks/useStateReset';
import { ApplicationDecisionRunActionType } from 'ApplicationDecisionRuns/actionTypes';
import { ApplicationDecisionRunsDataState, DecisionRunsSortingField } from 'ApplicationDecisionRuns/types';
import { ReduxState } from 'types/redux';
import { openDeleteDecisionWizard } from 'DeleteDecisionWizard/actions';
import { AccountDetailsReducerState } from 'AccountDetails/AccountDetailsReducer';
import { IndividualProcessingHistoryCase } from 'api/Types';
import { useDownloadApi } from 'providers/ApiServiceProvider';
import TABLE_HEADER_OFFSET from 'components/LoanOriginationSystem/ApplicationTabs/TableHeaderOffset';

interface DecisionEngineTabProps {
  applicationId: string;
}

const DecisionEngineTab = ({ applicationId }: DecisionEngineTabProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const downloadApi = useDownloadApi();

  const { sortingType, loaded } = useSelector<ReduxState, ApplicationDecisionRunsDataState>(
    (state) => state.applicationCases,
  );
  const userState = useSelector<ReduxState, AccountDetailsReducerState>((state) => state.accountDetails);
  const handleSort = (field: DecisionRunsSortingField, ascending: boolean) => {
    dispatch(sortApplicationCases(field, ascending));
    dispatch(getApplicationDecisionRuns(applicationId, { field, ascending }));
  };

  useStateReset(ApplicationDecisionRunActionType.ResetState);

  useEffect(() => {
    dispatch(getApplicationDecisionRuns(applicationId, sortingType));
  }, []);

  const individualCases = pagination.usePaginatedItems({ applicationId, sortingType });
  const paginationProps = pagination.usePagination({ applicationId, sortingType });
  const handleOpenRunDecisionPopup = () => dispatch(openRunDecisionWizard({ applicationId }));
  const handleDecisionRunDelete = (decisionRunId: string) => {
    dispatch(openDeleteDecisionWizard({ decisionRunId }));
  };

  const isDeleteCaseAvailable = (createdBy: string, type: IndividualProcessingHistoryCase['type']) =>
    type !== 'API' && userState?.id === createdBy;

  const getRowActions = (individualCase: IndividualProcessingHistoryCase) => [
    {
      title: 'View Result',
      handler: (caseId?: string) => history.push(`/decision/processing/individual/results/${caseId!}`),
    },
    {
      title: 'Download Result',
      handler: (caseId?: string) => downloadApi.downloadSimulation(caseId!),
      separatorRequired: true,
    },
    ...(isDeleteCaseAvailable(individualCase.createdBy, individualCase.type)
      ? [
          {
            title: 'Delete Result',
            handler: (caseId?: string) => handleDecisionRunDelete(caseId!),
            danger: true,
          },
        ]
      : []),
  ];
  const isEmpty = paginationProps.itemsTotal === 0;
  const renderNoTabData = () => (
    <TabNoItems
      title="No decisions have been run yet!"
      buttonMessage="Run Decision"
      onButtonClick={handleOpenRunDecisionPopup}
    />
  );

  const renderActions = () => (
    <Button className={styles.fixedWidthBtn} kind="primary" size="default" onClick={handleOpenRunDecisionPopup}>
      Run Decision
    </Button>
  );

  return (
    <>
      <TabHeader title="Decision Engine" actions={renderActions()} />
      {isEmpty && loaded ? (
        renderNoTabData()
      ) : (
        <section className={styles.tableContainer}>
          <StrategyRunHistoryTable
            individualCases={individualCases}
            paginationProps={paginationProps}
            getRowActions={getRowActions}
            sortableFieldKeys={Object.values(DecisionRunsSortingField)}
            onSort={handleSort}
            sortingType={sortingType}
            stickyMarginTop={TABLE_HEADER_OFFSET}
          />
        </section>
      )}
      <RunDecisionWizard />
      <DeleteDecisionWizard onDeleteComplete={() => dispatch(getApplicationDecisionRuns(applicationId, sortingType))} />
    </>
  );
};

export default DecisionEngineTab;
