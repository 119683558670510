import Fetcher from 'middlewares/Fetcher';
import { UploadSecureDocumentAction, uploadSecureDocumentSuccess, uploadSecureDocumentFailure } from './ActionCreator';
import { UploadSecureDocumentActionType } from './ActionTypes';
import { CustomerDocumentUploadApiType } from 'api/LoanOriginationSystem/CustomerDocumentUploadApi';

const UploadSecretDocumentMiddleware = (api: CustomerDocumentUploadApiType) =>
  Fetcher<string, UploadSecureDocumentAction>(
    UploadSecureDocumentActionType.UploadSecureDocument,
    uploadSecureDocumentSuccess,
    uploadSecureDocumentFailure,
    ({ payload }) => {
      return api.upload(payload.token, payload.file);
    },
  );

export default UploadSecretDocumentMiddleware;
