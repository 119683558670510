import React from 'react';
import AutoCompletion from 'components/AutoCompletion';
import { AutoCompletionSyncProps, SearchableOptionAttribute } from 'components/AutoCompletion/AutoCompletionSync';
import { getCountries, getCountryCallingCode, flags } from 'components/PhoneInput';
import phoneNumberInputLocale from 'react-phone-number-input/locale/en.json';
import { Option } from 'components/SelectInput/SelectInput';

import styles from './CountryCodeSelect.module.scss';

const options: Option[] = getCountries().map((countryCode: string) => {
  const Flag = flags[countryCode];
  return {
    value: countryCode,
    name: `+${getCountryCallingCode(countryCode)}`,
    description: phoneNumberInputLocale[countryCode],
    icon: <Flag className={styles.flag} />,
  };
});

interface CountryCodeSelectProps extends Omit<AutoCompletionSyncProps, 'options'> {}

const AUTO_COMPLETION_SEARCHED_ATTRS: SearchableOptionAttribute[] = ['name', 'description'];

const CountryCodeSelect = (props: CountryCodeSelectProps) => {
  return <AutoCompletion options={options} searchedOptionAttrs={AUTO_COMPLETION_SEARCHED_ATTRS} {...props} />;
};

export default CountryCodeSelect;
