import { AnyAction } from 'redux';
import loanOriginationSystemApplicationsReducer from 'LoanOriginationSystemApplications/Reducer';
import loanOriginationSystemApplicationPageReducer from 'LoanOriginationSystemApplicationPage/Reducer';
import loanOriginationSystemIntermediariesReducer from 'LoanOriginationSystemIntermediariesPage/Reducer';
import loanOriginationSystemBorrowersReducer from 'LoanOriginationSystemBorrowers/Reducer';
import loanOriginationSystemBorrowerDetailsReducer from 'LoanOriginationSystemBorrowerDetails/Reducer';
import variablesListReducer from 'LoanOriginationSystemVariablesList/Reducer';
import loanOriginationSystemLabelsReducer from 'LoanOriginationSystemLabelsPage/Reducer';
import loanOriginationSystemTasksReducer from 'LoanOriginationSystemTasks/Reducer';
import loanOriginationSystemDocumentsReducer from 'LoanOriginationSystemApplicationPage/Documents/Reducer';
import loanOriginationSystemOrganizationReducer from 'LoanOriginationSystemOrganization/Reducer';
import loanOriginationSystemApplicationTabsReducer from 'LoanOriginationSystemApplicationTabs/Reducer';
import { LoanOriginationSystemOrganizationState } from 'LoanOriginationSystemOrganization/Types';
import loanOriginationSystemProductsReducer from 'LoanOriginationSystemProducts/Reducer';
import applicationCasesReducer from 'ApplicationDecisionRuns/reducer';
import { LoanOriginationSystemApplicationTabsState } from 'LoanOriginationSystemApplicationTabs/Types';
import automatedProcessesReducer from 'ProcessAutomation/Reducer';
import { ProcessAutomationState } from 'ProcessAutomation/Types';
import loanOriginationSystemDeclineReasonsReducer from 'LoanOriginationSystemDeclineReasons/Reducer';
import { LoanOriginationSystemDeclineReasonsState } from 'LoanOriginationSystemDeclineReasons/Types';
import loanOriginationSystemApplicationStatusesReducer from 'LoanOriginationSystemApplicationStatuses/Reducer';
import { LoanOriginationSystemApplicationStatusesState } from 'LoanOriginationSystemApplicationStatuses/Types';
import runDecisionWizardReducer from 'RunDecisionWizard/runDecisionWizardReducer';
import deleteDecisionWizardReducer from 'DeleteDecisionWizard/deleteDecisionWizardReducer';
import variablesConfigurationReducer from 'LoanOriginationSystemVariablesConfiguration/Reducer';
import { VariablesConfigurationState } from 'LoanOriginationSystemVariablesConfiguration/Types';
import uploadSecureDocumentReducer from 'UploadSecureDocument/Reducer';
import { UploadSecureDocumentState } from 'UploadSecureDocument/Types';
import LoanOriginationSystemIntermediaryDetailsReducer from 'LoanOriginationSystemIntermediaryDetails/Reducer';
import applicationDataTabCardsReducer from 'LoanOriginationSystemApplicationDataTabCards/Reducer';
import { ApplicationDataTabCardsState } from 'LoanOriginationSystemApplicationDataTabCards/Types';
import applicationDataTabVariableConfigurationsReducer from 'ApplicationDataTabVariableConfigurations/Reducer';
import { ApplicationDataTabVariableConfigurationsState } from 'ApplicationDataTabVariableConfigurations/Types';
import ReportingFilterBarReducer from 'LoanOriginationSystemReporting/Filters/Reducer';
import ReportingReducer from 'LoanOriginationSystemReporting/Applications/Reducer';

export default {
  loanOriginationSystemApplications: loanOriginationSystemApplicationsReducer,
  loanOriginationSystemApplicationPage: loanOriginationSystemApplicationPageReducer,
  loanOriginationSystemIntermediariesState: loanOriginationSystemIntermediariesReducer,
  loanOriginationSystemBorrowers: loanOriginationSystemBorrowersReducer,
  loanOriginationSystemBorrowerDetails: loanOriginationSystemBorrowerDetailsReducer,
  loanOriginationSystemTasks: loanOriginationSystemTasksReducer,
  loanOriginationSystemDocuments: loanOriginationSystemDocumentsReducer,
  applicationTabsData: loanOriginationSystemApplicationTabsReducer as (
    state: LoanOriginationSystemApplicationTabsState,
    actions: AnyAction,
  ) => LoanOriginationSystemApplicationTabsState,
  applicationStatusesData: loanOriginationSystemApplicationStatusesReducer as (
    state: LoanOriginationSystemApplicationStatusesState,
    action: AnyAction,
  ) => LoanOriginationSystemApplicationStatusesState,
  applicationCases: applicationCasesReducer,
  runDecisionWizard: runDecisionWizardReducer,
  deleteDecisionRunWizard: deleteDecisionWizardReducer,
  loanOriginationSystemOrganization: loanOriginationSystemOrganizationReducer as (
    state: LoanOriginationSystemOrganizationState,
    action: AnyAction,
  ) => LoanOriginationSystemOrganizationState,
  applicationDataTabCards: applicationDataTabCardsReducer as (
    state: ApplicationDataTabCardsState,
    action: AnyAction,
  ) => ApplicationDataTabCardsState,
  applicationDataTabVariableConfigurations: applicationDataTabVariableConfigurationsReducer as (
    state: ApplicationDataTabVariableConfigurationsState,
    action: AnyAction,
  ) => ApplicationDataTabVariableConfigurationsState,
  loanOriginationSystemVariablesList: variablesListReducer,
  loanOriginationSystemLabelsState: loanOriginationSystemLabelsReducer,
  loanOriginationSystemProducts: loanOriginationSystemProductsReducer,
  loanOriginationSystemAutomatedProcesses: automatedProcessesReducer as (
    state: ProcessAutomationState,
    action: AnyAction,
  ) => ProcessAutomationState,
  loanOriginationSystemDeclineReasons: loanOriginationSystemDeclineReasonsReducer as (
    state: LoanOriginationSystemDeclineReasonsState,
    action: AnyAction,
  ) => LoanOriginationSystemDeclineReasonsState,
  variablesConfiguration: variablesConfigurationReducer as (
    state: VariablesConfigurationState,
    action: AnyAction,
  ) => VariablesConfigurationState,
  uploadSecureDocument: uploadSecureDocumentReducer as (
    state: UploadSecureDocumentState,
    action: AnyAction,
  ) => UploadSecureDocumentState,
  loanOriginationSystemIntermediaryDetails: LoanOriginationSystemIntermediaryDetailsReducer,
  reportingFilterBar: ReportingFilterBarReducer,
  reporting: ReportingReducer,
};
