import { AnyAction } from 'redux';
import { BranchData } from 'AddBranch/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { CREATE_BRANCH, CREATE_BRANCH_FAILURE, CREATE_BRANCH_SUCCESS } from 'AddBranch/ActionTypes';

export interface CreateBranchAction extends AnyAction {
  type: typeof CREATE_BRANCH;
}

export interface CreateNewBranchSuccess {
  type: typeof CREATE_BRANCH_SUCCESS;
  payload: BranchData;
}

export const createBranch = (payload: BranchData): CreateBranchAction => ({
  type: CREATE_BRANCH,
  payload,
});

export const createBranchRequestFailure = FailedActionCreator(CREATE_BRANCH_FAILURE);

export const createBranchRequestSuccess = (payload: BranchData): CreateNewBranchSuccess => ({
  type: CREATE_BRANCH_SUCCESS,
  payload,
});
