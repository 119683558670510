import { Middleware } from 'redux';
import { ChangeUserInfoError, ChangeUserInfoSuccess, ChangeUserInfoRequestAction } from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { User } from './AccountDetailsTypes';
import { IAccountDetailsApi } from 'api/Core/AccountDetailsApi';
import AccountDetailsActionType from 'AccountDetails/ActionTypes';

const ChangeUserMiddleware: (api: IAccountDetailsApi) => Middleware = (api) =>
  Fetcher<User, ChangeUserInfoRequestAction>(
    AccountDetailsActionType.UpdateAccountDetails,
    ChangeUserInfoSuccess,
    ChangeUserInfoError,
    ({ payload }) => api.updateAccountInfo(payload),
  );

export default ChangeUserMiddleware;
