import Pagination from 'pagination';
import { ReduxState } from 'types/redux';
import { BatchProcessingHistoryCase } from 'api/Types';

// The backend always sends 10 records regardless of the limit parameter
export const HISTORY_CASES_PER_PAGE_DEFAULT = 10;

const batchHistoryPagination = Pagination<ReduxState, BatchProcessingHistoryCase, { searchInputValue: string }>(
  ['batchProcessing', 'history'],
  HISTORY_CASES_PER_PAGE_DEFAULT,
  ({ searchInputValue }) => (searchInputValue ? ['searched', searchInputValue] : []),
);

export default batchHistoryPagination;
