import { ApiMiddleware } from 'api/APIRest';
import { ApplicationsFilterParams, ReportingData } from 'LoanOriginationSystemReporting/Applications/Types';
import { FilterInitialData } from 'LoanOriginationSystemReporting/Filters/Types';
import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { Application } from './LoanOriginationSystemApplicationsApi';
import { LoanOriginationSystemApplicationStatusesApi } from './LoanOriginationSystemApplicationStatusesApi';
import { LoanOriginationSystemIntermediariesApi } from './LoanOriginationSystemIntermediariesApi';
import { LabelsSortingField, LoanOriginationSystemLabelsApi } from './LoanOriginationSystemLabelsApi';
import { LoanOriginationSystemProductsApi } from './LoanOriginationSystemProductsApi';
import { Option } from 'components/SelectInput/SelectInput';
import { UserApi } from 'api/Core/UserApi';

export interface LoanOriginationSystemApplicationReportingApi {
  getFiltersInitialState(): Promise<FilterInitialData>;
  getReportingData(filterParams: ApplicationsFilterParams): Promise<ReportingData>;
}

export default class LoanOriginationSystemApplicationReportingRestApi extends LoanOriginationSystemApi<Application>
  implements LoanOriginationSystemApplicationReportingApi {
  constructor(
    middlewares: ApiMiddleware[],
    protected labelsApi: LoanOriginationSystemLabelsApi,
    protected intermediariesApi: LoanOriginationSystemIntermediariesApi,
    protected statusesApi: LoanOriginationSystemApplicationStatusesApi,
    protected productsApi: LoanOriginationSystemProductsApi,
    protected userApi: UserApi,
  ) {
    super(middlewares);
  }

  protected resourceName = 'application-reporting';

  public async getFiltersInitialState(): Promise<FilterInitialData> {
    const labels = await this.labelsApi.getLabels({
      sortingType: {
        field: LabelsSortingField.LabelName,
        ascending: true,
      },
    });
    const intermediaries = await this.intermediariesApi.getIntermediaries();
    const products = await this.productsApi.getAll({ search: '' });
    const statuses = await this.statusesApi.getAll();
    const { items: organizationUsers } = await this.userApi.getAll();

    const normalizedLabels = labels.items.map((item) => {
      return {
        name: item.name,
        value: item.id,
        color: item.color,
      };
    });

    const normalizedIntermediaries = intermediaries.items.map((item) => {
      return {
        name: item.variables.intermediary_name,
        value: item.id,
      } as Option;
    });

    const normalizedProducts = products.map(({ name, id }) => {
      return {
        name,
        value: id,
      };
    });

    const normalizedStatuses = statuses
      .map(({ name }) => {
        return {
          name,
          id: name,
        };
      })
      .reduce(
        (acc, status) => {
          if (acc.map[status.name]) {
            return acc;
          }
          acc.map[status.name] = true;
          acc.statuses.push(status as never);
          return acc;
        },
        {
          map: {},
          statuses: [],
        },
      ).statuses;

    const teamMembers = organizationUsers.map((user) => {
      return {
        value: user.id,
        name: `${user.firstName} ${user.lastName}`,
        imageId: user.imageId,
      };
    });

    return {
      intermediaries: normalizedIntermediaries,
      teamMembers,
      statuses: normalizedStatuses,
      products: normalizedProducts,
      labels: normalizedLabels,
    };
  }

  public async getReportingData(filterParams: ApplicationsFilterParams): Promise<ReportingData> {
    const params = new URLSearchParams();

    if (filterParams.productIds.length) {
      filterParams.productIds.forEach((productId) => params.append('productIds', productId));
    }

    if (filterParams.statusIds.length) {
      filterParams.statusIds.forEach((statusId) => params.append('statusNames', statusId));
    }

    if (filterParams.labelIds.length) {
      filterParams.labelIds.forEach((labelId) => params.append('labelIds', labelId));
    }

    if (filterParams.intermediaryIds.length) {
      filterParams.intermediaryIds.forEach((intermediaryId) => params.append('intermediaryIds', intermediaryId));
    }

    if (filterParams.teamMemberIds.length) {
      filterParams.teamMemberIds.forEach((teamMemberId) => params.append('teamMemberIds', teamMemberId));
    }

    if (filterParams.timeDelta.from) {
      params.append('createdAtFrom', filterParams.timeDelta.from.toString());
    }

    if (filterParams.timeDelta.to) {
      params.append('createdAtTo', filterParams.timeDelta.to.toString());
    }

    const reportingApplicationsData: ReportingData = await this.getResources(params);

    return reportingApplicationsData;
  }
}
