import { ProductSection } from 'ProductSectionAccessPermissions/ProductSectionAccessPermissionsTypes';

export enum DigifiProduct {
  LoanOriginationSystem = 'LoanOriginationSystem',
  DecisionEngine = 'DecisionEngine',
}

export type ProductAccess = Record<DigifiProduct, boolean>;

export { ProductSection };

export type ProductSectionAccessPermissions = Record<ProductSection, boolean>;

export enum ApplicationSectionName {
  CompanySettings = 'Company Settings',
  DecisionEngine = 'Decision Engine',
  LoanOriginationSystem = 'Loan Origination System',
  Dashboard = 'Dashboard',
  MyAccount = 'My Account',
}
