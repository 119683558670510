import React, { useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import MainLayout, { PageContent, PageWrapperWithFooter } from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import ApplicationDetails from 'components/LoanOriginationSystem/ApplicationDetails';
import ChangeStatusConfirmationPopup from 'components/LoanOriginationSystem/ApplicationDetails/ChangeStatusConfirmationPopup';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  closeChangeApplicationStatusPopup,
  deleteApplicationCoborrower,
  deleteApplicationIntermediary,
  getApplicationVariables,
  sortApplicationVariables,
  getApplicationData,
  clearApplicationData,
} from 'LoanOriginationSystemApplicationPage/ActionCreator';
import {
  closeRequestCustomerUploadPopup,
  closeUploadApplicationDocumentPopup,
  createDocuSignEnvelope,
  deleteApplicationDocument,
  requestCustomerUpload,
  setApplicationDocumentToDelete,
  toggleSendDocuSignSignaturePopup,
  uploadApplicationDocument,
} from 'LoanOriginationSystemApplicationPage/Documents/ActionCreator';
import {
  changeApplicationStatus,
  ChangeApplicationStatusActionOrigin,
  closeDeleteApplicationPopup,
  closeDuplicateApplicationPopup,
  deleteApplication,
  duplicateApplication,
} from 'LoanOriginationSystemApplications/ActionCreator';
import { useHistory, useParams } from 'react-router';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import { useQueryParams } from 'hooks/useQueryParam';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import ApplicationTabs from 'components/LoanOriginationSystem/ApplicationTabs';
import CreateTask from 'components/LoanOriginationSystem/CreateTask';
import EditTask from 'components/LoanOriginationSystem/EditTask';
import { closeDeleteTaskPopup, deleteTask } from 'LoanOriginationSystemTasks/ActionCreator';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import TasksFilter from 'components/LoanOriginationSystem/TasksDashboard/TasksFilter';
import UploadApplicationDocumentPopup from 'components/LoanOriginationSystem/ApplicationTabs/DocumentsTab/UploadApplicationDocumentPopup';
import ConfirmPopup from 'components/ConfirmPopup';
import styles from './Application.module.scss';
import NavigationLinkId from 'enums/NavigationLinkId';
import { ApplicationNotePopUpType } from 'LoanOriginationSystemApplicationPage/Notes/Types';
import { ApplicationNotePopUp } from 'ApplicationNoteWizard/ApplicationNoteWizard';
import {
  addApplicationNote,
  closeApplicationNotePopup,
  deleteApplicationNote,
  getApplicationNotes,
  setCurrentCurrentApplicationNote,
  updateApplicationNote,
} from 'LoanOriginationSystemApplicationPage/Notes/ActionCreator';
import DeleteApplicationNotePopUp from 'ApplicationNoteWizard/DeleteApplicationNoteWizard';
import RequestCustomerUploadPopup from 'components/LoanOriginationSystem/ApplicationTabs/RequestCustomerUploadPopup';
import { useDispatchRoutine, useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import BorrowerDefaultVariable from 'enums/BorrowerDefaultVariable';
import DeleteApplicationConfirmationPopup from 'components/LoanOriginationSystem/DeleteApplicationConfirmationPopup/DeleteApplicationConfirmationPopup';
import {
  ApplicationStatusesActionOrigin,
  getApplicationStatuses,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import ConnectEmailsPopup from 'components/LoanOriginationSystem/ApplicationTabs/ConnectEmailsPopup';
import { getOrganizationEmail } from 'utils/emailGetters';
import { closeConnectEmailsPopup, closeDeleteEmailPopup, deleteEmail } from 'LoanOriginationSystemEmails/ActionCreator';
import DeleteEmailConfirmationPopup from 'components/LoanOriginationSystem/DeleteEmailConfirmationPopup/DeleteEmailConfirmationPopup';
import EmailView from 'components/LoanOriginationSystem/EmailView';
import EmailNotFound from 'components/LoanOriginationSystem/EmailView/EmailNotFound';
import EditApplication from 'components/LoanOriginationSystem/EditApplication';
import DeleteConfirmationPopup from 'components/LoanOriginationSystem/EditApplication/DeleteConfirmationPopup/DeleteConfirmationPopup';
import EditApplicationStep from 'LoanOriginationSystemApplications/EditApplicationStep';
import { setBorrowerToUnlock, unlockBorrower } from 'LoanOriginationSystemBorrowers/UnlockBorrower/ActionCreator';
import UnlockCustomerPopup from 'components/LoanOriginationSystem/CreateApplication/UnlockCustomerPopup';
import { ESignIntegrationType } from 'api/Types';
import {
  selectCoBorrower,
  selectIntermediary,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { uniqueId } from 'lodash';
import SendESignaturePopup from 'components/LoanOriginationSystem/SendESignaturePopup';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { useESignTemplatesApi } from 'providers/ApiServiceProvider';
import DuplicateApplicationConfirmationPopup from 'components/LoanOriginationSystem/DuplicateApplicationConfirmationPopup/DuplicateApplicationConfirmationPopup';
import ApplicationAttribute from 'LoanOriginationSystemApplications/CreateApplication/ApplicationAttribute';
import ApplicationSkeleton from './ApplicationSkeleton';

const leftNav = makeLeftNavigation(NavigationLinkId.Applications, ApplicationSectionName.LoanOriginationSystem);

const Application = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const dispatchRoutineWithResult = useDispatchRoutineWithResult();
  const queryParams = useQueryParams();
  const emailId = queryParams.get('emailId');
  const eSignTemplatesApi = useESignTemplatesApi();

  const {
    statusToChange,
    applicationData,
    isStatusChangeInProgress,
    variables,
    variableSortingType,
    isDeletingApplicationCoborrower,
    isDeletingApplicationIntermediary,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemApplicationPage);
  const {
    documentCreatePopupOpen,
    documentToDelete,
    documentDeletingInProgress,
    documentRequestCustomerUploadPopUpOpen,
    documentUploadingInProgress,
    isDocuSignSignatureRequestPopupOpen,
    isCreatingDocuSignEnvelopeInProgress,
  } = useSelector((state: ReduxState) => state.loanOriginationSystemDocuments);
  const {
    isPopupOpen: isNotePopupOpen,
    popUpType: notePopUpType,
    currentNote,
    search: notesSearch,
    sortingType: notesSortingType,
    items: notesList,
  } = useSelector((state: ReduxState) => ({
    isPopupOpen: state.loanOriginationSystemApplicationPage.notes.isPopupOpen,
    popUpType: state.loanOriginationSystemApplicationPage.notes.popUpType,
    currentNote: state.loanOriginationSystemApplicationPage.notes.currentNote,
    search: state.loanOriginationSystemApplicationPage.notes.search,
    sortingType: state.loanOriginationSystemApplicationPage.notes.sortingType,
    items: state.loanOriginationSystemApplicationPage.notes.items,
  }));
  const { items: emailList, connectEmailsPopUpOpen, emailIdToDelete, deleteEmailInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemEmails,
  );

  const currentNoteText = notesList.find((note) => note.id === currentNote);
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const { name: organizationName, id: organizationId } = useSelector(
    (state: ReduxState) => state.organizationInformation,
  );
  const { applicationDisplayId } = useParams<{ applicationDisplayId: string }>();

  const declineReasons = applicationData ? declineReasonsByProductId[applicationData.product.id] : [];

  const { filters, taskIdToDelete, isDeleteInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemTasks,
  );

  const {
    unlockBorrower: { borrowerToUnlock, isUnlockingInProgress },
  } = useSelector((state: ReduxState) => state.loanOriginationSystemBorrowers);

  const { applicationIdToDelete, applicationToDuplicate } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemApplications,
  );

  const params = useQueryParams();
  const history = useHistory();

  const [createOrEditTaskDataWasChanged, setCreateOrEditTaskDataWasChanged] = useState(false);
  const [editApplicationDataWasChanged, setEditApplicationDataWasChanged] = useState(false);
  const [createTaskPopupOpen, setCreateTaskPopupOpen] = useState(false);
  const [taskIdToEdit, setTaskIdToEdit] = useState<string | null>(null);
  const [unsavedChangesPopupOpen, setUnsavedChangesPopupOpen] = useState(false);
  const [unsavedChangesPopupOpen2, setUnsavedChangesPopupOpen2] = useState(false);
  const [isDeletingApplication, setIsDeletingApplication] = useState(false);
  const [isDuplicatingApplication, setIsDuplicatingApplication] = useState(false);
  const [isDeleteCoborrower, setIsDeleteCoborrower] = useState(false);
  const [isDeleteIntermediary, setIsDeleteIntermediary] = useState(false);
  const [editApplicationKey, setEditApplicationKey] = useState(uniqueId());
  const [initialEditApplicationStep, setInitialEditApplicationStep] = useState(EditApplicationStep.Borrower);

  const onPopupClose = () => dispatch(closeChangeApplicationStatusPopup());

  const onApplicationNotePopUpClose = () => {
    batch(() => {
      dispatch(closeApplicationNotePopup());
      dispatch(setCurrentCurrentApplicationNote(null));
    });
  };

  const closeContextualView = () => {
    if (taskIdToEdit) {
      setTaskIdToEdit(null);
    }

    if (createTaskPopupOpen) {
      setCreateTaskPopupOpen(false);
    }

    if (params.has('edit')) {
      history.replace(`?tab-id=${queryParams.get('tab-id')}`);
    }

    if (params.has('emailId')) {
      history.replace(`?tab-id=${queryParams.get('tab-id')}`);
    }
  };

  const onChangeStatusClick = (status: ApplicationStatus, selectedDeclineReasons: string[]) => {
    if (!applicationData) {
      return;
    }

    const action = setActionOrigin(
      changeApplicationStatus(applicationData, status, selectedDeclineReasons),
      ChangeApplicationStatusActionOrigin.ApplicationsDetails,
    );

    dispatch(action);
  };

  const onDeleteTaskPopupClose = () => {
    dispatch(closeDeleteTaskPopup());
  };

  const onDeleteTask = () => {
    dispatch(deleteTask(taskIdToDelete!));
  };

  const handleDeleteApplicationDocumentPopupClose = () => {
    dispatch(setApplicationDocumentToDelete(null));
  };

  const handleConfirmApplicationDocumentDelete = () => {
    if (!applicationData || !documentToDelete) {
      return;
    }

    dispatch(deleteApplicationDocument(applicationData.id, documentToDelete.id));
  };

  const handleUploadApplicationDocumentPopupClose = () => {
    dispatch(closeUploadApplicationDocumentPopup());
  };

  const handleApplicationDocumentUpload = async (file: File) => {
    if (!applicationData) {
      return;
    }

    await dispatchRoutine(uploadApplicationDocument(applicationData.id, file));
  };

  const handleRequestCustomerUploadPopupClose = () => {
    dispatch(closeRequestCustomerUploadPopup());
  };

  const handleRequestCustomerUpload = (emailData: { subject: string; description: string }) =>
    dispatchRoutine(
      requestCustomerUpload({
        ...emailData,
        applicationId: applicationData!.id,
      }),
    );

  const getApplicationNotesList = () => {
    dispatch(getApplicationNotes(applicationData!.id, notesSearch, notesSortingType));
  };

  const handleAddApplicationNote = (note: string) => dispatchRoutine(addApplicationNote(applicationData!.id, note));

  const handleUpdateApplicationNote = (note: string) =>
    dispatchRoutine(updateApplicationNote(applicationData!.id, currentNote!, note));

  const handleDeleteApplicationNote = () => dispatchRoutine(deleteApplicationNote(applicationData!.id, currentNote!));

  const handleDeleteApplication = async () => {
    setIsDeletingApplication(true);

    await dispatchRoutine(deleteApplication(applicationIdToDelete!));

    setIsDeletingApplication(false);

    history.push('/los/applications');
  };

  const handleDuplicateApplication = async () => {
    setIsDuplicatingApplication(true);

    try {
      const result = await dispatchRoutineWithResult(duplicateApplication(applicationToDuplicate!.id));

      if (result[ApplicationAttribute.ApplicationId]) {
        history.push(`/los/applications/${result[ApplicationAttribute.ApplicationId]}`);
      }
    } finally {
      setIsDuplicatingApplication(false);
    }
  };

  const isEditApplication = () => {
    return params.has('edit');
  };

  const onCloseContextualView = () => {
    const taskPopupHasUnsavedChanges = (taskIdToEdit || createTaskPopupOpen) && createOrEditTaskDataWasChanged;
    const editApplicationPopupHasUnsavedChanges = params.has('edit') && editApplicationDataWasChanged;

    if (taskPopupHasUnsavedChanges || editApplicationPopupHasUnsavedChanges) {
      setUnsavedChangesPopupOpen(true);

      return;
    }

    if (isEditApplication()) {
      setInitialEditApplicationStep(EditApplicationStep.Borrower);
    }

    closeContextualView();
  };

  const onConnectEmailsPopupClose = () => dispatch(closeConnectEmailsPopup());

  const handleCloseDeleteEmailPopup = () => dispatch(closeDeleteEmailPopup());
  const handleDeleteEmail = async () => {
    await dispatchRoutine(deleteEmail(emailIdToDelete!));
    closeContextualView();
  };

  const onDeleteCoborrower = () => {
    setIsDeleteCoborrower(true);

    dispatch(getApplicationVariables(applicationData!));
  };

  const onDeleteIntermediary = () => {
    setIsDeleteIntermediary(true);

    dispatch(getApplicationVariables(applicationData!));
  };

  const handleDeleteCoborrower = async (variablesToDelete: string[]) => {
    const borrowerName = getBorrowerFullName(
      applicationData!.coborrowerType!,
      applicationData!.variables,
      ApplicationDefaultVariable.Coborrower,
    );

    await dispatchRoutine(deleteApplicationCoborrower(applicationData!.id, variablesToDelete));

    setIsDeleteCoborrower(false);
    resetEditApplicationForm();

    notification.createNotification(
      getMessage(MessageType.ApplicationCoborrowerDeleted, { borrowerName }),
      'success',
      dispatch,
    );
  };

  const handleDeleteIntermediary = async (variablesToDelete: string[]) => {
    const intermediaryName = applicationData!.variables[ApplicationDefaultVariable.Intermediary.Name];

    await dispatchRoutine(deleteApplicationIntermediary(applicationData!.id, variablesToDelete));

    setIsDeleteIntermediary(false);
    resetEditApplicationForm();

    notification.createNotification(
      getMessage(MessageType.ApplicationIntermediaryDeleted, { intermediaryName }),
      'success',
      dispatch,
    );
  };

  const onUnlockClick = () => {
    if (!borrowerToUnlock) {
      return;
    }

    dispatch(unlockBorrower(borrowerToUnlock.id));
  };

  const resetEditApplicationForm = () => {
    dispatch(selectIntermediary(null));
    dispatch(selectCoBorrower(null));

    setEditApplicationKey(uniqueId());
  };

  const handleEditApplicationReset = (step: EditApplicationStep, showUnsavedChangesPopup: boolean) => {
    setInitialEditApplicationStep(step);

    if (showUnsavedChangesPopup && editApplicationDataWasChanged) {
      setUnsavedChangesPopupOpen2(true);
    } else {
      resetEditApplicationForm();
    }
  };

  const handleUnsavedChangesPopupClose = () => {
    if (unsavedChangesPopupOpen) {
      setUnsavedChangesPopupOpen(false);
    } else {
      setUnsavedChangesPopupOpen2(false);
    }
  };

  const handleUnsavedChangesPopupLeave = () => {
    if (unsavedChangesPopupOpen) {
      closeContextualView();
      setUnsavedChangesPopupOpen(false);
    } else {
      resetEditApplicationForm();
      setUnsavedChangesPopupOpen2(false);
    }
  };

  const handleFetchTemplates = (search: string, type: ESignIntegrationType, abortSignal: AbortSignal) => {
    return eSignTemplatesApi.getDropdownOptions(search, type, abortSignal);
  };

  const handleESignClick = (templateId: string) => {
    if (!applicationData) {
      return;
    }

    dispatch(createDocuSignEnvelope(applicationData.id, templateId));
  };

  const handleSendESignaturePopupClose = () => dispatch(toggleSendDocuSignSignaturePopup());

  const renderOverlay = () => {
    if (borrowerToUnlock) {
      return (
        <UnlockCustomerPopup
          onPopupClose={() => dispatch(setBorrowerToUnlock(null))}
          onUnlockClick={onUnlockClick}
          isUnlockingInProgress={isUnlockingInProgress}
        />
      );
    }

    if (statusToChange) {
      return (
        <ChangeStatusConfirmationPopup
          onChangeStatusClick={(selectedDeclineReasons) => onChangeStatusClick(statusToChange, selectedDeclineReasons)}
          declineReasons={declineReasons}
          onPopupClose={onPopupClose}
          statusToChange={statusToChange.name}
          isLoading={isStatusChangeInProgress}
        />
      );
    }

    if (taskIdToDelete) {
      return (
        <ConfirmPopup
          title="Delete Task"
          message="Are you sure you want to remove this task?"
          confirmText="Yes, Delete Task"
          declineText="No, Go Back"
          onPopupClose={onDeleteTaskPopupClose}
          onConfirmClick={onDeleteTask}
          loading={isDeleteInProgress}
        />
      );
    }

    if (unsavedChangesPopupOpen || unsavedChangesPopupOpen2) {
      return (
        <UnsavedChangesPopup
          onPopupClose={handleUnsavedChangesPopupClose}
          onLeaveClick={handleUnsavedChangesPopupLeave}
        />
      );
    }

    if (documentToDelete) {
      return (
        <ConfirmPopup
          title="Delete Document"
          message="Are you sure that you want to delete this document?"
          confirmText="Delete Document"
          declineText="No, Go Back"
          onPopupClose={handleDeleteApplicationDocumentPopupClose}
          onConfirmClick={handleConfirmApplicationDocumentDelete}
          loading={documentDeletingInProgress}
        />
      );
    }

    if (documentCreatePopupOpen) {
      return (
        <UploadApplicationDocumentPopup
          onPopupClose={handleUploadApplicationDocumentPopupClose}
          onApplicationDocumentUpload={handleApplicationDocumentUpload}
          isLoading={documentUploadingInProgress}
        />
      );
    }

    if (documentRequestCustomerUploadPopUpOpen) {
      return (
        <RequestCustomerUploadPopup
          onPopupClose={handleRequestCustomerUploadPopupClose}
          organizationName={organizationName}
          borrowerEmail={applicationData!.variables[BorrowerDefaultVariable.Email] as string}
          handleRequestCustomerUpload={handleRequestCustomerUpload}
        />
      );
    }

    if (isNotePopupOpen) {
      if (notePopUpType === ApplicationNotePopUpType.Add || notePopUpType === ApplicationNotePopUpType.Update) {
        return (
          <ApplicationNotePopUp
            closePopUp={onApplicationNotePopUpClose}
            popUpType={notePopUpType}
            getApplicationNotes={getApplicationNotesList}
            create={handleAddApplicationNote}
            update={handleUpdateApplicationNote}
            note={currentNoteText}
          />
        );
      }

      return (
        <DeleteApplicationNotePopUp
          closePopUp={onApplicationNotePopUpClose}
          deleteApplicationNote={handleDeleteApplicationNote}
        />
      );
    }

    if (applicationIdToDelete) {
      return (
        <DeleteApplicationConfirmationPopup
          onConfirmClick={handleDeleteApplication}
          onClose={() => dispatch(closeDeleteApplicationPopup())}
          loading={isDeletingApplication}
        />
      );
    }

    if (applicationToDuplicate) {
      return (
        <DuplicateApplicationConfirmationPopup
          onConfirmClick={handleDuplicateApplication}
          onClose={() => dispatch(closeDuplicateApplicationPopup())}
          loading={isDuplicatingApplication}
          application={applicationToDuplicate}
        />
      );
    }

    if (connectEmailsPopUpOpen) {
      return (
        <ConnectEmailsPopup
          emailAddress={getOrganizationEmail(organizationId)}
          onPopupClose={onConnectEmailsPopupClose}
        />
      );
    }

    if (isDeleteCoborrower) {
      return (
        <DeleteConfirmationPopup
          entityName={EditApplicationStep.CoBorrower}
          variables={variables}
          values={applicationData!.variables}
          onClose={() => setIsDeleteCoborrower(false)}
          onSubmit={handleDeleteCoborrower}
          isLoading={isDeletingApplicationCoborrower}
          variableSortingType={variableSortingType}
          onVariablesSort={(field, ascending) => dispatch(sortApplicationVariables(field, ascending))}
        />
      );
    }

    if (emailIdToDelete) {
      return (
        <DeleteEmailConfirmationPopup
          onConfirmClick={handleDeleteEmail}
          onPopupClose={handleCloseDeleteEmailPopup}
          loading={deleteEmailInProgress}
        />
      );
    }

    if (isDeleteIntermediary) {
      return (
        <DeleteConfirmationPopup
          entityName={EditApplicationStep.Intermediary}
          variables={variables}
          values={applicationData!.variables}
          onClose={() => setIsDeleteIntermediary(false)}
          onSubmit={handleDeleteIntermediary}
          isLoading={isDeletingApplicationIntermediary}
          variableSortingType={variableSortingType}
          onVariablesSort={(field, ascending) => dispatch(sortApplicationVariables(field, ascending))}
        />
      );
    }

    if (isDocuSignSignatureRequestPopupOpen && applicationData) {
      return (
        <SendESignaturePopup
          application={applicationData}
          type={ESignIntegrationType.DocuSign}
          onGetTemplates={handleFetchTemplates}
          onSendESign={handleESignClick}
          onPopupClose={handleSendESignaturePopupClose}
          isSendingInProgress={isCreatingDocuSignEnvelopeInProgress}
          eSignatureName="DocuSign"
        />
      );
    }

    return null;
  };

  const renderContextualView = () => {
    if (isEditApplication()) {
      return (
        <EditApplication
          key={editApplicationKey}
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setEditApplicationDataWasChanged(changed)}
          onDeleteCoborrower={onDeleteCoborrower}
          onDeleteIntermediary={onDeleteIntermediary}
          onReset={handleEditApplicationReset}
          initialStep={initialEditApplicationStep}
        />
      );
    }

    if (createTaskPopupOpen) {
      return (
        <CreateTask
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          applicationId={applicationData?.id}
          onCreated={() => setCreateTaskPopupOpen(false)}
        />
      );
    }

    if (taskIdToEdit) {
      return (
        <EditTask
          id={taskIdToEdit}
          onClose={onCloseContextualView}
          setDataWasChanged={(changed) => setCreateOrEditTaskDataWasChanged(changed)}
          lockApplication
          onEditSuccess={() => setTaskIdToEdit(null)}
        />
      );
    }

    if (emailId) {
      const currentEmailInfo = emailList.find((email) => email.id === emailId);
      return currentEmailInfo ? (
        <EmailView
          currentEmailInfo={currentEmailInfo}
          onClose={onCloseContextualView}
          organizationEmail={getOrganizationEmail(organizationId)}
        />
      ) : (
        <EmailNotFound onClose={onCloseContextualView} />
      );
    }

    return null;
  };

  const rightSidePopupView = filters.isPopupVisible && (
    <TasksFilter
      dueDateFrom={filters.dueDateRange.from}
      dueDateTo={filters.dueDateRange.to}
      status={filters.selectedStatus}
    />
  );

  const applicationStatuses =
    useSelector((state: ReduxState) => getStatusesByProductId(state, applicationData?.product.id)) || [];

  useEffect(() => {
    if (applicationData && applicationStatuses.length === 0) {
      dispatch(
        setActionOrigin(
          getApplicationStatuses(applicationData.product.id),
          ApplicationStatusesActionOrigin.Applications,
        ),
      );
    }
  }, [applicationData]);

  useEffect(() => {
    dispatch(getApplicationData(applicationDisplayId));

    return () => {
      dispatch(clearApplicationData());
    };
  }, [applicationDisplayId]);

  return (
    <MainLayout
      leftNav={leftNav}
      overlay={renderOverlay()}
      contextualView={renderContextualView()}
      closeContextualView={onCloseContextualView}
      rightSidePopupView={rightSidePopupView}
    >
      <PageWrapperWithFooter>
        <PageContent noPadding className={styles.pageContent}>
          {applicationData ? (
            <>
              <ApplicationDetails applicationDisplayId={applicationDisplayId} />
              <ApplicationTabs
                onCreateTask={() => setCreateTaskPopupOpen(true)}
                onEditTask={setTaskIdToEdit}
                selectedTabId={params.get('tab-id')}
              />
            </>
          ) : (
            <ApplicationSkeleton />
          )}
        </PageContent>
      </PageWrapperWithFooter>
    </MainLayout>
  );
};

export default Application;
