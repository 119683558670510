import React, { CSSProperties, ReactNode, useState } from 'react';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';
import clsx from 'clsx';
import styles from 'components/WithBordersDndList/WithBordersDndList.module.scss';
import { DndListProps } from 'components/DndList/DndList';

interface WithBordersDndListProps<Item> extends DndListProps<Item> {
  children: (props: DndListProps<Item>) => ReactNode;
  placeholderStyles?: CSSProperties | null;
}

const WithBordersDndList = <Item extends { id: string | number }>({
  renderListItem,
  listItemClassName,
  children,
  ...props
}: WithBordersDndListProps<Item>) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<null | number>(null);

  const renderRow = (item: Item, index: number, snapshot: DraggableStateSnapshot) => {
    const ruleRowClasses = clsx(listItemClassName, styles.withBordersRow, {
      [styles.withBordersRow__dragging]: snapshot.isDragging,
      [styles.withBordersRow__hiddenTopBorder]: typeof hoveredItemIndex === 'number' && index === hoveredItemIndex + 1,
    });

    return (
      <div
        className={ruleRowClasses}
        onMouseEnter={() => setHoveredItemIndex(index)}
        onMouseLeave={() => setHoveredItemIndex(null)}
      >
        {renderListItem(item, index, snapshot)}
      </div>
    );
  };

  const componentProps = {
    ...props,
    renderListItem: renderRow,
  };

  return <>{children(componentProps)}</>;
};

export default WithBordersDndList;
