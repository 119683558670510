import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import replaceAt from 'utils/replaceAt';
import { ApplicationStatusesActionType } from './ActionTypes';
import { LoanOriginationSystemApplicationStatusesState } from './Types';
import {
  CreateApplicationStatusRuleSuccessAction,
  CreateApplicationStatusSuccessAction,
  DeleteApplicationStatusRuleSuccessAction,
  DeleteApplicationStatusSuccessAction,
  GetApplicationStatusesSuccessAction,
  UpdateApplicationStatusRuleSuccessAction,
  UpdateApplicationStatusSuccessAction,
} from './ActionCreator';

export type LoanOriginationSystemApplicationStatusesActions =
  | UpdateApplicationStatusRuleSuccessAction
  | DeleteApplicationStatusRuleSuccessAction
  | CreateApplicationStatusRuleSuccessAction
  | GetApplicationStatusesSuccessAction
  | DeleteApplicationStatusSuccessAction
  | CreateApplicationStatusSuccessAction
  | UpdateApplicationStatusSuccessAction;

const initialState: LoanOriginationSystemApplicationStatusesState = {
  statusesById: {},
  statusesIdsByProductId: {},
};

const getStatusesById = (statuses: ApplicationStatus[]) => {
  return statuses.reduce(
    (statusesById, status) => ({
      ...statusesById,
      [status.id]: status,
    }),
    {},
  );
};

const loanOriginationSystemApplicationStatusesReducer = (
  state = initialState,
  action: LoanOriginationSystemApplicationStatusesActions,
): LoanOriginationSystemApplicationStatusesState => {
  switch (action.type) {
    case ApplicationStatusesActionType.GetApplicationStatusesSuccess: {
      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          ...getStatusesById(action.payload.statuses),
        },
        statusesIdsByProductId: {
          ...state.statusesIdsByProductId,
          [action.payload.productId]: action.payload.statuses.map((status) => status.id),
        },
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusSuccess: {
      const { productId } = action.payload.status;

      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          [action.payload.status.id]: action.payload.status,
        },
        statusesIdsByProductId: {
          ...state.statusesIdsByProductId,
          [productId]: [...(state.statusesIdsByProductId[productId] || []), action.payload.status.id],
        },
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusSuccess: {
      const status = state.statusesById[action.payload.statusId];

      if (!status) {
        return state;
      }

      return {
        ...state,
        statusesIdsByProductId: {
          ...state.statusesIdsByProductId,
          [status.productId]: (state.statusesIdsByProductId[status.productId] || []).filter(
            (statusId) => statusId !== action.payload.statusId,
          ),
        },
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusSuccess: {
      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          [action.payload.status.id]: action.payload.status,
        },
      };
    }
    case ApplicationStatusesActionType.CreateApplicationStatusRuleSuccess: {
      const statusToUpdate = state.statusesById[action.payload.statusRule.statusId];

      if (!statusToUpdate) {
        return state;
      }

      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          [statusToUpdate.id]: {
            ...statusToUpdate,
            rules: [...statusToUpdate.rules, action.payload.statusRule],
          },
        },
      };
    }
    case ApplicationStatusesActionType.DeleteApplicationStatusRuleSuccess: {
      const statusToUpdate = state.statusesById[action.payload.statusId];

      if (!statusToUpdate) {
        return state;
      }

      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          [statusToUpdate.id]: {
            ...statusToUpdate,
            rules: statusToUpdate.rules.filter((rule) => rule.id !== action.payload.ruleId),
          },
        },
      };
    }
    case ApplicationStatusesActionType.UpdateApplicationStatusRuleSuccess: {
      const statusToUpdate = state.statusesById[action.payload.rule.statusId];

      if (!statusToUpdate) {
        return state;
      }

      const ruleIndex = statusToUpdate.rules.findIndex((rule) => rule.id === action.payload.rule.id);

      return {
        ...state,
        statusesById: {
          ...state.statusesById,
          [statusToUpdate.id]: {
            ...statusToUpdate,
            rules: replaceAt(statusToUpdate.rules, ruleIndex, action.payload.rule),
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemApplicationStatusesReducer;
