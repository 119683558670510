import React, { FC } from 'react';
import RunDecisionForm from 'components/RunDecisionForm';
import History from './History';
import styles from './IndividualProcessing.module.scss';
import { StrategiesListItem, IndividualRunInputs } from 'api/Types';
import { useHistory } from 'react-router-dom';
import { ProcessingStrategyInput } from 'api/DecisionEngineStrategiesType';
import PageName from 'constants/PageName';

export enum TabName {
  RUN = 'run',
  HISTORY = 'history',
}

export interface IndividualProcessingProps {
  currentStrategy?: string;
  strategies: StrategiesListItem[];
  onTabChange: (tab: TabName) => void;
  switchBlock: TabName;
  strategyInputs: ProcessingStrategyInput[] | null;
  onSearchInputChange: (searchInputValue: string) => void;
  onRunDecision: (inputs: IndividualRunInputs) => Promise<unknown>;
  initialInputValues?: IndividualRunInputs;
  isInputsLoading: boolean;
}

const IndividualProcessing: FC<IndividualProcessingProps> = ({
  currentStrategy,
  strategies,
  onTabChange,
  switchBlock,
  strategyInputs,
  onSearchInputChange,
  onRunDecision,
  initialInputValues,
  isInputsLoading,
}) => {
  const history = useHistory();
  const handleStrategyChange = (strategyId: string) => {
    history.push(`/decision/processing/individual/run/${strategyId}`);
  };

  const renderBlockContent = () => {
    if (switchBlock === 'run') {
      return (
        <>
          <h4 className={styles.title}>Inputs</h4>
          <RunDecisionForm
            strategies={strategies}
            currentStrategy={currentStrategy}
            strategyInputs={strategyInputs}
            onRunDecision={onRunDecision}
            onStrategyChange={handleStrategyChange}
            key={currentStrategy}
            initialInputValues={initialInputValues}
            inputContainerClassName={styles.input}
            inputsLoading={isInputsLoading}
          />
        </>
      );
    }

    if (switchBlock === 'history') {
      return <History onSearchInputChange={onSearchInputChange} />;
    }

    return null;
  };

  return (
    <div className={styles.processingPage}>
      <div className={styles.processingHeader}>
        <div className={styles.processingHeader__title}>
          <h2>{PageName.IndividualProcessing}</h2>
        </div>
      </div>
      <div className={styles.processingDescription}>
        <p>Provide inputs and generate a single decision</p>
      </div>
      <div className={styles.processingSwitch}>
        <input
          type="radio"
          id="run-decision"
          name="switch"
          checked={switchBlock === TabName.RUN}
          onChange={() => onTabChange(TabName.RUN)}
        />
        <label htmlFor="run-decision">Run Decision</label>
        <input
          type="radio"
          id="history"
          name="switch"
          checked={switchBlock === TabName.HISTORY}
          onChange={() => onTabChange(TabName.HISTORY)}
        />
        <label htmlFor="history">History</label>
      </div>
      <div className={styles.processingContent}>{renderBlockContent()}</div>
    </div>
  );
};

export default IndividualProcessing;
