import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import {
  GetBorrowersAction,
  getBorrowersForEmailsFilterFailure,
  getBorrowersForEmailsFilterSuccess,
} from './ActionCreator';
import { LoanOriginationSystemEmailsActionTypes } from './ActionTypes';
import { BorrowerForEmailsFilterType } from './Types';

const GetBorrowersMiddleware = (api: LoanOriginationSystemEmailsApi) =>
  Fetcher<BorrowerForEmailsFilterType[], GetBorrowersAction>(
    LoanOriginationSystemEmailsActionTypes.GetBorrowersForEmailsFilter,
    getBorrowersForEmailsFilterSuccess,
    getBorrowersForEmailsFilterFailure,
    async () => {
      const borrowers = await api.getBorrowers();

      return borrowers;
    },
  );

export default GetBorrowersMiddleware;
