import { connect } from 'react-redux';
import VersionsView from 'components/VersionsView/VersionsView';
import { VersionsListDataState } from 'VersionsViewData/Reducer';
import { clearListData, getVersionsListData, resetFilters } from 'VersionsViewData/Actions';

export type VersionsViewConnectedProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const mapStateToProps = ({ versionsListData }: { versionsListData: VersionsListDataState }) => {
  return {
    items: versionsListData.items,
    searchInputValue: versionsListData.searchInputValue,
    showArchived: versionsListData.showArchived,
    sortingType: versionsListData.sortingType,
    selectedUpdaters: versionsListData.selectedUpdaters,
  };
};

const mapDispatchToProps = {
  getVersionsListData,
  clearListData,
  resetFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionsView);
