import { Middleware } from 'redux';
import AccountDetailsActionType from './ActionTypes';
import { confirmPasswordSuccess, confirmPasswordFailed, ConfirmPasswordAction } from './AccountDetailsActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { IPasswordConfirmationApi } from 'api/Core/PasswordConfirmationApi';

const ConfirmPasswordMiddleware: (api: IPasswordConfirmationApi) => Middleware = (api) =>
  Fetcher<void, ConfirmPasswordAction>(
    AccountDetailsActionType.ConfirmPassword,
    confirmPasswordSuccess,
    confirmPasswordFailed,
    ({ payload }) => api.confirmPassword(payload.password),
  );

export default ConfirmPasswordMiddleware;
