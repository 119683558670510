import React from 'react';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { RobotImage } from 'static/images';
import { TextSize } from 'components/Avatar';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';

export interface WithSystemApiUser extends UserInfo {
  imageUrl?: string;
}

export interface WithSystemApiUserAvatarProps {
  className?: string;
  user?: WithSystemApiUser | null;
  size?: TextSize;
}

const SYSTEM_API_USER: WithSystemApiUser = {
  id: 'SystemApiId',
  firstName: 'System',
  lastName: 'API',
  imageUrl: RobotImage,
};

const WithSystemApiUserAvatar = ({ user, className, size }: WithSystemApiUserAvatarProps) => {
  const avatarUser = user || SYSTEM_API_USER;
  return (
    <ImageFetcher
      className={className}
      size={size}
      firstName={avatarUser.firstName}
      lastName={avatarUser.lastName}
      imageUrl={avatarUser.imageUrl}
      imageId={avatarUser.imageId}
      tooltip
    />
  );
};

export default WithSystemApiUserAvatar;
