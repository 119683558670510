import { AnyAction } from 'redux';
import { SessionInfo } from './Types';
import { SessionInfoActionType } from './ActionTypes';

export interface UpdateSessionInfoAction extends AnyAction {
  type: SessionInfoActionType.UpdateSessionInfo;
  payload: {
    sessionInfo: Partial<SessionInfo>;
  };
}

export interface SetIsLoadingAction extends AnyAction {
  type: SessionInfoActionType.SetIsLoading;
  payload: {
    isLoading: boolean;
  };
}

export const setIsLoading = (isLoading: boolean): SetIsLoadingAction => {
  return {
    type: SessionInfoActionType.SetIsLoading,
    payload: {
      isLoading,
    },
  };
};

export const updateSessionInfo = (sessionInfo: Partial<SessionInfo>): UpdateSessionInfoAction => ({
  type: SessionInfoActionType.UpdateSessionInfo,
  payload: {
    sessionInfo,
  },
});
