import React, { ChangeEvent, FC } from 'react';
import { NullableItems, PaginationProps } from 'pagination';
import { Variable } from 'Variables/VariablesTypes';
import styles from './Variables.module.scss';
import Button from 'components/Button';
import SearchNotFound from 'components/SearchNotFound';
import SearchInput from 'components/SearchInput';
import { VariablesSortingField, VariablesSortingType } from 'api/Core/VariablesApi';
import FilterButton from 'components/FilterButton';
import { useDispatch } from 'react-redux';
import {
  toggleFiltersPopup,
  filterVariablesByMembers,
  resetAllVariablesFilters,
} from 'Variables/Filters/ActionCreator';
import { VariablesFiltersState } from 'Variables/Filters/Types';
import VariablesTable from './VariablesTable';
import NoItems from 'components/NoItems';
import { NoResultsIcon } from 'static/images';
import PageName from 'constants/PageName';
import SliderButton from 'components/StrategyGroups/SliderButton';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

interface VariablesProps {
  variables: NullableItems<Variable>;
  paginationProps: PaginationProps;
  openVariableStatusChangePopup: (id: string) => void;
  onAddNewVariable: () => void;
  onEditVariable: (variableId: string) => void;
  onSearchInputChange: (searchInputValue: string) => void;
  searchInputValue: string;
  empty?: boolean;
  currentVariableId: string | null;
  sortingType: VariablesSortingType;
  onSort: (field: VariablesSortingField, ascending: boolean) => void;
  filters: VariablesFiltersState;
  showArchived: boolean;
  setShowArchived: (enabled: boolean) => void;
}

const Variables: FC<VariablesProps> = ({
  variables,
  onAddNewVariable,
  onEditVariable,
  openVariableStatusChangePopup,
  paginationProps,
  onSearchInputChange,
  searchInputValue,
  empty = false,
  currentVariableId,
  sortingType,
  onSort,
  filters,
  showArchived,
  setShowArchived,
}) => {
  const dispatch = useDispatch();

  const members = useOrganizationMembers();

  const handleSearchInputChange =
    onSearchInputChange && (({ target }: ChangeEvent<HTMLInputElement>) => onSearchInputChange(target.value));

  const handleFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const handleUserFilterChange = (newMembers: UserInfo[]) => {
    dispatch(filterVariablesByMembers(newMembers));
  };

  const areFiltersChanged = () => {
    return (
      filters.dueCreatedDateRange.from !== null ||
      filters.dueCreatedDateRange.to !== null ||
      filters.dueUpdatedDateRange.from !== null ||
      filters.dueUpdatedDateRange.to !== null ||
      filters.dataType !== '' ||
      filters.onlyCustom ||
      filters.onlyStandard
    );
  };

  const onResetAllFilters = () => {
    dispatch(resetAllVariablesFilters());
  };

  const handleSetShowArchived = (enabled: boolean) => {
    setShowArchived(enabled);
  };

  const renderBody = () => {
    if ((areFiltersChanged() || filters.selectedMembers.length !== 0) && empty) {
      return (
        <NoItems
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear Filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noItemsSubtitle}
          onButtonClick={onResetAllFilters}
          className={styles.noResultsFoundContainer}
        />
      );
    }
    if (empty && searchInputValue) {
      return <SearchNotFound searchValue={searchInputValue} />;
    }
    return (
      <VariablesTable
        variables={variables}
        onEditVariable={onEditVariable}
        openVariableStatusChangePopup={openVariableStatusChangePopup}
        paginationProps={paginationProps}
        currentVariableId={currentVariableId}
        sortingType={sortingType}
        onSort={onSort}
        searchInputValue={searchInputValue}
      />
    );
  };

  return (
    <div className={styles.variablesPage}>
      <div className={styles.variablesHeader}>
        <div className={styles.variablesHeader__title}>
          <h2>{PageName.Variables}</h2>
        </div>
        <div className={styles.variablesHeader__button}>
          <Button kind="primary" className={styles.addVariableButton} onClick={onAddNewVariable}>
            Add Variable
          </Button>
        </div>
      </div>
      <div className={styles.filtersContainer}>
        <SearchInput
          placeholder="Search"
          containerClassName={styles.variablesSearch}
          value={searchInputValue}
          onChange={handleSearchInputChange}
          onClear={() => onSearchInputChange('')}
        />
        <UserFilter
          className={styles.userFilter}
          users={members}
          selectedUsers={filters.selectedMembers}
          onChange={handleUserFilterChange}
        />
        <SliderButton active={showArchived} onChange={handleSetShowArchived} labelText="Show Archived" />
        <FilterButton
          onClick={handleFilterButtonClick}
          active={areFiltersChanged()}
          containerClassName={styles.filterButton}
        />
      </div>
      {renderBody()}
    </div>
  );
};

export default Variables;
