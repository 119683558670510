import { ESignIntegrationsState } from './Types';
import { ESignIntegrationsActionType } from './ActionTypes';
import {
  CreateDocuSignIntegrationSuccessAction,
  GetDocuSignIntegrationSuccessAction,
  UpdateDocuSignIntegrationSuccessAction,
} from './ActionCreator';

export type ESignIntegrationsActions =
  | GetDocuSignIntegrationSuccessAction
  | CreateDocuSignIntegrationSuccessAction
  | UpdateDocuSignIntegrationSuccessAction;

const initialState: ESignIntegrationsState = {
  docuSignIntegration: null,
  isDocuSignIntegrationLoaded: false,
};

const eSignIntegrationsReducer = (
  state: ESignIntegrationsState = initialState,
  action: ESignIntegrationsActions,
): ESignIntegrationsState => {
  switch (action.type) {
    case ESignIntegrationsActionType.GetDocuSignIntegrationSuccess:
    case ESignIntegrationsActionType.CreateDocuSignIntegrationSuccess:
    case ESignIntegrationsActionType.UpdateDocuSignIntegrationSuccess: {
      return {
        ...state,
        isDocuSignIntegrationLoaded: true,
        docuSignIntegration: action.payload.integration,
      };
    }
    default: {
      return state;
    }
  }
};

export default eSignIntegrationsReducer;
