import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { DELETE_DECISION_STRATEGY_REQUEST_SUCCESS } from './ActionTypes';
import {
  DeleteDecisionStrategySuccessAction,
  StrategyActionOrigin,
} from 'DecisionStrategy/DecisionStrategiesActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import { batch } from 'react-redux';
import { closeDeleteStrategyWizard } from 'DeleteStrategyWizard/Actions';
import getMessage, { MessageType } from 'constants/messages';
import { getStrategyGroupsData } from 'StrategyGroupsData/Actions';
import { getVersionsListData } from 'VersionsViewData/Actions';
import { History } from 'history';

const OnDeleteDecisionStrategySuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === DELETE_DECISION_STRATEGY_REQUEST_SUCCESS) {
    const { payload, meta } = action as DeleteDecisionStrategySuccessAction;
    const { actionOrigin } = meta;

    notification.createNotification(
      getMessage(MessageType.VersionDeleted, {
        strategyName: payload.strategyInfo.name,
        strategyVersion: payload.strategyInfo.version,
      }),
      'success',
      dispatch,
    );
    batch(() => {
      if (
        actionOrigin === StrategyActionOrigin.StrategiesGroupsList ||
        actionOrigin === StrategyActionOrigin.StrategyGroupVersionsList
      ) {
        dispatch(getStrategyGroupsData());
      }

      if (actionOrigin === StrategyActionOrigin.StrategyGroupVersionsList) {
        if (payload.isCurrent) {
          replace('/decision/strategies');
        } else {
          dispatch(getVersionsListData());
        }
      }

      if (actionOrigin === StrategyActionOrigin.VersionOverview) {
        replace('/decision/strategies');
      }
      dispatch(closeDeleteStrategyWizard());
    });
  }
  return result;
};

export default OnDeleteDecisionStrategySuccessMiddleware;
