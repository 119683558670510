import Feature from 'BillingPage/Feature';

const getFeature = (type: Feature, label: string) => ({ type, label });

const FEATURE_LAYOUT = [
  getFeature(Feature.CloudHosting, 'Cloud Hosting'),
  getFeature(Feature.OnlineAccess, 'Online Access'),
  getFeature(Feature.OnlineDocumentation, 'Online Documentation'),
  getFeature(Feature.StandardProcessAutomation, 'Standard Process Automation'),
  getFeature(Feature.StandardIntegrations, 'Standard Integrations'),
  getFeature(Feature.CustomerSupport, 'Customer Support'),
  getFeature(Feature.ApiAccess, 'API Access'),
  getFeature(Feature.DataExport, 'Data Export'),
  getFeature(Feature.CustomProcessAutomation, 'Custom Process Automation'),
  getFeature(Feature.CustomIntegrations, 'Custom Integrations'),
  getFeature(Feature.UserTraining, 'User Training'),
  getFeature(Feature.DedicatedSupportLead, 'Dedicated Support Lead'),
  getFeature(Feature.AdditionalSandboxAccount, 'Additional Sandbox Account'),
  getFeature(Feature.UptimeGuaranteeSla, 'Uptime Guarantee SLA'),
  getFeature(Feature.ResponseTimeSla, 'Response Time SLA'),
  getFeature(Feature.SecurityAndComplianceAuditSupport, 'Security & Compliance Audit Support'),
  getFeature(Feature.CustomLegalAgreements, 'Custom Legal Agreements'),
];

export default FEATURE_LAYOUT;
