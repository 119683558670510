import Fetcher from 'middlewares/Fetcher';
import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import {
  RenameApplicationDocumentAction,
  renameApplicationDocumentSuccess,
  renameApplicationDocumentFailure,
} from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';
import getMessage, { MessageType } from 'constants/messages';
import { RequestError } from 'utils/fetch';

const RenameApplicationDocumentMiddleware = (api: ApplicationDocumentsApi) =>
  Fetcher<void, RenameApplicationDocumentAction>(
    ApplicationDocumentsActionType.RenameApplicationDocument,
    renameApplicationDocumentSuccess,
    renameApplicationDocumentFailure,
    async (action) => {
      try {
        await api.rename(action.payload.applicationId, action.payload.documentId, action.payload.documentName);
      } catch (err) {
        if (err instanceof RequestError && err.data && err.data.isEditingNotAllowedError) {
          throw err;
        }

        throw new Error(getMessage(MessageType.ApplicationDocumentIncorrectNameProvided));
      }
    },
  );

export default RenameApplicationDocumentMiddleware;
