import { Dispatch } from 'react';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { UpdateApplicationTeamMembersSuccessAction } from 'LoanOriginationSystemApplicationPage/ActionCreator';
import { ApplicationPageActionType } from 'LoanOriginationSystemApplicationPage/ActionTypes';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';

const isUpdateApplicationTeamMembersSuccessAction = (
  action: AnyAction,
): action is UpdateApplicationTeamMembersSuccessAction =>
  action.type === ApplicationPageActionType.UpdateApplicationTeamMembersSuccess;

const updateApplicationTeamMembersSuccessMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateApplicationTeamMembersSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationTeamMembersUpdated), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default updateApplicationTeamMembersSuccessMiddleware;
