import React, { ChangeEvent, FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxState } from 'types/redux';
import { ESignTemplatesSortingField } from 'api/Core/ESignTemplatesApi';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import { NoResultsIcon } from 'static/images';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import useStateReset from 'hooks/useStateReset';
import { setDeleteTemplateId, changeSortingType } from 'DocuSign/ActionCreator';
import {
  setSearchInputValue,
  toggleFiltersPopup,
  filterTemplatesByMembers,
  resetAllFilters,
} from 'DocuSign/Filters/ActionCreator';
import { DocuSignActionType } from 'DocuSign/ActionTypes';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { DocuSignIntegration } from 'api/Core/DocuSignIntegrationApi';
import { ESignTemplatesActionOrigin, getESignTemplates } from 'ESignTemplates/ActionCreator';
import { getESignTemplatesByIds } from 'ESignTemplates/Selectors';
import SearchInput from 'components/SearchInput';
import TemplatesTable from 'components/DocuSign/TemplatesTable';
import UserFilter from 'components/LoanOriginationSystem/UserFilter';
import FilterButton from 'components/FilterButton';
import NoItems from 'components/NoItems';
import pagination, {
  DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
  DocuSignTemplatesPaginationParams,
} from 'components/DocuSign/pagination';
import SearchNotFound from 'components/SearchNotFound';
import styles from './DocuSignTemplates.module.scss';

interface DocuSignTemplatesProps {
  integration: DocuSignIntegration | null;
  isIntegrationLoaded: boolean;
  onGoToCredentials: () => void;
  onTemplatesImport: () => void;
}

const DocuSignTemplates: FC<DocuSignTemplatesProps> = ({
  integration,
  onGoToCredentials,
  onTemplatesImport,
  isIntegrationLoaded,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { sortingType, filters } = useSelector((state: ReduxState) => state.docuSign);

  useEffect(() => {
    if (integration) {
      const action = setActionOrigin(
        getESignTemplates(
          {
            integrationId: integration.id,
            search: filters.searchInputValue,
            status: filters.selectedStatus,
            members: filters.selectedMembers,
            createdDateRange: filters.createdDateRange,
            updatedDateRange: filters.updatedDateRange,
          },
          sortingType,
          DOCU_SIGN_TEMPLATES_PER_PAGE_DEFAULT,
        ),
        ESignTemplatesActionOrigin.DocuSignConfiguration,
      );
      dispatch(action);
    }
  }, [integration]);

  useStateReset(DocuSignActionType.ResetTemplatesState);

  const params: DocuSignTemplatesPaginationParams = {
    search: filters.searchInputValue,
    sortingType,
    integrationId: integration?.id,
    selectedMembers: filters.selectedMembers,
    selectedStatus: filters.selectedStatus,
    createdDateRange: filters.createdDateRange,
    updatedDateRange: filters.updatedDateRange,
  };

  const templateIds = pagination.usePaginatedItems(params);
  const paginationProps = pagination.usePagination(params);
  const templates = useSelector((state: ReduxState) => getESignTemplatesByIds(state, templateIds));
  const members = useOrganizationMembers();

  const handleSearchClear = () => {
    dispatch(setSearchInputValue('', integration?.id));
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchInputValue(event.target.value, integration?.id));
  };

  const handleTemplateDelete = (id: string) => {
    dispatch(setDeleteTemplateId(id));
  };

  const handleFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const handleUserFilterChange = (newMembers: UserInfo[]) => {
    dispatch(filterTemplatesByMembers(newMembers, integration?.id));
  };

  const handleFiltersReset = () => {
    dispatch(resetAllFilters(integration?.id));
  };

  const handleTemplateEdit = (id: string) => {
    history.replace(`?edit=${id}`);
  };

  const handleSort = (sortingField: ESignTemplatesSortingField, ascending: boolean) => {
    dispatch(
      changeSortingType(
        {
          field: sortingField,
          ascending,
        },
        integration?.id,
      ),
    );
  };

  const areFiltersApplied = () => {
    return (
      !!filters.selectedStatus ||
      filters.createdDateRange.from !== null ||
      filters.createdDateRange.to !== null ||
      filters.updatedDateRange.from !== null ||
      filters.updatedDateRange.to !== null
    );
  };

  const renderTableContent = () => {
    if (filters.searchInputValue && !templates.length) {
      return <SearchNotFound searchValue={filters.searchInputValue} />;
    }

    if ((areFiltersApplied() || filters.selectedMembers.length) && !templates.length) {
      return (
        <NoItems
          className={styles.noFiltersContainer}
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear all filters"
          subtitle="Please remove or adjust your filters."
          subtitleClassName={styles.noFiltersSubtitle}
          onButtonClick={handleFiltersReset}
        />
      );
    }

    return (
      <TemplatesTable
        source={templates}
        paginationProps={paginationProps}
        searchInputValue={filters.searchInputValue}
        sortingType={sortingType}
        onSort={handleSort}
        onTemplateDelete={handleTemplateDelete}
        onTemplateEdit={handleTemplateEdit}
      />
    );
  };

  const renderContent = () => {
    if (!isIntegrationLoaded) {
      return null;
    }

    if (!integration) {
      return (
        <NoItems
          className={styles.noItemsContainer}
          titleClassName={styles.noItemsTitle}
          buttonClassName={styles.noItemsButton}
          title="Your credentials have not been added yet!"
          buttonMessage="Go To Credentials"
          onButtonClick={onGoToCredentials}
        />
      );
    }

    if (!templates.length && !filters.searchInputValue && !areFiltersApplied() && !filters.selectedMembers.length) {
      return (
        <NoItems
          className={styles.noItemsContainer}
          titleClassName={styles.noItemsTitle}
          buttonClassName={styles.noItemsButton}
          title="Import your templates to get started!"
          buttonMessage="Import Templates"
          onButtonClick={onTemplatesImport}
        />
      );
    }

    return (
      <>
        <div className={styles.header}>
          <div className={styles.filtersContainer}>
            <SearchInput
              value={filters.searchInputValue}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              placeholder="Search"
            />
            <UserFilter
              className={styles.userFilter}
              users={members}
              selectedUsers={filters.selectedMembers}
              onChange={handleUserFilterChange}
            />
          </div>
          <FilterButton onClick={handleFilterButtonClick} active={areFiltersApplied()} />
        </div>
        {renderTableContent()}
      </>
    );
  };

  return (
    <div>
      <h4 className={styles.layoutHeader}>Templates</h4>
      {renderContent()}
    </div>
  );
};

export default DocuSignTemplates;
