import Fetcher from 'middlewares/Fetcher';
import { DocuSignEnvelopeApi } from 'api/LoanOriginationSystem/DocuSignEnvelopeApi';
import {
  createDocuSignEnvelopeFailure,
  createDocuSignEnvelopeSuccess,
  CreateDocuSignEnvelopeAction,
} from './ActionCreator';
import { ApplicationDocumentsActionType } from './ActionTypes';

const CreateDocuSignEnvelopeMiddleware = (api: DocuSignEnvelopeApi) =>
  Fetcher<void, CreateDocuSignEnvelopeAction>(
    ApplicationDocumentsActionType.CreateDocuSignEnvelope,
    createDocuSignEnvelopeSuccess,
    createDocuSignEnvelopeFailure,
    ({ payload }) => {
      return api.createEnvelope(payload.applicationId, payload.templateId);
    },
  );

export default CreateDocuSignEnvelopeMiddleware;
