import React, { useState } from 'react';
import clsx from 'clsx';
import { DropResult } from 'react-beautiful-dnd';
import { VariableConfiguration } from 'api/Types';
import Button from 'components/Button';
import { countDefaultVariables } from 'components/ConfigurableForm/utils';
import VariablesConfiguration from 'components/LoanOriginationSystem/VariablesConfiguration';
import VariablesConfigurationCard from 'components/LoanOriginationSystem/VariablesConfiguration/VariablesConfigurationCard';
import styles from './BaseVariablesConfigurationLayout.module.scss';
import getPosition from 'utils/getPosition';
import { Variable } from 'Variables/VariablesTypes';

export interface BaseVariablesConfigurationLayoutProps {
  configurations: VariableConfiguration[] | null;
  renderHeader?: () => React.ReactNode;
  onVariableReorder: (id: string, column: number, position: number) => void;
  onVariableDelete: (id: string) => void;
  onVariableAdd: (column: number, position: number, variable: Variable) => void;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  formHeader?: React.ReactNode;
  cardHeader?: string;
  headerActions?: React.ReactNode;
  hideAddCustomVariablesButton?: boolean;
  className?: string;
  formTitleClassName?: string;
  isDropDisabled?: boolean;
  maxVariablesPerCard?: number;
}

const CARD_ID = 'configurationCard';

const BaseVariablesConfigurationLayout = ({
  renderHeader,
  configurations,
  onVariableReorder,
  onVariableDelete,
  onVariableAdd,
  formatDisplayTitle,
  cardHeader,
  formHeader,
  hideAddCustomVariablesButton,
  headerActions,
  className,
  formTitleClassName,
  maxVariablesPerCard,
}: BaseVariablesConfigurationLayoutProps) => {
  const [variablesPaneOpen, setVariablesPaneOpen] = useState(false);

  const handleReorder = (result: DropResult) => {
    if (!result.destination || !configurations) {
      return;
    }

    const [, columnIndex] = result.destination.droppableId.split('-');
    const [, sourceColumnIndex] = result.source.droppableId.split('-');

    const sourceColumn = configurations
      .filter(({ column }) => column === Number(sourceColumnIndex))
      .sort((firstConfiguration, secondConfiguration) => firstConfiguration.position - secondConfiguration.position);

    const item = sourceColumn[countDefaultVariables(sourceColumn) + result.source.index];

    if (!item) {
      return;
    }

    const destinationColumn = configurations
      .filter(({ column, id }) => column === Number(columnIndex) && item.id !== id)
      .sort((firstConfiguration, secondConfiguration) => firstConfiguration.position - secondConfiguration.position);

    const position = getPosition(
      destinationColumn,
      countDefaultVariables(destinationColumn) + result.destination.index,
    );

    onVariableReorder(item.id, Number(columnIndex), position);
  };

  const handleAddVariable = (cardId: string, column: number, row: number, variable: Variable) => {
    if (!configurations) {
      return;
    }

    const destinationColumn = configurations
      .filter((configuration) => configuration.column === column)
      .sort((firstConfiguration, secondConfiguration) => firstConfiguration.position - secondConfiguration.position);

    const position = getPosition(destinationColumn, countDefaultVariables(destinationColumn) + row);

    onVariableAdd(column, position, variable);
  };

  const toggleVariablesPaneOpen = () => setVariablesPaneOpen(!variablesPaneOpen);

  const renderFormHeader = () => {
    if (typeof formHeader === 'string') {
      return <h4 className={styles.configurationFormHeader}>{formHeader}</h4>;
    }

    return formHeader;
  };

  const isDropDisabled = (sourceDroppableId: string | null) => {
    return (
      !configurations ||
      (typeof maxVariablesPerCard !== 'undefined' &&
        configurations.length >= maxVariablesPerCard &&
        !sourceDroppableId?.includes(CARD_ID))
    );
  };

  return (
    <div className={clsx(className)}>
      {renderHeader && renderHeader()}
      <div className={styles.configurationFormContainer}>
        <div className={clsx(styles.configurationFormTitle, formTitleClassName)}>
          <div className={styles.headerContainer}>
            {renderFormHeader()}
            {headerActions && <div>{headerActions}</div>}
          </div>
          {!hideAddCustomVariablesButton && <Button onClick={toggleVariablesPaneOpen}>Add Custom Variable</Button>}
        </div>
        <VariablesConfiguration
          variablesPaneOpen={variablesPaneOpen}
          onClosePane={toggleVariablesPaneOpen}
          onAddVariable={handleAddVariable}
          onReorder={handleReorder}
        >
          {(placeholderStyles, droppableType, draggingId, sourceDroppableId) => (
            <VariablesConfigurationCard
              cardId={CARD_ID}
              placeholderStyle={placeholderStyles}
              configuration={configurations || null}
              isDragDisabled={variablesPaneOpen}
              title={cardHeader}
              hideTitle={!cardHeader}
              draggingId={draggingId}
              droppableType={droppableType}
              onVariableDelete={onVariableDelete}
              formatDisplayTitle={formatDisplayTitle}
              isDropDisabled={isDropDisabled(sourceDroppableId)}
            />
          )}
        </VariablesConfiguration>
      </div>
    </div>
  );
};

export default BaseVariablesConfigurationLayout;
