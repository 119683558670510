import React, { FC } from 'react';
import clsx from 'clsx';
import FEATURE_LAYOUT from './FeatureLayout';
import Feature from 'BillingPage/Feature';
import { ErrorNotificationIcon, SuccessNotificationIcon } from 'static/images';
import styles from './Features.module.scss';
import { SkeletonCircle } from 'components/Skeleton';
import SkeletonText from 'components/Skeleton/SkeletonText';
import QuestionIcon from 'components/QuestionIcon';

export type FeaturesConfig = {
  [key in Feature]: boolean;
};

interface FeaturesProps {
  className?: string;
  features: FeaturesConfig | null;
  professionalServicesRate: string | null;
}

const Features: FC<FeaturesProps> = ({ className, features, professionalServicesRate }) => {
  const getFeatureIcon = (type: Feature) => {
    if (!features) {
      return <SkeletonCircle width="20px" height="20px" color="primary6" className={styles.featureIcon} />;
    }

    return features[type] ? (
      <SuccessNotificationIcon className={styles.featureIcon} />
    ) : (
      <ErrorNotificationIcon className={styles.featureIcon} />
    );
  };

  const renderProfessionalServicesRateIcon = (type: Feature) => {
    if (type !== Feature.CustomIntegrations && type !== Feature.CustomProcessAutomation) {
      return null;
    }

    const tooltip = professionalServicesRate
      ? `Charged at ${professionalServicesRate}`
      : 'Please contact DigiFi for pricing';

    return <QuestionIcon className={styles.professionalServicesRateIcon} tooltip={tooltip} size={20} />;
  };

  return (
    <div className={clsx(styles.container, className)}>
      <p className={styles.label}>Features</p>
      <div className={styles.columns}>
        {FEATURE_LAYOUT.map(({ type, label }) => (
          <div key={type} className={styles.feature}>
            {getFeatureIcon(type)}
            {features ? label : <SkeletonText width="200px" height="16px" color="primary6" lineHeight="20px" />}
            {renderProfessionalServicesRateIcon(type)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
