import Fetcher from 'middlewares/Fetcher';
import { ApplicationDataTabCardsApi } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabCardsActionType } from './ActionTypes';
import {
  deleteApplicationDataTabCardSuccess,
  deleteApplicationDataTabCardFailure,
  DeleteApplicationDataTabCardAction,
} from './ActionCreator';

const DeleteApplicationDataTabCardMiddleware = (api: ApplicationDataTabCardsApi) =>
  Fetcher<string, DeleteApplicationDataTabCardAction>(
    ApplicationDataTabCardsActionType.DeleteApplicationDataTabCard,
    deleteApplicationDataTabCardSuccess,
    deleteApplicationDataTabCardFailure,
    async (action) => {
      await api.delete(action.payload.cardId);

      return action.payload.cardId;
    },
  );

export default DeleteApplicationDataTabCardMiddleware;
