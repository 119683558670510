import { ConditionOutput, MultipleRule } from 'BranchInfo/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import maskNumberValue from 'utils/masks/maskNumberValue';
import { OPTION_SEPARATOR } from 'components/ComparisonOperands/ComparisonOperandIn';
import formatValueByDataType from 'utils/formatValueByDataType';
import { Variable } from 'Variables/VariablesTypes';

export const getVariableDisplayValue = (
  rule: MultipleRule,
  sourceVariableData: Variable,
  firstOperandVariableData: Variable,
  secondOperandVariableData: Variable,
) => {
  const { firstOperandValue, secondOperandValue, selectedComparison, firstOperandType, secondOperandType } = rule;

  if (selectedComparison === 'IS NULL' || selectedComparison === 'IS NOT NULL') {
    return '';
  }

  if (selectedComparison === 'RANGE') {
    const minimumOperand =
      firstOperandType.toLowerCase() === 'value'
        ? formatValueByDataType(firstOperandValue, sourceVariableData!)
        : firstOperandVariableData?.displayName;
    const maximumOperand =
      secondOperandType.toLowerCase() === 'value'
        ? formatValueByDataType(secondOperandValue, sourceVariableData!)
        : secondOperandVariableData?.displayName;

    return `${minimumOperand} to ${maximumOperand}`;
  }

  if (selectedComparison === 'IN' || selectedComparison === 'NOT IN') {
    return firstOperandValue
      .split(OPTION_SEPARATOR)
      .map((value: string) => formatValueByDataType(value, sourceVariableData!))
      .join(`${OPTION_SEPARATOR} `);
  }

  return firstOperandType.toLowerCase() === 'variable'
    ? firstOperandVariableData?.displayName
    : formatValueByDataType(firstOperandValue, sourceVariableData!);
};

export const getConditionOutputValue = (
  output: ConditionOutput,
  type: ModuleType,
  sourceVariableData?: Variable,
  operandVariableData?: Variable,
): string | undefined => {
  switch (type) {
    case ModuleType.RuleBasedOutputs:
      return getRuleBasedOutputsConditionOutputValue(
        output,
        sourceVariableData,
        operandVariableData,
      );
    case ModuleType.ScoringModel:
      return maskNumberValue(output.operandValue);
    default:
      return output.operandValue;
  }
};

const getRuleBasedOutputsConditionOutputValue = (
  output: ConditionOutput,
  sourceVariableData?: Variable,
  operandVariableData?: Variable,
) => {
  if (!sourceVariableData) {
    return undefined;
  }

  const { operandValue, operandType } = output;

  const sourceVariableTitle = sourceVariableData.displayName;

  const targetValue = operandType.toLowerCase() === 'value'
    ? formatValueByDataType(operandValue, sourceVariableData)
    : operandVariableData?.displayName;

  return `${sourceVariableTitle} = ${targetValue}`;
}
