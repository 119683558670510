import React, { FC, useState } from 'react';
import styles from './NoStrategiesAvailable.module.scss';
import Button from 'components/Button';
import { useDispatch } from 'react-redux';
import AutomatedDecisionEngine from 'static/images/automated-decision-engine.png';
import { createStrategy } from 'Strategies/ActionCreator';

const NoStrategiesAvailable: FC = () => {
  const dispatch = useDispatch();
  const [isStrategyCreateInProgress, setIsStrategyCreateInProgress] = useState(false);
  const onCreateNewStrategy = () => {
    setIsStrategyCreateInProgress(true);
    dispatch(createStrategy());
  };

  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <h1 className={styles.title}>Welcome to DigiFi’s Decision Engine</h1>
        <p className={styles.info}>
          To get started with DigiFi’s Decision Engine and configure an automated decision workflow for underwriting or
          verification, please create your first strategy.
        </p>
        <Button kind="primary" size="default" onClick={onCreateNewStrategy} isLoading={isStrategyCreateInProgress}>
          New Strategy
        </Button>
      </div>
      <img src={AutomatedDecisionEngine} className={styles.image} alt="Lending portal" />
    </div>
  );
};

export default NoStrategiesAvailable;
