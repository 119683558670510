import { ScoringModelState } from './Types';
import { UPDATE_SCORING_RULE_SEGMENT } from './ActionTypes';
import { AnyAction } from 'redux';
import { GET_BRANCH_INFO_REQUEST_SUCCESS } from 'BranchInfo/ActionTypes';

const initialState: ScoringModelState = {
  initialScore: null,
  outputVariable: null,
};

const ScoringModelReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case UPDATE_SCORING_RULE_SEGMENT: {
      return { ...state, initialScore: action.payload.initialScore, outputVariable: action.payload.outputVariable };
    }
    case GET_BRANCH_INFO_REQUEST_SUCCESS: {
      const { initialScore, outputVariable } = action.payload;
      return {
        initialScore,
        outputVariable
      };
    }
    default:
      return state;
  }
};

export default ScoringModelReducer;
