import { AnyAction } from 'redux';
import { cloneDeep } from 'lodash';
import {
  DELETE_DECISION_STRATEGY_REQUEST,
  DELETE_DECISION_STRATEGY_REQUEST_SUCCESS,
  DELETE_DECISION_STRATEGY_REQUEST_FAILURE,
  GET_DECISION_STRATEGY_INFO_REQUEST,
  GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS,
  GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE,
  UPDATE_STRATEGY_NAME_REQUEST,
  UPDATE_STRATEGY_NAME_REQUEST_SUCCESS,
  UPDATE_STRATEGY_NAME_REQUEST_FAILURE,
  ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS,
  UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS,
  ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE,
  REORDER_MODULE_BRANCHES,
  REORDER_MODULE_BRANCHES_SUCCESS,
  REORDER_MODULE_BRANCHES_FAILURE,
  COLLAPSE_ALL_STRATEGY_MODULES,
  EXPAND_STRATEGY_MODULE,
  REMOVE_COLLAPSED_STRATEGY_MODULE,
  ADD_COLLAPSED_STRATEGY_MODULE,
  REMOVE_COLLAPSED_STATE,
  DELETE_DECISION_STRATEGY_MODULE_REQUEST,
  DELETE_DECISION_STRATEGY_MODULE_FAILURE,
  SET_STRATEGY_OVERVIEW_DATA,
} from './ActionTypes';
import { DecisionStrategiesState, NormalizedStatus } from './DecisionStrategiesTypes';
import {
  DecisionStrategiesSetSearchInput,
  GetDecisionStrategyInfoSuccessAction,
} from './DecisionStrategiesActionCreator';
import {
  DELETE_BRANCH_FAILURE,
  DELETE_BRANCH_REQUEST,
  DELETE_BRANCH_SUCCESS,
  DUPLICATE_BRANCH_FAILURE,
  DUPLICATE_BRANCH_REQUEST,
} from 'BranchInfo/ActionTypes';
import {
  UPDATE_MODULE_INFO_REQUEST,
  UPDATE_MODULE_INFO_REQUEST_FAILURE,
  UPDATE_MODULE_NAME,
  UPDATE_MODULE_NAME_FAILURE,
} from 'ModuleInfo/ActionTypes';
import { DUPLICATE_MODULE, DUPLICATE_MODULE_FAILURE } from 'DuplicateModule/ActionTypes';
import { CREATE_BRANCH, CREATE_BRANCH_FAILURE } from 'AddBranch/ActionTypes';

const initialState: DecisionStrategiesState = {
  items: [],
  page: 1,
  itemsPerPage: 10,
  itemsTotal: undefined,
  error: null,
  isLoading: false,
  blockingActionInProgress: false,
  strategiesDetailedAttributes: {},
  searchInputValue: '',
  searched: {},
  strategiesRecoveryCopy: undefined,
  collapsedModules: {},
};

const decisionStrategiesReducer = (state = initialState, action: AnyAction): DecisionStrategiesState => {
  switch (action.type) {
    case DELETE_DECISION_STRATEGY_REQUEST: {
      return {
        ...state,
        error: '',
        blockingActionInProgress: true,
      };
    }
    case DELETE_DECISION_STRATEGY_REQUEST_SUCCESS: {
      return {
        ...state,
        error: '',
        blockingActionInProgress: false,
      };
    }
    case DELETE_DECISION_STRATEGY_REQUEST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        blockingActionInProgress: false,
      };
    }
    case REMOVE_COLLAPSED_STRATEGY_MODULE: {
      const { moduleIndex, strategyId, lookupName } = action.payload;

      const visibleModules = state.strategiesDetailedAttributes[strategyId].moduleList.filter((module) => {
        return module.lookupName !== lookupName;
      });

      const visibleCollapsedModules = state.collapsedModules[strategyId];
      visibleCollapsedModules.splice(moduleIndex, 1);

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: {
            ...state.strategiesDetailedAttributes[strategyId],
            moduleList: visibleModules,
          },
        },
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: visibleCollapsedModules,
        },
      };
    }
    case GET_DECISION_STRATEGY_INFO_REQUEST: {
      return {
        ...state,
        error: '',
        isLoading: true,
      };
    }
    case GET_DECISION_STRATEGY_INFO_REQUEST_SUCCESS: {
      const strategy = (action as GetDecisionStrategyInfoSuccessAction).payload;
      const modulesCount = strategy.moduleList.length;

      const { strategiesDetailedAttributes } = state;

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...strategiesDetailedAttributes,
          [strategy.id]: strategy,
        },
        collapsedModules: {
          ...state.collapsedModules,
          [strategy.id]: state.collapsedModules[strategy.id]
            ? state.collapsedModules[strategy.id]
            : new Array(modulesCount).fill(false),
        },
        error: '',
        isLoading: false,
        blockingActionInProgress: false,
      };
    }
    case GET_DECISION_STRATEGY_INFO_REQUEST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        error: message,
        isLoading: false,
      };
    }
    case UPDATE_STRATEGY_NAME_REQUEST: {
      const { name: nameToSet, id: strategyId } = action.payload;
      const strategiesRecoveryCopy = cloneDeep(state.strategiesDetailedAttributes);
      const strategy = state.strategiesDetailedAttributes[strategyId];
      strategy.name = nameToSet;

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: strategy,
        },
        strategiesRecoveryCopy,
        error: '',
        isLoading: true,
      };
    }
    case UPDATE_STRATEGY_NAME_REQUEST_SUCCESS: {
      return {
        ...state,
        strategiesRecoveryCopy: undefined,
        error: '',
        isLoading: false,
      };
    }
    case UPDATE_STRATEGY_NAME_REQUEST_FAILURE: {
      const { message } = action.payload;

      return {
        ...state,
        strategiesDetailedAttributes: state.strategiesRecoveryCopy!,
        strategiesRecoveryCopy: undefined,
        error: message,
        isLoading: false,
      };
    }
    case UPDATE_STRATEGY_DESCRIPTION_REQUEST: {
      const { description, id: strategyId } = action.payload;
      const strategiesRecoveryCopy = cloneDeep(state.strategiesDetailedAttributes);
      const strategy = state.strategiesDetailedAttributes[strategyId];
      strategy.description = description;

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: strategy,
        },
        strategiesRecoveryCopy,
        error: '',
        isLoading: true,
      };
    }
    case UPDATE_STRATEGY_DESCRIPTION_REQUEST_SUCCESS: {
      return {
        ...state,
        strategiesRecoveryCopy: undefined,
        error: '',
        isLoading: false,
      };
    }
    case UPDATE_STRATEGY_DESCRIPTION_REQUEST_FAILURE: {
      const { message } = action.payload;

      return {
        ...state,
        strategiesDetailedAttributes: state.strategiesRecoveryCopy!,
        strategiesRecoveryCopy: undefined,
        error: message,
        isLoading: false,
      };
    }
    case ACTION_TYPE_DECISION_STRATEGIES_SET_SEARCH_INPUT: {
      const searchInputValue = (action as DecisionStrategiesSetSearchInput).payload;
      return {
        ...state,
        searchInputValue,
      };
    }
    case ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_REQUEST: {
      const { strategyId, modulesList, indices } = action.payload;
      const strategiesRecoveryCopy = cloneDeep(state.strategiesDetailedAttributes);
      const strategy = state.strategiesDetailedAttributes[strategyId];
      strategy.moduleList = modulesList;

      const collapsedModules = state.collapsedModules[strategyId];
      const visibleCollapsedModules = indices.map((val: number) => {
        return collapsedModules[val];
      });

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: strategy,
        },
        strategiesRecoveryCopy,
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: visibleCollapsedModules,
        },
        blockingActionInProgress: true,
      };
    }
    case ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_SUCCESS: {
      return {
        ...state,
        strategiesRecoveryCopy: undefined,
        error: '',
        blockingActionInProgress: false,
      };
    }
    case ACTION_TYPE_DECISION_STRATEGY_MODULE_REORDER_FAILURE: {
      const { message } = action.payload;

      return {
        ...state,
        strategiesDetailedAttributes: state.strategiesRecoveryCopy!,
        strategiesRecoveryCopy: undefined,
        error: message,
        blockingActionInProgress: false,
      };
    }
    case REORDER_MODULE_BRANCHES: {
      const { strategyId, branchIndices, moduleKey } = action.payload;
      const strategiesRecoveryCopy = cloneDeep(state.strategiesDetailedAttributes);
      const strategy = state.strategiesDetailedAttributes[strategyId];
      const updatedModuleList = strategy.moduleList.map((module) => {
        if (module.lookupName === moduleKey) {
          return {
            ...module,
            branches: branchIndices.map((branchIndex: number) => module.branches[branchIndex]),
          };
        }
        return { ...module };
      });

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: {
            ...strategy,
            moduleList: updatedModuleList,
          },
        },
        strategiesRecoveryCopy,
        blockingActionInProgress: true,
      };
    }
    case REORDER_MODULE_BRANCHES_SUCCESS: {
      return {
        ...state,
        strategiesRecoveryCopy: undefined,
        error: '',
        blockingActionInProgress: false,
      };
    }
    case REORDER_MODULE_BRANCHES_FAILURE: {
      return {
        ...state,
        strategiesDetailedAttributes: state.strategiesRecoveryCopy!,
        strategiesRecoveryCopy: undefined,
        blockingActionInProgress: false,
      };
    }
    case DELETE_BRANCH_REQUEST: {
      return {
        ...state,
        error: '',
        blockingActionInProgress: true,
      };
    }
    case DELETE_BRANCH_SUCCESS: {
      const { strategyId, moduleKey, branchIndex } = action.payload;
      const strategy = state.strategiesDetailedAttributes[strategyId];
      const updatedModules = strategy.moduleList.map((module) => {
        if (module.lookupName === moduleKey) {
          module.branches.splice(parseInt(branchIndex, 10), 1);
        }
        return module;
      });

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [strategyId]: {
            ...strategy,
            moduleList: updatedModules,
          },
        },
        error: '',
        blockingActionInProgress: false,
      };
    }
    case DELETE_BRANCH_FAILURE: {
      return {
        ...state,
        error: '',
        blockingActionInProgress: false,
      };
    }
    case COLLAPSE_ALL_STRATEGY_MODULES: {
      const { strategyId, modulesCount } = action.payload;

      return {
        ...state,
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: new Array(modulesCount).fill(true),
        },
      };
    }
    case EXPAND_STRATEGY_MODULE: {
      const { strategyId, moduleIndex, isCollapsed } = action.payload;

      const visibleModuleList = state.collapsedModules[strategyId];
      visibleModuleList[moduleIndex] = isCollapsed;

      return {
        ...state,
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: visibleModuleList,
        },
      };
    }
    case ADD_COLLAPSED_STRATEGY_MODULE: {
      const { strategyId } = action.payload;

      const visibleModuleList = state.collapsedModules[strategyId];
      visibleModuleList.push(false);

      return {
        ...state,
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: visibleModuleList,
        },
      };
    }
    case REMOVE_COLLAPSED_STATE: {
      const strategyId = action.payload;
      const visibleModuleList = state.collapsedModules[strategyId]
        ? state.collapsedModules[strategyId].fill(false)
        : [];

      return {
        ...state,
        collapsedModules: {
          ...state.collapsedModules,
          [strategyId]: visibleModuleList,
        },
      };
    }
    case UPDATE_MODULE_INFO_REQUEST: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case UPDATE_MODULE_INFO_REQUEST_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case DUPLICATE_MODULE: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case DUPLICATE_MODULE_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case UPDATE_MODULE_NAME: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case UPDATE_MODULE_NAME_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case CREATE_BRANCH: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case CREATE_BRANCH_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case DELETE_DECISION_STRATEGY_MODULE_REQUEST: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case DELETE_DECISION_STRATEGY_MODULE_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case DUPLICATE_BRANCH_REQUEST: {
      return {
        ...state,
        blockingActionInProgress: true,
      };
    }
    case DUPLICATE_BRANCH_FAILURE: {
      return {
        ...state,
        blockingActionInProgress: false,
      };
    }
    case SET_STRATEGY_OVERVIEW_DATA: {
      const {
        id,
        displayTitle: name,
        title,
        status,
        version,
        updatedDate,
        locked,
        updatedByUserInfo,
        description,
      } = action.payload;

      return {
        ...state,
        strategiesDetailedAttributes: {
          ...state.strategiesDetailedAttributes,
          [id]: {
            ...state.strategiesDetailedAttributes[id],
            id,
            name,
            title,
            version,
            status: status === null ? NormalizedStatus.Inactive : status,
            isLocked: locked,
            editorFirstName: updatedByUserInfo ? updatedByUserInfo.firstName : null,
            editorLastName: updatedByUserInfo ? updatedByUserInfo.lastName : null,
            editorImageId: updatedByUserInfo ? updatedByUserInfo.imageId : null,
            updated: updatedDate,
            description,
            moduleList: state.strategiesDetailedAttributes[id] ? state.strategiesDetailedAttributes[id].moduleList : [],
          },
        },
      };
    }
    default:
      return state;
  }
};

export default decisionStrategiesReducer;
