import React, { PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import useAsyncValueInputProps from 'hooks/useAsyncValueInputProps';
import EditableInput from 'components/EditableInput';
import { ValueValidationType } from 'components/Input';
import styles from './BaseTabLayout.module.scss';
import { ApplicationTab } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import trimAll from 'utils/trimAll';

export interface BaseTabLayoutProps {
  className?: string;
  tab: ApplicationTab;
  onTabNameUpdate: (tabId: string, newName: string) => void;
  renderActions?: () => ReactNode;
}

const TAB_MAX_NAME_LENGTH = 20;

const BaseTabLayout = ({
  children,
  tab,
  onTabNameUpdate,
  renderActions,
  className,
}: PropsWithChildren<BaseTabLayoutProps>) => {
  const tabNameInputProps = useAsyncValueInputProps({
    value: tab.name,
    placeholderText: tab.name,
    handleChange: (newNabName) => {
      const correctTabName = trimAll(newNabName);

      onTabNameUpdate(tab.id, correctTabName);
    },
  });

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.header}>
        <EditableInput
          {...tabNameInputProps}
          className={styles.editableInput}
          maxLength={TAB_MAX_NAME_LENGTH}
          valueValidationType={ValueValidationType.LettersNumbersWithSpecialCharacters}
        />
        {renderActions && renderActions()}
      </div>
      {children}
    </div>
  );
};

export default BaseTabLayout;
