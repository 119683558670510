import { AnyAction } from 'redux';
import { LoanOriginationSystemTasksFiltersActionTypes } from './ActionTypes';
import { TaskStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemTasksApi';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface FiltersResult {
  dueDateRange: {
    from: Date | null;
    to: Date | null;
  };
  status: TaskStatus | null;
}

export interface SetSearchInputValueAction extends AnyAction {
  type: LoanOriginationSystemTasksFiltersActionTypes.SetSearchInputValue;
  payload: {
    searchInputValue: string;
  };
}

export interface ToggleFiltersPopupAction extends AnyAction {
  type: LoanOriginationSystemTasksFiltersActionTypes.ToggleFiltersPopup;
}

export interface ChangePopupFiltersAction extends AnyAction {
  type: LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters;
  payload: FiltersResult;
}

export interface FilterTasksByMembersAction extends AnyAction {
  type: LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers;
  payload: {
    members: UserInfo[];
  };
}

export interface ResetAllTasksFilters extends AnyAction {
  type: LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters;
}

export const setSearchInputValue = (searchInputValue: string): SetSearchInputValueAction => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.SetSearchInputValue,
    payload: {
      searchInputValue,
    },
  };
};

export const toggleFiltersPopup = (): ToggleFiltersPopupAction => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.ToggleFiltersPopup,
  };
};

export const changePopupFilters = (filter: FiltersResult): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters,
    payload: filter,
  };
};

export const clearPopupFilters = (): ChangePopupFiltersAction => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.ChangePopupFilters,
    payload: {
      dueDateRange: {
        from: null,
        to: null,
      },
      status: null,
    },
  };
};

export const filterTasksByMembers = (members: UserInfo[]): FilterTasksByMembersAction => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.FilterTasksByMembers,
    payload: {
      members,
    },
  };
};

export const resetAllTasksFilters = (): ResetAllTasksFilters => {
  return {
    type: LoanOriginationSystemTasksFiltersActionTypes.ResetAllFilters,
  };
};
