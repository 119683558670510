import { LoanOriginationSystemDeclineReasonsState } from './Types';
import { GetDeclineReasonsSuccessAction, UpdateDeclineReasonsSuccessAction } from './ActionCreator';
import { DeclineReasonsActionType } from './ActionTypes';

type LoanOriginationSystemDeclineReasonsActions = GetDeclineReasonsSuccessAction | UpdateDeclineReasonsSuccessAction;

const initialState: LoanOriginationSystemDeclineReasonsState = {
  declineReasonsByProductId: {},
};

const loanOriginationSystemDeclineReasonsReducer = (
  state = initialState,
  action: LoanOriginationSystemDeclineReasonsActions,
): LoanOriginationSystemDeclineReasonsState => {
  switch (action.type) {
    case DeclineReasonsActionType.UpdateDeclineReasonsSuccess:
    case DeclineReasonsActionType.GetDeclineReasonsSuccess: {
      return {
        ...state,
        declineReasonsByProductId: {
          ...state.declineReasonsByProductId,
          [action.payload.productId]: action.payload.declineReasons,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default loanOriginationSystemDeclineReasonsReducer;
