import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ApplicationStatusesActionType } from './ActionTypes';
import { AnyAction } from 'redux';
import { RuleBuilderCoreData } from 'RuleCreate/Types';

export enum ApplicationStatusesActionOrigin {
  Applications = 'Applications',
  ApplicationsWorkflowSetup = 'ApplicationsWorkflowSetup',
}

export enum UpdateApplicationStatusActionOrigin {
  StatusesBoard = 'StatusesBoard',
}

export interface GetApplicationStatusesResult {
  statuses: ApplicationStatus[];
  productId: string;
}

export interface DeleteApplicationStatusRuleParams {
  statusId: string;
  ruleId: string;
}

export interface GetApplicationStatusesAction extends ActionWithOrigin<ApplicationStatusesActionOrigin> {
  type: ApplicationStatusesActionType.GetApplicationStatuses;
  payload: {
    productId: string;
  };
}

export interface GetApplicationStatusesSuccessAction extends ActionWithOrigin<ApplicationStatusesActionOrigin> {
  type: ApplicationStatusesActionType.GetApplicationStatusesSuccess;
  payload: GetApplicationStatusesResult;
}

export interface DeleteApplicationStatusAction extends AnyAction {
  type: ApplicationStatusesActionType.DeleteApplicationStatus;
  payload: {
    statusId: string;
  };
}

export interface DeleteApplicationStatusSuccessAction extends AnyAction {
  type: ApplicationStatusesActionType.DeleteApplicationStatusSuccess;
  payload: {
    statusId: string;
  };
}

export interface UpdateApplicationStatusAction extends ActionWithOrigin<UpdateApplicationStatusActionOrigin> {
  type: ApplicationStatusesActionType.UpdateApplicationStatus;
  payload: {
    statusId: string;
    status: Partial<ApplicationStatus>;
  };
}

export interface UpdateApplicationStatusSuccessAction extends ActionWithOrigin<UpdateApplicationStatusActionOrigin> {
  type: ApplicationStatusesActionType.UpdateApplicationStatusSuccess;
  payload: {
    status: ApplicationStatus;
  };
}

export interface CreateApplicationStatusAction extends AnyAction {
  type: ApplicationStatusesActionType.CreateApplicationStatus;
  payload: {
    productId: string;
    name: string;
    position: number;
  };
}

export interface CreateApplicationStatusSuccessAction extends AnyAction {
  type: ApplicationStatusesActionType.CreateApplicationStatusSuccess;
  payload: {
    status: ApplicationStatus;
  };
}

export interface CreateApplicationStatusRuleAction extends AnyAction {
  type: ApplicationStatusesActionType.CreateApplicationStatusRule;
  payload: {
    statusId: string;
    multipleRules: RuleBuilderCoreData[];
  };
}

export interface CreateApplicationStatusRuleSuccessAction extends AnyAction {
  type: ApplicationStatusesActionType.CreateApplicationStatusRuleSuccess;
  payload: {
    statusRule: ApplicationStatusRule;
  };
}

export interface UpdateApplicationStatusRuleAction extends AnyAction {
  type: ApplicationStatusesActionType.UpdateApplicationStatusRule;
  payload: {
    ruleId: string;
    multipleRules: RuleBuilderCoreData[];
  };
}

export interface UpdateApplicationStatusRuleSuccessAction extends AnyAction {
  type: ApplicationStatusesActionType.UpdateApplicationStatusRuleSuccess;
  payload: {
    rule: ApplicationStatusRule;
  };
}

export interface DeleteApplicationStatusRuleAction extends AnyAction {
  type: ApplicationStatusesActionType.DeleteApplicationStatusRule;
  payload: {
    statusId: string;
    ruleId: string;
  };
}

export interface DeleteApplicationStatusRuleSuccessAction extends AnyAction {
  type: ApplicationStatusesActionType.DeleteApplicationStatusRuleSuccess;
  payload: DeleteApplicationStatusRuleParams;
}

export const getApplicationStatuses = (productId: string): GetApplicationStatusesAction => ({
  type: ApplicationStatusesActionType.GetApplicationStatuses,
  payload: {
    productId,
  },
});

export const getApplicationStatusesSuccess = (
  result: GetApplicationStatusesResult,
): GetApplicationStatusesSuccessAction => ({
  type: ApplicationStatusesActionType.GetApplicationStatusesSuccess,
  payload: result,
});

export const deleteApplicationStatus = (statusId: string): DeleteApplicationStatusAction => ({
  type: ApplicationStatusesActionType.DeleteApplicationStatus,
  payload: {
    statusId,
  },
});

export const deleteApplicationStatusSuccess = (deletedStatusId: string): DeleteApplicationStatusSuccessAction => ({
  type: ApplicationStatusesActionType.DeleteApplicationStatusSuccess,
  payload: {
    statusId: deletedStatusId,
  },
});

export const createApplicationStatus = (
  name: string,
  position: number,
  productId: string,
): CreateApplicationStatusAction => ({
  type: ApplicationStatusesActionType.CreateApplicationStatus,
  payload: {
    productId,
    name,
    position,
  },
});

export const createApplicationStatusSuccess = (status: ApplicationStatus): CreateApplicationStatusSuccessAction => ({
  type: ApplicationStatusesActionType.CreateApplicationStatusSuccess,
  payload: {
    status,
  },
});

export const updateApplicationStatus = (
  statusId: string,
  status: Partial<ApplicationStatus>,
): UpdateApplicationStatusAction => ({
  type: ApplicationStatusesActionType.UpdateApplicationStatus,
  payload: {
    statusId,
    status,
  },
});

export const updateApplicationStatusSuccess = (status: ApplicationStatus): UpdateApplicationStatusSuccessAction => ({
  type: ApplicationStatusesActionType.UpdateApplicationStatusSuccess,
  payload: {
    status,
  },
});

export const createApplicationStatusRule = (
  statusId: string,
  multipleRules: RuleBuilderCoreData[],
): CreateApplicationStatusRuleAction => ({
  type: ApplicationStatusesActionType.CreateApplicationStatusRule,
  payload: {
    statusId,
    multipleRules,
  },
});

export const createApplicationStatusRuleSuccess = (
  statusRule: ApplicationStatusRule,
): CreateApplicationStatusRuleSuccessAction => ({
  type: ApplicationStatusesActionType.CreateApplicationStatusRuleSuccess,
  payload: {
    statusRule,
  },
});

export const deleteApplicationStatusRule = (statusId: string, ruleId: string): DeleteApplicationStatusRuleAction => ({
  type: ApplicationStatusesActionType.DeleteApplicationStatusRule,
  payload: {
    statusId,
    ruleId,
  },
});

export const deleteApplicationStatusRuleSuccess = (
  params: DeleteApplicationStatusRuleParams,
): DeleteApplicationStatusRuleSuccessAction => ({
  type: ApplicationStatusesActionType.DeleteApplicationStatusRuleSuccess,
  payload: params,
});

export const updateApplicationStatusRule = (
  ruleId: string,
  multipleRules: RuleBuilderCoreData[],
): UpdateApplicationStatusRuleAction => ({
  type: ApplicationStatusesActionType.UpdateApplicationStatusRule,
  payload: {
    ruleId,
    multipleRules,
  },
});

export const updateApplicationStatusRuleSuccess = (
  rule: ApplicationStatusRule,
): UpdateApplicationStatusRuleSuccessAction => ({
  type: ApplicationStatusesActionType.UpdateApplicationStatusRuleSuccess,
  payload: {
    rule,
  },
});

export const getApplicationStatusesFailed = FailedActionCreator(
  ApplicationStatusesActionType.GetApplicationStatusesFailed,
);
export const deleteApplicationStatusFailure = FailedActionCreator(
  ApplicationStatusesActionType.DeleteApplicationStatusFailure,
);
export const createApplicationStatusFailure = FailedActionCreator(
  ApplicationStatusesActionType.CreateApplicationStatusFailure,
);
export const updateApplicationStatusFailure = FailedActionCreator(
  ApplicationStatusesActionType.UpdateApplicationStatusFailure,
);
export const createApplicationStatusRuleFailure = FailedActionCreator(
  ApplicationStatusesActionType.CreateApplicationStatusRuleFailure,
);
export const deleteApplicationStatusRuleFailure = FailedActionCreator(
  ApplicationStatusesActionType.DeleteApplicationStatusRuleFailure,
);
export const updateApplicationStatusRuleFailure = FailedActionCreator(
  ApplicationStatusesActionType.UpdateApplicationStatusRuleFailure,
);
