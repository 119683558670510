import { AnyAction } from 'redux';
import {
  CLOSE_DELETE_INTERMEDIARY_POPUP,
  DELETE_INTERMEDIARY,
  DELETE_INTERMEDIARY_FAILURE,
  DELETE_INTERMEDIARY_SUCCESS,
  GET_INTERMEDIARIES,
  GET_INTERMEDIARIES_FAILED,
  GET_INTERMEDIARIES_SUCCESS,
  OPEN_DELETE_INTERMEDIARY_POPUP,
  SORT_INTERMEDIARIES,
  GET_INTERMEDIARY_TO_DELETE_APPLICATIONS,
  GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS,
  GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE,
} from './ActionTypes';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemIntermediariesFiltersState } from './Filters/Types';
import {
  IntermediariesSortingField,
  IntermediariesSortingType,
  Intermediary,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

type GetIntermediariesSuccessData = TableViewData<Intermediary> & { searchInputValue: string };
export type GetIntermediaryToDeleteApplicationsSuccessData = TableViewData<Application>;

export interface GetIntermediariesAction extends AnyAction {
  type: typeof GET_INTERMEDIARIES;
  payload: {
    filters: LoanOriginationSystemIntermediariesFiltersState;
    sortingType: IntermediariesSortingType;
  };
}

export interface GetIntermediariesSuccessAction extends AnyAction {
  type: typeof GET_INTERMEDIARIES_SUCCESS;
  payload: GetIntermediariesSuccessData;
}

export interface SortIntermediariesAction extends AnyAction {
  type: typeof SORT_INTERMEDIARIES;
  payload: IntermediariesSortingType;
}

export interface OpenDeleteIntermediaryPopupAction extends AnyAction {
  type: typeof OPEN_DELETE_INTERMEDIARY_POPUP;
  payload: {
    intermediaryToDeleteId: string;
  };
}

export interface CloseDeleteIntermediaryPopupAction extends AnyAction {
  type: typeof CLOSE_DELETE_INTERMEDIARY_POPUP;
}

export interface DeleteIntermediaryAction extends AnyAction {
  type: typeof DELETE_INTERMEDIARY;
  payload: {
    intermediaryToDeleteId: string;
  };
}

export interface DeleteIntermediarySuccessAction extends AnyAction {
  type: typeof DELETE_INTERMEDIARY_SUCCESS;
  payload: {
    intermediary: Intermediary;
  };
}

export interface GetIntermediaryToDeleteApplicationsAction extends AnyAction {
  type: typeof GET_INTERMEDIARY_TO_DELETE_APPLICATIONS;
  payload: {
    id: string;
  };
}

export interface GetIntermediaryToDeleteApplicationsSuccessAction extends AnyAction {
  type: typeof GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS;
  payload: {
    applications: GetIntermediaryToDeleteApplicationsSuccessData;
  };
}

export const getIntermediaries = (
  filters: LoanOriginationSystemIntermediariesFiltersState,
  sortingType: IntermediariesSortingType,
): GetIntermediariesAction => {
  return {
    type: GET_INTERMEDIARIES,
    payload: {
      filters,
      sortingType,
    },
  };
};

export const getIntermediariesSuccess = (result: GetIntermediariesSuccessData): GetIntermediariesSuccessAction => {
  return {
    type: GET_INTERMEDIARIES_SUCCESS,
    payload: result,
  };
};

export const sortIntermediaries = (
  field: IntermediariesSortingField,
  ascending: boolean,
): SortIntermediariesAction => ({
  type: SORT_INTERMEDIARIES,
  payload: {
    ascending,
    field,
  },
});

export const closeDeleteIntermediaryPopup = (): CloseDeleteIntermediaryPopupAction => {
  return {
    type: CLOSE_DELETE_INTERMEDIARY_POPUP,
  };
};

export const openDeleteIntermediaryPopup = (intermediaryToDeleteId: string): OpenDeleteIntermediaryPopupAction => {
  return {
    type: OPEN_DELETE_INTERMEDIARY_POPUP,
    payload: {
      intermediaryToDeleteId,
    },
  };
};

export const deleteIntermediary = (intermediaryToDeleteId: string): DeleteIntermediaryAction => {
  return {
    type: DELETE_INTERMEDIARY,
    payload: {
      intermediaryToDeleteId,
    },
  };
};

export const deleteIntermediarySuccess = (intermediary: Intermediary): DeleteIntermediarySuccessAction => {
  return {
    type: DELETE_INTERMEDIARY_SUCCESS,
    payload: {
      intermediary,
    },
  };
};

export const getIntermediaryToDeleteApplications = (id: string): GetIntermediaryToDeleteApplicationsAction => {
  return {
    type: GET_INTERMEDIARY_TO_DELETE_APPLICATIONS,
    payload: {
      id,
    },
  };
};

export const getIntermediaryToDeleteApplicationsSuccess = (
  applications: GetIntermediaryToDeleteApplicationsSuccessData,
): GetIntermediaryToDeleteApplicationsSuccessAction => {
  return {
    type: GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_SUCCESS,
    payload: {
      applications,
    },
  };
};

export const getIntermediaryToDeleteApplicationsFailed = FailedActionCreator(
  GET_INTERMEDIARY_TO_DELETE_APPLICATIONS_FAILURE,
);

export const getIntermediariesFailed = FailedActionCreator(GET_INTERMEDIARIES_FAILED);

export const deleteIntermediaryFailed = FailedActionCreator(DELETE_INTERMEDIARY_FAILURE);
