import { AnyAction } from 'redux';
import {
  ApplicationTab,
  CreateApplicationTabParams,
  UpdateApplicationTabParams,
} from 'api/LoanOriginationSystem/ApplicationTabsApi';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import { LoanOriginationSystemApplicationTabsActionTypes } from './ActionTypes';

export enum ApplicationTabsActionOrigin {
  ApplicationSetup = 'ApplicationSetup',
  ApplicationDetails = 'ApplicationDetails',
}

export interface GetApplicationTabsResult {
  tabs: ApplicationTab[];
  productId: string;
}

export interface GetApplicationTabsAction extends ActionWithOrigin<ApplicationTabsActionOrigin> {
  type: LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabs;
  payload: {
    productId: string;
  };
}

export interface GetApplicationTabsActionSuccess extends ActionWithOrigin<ApplicationTabsActionOrigin> {
  type: LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabsSuccess;
  payload: GetApplicationTabsResult;
}

export interface UpdateApplicationTabAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTab;
  payload: {
    id: string;
    params: UpdateApplicationTabParams;
  };
}

export interface UpdateApplicationTabSuccessAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabSuccess;
  payload: {
    tab: ApplicationTab;
  };
}

export interface CreateApplicationTabAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTab;
  payload: CreateApplicationTabParams;
}

export interface CreateApplicationTabSuccessAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess;
  payload: {
    tab: ApplicationTab;
  };
}

export interface DeleteApplicationTabAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTab;
  payload: {
    id: string;
  };
}

export interface DeleteApplicationTabSuccessAction extends AnyAction {
  type: LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabSuccess;
  payload: {
    deletedId: string;
  };
}

export const getApplicationTabs = (productId: string): GetApplicationTabsAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabs,
  payload: {
    productId,
  },
});

export const getApplicationTabsSuccess = (result: GetApplicationTabsResult): GetApplicationTabsActionSuccess => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabsSuccess,
  payload: result,
});

export const updateApplicationTab = (id: string, params: UpdateApplicationTabParams): UpdateApplicationTabAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTab,
  payload: {
    id,
    params,
  },
});

export const updateApplicationTabSuccess = (applicationTab: ApplicationTab): UpdateApplicationTabSuccessAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabSuccess,
  payload: {
    tab: applicationTab,
  },
});

export const createApplicationTab = (params: CreateApplicationTabParams): CreateApplicationTabAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTab,
  payload: params,
});

export const createApplicationTabSuccess = (tab: ApplicationTab): CreateApplicationTabSuccessAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabSuccess,
  payload: {
    tab,
  },
});

export const deleteApplicationTab = (id: string): DeleteApplicationTabAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTab,
  payload: {
    id,
  },
});

export const deleteApplicationTabSuccess = (id: string): DeleteApplicationTabSuccessAction => ({
  type: LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabSuccess,
  payload: {
    deletedId: id,
  },
});

export const getApplicationTabsFailure = FailedActionCreator(
  LoanOriginationSystemApplicationTabsActionTypes.GetApplicationTabsFailure,
);

export const updateApplicationTabFailure = FailedActionCreator(
  LoanOriginationSystemApplicationTabsActionTypes.UpdateApplicationTabFailure,
);

export const createApplicationTabFailure = FailedActionCreator(
  LoanOriginationSystemApplicationTabsActionTypes.CreateApplicationTabFailure,
);

export const deleteApplicationTabFailure = FailedActionCreator(
  LoanOriginationSystemApplicationTabsActionTypes.DeleteApplicationTabFailure,
);
