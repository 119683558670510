import React, { useMemo } from 'react';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import AutoCompletion from 'components/AutoCompletion';
import styles from './LabelsDropdown.module.scss';
import { Option } from 'components/SelectInput/SelectInput';
import ExtendedTagLine from 'components/LoanOriginationSystem/ExtendedTagLine';
import { LoaderState } from 'components/LoaderWithState/LoaderWithState';

interface LabelsDropdownProps {
  labels: Label[];
  isAutomatedProcessActive: boolean;
  selectedLabelId?: string;
  onLabelSelect: (labelId: string) => void;
  disabled?: boolean;
  loaderState?: LoaderState | null;
  onLoaderStateReset?: () => void;
}

const LabelsDropdown = ({
  labels,
  isAutomatedProcessActive,
  selectedLabelId,
  onLabelSelect,
  disabled,
  loaderState,
  onLoaderStateReset,
}: LabelsDropdownProps) => {
  const labelOptions = useMemo(() => {
    return labels.map((item, index) => {
      const icon = <ExtendedTagLine key={index} title={item.name} color={item.color} />;
      
      return {
        value: item.id,
        name: item.name,
        componentOption: icon,
        icon,
      };
    });
  }, [labels]);

  const onSelectedLabelChange = ({ value }: Option) => {
    onLabelSelect(value);
  };

  return (
    <AutoCompletion
      onChange={onSelectedLabelChange}
      options={labelOptions}
      selectedOption={labelOptions.find(({ value }) => value === selectedLabelId)}
      hideClearIcon={isAutomatedProcessActive}
      placeholder="List of Labels"
      className={styles.autoCompletion}
      disabled={disabled}
      showLoader
      onLoaderStateReset={onLoaderStateReset}
      loaderState={loaderState}
    />
  );
};

export default LabelsDropdown;
