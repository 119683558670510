import React, { FC } from 'react';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { Tag } from 'components/Tag';
import { TagColor } from 'components/Tag/Tag';

const getTagColorByProductStatus = (status: ProductStatus, useBackgroundForArchived?: boolean): TagColor => {
  switch (status) {
    case ProductStatus.Draft:
      return 'blue';
    case ProductStatus.Active:
      return 'green';
    case ProductStatus.Archived:
      return useBackgroundForArchived ? 'gray' : 'white';
    default:
      return 'green';
  }
};

export interface ProductStatusTagProps {
  status: ProductStatus;
  className?: string;
  icon?: React.ReactNode;
  useBackgroundForArchived?: boolean;
}

const ProductStatusTag: FC<ProductStatusTagProps> = ({ status, icon, className, useBackgroundForArchived }) => (
  <Tag className={className} color={getTagColorByProductStatus(status, useBackgroundForArchived)}>
    {icon}
    {status}
  </Tag>
);

export default ProductStatusTag;
