import React, { ReactNode, Ref, cloneElement, ReactElement } from 'react';
import clsx from 'clsx';
import useRandomIdFallback from 'hooks/randomIdFallback';

import styles from './TextInput.module.scss';

export interface TextInputWrapperProps<ChildProps> {
  labelTitle?: string;
  children: ReactElement<ChildProps>;
  id?: string;
  errorMessage?: string;
  topRightElement?: ReactNode;
  hasRightNeighbour?: boolean;
  hasLeftNeighbour?: boolean;
  hasRightPadding?: boolean;
  hasLeftPadding?: boolean;
  inputRef?: Ref<any>;
  containerClassName?: string;
  disabled?: boolean;
}

const TextInputWrapper = <ChildProps extends { id: string; ref?: Ref<any>; className: string; disabled?: boolean }>(
  props: TextInputWrapperProps<ChildProps>,
) => {
  const {
    id: providedId,
    inputRef,
    labelTitle,
    errorMessage,
    topRightElement,
    children,
    hasRightNeighbour,
    hasLeftNeighbour,
    hasLeftPadding,
    hasRightPadding,
    containerClassName,
    disabled,
  } = props;

  const id = useRandomIdFallback(providedId);

  const inputClassName = clsx(
    styles.input,
    disabled && styles.input__disabled,
    errorMessage && styles.inputError,
    hasRightNeighbour && styles.inputWithRightNeighbour,
    hasLeftNeighbour && styles.inputWithLeftNeighbour,
    hasRightPadding && styles.inputWithRightPadding,
    hasLeftPadding && styles.inputWithLeftPadding,
  );

  return (
    <div className={clsx(styles.inputContainer, containerClassName)}>
      <div className={styles.labelWithLink}>
        {labelTitle && (
          <label htmlFor={id} className={styles.label}>
            {labelTitle}
          </label>
        )}
        {topRightElement}
      </div>
      {cloneElement(children, { id, ref: inputRef, className: inputClassName } as ChildProps)}
      {errorMessage && <p className={styles.errorNotification}>{errorMessage}</p>}
    </div>
  );
};

export default TextInputWrapper;
