const SIZES = {
  InvoiceDate: 17,
  DueDate: 17,
  Description: 33,
  InvoiceNumber: 13,
  Amount: 10,
  Status: 10,
};

export default SIZES;
