import React, { FC, useState } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import CreateAccountForm, { CreateAccountFormStep } from 'components/Auth/CreateAccountForm/CreateAccountForm';
import { useAuthProvider } from 'providers/AuthProvider';
import { CreateAccountData } from 'api/Core/AuthApi';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import { GetUserInfoRequest } from 'AccountDetails/AccountDetailsActionCreator';
import notification from 'handlers/notification/notificationActionCreator';
import { useDispatch } from 'react-redux';
import styles from './CreateAccount.module.scss';
import clsx from 'clsx';

const CreateAccount: FC = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const authProvider = useAuthProvider();
  const [step, setStep] = useState<CreateAccountFormStep>(CreateAccountFormStep.AccountInformation);

  const handleCreateAccount = async (data: CreateAccountData) => {
    try {
      await authProvider.createAccount(data);
      await dispatchRoutine(GetUserInfoRequest());
    } catch (error) {
      notification.createNotification(error.message, 'error', dispatch);
    }
  };

  const formContainerBodyClass = clsx(
    styles.formContainerBody,
    step === CreateAccountFormStep.PlanAndProductInformation && styles.smallTopPaddingContainer,
  );
  return (
    <AuthLayout
      formContainerClassName={styles.fromContainer}
      formContainerBodyClassName={formContainerBodyClass}
      title="Create Account"
      subTitle="Start your 14-day free trial. No credit card required."
      showBackLink={step !== CreateAccountFormStep.AccountInformation}
      onClickBack={() => setStep(step - 1)}
    >
      <CreateAccountForm step={step} onStepChange={setStep} onCreateAccount={handleCreateAccount} />
    </AuthLayout>
  );
};

export default CreateAccount;
