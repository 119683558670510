import { AnyAction } from 'redux';
import { CLOSE_DELETE_RULE_POP_UP, OPEN_DELETE_RULE_POP_UP } from './ActionTypes';
import { DeleteRulePopUpState } from './Types';

const initialState: DeleteRulePopUpState = {
  isOpen: false,
  ruleIndex: null,
  entityType: null,
};

const DeleteRulePopUpReducer = (state = initialState, action: AnyAction): DeleteRulePopUpState => {
  switch (action.type) {
    case OPEN_DELETE_RULE_POP_UP:
      return { isOpen: true, ruleIndex: action.payload.ruleIndex, entityType: action.payload.entityType };
    case CLOSE_DELETE_RULE_POP_UP:
      return initialState;
    default:
      return state;
  }
};

export default DeleteRulePopUpReducer;
