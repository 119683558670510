import useOnce from 'hooks/useOnce';
import { getOrganizationUsers } from 'LoanOriginationSystemOrganization/ActionCreator';
import { UserState } from 'AccountDetails/AccountDetailsTypes';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const useOrganizationMembers = () => {
  const dispatch = useDispatch();

  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const { organizationMembers } = useSelector((state: ReduxState) => state.loanOriginationSystemOrganization);

  const loadMembers = useOnce((currentUser: UserState) => {
    dispatch(getOrganizationUsers(currentUser, true));
  });

  useEffect(() => {
    if (organizationMembers.length === 0 && accountDetails && !accountDetails.isLoading) {
      loadMembers(accountDetails);
    }
  }, [accountDetails]);

  return organizationMembers;
};

export default useOrganizationMembers;
