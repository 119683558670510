import { History } from 'history';
import { CREATE_NEW_STRATEGY_VERSION_REQUEST, CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS } from './ActionTypes';
import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import {
  CreateNewStrategyVersionRequestAction,
  createNewStrategyVersionRequestFailure,
  createNewStrategyVersionRequestSuccess,
  CreateNewStrategyVersionRequestSuccessAction,
} from './CreateNewStrategyVersionActionCreator';
import Fetcher from 'middlewares/Fetcher';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { IStrategiesApi } from 'api/DecisionEngine/StrategiesApi';

export const CREATE_NEW_STRATEGY_VERSION_ACTION_ORIGIN_BUTTON = 'CREATE_NEW_STRATEGY_VERSION_ACTION_ORIGIN_BUTTON';

const CreateNewStrategyVersionMiddleware: (api: IStrategiesApi) => Middleware = (api) =>
  Fetcher<{ strategyId: string; strategyVersion: string; strategyName: string }, CreateNewStrategyVersionRequestAction>(
    CREATE_NEW_STRATEGY_VERSION_REQUEST,
    createNewStrategyVersionRequestSuccess,
    createNewStrategyVersionRequestFailure,
    async ({ payload }) => {
      const { strategyId, strategyName } = payload;
      const { id: newStrategyId, version } = await api.createNewVersion(strategyId, strategyName);
      return {
        strategyId: newStrategyId,
        strategyName,
        strategyVersion: version.toString(),
      };
    },
  );

const isCreateNewStrategyVersionSuccessAction = (
  action: AnyAction,
): action is CreateNewStrategyVersionRequestSuccessAction =>
  action.type === CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS;

export const CreateNewStrategyVersionSuccessMiddleware = ({ push }: History): Middleware => ({
  dispatch,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);
  if (isCreateNewStrategyVersionSuccessAction(action)) {
    const { strategyId, strategyVersion, strategyName } = action.payload;
    notification.createNotification(
      getMessage(MessageType.StrategyVersionCreated, {
        strategyVersion,
        strategyName,
      }),
      'success',
      dispatch,
    );
    if (action.meta?.actionOrigin === CREATE_NEW_STRATEGY_VERSION_ACTION_ORIGIN_BUTTON) {
      push(`/decision/strategies/${strategyId}/overview`);
    }
  }
  return result;
};

export default CreateNewStrategyVersionMiddleware;
