import Debouncer from 'middlewares/Debouncer';
import { getEmails } from './ActionCreator';
import { LoanOriginationSystemEmailsFiltersActionTypes } from './Filters/ActionTypes';

export default Debouncer(
  (
    payload: {
      searchInputValue: string;
      borrowerId?: string;
    },
    getState,
  ) => {
    const { borrowerId } = payload;
    const { loanOriginationSystemEmails } = getState();
    const { filters, sortingType } = loanOriginationSystemEmails;

    return getEmails({ ...filters, ...(borrowerId ? { selectedBorrower: borrowerId } : {}) }, sortingType);
  },
  [LoanOriginationSystemEmailsFiltersActionTypes.SetSearchInputValue],
);
