import { AnyAction } from 'redux';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductConfigurationType } from './ActionTypes';

export interface ResetProductConfigurationAction extends AnyAction {
  type: LoanOriginationSystemProductConfigurationType.ResetProductConfiguration;
}

export interface SelectProductForConfigurationAction extends AnyAction {
  type: LoanOriginationSystemProductConfigurationType.SelectProduct;
  payload: { product: Product };
}

export const resetProductConfiguration = (): ResetProductConfigurationAction => ({
  type: LoanOriginationSystemProductConfigurationType.ResetProductConfiguration,
});

export const selectProductForConfiguration = (product: Product): SelectProductForConfigurationAction => ({
  type: LoanOriginationSystemProductConfigurationType.SelectProduct,
  payload: {
    product,
  },
});
