import { Middleware } from 'redux';

import { ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES } from './ActionTypes';
import {
  getExternalIntegrationVariablesSuccess,
  getExternalIntegrationVariablesError,
  GetExternalIntegrationVariablesAction,
} from './ActionCreator';
import Fetcher from 'middlewares/Fetcher';
import { DecisionEngineApi } from 'api/Types';

const GetExternalIntegrationVariablesMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<any, GetExternalIntegrationVariablesAction>(
    ACTION_TYPE_GET_EXTERNAL_INTEGRATION_VARIABLES,
    getExternalIntegrationVariablesSuccess,
    getExternalIntegrationVariablesError,
    ({ payload }) => api.getVariablesForExternalIntegration(payload),
  );

export default GetExternalIntegrationVariablesMiddleware;
