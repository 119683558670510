import { CreateAccountData, CreateAccountInformation } from 'api/Core/AuthApi';
import { useRecaptcha } from 'CaptchaProvider';
import CaptchaAction from 'enums/CaptchaAction';
import React, { FC, useState } from 'react';
import AccountInformationStep from './AccountInformationStep';
import PlanAndProductInformationStep from './PlanAndProductInformationStep';

export enum CreateAccountFormStep {
  AccountInformation = 1,
  PlanAndProductInformation = 2,
}

interface CreateAccountFormProps {
  step: CreateAccountFormStep;
  onStepChange: (step: CreateAccountFormStep) => void;
  onCreateAccount: (data: CreateAccountData) => Promise<void>;
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({ step, onStepChange, onCreateAccount }) => {
  const [accountInformation, setAccountInformation] = useState<CreateAccountInformation | null>(null);
  const [productTemplate, setProductTemplate] = useState<string | undefined>('');

  const executeCaptcha = useRecaptcha();

  const handleAccountInformationStepContinue = (data: CreateAccountInformation) => {
    setAccountInformation(data);

    onStepChange(CreateAccountFormStep.PlanAndProductInformation);
  };

  const handleCreateAccount = async (productTemplateToSet?: string) => {
    setProductTemplate(productTemplateToSet);

    const token = await executeCaptcha(CaptchaAction.CreateAccount);
    if (!token) {
      return;
    }

    await onCreateAccount({
      accountInformation: accountInformation!,
      productTemplate: productTemplateToSet,
      token,
    });
  };

  return (
    <div>
      {step === CreateAccountFormStep.AccountInformation && (
        <AccountInformationStep
          initialData={accountInformation}
          onChange={setAccountInformation}
          onContinue={handleAccountInformationStepContinue}
        />
      )}
      {step === CreateAccountFormStep.PlanAndProductInformation && (
        <PlanAndProductInformationStep
          initialTemplate={productTemplate}
          onChange={setProductTemplate}
          onCreateAccount={handleCreateAccount}
        />
      )}
    </div>
  );
};

export default CreateAccountForm;
