import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import getPosition from 'utils/getPosition';
import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import { ApplicationStatusRule } from 'api/LoanOriginationSystem/ApplicationStatusRuleApi';
import { UserRoleName } from 'AccountDetails/AccountDetailsTypes';
import {
  ApplicationStatus,
  PermissionsField,
} from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import {
  getApplicationStatuses,
  ApplicationStatusesActionOrigin,
  createApplicationStatus,
  updateApplicationStatus,
  UpdateApplicationStatusActionOrigin,
} from 'LoanOriginationSystemApplicationStatuses/ActionCreator';
import {
  setApplicationStatusToDelete,
  openDeclineReasonsPopup,
  setStatusToAddRule,
  setStatusRuleToEdit,
  setDeleteStatusRuleData,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationsWorkflowSetup/ActionCreator';
import { getDeclineReasons } from 'LoanOriginationSystemDeclineReasons/ActionCreator';
import { getStatusesByProductId } from 'LoanOriginationSystemApplicationStatuses/Selectors';
import TabSwitch from 'components/TabSwitch';
import ProductConfigurationSubHeader from 'components/LoanOriginationSystem/ProductConfiguration/ProductConfigurationSubHeader';
import Body from './Body';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import ApplicationWorkflowSetupTab from './ApplicationWorkflowSetupTab';
import styles from './ApplicationWorkflowSetup.module.scss';

const STATUSES_TO_SKIP = 1;

const TABS = Object.values(ApplicationWorkflowSetupTab).map((tab) => ({
  label: tab,
  id: tab,
}));

const ApplicationWorkflowSetup = () => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const { product: selectedProduct } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration,
  );
  const { isBlockingRequestInProgress, statusUpdateIds } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration.applicationWorkflowSetup,
  );
  const applicationStatuses = useSelector((state: ReduxState) => getStatusesByProductId(state, selectedProduct?.id));
  const { declineReasonsByProductId } = useSelector((state: ReduxState) => state.loanOriginationSystemDeclineReasons);
  const [selectedTab, setSelectedTab] = useState(ApplicationWorkflowSetupTab.ProcessingStatuses);

  useEffect(() => {
    if (!applicationStatuses && selectedProduct) {
      dispatch(
        setActionOrigin(
          getApplicationStatuses(selectedProduct.id),
          ApplicationStatusesActionOrigin.ApplicationsWorkflowSetup,
        ),
      );
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct && !declineReasonsByProductId[selectedProduct.id]) {
      dispatch(getDeclineReasons(selectedProduct.id));
    }
  }, [selectedProduct]);

  const handleApplicationStatusReorder = (index: number, sourceIndex: number) => {
    if (!applicationStatuses) {
      return;
    }

    const statusToReorder = applicationStatuses[sourceIndex];

    if (!statusToReorder) {
      return;
    }

    const filteredStatus = applicationStatuses.filter((status) => {
      return (
        status.id !== statusToReorder.id &&
        !Object.values(DefaultApplicationStatus).includes(status.name as DefaultApplicationStatus)
      );
    });

    const position = getPosition(filteredStatus, index);

    const action = setActionOrigin(
      updateApplicationStatus(statusToReorder.id, { position }),
      UpdateApplicationStatusActionOrigin.StatusesBoard,
    );

    dispatch(action);
  };

  const handleApplicationStatusDelete = (status: ApplicationStatus) => dispatch(setApplicationStatusToDelete(status));

  const handleApplicationStatusCreate = () => {
    if (!selectedProduct || !applicationStatuses) {
      return;
    }

    const filteredStatuses = applicationStatuses.filter((status) => {
      return !Object.values(DefaultApplicationStatus).includes(status.name as DefaultApplicationStatus);
    });
    const name = `Status ${filteredStatuses.length + STATUSES_TO_SKIP}`;
    const position = getPosition(filteredStatuses, filteredStatuses.length);

    dispatch(createApplicationStatus(name, position, selectedProduct.id));
  };

  const handleApplicationStatusNameUpdate = async (statusId: string, name: string) => {
    await dispatchRoutine(updateApplicationStatus(statusId, { name }));
  };

  const handleApplicationStatusPermissionsUpdate = (
    statusId: string,
    permissionsField: PermissionsField,
    permissions: UserRoleName[],
  ) => {
    dispatch(updateApplicationStatus(statusId, { [permissionsField]: permissions }));
  };

  const handleApplicationStatusRuleAdd = (status: ApplicationStatus) => {
    dispatch(setStatusToAddRule(status));
  };

  const handleApplicationStatusRuleEdit = (statusRule: ApplicationStatusRule) => {
    dispatch(setStatusRuleToEdit(statusRule));
  };

  const handleApplicationStatusRuleDelete = (status: ApplicationStatus, rule: ApplicationStatusRule) => {
    dispatch(setDeleteStatusRuleData({ statusId: status.id, ruleId: rule.id }));
  };

  const handleEditDeclineReasons = () => {
    dispatch(openDeclineReasonsPopup());
  };

  const renderQuestionTooltip = () => (
    <>
      <p>The statuses that applications will pass through</p>
      <p>and the related rules and user permissions.</p>
    </>
  );

  return (
    <>
      <ProductConfigurationSubHeader
        className={styles.subheader}
        renderQuestionTooltip={renderQuestionTooltip}
        title="Application Workflow"
      />
      <TabSwitch
        className={styles.tabsSwitch}
        tabClassName={styles.tab}
        tabs={TABS}
        selectedTabId={selectedTab}
        onSelect={(tab) => setSelectedTab(tab.id as ApplicationWorkflowSetupTab)}
        displayBorder={false}
      />
      <Body
        selectedTab={selectedTab}
        statuses={applicationStatuses}
        statusUpdateIds={statusUpdateIds}
        onEditDeclineReasons={handleEditDeclineReasons}
        onApplicationStatusPermissionsChange={handleApplicationStatusPermissionsUpdate}
        onApplicationStatusCreate={handleApplicationStatusCreate}
        onApplicationStatusReorder={handleApplicationStatusReorder}
        onApplicationStatusDelete={handleApplicationStatusDelete}
        onApplicationStatusNameUpdate={handleApplicationStatusNameUpdate}
        onApplicationStatusRuleAdd={handleApplicationStatusRuleAdd}
        onApplicationStatusRuleEdit={handleApplicationStatusRuleEdit}
        onApplicationStatusRuleDelete={handleApplicationStatusRuleDelete}
        isLoading={isBlockingRequestInProgress}
      />
    </>
  );
};

export default ApplicationWorkflowSetup;
