import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NullableItems, PaginationProps } from 'pagination';
import { SendGridIntegration, SendGridTemplate } from 'SendGrid/Types';
import Button from 'components/Button';
import Integration from './Integration';
import SendGridTemplates from './Templates';
import styles from './SendGrid.module.scss';
import { SendGridTemplatesSortingType } from 'api/LoanOriginationSystem/SendGridTemplateApi';
import NoItems from 'components/NoItems';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { isEqual } from 'lodash';
import { initialState as filtersInitialState } from 'SendGrid/Filters/Reducer';
import { LoanOriginationSystemTemplatesFiltersState } from 'SendGrid/Filters/Types';
import { simpleAction } from 'utils/actions/SimpleAction';
import { SendGridActionType } from 'SendGrid/ActionTypes';
import { SendGridTab } from './SendGridTab';
import TabSwitch, { TabSwitchOption } from 'components/TabSwitch';
import PageName from 'constants/PageName';

interface SendGridProps {
  onDeletePopupOpen: (id: string) => void;
  onImportTemplatePopUpOpen: () => void;
  onSearchInputChange: (value: string) => void;
  onTabChange: (tab: SendGridTab) => void;
  openEditTemplateContextualView: (id: string) => void;
  pageTab: SendGridTab;
  paginationProps: PaginationProps;
  saveIntegration: (clientApiKey: string) => Promise<void>;
  searchInputValue: string;
  sortingType: SendGridTemplatesSortingType;
  integration: SendGridIntegration | null;
  isIntegrationLoaded: boolean;
  templates: NullableItems<SendGridTemplate>;
  selectedMembers: UserInfo[];
  filters: LoanOriginationSystemTemplatesFiltersState;
}

const TABS = [
  {
    label: 'Credentials',
    id: SendGridTab.Credentials,
  },
  {
    label: 'Templates',
    id: SendGridTab.Templates,
  },
];

const SendGrid: FC<SendGridProps> = ({
  onDeletePopupOpen,
  onImportTemplatePopUpOpen,
  onSearchInputChange,
  onTabChange,
  openEditTemplateContextualView,
  pageTab,
  paginationProps,
  saveIntegration,
  searchInputValue,
  sortingType,
  integration,
  templates,
  selectedMembers,
  filters,
  isIntegrationLoaded,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      if (pageTab === 'templates') {
        dispatch(simpleAction(SendGridActionType.ResetTemplatesState));
      }
    };
  }, [pageTab]);

  const handleTabChange = (newTab: TabSwitchOption) => {
    onTabChange(newTab.id as SendGridTab);
  };

  const renderTemplatesContent = () => {
    if (!isIntegrationLoaded) {
      return null;
    }

    if (!integration) {
      return (
        <NoItems
          className={styles.noItemsContainer}
          titleClassName={styles.noItemsTitle}
          buttonClassName={styles.noItemsButton}
          title="Your credentials have not been added yet!"
          buttonMessage="Go To Credentials"
          onButtonClick={() => onTabChange(SendGridTab.Credentials)}
        />
      );
    }

    if (!templates.length && isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          className={styles.noItemsContainer}
          titleClassName={styles.noItemsTitle}
          buttonClassName={styles.noItemsButton}
          title="Import your templates to get started!"
          buttonMessage="Import Templates"
          onButtonClick={onImportTemplatePopUpOpen}
        />
      );
    }

    return (
      <SendGridTemplates
        templates={templates}
        paginationProps={paginationProps}
        onDeletePopupOpen={onDeletePopupOpen}
        onSearchInputChange={onSearchInputChange}
        searchInputValue={searchInputValue}
        sortingType={sortingType}
        openEditTemplateContextualView={openEditTemplateContextualView}
        selectedMembers={selectedMembers}
        filters={filters}
      />
    );
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.pageHeader}>
        <div className={styles.PageHeaderTitleContainer}>
          <h2 className={styles.pageTitle}>{PageName.SendGridIntegration}</h2>
        </div>
        {pageTab === 'templates' && (
          <Button
            kind="primary"
            size="default"
            className={styles.importButton}
            onClick={onImportTemplatePopUpOpen}
            disabled={!integration}
          >
            Import Templates
          </Button>
        )}
      </div>
      <TabSwitch className={styles.tabSwitch} tabs={TABS} selectedTabId={pageTab} onSelect={handleTabChange} />
      <div className={styles.pageContent}>
        {pageTab === SendGridTab.Credentials ? (
          <Integration
            integration={integration}
            isIntegrationLoaded={isIntegrationLoaded}
            onIntegrationSave={saveIntegration}
          />
        ) : (
          <div>
            <h4 className={styles.templatesHeader}>Templates</h4>
            {renderTemplatesContent()}
          </div>
        )}
      </div>
    </div>
  );
};
export default SendGrid;
