import { AnyAction, MiddlewareAPI, Middleware } from 'redux';
import { ReduxState } from 'types/redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ApplicationDocumentsActionType } from './ActionTypes';
import { CreateDocuSignEnvelopeSuccessAction } from './ActionCreator';

const isCreateDocuSignEnvelopeSuccessAction = (action: AnyAction): action is CreateDocuSignEnvelopeSuccessAction =>
  action.type === ApplicationDocumentsActionType.CreateDocuSignEnvelopeSuccess;

const notifyOnSuccessDocuSignEnvelopeMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateDocuSignEnvelopeSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.DocuSignEnvelopeCreated), 'success', dispatch);
  }

  return result;
}) as Middleware;

export default notifyOnSuccessDocuSignEnvelopeMiddleware;
