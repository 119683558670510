import { AuthHandler } from 'AuthHandler';
import { createContext, useContext } from 'react';

const AuthContext = createContext<AuthHandler | null>(null);

export const useAuthProvider = () => {
  const authHandler = useContext(AuthContext);
  if (!authHandler) {
    throw new Error(`Can't get AuthHandler from ReactContext. Make sure that this component is a descendant of AuthProvider.`);
  }
  return authHandler;
};

export default AuthContext.Provider;
