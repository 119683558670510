import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import ConfirmPopup from 'components/ConfirmPopup';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import React from 'react';
import formatDisplayId from 'utils/userIdFormat';

interface DuplicateApplicationConfirmationPopupProps {
  onConfirmClick: () => void;
  onClose: () => void;
  loading: boolean;
  application: Application;
}

const DuplicateApplicationConfirmationPopup = ({
  onConfirmClick,
  onClose,
  loading,
  application,
}: DuplicateApplicationConfirmationPopupProps) => {
  const borrowerFullName = getBorrowerFullName(application.borrowerType, application.variables);
  const formattedDisplayId = formatDisplayId(application.displayId);

  const message = `
    This will create a new application with the same data and documents as the application for
    ${borrowerFullName} (Application ${formattedDisplayId}).
  `;

  return (
    <ConfirmPopup
      message={message}
      confirmText="Duplicate Application"
      declineText="No, Go Back"
      title="Duplicate Application"
      onConfirmClick={onConfirmClick}
      onPopupClose={onClose}
      loading={loading}
    />
  );
};

export default DuplicateApplicationConfirmationPopup;
