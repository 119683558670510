import { RefObject, useEffect } from 'react';

const FOCUSABLE_ELEMENT_SELECTOR = 'button:not([disabled]), input:not([disabled]):not([type=radio])';

const getFocusableChildren = (parentElement: HTMLElement) => {
  const focusableChildren = parentElement.querySelectorAll(FOCUSABLE_ELEMENT_SELECTOR) as NodeListOf<HTMLElement>;
  return Array.from(focusableChildren).sort((a, b) => a.tabIndex - b.tabIndex);
};

const useFocusLock = (ref: RefObject<HTMLElement>) => {
  const handleKeyPress = (event: KeyboardEvent) => {
    if (!ref.current) {
      return;
    }

    if (event.key !== 'Tab') {
      return;
    }

    event.preventDefault();

    // looping focus switching inside the focus lock container
    const focusableChildren = getFocusableChildren(ref.current);
    if (focusableChildren.length === 0) {
      // if focus lock holder has no focusable elements, we disable switching focus by Tab key
      return;
    }

    if (event.shiftKey) {
      const previousItem = findPreviousFocusableChild(focusableChildren, document.activeElement);
      previousItem.focus();
    } else {
      const nextItem = findNextFocusableChild(focusableChildren, document.activeElement);
      nextItem.focus();
    }
  };

  const findPreviousFocusableChild = (focusableChildren: HTMLElement[], lastFocusedElement: Element | null) => {
    if (!lastFocusedElement) {
      return focusableChildren[0];
    }

    const currentFocusedChildIndex = focusableChildren.indexOf(lastFocusedElement as HTMLElement);

    if (currentFocusedChildIndex === -1 || currentFocusedChildIndex === 0) {
      return focusableChildren[focusableChildren.length - 1];
    }

    return focusableChildren[currentFocusedChildIndex - 1];
  };

  const findNextFocusableChild = (focusableChildren: HTMLElement[], lastFocusedElement: Element | null) => {
    if (!lastFocusedElement) {
      return focusableChildren[0];
    }

    const currentFocusedChildIndex = focusableChildren.indexOf(lastFocusedElement as HTMLElement);

    if (currentFocusedChildIndex === -1 || currentFocusedChildIndex === focusableChildren.length - 1) {
      return focusableChildren[0];
    }

    return focusableChildren[currentFocusedChildIndex + 1];
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);
};

export default useFocusLock;
