import { MessageType } from 'constants/messages';
import { ErrorType } from 'constants/ErrorType';

const errorTypeToMessageTypeMap: Record<ErrorType, MessageType> = {
  [ErrorType.ArchiveStrategyWithActiveVersionDisallowed]: MessageType.StrategyHasActiveVersion,
  [ErrorType.DeleteStrategyWithLockedVersionDisallowed]: MessageType.StrategyHasLockedVersion,
  [ErrorType.ArchiveActiveVersionDisallowed]: MessageType.VersionsIsActive,
  [ErrorType.DeleteLockedVersionDisallowed]: MessageType.VersionsIsLocked,
  [ErrorType.CopyStrategyWithoutModules]: MessageType.RestrictCreateNewVersionForEmptyStrategy,
};

export default errorTypeToMessageTypeMap;
