import { AnyAction } from 'redux';
import { LoanOriginationSystemLabelsActionTypes } from './ActionTypes';
import { TableViewData } from 'api/LoanOriginationSystem/Types';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemLabelsFiltersState } from './Filters/Types';
import { Label, LabelsSortingType, LabelsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';

type GetLabelsSuccessData = TableViewData<Label> & { searchInputValue: string };

export interface GetLabelsAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.GetLabels;
  payload: {
    filters?: LoanOriginationSystemLabelsFiltersState;
    sortingType?: LabelsSortingType;
  };
}

export interface GetLabelsSuccessAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.GetLabelsSuccess;
  payload: GetLabelsSuccessData;
}

export interface GetLabelInfoAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.GetLabelInfo;
  payload: string;
}

export interface GetLabelInfoSuccessAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.GetLabelInfoSuccess;
  payload: Label;
}

export interface SortLabelsAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.SortLabels;
  payload: LabelsSortingType;
}

export interface OpenDeleteLabelPopupAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.SetLabelToDeleteId;
  payload: string;
}

export interface CloseDeleteLabelPopupAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.CloseDeleteLabelPopUp;
}

export interface DeleteLabelAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.DeleteLabel;
  payload: string;
}

export interface DeleteLabelSuccessAction extends AnyAction {
  type: LoanOriginationSystemLabelsActionTypes.DeleteLabelSuccess;
  payload: DeleteLabelSuccessResult;
}

export interface DeleteLabelSuccessResult {
  name: string;
}

export interface OpenCreatePopUpAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.OpenCreateLabelPopUp;
}

export interface CloseCreatePopUpAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.CloseCreateLabelPopUp;
}

export interface CreateLabelAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.CreateLabel;
  payload: {
    name: string;
    color: string;
  };
}

export interface CreateLabelSuccessAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.CreateLabelSuccess;
  payload: Label;
}

export interface OpenUpdateLabelPopUpAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.OpenUpdateLabelPopUp;
}

export interface CloseUpdateLabelPopUpAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.CloseUpdateLabelPopUp;
}

export interface UpdateLabelAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.UpdateLabel;
  payload: {
    id: string;
    name: string;
    color: string;
  };
}

export interface UpdateLabelSuccessAction extends AnyAction {
  type: typeof LoanOriginationSystemLabelsActionTypes.UpdateLabelSuccess;
  payload: Label;
}

export const getLabels = ({
  filters,
  sortingType,
}: {
  filters?: LoanOriginationSystemLabelsFiltersState;
  sortingType?: LabelsSortingType;
}): GetLabelsAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.GetLabels,
    payload: {
      filters,
      sortingType,
    },
  };
};

export const getLabelsSuccess = (result: GetLabelsSuccessData): GetLabelsSuccessAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.GetLabelsSuccess,
    payload: result,
  };
};

export const getLabelsFailure = FailedActionCreator(LoanOriginationSystemLabelsActionTypes.GetLabelsFailure);

export const getLabelInfo = (id: string): GetLabelInfoAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.GetLabelInfo,
    payload: id,
  };
};

export const getLabelInfoSuccess = (result: Label): GetLabelInfoSuccessAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.GetLabelInfoSuccess,
    payload: result,
  };
};

export const getLabelInfoFailure = FailedActionCreator(LoanOriginationSystemLabelsActionTypes.GetLabelInfoFailure);

export const sortLabels = (field: LabelsSortingField, ascending: boolean): SortLabelsAction => ({
  type: LoanOriginationSystemLabelsActionTypes.SortLabels,
  payload: {
    ascending,
    field,
  },
});

export const openDeleteLabelPopup = (labelId: string): OpenDeleteLabelPopupAction => ({
  type: LoanOriginationSystemLabelsActionTypes.SetLabelToDeleteId,
  payload: labelId,
});

export const closeDeleteLabelPopup = (): CloseDeleteLabelPopupAction => ({
  type: LoanOriginationSystemLabelsActionTypes.CloseDeleteLabelPopUp,
});

export const deleteLabel = (labelId: string): DeleteLabelAction => ({
  type: LoanOriginationSystemLabelsActionTypes.DeleteLabel,
  payload: labelId,
});

export const deleteLabelSuccess = (data: DeleteLabelSuccessResult): DeleteLabelSuccessAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.DeleteLabelSuccess,
    payload: data,
  };
};

export const deleteLabelFailure = FailedActionCreator(LoanOriginationSystemLabelsActionTypes.DeleteLabelFailure);

export const openCreateLabelPopUp = (): OpenCreatePopUpAction => ({
  type: LoanOriginationSystemLabelsActionTypes.OpenCreateLabelPopUp,
});

export const closeCreateLabelPopUp = (): CloseCreatePopUpAction => ({
  type: LoanOriginationSystemLabelsActionTypes.CloseCreateLabelPopUp,
});

export const createLabel = (name: string, color: string): CreateLabelAction => ({
  type: LoanOriginationSystemLabelsActionTypes.CreateLabel,
  payload: {
    name,
    color,
  },
});

export const createLabelSuccess = (data: Label): CreateLabelSuccessAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.CreateLabelSuccess,
    payload: data,
  };
};

export const createLabelFailure = FailedActionCreator(LoanOriginationSystemLabelsActionTypes.CreateLabelFailure);

export const openUpdateLabelPopUp = (id: string): OpenUpdateLabelPopUpAction => ({
  type: LoanOriginationSystemLabelsActionTypes.OpenUpdateLabelPopUp,
  payload: id,
});

export const closeUpdateLabelPopUp = (): CloseUpdateLabelPopUpAction => ({
  type: LoanOriginationSystemLabelsActionTypes.CloseUpdateLabelPopUp,
});

export const updateLabel = (id: string, name: string, color: string): UpdateLabelAction => ({
  type: LoanOriginationSystemLabelsActionTypes.UpdateLabel,
  payload: {
    id,
    name,
    color,
  },
});

export const updateLabelSuccess = (data: Label): UpdateLabelSuccessAction => {
  return {
    type: LoanOriginationSystemLabelsActionTypes.UpdateLabelSuccess,
    payload: data,
  };
};

export const updateLabelFailure = FailedActionCreator(LoanOriginationSystemLabelsActionTypes.UpdateLabelFailure);
