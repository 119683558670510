import LoanOriginationSystemApi from './LoanOriginationSystemApi';
import { ComparisonOperandType, ComparisonOperator } from 'components/SimpleRuleBuilder/Types';
import { RuleBuilderCoreData } from 'RuleCreate/Types';
import { Variable } from 'Variables/VariablesTypes';

export interface ApplicationStatusMultipleRule {
  id: string;
  sourceVariable: Variable;
  firstOperandVariable?: Variable;
  firstOperandValue: string;
  firstOperandType: ComparisonOperandType;
  secondOperandVariable?: Variable;
  secondOperandValue: string;
  secondOperandType: ComparisonOperandType;
  comparisonOperator: ComparisonOperator;
}

export interface ApplicationStatusRule {
  id: string;
  statusId: string;
  productId: string;
  multipleRules: ApplicationStatusMultipleRule[];
  createdAt: Date;
}

export interface ApplicationStatusRuleApi {
  create(statusId: string, multipleRules: RuleBuilderCoreData[]): Promise<ApplicationStatusRule>;
  update(ruleId: string, multipleRules: RuleBuilderCoreData[]): Promise<ApplicationStatusRule>;
  delete(ruleId: string): Promise<void>;
}

export default class ApplicationStatusRuleRestApi extends LoanOriginationSystemApi<ApplicationStatusRule>
  implements ApplicationStatusRuleApi {
  protected resourceName = 'application-status-rules';

  public async create(statusId: string, multipleRules: RuleBuilderCoreData[]) {
    return this.fetch<ApplicationStatusRule>(`/${this.resourceName}`, 'POST', {
      rule: {
        statusId,
        multipleRules,
      },
    });
  }

  public async delete(ruleId: string) {
    return this.fetch<void>(`/${this.resourceName}/${ruleId}`, 'DELETE');
  }

  public update(ruleId: string, multipleRules: RuleBuilderCoreData[]): Promise<ApplicationStatusRule> {
    return this.updateResource(ruleId, {
      rule: {
        multipleRules,
      },
    });
  }
}
