export enum ESignIntegrationsActionType {
  GetDocuSignIntegration = 'eSignIntegrations/getDocuSignIntegration',
  GetDocuSignIntegrationSuccess = 'eSignIntegrations/getDocuSignIntegrationSuccess',
  GetDocuSignIntegrationFailure = 'eSignIntegrations/getDocuSignIntegrationFailure',
  CreateDocuSignIntegration = 'eSignIntegrations/createDocuSignIntegration',
  CreateDocuSignIntegrationSuccess = 'eSignIntegrations/createDocuSignIntegrationSuccess',
  CreateDocuSignIntegrationFailure = 'eSignIntegrations/createDocuSignIntegrationFailure',
  UpdateDocuSignIntegration = 'eSignIntegrations/updateDocuSignIntegration',
  UpdateDocuSignIntegrationSuccess = 'eSignIntegrations/updateDocuSignIntegrationSuccess',
  UpdateDocuSignIntegrationFailure = 'eSignIntegrations/updateDocuSignIntegrationFailure',
}
