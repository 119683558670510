import React, { FC } from 'react';
import styles from './StrategyTableRow.module.scss';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import StrategyVersionInfo from 'components/StrategyGroups/StrategyVersionInfo';
import { StrategyGroupVersion } from 'api/Types';
import StrategyActions from 'components/StrategyGroups/StrategyActions';
import clsx from 'clsx';

interface StrategyTableRowProps {
  name: string;
  archived: boolean;
  versions: StrategyGroupVersion[];
  versionsNumber: number;
  onClick: () => void;
}

const StrategyTableRow: FC<StrategyTableRowProps> = ({ name, archived, versions, versionsNumber, onClick }) => {
  return (
    <div onClick={() => onClick()}>
      <TableRow className={styles.row}>
        <TableBodyCell className={styles.infoCell}>
          <div className={styles.displayName}>
            <p className={clsx(styles.strategyName, archived && styles.strategyName__inactive)}>{name}</p>
            <p className={styles.versionsCount}>{`${versionsNumber} ${versionsNumber > 1 ? 'versions' : 'version'}`}</p>
          </div>
        </TableBodyCell>
        <TableBodyCell className={styles.versionsCell}>
          <div className={styles.versionsContainer}>
            {versions.map((version) => (
              <StrategyVersionInfo
                version={version}
                isTable
                key={version.id}
                versionActionsClassName={styles.actionsContainer}
                isStatusDisplay
              />
            ))}
            <div className={styles.rowActionsContainer}>
              <StrategyActions archived={archived} name={name} btnClassName={styles.strategyActionsBtn} />
            </div>
          </div>
        </TableBodyCell>
      </TableRow>
    </div>
  );
};

export default StrategyTableRow;
