import { isValidPhoneNumber } from 'react-phone-number-input';
import { MessageType } from 'constants/messages';

const ID_NUMBER_DEFAULT_LENGTH = 9;

export const isEmptyField = (value: string): boolean => !value.trim();

export const validateEmail = (email: string, required: boolean = true): string => {
  if (required && (!email || isEmptyField(email))) {
    return 'Email is required';
  }

  if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return 'Invalid email address';
  }

  return '';
};

export const validatePassword = (password: string): MessageType | null => {
  if (password.length === 0) {
    return MessageType.PasswordRequired;
  }

  if (password.length < 8 || !/[a-z]/.test(password) || !/[A-Z]/.test(password) || !/[0-9]/.test(password)) {
    return MessageType.PasswordRequirementsNotMet;
  }

  return null;
};

export const removeWhiteSpace = <Value>(value: Value): Value | string => {
  if (typeof value === 'string') {
    return value.replace(/^\s+/, '');
  }

  return value;
};

export const validateIDNumber = (value: string, template?: string, required: boolean = true) => {
  if (isEmptyField(value) && !required) {
    return '';
  }

  if (isEmptyField(value)) {
    return 'ID Number is required';
  }

  if (template && template !== 'Other') {
    if (value.length !== ID_NUMBER_DEFAULT_LENGTH) {
      return 'Invalid identification number';
    }
  }

  return '';
};

export const validatePhoneNumber = (phoneNumber: string, isRequired: boolean = true) => {
  if (isEmptyField(phoneNumber) && !isRequired) {
    return '';
  }

  if (isEmptyField(phoneNumber)) {
    return 'Phone Number is required';
  }

  if (!isValidPhoneNumber(phoneNumber)) {
    return 'Invalid phone number';
  }

  return '';
};
