export const GET_VARIABLES_REQUEST = 'GET_VARIABLES_REQUEST';
export const GET_VARIABLES_REQUEST_SUCCESS = 'GET_VARIABLES_SUCCESS';
export const GET_VARIABLES_REQUEST_FAILURE = 'GET_VARIABLES_FAILURE';
export const ACTION_TYPE_EDIT_VARIABLE_REQUEST = 'ACTION_TYPE_EDIT_VARIABLE_REQUEST';
export const ACTION_TYPE_EDIT_VARIABLE_SUCCESS = 'ACTION_TYPE_EDIT_VARIABLE_SUCCESS';
export const ACTION_TYPE_EDIT_VARIABLE_FAILURE = 'ACTION_TYPE_EDIT_VARIABLE_FAILURE';
export const ACTION_TYPE_CREATE_VARIABLE_REQUEST = 'ACTION_TYPE_GET_VARIABLE_REQUEST';
export const ACTION_TYPE_CREATE_VARIABLE_SUCCESS = 'ACTION_TYPE_GET_VARIABLE_SUCCESS';
export const ACTION_TYPE_CREATE_VARIABLE_FAILURE = 'ACTION_TYPE_GET_VARIABLE_FAILURE';

export enum VariablesActionType {
  ResetVariablesState = 'variables/resetVariablesState',
  SortVariables = 'variables/sortVariables',
  SetShowArchived = 'variables/setShowArchived',
  ChangeVariableStatus = 'variables/changeVariableStatus',
  ChangeVariableStatusSuccess = 'variables/changeVariableStatusSuccess',
  ChangeVariableStatusFailure = 'variables/changeVariableStatusFailure',
}
