import React from 'react';
import Button from 'components/Button';
import styles from './FinalStatusInformation.module.scss';
import { LargeErrorIcon, LargeSuccessIcon } from 'static/images';

type StatusRulesInformationType = 'approved' | 'rejected';

export interface StatusRulesInformationProps {
  type: StatusRulesInformationType;
  displayViewHistoryButton: boolean;
  onViewHistory: () => void;
}

const FinalStatusInformation = ({ type, onViewHistory, displayViewHistoryButton }: StatusRulesInformationProps) => {
  const renderTitle = () => {
    if (type === 'approved') {
      return (
        <h5 className={styles.title}>
          Application Approved!{' '}
          <span aria-label="success" role="img">
            🎉
          </span>
        </h5>
      );
    }

    return <h5 className={styles.rejectedTitle}>Application Rejected!</h5>;
  };

  const renderIcon = () => {
    if (type === 'approved') {
      return <LargeSuccessIcon />;
    }

    return <LargeErrorIcon />;
  };

  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>{renderIcon()}</div>
      <h4 className={styles.header}>Status Rules</h4>
      <div>
        {renderTitle()}
        <p className={styles.description}>
          Please refer to the application's history for information on statuses and workflow.
        </p>
        {displayViewHistoryButton && (
          <Button kind="primary" size="form" onClick={onViewHistory}>
            View History
          </Button>
        )}
      </div>
    </div>
  );
};

export default FinalStatusInformation;
