import { debounce } from 'lodash';

type DebounceParams = Parameters<typeof debounce>;

interface Callback<Value, Result> {
  (values: Value[]): Promise<Result[]>;
}

interface PromisePrivateApi<Value = unknown, RejectionReason = Error> {
  resolve(value: Value): void;
  reject(reason: RejectionReason): void;
}

const DebouncingAccumulator = <Val, Res>(
  callback: Callback<Val, Res>,
  wait?: DebounceParams[1],
  options?: DebounceParams[2],
) => {
  let accumulator: Val[] = [];
  let subscribers: PromisePrivateApi<Res>[] = [];

  const handler = debounce(
    async () => {
      const values = accumulator;
      const promises = subscribers;
      accumulator = [];
      subscribers = [];
      try {
        const results = await callback(values);
        results.forEach((value, index) => {
          promises[index].resolve(value);
        });
      } catch (error) {
        promises.forEach(({ reject }) => reject(error));
      }
    },
    wait,
    options,
  );

  return (value: Val) =>
    new Promise<Res>((resolve, reject) => {
      accumulator.push(value);
      subscribers.push({ resolve, reject });
      handler();
    });
};

export default DebouncingAccumulator;
