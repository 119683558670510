import { AnyAction } from 'redux';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import {
  GET_COLUMN_VIEW_PRODUCT_DATA,
  GET_COLUMN_VIEW_PRODUCT_DATA_FAILED,
  GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS,
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_LIST_FAILED,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_TABLE_VIEW_PRODUCT_DATA,
  GET_TABLE_VIEW_PRODUCT_DATA_FAILED,
  GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS,
  LoanOriginationSystemApplicationsActionType,
  SELECT_PRODUCT,
  SORT_TABLE_VIEW_PRODUCT_DATA,
} from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { BaseLoanOriginationSystemApplicationsFilters } from 'LoanOriginationSystemApplications/Filters/Types';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import {
  ApplicationSortingField,
  ApplicationsSortingType,
  ChangeSortingTypePayload,
  GetTableViewProductDataResult,
} from 'api/Types';
import { ApplicationStatusRulesValidationData, CreateApplicationProductData, ViewType } from './Types';

export enum ApplicationUpdatingState {
  Updating = 'updating',
  Success = 'success',
  Failure = 'failure',
}

export enum ChangeApplicationStatusActionOrigin {
  ApplicationsDashboard = 'applicationsDashboard',
  ApplicationsDetails = 'applicationDetails',
}

export interface GetProductsListRequestAction {
  type: typeof GET_PRODUCTS_LIST;
}

export interface GetProductsListSuccessActionPayload {
  productsList: Product[];
  selectProductRequired?: boolean;
}

export interface GetProductsListSuccessAction {
  type: typeof GET_PRODUCTS_LIST_SUCCESS;
  payload: GetProductsListSuccessActionPayload;
}

export interface GetColumnViewProductDataAction {
  type: typeof GET_COLUMN_VIEW_PRODUCT_DATA;
  payload: {
    productId: string;
    filters: BaseLoanOriginationSystemApplicationsFilters;
    sortingType: ApplicationsSortingType;
  };
}
export interface GetColumnViewProductDataSuccessAction {
  type: typeof GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS;
  payload: Application[];
}

export interface GetTableViewProductDataAction extends AnyAction {
  type: typeof GET_TABLE_VIEW_PRODUCT_DATA;
  payload: {
    productId: string;
    filters: BaseLoanOriginationSystemApplicationsFilters;
    sortingType: ApplicationsSortingType;
  };
}

export interface GetTableViewProductDataSuccessAction extends AnyAction {
  type: typeof GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS;
  payload: GetTableViewProductDataResult;
}

export interface SelectProductAction extends AnyAction {
  type: typeof SELECT_PRODUCT;
  payload: {
    productId: string;
  };
}

export interface SortTableViewProductDataAction extends AnyAction {
  type: typeof SORT_TABLE_VIEW_PRODUCT_DATA;
  payload: ChangeSortingTypePayload;
}

export interface SortColumnViewProductDataAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.SortColumnViewProductData;
  payload: ChangeSortingTypePayload;
}

export interface ChangeApplicationStatusAction extends ActionWithOrigin<ChangeApplicationStatusActionOrigin> {
  type: LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus;
  payload: {
    application: Application;
    status: ApplicationStatus;
    declineReasons?: string[];
    sourceIndex?: number;
    destinationIndex?: number;
  };
}

export interface ChangeApplicationStatusSuccessAction extends ActionWithOrigin<ChangeApplicationStatusActionOrigin> {
  type: LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusSuccess;
  payload: {
    application: Application;
  };
}

export interface MoveApplicationWithoutChangingStatusAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.MoveApplicationWithoutChangingStatus;
  payload: {
    application: Application;
    status: ApplicationStatus;
    destinationIndex: number;
  };
}

export interface DeleteApplicationAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.DeleteApplication;
  payload: {
    applicationId: string;
  };
}

export interface DeleteApplicationSuccessAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.DeleteApplicationSuccess;
}

export interface DuplicateApplicationAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.DuplicateApplication;
  payload: {
    applicationId: string;
  };
}

export interface DuplicateApplicationSuccessAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.DuplicateApplicationSuccess;
}

export interface OpenDeleteApplicationPopupAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.OpenDeleteApplicationPopup;
  payload: {
    applicationId: string;
  };
}

export interface CloseDeleteApplicationPopupAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.CloseDeleteApplicationPopup;
}

export interface OpenDuplicateApplicationPopupAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.OpenDuplicateApplicationPopup;
  payload: {
    application: Application;
  };
}

export interface CloseDuplicateApplicationPopupAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.CloseDuplicateApplicationPopup;
}

export interface SetApplicationStatusRulesValidationDataAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.SetApplicationStatusRulesValidationData;
  payload: ApplicationStatusRulesValidationData | null;
}

export interface ChangeApplicationUpdatingStateAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.ChangeApplicationUpdatingState;
  payload: {
    state: ApplicationUpdatingState | null;
    applicationId: string;
  };
}

export interface ToggleCreateNewApplicationPopupOpenAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.ToggleCreateNewApplicationPopupOpen;
}

export interface SetCreateApplicationProductDataAction extends AnyAction {
  type: LoanOriginationSystemApplicationsActionType.SetCreateApplicationProductData;
  payload: CreateApplicationProductData | null;
}

export const toggleCreateNewApplicationPopupOpen = (): ToggleCreateNewApplicationPopupOpenAction => ({
  type: LoanOriginationSystemApplicationsActionType.ToggleCreateNewApplicationPopupOpen,
});

export const setCreateApplicationProductData = (
  data: CreateApplicationProductData | null,
): SetCreateApplicationProductDataAction => ({
  type: LoanOriginationSystemApplicationsActionType.SetCreateApplicationProductData,
  payload: data,
});

export const getProductsList = (): GetProductsListRequestAction => {
  return {
    type: GET_PRODUCTS_LIST,
  };
};

export const getProductsListSuccess = (payload: GetProductsListSuccessActionPayload): GetProductsListSuccessAction => {
  return {
    type: GET_PRODUCTS_LIST_SUCCESS,
    payload,
  };
};

export const getProductsListFailed = FailedActionCreator(GET_PRODUCTS_LIST_FAILED);

export const getColumnViewProductData = (
  productId: string,
  filters: BaseLoanOriginationSystemApplicationsFilters,
  sortingType: ApplicationsSortingType,
): GetColumnViewProductDataAction => {
  return {
    type: GET_COLUMN_VIEW_PRODUCT_DATA,
    payload: {
      productId,
      filters,
      sortingType,
    },
  };
};

export const getColumnViewProductDataSuccess = (productData: Application[]): GetColumnViewProductDataSuccessAction => {
  return {
    type: GET_COLUMN_VIEW_PRODUCT_DATA_SUCCESS,
    payload: productData,
  };
};

export const getColumnViewProductDataFailed = FailedActionCreator(GET_COLUMN_VIEW_PRODUCT_DATA_FAILED);

export const getTableViewProductData = (
  productId: string,
  filters: BaseLoanOriginationSystemApplicationsFilters,
  sortingType: ApplicationsSortingType,
): GetTableViewProductDataAction => {
  return {
    type: GET_TABLE_VIEW_PRODUCT_DATA,
    payload: {
      productId,
      filters,
      sortingType,
    },
  };
};

export const getTableViewProductDataSuccess = (
  productData: GetTableViewProductDataResult,
): GetTableViewProductDataSuccessAction => {
  return {
    type: GET_TABLE_VIEW_PRODUCT_DATA_SUCCESS,
    payload: productData,
  };
};

export const getTableViewProductDataFailed = FailedActionCreator(GET_TABLE_VIEW_PRODUCT_DATA_FAILED);

export const selectProduct = (productId: string): SelectProductAction => {
  return {
    type: SELECT_PRODUCT,
    payload: {
      productId,
    },
  };
};

export const getProductDataByViewType = (
  productId: string,
  viewType: ViewType,
  filters: BaseLoanOriginationSystemApplicationsFilters,
  sortingType: ApplicationsSortingType,
): GetColumnViewProductDataAction | GetTableViewProductDataAction => {
  if (viewType === ViewType.Column) {
    return getColumnViewProductData(productId, filters, sortingType);
  }

  return getTableViewProductData(productId, filters, sortingType);
};

export const sortTableViewProductData = (
  field: ApplicationSortingField,
  ascending: boolean,
): SortTableViewProductDataAction => {
  return {
    type: SORT_TABLE_VIEW_PRODUCT_DATA,
    payload: {
      ascending,
      field,
      viewType: ViewType.Table,
    },
  };
};

export const sortColumnViewProductData = (
  field: ApplicationSortingField,
  ascending: boolean,
): SortColumnViewProductDataAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.SortColumnViewProductData,
    payload: {
      ascending,
      field,
      viewType: ViewType.Column,
    },
  };
};

export const changeApplicationStatus = (
  application: Application,
  status: ApplicationStatus,
  declineReasons?: string[],
  sourceIndex?: number,
  destinationIndex?: number,
): ChangeApplicationStatusAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.ChangeApplicationStatus,
    payload: {
      application,
      status,
      declineReasons,
      sourceIndex,
      destinationIndex,
    },
  };
};

export const moveApplicationWithoutChangingStatus = (
  application: Application,
  status: ApplicationStatus,
  destinationIndex: number,
): MoveApplicationWithoutChangingStatusAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.MoveApplicationWithoutChangingStatus,
    payload: {
      application,
      status,
      destinationIndex,
    },
  };
};

export const changeApplicationStatusSuccess = (application: Application): ChangeApplicationStatusSuccessAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusSuccess,
    payload: {
      application,
    },
  };
};

export const changeApplicationStatusFailed = (error: Error) => ({
  type: LoanOriginationSystemApplicationsActionType.ChangeApplicationStatusFailed,
  payload: error,
});

export const deleteApplication = (applicationId: string): DeleteApplicationAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.DeleteApplication,
    payload: {
      applicationId,
    },
  };
};

export const deleteApplicationSuccess = (): DeleteApplicationSuccessAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.DeleteApplicationSuccess,
  };
};

export const deleteApplicationFailed = FailedActionCreator(
  LoanOriginationSystemApplicationsActionType.DeleteApplicationFailed,
);

export const openDeleteApplicationPopup = (applicationId: string): OpenDeleteApplicationPopupAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.OpenDeleteApplicationPopup,
    payload: {
      applicationId,
    },
  };
};

export const closeDeleteApplicationPopup = (): CloseDeleteApplicationPopupAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.CloseDeleteApplicationPopup,
  };
};

export const openDuplicateApplicationPopup = (application: Application): OpenDuplicateApplicationPopupAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.OpenDuplicateApplicationPopup,
    payload: {
      application,
    },
  };
};

export const closeDuplicateApplicationPopup = (): CloseDuplicateApplicationPopupAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.CloseDuplicateApplicationPopup,
  };
};

export const duplicateApplication = (applicationId: string): DuplicateApplicationAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.DuplicateApplication,
    payload: {
      applicationId,
    },
  };
};

export const duplicateApplicationSuccess = (): DuplicateApplicationSuccessAction => {
  return {
    type: LoanOriginationSystemApplicationsActionType.DuplicateApplicationSuccess,
  };
};

export const duplicateApplicationFailed = FailedActionCreator(
  LoanOriginationSystemApplicationsActionType.DuplicateApplicationFailed,
);

export const setApplicationStatusRulesValidationData = (
  data: ApplicationStatusRulesValidationData | null,
): SetApplicationStatusRulesValidationDataAction => ({
  type: LoanOriginationSystemApplicationsActionType.SetApplicationStatusRulesValidationData,
  payload: data,
});

export const changeApplicationUpdatingState = (
  applicationId: string,
  state: ApplicationUpdatingState | null,
): ChangeApplicationUpdatingStateAction => ({
  type: LoanOriginationSystemApplicationsActionType.ChangeApplicationUpdatingState,
  payload: {
    state,
    applicationId,
  },
});
