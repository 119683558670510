import Fetcher from 'middlewares/Fetcher';
import {
  Intermediary,
  LoanOriginationSystemIntermediariesApi,
} from 'api/LoanOriginationSystem/LoanOriginationSystemIntermediariesApi';
import { CreateIntermediaryAction, createIntermediaryFailure, createIntermediarySuccess } from './ActionCreator';
import { LoanOriginationSystemCreateIntermediaryTypes } from './ActionTypes';

const CreateIntermediaryMiddleware = (api: LoanOriginationSystemIntermediariesApi) =>
  Fetcher<Intermediary, CreateIntermediaryAction>(
    LoanOriginationSystemCreateIntermediaryTypes.CreateIntermediary,
    createIntermediarySuccess,
    createIntermediaryFailure,
    (action) => {
      return api.createIntermediary(action.payload.data);
    },
  );

export default CreateIntermediaryMiddleware;
