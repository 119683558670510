import { StrategyGroupActionType } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { StrategyGroupsData } from 'api/Types';
import { StrategyListParams } from 'api/DecisionEngineStrategiesType';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface GetStrategyGroupsDataAction {
  type: typeof StrategyGroupActionType.GetStrategyGroupsData;
  payload?: StrategyListParams;
}

export interface SetShowArchivedAction {
  type: typeof StrategyGroupActionType.SetShowArchived;
  payload: boolean;
}

export interface ResetFiltersAction {
  type: typeof StrategyGroupActionType.ResetFilters;
}

export interface SetUpdatersAction {
  type: typeof StrategyGroupActionType.SetUpdaters;
  payload: UserInfo[];
}

export interface GetStrategyGroupsDataSuccessAction {
  type: typeof StrategyGroupActionType.GetStrategyGroupsDataSuccess;
  payload: StrategyGroupsData;
}

export interface ClearStrategyGroupsDataAction {
  type: typeof StrategyGroupActionType.ClearStrategyGroupsData;
}

export interface SetStrategyGroupsSearchInputAction {
  type: typeof StrategyGroupActionType.SetStrategyGroupsSearchInput;
  payload: string;
}

export const getStrategyGroupsData = (payload?: StrategyListParams): GetStrategyGroupsDataAction => ({
  type: StrategyGroupActionType.GetStrategyGroupsData,
  payload,
});

export const clearStrategyGroupsData = (): ClearStrategyGroupsDataAction => ({
  type: StrategyGroupActionType.ClearStrategyGroupsData,
});

export const getStrategyGroupsDataSuccess = (
  payload: GetStrategyGroupsDataSuccessAction['payload'],
): GetStrategyGroupsDataSuccessAction => ({
  type: StrategyGroupActionType.GetStrategyGroupsDataSuccess,
  payload,
});

export const getStrategyGroupsDataFailure = FailedActionCreator(StrategyGroupActionType.GetStrategyGroupsDataFailure);

export const setStrategyGroupsSearchInput = (
  payload: SetStrategyGroupsSearchInputAction['payload'],
): SetStrategyGroupsSearchInputAction => ({
  type: StrategyGroupActionType.SetStrategyGroupsSearchInput,
  payload,
});

export const setShowArchived = (payload: SetShowArchivedAction['payload']): SetShowArchivedAction => ({
  type: StrategyGroupActionType.SetShowArchived,
  payload,
});

export const resetFilters = (): ResetFiltersAction => ({
  type: StrategyGroupActionType.ResetFilters,
});

export const setUpdaters = (payload: UserInfo[]): SetUpdatersAction => ({
  type: StrategyGroupActionType.SetUpdaters,
  payload,
});
