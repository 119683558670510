import React, { MouseEvent, ReactNode } from 'react';
import ExternalLink from 'components/ExternalLink/ExternalLink';
import { ShareImage } from 'static/images';

import styles from './AsideLink.module.scss';
import clsx from 'clsx';

export interface AsideLinkProps {
  href: string;
  external?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: <E extends HTMLElement>(e: MouseEvent<E>) => void;
}

const AsideLink = ({ href, external = false, children, className, onClick }: AsideLinkProps) => {
  const Component = external ? ExternalLink : 'a';

  const handleClick = external
    ? undefined
    : (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        onClick?.(event);
      };

  return (
    <Component href={href} className={clsx(styles.asideLink, className)} onClick={handleClick}>
      {external && <ShareImage className={styles.linkImage} />}
      <p className={styles.linkTitle}>{children}</p>
    </Component>
  );
};

export default AsideLink;
