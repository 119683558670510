import React, { FC, useRef, useState } from 'react';
import styles from './ProcessingIndividualCase.module.scss';
import { VectorBottomImage } from 'static/images';
import Button from 'components/Button';
import UserAvatar from 'components/UserAvatar';
import { Link } from 'react-router-dom';
import DateTime from 'components/DateTime';
import { DateTimeFormat } from 'utils/dateFormat';
import ButtonWithImage from 'components/ButtonWithImage';
import clsx from 'clsx';
import { ModuleNameStatusMap } from 'pages/ProcessingIndividualCase/ProcessingIndividualCase';
import Table from 'components/Table';
import TableHeadCell from 'components/Table/TableHeadCell';
import TableHead from 'components/Table/TableHead';
import TableBody from 'components/Table/TableBody';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import WrapperWithTooltip from 'components/Tooltip';
import { SkeletonRectangle, SkeletonCircle } from 'components/Skeleton';
import { IndividualCaseState } from 'StrategyProcessing/Types';

export interface ProcessingIndividualCaseProps {
  individualCase: IndividualCaseState;
  handleDownloadResults: () => void;
  resultType: ModuleStatus;
  moduleNameStatusMap: ModuleNameStatusMap[];
  inputs: VariableWithValue[];
  outputs: VariableWithValue[];
  reprocessDecision: () => void;
}

export interface VariableWithValue {
  [key: string]: string;
}

const SIZE = 50;
const INPUTS_PER_PAGE_DEFAULT = 13;
const OUTPUTS_PER_PAGE_DEFAULT = 7;

export enum ModuleStatus {
  Passed = 'Passed',
  Failed = 'Failed',
  Error = 'Error',
  Completed = 'Completed',
  NotRun = 'Not Run',
}

export type VariablesType = 'inputs' | 'outputs';

const statusStyles = new Map([
  [ModuleStatus.Passed, styles.statusPassed],
  [ModuleStatus.Failed, styles.statusFailed],
  [ModuleStatus.Error, styles.statusError],
  [ModuleStatus.Completed, styles.statusCompleted],
  [ModuleStatus.NotRun, styles.statusNotRun],
]);

const getStateColor = (status: ModuleStatus) => {
  return statusStyles.get(status);
};

const ProcessingIndividualCase: FC<ProcessingIndividualCaseProps> = ({
  individualCase,
  handleDownloadResults,
  resultType,
  moduleNameStatusMap,
  inputs,
  outputs,
  reprocessDecision,
}) => {
  const resultTable = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [resultTableHeight, setResultTableHeight] = useState(0);

  const { displayTitle, user, createdAt, strategyDisplayName, application, isLoading } = individualCase;

  const handleChangeTableOpenState = () => {
    setIsOpen(!isOpen);
    if (resultTable.current) {
      const { children: containerChildren } = resultTable.current!;
      const { offsetHeight } = containerChildren[0] as HTMLElement;
      setResultTableHeight(offsetHeight);
    }
  };

  const renderModuleResults = () => {
    const lastModuleIndex = moduleNameStatusMap.length - 1;

    return moduleNameStatusMap.map(({ name, status }, index) => (
      <TableRow key={name + index} disableHover className={clsx(index === lastModuleIndex && styles.lastModuleResult)}>
        <TableBodyCell width={SIZE} noPadding>
          {name}
        </TableBodyCell>
        <TableBodyCell width={SIZE} noPadding>
          <div className={clsx(styles.moduleStatus, getStateColor(status))}>
            {status}
          </div>
        </TableBodyCell>
      </TableRow>
    ));
  };

  const renderVariablesSkeleton = (variablesType: VariablesType) => {
    return Array(variablesType === 'inputs' ? INPUTS_PER_PAGE_DEFAULT : OUTPUTS_PER_PAGE_DEFAULT)
      .fill(null)
      .map((_, index) => (
        <TableRow disableHover key={index}>
          <TableBodyCell width={SIZE}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
          <TableBodyCell width={SIZE}>
            <SkeletonRectangle width="80%" color="primary6" height="16px" />
          </TableBodyCell>
        </TableRow>
      ));
  };

  const renderVariables = (variablesType: VariablesType) => {
    const variables = variablesType === 'inputs' ? inputs : outputs;
    if (isLoading) {
      return renderVariablesSkeleton(variablesType);
    }

    return Object.keys(variables).map((key) => (
      <TableRow disableHover key={key}>
        <TableBodyCell width={SIZE}>{key}</TableBodyCell>
        <TableBodyCell width={SIZE}>{`${variables[key]}`}</TableBodyCell>
      </TableRow>
    ));
  };

  const renderDeclineReasonOrMessage = () => {
    if (individualCase.declineReasons.length) {
      return (
        <TableRow disableHover>
          <TableBodyCell width={SIZE}>Decline Reasons</TableBodyCell>
          <TableBodyCell width={SIZE} className={styles.textField}>
            {individualCase.declineReasons.join(', ')}
          </TableBodyCell>
        </TableRow>
      );
    }
    if (individualCase.error.length) {
      return (
        <TableRow disableHover>
          <TableBodyCell width={SIZE}>Message</TableBodyCell>
          <TableBodyCell width={SIZE} className={styles.textField}>
            {individualCase.error.join(', ')}
          </TableBodyCell>
        </TableRow>
      );
    }
    return null;
  };

  const tooltip = `${strategyDisplayName} has been deleted.`;

  const applicationName =
    application &&
    (application.variables.borrower_company_name ||
      `${application.variables.borrower_first_name} ${application.variables.borrower_last_name}`);

  return (
    <div className={styles.page}>
      <div className={styles.caseHeader}>
        <div className={styles.caseHeader__title}>
          {isLoading ? <SkeletonRectangle width="375px" height="24px" color="primary20" /> : <h2>{displayTitle}</h2>}
        </div>
        <WrapperWithTooltip tooltip={individualCase.strategyId ? '' : tooltip} position="top">
          <div className={styles.caseHeader__button}>
            <Button kind="secondary" size="default" onClick={reprocessDecision} disabled={!individualCase.strategyId}>
              Re-Process Decision
            </Button>
          </div>
        </WrapperWithTooltip>
      </div>
      <div className={styles.caseInfo}>
        <div className={styles.caseUpdateInfo}>
          {isLoading ? (
            <>
              <SkeletonCircle width="24px" height="24px" color="primary10" />
              <SkeletonRectangle width="480px" height="16px" color="primary6" className={styles.createdDateSkeleton} />
            </>
          ) : (
            <>
              <UserAvatar
                firstName={user.creator.split(' ')[0]}
                lastName={user.creator.split(' ')[1]}
                size="tiny"
                tooltip
              />
              <p>
                Created <DateTime time={createdAt} format={DateTimeFormat.LongWithSeconds} /> using the strategy
                <Link
                  to={`/decision/strategies/${individualCase.strategyId}/overview`}
                  className={styles.headerLink}
                >{` ${strategyDisplayName}`}</Link>{' '}
                {application && (
                  <>
                    for the application{' '}
                    <Link to={`/los/applications/${application.displayId}`} className={styles.headerLink}>
                      {applicationName}
                    </Link>
                  </>
                )}
              </p>
            </>
          )}
        </div>
      </div>
      <div className={styles.caseContent}>
        <div className={styles.caseContent__header}>
          <h4 className={styles.title}>Processing Results</h4>
          <ButtonWithImage title="Download Results" onClick={handleDownloadResults} />
        </div>
        <div className={styles.moduleTable}>
          <div className={styles.moduleTable__header}>
            <p>Overall Result</p>
            {isLoading ? (
              <SkeletonRectangle width="120px" height="16px" color="primary6" />
            ) : (
              <div className={clsx(styles.moduleStatus, getStateColor(resultType))}>{resultType}</div>
            )}
          </div>
          <div className={clsx(styles.moduleTable__row, !isOpen && styles.moduleTable_openRow)}>
            <div className={styles.expandButton} onClick={handleChangeTableOpenState}>
              <p>{isOpen ? 'Hide Module Results' : 'Show Module Results'}</p>
              <button type="button" className={isOpen ? '' : styles.isOpen}>
                <VectorBottomImage />
              </button>
            </div>
          </div>
          <div className={styles.moduleList} ref={resultTable} style={isOpen ? { maxHeight: resultTableHeight } : {}}>
            <Table>
              <TableHead>
                <TableHeadCell width={SIZE}>Module</TableHeadCell>
                <TableHeadCell width={SIZE}>Result</TableHeadCell>
              </TableHead>
              <TableBody>{renderModuleResults()}</TableBody>
            </Table>
          </div>
        </div>
        <div className={styles.variableContainer}>
          <Table>
            <h4 className={styles.title}>Input Variables</h4>
            <TableHead>
              <TableHeadCell width={SIZE}>Variable</TableHeadCell>
              <TableHeadCell width={SIZE}>Value</TableHeadCell>
            </TableHead>
            <TableBody>{renderVariables('inputs')}</TableBody>
          </Table>
          <Table>
            <h4 className={styles.title}>Output Variables</h4>
            <TableHead>
              <TableHeadCell width={SIZE}>Variable</TableHeadCell>
              <TableHeadCell width={SIZE}>Value</TableHeadCell>
            </TableHead>
            <TableBody>
              {renderDeclineReasonOrMessage()}
              {renderVariables('outputs')}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ProcessingIndividualCase;
