import { AutoCompletionProps } from 'components/AutoCompletionMultiselect';
import { noop } from 'lodash';
import { ReactElement, useState } from 'react';
import { Option } from 'components/SelectInput/SelectInput';

const useAutoCompletionMultiSelectProps = <Item>(
  items: Item[],
  initialSelectedItems: Item[],
  getValue: (item: Item) => string,
  getName: (item: Item) => string,
  renderInputOptionPrefix?: (item: Item) => ReactElement,
  renderListOptionPrefix?: (item: Item) => ReactElement,
): [Omit<AutoCompletionProps, 'labelTitle' | 'id'>, Item[], (newItem: Item[]) => void] => {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);

  const getByValue = (value: string) => items.find((item) => getValue(item) === value)!;

  const props: Omit<AutoCompletionProps, 'labelTitle' | 'id'> = {
    options: items.map((item) => ({
      value: getValue(item),
      type: '',
      name: getName(item),
      icon: renderListOptionPrefix?.(item),
      color: ((item as unknown) as Option).color,
    })),
    selectedOptions: selectedItems.map((item) => getValue(item)),
    onInputChange: noop,
    onSelect: (addedItemValue) => {
      if (!selectedItems.find((selectedItem) => getValue(selectedItem) === addedItemValue)) {
        const addedItem = items.find((item) => getValue(item) === addedItemValue)!;

        setSelectedItems([...selectedItems, addedItem]);
      }
    },
    onDeleteOption: (deletedItemValue) => {
      setSelectedItems(selectedItems.filter((selectedItem) => getValue(selectedItem) !== deletedItemValue));
    },
    renderInputOptionPrefix: renderInputOptionPrefix
      ? (value: string) => renderInputOptionPrefix(getByValue(value))
      : undefined,
  };

  return [props, selectedItems, setSelectedItems];
};

export default useAutoCompletionMultiSelectProps;
