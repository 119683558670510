import Fetcher from 'middlewares/Fetcher';
import { IntermediaryVariableConfigurationsApi } from 'api/LoanOriginationSystem/IntermediaryVariableConfigurationsApi';
import { VariableConfiguration } from 'api/Types';
import {
  UpdateIntermediaryVariableConfigurationAction,
  updateIntermediaryVariableConfigurationSuccess,
  updateIntermediaryVariableConfigurationFailure,
} from './ActionCreator';
import { VariablesConfigurationActionType } from './ActionTypes';

const UpdateIntermediaryVariableConfigurationMiddleware = (api: IntermediaryVariableConfigurationsApi) =>
  Fetcher<VariableConfiguration, UpdateIntermediaryVariableConfigurationAction>(
    VariablesConfigurationActionType.UpdateIntermediaryVariableConfiguration,
    updateIntermediaryVariableConfigurationSuccess,
    updateIntermediaryVariableConfigurationFailure,
    (action) => {
      return api.update(action.payload.id, action.payload.params);
    },
  );

export default UpdateIntermediaryVariableConfigurationMiddleware;
