import React, { FC } from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import UserCard from 'components/UserCard';
import styles from './UserNotActive.module.scss';
import { useAuthProvider } from 'providers/AuthProvider';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';

const UserNotActive: FC = () => {
  const authProvider = useAuthProvider();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);

  const subTitle = (
    <>
      <div>Your account is not active at this time.</div>
      <div>Please contact your account administrator.</div>
    </>
  );

  return (
    <AuthLayout title="User Not Active" subTitle={subTitle}>
      <div className={styles.container}>
        <UserCard
          firstName={accountDetails!.firstName}
          lastName={accountDetails!.lastName}
          imageId={accountDetails!.imageId}
          onLogOutClick={() => authProvider.logout()}
          noMargin
        />
      </div>
    </AuthLayout>
  );
};

export default UserNotActive;
