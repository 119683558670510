import React, { FC } from 'react';

import PopUpWizard from 'components/PopUps/PopUpWizard';
import GenerateSecondNewSecretPassword from 'components/DigifiApi/PopUps/GenerateSecondNewSecretPassword';
import GenerateSecondNewSecret from 'components/DigifiApi/PopUps/GenerateSecondNewSecret';
import {
  WARNING_TEXT_GENERATE_SECRET,
  STEP_NUMBER_GENERATE_SECRET_POP_UP,
} from 'GenerateFirstSecretWizard/GenerateFirstSecretWizard';

interface GenerateSecondSecretWizardProps {
  step: number;
  closePopUp: () => void;
  title: string;
  verifyPassword: (password: string) => Promise<unknown>;
  generateSecondSecret: () => Promise<unknown>;
}

const GenerateSecondSecretWizard: FC<GenerateSecondSecretWizardProps> = (props) => {
  const { step, closePopUp, title, verifyPassword, generateSecondSecret } = props;

  return (
    <PopUpWizard
      step={step}
      closePopUp={closePopUp}
      title={title}
      warningText={step === STEP_NUMBER_GENERATE_SECRET_POP_UP ? WARNING_TEXT_GENERATE_SECRET : ''}
    >
      <GenerateSecondNewSecretPassword verifyPassword={verifyPassword} />
      <GenerateSecondNewSecret closePopUp={closePopUp} generateSecondSecret={generateSecondSecret} />
    </PopUpWizard>
  );
};

export default GenerateSecondSecretWizard;
