import React, { ReactNode, FC, forwardRef } from 'react';
import clsx from 'clsx';

import styles from './PopUpCore.module.scss';
import { CSSTransition } from 'react-transition-group';

interface PopUpProps {
  children: ReactNode;
  className?: string;
  warningText?: string;
}

const PopUpCore: FC<PopUpProps> = ({ children, className, warningText }, ref) => {
  return (
    <CSSTransition
      in
      appear
      timeout={Number(styles.animationTimeout)}
      classNames={{
        appear: styles.popupAppear,
        appearActive: styles.popupAppearActive,
        appearDone: styles.popupAppearDone,
      }}
    >
      <div className={clsx(styles.popupCore, className)}>
        <section className={clsx(styles.popupMain)} ref={ref}>
          {children}
          {warningText && (
            <p className={styles.warningText}>
              <span>Warning: </span>
              {warningText}
            </p>
          )}
        </section>
      </div>
    </CSSTransition>
  );
};

export default forwardRef(PopUpCore);
