import DefaultApplicationStatus from 'LoanOriginationSystemApplications/DefaultApplicationStatus';
import Button from 'components/Button';
import styles from './StatusDropdownButton.module.scss';
import { CheckedImage, DropdownImage, StopImage } from 'static/images';
import React, { forwardRef, Ref } from 'react';
import clsx from 'clsx';

interface StatusDropdownButtonProps {
  status: string;
  onClick: () => void;
}

const StatusDropdownButton = forwardRef(({ status, onClick }: StatusDropdownButtonProps, ref: Ref<HTMLDivElement>) => {
  switch (status) {
    case DefaultApplicationStatus.Approved:
      return (
        <div className={clsx(styles.statusButton, styles.approvedButton)}>
          <CheckedImage />
          <div>Approved</div>
        </div>
      );
    case DefaultApplicationStatus.Rejected:
      return (
        <div className={clsx(styles.statusButton, styles.rejectedButton)}>
          <StopImage />
          <div>Rejected</div>
        </div>
      );
    default:
      return (
        <div ref={ref}>
          <Button kind="primary" className={styles.titleButton} onClick={onClick}>
            Change Status
            <DropdownImage />
          </Button>
        </div>
      );
  }
});

export default StatusDropdownButton;
