import React from 'react';
import { NullableItems } from 'pagination';
import Table, { TableBodyContent } from 'components/Table';
import TableBody from 'components/Table/TableBody';
import SkeletonEmailLine from './SkeletonEmailAttachmentLine';
import EmailLine from './EmailAttachmentLine';
import { EmailAttachment } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';

interface EmailAttachmentsTableProps {
  attachments: NullableItems<EmailAttachment>;
}

const EmailAttachmentsTable = ({ attachments }: EmailAttachmentsTableProps) => {
  const renderRow = (attachment: EmailAttachment) => <EmailLine attachment={attachment} key={attachment.id} />;

  const renderSkeletonRow = (index: number) => {
    return <SkeletonEmailLine key={index} />;
  };

  return (
    <Table>
      <TableBody>
        <TableBodyContent rows={attachments} renderSkeletonTableRow={renderSkeletonRow} renderTableRow={renderRow} />
      </TableBody>
    </Table>
  );
};

export default EmailAttachmentsTable;
