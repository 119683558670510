import { AnyAction, Middleware } from 'redux';

import { ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS } from './ActionTypes';

import notification from 'handlers/notification/notificationActionCreator';
import { getOrganizationInfo } from 'CompanyInformation/CompanyInformationActionCreator';
import { RoutineHandler } from 'middlewares/Fetcher';

export const OnGenerateSecondSecretSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === ACTION_TYPE_GENERATE_SECOND_SECRET_REQUEST_SUCCESS) {
      notification.createNotification('New secret set successfully.', 'success', dispatch);
      dispatch(getOrganizationInfo());
    }
  },
);

export default OnGenerateSecondSecretSuccessMiddleware;
