import React, { FC } from 'react';
import styles from 'components/RulesTable/RulesTable.module.scss';
import TableRow from 'components/Table/TableRow';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableActionCell from 'components/Table/TableActionCell';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import clsx from 'clsx';

interface SkeletonRuleLineProps {
  moduleType: ModuleType;
  className?: string;
}

const SkeletonRuleLine: FC<SkeletonRuleLineProps> = ({ moduleType, className }) => {
  return (
    <TableRow disableHover className={clsx(styles.skeletonRow, className && className)}>
      {moduleType === 'assignments' || moduleType === 'calculations' ? (
        <>
          <TableBodyCell className={styles.skeletonOutputWide}>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableBodyCell className={styles.skeletonValueWide} noPadding>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableActionCell />
        </>
      ) : (
        <>
          <TableBodyCell className={styles.variable} noPadding>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableBodyCell className={styles.comparison}>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableBodyCell className={styles.value}>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableBodyCell className={styles.skeletonOutput}>
            <SkeletonRectangle width="80%" height="16px" color="primary6" />
          </TableBodyCell>
          <TableActionCell />
        </>
      )}
    </TableRow>
  );
};

export default SkeletonRuleLine;
