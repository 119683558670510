import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { History } from 'history';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { getBorrowers } from 'LoanOriginationSystemBorrowers/ActionCreator';
import { LoanOriginationSystemBorrowersActionTypes } from './ActionTypes';
import { DeleteBorrowerSuccessAction } from './ActionCreator';

const isDeleteBorrowerSuccessAction = (action: AnyAction): action is DeleteBorrowerSuccessAction =>
  action.type === LoanOriginationSystemBorrowersActionTypes.DeleteBorrowerSuccess;

const DeleteBorrowerSuccessMiddleware = ({ replace }: History): Middleware => ({
  dispatch,
  getState,
}: MiddlewareAPI<any>) => (next: (action: AnyAction) => any) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteBorrowerSuccessAction(action)) {
    replace(`/los/borrowers`);

    const { loanOriginationSystemBorrowers } = getState();

    const { filters, sortingType } = loanOriginationSystemBorrowers;

    notification.createNotification(
      getMessage(MessageType.BorrowerDeleted, {
        borrowerName: action.payload.name,
      }),
      'success',
      dispatch,
    );

    dispatch(getBorrowers(filters, sortingType));
  }

  return result;
};

export default DeleteBorrowerSuccessMiddleware;
