import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import { TableBodyCell, TableRow } from 'components/Table';
import TableColumnSize from 'components/LoanOriginationSystem/SendESignaturePopup/RecipientsTable/TableColumnSize';

const SkeletonRecipientsTableItem = () => (
  <TableRow disableHover>
    <TableBodyCell width={TableColumnSize.Recipient}>
      <SkeletonRectangle width="80%" height="12px" color="primary6" />
    </TableBodyCell>
    <TableBodyCell width={TableColumnSize.Name}>
      <SkeletonRectangle width="80%" height="12px" color="primary6" />
    </TableBodyCell>
    <TableBodyCell width={TableColumnSize.Email}>
      <SkeletonRectangle width="80%" height="12px" color="primary6" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonRecipientsTableItem;
