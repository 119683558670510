import React, { useRef, useState } from 'react';
import { BeforeCapture, DropResult } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { ApplicationTab, ApplicationTabType } from 'api/LoanOriginationSystem/ApplicationTabsApi';
import DndList from 'components/DndList';
import ButtonWithImage from 'components/ButtonWithImage';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import WrapperWithTooltip from 'components/Tooltip';
import TabListItem from './TabListItem';
import styles from './TabsList.module.scss';

export interface TabsListProps {
  selectedTabId: string | null;
  tabs: ApplicationTab[];
  onTabAdd: (type: ApplicationTabType) => void;
  onTabDelete: (tab: ApplicationTab) => void;
  onTabReorder: (tab: ApplicationTab, index: number) => void;
  onTabSelect: (tab: ApplicationTab) => void;
  displayBorder?: boolean;
}

export interface TabDropdownOption {
  name: string;
  type: ApplicationTabType;
  plural?: true;
}

const TABS_OPTIONS: TabDropdownOption[] = [
  { name: 'Data Tab', type: ApplicationTabType.DataTab },
  { name: 'Documents', type: ApplicationTabType.Documents, plural: true },
  { name: 'Emails', type: ApplicationTabType.Emails, plural: true },
  { name: 'Tasks', type: ApplicationTabType.Tasks, plural: true },
  { name: 'Notes', type: ApplicationTabType.Notes, plural: true },
  { name: 'Decision Engine', type: ApplicationTabType.DecisionEngine },
  { name: 'Status Rules', type: ApplicationTabType.StatusRules },
  { name: 'History', type: ApplicationTabType.History },
];

const TABS_DROPPABLE_ID = 'tabsList';
const TABS_MAX_COUNT = 20;

export const TabsList = ({
  selectedTabId,
  tabs,
  onTabReorder,
  onTabDelete,
  onTabAdd,
  onTabSelect,
  displayBorder = true,
}: TabsListProps) => {
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [addTabPopupOpen, setAddTabPopupOpen] = useState(false);
  const addTabButtonRef = useRef<HTMLButtonElement | null>(null);
  const tabsTypes = tabs.map((tab) => tab.type);

  const getDraggableId = (itemId: string | number) => itemId as string;

  const openAddTabPopup = () => setAddTabPopupOpen(true);
  const closeAddTabPopup = () => setAddTabPopupOpen(false);
  const handleAddTab = (type: ApplicationTabType) => {
    onTabAdd(type);
    closeAddTabPopup();
  };

  const handleBeforeCapture = (event: BeforeCapture) => {
    setDraggingId(event.draggableId);
  };

  const handleDragEnd = () => setDraggingId(null);

  const handleReorder = (updatedItems: ApplicationTab[], result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    onTabReorder(tabs[source.index], destination.index);
  };

  const handleTabDelete = (id: string) => {
    const tabToDelete = tabs.find((tab) => tab.id === id);

    if (!tabToDelete) {
      return;
    }

    onTabDelete(tabToDelete);
  };

  const renderTabDropdownOption = ({ name, type, plural }: TabDropdownOption) => {
    const disabled = type !== ApplicationTabType.DataTab && tabsTypes.includes(type);

    return (
      <WrapperWithTooltip key={type} tooltip={disabled ? `${name} ${plural ? 'are' : 'is'} already activated` : ''}>
        <span>
          <ActionPopUpItem
            className={clsx(disabled && styles.disabledAddTabButton)}
            key={type}
            disabled={disabled}
            onClick={() => handleAddTab(type)}
          >
            {name}
          </ActionPopUpItem>
        </span>
      </WrapperWithTooltip>
    );
  };

  const renderTabListItem = (item: ApplicationTab) => (
    <TabListItem
      selected={selectedTabId === item.id}
      isDragging={getDraggableId(item.id) === draggingId}
      id={item.id}
      name={item.name}
      onSelect={(tabId) => onTabSelect(tabs.find((tab) => tab.id === tabId)!)}
      onDelete={handleTabDelete}
    />
  );

  return (
    <div className={styles.container}>
      <DndList
        direction="horizontal"
        withPlaceholder
        items={tabs}
        handleReorder={handleReorder}
        handleBeforeCapture={handleBeforeCapture}
        handleDragEnd={handleDragEnd}
        getDraggableId={getDraggableId}
        listItemClassName={styles.tabListItem}
        listClassName={styles.tabsList}
        droppableId={TABS_DROPPABLE_ID}
        renderListItem={renderTabListItem}
      />
      {tabs.length < TABS_MAX_COUNT && (
        <ButtonWithImage
          ref={addTabButtonRef}
          className={styles.addTabButton}
          title="Add Tab"
          kind="add"
          onClick={openAddTabPopup}
        />
      )}
      <ContextualPopUp anchorEl={addTabButtonRef.current} open={addTabPopupOpen} onClose={closeAddTabPopup}>
        <div className={styles.actionsContainer}>{TABS_OPTIONS.map(renderTabDropdownOption)}</div>
      </ContextualPopUp>
      {displayBorder && <div className={styles.border} />}
    </div>
  );
};

export default TabsList;
