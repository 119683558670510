import React, { ChangeEvent, PropsWithChildren } from 'react';
import { CloseImage } from 'static/images';
import SearchInput from 'components/SearchInput';
import Button from 'components/Button';
import styles from './VariablesPaneLayout.module.scss';

interface VariablesPaneLayoutProps {
  onClose: () => void;
  onSearch: (search: string) => void;
  onCreateNewVariable: () => void;
  search: string;
}

const VariablesPaneLayout = ({
  children,
  search,
  onCreateNewVariable,
  onClose,
  onSearch,
}: PropsWithChildren<VariablesPaneLayoutProps>) => {
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    onSearch(event.target.value);
  };

  const handleClearSearch = () => {
    onSearch('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>Custom Variables</h3>
        <button type="button" className={styles.closeButton} onClick={onClose}>
          <CloseImage />
        </button>
      </div>
      <div className={styles.subtitle}>
        <SearchInput
          placeholder="Search"
          containerClassName={styles.searchInput}
          value={search}
          onChange={handleSearch}
          onClear={handleClearSearch}
        />
      </div>
      {children}
      <div className={styles.actionsContainer}>
        <Button fullWidth onClick={onCreateNewVariable} size="form">
          Create New Variable
        </Button>
      </div>
    </div>
  );
};

export default VariablesPaneLayout;
