import { useEffect } from 'react';

const useFontLoaded = (callback: () => void) => {
  useEffect(() => {
    let mounted = true;

    (document as any).fonts.ready.then(() => {
      if (mounted) {
        callback();
      }
    });

    return () => {
      mounted = false;
    };
  }, []);
};

export default useFontLoaded;
