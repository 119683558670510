import React, { ReactNode } from 'react';

import styles from './Container.module.scss';
import clsx from 'clsx';

interface AddRuleDialogContainerProps {
  children: ReactNode;
  hasTopMargin?: boolean;
}

const AddRuleDialogContainer = ({ children, hasTopMargin = false }: AddRuleDialogContainerProps) => {
  const className = clsx(styles.container, hasTopMargin && styles.containerHasTopMargin);
  return <div className={className}>{children}</div>;
};

export default AddRuleDialogContainer;
