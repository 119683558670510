import React from 'react';
import { ApplicationsTableSizes } from 'components/LoanOriginationSystem/ApplicationsDashboard/Body/ApplicationsTable/TableColumnSizes';
import TableRow from 'components/Table/TableRow';
import TableBodyCell from 'components/Table/TableBodyCell';
import SkeletonRectangle from 'components/Skeleton/SkeletonRectangle';
import SkeletonCirclesList from 'components/Skeleton/SkeletonCirclesList';
import styles from './SkeletonApplicationTableBody.module.scss';

const SkeletonApplicationTableBody = () => (
  <TableRow disableHover>
    <TableBodyCell width={ApplicationsTableSizes.BorrowerFullName}>
      <SkeletonRectangle color="primary6" width="80%" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.DisplayId}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.CreatedAt}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.Status}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.BorrowerPhoneNumber}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.TeamMembers} noPadding>
      <SkeletonCirclesList color="primary6" width="28px" height="28px" />
    </TableBodyCell>
    <TableBodyCell width={ApplicationsTableSizes.LoanAmount} className={styles.flexEndJustify}>
      <SkeletonRectangle width="80%" color="primary6" height="16px" />
    </TableBodyCell>
  </TableRow>
);

export default SkeletonApplicationTableBody;
