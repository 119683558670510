import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonInfoPanel.module.scss';

const SkeletonInfoPanel = () => {
  const renderRow = (index: number) => (
      <div className={styles.infoPanelRow} key={index}>
        <SkeletonRectangle width="90px" height="12px" color="primary10" />
        <SkeletonRectangle width="180px" height="12px" color="primary10" />
      </div>
  );
  
  const renderColumn = (index: number) => (
    <div className={styles.infoPanelColumn} key={index}>
      <SkeletonRectangle width="220px" height="12px" color="primary20" />
      {new Array(2).fill(null).map((_item, i) => renderRow(i))}
    </div>
  );

  return (
    <div className={styles.infoPanel}>
      <div className={styles.infoPanelHeader}>
        <SkeletonRectangle width="400px" height="16px" color="primary20" />
      </div>
      <div className={styles.infoPanelContent}>
        {new Array(3).fill(null).map((_item, index) => renderColumn(index))}
      </div>
    </div>
  );
};

export default SkeletonInfoPanel;
