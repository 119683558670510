import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { CreateProductAction, createProductFailure, createProductSuccess } from './ActionCreator';

const CreateProductMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product, CreateProductAction>(
    LoanOriginationSystemProductsActionType.CreateProduct,
    createProductSuccess,
    createProductFailure,
    (action) => {
      return api.create(action.payload);
    },
  );

export default CreateProductMiddleware;
