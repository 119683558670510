import { ApplicationStatusRulesValidationResult } from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import { LoanOriginationSystemCreateApplicationState } from 'LoanOriginationSystemApplications/CreateApplication/Types';
import { LoanOriginationSystemApplicationsFiltersState } from 'LoanOriginationSystemApplications/Filters/Types';
import { ItemsStateWithPagination } from 'pagination';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { ApplicationFormPage, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ApplicationUpdatingState } from './ActionCreator';
import { ApplicationsSortingType } from 'api/Types';

export interface ApplicationStatusRulesValidationData {
  application: Application;
  status: ApplicationStatus;
  statusRulesValidationResult: ApplicationStatusRulesValidationResult;
}

export interface CreateApplicationProductData {
  product: Product;
  additionalFormPages: ApplicationFormPage[];
}

export interface LoanOriginationSystemApplicationsState {
  productsList: Product[] | null;
  selectedProduct: Product | null;

  isLoading: boolean;

  currencySymbol: string;

  columnViewData: Application[] | null;
  tableViewData: ItemsStateWithPagination<Application> | null;

  tableViewSortingType: ApplicationsSortingType;
  columnViewSortingType: ApplicationsSortingType;

  filters: LoanOriginationSystemApplicationsFiltersState;
  createApplication: LoanOriginationSystemCreateApplicationState;

  applicationIdToDelete: string | null;
  applicationToDuplicate: Application | null;

  applicationStatusRulesValidationData: ApplicationStatusRulesValidationData | null;
  applicationUpdatingStatesById: Record<string, ApplicationUpdatingState | null>;
  isCreateNewApplicationPopupOpen: boolean;

  createApplicationProductData: CreateApplicationProductData | null;
}

export enum ViewType {
  Column = 'column',
  Table = 'table',
  Tile = 'tile',
}
