import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import ApplicationDefaultVariable from 'enums/ApplicationDefaultVariable';
import { getBorrowerFullName } from 'LoanOriginationSystemBorrowers/utils';
import { Application } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import {
  ESignTemplate,
  ESignTemplateRecipient,
  ESignTemplateRecipientEntityType,
  ESignTemplateRecipientMappingEntity,
} from 'api/Core/ESignTemplatesApi';
import { ESignIntegrationType } from 'api/LoanOriginationSystem/Types';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import AutoCompletion from 'components/AutoCompletion';
import Button from 'components/Button';
import { Option } from 'components/SelectInput/SelectInput';
import { getESignTemplate } from 'ESignTemplates/ActionCreator';
import { getESignTemplate as getESignTemplateSelector } from 'ESignTemplates/Selectors';
import { useVariables } from 'hooks/useVariableDataType';
import FieldsTable from './FieldsTable';
import RecipientsTable from './RecipientsTable';
import { isEmptyVariableValue } from 'utils/isEmptyVariableValue';
import { RecipientTableItem } from './RecipientsTable/RecipientTableRow/RecipientTableRow';
import { FieldTableItem } from './FieldsTable/FieldTableRow/FieldTableRow';
import styles from './SendESignaturePopup.module.scss';

interface SendESignaturePopupProps {
  type: ESignIntegrationType;
  application: Application;
  eSignatureName: string;
  onGetTemplates: (search: string, type: ESignIntegrationType, abortSignal: AbortSignal) => Promise<ESignTemplate[]>;
  onSendESign: (templateId: string) => void;
  onPopupClose: () => void;
  isSendingInProgress?: boolean;
}

const getRecipientsErrors = (recipientItems: RecipientTableItem[]) => {
  return recipientItems.reduce((errors: string[], recipientItem) => {
    const newErrors = [];

    if (isEmptyVariableValue(recipientItem.values.name)) {
      newErrors.push(`Recipient ${recipientItem.name} Name`);
    }

    if (isEmptyVariableValue(recipientItem.values.email)) {
      newErrors.push(`Recipient ${recipientItem.name} Email`);
    }

    return [...errors, ...newErrors];
  }, []);
};

const getFieldsErrors = (fieldItems: FieldTableItem[]) => {
  return fieldItems.reduce(
    (errors: string[], field) =>
      isEmptyVariableValue(field.value) && field.required ? [...errors, field.name] : errors,
    [],
  );
};

const SendESignaturePopup: FC<SendESignaturePopupProps> = ({
  onPopupClose,
  onGetTemplates,
  type,
  application,
  onSendESign,
  isSendingInProgress,
  eSignatureName,
}) => {
  const dispatch = useDispatch();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>();
  const selectedTemplate = useSelector((state: ReduxState) =>
    getESignTemplateSelector(state, selectedTemplateId || null),
  );

  const recipientsVariableIds = Object.values(selectedTemplate?.recipientsMapping || {}).reduce(
    (ids, mappingEntity) => {
      return [...ids, mappingEntity.email, mappingEntity.name];
    },
    [] as Array<string | undefined>,
  );
  const fieldsVariableIds = Object.values(selectedTemplate?.variablesMapping || {});

  const { isLoading, getVariable } = useVariables(...recipientsVariableIds, ...fieldsVariableIds);

  useEffect(() => {
    if (selectedTemplateId) {
      dispatch(getESignTemplate(selectedTemplateId));
    }
  }, [selectedTemplateId]);

  const handleFetchOptions = async (search: string, abortSignal: AbortSignal): Promise<Option[]> => {
    const templates = await onGetTemplates(search, type, abortSignal);

    return templates.map((template) => ({
      name: template.name,
      value: template.id,
    }));
  };

  const getRecipientValues = (
    recipient: ESignTemplateRecipient,
    mappingEntity: ESignTemplateRecipientMappingEntity,
  ) => {
    switch (mappingEntity.type) {
      case ESignTemplateRecipientEntityType.Other: {
        const nameVariable = recipient.recipientName ? null : getVariable(mappingEntity.name);
        const emailVariable = recipient.recipientEmail ? null : getVariable(mappingEntity.email);

        const nameByVariable = nameVariable ? application.variables[nameVariable.systemName] : null;
        const emailByVariable = emailVariable ? application.variables[emailVariable.systemName] : null;

        return {
          name: recipient.recipientName ?? nameByVariable,
          email: recipient.recipientEmail ?? emailByVariable,
        };
      }
      case ESignTemplateRecipientEntityType.Borrower: {
        const borrowerFullName = getBorrowerFullName(application.borrowerType, application.variables);

        return {
          name: borrowerFullName,
          email: application.variables[ApplicationDefaultVariable.Borrower.Email],
        };
      }
      case ESignTemplateRecipientEntityType.CoBorrower: {
        const coBorrowerFullName = application.coborrowerType
          ? getBorrowerFullName(
              application.coborrowerType,
              application.variables,
              ApplicationDefaultVariable.Coborrower,
            )
          : null;

        return {
          name: coBorrowerFullName,
          email: application.variables[ApplicationDefaultVariable.Coborrower.Email],
        };
      }
      case ESignTemplateRecipientEntityType.Intermediary: {
        return {
          name: application.variables[ApplicationDefaultVariable.Intermediary.Name],
          email: application.variables[ApplicationDefaultVariable.Intermediary.Email],
        };
      }
      default: {
        return null as never;
      }
    }
  };

  const getRecipientTableItems = (template: ESignTemplate) => {
    if (isLoading) {
      return null;
    }

    return template.recipients.map((recipient) => {
      const mappingEntry = template.recipientsMapping[recipient.recipientId];

      return {
        ...recipient,
        values: mappingEntry ? getRecipientValues(recipient, mappingEntry) : { name: null, email: null },
      };
    });
  };

  const getFieldsTableItems = (template: ESignTemplate) => {
    if (isLoading) {
      return null;
    }

    return template.fields.map((field) => {
      const variable = getVariable(template.variablesMapping[field.fieldId]);

      return {
        ...field,
        value: variable ? application.variables[variable.systemName] : null,
      };
    });
  };

  const recipientItems = selectedTemplate ? getRecipientTableItems(selectedTemplate) : null;
  const fieldItems = selectedTemplate ? getFieldsTableItems(selectedTemplate) : null;

  const recipientsErrors = recipientItems ? getRecipientsErrors(recipientItems) : [];
  const fieldsErrors = fieldItems ? getFieldsErrors(fieldItems) : [];

  const errors = [...recipientsErrors, ...fieldsErrors];

  const renderContent = () => {
    if (!selectedTemplateId) {
      return null;
    }

    return (
      <div>
        <RecipientsTable items={recipientItems} />
        <FieldsTable items={fieldItems} />
      </div>
    );
  };

  const renderErrors = () => {
    if (!errors.length) {
      return null;
    }

    return (
      <div className={styles.bottomError}>
        This cannot be send for e-sign because it is missing the following required variables: {errors.join(', ')}
      </div>
    );
  };

  const handleSendESignClick = () => {
    if (!selectedTemplateId) {
      return;
    }

    onSendESign(selectedTemplateId);
  };

  return (
    <PopUp title="Send Documents for Electronic Signature" closePopUp={onPopupClose}>
      <PopUpContent hasTopMargin className={styles.popupContent}>
        <div className={styles.innerContainer}>
          <AutoCompletion
            value={selectedTemplateId}
            labelTitle={`Select ${eSignatureName} Template`}
            onChange={(option) => setSelectedTemplateId(option.value)}
            fetchOptions={handleFetchOptions}
            loading={Boolean(selectedTemplateId) && !selectedTemplate}
          />
          {renderContent()}
          <Button
            isLoading={isSendingInProgress}
            className={styles.sendESignButton}
            disabled={!selectedTemplate || !!errors.length}
            onClick={handleSendESignClick}
            kind={selectedTemplate ? 'primary' : 'secondary'}
            size="form"
          >
            {selectedTemplate ? 'Send for E-Sign' : 'Continue'}
          </Button>
        </div>
        {renderErrors()}
      </PopUpContent>
    </PopUp>
  );
};

export default SendESignaturePopup;
