import React from 'react';
import TabMenu from 'components/TabMenu';
import styles from './ProcessAutomationTab.module.scss';
import AutomatedProcessCard from 'components/LoanOriginationSystem/ProcessAutomationDashboard/AutomatedProcessCard';
import {
  AutomatedProcessCategory,
  AutomatedProcess,
  AutomatedProcessGroup,
} from 'api/LoanOriginationSystem/ProcessAutomationApi';
import { Label } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import SkeletonAutomatedProcess from 'components/LoanOriginationSystem/ProcessAutomationDashboard/SkeletonAutomatedProcess';
import { SendGridTemplate } from 'SendGrid/Types';
import { NullableItems, PaginationProps } from 'pagination';
import Pagination from 'components/Pagination';
import { selectCategory } from 'ProcessAutomation/ActionCreator';
import { useDispatch } from 'react-redux';
import NoItems from 'components/NoItems';
import { ShareImage } from 'static/images';

enum AutomatedProcessCategorySwitchOption {
  All = 'All',
  Activities = 'Activities',
  Emails = 'Emails',
  Labels = 'Labels',
}

const switchOptions = [
  {
    label: AutomatedProcessCategorySwitchOption.All,
    value: AutomatedProcessCategorySwitchOption.All,
  },
  {
    label: AutomatedProcessCategorySwitchOption.Activities,
    value: AutomatedProcessCategorySwitchOption.Activities,
  },
  {
    label: AutomatedProcessCategorySwitchOption.Emails,
    value: AutomatedProcessCategorySwitchOption.Emails,
  },
  {
    label: AutomatedProcessCategorySwitchOption.Labels,
    value: AutomatedProcessCategorySwitchOption.Labels,
  },
];

interface StandardAutomatedProcessProps {
  automatedProcesses: NullableItems<AutomatedProcess>;
  onActivationStatusChange: (id: string, status: boolean) => void;
  onRuntimeOptionsChange: (id: string, runtimeOptionsUpdate: Partial<AutomatedProcess['runtimeOptions']>) => void;
  labels: Label[] | null;
  emailTemplates: SendGridTemplate[] | null;
  paginationProps: PaginationProps;
  selectedGroup: AutomatedProcessGroup;
  selectedCategory?: AutomatedProcessCategory;
}

const handleContactDigifi = () => {
  window.open('https://www.digifi.io/contact-support', '_blank');
};

const ProcessAutomationTab = ({
  automatedProcesses,
  onActivationStatusChange,
  onRuntimeOptionsChange,
  labels,
  emailTemplates,
  paginationProps,
  selectedGroup,
  selectedCategory,
}: StandardAutomatedProcessProps) => {
  const dispatch = useDispatch();

  const optionToCategory = (option: AutomatedProcessCategorySwitchOption) => {
    switch (option) {
      case AutomatedProcessCategorySwitchOption.Activities:
        return AutomatedProcessCategory.Activities;
      case AutomatedProcessCategorySwitchOption.Emails:
        return AutomatedProcessCategory.Emails;
      case AutomatedProcessCategorySwitchOption.Labels:
        return AutomatedProcessCategory.Labels;
      default:
        return undefined;
    }
  };

  const categoryToOption = (category?: AutomatedProcessCategory) => {
    switch (category) {
      case AutomatedProcessCategory.Activities:
        return AutomatedProcessCategorySwitchOption.Activities;
      case AutomatedProcessCategory.Emails:
        return AutomatedProcessCategorySwitchOption.Emails;
      case AutomatedProcessCategory.Labels:
        return AutomatedProcessCategorySwitchOption.Labels;
      default:
        return AutomatedProcessCategorySwitchOption.All;
    }
  };

  const handleCategoryChange = (option: AutomatedProcessCategorySwitchOption) => {
    dispatch(selectCategory(optionToCategory(option)));
  };

  const renderProcess = (automatedProcess: AutomatedProcess | null, index: number) => {
    if (!automatedProcess) {
      return <SkeletonAutomatedProcess key={index} />;
    }

    return (
      <AutomatedProcessCard
        emailTemplates={emailTemplates!}
        labels={labels!}
        key={automatedProcess.id}
        automatedProcess={automatedProcess}
        onActivationStatusChange={onActivationStatusChange}
        onRuntimeOptionsChange={onRuntimeOptionsChange}
      />
    );
  };

  const renderSkeleton = () => {
    return new Array(paginationProps.itemsPerPage)
      .fill(null)
      .map((_, index) => <SkeletonAutomatedProcess key={index} />);
  };

  const renderContent = () => {
    if (!automatedProcesses || !emailTemplates || !labels) {
      return <div className={styles.container}>{renderSkeleton()}</div>;
    }

    if (!automatedProcesses.length) {
      return (
        <NoItems
          className={styles.noItems}
          title="You have no custom process automation yet!"
          titleClassName={styles.noItemsTitle}
          subtitle="Please contact DigiFi to discuss how custom process automation can be added for your company."
          subtitleClassName={styles.noItemsSubtitle}
          buttonMessage="Contact DigiFi"
          buttonClassName={styles.noItemsButton}
          buttonImage={<ShareImage />}
          buttonImageClassName={styles.buttonImage}
          onButtonClick={handleContactDigifi}
        />
      );
    }

    return <div className={styles.container}>{automatedProcesses.map(renderProcess)}</div>;
  };

  const shouldDisplayPagination =
    !!paginationProps.itemsTotal && paginationProps.itemsTotal > paginationProps.itemsPerPage;

  return (
    <div>
      {selectedGroup === AutomatedProcessGroup.Standard && (
        <div className={styles.tabMenu}>
          <TabMenu
            options={switchOptions}
            value={categoryToOption(selectedCategory)}
            onChange={(option) => handleCategoryChange(option as AutomatedProcessCategorySwitchOption)}
          />
        </div>
      )}
      {renderContent()}
      {shouldDisplayPagination && <Pagination {...paginationProps} />}
    </div>
  );
};

export default ProcessAutomationTab;
