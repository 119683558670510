import { InvoicesSortingField } from 'api/BillingApi';
import { FailedAction } from 'utils/actions/FailedActionCreator';
import {
  GetApplicationsCountSuccessAction,
  GetBillingInfoAction,
  GetBillingInfoSuccessAction,
  GetDecisionsCountSuccessAction,
  GetInvoicesAction,
  GetInvoicesSuccessAction,
} from './ActionCreator';
import BillingPageActionType from './ActionTypes';
import BillingPageState from './Types';

const initialState: BillingPageState = {
  isLoading: false,
  infoData: null,
  invoices: null,
  applicationsCount: null,
  decisionsCount: null,
  invoicesSortingType: {
    ascending: false,
    field: InvoicesSortingField.InvoiceDate,
  },
};

type BillingPageActions =
  | GetBillingInfoAction
  | GetBillingInfoSuccessAction
  | FailedAction<BillingPageActionType.GetBillingInfoFailed>
  | GetInvoicesAction
  | GetInvoicesSuccessAction
  | GetApplicationsCountSuccessAction
  | GetDecisionsCountSuccessAction;

const billingPageReducer = (state = initialState, action: BillingPageActions): BillingPageState => {
  switch (action.type) {
    case BillingPageActionType.GetBillingInfo:
      return {
        ...state,
        isLoading: true,
      };
    case BillingPageActionType.GetBillingInfoSuccess:
      return {
        ...state,
        infoData: action.payload.infoData,
        isLoading: false,
      };
    case BillingPageActionType.GetBillingInfoFailed:
      return {
        ...state,
        isLoading: false,
      };
    case BillingPageActionType.GetInvoicesSuccess:
      return {
        ...state,
        invoices: action.payload.invoices,
      };
    case BillingPageActionType.GetApplicationsCountSuccess:
      return {
        ...state,
        applicationsCount: action.payload.applicationsCount,
      };
    case BillingPageActionType.GetDecisionsCountSuccess:
      return {
        ...state,
        decisionsCount: action.payload.decisionsCount,
      };
    case BillingPageActionType.GetInvoices:
      return {
        ...state,
        invoicesSortingType: action.payload.sortingType,
      };
    default:
      return state;
  }
};

export default billingPageReducer;
