import React, { FC } from 'react';
import Footer from 'components/Footer';
import MainLayout from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageFooter from 'MainLayout/PageFooter';
import PageContent from 'MainLayout/PageContent';
import PageWrapper from 'MainLayout/PageWrapper';
import { ApplicationSectionName } from 'components/RouteWithPermissions/Types';
import RestrictPermissions from 'components/RestrictPermissions';

interface RestrictPermissionsPageProps {
  pageName: string;
  menuEntry?: ApplicationSectionName;
}

const RestrictPermissionsPage: FC<RestrictPermissionsPageProps> = ({ pageName, menuEntry }) => {
  const leftNav = makeLeftNavigation(pageName, menuEntry);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent noPadding>
          <RestrictPermissions pageName={pageName} />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default RestrictPermissionsPage;
