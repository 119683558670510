export enum LoanOriginationSystemLabelsActionTypes {
  GetLabels = 'loanOriginationSystemLabels/getLabels',
  GetLabelsSuccess = 'loanOriginationSystemLabels/getLabelsSuccess',
  GetLabelInfo = 'loanOriginationSystemLabels/getLabelInfo',
  GetLabelInfoSuccess = 'loanOriginationSystemLabels/getLabelInfoSuccess',
  GetLabelInfoFailure = 'loanOriginationSystemLabels/getLabelInfoFailure',
  GetLabelsFailure = 'loanOriginationSystemLabels/getLabelsFailure',
  SortLabels = 'loanOriginationSystemLabels/sortLabels',
  SetLabelToDeleteId = 'loanOriginationSystemLabels/setLabelToDeleteId',
  CloseDeleteLabelPopUp = 'loanOriginationSystemLabels/closeDeleteLabelPopUp',
  DeleteLabel = 'loanOriginationSystemLabels/deleteLabel',
  DeleteLabelSuccess = 'loanOriginationSystemLabels/deleteLabelSuccess',
  DeleteLabelFailure = 'loanOriginationSystemLabels/deleteLabelFailure',
  OpenCreateLabelPopUp = 'loanOriginationSystemLabels/openCreateLabelPopUp',
  CloseCreateLabelPopUp = 'loanOriginationSystemLabels/closeCreateLabelPopUp',
  CreateLabel = 'loanOriginationSystemLabels/createLabel',
  CreateLabelSuccess = 'loanOriginationSystemLabels/createLabelSuccess',
  CreateLabelFailure = 'loanOriginationSystemLabels/createLabelFailure',
  OpenUpdateLabelPopUp = 'loanOriginationSystemLabels/openUpdateLabelPopUp',
  CloseUpdateLabelPopUp = 'loanOriginationSystemLabels/closeUpdateLabelPopUp',
  UpdateLabel = 'loanOriginationSystemLabels/updateLabel',
  UpdateLabelSuccess = 'loanOriginationSystemLabels/updateLabelSuccess',
  UpdateLabelFailure = 'loanOriginationSystemLabels/updateLabelFailure',
  ResetState = 'loanOriginationSystemLabels/resetState',
}
