import { AnyAction } from 'redux';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { NormalizedApplicationDataTabCard } from 'api/LoanOriginationSystem/ApplicationDataTabCardsApi';
import { ApplicationDataTabVariableConfiguration } from 'api/LoanOriginationSystem/ApplicationDataTabVariableConfigurationsApi';
import { ApplicationDataTabCardsActionType } from './ActionTypes';

export interface GetApplicationDataTabCardsResult {
  tabId: string;
  cards: Record<string, NormalizedApplicationDataTabCard>;
  applicationVariableConfigurations: Record<string, ApplicationDataTabVariableConfiguration> | undefined;
  cardIds: string[];
}

export interface UpdateApplicationDataTabCardResult {
  cards: Record<string, NormalizedApplicationDataTabCard>;
  applicationVariableConfigurations: Record<string, ApplicationDataTabVariableConfiguration>;
}

export interface GetApplicationDataTabCardsAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.GetApplicationDataTabCards;
  payload: {
    tabId: string;
  };
}

export interface GetApplicationDataTabCardsSuccessAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.GetApplicationDataTabCardsSuccess;
  payload: GetApplicationDataTabCardsResult;
}

export interface CreateApplicationDataTabCardAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.CreateApplicationDataTabCard;
  payload: {
    tabId: string;
    position: number;
    row: number;
    name: string;
  };
}

export interface CreateApplicationDataTabCardSuccessAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.CreateApplicationDataTabCardSuccess;
  payload: GetApplicationDataTabCardsResult;
}

export interface UpdateApplicationDataTabCardAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.UpdateApplicationDataTabCard;
  payload: {
    cardId: string;
    name: string;
  };
}

export interface UpdateApplicationDataTabCardSuccessAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardSuccess;
  payload: UpdateApplicationDataTabCardResult;
}

export interface DeleteApplicationDataTabCardAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.DeleteApplicationDataTabCard;
  payload: {
    cardId: string;
  };
}

export interface DeleteApplicationDataTabCardSuccessAction extends AnyAction {
  type: ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardSuccess;
  payload: {
    deletedCardId: string;
  };
}

export const getApplicationDataTabCards = (tabId: string): GetApplicationDataTabCardsAction => ({
  type: ApplicationDataTabCardsActionType.GetApplicationDataTabCards,
  payload: {
    tabId,
  },
});

export const getApplicationDataTabCardsSuccess = (
  result: GetApplicationDataTabCardsResult,
): GetApplicationDataTabCardsSuccessAction => ({
  type: ApplicationDataTabCardsActionType.GetApplicationDataTabCardsSuccess,
  payload: result,
});

export const createApplicationDataTabCard = (
  name: string,
  tabId: string,
  position: number,
  row: number,
): CreateApplicationDataTabCardAction => ({
  type: ApplicationDataTabCardsActionType.CreateApplicationDataTabCard,
  payload: {
    name,
    tabId,
    position,
    row,
  },
});

export const createApplicationDataTabCardSuccess = (
  result: GetApplicationDataTabCardsResult,
): CreateApplicationDataTabCardSuccessAction => ({
  type: ApplicationDataTabCardsActionType.CreateApplicationDataTabCardSuccess,
  payload: result,
});

export const updateApplicationDataTabCard = (cardId: string, name: string): UpdateApplicationDataTabCardAction => ({
  type: ApplicationDataTabCardsActionType.UpdateApplicationDataTabCard,
  payload: {
    cardId,
    name,
  },
});

export const updateApplicationDataTabCardSuccess = (
  result: UpdateApplicationDataTabCardResult,
): UpdateApplicationDataTabCardSuccessAction => ({
  type: ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardSuccess,
  payload: result,
});

export const deleteApplicationDataTabCard = (cardId: string): DeleteApplicationDataTabCardAction => ({
  type: ApplicationDataTabCardsActionType.DeleteApplicationDataTabCard,
  payload: {
    cardId,
  },
});

export const deleteApplicationDataTabCardSuccess = (cardId: string): DeleteApplicationDataTabCardSuccessAction => ({
  type: ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardSuccess,
  payload: {
    deletedCardId: cardId,
  },
});

export const getApplicationDataTabCardsFailure = FailedActionCreator(
  ApplicationDataTabCardsActionType.GetApplicationDataTabCardsFailure,
);
export const createApplicationDataTabCardFailure = FailedActionCreator(
  ApplicationDataTabCardsActionType.CreateApplicationDataTabCardFailure,
);
export const updateApplicationDataTabCardFailure = FailedActionCreator(
  ApplicationDataTabCardsActionType.UpdateApplicationDataTabCardFailure,
);
export const deleteApplicationDataTabCardFailure = FailedActionCreator(
  ApplicationDataTabCardsActionType.DeleteApplicationDataTabCardFailure,
);
