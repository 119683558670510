import React, { FC, useState } from 'react';
import { EditUserParams } from 'EditCompanyUser/EditCompanyUserTypes';
import SIZES from 'components/CompanyInformationUsers/TableColumnSizes';
import styles from './UserLine.module.scss';
import RowActions, { RowActionsContainer } from 'components/RowActions';
import TableBodyCell from 'components/Table/TableBodyCell';
import TableRow from 'components/Table/TableRow';
import clsx from 'clsx';
import ImageFetcher from 'components/ImageFetcher/ImageFetcher';
import { useDispatchRoutineWithResult } from 'middlewares/Fetcher';
import { getOrganizationSeats } from 'CompanyInformation/CompanyInformationActionCreator';
import SeatsAttribute from 'CompanyInformation/SeatsAttribute';
import { SeatsData } from 'api/Core/OrganizationApi';
import { OrganizationUser } from 'CompanyInformation/CompanyInformationTypes';

interface UserLineProps {
  user: OrganizationUser;
  openEditUserContextualPopUp: (userId: string) => void;
  updateUser: (userInfo: EditUserParams) => void;
  isSelected: boolean;
  isCurrentUser: boolean;
  handleOpenSeatsAreFullPopUp: () => void;
}

const userActionLabels = {
  activateAccount: 'Activate Account',
  deactivateAccount: 'Deactivate Account',
};

const UserLine: FC<UserLineProps> = ({
  user,
  openEditUserContextualPopUp,
  updateUser,
  isSelected,
  handleOpenSeatsAreFullPopUp,
  isCurrentUser,
}) => {
  const dispatchRoutine = useDispatchRoutineWithResult();
  const { firstName, lastName, email, isMfaEnabled, isEmailVerified, isActive, role, id, imageId } = user;

  const [isTogglingStatusLoading, setIsTogglingStatusLoading] = useState(false);

  const toggleActive = async () => {
    if (!isActive) {
      setIsTogglingStatusLoading(true);
      const seatsData = await dispatchRoutine(getOrganizationSeats());
      const { occupiedSeats, purchasedSeats } = seatsData[SeatsAttribute.Seats] as SeatsData;
      setIsTogglingStatusLoading(false);

      if (occupiedSeats >= purchasedSeats) {
        handleOpenSeatsAreFullPopUp();
        return;
      }
    }

    updateUser({
      isActive: !isActive,
      userId: id,
      role,
    });
  };

  const handleEditUser = () => {
    openEditUserContextualPopUp(id);
  };

  return (
    <RowActionsContainer>
      <div
        className={clsx(styles.userLine, isSelected && styles.userLineActive)}
        onClick={isTogglingStatusLoading ? undefined : handleEditUser}
      >
        <TableRow disabled={isTogglingStatusLoading}>
          <TableBodyCell width={SIZES.FIRST_NAME} noPadding>
            <div className={styles.userLine__image}>
              <ImageFetcher firstName={firstName} lastName={lastName} size="small" imageId={imageId} />
            </div>
            <p>{firstName}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.LAST_NAME}>
            <p>{lastName}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.EMAIL}>
            <p>{email}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.PERMISSIONS_TYPE}>
            <p className={styles.userRole}>{role}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.PHONE_AUTHENTICATION}>
            <p>{isMfaEnabled ? 'Enabled' : 'Disabled'}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.EMAIL_VERIFIED}>
            <p>{isEmailVerified ? 'Yes' : 'No'}</p>
          </TableBodyCell>
          <TableBodyCell width={SIZES.ACCOUNT_STATUS}>
            <div className={clsx(styles.userLine__status, !isActive && styles.statusInactive)}>
              <p>{isActive ? 'Active' : 'Inactive'}</p>
            </div>
          </TableBodyCell>
          <RowActions
            actions={[
              {
                title: 'Edit User',
                handler: handleEditUser,
                danger: false,
                separatorRequired: isActive,
              },
              {
                danger: isActive,
                handler: () => toggleActive(),
                title: isActive ? userActionLabels.deactivateAccount : userActionLabels.activateAccount,
                disabled: isCurrentUser,
              },
            ]}
            isLoading={isTogglingStatusLoading}
          />
        </TableRow>
      </div>
    </RowActionsContainer>
  );
};

export default UserLine;
