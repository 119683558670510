import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { DecisionEngineApi, DownloadAPI, DropdownStrategy, StrategyGroupsData } from 'api/Types';
import {
  DOWNLOAD_API_TEMPLATE,
  GET_API_ACTIVATIONS_REQUEST,
  UPDATE_API_ACTIVATIONS_REQUEST,
  UPDATE_API_ACTIVATIONS_SUCCESS,
  DOWNLOAD_API_TEMPLATE_SUCCESS,
  ApiActivationActionType,
} from './ActionTypes';
import {
  getApiActivationsSuccess,
  getApiActivationsFailure,
  GetApiActivationsRequestAction,
  getApiActivationsRequest,
  UpdateApiActivationsRequestAction,
  updateActivationsSuccess,
  updateActivationsFailure,
  downloadApiTemplateSuccess,
  downloadApiTemplateFailure,
  DownloadApiTemplateAction,
  closeApiActivationsPopUp,
  GetDropdownGroupedStrategiesAction,
  getDropdownGroupedStrategiesSuccess,
  getDropdownGroupedStrategiesFailure,
} from './ActionCreator';
import Fetcher, { RoutineHandler } from 'middlewares/Fetcher';
import notification from 'handlers/notification/notificationActionCreator';
import { IStrategiesApi } from 'api/DecisionEngine/StrategiesApi';
import { ReduxState } from 'types/redux';
import { IStrategyGroupsApi } from 'api/DecisionEngine/StrategyGroupApi';

export const GetApiActivationsMiddleware: (api: IStrategyGroupsApi) => Middleware = (api) =>
  Fetcher<StrategyGroupsData, GetApiActivationsRequestAction, ReduxState>(
    GET_API_ACTIVATIONS_REQUEST,
    getApiActivationsSuccess,
    getApiActivationsFailure,
    async (action, { apiActivation }) => {
      const {
        page,
        itemsPerPage,
        showArchived,
        searchInputValue,
        selectedUpdaters,
        versionsStatuses,
        sortingType,
      } = apiActivation;
      const { payload = {} } = action;

      const response = await api.findGroups({
        page,
        perPage: itemsPerPage,
        showArchived,
        searchInputValue,
        updaters: selectedUpdaters.map(({ id }) => id),
        versionsStatuses,
        sortingType,
        ...payload,
      });

      return {
        ...response,
        searchInputValue,
        selectedUpdaters,
      };
    },
  );

export const UpdateApiActivationsMiddleware: (api: DecisionEngineApi) => Middleware = (api) =>
  Fetcher<void, UpdateApiActivationsRequestAction>(
    UPDATE_API_ACTIVATIONS_REQUEST,
    updateActivationsSuccess,
    updateActivationsFailure,
    ({ payload }) => api.updateApiActivations(payload),
  );

export const onUpdateApiActivationsSuccessMiddleware: Middleware = RoutineHandler(
  async (action: AnyAction, { dispatch }) => {
    if (action.type === UPDATE_API_ACTIVATIONS_SUCCESS) {
      notification.createNotification('Changes saved successfully!', 'success', dispatch);
      dispatch(getApiActivationsRequest());
    }
  },
);

export const DownloadApiActivationsMiddleware: (api: DownloadAPI) => Middleware = (api) =>
  Fetcher<void, DownloadApiTemplateAction>(
    DOWNLOAD_API_TEMPLATE,
    downloadApiTemplateSuccess,
    downloadApiTemplateFailure,
    ({ payload }) => api.downloadApiTemplateURL(payload.strategyId, payload.type),
  );

export const onDownloadApiActivationsSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);
  if (action.type === DOWNLOAD_API_TEMPLATE_SUCCESS) {
    notification.createNotification(
      'The API request template downloaded successfully to your Downloads folder.',
      'success',
      dispatch,
    );
    dispatch(closeApiActivationsPopUp());
  }
  return result;
};

export const GetDropdownGroupedStrategiesMiddleware: (api: IStrategiesApi) => Middleware = (api) =>
  Fetcher<DropdownStrategy[], GetDropdownGroupedStrategiesAction>(
    ApiActivationActionType.GetDropdownGroupedStrategies,
    getDropdownGroupedStrategiesSuccess,
    getDropdownGroupedStrategiesFailure,
    ({ payload }) => api.findDropdownGroupedStrategies(payload.groupTitle),
  );
