import { Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import clsx from 'clsx';
import ActionPopUp from 'components/ActionPopUp';
import ActionPopUpItem from 'components/ActionPopUpItem';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import React, { useRef, useState } from 'react';
import { CheckedImage, DropdownImage } from 'static/images';
import styles from './ProductListDropdown.module.scss';

interface ProductListDropdownProps {
  selectedProduct: Product;
  source: Product[];
  onSelect: (product: Product) => void;
}

const ProductListDropdown = ({ selectedProduct, source, onSelect }: ProductListDropdownProps) => {
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const onItemClick = (product: Product) => {
    setOpen(false);
    onSelect(product);
  };

  return (
    <>
      <div className={styles.productList} onClick={() => setOpen(true)}>
        <h2>{selectedProduct.name}</h2>
        <DropdownImage ref={containerRef} />
      </div>
      <ContextualPopUp
        anchorEl={containerRef.current}
        open={open}
        onClose={() => setOpen(false)}
        className={styles.contextualPopUp}
        style={{ maxHeight: styles.contextualPopUpMaxHeight, maxWidth: styles.contextualPopUpMaxWidth }}
      >
        <ActionPopUp title="Products" onClose={() => setOpen(false)} className={styles.actionPopUp}>
          {source.map((product) => (
            <ActionPopUpItem
              className={clsx(styles.actionItem, product.id === selectedProduct.id && styles.selected)}
              key={product.id}
              onClick={() => onItemClick(product)}
            >
              {product.name}
              {product.id === selectedProduct.id && <CheckedImage className={styles.checkIcon} />}
            </ActionPopUpItem>
          ))}
        </ActionPopUp>
      </ContextualPopUp>
    </>
  );
};

export default ProductListDropdown;
