import React, { FC, useEffect, useState } from 'react';
import styles from './PopUp.module.scss';
import Button from 'components/Button';
import ButtonsFormRow from 'components/ButtonsFormRow/ButtonsFormRow';
import AutoCompletion from 'components/AutoCompletion';
import { Option } from 'components/SelectInput/SelectInput';
import { Tag } from 'components/Tag';
import { statusColors } from 'pages/IndividualProcessing/IndividualProcessing';
import TextInput from 'components/TextInput';
import getNormalizedStatus from 'utils/getNormalizedStatus';
import { DropdownStrategy, StrategyGroup } from 'api/Types';

interface ConfirmChangeProps {
  activationInfo: StrategyGroup;
  strategies: DropdownStrategy[];
  updateActivationList: (groupName: string, active: string | null, testing: string | null) => void;
}

const EMPTY_DROPDOWN_ITEM_VALUE = 'none';

const DEFAULT_EMPTY_VALUE_OPTION = {
  name: 'None',
  value: EMPTY_DROPDOWN_ITEM_VALUE,
  iconAfter: null,
};

const ConfirmChange: FC<ConfirmChangeProps> = (props) => {
  const { activationInfo, updateActivationList, strategies } = props;
  const [activeVersion, setActiveVersion] = useState<string | null>(null);
  const [testingVersion, setTestingVersion] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const versionsList: Option[] = [DEFAULT_EMPTY_VALUE_OPTION];
  strategies.forEach((version) => {
    const normalizedStatus = getNormalizedStatus(version.status);
    versionsList.push({
      name: version.version.toString(),
      value: version.id || EMPTY_DROPDOWN_ITEM_VALUE,
      iconAfter: <Tag color={statusColors.get(normalizedStatus)}>{normalizedStatus}</Tag>,
    });
  });
  const initialActiveVersion =
    strategies.find((strategy) => strategy.status === 'active')?.id || EMPTY_DROPDOWN_ITEM_VALUE;
  const initialTestingVersion =
    strategies.find((strategy) => strategy.status === 'testing')?.id || EMPTY_DROPDOWN_ITEM_VALUE;

  useEffect(() => {
    setActiveVersion(initialActiveVersion);
    setTestingVersion(initialTestingVersion);
  }, [strategies]);

  const updateApiActivation = async (groupName: string, active: string | null, testing: string | null) => {
    setIsLoading(true);
    await updateActivationList(groupName, active, testing);
    setIsLoading(false);
  };

  const isButtonDisabled =
    (initialActiveVersion === activeVersion && initialTestingVersion === testingVersion) ||
    (activeVersion === testingVersion && activeVersion !== 'none');
  return (
    <>
      <TextInput labelTitle="Strategy Name" value={activationInfo.name} disabled />
      <AutoCompletion
        labelTitle="Active Version"
        onChange={({ value }) => setActiveVersion(value)}
        value={activeVersion || undefined}
        options={versionsList}
      />
      <AutoCompletion
        labelTitle="Testing Version"
        onChange={({ value }) => setTestingVersion(value)}
        value={testingVersion || undefined}
        options={versionsList}
      />
      <ButtonsFormRow>
        <Button
          kind="primary"
          size="form"
          disabled={isButtonDisabled}
          onClick={() => updateApiActivation(activationInfo.name, activeVersion, testingVersion)}
          className={styles.saveButton}
          isLoading={isLoading}
        >
          Save Changes
        </Button>
      </ButtonsFormRow>
    </>
  );
};

export default ConfirmChange;
