import { validateEmail } from 'utils/validation/validation';

export interface ForgotPasswordFormData {
  email: string;
}

export type ValidateForgotPasswordFormResult = Partial<Record<keyof ForgotPasswordFormData, string>>;

const validateForgotPasswordForm = (data: ForgotPasswordFormData): ValidateForgotPasswordFormResult => {
  const errors: ValidateForgotPasswordFormResult = {};

  const emailError = validateEmail(data.email);

  if (emailError) {
    errors.email = emailError;
  }

  return errors;
};

export default validateForgotPasswordForm;
