import { createContext, useContext } from 'react';
import { DecisionEngineApi, DownloadAPI } from 'api/Types';
import { LoanOriginationSystemProductsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { ApplicationDocumentsApi } from 'api/LoanOriginationSystem/DocumentsApi';
import { LoanOriginationSystemEmailsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { VariablesApi } from 'api/Core/VariablesApi';
import { ESignTemplatesApi } from 'api/Core/ESignTemplatesApi';
import { AssetsApi } from 'api/Core/AssetsApi';
import { LoanOriginationSystemLabelsApi } from 'api/LoanOriginationSystem/LoanOriginationSystemLabelsApi';
import { OrganizationApi } from 'api/Core/OrganizationApi';

export interface ApiProviderValue {
  downloadApi: DownloadAPI;
  decisionEngineApi: DecisionEngineApi;
  variablesApi: VariablesApi;
  productsApi: LoanOriginationSystemProductsApi;
  applicationDocumentsApi: ApplicationDocumentsApi;
  emailsApi: LoanOriginationSystemEmailsApi;
  assetsApi: AssetsApi;
  eSignTemplatesApi: ESignTemplatesApi;
  labelsApi: LoanOriginationSystemLabelsApi;
  organizationApi: OrganizationApi;
}

export type Services =
  | DownloadAPI
  | DecisionEngineApi
  | VariablesApi
  | LoanOriginationSystemProductsApi
  | ApplicationDocumentsApi
  | LoanOriginationSystemEmailsApi
  | AssetsApi
  | ESignTemplatesApi
  | LoanOriginationSystemLabelsApi
  | OrganizationApi;

const ApiContext = createContext<ApiProviderValue>({} as ApiProviderValue);

const useService = <ServiceType extends Services>(serviceName: keyof ApiProviderValue) => () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const services = useContext(ApiContext);

  return services[serviceName] as ServiceType;
};

export const useDownloadApi = useService<DownloadAPI>('downloadApi');

export const useDecisionEngineApi = useService<DecisionEngineApi>('decisionEngineApi');

export const useVariablesApi = useService<VariablesApi>('variablesApi');

export const useProductsApi = useService<LoanOriginationSystemProductsApi>('productsApi');

export const useApplicationDocumentsApi = useService<ApplicationDocumentsApi>('applicationDocumentsApi');

export const useEmailsApi = useService<LoanOriginationSystemEmailsApi>('emailsApi');

export const useAssetsApi = useService<AssetsApi>('assetsApi');

export const useESignTemplatesApi = useService<ESignTemplatesApi>('eSignTemplatesApi');

export const useLabelsApi = useService<LoanOriginationSystemLabelsApi>('labelsApi');

export const useOrganizationApi = useService<OrganizationApi>('organizationApi');

const ApiProvider = ApiContext.Provider;

export default ApiProvider;
