import React, { FC } from 'react';
import styles from './RequiredVariablesTable.module.scss';
import TableRow from './TableRow';
import { EditExternalIntegrationVariablesPopUpState } from 'EditExternalIntegrationVariablesPopUp/Types';
import RulesListTableHeader from 'components/RulesListTableHeader';
import SkeletonIntegrationLine from 'components/ExternalIntegration/Tables/SkeletonIntegrationLine';

const TABLE_TITLE = 'Variables Required for Integration';
const TABLE_DESCRIPTION =
  'The following data items are required to run the \n integration and the variables below will be used.';

interface TableHeaderProps {
  dataItem: string;
  usedVariable: string;
}

interface RequiredVariablesTableProps {
  externalIntegrationInfo: EditExternalIntegrationVariablesPopUpState;
  isLoading: boolean;
}

const DEFAULT_ROWS_AMOUNT = 10;

const TableHeader: FC<TableHeaderProps> = ({ dataItem, usedVariable }) => (
  <div className={styles.ruleRow}>
    <div className={styles.dataItem}>{dataItem}</div>
    <div className={styles.variable}>{usedVariable}</div>
  </div>
);

const RequiredVariablesTable: FC<RequiredVariablesTableProps> = ({ externalIntegrationInfo, isLoading }) => {
  const { inputList, numberVariables, stringVariables, booleanVariables, dateVariables } = externalIntegrationInfo;
  const variableList = isLoading ? new Array(DEFAULT_ROWS_AMOUNT).fill(null) : inputList;
  return (
    <div className={styles.container}>
      <RulesListTableHeader title={TABLE_TITLE} description={TABLE_DESCRIPTION} />
      <TableHeader dataItem="Data Item Name" usedVariable="Variable Used" />
      {variableList.map((variable, index) => {
        if (variable === null) {
          return <SkeletonIntegrationLine key={index} />;
        }
        return (
          <TableRow
            key={index}
            variable={variable}
            numberVariables={numberVariables}
            stringVariables={stringVariables}
            booleanVariables={booleanVariables}
            dateVariables={dateVariables}
          />
        );
      })}
    </div>
  );
};

export default RequiredVariablesTable;
