import {
  LoanOriginationSystemBorrowersApi,
  Borrower,
} from 'api/LoanOriginationSystem/LoanOriginationSystemBorrowersApi';
import {
  LoadBorrowersSuggestionsAction,
  loadBorrowersSuggestionsFailed,
  loadBorrowersSuggestionsSuccess,
} from 'LoanOriginationSystemApplications/CreateApplication/ActionCreator';
import { LOAD_BORROWERS_SUGGESTIONS } from 'LoanOriginationSystemApplications/CreateApplication/ActionTypes';
import Fetcher from 'middlewares/Fetcher';

const LoadBorrowersSuggestionsMiddleware = (api: LoanOriginationSystemBorrowersApi) =>
  Fetcher<Borrower[], LoadBorrowersSuggestionsAction>(
    LOAD_BORROWERS_SUGGESTIONS,
    loadBorrowersSuggestionsSuccess,
    loadBorrowersSuggestionsFailed,
    (action) => {
      const { filter } = action.payload;

      return api.getBorrowersSuggestions(filter);
    },
  );

export default LoadBorrowersSuggestionsMiddleware;
