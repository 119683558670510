import React from 'react';
import Footer from 'components/Footer';
import MainLayout from 'MainLayout';
import { makeLeftNavigation } from 'MainLayout/utils';
import PageFooter from 'MainLayout/PageFooter';
import PageContent from 'MainLayout/PageContent';
import PageWrapper from 'MainLayout/PageWrapper';
import NotFound from 'components/NotFound';

const NotFoundPage = () => {
  const leftNav = makeLeftNavigation('', '', true);

  return (
    <MainLayout leftNav={leftNav}>
      <PageWrapper>
        <PageContent noPadding>
          <NotFound />
        </PageContent>
        <PageFooter>
          <Footer separator />
        </PageFooter>
      </PageWrapper>
    </MainLayout>
  );
};

export default NotFoundPage;
