import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { ReduxState } from 'types/redux';
import { getIntermediaryApplications, SortIntermediaryApplicationsAction } from './ActionCreator';
import { LoanOriginationSystemIntermediaryDetailsActionTypes } from './ActionTypes';

const isSortIntermediaryApplicationsAction = (action: AnyAction): action is SortIntermediaryApplicationsAction =>
  action.type === LoanOriginationSystemIntermediaryDetailsActionTypes.SortIntermediaryApplications;

const sortIntermediaryApplicationsMiddleware = (({ dispatch }: MiddlewareAPI<ReduxState>) => (
  next: Dispatch<AnyAction>,
) => (action: AnyAction) => {
  const result = next(action);

  if (isSortIntermediaryApplicationsAction(action)) {
    dispatch(getIntermediaryApplications(action.payload.intermediaryId, action.payload.sortingType));
  }

  return result;
}) as Middleware;

export default sortIntermediaryApplicationsMiddleware;
