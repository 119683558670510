import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { AvatarIcon, CloseButtonImage, RoundRobinAvatarIcon } from 'static/images';
import UserAvatarInlineList from 'components/UserAvatarInlineList';
import ButtonWithImage from 'components/ButtonWithImage';
import WrapperWithTooltip from 'components/Tooltip';
import { SkeletonCircle, SkeletonRectangle } from 'components/Skeleton';
import EditTeamMembersPopup from './EditTeamMembersPopup';
import useOrganizationMembers from 'hooks/useOrganizationMembers';
import styles from './ApplicationContextualViewLayout.module.scss';

export interface ApplicationContextualViewLayoutProps {
  contentClassName?: string;
  onClose: () => void;
  teamMembersIds?: string[] | null;
  displayEditTeamMembers?: boolean;
  onTeamMembersChange?: (newTeamMembersIds: string[]) => Promise<void>;
  displayRoundRobinUser?: boolean;
  hideEditTeamMembersButton?: boolean;
  renderAfterContent?: () => React.ReactNode;
  isTeamMembersUpdatingInProgress?: boolean;
}

const MAX_USERS_COUNT = 6;

const ApplicationContextualViewLayout: FC<ApplicationContextualViewLayoutProps> = ({
  contentClassName,
  onClose,
  teamMembersIds,
  onTeamMembersChange,
  displayRoundRobinUser,
  hideEditTeamMembersButton,
  renderAfterContent,
  isTeamMembersUpdatingInProgress,
  children,
}) => {
  const members = useOrganizationMembers();
  const [displayEditTeamMembersPopup, setDisplayEditTeamMembersPopup] = useState(false);

  const renderTeamMembersSectionAvatarsList = () => {
    if (displayRoundRobinUser) {
      return (
        <WrapperWithTooltip tooltip="A team member will be assigned based on round-robin selection">
          <RoundRobinAvatarIcon />
        </WrapperWithTooltip>
      );
    }

    if (!teamMembersIds || !members.length) {
      return <SkeletonCircle width="28px" height="28px" color="primary6" />;
    }

    if (!teamMembersIds.length) {
      return (
        <WrapperWithTooltip tooltip="Unassigned">
          <AvatarIcon />
        </WrapperWithTooltip>
      );
    }

    const teamMembers = members.filter((member) => teamMembersIds.includes(member.id));

    return (
      <UserAvatarInlineList
        size={10}
        className={styles.userAvatarsList}
        maxCount={MAX_USERS_COUNT}
        source={teamMembers}
      />
    );
  };

  const renderTeamMembersSectionButton = () => {
    if (hideEditTeamMembersButton || displayRoundRobinUser) {
      return null;
    }

    if (!teamMembersIds || !members.length) {
      return <SkeletonRectangle width="187px" height="36px" marginLeft="8px" color="primary6" />;
    }

    return (
      <ButtonWithImage
        className={styles.editTeamMembersButton}
        title={teamMembersIds.length ? 'Edit Team Members' : 'Add Team Members'}
        kind={teamMembersIds.length ? 'edit' : 'add'}
        onClick={() => setDisplayEditTeamMembersPopup(true)}
      />
    );
  };

  const handleSaveChanges = async (newTeamMembersIds: string[]) => {
    await onTeamMembersChange?.(newTeamMembersIds);

    setDisplayEditTeamMembersPopup(false);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.content, contentClassName)}>
        <div className={styles.title}>
          <div>
            <h2>Application Form</h2>
            <div className={styles.teamMembersSection}>
              {renderTeamMembersSectionAvatarsList()}
              {renderTeamMembersSectionButton()}
            </div>
          </div>
          <CloseButtonImage className={styles.closeButton} onClick={onClose} />
        </div>
        {children}
      </div>
      {renderAfterContent?.()}
      {displayEditTeamMembersPopup && (
        <EditTeamMembersPopup
          usePortal
          onSaveChanges={handleSaveChanges}
          initialSelectedTeamMemberIds={teamMembersIds || []}
          onClose={() => setDisplayEditTeamMembersPopup(false)}
          isSavingInProgress={isTeamMembersUpdatingInProgress}
        />
      )}
    </div>
  );
};

export default ApplicationContextualViewLayout;
