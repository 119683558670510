import { AnyAction, MiddlewareAPI } from 'redux';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import { ApplicationStatusesActionType } from './ActionTypes';
import {
  CreateApplicationStatusRuleSuccessAction,
  DeleteApplicationStatusRuleSuccessAction,
  UpdateApplicationStatusRuleSuccessAction,
} from './ActionCreator';

const isCreateApplicationStatusRuleSuccessAction = (
  action: AnyAction,
): action is CreateApplicationStatusRuleSuccessAction =>
  action.type === ApplicationStatusesActionType.CreateApplicationStatusRuleSuccess;

const isUpdateApplicationStatusRuleSuccessAction = (
  action: AnyAction,
): action is UpdateApplicationStatusRuleSuccessAction =>
  action.type === ApplicationStatusesActionType.UpdateApplicationStatusRuleSuccess;

const isDeleteApplicationStatusRuleSuccessAction = (
  action: AnyAction,
): action is DeleteApplicationStatusRuleSuccessAction =>
  action.type === ApplicationStatusesActionType.DeleteApplicationStatusRuleSuccess;

export const notifyOnCreateApplicationStatusRuleMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isCreateApplicationStatusRuleSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationStatusRuleCreated), 'success', dispatch);
  }

  return result;
};

export const notifyOnUpdateApplicationStatusRuleMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isUpdateApplicationStatusRuleSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationStatusRuleUpdated), 'success', dispatch);
  }

  return result;
};

export const notifyOnDeleteApplicationStatusRuleMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteApplicationStatusRuleSuccessAction(action)) {
    notification.createNotification(getMessage(MessageType.ApplicationStatusRuleDeleted), 'success', dispatch);
  }

  return result;
};
