import React, { FC } from 'react';
import clsx from 'clsx';
import styles from './ButtonRound.module.scss';
import WrapperWithTooltip, { TooltipPosition } from 'components/Tooltip';

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface ButtonRoundProps extends HTMLButtonProps {
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}

const ButtonRound: FC<ButtonRoundProps> = ({ children, className, tooltip, tooltipPosition, ...props }) => {
  return (
    <WrapperWithTooltip tooltip={tooltip} position={tooltipPosition}>
      <div>
        <button type="button" className={clsx(className, styles.buttonRound)} {...props}>
          {children}
        </button>
      </div>
    </WrapperWithTooltip>
  );
};

export default ButtonRound;
