import React from 'react';
import { SkeletonInput } from 'components/Skeleton';
import styles from './FormSkeleton.module.scss';

const FIELDS_COUNT = 9;

export interface FormSkeleton {
  fieldsCount?: number;
}

const FormSkeleton = ({ fieldsCount = FIELDS_COUNT }) => {
  const renderSkeletonFields = (length: number) => {
    return new Array(length).fill(null).map((value, index) => <SkeletonInput key={index} />);
  };

  return <div className={styles.container}>{renderSkeletonFields(fieldsCount)}</div>;
};

export default FormSkeleton;
