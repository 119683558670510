import React from 'react';
import DownloadApiTemplateButton from 'components/LoanOriginationSystem/DownloadApiTemplateButton';
import EnvironmentWrapper from 'components/EnvironmentWrapper';
import ReactAppEnvironment from 'enums/ReactAppEnvironment';
import styles from './IntermediaryVariablesConfigurationHeader.module.scss';

export interface IntermediaryVariablesConfigurationHeaderProps {
  onDownloadApiTemplate: () => void;
}

const DownloadApiTemplateButtonEnvironmentWrapper = EnvironmentWrapper(
  DownloadApiTemplateButton,
  ReactAppEnvironment.Local,
  ReactAppEnvironment.Test,
);

const IntermediaryVariablesConfigurationHeader = ({
  onDownloadApiTemplate,
}: IntermediaryVariablesConfigurationHeaderProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2 className={styles.headerTitle}>Intermediaries</h2>
        <DownloadApiTemplateButtonEnvironmentWrapper onDownloadApiRequestTemplate={onDownloadApiTemplate} />
      </div>
      <p className={styles.subTitle}>
        Tailor your intermediary data to your business' specific needs by adding additional variables to the basic
        system configuration
      </p>
    </div>
  );
};

export default IntermediaryVariablesConfigurationHeader;
