import React, { useCallback } from 'react';
import styles from './ResultsCard.module.scss';
import { StatusWithCount } from 'components/LoanOriginationSystem/ReportingDashboard/ReportingDashboard';
import { currencyFormatter } from 'components/LoanOriginationSystem/ReportingDashboard/Chart/utils';
import StatisticBlock from 'components/StatisticBlock';
import { downloadFile } from 'utils/downloadFile';

interface ResultsCardProps {
  applicationsNumber: number;
  applicationsAmount: number;
  statusesWithCount: StatusWithCount[];
  downloadURL: string;
  loading: boolean;
  currency: string;
}

const ResultsCard = ({
  applicationsNumber,
  applicationsAmount,
  statusesWithCount,
  downloadURL,
  loading,
  currency,
}: ResultsCardProps) => {
  const getFormattedAmount = useCallback(currencyFormatter(currency), [currency]);

  const statusesWithCountToRender =
    statusesWithCount.length > 0 ? statusesWithCount.map(({ name, count }) => ({ name, value: count })) : null;

  return (
    <div className={styles.container}>
      <StatisticBlock
        title="Amount of Applications"
        value={loading ? null : getFormattedAmount(applicationsAmount)}
        secondaryTitle="Number of Applications"
        items={statusesWithCountToRender}
        buttonTitle="Download"
        buttonSubTitle="CSV"
        total={loading ? null : applicationsNumber}
        onButtonClick={() => downloadFile(downloadURL, `${new Date()}.csv`)}
      />
    </div>
  );
};

export default ResultsCard;
