import React from 'react';
import Button from 'components/Button';
import ButtonsFormRow from 'components/ButtonsFormRow/ButtonsFormRow';

interface VariableFormButtonsProps {
  disabled?: boolean;
  update?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  isSavingInProgress?: boolean;
}

const VariableFormButtons = ({
  onSave,
  onCancel,
  update,
  isSavingInProgress,
  disabled = false,
}: VariableFormButtonsProps) => {
  return (
    <ButtonsFormRow>
      <Button isLoading={isSavingInProgress} kind="primary" size="form" onClick={onSave} disabled={disabled}>
        {update ? 'Save Changes' : 'Create Variable'}
      </Button>
      {onCancel && (
        <Button size="form" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </ButtonsFormRow>
  );
};

export default VariableFormButtons;
