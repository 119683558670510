import { AnyAction } from 'redux';

import { OPEN_DELETE_DECISION_STRATEGY_MODULE_WIZARD, CLOSE_DELETE_DECISION_STRATEGY_MODULE_WIZARD } from './constants';
import { DeleteDecisionStrategyModuleWizardState } from './deleteDecisionStrategyModuleWizardTypes';

const initialState: DeleteDecisionStrategyModuleWizardState = {
  isOpen: false,
  strategyId: '',
  moduleLookupName: '',
  moduleIndex: 0,
};

const DeleteDecisionStrategyModuleWizardReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case OPEN_DELETE_DECISION_STRATEGY_MODULE_WIZARD: {
      const { strategyId, moduleLookupName, moduleIndex } = action.payload;
      return {
        isOpen: true,
        strategyId,
        moduleLookupName,
        moduleIndex,
      };
    }
    case CLOSE_DELETE_DECISION_STRATEGY_MODULE_WIZARD:
      return { isOpen: false };
    default:
      return state;
  }
};

export default DeleteDecisionStrategyModuleWizardReducer;
