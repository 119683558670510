import { AnyAction, MiddlewareAPI } from 'redux';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';
import {
  BulkUpdateBorrowerVariableConfigurationsSuccessAction,
  BulkUpdateIntermediaryVariableConfigurationsSuccessAction,
  BulkUpdateVariableConfigurationsActionOrigin,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import { VariablesConfigurationActionType } from 'LoanOriginationSystemVariablesConfiguration/ActionTypes';
import { BulkUpdateApplicationVariableConfigurationsSuccessAction } from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';

const isBorrowerRequiredFieldsUpdateSuccessAction = (
  action: ActionWithOrigin,
): action is BulkUpdateBorrowerVariableConfigurationsSuccessAction =>
  action.type === VariablesConfigurationActionType.BulkUpdateBorrowerVariableConfigurationsSuccess &&
  action.meta?.actionOrigin === BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup;

const isIntermediaryRequiredFieldsUpdateSuccessAction = (
  action: ActionWithOrigin,
): action is BulkUpdateIntermediaryVariableConfigurationsSuccessAction =>
  action.type === VariablesConfigurationActionType.BulkUpdateIntermediaryVariableConfigurationsSuccess &&
  action.meta?.actionOrigin === BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup;

const isApplicationRequiredFieldsUpdateSuccessAction = (
  action: ActionWithOrigin,
): action is BulkUpdateApplicationVariableConfigurationsSuccessAction =>
  action.type === VariablesConfigurationActionType.BulkUpdateApplicationVariableConfigurationsSuccess &&
  action.meta?.actionOrigin === BulkUpdateVariableConfigurationsActionOrigin.EditRequiredVariablesPopup;

const getPageName = (action: AnyAction) => {
  if (isApplicationRequiredFieldsUpdateSuccessAction(action)) {
    return 'Application';
  }

  if (isIntermediaryRequiredFieldsUpdateSuccessAction(action)) {
    return 'Intermediary';
  }

  if (isBorrowerRequiredFieldsUpdateSuccessAction(action)) {
    return 'Borrower';
  }

  return '';
};

const notifyOnUpdateRequiredFieldsMiddleware = ({ dispatch }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  const pageName = getPageName(action);

  if (pageName) {
    notification.createNotification(
      getMessage(MessageType.RequiredVariablesUpdated, { pageName }),
      'success',
      dispatch,
    );
  }

  return result;
};

export default notifyOnUpdateRequiredFieldsMiddleware;
