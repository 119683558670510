import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { resetProductConfiguration } from 'LoanOriginationSystemProducts/ProductConfiguration/ActionCreator';
import { ProductConfigurationStep } from 'LoanOriginationSystemProducts/ProductConfiguration/Types';
import {
  getProduct,
  GetProductActionOrigin,
  setProductToDuplicate,
  setStatusChangeData,
  updateProduct,
  UpdateProductActionOrigin,
} from 'LoanOriginationSystemProducts/ActionCreator';
import Stepper from 'components/Stepper';
import Button from 'components/Button';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import ApplicationSetup from './ApplicationSetup';
import ApplicationWorkflowSetup from './ApplicationWorkflowSetup';
import ApplicationFormConfiguration from './ApplicationFormConfiguration';
import Header, { SkeletonHeader } from './Header';
import styles from './ProductConfiguration.module.scss';

export interface ProductConfigurationProps {
  productId: string;
  step: ProductConfigurationStep;
  onStepChange: (step: ProductConfigurationStep) => void;
  onExit: () => void;
}

const PRODUCT_PROCESS_STEPS = [
  {
    type: ProductConfigurationStep.ApplicationFormConfiguration,
    label: 'Application Form',
  },
  {
    type: ProductConfigurationStep.ApplicationSetup,
    label: 'Application Views',
  },
  {
    type: ProductConfigurationStep.WorkflowSetup,
    label: 'Application Workflow',
  },
];

const ProductConfiguration = ({ productId, onExit, onStepChange, step }: ProductConfigurationProps) => {
  const dispatch = useDispatch();
  const dispatchRoutine = useDispatchRoutine();
  const { product: selectedProduct, isProductUpdateInProgress } = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration,
  );
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  useEffect(() => {
    if (!selectedProduct) {
      const action = setActionOrigin(getProduct(productId), GetProductActionOrigin.ProductConfiguration);

      dispatch(action);
    }

    return () => {
      dispatch(resetProductConfiguration());
    };
  }, []);

  const handleContinueClick = async () => {
    if (!selectedProduct) {
      return;
    }

    if (step === ProductConfigurationStep.ApplicationFormConfiguration) {
      onStepChange(ProductConfigurationStep.ApplicationSetup);

      return;
    }

    if (step === ProductConfigurationStep.ApplicationSetup) {
      onStepChange(ProductConfigurationStep.WorkflowSetup);

      return;
    }

    if (selectedProduct.status !== ProductStatus.Active) {
      setIsUpdateInProgress(true);

      await dispatchRoutine(updateProduct(selectedProduct.id, { status: ProductStatus.Active }));

      setIsUpdateInProgress(false);
    }

    onExit();
  };

  const handleProductNameChange = (newName: string) => {
    if (!selectedProduct) {
      return;
    }

    const action = setActionOrigin(
      updateProduct(selectedProduct.id, { name: newName }),
      UpdateProductActionOrigin.ProductConfigurationName,
    );

    dispatch(action);
  };

  const handleStepChange = (newStep: string) => {
    onStepChange(newStep as ProductConfigurationStep);
  };

  const handleProductStatusChange = (newStatus: ProductStatus) => {
    if (!selectedProduct) {
      return;
    }

    dispatch(
      setStatusChangeData({
        product: selectedProduct,
        status: newStatus,
      }),
    );
  };

  const handleProductDuplicate = () => {
    if (!selectedProduct) {
      return;
    }

    dispatch(setProductToDuplicate(selectedProduct));
  };

  const renderStepContent = () => {
    if (step === ProductConfigurationStep.ApplicationFormConfiguration) {
      return <ApplicationFormConfiguration />;
    }

    if (step === ProductConfigurationStep.ApplicationSetup) {
      return <ApplicationSetup />;
    }

    return <ApplicationWorkflowSetup />;
  };

  const renderHeader = () => {
    if (!selectedProduct) {
      return <SkeletonHeader />;
    }

    return (
      <Header
        productName={selectedProduct.name}
        productStatus={selectedProduct.status}
        productUpdatedAt={selectedProduct.updatedAt}
        productUpdatedBy={selectedProduct.updatedBy}
        isUpdating={isProductUpdateInProgress}
        onProductNameChange={handleProductNameChange}
        onProductStatusChange={handleProductStatusChange}
        onDuplicate={handleProductDuplicate}
      />
    );
  };

  const stepIndex = PRODUCT_PROCESS_STEPS.findIndex(({ type }) => type === step);

  return (
    <>
      <div className={styles.container}>
        {renderHeader()}
        {renderStepContent()}
      </div>
      <div className={styles.footer}>
        <Stepper alwaysActive steps={PRODUCT_PROCESS_STEPS} currentStep={step} onStepChange={handleStepChange} />
        <div className={styles.actions}>
          <Button className={styles.exitButton} size="small" onClick={onExit} kind="secondary">
            Exit
          </Button>
          <Button
            className={styles.continueButton}
            size="small"
            disabled={!selectedProduct}
            kind="primary"
            isLoading={isUpdateInProgress}
            onClick={handleContinueClick}
          >
            {stepIndex === PRODUCT_PROCESS_STEPS.length - 1 ? 'Finish' : 'Continue'}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProductConfiguration;
