import React, { FC } from 'react';
import styles from './FullPageLoader.module.scss';
import Spinner from 'components/Spinner';

interface FullPageLoaderProps {
  isDataLoaded: boolean;
}

const FullPageLoader: FC<FullPageLoaderProps> = ({ isDataLoaded, children }) => {
  return (
    <>
      {isDataLoaded ? (
        children
      ) : (
        <div className={styles.loaderContainer}>
          <Spinner size={50} background="light" />
        </div>
      )}
    </>
  );
};

export default FullPageLoader;
