import { TableViewData, DataViewSortingType } from 'api/LoanOriginationSystem/Types';
import CoreSystemApi from 'api/Core/CoreSystemApi';
import {
  DataType as DataTypeValue,
  IVariableShortInfo,
  NewVariableApiType,
  SavedVariableApiType,
  Variable,
  VisualDataType,
} from 'Variables/VariablesTypes';
import formatDate from 'utils/dateFormat';
import { VariableItem } from 'api/VariablesType';
import { validateVariableUserAccessPermissions } from 'Variables/utils';
import { UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';

export interface VariablesDataFilter {
  ids?: string[];
  offset?: number;
  count?: number;
  search?: string;
  dueCreatedDateRange?: {
    from: Date | null;
    to: Date | null;
  };
  dueUpdatedDateRange?: {
    from: Date | null;
    to: Date | null;
  };
  onlyCustom?: boolean;
  onlyStandard?: boolean;
  dataType?: DataTypeValue;
  visualDataType?: VisualDataType;
  systemNames?: string[];
  teamMembers?: UserInfo[];
  includeArchived?: boolean;
}

export enum VariablesSortingField {
  DisplayName = 'display_title',
  SystemName = 'title',
  DataType = 'data_type',
  VariableType = 'isStandard',
  UpdatedAt = 'updatedAt',
}

export type VariablesSortingType = DataViewSortingType<VariablesSortingField>;

export interface VariablesApi {
  getVariables(filters?: VariablesDataFilter, sortingType?: VariablesSortingType, abortSignal?: AbortSignal): Promise<TableViewData<Variable>>;
  findById(id: string): Promise<Variable>;
  getAllVariables(): Promise<IVariableShortInfo[]>;
  changeVariableStatus(variableId: string, archived: boolean): Promise<Variable>;
  getStandardVariables(): Promise<Variable[]>;
  createVariable(variableData: NewVariableApiType): Promise<VariableItem>;
  updateVariable(variableData: SavedVariableApiType): Promise<VariableItem>;
}

type VariablesSearchParams = Partial<{
  ids: string[];
  includeArchived: boolean;
  dueUpdatedDateRangeTo: string;
  dueUpdatedDateRangeFrom: string;
  dueCreatedDateRangeTo: string;
  dueCreatedDateRangeFrom: string;
  visualDataType: VisualDataType;
  dataType: DataTypeValue;
  onlyStandard: boolean;
  onlyCustom: boolean;
  teamMemberIds: string[];
  systemNames: string[];
  search: string;
  count: number;
  offset: number;
  sortDirection: string;
  sortField: VariablesSortingField;
}>;

export default class VariablesRestApi extends CoreSystemApi<Variable> implements VariablesApi {
  protected resourceName = 'variables';

  public async getVariables(filters?: VariablesDataFilter, sortingType?: VariablesSortingType, abortSignal?: AbortSignal) {
    const params: VariablesSearchParams = {};

    params.sortField = sortingType?.field;

    if (sortingType?.ascending !== undefined) {
      params.sortDirection = sortingType.ascending ? 'asc' : 'desc';
    }

    if (filters) {
      const {
        ids,
        dueCreatedDateRange,
        dueUpdatedDateRange,
        systemNames,
        onlyCustom,
        onlyStandard,
        dataType,
        visualDataType,
        offset,
        count,
        search,
        teamMembers,
        includeArchived,
      } = filters;

      params.offset = offset;
      params.count = count;
      params.search = search;
      params.systemNames = systemNames;
      params.teamMemberIds = teamMembers?.length ? teamMembers?.map((member) => member.id) : undefined;
      params.onlyCustom = onlyCustom;
      params.onlyStandard = onlyStandard;
      params.dataType = dataType;
      params.visualDataType = visualDataType;
      if (dueCreatedDateRange && dueCreatedDateRange.from) {
        params.dueCreatedDateRangeFrom = formatDate(dueCreatedDateRange.from);
      }
      if (dueCreatedDateRange && dueCreatedDateRange.to) {
        params.dueCreatedDateRangeTo = formatDate(dueCreatedDateRange.to);
      }
      if (dueUpdatedDateRange && dueUpdatedDateRange.from) {
        params.dueUpdatedDateRangeFrom = formatDate(dueUpdatedDateRange.from);
      }
      if (dueUpdatedDateRange && dueUpdatedDateRange.to) {
        params.dueUpdatedDateRangeTo = formatDate(dueUpdatedDateRange.to);
      }
      params.includeArchived = includeArchived;
      params.ids = ids;
    }

    return this.fetch<TableViewData<Variable>>(`/${this.resourceName}/search`, 'PUT', params, { abortSignal });
  }

  public async findById(id: string) {
    return this.fetch<Variable>(`/${this.resourceName}/${id}`);
  }

  public async getAllVariables() {
    return this.fetch<IVariableShortInfo[]>(`/${this.resourceName}/all`);
  }

  public async changeVariableStatus(variableId: string, status: boolean) {
    return this.fetch<Variable>(`/${this.resourceName}/${variableId}/status`, 'PATCH', {
      archived: status,
    });
  }

  public async getStandardVariables() {
    const data = await this.getVariables({ onlyStandard: true });

    return data.items;
  }

  public createVariable(variableData: NewVariableApiType): Promise<VariableItem> {
    validateVariableUserAccessPermissions(variableData);

    const {
      dataType,
      description = '',
      displayName,
      numberFormat,
      stringFormat,
      phoneNumberFormat,
      optionsList,
      userAccessPermissions,
      identificationNumberType,
      identificationNumberDescription,
      currency,
      dateFormat,
    } = variableData;

    const requestBody = {
      category: 'Custom',
      data_type: dataType,
      description,
      name: displayName,
      numberFormat,
      stringFormat,
      phoneNumberFormat,
      optionsList,
      userAccessPermissions,
      identificationNumberType,
      identificationNumberDescription,
      currency,
      dateFormat,
    };
    return this.fetch<VariableItem>(`/${this.resourceName}`, 'POST', requestBody);
  }

  public updateVariable(variableData: SavedVariableApiType): Promise<VariableItem> {
    validateVariableUserAccessPermissions(variableData);

    return this.fetch<VariableItem>(`/${this.resourceName}/${variableData.id}`, 'PUT', variableData);
  }
}
