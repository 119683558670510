import { useMemo } from 'react';
import { VariableConfiguration, FormLayoutData } from 'api/LoanOriginationSystem/Types';
import { validateConfigurableFormData } from './validation';

const useConfigurableFormValidation = (
  configuration: VariableConfiguration[] | null = [],
  ignoreCustomFields: boolean = false,
) => {
  const fields = useMemo(() => {
    if (!configuration) {
      return [];
    }

    return configuration.filter((variableConfiguration) => {
      return !ignoreCustomFields || variableConfiguration.default;
    });
  }, [configuration, ignoreCustomFields]);

  return (data: FormLayoutData) => {
    return validateConfigurableFormData(data, fields);
  };
};

export default useConfigurableFormValidation;
