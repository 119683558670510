import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import NoItems from 'components/NoItems';
import styles from './EmailsDashboard.module.scss';
import { NoResultsIcon } from 'static/images';
import { ReduxState } from 'types/redux';
import { openDeleteEmailPopup, sortEmails } from 'LoanOriginationSystemEmails/ActionCreator';
import {
  resetAllEmailsFilters,
  setSearchInputValue,
  toggleFiltersPopup,
} from 'LoanOriginationSystemEmails/Filters/ActionCreator';
import { Email, EmailsSortingField } from 'api/LoanOriginationSystem/LoanOriginationSystemEmailsApi';
import { isEqual } from 'lodash';
import { initialState as filtersInitialState } from 'LoanOriginationSystemEmails/Filters/Reducer';
import SearchNotFound from 'components/SearchNotFound';
import EmailsTable from './EmailsTable';
import { useHistory } from 'react-router-dom';
import { getOrganizationEmail } from 'utils/emailGetters';
import { NullableItems, PaginationProps } from 'pagination';

interface EmailsDashboardProps {
  organizationId: string;
  emails: NullableItems<Email>;
  paginationProps: PaginationProps;
}

const EmailsDashboard: FC<EmailsDashboardProps> = ({ organizationId, emails, paginationProps }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { filters, sortingType } = useSelector((state: ReduxState) => state.loanOriginationSystemEmails);

  const organizationEmail = getOrganizationEmail(organizationId);

  const handleSearch = (search: string) => {
    dispatch(setSearchInputValue(search));
  };

  const handleSort = (field: EmailsSortingField, ascending: boolean) => {
    dispatch(sortEmails(field, ascending));
  };

  const handleFilterButtonClick = () => {
    dispatch(toggleFiltersPopup());
  };

  const handleResetAllFilters = () => {
    dispatch(resetAllEmailsFilters());
  };

  const handleOpenEmail = (id: string) => history.replace(`?emailId=${id}`);
  const handleDeleteEmail = (id: string) => dispatch(openDeleteEmailPopup(id));

  const isNoEmails = !emails.length && !filters.searchInputValue && isEqual(filters, filtersInitialState);

  const renderBody = () => {
    if (filters.searchInputValue && !emails.length) {
      return <SearchNotFound searchValue={filters.searchInputValue} />;
    }

    if (!emails.length && isEqual(filters, filtersInitialState)) {
      return (
        <NoItems title="No emails have been added yet!" className={styles.noData} titleClassName={styles.noDataTitle} />
      );
    }

    if (!emails.length && !isEqual(filters, filtersInitialState)) {
      return (
        <NoItems
          title="No Results Found"
          icon={<NoResultsIcon />}
          buttonMessage="Clear filters"
          subtitle="Please remove or adjust your filters."
          onButtonClick={handleResetAllFilters}
          className={styles.noItemsContainer}
        />
      );
    }

    return (
      <EmailsTable
        paginationProps={paginationProps}
        emails={emails}
        onSort={handleSort}
        sortingType={sortingType}
        onOpenEmail={handleOpenEmail}
        onDeleteEmail={handleDeleteEmail}
        organizationEmail={organizationEmail}
        searchValue={filters.searchInputValue}
      />
    );
  };

  return (
    <>
      <Header
        searchInputValue={filters.searchInputValue}
        subTitleHidden={isNoEmails}
        filtersChanged={!isEqual(filters, filtersInitialState)}
        onSearch={handleSearch}
        openFiltersPopup={handleFilterButtonClick}
        organizationEmail={organizationEmail}
      />
      {renderBody()}
    </>
  );
};

export default EmailsDashboard;
