import Fetcher from 'middlewares/Fetcher';
import {
  ApplicationStatusesRulesValidationApi,
  ApplicationStatusRulesValidationResult,
} from 'api/LoanOriginationSystem/ApplicationStatusesRulesValidationApi';
import {
  getStatusesRulesValidationResultsSuccess,
  getStatusesRulesValidationResultsFailure,
  GetStatusesRulesValidationResultsAction,
} from './ActionCreator';
import { ApplicationStatusRulesValidationActionType } from './ActionTypes';

const GetStatusesRulesValidationResultsMiddleware = (api: ApplicationStatusesRulesValidationApi) =>
  Fetcher<ApplicationStatusRulesValidationResult[], GetStatusesRulesValidationResultsAction>(
    ApplicationStatusRulesValidationActionType.GetStatusesRulesValidationResults,
    getStatusesRulesValidationResultsSuccess,
    getStatusesRulesValidationResultsFailure,
    (action) => {
      return api.getValidationResults(action.payload.applicationId);
    },
  );

export default GetStatusesRulesValidationResultsMiddleware;
