import React from 'react';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { NullableItems } from 'pagination';
import ProductsListItem from './ProductsListItem';
import SkeletonProductsListItem from './SkeletonProductsListItem';
import styles from './ProductsList.module.scss';

export interface ProductsListProps {
  products: NullableItems<Product>;
  onProductSelect: (product: Product) => void;
  onProductDuplicate: (product: Product) => void;
  onChangeProductStatus: (product: Product, status: ProductStatus) => void;
}

const ProductsList = ({ products, onChangeProductStatus, onProductDuplicate, onProductSelect }: ProductsListProps) => {
  const renderListItem = (product: Product | null, index: number) => {
    if (!product) {
      return <SkeletonProductsListItem key={index} />;
    }

    return (
      <ProductsListItem
        key={product.id}
        product={product}
        onProductSelect={onProductSelect}
        onDuplicate={onProductDuplicate}
        onChangeStatus={onChangeProductStatus}
      />
    );
  };

  return <div className={styles.container}>{products.map(renderListItem)}</div>;
};

export default ProductsList;
