import { ApplicationStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationStatusesApi';
import { ReportingMonthInfo } from 'LoanOriginationSystemReporting/Applications/Types';
import { isEmpty } from 'lodash';
import React from 'react';
import { getChartBarKeys } from 'components/LoanOriginationSystem/ReportingDashboard/utils';
import styles from './ResultTable.module.scss';
import ResultTableSkeleton from './ResultTableSkeleton';
import ScrollTablePart from './ScrollTablePart/ScrollTablePart';
import StaticTablePart from './StaticTablePart/StaticTablePart';

interface ResultTableProps {
  reportingEntitiesMap: Record<string, ReportingMonthInfo>;
  loading: boolean;
  statuses: ApplicationStatus[];
  currency: string;
}

const ResultTable = ({ reportingEntitiesMap, loading, statuses, currency }: ResultTableProps) => {
  if (loading || isEmpty(reportingEntitiesMap) || isEmpty(statuses)) {
    return <ResultTableSkeleton />;
  }

  return (
    <div className={styles.container}>
      <StaticTablePart statuses={statuses} tableSections={getChartBarKeys(currency)} />
      <ScrollTablePart reportingEntitiesMap={reportingEntitiesMap} statuses={statuses} />
    </div>
  );
};

export default ResultTable;
