import Fetcher from 'middlewares/Fetcher';
import { LoanOriginationSystemProductsApi, Product } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import { LoanOriginationSystemProductsActionType } from './ActionTypes';
import { ChangeProductStatusAction, changeProductStatusFailure, changeProductStatusSuccess } from './ActionCreator';

const ChangeProductStatusMiddleware = (api: LoanOriginationSystemProductsApi) =>
  Fetcher<Product, ChangeProductStatusAction>(
    LoanOriginationSystemProductsActionType.ChangeProductStatus,
    changeProductStatusSuccess,
    changeProductStatusFailure,
    (action) => {
      return api.update(action.payload.productId, { status: action.payload.status });
    },
  );

export default ChangeProductStatusMiddleware;
