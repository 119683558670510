import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { setActionOrigin } from 'utils/actions/ActionWithOrigin';
import { Variable } from 'Variables/VariablesTypes';
import { updateProduct, UpdateProductActionOrigin } from 'LoanOriginationSystemProducts/ActionCreator';
import {
  setFormPageToDelete,
  setPageToEditRequiredFields,
  toggleFormSettingsPopupVisibility,
} from 'LoanOriginationSystemProducts/ProductConfiguration/ApplicationFormConfiguration/ActionCreator';
import { ApplicationFormPage } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import {
  getApplicationVariableConfigurationsSelector,
  getBorrowerVariableConfigurationsSelector,
  getIntermediaryVariableConfigurationsSelector,
} from 'LoanOriginationSystemVariablesConfiguration/Selectors';
import { useDispatchRoutine } from 'middlewares/Fetcher';
import {
  getBorrowerVariableConfigurations,
  getIntermediaryVariableConfigurations,
} from 'LoanOriginationSystemVariablesConfiguration/ActionCreator';
import {
  createApplicationVariableConfiguration,
  deleteApplicationVariableConfiguration,
  getApplicationVariableConfigurations,
  updateApplicationVariableConfiguration,
} from 'LoanOriginationSystemVariablesConfiguration/ApplicationVariableConfigurationsActionCreator';
import BaseVariablesConfigurationLayout from 'components/LoanOriginationSystem/VariablesConfiguration/BaseVariablesConfigurationLayout';
import { formatDefaultFieldsDisplayTitle as formatBorrowerDisplayName } from 'LoanOriginationSystemBorrowers/utils';
import { formatDefaultFieldsDisplayTitle as formatIntermediaryDisplayName } from 'LoanOriginationSystemIntermediariesPage/utils';
import ButtonWithImage from 'components/ButtonWithImage';
import { SkeletonRectangle } from 'components/Skeleton';
import Header from './Header';
import styles from './ApplicationFormConfiguration.module.scss';

const getHeaderByApplicationFormPage = (selectedApplicationFormPage: ApplicationFormPage | null) => {
  switch (selectedApplicationFormPage) {
    case ApplicationFormPage.Borrower: {
      return 'Borrower';
    }
    case ApplicationFormPage.CoBorrower: {
      return 'Co-Borrower';
    }
    case ApplicationFormPage.Intermediary: {
      return 'Intermediary';
    }
    case ApplicationFormPage.ApplicationDetails: {
      return 'Application Details';
    }
    default: {
      return <SkeletonRectangle width="200px" height="28px" color="primary20" />;
    }
  }
};

const MAX_VARIABLES_PER_CARD = 50;

const ApplicationFormConfiguration = () => {
  const dispatchRoutine = useDispatchRoutine();
  const dispatch = useDispatch();
  const selectedProduct = useSelector(
    (state: ReduxState) => state.loanOriginationSystemProducts.productConfiguration.product,
  );

  const borrowerVariableConfigurations = useSelector((state: ReduxState) => {
    return getBorrowerVariableConfigurationsSelector(state, {
      borrowerType: selectedProduct?.borrowerType,
      productId: selectedProduct?.id,
    });
  });

  const intermediaryVariableConfigurations = useSelector((state: ReduxState) => {
    if (!selectedProduct) {
      return null;
    }

    return getIntermediaryVariableConfigurationsSelector(state, {
      productId: selectedProduct.id,
    });
  });

  const applicationVariableConfigurations = useSelector((state: ReduxState) => {
    if (!selectedProduct) {
      return null;
    }

    return getApplicationVariableConfigurationsSelector(state, { productId: selectedProduct.id });
  });

  const [selectedApplicationFormPage, setSelectedApplicationFormPage] = useState(
    selectedProduct?.settings.applicationFormPages[0],
  );

  useEffect(() => {
    if (!selectedApplicationFormPage) {
      setSelectedApplicationFormPage(selectedProduct?.settings.applicationFormPages[0]);
    }

    if (!selectedProduct) {
      return;
    }

    if (
      selectedApplicationFormPage === ApplicationFormPage.Borrower ||
      selectedApplicationFormPage === ApplicationFormPage.CoBorrower
    ) {
      dispatch(getBorrowerVariableConfigurations(selectedProduct.borrowerType, selectedProduct.id));
    }

    if (selectedApplicationFormPage === ApplicationFormPage.Intermediary) {
      dispatch(getIntermediaryVariableConfigurations(selectedProduct.id));
    }

    if (selectedApplicationFormPage === ApplicationFormPage.ApplicationDetails) {
      dispatch(getApplicationVariableConfigurations(selectedProduct.id));
    }
  }, [selectedProduct, selectedApplicationFormPage]);

  const handleTabChange = (tab: ApplicationFormPage) => {
    setSelectedApplicationFormPage(tab);
  };

  const handleEditRequiredFieldClick = () => {
    if (!selectedApplicationFormPage) {
      return;
    }

    dispatch(setPageToEditRequiredFields(selectedApplicationFormPage));
  };

  const handleApplicationPageDelete = (formPage: ApplicationFormPage) => {
    dispatch(setFormPageToDelete(formPage));
  };

  const handleApplicationFormPageAdd = async (formPage: ApplicationFormPage) => {
    if (!selectedProduct) {
      return;
    }

    const newApplicationFormPages = [...selectedProduct.settings.applicationFormPages, formPage];

    await dispatchRoutine(
      setActionOrigin(
        updateProduct(selectedProduct.id, { settings: { applicationFormPages: newApplicationFormPages } }),
        UpdateProductActionOrigin.AddConfigurableApplicationFormPage,
      ),
    );

    setSelectedApplicationFormPage(formPage);
  };

  const handleFormatDisplayTitle = (systemName: string, title: string) => {
    if (
      selectedApplicationFormPage === ApplicationFormPage.Borrower ||
      selectedApplicationFormPage === ApplicationFormPage.CoBorrower
    ) {
      return formatBorrowerDisplayName(systemName, title);
    }

    if (selectedApplicationFormPage === ApplicationFormPage.Intermediary) {
      return formatIntermediaryDisplayName(systemName, title);
    }

    return title;
  };

  const handleFormSettingsClick = () => {
    dispatch(toggleFormSettingsPopupVisibility());
  };

  const renderHeaderActions = () => (
    <ButtonWithImage
      disabled={!selectedProduct || !getConfigurations()}
      className={styles.pageSettingsButton}
      title="Edit Required Fields"
      kind="edit"
      onClick={handleEditRequiredFieldClick}
    />
  );

  const handleVariableAdd = (column: number, position: number, variable: Variable) => {
    if (selectedApplicationFormPage !== ApplicationFormPage.ApplicationDetails || !selectedProduct) {
      return;
    }

    dispatch(createApplicationVariableConfiguration(variable, selectedProduct.id, position, column));
  };

  const handleVariableReorder = (id: string, column: number, position: number) => {
    if (selectedApplicationFormPage !== ApplicationFormPage.ApplicationDetails || !selectedProduct) {
      return;
    }

    dispatch(
      updateApplicationVariableConfiguration(id, {
        column,
        position,
      }),
    );
  };

  const handleVariableDelete = (id: string) => {
    if (selectedApplicationFormPage !== ApplicationFormPage.ApplicationDetails || !selectedProduct) {
      return;
    }

    dispatch(deleteApplicationVariableConfiguration(id));
  };

  const getConfigurations = () => {
    switch (selectedApplicationFormPage) {
      case ApplicationFormPage.Borrower: {
        return borrowerVariableConfigurations;
      }
      case ApplicationFormPage.CoBorrower: {
        return borrowerVariableConfigurations;
      }
      case ApplicationFormPage.Intermediary: {
        return intermediaryVariableConfigurations;
      }
      case ApplicationFormPage.ApplicationDetails: {
        return applicationVariableConfigurations;
      }
      default: {
        return null;
      }
    }
  };

  const renderContent = () => {
    return (
      <BaseVariablesConfigurationLayout
        formTitleClassName={styles.variableConfigurationFormTitle}
        configurations={getConfigurations()}
        formHeader={getHeaderByApplicationFormPage(selectedApplicationFormPage || null)}
        hideAddCustomVariablesButton={selectedApplicationFormPage !== ApplicationFormPage.ApplicationDetails}
        onVariableReorder={handleVariableReorder}
        onVariableDelete={handleVariableDelete}
        onVariableAdd={handleVariableAdd}
        headerActions={renderHeaderActions()}
        formatDisplayTitle={handleFormatDisplayTitle}
        maxVariablesPerCard={MAX_VARIABLES_PER_CARD}
      />
    );
  };

  return (
    <div className={styles.applicationFormConfigurationContainer}>
      <Header
        selectedApplicationFormPage={selectedApplicationFormPage}
        applicationFormPages={selectedProduct?.settings.applicationFormPages || null}
        onTabChange={handleTabChange}
        onApplicationFormPageAdd={handleApplicationFormPageAdd}
        onApplicationFormPageDelete={handleApplicationPageDelete}
        onFormSettingsClick={handleFormSettingsClick}
      />
      <div className={styles.configurationContent}>{renderContent()}</div>
    </div>
  );
};

export default ApplicationFormConfiguration;
