import React from 'react';
import { SkeletonRectangle } from 'components/Skeleton';
import styles from './SkeletonTabsList.module.scss';
import clsx from 'clsx';

const MIN_TAB_WIDTH = 74;
const MAX_TAB_WIDTH = 100;
const TABS_LENGTH = 7;

export interface SkeletonProps {
  className?: string;
}

const TabListSkeleton = ({ className } : SkeletonProps) => (
  <div className={clsx(styles.container, className)}>
    {new Array(TABS_LENGTH).fill(null).map((_item, index) => (
      <SkeletonRectangle
        key={index}
        width={`${Math.floor(Math.random() * (MAX_TAB_WIDTH - MIN_TAB_WIDTH) + MIN_TAB_WIDTH)}px`}
        height="16px"
        color="primary6"
      />
    ))}
  </div>
);

export default TabListSkeleton;
