import Debouncer from 'middlewares/Debouncer';
import { LoanOriginationSystemProductsFiltersActionTypes } from './ActionTypes';
import { getProducts } from 'LoanOriginationSystemProducts/ActionCreator';

const onFiltersChangeMiddleware = Debouncer(
  (payload: string, getState) => {
    const { loanOriginationSystemProducts } = getState();
    const { filters } = loanOriginationSystemProducts;

    return getProducts(filters);
  },
  [
    LoanOriginationSystemProductsFiltersActionTypes.SetSearchInputValue,
    LoanOriginationSystemProductsFiltersActionTypes.SetFilterStatuses,
  ],
);

export default onFiltersChangeMiddleware;
