import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { LoanOriginationSystemReportingActionType } from './ActionTypes';
import { ApplicationsFilterParams, ReportingData } from './Types';

export interface GetReportingDataAction {
  type: LoanOriginationSystemReportingActionType.GetReportingData;
  payload: ApplicationsFilterParams;
}

export interface GetReportingDataSuccessAction {
  type: LoanOriginationSystemReportingActionType.GetReportingDataSuccess;
  payload: ReportingData;
}

export const getReportingData = (payload: ApplicationsFilterParams): GetReportingDataAction => {
  return {
    type: LoanOriginationSystemReportingActionType.GetReportingData,
    payload,
  };
};

export const getReportingDataSuccess = (payload: ReportingData): GetReportingDataSuccessAction => {
  return {
    type: LoanOriginationSystemReportingActionType.GetReportingDataSuccess,
    payload,
  };
};

export const getReportingDataFailure = FailedActionCreator(
  LoanOriginationSystemReportingActionType.GetReportingDataFailure,
);
