import { ComparisonOperandType } from 'components/SimpleRuleBuilder/Types';
import { ModuleType } from 'DecisionStrategy/DecisionStrategiesTypes';
import { RuleOutput } from 'RuleCreate/Types';
import { RuleBuilderState } from './Types';

export const getIsCurrentOperandFilled = (
  operandType: ComparisonOperandType,
  operandVariableId: string | undefined,
  operandValue: string,
): boolean => {
  if (operandType === ComparisonOperandType.VARIABLE) {
    return Boolean(operandVariableId);
  }

  if (operandValue === '-' || operandValue === '.') {
    return false;
  }

  return Boolean(operandValue);
};

export const getIsRuleCompleted = (rule: RuleBuilderState): boolean => {
  const {
    sourceVariableId,
    selectedComparison,
    firstOperandValue,
    firstOperandVariableId,
    secondOperandValue,
    secondOperandVariableId,
    firstOperandType,
    secondOperandType,
  } = rule;

  if (!sourceVariableId || !selectedComparison) {
    return false;
  }

  if (selectedComparison === 'RANGE') {
    return (
      getIsCurrentOperandFilled(firstOperandType, firstOperandVariableId, firstOperandValue) &&
      getIsCurrentOperandFilled(secondOperandType, secondOperandVariableId, secondOperandValue)
    );
  }

  if (selectedComparison === 'IS NULL' || selectedComparison === 'IS NOT NULL') {
    return true;
  }

  return getIsCurrentOperandFilled(firstOperandType, firstOperandVariableId, firstOperandValue);
};

export const getIsComplexRulesButtonEnabled = (rulesList: RuleBuilderState[]): boolean =>
  rulesList.length >= 2 && rulesList.every(getIsRuleCompleted);

const getIsOutputComplete = (output: RuleOutput, moduleType: ModuleType) => {
  const { sourceVariableId, operandVariableId, operandValue, operandType } = output;
  const isOperandInputFilled = Boolean(operandType === ComparisonOperandType.VALUE ? operandValue : operandVariableId);

  if (moduleType === 'output') {
    return sourceVariableId && isOperandInputFilled;
  }

  return isOperandInputFilled;
};

export const getIsOutputsCompleted = (outputsList: RuleOutput[], moduleType: ModuleType) =>
  outputsList.every((output) => getIsOutputComplete(output, moduleType));
