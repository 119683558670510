import React, { ReactNode, useRef, useEffect, useState, FC, Children } from 'react';

import styles from './PopUpWizard.module.scss';
import PopUp from './_LegacyPopUp';
import clsx from 'clsx';

interface PopUpWizardProps {
  children: ReactNode;
  step: number;
  closePopUp: () => void;
  title: string;
  warningText?: string;
  closable?: boolean;
  noPadding?: boolean;
}

const PopUpWizard: FC<PopUpWizardProps> = ({
  children,
  step,
  closePopUp,
  title,
  warningText,
  closable,
  noPadding = false,
}) => {
  const [currentChildHeight, setCurrentChildHeight] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rafRef = useRef<number | null>(null);

  const recalculateHeight = () => {
    const { children: containerChildren } = containerRef.current!;
    const { offsetHeight } = containerChildren[step] as HTMLElement;

    setCurrentChildHeight(offsetHeight);

    rafRef.current = window.requestAnimationFrame(recalculateHeight);
  };

  useEffect(() => {
    rafRef.current = window.requestAnimationFrame(recalculateHeight);

    return () => {
      cancelAnimationFrame(rafRef.current!);
    };
  }, [step]);

  return (
    <PopUp closePopUp={closePopUp} title={title} closable={closable}>
      <div
        className={styles.popupContentSlideContainer}
        style={{
          transform: `translateX(-${step * 100}%)`,
          height: currentChildHeight || 'auto',
        }}
        ref={containerRef}
      >
        {Children.map(children, (popup, index) => (
          <div
            className={clsx(styles.popupSlide, !noPadding && styles.popupSlide__withPadding)}
            style={step !== index ? { height: currentChildHeight || 'auto' } : {}}
          >
            {popup}
          </div>
        ))}
      </div>
      {warningText && (
        <p className={styles.warningText}>
          <span>Warning: </span>
          {warningText}
        </p>
      )}
    </PopUp>
  );
};

export default PopUpWizard;
