export enum ApplicationDocumentsActionType {
  GetApplicationDocuments = 'applicationDocuments/getApplicationDocuments',
  GetApplicationDocumentsSuccess = 'applicationDocuments/getApplicationDocumentsSuccess',
  GetApplicationDocumentsFailure = 'applicationDocuments/getApplicationDocumentsFailure',
  OpenApplicationDocumentUploadPopup = 'applicationDocuments/openApplicationDocumentUploadPopup',
  CloseApplicationDocumentUploadPopup = 'applicationDocuments/closeApplicationDocumentUploadPopup',
  OpenRenameApplicationDocumentPopup = 'applicationDocuments/openRenameApplicationDocumentPopup',
  CloseRenameApplicationDocumentPopup = 'applicationDocuments/closeRenameApplicationDocumentPopup',
  RenameApplicationDocument = 'applicationDocuments/RenameApplicationDocument',
  UploadApplicationDocument = 'applicationDocuments/uploadApplicationDocument',
  UploadApplicationDocumentSuccess = 'applicationDocuments/uploadApplicationDocumentSuccess',
  RenameApplicationDocumentSuccess = 'applicationDocuments/renameApplicationDocumentSuccess',
  UploadApplicationDocumentFailure = 'applicationDocuments/uploadApplicationDocumentFailure',
  DeleteApplicationDocument = 'applicationDocuments/deleteApplicationDocument',
  DeleteApplicationDocumentSuccess = 'applicationDocuments/deleteApplicationDocumentSuccess',
  DeleteApplicationDocumentFailure = 'applicationDocuments/deleteApplicationDocumentFailure',
  RenameApplicationDocumentFailure = 'applicationDocuments/renameApplicationDocumentFailure',
  SetSearchValue = 'applicationDocuments/setSearchValue',
  SortApplicationDocuments = 'applicationDocuments/sortApplicationDocuments',
  SetApplicationDocumentToDelete = 'applicationDocuments/setApplicationDocumentToDelete',
  RequestCustomerUpload = 'applicationDocuments/setApplicationRequestCustomerUpload',
  RequestCustomerUploadSuccess = 'applicationDocuments/setApplicationRequestCustomerUploadSuccess',
  RequestCustomerUploadFailure = 'applicationDocuments/setApplicationRequestCustomerUploadFailure',
  OpenRequestCustomerUploadPopup = 'applicationDocuments/openRequestCustomerUploadPopup',
  CloseRequestCustomerUploadPopup = 'applicationDocuments/closeRequestCustomerUploadPopup',
  DownloadDocument = 'applicationDocuments/downloadDocument',
  DownloadDocumentSuccess = 'applicationDocuments/downloadDocumentSuccess',
  DownloadDocumentFailure = 'applicationDocuments/downloadDocumentFailure',
  RemoveDownloadedDocument = 'applicationDocuments/removeDownloadedDocument',
  DownloadArchiveOfApplicationDocuments = 'applicationDocuments/downloadArchiveOfApplicationDocuments',
  DownloadArchiveOfApplicationDocumentsSuccess = 'applicationDocuments/downloadArchiveOfApplicationDocumentsSuccess',
  DownloadArchiveOfApplicationDocumentsFailure = 'applicationDocuments/downloadArchiveOfApplicationDocumentsFailure',
  CreateDocuSignEnvelope = 'applicationDocuments/createDocuSignEnvelope',
  CreateDocuSignEnvelopeSuccess = 'applicationDocuments/createDocuSignEnvelopeSuccess',
  CreateDocuSignEnvelopeFailure = 'applicationDocuments/createDocuSignEnvelopeFailure',
  ToggleSendDocuSignSignaturePopup = 'applicationDocuments/toggleSendDocuSignSignaturePopup',
  ResetState = 'applicationDocuments/resetState',
}
