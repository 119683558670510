import Fetcher from 'middlewares/Fetcher';
import { ESignTemplatesApi, ESignTemplate } from 'api/Core/ESignTemplatesApi';
import { ESignTemplatesActionType } from './ActionTypes';
import { updateESignTemplateSuccess, updateESignTemplateFailure, UpdateESignTemplateAction } from './ActionCreator';

const UpdateESignTemplateMiddleware = (api: ESignTemplatesApi) =>
  Fetcher<ESignTemplate, UpdateESignTemplateAction>(
    ESignTemplatesActionType.UpdateESignTemplate,
    updateESignTemplateSuccess,
    updateESignTemplateFailure,
    (action) => {
      return api.update(action.payload.id, action.payload.template);
    },
  );

export default UpdateESignTemplateMiddleware;
