import {
  OPEN_CREATE_NEW_STRATEGY_VERSION_POP_UP,
  CLOSE_CREATE_NEW_STRATEGY_VERSION_POP_UP,
  CREATE_NEW_STRATEGY_VERSION_REQUEST,
  CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS,
  CREATE_NEW_STRATEGY_VERSION_REQUEST_FAILURE,
} from './ActionTypes';

import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { ActionWithOrigin } from 'utils/actions/ActionWithOrigin';

export const openCreateNewStrategyVersionPopUp = (strategyId: string, strategyName: string) => ({
  type: OPEN_CREATE_NEW_STRATEGY_VERSION_POP_UP,
  payload: { strategyId, strategyName },
});

export const closeCreateNewStrategyVersionPopUp = () => ({ type: CLOSE_CREATE_NEW_STRATEGY_VERSION_POP_UP });

export interface CreateNewStrategyVersionRequestAction {
  type: typeof CREATE_NEW_STRATEGY_VERSION_REQUEST;
  payload: {
    strategyId: string;
    strategyName: string;
  };
}

export const createNewStrategyVersionRequest = (
  strategyId: string,
  strategyName: string,
): CreateNewStrategyVersionRequestAction => ({
  type: CREATE_NEW_STRATEGY_VERSION_REQUEST,
  payload: {
    strategyId,
    strategyName,
  },
});

export interface CreateNewStrategyVersionRequestSuccessAction extends ActionWithOrigin {
  type: typeof CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS;
  payload: {
    strategyId: string;
    strategyName: string;
    strategyVersion: string;
  };
}

export const createNewStrategyVersionRequestSuccess = (payload: {
  strategyId: string;
  strategyVersion: string;
  strategyName: string;
}): CreateNewStrategyVersionRequestSuccessAction => ({
  type: CREATE_NEW_STRATEGY_VERSION_REQUEST_SUCCESS,
  payload,
});

export const createNewStrategyVersionRequestFailure = FailedActionCreator(CREATE_NEW_STRATEGY_VERSION_REQUEST_FAILURE);
