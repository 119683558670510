import React from 'react';
import AuthLayout from 'components/Auth/AuthLayout';
import SecurePhoneAuthenticationForm from 'components/Auth/SecurePhoneAuthenticationForm';
import styles from './SecurePhoneAuthentication.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import { formatPhoneNumber } from 'components/PhoneInput';
import { useAuthProvider } from 'providers/AuthProvider';
import getMessage, { MessageType } from 'constants/messages';
import notification from 'handlers/notification/notificationActionCreator';
import useTimer from 'hooks/useTimer';

const SecurePhoneAuthentication = () => {
  const { seconds, restart, isRunning } = useTimer();
  const accountDetails = useSelector((state: ReduxState) => state.accountDetails);
  const authProvider = useAuthProvider();
  const dispatch = useDispatch();

  const formattedPhone = accountDetails?.phone && (formatPhoneNumber(accountDetails.phone) || accountDetails.phone);

  const getSubTitle = () => {
    return (
      <>
        Please enter the code sent to <div className={styles.subTitlePhone}>{formattedPhone}</div>
      </>
    );
  };

  const handleResendCode = async () => {
    await authProvider.resendPhoneVerificationCode();

    notification.createNotification(
      getMessage(MessageType.AuthenticationCodeSent, { phone: formattedPhone }),
      'success',
      dispatch,
    );

    restart();
  };

  const onSubmit = async (code: string) => {
    try {
      await authProvider.verifyPhoneVerificationCode(code);
    } catch (e) {
      notification.createNotification(e.message, 'error', dispatch);
    }
  };

  return (
    <AuthLayout
      title="Secure Phone Authentication"
      subTitle={getSubTitle()}
      showBackLink
      onClickBack={() => authProvider.logout()}
    >
      <SecurePhoneAuthenticationForm
        onResendCode={handleResendCode}
        resendInSeconds={isRunning ? seconds : 0}
        onSubmit={onSubmit}
      />
    </AuthLayout>
  );
};

export default SecurePhoneAuthentication;
