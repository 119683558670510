import { AnyAction, Middleware, MiddlewareAPI } from 'redux';
import { DeleteIntermediarySuccessAction, getIntermediaries } from './ActionCreator';
import { DELETE_INTERMEDIARY_SUCCESS } from './ActionTypes';
import notification from 'handlers/notification/notificationActionCreator';
import getMessage, { MessageType } from 'constants/messages';
import IntermediaryDefaultVariable from 'enums/IntermediaryDefaultVariable';

const isDeleteIntermediarySuccessAction = (action: AnyAction): action is DeleteIntermediarySuccessAction =>
  action.type === DELETE_INTERMEDIARY_SUCCESS;

export const DeleteIntermediarySuccessMiddleware = (): Middleware => ({ dispatch, getState }: MiddlewareAPI<any>) => (
  next: (action: AnyAction) => any,
) => (action: AnyAction) => {
  const result = next(action);

  if (isDeleteIntermediarySuccessAction(action)) {
    const { loanOriginationSystemIntermediariesState } = getState();

    const { filters, sortingType } = loanOriginationSystemIntermediariesState;

    dispatch(getIntermediaries(filters, sortingType));

    const intermediaryName = action.payload.intermediary.variables[IntermediaryDefaultVariable.Name];
    notification.createNotification(
      getMessage(MessageType.IntermediaryDeleted, { intermediaryName }),
      'success',
      dispatch,
    );
  }

  return result;
};

export default DeleteIntermediarySuccessMiddleware;
