import React, { FC, ReactElement } from 'react';
import { Variable } from 'Variables/VariablesTypes';

export interface VariableTooltipWrapperProps {
  variable?: Variable;
  ignoreStandard?: boolean;
  fieldName?: string;
  tooltip?: string;
  children: ReactElement<{ tooltip?: string }>;
}

const VariableTooltipWrapper: FC<VariableTooltipWrapperProps> = ({
  variable,
  ignoreStandard,
  children,
  fieldName,
  tooltip,
}) => {
  const getTooltip = () => {
    if (tooltip) {
      return tooltip;
    }

    if (variable?.isStandard && !ignoreStandard) {
      return fieldName ? `The ${fieldName} of Standard Variables cannot be modified` : null;
    }

    if (variable?.dependsOn) {
      return `This variable uses the Data Type and Permissions of ${variable.dependsOn.displayName} variable`;
    }

    return null;
  };

  return React.cloneElement(children, { tooltip: getTooltip() });
};

export default VariableTooltipWrapper;
