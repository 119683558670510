import React from 'react';
import AutoCompletionMultiselect from 'components/AutoCompletionMultiselect';
import Button from 'components/Button/Button';
import CheckboxMultiSelectList from 'components/CheckboxMultiSelectList';
import {
  changeFilterState,
  closeReportingFiltersBar,
  toggleStatus,
} from 'LoanOriginationSystemReporting/Filters/ActionCreator';
import {
  ChangeFilterStateConfig,
  ReportingFilterBarState,
  ReportingFilterStateChangeMethods,
} from 'LoanOriginationSystemReporting/Filters/Types';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'types/redux';
import FilterLayout from 'components/LoanOriginationSystem/FilterLayout';
import styles from './ReportingFilter.module.scss';

interface ReportingFilter {
  onApplyClick: () => void;
  onClearFiltersClick: () => void;
}

const noop = () => {};

const ReportingFilter = ({ onApplyClick, onClearFiltersClick }: ReportingFilter) => {
  const dispatch = useDispatch();

  const {
    statuses,
    intermediaries,
    labels,
    products,
    teamMembers,
    selectedStatuses,
    selectedIntermediaries,
    selectedLabels,
    selectedProducts,
    selectedTeamMembers,
  } = useSelector<ReduxState, ReportingFilterBarState>((state) => state.reportingFilterBar);

  const onClosePopup = () => {
    dispatch(closeReportingFiltersBar());
  };

  const handleChangeFilterState = (data: ChangeFilterStateConfig) => {
    dispatch(changeFilterState(data));
  };

  const handleToggleStatus = (id: string) => {
    dispatch(toggleStatus(id));
  };

  return (
    <FilterLayout onClose={onClosePopup} onClearFiltersClick={onClearFiltersClick}>
      <p className={styles.title}>Application Status</p>
      <CheckboxMultiSelectList
        items={statuses.map((status) => ({ id: status.id, label: status.name }))}
        selectedItemsIds={selectedStatuses}
        onChange={handleToggleStatus}
        className={styles.checkboxesMarginLeft}
      />
      <AutoCompletionMultiselect
        id="labelsSelect"
        labelTitle="Label"
        options={labels}
        selectedOptions={selectedLabels}
        onSelect={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Add,
            entityType: 'selectedLabels',
            value,
          })
        }
        onDeleteOption={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Delete,
            entityType: 'selectedLabels',
            value,
          })
        }
        onInputChange={noop}
        isLabelTags
      />
      <AutoCompletionMultiselect
        id="intermediarySelect"
        labelTitle="Intermediary"
        options={intermediaries}
        selectedOptions={selectedIntermediaries}
        onSelect={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Add,
            entityType: 'selectedIntermediaries',
            value,
          })
        }
        onDeleteOption={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Delete,
            entityType: 'selectedIntermediaries',
            value,
          })
        }
        onInputChange={noop}
      />
      <AutoCompletionMultiselect
        id="teamMemberSelect"
        labelTitle="Team Member"
        options={teamMembers}
        selectedOptions={selectedTeamMembers}
        onSelect={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Add,
            entityType: 'selectedTeamMembers',
            value,
          })
        }
        onDeleteOption={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Delete,
            entityType: 'selectedTeamMembers',
            value,
          })
        }
        onInputChange={noop}
      />
      <AutoCompletionMultiselect
        id="productSelect"
        labelTitle="Product"
        options={products}
        selectedOptions={selectedProducts}
        onSelect={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Add,
            entityType: 'selectedProducts',
            value,
          })
        }
        onDeleteOption={(value: string) =>
          handleChangeFilterState({
            method: ReportingFilterStateChangeMethods.Delete,
            entityType: 'selectedProducts',
            value,
          })
        }
        onInputChange={noop}
      />

      <Button kind="primary" size="form" onClick={onApplyClick} className={styles.buttonTopMargin}>
        Apply Filter
      </Button>
    </FilterLayout>
  );
};

export default ReportingFilter;
