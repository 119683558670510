import React, { useEffect, useState } from 'react';
import { FormLayoutData, VariableConfiguration, VariableValue } from 'api/LoanOriginationSystem/Types';
import PopUp from 'components/PopUps/PopUp';
import PopUpContent from 'components/PopUps/PopUpContent';
import Button from 'components/Button';
import ConfigurableForm, { useConfigurableFormValidation } from 'components/ConfigurableForm';
import { isConfigurableFormDataChanged } from 'components/ConfigurableForm/utils';
import { FormSkeleton } from 'components/Skeleton';
import UnsavedChangesPopup from 'components/UnsavedChangesPopup';
import styles from './EditFormDataPopup.module.scss';

export interface EditFormDataPopupProps {
  initialData: FormLayoutData;
  configurations: VariableConfiguration[] | null;
  getConfigurations: () => void;
  title: string | React.ReactNode;
  onPopupClose: () => void;
  onSubmit: (data: FormLayoutData) => void;
  isUpdatingInProgress?: boolean;
  formatDisplayTitle?: (systemName: string, title: string) => string;
  usePortal?: boolean;
}

const EditFormDataPopup = ({
  title,
  getConfigurations,
  configurations,
  initialData,
  onPopupClose,
  formatDisplayTitle,
  isUpdatingInProgress,
  onSubmit,
  usePortal,
}: EditFormDataPopupProps) => {
  const [formData, setFormData] = useState(initialData);
  const [displayUnsavedChangesPopup, setDisplayUnsavedChangesPopup] = useState(false);

  const validateFormData = useConfigurableFormValidation(configurations);

  const isDataWasChanged = isConfigurableFormDataChanged(initialData, formData);

  useEffect(() => {
    if (!configurations) {
      getConfigurations();
    }
  }, []);

  const handleFieldChange = ({ variable }: VariableConfiguration, value: VariableValue) => {
    setFormData({
      ...formData,
      [variable.systemName]: value,
    });
  };

  const renderForm = () => {
    if (!configurations) {
      return <FormSkeleton />;
    }

    return (
      <ConfigurableForm
        configurations={configurations}
        data={formData}
        onFieldChange={handleFieldChange}
        formatDisplayTitle={formatDisplayTitle}
      />
    );
  };

  const handleClose = () => {
    if (isDataWasChanged) {
      setDisplayUnsavedChangesPopup(true);

      return;
    }

    onPopupClose();
  };

  return (
    <PopUp usePortal={usePortal} closePopUp={handleClose} title={title}>
      <PopUpContent>
        {renderForm()}
        <Button
          size="form"
          kind="primary"
          className={styles.saveChangesButton}
          disabled={!isDataWasChanged || !configurations || !validateFormData(formData)}
          onClick={() => onSubmit(formData)}
          isLoading={isUpdatingInProgress}
        >
          Save Changes
        </Button>
      </PopUpContent>
      {displayUnsavedChangesPopup && (
        <UnsavedChangesPopup onPopupClose={() => setDisplayUnsavedChangesPopup(false)} onLeaveClick={onPopupClose} />
      )}
    </PopUp>
  );
};

export default EditFormDataPopup;
