const areArraysEqual = (firstArray: Array<string | number>, secondArray: Array<string | number>) => {
  if (firstArray.length !== secondArray.length) {
    return false;
  }

  const sortedSecondArray = secondArray.sort();

  return firstArray.sort().every((item, key) => item === sortedSecondArray[key]);
};

export default areArraysEqual;
