import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from './index';

export const formatDate = (date: Date | null) => {
  return date ? moment(date).format(DEFAULT_DATE_FORMAT) : '';
};

export const getOnDateChange = (setDate: (date: Date | null) => void) => (value: string) => {
  const momentDate = moment(value, DEFAULT_DATE_FORMAT);

  setDate(
    // moment.isValid returns true if string contains '_' instead of a number
    momentDate.isValid() && !value.includes('_') ? moment(value, DEFAULT_DATE_FORMAT).toDate() : null,
  );
};
