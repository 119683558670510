import { AnyAction } from 'redux';
import { LoanOriginationSystemOrganizationActionType } from './ActionTypes';
import FailedActionCreator from 'utils/actions/FailedActionCreator';
import { UserState } from 'AccountDetails/AccountDetailsTypes';
import { Application, UserInfo } from 'api/LoanOriginationSystem/LoanOriginationSystemApplicationsApi';
import { Variable } from 'Variables/VariablesTypes';

export interface GetOrganizationUsersAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetOrganizationUsers;
  payload: {
    accountDetails: UserState;
    disablePagination?: boolean;
  };
}

export interface GetOrganizationUsersSuccessAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetOrganizationUsersSuccess;
  payload: UserInfo[];
}

export interface GetOrganizationApplicationsAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetOrganizationApplications;
}

export interface GetOrganizationApplicationsSuccessAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetOrganizationApplicationsSuccess;
  payload: {
    applications: Application[];
  };
}

export interface GetStandardVariablesAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetStandardVariables;
}

export interface GetStandardVariablesSuccessAction extends AnyAction {
  type: LoanOriginationSystemOrganizationActionType.GetStandardVariablesSuccess;
  payload: {
    standardVariables: Variable[];
  };
}

export const getOrganizationUsers = (
  accountDetails: UserState,
  disablePagination?: boolean,
): GetOrganizationUsersAction => {
  return {
    type: LoanOriginationSystemOrganizationActionType.GetOrganizationUsers,
    payload: {
      accountDetails,
      disablePagination,
    },
  };
};

export const getOrganizationUsersSuccess = (organizationMembers: UserInfo[]): GetOrganizationUsersSuccessAction => {
  return {
    type: LoanOriginationSystemOrganizationActionType.GetOrganizationUsersSuccess,
    payload: organizationMembers,
  };
};

export const getOrganizationApplications = (): GetOrganizationApplicationsAction => {
  return {
    type: LoanOriginationSystemOrganizationActionType.GetOrganizationApplications,
  };
};

export const getOrganizationApplicationsSuccess = (
  applications: Application[],
): GetOrganizationApplicationsSuccessAction => {
  return {
    type: LoanOriginationSystemOrganizationActionType.GetOrganizationApplicationsSuccess,
    payload: {
      applications,
    },
  };
};

export const getStandardVariables = (): GetStandardVariablesAction => ({
  type: LoanOriginationSystemOrganizationActionType.GetStandardVariables,
});

export const getStandardVariablesSuccess = (standardVariables: Variable[]): GetStandardVariablesSuccessAction => ({
  type: LoanOriginationSystemOrganizationActionType.GetStandardVariablesSuccess,
  payload: {
    standardVariables,
  },
});

export const getOrganizationUsersFailure = FailedActionCreator(
  LoanOriginationSystemOrganizationActionType.GetOrganizationUsersFailure,
);

export const getOrganizationApplicationsFailure = FailedActionCreator(
  LoanOriginationSystemOrganizationActionType.GetOrganizationApplicationsFailure,
);

export const getStandardVariablesFailure = FailedActionCreator(
  LoanOriginationSystemOrganizationActionType.GetStandardVariablesFailure,
);
