import React, { FC } from 'react';
import PopUpWizard from 'components/PopUps/PopUpWizard';
import DeletePopup from 'components/DeletePopup';

interface RunDecisionWizardProps {
  isLoading: boolean;
  closePopUp: () => void;
  decisionRunId: string;
  onDeleteDecisionRun: (decisionRunId: string) => void;
}

export const DeleteDecisionWizard: FC<RunDecisionWizardProps> = ({
  closePopUp,
  isLoading,
  onDeleteDecisionRun,
  decisionRunId,
}) => {
  return (
    <PopUpWizard step={0} closePopUp={closePopUp} title="Delete Result" closable={!isLoading}>
      <DeletePopup
        message="Do you want to permanently delete this result?"
        handleDelete={() => onDeleteDecisionRun(decisionRunId)}
        handleClose={closePopUp}
        isLoading={isLoading}
        cancelBtnDisabled={isLoading}
      />
    </PopUpWizard>
  );
};
