import React, { FC, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { ApplicationFormPage } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import ButtonWithImage from 'components/ButtonWithImage';
import TabSwitch, { TabSwitchSkeleton, TabSwitchOption } from 'components/TabSwitch';
import ContextualPopUp from 'components/PopUps/ContextualPopUp';
import WrapperWithTooltip from 'components/Tooltip';
import ActionPopUpItem from 'components/ActionPopUpItem';
import { labelsByFormPageMap } from 'components/LoanOriginationSystem/ProductConfiguration/ApplicationFormConfiguration/labels';
import ProductConfigurationSubHeader from 'components/LoanOriginationSystem/ProductConfiguration/ProductConfigurationSubHeader';
import styles from './Header.module.scss';

export interface HeaderProps {
  selectedApplicationFormPage?: ApplicationFormPage | null;
  applicationFormPages?: ApplicationFormPage[] | null;
  onTabChange: (tab: ApplicationFormPage) => void;
  onApplicationFormPageDelete: (formPage: ApplicationFormPage) => void;
  onApplicationFormPageAdd: (formPage: ApplicationFormPage) => void;
  onFormSettingsClick: () => void;
}

interface TabDropdownOption {
  name: string;
  type: ApplicationFormPage;
}

const APPLICATION_PAGES_OPTIONS = [
  { name: 'Co-Borrower', type: ApplicationFormPage.CoBorrower },
  { name: 'Intermediary', type: ApplicationFormPage.Intermediary },
];

const DEFAULT_APPLICATION_PAGES = [ApplicationFormPage.Borrower, ApplicationFormPage.ApplicationDetails];

const Header: FC<HeaderProps> = ({
  selectedApplicationFormPage,
  applicationFormPages,
  onTabChange,
  onApplicationFormPageAdd,
  onApplicationFormPageDelete,
  onFormSettingsClick,
}) => {
  const [displayAddPagePopup, setDisplayAddPagePopup] = useState(false);
  const addPageButtonRef = useRef<HTMLButtonElement | null>(null);

  const tabs: TabSwitchOption[] | null = useMemo(() => {
    if (!applicationFormPages) {
      return null;
    }

    const availableApplicationFormPages = Object.values(ApplicationFormPage);
    const baseTabs = applicationFormPages
      .map((formPage) => ({
        id: formPage,
        label: labelsByFormPageMap.get(formPage) || '',
        disableDelete: DEFAULT_APPLICATION_PAGES.includes(formPage),
      }))
      .sort((firstTab, secondTab) => {
        return availableApplicationFormPages.indexOf(firstTab.id) - availableApplicationFormPages.indexOf(secondTab.id);
      });

    const displayAddPage = !Object.values(ApplicationFormPage).every((page) => applicationFormPages.includes(page));

    return displayAddPage
      ? [
          ...baseTabs,
          {
            label: 'Add Page',
            id: 'addPage',
            type: 'button',
            ref: addPageButtonRef,
            onClick: () => setDisplayAddPagePopup(true),
          },
        ]
      : baseTabs;
  }, [applicationFormPages]);

  const handleCloseAddPagePopup = () => setDisplayAddPagePopup(false);

  const handleApplicationFormPageAdd = (type: ApplicationFormPage) => {
    onApplicationFormPageAdd(type);
    handleCloseAddPagePopup();
  };

  const renderTabDropdownOption = ({ name, type }: TabDropdownOption) => {
    const disabled = applicationFormPages?.includes(type);

    return (
      <WrapperWithTooltip key={type} tooltip={disabled ? `The ${name} page is already active` : ''}>
        <div className={clsx(disabled && styles.disabledAddPageButtonContextualButtonContainer)}>
          <ActionPopUpItem
            className={styles.addPageButtonContextualButton}
            key={type}
            disabled={disabled}
            onClick={() => handleApplicationFormPageAdd(type)}
          >
            {name}
          </ActionPopUpItem>
        </div>
      </WrapperWithTooltip>
    );
  };

  const renderTabs = () => {
    if (!tabs) {
      return (
        <TabSwitchSkeleton
          itemsCount={Object.values(ApplicationFormPage).length}
          className={styles.tabSwitchSkeleton}
        />
      );
    }

    return (
      <TabSwitch
        tabs={tabs}
        selectedTabId={selectedApplicationFormPage || ''}
        onSelect={({ id }) => onTabChange(id as ApplicationFormPage)}
        allowTabDelete
        onTabDelete={({ id }) => onApplicationFormPageDelete(id as ApplicationFormPage)}
        tabClassName={styles.tab}
        borderContainerClassName={styles.tabSwitchBorderContainer}
        removeTabClassName={styles.removeTabImage}
      />
    );
  };

  const renderQuestionTooltip = () => (
    <>
      <p>The information, requirements and settings</p>
      <p>for creating new applications.</p>
    </>
  );

  const renderSubHeaderActions = () => (
    <ButtonWithImage
      disabled={!tabs}
      className={styles.formSettingsButton}
      title="Edit Form Settings"
      kind="edit"
      onClick={() => onFormSettingsClick()}
    />
  );

  return (
    <div className={styles.header}>
      <ProductConfigurationSubHeader
        className={styles.subheader}
        renderQuestionTooltip={renderQuestionTooltip}
        renderActions={renderSubHeaderActions}
        title="Application Form"
      />
      {renderTabs()}
      <ContextualPopUp
        anchorEl={addPageButtonRef.current}
        open={displayAddPagePopup}
        onClose={handleCloseAddPagePopup}
        anchorOrigin={{ vertical: 'bottom', horizontal: 0 }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <div className={styles.actionsContainer}>{APPLICATION_PAGES_OPTIONS.map(renderTabDropdownOption)}</div>
      </ContextualPopUp>
    </div>
  );
};

export default Header;
