import React, { FC } from 'react';
import { Product, ProductStatus } from 'api/LoanOriginationSystem/LoanOriginationSystemProductsApi';
import capitalize from 'capitalize';
import ConfirmPopup from 'components/ConfirmPopup';
import styles from './ChangeProductStatusConfirmPopup.module.scss';

export interface ChangeProductStatusConfirmPopupProps {
  product: Product;
  status: ProductStatus;
  onPopupClose: () => void;
  onConfirmClick: () => void;
  loading?: boolean;
}

const ChangeProductStatusConfirmPopup: FC<ChangeProductStatusConfirmPopupProps> = ({
  product,
  status,
  onPopupClose,
  onConfirmClick,
  loading,
}) => {
  const message =
    status === ProductStatus.Archived
      ? `Please confirm that you would like to archive your ${product.name} product.`
      : `Please confirm that you would like to set ${product.name} to ${capitalize(status)}.`;

  return (
    <ConfirmPopup
      title={status === ProductStatus.Archived ? 'Archive Product' : 'Change Product Status'}
      message={message}
      confirmText={status === ProductStatus.Archived ? 'Archive' : 'Confirm'}
      declineText="No, Go Back"
      onPopupClose={onPopupClose}
      onConfirmClick={onConfirmClick}
      confirmButtonClassName={styles.confirmButton}
      declineButtonClassName={styles.declineButton}
      loading={loading}
    />
  );
};

export default ChangeProductStatusConfirmPopup;
